<template>
  <transition name="side-notification">
    <div
      class="side-notification"
      :class="'side-notification--' + type"
      v-if="show_notification"
    >
      <p class="d-flex">
        <fa-icon
          size="lg"
          class="side-notification__icon margin__r--12"
          :class="`color--${color}`"
          :icon="icon"
          aria-hidden="true"
        />

        {{ t(text) }}
      </p>

      <button
        class="side-notification__close"
        :aria-label="t('literal.close')"
        @click="handleClickClose()"
      >
        <fa-icon
          class="color--vlg"
          :icon="['fas', 'times']"
          aria-hidden="true"
        />
      </button>
    </div>
  </transition>
</template>

<script setup>
import { ref, computed, toRefs, onMounted } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useNotificationStore } from '@/stores/NotificationStore'
import { get as _get } from 'lodash'

const props = defineProps({ notification: { type: Object, required: true } })

const { notification } = toRefs(props)

const show_notification = ref(false)

const { t } = useI18n()
const { deleteSideNotification } = useNotificationStore()

const text = computed(() => _get(notification.value, 'text', ''))
const type = computed(() => _get(notification.value, 'type', 'success'))
const delay = computed(() => _get(notification.value, 'delay', 5000))

const icon = computed(() => {
  if (type.value === 'success') return ['far', 'check-circle']
  if (type.value === 'info') return ['fal', 'info-circle']
  if (type.value === 'warning') return ['fas', 'exclamation-circle']

  return ['fal', 'exclamation-triangle']
})

const color = computed(() => {
  if (type.value === 'warning') return 'warning'
  if (type.value === 'success') return 'success'
  if (type.value === 'info') return 'ter'

  return 'danger'
})

const handleClickClose = () => {
  show_notification.value = false

  setTimeout(() => deleteSideNotification(notification.value), 700)
}

if (delay.value) setTimeout(() => handleClickClose(), delay.value)

onMounted(() => show_notification.value = true)
</script>

<style scoped lang="scss">
.side-notification {
  position: relative;
  padding: 12px 24px 12px 12px;
  border-radius: $radius__cards;
  background: $white;
  box-shadow: 0 4px 8px 0 rgba(82, 97, 115, 0.1);
  font-size: em(14px);
  word-break: break-word;

  &-enter-active {
    animation: translateY .7s;

    @include mq(sm) {
      animation: translateX .7s;
    }
  }

  &-leave-active {
    animation: translateY .7s reverse;

    @include mq(sm) {
      animation: translateX .7s reverse;
    }
  }

  &--error {
    border-left: 3px solid $color--danger;
  }

  &--warning {
    border-left: 3px solid $color--warning;
  }

  &--success {
    border-left: 3px solid $color--success;
  }

  &__icon {
    margin-top: 1px;
  }

  &__close {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}

@keyframes translateX {
  0% {
    transform: translateX(350px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes translateY {
  0% {
    transform: translateY(50%);
  }

  100% {
    transform: translateY(0%);
  }
}
</style>
