import { ref } from 'vue'

export function useModals () {
  const show_modal = ref(false)
  const openModal = () => show_modal.value = true
  const closeModal = () => show_modal.value = false

  return {
    openModal,
    closeModal,
    show_modal
  }
}
