<template>
  <section>
    <ul
      class="tabs"
      role="tablist"
    >
      <template v-if="isActiveTab('forgot_password')">
        <li class="tabs__item tabs__item--center">
          <button
            class="tab tab--icon btn btn__special--back btn__size--square-24"
            :aria-label="t(`literal.login`)"
            aria-controls="login"
            @click="handleClickTab('login')"
          >
            <fa-icon
              class="icon"
              :icon="['fas', 'long-arrow-alt-left']"
              aria-hidden="true"
            />
          </button>
        </li>

        <li class="tabs__item">
          <button
            class="tab tab--active link__color--vlg link--naked"
            aria-selected="true"
            aria-controls="forgot_password"
            @click="handleClickTab('forgot_password')"
            @keydown="e => handleKeydownTab(e)"
          >
            {{ t(`literal.forgot_password`) }}
          </button>
        </li>
      </template>

      <template v-else>
        <li
          class="tabs__item"
          v-for="availableTab in availableTabs"
          :key="availableTab"
        >
          <button
            class="tab link__color--vlg link--naked"
            :class="{ 'tab--active': isActiveTab(availableTab) } "
            :tabindex="!isActiveTab(availableTab) ? -1 : null"
            :aria-selected="isActiveTab(availableTab)"
            :aria-controls="availableTab"
            @click="handleClickTab(availableTab)"
            @focus="handleClickTab(availableTab)"
            @keydown="e => handleKeydownTab(e)"
          >
            <template v-if="availableTab === 'register'">
              {{ t(`literal.create_your_account`) }}
            </template>

            <template v-else>
              {{ t(`literal.${availableTab}`) }}
            </template>
          </button>
        </li>
      </template>
    </ul>

    <login-form
      class="tabpanel margin__t--24"
      role="tabpanel"
      tabindex="0"
      aria-labelledby="login"
      v-show="isActiveTab('login')"
      @user-authenticated="handleUserAuthenticated()"
      @forgot-password="handleClickTab('forgot_password')"
    />

    <register-form
      class="tabpanel margin__t--24"
      role="tabpanel"
      tabindex="0"
      aria-labelledby="register"
      v-show="isActiveTab('register')"
      @user-authenticated="handleUserAuthenticated()"
    />

    <forgot-password-form
      class="tabpanel margin__t--24"
      role="tabpanel"
      tabindex="0"
      aria-labelledby="forgot_password"
      v-show="isActiveTab('forgot_password')"
      @forgot-password-mail-sended="handleClickTab('login')"
    />
  </section>
</template>

<script setup>
import { ref, computed, watch, defineAsyncComponent, toRefs, onMounted, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useLanguageStore } from '@/stores/LanguageStore'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { useI18n } from '@/vendors/i18n'
import { storeToRefs } from 'pinia'
import { get as _get } from 'lodash'

const LoginForm = defineAsyncComponent(() => import('&/modules/sessions/LoginForm'))
const RegisterForm = defineAsyncComponent(() => import('&/modules/sessions/RegisterForm'))
const ForgotPasswordForm = defineAsyncComponent(() => import('&/modules/sessions/ForgotPasswordForm'))

const emit = defineEmits(['authenticated', 'close'])

const props = defineProps({
  tab: { type: String, default: 'register' },
  availableTabs: { type: Array, default: () => ['login', 'register'] }
})

const { tab: defaultTab, availableTabs } = toRefs(props)

const { t } = useI18n()
const { replace } = useRouter()
const route = useRoute()
const { locale } = storeToRefs(useLanguageStore())
const { initAuthentication } = useAuthenticationStore()

const tabs = ref([])
const currentTab = ref(defaultTab.value)
const controls = computed(() => tabs.value.map(tab => tab.getAttribute('aria-controls')))

const isActiveTab = tab => currentTab.value === tab

const handleClickTab = tab => currentTab.value = tab

const handleKeydownTab = e => {
  const target = e.currentTarget
  const control = target.getAttribute('aria-controls')

  if (!controls.value.length || !control) return
  if (e.key === 'ArrowLeft') return currentTab.value = control === controls.value[0] ? controls.value[controls.value.length - 1] : _get(controls.value, controls.value.indexOf(control) - 1)
  if (e.key === 'ArrowRight') return currentTab.value = control === controls.value[controls.value.length - 1] ? controls.value[0] : _get(controls.value, controls.value.indexOf(control) + 1)
}

const handleUserAuthenticated = () => {
  initAuthentication()
    .then(() => {
      if (route.params.locale !== locale.value) replace({ params: { locale: locale.value }, query: route.query })
    })
    .finally(() => emit('authenticated'))
}

watch(currentTab, () => {
  nextTick(() => {
    tabs.value = Array.from(document.querySelectorAll('.tab'))
    tabs.value.find(t => t.getAttribute('aria-controls') === currentTab.value)?.focus()
  })
})

onMounted(() => tabs.value = Array.from(document.querySelectorAll('.tab')))
</script>

<style scoped lang="scss">
.tabs {
  display: flex;

  &__item {
    &:not(:last-child) {
      margin-right: 24px;
    }

    &--center {
      display: flex;
      align-items: center;
    }
  }
}

.tab {
  font-family: $font-family--secondary;
  font-size: em(16px);
  text-align: left;

  @include mq(sm) {
    font-size: em(20px);
  }

  @include mq(md) {
    font-size: em(24px);
  }

  &--icon {
    font-size: rem(16px);
  }

  &--active {
    color: $color--primary;

    &:after {
      content: '';
      display: block;
      width: 20px;
      height: 1px;
      background: $color--primary;
      margin-top: 6px;
    }
  }
}

.tabpanel {
  &:focus {
    outline: 1px dashed $vlg;
    outline-offset: 10px;
  }
}

.sessions {
  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .btn {
      align-self: center;
    }
  }
}

.link {
  color: $vlg;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: $margin--base * 2;
  }

  &--active {
    color: $color--success;

    &:after {
      content: '';
      display: block;
      width: 20px;
      height: 1px;
      background: $color--success;
      margin-top: calc($margin--base / 2);
    }
  }
}
</style>
