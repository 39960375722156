import { defineStore } from 'pinia'
import { toValue } from 'vue'
import Template from '@/classes/templates/Template'

export const useTemplateStore = defineStore('TemplateStore', {
  state: () => ({
    template: {},
    loader: false,
    error: {}
  }),
  getters: {
    getTemplate: state => ({ id }) => {
      return state.$getItem({
        resource: 'template',
        key: id,
        fetch: () => state.fetchTemplate({ id: toValue(id) })
      })
    }
  },
  actions: {
    fetchTemplate ({ id }) {
      return this.$fetchItem({
        endpoint: `/api/v1/templates/${id}`,
        resource: `template.${id}`,
        model: Template
      })
    }
  }
})