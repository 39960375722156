<template>
  <nav>
    <ul class="settings__list">
      <li class="settings__item">
        <router-link
          class="settings__link"
          :class="{'settings__link--active': $route.name === 'ProfileSettingsPersonalInfo'}"
          :to="{ name: 'ProfileSettingsPersonalInfo' }"
        >
          <p>
            <fa-icon
              size="xs"
              class="margin__r--6 color--prim"
              :icon="['fal', 'user']"
              fixed-width
            />
            {{ $i18n.t('literal.personal_info') }}
          </p>

          <fa-icon
            size="xs"
            class="settings__back color--mg"
            :icon="['fas', 'chevron-right']"
          />
        </router-link>
      </li>

      <li class="settings__item">
        <router-link
          class="settings__link"
          :class="{'settings__link--active': $route.name === 'ProfileSettingsAddress'}"
          :to="{ name: 'ProfileSettingsAddress' }"
        >
          <p>
            <fa-icon
              size="xs"
              class="margin__r--6 color--prim"
              :icon="['fal', 'map-marker-alt']"
              fixed-width
            />
            {{ $i18n.t('literal.address') }}
          </p>

          <fa-icon
            size="xs"
            class="settings__back color--mg"
            :icon="['fas', 'chevron-right']"
          />
        </router-link>
      </li>

      <li class="settings__item">
        <router-link
          class="settings__link"
          :class="{'settings__link--active': $route.name === 'ProfileSettingsProfilePicture'}"
          :to="{ name: 'ProfileSettingsProfilePicture' }"
        >
          <p>
            <fa-icon
              size="xs"
              class="margin__r--6 color--prim"
              :icon="['fal', 'camera']"
              fixed-width
            />
            {{ $i18n.t('literal.profile_picture') }}
          </p>

          <fa-icon
            size="xs"
            class="settings__back color--mg"
            :icon="['fas', 'chevron-right']"
          />
        </router-link>
      </li>

      <li
        class="settings__item"
        v-if="hasIntegration"
      >
        <router-link
          class="settings__link"
          :class="{'settings__link--active': $route.name === 'ProfileSettingsIntegrations'}"
          :to="{ name: 'ProfileSettingsIntegrations' }"
        >
          <p>
            <fa-icon
              size="xs"
              class="margin__r--6 color--prim"
              :icon="['fal', 'plug']"
              fixed-width
            />
            {{ $i18n.t('literal.integrations') }}
          </p>

          <fa-icon
            size="xs"
            class="settings__back color--mg"
            :icon="['fas', 'chevron-right']"
          />
        </router-link>
      </li>

      <li class="settings__item">
        <router-link
          class="settings__link"
          :class="{'settings__link--active': $route.name === 'ProfileSettingsChangePassword'}"
          :to="{ name: 'ProfileSettingsChangePassword' }"
        >
          <p>
            <fa-icon
              size="xs"
              class="margin__r--6 color--prim"
              :icon="['fal', 'lock']"
              fixed-width
            />
            {{ $i18n.t('literal.change_password') }}
          </p>

          <fa-icon
            size="xs"
            class="settings__back color--mg"
            :icon="['fas', 'chevron-right']"
          />
        </router-link>
      </li>

      <li class="settings__item">
        <router-link
          class="settings__link"
          :class="{'settings__link--active': $route.name === 'ProfileSettingsLanguage'}"
          :to="{ name: 'ProfileSettingsLanguage' }"
        >
          <p>
            <fa-icon
              size="xs"
              class="margin__r--6 color--prim"
              :icon="['fal', 'language']"
              fixed-width
            />
            {{ $i18n.t('literal.language') }}
          </p>

          <fa-icon
            size="xs"
            class="settings__back color--mg"
            :icon="['fas', 'chevron-right']"
          />
        </router-link>
      </li>
    </ul>

    <div class="settings__download">
      <router-link
        class="btn__download btn btn__color--ter btn__size--small"
        target="_blank"
        :to="{ name: 'ProfileExportData' }"
      >
        {{ $i18n.t('profile|settings.btn_see_your_data') }}
      </router-link>
    </div>
  </nav>
</template>

<script>
import { defineComponent } from 'vue'
import { useAllowedFeatures } from '@/composables/app/useAllowedFeatures'

export default defineComponent({
  name: 'SettingsNav',
  setup: () => {
    const { allowedSportingActivitiesProvider } = useAllowedFeatures()

    return { allowedSportingActivitiesProvider }
  },
  computed: {
    hasIntegration () {
      return [this.allowedSportingActivitiesProvider('strava')].some(integration => integration)
    }
  }
})
</script>

<style lang="scss" scoped>
.settings {
  &__item {
    &:first-child .settings__link {
      padding-top: 0;
    }

    &:last-child .settings__link {
      padding-bottom: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $dw;
    }
  }

  &__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $padding--base 0;
    color: $mg;

    &--active {
      font-weight: bold;
      color: $black;
    }

    &:hover {
      .settings__back {
        transform: translateX(0);
      }
    }
  }

  &__download {
    margin-top: $margin--base * 3;
    border-radius: $radius__buttons;
  }
}

.btn {
  &__download {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
