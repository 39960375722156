<template>
  <div>
    <label
      v-if="translate(label)"
      class="input__label"
      :for="id"
    >
      {{ translate(label) }} <span v-if="block.is_required">*</span>
    </label>

    <input-datepicker
      class="margin__t--6"
      v-model="date"
      :id="id"
      :open-date="options.open_date"
      :show-time="options.show_time"
      :available-dates="options.available_dates"
      :v="validator"
    />
  </div>
</template>

<script setup>
import { inject, toRefs, computed } from 'vue'
import { useModule } from '@/composables/app/useModule'
import { useProcedure, useProcedureBlockValidator } from '@/composables/modules/procedures'
import { useI18n } from '@/vendors/i18n'
import { format } from 'date-fns'
import { get as _get, set as _set } from 'lodash'

import InputDatepicker from '&/atoms/InputDatepicker'

const props = defineProps({ block: { type: Object, required: true } })

const { block } = toRefs(props)

const procedure = inject('procedure')
const procedure_execution = inject('procedure_execution')

const { translate } = useI18n()
const { getDefaultValue } = useProcedure({ procedure })
const { id, label, attributes, options } = useModule({ component: block, parent: procedure_execution })

const defaultValue = computed(() => {
  if (attributes.value.default_option === 'specific_date') return attributes.value.default_value
  if (attributes.value.default_option === 'today') return new Date().toISOString()

  return getDefaultValue(block.value.default_value)
})

const dateFormat = computed(() => _get(options.value, 'format'))

const date = computed({
  get: () => _get(procedure_execution.value, block.value.model),
  set: value => _set(procedure_execution.value, block.value.model, dateFormat.value ? format(new Date(value), dateFormat.value) : value)
})

const { validator } = useProcedureBlockValidator({ validations: block.value.validations, state: date })

if (!date.value && defaultValue.value) date.value = defaultValue.value
</script>
