<template>
  <div>
    <fieldset class="amounts">
      <legend class="visually-hidden">
        {{ t('literal.choose_amount') }}
      </legend>

      <div
        class="amount"
        v-for="amount in amounts"
        :key="amount"
      >
        <input
          type="radio"
          class="amount__input visually-hidden"
          v-model="formAmount"
          :value="amount"
          :id="`${id}_${amount}`"
        >

        <label
          class="amount__label d-block padding--12 align--center"
          :for="`${id}_${amount}`"
        >
          <app-money :amount="amount" />
        </label>
      </div>
    </fieldset>

    <div
      class="margin__t--12"
      v-if="isCustomAmountFieldRequired"
    >
      <label
        class="input__label margin__b--6"
        for="custom_amount"
      >
        {{ translate(label) }}
      </label>

      <input-amount
        id="custom_amount"
        v-model.number="customAmount"
        :v="validator"
        :class="{ 'color--vlg': !customAmount }"
        placeholder="0"
      />
    </div>

    <p
      class="disclaimer margin__t--24"
      v-if="frequency === 'monthly'"
    >
      {{ t('$block_amount.recurring_disclaimer_text') }}
    </p>
  </div>
</template>

<script setup>
import { ref, computed, defineAsyncComponent, toRefs, inject } from 'vue'
import { useModule } from '@/composables/app/useModule'
import { useProcedureTransaction, useProcedureBlockValidator } from '@/composables/modules/procedures'
import { get as _get } from 'lodash'
import { useI18n } from '@/vendors/i18n'

const InputAmount = defineAsyncComponent(() => import('&/atoms/InputAmount'))
const AppMoney = defineAsyncComponent(() => import('&/atoms/AppMoney'))

const props = defineProps({ block: { type: Object, required: true } })

const { block } = toRefs(props)

const procedure = inject('procedure')
const procedure_execution = inject('procedure_execution')

const timeout = ref()

const { t, translate } = useI18n()
const { id, attributes } = useModule({ component: block, parent: procedure_execution })
const { formAmount, frequency } = useProcedureTransaction({ procedure, procedure_execution })
const { validator } = useProcedureBlockValidator({ validations: block.value.validations, state: formAmount })

const label = computed(() => _get(attributes.value, `${frequency.value}.label`, {}))
const amounts = computed(() => _get(attributes.value, `${frequency.value}.amounts`, []).map(amount => amount.value))
const defaultAmount = computed(() => _get(attributes.value, `${frequency.value}.highlighted_amount`, _get(amounts.value, '0', 0)))
const isCustomAmountFieldRequired = computed(() => _get(attributes.value, `${frequency.value}.custom_amount_field_required`, true))

const customAmount = computed({
  get: () => formAmount.value !== 0 && !amounts.value.includes(formAmount.value) ? formAmount.value : null,
  set: value => {
    clearTimeout(timeout.value)

    timeout.value = setTimeout(() => formAmount.value = value, 500)
  }
})

if (!formAmount.value) formAmount.value = defaultAmount.value
</script>

<style lang="scss" scoped>
.amounts {
  border: 1px solid $dw;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  @include mq(md) {
    flex-direction: row;
  }
}

.amount {
  flex-grow: 1;
  position: relative;

  &__input {
    &:focus+.amount__label {
      border-color: $dg;
    }

    &:checked+.amount__label {
      cursor: default;
      background-color: $color--primary;
      border-color: $color--primary;
      color: set-contrast($color--primary);
    }

    &:disabled+.amount__label {
      background: transparent;
      color: $black;
      border-color: transparent;
    }
  }

  &__label {
    color: $lg;
    border-radius: 6px;
    width: 100%;
    user-select: none;
    white-space: nowrap;
    transition: all .3s;
    cursor: pointer;
    border: 1px solid transparent;

    &:hover {
      background-color: tint($color--primary, 95%, 95%);
      border-color: tint($color--primary, 95%, 95%);
      color: $black;
    }
  }
}

.disclaimer {
  font-size: rem(12px);
  color: $mg;
  padding: 12px;
  border: 1px solid $mg;
}
</style>
