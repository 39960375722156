export default [
  {
    name: 'project_aside',
    layout: 'small',
    style: {
      md: {
        width: '300px'
      }
    }
  }
]