<template>
  <div
    ref="wrapper"
    class="scrolling-wrapper"
    @mousedown="handleMouseDown"
    @mousemove="handleMouseMove"
    @mouseleave="is_down = false"
    @mouseup="is_down = false"
  >
    <slot />
  </div>
</template>

<script setup>
import { ref } from 'vue'

const wrapper = ref()
const start_x = ref()
const scroll_left = ref()
const is_down = ref(false)

const handleMouseDown = e => {
  is_down.value = true
  start_x.value = e.pageX - wrapper.value.offsetLeft
  scroll_left.value = wrapper.value.scrollLeft
}

const handleMouseMove = e => {
  if (!is_down.value) return
  e.preventDefault()

  const x = (e.pageX - wrapper.value.offsetLeft - start_x.value) * 3
  wrapper.value.scrollLeft = scroll_left.value - x
}
</script>

  <style lang="scss" scoped>
  .scrolling-wrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    cursor: grab;

    &::-webkit-scrollbar {
      height: 3px;
    }

    &::-webkit-scrollbar-track {
      background: rgba($dg, 0.1);
      cursor: initial;
    }

    &::-webkit-scrollbar-thumb {
      background: $color--secondary;
      border-radius: 10px;
      cursor: initial;
    }

    @supports not selector(::-webkit-scrollbar) {
      & {
        scrollbar-color: $color--secondary rgba($dg, 0.1);
        scrollbar-width: thin;
      }
    }
  }
  </style>