import { computed } from 'vue'
import { useStore } from 'vuex'

const mapState = module => {
  const store = useStore()
  return Object.fromEntries(
    Object.keys(store.state)
      .filter(key => module ? key.includes(`${module}/`) : true)
      .map(key => {
        const key_name = module ? key.replace(`${module}/`, '') : key
        return [key_name, computed(() => store.state[key])]
      })
  )
}

const mapGetters = module => {
  const store = useStore()
  return Object.fromEntries(
    Object.keys(store.getters)
      .filter(getter => module ? getter.includes(`${module}/`) : true)
      .map(getter => {
        const getter_name = module ? getter.replace(`${module}/`, '') : getter
        return [getter_name, computed(() => store.getters[getter])]
      })
  )
}

const mapMutations = module => {
  const store = useStore()
  return Object.fromEntries(
    Object.keys(store['_mutations'])
      .filter(mutation => module ? mutation.includes(`${module}/`) : true)
      .map(mutation => {
        const mutation_name = module ? mutation.replace(`${module}/`, '') : mutation
        return [mutation_name, value => store.commit(mutation, value)]
      })
  )
}

const mapActions = module => {
  const store = useStore()
  return Object.fromEntries(
    Object.keys(store['_actions'])
      .filter(action => module ? action.includes(`${module}/`) : true)
      .map(action => {
        const action_name = module ? action.replace(`${module}/`, '') : action
        return [action_name, value => store.dispatch(action, value)]
      })
  )
}

export { mapState, mapGetters, mapMutations, mapActions }
