<template>
  <div
    class="editor__content"
    :class="`editor__content--${layout}`"
    v-html="text"
    v-if="isShowed('text_editor') && text"
  />
</template>

<script setup>
import { computed, toRefs } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useBlock } from '@/composables/modules/templates'

const props = defineProps({
  column: { type: Object, required: true },
  block: { type: Object, required: true }
})

const { column } = toRefs(props)

const { translate } = useI18n()
const { getText, isShowed } = useBlock(props.block)

const layout = computed(() => column.value.is_small ? 'compact' : 'normal')
const text = computed(() => translate(getText('editor')))
</script>