import { inject } from 'vue'
import procedure from './procedure'
import project from './project'
import session from './session'
import user from './user'
import action from './action'
import update from './update'

class Tracking {
  constructor () {
    this.session = session
    this.user = user
    this.procedure = procedure
    this.project = project
    this.action = action
    this.update = update
  }
}

const trackingSymbol = Symbol()
const tracking = new Tracking()

export default tracking

export const createTracking = {
  install: app => app.provide(trackingSymbol, tracking)
}

export function useTracking () {
  return inject(trackingSymbol)
}