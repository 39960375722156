export default [
  {
    name: 'custom_footer_blindenzorglichtliefde',
    path: 'modules/pages/blindenzorglichtliefde/FooterBlindenzorglichtliefde'
  },
  {
    name: 'custom_header_blindenzorglichtliefde',
    path: 'modules/pages/blindenzorglichtliefde/HeaderBlindenzorglichtliefde'
  },
  {
    name: 'crowdfunding_blindenzorglichtliefde',
    path: 'modules/pages/blindenzorglichtliefde/CrowdfundingBlindenzorglichtliefde'
  }
]
