<template>
  <section
    class="card__padding"
    v-if="project"
  >
    <h3 class="title__h3 bold">
      {{ t('literal.invite_members_to_project') }}
    </h3>

    <p class="margin__t--6">
      {{ t('project_procedure.invite_members_description') }}
    </p>

    <form-notification
      class="margin__t--24"
      :keys="error"
    />

    <div class="margin__t--24">
      <template
        v-for="(invite, index) in invites"
        :key="`index_${index}`"
      >
        <procedure-invite-members-item
          class="margin__t--12"
          v-model="invite.email"
        />
      </template>
    </div>

    <div class="margin__t--18">
      <button
        class="btn btn__invite"
        v-if="!project.limit_of_members || limitOfMembersLeft > 0"
        @click.prevent="handleClickAddEmail()"
      >
        <fa-icon
          class="margin__r--6"
          :icon="['fal', 'plus']"
        />

        {{ t('literal.invite_another_member') }}
      </button>
    </div>

    <div class="margin__t--24">
      <app-save-button
        class="btn btn__size--small btn__color--prim"
        :disabled="loader || !emails.length"
        :is-loading="loader || inviteLoader"
        @click="handleClickSend()"
      >
        <template #icon>
          <fa-icon :icon="['fal', 'paper-plane']" />
        </template>

        {{ t('literal.send_invitation') }}
      </app-save-button>
    </div>
  </section>
</template>

<script setup>
import { ref, computed, toRefs, defineAsyncComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useVuelidate } from '@vuelidate/core'
import { useI18n } from '@/vendors/i18n'
import { useProjectStore } from '@/stores/ProjectStore'

import AppSaveButton from '&/molecules/AppSaveButton'
import ProcedureInviteMembersItem from '&/modules/project/ProcedureInviteMembersItem'

const FormNotification = defineAsyncComponent(() => import('&/molecules/FormNotification'))

const emit = defineEmits(['close'])

const props = defineProps({ projectSlug: { type: String, required: true } })

const { projectSlug } = toRefs(props)

const { t } = useI18n()
const { getProject, getInvites, fetchInvites, postProjectInvites } = useProjectStore()
const { loader, error } = storeToRefs(useProjectStore())
const v$ = useVuelidate(null, null, { $scope: 'invite' })

const { project } = getProject({ id: projectSlug.value })
const { invites: allInvites, loader: inviteLoader } = getInvites({ id: project.value?.id, query: { active: true }, dependencies: [project] })

const invites = ref([{ email: null }])

const emails = computed(() => invites.value.filter(invite => invite.email))
const limitOfMembersLeft = computed(() => project.value && allInvites.value ? project.value.members_left - allInvites.value.length - invites.value.length : 0)

const handleClickAddEmail = () => invites.value.push({ email: null })

const handleClickSend = () => {
  v$.value.$touch()

  if (v$.value.$error) return

  postProjectInvites({ id: project.value.id, params: emails.value })
    .then(() => fetchInvites({ id: project.value?.id, query: { active: true } }).then(() => emit('close')))
}
</script>

<style lang="scss" scoped>
.btn {
  &__invite {
    border: 1px dashed rgba($dg, 0.1);
    display: block;
    width: 100%;
    text-align: left;
    padding: 12px;
    font-size: em(14px);
    color: rgba($dg, 0.6);
    transition: all .3s;

    &:hover {
      border: 1px dashed rgba($dg, 0.6);
      color: rgba($dg, 0.8);
    }
  }
}
</style>
