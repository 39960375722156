export default class SportingActivity {
  constructor ({ sporting_activity_id = null, name = null, start_date = new Date(), distance = 0, moving_time = 0, elapsed_time = 0, target_type = null, target_id = null, sporting_athlete_id = null, activity_type = null } = {}) {
    this.sporting_activity_id = sporting_activity_id
    this.name = name
    this.start_date = start_date
    this.distance = distance
    this.moving_time = moving_time
    this.elapsed_time = elapsed_time
    this.target_type = target_type
    this.target_id = target_id
    this.sporting_athlete_id = sporting_athlete_id
    this.activity_type = activity_type
  }
}
