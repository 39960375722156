<template>
  <footer class="footer">
    <div class="container footer__container">
      <div class="footer__col">
        <h3
          class="footer__title bold"
          v-if="translate(column_1.title)"
        >
          {{ translate(column_1.title) }}
        </h3>

        <app-session-button class="session" />

        <nav
          class="nav"
          v-if="column_1.navigation?.length"
        >
          <ul>
            <li
              class="nav__li"
              v-for="({ link }, key) in column_1.navigation"
              :key="`link_${key}`"
            >
              <app-link
                :link="link"
                class="nav__a"
              />
            </li>
          </ul>
        </nav>

        <p
          class="footer__subtitle footer__subtitle--small bold margin__t--30"
          v-if="translate(column_1.subtitle)"
        >
          {{ translate(column_1.subtitle) }}
        </p>

        <nav
          class="nav"
          v-if="column_1.policy_navigation?.length"
        >
          <ul>
            <li
              class="nav__li"
              v-for="({ link }, key) in column_1.policy_navigation"
              :key="`policy_navigation_${key}`"
            >
              <app-link
                :link="link"
                class="nav__a"
              />
            </li>
          </ul>
        </nav>
      </div>

      <div class="footer__col footer__col--newsletter">
        <h3
          class="footer__title bold"
          v-if="translate(column_2.title)"
        >
          {{ translate(column_2.title) }}
        </h3>

        <div
          id="fastenaktion-newsletter-form"
          class="fastenaktion-newsletter-form"
          v-html="translate(column_2.form.element)"
          v-if="translate(column_2.form.url) && translate(column_2.form.element)"
        />

        <component
          :is="'script'"
          src="https://fastenaktionch.gu-marketingsuite.com/f/embed.php?id=32"
          async
          charset="utf-8"
          v-if="translate(column_2.form.url) && translate(column_2.form.element)"
        />
      </div>

      <div class="footer__col footer__col--contact">
        <h3
          class="footer__title bold"
          v-if="translate(column_3.title)"
        >
          {{ translate(column_3.title) }}
        </h3>

        <p
          class="footer__subtitle bold"
          v-if="translate(column_3.subtitle)"
        >
          {{ translate(column_3.subtitle) }}
        </p>

        <address
          v-html="address"
          v-if="address"
        />

        <ul
          class="nav margin__t--18"
          v-if="column_3.contact?.length"
        >
          <li
            v-for="({ type, prefix, text}, key ) in column_3.contact"
            :key="`contact_${key}`"
          >
            <span
              class="nav__prefix"
              v-if="translate(prefix)"
            >
              {{ translate(prefix) }}
            </span>

            <template v-if="type === 'email'">
              <a
                class="nav__a"
                :href="`mailto:${translate(text)}`"
              >
                {{ translate(text) }}
              </a>
            </template>

            <template v-else-if="type === 'phone_number'">
              <a
                class="nav__a"
                :href="`tel:${translate(text).replace(/ /g, '')}`"
              >
                {{ translate(text) }}
              </a>
            </template>

            <template v-else>
              {{ translate(text) }}
            </template>
          </li>
        </ul>

        <ul
          class="networks"
          v-if="socialNetworks.length > 0"
        >
          <li
            class="networks__li"
            v-for="({ url, icon }, key) in socialNetworks"
            :key="'network_' + key"
          >
            <a
              class="networks__a"
              :href="translate(url)"
              target="_blank"
            >
              <fa-icon
                v-if="icon"
                :icon="icon"
                size="lg"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useClientStore } from '@/stores/ClientStore'
import { get as _get } from 'lodash'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
import AppSessionButton from '&/organisms/AppSessionButton'

const props = defineProps({
  section: { type: Object, required: true }
})

const { translate } = useI18n()
const { getClient } = useClientStore()

const { client } = getClient()

const column_1 = computed(() => _get(props, 'section.attributes.column_1', {}))
const column_2 = computed(() => _get(props, 'section.attributes.column_2', {}))
const column_3 = computed(() => _get(props, 'section.attributes.column_3', {}))

const address = computed(() => _get(column_3.value, 'address', []).map(({ line }) => translate(line)).filter(v => v).join('<br />'))

const socialNetworks = computed(() => {
  return _get(client.value, 'platform.social_networks', [])
    .map(link => {
      if (link.icon.includes('linkedin')) link.icon[1] = 'linkedin-in'
      if (link.icon.includes('facebook-square')) link.icon[1] = 'facebook-f'

      return link
    })
    .filter(({ url }) => translate(url))
})
</script>

<style lang="scss" scoped>
$color--primary: #e00234;

.footer {
  background: #989898;
  padding: 56px 0 78px;
  line-height: 1.2;

  @include mq(md) {
    padding: 68px 0 78px;
  }

  &__container {
    display: grid;
    grid-template-columns: minmax(auto, 100%);
    gap: 50px;

    @include mq(md) {
      grid-template-columns: repeat(3, minmax(auto, 1fr));
      gap: 62px;
    }
  }

  &__col {
    &--newsletter {
      @media (max-width: 991px) {
        order: -1;
      }
    }

    &--contact {
      @include mq(md) {
        font-size: rem(22px);
      }
    }
  }

  &__title {
    font-size: rem(22px);
    margin-bottom: 32px;

    @include mq(md) {
      font-size: rem(28px);
    }
  }

  &__subtitle {
    &--small {
      font-size: rem(14px);
    }
  }
}

:deep(.session) {
  margin-bottom: 32px;
  line-height: 1.2;
}

.nav {
  &__li {
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &__prefix {
    margin-right: 3px;
  }

  :deep(.nav__a),
  &__a {
    transition: all .3s ease-in-out;

    &:hover,
    &.is-active {
      color: $color--primary;
    }
  }
}

.networks {
  display: flex;
  gap: 18px;
  margin-top: 16px;

  &__a {
    display: flex;
    width: 42px;
    height: 42px;
    font-size: rem(27px);
    color: #989898;
    background: $black;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    transition: all .3s ease-in-out;

    &:hover {
      background: $color--primary;
    }
  }
}
</style>

<style lang="scss">
#fastenaktion-newsletter-form.fastenaktion-newsletter-form {
  font-family: $font-family--primary!important;

  form {
    margin: 0!important;
    padding: 0!important;
    width: 100%!important;
    font-size: rem(16px)!important;
  }

  label {
    font-weight: normal!important;
    font-size: rem(16px)!important;

    @include mq(md) {
      font-size: rem(18px)!important;
    }
  }

  select {
    height: 38px;
    line-height: 38px;
    padding: 0 10px!important;
  }

  input[type="text"] {
    height: 38px;
    line-height: 38px;
    padding: 0 10px!important;
  }

  button[type="submit"]._submit {
    text-transform: uppercase;
    font-weight: bold;
    font-size: rem(20px);
    line-height: 1;
    border-radius: 5px;
    padding: 1px;
    transition: all .3s ease-in-out;
    padding: 10px 28px!important;

    &:hover {
      transform: matrix(0.996195, -0.0871557, 0.0871557, 0.996195, 0, 0);
    }
  }

  .g-recaptcha {
    transform: scale(.7);
    transform-origin: left top;

    @include mq(sm) {
      transform: scale(1);
    }
  }
}
</style>