<template>
  <transition name="bounce">
    <div
      class="cookiebar"
      v-if="isShow"
    >
      <div class="cookiebar__header">
        <h4>
          {{ t('literal.cookie_preferences') }}
        </h4>
      </div>

      <div class="padding__tb--18">
        <p v-if="isOverview">
          {{ t('literal.cookie_preferences_description') }}
        </p>

        <template v-if="isManage">
          <div>
            <input-checkbox
              name="functional"
              v-model="consent.functional"
              :disabled="true"
            >
              <strong class="bold">{{ t('literal.functional_cookies') }}</strong>
            </input-checkbox>

            <p class="cookiebar__description color--vlg margin__t--6 padding__l--30">
              {{ t('literal.functional_cookies_description') }}
            </p>
          </div>

          <div class="margin__t--18">
            <input-checkbox
              name="analytics"
              v-model="consent.analytics"
            >
              <strong class="bold">{{ t('literal.analytics_cookies') }}</strong>
            </input-checkbox>

            <p class="cookiebar__description margin__t--6 padding__l--30">
              {{ t('literal.analytics_cookies_description') }}
            </p>
          </div>

          <div class="margin__t--18">
            <input-checkbox
              name="marketing"
              v-model="consent.marketing"
            >
              <strong class="bold">{{ t('literal.marketing_cookies') }}</strong>
            </input-checkbox>

            <p class="cookiebar__description margin__t--6 padding__l--30">
              {{ t('literal.marketing_cookies_description') }}
            </p>
          </div>
        </template>

        <router-link
          target="_blank"
          class="cookiebar__link link__color--white-prim link--naked margin__t--18"
          :to="{ name: 'Page', params: { path: 'cookie-policy', locale: $i18n.locale } }"
        >
          {{ t('literal.read_our_full_cookie_policy') }}

          <fa-icon
            class="margin__l--6"
            size="lg"
            :icon="['fal', 'external-link']"
            fixed-width
          />
        </router-link>
      </div>

      <div class="cookiebar__footer padding__t--18">
        <template v-if="isOverview">
          <button
            class="btn btn__size--small btn__color--prim"
            @click="acceptAll"
          >
            {{ t('literal.accept_cookies') }}
          </button>

          <button
            class="link__block link__color--white-prim margin__t--12"
            @click="switchView('manage')"
          >
            {{ t('literal.manage_cookies') }}
          </button>
        </template>

        <template v-if="isManage">
          <button
            class="btn btn__size--small btn__color--prim"
            @click="setConsent"
          >
            {{ t('literal.save_preferences') }}
          </button>

          <button
            class="link__block link__color--white-prim margin__t--12"
            @click="acceptAll"
          >
            {{ t('literal.accept_all_cookies') }}
          </button>
        </template>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { computed, ref, reactive } from 'vue'
import { gtm } from '@/vendors/integrations'
import { $cookies } from '@/utils/utils'
import { useI18n } from '@/vendors/i18n'
import InputCheckbox from '&/atoms/InputCheckbox'

const { t } = useI18n()

const view = ref('overview')
const cookie = computed(() => $cookies.get('koa_gtm_cookie_consent'))
const consent = reactive({
  functional: true,
  analytics: cookie.value ? !!cookie.value.includes('analytics') : false,
  marketing: cookie.value ? !!cookie.value.includes('marketing') : false
})

const isShow = ref(false)
const isOverview = computed(() => view.value === 'overview')
const isManage = computed(() => view.value === 'manage')

const switchView = key => view.value = key
const open = () => isShow.value = true
const close = () => isShow.value = false

const acceptAll = () => {
  consent.analytics = true
  consent.marketing = true

  setConsent()
}

const setConsent = () => {
  $cookies.set({ name: 'koa_gtm_cookie_consent', value: Object.entries(consent).filter(([, value]) => value).map(([type]) => type).join(' ') })

  gtm.consent('consent', 'update', {
    analytics_storage: consent.analytics ? 'granted' : 'denied',
    ad_storage: consent.marketing ? 'granted' : 'denied',
    ad_user_data: consent.marketing ? 'granted' : 'denied'
  })

  setTimeout(() => gtm.track({ event: 'consent_updated' }), 500)

  close()
}

if (!cookie.value) {
  setTimeout(() => open(), 1000)
}

defineExpose({ switchView, open })
</script>

<style lang="scss" scoped>
.cookiebar {
  width: 300px;
  max-height: 90vh;
  overflow-y: auto;
  padding: $padding__base * 1.5;
  margin-left: -150px;
  border-radius: $radius__cards;
  word-break: break-word;
  background: $dg;
  color: $white;
  position: fixed;
  z-index: 999;
  left: 50%;
  bottom: 5vh;
  transform-origin: center;

  -webkit-overflow-scrolling: touch;

  @media screen and ( min-height: 520px ) {
    bottom: $margin__base * 2;
  }

  @include mq(sm) {
    left: 5vh;
    margin: 0;
  }

  &.bounce-enter-active {
    animation-name: bounce-in;
    animation-duration: 300ms;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }

  &.bounce-leave-active {
    opacity: 0;
    transition: opacity .2s;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: em(20px);
  }

  &__footer {
    border-top: 1px solid rgba(#ffffff, 0.2);
    text-align: center;
  }

  &__description {
    font-size: 14px;
  }

  &__link {
    display: flex;
    align-items: center;
  }

  &:deep() .checkbox {
    &__label {
      color: $white;

      &.checkbox--disabled {
        color: $vlg;
      }
    }

    &__element {
      + .checkbox__icon {
        color: $color--primary;
      }

      &:focus + .checkbox__icon {
        color: $color--primary;
      }

      &:checked + .checkbox__icon {
        color: $color--success;
      }
    }
  }
}

.link {
  &__block {
    display: block;
    width: 100%;
  }
}
</style>
