import { slugify, guid } from '@/utils/utils'

export default class extends File {
  constructor (file, fileName, options) {
    super([file], slugify(encodeURI(fileName || file.name).split('.').slice(0, -1).join('.').replace('_', '-')).slice(0, 140) + guid(1) + '.' + (fileName || file.name).split('.').pop(), options || file)

    this.filename = fileName || file.name
  }

  get filename () {
    return this._filename
  }

  set filename (name) {
    this._filename = name.split('.').slice(0, -1).join('.')
  }

  get extension () {
    return this.name.split('.').pop()
  }

  get filesize () {
    return this.size / 1024 / 1024
  }

  get is_svg () {
    return this.extension === 'svg' || this.type === 'image/svg+xml'
  }

  toArrayBuffer (callback = () => ({})) {
    const reader = new FileReader()

    reader.onload = () => callback(reader.result)
    reader.readAsArrayBuffer(this)
  }

  toDataURL (callback = () => ({})) {
    const reader = new FileReader()

    reader.onload = () => callback(reader.result)
    reader.readAsDataURL(this)
  }

  toBlob () {
    return new Blob([this], { type: this.type })
  }

  toBlobURL () {
    return URL.createObjectURL(this, { type: this.type })
  }
}