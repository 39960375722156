<template>
  <div v-if="client">
    <template v-if="!client.is_maintenance">
      <account-verification-notification-bar />

      <template v-if="header">
        <app-component-loader
          v-show="showHeader"
          :objects="{ section: header }"
          :component-path="header.path"
        />
      </template>

      <router-view :key="route.path" />

      <template v-if="footer">
        <app-component-loader
          v-show="showFooter"
          :objects="{ section: footer }"
          :component-path="footer.path"
        />
      </template>

      <app-footer-signature />
    </template>

    <app-maintenance v-else />

    <app-reload-modal
      v-if="showReloadModal"
      @close="hideReloadModal"
    />

    <app-side-notifications />
    <app-notification-modal />
  </div>
</template>

<script setup>
import { defineAsyncComponent, ref, computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useI18n } from '@/vendors/i18n'
import { useHead } from '@unhead/vue'
import { useClientStore } from '@/stores/ClientStore'
import { useGlobalStore } from '@/stores/GlobalStore'
import { version as appVersion } from '@/version.js'
import { get as _get } from 'lodash'
import cssVars from 'css-vars-ponyfill'
import store from '@/store'

import AppComponentLoader from '&/atoms/AppComponentLoader'
import AccountVerificationNotificationBar from '&/molecules/AccountVerificationNotificationBar'
import AppFooterSignature from '&/organisms/AppFooterSignature'
import AppNotificationModal from '&/organisms/AppNotificationModal'
import AppSideNotifications from '&/organisms/AppSideNotifications'

const AppReloadModal = defineAsyncComponent(() => import('&/organisms/AppReloadModal'))
const AppMaintenance = defineAsyncComponent(() => import('@/components/organisms/AppMaintenance'))

cssVars({ include: '[data-include]' })

const route = useRoute()
const { t } = useI18n()

const { getClient } = useClientStore()
const { client } = getClient()
const { is_router_error: isRouterError } = storeToRefs(useGlobalStore())

const is_new_version = ref(false)
const clientName = computed(() => _get(client.value, 'name', ''))
const requireLayoutFromRoute = computed(() => _get(route, 'meta.layout'))
const layout = computed(() => _get(client.value, 'main_layout'))
const header = computed(() => layout.value?.sections.find(section => section.type === 'header') || null)
const footer = computed(() => layout.value?.sections.find(section => section.type === 'footer') || null)
const showHeader = computed(() => header.value && ['header', 'full'].includes(requireLayoutFromRoute.value))
const showFooter = computed(() => footer.value && ['footer', 'full'].includes(requireLayoutFromRoute.value))

const showReloadModal = computed(() => isRouterError.value && is_new_version.value)

const hideReloadModal = () => store.commit('setRouterError', false)

watch(isRouterError, () => {
  fetch('/json/version.json')
    .then(async response => {
      const { version } = await response.json()
      is_new_version.value = version !== appVersion
    })
})

useHead({ titleTemplate: title => `${title || t('literal.loading')} | ${clientName.value}` })
</script>

<style lang="scss" data-include>
@import 'floating-vue/dist/style.css';
@import '@/scss/style.scss';
</style>
