import { defineStore } from 'pinia'
import Update from '@/classes/updates/Update'
import Updates from '@/classes/updates/Updates'
import { pick as _pick } from 'lodash'

export const useUpdateStore = defineStore('UpdateStore', {
  state: () => ({
    updates: {},
    update: {},
    loader: false,
    error: {}
  }),
  getters: {
    getUpdates: state => ({ query } = {}) => {
      return state.$getItems({
        resource: 'updates',
        query,
        fetch: () => state.fetchUpdates({ query })
      })
    },
    getUpdate: state => ({ id }) => {
      return state.$getItem({
        resource: 'update',
        key: id,
        fetch: () => state.fetchUpdate({ id })
      })
    }
  },
  actions: {
    fetchUpdates ({ query }) {
      return this.$fetchItems({
        endpoint: '/api/v1/updates',
        resource: 'updates',
        model: Updates,
        query
      })
    },
    fetchUpdate ({ id }) {
      return this.$fetchItem({
        endpoint: `/api/v1/updates/${id}`,
        resource: `update.${id}`,
        model: Update
      })
    },
    postUpdate ({ params }) {
      return this.$postItem({
        endpoint: '/api/v1/updates',
        resource: 'update.{id}',
        model: Update,
        params: {
          update: new Update(params)
        }
      })
    },
    patchUpdate ({ params, id }) {
      return this.$patchItem({
        endpoint: `/api/v1/updates/${id}`,
        resource: `update.${id}`,
        model: Update,
        params: {
          update: _pick(new Update(params), Object.keys(params))
        }
      })
    },
    deleteUpdate ({ id }) {
      return this.$deleteItem({
        endpoint: `/api/v1/updates/${id}`,
        resource: id
      })
    }
  }
})