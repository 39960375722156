import Address from '@/classes/addresses/Address'
import Campaign from '@/classes/campaigns/Campaign'
import Benefiting from '@/classes/benefitings/Benefiting'
import Template from '@/classes/templates/Template'
import Procedures from '@/classes/procedures/Procedures'
import Users from '@/classes/users/Users'
import { get as _get, omit as _omit } from 'lodash'

export default class Project {
  constructor ({ id = null, name = null, goal_amount = null, fixed_goal_amount = null, start_date = null, end_date = null, image = null, description = null, status = null, slug = null, address = {}, campaign = {}, procedures, users, settings = {}, tags = [], informations = {}, video_url = null, invite_url = null, statistics = {}, benefiting, amount_collected = 0, contributors_count = 0, template_id = null, template = {}, created_at = null } = {}) {
    this.id = id
    this.name = name
    this.goal_amount = goal_amount
    this.fixed_goal_amount = fixed_goal_amount
    this.start_date = start_date
    this.end_date = end_date
    this.image = image
    this.description = description
    this.status = status
    this.slug = slug
    this.address = new Address(_get(address, 'table', address))
    this.campaign = new Campaign(campaign)
    this.procedures = new Procedures(procedures)
    this.users = new Users(users)
    this.settings = settings
    this.tags = tags
    this.informations = informations
    this.video_url = video_url
    this.invite_url = invite_url
    this.statistics = statistics
    this.benefiting = typeof benefiting === 'object' ? new Benefiting(benefiting) : benefiting
    this.amount_collected = amount_collected
    this.contributors_count = contributors_count
    this.template_id = template_id
    this.template = new Template(template)
    this.created_at = created_at
  }

  get has_benefiting () {
    return typeof this.benefiting === 'string' && this.benefiting
  }

  get to_database () {
    const ommited_keys = []

    if (!this.has_benefiting) ommited_keys.push('benefiting')

    return _omit({
      address: this.address,
      benefiting: this.benefiting,
      start_date: this.start_date,
      end_date: this.end_date,
      fixed_goal_amount: this.fixed_goal_amount,
      name: this.name
    }, ommited_keys)
  }

  get limit_of_members () {
    return this.campaign.limit_of_members || 0
  }

  get goal_amount_per_member () {
    return this.campaign.goal_amount_per_member || 0
  }

  get users_count () {
    return this.users.length
  }

  get members_count () {
    return this.users_count - 1
  }

  get members_left () {
    return this.limit_of_members - this.members_count
  }

  get is_goal_allowed () {
    return !('goal_amount' in this.campaign.project_display_settings) || !!this.campaign.project_display_settings.goal_amount
  }

  get is_members_invite_allowed () {
    return !!this.campaign.project_display_settings.invite_project_members
  }

  get is_approved () {
    return this.status !== 'need_approval'
  }

  isAuthorized (authUser) {
    return authUser && (this.users.some(user => user.id === authUser.id) || authUser.is_koalect || authUser.is_super_admin)
  }

  isAdmin (authUser) {
    return this.isAuthorized(authUser) && ['admin', 'koalect'].includes(authUser.role)
  }
}
