<template>
  <footer
    class="footer"
    v-if="section"
  >
    <div class="footer__wrapper">
      <section
        v-if="column1.links.length"
        class="footer__column"
      >
        <ul class="footer__ul">
          <li
            class="footer__li"
            v-for="({ link }, index) in column1.links"
            :key="'footer_column1_item_' + index"
          >
            <a
              class="footer__link"
              v-if="link && link.show"
              @click.prevent="setRouteNavigation(link)"
            >
              {{ $i18n.translate(link.text) }}
            </a>
          </li>
        </ul>
      </section>

      <section
        v-if="column2.links.length"
        class="footer__column"
      >
        <ul class="footer__ul">
          <li
            class="footer__li"
            v-for="({ link }, index) in column2.links"
            :key="'footer_column1_item_' + index"
          >
            <a
              class="footer__link"
              v-if="link && link.show"
              @click.prevent="setRouteNavigation(link)"
            >
              {{ $i18n.translate(link.text) }}
            </a>
          </li>
        </ul>
      </section>

      <section
        v-if="column3.links.length"
        class="footer__column"
      >
        <ul class="footer__ul">
          <li
            class="footer__li"
            v-for="({ link }, index) in column3.links"
            :key="'footer_column1_item_' + index"
          >
            <a
              class="footer__link"
              v-if="link && link.show"
              @click.prevent="setRouteNavigation(link)"
            >
              {{ $i18n.translate(link.text) }}
            </a>
          </li>
        </ul>
      </section>

      <section
        v-if="column4.links.length"
        class="footer__column"
      >
        <ul class="footer__ul">
          <li
            class="footer__li"
            v-for="({ link }, index) in column4.links"
            :key="'footer_column1_item_' + index"
          >
            <a
              class="footer__link"
              v-if="link && link.show"
              @click.prevent="setRouteNavigation(link)"
            >
              {{ $i18n.translate(link.text) }}
            </a>
          </li>
        </ul>
      </section>

      <section
        v-if="$i18n.translate(logo.image)"
        class="footer__column"
      >
        <img
          class="footer__logo"
          :src="$i18n.translate(logo.image)"
          alt="Logo Kajaktrail"
        >
      </section>
    </div>
  </footer>
</template>

<script>
import { defineComponent } from 'vue'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'

export default defineComponent({
  name: 'CustomFooterKajaktrail',
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    column1 () {
      return _get(this.section, 'attributes.column_1', {})
    },
    column2 () {
      return _get(this.section, 'attributes.column_2', {})
    },
    column3 () {
      return _get(this.section, 'attributes.column_3', {})
    },
    column4 () {
      return _get(this.section, 'attributes.column_4', {})
    },
    logo () {
      return _get(this.section, 'attributes.logo', {})
    }
  }
})
</script>

<style lang="scss" scoped>
.footer {
  background-color: $color--secondary;
  padding: 30px;

  @include mq(md) {
    padding: 40px 48px 40px 48px;
  }

  &__wrapper {
    text-align: center;

    @include mq(md) {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      text-align: left;
    }
  }

  &__column {
    margin-bottom: 13px;

    @include mq(md) {
      margin-right: 100px;
      margin-bottom: 0;
    }

    &:last-child {
      margin: 30px 0 0 auto;

      @include mq(md) {
        margin: 0 0 0 auto;
      }
    }
  }

  &__ul {
    line-height: 20px;
    font-size: 17px;
  }

  &__li {
    margin-bottom: 13px;
  }

  &__link {
    color: $white;

    &:hover {
      color: shade($white, 10%, 50%);
    }
  }

  &__logo {
    max-width: 41px;
    display: block;
    margin: auto;
  }
}
</style>
