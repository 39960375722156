<template>
  <main class="main">
    <section class="section green-bckg hero">
      <div class="wrapper">
        <figure class="hero__picture">
          <img
            v-if="$i18n.translate(banner.image)"
            :src="$i18n.translate(banner.image)"
          >
        </figure>

        <div class="hero__content">
          <h1
            class="title"
            v-if="$i18n.translate(banner.title)"
          >
            {{ $i18n.translate(banner.title) }}
          </h1>

          <div
            v-if="$i18n.translate(banner.text)"
            v-html="$i18n.translate(banner.text)"
          />

          <a
            class="cta cta--big margin__t--18"
            v-if="$i18n.translate(banner.button.text)"
            :href="banner.button.target_url"
          >
            {{ $i18n.translate(banner.button.text) }}
          </a>
        </div>
      </div>
    </section>

    <section
      class="section section--little shorts"
      v-if="metrics.length > 0"
    >
      <ul class="wrapper shorts__list">
        <li
          class="shorts__el"
          v-for="metric in metrics"
          :key="metric.index"
        >
          <img
            v-if="$i18n.translate(metric.icon)"
            class="shorts__el--icon"
            :src="$i18n.translate(metric.icon)"
          >

          <p
            class="shorts__el--number"
            v-if="$i18n.translate(metric.number)"
          >
            {{ $i18n.translate(metric.number) }}
          </p>

          <p
            class="shorts__el--text"
            v-if="$i18n.translate(metric.text)"
          >
            {{ $i18n.translate(metric.text) }}
          </p>
        </li>
      </ul>
    </section>

    <section
      class="section section--little green-bckg"
      v-if="project_items.length"
    >
      <div class="wrapper">
        <header class="infoText">
          <h2
            v-if="$i18n.translate(projects.title)"
            class="title"
          >
            {{ $i18n.translate(projects.title) }}
          </h2>

          <div
            class="infoText__content"
            v-if="$i18n.translate(projects.text)"
            v-html="$i18n.translate(projects.text)"
          />
        </header>

        <div class="row">
          <template
            v-for="project in project_items"
            :key="`project_${project.id}`"
          >
            <div class="col-12 col-sm-6 col-md-3">
              <project-card
                class="project-card"
                :project="project"
              />
            </div>
          </template>
        </div>

        <footer class="infoText margin__t--18">
          <a
            class="cta cta--big"
            v-if="projects.button"
            :href="projects.button.target_url"
          >
            {{ $i18n.translate(projects.button.text) }}
          </a>
        </footer>
      </div>
    </section>

    <section class="section green-bckg">
      <div class="wrapper twoCol">
        <div class="twoCol__contentText">
          <h2
            v-if="$i18n.translate(goal.title)"
            class="title"
          >
            {{ $i18n.translate(goal.title) }}
          </h2>

          <div
            class="catch"
            v-if="$i18n.translate(goal.subtitle)"
            v-html="$i18n.translate(goal.subtitle)"
          />

          <div
            v-if="$i18n.translate(goal.text)"
            v-html="$i18n.translate(goal.text)"
          />

          <ul class="groupOfCta margin__t--18">
            <li v-if="goal.button_1">
              <a
                class="cta"
                :href="goal.button_1.target_url"
              >
                {{ $i18n.translate(goal.button_1.text) }}
              </a>
            </li>

            <li v-if="goal.button_2">
              <a
                class="cta cta--second"
                :href="goal.button_2.target_url"
              >
                {{ $i18n.translate(goal.button_2.text) }}
              </a>
            </li>
          </ul>
        </div>

        <img
          v-if="$i18n.translate(goal.image)"
          :src="$i18n.translate(goal.image)"
        >
      </div>
    </section>

    <section
      class="section section--little red-bckg shorts"
      v-if="infos.length > 0"
    >
      <ul
        class="wrapper shorts__list"
      >
        <li
          class="shorts__el"
          v-for="info in infos"
          :key="info.index"
        >
          {{ $i18n.translate(info.item) }}
        </li>
      </ul>
    </section>

    <section class="section green-bckg">
      <div class="wrapper twoCol">
        <img
          v-if="$i18n.translate(description.image)"
          :src="$i18n.translate(description.image)"
        >

        <div class="twoCol__contentText twoCol__contentText--reverse">
          <h2
            v-if="$i18n.translate(description.title)"
            class="title"
          >
            {{ $i18n.translate(description.title) }}
          </h2>

          <div
            v-if="$i18n.translate(description.text)"
            v-html="$i18n.translate(description.text)"
          />

          <ul class="groupOfCta margin__t--18">
            <li v-if="description.button_1">
              <a
                class="cta"
                :href="description.button_1.target_url"
              >
                {{ $i18n.translate(description.button_1.text) }}
              </a>
            </li>

            <li v-if="description.button_2">
              <a
                class="cta cta--second"
                :href="description.button_2.target_url"
              >
                {{ $i18n.translate(description.button_2.text) }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section
      class="section"
      v-if="quotes.item.length > 0 || $i18n.translate(quotes.button.text)"
    >
      <div class="wrapper wrapper--centered">
        <div class="carousel">
          <div
            class="carousel__viewport"
            @mouseenter="isPaused = true"
            @mouseleave="isPaused = false"
          >
            <figure
              v-for="(quote, index) in quotes.item"
              :key="index"
              :style="{ transform: `translateX(-${index}00%)` }"
              :class="['carousel__slide', {'carousel__slide--active': index === showSlide}]"
            >
              <blockquote>{{ $i18n.translate(quote.text) }}</blockquote>

              <figcaption v-if="$i18n.translate(quote.author)">
                {{ $i18n.translate(quote.author) }}
              </figcaption>
            </figure>
          </div>

          <button
            class="carousel__btn"
            @click="prev()"
          >
            <fa-icon :icon="['fas', 'chevron-left']" />
          </button>

          <button
            class="carousel__btn carousel__btn--next"
            @click="next()"
          >
            <fa-icon :icon="['fas', 'chevron-right']" />
          </button>
        </div>

        <a
          class="cta cta--second cta--big"
          v-if="$i18n.translate(quotes.button.text)"
          :href="quotes.button.target_url"
        >
          {{ $i18n.translate(quotes.button.text) }}
        </a>
      </div>
    </section>
  </main>
</template>

<script>
import { defineComponent } from 'vue'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'
import { mapActions } from 'vuex'
import ProjectCard from '&/organisms/ProjectCard'

export default defineComponent({
  name: 'CustomHomepageBaluchon',
  data () {
    return {
      showSlide: 0,
      isPaused: false,
      sliderInterval: null,
      project_items: []
    }
  },
  mixins: [PageRouteNavigation],
  components: { ProjectCard },
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  mounted () {
    this.delayToChange()

    this.fetchProjects({
      query: _get(this.projects, 'query', {
        'sort_by_field[field]': 'current_amount',
        'sort_by_field[direction]': 'desc',
        'per_page': 4
      }), removeStoredItems: true
    })
      .then(data => this.project_items = Object.values(data.items))
  },
  computed: {
    banner () {
      return _get(this.section, 'attributes.banner', {})
    },
    metrics () {
      return _get(this.section, 'attributes.metrics', {})
    },
    projects () {
      return _get(this.section, 'attributes.projects', {})
    },
    goal () {
      return _get(this.section, 'attributes.goal', {})
    },
    infos () {
      return _get(this.section, 'attributes.infos', [])
    },
    description () {
      return _get(this.section, 'attributes.description', {})
    },
    quotes () {
      return _get(this.section, 'attributes.quotes', {})
    }
  },
  methods: {
    ...mapActions({
      fetchProjects: 'projects/fetchProjects'
    }),
    next (clicked = true) {
      if (clicked) {
        clearInterval(this.sliderInterval)
      }
      this.showSlide >= this.quotes.item.length - 1 ? this.showSlide = 0 : ++this.showSlide
    },
    prev () {
      clearInterval(this.sliderInterval)
      this.showSlide <= 0 ? this.showSlide = this.quotes.item.length - 1 : --this.showSlide
    },
    delayToChange () {
      this.sliderInterval = setInterval(() => {
        if (!this.isPaused) {
          this.next(false)
        }
      }, 6000)
    }
  }
})
</script>

<style lang="scss" scoped>
  $color-principal-dark: #750727;
  $color-principal-neutral: #d50d47;
  $color-secondary-dark: #719419;
  $color-secondary-neutral: #92c020;
  $color-secondary-light: #e7efd3;

  main{
    font-size: 16px;

    @include mq(md){
      font-size: 20px;
    }
  }

  .section {
    padding: 100px 0;

    @include mq(sm){
      padding: 120px 0;
    }

    &--little {
      padding: 60px 0;
    }
  }

  .green-bckg {
    background-color: $color-secondary-light;
  }

  .red-bckg {
    color: #ffffff;
    background-color: $color-principal-neutral;
  }

  .cta {
    padding: 12px 24px;
    font-size: .75rem;
    color: $white;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: $radius__buttons;
    background-color: $color-secondary-neutral;
    display: inline-block;
    transition: background-color $transition__duration;

    &:hover {
      background-color: $color-secondary-dark;
    }

    &--second {
      background-color: $color-principal-neutral;

      &:hover {
        background-color: $color-principal-dark;
      }
    }

    &--big {
      font-size: 1rem;
    }
  }

  .title {
    font-size: 1.9rem;
    font-family: "Helvetica Neue LT Std Medium",Arial,sans-serif;
    font-weight: 700;
  }

  .wrapper {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;

    @include mq(md){
      padding: 0 50px;
    }

    &--centered {
      display: flex;
      flex-direction: column;

      & .cta {
        margin: auto;
      }
    }
  }

  p {
    margin-bottom: .5rem;
  }

  .hero {
    position: relative;

    &.section {
      padding: 0 0 100px 0;
    }

    & .wrapper {
      padding: 0;

      @include mq(sm){
        display: flex;
        padding: 0 15px;
      }

      @include mq(md){
        padding: 0 50px;
      }
    }

    &__picture {
      flex: 1 1 50%;

      & img {
        width: 100%;

        @include mq(md){
          width: calc(100% + 50px);
        }
      }
    }

    &__content {
      position: absolute;
      bottom: -40px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      background-color: $white;
      padding: 50px;
      font-weight: 700;
      z-index: 1;
      width: 90%;

      @include mq(sm){
        position: relative;
        width: 100%;
        bottom: initial;
        left: initial;
        transform: initial;
        text-align: initial;
        flex: 1 1 50%;

        max-width: 600px;
        font-weight: 700;
        height: 100%;
        margin-left: -50px;
        z-index: 1;
        align-self: center;
      }

      & .title {
        color: $color-principal-neutral;

        @include mq(md){
          font-size: 2.75rem;
        }
      }
    }
  }

  .shorts {
    text-align: center;

    &__list {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include mq(sm){
        flex-direction: initial;
        align-items: initial;
        justify-content: space-around;
      }
    }

    &__el {
      width: 100%;
      max-width: 300px;

      &:not(:last-child){
        margin: 0 0 30px 0;

        @include mq(sm){
          margin: 0 25px 0 0;
        }
      }

      &--number {
        color: $color-principal-neutral;
        font-size: 2.8125rem;
        font-weight: 700;
        display: block;
        text-align: center;
        line-height: 2.8125rem;
        margin-bottom: calc($margin__base / 2);
      }

      &--icon {
        max-height: 40px;
        width: auto;
      }
    }
  }

  .infoText {
    text-align: center;

    &__content {
      margin: $margin__base 0 $margin__base * 2;
    }
  }

  .twoCol {
    display: grid;
    grid-template-rows: repeat(2, auto);

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 100px;
      align-items: center;
      justify-content: center;
    }

    & img {
      width: 100%;
    }

    &__contentText {
      padding: 25px;

      @include mq(sm){
        max-width: 427px;
        margin: 0 0 0 auto;
        padding: initial;
      }

      &--reverse {
        grid-row-start: 1;

        @include mq(sm){
        grid-row-start: initial;
          margin: 0 auto 0 0;
        }
      }
    }

    & .catch {
      margin: $margin__base 0;
      font-size: rem(22px);
      display: block;
      font-weight: 700;
      line-height: 1.6;
    }

    & .groupOfCta {
      display: grid;
        grid-template-rows: repeat(2, auto);
        row-gap: 20px;
        font-size: rem(14px);

      @include mq(sm) {
        grid-template-rows: initial;
      grid-template-columns: repeat(2, 1fr);
        column-gap: initial;
        width: 100%;
      column-gap: 8px;
      }

      @include mq(lg){
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: initial;
        row-gap: initial;
        column-gap: 8px;
      }

      & .cta {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    & .title {
      margin-bottom: $margin__base;
      font-size: rem(38px);
    }
  }

  .carousel {
    position: relative;
    margin-bottom: 50px;

    &__viewport {
      max-width: 900px;
      margin: auto;
      width: calc(100% - 80px);
      display: flex;
      overflow: hidden;
      align-items: center;

      @include mq(sm){
        width: calc(100% - 100px);
      }
    }

    &__slide {
      font-size: 1.1rem;
      text-align: center;
      min-width: 100%;
      opacity: 0;
      position: relative;
      transition: opacity $transition__duration;
      pointer-events: none;

      &--active {
        opacity: 1;
        z-index: 1;
      }

      @include mq(sm){
        font-size: 1.625rem;
      }

      & figure {
        float: left;
      }

      & blockquote {
        font-weight: 900;
        line-height: 1.4;
      }

      & figcaption::before {
        content: '— ';
      }
    }

    &__btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      font-size: 30px;
      color: $color-principal-neutral;

      &--next{
        left: inherit;
        right: 0;
      }
    }
  }
</style>
