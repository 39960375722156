<template>
  <div class="homepage bg__color--white">
    <section
      class="banner bg__color--sec color--white"
      :style="{ backgroundImage: translate(banner.image) ? `url(${translate(banner.image)})` : 'none' }"
      v-if="isVisible('banner')"
    >
      <div class="container banner__container">
        <div class="banner__wrapper">
          <h1
            class="title banner__title"
            v-if="translate(banner.title)"
          >
            {{ translate(banner.title) }}
          </h1>

          <div
            class="editor editor__content banner__content margin__t--12"
            v-if="translate(banner.content)"
            v-html="translate(banner.content)"
          />

          <app-link
            class="btn btn__color--prim btn__size--classic cta margin__t--24 d-inline-block"
            :link="banner.cta"
          />
        </div>
      </div>
    </section>

    <section
      class="campaigns"
      v-if="isVisible('campaigns')"
    >
      <div class="container">
        <div
          class="hgroup margin__b--24"
          v-if="translate(campaigns.title) || translate(campaigns.subtitle)"
        >
          <h2
            class="bold color--prim hgroup__title"
            v-if="translate(campaigns.title)"
          >
            {{ translate(campaigns.title) }}
          </h2>

          <h3
            class="title hgroup__subtitle"
            v-if="translate(campaigns.subtitle)"
          >
            {{ translate(campaigns.subtitle) }}
          </h3>
        </div>

        <div class="campaigns__wrapper">
          <div class="padding__t--24">
            <div
              class="editor editor__content campaigns__content margin__t--12"
              v-if="translate(campaigns.content)"
              v-html="translate(campaigns.content)"
            />

            <app-link
              class="btn btn__color--sec btn__size--classic cta margin__t--24 d-inline-block"
              :link="campaigns.cta"
            />
          </div>

          <div class="campaigns__cover">
            <figure
              class="campaigns__image"
              :style="{ backgroundImage: translate(campaigns.image) ? `url(${translate(campaigns.image)})` : 'none' }"
            >
              <fa-icon
                class="no-thumb"
                :icon="['far', 'image']"
                v-if="!translate(campaigns.image)"
              />
            </figure>
          </div>
        </div>
      </div>
    </section>

    <section
      class="how_it_works align--center"
      v-if="isVisible('how_it_works') && howItWorks.steps.length"
    >
      <div class="container">
        <h2
          class="title title__h2 color--prim how_it_works__title margin__b--54"
          v-if="translate(howItWorks.title)"
        >
          {{ translate(howItWorks.title) }}
        </h2>

        <ol class="how_it_works__steps">
          <li
            class="step color--white"
            v-for="({ icon, title, text }, index) in howItWorks.steps"
            :key="`step_${index}`"
          >
            <figure
              class="step__icon margin__b--24"
              v-if="translate(icon)"
            >
              <img
                :src="translate(icon)"
                aria-hidden="true"
              >
            </figure>

            <span class="step__number title bold">
              {{ (index + 1) < 10 ? `0${index + 1}.` : `${index + 1}.` }}
            </span>

            <h3
              class="title bold step__title margin__t--12"
              v-if="translate(title)"
            >
              {{ translate(title) }}
            </h3>

            <div
              class="editor editor__content step__text margin__t--12"
              v-if="translate(text)"
              v-html="translate(text)"
            />
          </li>
        </ol>

        <app-link
          class="btn btn__color--prim btn__size--prim cta d-inline-block margin__t--48"
          :link="howItWorks.cta"
        />
      </div>
    </section>

    <section
      class="actions"
      v-if="isVisible('actions') && projects.length"
    >
      <div class="container align--center">
        <h2
          class="title title__h2 color--ter margin__b--48"
          v-if="translate(actions.title)"
        >
          {{ translate(actions.title) }}
        </h2>

        <div class="row align--left">
          <template
            v-for="project in projects"
            :key="project.id"
          >
            <section class="col-12 col-sm-6 col-md-3">
              <project-card
                class="project-card"
                :project="project"
              />
            </section>
          </template>
        </div>

        <app-link
          class="btn btn__color--sec btn__size--classic cta d-inline-block margin__t--48"
          :link="actions.cta"
        />
      </div>
    </section>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useProjectStore } from '@/stores/ProjectStore'
import { useI18n } from '@/vendors/i18n'
import { get as _get, omit as _omit } from 'lodash'
import useSection from '@/composables/modules/pages/useSection'
import { projects_overview_options } from '@/configurations/general/components-default-data'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const ProjectCard = defineAsyncComponent(() => import('&/organisms/ProjectCard'))

const props = defineProps({
  page: { type: Object, required: true },
  sections: { type: Object, required: true }
})

const { translate } = useI18n()
const { getSectionAttributes, isVisible } = useSection(props.page)
const { getProjects } = useProjectStore()

const banner = computed(() => getSectionAttributes('banner'))
const campaigns = computed(() => getSectionAttributes('campaigns'))
const howItWorks = computed(() => getSectionAttributes('how_it_works'))
const actions = computed(() => getSectionAttributes('actions'))

const query = computed(() => {
  const query = _get(actions.value, 'query', {})
  const sortByField = _get(projects_overview_options.find(sort => sort.value === query.sort), 'query', { 'sort_by_field[field]': 'created_at','sort_by_field[direction]': 'desc' })

  return _omit({ ...query, ...sortByField }, ['sort', 'collapse'])
})

const { projects } = getProjects({ query })

</script>

<style lang="scss" scoped>
$color--primary: #ED3B85;
$color--secondary: #4061CC;
$color--tertiary: #191045;

:deep(.cta) {
  padding: 18px 40px;
  border-radius: 27px;
  font-size: rem(16px);
  line-height: 1.16;
  letter-spacing: -0.16px;

  @include mq(md) {
    font-size: rem(18px);
  }
}

.title {
  &__h2 {
    font-size: rem(28px);
    line-height: 1.04;

    @include mq(sm) {
      font-size: rem(48px);
    }
  }
}

.no-thumb {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: $white;
  font-size: rem(56px);
  opacity: 0.7;

  @include mq(sm) {
    font-size: rem(72px);
  }
}

.hgroup {
  border-left: 3px solid $color--primary;
  padding-left: 24px;

  @include mq(sm) {
    padding-left: 48px;
  }

  &__title {
    font-size: rem(18px);
    line-height: 1.4;
    letter-spacing: -0.22px;

    @include mq(sm) {
       font-size: rem(24px);
    }
  }

  &__subtitle {
    font-size: rem(20px);
    line-height: 1.04;
    margin-top: 12px;
    color: $color--tertiary;

    @include mq(sm) {
      font-size: rem(36px);
      margin-top: 24px;
    }

    @include mq(md) {
      font-size: rem(48px);
      margin-top: 36px;
    }
  }
}

.editor {
  letter-spacing: -0.22px;
}

.homepage {
  position: relative;
  overflow: hidden;
  margin-top: -108px;

  @include mq(sm) {
    margin-top: -127px;
  }

  @include mq(md) {
    margin-top: -172px;
  }

  @include mq(lg) {
    margin-top: -174px;
  }
}

.banner {
  padding: 180px 0 72px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0 0 32px 32px;
  position: relative;
  overflow: hidden;
  border-radius: 32px;

  @include mq(sm) {
    padding: 244px 0 120px;
  }

  @include mq(md) {
    padding: 324px 0 216px;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: shade($color--secondary, 50%);
    opacity: 0.7;
  }

  &__container {
    position: relative;
  }

  &__wrapper {
    max-width: 840px;
    box-sizing: content-box;

    @include mq(md) {
      padding-left: 24px;
    }

    @include mq(lg) {
      padding-left: 84px;
    }
  }

  &__title {
    font-size: rem(36px);
    line-height: 1.15;
    max-width: 878px;

    @include mq(sm) {
      font-size: rem(52px);
    }

    @include mq(md) {
      font-size: rem(84px);
    }
  }

  &__content {
    font-size: rem(18px);
    line-height: 1.4;

    @include mq(sm) {
      font-size: rem(20px);
    }

    @include mq(md) {
      font-size: rem(24px);
    }
  }
}

.campaigns {
  padding: 48px 0;

  @include mq(sm) {
    padding: 80px 0;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 36px;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
    }

    @include mq(md) {
      grid-template-columns: 5fr 6fr;
      gap: 36px;
    }

    @include mq(lg) {
      gap: 132px;
    }
  }

  &__content {
    font-size: rem(18px);
    line-height: 1.5;
    color: #737682;

    @include mq(md) {
      font-size: rem(24px);
    }
  }

  &__cover {
    position: relative;
    min-width: 0;
  }

  &__image {
    background-color: $color--tertiary;
    overflow: hidden;
    position: relative;
    border-radius: 32px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @include aspect-ratio(708, 567);

    @include mq(sm) {
      width: 50vw;
      max-width: 708px;
      border-radius: 0 0 0 80px;
    }
  }
}

.how_it_works {
  padding: 48px 0;
  background: #ECEBF0;

  @include mq(sm) {
    padding: 96px 0;
  }

  &__steps {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;

    @include mq(sm) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include mq(lg) {
      grid-template-columns: repeat(6, 1fr);
    }

    .step {
      background: $color--secondary;
      border-radius: 32px;
      padding: 24px;

      @include mq(sm) {
        border-radius: 48px;

        grid-column: span 2;

        &:last-child:nth-child(2n+1) {
          grid-column-end: -2;
        }
      }

      @include mq(md) {
        padding: 24px 48px;
      }

      @include mq(lg) {
        &:last-child:nth-child(2n+1) {
          grid-column-end: inherit;
        }

        &:last-child:nth-child(3n+1) {
          grid-column-end: -3;
        }

        &:nth-last-child(2):nth-child(3n+1) {
          grid-column-end: -4;
        }
      }

      &__icon {
        width: 76px;
        height: 76px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: $white;
        border-radius: 50%;

        img {
          width: auto;
          max-height: 46px;
        }
      }

      &__number {
        font-size: rem(24px);
        color: #FFD2D2;
        display: block;
      }

      &__title {
        font-size: rem(20px);
        line-height: 1.2;

        @include mq(sm) {
          font-size: rem(22px);
        }
      }

      &__text {
        font-size: rem(16px);
        line-height: 1.5;
         letter-spacing: 0;

        @include mq(sm) {
          font-size: rem(18px);
        }
      }
    }
  }
}

.actions {
  padding: 48px 0;

  @include mq(sm) {
    padding: 96px 0 76px;
  }
}
</style>