import store from '@/store'
import WebFontLoader from 'webfontloader'
import { get as _get, uniq as _uniq } from 'lodash'

class Branding {
  constructor () {
    const client = _get(store, 'getters.client/client')

    this.style = document.querySelector('html').style

    this.colors = _get(client, 'style.colors', [])
    this.typefaces = _get(client, 'style.typefaces', [])

    this.setColors().setFonts().loadFonts()
  }

  setColors () {
    this.colors.forEach(color => {
      this.style.setProperty(`--${color.name}`, `hsl(${color.hue}, ${color.saturation}%, ${color.lightness}%)`)
      this.style.setProperty(`--${color.name}-h`, color.hue)
      this.style.setProperty(`--${color.name}-s`, color.saturation)
      this.style.setProperty(`--${color.name}-l`, color.lightness)
      this.style.setProperty(`--${color.name}-r`, color.red)
      this.style.setProperty(`--${color.name}-g`, color.blue)
      this.style.setProperty(`--${color.name}-b`, color.green)
    })

    return this
  }

  setFonts () {
    this.typefaces.forEach(typo => {
      if (typo.name === 'primary') this.style.setProperty('--font-size-base', typo.size || '16px')

      this.style.setProperty(`--font-family-${typo.name}`, `"${typo.family}"`)
    })

    return this
  }

  loadFonts () {
    WebFontLoader.load({
      google: {
        families: _uniq(this.typefaces.map(typeface => typeface.file))
      }
    })

    return this
  }
}

export const createBranding = {
  install: () => new Branding()
}
