import File from '@/classes/files/File'
import Image from '@/classes/files/Image'

export default class Files extends Array {
  constructor (files) {
    super(...Array.from(files).map(file => file.type.includes('image/') ? new Image(file) : new File(file)))
  }

  get images () {
    return this.filter(file => file.type.match(/image/g))
  }
}