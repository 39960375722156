<template>
  <main class="home">
    <section class="banner">
      <figure
        class="banner__slider"
        :style="{ backgroundImage: `url(${ $i18n.translate(banner.slider[index].image)})` }"
      />

      <div class="banner__content">
        <h1
          class="banner__title"
          v-if="$i18n.translate(banner.title)"
        >
          {{ $i18n.translate(banner.title) }}
        </h1>

        <p v-if="$i18n.translate(banner.text)">
          {{ $i18n.translate(banner.text) }}
        </p>

        <a
          class="banner__button btn btn__size--classic"
          v-if="$i18n.translate(banner.button.text)"
          @click.prevent="setRouteNavigation(banner.button)"
        >
          {{ $i18n.translate(banner.button.text) }}
        </a>

        <ul
          v-if="banner.slider.length > 0"
          class="banner__bullets"
        >
          <li
            v-for="(slide, i) in banner.slider"
            :key="`slide_${i}`"
            class="banner__bullets-item"
            @click="goToItem(i)"
            :ref="`bullet`"
          />
        </ul>
      </div>
    </section>

    <ul
      class="stats"
      v-if="metrics.length > 0 && statistics"
    >
      <li
        class="stats__item"
        v-for="(metric, i) in metrics"
        :key="`metric_item_${i}`"
      >
        <p>
          <strong class="stats__number">
            <app-date
              v-if="metric.type === 'date' && metric.date"
              :timestamp="metric.date"
              filter="differenceInCalendarDays"
            />

            <app-money
              v-else-if="metric.type === 'amount_collected'"
              :amount="statistics[metric.type]"
            />

            <template v-else-if="metric.type === 'sporting_statistics'">
              {{ getSportingStatistic(metric.sporting_statistics) }}
            </template>

            <template v-else>
              {{ statistics[metric.type] }}
            </template>
          </strong>

          <span
            v-if="$i18n.translate(metric.text)"
            class="d-block"
          >
            {{ $i18n.translate(metric.text) }}
          </span>
        </p>
      </li>
    </ul>

    <section class="steps wrapper">
      <h2
        v-if="$i18n.translate(steps.title)"
        class="steps__title separator"
      >
        {{ $i18n.translate(steps.title) }}
      </h2>

      <div
        class="steps__text"
        v-if="$i18n.translate(steps.text)"
        v-html="$i18n.translate(steps.text)"
      />

      <article
        v-if="steps.items.length > 0"
        class="steps__step"
      >
        <div
          class="step"
          v-for="(step, i) in steps.items"
          :key="`step_${i}`"
        >
          <img
            class="step__img"
            :src="$i18n.translate(step.img)"
            v-if="step.img"
          >

          <h3
            v-if="$i18n.translate(step.title)"
            class="step__title bold"
          >
            {{ $i18n.translate(step.title) }}
          </h3>

          <div
            v-if="$i18n.translate(step.text)"
            class="step__text editor__content"
            v-html="$i18n.translate(step.text)"
          />
        </div>
      </article>

      <a
        class="steps__button bold btn btn__size--classic"
        v-if="steps.button && steps.button.show"
        @click.prevent="setRouteNavigation(steps.button)"
      >
        {{ $i18n.translate(steps.button.text) }}

        <fa-icon
          class="button-icon margin__l--6"
          :icon="['fas', 'long-arrow-alt-right']"
        />
      </a>
    </section>

    <section class="projects">
      <div class="projects__content wrapper">
        <h2
          class="separator projects__title"
          v-if="$i18n.translate(projects.title)"
        >
          {{ $i18n.translate(projects.title) }}
        </h2>

        <a
          class="projects__button"
          v-if="$i18n.translate(projects.button.text) && projects.button.show"
          @click.prevent="setRouteNavigation(projects.button)"
        >
          {{ $i18n.translate(projects.button.text) }}
        </a>

        <ul class="projects__switch">
          <li>
            <button
              class="switch-link switch-link--first btn__size--small"
              v-html="$i18n.t('literal.discover_our_projects')"
              @click="setActive"
              :class="{ 'switch-link--active': toActive}"
            />
          </li>

          <li>
            <button
              class="switch-link switch-link--last btn__size--small"
              v-html="$i18n.t('literal.ended')"
              @click="setActive"
              :class="{ 'switch-link--active': !toActive}"
            />
          </li>
        </ul>
      </div>

      <section
        class="projects--section wrapper"
      >
        <div class="projects__grid">
          <template
            v-for="project in projectItems"
            :key="project.id"
          >
            <section>
              <project-card
                class="project-card"
                :project="project"
              />
            </section>
          </template>
        </div>

        <a
          class="projects__more"
          v-if="projects.query_ended.button && projects.query_ended.button.show && !toActive"
          @click.prevent="setRouteNavigation(projects.query_ended.button)"
        >
          <fa-icon
            class="steps__button-icon margin__r--6"
            :icon="['fas', 'plus']"
          />

          {{ $i18n.translate(projects.query_ended.button.text) }}
        </a>
      </section>
    </section>

    <section
      class="student"
      v-if="$i18n.translate(addProject.text)"
      :style="{ backgroundImage: `url(${ $i18n.translate(addProject.image)})` }"
    >
      <div class="student__block wrapper">
        <div class="student__content">
          <div
            v-if="$i18n.translate(addProject.text)"
            class="student__title editor__content"
            v-html="$i18n.translate(addProject.text)"
          />

          <a
            class="student__button bold btn btn__size--classic"
            v-if="addProject.button && addProject.button.show"
            @click.prevent="setRouteNavigation(addProject.button)"
          >
            {{ $i18n.translate(addProject.button.text) }}

            <fa-icon
              class="button-icon margin__l--6"
              :icon="['fas', 'long-arrow-alt-right']"
            />
          </a>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { defineComponent } from 'vue'
import { get as _get, merge as _merge } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import AppMoney from '&/atoms/AppMoney'
import PageRouteNavigation from '%/PageRouteNavigation'
import ProjectCard from '&/organisms/ProjectCard'

export default defineComponent({
  name: 'CustomHomepageIHECS',
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  data: function () {
    return {
      index: 0,
      toActive: true,
      project_cards_ongoing: [],
      project_cards_ended: []
    };
  },
  mixins: [PageRouteNavigation],
  components: {
    AppMoney,
    ProjectCard
  },
  created () {
    const ongoingQueries = _merge(_get(this.projects, 'query_ongoing'), { duration: 'ongoing' })
    const endedQueries = _merge(_get(this.projects, 'query_ended'), { duration: 'ended' })

    this.fetchClientStatistics()

    this.fetchProjects({
      query: ongoingQueries || {
        'sort_by_field[field]': 'current_amount',
        'sort_by_field[direction]': 'desc',
        'duration': 'ongoing',
        'per_page': 4
      }, removeStoredItems: true
    })
      .then(data => this.project_cards_ongoing = Object.values(data.items))

    this.fetchProjects({
      query: endedQueries || {
        'sort_by_field[field]': 'current_amount',
        'sort_by_field[direction]': 'desc',
        'duration': 'ended',
        'per_page': 4
      }, removeStoredItems: true
    })
      .then(data => this.project_cards_ended = Object.values(data.items))

    this.sliderFunction()
  },
  computed: {
    ...mapGetters({
      statistics: 'client/statistics',
    }),
    banner () {
      return _get(this.section, 'attributes.banner', {})
    },
    metrics () {
      return _get(this.section, 'attributes.metrics', []).slice(0, 3)
    },
    steps () {
      return _get(this.section, 'attributes.steps', {})
    },
    projects () {
      return _get(this.section, 'attributes.projects', {})
    },
    projectItems () {
      return this.toActive ? this.project_cards_ongoing : this.project_cards_ended
    },
    addProject () {
      return _get(this.section, 'attributes.add_project', {})
    }
  },
  methods: {
    ...mapActions({
      fetchClientStatistics: 'client/fetchClientStatistics',
      fetchProjects: 'projects/fetchProjects'
    }),
    resetBullet () {
      for (let x = 0; x < this.banner.slider.length; x++) {
        this.$refs.bullet[x].style.background = '#ffffff'
      }
    },
    resetSlider () {
      clearInterval(this.slider)
      this.sliderFunction()
    },
    sliderFunction () {
      this.slider = setInterval(() => {
        this.resetBullet()
        this.index++

        if (this.index > this.banner.slider.length - 1) {
          this.index = 0
        }
        this.$refs.bullet[this.index].style.background = '#6bc5cf'
      }, 10000);
    },
    goToItem (i) {
      this.resetBullet()
      this.index = i
      this.$refs.bullet[i].style.background = '#6bc5cf'
      this.resetSlider()
    },
    setActive () {
      this.toActive = !this.toActive;
    }
  },
  unmounted () {
    clearInterval(this.slider)
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #f67134;
$color--secondary: #0086b2;
$color--tertiary: #6bc5cf;
$grey: #f7f7f7;
$dark-grey: #555555;
$black: #333333;
$white: #ffffff;

.home {
  position: relative;
  background: $white;
  font-family: arial,sans-serif;
}

.separator {
  border: none;

  &::after {
    display: block;
    content: '';
    background-color: $color--tertiary;
    width: 50px;
    height: 2px;
    margin-top: $margin__base;
    margin-bottom: $margin__base * 3;
  }
}

.banner {
  position: relative;
  z-index: 0;
  color: $white;
  padding: $padding__base * 4 $padding__base $padding__base * 7;
  text-align: center;

  &__content {
    position: relative;
    bottom: 0;
  }

  &__title {
    font-size: rem(36px);
    margin-top: $margin__base * 15;

    @include mq(md) {
      font-size: rem(48px);
    }
  }

  &__button {
    display: inline-block;
    font-size: rem(20px);
    font-weight: 400;
    font-family: $font-family--secondary;
    color: $white;
    background: $color--primary;
    margin-top: $margin__base * 3;
    text-transform: uppercase;
    text-align: center;
    max-width: 300px;
    border-radius: 3px;

    @include mq(sm) {
      white-space: nowrap;
    }
  }

  &__bullets {
    display: flex;
    justify-content: space-around;
    margin: 24px auto;
    width: 60px;

    &-item {
      display: block;
      width: 7px;
      height: 7px;
      background: #fff;
      border-radius: 50%;

      &:nth-child(1) {
        background: $color--tertiary;
      }
    }
  }

  &__slider {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    z-index: -10;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: all .6s;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      background-color: rgba(107,197,207,.6);
      z-index: -5;
      width: 100%;
      height: 100%;
    }
  }
}

.stats {
  position: relative;
  background: $white;
  text-align: center;
  box-shadow: 0 2px 10px 1px #ccc;
  border-radius: 3px;
  margin-top: -50px;
  z-index: 10;

  @include mq(sm) {
    max-width: 70%;
    margin: -50px auto 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  &__item {
    font-weight: bold;
    text-transform: lowercase;
    color: #aaaaaa;
    padding: $padding__base 0;
  }

  &__number {
    font-size: rem(27px);
    font-family: $font-family--secondary;

    @include mq(sm) {
      font-size: rem(36px);
    }
  }
}

.steps {
  background: $white;
  margin-bottom: $margin__base * 3;

  &__title {
    font-size: rem(24px);
    text-transform: uppercase;
    text-align: center;
    margin-top: $margin__base * 3;

    @include mq(sm) {
      font-size: rem(36px);
    }

    &.separator::after {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__text {
    padding: 0 $padding__base $padding__base * 3;
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
  }

  &__step {
    @include mq(sm) {
      display: flex;
    }
  }

  &__button {
    display: block;
    width: fit-content;
    font-size: rem(12px);
    background: $color--primary;
    border-radius: 3px;
    color: $white;
    text-transform: uppercase;
    margin: 0 auto $margin__base;

    &-icon {
      font-weight: bold;
    }
  }
}

.step {
  position: relative;
  margin: $margin__base * 3 $margin__base;
  text-align: center;

  &::after, &:first-child::before {
    content: '';
    display: block;
    opacity: .2;
    width: 50px;
    height: 50px;
    background: url(data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjaGV2cm9uLXJpZ2h0IiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtY2hldnJvbi1yaWdodCBmYS13LTEwIiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDMyMCA1MTIiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTI4NS40NzYgMjcyLjk3MUw5MS4xMzIgNDY3LjMxNGMtOS4zNzMgOS4zNzMtMjQuNTY5IDkuMzczLTMzLjk0MSAwbC0yMi42NjctMjIuNjY3Yy05LjM1Ny05LjM1Ny05LjM3NS0yNC41MjItLjA0LTMzLjkwMUwxODguNTA1IDI1NiAzNC40ODQgMTAxLjI1NWMtOS4zMzUtOS4zNzktOS4zMTctMjQuNTQ0LjA0LTMzLjkwMWwyMi42NjctMjIuNjY3YzkuMzczLTkuMzczIDI0LjU2OS05LjM3MyAzMy45NDEgMEwyODUuNDc1IDIzOS4wM2M5LjM3MyA5LjM3MiA5LjM3MyAyNC41NjguMDAxIDMzLjk0MXoiPjwvcGF0aD48L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
    transform: rotate(90deg);
  }

  &::after {
    @include mq(sm) {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, 50%);
    }

    @include mq(md) {
      transform: translate(15%, 50%);
    }

    @include mq(lg) {
      transform: translate(50%, 50%);
    }
  }


  &:first-child::before {
    @include mq(sm) {
      position: absolute;
      transform: translate(-50%, 50%);
    }

    @include mq(md) {
      transform: translate(-15%, 50%);
    }

    @include mq(lg) {
      transform: translate(-50%, 50%);
    }
  }

  &__img {
    display: block;
    height: 72px;
    width: auto;
    margin: $margin__base auto $margin__base * 2;
  }

  &__text {
    margin: $margin__base 0;
  }
}

.projects {
  background: $grey;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    background: $grey;
    width: 100vw;
    height: auto;
  }

  &__content {
    position: relative;
    padding: $padding__base * 3 $margin__base;
  }

  &__title {
    font-size: rem(24px);
    text-transform: uppercase;

    @include mq(sm) {
      font-size: rem(36px);
    }
  }

  &__button {
    font-size: rem(12px);
    margin: $margin__base * 3 0;
    padding: calc($padding__base / 2) $padding__base;
    border: 1px solid $color--tertiary;
    height: fit-content;
    text-transform: uppercase;
    color: $color--tertiary;
    border-radius: 3px;

    @include mq(sm) {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    &:hover {
      color: darken($color--tertiary, 15%);
      border-color: darken($color--tertiary, 15%);
    }
  }

  &__switch {
    font-size: rem(12px);
    display: flex;
    margin: $margin__base * 2 0 0;
    border-radius: 3px;
    overflow: hidden;
  }

  &--section {
    background: $grey;
    padding-bottom: $padding__base * 3;
  }

  &__grid {
    @include mq(sm) {
      display: grid;
      grid-gap: $grid-gutter-width;
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(md) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include mq(lg) {
      grid-template-columns: repeat(4, 1fr);
    }
}

  &__more {
    color: $color--primary;
    text-decoration: underline;
    margin-top: $margin__base * 2;
    display: block;
    text-align: center;
  }
}

.student {
  background: $white;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 400px;

  &__block {
    position: relative;
  }

  &__content {
    position: absolute;
    top: 0;
    background-color: rgba(107,197,207,.8);
    text-align: center;
    color: $white;
    padding: $padding__base $padding__base * 2;
    margin: 0 $margin__base;

    @include mq(sm) {
      max-width: 360px;
      right: 0;
    }
  }

  &__title {
    margin: $margin__base 0;
  }

  &__button {
    display: block;
    font-size: rem(12px);
    width: fit-content;
    background: $color--primary;
    color: $white;
    text-transform: uppercase;
    margin: $margin__base * 3 auto $margin__base;
  }
}

.wrapper {
  max-width: 340px;
  margin-right: auto;
  margin-left: auto;

  @include mq(sm) {
    max-width: 640px;
  }

  @include mq(md) {
    max-width: 930px;
  }

  @include mq(lg) {
    max-width: 1140px;
  }
}

.btn {
  white-space: nowrap;
  text-align: center;

  &:hover {
    background: darken($color--tertiary, 15%);
  }
}

.switch-link {
  background: $white;
  border-radius: 0;

  &:hover {
    background-color: darken($white, 5%);
  }


  &--last {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &--active {
    background: $color--tertiary;
    color: $white;

    &:hover {
      background: $color--tertiary;
    }
  }
}

.section--active {
  display: block;
}

.project-card {
  display: block;
  margin: $margin__base * 2 0;
}
</style>
