<template>
  <div class="checkbox__container">
    <label
      class="checkbox__label"
      :class="{ 'checkbox--selected': isSelected, 'checkbox--disabled': disabled }"
    >
      <input
        class="input checkbox__element"
        type="checkbox"
        :id="id || `checkbox_id${uid}`"
        :name="name"
        :value="modelValue"
        :required="required"
        :checked="state"
        :disabled="disabled"
        @change="onChange"
        @blur="blurIfMandatory"
      >

      <fa-icon
        class="checkbox__icon"
        :icon="isSelected ? ['fal', 'check-square'] : ['fal', 'square']"
      />

      <span class="checkbox__item">
        <slot />
      </span>
    </label>

    <div
      class="input--errors margin__t--6"
      v-if="v && v.$error"
    >
      <p
        class="input--error"
        v-if="v.required && v.required.$invalid"
      >
        {{ $i18n.t('errors.input_checkbox_required') }}
      </p>

      <p
        class="input--error"
        v-if="v.sameAs && v.sameAs.$invalid"
      >
        {{ $i18n.t('errors.input_checkbox_required') }}
      </p>

      <p
        class="input--error"
        v-if="v.terms && v.terms.$invalid"
      >
        {{ $i18n.t('errors.input_checkbox_terms') }}
      </p>

      <p
        class="input--error"
        v-if="v.stripe_tos && v.stripe_tos.$invalid"
      >
        {{ $i18n.t('errors.input_checkbox_stripe_tos') }}
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { isArray as _isArray, isBoolean as _isBoolean } from 'lodash'
import { guid } from '@/utils/utils'

export default defineComponent({
  model: {
    prop: 'modelValue',
    event: 'input'
  },
  emits: ['update:modelValue'],
  props: {
    id: {
      type: String
    },
    name: {
      type: String,
      default: 'input-checkbox'
    },
    modelValue: {
      default: null
    },
    checked: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    v: {
      type: Object
    },
    model: {}
  },
  setup: () => ({ uid: guid(2) }),
  computed: {
    state () {
      if (this.modelValue === undefined) {
        return this.checked
      }

      if (_isArray(this.modelValue)) {
        return this.modelValue.indexOf(this.value) > -1
      }

      return !!this.modelValue
    },
    isSelected () {
      let selected = false
      if (_isArray(this.modelValue)) {
        return this.modelValue.indexOf(this.value) > -1
      } else if (_isBoolean(this.modelValue)) {
        selected = this.modelValue
      } else if (this.modelValue === 'true' || this.modelValue === 'false') {
        selected = JSON.parse(this.modelValue)
      }
      return selected
    }
  },
  methods: {
    onChange () {
      this.toggle()
    },
    toggle () {
      let value

      if (_isArray(this.modelValue)) {
        value = this.modelValue.slice(0)

        if (this.state) {
          value.splice(value.indexOf(this.value), 1)
        } else {
          value.push(this.modelValue)
        }
      } else {
        value = !this.state
      }

      this.$emit('update:modelValue', value)
    },
    blurIfMandatory () {
      if (this.v) {
        this.v.$touch()
      }
    }
  },
  watch: {
    checked (newValue) {
      if (newValue !== this.state) {
        this.toggle()
      }
    }
  },
  mounted () {
    if (this.checked && !this.state) {
      this.toggle()
    }
  }
})
</script>

<style lang="scss" scoped>
.checkbox {
  &__element {
    position: absolute;
    appearance: none;
    width: 0;
    height: 0;
    pointer-events: none;

    &:focus + .checkbox__icon {
      color: $dg;
    }
  }

  &__label {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    color: rgba($dg, .6);
    cursor: pointer;
  }

  &__icon {
    margin-top: 3px;
    margin-right: $margin__base;
  }

  &--selected {
    color: $black;

    .checkbox__icon {
      color: $color--success !important;
    }
  }

  &--disabled {
    .checkbox__icon {
      color: $vlg!important;
    }
  }
}
</style>
