<template>
  <div translate="no">
    <section
      class="banner color--white"
      :style="{ backgroundImage: `url(${translate(banner.image)})` }"
      v-if="isVisible('banner')"
    >
      <div class="container">
        <div class="banner__wrapper">
          <h1
            class="title__h1"
            v-if="translate(banner.title)"
          >
            {{ translate(banner.title) }}
          </h1>

          <div
            class="editor editor__content banner__content margin__t--24"
            v-if="translate(banner.content)"
            v-html="translate(banner.content)"
          />
        </div>
      </div>
    </section>

    <section
      class="stats color--white"
      v-if="isVisible('stats') && metrics.length && statistics"
    >
      <ol class="container stats__wrapper padding__tb--24">
        <li
          class="stats__li"
          v-for="(metric, key) in metrics"
          :key="`metric_${key}`"
        >
          <strong class="stats__value">
            <app-date
              v-if="metric.type === 'date' && metric.date"
              :timestamp="metric.date"
              filter="differenceInCalendarDays"
            />

            <app-money
              v-else-if="metric.type === 'amount_collected'"
              :amount="statistics[metric.type] || 0"
            />

            <template v-else-if="metric.type === 'custom'">
              {{ translate(metric.value) || 0 }}
            </template>

            <template v-else>
              {{ statistics[metric.type] || 0 }}
            </template>
          </strong>

          {{ translate(metric.text) }}
        </li>
      </ol>
    </section>

    <section
      class="about padding__tb--48"
      v-if="isVisible('about')"
    >
      <div class="container about__wrapper">
        <div class="about__column">
          <h2
            class="title__h2 color--prim"
            v-if="translate(about.title)"
          >
            {{ translate(about.title) }}
          </h2>

          <div
            class="editor editor__content about__content margin__t--24"
            v-if="translate(about.content)"
            v-html="translate(about.content)"
          />

          <app-link
            :link="about.cta"
            class="bold btn btn__color--prim btn__size--medium d-inline-block margin__t--48 cta"
          />
        </div>

        <figure class="about__figure">
          <img
            :src="translate(about.image)"
            :alt="translate(about.title)"
            class="about__image"
            v-if="translate(about.image)"
          >

          <fa-icon
            class="no-thumb"
            :icon="['far', 'image']"
            v-else
          />
        </figure>
      </div>
    </section>

    <section
      class="numbers padding__tb--24"
      v-if="isVisible('breastcancernumbers') && breastCancerNumbers.items && breastCancerNumbers.items.length"
    >
      <div class="container">
        <h2
          class="title__h2 color--prim margin__b--30 align--center"
          v-if="translate(breastCancerNumbers.title)"
        >
          {{ translate(breastCancerNumbers.title) }}
        </h2>

        <ol class="numbers__items">
          <li
            class="number number--large color--white align--center"
            v-for="(number, key) in breastCancerNumbers.items"
            :key="`number_${key}`"
          >
            <figure
              class="number__figure margin__b--24"
              v-if="translate(number.icon)"
            >
              <img
                class="number__icon"
                :src="translate(number.icon)"
              >
            </figure>

            <h2
              class="number__number title__h3"
              v-if="translate(number.number)"
            >
              {{ translate(number.number) }}
            </h2>

            <div
              class="editor editor__content number__text margin__t--6"
              v-if="translate(number.text)"
              v-html="translate(number.text)"
            />
          </li>
        </ol>
      </div>
    </section>

    <section
      class="numbers padding__tb--24"
      v-if="isVisible('bignumbers') && bigNumbers.items && bigNumbers.items.length"
    >
      <div class="container">
        <h2
          class="title__h2 color--prim margin__b--30 align--center"
          v-if="translate(bigNumbers.title)"
        >
          {{ translate(bigNumbers.title) }}
        </h2>

        <ol class="numbers__items numbers__items--4">
          <li
            class="number color--white align--center"
            v-for="(number, key) in bigNumbers.items"
            :key="`number_${key}`"
          >
            <figure
              class="number__figure margin__b--24"
              v-if="translate(number.icon)"
            >
              <img
                class="number__icon"
                :src="translate(number.icon)"
              >
            </figure>

            <h2
              class="number__number title__h3"
              v-if="translate(number.number)"
            >
              {{ translate(number.number) }}
            </h2>

            <div
              class="editor editor__content number__text margin__t--6"
              v-if="translate(number.text)"
              v-html="translate(number.text)"
            />
          </li>
        </ol>
      </div>
    </section>

    <section
      class="video align--center padding__t--48 padding__b--36"
      v-if="isVisible('video') && translate(video.url)"
    >
      <div class="container">
        <h2
          class="title__h2 color--prim margin__b--30"
          v-if="translate(video.title)"
        >
          {{ translate(video.title) }}
        </h2>

        <app-video-embed
          :src="translate(video.url)"
          class="video__embed"
        />

        <div
          class="editor editor__content video__content margin__t--24"
          v-if="translate(video.content)"
          v-html="translate(video.content)"
        />
      </div>
    </section>

    <section
      class="products padding__tb--36"
      v-if="isVisible('products') && products.items && products.items.length"
    >
      <div class="container">
        <h2
          class="title__h2 color--prim margin__b--30 align--center"
          v-if="translate(products.title)"
        >
          {{ translate(products.title) }}
        </h2>

        <ul class="products__items">
          <li
            class="product"
            v-for="(product, key) in products.items"
            :key="`product_${key}`"
          >
            <figure class="product__figure">
              <img
                :src="translate(product.image)"
                :alt="translate(product.name)"
                class="product__image"
                v-if="translate(product.image)"
              >

              <fa-icon
                class="no-thumb"
                :icon="['far', 'image']"
                v-else
              />
            </figure>

            <div class="padding__tb--24 padding__lr--18 product__content">
              <h3
                class="title__h4 product__name d-flex justify-content-between color--prim"
                v-if="translate(product.name)"
              >
                <app-link
                  class="product__link link__color--prim link--naked"
                  :link="product.cta"
                  v-if="product.cta.show"
                >
                  {{ translate(translate(product.name)) }}
                </app-link>

                <template v-else>
                  {{ translate(translate(product.name)) }}
                </template>

                <app-money
                  class="product__price color--black"
                  :amount="product.price"
                  v-if="parseFloat(product.price) > 0"
                />
              </h3>

              <div
                class="editor editor__content product__text margin__t--6"
                v-if="translate(product.text)"
                v-html="translate(product.text)"
              />

              <app-link
                :link="product.cta"
                class="cta product__cta btn btn__size--small btn__color--prim d-inline-block bold margin__t--18"
              />
            </div>
          </li>
        </ul>
      </div>
    </section>

    <section
      class="events"
      v-if="isVisible('events') && events.items && events.items.length"
    >
      <div class="container">
        <h2
          class="title__h2 color--prim margin__b--30 align--center"
          v-if="translate(events.title)"
        >
          {{ translate(events.title) }}
        </h2>

        <ul class="events__items">
          <li
            class="event"
            v-for="(event, key) in events.items"
            :key="`event_${key}`"
          >
            <figure class="event__figure">
              <img
                :src="translate(event.image)"
                :alt="translate(event.name)"
                class="event__image"
                v-if="translate(event.image)"
              >

              <fa-icon
                class="no-thumb"
                :icon="['far', 'image']"
                v-else
              />
            </figure>

            <div class="padding__tb--30 padding__lr--18 event__content">
              <h3
                class="title__h3 event__title d-flex justify-content-between align-items-center"
                v-if="translate(event.title)"
              >
                <app-link
                  class="event__link link__color--black-prim link--naked"
                  :link="event.cta"
                  v-if="event.cta.show"
                >
                  {{ translate(translate(event.title)) }}
                </app-link>

                <template v-else>
                  {{ translate(translate(event.title)) }}
                </template>
              </h3>

              <div
                class="editor editor__content event__text margin__t--18"
                v-if="translate(event.text)"
                v-html="translate(event.text)"
              />

              <app-link
                :link="event.cta"
                class="cta event__cta btn btn__size--small btn__color--prim d-inline-block bold margin__t--18"
              />
            </div>

            <ul
              v-if="event.date || translate(event.location)"
              class="event__info"
            >
              <li v-if="event.date">
                <app-date
                  :timestamp="event.date"
                  filter="dd MMMM Y"
                />
              </li>

              <li v-if="translate(event.location)">
                {{ translate(event.location) }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useClientStore } from '@/stores/ClientStore'
import useSection from '@/composables/modules/pages/useSection'
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const AppMoney = defineAsyncComponent(() => import('&/atoms/AppMoney'))
const AppDate = defineAsyncComponent(() => import('&/atoms/AppDate'))
const AppVideoEmbed = defineAsyncComponent(() => import('&/atoms/AppVideoEmbed'))

const props = defineProps({
  page: { type: Object, required: true },
  sections: { type: Object, required: true }
})

const { translate } = useI18n()
const { getSectionAttributes, isVisible } = useSection(props.page)
const { getStatistics } = useClientStore()

const banner = computed(() => getSectionAttributes('banner'))
const stats = computed(() => getSectionAttributes('stats'))
const about = computed(() => getSectionAttributes('about'))
const breastCancerNumbers = computed(() => getSectionAttributes('breastcancernumbers'))
const bigNumbers = computed(() => getSectionAttributes('bignumbers'))
const video = computed(() => getSectionAttributes('video'))
const products = computed(() => getSectionAttributes('products'))
const events = computed(() => getSectionAttributes('events'))

const metrics = computed(() => _get(stats.value, 'metrics', []))

const { statistics } = getStatistics({ query: { campaign_id: _get(metrics.value, 'settings.campaign_id', []), procedure_id: _get(metrics.value, 'settings.procedure_id', []) } })
</script>

<style lang="scss" scoped>
$color--primary: #E60F8E;
$color--secondary: #00C2E1;
$color--tertiary: #653472;

.title {
  &__h1,
  &__h2 {
    font-size: rem(32px);
    line-height: 1.1;

    @include mq(sm) {
      font-size: rem(38px);
    }
  }

  &__h3 {
    font-size: rem(28px);
    line-height: 1.3;

    @include mq(sm) {
      font-size: rem(32px);
    }
  }

  &__h4 {
    font-size: rem(18px);
    line-height: 1.4;
  }
}
.no-thumb {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: $white;
  font-size: rem(72px);
}

:deep() {
  .cta {
    font-size: rem(18px);
    line-height: 1;
    border-radius: 25px;
    position: relative;
    z-index: 99;
  }
}

.banner {
  position: relative;
  padding: 72px 0;
  font-size: rem(18px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $color--tertiary;

  @include mq(sm) {
    padding: 126px 0;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 80%, #ED55A7 100%);

    @include mq(sm) {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 68%, #ED55A7 100%);
    }
  }

  &__wrapper {
    max-width: 670px;
    position: relative;
    z-index: 10;

    @include mq(lg) {
      padding-left: 108px;
    }
  }

  &__content {
    max-width: 620px;
  }
}

.stats {
  background: #8B8C8C;

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 32px;

    @include mq(sm) {
      flex-direction: row;
      gap: 40px;
    }
  }

  &__li {
    font-size: rem(18px);
    line-height: 1.1;
    text-align: center;
  }

  &__value {
    font-size: rem(36px);
    line-height: 1;
    margin-bottom: 6px;
    display: block;
    text-align: center;
    font-family: $font-family--secondary;

    @include mq(md) {
      font-size: rem(48px);
    }
  }
}

.about {
  &__wrapper {
    display: grid;
    gap: 48px;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(lg) {
      grid-template-columns: 1fr 516px;
    }

    @include mq(lg) {
      gap: 108px;
    }
  }

  &__column {
    @include mq(lg) {
      padding-left: 108px;
    }
  }

  &__content {
    font-size: rem(18px);
    line-height: 1.4;
  }

  &__figure {
    position: relative;
    overflow: hidden;
    border-radius: 32px 32px 150px 32px;
    background-color: $color--tertiary;
    width: 100%;
    max-width: 516px;

    @include aspect-ratio(1, 1);
  }

  &__image {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }
}

.numbers {
  &__items {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr;

    @include mq(sm) {
      grid-template-columns: repeat(4, 1fr);

      .number {
        grid-column: span 2;

        &:last-child:nth-child(2n - 1) {
          grid-column-end: -2;
        }
      }
    }

    @include mq(md) {
      grid-template-columns: repeat(6, 1fr);

      .number {
        &:last-child:nth-child(2n - 1) {
          grid-column-end: inherit;
        }

        &:last-child:nth-child(3n - 1) {
          grid-column-end: -2;
        }

        &:nth-last-child(2):nth-child(3n + 1) {
          grid-column-end: 4;
        }

        &:last-child:nth-child(3n - 2) {
          grid-column-end: 5;
        }
      }
    }

    @include mq(lg) {
      &--4 {
        grid-template-columns: repeat(12, 1fr);

        .number {
          grid-column: span 3;

          &:last-child:nth-child(3n - 1),
          &:nth-last-child(2):nth-child(3n + 1),
          &:last-child:nth-child(3n - 2) {
            grid-column-end: inherit;
          }

          &:last-child:nth-child(4n - 1) {
            grid-column-end: -3;
          }

          &:nth-last-child(3):nth-child(4n + 1) {
            grid-column-end: 5;
          }

          &:nth-last-child(2):nth-child(4n + 1) {
            grid-column-end: 7;
          }

          &:last-child:nth-child(4n - 3) {
            grid-column: span 4;
            grid-column-end: 9;
          }
        }
      }
    }
  }
}

.number {
  padding: 24px;
  border-radius: 16px;
  background: linear-gradient(0deg, #d3348B -2.33%, #F660A7 97.25%);

  @include mq(lg) {
    padding: 36px;
    grid-column: span 3;

    &--large {
      .number {
        &__number {
          font-size: rem(32px);
        }

        &__text {
          font-size: rem(24px);
        }

        &__figure {
           width: 180px;
        }
      }
    }
  }

  &__figure {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 24px;
    width: 113px;
    max-width: 100%;
    background: $white;

    @include aspect-ratio(1, 1);
  }

  &__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: auto;
    transform: translate(-50%, -50%);
  }

  &__number {
    font-size: rem(24px);
  }

  &__text {
    font-size: rem(18px);
    line-height: 25px;
  }
}

.video {
  &__content {
    font-size: rem(18px);
    line-height: 1.4;
  }
}

.products {
  &__items {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr;

    @include mq(sm) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include mq(md) {
      grid-template-columns: repeat(6, 1fr);
    }

    @include mq(lg) {
      grid-template-columns: repeat(8, 1fr);
    }
  }
}

.product {
  position: relative;
  border-radius: 36px;
  background: #FFF;
  box-shadow: 0px 9px 17px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;

  @include mq(sm) {
    grid-column: span 2;

    &:last-child:nth-child(2n - 1) {
      grid-column-end: -2;
    }
  }

  @include mq(md) {
    &:last-child:nth-child(2n - 1) {
      grid-column-end: inherit;
    }

    &:last-child:nth-child(3n - 1) {
      grid-column-end: -2;
    }

    &:nth-last-child(2):nth-child(3n + 1) {
      grid-column-end: 4;
    }

    &:last-child:nth-child(3n - 2) {
      grid-column-end: 5;
    }
  }

  @include mq(lg) {
    &:last-child:nth-child(3n - 1),
    &:nth-last-child(2):nth-child(3n + 1),
    &:last-child:nth-child(3n - 2) {
      grid-column-end: inherit;
    }

    &:last-child:nth-child(4n - 1) {
      grid-column-end: -2;
    }

    &:nth-last-child(3):nth-child(4n + 1) {
      grid-column-end: 4;
    }

    &:last-child:nth-child(4n - 2) {
      grid-column-end: -3;
    }

    &:nth-child(4n+1):nth-last-child(2) {
      grid-column-end: -5;
    }

    &:nth-child(4n+1):last-child {
      grid-column-end: -4;
    }
  }

  &__figure {
    position: relative;
    background: $color--tertiary;

    @include aspect-ratio(308, 204);
  }

  &__image {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    top: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
  }

  &__name {
    width: 100%;
    gap: 12px;
  }

  &__price {
    margin-left: auto;
  }

  &__text {
    flex-grow: 1;
    font-size: rem(12px);
    line-height: 1.6;
  }

  & :deep() {
    .product {
      &__link {
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          width: 100%;
          height: 100%;
          z-index: 88;
        }
      }

      &__cta {
        font-size: rem(12px);
        padding: 9px 18px;
      }
    }
  }
}

.events {
  padding: 36px 0 120px;

  &__items {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr;

    @include mq(sm) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include mq(md) {
      grid-template-columns: repeat(6, 1fr);
    }
  }
}

.event {
  position: relative;
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 9px 17px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;

  @include mq(sm) {
    grid-column: span 2;

    &:last-child:nth-child(2n - 1) {
      grid-column-end: -2;
    }
  }

  @include mq(md) {
    &:last-child:nth-child(2n - 1) {
      grid-column-end: inherit;
    }

    &:last-child:nth-child(3n - 1) {
      grid-column-end: -2;
    }

    &:nth-last-child(2):nth-child(3n + 1) {
      grid-column-end: 4;
    }

    &:last-child:nth-child(3n - 2) {
      grid-column-end: 5;
    }
  }

  &__figure {
    position: relative;
    background: $color--tertiary;

    @include aspect-ratio(408, 248);
  }

  &__image {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    top: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    text-align: center;
  }

  &__name {
    width: 100%;
    gap: 12px;
  }

  &__price {
    margin-left: auto;
  }

  &__text {
    flex-grow: 1;
    font-size: rem(18px);
    line-height: 1.4;
  }

  &__info {
    display: flex;
    color: #A5A5A5;
    justify-content: center;
    padding: 18px;
    border-top: 1px solid #A5A5A5;

    li {
      &:not(:last-child) {
        &::after {
          content: '-';
          display: inline-block;
          margin: 0 3px;
        }
      }
    }
  }

  & :deep() {
    .event {
      &__link {
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          width: 100%;
          height: 100%;
          z-index: 88;
        }
      }

      &__cta {
        font-size: rem(12px);
        padding: 9px 18px;
      }
    }
  }
}
</style>