export default [
  {
    name: 'custom_footer_technopolis',
    path: 'modules/pages/technopolis/FooterTechnopolis'
  },
  {
    name: 'homepage_technopolis',
    path: 'modules/pages/technopolis/HomepageTechnopolis'
  },
  {
    name: 'custom_header_technopolis',
    path: 'modules/pages/technopolis/HeaderTechnopolis'
  },
  {
    name: 'homepage2024_technopolis',
    path: 'modules/pages/technopolis/Homepage2024Technopolis'
  }
]
