<template>
  <div
    class="modal"
    role="dialog"
  >
    <div
      class="modal__mask"
      @mousedown.self="$emit('close')"
    >
      <div class="modal__wrapper">
        <div
          class="modal__container"
          :class="['modal__size--' + size]"
          @click.stop
        >
          <button
            class="modal__btn--close"
            @click.prevent="$emit('close')"
          >
            <fa-icon
              class="color--lg"
              size="sm"
              :icon="['fal', 'times']"
              fixed-width
            />
          </button>

          <div class="modal__body">
            <slot name="body" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppModal',
  emits: ['close'],
  props: {
    size: {
      type: String,
      default: 'md'
    }
  }
})
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  display: block;
  z-index: 9998;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(#333333, 0.7);
  transition: opacity 0.3s ease;
  overflow-y: auto;
  outline: 0;
  padding: 0;

  &__mask {
    position: relative;
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
    z-index: 9999;
    padding: $padding__base * 1.5 0;
  }

  &__wrapper {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &__container {
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    background-color: $white;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    outline: 0;
    z-index: 2000;
    pointer-events: all;
    height: auto;
    text-align: left;
  }

  &__size {
    @each $mq, $screenSize in $grid-breakpoints {
      &--#{$mq} {
        width: 100%;

        @include mq(sm) {
          width: calc($screenSize / 2);
        }

        @include mq(md) {
          width: calc($screenSize / 1.5);
        }

        @include mq(lg) {
          width: calc($screenSize / 1.25);
        }

        @include mq(xl) {
          width: $screenSize;
        }
      }
    }

    &--confirm {
      width: 100%;

      @include mq(sm) {
        width: 500px;
      }
    }
  }

  &__btn {
    display: block;

    &--close {
      position: absolute;
      top: $padding__base;
      right: $padding__base;
      z-index: 1;

      &:focus {
        outline: 1px dashed $black;
        outline-offset: 2px;
      }
    }
  }

  &-enter {
    opacity: 0;
  }

  &-leave-active {
    opacity: 0;
  }

  &-enter &__container,
  &-leave-active &__container {
    transform: scale(1.1);
  }
}
</style>
