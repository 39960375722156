<template>
  <main class="home">
    <section class="banner wrapper">
      <article class="banner__container">
        <div class="banner__inner">
          <h2
            class="banner__title"
            v-if="$i18n.translate(banner.title)"
          >
            {{ $i18n.translate(banner.title) }}
          </h2>

          <div
            class="banner__text editor__content editor margin__tb--24"
            v-if="$i18n.translate(banner.text)"
            v-html="$i18n.translate(banner.text)"
          />

          <app-link
            :link="banner.link"
            class="banner__link"
            v-slot="{ text }"
          >
            <fa-icon
              class="banner__link-icon margin__r--6"
              :icon="['fal', 'arrow-right']"
            />

            {{ text }}
          </app-link>
        </div>

        <figure
          class="banner__image"
          :style="{backgroundImage: `url(${$i18n.translate(banner.image)})`}"
        />
      </article>
    </section>

    <section
      class="stats"
      v-if="stats.items.length > 0 && statistics"
    >
      <ul class="wrapper stats__list padding__tb--12">
        <li
          class="stat"
          v-for="(stat, id) in stats.items"
          :key="`metric_item_${id}`"
        >
          <app-date
            class="stat__type bold"
            v-if="stat.type === 'date' && stat.date"
            :timestamp="stat.date"
            filter="differenceInCalendarDays"
          />

          <app-money
            class="stat__type bold"
            v-else-if="stat.type === 'amount_collected'"
            :amount="statistics[stat.type]"
          />

          <template v-else-if="stat.type === 'sporting_statistics'">
            {{ getSportingStatistic(stat.sporting_statistics) }}
          </template>

          <span
            class="stat__type bold"
            v-else
          >
            {{ statistics[stat.type] }}
          </span>

          <span
            v-if="stat.text"
            class="stat__text"
          >
            {{ $i18n.translate(stat.text) }}
          </span>
        </li>
      </ul>
    </section>

    <section class="campaigns">
      <div
        class="wrapper wrapper__campaigns"
        v-if="campaign_items.length > 0"
      >
        <h2
          class="campaigns__title bold margin__b--60"
          v-if="$i18n.translate(campaigns.title)"
        >
          {{ $i18n.translate(campaigns.title) }}
        </h2>

        <section class="campaigns__item">
          <article
            class="campaign"
            v-for="(campaign, id) in campaign_items"
            :key="`campaing_${id}`"
            @click="goToCampaign(campaign)"
          >
            <figure
              class="campaign__image"
              :style="{backgroundImage: `url(${$i18n.translate(campaign.image.small.url)})`}"
            >
              <figcaption class="campaign__name btn__size--small">
                {{ $i18n.translate(campaign.name) }}
              </figcaption>

              <div
                v-if="$i18n.translate(campaign.description)"
                class="campaign__description"
              >
                <div
                  class="campaign__description--p padding--24"
                  v-html="truncate($i18n.translate(campaign.description), 200)"
                />
              </div>
            </figure>
          </article>
        </section>
      </div>
    </section>

    <section
      class="projects"
      v-if="project_items.length > 0"
    >
      <div class="wrapper wrapper__projects">
        <h2
          class="projects__title bold margin__b--48"
          v-if="$i18n.translate(projects.title)"
        >
          {{ $i18n.translate(projects.title) }}
        </h2>

        <section class="projects__item margin__b--48">
          <div
            class="project"
            v-for="project in project_items"
            :key="`project__${project.id}`"
          >
            <project-card
              class="project-card"
              :project="project"
            />
          </div>
        </section>

        <button
          class="projects__button bold btn__color--prim btn__size--classic"
          v-if="$i18n.translate(projects.button.text)"
          @click.prevent="setRouteNavigation(projects.button)"
        >
          {{ $i18n.translate(projects.button.text) }}
        </button>
      </div>
    </section>
  </main>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import { get as _get } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import ProjectCard from '&/organisms/ProjectCard'
import { truncate } from '@/utils/utils'
import PageRouteNavigation from '%/PageRouteNavigation'
import { projects_overview_options } from '@/configurations/general/components-default-data'

export default defineComponent({
  name: 'CustomHomepageUGent',
  components: {
    AppLink: defineAsyncComponent(() => import('&/atoms/AppLink')),
    AppDate: defineAsyncComponent(() => import('&/atoms/AppDate')),
    AppMoney: defineAsyncComponent(() => import('&/atoms/AppMoney')),
    ProjectCard
  },
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      campaign_items: [],
      project_items: [],
      truncate,
      projects_overview_options
    }
  },
  created () {
    if (this.campaigns.ids.length) {
      this.fetchCampaigns({ query: { campaign_id: this.campaigns.ids } })
        .then(data => this.campaign_items = Object.values(data.items))
    }

    this.fetchProjects({
      query: this.queries,
      removeStoredItems: true
    })
      .then(data => this.project_items = Object.values(data.items))

    if (this.stats.items.length)
      this.fetchClientStatistics({ query: this.stats.query })
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      statistics: 'client/statistics'
    }),
    attributes () {
      return _get(this.section, 'attributes', {})
    },
    queries () {
      return {
        'sort_by_field[field]': this.getFieldQuery(this.attributes.projects.query.sort, 'sort_by_field[field]') || 'current_amount',
        'sort_by_field[direction]': this.getFieldQuery(this.attributes.projects.query.sort, 'sort_by_field[direction]') || 'desc',
        'campaign_id': this.attributes.projects.query.campaign_id || [],
        'per_page': this.attributes.projects.query.per_page || 4
      }
    },
    banner () {
      return _get(this.attributes, 'banner', {})
    },
    campaigns () {
      return _get(this.attributes, 'campaigns', {})
    },
    projects () {
      return _get(this.attributes, 'projects', {})
    },
    stats () {
      return _get(this.attributes, 'stats', {})
    },
    sportingStatistics () {
      return _get(this.statistics, 'sporting_statistics', {})
    }
  },
  methods: {
    ...mapActions({
      fetchClientStatistics: 'client/fetchClientStatistics',
      fetchCampaigns: 'campaigns/fetchCampaigns',
      fetchProjects: 'projects/fetchProjects'
    }),
    getFieldQuery (value, key) {
      const option = _get(this.getFieldOption(value), 'query', {})
      return option[key]
    },
    getFieldOption (value) {
      return this.projects_overview_options.find(option => option.value === value) || {}
    },
    getSportingStatistic (key) {
      const value = _get(this.sportingStatistics, key, 0)

      if (key === 'total_distance') return value / 1000
      if (key === 'total_time') return Math.round(value / 3600)

      return value
    },
    goToCampaign (campaign) {
      const procedureSlug = Array.isArray(campaign.procedures) ? campaign.procedures.find(procedure => procedure.category === 'create-project').slug : null

      if (_get(campaign, `cta.url[${this.$i18n.locale}]`)) {
        window.open(campaign.cta.url[this.$i18n.locale], '_blank')
      } else {
        this.$router.push({ name: 'ClientProcedure', params: { procedureSlug } }).catch()
      }
    }
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #1E64C8;
$color--secondary: #E9F0FA;
$color--secondary-hover: #030912;

.home {
  background: $white;
  padding-top: $padding__base * 2;
}

.wrapper {
  max-width: 1318px;

  &__campaigns, &__projects {
    margin: 0 $margin__base;
  }

  @include mq(sm) {
    margin: 0 $margin__base * 4;
  }

  @include mq(md) {
    margin: 0 $margin__base * 7;
  }

  @include mq(xl) {
    margin: 0 auto;
  }
}

.banner {
  &__container {
    display: grid;
    grid-template-columns: 1fr;
    gap: $grid-gutter-width;

    @include mq(md) {
      grid-template-columns: 57% auto;
    }

    @include mq(lg) {
      width: 85%;
      margin: 0 auto;
    }
  }

  &__inner {
    justify-self: center;
    background: $color--secondary;
    padding: $padding__base * 5 $padding__base * 2;
    width: 100%;

    @include mq(sm) {
      padding: $padding__base * 6 $padding__base * 3;
    }
  }

  &__title, &__text {
    max-width: 516px;
    display: block;
  }

  &__title {
    color: $color--primary;
    text-transform: uppercase;
    font-size: rem(18px);
    font-weight: bold;
  }

  &:deep(.banner__link) {
    color: $color--primary;
    text-decoration: underline;
    transition: all $transition__duration;
    max-width: 516px;
    display: block;

    .banner__link-icon {
      color: $black;
      font-size: rem(12px);
    }

    &:hover {
      color: shade(#15488E);
    }
  }

  &__image {
    background-color: $color--primary;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include aspect-ratio(1.2, 1);
  }
}

.stats {
  background: $color--secondary;
  margin-bottom: $margin__base * 8;

  @include mq(sm) {
    margin-top: $margin__base * 4;
  }

  &__list {
    @include mq(sm) {
      display: flex;
      justify-content: space-evenly;
    }

    @include mq(xl) {
      margin: 0 auto;
    }
  }

  .stat {
    text-align: center;
    padding: $padding__base * 2 0;

    @include mq(sm) {
      padding: $padding__base * 4 0;
    }

    &__type {
      display: block;
      color: $color--primary;
      font-size: rem(32px);
    }

    &__text {
      font-size: rem(22px);
      font-weight: normal;
    }
  }
}

.campaigns {
  &__title {
    text-align: center;
    font-size: rem(40px);
    color: $color--primary;
  }

  &__item {
    display: grid;
    gap: $grid-gutter-width;
    margin-bottom: $margin__base * 10;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(lg) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.campaign {
  position: relative;
  cursor: pointer;
  transition: all $transition__duration;

  @include mq(md) {
    overflow: hidden;
  }

  &__image {
    background: $vdw;
    background-size: cover;
    background-position: center;
    border-bottom: 5px solid $color--primary;
    @include aspect-ratio(1.33, 1);

    @include mq(sm) {
      @include aspect-ratio(1, 1.1);
    }
  }

  &__name {
    background: $color--secondary;
    position: absolute;
    text-align: center;
    top: $margin__base * 2;
    left: 50%;
    transform: translateX(-50%);
    color: $color--primary;
    font-weight: bold;
    text-transform: uppercase;

    @include mq(sm) {
      top: $margin__base * 2;
      left: $margin__base * 2;
      transform: translateX(0);
    }
  }

  &__description {
    background: $color--primary;
    color: $white;

    @include mq(sm) {
      position: absolute;
      width: 100%;
      transform: translateY(-100%);
      font-size: rem(14px);
    }

    @include mq(lg) {
      position: absolute;
      width: 100%;
      transition: ease-out .25s;
      transform-origin: bottom;
      transform: translateY(0);
      font-size: rem(16px);
    }

    &--p {
      word-break: break-word;
    }
  }

  &:hover {
    .campaign__description {
      @include mq(lg) {
        transform: translateY(-100%);
      }
    }
  }
}

.projects {
  padding-bottom: $padding__base * 10;

  &__title {
    text-align: center;
    font-size: rem(40px);
    color: $color--primary;
  }

  &__item {
    display: grid;
    gap: $grid-gutter-width;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(md) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include mq(lg) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__button {
    display: block;
    margin: 0 auto;
    color: $white;
    transition: all $transition__duration;
  }
}
</style>
