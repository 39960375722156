<template>
  <app-component-loader
    :objects="{ rows }"
    :component-path="path"
    v-if="path"
  />
</template>

<script setup>
import { computed, provide, toRefs } from 'vue'
import { useTemplateStore } from '@/stores/TemplateStore'
import { get as _get } from 'lodash'

import AppComponentLoader from '&/atoms/AppComponentLoader'

const props = defineProps({
  templateId: { type: String, required: true },
  preview: { type: Boolean, default: false },
  objects: { type: Object, default: () => ({}) }
})

const { getTemplate } = useTemplateStore()

const { templateId, preview, objects } = toRefs(props)

const { template } = getTemplate({ id: templateId })

const rows = computed(() => _get(template.value, 'configuration.rows', []))
const path = computed(() => _get(template.value, 'configuration.path'))

provide('template', template)
provide('objects', objects)
provide('preview', preview)
</script>