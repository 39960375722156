import { defineStore } from 'pinia'
import Client from '@/classes/clients/Client'
import store from '@/store'

export const useClientStore = defineStore('ClientStore', {
  state: () => ({
    client: {},
    statistics: {},
    loader: false,
    error: {}
  }),
  getters: {
    getClient: state => () => {
      return state.$getItem({
        resource: 'client',
        fetch: () => state.fetchClient()
      })
    },
    getStatistics: state => ({ query, dependencies } = {}) => {
      return state.$getItem({
        resource: 'statistics',
        query,
        dependencies,
        fetch: () => state.fetchClientStatistics({ query })
      })
    }
  },
  actions: {
    fetchClient () {
      return this.$fetchItem({
        endpoint: '/api/v1/client',
        resource: 'client',
        model: Client
      }).then(client => {
        store.commit('client/setClient', client)
        return client
      })
    },
    fetchClientStatistics ({ query }) {
      return this.$fetchItem({
        endpoint: '/api/v1/client/statistics',
        resource: 'statistics',
        query
      })
    }
  }
})