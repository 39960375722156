<template>
  <div>
    <p
      v-if="invite"
      v-html="t('$block_project_invite.project_owner_invites_you_to_join_project_title', { project_owner: `<strong class='bold'>${invite.inviter.firstname}</strong>`, project_title: `<strong class='bold'>${invite.target.name}</strong>` })"
    />

    <div
      class="d-flex justify-content-center padding__tb--12"
      v-else-if="loader"
    >
      <app-spinner v-if="loader" />
    </div>
  </div>
</template>

<script setup>
import { toRefs, inject, defineAsyncComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useNotificationStore } from '@/stores/NotificationStore'
import { useProjectStore } from '@/stores/ProjectStore'
import { useModule } from '@/composables/app/useModule'
import { useProcedure, useProcedureNavigation } from '@/composables/modules/procedures'
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'
import { whenever } from '@/utils/utils'

const AppSpinner = defineAsyncComponent(() => import('@/components/atoms/AppSpinner'))

const props = defineProps({ block: { type: Object, required: true } })

const { block } = toRefs(props)

const procedure = inject('procedure')
const procedure_execution = inject('procedure_execution')
const is_loading = inject('is_loading')

const { t } = useI18n()
const { query } = useRoute()
const { postModalNotification } = useNotificationStore()
const { getInvite } = useProjectStore()
const { targetId } = useProcedure({ procedure })
const { nextRedirection } = useProcedureNavigation({ procedure, procedure_execution })
const { model } = useModule({ component: block, parent: procedure_execution })

model.value = _get(query, 'invite_token')

const isReady = computed(() => !is_loading.value)

const { invite, loader, error } = getInvite({ id: targetId.value, token: model.value, dependencies: [isReady] })

whenever(error, () => postModalNotification({ title: 'errors.an_error_occurred', text: error.value?.status === 404 ? 'errors.project_invitation_not_found' : '', close: nextRedirection }))
</script>