<template>
  <footer class="green-bckg">
    <div class="footer">
      <div class="footer__logo">
        <router-link
          :to="{ name: 'Home', params: { locale: $i18n.locale } }"
          v-show="client.logo.url"
        >
          <img
            v-if="client.logo"
            :src="client.logo.url"
            :alt="client.name"
          >
        </router-link>
      </div>

      <div
        class="networks"
        v-if="column_2.website || socialNetworks.length > 0"
      >
        <div
          class="title"
          v-if="$i18n.translate(column_2.website.text)"
        >
          <a
            class="title link"
            :href="$i18n.translate(column_2.website.url)"
            target="_blank"
          >
            {{ $i18n.translate(column_2.website.text) }}
          </a>
        </div>

        <ul
          class="networks"
          v-if="socialNetworks.length > 0"
        >
          <li
            class="networks__item"
            v-for="(network, i) in socialNetworks"
            :key="'footer_menu_network_' + i"
          >
            <a
              class="networks__link link"
              :href="network.url[$i18n.locale]"
              target="_blank"
            >
              <fa-icon
                v-if="network.icon"
                size="lg"
                :icon="network.icon"
              />
            </a>
          </li>
        </ul>
      </div>

      <div
        class="contact"
        v-if="column_3.address.length > 0 || column_3.contact.length > 0"
      >
        <h3
          class="title"
          v-if="$i18n.translate(column_3.name)"
        >
          {{ $i18n.translate(column_3.name) }}
        </h3>

        <address v-if="column_3.address && column_3.address.length > 0">
          <template
            v-for="({ line }, i) in column_3.address"
            :key="'footer_address_line_' + i"
          >
            <span>{{ $i18n.translate(line) }}<br></span>
          </template>
        </address>

        <address
          v-if="column_3.contact && column_3.contact.length > 0"
        >
          <template v-for="(item, index) in contactInfos">
            <a
              class="footer__infos-link d-block link"
              :key="'footer_infos_line_' + index"
              :href="item.url"
              v-if="item.url"
              target="_blank"
            >
              {{ item.text }}
            </a>

            <span
              v-else
              class="footer__infos-text d-block"
              :key="'footer_infos_line_' + index"
            >
              {{ item.text }}
            </span>
          </template>
        </address>
      </div>

      <ul
        class="sponsors"
        v-if="column_4.sponsors.length > 0"
      >
        <li
          class="sponsors__logo"
          v-for="sponsor in column_4.sponsors"
          :key="sponsor.index"
        >
          <img
            v-if="$i18n.translate(sponsor.logo)"
            :src="$i18n.translate(sponsor.logo)"
            :alt="$i18n.translate(sponsor.alt)"
          >

          <span
            v-else
            v-html="$i18n.translate(sponsor.name)"
          />
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'

export default defineComponent({
  name: 'CustomFooterBaluchon',
  computed: {
    ...mapGetters({
      client: 'client/client'
    }),
    logo () {
      return _get(this.section, 'attributes.logo')
    },
    socialNetworks () {
      return _get(this.client, 'platform.social_networks', []).filter(link => link.url[this.$i18n.locale])
    },
    column_1 () {
      return _get(this.section, 'attributes.column_1', [])
    },
    column_2 () {
      return _get(this.section, 'attributes.column_2', [])
    },
    column_3 () {
      return _get(this.section, 'attributes.column_3', [])
    },
    contactInfos () {
      return _get(this.column_3, 'contact', [])
        .map(info => {
          return {
            text: this.$i18n.translate(info.text),
            url: info.type !== 'text' ? (info.type === 'phone_number' ? 'tel:' : 'mailto:') + this.$i18n.translate(info.text).replace(/ /g, '') : null
          }
        })
        .filter(info => info.text)
    },
    column_4 () {
      return _get(this.section, 'attributes.column_4', [])
    }
  },
  props: {
    section: {
      type: Object,
      required: true
    }
  }
})
</script>

<style lang="scss" scoped>
  $color-principal-dark: #750727;
  $color-principal-neutral: #d50d47;
  $color-secondary-dark: #719419;
  $color-secondary-neutral: #92c020;
  $color-secondary-light: #e7efd3;

  footer {
    background-color: $color-secondary-light;
    font-size: 16px;

    @include mq(md){
      font-size: 20px;
    }
  }

  .footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    margin: 0 auto;
    padding: 25px 15px;
    justify-items: center;
    row-gap: 30px;

    @include mq(sm) {
      grid-template-rows: initial;
      row-gap: initial;
      justify-items: initial;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__logo {
      text-align: center;
      margin: auto;
      max-width: 172px;

      @include mq(sm) {
        text-align: left;
        flex: 0 0 25%;
      }

      & img {
        min-height: 65px;
        width: 100%;
      }
    }
  }

  .contact {
    grid-column: 1/-1;

    @include mq(sm) {
      grid-column: initial;
      flex: 0 0 25%;
      margin: 0 auto;
    }
  }

  .sponsors {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    grid-column: 1/-1;

    @include mq(sm) {
      grid-column: initial;
      flex: 0 0 35%;
      margin: 0 auto;
    }

    &__logo {
      display: inline;
      margin: 5px;
      height: 55px;

      @include mq(xl) {
      flex: 1 1 25%;
      text-align: center;
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        height: 65px;
      }

      & img {
        height: 100%;
        width: auto;
      }
    }
  }

  .title {
    font-weight: 700;
    margin-bottom: 25px;
  }

  .link {
    transition: color $transition__duration;

    &:hover, &:focus {
      color: $color-principal-neutral;
    }

    &:active {
      color: $color-principal-dark;
    }
  }

  .networks {
    @include mq(sm){
      flex: 0 0 15%;
    }
    &__ul {
      display: flex;
      align-items: center;
    }

    &__li:not(:first-of-type) {
      margin-left: .8em;
    }

    &__a {
      transition: all .3s;
    }

    &__span {
      display: none;
    }
  }
</style>
