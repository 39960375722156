<template>
  <section
    class="section"
    :style="sectionStyle"
  >
    <div class="container">
      <div
        class="editor editor__content editor__page margin__b--36"
        v-if="$i18n.translate(attributes.text)"
        v-html="$i18n.translate(attributes.text)"
      />

      <section>
        <app-slides :options="options">
          <article
            class="glider__slide"
            v-for="(sponsor, index) in sponsors"
            :key="'sponsor_' + index"
          >
            <a
              class="glider__link"
              :class="{'cursor--default': !$i18n.translate(sponsor.website)}"
              target="_blank"
              :href="$i18n.translate(sponsor.website)"
              v-if="$i18n.translate(sponsor.website)"
            >
              <img
                class="glider__img"
                :src="$i18n.translate(sponsor.logo)"
                :alt="$i18n.translate(sponsor.name)"
              >
            </a>

            <img
              v-else
              class="glider__img"
              :src="$i18n.translate(sponsor.logo)"
              :alt="$i18n.translate(sponsor.name)"
            >
          </article>
        </app-slides>
      </section>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import AppSlides from '&/organisms/AppSlides'
import { useSectionStyle } from '@/composables/modules/pages'

export default defineComponent({
  name: 'SectionSponsors',
  components: {
    AppSlides
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  setup: props => ({ ...useSectionStyle(props) }),
  data () {
    return {
      options: {
        slidesToShow: 1,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 2
            }
          }
        ]
      }
    }
  },
  computed: {
    attributes () {
      return this.section.attributes
    },
    style () {
      return this.section.style
    },
    sponsors () {
      return this.attributes.sponsors.filter(sponsor => this.$i18n.translate(sponsor.logo))
    }
  }
})
</script>

<style lang="scss" scoped>
.section {
  padding: $padding__base 0;

  @include mq(md) {
    padding: $padding__base * 3 0;
  }
}

.glider {
  &__link {
    display: block;
  }

  &__slide {
    display: inline-block;
    vertical-align: middle;
    padding: 0 $padding__base;
  }
}
</style>
