export default [
  {
    name: 'custom_footer_designmuseumgent',
    path: 'modules/pages/designmuseumgent/FooterDesignmuseumgent'
  },
  {
    name: 'custom_header_designmuseumgent',
    path: 'modules/pages/designmuseumgent/HeaderDesignmuseumgent'
  },
  {
    name: 'homepage_designmuseumgent',
    path: 'modules/pages/designmuseumgent/HomepageDesignmuseumgent'
  }
]
