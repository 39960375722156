import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useProcedure } from '@/composables/modules/procedures'
import { extractNumbers } from '@/utils/utils'
import { get as _get } from 'lodash'

export function useProcedureStep ({ procedure }) {
  const route = useRoute()
  const { steps } = useProcedure({ procedure })

  const step_index = extractNumbers(route.params.stepSlug || 'step-1')

  const stepsLength = computed(() => steps.value.length)
  const step = computed(() => _get(steps.value, `${[step_index - 1]}`))

  const isFirstStep = computed(() => step_index === 1)
  const isBeforeLastStep = computed(() => stepsLength.value - 1 === step_index)
  const isLastStep = computed(() => stepsLength.value === step_index)
  const isInexistentStep = computed(() => step_index > stepsLength.value || step_index < 1)
  const stepContainsCustomFields = computed(() => step.value.sections.has_custom_fields)
  const backButton = computed(() => _get(step.value, 'attributes.back_btn'))
  const nextButton = computed(() => _get(step.value, 'attributes.next_btn'))

  return {
    step_index,
    step,
    isFirstStep,
    isBeforeLastStep,
    isLastStep,
    isInexistentStep,
    stepContainsCustomFields,
    backButton,
    nextButton
  }
}