import { get as _get, set as _set } from 'lodash'

export default {
  setRouterError (state, boolean) {
    state.is_router_error = boolean
  },
  setItem (state, { resource, id, item }) {
    _set(state, `${resource}.${id}`, item)
  },
  deleteItem (state, { resource, id }) {
    delete _get(state, resource, {})[id]
  },
  deleteItems (state, { resource }) {
    _set(state, resource, {})
  }
}
