import { createPinia } from 'pinia'
import { resetStore, actionsStore, gettersStore } from '@/stores/plugins'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const pinia = createPinia()

pinia.use(resetStore)
pinia.use(actionsStore)
pinia.use(gettersStore)
pinia.use(piniaPluginPersistedstate)

export default pinia
