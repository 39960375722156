<template>
  <div class="custom">
    <section class="banner">
      <div class="wrapper wrapper--smallest">
        <figure
          v-if="$i18n.translate(banner.map)"
          class="banner__map"
          :style="{ backgroundImage: `url(${ $i18n.translate(banner.map) })`}"
        />

        <article class="banner__content">
          <img
            :src="$i18n.translate(banner.logo.url)"
            :alt="$i18n.translate(banner.logo.alt)"
            v-if="$i18n.translate(banner.logo.url)"
            class="banner__logo"
          >

          <h1
            class="title title-h1"
            v-if="$i18n.translate(banner.title)"
          >
            {{ $i18n.translate(banner.title) }}
          </h1>

          <div
            class="banner__text editor__content"
            v-if="$i18n.translate(banner.text)"
            v-html="$i18n.translate(banner.text)"
          />

          <button
            v-if="$i18n.translate(banner.button)"
            class="cta cta--primary banner__button"
            @click.prevent="scrollTo('#campaign', 0)"
          >
            {{ $i18n.translate(banner.button) }}

            <fa-icon
              class="cta__icon margin__l--12"
              :icon="['fas', 'chevron-down']"
              aria-hidden="true"
            />
          </button>
        </article>
      </div>
    </section>

    <section
      class="stats"
      v-if="statistics"
    >
      <div class="wrapper wrapper--smallest">
        <ul class="counter">
          <li
            class="counter__item"
            v-if="$i18n.translate(stats.labels.collected_amount)"
          >
            <span class="counter__label">{{ $i18n.translate(stats.labels.collected_amount) }}</span>
            <strong class="counter__value bold">
              <app-money :amount="statistics.current_amount" />
            </strong>
          </li>

          <li
            class="counter__item"
            v-if="$i18n.translate(stats.labels.still_needed)"
          >
            <span class="counter__label">{{ $i18n.translate(stats.labels.still_needed) }}</span>
            <strong class="counter__value bold">
              <app-money :amount="statistics.goal_amount - statistics.current_amount" />
            </strong>
          </li>

          <li
            class="counter__item"
            v-if="$i18n.translate(stats.labels.goal_amount)"
          >
            <span class="counter__label">{{ $i18n.translate(stats.labels.goal_amount) }}</span>
            <strong class="counter__value bold">
              <app-money :amount="statistics.goal_amount" />
            </strong>
          </li>
        </ul>

        <div class="progress-bar margin__t--36">
          <div
            class="progress-bar__current"
            :style="{ width: `${ statistics.percentage }%` }"
          >
            <span class="progress-bar__label">{{ statistics.percentage }}%</span>
          </div>
        </div>
      </div>
    </section>


    <section
      class="campaign"
      id="campaign"
    >
      <header class="wrapper wrapper--smallest">
        <h2
          class="title title-h2"
          v-if="$i18n.translate(campaign.title)"
        >
          {{ $i18n.translate(campaign.title) }}
        </h2>

        <div
          class="campaign__text editor__content"
          v-if="$i18n.translate(campaign.text)"
          v-html="$i18n.translate(campaign.text)"
        />

        <ul class="campaign__legend legend margin__t--24">
          <li
            v-if="$i18n.translate(campaign.legend.open)"
            class="legend__item legend__item--yellow"
          >
            {{ $i18n.translate(campaign.legend.open) }}
          </li>

          <li
            v-if="$i18n.translate(campaign.legend.close)"
            class="legend__item legend__item--blue"
          >
            {{ $i18n.translate(campaign.legend.close) }}
          </li>

          <li v-if="$i18n.translate(campaign.legend.tax_certificate)">
            {{ $i18n.translate(campaign.legend.tax_certificate) }}
          </li>
        </ul>
      </header>

      <div
        class="campaign__hospital"
        ref="hospital"
        id="hospital"
      >
        <hospital-lightfortheworld
          :show-modal="showRoomModal"
          :is-funded="isRoomFunded"
          :is-showed="isRoomShowed"
        />
      </div>

      <app-modal
        v-show="modal.show"
        @close="modal.show = false"
      >
        <template #body>
          <header class="room__header">
            <h3
              class="bold"
              v-if="$i18n.translate(modal.room.name)"
            >
              {{ $i18n.translate(modal.room.name) }}
            </h3>
          </header>

          <figure
            class="room__slider thumb"
            ref="slider"
          >
            <template v-if="slides.length > 0">
              <div
                class="room__slides"
                :style="{ width: `${ clonedSlides.length * slider.width }px`, left: `${ -slider.width * ((clonedSlides.length - slides.length) / 2) }px`, transform: `translateX(${ -slider.index * slider.width }px)` }"
                :class="{'is-animated': slider.has_transition }"
              >
                <div
                  class="room__slide"
                  v-for="(image, index) in clonedSlides"
                  :style="{ backgroundImage: `url(${ $i18n.translate(image.url) })`, width: `${slider.width}px` }"
                  :key="`modal_slide_${index}`"
                />
              </div>

              <nav
                class="room__nav"
                v-if="slides.length > 1"
              >
                <button
                  class="room__nav--prev"
                  @click="goToPrev()"
                  aria-label="Go to prev slide"
                >
                  <fa-icon
                    :icon="['fas', 'chevron-left']"
                    aria-hidden="true"
                  />
                </button>

                <button
                  class="room__nav--next"
                  @click="goToNext()"
                  aria-label="Go to next slide"
                >
                  <fa-icon
                    :icon="['fas', 'chevron-right']"
                    aria-hidden="true"
                  />
                </button>
              </nav>
            </template>

            <fa-icon
              :icon="['far', 'image']"
              v-else
            />
          </figure>

          <div
            class="room__content"
            :class="{'with-button': modal.room.show_button && $i18n.translate(campaign.modal.button)}"
          >
            <div
              class="room__text editor__content"
              v-if="$i18n.translate(modal.room.text)"
              v-html="$i18n.translate(modal.room.text)"
            />

            <div
              class="progress-bar room__progress-bar margin__t--48"
              v-if="modal.room.show_progressbar && modal.room.goal_amount"
            >
              <div
                class="progress-bar__current"
                :style="{ width: `${modal.room.percentage}%` }"
              >
                <div
                  class="progress-bar__label"
                  :class="{'is-funded': modal.room.percentage >= 100 }"
                >
                  <span v-if="modal.room.percentage >= 100">
                    {{ $i18n.translate(campaign.modal.already_funded) }}
                  </span>

                  <app-money
                    :amount="modal.room.current_amount"
                    :class="{ bold: modal.room.percentage >= 100 }"
                  />
                </div>
              </div>

              <span class="progress-bar__goal">
                <app-money :amount="modal.room.goal_amount" />
              </span>
            </div>

            <router-link
              :to="{ name: 'ClientProcedure', params: { procedureSlug: modal.room.procedure_slug } }"
              class="cta cta--primary room__button"
              v-if="modal.room.show_button && $i18n.translate(campaign.modal.button)"
            >
              {{ $i18n.translate(campaign.modal.button) }}

              <fa-icon
                class="cta__icon margin__l--12"
                :icon="['fas', 'chevron-right']"
                aria-hidden="true"
              />
            </router-link>
          </div>
        </template>
      </app-modal>

      <footer
        class="align--center wrapper wrapper--smallest campaign__footer"
        v-if="campaign.cta && $i18n.translate(campaign.cta.text) && campaign.cta.show"
      >
        <button
          class="cta cta--alt cta--secondary"
          @click.prevent="setRouteNavigation(campaign.cta)"
        >
          {{ $i18n.translate(campaign.cta.text) }}

          <fa-icon
            :icon="['fal', 'external-link-square']"
            class="cta__icon margin__l--12"
            v-if="campaign.cta.type === 'custom'"
          />
        </button>
      </footer>
    </section>

    <article class="why">
      <div class="wrapper wrapper--smallest">
        <header v-if="$i18n.translate(why.title)">
          <h2 class="title title-h2">
            {{ $i18n.translate(why.title) }}
          </h2>
        </header>

        <div class="why__content">
          <figure class="thumb why__image">
            <img
              :src="$i18n.translate(why.image.url)"
              :alt="$i18n.translate(why.image.text)"
              v-if="$i18n.translate(why.image.url)"
            >

            <fa-icon
              :icon="['far', 'image']"
              v-else
            />
          </figure>

          <div
            class="why__text editor__content"
            v-if="$i18n.translate(why.text)"
            v-html="$i18n.translate(why.text)"
          />
        </div>

        <footer
          class="align--center wrapper wrapper--smallest why__footer"
          v-if=" $i18n.translate(why.button)"
        >
          <button
            class="cta cta--secondary"
            @click.prevent="scrollTo('#campaign', 0)"
          >
            {{ $i18n.translate(why.button) }}
          </button>
        </footer>
      </div>
    </article>

    <section
      class="video"
      id="video"
      v-if="$i18n.translate(video.url)"
    >
      <header
        class="wrapper wrapper--smallest video__header"
        v-if="$i18n.translate(video.title)"
      >
        <h2 class="title title-h2 color--white">
          {{ $i18n.translate(video.title) }}
        </h2>

        <div
          class="video__text editor__content"
          v-if="$i18n.translate(video.text)"
          v-html="$i18n.translate(video.text)"
        />
      </header>

      <div class="wrapper">
        <app-video-embed :src="$i18n.translate(video.url)" />

        <footer
          class="align--center video__footer"
          v-if="$i18n.translate(video.button)"
        >
          <button
            class="cta cta--primary"
            @click.prevent="scrollTo('#campaign', 0)"
            v-if="$i18n.translate(video.button)"
          >
            {{ $i18n.translate(video.button) }}
          </button>
        </footer>
      </div>
    </section>

    <article class="about">
      <div class="wrapper about__wrapper">
        <header class="about__header">
          <h2
            class="title title-h3"
            v-if="$i18n.translate(about.title)"
          >
            {{ $i18n.translate(about.title) }}
          </h2>
        </header>

        <div class="about__content">
          <div
            class="about__text editor__content"
            v-if="$i18n.translate(about.text)"
            v-html="$i18n.translate(about.text)"
          />

          <button
            v-if="$i18n.translate(about.button)"
            class="cta cta--secondary about__button"
            @click.prevent="scrollTo('#campaign', 0)"
          >
            {{ $i18n.translate(about.button) }}

            <fa-icon
              class="cta__icon margin__l--12"
              :icon="['fas', 'chevron-up']"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'
import { isPast, isFuture } from 'date-fns'
import { scrollTo } from '@/utils/utils'
import HospitalLightfortheworld from './HospitalLightfortheworld'

export default {
  name: 'CustomLightfortheworldKananga',
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  components: {
    HospitalLightfortheworld,
    AppVideoEmbed: defineAsyncComponent(() => import('&/atoms/AppVideoEmbed')),
    AppMoney: defineAsyncComponent(() => import('&/atoms/AppMoney')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal'))
  },
  setup: () => ({ scrollTo }),
  mixins: [
    PageRouteNavigation
  ],
  data () {
    return {
      projects: [],
      modal: {
        show: false,
        room: {}
      },
      slider: {
        index: 0,
        width: 448,
        has_transition: true,
        is_animating: false
      }
    }
  },
  created () {
    this.fetchProjects({ query: { campaign_id: this.campaign.id || [], per_page: 50 } })
      .then(projects => this.projects = projects.items)
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)

    this.$nextTick(() => {
      this.$refs.hospital.scrollTo(this.$refs.hospital.clientWidth / 2, 0)

      if (this.$route.hash) this.scrollTo(this.$route.hash, 10)
    })
  },
  computed: {
    ...mapGetters({
      getProjects: 'projects/getProjects'
    }),
    attributes () {
      return _get(this.section, 'attributes', {})
    },
    banner () {
      return _get(this.attributes, 'banner', {})
    },
    stats () {
      return _get(this.attributes, 'stats', {})
    },
    campaign () {
      return _get(this.attributes, 'campaign', {})
    },
    rooms () {
      return _get(this.campaign, 'rooms', [])
        .map(room => {
          const project = this.projects.find(project => room.project_id.includes(project.id))
          if (!project) return null

          const procedure = project.procedures.find(procedure => procedure.category === 'donation')
          const percentage = parseInt(procedure.goal_amount ? (procedure.current_amount / procedure.goal_amount) * 100 : 0);
          let show_button = room.show_button

          if (show_button) {
            if (this.isDisableProcedure(procedure)) {
              show_button = false
            } else if (percentage >= 100) {
              show_button = false
            }
          }

          return {
            ...room,
            goal_amount: procedure.goal_amount,
            current_amount: procedure.current_amount,
            procedure_slug: procedure.slug,
            percentage,
            show_button
          }
        })
        .filter(room => room && !!room.show)
    },
    why () {
      return _get(this.attributes, 'why', {})
    },
    video () {
      return _get(this.attributes, 'video', {})
    },
    about () {
      return _get(this.attributes, 'about', {})
    },
    slides () {
      return _get(this.modal, 'room.images', [])
    },
    clonedSlides () {
      const slides = [...this.slides]

      slides.unshift(this.slides[this.slides.length - 1])
      slides.push(this.slides[0])

      return slides
    },
    statistics () {
      let goal_amount = 0
      let current_amount = 0

      this.projects.forEach(project => {
        const procedure = project.procedures.find(procedure => procedure.category === 'donation')

        goal_amount += procedure.goal_amount
        current_amount += procedure.current_amount
      })

      return {
        current_amount,
        goal_amount,
        percentage: parseInt((current_amount / goal_amount) * 100)
      }
    }
  },
  methods: {
    ...mapActions({
      fetchProjects: 'projects/fetchProjects'
    }),
    isDisableProcedure (procedure) {
      return isFuture(new Date(procedure.start_date)) || (!!procedure.end_date && isPast(new Date(procedure.end_date)))
    },
    showRoomModal (index) {
      const room = this.rooms.find(room => room.index === index)

      if (!room) return

      this.modal = { show: true, room }

      this.$nextTick(() => this.slider.width = this.$refs.slider.clientWidth)
    },
    isRoomFunded (index) {
      const room = this.rooms.find(room => room.index === index)

      return room ? room.percentage >= 100 : false
    },
    isRoomShowed (index) {
      const room = this.rooms.find(room => room.index === index)

      return room ? room.show : false
    },
    handleResize () {
      if (this.modal.show && this.$refs.slider && this.slides.length)
        this.slider.width = this.$refs.slider.clientWidth
    },
    goToPrev () {
      if (this.slider.is_animating) return

      this.slider.is_animating = true

      if (this.slider.index <= 0) {
        this.slider.has_transition = false
        this.slider.index = this.slides.length

        setTimeout(() => {
          this.slider.is_animating = false
          this.goToPrev()
        })
      } else {
        this.slider.has_transition = true
        this.slider.index--

        setTimeout(() => this.slider.is_animating = false, 300)
      }
    },
    goToNext () {
      if (this.slider.is_animating) return

      this.slider.is_animating = true

      if (this.slider.index >= this.slides.length - 1) {
        this.slider.has_transition = false
        this.slider.index = -1

        setTimeout(() => {
          this.slider.is_animating = false
          this.goToNext()
        })
      } else {
        this.slider.has_transition = true
        this.slider.index++

        setTimeout(() => this.slider.is_animating = false, 300)
      }
    }
  },
  unmounted () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style lang="scss" scoped>
$color--primary: #FFDD1C;
$color--secondary: #050D39;
.wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;

  @include mq(sm) {
    padding: 0 30px;
  }

  @include mq(md) {
    padding: 0 60px;

    &--largest {
       padding: 0 30px;
       max-width: 1500px;
    }
  }

  &--smallest {
    @include mq(md) {
      max-width: 1080px;
    }
  }
}

.title {
  line-height: 1.25;
  font-weight: 800;

  &::after {
    content: '';
    display: block;
    background: $color--primary;
    width: 80px;
    height: 6px;
    margin-top: $margin__base * 2;
  }

  &-h1 {
    font-size: rem(36px);

    @include mq(sm) {
      font-size: rem(48px);
    }

    @include mq(md) {
      font-size: rem(60px);
    }

    &::after {
      height: 10px;
    }
  }

  &-h2 {
    font-size: rem(28px);

    @include mq(sm) {
      font-size: rem(36px);
    }

    @include mq(md) {
      font-size: rem(48px);
    }
  }

  &-h3 {
    font-size: rem(28px);

    @include mq(sm) {
      font-size: rem(36px);
    }
  }
}

.editor__content {
  color: #000000;

  & :deep() {
    strong a {
      font-weight: 700;
    }
  }
}

.cta {
  padding: calc($padding__base * 1.3) calc($padding__base * 1.5);
  font-size: rem(16px);
  font-weight: 700;
  transition: all .2s;
  border-radius: 4px;

  @include mq(sm) {
    font-size: rem(18px);
  }

  &--alt {
    padding: calc($padding__base / 2) $padding__base;

    @include mq(sm) {
      font-size: rem(16px);
    }
  }

  &__icon {
    font-size: rem(14px);
  }

  &--primary {
    background: $color--primary;
    color: #111;

    &:focus,
    &:hover {
      background-color: shade($color--primary);
    }
  }

  &--secondary {
    background: $color--secondary;
    color: $white;

    &:focus,
    &:hover {
      background-color: shade($color--secondary);
    }
  }
}

.thumb {
  position: relative;
  font-size: rem(48px);

  @include mq(sm) {
    font-size: rem(72px);
  }

  svg.fa-image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.progress-bar {
  width: 100%;
  height: 5px;
  border-radius: 3px;
  position: relative;
  background: rgba(#fff, 0.25);

  &__current {
    position: relative;
    border-radius: 3px;
    background: $color--primary;
    height: 100%;
    max-width: 100%;
    text-align: right;
  }

  &__label {
    display: block;
    position: relative;
    top: 9px;
    color: $color--primary;

    &.is-funded {
      display: flex;
      justify-content: space-between;
    }
  }

  &__goal {
    position: absolute;
    right: 0;
    top: 9px;
    color: $white;
  }
}

.banner {
  background: rgba(148, 191, 211, 0.5);;
  position: relative;
  margin-top: -101px;

  @include mq(md) {
    margin-top: -169px;
  }

  &__map {
    background-repeat: no-repeat;
    background-size: auto 100%;
    z-index: 0;

    @include aspect-ratio(842, 741);

    @include mq(md) {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-position: right 0 bottom 17px;

      &::before {
        display: none;
      }
    }
  }

  &__content {
    position: relative;
    z-index: 10;
    margin-top: -70px;
    padding-bottom: calc($padding__base * 4);

    @include mq(sm) {
      margin-top: -240px;
      padding-bottom: calc($padding__base * 7);
    }

    @include mq(md) {
      margin: 0;
      padding-top: 262px;
      padding-bottom: calc($padding__base * 9);
    }
  }

  &__logo {
    margin-bottom: calc($margin__base * 2);
    max-width: 180px;
    height: auto;

    @include mq(sm) {
      max-width: 214px;
      margin-bottom: calc($margin__base * 3);
    }
  }

  &__text {
    max-width: 684px;
    line-height: 1.5;
    margin-top: calc($margin__base * 2);

    @include mq(sm) {
      margin-top: calc($margin__base * 4);
      font-size: rem(18px);
    }
  }

  &__button {
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
  }
}

.stats {
  background: rgba($color--secondary, 0.98);
  padding: calc($padding__base * 5) 0;

  @include mq(sm) {
    padding: calc($padding__base * 8) 0;
  }

  .counter {
    color: $white;
    text-align: center;

    @include mq(sm) {
      display: flex;
      justify-content: space-between;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: calc($margin__base * 2);

        @include mq(sm) {
          margin: 0 calc($margin__base * 2) 0 0;
        }
      }
    }

    &__label {
      text-transform: uppercase;
      display: block;
    }

    &__value {
      font-size: rem(18px);
      display: block;
    }
  }
}

.campaign {
  padding: calc($padding__base * 4) 0;

  @include mq(sm) {
    padding: calc($padding__base * 6) 0;
  }

  &__text {
    max-width: 565px;
    line-height: 1.5;
    margin-top: calc($margin__base * 2);

    @include mq(sm) {
      margin-top: calc($margin__base * 4);
      font-size: rem(18px);
    }
  }

  &__hospital {
    padding: $padding__base calc($padding__base * 2);
    overflow-x: auto;

    @include mq(sm) {
      margin-top: calc($margin__base * -2.5);
    }

    @include mq(sm) {
      margin-top: calc($margin__base * -6);
    }

    svg {
      min-width: 920px;

      @include mq(sm) {
        max-width: 1440px;
        max-height: calc(100vh - 168px);
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &__footer {
    margin-top: calc($margin__base * 2);

    @include mq(md) {
      margin-top: calc($margin__base * -2);
    }
  }
  .legend {
    font-size: rem(14px);

    &__item {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: $margin__base;
      }

      &--blue, &--yellow {
        &::before {
          content: '';
          width: 24px;
          height: 24px;
          display: inline-block;
          margin-right: $margin__base;
          border-radius: 50%;
        }
      }

      &--blue {
        font-size: rem(18px);
        &::before {
          background: rgba($color--secondary, .98);
        }
      }

      &--yellow {
        font-size: rem(18px);
        &::before {
          background: $color--primary;
        }
      }
    }
  }
  .room {
    &__header {
      padding: calc($padding__base * 1.5) calc($padding__base * 2);
      font-size: rem(18px);
      min-height: 27px;
    }

    &__slider {
      background: $color--primary;
      position: relative;
      overflow: hidden;

      @include aspect-ratio(440, 296);
    }

    &__slides {
      display: flex;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      &.is-animated {
        transition: .3s ease-in-out;
      }
    }

    &__slide {
      position: relative;
      height: 100%;
      width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &__nav {
      display: flex;
      justify-content: space-between;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      font-size: rem(20px);
      color: $white;
      padding: 0 $padding__base;

      svg {
        transition: .2s all;
        opacity: 0.7;
      }

      &:hover {
        svg {
          opacity: 1;
        }
      }

      &--prev {
        &:hover {
          svg {
            transform: translateX(-6px);
          }
        }
      }

      &--next {
        &:hover {
          svg {
            transform: translateX(6px);
          }
        }
      }
    }

    &__content {
      padding: calc($padding__base * 1.5) calc($padding__base * 2) calc($padding__base * 3);

      &.with-button {
        padding: calc($padding__base * 1.5) calc($padding__base * 2) calc($padding__base * 5);
      }
    }

    &__text {
      @include mq(sm) {
        font-size: rem(18px);
      }
    }

    &__progress-bar {
      background: rgba($color--secondary, 0.25);
      font-size: rem(14px);

      .progress-bar {
        &__label {
          transform: translateY(-100%);
          top: -3px;
          color: #111;
        }

        &__goal {
          top: 6px;
          color: #111;
        }
      }
    }

    &__button {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 50%);
      white-space: nowrap;

      &:hover {
        svg {
          transform: translateX(6px);
        }
      }

      svg {
        transition: all .2s;
      }
    }
  }

  & :deep() {
    .modal {
      &__mask {
        background: rgba($color--secondary, .58);
      }

      &__container {
        max-width: 448px;
        border-radius: 7px;
        border: 2px solid $color--primary;
        box-shadow: 0px 4px 12px rgba(17, 17, 17, 0.25);
        overflow: visible;
      }

      &__btn--close {
        font-size: rem(24px);
        top: 14px;
      }
    }
  }
}

.why {
  padding: calc($padding__base * 4) 0;
  background: rgba($color--primary, 0.33);

  @include mq(sm) {
    padding: calc($padding__base * 6) 0;
  }

  &__content {
    overflow: hidden;
    margin-top: calc($margin__base * 2);

    @include mq(sm) {
      margin-top: calc($margin__base * 4);
      font-size: rem(18px);
      max-width: 920px;
    }
  }

  &__image {
    background: $color--primary;
    margin-bottom: calc($margin__base * 2);
    width: 100%;

    @include aspect-ratio(566, 354);

    @include mq(sm) {
      max-width: 50%;
      float: left;
      margin: 0 calc($margin__base * 2) calc($margin__base * 2) 0;
    }

    @include mq(md) {
      width: 566px;
      max-width: 100%;
    }

    img {
      position: absolute;
      width: 100%;
      height: auto;
      top: 0;
      left: 0;
    }
  }

  &__footer {
    margin-top: calc($margin__base * 2);

    @include mq(sm) {
      margin-top: calc($margin__base * 4);
    }
  }
}

.video {
  background: rgba($color--secondary, 0.98);
  padding: calc($padding__base * 4) 0;

  @include mq(sm) {
    padding: calc($padding__base * 6) 0;
  }

  &__header {
    margin-bottom: calc($margin__base * 2);

    @include mq(sm) {
      margin-bottom: calc($margin__base * 4);
    }
  }

  &__text {
    margin-top: calc($margin__base * 2);
    color: $white;
    max-width: 684px;

    @include mq(sm) {
      margin-top: calc($margin__base * 4);
      font-size: rem(18px);
    }
  }

  &__footer {
    margin-top: calc($margin__base * 2);

    @include mq(sm) {
      margin-top: calc($margin__base * 4);
    }
  }
}

.about {
  padding: calc($padding__base * 4) 0;

  @include mq(sm) {
    padding: calc($padding__base * 6) 0;
  }

  &__wrapper {
    @include mq(sm) {
      display: flex;
    }
  }

  &__header {
    margin-bottom: calc($margin__base * 2);
    flex: 0 0 auto;

    @include mq(sm) {
      width: 210px;
      margin: 0 0 calc($margin__base * 2);
    }

    @include mq(md) {
      width: 270px;
      padding-left: calc($padding__base * 5);
    }
  }

  &__content {
    max-width: 684px;
  }
}
</style>
