export default [
  {
    name: 'custom_header_ilot',
    path: 'modules/pages/ilot/HeaderIlot'
  },
  {
    name: 'custom_footer_ilot',
    path: 'modules/pages/ilot/FooterIlot'
  },
  {
    name: 'crowdfunding_ilot',
    path: 'modules/pages/ilot/CrowdfundingIlot'
  }
]
