import i18n from '@/vendors/i18n'

const projects_overview_options = [
  {
    label:i18n.getLocalesTranslations('literal.latest_created_first'),
    value: 'created_at_first',
    query: {
      'sort_by_field[field]': 'created_at',
      'sort_by_field[direction]': 'desc'
    }
  },
  {
    label:i18n.getLocalesTranslations('literal.latest_created_last'),
    value: 'created_at_last',
    query: {
      'sort_by_field[field]': 'created_at',
      'sort_by_field[direction]': 'asc'
    }
  },
  {
    label:i18n.getLocalesTranslations('literal.most_collected_first'),
    value: 'amount_first',
    query: {
      'sort_by_field[field]': 'current_amount',
      'sort_by_field[direction]': 'desc'
    }
  },
  {
    label:i18n.getLocalesTranslations('literal.less_collected_first'),
    value: 'amount_last',
    query: {
      'sort_by_field[field]': 'current_amount',
      'sort_by_field[direction]': 'asc'
    }
  },
  {
    label:i18n.getLocalesTranslations('literal.ending_soon_first'),
    value: 'end_date_first',
    query: {
      'sort_by_field[field]': 'end_date',
      'sort_by_field[direction]': 'asc'
    }
  },
  {
    label:i18n.getLocalesTranslations('literal.most_contributors_first'),
    value: 'most_contributors_first',
    query: {
      'sort_by_field[field]': 'contributors_count',
      'sort_by_field[direction]': 'desc'
    }
  },
  {
    label:i18n.getLocalesTranslations('literal.most_contributors_last'),
    value: 'most_contributors_last',
    query: {
      'sort_by_field[field]': 'contributors_count',
      'sort_by_field[direction]': 'asc'
    }
  },
  {
    label:i18n.getLocalesTranslations('literal.last_activity'),
    value: 'activity_last',
    query: {
      'sort_by_field[field]': 'activity',
      'sort_by_field[direction]': 'desc'
    }
  }
]

export {
  projects_overview_options
}
