<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__grid">
        <article class="column1">
          <a
            :href="translate(column1.image_url)"
            class="column1__link"
            v-if="translate(column1.image_url)"
          >
            <img
              :src="translate(column1.image)"
              :alt="translate(column1.image_url)"
              class="column1__img"
            >
          </a>

          <img
            :src="translate(column1.image)"
            :alt="translate(column1.image_url)"
            class="column1__img"
            v-else
          >
        </article>

        <article class="column2">
          <div
            class="editor editor__content column2__address"
            v-if="translate(column2.address)"
            v-html="translate(column2.address)"
          />

          <ul class="column2__social social__ul">
            <li
              class="social__li"
              v-for="(media, index) in socialMedias"
              :key="'footer_menu_social_media_' + index"
            >
              <a
                class="social__link"
                :href="translate(media.url)"
                target="_blank"
              >
                <fa-icon
                  v-if="media.icon"
                  :icon="media.icon"
                  class="social__icon"
                />
              </a>
            </li>
          </ul>
        </article>

        <article class="column3">
          <ul
            class="column3__navigation"
            v-if="column3.links && column3.links.length"
          >
            <li
              class="navigation__li"
              v-for="({ link }, key) in column3.links"
              :key="`navigation_link_${key}`"
            >
              <app-link
                :link="link"
                class="navigation__link"
              />
            </li>
          </ul>
        </article>

        <article class="column4">
          <div class="column4__session">
            <template v-if="isAuthUser">
              <router-link
                class="session__user"
                :to="{ name: 'Profile', params: { locale } }"
              >
                <user-profile-img
                  :size="2"
                  :avatar="authUser.avatar"
                />

                <p class="margin__l--6">
                  {{ authUser.firstname }}
                </p>
              </router-link>
            </template>

            <template v-else>
              <a
                @click.prevent="toggleSessionModal('login')"
                class="session__user"
              >
                <fa-icon
                  size="lg"
                  :icon="['fal', 'user-circle']"
                  fixed-width
                />

                {{ translate(column4.login) || t('literal.login') }}
              </a>
            </template>
          </div>

          <ul
            class="column4__buttons buttons__ul"
            v-if="column4.links && column4.links.length"
          >
            <li
              class="buttons__li"
              v-for="({ link }, key) in column4.links"
              :key="`link_${key}`"
            >
              <app-link
                :link="link"
                class="buttons__link"
              />
            </li>
          </ul>
        </article>

        <article class="column5">
          <a
            :href="translate(column5.logo_url)"
            class="column5__link"
            v-if="translate(column5.logo_url)"
          >
            <img
              :src="translate(column5.logo)"
              :alt="translate(column5.logo_url)"
              class="column5__img"
            >
          </a>

          <img
            :src="translate(column5.logo)"
            :alt="translate(column5.logo_url)"
            class="column5__img"
            v-else
          >
        </article>
      </div>

      <div class="column6">
        <ul
          class="column6__ul"
          v-if="column6.images && column6.images.length"
        >
          <li
            class="column6__li"
            v-for="({ link, image }, key) in column6.images"
            :key="`bar_image_${key}`"
          >
            <app-link
              :link="link"
              v-if="translate(image)"
            >
              <img
                :src="translate(image)"
                :alt="translate(link.text)"
                class="column6__img"
              >
            </app-link>

            <img
              :src="translate(image)"
              :alt="translate(link.text)"
              class="column6__img"
              v-else
            >
          </li>
        </ul>
      </div>
    </div>

    <app-modal
      v-if="showSessionModal"
      size="sm"
      @close="showSessionModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="sessionTemplate"
          @authenticated="showSessionModal = false"
        />
      </template>
    </app-modal>
  </footer>
</template>

<script setup>
import { defineAsyncComponent, ref, computed } from 'vue'
import { useClientStore } from '@/stores/ClientStore'
import { useLanguageStore } from '@/stores/LanguageStore'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { storeToRefs } from 'pinia'
import { get as _get } from 'lodash'
import { useI18n } from '@/vendors/i18n'

import AppLink from '&/atoms/AppLink'

const UserProfileImg = defineAsyncComponent(() => import('&/atoms/UserProfileImg'))
const SessionForms = defineAsyncComponent(() => import('&/modules/sessions/SessionForms'))
const AppModal = defineAsyncComponent(() => import('&/organisms/AppModal'))

const props = defineProps({
  section: { type: Object, required: true }
})

const { authUser, isAuthUser } = storeToRefs(useAuthenticationStore())
const { locale } = storeToRefs(useLanguageStore())
const { translate, t } = useI18n()
const { getClient } = useClientStore()

const { client } = getClient()

const sessionTemplate = ref('login')
const showSessionModal = ref(false)

const column1 = computed(() => _get(props, 'section.attributes.column_1', {}))
const column2 = computed(() => _get(props, 'section.attributes.column_2', {}))
const column3 = computed(() => _get(props, 'section.attributes.column_3', {}))
const column4 = computed(() => _get(props, 'section.attributes.column_4', {}))
const column5 = computed(() => _get(props, 'section.attributes.column_5', {}))
const column6 = computed(() => _get(props, 'section.attributes.column_6', {}))

const toggleSessionModal = template => {
  sessionTemplate.value = template
  showSessionModal.value = true
}

const socialMedias = computed(() => {
  const links = _get(client.value, 'platform.social_networks', [])

  return links.map(link => {
    if (link.icon.includes('facebook-square')) {
      link.icon[1] = 'facebook-f'
    } else if (link.icon.includes('linkedin')) {
      link.icon[1] = 'linkedin-in'
    }

    return link
  }).filter(link => translate(link.url))
})
</script>

<style lang="scss" scoped>
.footer {
  padding: 48px 0;
  background: linear-gradient(180deg, $color--primary 0%, $vdw 100%);

  &__grid {
    display: flex;
    flex-direction: column;
    gap: $margin--base * 3;
    align-items: center;

    @include mq(md) {
      display: grid;
      grid-template-columns: repeat(5, fit-content(200px));
      justify-content: space-between;
      align-items: flex-start;
    }
  }
}

.column1 {
  &__img {
    display: block;
    max-width: 95px;
  }
}

.column2 {
  &__social {
    margin-top: 18px;
    display: flex;
    align-items: center;
    gap: 8px;

    .social__link {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $black;
      color: $white;
      border-radius: 50%;
      width: 34px;
      height: 34px;
      font-size: 23px;
    }
  }
}

.column3 {
  &__navigation {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .navigation__li:hover {
      text-decoration: underline;
    }

    &:deep(.navigation__link) {
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}

.column4 {
  &__session {
    .session__user {
      display: flex;
      align-items: center;
      gap: 3px;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__buttons {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &:deep(.buttons__link) {
      color: $color--primary;
      font-size: .85rem;
      font-weight: bold;
      text-transform: uppercase;
      padding: 7px 30px;
      border: 3px solid $color--primary;
      background-color: $white;
      line-height: 1;
      transition: all .25s ease-out;

      &:hover {
        color: $black;
        border-color: $black;
      }
    }
  }
}

.column5 {
  &__img {
    display: block;
    max-width: 160px;
  }
}

.column6 {
  width: 100%;

  &__ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 24px;

    @include mq(md) {
      justify-content: flex-end;
      margin-top: 0px;
      flex-wrap: nowrap;
    }
  }

  &__img {
    max-height: 50px;
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}
</style>
