<template>
  <div class="position-relative">
    <header
      class="header"
      :class="{'header--fixed': mobileMenuOpened}"
    >
      <div class="wrapper topbar">
        <a
          v-if="!isAuthenticated"
          class="register link--naked pointer"
          @click.prevent="openSessionAppModal('login')"
        >
          <fa-icon
            class="margin__r--6"
            :icon="['fas', 'user']"
            fixed-width
          />

          {{ $i18n.t('literal.login') }}
        </a>

        <router-link
          class="pointer d-flex"
          :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
          v-if="isAuthenticated && user"
          @click.capture="mobileMenuOpened = false"
        >
          <user-profile-img
            class="margin__r--6"
            :avatar="user.avatar"
            size="2"
          />

          {{ user.firstname }}
        </router-link>
      </div>

      <div class="wrapper">
        <div class="header__col flex-grow-1">
          <a
            :href="$i18n.translate(logo.url)"
            v-if="$i18n.translate(logo.url)"
            class="logo"
          >
            <img
              class="logo--img"
              :src="client.logo.url"
              :alt="client.name"
            >
          </a>

          <router-link
            :to="{ name: 'Home', params: { locale: $i18n.locale } }"
            v-show="client.logo.url"
            v-else
            class="logo"
          >
            <img
              class="logo--img"
              :src="client.logo.url"
              :alt="client.name"
            >
          </router-link>

          <ul class="nav">
            <li
              class="nav__item"
              :class="{'nav__item--has-submenu': item.submenu.length > 0}"
              v-for="(item, index) in menu"
              :key="`menu_1_item_${index}`"
            >
              <a
                class="nav__link"
                :href="$i18n.translate(item.link.url)"
                v-if="isWebsiteLink(item.link)"
              >
                {{ $i18n.translate(item.link.text) }}
              </a>

              <a
                class="nav__link"
                :class="{'nav__link--active': isVisited(item.link)}"
                @click.prevent="setRouteNavigation(item.link); mobileMenuOpened = false"
                v-else
              >
                {{ $i18n.translate(item.link.text) }}
              </a>

              <fa-icon
                v-if="item.submenu.length > 0"
                class="nav__icon margin__l--6"
                :icon="['fal', 'chevron-down']"
              />

              <ul
                v-if="item.submenu.length > 0"
                class="nav__submenu"
              >
                <li
                  class="nav__submenu-item"
                  v-for="(subitem, key) in item.submenu"
                  :key="`menu_1_item_${index}_${key}`"
                >
                  <a
                    class="nav__link"
                    :href="$i18n.translate(subitem.link.url)"
                    v-if="isWebsiteLink(subitem.link)"
                  >
                    {{ $i18n.translate(subitem.link.text) }}
                  </a>

                  <a
                    class="nav__submenu-link"
                    :class="{'nav__submenu-link--active': isVisited(subitem.link)}"
                    @click.prevent="setRouteNavigation(subitem.link); mobileMenuOpened = false"
                    v-else
                  >
                    {{ $i18n.translate(subitem.link.text) }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div class="header__col">
          <ul class="actions">
            <li
              v-if="cta.button_1 && cta.button_1.show"
              class="actions__item"
            >
              <a
                class="actions__btn bold d-block"
                :href="$i18n.translate(cta.button_1.url)"
                v-if="isWebsiteLink(cta.button_1)"
              >
                <svg
                  class="actions__svg actions__svg--orange"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="180px"
                  height="52px"
                  viewBox="0 0 180 52"
                  style="enable-background:new 0 0 180 52;"
                  preserveAspectRatio="none"
                  xml:space="preserve"
                >
                  <polygon
                    style="fill-rule: evenodd;clip-rule: evenodd;"
                    points="5.7,52 5.7,52 180,46.7 180,0 0,4.7 "
                  />
                </svg>

                <span>
                  {{ $i18n.translate(cta.button_1.text) }}
                </span>
              </a>

              <button
                class="actions__btn bold"
                @click.prevent="setRouteNavigation(cta.button_1); mobileMenuOpened = false"
                v-else
              >
                <svg
                  class="actions__svg actions__svg--orange"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="180px"
                  height="52px"
                  viewBox="0 0 180 52"
                  style="enable-background:new 0 0 180 52;"
                  preserveAspectRatio="none"
                  xml:space="preserve"
                >
                  <polygon
                    style="fill-rule: evenodd;clip-rule: evenodd;"
                    points="5.7,52 5.7,52 180,46.7 180,0 0,4.7 "
                  />
                </svg>

                <span>
                  {{ $i18n.translate(cta.button_1.text) }}
                </span>
              </button>
            </li>

            <li
              class="actions__item"
              v-if="cta.button_2 && cta.button_2.show"
            >
              <a
                class="actions__btn bold d-block"
                :href="$i18n.translate(cta.button_2.url)"
                v-if="isWebsiteLink(cta.button_2)"
              >
                <svg
                  class="actions__svg actions__svg--orange"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="180px"
                  height="52px"
                  viewBox="0 0 180 52"
                  style="enable-background:new 0 0 180 52;"
                  preserveAspectRatio="none"
                  xml:space="preserve"
                >
                  <polygon
                    style="fill-rule: evenodd;clip-rule: evenodd;"
                    points="5.7,52 5.7,52 180,46.7 180,0 0,4.7 "
                  />
                </svg>

                <span>
                  {{ $i18n.translate(cta.button_2.text) }}
                </span>
              </a>

              <button
                class="actions__btn bold"
                @click.prevent="setRouteNavigation(cta.button_2.url); mobileMenuOpened = false"
                v-else
              >
                <svg
                  class="actions__svg actions__svg--blue"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="180px"
                  height="52px"
                  viewBox="0 0 180 52"
                  style="enable-background:new 0 0 180 52;"
                  preserveAspectRatio="none"
                  xml:space="preserve"
                >
                  <polygon
                    style="fill-rule: evenodd;clip-rule: evenodd;"
                    points="5.7,52 5.7,52 180,46.7 180,0 0,4.7 "
                  />
                </svg>

                <span>
                  {{ $i18n.translate(cta.button_2.text) }}
                </span>
              </button>
            </li>
          </ul>

          <app-burger-menu
            class="burger"
            :state="mobileMenuOpened"
            @toggle="toggleMobileMenu"
          />
        </div>
      </div>
    </header>

    <section
      class="mobile"
      v-if="mobileMenuOpened"
    >
      <nav v-if="menu.length > 0">
        <ul class="mobile-nav">
          <li
            class="mobile-nav__item"
            v-for="(item, index) in menu"
            :key="`menu_1_item_${index}`"
          >
            <a
              class="mobile-nav__link"
              :href="$i18n.translate(item.link.url)"
              v-if="isWebsiteLink(item.link)"
            >
              {{ $i18n.translate(item.link.text) }}
            </a>

            <a
              class="mobile-nav__link"
              :class="{'mobile-nav__link--active': isVisited(item.link)}"
              @click.prevent="setRouteNavigation(item.link); mobileMenuOpened = false"
              v-else
            >
              {{ $i18n.translate(item.link.text) }}
            </a>

            <ul
              v-if="item.submenu.length > 0"
              class="mobile-nav__submenu"
            >
              <li
                class="mobile-nav__submenu-item"
                v-for="(subitem, key) in item.submenu"
                :key="`menu_1_item_${index}_${key}`"
              >
                <a
                  class="mobile-nav__submenu-link"
                  :href="$i18n.translate(subitem.link.url)"
                  v-if="isWebsiteLink(subitem.link)"
                >
                  {{ $i18n.translate(subitem.link.text) }}
                </a>

                <a
                  class="mobile-nav__submenu-link"
                  :class="{'mobile-nav__submenu-link--active': isVisited(subitem.link)}"
                  @click.prevent="setRouteNavigation(subitem.link); mobileMenuOpened = false"
                  v-else
                >
                  {{ $i18n.translate(subitem.link.text) }}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>

      <ul class="mobile-actions">
        <li
          v-if="cta.button_1 && cta.button_1.show"
          class="mobile-actions__item"
        >
          <a
            class="actions__btn bold d-inline-block"
            :href="$i18n.translate(cta.button_1.url)"
            v-if="isWebsiteLink(cta.button_1)"
          >
            <svg
              class="actions__svg actions__svg--orange"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="180px"
              height="52px"
              viewBox="0 0 180 52"
              style="enable-background:new 0 0 180 52;"
              preserveAspectRatio="none"
              xml:space="preserve"
            >
              <polygon
                style="fill-rule: evenodd;clip-rule: evenodd;"
                points="5.7,52 5.7,52 180,46.7 180,0 0,4.7 "
              />
            </svg>

            <span>
              {{ $i18n.translate(cta.button_1.text) }}
            </span>
          </a>

          <button
            class="actions__btn bold"
            @click.prevent="setRouteNavigation(cta.button_1); mobileMenuOpened = false"
            v-else
          >
            <svg
              class="actions__svg actions__svg--orange"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="180px"
              height="52px"
              viewBox="0 0 180 52"
              style="enable-background:new 0 0 180 52;"
              preserveAspectRatio="none"
              xml:space="preserve"
            >
              <polygon
                style="fill-rule: evenodd;clip-rule: evenodd;"
                points="5.7,52 5.7,52 180,46.7 180,0 0,4.7 "
              />
            </svg>

            <span>
              {{ $i18n.translate(cta.button_1.text) }}
            </span>
          </button>
        </li>

        <li
          class="mobile-actions__item"
          v-if="cta.button_2 && cta.button_2.show"
        >
          <a
            class="actions__btn bold d-block"
            :href="$i18n.translate(cta.button_2.url)"
            v-if="isWebsiteLink(cta.button_2)"
          >
            <svg
              class="actions__svg actions__svg--orange"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="180px"
              height="52px"
              viewBox="0 0 180 52"
              style="enable-background:new 0 0 180 52;"
              preserveAspectRatio="none"
              xml:space="preserve"
            >
              <polygon
                style="fill-rule: evenodd;clip-rule: evenodd;"
                points="5.7,52 5.7,52 180,46.7 180,0 0,4.7 "
              />
            </svg>

            <span>
              {{ $i18n.translate(cta.button_2.text) }}
            </span>
          </a>

          <button
            class="actions__btn bold"
            @click.prevent="setRouteNavigation(cta.button_2.url); mobileMenuOpened = false"
            v-else
          >
            <svg
              class="actions__svg actions__svg--blue"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="180px"
              height="52px"
              viewBox="0 0 180 52"
              style="enable-background:new 0 0 180 52;"
              preserveAspectRatio="none"
              xml:space="preserve"
            >
              <polygon
                style="fill-rule: evenodd;clip-rule: evenodd;"
                points="5.7,52 5.7,52 180,46.7 180,0 0,4.7 "
              />
            </svg>

            <span>
              {{ $i18n.translate(cta.button_2.text) }}
            </span>
          </button>
        </li>
      </ul>
    </section>

    <app-modal
      v-if="showSessionAppModal"
      size="sm"
      @close="showSessionAppModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="template"
          @authenticated="showSessionAppModal = false"
        />
      </template>
    </app-modal>
  </div>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import { get as _get } from 'lodash'
import { mapGetters } from 'vuex'
import UserProfileImg from '&/atoms/UserProfileImg'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
import PageRouteNavigation from '%/PageRouteNavigation'

export default defineComponent({
  name: 'CustomHeaderSFOV',
  components: {
    UserProfileImg,
    AppBurgerMenu,
    SessionForms: defineAsyncComponent(() => import('&/modules/sessions/SessionForms')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      showSessionAppModal: false,
      mobileMenuOpened: false,
      template: null
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    logo () {
      return _get(this.section, 'attributes.logo')
    },
    menu () {
      return _get(this.section, 'attributes.menu')
    },
    cta () {
      return _get(this.section, 'attributes.cta')
    }
  },
  methods: {
    openSessionAppModal (template) {
      this.template = template
      this.showSessionAppModal = true
    },
    toggleMobileMenu (value) {
      this.mobileMenuOpened = !value
    },
    isWebsiteLink (link) {
      return link.type === 'custom' && this.$i18n.translate(link.url).includes('https://streekfondsoostvlaanderen.be')
    }
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #dbeff7;
$color--secondary: #056fa0;
$color--tertiary: #eb6608;
$color--hover: #32405a;

.header {
  position: relative;
  z-index: 999;
  padding: calc($padding__base / 2) 0 $padding__base;
  background: $white;

  @include mq(sm) {
    padding: calc($padding__base / 2) 0 $padding__base * 2;
  }

  &__col {
    display: flex;
    align-items: center;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 $padding__base * 1.5;

  @include mq(sm) {
    padding: 0 $padding__base * 2.5;
  }

  @include mq(lg) {
    padding: 0 $padding__base * 4;
  }
}

.topbar {
  font-size: rem(14px);
  flex-direction: row-reverse;

  @include mq(sm) {
    margin-bottom: $margin__base;
  }
}

.logo {
  width: 75px;
  height: auto;

  &--img {
    width: 100%;
    height: auto;
    display: block;
  }

  @include mq(sm) {
    width: 100px;
  }
}

.nav {
  display: none;
  font-size: rem(18px);

  @include mq(lg) {
    display: flex;
    margin-left: $margin__base * 2.5;
  }

  @include mq(xl) {
    margin-left: $margin__base * 7.5;
  }

  &__item {
    display: flex;
    align-items: center;
    position: relative;

    &:not(:last-child) {
      margin-right: $margin__base;

      @include mq(sm) {
        margin-right: $margin__base * 2;
      }
    }

    &--has-submenu {
      &:hover {
        .nav {
          &__icon {
            transform: rotate(-180deg);
          }

          &__item::before {
            transform: scaleX(1);
          }

          &__submenu {
            display: block;
          }
        }
      }
    }
  }

  &__icon {
    font-size: rem(10px);
    transition: all $transition__duration;
  }

  &__link {
    position: relative;
    display: block;
    padding: calc($padding__base / 2) 0;
    color: #32405A;
    transition: all $transition__duration;

    &::before {
      content: '';
      width: 100%;
      height: 3px;
      background: $color--tertiary;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      transform-origin: 50% 50%;
      transform: scaleX(0);
      transition: all $transition__duration;
    }

    &--active {
      font-weight: bold;
    }

    &--active, &:hover {
      &::before {
        transform: scaleX(1);
      }
    }
  }

  &__submenu {
    display: none;
    position: absolute;
    background: $color--primary;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    min-width: 100px;
    border-top: 2px solid transparent;
    font-size: rem(16px);
    text-align: center;
    line-height: 1.3;

    &-item {
      &:not(:first-child) {
        border-top: 1px solid rgba(47,66,61,.3);
      }
    }

    &-link {
      display: block;
      padding: calc($padding__base / 2) $padding__base * 1.5;
      color: #32405A;
      transition: all $transition__duration;

      &:hover, &--active {
        color: $color--tertiary;
      }
    }
  }
}

.actions {
  display: none;

  @include mq(sm) {
    display: flex;
    justify-content: flex-end;
    align-self: center;
  }

  &__item {
    &:not(:last-child) {
      margin-right: $margin__base * 2;
    }

    &:nth-child(n+2) {
      .actions__svg polygon {
        fill: #056FA0;
      }
    }
  }

  &__btn {
    position: relative;
    padding: $padding__base * 2 $padding__base * 4;
    color: $white;

    &:hover {
      .actions {
        &__svg {
          polygon {
            fill: $color--hover;
          }
        }
      }
    }
  }

  &__svg {
    position: absolute;
    z-index: -5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all ease-in .2s;

    polygon {
      transition: all $transition__duration;
    }

    &--orange {
      polygon {
        fill: $color--tertiary;
      }
    }

    &--blue {
      polygon {
        fill: #056FA0;
      }
    }
  }

  &__text {
    width: 100%;
  }
}

.burger {
  padding: 0;
  width: 30px;
  margin-left: $margin__base * 2;

  :deep() .menu {
    &__label {
      display: none;
    }

    &__line, &__close::before, &__close::after {
      background-color: #eb6608;
    }
  }

  @include mq(lg) {
    display: none;
  }
}

.mobile {
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  width: 100%;
  z-index: 999;
  background: $white;
  text-align: center;
  border-bottom: 2px solid #32405A;

  @include mq(lg) {
    display: none;
  }
}

.mobile-nav {
  &__link {
    font-size: rem(24px);
    color: #32405A;
    padding: calc($padding__base / 2) $padding__base;
    display: inline-block;
    position: relative;

    &--active {
      font-weight: bold;

      &::before {
        content: '';
        width: 100%;
        height: 3px;
        background: $color--tertiary;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: all $transition__duration;
      }
    }
  }

  &__submenu {
    background: $color--primary;
    padding: $padding__base;

    &-link {
      color: #32405a;
      position: relative;

      &--active {
        color: $color--tertiary;
      }
    }
  }
}

.mobile-actions {
  padding: $padding__base;

  @include mq(sm) {
    display: none;
  }

  &__item {
    &:not(:first-child) {
      margin-top: $margin__base;

      @include mq(sm) {
        margin: 0 0 0 $margin__base;
      }
    }
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}
</style>
