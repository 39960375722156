<template>
  <template v-if="procedure && isShowed('button')">
    <project-button
      class="btn__size--full-12"
      :project="project"
      :is-preview="isPreview"
    >
      <template #ongoing>
        {{ translate(getText('ongoing')) || t('literal.donate') }}
      </template>

      <template #reached>
        {{ translate(getText('limited_to_one_contribution')) || t('literal.already_contributed') }}
      </template>

      <template #not_started>
        {{ translate(getText('not_started_yet')) || t('project_page.btn_donate_not_started_yet') }}
      </template>

      <template #ended>
        {{ translate(getText('finished')) || t('project_page.btn_donate_finished') }}
      </template>
    </project-button>

    <p
      class="info align--center color--lg margin__t--6"
      v-if="!procedure.is_started"
    >
      {{ tc('plural.starts_in_x_day', days, { daysNumber: days }) }}
    </p>
  </template>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useBlock } from '@/composables/modules/templates'
import { differenceInCalendarDays } from 'date-fns'
import { get as _get } from 'lodash'

const ProjectButton = defineAsyncComponent(() => import('@/components/modules/project/ProjectButton'))

const props = defineProps({ block: { type: Object, required: true } })

const { t, translate, tc } = useI18n()
const { objects, getText, isPreview, isShowed } = useBlock(props.block)

const project = computed(() => _get(objects.value, 'project'))
const procedure = computed(() => project.value?.procedures.donation)

const days = computed(() => differenceInCalendarDays(new Date(procedure.value.start_date), new Date()))
</script>

<style lang="scss" scoped>
.info {
  font-size: rem(14px);
}
</style>