<template>
  <input
    ref="autocomplete"
    type="text"
    class="margin__t--6 input input--border input--radius"
    :id="id"
    :placeholder="placeholder"
    v-model="autocompleteText"
    v-bind="$attrs"
    @focus="onFocus()"
    @blur="onBlur()"
    @change="onChange"
    @keypress="onKeyPress"
    @keyup="onKeyUp"
  >

  <div
    class="input--errors"
    v-if="v && v.$error"
  >
    <p
      class="input--error"
      v-if="v.$invalid"
    >
      {{ $i18n.t('errors.input_text_required') }}
    </p>
  </div>
</template>

<script>
// TODO Need to be refactor
import { defineComponent } from 'vue'
import { useGMaps } from '@/vendors/integrations/gmaps'
import Address from '@/classes/addresses/Address'

const ADDRESS_COMPONENTS = {
  subpremise: 'short_name',
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  administrative_area_level_2: 'long_name',
  country: 'long_name',
  postal_code: 'short_name'
}

const CITIES_TYPE = ['locality', 'administrative_area_level_3']
const REGIONS_TYPE = ['locality', 'sublocality', 'postal_code', 'country', 'administrative_area_level_1', 'administrative_area_level_2']

export default defineComponent({
  name: 'AppGoogleAutocomplete',
  emits: ['inputChange', 'placechanged', 'focus', 'blur', 'change', 'keypress', 'keyup', 'error'],
  props: {
    address: {
      type: Object,
      default: () => ({})
    },
    id: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: '...'
    },
    types: {
      type: String,
      default: 'address'
    },
    country: {
      type: [String, Array],
      default: null
    },
    enableGeolocation: {
      type: Boolean,
      default: false
    },
    geolocationOptions: {
      type: Object,
      default: null
    },
    v: {
      type: Object
    }
  },
  setup: () => ({ gmaps: useGMaps() }),
  data () {
    return {
      autocomplete: null,
      autocompleteText: '',
      geolocation: {
        geocoder: null,
        loc: null,
        position: null
      }
    }
  },
  created () {
    const address = []

    if (this.types !== '(cities)') address.push(this.address.line1)

    address.push(this.address.city, this.address.country)

    this.autocompleteText = address.filter(line => line).join(', ')
  },
  watch: {
    autocompleteText: function (newVal, oldVal) {
      this.$emit('inputChange', { newVal, oldVal }, this.id)

      if (!newVal) this.autocomplete.set('place', null)
    },
    country: function () {
      this.autocomplete.setComponentRestrictions({
        country: this.country === null ? [] : this.country
      })
    }
  },
  mounted () {
    this.gmaps.loader().then(() => {
      const options = {}

      if (this.types) {
        options.types = [this.types]
      }

      if (this.country) {
        options.componentRestrictions = {
          country: this.country
        }
      }

      options.fields = ['address_components', 'geometry']

      this.autocomplete = new google.maps.places.Autocomplete(
        document.getElementById(this.id),
        options
      )

      this.autocomplete.addListener('place_changed', this.onPlaceChanged)
    })
  },
  methods: {
    onPlaceChanged () {
      let place = this.autocomplete.getPlace()

      if (!place || !place.geometry) {
        this.$emit('placechanged', new Address(), null)
        return
      }

      if (place.address_components !== undefined) {
        this.$emit('placechanged', this.formatResult(place), place)
        this.autocompleteText = document.getElementById(this.id).value
        this.onChange()
      }
    },
    onFocus () {
      this.biasAutocompleteLocation()
      this.$emit('focus')
    },
    onBlur () {
      if (this.v) this.v.$touch()
      this.$emit('blur')
    },
    onChange () {
      this.$emit('change', this.autocompleteText)
    },
    onKeyPress (event) {
      this.$emit('keypress', event)
    },
    onKeyUp (event) {
      this.$emit('keyup', event)
    },
    clear () {
      this.autocompleteText = ''
    },
    focus () {
      this.$refs.autocomplete.focus()
    },
    blur () {
      this.$refs.autocomplete.blur()
    },
    update (value) {
      this.autocompleteText = value
    },
    updateCoordinates (value) {
      if (!value && !(value.lat || value.lng)) return
      /* global google */
      if (!this.geolocation.geocoder) this.geolocation.geocoder = new google.maps.Geocoder()

      this.geolocation.geocoder.geocode({ 'location': value }, (results, status) => {
        if (status === 'OK') {
          results = this.filterGeocodeResultTypes(results)
          if (results[0]) {
            this.$emit('placechanged', this.formatResult(results[0]), results[0], this.id)
            this.update(results[0].formatted_address)
          } else {
            this.$emit('error', 'no result for provided coordinates')
          }
        } else {
          this.$emit('error', 'error getting address from coords')
        }
      })
    },
    geolocate () {
      this.updateGeolocation(geolocation => {
        this.updateCoordinates(geolocation)
      })
    },
    updateGeolocation (callback = null) {
      if (navigator.geolocation) {
        let options = {}
        if (this.geolocationOptions) Object.assign(options, this.geolocationOptions)

        navigator.geolocation.getCurrentPosition(position => {
          let geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
          this.geolocation.loc = geolocation
          this.geolocation.position = position
          if (callback) callback(geolocation, position)
        }, err => {
          this.$emit('error', 'Cannot get Coordinates from navigator', err)
        }, options)
      }
    },
    biasAutocompleteLocation () {
      if (this.enableGeolocation) {
        this.updateGeolocation((geolocation, position) => {
          let circle = new google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy
          })

          this.autocomplete.setBounds(circle.getBounds())
        })
      }
    },
    formatResult (place) {
      const address = {}

      for (let i = 0; i < place.address_components.length; i++) {
        const type = place.address_components[i].types[0]

        if (ADDRESS_COMPONENTS[type])
          address[type] = place.address_components[i][ADDRESS_COMPONENTS[type]]
      }

      address['latitude'] = place.geometry.location.lat()
      address['longitude'] = place.geometry.location.lng()

      const line1 = address.street_number ? `${address.route} ${address.street_number}` : address.route

      return new Address({
        line1,
        postal_code: address.postal_code,
        city: address.locality,
        country: address.country,
        state: address.administrative_area_level_1,
        latitude: address.latitude,
        longitude: address.longitude
      })
    },
    filterGeocodeResultTypes (results) {
      if (!results || !this.types) return results
      let output = []
      let types = [this.types]
      if (types.includes('cities')) types = types.concat(CITIES_TYPE)
      if (types.includes('regions')) types = types.concat(REGIONS_TYPE)

      for (let r of results) {
        for (let t of r.types) {
          if (types.includes(t)) {
            output.push(r)
            break
          }
        }
      }

      return output
    }
  }
})
</script>
