import { toValue } from 'vue'
import { defineStore } from 'pinia'
import Contribution from '@/classes/contributions/Contribution'

export const useContributionStore = defineStore('ContributionStore', {
  state: () => ({
    contribution: {},
    loader: false,
    error: {}
  }),
  getters: {
    getContribution: state => ({ id }) => {
      return state.$getItem({
        resource: 'contribution',
        key: id,
        fetch: () => state.fetchContribution({ id })
      })
    }
  },
  actions: {
    fetchContribution ({ id }) {
      return this.$fetchItem({
        endpoint: `/api/v1/contributions/${toValue(id)}`,
        resource: `contribution.${toValue(id)}`,
        model: Contribution
      })
    }
  }
})