<template>
  <hr class="divider">
</template>

<style lang="scss" scoped>
.divider {
  display: block;
  width: 100%;
  height: 12px;
  margin: 0;
  border: none;
}
</style>
