
import { inject } from 'vue'
import { loadScript } from '@/utils/utils'

class GoogleMaps {
  constructor () {
    this.key = null
  }

  init ({ key }) {
    this.key = key
  }

  loader () {
    return loadScript(`https://maps.googleapis.com/maps/api/js?key=${this.key}&libraries=places`)
  }
}

const gmapsSymbol = Symbol()
const gmaps = new GoogleMaps()

export const createGMaps = {
  install: (app, { key } = {}) => {
    gmaps.init({ key })

    app.provide(gmapsSymbol, gmaps)
  }
}

export function useGMaps () {
  return inject(gmapsSymbol)
}

export default gmaps