export default [
  {
    name: 'custom_header_big',
    path: 'modules/pages/big/HeaderBig'
  },
  {
    name: 'custom_footer_big',
    path: 'modules/pages/big/FooterBig'
  },
  {
    name: 'campaign_big',
    path: 'modules/pages/big/CampaignBig'
  }
]
