import { computed, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'

export function useProcedureBlockValidator ({ validations = {}, state }) {
  const v = useVuelidate(validations, state, { $scope: 'procedure' })
  const v$ = useVuelidate(validations, state)

  const validator = computed(() => v.value.$dirty ? v$.value : v.value)

  watch(validations, () => v$.value.$touch(), { immediate: true })

  return { validator }
}