<template>
  <div class="bg__color--white">
    <section
      class="banner color--white"
      :style="{ backgroundImage: translate(banner.image) ? `url(${translate(banner.image)})` : 'none' }"
      v-if="isVisible('banner')"
    >
      <div class="container banner__container">
        <div class="banner__wrapper">
          <app-share
            :networks="socialNetworls"
            :email-text="emailText"
            :whatsapp-text="whatsappText"
            :x-text="xText"
          />
        </div>
      </div>
    </section>

    <section
      class="map"
      v-if="isVisible('map')"
    >
      <div class="container align--center">
        <h1
          class="title__h1 bold color--prim margin__b--24 align--center"
          v-if="translate(map.title)"
        >
          {{ translate(map.title) }}
        </h1>

        <app-link
          class="btn btn__color--sec btn__size--classic cta d-inline-block"
          :link="map.cta"
        />

        <app-google-maps
          class="map__container margin__t--54"
          :markers="markers"
          :map-style="mapStyle"
        >
          <template #infowindow="project">
            <project-map-card
              :project="project"
              v-if="project"
            />
          </template>
        </app-google-maps>

        <div
          class="editor editor__content margin__t--30 map__text"
          v-if="translate(map.text)"
          v-html="translate(map.text)"
        />
      </div>
    </section>

    <section
      class="actions"
      v-if="isVisible('actions') && projects.length"
    >
      <div class="container align--center">
        <div class="row align--left">
          <template
            v-for="project in projects"
            :key="project.id"
          >
            <section class="col-12 col-sm-6 col-md-3">
              <project-card
                class="project-card"
                :project="project"
              />
            </section>
          </template>
        </div>

        <app-link
          class="btn btn__color--sec btn__size--classic cta d-inline-block margin__t--30"
          :link="actions.cta"
        />
      </div>
    </section>

    <section
      class="about bg__color--prim color--white align--center"
      v-if="isVisible('about')"
    >
      <div class="container">
        <h2
          class="title__h2 bold margin__b--24"
          v-if="translate(about.title)"
        >
          {{ translate(about.title) }}
        </h2>

        <div
          class="editor editor__content margin__t--30 about__text"
          v-if="translate(about.text)"
          v-html="translate(about.text)"
        />

        <app-link
          class="btn btn__color--sec btn__size--classic cta d-inline-block margin__t--30 about__cta"
          :link="actions.cta"
        />
      </div>
    </section>

    <section
      class="stats"
      v-if="isVisible('stats') && metrics.length && statistics"
    >
      <ol
        class="container stats__ol"
        :class="{ 'stats__ol--2': metrics.length % 2 == 0 }"
      >
        <li
          class="stats__li"
          v-for="(metric, key) in metrics"
          :key="`metric_${key}`"
        >
          <strong class="stats__value">
            <app-date
              v-if="metric.type === 'date' && metric.date"
              :timestamp="metric.date"
              filter="differenceInCalendarDays"
            />

            <app-money
              v-else-if="metric.type === 'amount_collected'"
              :amount="statistics[metric.type] || 0"
            />

            <template v-else-if="metric.type === 'benefitings_count'">
              {{ benefitingsCount }}
            </template>

            <template v-else-if="metric.type === 'custom'">
              {{ translate(metric.value) || 0 }}
            </template>

            <template v-else>
              {{ statistics[metric.type] || 0 }}
            </template>
          </strong>

          {{ translate(metric.text) }}
        </li>
      </ol>
    </section>

    <section
      class="links"
      v-if="isVisible('links') && links.items.length"
    >
      <div class="container links__wrapper">
        <article
          class="link_item"
          v-for="(item, key) in links.items"
          :key="`link_${key}`"
        >
          <figure
            class="link_item__figure"
            :style="{ backgroundImage: translate(item.image) ? `url(${translate(item.image)})` : 'none' }"
          >
            <fa-icon
              class="no-thumb"
              :icon="['far', 'image']"
              v-if="!translate(item.image)"
            />
          </figure>

          <h2 class="title__h3 link_item__title">
            <app-link
              :link="item.cta"
              class="color--white link_item__link"
            />
          </h2>
        </article>
      </div>
    </section>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useClientStore } from '@/stores/ClientStore'
import { useProjectStore } from '@/stores/ProjectStore'
import useSection from '@/composables/modules/pages/useSection'
import { useI18n } from '@/vendors/i18n'
import { projects_overview_options } from '@/configurations/general/components-default-data'
import { get as _get, uniqBy as _uniqBy, omit as _omit } from 'lodash'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const AppMoney = defineAsyncComponent(() => import('&/atoms/AppMoney'))
const AppDate = defineAsyncComponent(() => import('&/atoms/AppDate'))
const AppGoogleMaps = defineAsyncComponent(() => import('&/atoms/AppGoogleMaps'))
const AppShare = defineAsyncComponent(() => import('&/molecules/AppShare'))
const ProjectCard = defineAsyncComponent(() => import('&/organisms/ProjectCard'))
const ProjectMapCard = defineAsyncComponent(() => import('&/organisms/ProjectMapCard'))

const props = defineProps({
  page: { type: Object, required: true },
  sections: { type: Object, required: true }
})

const { translate } = useI18n()
const { getSectionAttributes, isVisible } = useSection(props.page)
const { getStatistics } = useClientStore()
const { getProjects } = useProjectStore()

const mapStyle = [{ 'featureType': 'all', 'elementType': 'labels', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'administrative', 'elementType': 'labels', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'administrative', 'elementType': 'labels.text.fill', 'stylers': [{ 'color': '#444444' }, { 'visibility': 'off' }] }, { 'featureType': 'administrative.neighborhood', 'elementType': 'labels', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'landscape', 'elementType': 'all', 'stylers': [{ 'visibility': 'on' }, { 'color': '#e0dfe0' }] }, { 'featureType': 'landscape', 'elementType': 'labels', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'poi', 'elementType': 'all', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'poi', 'elementType': 'labels', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'poi.park', 'elementType': 'geometry', 'stylers': [{ 'color': '#a8a9a8' }, { 'visibility': 'off' }] }, { 'featureType': 'road', 'elementType': 'all', 'stylers': [{ 'saturation': -100 }, { 'lightness': 45 }, { 'visibility': 'off' }] }, { 'featureType': 'road', 'elementType': 'geometry.fill', 'stylers': [{ 'visibility': 'on' }, { 'color': '#b7b7b7' }] }, { 'featureType': 'road', 'elementType': 'labels', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'road.highway', 'elementType': 'all', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'road.highway', 'elementType': 'labels', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'road.arterial', 'elementType': 'labels.icon', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'transit', 'elementType': 'all', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'transit', 'elementType': 'labels', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'water', 'elementType': 'all', 'stylers': [{ 'color': '#ffffff' }, { 'visibility': 'on' }] }, { 'featureType': 'water', 'elementType': 'labels', 'stylers': [{ 'visibility': 'off' }] }]

const banner = computed(() => getSectionAttributes('banner'))
const map = computed(() => getSectionAttributes('map'))
const actions = computed(() => getSectionAttributes('actions'))
const about = computed(() => getSectionAttributes('about'))
const stats = computed(() => getSectionAttributes('stats'))
const links = computed(() => getSectionAttributes('links'))

const query = computed(() => {
  const query = _get(actions.value, 'settings', {})
  const sortByField = _get(projects_overview_options.find(sort => sort.value === query.sort), 'query', { 'sort_by_field[field]': 'created_at', 'sort_by_field[direction]': 'desc' })

  return _omit({ ...query, ...sortByField }, ['sort', 'collapse'])
})

const { statistics } = getStatistics({ query: _get(stats.value, 'settings', {}) })
const { projects } = getProjects({ query })
const { projects: projectLocations } = getProjects({ query: { ...query.value, view_format: 'locations' } })

const socialNetworls = computed(() => _get(banner.value, 'sharing_links.social_networks', []))
const emailText = computed(() => translate(_get(banner.value, 'sharing_links.email', {})))
const xText = computed(() => translate(_get(banner.value, 'sharing_links.x', {})))
const whatsappText = computed(() => translate(_get(banner.value, 'sharing_links.whatsapp', {})))

const metrics = computed(() => _get(stats.value, 'metrics', []))
const benefitingsCount = computed(() => _uniqBy(projectLocations.value.filter(project => project.benefiting.name), 'benefiting.name').length)

const markers = computed(() => projectLocations.value.map(project => ({ ...project, position: { lat: _get(project, 'address.latitude'), lng: _get(project, 'address.longitude') } })))
</script>

<style lang="scss" scoped>
$color--primary: #1D7874;
$color--secondary: #F95738;

.title {
  &__h1,
  &__h2 {
    font-size: rem(32px);
    line-height: 1.1;

    @include mq(sm) {
      font-size: rem(56px);
    }
  }

  &__h3 {
    font-size: rem(32px);
    line-height: 1.1;

    @include mq(md) {
      font-size: rem(48px);
    }
  }
}
.no-thumb {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: $white;
  font-size: rem(56px);
  opacity: 0.7;
}

:deep() {
  .cta {
    font-size: rem(16px);
    line-height: 1.55;
    border-radius: 0;
    position: relative;
    z-index: 99;
    font-family: $font-family--secondary;
    text-transform: uppercase;

    @include mq(sm) {
      font-size: rem(20px);
    }
  }
}

.banner {
  position: relative;
  padding: 12px 0;
  font-size: rem(18px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $color--primary;
  margin-top: -65px;

  @include mq(sm) {
    margin-top: -136px;
  }

  @include mq(md) {
    margin-top: -198px;
  }

  @include mq(md) {
    padding: 0;
  }

  & + section {
    padding-top: 60px;
  }

  &__container {
    @include aspect-ratio(1, 1);

    @include mq(sm) {
      @include aspect-ratio(1440, 829);
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    @include mq(md) {
      flex-direction: row-reverse;
    }
  }

  &:deep() {
    .share {
      font-size: rem(16px);
      padding: 12px;
      background: $color--secondary;

      @include mq(sm) {
        font-size: rem(20px);
      }

      @include mq(md) {
        flex-direction: column;
        background: none;
      }

      &__li {
        border-radius: 50%;
        border: 1px solid $white;
      }

      &__button {
        color: $white;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        transition: all .2s ease-in-out;

        &:hover {
          background: $color--secondary;
        }
      }
    }
  }
}

.map {
  padding: 30px 0;

  &__container {
    background: $vdw;

    @include aspect-ratio(1, 1);

    @include mq(sm) {
      @include aspect-ratio(1727, 720);
    }
  }

  &__text {
    max-width: 840px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.125;
    font-size: rem(18px);

    @include mq(md) {
      font-size: rem(24px);
    }
  }
}

.actions {
  padding: 0 0 30px;
}

.about {
  margin: 30px 0 60px;
  padding: 48px 0 72px;
  position: relative;

  &__text {
    max-width: 1050px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.125;
    font-size: rem(18px);

    @include mq(md) {
      font-size: rem(24px);
    }
  }

  &:deep(.cta) {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}

.stats {
  padding: 30px 0;

  &__ol {
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;
    text-align: center;

    @include mq(sm) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include mq(md) {
      grid-template-columns: repeat(6, 1fr);
    }

    &--2 {
      @include mq(md) {
        grid-template-columns: repeat(12, 1fr);

        .stats {
          &__li {
            grid-column: span 5;

            &:last-child:nth-child(2n+1),
            &:nth-last-child(2):nth-child(3n+1) {
              grid-column-end: inherit;
            }

            &:nth-child(2n+1) {
              grid-column-end: -7;
            }
          }
        }
      }
    }
  }

  &__li {
    background: $color--secondary;
    padding: 30px;
    color: $white;
    font-size: rem(18px);
    line-height: 1.1;

    @include mq(sm) {
      grid-column: span 2;

      &:last-child:nth-child(2n+1) {
        grid-column-end: -2;
      }
    }

    @include mq(md) {
      &:last-child:nth-child(2n+1) {
        grid-column-end: inherit;
      }

      &:nth-last-child(2):nth-child(3n+1) {
         grid-column-end: -4;
      }
    }
  }

  &__value {
    font-family: $font-family--secondary;
    display: block;
    font-size: rem(72px);
    line-height: 1;
    margin-bottom: 12px;

    @include mq(xl) {
      font-size: rem(96px);
    }
  }
}

.links {
  padding: 30px 0 60px;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;
    text-align: center;

    @include mq(sm) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include mq(lg) {
      grid-template-columns: repeat(6, 1fr);
    }
  }
}

.link_item {
  grid-column: span 2;
  background-color: $color--primary;
  position: relative;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: darken($color--primary, 10%);

    .link_item {
      &__figure {
        clip-path: polygon(0.000% 0.000%, 0.000% 90%, 100.000% 60%, 100.000% 0.000%);
      }
    }
  }

  &:nth-child(even) {
    background-color: $color--secondary;

    &:hover {
      background-color: darken($color--secondary, 10%);
    }

    .link_item {
      &__figure {
        background: darken($color--secondary, 10%);
      }
    }
  }

  @include mq(sm) {
    &:last-child:nth-child(2n+1) {
      grid-column-end: -2;
    }
  }

  @include mq(lg) {
    &:last-child:nth-child(2n+1) {
      grid-column-end: inherit;
    }

    &:last-child:nth-child(3n+1) {
      grid-column-end: -3;
    }

    &:nth-last-child(2):nth-child(3n+1) {
      grid-column-end: -4;
    }
  }

  &__figure {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: darken($color--primary, 10%);
    transition: all 0.3s ease-in-out;
    clip-path: polygon(0.000% 0.000%, 0.000% 100%, 100.000% 85%, 100.000% 0.000%);

    @include aspect-ratio(410, 298);
  }

  &__title {
    padding: 24px 32px 32px;
  }

  &:deep(.link_item__link) {
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>