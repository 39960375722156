<template>
  <main class="main home">
    <section
      class="banner"
      :style="{backgroundImage: `url(${$i18n.translate(banner.image)})`}"
    >
      <div class="wrapper banner__wrapper">
        <article class="banner__inner">
          <h1
            class="banner__title bold"
            v-if="$i18n.translate(banner.title)"
          >
            {{ $i18n.translate(banner.title) }}
          </h1>

          <div
            class="editor__content banner__text margin__t--24"
            v-if="$i18n.translate(banner.text)"
            v-html="$i18n.translate(banner.text)"
          />

          <footer
            v-if="banner.buttons.length > 0"
            class="banner__buttons"
          >
            <a
              v-for="(button, key) in banner.buttons"
              :class="`cta btn__color--${button.color} btn__size--classic banner__button`"
              :key="`banner_btn_${key}`"
              @click.prevent="setRouteNavigation(button.button)"
            >
              <span>{{ $i18n.translate(button.button.text) }}</span>
            </a>
          </footer>
        </article>
      </div>
    </section>

    <section
      class="campaigns"
      v-if="campaign_items.length > 0"
    >
      <div class="wrapper campaigns__wrapper">
        <div class="campaigns__inner">
          <h2
            class="campaigns__title title-h2 bold margin__b--48"
            v-if="$i18n.translate(campaigns.title)"
          >
            {{ $i18n.translate(campaigns.title) }}
          </h2>

          <article
            class="media-text campaigns__campaign"
            :class="{'media-text--inverse': key % 2}"
            v-for="(item, key) in campaign_items"
            :key="`campaign_${item.id}`"
          >
            <figure
              class="media-text__figure"
              :style="{backgroundImage: `url(${item.image.small.url})`}"
            >
              <figcaption class="media-text__figcaption">
                {{ $i18n.translate(item.name) }}
              </figcaption>
            </figure>

            <div class="media-text__content">
              <h3
                class="title-h3 bold"
                v-if="$i18n.translate(item.name)"
              >
                {{ $i18n.translate(item.name) }}
              </h3>

              <div
                class="editor__content banner__description margin__t--18"
                v-if="$i18n.translate(item.description)"
                v-html="$i18n.translate(item.description)"
              />

              <button
                class="cta btn__color--prim btn__size--classic margin__t--24"
                @click.prevent="setRouteNavigation(item.learn_more)"
                v-if="item.learn_more.show"
              >
                {{ $i18n.translate(item.learn_more.text) }}
              </button>

              <button
                class="cta btn__color--prim btn__size--classic margin__t--24"
                @click.prevent="setRouteNavigation(item.cta)"
                v-else
              >
                {{ $i18n.translate(item.cta.text) }}
              </button>
            </div>
          </article>
        </div>
      </div>
    </section>

    <section
      class="projects"
      v-if="project_items.length"
    >
      <div class="wrapper">
        <h2
          class="campaigns__title title-h2 bold margin__b--48"
          v-if="$i18n.translate(projects.title)"
        >
          {{ $i18n.translate(projects.title) }}
        </h2>

        <div class="projects__list row">
          <template
            v-for="project in project_items"
            :key="`project_${project.id}`"
          >
            <div class="col-12 col-sm-6 col-md-3">
              <project-card
                class="project-card"
                :project="project"
              />
            </div>
          </template>
        </div>

        <footer
          v-if="projects.buttons.length > 0"
          class="projects__buttons"
        >
          <a
            v-for="(button, key) in banner.buttons"
            :class="`cta btn__color--${button.color} btn__size--classic projects__button`"
            :key="`projects_btn_${key}`"
            @click.prevent="setRouteNavigation(button.button)"
          >
            <span>{{ $i18n.translate(button.button.text) }}</span>
          </a>
        </footer>
      </div>
    </section>

    <section
      class="partners"
      v-if="partners.items.length > 0"
    >
      <div class="wrapper partners__wrapper">
        <div class="partners__inner">
          <h2
            class="title-h2 partners__title bold"
            v-if="$i18n.translate(partners.title)"
          >
            {{ $i18n.translate(partners.title) }}
          </h2>

          <div class="partners__slider slider">
            <div
              class="slider__figure"
              ref="slider-figure"
            >
              <div
                class="slider__images"
                :class="{'is-animated': has_transition}"
                ref="slider-images"
                :style="sliderImagesStyle"
              >
                <figure
                  class="slider__image"
                  :style="{backgroundImage: `url(${$i18n.translate(slide.image)})`}"
                  v-for="(slide, key) in clonedSlides"
                  :key="`partner_image_${key}`"
                />
              </div>
            </div>

            <div
              class="slider__content"
              ref="slider-content"
            >
              <div
                class="slider__slides"
                :class="{'is-animated': has_transition}"
                ref="slider-slides"
                :style="sliderSlidesStyle"
              >
                <article
                  class="slider__slide"
                  v-for="(slide, key) in clonedSlides"
                  :key="`partner_${key}`"
                >
                  <img
                    :src="$i18n.translate(slide.logo)"
                    :alt="$i18n.translate(slide.name)"
                    v-if="$i18n.translate(slide.logo)"
                    class="partner__logo margin__b--24"
                  >

                  <div
                    class="editor__content editor partner__text"
                    v-if="$i18n.translate(slide.text)"
                    v-html="$i18n.translate(slide.text)"
                  />

                  <button
                    class="cta btn__color--prim btn__size--classic margin__t--24"
                    @click.prevent="setRouteNavigation(slide.button)"
                    v-if="slide.button && slide.button.show"
                  >
                    {{ $i18n.translate(slide.button.text) }}
                  </button>
                </article>
              </div>
            </div>

            <nav
              v-if="partners.items.length > 1"
              class="slider__nav"
            >
              <button
                class="slider__nav-button slider__nav-button--left"
                @click="goToPrev()"
              >
                <fa-icon :icon="['fal', 'arrow-left']" />
              </button>

              <button
                class="slider__nav-button slider__nav-button--right"
                @click="goToNext()"
              >
                <fa-icon :icon="['fal', 'arrow-right']" />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </section>

    <section class="goals">
      <div class="wrapper">
        <div
          class="editor__content goals__text"
          v-if="$i18n.translate(goals.text)"
          v-html="$i18n.translate(goals.text)"
        />

        <div
          class="goals__items"
          v-if="goals.items.length > 0"
        >
          <article
            class="goals__item"
            v-for="(item, key) in goals.items"
            :key="`goal_${key}`"
          >
            <h2
              class="title-h4 goals__item-title bold"
              v-if="$i18n.translate(item.title)"
              v-html="parseText($i18n.translate(item.title))"
            />

            <div
              class="editor__content editor goals__item-text margin__t--18"
              v-if="$i18n.translate(item.text)"
              v-html="$i18n.translate(item.text)"
            />
          </article>
        </div>
      </div>
    </section>

    <section class="about">
      <div class="wrapper about__wrapper">
        <h2
          class="about__title title-h2 bold margin__b--60"
          v-if="$i18n.translate(about.main_title)"
        >
          {{ $i18n.translate(about.main_title) }}
        </h2>

        <div class="about__inner">
          <article class="media-text media-text--inverse">
            <figure
              class="media-text__figure"
              :style="{backgroundImage: `url(${$i18n.translate(about.image)})`}"
            >
              <figcaption class="media-text__figcaption">
                {{ $i18n.translate(about.title) }}
              </figcaption>
            </figure>

            <div class="media-text__content">
              <h3
                class="title-h3 bold"
                v-if="$i18n.translate(about.title)"
              >
                {{ $i18n.translate(about.title) }}
              </h3>

              <div
                class="editor__content banner__description margin__t--18"
                v-if="$i18n.translate(about.text)"
                v-html="$i18n.translate(about.text)"
              />

              <button
                class="cta btn__color--prim btn__size--classic margin__t--24"
                v-if="about.button.show"
              >
                {{ $i18n.translate(about.button.text) }}
              </button>
            </div>
          </article>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'
import ProjectCard from '&/organisms/ProjectCard'

export default defineComponent({
  name: 'CustomHomepageUMons',
  mixins: [PageRouteNavigation],
  components: { ProjectCard },
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      index: 0,
      has_transition: true,
      slider_images_width: 0,
      slider_slides_width: 0,
      campaign_items: [],
      project_items: []
    }
  },
  created () {
    this.fetchProjects({
      query: _get(this.projects, 'query', {
        'sort_by_field[field]': 'current_amount',
        'sort_by_field[direction]': 'desc',
        'per_page': 4
      }), removeStoredItems: true
    })
      .then(data => this.project_items = Object.values(data.items))

    if (this.campaigns.ids.length) {
      this.fetchCampaigns({ query: { campaign_id: this.campaigns.ids } })
        .then(data => this.campaign_items = Object.values(data.items))
    }
  },
  mounted () {
    window.addEventListener('resize', this.onResize)

    this.onResize()
  },
  computed: {
    banner () {
      return _get(this.section, 'attributes.banner', {})
    },
    campaigns () {
      return _get(this.section, 'attributes.campaigns', {})
    },
    projects () {
      return _get(this.section, 'attributes.projects', {})
    },
    partners () {
      return _get(this.section, 'attributes.partners', {})
    },
    goals () {
      return _get(this.section, 'attributes.goals', {})
    },
    about () {
      return _get(this.section, 'attributes.about', {})
    },
    sliderImagesStyle () {
      if (this.slides.length <= 1) return {}

      return {
        left: -this.slider_images_width * 2 + 'px',
        transform: `translateX(${this.index * -this.slider_images_width + 'px'})`
      }
    },
    sliderSlidesStyle () {
      if (this.slides.length <= 1) return {}

      return {
        left: -this.slider_slides_width * 2 + 'px',
        transform: `translateX(${this.index * -this.slider_slides_width + 'px'})`
      }
    },
    slides () {
      return _get(this.partners, 'items', {})
    },
    clonedSlides () {
      if (this.slides.length <= 1) return this.slides

      const slides = [...this.slides]

      for (let i = 1; i <= 2; i++) {
        slides.unshift(this.slides[this.slides.length - i])
      }

      for (let i = 0; i < 2; i++) {
        slides.push(this.slides[i])
      }

      return slides
    }
  },
  methods: {
    ...mapActions({
      fetchProjects: 'projects/fetchProjects',
      fetchCampaigns: 'campaigns/fetchCampaigns'
    }),
    parseText (text) {
      return text.replace(/<red>(.*?)<\/red>/g, '<span>$1</span> ')
    },
    goToPrev () {
      if (this.index <= 0) {
        this.has_transition = false
        this.$refs.slides.style.transform = `translateX(${-this.width * this.slides.length + 'px'})`

        this.$refs['slider-images'].style.transform = `translateX(${-this.slider_images_width * this.slides.length + 'px'})`
        this.$refs['slider-slides'].style.transform = `translateX(${-this.slider_slides_width * this.slides.length + 'px'})`

        setTimeout(() => {
          this.has_transition = true
          this.index = this.slides.length - 1
        })
      } else {
        this.index--
      }
    },
    goToNext () {
      if (this.index >= this.slides.length - 1) {
        this.has_transition = false
        this.$refs['slider-images'].style.transform = `translateX(${-this.slider_images_width * -1 + 'px'})`
        this.$refs['slider-slides'].style.transform = `translateX(${-this.slider_slides_width * -1 + 'px'})`

        setTimeout(() => {
          this.has_transition = true
          this.index = 0
        })
      } else {
        this.index++
      }
    },
    onResize () {
      if (this.slides.length) {
        if (this.$refs['slider-figure'])
          this.slider_images_width = this.$refs['slider-figure'].clientWidth

        if (this.$refs['slider-content'])
          this.slider_slides_width = this.$refs['slider-content'].clientWidth
      }
    }
  },
  unmounted () {
    window.removeEventListener('resize', this.onResize)
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #1D97B1;
$color--secondary: #B80237;

.wrapper {
  max-width: 1318px;
  margin: 0 auto;
  padding: 0 $padding__base * 1.5;

  @include mq(sm) {
    padding: 0 $padding__base * 2;
  }
}

.cta {
  cursor: pointer;
  transition: all $transition__duration;
  font-weight: bold;
  font-size: rem(14px);
}

.title {
  &-h2 {
    font-size: rem(36px);
    line-height: 1.3;

    @include mq(sm) {
      font-size: rem(42px);
    }
  }

  &-h3 {
    line-height: 1.3;
    font-size: rem(28px);
  }

  &-h4 {
    line-height: 1.3;
    font-size: rem(24px);
  }
}

.editor {
  & :deep() ul {
    list-style: none;

    li {
      display: flex;

      &::before {
        content: "\2022";
        color: $color--secondary;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        flex: 0 0;
        margin-right: $margin__base;
      }
    }
  }
}


.media-text {
  position: relative;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);

  @include mq(sm) {
    width: auto;
  }

  @include mq(md) {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
  }

  &__figure {
    background-color: #D8D8D8;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include aspect-ratio(1, 1);

    @include mq(md) {
      position: absolute;
      width: 50%;
      height: 100%;
      z-index: 0;

      &::before {
        display: none;
      }
    }
  }

  &__figcaption {
    color: transparent;
    position: absolute;
    top: 0;
  }

  &__content {
    grid-column: 5 / 11;
    position: relative;
    z-index: 10;
    background: #F6F5F5;
    padding: $padding__base * 2;
    box-shadow: 12px 12px 20px 0 rgba(0,0,0,0.07);

    @include mq(sm) {
      padding: $padding__base * 4 $padding__base * 3.5;
    }

    @include mq(md) {
      margin: $margin__base * 10 0 $margin__base * 4;
    }
  }

  &--inverse {
    .media-text {
      &__figure {
        right: 0;
      }

      &__content {
        grid-column: 1 / 7;
      }
    }
  }
}

.banner {
  position: relative;
  background-color: $color--secondary;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;
  padding: $padding__base * 5 0;

  @include mq(sm) {
    padding: $padding__base * 9 0 $padding__base * 14;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #0F1A1D;
    opacity: 0.7;
    z-index: 0;
  }

  &__wrapper {
    position: relative;
    z-index: 10;

    @include mq(sm) {
      display: grid;
      gap: $margin__base * 2;
      grid-template-columns: repeat(12, 1fr);
    }
  }

  &__inner {
    max-width: 800px;
    grid-column-start: 1;
    grid-column-end: 10;

    @include mq(md) {
      grid-column-start: 2;
      grid-column-end: 9;
    }
  }

  &__title {
    font-size: rem(42px);
  }

  &__text {
    font-size: rem(18px);

    @include mq(sm) {
      font-size: rem(20px);
    }
  }

  &__buttons {
    margin-top: $margin__base * 4;
    display: flex;
    flex-wrap: wrap;
  }

  &__button {
    margin-top: $margin__base;
    width: 100%;
    text-align: center;

    @include mq(sm) {
      width: auto;

      &:not(:last-child) {
        margin-right: $margin__base * 1.5;
      }
    }
  }
}

.campaigns {
  padding: $padding__base * 5 0;

  &__wrapper {
    @include mq(lg) {
      display: grid;
      gap: $margin__base * 2;
      grid-template-columns: repeat(12, 1fr);
    }
  }

  &__inner {
    grid-column-start: 2;
    grid-column-end: 12;
  }

  &__campaign {
    &:not(:last-child) {
      margin-bottom: $margin__base * 4;
    }
  }
}

.projects {
  padding: $padding__base * 5 0;

  &__buttons {
    margin-top: $margin__base * 3;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__button {
    margin-top: $margin__base;
    width: 100%;
    text-align: center;

    @include mq(sm) {
      width: auto;

      &:not(:last-child) {
        margin-right: $margin__base * 1.5;
      }
    }
  }
}

.partners {
  background: #F6F5F5;
  padding: $padding__base * 5 0;

  @include mq(sm) {
    padding: $padding__base * 5 0 $padding__base * 10;
  }

  &__wrapper {
    @include mq(lg) {
      display: grid;
      gap: $margin__base * 2;
      grid-template-columns: repeat(12, 1fr);
    }
  }

  &__inner {
    grid-column-start: 2;
    grid-column-end: 12;
  }

  &__title {
    position: relative;
    z-index: 10;
    margin-bottom: $margin__base * 4;

    @include mq(md) {
      margin-bottom: 0;
    }
  }

  &__slider {
    position: relative;
    z-index: 0;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);

    @include mq(sm) {
      width: 100%;
      left: 0;
      transform: translateX(0);
    }

    @include mq(md) {
      margin-top: -$margin__base * 3.5;
    }
  }

  .partner {
    &__logo {
      max-width: 130px;
      height: auto;
    }
  }
}

.slider {
  display: grid;
  grid-template-columns: 1fr;
  position: relative;

  @include mq(md) {
    grid-template-columns: 4fr 6fr;
  }

  &__figure {
    position: relative;
    overflow: hidden;

    @include mq(md) {
      margin: $margin__base * 4.5 0 $margin__base * 6;

      &::before {
        display: none;
      }
    }
  }

  &__images {
    position: relative;
    display: flex;
    height: 100%;

    &.is-animated {
      transition: all .3s;
    }
  }

  &__image {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 0 0 100%;
    background-color: #D8D8D8;

    @include aspect-ratio(1, 1);

    @include mq(sm) {
      @include aspect-ratio(9, 5);
    }

    @include mq(md) {
      &::before {
        display: none;
      }
    }
  }

  &__content {
    position: relative;
    overflow: hidden;
    background: #FFFFFF;
    box-shadow: 12px 12px 20px 0 rgba(0,0,0,0.07);
  }

  &__slides {
    position: relative;
    display: flex;

    &.is-animated {
      transition: all .3s;
    }
  }

  &__slide {
    width: 100%;
    flex: 0 0 100%;
    padding: $padding__base * 2;

    @include mq(sm) {
      padding: $padding__base * 2.5 $padding__base * 5 $padding__base * 5;
    }
  }

  &__nav {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    display: flex;
    justify-content: center;
    top: -25px;

    @include mq(md) {
      top: 50%;
      justify-content: space-between;
    }

    &-button {
      background: $color--primary;
      color: $white;
      font-size: rem(18px);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      text-align: center;
      line-height: 50px;
      transition: all .3s;

      &:not(:last-child) {
        margin-right: $margin__base * 2;
      }

      @include mq(md) {
        margin: 0;
        font-size: rem(13px);
        width: 30px;
        height: 30px;
        line-height: 30px;
      }

      &:hover {
        background: shade($color--primary);
      }

      svg {
        position: relative;
        transition: all .3s;
      }

      &--left {
        position: relative;

        @include mq(md) {
          transform: translateX(-50%);
        }

        @include mq(lg) {
          left: -$margin__base * 6;
          transform: translateX(-100%);
        }

        &:hover {
          svg {
            @include mq(md) {
              transform: translateX(calc($margin__base / 2) * -1);
            }
          }
        }
      }

      &--right {
        position: relative;

        @include mq(md) {
          transform: translateX(50%);
        }

        @include mq(lg) {
          right: -$margin__base * 6;
          transform: translateX(100%);
        }

        &:hover {
          svg {
            @include mq(md) {
              transform: translateX(calc($margin__base / 2));
            }
          }
        }
      }
    }
  }
}

.goals {
  background: rgba(#5FB5C7, 0.14);
  padding: $padding__base * 5 0;

  @include mq(sm) {
    padding: $padding__base * 5 0 $padding__base * 10;
  }

  &__items {
    margin-top: $margin__base * 2.5;
    display: grid;
    grid-template-columns: 1fr;
    gap: $margin__base * 2;

    @include mq(sm) {
      margin-top: $margin__base * 5;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__item {
    background: #F6F5F5;
    box-shadow: 6px 6px 24px 0 rgba(0,0,0,0.10);
    padding: $padding__base * 2.5;

    @include mq(md) {
      padding: $padding__base * 4 $padding__base * 5;
    }

    &-title {
      & :deep() span {
        color: $color--secondary;
      }
    }

    &--text {
      & :deep() ul {
        li {
          &:not(:last-child) {
            margin-bottom: $margin__base * 2;
          }
        }
      }
    }
  }
}

.about {
  padding: $padding__base * 5 0;

  @include mq(sm) {
    padding: $padding__base * 4 0 $padding__base * 10;
  }

  &__wrapper {
    @include mq(lg) {
      display: grid;
      gap: $margin__base * 2;
      grid-template-columns: repeat(12, 1fr);
    }
  }

  &__inner {
    grid-column-start: 2;
    grid-column-end: 12;
  }

  &__title {
    color: $color--secondary;
    text-align: center;
    grid-column: 1 / 13;
  }
}
</style>
