<template>
  <header
    class="header bg__color--white"
    :class="{'is-sticky': show_mobile_menu}"
  >
    <div class="container header__container">
      <a
        class="logo"
        :href="translate(logo.url)"
        v-if="translate(logo.url)"
      >
        <img
          class="logo__img"
          :src="translate(logo.img) || client.logo.small.url"
          :alt="client.name"
        >
      </a>

      <router-link
        class="logo"
        :to="{ name: 'Home', params: { locale } }"
        v-else
      >
        <img
          class="logo__img"
          :src="translate(logo.img) || client.logo.small.url"
          :alt="client.name"
        >
      </router-link>

      <nav class="nav">
        <ul class="nav__ul">
          <li
            class="nav__li d-none d-md-block"
            v-for="({ link }, key) in nav.links"
            :key="`link_${key}`"
          >
            <app-link
              :link="link"
              class="nav__a link__color--prim link--naked"
              target="_self"
            />
          </li>

          <li class="nav__li">
            <language-switcher class="language-switcher" />
          </li>

          <li class="nav__li no-padding">
            <app-session-button
              class="session color--prim"
              :text="false"
            >
              <template #login>
                <fa-icon
                  class="session__icon"
                  :icon="['fal', 'user']"
                />
              </template>
            </app-session-button>
          </li>
        </ul>

        <div class="header__actions">
          <div class="increase-text d-none d-md-block">
            <button class="increase-text__action">
              <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzciIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAzNyAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBhcmlhLWhpZGRlbj0idHJ1ZSI+CiAgICAgICAgICAgICAgICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjMuNzA2MSAwTDE0LjkxNyAyMS4wMDU5SDE3LjkzNDZMMjAuMTMxOCAxNS42NTkySDMxLjExODJMMzMuMjcxNSAyMS4wMDU5SDM2LjMzM0wyNy41NDM5IDBIMjMuNzA2MVpNMzAuMDkyOCAxMy4xMzk2SDIxLjEyNzlMMjUuNjI1IDIuMTk3MjdMMzAuMDkyOCAxMy4xMzk2Wk01Ljg1OTM4IDcuMDAxOTVMMCAyMS4wMDU5SDIuMDExNzJMMy40NzY1NiAxNy40NDE0SDEwLjgwMDhMMTIuMjM2MyAyMS4wMDU5SDE0LjI3NzNMOC40MTc5NyA3LjAwMTk1SDUuODU5MzhaTTEwLjExNzIgMTUuNzYxN0g0LjE0MDYyTDcuMTM4NjcgOC40NjY4TDEwLjExNzIgMTUuNzYxN1oiIGZpbGw9IiM0MjI3NzgiIGZpbGwtb3BhY2l0eT0iMC41Ij48L3BhdGg+CiAgICAgICAgICAgICAgICA8L3N2Zz4=">
            </button>

            <ul class="increase-text__ul">
              <li
                class="increase-text__li"
                v-for="size in [100, 140, 180]"
                :key="`font_size_${size}`"
              >
                <button
                  :class="`link__color--prim link--naked increase-text__btn increase-text__btn--${size}`"
                  :aria-label="`Adjust the text size to ${size}%`"
                  @click="setFontSize(size)"
                >
                  A
                </button>
              </li>
            </ul>
          </div>

          <button
            class="search-btn"
            :aria-label="translate(search.label) || 'Search'"
            @click="show_search = !show_search"
          >
            <fa-icon
              aria-hidden="true"
              :icon="['fal', show_search ? 'xmark-large' : 'magnifying-glass']"
              fixed-width
            />
          </button>
        </div>

        <app-burger-menu
          class="burger-menu d-md-none"
          :state="show_mobile_menu"
          @toggle="handleClickBurgerMenu()"
        />
      </nav>
    </div>

    <nav class="secondary-nav">
      <ul
        class="secondary-nav__ul container d-none d-md-flex flex-wrap"
        :class="{ 'secondary-nav__ul--is-hide': show_search }"
        v-if="secondaryNav.links.length"
      >
        <li
          class="secondary-nav__li"
          v-for="({ link }, key) in secondaryNav.links"
          :key="`link_${key}`"
        >
          <app-link
            :link="link"
            class="link__color--prim link--naked bold d-inline-block"
            target="_self"
          />
        </li>
      </ul>
    </nav>

    <form
      class="search"
      :action="translate(search.url)"
      v-if="translate(search.url) && translate(search.query) && show_search"
    >
      <fieldset class="container search__container d-flex align-items-center">
        <label
          for="search"
          class="sr-only"
        >
          {{ translate(search.label) || 'Search' }}
        </label>

        <input
          id="search"
          type="search"
          class="search__input"
          :name="translate(search.query)"
          :placeholder="translate(search.placeholder)"
        >

        <button
          type="submit"
          class="search__btn"
        >
          <fa-icon
            aria-hidden="true"
            :icon="['fal', 'magnifying-glass']"
            fixed-width
          />
        </button>
      </fieldset>
    </form>

    <transition name="fade-mobile-menu">
      <nav
        class="mobile-menu"
        v-if="show_mobile_menu"
      >
        <ul
          class="mobile-nav mobile-nav--prim bg__color--prim"
          v-if="secondaryNav.links.length"
        >
          <li
            class="mobile-nav__li"
            v-for="({ link }, key) in secondaryNav.links"
            :key="`link_${key}`"
          >
            <app-link
              :link="link"
              class="mobile-nav__a link__color--white link--naked"
              target="_self"
            />
          </li>
        </ul>

        <div class="container">
          <div
            class="calltoactions padding__tb--30 d-flex align-items-center flex-wrap justify-content-center"
            v-if="callToActions.length"
          >
            <app-link
              :link="link"
              class="btn calltoactions__btn bold"
              :class="{ 'btn__color--vdw': key % 2 !== 0, 'btn__color--ter': key % 2 === 0 }"
              target="_self"
              v-for="({ link }, key) in callToActions"
              :key="`cta_${key}`"
            />
          </div>

          <ul
            class="mobile-nav mobile-nav--sec align--center"
            v-if="nav.links.length"
          >
            <li
              class="mobile-nav__li"
              v-for="({ link }, key) in nav.links"
              :key="`link_${key}`"
            >
              <app-link
                :link="link"
                class="link__color--prim link--naked mobile-nav__a"
                target="_self"
              />
            </li>
          </ul>
        </div>
      </nav>
    </transition>
  </header>
</template>

<script setup>
import { ref, computed, toRefs, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useClientStore } from '@/stores/ClientStore'
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'
import { scrollTo } from '@/utils/utils'

import AppLink from '&/atoms/AppLink'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
import LanguageSwitcher from '&/atoms/LanguageSwitcher'
import AppSessionButton from '&/organisms/AppSessionButton'

const props = defineProps({ section: { type: Object, required: true } })

const route = useRoute()
const { getClient } = useClientStore()
const { translate } = useI18n()
const { locale } = toRefs(useI18n())

const { client } = getClient()

const show_mobile_menu = ref(false)
const show_search = ref(false)

const logo = computed(() => _get(props, 'section.attributes.logo', {}))
const nav = computed(() => _get(props, 'section.attributes.nav', {}))
const secondaryNav = computed(() => _get(props, 'section.attributes.secondary_nav', {}))
const search = computed(() => _get(props, 'section.attributes.search', {}))
const callToActions = computed(() => _get(props, 'section.attributes.call_to_actions', []))

const setFontSize = percentage => {
  const font_size = parseInt(_get(client.value, 'style.typefaces.0.size', '18px').replace('px', ''))

  document.documentElement.style.fontSize = `${(font_size * percentage) / 100}px`
}

const handleClickBurgerMenu = () => {
  scrollTo()
  show_mobile_menu.value = !show_mobile_menu.value
}

watch(route, () => show_mobile_menu.value = false)
</script>

<style lang="scss" scoped>
$font-size__base: 18px;

.header {
  position: relative;
  z-index: 999;

  &.is-sticky {
    position: sticky;
    width: 100%;
    top: 0;

    @include mq(md) {
      position: relative;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90px;
    position: relative;
    z-index: 10;
    background: $white;
    gap: 20px;

    @include mq(md) {
      height: auto;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

.container {
  max-width: 1430px;
  padding-left: 20px;
  padding-right: 20px;

  @include mq(md) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.logo {
  display: block;

  &__img {
    display: block;
    height: 45px;
    width: auto;

    @include mq(md) {
      height: 85px;
    }
  }
}

.nav {
  font-size: rem(15px);
  line-height: 1;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 20px;

  &__ul {
    display: flex;
    align-items: center;
    gap: 20px;

    @include mq(md) {
      gap: 0;
    }
  }

  &__li {
    @include mq(md) {
      padding: 4px 0;

      &:not(:last-child) {
        border-right: 1px solid $color--primary;
      }

      & > :deep(*),
      & > * {
        padding: 0 10px;
      }

      &:first-child > *,
      &:first-child > :deep(*) {
        padding-left: 0;
      }

      &:last-child > *,
      &:last-child > :deep(*) {
        padding-right: 0;
      }
    }

    @include mq(lg) {
      & > :deep(*),
      & > * {
        padding: 0 20px;
      }
    }
  }

  :deep(.nav__a) {
    display: block;
    line-height: 1;
  }
}

.secondary-nav {
  background: $vdw;

  &__ul {
    font-size: rem(22px);
    gap: 20px;
    text-transform: uppercase;
    line-height: 1.3;
    padding-top: 20px;
    padding-bottom: 20px;

    &--is-hide {
      opacity: 0;
    }
  }
}

.language-switcher {
  &__icon {
    margin-right: 6px;
  }

  &:deep() {
    .locale-switcher {
      background: transparent;
    }

    .v-select,
    .vs__search {
      font-size: 18px;
      border: 0px!important;
      color: $color--primary;

      @include mq(md) {
        font-size: 15px;
      }
    }

    .vs__selected {
      color: $color--primary;
      line-height: 1;
    }

    .vs__dropdown-toggle {
      padding: 0;
    }

    .vs__dropdown-option {
      text-transform: none;
      font-size: rem(12px);
    }

    .vs__actions {
      padding-left: 3px;
    }
  }
}

.search {
  width: 100%;
  background: $vdw;
  font-size: rem(13px);
  position: absolute;
  bottom: 0;
  transform: translateY(100%);

  @include mq(md) {
    transform: none;
  }

  &__input {
    line-height: 60px;
    background: none;
    border: 0;
    flex: 1 1 auto;

    &:focus {
      outline: none;
    }
  }

  &__btn {
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    font-size: rem(24px);
  }
}

.increase-text {
  position: relative;
  display: inline-block;

  &:hover {
    .increase-text__ul {
      display: inline-flex;
    }
  }

  &__action {
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color--primary;
  }

  &__ul {
    display: none;
    border: 1px solid $color--primary;
    padding: 10px;
    font-size: 10px;
    align-items: flex-end;
    gap: 4px;
    position: absolute;
    right: 0;
    bottom: 1px;
    transform: translateY(100%);
    background: $white;
  }

  &__btn {
    padding: 3px;
    border: 1px solid $color--primary;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    &--140 {
      font-size: 13px;
      width: 20px;
      height: 20px;
    }

    &--180 {
      font-size: 16px;
      width: 24px;
      height: 24px;
      padding: 5px;
    }
  }
}

.search-btn {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color--primary;
  font-size: 24px;

  @include mq(md) {
    width: 46px;
    border: 1px solid $color--primary;
  }
}

.burger-menu {
  :deep() {
    padding: 0;

    .menu__label {
      display: none;
    }

    .menu__line {
      width: 25px;
      background-color: $color--primary;
      margin-bottom: 5px;

      &:nth-child(3) {
        margin-bottom: 0;
      }
    }

    .menu__close {
      &::before,
      &::after {
        background-color: $color--primary;
      }
    }
  }
}

.mobile-menu {
  position: fixed;
  top: 0;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  padding-top: 90px;
  background: $white;
  z-index: 0;
  transform: translateX(0);
  transition: all .3s ease-in-out;

  @include mq(md) {
    display: none;
  }
}

.fade-mobile-menu-enter-active {
  animation: fadeTranslationX .5s ease-in-out;
}

.fade-mobile-menu-leave-active {
  animation: fadeTranslationX .5s ease-in-out reverse;
}

@keyframes fadeTranslationX {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.mobile-nav {
  font-size: rem(18px);
  color: $color--primary;

  &--prim {
    padding: 15px 30px;
    color: $white;
    font-size: rem(20px);
    text-transform: uppercase;
  }

  &__li {
    &:not(:last-child) {
      border-bottom: 1px solid currentColor;
    }
  }

  :deep(.mobile-nav__a) {
    padding: 20px 0;
    display: block;
  }
}

:deep(.session ) {
  font-size: 24px;

  @include mq(md) {
    font-size: 15px;

    .session__avatar {
      width: 23px;
      height: 23px;
    }
  }
}

.calltoactions {
  :deep(.calltoactions__btn) {
    padding: 20px;
    color: $color--primary;
    border-radius: 0;
    line-height: 1.3;
  }
}
</style>