<template>
  <div class="custom">
    <article class="banner">
      <div
        class="banner__img"
        :style="`background-image: url(${$i18n.translate(banner.background)})`"
      />

      <div class="container">
        <div class="banner__wrapper">
          <div class="banner__content">
            <h1
              class="banner__title"
              v-if="$i18n.translate(banner.title)"
            >
              {{ $i18n.translate(banner.title) }}
            </h1>

            <div
              class="banner__text editor editor__content"
              v-if="$i18n.translate(banner.text)"
              v-html="$i18n.translate(banner.text)"
            />
          </div>
        </div>
      </div>
    </article>

    <section
      class="why"
      v-if="why.length > 1"
    >
      <div class="container">
        <article
          class="media-text"
          v-for="(item, index) in why"
          :key="`media-text_why_${index}`"
        >
          <img
            class="media-text__bg"
            :src="$i18n.translate(item.img_src)"
            :alt="$i18n.translate(item.title)"
          >

          <div class="media-text__content">
            <h2
              class="title--h2"
              v-if="$i18n.translate(item.title)"
            >
              {{ $i18n.translate(item.title) }}
            </h2>

            <div
              class="editor editor__content"
              v-html="$i18n.translate(item.text)"
              v-if="$i18n.translate(item.text)"
            />
          </div>
        </article>
      </div>
    </section>

    <section
      class="how"
      v-if="how.videos.length > 0"
    >
      <div class="container">
        <h2
          class="how__title"
          v-if="$i18n.translate(how.title)"
        >
          {{ $i18n.translate(how.title) }}
        </h2>

        <div
          class="how__text editor editor__content"
          v-if="$i18n.translate(how.text)"
          v-html="$i18n.translate(how.text)"
        />

        <div class="how__wrapper">
          <ul class="how__ul">
            <li
              class="how__li"
              v-for="(video, index) in videos"
              :key="`video_${index}`"
            >
              <figure
                :style="{backgroundImage: `url(${$i18n.translate(video.img_src)})`}"
                class="how__figure"
                @click="video.modal = true"
              >
                <figcaption>{{ $i18n.translate(video.title) }}</figcaption>
              </figure>

              <div class="how__content">
                <h2
                  class="how__subtitle"
                  v-if="$i18n.translate(video.title)"
                >
                  {{ $i18n.translate(video.title) }}
                </h2>

                <div
                  class="editor editor__content"
                  v-html="$i18n.translate(video.text)"
                  v-if="$i18n.translate(video.text)"
                />
              </div>

              <app-modal
                v-if="video.modal"
                size="lg"
                @close="video.modal = false"
              >
                <template #body>
                  <app-video-embed :src="$i18n.translate(video.video_src)" />
                </template>
              </app-modal>
            </li>
          </ul>
        </div>

        <footer class="how__footer">
          <button
            v-if="how.button && how.button.show && $i18n.translate(how.button.text)"
            @click.prevent="setRouteNavigation(how.button)"
            class="cta"
          >
            {{ $i18n.translate(how.button.text) }}
          </button>
        </footer>
      </div>
    </section>

    <section
      class="goal"
      v-if="goals.length > 0"
    >
      <div class="container">
        <article
          class="media-text goal__item"
          v-for="(item, index) in goals"
          :key="`media-text_goal_${index}`"
        >
          <img
            class="media-text__bg"
            :src="$i18n.translate(item.img_src)"
            :alt="$i18n.translate(item.title)"
          >

          <div class="media-text__content">
            <h2 class="title--h2">
              {{ $i18n.translate(item.title) }}
            </h2>

            <div
              class="editor editor__content"
              v-html="$i18n.translate(item.text)"
            />
          </div>
        </article>
      </div>
    </section>
  </div>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'

export default defineComponent({
  name: 'CustomTowalkagainWaaromsteunen',
  components: {
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal')),
    AppVideoEmbed: defineAsyncComponent(() => import('&/atoms/AppVideoEmbed'))
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  mixins: [
    PageRouteNavigation
  ],
  data () {
    return {
      videos: {}
    }
  },
  created () {
    this.videos = _get(this.how, 'videos', []).map(video => ({ ...video, modal: false }))
  },
  computed: {
    attributes () {
      return _get(this.section, 'attributes', {})
    },
    banner () {
      return _get(this.attributes, 'banner', {})
    },
    why () {
      return _get(this.attributes, 'why.items', [])
    },
    how () {
      return _get(this.attributes, 'how', {})
    },
    goals () {
      return _get(this.attributes, 'goal.items', [])
    }
  }
})
</script>

<style lang="scss" scoped>
.media-text {
  position: relative;
  padding: 20px 0;

  &:nth-child(odd) {
    flex-direction: row-reverse;
  }

  @include mq(md) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 60px 0;
  }

  &__bg {
    margin-bottom: 30px;
    width: 100%;

    @include mq(md) {
      width: 400px;
    }

    @include mq(lg) {
      width: 560px;
    }
  }

  &__content {
    flex: 0 1 47%;
  }

  & .title--h2 {
    margin-bottom: 25px;
    color: #131415;
    font-size: 26px;
    padding-left: 35px;
    position: relative;
    font-weight: bold;
    text-transform: uppercase;
    font-style: italic;
    font-family: $font-family--secondary;

    &:before {
      content: image-path('towalkagain/triangle.svg');
      position: absolute;
      left: 0;
    }
  }

  & .cta {
    margin: 48px 0;
  }
}

.cta {
  background-color: $color--primary;
  padding: 12px 24px;
  text-transform: uppercase;
  font-size: 16px;
  border-radius: 3px;
  font-weight: bold;
  transition: all .3s;
  font-family: $font-family--secondary;
  font-style: italic;

  &:hover {
    background: shade($color--primary, 3%, 47%);
  }
}

.banner {
  font-size: rem(16px);
  position: relative;
  z-index: 10;
  color: $white;
  background-color: #333;
  padding: 40px 0;

  @include mq(md) {
    padding: 49px 0 179px 0;
    font-size: rem(18px);
  }

  &__img {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: .8;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__wrapper {
    position: relative;
    z-index: 10;
    display: flex;
    max-width: 525px;
    background: rgba(248,248,248,.7);

    @include mq(md) {
      justify-content: flex-start;
    }
  }

  &__content {
    padding: 50px;
    color: $black;
  }

  &__title {
    font-size: rem(26px);
    margin-bottom: 9px;
    font-weight: bold;
    font-style: italic;
    font-family: $font-family--secondary;

    @include mq(md) {
      font-size: rem(34px);
    }
  }

  &__text {
    margin: 18px 0;
  }
}

.why {
  background: $white;
  padding: 60px 20px;
}

.how {
  position: relative;
  padding: 98px 0 48px;
  background-color: #f7f7f1;

  &__title {
    margin-bottom: 25px;
    color: #131415;
    font-size: 26px;
    padding-left: 35px;
    position: relative;
    font-weight: bold;
    text-transform: uppercase;
    font-style: italic;
    font-family: $font-family--secondary;
    text-align: center;
    max-width: 920px;
    margin: 0 auto 25px;

    &:before {
      content: image-path('towalkagain/triangle.svg');
      position: relative;
      transform: translateX(-100%);
      left: -12px;
    }
  }

  &__text {
    text-align: center;
    max-width: 920px;
    margin: 0 auto 48px;
  }

  &__footer {
    text-align: center;
  }

  &__ul {
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;
    max-width: 1140px;
    margin: 0 auto;

    @include mq(md) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: $margin--base * 3;
    }
  }

  &__figure {
    background-color: $vlg;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;

    @include aspect-ratio(1, 1);

    figcaption {
      position: absolute;
      color: transparent;
      top: 0;
    }
  }

  &__content {
    padding: $padding__base * 2;
    font-size: rem(15px);

    @include mq(sm) {
      padding: $padding__base * 3;
    }
  }

  &__subtitle {
    font-size: 20px;
    font-style: italic;
    font-weight: bold;
    margin-bottom: 24px;
    text-transform: uppercase;
    font-family: $font-family--secondary;
  }
}

.goal {
  background: $white;
  padding: 60px 20px;

  &__item {
    flex-direction: row-reverse;

    &:nth-child(odd) {
      flex-direction: row;
    }
  }
}
</style>
