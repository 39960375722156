<template>
  <footer
    v-if="section"
    class="footer footer__with-columns"
  >
    <div class="footer__container">
      <section class="footer__column">
        <router-link
          :to="{ name: 'Home', params: { locale: $i18n.locale } }"
          v-show="client.logo.url"
        >
          <img
            class="logo__img"
            :src="client.logo.url"
            :alt="client.name"
          >
        </router-link>
      </section>

      <section class="footer__column">
        <ul>
          <li
            class="footer__li"
            v-for="(item, menuIndex) in column_1.links"
            :key="'footer_menu_item_' + menuIndex"
          >
            <a
              class="footer__link link--naked"
              :class="{'footer__link--active': isVisited(item.link)}"
              v-if="item"
              @click.prevent="setRouteNavigation(item.link)"
            >
              <fa-icon
                class="margin__r--6"
                v-if="item.link.icon"
                :icon="item.link.icon"
              />
              {{ $i18n.translate(item.link.text) }}
            </a>
          </li>
        </ul>
      </section>

      <section class="footer__column">
        <h3
          class="title--h3"
          v-if="column_2.title"
        >
          {{ column_2.title[$i18n.locale] }}
        </h3>

        <p
          class="footer__text margin__b--24"
          v-if="$i18n.translate(column_2.name)"
        >
          {{ $i18n.translate(column_2.name) }}
        </p>

        <address v-if="column_2.address && column_2.address.length > 0">
          <span
            v-for="(addressLine, i) in column_2.address"
            :key="'address_' + i"
          >
            {{ $i18n.translate(addressLine.text) }} <br>
          </span>
        </address>
      </section>

      <section class="footer__column">
        <address
          v-if="contactInfos.length > 0"
          class="footer--contact margin__t--24"
        >
          <template v-for="(item, index) in contactInfos">
            <a
              class="footer__link d-block"
              :key="'footer_infos_line_' + index"
              :href="item.url"
              v-if="item.url"
              target="_blank"
            >
              {{ item.text }}
            </a>

            <span
              v-else
              class="footer__link d-block"
              :key="'footer_infos_line_' + index"
            >
              {{ item.text }}
            </span>
          </template>
        </address>
      </section>

      <section class="footer__column">
        <ul
          class="networks__ul"
          v-if="socialNetworks.length > 0 && socialNetworks"
        >
          <li
            class="networks__li"
            v-for="(network, i) in socialNetworks"
            :key="'footer_menu_network_' + i"
          >
            <a
              class="networks__a"
              :href="network.url[$i18n.locale]"
              target="_blank"
            >
              <span
                class="networks__span"
                v-if="network.text"
              >
                {{ $i18n.translate(network.text) }}
              </span>

              <fa-icon
                v-if="network.icon"
                size="lg"
                :icon="network.icon"
              />
            </a>
          </li>
        </ul>
      </section>
    </div>
  </footer>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'

export default defineComponent({
  name: 'CustomFooterIlesDePaix',
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client'
    }),
    socialNetworks () {
      const links = _get(this.client, 'platform.social_networks') || []
      return links.length > 0 ? links.filter(link => link.url[this.$i18n.locale]) : []
    },
    columns () {
      return _get(...this.column_1, ...this.column_2, ...this.column_3)
    },
    column_1 () {
      return _get(this.section, 'attributes.column_1', [])
    },
    column_2 () {
      return _get(this.section, 'attributes.column_2', [])
    },
    column_3 () {
      return _get(this.section, 'attributes.column_3', [])
    },
    contactInfos () {
      return _get(this.column_3, 'contact', [])
        .map(info => {
          return {
            text: this.$i18n.translate(info.text),
            url: info.type !== 'text' ? (info.type === 'phone_number' ? 'tel:' : 'mailto:') + this.$i18n.translate(info.text).replace(/ /g, '') : null
          }
        })
        .filter(info => info.text)
    }
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #cba89e;
$color--secondary: #b27d71;
$color--tertiary: #2c2c2b;

.footer {
  position: relative;
  background-color: $color--primary;
  background-position: 70% 80%;

  &__container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: $margin--base * 2;
    justify-items: center;
    align-items: center;
    text-align: center;

    @include mq(sm) {
      grid-template-columns: repeat(3, 1fr);
      text-align: left;
    }

    @include mq(md) {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &__with-columns {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 2;
    }
  }

  &__li {
    margin: 6px 0 6px 0;

    @include mq(md) {
      margin: 12px 0;
    }
  }

  &__text {
    margin-top: $margin--base;
    color: $color--tertiary;
  }

  &__button {
    font-family: $font-family--secondary;
    border-radius: 50px;
    font-size: rem(16px);
    padding: $padding__base $padding__base * 2;
    transition: all $transition__duration;
    display: inline-block;
    background-color: $color--tertiary;
    color: $white;

    &:focus, &:hover {
      background-color: shade($color--tertiary);
    }
  }

  &__link {
    color: $color--tertiary;
    display: inline-block;
    position: relative;
    font-weight: bold;
    transition: all .3s;

    &:hover, &--active {
      color: $color--secondary;
    }
  }
}

.networks {
  &__ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: $margin--base * 1.5;
    justify-items: center;
    align-items: center;
  }

  &__a {
    color: $color--tertiary;
    transition: all .3s;

    &:hover, &:focus {
      color: $color--secondary;
    }
  }

  &__span {
    display: none;
  }
}

.title {
  &--h3 {
    font-family: $font-family--secondary;
    font-size: rem(20px);
    color: $color--tertiary;
    position: relative;
    display: inline-block;
    font-weight: bold;
  }
}

.logo {
  &__img {
    width: 100%;
    max-width: 60px;
    display: inline-block;
  }
}
</style>
