export default [
  {
    name: 'custom_footer_refugeewalk',
    path: 'modules/pages/vluchtelingenwerkvlaanderen/FooterVluchtelingenwerkvlaanderen'
  },
  {
    name: 'custom_header_refugeewalk',
    path: 'modules/pages/vluchtelingenwerkvlaanderen/HeaderVluchtelingenwerkvlaanderen'
  },
  {
    name: 'custom_homepage_refugeewalk',
    path: 'modules/pages/vluchtelingenwerkvlaanderen/HomepageVluchtelingenwerkvlaanderen'
  }
]
