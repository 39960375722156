import { toValue } from 'vue'
import { cloneDeep as _cloneDeep, get as _get, set as _set, omit as _omit } from 'lodash'

export default function resetStore ({ store }) {
  const initialState = _cloneDeep(store.$state)

  store.$resetStore = keys => {
    if (!keys) return store.$state = _cloneDeep(initialState)

    if (!Array.isArray(keys)) keys = [keys]

    keys.forEach(key => _set(store.$state, key, _cloneDeep(_get(initialState, key, {}))))
  }

  store.$resetStoreItem = ({ ressource, key }) => {
    if (!toValue(ressource) || !toValue(key)) return store.$state = _cloneDeep(initialState)

    _set(store.$state, toValue(ressource), _omit(_get(store.$state, toValue(ressource), {}), [toValue(key)]))
  }
}