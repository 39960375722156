<template>
  <div class="align--center">
    <fa-icon
      :icon="['fal', 'inbox-full']"
      size="5x color--prim"
    />

    <p class="margin__t--24">
      {{ t('session.account_verification_description') }}
    </p>

    <send-verification-email-button class="btn btn__size--medium btn__color--prim margin__t--24" />
  </div>
</template>

<script setup>
import { useI18n } from '@/vendors/i18n'

import SendVerificationEmailButton from '&/molecules/SendVerificationEmailButton'

const { t } = useI18n()
</script>