import { Extension } from '@tiptap/core'
import { get as _get } from 'lodash'

export default Extension.create({
  addOptions () {
    return {
      types: [],
      styles: {}
    }
  },

  addGlobalAttributes () {
    return [
      {
        types: this.options.types,
        attributes: {
          isInlineStyle: { default: !!this.options.types.length, rendered: false },
          inlineStyles: { default: this.options.styles, rendered: false },
          rules: {
            default: null,
            renderHTML: attributes => {
              let { type } = attributes

              if (type === 'heading') type += `_h${attributes.level}`

              const style = _get(this.options.styles, type)

              return style ? { style } : {}
            }
          }
        }
      }
    ]
  }
})