import Step from '@/classes/procedures/Step'

export default class Steps extends Array {
  constructor (steps, ...rest) {
    Array.isArray(steps) ? super(...steps.map(step => new Step(step))) : super(...rest)
  }

  get success () {
    return this.find(step => step.name === 'success')
  }

  get has_success () {
    return this.some(step => step.name === 'success')
  }
}
