import axios from '@/vendors/axios/axios'
import queryString from 'query-string'
import Paginator from '@/classes/paginators/Paginator'

export default {
  fetchItem ({ commit }, { endpoint, resource, query, model, id }) {
    const q = query ? `?${queryString.stringify(query, { arrayFormat: 'bracket' })}` : ''

    return new Promise((resolve, reject) => {
      axios.get(endpoint + q)
        .then(response => {
          const item = model ? model(response.data.data) : response.data.data

          if (resource) {
            const $resource = id ? resource : `${resource}.items`
            const $id = id || item.id
            commit('setItem', { resource: $resource, id: $id, item })
          }

          resolve(item)
        })
        .catch(error => reject(error))
    })
  },
  fetchItems ({ commit }, { endpoint, resource, query, model, removeStoredItems = false }) {
    const q = query ? `?${queryString.stringify(query, { arrayFormat: 'bracket' })}` : ''

    return new Promise((resolve, reject) => {
      axios.get(endpoint + q)
        .then(response => {
          let items = response.data.data
          const paginator = response.data.paginator ? new Paginator(response.data.paginator) : null

          if (removeStoredItems && resource) {
            commit('deleteItems', { resource: `${resource}.items` })
          }

          items = items.map(item => {
            if (model) item = model(item)
            if (resource) {
              commit('setItem', { resource: `${resource}.items`, id: item.id, item })
            }
            return item
          })

          if (paginator && resource) {
            commit('setItem', { resource, id: 'paginator', item: paginator })
          }

          resolve({ items, paginator })
        })
        .catch(error => reject(error))
    })
  },
  postItem ({ commit }, { endpoint, resource, params, query, model, id }) {
    const q = query ? `?${queryString.stringify(query, { arrayFormat: 'bracket' })}` : ''

    return new Promise((resolve, reject) => {
      axios.post(endpoint + q, params)
        .then(response => {
          const item = model ? model(response.data.data) : response.data.data

          if (resource) {
            const $resource = id ? resource : `${resource}.items`
            const $id = id || item.id
            commit('setItem', { resource: $resource, id: $id, item })
          }

          resolve(item)
        })
        .catch(error => reject(error))
    })
  },
  postItems ({ commit }, { endpoint, resource, params, model }) {
    return new Promise((resolve, reject) => {
      axios.post(endpoint, params)
        .then(response => {
          let items = response.data.data

          items = items.map(item => {
            if (model) item = model(item)
            if (resource) commit('setItem', { resource: `${resource}.items`, id: item.id, item })
            return item
          })

          resolve(items)
        })
        .catch(error => reject(error))
    })
  },
  patchImage ({ commit }, { endpoint, resource, id, params, model }) {
    return new Promise((resolve, reject) => {
      axios.patch(endpoint, params, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          const item = model ? model(response.data.data) : response.data.data

          if (resource) {
            const $resource = id ? resource : `${resource}.items`
            const $id = id || item.id
            commit('setItem', { resource: $resource, id: $id, item })
          }

          resolve(item)
        })
        .catch(error => reject(error))
    })
  },
  patchItem ({ commit }, { endpoint, resource, params, query, model, id }) {
    const q = query ? `?${queryString.stringify(query, { arrayFormat: 'bracket' })}` : ''

    return new Promise((resolve, reject) => {
      axios.patch(endpoint + q, params)
        .then(response => {
          const item = model ? model(response.data.data) : response.data.data

          if (resource) {
            const $resource = id ? resource : `${resource}.items`
            const $id = id || item.id
            commit('setItem', { resource: $resource, id: $id, item })
          }

          resolve(item)
        })
        .catch(error => reject(error))
    })
  },
  deleteSingleItem ({ commit }, { endpoint, resource, id }) {
    return new Promise((resolve, reject) => {
      axios.delete(endpoint)
        .then(response => {
          const item = response.data.data

          if (resource && id) {
            commit('setItem', { resource, id, item })
          }

          resolve()
        })
        .catch(error => reject(error))
    })
  },
  deleteItem ({ commit }, { endpoint, resource, id }) {
    return new Promise((resolve, reject) => {
      axios.delete(endpoint)
        .then(response => {
          const item = response.data.data

          if (resource && id) {
            commit('deleteItem', { resource: `${resource}.items`, id })
          }

          resolve(item)
        })
        .catch(error => reject(error))
    })
  }
}
