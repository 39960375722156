import { useClientStore } from '@/stores/ClientStore'
import { useLanguageStore } from '@/stores/LanguageStore'
import { storeToRefs } from 'pinia'

const Page = () => import(/* webpackChunkName: 'Page' */'@/views/standalone/ViewPage')
const NotFound = () => import(/* webpackChunkName: 'NotFound' */'@/views/standalone/ViewNotFound')

const routes = [
  {
    path: '',
    name: 'Home',
    component: Page,
    meta: {
      layout: 'full'
    },
    beforeEnter (to, from, next) {
      const { getClient } = useClientStore()
      const { client } = getClient()
      const { locale } = storeToRefs(useLanguageStore())

      if (client.value && client.value.platform.redirection) {
        next({ name: 'Page', params: { path: client.value.platform.redirection, locale: locale.value } })
      } else {
        next()
      }
    }
  },
  {
    path: 'not-found',
    name: 'NotFound',
    meta: {
      layout: 'full'
    },
    component: NotFound
  },
  {
    path: ':path',
    name: 'Page',
    component: Page,
    props: true,
    meta: {
      layout: 'full'
    }
  }
]

export default routes
