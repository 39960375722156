import Axios from 'axios'
import { $cookies } from '@/utils/utils'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { storeToRefs } from 'pinia'

const API_URL = process.env.VUE_APP_ROOT_API

const axios = Axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

axios.CancelToken = Axios.CancelToken
axios.isCancel = Axios.isCancel

axios.interceptors.request.use(config => {
  const { auth_token } = storeToRefs(useAuthenticationStore())
  const token = auth_token.value = $cookies.get('authToken') || null
  config.headers['Authorization'] = token ? `Bearer ${token}` : ''
  return config
}, error => Promise.reject(error))

axios.interceptors.response.use(response => {
  return response
}, ({ response }) => Promise.reject(response))

export default axios
