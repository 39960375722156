<template>
  <header
    class="header padding--24"
    v-if="section"
  >
    <language-switcher
      class="languages"
      v-slot="{ options, updateLanguage, isActive }"
    >
      <ul class="header__language">
        <li
          class="header__language"
          v-for="language in options"
          :key="language.id"
        >
          <button
            class="header__language-btn link--naked"
            :class="{'header__language-btn--active': isActive(language.id)}"
            type="button"
            @click.prevent="updateLanguage(language.id)"
          >
            {{ language.label.value.toUpperCase() }}
          </button>
        </li>
      </ul>
    </language-switcher>
  </header>
</template>

<script setup>
import { toRefs } from 'vue'

import LanguageSwitcher from '&/atoms/LanguageSwitcher'

const props = defineProps({ section: { required: true, type: Object } })
const { section } = toRefs(props)
</script>

<style lang="scss" scoped>
$color--secondary: #ffd500;

.header {
  background: $white;

  &__language {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    gap: 6px;

    &-btn {
      color: $black;

      &:not(:last-child) {
        :after {
          content: '/';
          color: $black;
          font-weight: normal;
        }
      }

      &--active {
        color: $color--secondary;
        font-weight: bold;
      }
    }
  }
}
</style>
