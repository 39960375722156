<template>
  <ul
    class="pagination"
    v-if="totalPages > 1"
  >
    <li>
      <button
        class="pagination__arrow pagination__arrow--left"
        :class="{ 'disabled': paginator.is_first_page }"
        type="button"
        @click="handleClickPage(currentPage - 1)"
        :disabled="paginator.is_first_page"
        aria-label="Go to previous page"
      >
        <fa-icon :icon="['fas', 'chevron-left']" />
      </button>
    </li>

    <li class="pagination__item">
      <button
        class="pagination__button"
        type="button"
        @click="handleClickPage(1)"
        :disabled="paginator.is_first_page"
        :class="{ active: paginator.is_first_page }"
        aria-label="Go to first page"
      >
        1
      </button>
    </li>

    <li v-if="currentPage > 1 + (maxVisibleButtons - 1)">
      ...
    </li>

    <li
      v-for="page in pages"
      :key="page"
      class="pagination__item"
    >
      <button
        class="pagination__button"
        type="button"
        @click="handleClickPage(page)"
        :disabled="page === currentPage"
        :class="{ active: page === currentPage }"
        :aria-label="`Go to page number ${page}`"
      >
        {{ page }}
      </button>
    </li>

    <li v-if="currentPage < totalPages - (maxVisibleButtons - 1)">
      ...
    </li>

    <li
      class="pagination__item"
      v-if="totalPages !== 1"
    >
      <button
        class="pagination__button"
        type="button"
        @click="handleClickPage(totalPages)"
        :disabled="paginator.is_last_page"
        :class="{ active: paginator.is_last_page }"
        aria-label="Go to last page"
      >
        {{ totalPages }}
      </button>
    </li>

    <li>
      <button
        class="pagination__arrow pagination__arrow--right"
        :class="{ 'disabled': paginator.is_last_page }"
        type="button"
        @click="handleClickPage(currentPage + 1)"
        :disabled="paginator.is_last_page"
        aria-label="Go to next page"
      >
        <fa-icon :icon="['fas', 'chevron-right']" />
      </button>
    </li>
  </ul>
</template>

<script setup>
import { computed, toRefs } from 'vue'

const emit = defineEmits(['change'])

const props = defineProps({
  paginator: { type: Object },
  maxVisibleButtons: { type: Number, default: 3 }
})

const { paginator } = toRefs(props)

const currentPage = computed(() => paginator.value?.current_page)
const totalPages = computed(() => paginator.value?.total_pages)

const startRange = computed(() => {
  if (currentPage.value <= props.maxVisibleButtons - 1) return 1
  if (currentPage.value === totalPages.value) return totalPages.value - props.maxVisibleButtons + 1

  return currentPage.value - 1
})

const endRange = computed(() => Math.min(startRange.value + props.maxVisibleButtons - 1, totalPages.value))

const pages = computed(() => {
  const range = []

  if (startRange.value !== endRange.value) {
    for (let i = startRange.value; i <= endRange.value; i++) range.push(i)
  }

  return range.filter(page => page !== 1 && page !== totalPages.value)
})

const handleClickPage = page => emit('change', page)
</script>

<style scoped lang="scss">
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  &__item {
    margin-right: calc($margin__base / 4);
    margin-left: calc($margin__base / 4);
    font-size: rem(12px);
    font-weight: lighter;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 20px;
    user-select: none;
    border-radius: $radius__input;

    &.active {
      background: $color--primary;
      color: $white;
    }
  }

  &__arrow {
    width: 20px;
    height: 20px;
    border: 1px solid $lg;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;

    &--right {
      margin-left: $margin__base;
    }

    &--left {
      margin-right: $margin__base;
    }

    &.disabled {
      border-color: transparent;
      color: $vlg;
    }
  }
}
</style>
