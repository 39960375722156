export default element => {
  const style = element.getAttribute('style') || ''
  const rules = {}

  style.replaceAll('; ', ';').split(';').forEach(rule => {
    const [k, v] = rule.split(':')

    rules[k] = v
  })

  return rules
}