import { getRegexExpression } from '@/vendors/tiptap/helpers'

export default attrs => {
  const { type, input } = attrs

  if (!input) return {}

  if (type === 'video') {
    const services = [
      {
        regex: getRegexExpression('youtube'),
        url: 'https://www.youtube.com/embed/$1'
      },
      {
        regex: getRegexExpression('vimeo'),
        url: 'https://player.vimeo.com/video/$1'
      },
      {
        regex: getRegexExpression('dailymotion'),
        url: 'https://www.dailymotion.com/embed/video/$1'
      }
    ]

    const service = services.map(service => ({ ...service, match: service.regex.exec(input) })).find(service => service.match)

    if (!service) return {}

    return {
      ...attrs,
      src: input.replace(service.regex, service.url),
      width: 630,
      height: 354,
      allowfullscreen: 0,
      allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
      textAlign: 'center'
    }

  } else {
    const parser = new DOMParser()
    const iframe = parser.parseFromString(input, 'text/html').querySelector('iframe')

    if (!iframe) return {}

    return {
      ...attrs,
      src: iframe.getAttribute('src'),
      width: iframe.getAttribute('width'),
      height: iframe.getAttribute('height'),
      frameborder: iframe.getAttribute('frameborder'),
      allowfullscreen: iframe.getAttribute('allowfullscreen'),
      allow: iframe.getAttribute('allow')
    }
  }
}