<template>
  <div>
    <div
      class="sponsors bg__color--prim"
      v-if="sponsors.length > 0"
    >
      <div class="container sponsors__wrapper">
        <ul class="sponsors__items">
          <li
            v-for="(sponsor, index) in sponsors"
            :key="`sponsor_${index}`"
            class="sponsor"
          >
            <a
              :href="translate(sponsor.url)"
              class="sponsor__link"
              v-if="translate(sponsor.url)"
            >
              <img
                :src="translate(sponsor.image)"
                :alt="translate(sponsor.name)"
                class="sponsor__logo"
                :style="{ maxHeight: sponsor.height ? `${sponsor.height}px` : '60px' }"
              >
            </a>

            <img
              :src="translate(sponsor.image)"
              :alt="translate(sponsor.name)"
              class="sponsor__logo"
              :style="{ maxHeight: sponsor.height ? `${sponsor.height}px` : '60px' }"
              v-else
            >
          </li>
        </ul>
      </div>
    </div>

    <footer class="footer">
      <div class="container footer__wrapper">
        <div class="footer__col">
          <template v-if="translate(logo.image) || client.logo.url">
            <a
              :href="translate(logo.url)"
              class="logo"
              v-if="translate(logo.url)"
            >
              <img
                class="logo__img"
                :src="translate(logo.image) || client.logo.url"
                :alt="client.name"
              >
            </a>

            <router-link
              :to="{ name: 'Home', params: { locale } }"
              class="logo"
              v-else
            >
              <img
                class="logo__img"
                :src="translate(logo.image) || client.logo.url"
                :alt="client.name"
              >
            </router-link>
          </template>
        </div>

        <div class="footer__col">
          <ul
            class="navigation"
            v-if="navigation1.length > 0"
          >
            <li
              class="navigation__li"
              v-for="({ link }, key) in navigation1"
              :key="`link_1_${key}`"
            >
              <app-link
                :link="link"
                class="navigation__link link__color--white link--naked"
              />
            </li>
          </ul>
        </div>

        <div class="footer__col">
          <ul
            class="navigation"
            v-if="navigation3.length > 0"
          >
            <li
              class="navigation__li"
              v-for="({ link }, key) in navigation3"
              :key="`link_3_${key}`"
            >
              <app-link
                :link="link"
                class="navigation__link link__color--white link--naked"
              />
            </li>
          </ul>
        </div>

        <div class="footer__col">
          <ul
            class="navigation"
            v-if="navigation2.length > 0"
          >
            <li
              class="navigation__li"
              v-for="({ link }, key) in navigation2"
              :key="`link_2_${key}`"
            >
              <app-link
                :link="link"
                class="navigation__link link__color--white link--naked"
              />
            </li>
          </ul>
        </div>
      </div>
    </footer>

    <div
      class="social_networks"
      v-if="socialMedias.length > 0"
    >
      <div class="container social_networks__wrapper">
        <ul class="social_networks__items">
          <li
            class="social_networks__li"
            v-for="(media, i) in socialMedias"
            :key="'network_' + i"
          >
            <a
              class="social_networks__link link__color--white link--naked"
              :href="translate(media.url)"
              target="_blank"
            >
              <fa-icon
                v-if="media.icon"
                size="lg"
                :icon="media.icon"
                class="social_networks__icon"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { mapGetters } from '@/store/map-state'
import { get as _get } from 'lodash'
import { useI18n } from '@/vendors/i18n'

import AppLink from '&/atoms/AppLink'

const props = defineProps({
  section: { type: Object, required: true }
})

const { getLocale, translate } = useI18n()
const { client } = mapGetters('client')

const locale = computed(() => getLocale())
const logo = computed(() => _get(props, 'section.attributes.column_1.logo', {}))
const navigation1 = computed(() => _get(props, 'section.attributes.column_2.navigation', []).filter(item => translate(item.link.text)))
const navigation2 = computed(() => _get(props, 'section.attributes.column_3.navigation', []).filter(item => translate(item.link.text)))
const navigation3 = computed(() => _get(props, 'section.attributes.column_4.navigation', []).filter(item => translate(item.link.text)))
const sponsors = computed(() => _get(props, 'section.attributes.sponsors.items', []).filter(item => translate(item.image)))
const socialMedias = computed(() => _get(client.value, 'platform.social_networks', []).filter(link => translate(link.url)))
</script>

<style lang="scss" scoped>
.sponsors {
  padding: $padding__base * 8 0;
  background-image: url('https://koalect-images.s3.eu-west-3.amazonaws.com/clients/jez/sponsors_bg.png');
  background-position: center;
  background-repeat: no-repeat;

  &__items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
  }
}

.sponsor {
  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all $transition__duration;

    &:hover {
      opacity: 0.8;
    }
  }

  &__logo {
    width: 100%;
    height: auto;
    max-width: 177px;
    max-height: 61px;
  }
}

.footer {
  background: #000;
  color: $white;
  padding: $padding__base * 4 0 $padding__base * 8;
  text-align: center;

  @include mq(md) {
    text-align: left;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: $margin__base * 2;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(md) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include mq(lg) {
      gap: $margin__base * 10;
    }
  }
}

.social_networks {
  background: #000;

  &__wrapper {
    border-top: 1px solid #4E4D4D;
  }

  &__items {
    padding: $padding__base * 1.5 0 $padding__base * 2.5;
    display: flex;
    justify-content: center;
    gap: $margin__base;

    @include mq(md) {
      justify-content: flex-end;
    }
  }
}
</style>
