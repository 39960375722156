import { ref, inject, toValue } from 'vue'
import { get as _get, mapValues as _mapValues } from 'lodash'

export default function useBlock (block) {
  const objects = inject('objects', {})
  const isPreview = inject('preview', ref(false))

  const attributes = toValue(block)?.attributes || {}
  const settings = toValue(block)?.settings || {}

  const isShowed = model => _get(attributes, `show_${model}`, false)

  const getText = name => {
    name = name ? `text_${name}`: 'text'

    if (`${name}_singular` in attributes && `${name}_plural` in attributes)
      return _mapValues(attributes[`${name}_singular`], (value, key) => [value, attributes[`${name}_plural`][key]].join(' | '))

    return _get(attributes, name)
  }

  return {
    attributes,
    settings,
    isPreview,
    objects,
    isShowed,
    getText
  }
}