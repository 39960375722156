<template>
  <div>
    <header
      class="header"
      :class="{'header--fixed': mobileMenuOpened}"
      v-if="section"
    >
      <div class="wrapper d-none d-md-flex justify-content-end padding__tb--12">
        <language-switcher v-slot="{ options, updateLanguage, isActive }">
          <ul class="languages__ul">
            <li
              class="languages__li"
              v-for="language in options"
              :key="language.id"
            >
              <button
                class="languages__a"
                :class="{'active': isActive(language.id)}"
                type="button"
                @click.prevent="updateLanguage(language.id)"
              >
                <template v-if="isPhone">
                  {{ language.label.value.toUpperCase() }}
                </template>

                <template v-else>
                  {{ language.label.name }}
                </template>
              </button>
            </li>
          </ul>
        </language-switcher>

        <button
          @click.prevent="openSessionModal('register')"
          class="header__session"
          v-if="!isAuthenticated"
        >
          <fa-icon
            class="margin__r--6"
            :icon="['fas', 'user']"
          />
        </button>

        <router-link
          class="header__session header__session--user d-flex align-items-center"
          :to="{ name: 'Profile', params: { locale } }"
          v-if="isAuthenticated && user"
        >
          <user-profile-img
            size="2"
            :avatar="user.avatar"
          />
        </router-link>
      </div>

      <div class="wrapper header__wrapper">
        <div class="header__cell d-md-none">
          <app-burger-menu
            class="header__burger"
            :state="mobileMenuOpened"
            @toggle="toggleMobileMenu"
          />
        </div>

        <div class="header__cell">
          <router-link
            :to="{ name: 'Home', params: { locale } }"
            v-show="client.logo.url"
            class="logo"
          >
            <img
              class="logo__img"
              :src="client.logo.url"
              :alt="client.name"
            >
          </router-link>

          <nav
            v-if="navigation.length > 0"
          >
            <ul class="header__menu">
              <li
                class="header__menu-item"
                v-for="(item, index) in navigation"
                :key="`menu_item_${index}`"
              >
                <a
                  class="header__menu-link"
                  :class="{'header__menu-link--active': isVisited(item.link)}"
                  @click.prevent="setRouteNavigation(item.link); mobileMenuOpened = false"
                >
                  {{ $i18n.translate(item.link.text) }}
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <nav
          class="header__cell"
          v-if="actions.length > 0"
        >
          <ul class="header__actions">
            <li
              class="header__actions-item"
              v-for="(item, index) in actions"
              :key="`actions_item_${index}`"
            >
              <a
                class="header__actions-link"
                :class="{'header__actions-link--active': isVisited(item.link)}"
                @click.prevent="setRouteNavigation(item.link); mobileMenuOpened = false"
              >
                {{ $i18n.translate(item.link.text) }}
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <transition name="fade-mobile-menu">
        <section
          class="mobile"
          v-if="mobileMenuOpened"
        >
          <div class="wrapper mobile__wrapper">
            <ul
              class="mobile__menu"
              v-if="navigation.length > 0"
            >
              <li
                class="mobile__menu-item"
                v-for="(item, index) in navigation"
                :key="`menu_mobile_item_${index}`"
              >
                <a
                  class="mobile__menu-link"
                  :class="{'mobile__menu-link--active': isVisited(item.link)}"
                  @click.prevent="setRouteNavigation(item.link); mobileMenuOpened = false"
                >
                  {{ $i18n.translate(item.link.text) }}

                  <img
                    class="mobile__menu-logo"
                    :src="$i18n.translate(item.logo)"
                    :alt="$i18n.translate(item.link.text)"
                    v-if="$i18n.translate(item.logo)"
                  >
                </a>

                <ul
                  v-if="item.sublinks && item.sublinks.length > 0"
                  class="mobile__menu-submenu padding--6"
                >
                  <li
                    class="mobile__menu-subitem"
                    v-for="(sublink, i) in item.sublinks"
                    :key="`menu_item_${index}_${i}`"
                  >
                    <a
                      class="mobile__menu-sublink padding__tb--6"
                      :class="{'mobile__menu-sublink--active': isVisited(sublink.link)}"
                      @click.prevent="setRouteNavigation(sublink.link); mobileMenuOpened = false"
                    >
                      {{ $i18n.translate(sublink.link.text) }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>

            <hr class="separator separator__size--full border__color--dw margin__tb--12 d-md-none">

            <div class="mobile__sessions d-md-none">
              <language-switcher v-slot="{ options, updateLanguage, isActive }">
                <ul class="languages__ul">
                  <li
                    class="languages__li"
                    v-for="language in options"
                    :key="language.id"
                  >
                    <button
                      class="languages__a"
                      :class="{'active': isActive(language.id)}"
                      type="button"
                      @click.prevent="updateLanguage(language.id)"
                    >
                      <template v-if="isPhone">
                        {{ language.label.value.toUpperCase() }}
                      </template>

                      <template v-else>
                        {{ language.label.name }}
                      </template>
                    </button>
                  </li>
                </ul>
              </language-switcher>

              <ul>
                <li
                  class="mobile__sessions-item"
                  v-if="!isAuthenticated"
                >
                  <a
                    @click.prevent="openSessionModal('login')"
                    class="link--naked pointer"
                  >
                    <fa-icon
                      class="margin__r--6"
                      :icon="['fas', 'user']"
                      fixed-width
                    />
                  </a>
                </li>

                <li
                  class="mobile__sessions-item pointer d-flex align-items-center"
                  v-if="isAuthenticated && user"
                >
                  <router-link
                    :to="{ name: 'Profile', params: { locale } }"
                    @click.capture="mobileMenuOpened = false"
                  >
                    <user-profile-img
                      size="2"
                      :avatar="user.avatar"
                    />
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </transition>
    </header>

    <app-modal
      v-if="showSessionModal"
      size="sm"
      @close="showSessionModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="template"
          @authenticated="showSessionModal = false"
        />
      </template>
    </app-modal>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
import { get as _get } from 'lodash'
import { mapGetters } from 'vuex'
import PageRouteNavigation from '%/PageRouteNavigation'
import UserProfileImg from '&/atoms/UserProfileImg'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
import LanguageSwitcher from '&/atoms/LanguageSwitcher'
import { useLanguageStore } from '@/stores/LanguageStore'
import { storeToRefs } from 'pinia'
import { scrollTo } from '@/utils/utils'
import isMobile from 'is-mobile'

export default defineComponent({
  name: 'CustomHeaderCo2Logic',
  components: {
    UserProfileImg,
    AppBurgerMenu,
    LanguageSwitcher,
    SessionForms: defineAsyncComponent(() => import('&/modules/sessions/SessionForms')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      showSessionModal: false,
      mobileMenuOpened: false,
      template: null
    }
  },
  setup: () => {
    const { locale } = storeToRefs(useLanguageStore())

    return {
      scrollTo,
      locale
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    navigation () {
      return _get(this.section, 'attributes.navigation', [])
    },
    actions () {
      return _get(this.section, 'attributes.actions', [])
    },
    isPhone () {
      return isMobile({ tablet: false })
    }
  },
  methods: {
    openSessionModal (template) {
      this.template = template
      this.showSessionModal = true
    },
    toggleMobileMenu (value) {
      const delay = !value ? 300 : 0
      this.scrollTo()

      setTimeout(() => {
        this.mobileMenuOpened = !value
      }, delay)
    }
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #2DCD9B;
$color--secondary: #003732;

.wrapper {
  max-width: 1310px;
  margin: 0 auto;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
}

.languages {
  &__ul {
    display: flex;
    font-size: 14px;
  }

  &__li {
    padding: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:last-child) {
      border-right: 1px solid rgba(0, 55, 50, 0.6);
    }
  }

  &__a {
    display: block;
    color: rgba($color--secondary, 0.6);

    &:hover, &.active {
      color: $color--secondary;
    }
  }
}

.logo {
  display: block;
  position: relative;
  width: 60px;

  @include mq(md) {
    width: 100px;
    margin-right: $margin__base * 3;
  }

  &__img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.header {
  background: $white;
  position: relative;
  z-index: 999;

  &--fixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;

    @include mq(lg) {
      position: relative;
    }
  }

  &__session {
    margin-left: 24px;
    font-size: rem(14px);

    svg {
      color: rgba(#8E8B8B, 0.74);
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    position: relative;

    & > * {
      width: 33.333%;

      @include mq(md) {
        width: auto;
      }
    }

    @include mq(md) {
      padding: 0 24px 12px 24px;
      top: -10px;
    }
  }

  &__cell {
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      justify-content: flex-start;
    }

    &:last-child {
      justify-content: flex-end;
    }
  }

  &__menu {
    display: none;

    @include mq(md) {
      display: flex;
      align-items: center;
    }

    &-item {
      position: relative;

      &:not(:last-child) {
        margin-right: 42px;
      }

      &:hover {
        .header__menu-submenu {
          display: block;
        }
      }
    }

    &-link {
      position: relative;
      display: flex;
      align-items: center;
      color: $color--secondary;
      padding: 10px 0;
      transition: all .2s;
      font-size: 21px;
      font-weight: 700;

      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 3px;
        width: 0px;
        background-color: $color--primary;
        top: 42px;
        transition: all .6s;
      }

      &:hover:after, &--active:after {
        width: 36px;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1;

    @include mq(md) {
      font-size: 21px;
    }

    &-item {
      &:not(:last-child) {
        margin-right: $margin__base * 2;
      }
    }

    &-link {
      position: relative;
      font-family: $font-family--secondary;
      display: block;
      background: $color--primary;
      color: set-contrast($color--primary);
      padding: 8px 24px;
      border-radius: 4px;
      border: 2px solid $color--primary;
      line-height: 1;
      letter-spacing: 1px;
      font-weight: 700;
      text-align: center;
      box-shadow: none;

      &:hover {
        box-shadow: 0 2px 0 0 rgba($color--secondary, 0.75);
        top: -1px;
        background-color: #32d2a0;
      }
    }
  }

  &__burger {
    color: $color--primary;
    line-height: 1;

    & :deep() {
      .menu__line {
        background-color: $color--primary;
      }

      .menu__label {
        display: none;
      }

      .menu__close::before, .menu__close::after {
        background-color: $color--primary;
      }
    }
  }
}

.mobile {
  position: fixed;
  top: 80px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 80px);
  background: $white;
  z-index: 999;
  text-align: center;

  @include mq(md) {
    top: 156px;
    height: calc(100vh - 156px);
  }

  @include mq(lg) {
    display: none;
  }

  &__wrapper {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__menu {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-link {
      color: $color--secondary;
      padding: 10px 0;
      display: inline-block;
      position: relative;
      line-height: 1;
      font-size: 24px;
      margin-bottom: 18px;
      font-weight: 700;

      &--active:after {
        content: '';
        display: block;
        position: absolute;
        height: 3px;
        width: 36px;
        background-color: $color--primary;
        top: 42px;
        transition: all .6s;
      }
    }
  }

  &__sessions {
    color: $color--secondary;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 24px;

    .languages__ul {
      margin-right: 24px;
    }

    a {
      color: $color--secondary;
    }

    svg {
      color: rgba(#8E8B8B, 0.74);
    }

    &-item {
      &:not(:last-child) {
        margin-right: $margin__base * 2;
      }
    }
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}

.fade-mobile-menu-enter-active, .fade-mobile-menu-leave-active {
  transition: opacity .5s;
}
.fade-mobile-menu-enter, .fade-mobile-menu-leave-to {
  opacity: 0;
}
</style>
