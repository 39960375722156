<template>
  <h2
    class="title__h3 bold"
    v-if="translate(attributes.title)"
  >
    {{ translate(attributes.title) }}
  </h2>
</template>

<script setup>
import { toRefs, inject } from 'vue'
import { useModule } from '@/composables/app/useModule'
import { useI18n } from '@/vendors/i18n'

const props = defineProps({ block: { type: Object, required: true } })

const { block } = toRefs(props)

const procedure_execution = inject('procedure_execution')

const { translate } = useI18n()
const { attributes } = useModule({ component: block, parent: procedure_execution })
</script>
