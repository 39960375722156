<template>
  <div>
    <header
      class="header"
      :class="{'mobile': showMobileMenu}"
    >
      <div class="container">
        <router-link
          :to="{ name: 'Home', params: { locale } }"
          v-show="client.logo.url"
        >
          <img
            class="logo__img"
            :src="client.logo.url"
            :alt="client.name"
          >
        </router-link>

        <ul class="navigation">
          <li
            class="navigation__li"
            v-for="({ link }, key) in navigation"
            :key="`link_${key}`"
          >
            <app-link
              :link="link"
              class="navigation__link"
            />
          </li>
        </ul>

        <ul class="actions">
          <li
            class="actions__li"
            v-for="({ link }, key) in actions"
            :key="`link_${key}`"
          >
            <app-link
              :link="link"
              class="actions__link"
            />
          </li>
        </ul>

        <article class="session">
          <language-switcher
            class="session__language"
            v-slot="{ options, updateLanguage, isActive }"
          >
            <ul class="languages">
              <li
                class="languages__li"
                v-for="language in options"
                :key="language.id"
              >
                <button
                  class="languages__link"
                  :class="{'active': isActive(language.id)}"
                  type="button"
                  @click.prevent="updateLanguage(language.id)"
                >
                  {{ language.label.value.toUpperCase() }}
                </button>
              </li>
            </ul>
          </language-switcher>

          <template v-if="isAuthenticated && user">
            <router-link
              class="session__user"
              :to="{ name: 'Profile', params: { locale } }"
            >
              <user-profile-img
                :size="2"
                :avatar="user.avatar"
              />

              <p class="margin__l--6">
                {{ user.firstname }}
              </p>
            </router-link>
          </template>

          <template v-else>
            <a
              @click.prevent="toggleSessionModal('login')"
              class="session__login"
            >
              {{ translate(login) || t('literal.login') }}
            </a>
          </template>
        </article>

        <app-burger-menu
          class="burger"
          :state="showMobileMenu"
          @toggle="toggleMobileMenu"
        />
      </div>
    </header>

    <app-modal
      v-if="showSessionModal"
      size="sm"
      @close="showSessionModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="sessionTemplate"
          @authenticated="showSessionModal = false"
        />
      </template>
    </app-modal>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, ref, watch } from 'vue'
import { mapGetters } from '@/store/map-state'
import { get as _get } from 'lodash'
import { useI18n } from '@/vendors/i18n'
import { useRoute } from 'vue-router'

import AppLink from '&/atoms/AppLink'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
import LanguageSwitcher from '&/atoms/LanguageSwitcher'
const UserProfileImg = defineAsyncComponent(() => import('&/atoms/UserProfileImg'))
const SessionForms = defineAsyncComponent(() => import('&/modules/sessions/SessionForms'))
const AppModal = defineAsyncComponent(() => import('&/organisms/AppModal'))

const props = defineProps({
  section: { type: Object, required: true }
})

const { getLocale, t, translate } = useI18n()
const route = useRoute()
const { client } = mapGetters('client')
const { isAuthenticated, user } = mapGetters('auth')

const showSessionModal = ref(false)
const showMobileMenu = ref(false)
const sessionTemplate = ref('login')
const locale = computed(() => getLocale())
const navigation = computed(() => _get(props, 'section.attributes.navigation', []))
const actions = computed(() => _get(props, 'section.attributes.actions', []))
const login = computed(() => _get(props, 'section.attributes.login', {}))

watch(route, () => showMobileMenu.value = false)

const toggleSessionModal = (template) => {
  sessionTemplate.value = template
  showSessionModal.value = true
}

const toggleMobileMenu = () => {
  showMobileMenu.value = !showMobileMenu.value
}
</script>

<style lang="scss" scoped>
.container {
  padding: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  position: relative;
}

.header {
  background-color: $white;
  padding: 16px;

  @include mq(md) {
    padding: 28px 0 64px 0;
  }

  & .burger {
    display: inherit;
    margin-left: auto;

    @include mq(md) {
      display: none;
    }
  }
}

.logo {
  &__img {
    max-width: 180px;
    display: block;
    transition: width .3s, height .3s;

    @include mq(md) {
      max-width: 290px;
      margin-right: 44px;
    }
  }
}

.navigation {
  margin-left: auto;
  margin-right: 52px;
  display: none;

  @include mq(md) {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  :deep(.navigation__link) {
    line-height: 1;
    font-size: 24px;
    transition: all .3s;
    display: inline-block;
    position: relative;

    &:after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: $black;
      position: absolute;
      bottom: -2px;
      left: 0;
      transform: scaleX(0);
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }

    &:hover:after, &.is-active:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
}

.actions {
  display: none;
  position: relative;

  @include mq(md) {
    display: inherit;
  }

  &__li:not(:last-child) {
    margin-right: 12px;
  }

  :deep(.actions__link) {
    padding: 12px 18px;
    font-size: 24px;
    display: block;
    background-color: $black;
    color: $white;
    border: 1px solid transparent;
    transition: all .3s;

    &:hover {
      border: 1px solid $black;
      background-color: $white;
      color: $black;
    }
  }
}

.session {
  position: absolute;
  top: -21px;
  right: 0;
  display: none;

  @include mq(md) {
    display: flex;
    align-items: center;
  }

  &__login {
    font-size: 14px;
    transition: all .3s;
  }

  &__user {
    display: flex;
    align-items: center;
    transition: all .3s;

    &:hover {
      color: shade($color--secondary, 1%, 25%);
    }
  }

  &__language {
    margin-right: 24px;

    &:deep .locale-switcher {
      background-color: transparent;
    }
  }
}

.languages {
  display: flex;

  &__li {
    position: relative;
  }

  &__li:not(:last-child):after {
    content: '';
    display: block;
    width: 1px;
    height: 15px;
    background-color: $black;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &__link {
    padding: 6px;

    &.active {
      font-weight: bold;
    }
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}

@media (max-width: 991px) {
  .mobile {
    position: fixed;
    min-height: 100vh;
    min-width: 100vw;
    z-index: 100;

    .logo__img {
      max-width: 220px;
      margin-top: 40px;
    }

    .container {
      flex-direction: column;
    }

    .navigation {
      display: block;
      margin-top: 40px;
      margin-left: 0;
      margin-right: 0;

      &__li {
        text-align: center;
        padding: 0;

        &:not(:first-child) {
          margin-top: 12px;
        }
      }
    }

    .actions {
      display: block;
      margin-top: 40px;

      &__li:not(:last-child) {
        margin-right: 0;
        margin-bottom: 12px;
      }

      &__li {
        text-align: center;
      }
    }

    .session {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 70px;
      top: 0;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -24px;
        width: 100%;
        height: 1px;
        background-color: $color--secondary;
      }
    }

    .burger {
      position: absolute;
      right: 0;
    }
  }
}
</style>
