export default [
  {
    name: 'custom_footer_expeditienatuurpunt',
    path: 'modules/pages/expeditienatuurpunt/FooterExpeditienatuurpunt'
  },
  {
    name: 'custom_header_expeditienatuurpunt',
    path: 'modules/pages/expeditienatuurpunt/HeaderExpeditienatuurpunt'
  },
  {
    name: 'custom_homepage_expeditienatuurpunt',
    path: 'modules/pages/expeditienatuurpunt/HomepageExpeditienatuurpunt'
  }
]
