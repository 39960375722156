<template>
  <div class="home">
    <article class="banner">
      <div
        class="banner__img"
        :style="`background-image: url(${$i18n.translate(banner.background)})`"
      />

      <div class="container">
        <div class="banner__wrapper">
          <div class="banner__content">
            <h1 class="banner__title">
              {{ $i18n.translate(banner.title) }}
            </h1>

            <p
              class="banner__text editor editor__content"
              v-html="$i18n.translate(banner.text)"
            />

            <button
              v-if="banner.button && banner.button.show"
              @click.prevent="setRouteNavigation(banner.button)"
              class="cta"
            >
              {{ $i18n.translate(banner.button.text) }}
            </button>
          </div>
        </div>
      </div>
    </article>

    <section
      class="counter"
      v-if="metrics.length > 0"
    >
      <ul
        class="counter__ul"
        v-if="metrics.length > 0 && stats"
      >
        <li
          class="counter__li"
          v-for="(metric, index) in metrics"
          :key="`metric_${index}`"
        >
          <span class="bold margin__b--6">
            <app-date
              class="counter__stats"
              v-if="metric.type === 'date' && metric.date"
              :timestamp="metric.date"
              filter="differenceInCalendarDays"
            />

            <app-money
              class="counter__stats"
              v-else-if="metric.type === 'amount_collected'"
              :amount="stats[metric.type]"
            />

            <span
              class="counter__stats"
              v-else
            >
              {{ stats[metric.type] }}
            </span>
          </span>

          <p class="counter__label">
            {{ $i18n.translate(metric.text) }}
          </p>
        </li>
      </ul>
    </section>

    <section
      class="ctas"
      v-if="ctas.items.length > 0"
    >
      <div class="container">
        <ul class="ctas__ul">
          <li
            v-for="(cta, index) in ctas.items"
            :key="`cta_home_${index}`"
          >
            <div class="ctas__content">
              <h2
                class="ctas__title"
                v-if="$i18n.translate(cta.title)"
              >
                {{ $i18n.translate(cta.title) }}
              </h2>

              <figure class="ctas__img">
                <img
                  class="ctas__img-img"
                  :src="$i18n.translate(cta.img_src)"
                  :alt="$i18n.translate(cta.title)"
                  v-if="$i18n.translate(cta.img_src)"
                >
              </figure>

              <div
                class="ctas__text editor editor__content"
                v-if="$i18n.translate(cta.text)"
                v-html="$i18n.translate(cta.text)"
              />

              <button
                v-if="cta.button && cta.button.show"
                @click.prevent="setRouteNavigation(cta.button)"
                class="ctas__button cta btn__size--classic"
              >
                {{ $i18n.translate(cta.button.text) }}
              </button>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <section class="steps">
      <div class="container">
        <h2 class="steps__title">
          {{ $i18n.translate(steps.title) }}
        </h2>

        <div class="steps__wrapper">
          <ul class="steps__ul">
            <li
              class="steps__li"
              v-for="(step, index) in steps.items"
              :key="`step_home_${index}`"
            >
              <div class="steps__content">
                <img
                  class="steps__icon"
                  :src="$i18n.translate(step.img_src)"
                  :alt="$i18n.translate(step.title)"
                >

                <h2 class="steps__subtitle">
                  {{ $i18n.translate(step.title) }}
                </h2>

                <p
                  class="editor editor__content"
                  v-html="$i18n.translate(step.text)"
                />
              </div>
            </li>
          </ul>
        </div>

        <div class="steps__btn-container">
          <button
            v-if="steps.cta && steps.cta.show"
            @click.prevent="setRouteNavigation(steps.cta)"
            class="cta"
          >
            {{ $i18n.translate(steps.cta.text) }}
          </button>
        </div>
      </div>
    </section>

    <section class="updates">
      <div class="container">
        <article
          class="update"
          v-for="(update, index) in news.items"
          :key="`home_update_${index}`"
        >
          <img
            class="update__bg"
            :src="$i18n.translate(update.img_src)"
            :alt="$i18n.translate(update.title)"
          >

          <div class="update__content">
            <h2 class="title--h2">
              {{ $i18n.translate(update.title) }}
            </h2>

            <div
              class="editor editor__content"
              v-html="$i18n.translate(update.text)"
            />

            <button
              v-if="update.button && update.button.show"
              @click.prevent="setRouteNavigation(update.button)"
              class="cta"
            >
              {{ $i18n.translate(update.button.text) }}
            </button>
          </div>
        </article>
      </div>
    </section>

    <section
      class="quotes"
      v-if="$i18n.translate(quote.text)"
    >
      <div class="container">
        <article class="quote">
          <img
            class="quote__img"
            :src="$i18n.translate(quote.image)"
            :alt="$i18n.translate(quote.author)"
          >

          <div class="quote__content">
            <div
              class="editor editor__content"
              v-html="$i18n.translate(quote.text)"
            />

            <p class="quote__author">
              {{ $i18n.translate(quote.author) }}
            </p>
          </div>
        </article>
      </div>
    </section>
  </div>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'

export default defineComponent({
  name: 'CustomHomepageLightForTheWorld',
  components: {
    AppDate: defineAsyncComponent(() => import('&/atoms/AppDate')),
    AppMoney: defineAsyncComponent(() => import('&/atoms/AppMoney'))
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  created () {
    this.fetchClientStatistics()
  },
  mixins: [
    PageRouteNavigation
  ],
  computed: {
    ...mapGetters({
      stats: 'client/statistics'
    }),
    projectSection () {
      return {
        ...this.section,
        attributes: this.section.attributes.projects
      }
    },
    attributes () {
      return _get(this.section, 'attributes', {})
    },
    banner () {
      return _get(this.attributes, 'banner', {})
    },
    news () {
      return _get(this.attributes, 'news', {})
    },
    ctas () {
      return _get(this.attributes, 'ctas', {})
    },
    steps () {
      return _get(this.attributes, 'steps', {})
    },
    metrics () {
      return _get(this.attributes, 'metrics', [])
    },
    quote () {
      return _get(this.attributes, 'quote', {})
    }
  },
  methods: {
    ...mapActions({
      fetchClientStatistics: 'client/fetchClientStatistics'
    })
  }
})
</script>

<style lang="scss" scoped>
.home {
  position: relative;
  z-index: 10;
}

.banner {
  font-size: rem(16px);
  position: relative;
  z-index: 10;
  color: $white;
  background-color: #333;
  padding: 40px 0;

  @include mq(md) {
    padding: 100px 0 179px 0;
    font-size: rem(18px);
  }

  &__img {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: .8;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__wrapper {
    position: relative;
    z-index: 10;
    display: flex;
    max-width: 525px;
    background: rgba(248,248,248,.7);

    @include mq(md) {
      justify-content: flex-start;
    }
  }

  &__content {
    padding: 50px;
    color: $black;
  }

  &__title {
    font-size: 26px;
    margin-bottom: 9px;
    text-transform: uppercase;
    font-weight: bold;
    font-style: italic;
    font-family: $font-family--secondary;

    @include mq(md) {
      font-size: 34px;
    }
  }

  &__text {
    margin: 18px 0;
  }

  & + .ctas {
    padding-top: 60px;
  }
}

.counter {
  position: relative;
  z-index: 10;

  @include mq(md) {
    transform: translate(0,-50%);
  }

  &__ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 42px;
    transition: opacity .2s linear, transform .2s linear;
    text-align: center;
    opacity: 1;
    color: $white;
    background-color: $color--secondary;
    margin: auto;

    @include mq(sm) {
      max-width: 840px;
    }
  }

  &__li {
    width: 33%;
    line-height: 1.3;

    @include mq(sm) {
      line-height: 24px;
    }
  }

  &__stats {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.2;
    display: block;
    margin-bottom: 6px;
    font-style: italic;
    font-family: $font-family--secondary;

    @include mq(sm) {
      font-size: 34px;
      line-height: 42px;
    }
  }

  &__label {
    font-size: rem(14px);

    @include mq(sm) {
      font-size: rem(18px);
    }
  }
}

.ctas {
  position: relative;
  z-index: 5;
  padding-top: 60px;
  padding-bottom: 32px;
  background-color: #f7f7f1;

  @include mq(md) {
    padding-top: 0;
  }

  &__ul {
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;

    @include mq(md) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: $margin--base * 3;
    }
  }

  &__content {
    position: relative;
    min-height: 100%;
    border-radius: 2px;
    background-color: $white;
    text-align: center;
    padding: $padding__base * 2 0;

    @include mq(md) {
      text-align: left;
    }
  }

  &__title {
    font-size: 20px;
    font-style: italic;
    font-weight: bold;
    margin-bottom: $margin__base * 2;
    text-transform: uppercase;
    text-align: center;
    font-family: $font-family--secondary;
    padding: 0 $padding__base * 5;
  }

  &__img {
    @include aspect-ratio(400, 150);
    background: #ececec;

    &-img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  &__text {
    margin: $margin__base * 2 0 $margin__base * 8;
    padding: 0 $padding__base * 5;
  }

  &__button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: $margin__base * 2;
  }
}

.steps {
  position: relative;
  z-index: 5;
  padding-top: 60px;
  padding-bottom: 32px;
  background-color: #f7f7f1;

  &__btn-container {
    margin: 48px auto;
    text-align: center;
  }

  &__ul {
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;

    @include mq(md) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: $margin--base * 3;
    }
  }

  &__li {
    width: 100%;
  }

  &__content {
    position: relative;
    min-height: 100%;
    padding: 55px;
    border-radius: 2px;
    background-color: $white;
    text-align: center;

    @include mq(md) {
      text-align: left;
    }
  }

  &__icon {
    max-width: 56px;
    height: auto;
    margin: 0 auto 10px auto;
    display: block;

    @include mq(md) {
      margin: 0 auto 30px auto;
    }
  }

  &__subtitle {
    font-size: 20px;
    font-style: italic;
    font-weight: bold;
    margin-top: 42px;
    margin-bottom: 24px;
    text-transform: uppercase;
    font-family: $font-family--secondary;
  }

  &__title {
    font-size: 30px;
    font-style: italic;
    font-weight: bold;
    margin-bottom: 24px;
    padding: 0 20px;
    text-transform: uppercase;
    text-align: center;
    font-family: $font-family--secondary;
  }
}

.updates {
  background: $white;
  padding: 60px 20px;
}

.update {
  position: relative;
  padding: 20px 0;

  &:nth-child(odd) {
    flex-direction: row-reverse;
  }

  @include mq(md) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 60px 0;
  }

  &__bg {
    margin-bottom: 30px;
    width: 100%;

    @include mq(md) {
      width: 400px;
    }

    @include mq(lg) {
      width: 560px;
    }
  }

  &__content {
    flex: 0 1 47%;
  }

  & .title--h2 {
    margin-bottom: 25px;
    color: #131415;
    font-size: 26px;
    padding-left: 35px;
    position: relative;
    font-weight: bold;
    text-transform: uppercase;
    font-style: italic;
    font-family: $font-family--secondary;

    &:before {
      content: image-path('towalkagain/triangle.svg');
      position: absolute;
      left: 0;
    }
  }

  & .cta {
    margin: 48px 0;
  }
}

.quotes {
  padding: 40px 24px;
  background-color: #f7f7f1;

  @include mq(md) {
    padding: 106px;
  }
}

.quote {
  display: grid;
  grid-template-columns: 1fr;
  gap: $margin--base * 3;
  justify-content: center;

  @include mq(md) {
    grid-template-columns: 300px 1fr;
    padding: 36px;
  }

  &__img {
    display: block;
    margin: auto;
    max-width: 210px;

    @include mq(md) {
      max-width: auto;
    }
  }

  &__content {
    padding: 17px 0 0 24px;
    position: relative;

    &:before {
      content: image-path('towalkagain/quote.svg');
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
    }
  }

  &__author {
    font-size: 28px;
    font-weight: bold;
    text-align: right;
    text-transform: uppercase;
    font-style: italic;
    line-height: 37px;
    margin-top: 48px;
    font-family: $font-family--secondary;
  }
}

.figcaption {
  font: 0;
  position: absolute;
  z-index: 0;
  color: transparent;
  border: 0;
}

.cta {
  background-color: $color--primary;
  padding: 12px 24px;
  text-transform: uppercase;
  font-size: 16px;
  border-radius: 3px;
  font-weight: bold;
  transition: all .3s;
  font-family: $font-family--secondary;
  font-style: italic;

  &:hover {
    background: shade($color--primary, 3%, 47%);
  }
}
</style>
