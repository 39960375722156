<template>
  <footer class="footer">
    <div class="wrapper footer__wrapper">
      <div class="footer__col">
        <router-link
          :to="{ name: 'Home', params: { locale: $i18n.locale } }"
          v-if="client.logo.url && $i18n.translate(column1.logo)"
          class="footer__logo"
        >
          <img
            class="footer__logo-img"
            :src="$i18n.translate(column1.logo)"
            :alt="client.name"
          >
        </router-link>

        <ul
          class="networks margin__t--48"
          v-if="socialNetworks.length > 0"
        >
          <li
            class="networks__li"
            v-for="(network, index) in socialNetworks"
            :key="'network_' + index"
          >
            <a
              class="networks__a"
              :href="$i18n.translate(network.url)"
              target="_blank"
              :aria-label="$i18n.translate(network.text)"
            >
              <fa-icon
                v-if="network.icon"
                size="lg"
                :icon="network.icon"
              />
            </a>
          </li>
        </ul>
      </div>
      <div class="footer__col">
        <div
          class="editor__content"
          v-if="$i18n.translate(column2.text)"
          v-html="$i18n.translate(column2.text)"
        />
      </div>
      <div class="footer__col">
        <nav>
          <ul class="menu">
            <template
              v-for="(link, index) in column3.links"
              :key="`link_${index}`"
            >
              <li class="menu__li">
                <app-link
                  :link="link.link"
                  class="menu__a bold"
                />
              </li>
            </template>

            <li>
              <template v-if="isAuthenticated && user">
                <router-link
                  class="profile d-flex align-items-center profile bold"
                  :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
                >
                  <user-profile-img
                    :avatar="user.avatar"
                    size="2"
                  />

                  <span class="margin__l--6">{{ user.firstname }}</span>
                </router-link>
              </template>

              <template v-else>
                <button
                  @click.prevent="openModal"
                  class="profile bold d-flex align-items-center"
                >
                  <fa-icon
                    :icon="['fas', 'user']"
                    fixed-width
                  />

                  <span class="margin__l--6">{{ $i18n.t('literal.login') }}</span>
                </button>
              </template>
            </li>
          </ul>
        </nav>
      </div>
      <div class="footer__col">
        <div class="donation padding--18">
          <h4
            class="donation__title bold"
            v-if="column4.donation.title && $i18n.translate(column4.donation.title)"
          >
            {{ $i18n.translate(column4.donation.title) }}
          </h4>

          <app-link
            :link="column4.donation.cta"
            class="cta bold margin__t--18"
          />
        </div>
      </div>
    </div>

    <session-modal
      v-if="show_modal"
      @close="closeModal"
    />
  </footer>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { get as _get } from 'lodash'
import { useStore } from 'vuex'
import { useModals } from '@/composables/app/useModals'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const UserProfileImg = defineAsyncComponent(() => import('&/atoms/UserProfileImg'))
const SessionModal = defineAsyncComponent(() => import('&/organisms/SessionModal'))

const props = defineProps({
  section: { type: Object, required: true }
})

const store = useStore()
const { show_modal, openModal, closeModal } = useModals()

const client = computed(() => store.getters['client/client'])
const user = computed(() => store.getters['auth/user'])
const isAuthenticated = computed(() => store.getters['auth/isAuthenticated'])

const column1 = computed(() => _get(props, 'section.attributes.column_1'))
const column2 = computed(() => _get(props, 'section.attributes.column_2'))
const column3 = computed(() => _get(props, 'section.attributes.column_3'))
const column4 = computed(() => _get(props, 'section.attributes.column_4'))
const socialNetworks = computed(() => {
  return (_get(client.value, 'platform.social_networks') || [])
    .map(network => {
      if (network.icon[1] === 'facebook-square') network.icon[1] = 'facebook-f'

      return network
    })
})
</script>


<style lang="scss" scoped>
$color--primary: #131415;
$color--secondary: #FEFF00;
.wrapper {
  max-width: 1440px;
  padding: 0 $padding__base;
  margin: 0 auto;

  @include mq(sm) {
    padding: 0 $padding__base * 2;
  }

  @include mq(md) {
    padding: 0 $padding__base * 7;
  }
}

:deep(.cta) {
  padding: $padding__base $padding__base * 2;
  background: $color--primary;
  font-family: $font-family--secondary;
  color: $white;
  text-transform: uppercase;
  font-size: rem(16px);
  line-height: .9;
  transition: all $transition__duration;
  white-space: nowrap;
  display: inline-block;

  @include mq(sm) {
    padding: $padding__base * 1.5 $padding__base * 2.5;
    font-size: rem(20px);
  }

  &:hover {
    background: darken($color--primary, 10);
    color: $color--secondary;
  }
}

.footer {
  background: $color--secondary;
  padding: $padding__base * 4 0 $padding__base * 6;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: $margin__base * 2;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(lg) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__logo {
    max-width: 100%;
    width: 192px;
    height: auto;

    &-img {
      display: block;
    }
  }
}

.networks {
  display: flex;
  align-items: center;

  &__li {
    &:not(:last-child) {
      margin-right: $margin__base * 1.4;
    }
  }

  &__a {
    display: block;
    width: 37px;
    height: 37px;
    line-height: 37px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid $color--primary;
    transition: all .2s;

    &:hover {
      background: $color--primary;
      color: $color--secondary;
    }
  }
}

.menu {
  &__li {
    &:not(:last-child) {
      margin-bottom: $margin__base;
    }
  }

  &:deep(.menu__a) {
    font-family: $font-family--secondary;
    font-size: rem(24px);
    line-height: 1.1;
    text-transform: uppercase;

    &.is-active, &:hover {
      text-decoration: underline;
    }
  }
}

.profile {
  font-family: $font-family--secondary;
  font-size: rem(24px);
  line-height: 1.1;
  text-transform: uppercase;
}

.donation {
  border: 1px solid $color--primary;

  &__title {
    font-family: $font-family--secondary;
    text-transform: uppercase;
    font-size: rem(17px);
    line-height: 1.1;
  }
}
</style>
