import pageSections from '@/configurations/pages'
import { clone } from '@/utils/utils'
import { merge as _merge } from 'lodash'

let Section = class Section {
  constructor ({ name = null, type = null, options = {}, style = {}, attributes = {}, settings = {}, content = {}, path = null } = {}) {
    this.name = name
    this.type = type
    this.options = options
    this.style = style
    this.attributes = attributes
    this.settings = settings
    this.content = content
    this.path = path
  }

  isVisible = () => {
    return !('is_visible' in this.options) || !!this.options.is_visible
  }

  database = () => {
    return {
      name: this.name,
      style: this.style,
      type: this.type,
      attributes: this.attributes,
      options: this.options
    }
  }

  static = () => {
    const referenceSection = clone(pageSections.find(section => {
      const match = this.name.match(/.*(?=_)/gm) || []
      return match.includes(section.name) || section.name === this.name
    }))
    return referenceSection ? new Section(_merge(referenceSection, this.database())) : this
  }
}

export default Section
