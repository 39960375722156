import Project from '@/classes/projects/Project'
const endpoint = '/api/v1/projects'

export default {
  namespaced: true,
  state: {
    items: {}
  },
  getters: {
    getProjects: state => state.items,
    getProjectsPaginator: state => state.paginator,
    getProjectBySlug: state => slug => Object.values(state.items).find(item => item.slug === slug),
    getProjectById: state => id => Object.values(state.items).find(item => item.id === id)
  },
  actions: {
    fetchProjects: ({ dispatch }, { query, removeStoredItems }) => dispatch(
      'fetchItems',
      {
        endpoint,
        query,
        resource: 'projects',
        model: project => new Project(project),
        removeStoredItems
      },
      { root: true }
    ),

    fetchProject: ({ dispatch }, { id }) => dispatch(
      'fetchItem',
      {
        endpoint: `${endpoint}/${id}`,
        resource: 'projects',
        model: project => new Project(project)
      },
      { root: true }
    )
  }
}
