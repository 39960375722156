<template>
  <main class="main home">
    <section
      class="hero"
      v-if="$i18n.translate(section.attributes.hero.image)"
    >
      <img
        :src="$i18n.translate(section.attributes.hero.image)"
        width="1440"
        height="550"
      >
    </section>

    <section
      class="counter"
      v-if="section.attributes.metrics.length > 0 && stats && stats.amount_collected > 0"
    >
      <ul
        class="counter__ul"
      >
        <li
          class="counter__li padding--12"
          v-for="(metric, index) in section.attributes.metrics"
          :key="`${metric.type}_${index}`"
        >
          <div class="counter__stats bold">
            <app-date
              v-if="metric.type === 'date' && metric.date"
              :timestamp="metric.date"
              filter="differenceInCalendarDays"
            />
            <app-money
              v-else-if="metric.type === 'amount_collected'"
              :amount="stats[metric.type]"
            />
            <p v-else>
              {{ stats[metric.type] }}
            </p>
          </div>

          <p class="counter__label">
            {{ $i18n.translate(metric.text) }}
          </p>
        </li>
      </ul>
    </section>

    <section
      class="no_metric"
      v-else
    >
      <div
        class="editor__content no_metric__content"
        v-html="$i18n.translate(section.attributes.no_metric.content)"
      />
    </section>

    <section
      class="actions"
      v-if="hasCtas"
    >
      <div class="wrapper actions__wrapper">
        <template
          v-for="(cta, index) in section.attributes.actions"
        >
          <button
            class="btn btn__size--classic btn__solid--prim bold"
            @click.prevent="setRouteNavigation(cta)"
            :key="`cta_${index}`"
            v-if="cta.show"
          >
            {{ $i18n.translate(cta.text) }}
          </button>
        </template>
      </div>
    </section>

    <article class="about">
      <div class="wrapper about__wrapper no-gutters">
        <figure class="thumb about__image col-md-6">
          <img
            :src="$i18n.translate(section.attributes.about.image)"
            width="768"
            height="893"
            v-if="$i18n.translate(section.attributes.about.image)"
          >
          <fa-icon
            :icon="['far', 'image']"
            size="4x"
            v-else
          />
        </figure>

        <div
          class="editor__content about__content col-md-6"
          v-html="$i18n.translate(section.attributes.about.content)"
        />
      </div>
    </article>

    <article class="campaign">
      <div class="wrapper campaign__wrapper no-gutters">
        <figure
          class="thumb campaign__image col-md-6 col-lg-7"
          :style="{ backgroundImage: `url(${$i18n.translate(section.attributes.campaign.image)})` }"
        >
          <fa-icon
            :icon="['far', 'image']"
            size="4x"
            v-if="!$i18n.translate(section.attributes.campaign.image)"
          />
        </figure>

        <div class="editor__content campaign__content col-md-6 col-lg-8">
          <div
            class="campaign__text"
            v-html="$i18n.translate(section.attributes.campaign.content)"
          />

          <button
            class="btn btn__size--classic btn__solid--prim bold margin__t--24"
            @click.prevent="setRouteNavigation(section.attributes.campaign.cta)"
            v-if="section.attributes.campaign.cta.show"
          >
            {{ $i18n.translate(section.attributes.campaign.cta.text) }}
          </button>
        </div>
      </div>
    </article>
  </main>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import AppMoney from '&/atoms/AppMoney'
import PageRouteNavigation from '%/PageRouteNavigation'

export default defineComponent({
  name: 'CustomHomepageUZGent',
  components: {
    AppMoney,
    AppDate: defineAsyncComponent(() => import('&/atoms/AppDate'))
  },
  mixins: [PageRouteNavigation],
  data () {
    return {}
  },
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  created () {
    this.fetchClientStatistics()
  },
  computed: {
    ...mapGetters({
      stats: 'client/statistics'
    }),
    hasCtas () {
      return Object.values(this.section.attributes.actions).filter(cta => cta.show).length
    }
  },
  methods: {
    ...mapActions({
      fetchClientStatistics: 'client/fetchClientStatistics'
    })
  }
})
</script>

<style lang="scss" scoped>
  $color--primary: #0169CA;
  $color--secondary: #131415;

  .wrapper {
    position: relative;
    margin: 0 auto;
    max-width: 1440px;
  }

  .home {
    background: $white;
    padding-top: $padding__base * 3;

    @include mq(sm) {
      padding-top: $padding__base * 5;
    }
  }

  .btn {
    text-transform: uppercase;
    border-radius: 0;
    font-size: rem(16px);
  }

  .thumb {
    position: relative;
    background: $dw;
    color: $vlg;
    font-size: rem(28px);

    @include mq(md) {
      font-size: rem(24px);
    }

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .editor {
    &__content {
      color: $color--secondary;

      @include mq(sm) {
        font-size: rem(18px);
      }

      & :deep() {
        h1 {
          font-size: rem(28px);
          text-transform: uppercase;

          @include mq(sm) {
            font-size: rem(34px);
          }
        }

        h2 {
          color: $color--secondary;
          text-transform: uppercase;
          line-height: 1.4;
          font-size: rem(24px);
          margin-top: 0;

          @include mq(sm) {
            font-size: rem(34px);
          }

          &:first-child {
            &::before {
              content: '';
              display: block;
              width: 92px;
              height: 3px;
              background: $color--primary;
              margin-bottom: $margin__base;
            }
          }
        }

        ul {
          li {
            position: relative;
            list-style: none;

            &::before {
              content: '';
              display: block;
              position: absolute;
              left: -17px;
              top: 7px;
              background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA3LjIgMTEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDcuMiAxMTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDt9Cjwvc3R5bGU+Cjxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMCwxMSA3LjIsNS42IDAuMiwwICIvPgo8L3N2Zz4K");
              background-repeat: no-repeat;
              background-size: auto 100%;
              width: 7px;
              height: 11px;
            }
          }
        }
      }
    }
  }

  .hero {
    position: relative;
    z-index: 0;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    & + .counter, & + .no_metric {
      @include mq(md) {
        margin-top: -81px;
      }
    }

    & + .actions {
      margin-top: $margin__base * 2;
    }
  }

  .counter {
    position: relative;
    z-index: 10;
    color: $white;
    font-size: rem(18px);
    text-align: center;

    @include mq(md) {
      margin: 0 0 $margin__base * 2;
    }

    &__ul {
      background: $color--primary;
      padding: $padding__base;

      @include mq(sm) {
        display: flex;
        justify-content: center;
      }

      @include mq(md) {
        max-width: 850px;
        margin: 0 auto;
        padding: $padding__base * 3;
      }
    }

    &__li {
      @include mq(md) {
        margin-right: 5 * $margin__base;
      }

      &:last-child {
        margin: 0;
      }
    }

    &__stats {
      font-size: rem(26px);
    }
  }

  .no_metric {
    position: relative;
    z-index: 10;
    font-size: rem(18px);
    text-align: center;

    @include mq(md) {
      margin: 0 0 $margin__base * 2;
    }

    &__content {
      color: $white;
      background: $color--primary;
      padding: $padding__base;

      @include mq(sm) {
        display: flex;
        justify-content: center;
      }

      @include mq(md) {
        max-width: 850px;
        margin: 0 auto;
        padding: 49px 0;
      }
    }
  }

  .actions {
    &__wrapper {
      text-align: center;
      padding: $padding__base * 2;

      @include mq(sm) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 850px;
        margin: 0 auto;
      }

      @include mq(md) {
        padding: 0;
      }
    }

    .btn {
      flex-grow: 1;
      margin-bottom: $margin__base;
      width: 100%;

      @include mq(sm) {
        margin: 0 $margin__base * 3 0 0;
        width: auto;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  .about {
    @include mq(sm) {
      padding: 4 * $padding__base 0;
    }

    @include mq(md) {
      padding: 8 * $padding__base 0;
    }

    &__wrapper {
      @include mq(md) {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
        width: 83.33333%;
        max-width: 1075px;
      }
    }

    &__content {
      padding: $padding__base * 2;
      flex-shrink: 1;

      @include mq(md) {
        padding: 0;
        margin-right: $margin__base * 2;
      }
    }

    &__image {
      width: 100%;

      @include aspect-ratio(524, 609);

      @include mq(md) {
        max-width: 524px;
      }
    }
  }

  .campaign {
    &__wrapper {
      @include mq(md) {
        display: flex;
        width: 83.33333%;
        max-width: 1075px;
      }

      @include mq(lg) {
        align-items: center;
        flex-direction: row-reverse;
      }
    }

    &__image {
      margin-right: $margin__base * 2;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      @include aspect-ratio(634, 613);

      @include mq(lg) {
        position: absolute;
        left: 0;
        height: 100%;
      }
    }

    &__content {
      position: relative;
      background: $white;
      padding: $padding__base * 2;
      flex-shrink: 1;

      @include mq(md) {
        padding: 0;
      }

      @include mq(lg) {
        box-sizing: content-box;
        margin: $margin__base * 5 0;
        padding: $padding__base * 5 0 $padding__base * 5 $padding__base * 5;
      }

      .btn {
        min-width: 250px;
      }
    }

    &__text {
      & :deep() {
        p, ul, li {
          @include mq(lg) {
            max-width: 577px;
          }
        }
      }
    }
  }
</style>