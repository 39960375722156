<template>
  <div class="footer__signature">
    <div class="signature__container container">
      <a
        class="signature__logo"
        href="https://www.koalect.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          :src="getImagePath('logo/koalect_raisenow_dark_grey_logo.png')"
          alt="Koalect"
        >
      </a>

      <ul class="signature__list">
        <li
          class="signature__item"
          v-if="translate(getTermsOfUseTitle)"
        >
          <router-link
            class="link__color--lg-prim link--naked"
            :to="{ name: 'Page', params: { path: 'terms-of-use', locale: $i18n.locale } }"
          >
            {{ translate(getTermsOfUseTitle) }}
          </router-link>
        </li>

        <li
          class="signature__item"
          v-if="translate(getPrivacyPolicyTitle)"
        >
          <router-link
            class="link__color--lg-prim link--naked"
            :to="{ name: 'Page', params: { path: 'privacy-policy', locale: $i18n.locale } }"
          >
            {{ translate(getPrivacyPolicyTitle) }}
          </router-link>
        </li>

        <li class="signature__item">
          <button
            class="link__color--lg-prim link--naked"
            @click="openCookieBar"
          >
            {{ t('literal.cookie_preferences') }}
          </button>
        </li>

        <li class="signature__item">
          © <app-date
            filter="yyyy"
            :timestamp="new Date()"
          /> Koalect
        </li>
      </ul>
    </div>

    <cookie-bar ref="cookiebar" />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { mapGetters } from '@/store/map-state'
import { getImagePath } from '@/utils/utils'
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'
import CookieBar from '&/organisms/CookieBar'
import AppDate from '&/atoms/AppDate'

const { translate, t } = useI18n()
const { client } = mapGetters('client')

const cookiebar = ref()

const getTermsOfUseTitle = computed(() => _get(client.value.pages.find(page => page.path === 'terms-of-use'), 'title'))
const getPrivacyPolicyTitle = computed(() => _get(client.value.pages.find(page => page.path === 'privacy-policy'), 'title'))

const openCookieBar = () => {
  cookiebar.value?.switchView('manage')
  cookiebar.value?.open()
}
</script>

<style lang="scss" scoped>
.footer {
  &__signature {
    position: relative;
    border-top: 1px solid $dw;
    padding: 12px 0;
    background-color: $color--bg;
    text-align: center;

    @include mq(sm) {
      padding: 24px 0;
    }
  }
}

.signature {
  &__container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;
    align-items: center;

    @include mq(sm) {
      grid-template-columns: 118px auto;
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
  }

  &__list {
    @include mq(sm) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &__item {
    font-size: em(14px);
    color: $mg;
    padding: 3px 0;

    @include mq(sm) {
      &:not(:first-child):before {
        content: '';
        display: inline-block;
        width: 1px;
        height: 16px;
        background: $vlg;
        border-radius: 50%;
        vertical-align: middle;
        margin: 0 6px;
      }
    }

    @include mq(md) {
      &:not(:first-child):before {
        margin: 0 12px;
      }
    }
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}
</style>
