<template>
  <div class="notification notification--warning">
    <p class="bold">
      {{ t('literal.your_payment_is_being_processed') }}
    </p>

    <p class="margin__t--6">
      {{ t('literal.you_will_receive_an_email_to_confirm_whether_or_not_your_payment_is_done') }}
    </p>
  </div>
</template>

<script setup>
import { useI18n } from '@/vendors/i18n'

const { t } = useI18n()
</script>

<style scoped lang="scss">
.notification {
  padding: 12px 18px;
  border-top-right-radius: $radius__cards;
  border-bottom-right-radius: $radius__cards;
  margin-bottom: 24px;

  &--warning {
    background: tint($color--warning, 50%, 90%);
    border-left: 3px solid $color--warning;
  }
}
</style>