<template>
  <router-link
    :to="{ name: 'ClientProcedure', params: { procedureSlug: procedure.slug }, query }"
    id="donate"
    class="btn btn__color--prim align--center"
    v-if="procedure?.is_ongoing && project.is_approved && !isPreview && !isExecutionLimitReached"
  >
    <slot
      name="default"
      v-if="slots.default"
    />

    <slot
      name="ongoing"
      v-else
    >
      {{ t('literal.donate') }}
    </slot>
  </router-link>

  <button
    class="btn btn__color--prim"
    :disabled="true"
    v-else
  >
    <slot v-if="slots.default" />

    <template v-else>
      <template v-if="isExecutionLimitReached">
        <slot name="reached">
          {{ t('literal.already_contributed') }}
        </slot>
      </template>

      <template v-else-if="procedure.is_ongoing">
        <slot name="ongoing">
          {{ t('literal.donate') }}
        </slot>
      </template>

      <template v-else-if="!procedure.is_started">
        <slot name="not_started">
          {{ t('project_page.btn_donate_not_started_yet') }}
        </slot>
      </template>

      <template v-else-if="procedure.is_ended">
        <slot name="ended">
          {{ t('project_page.btn_donate_finished') }}
        </slot>
      </template>
    </template>
  </button>
</template>

<script setup>
import { computed, useSlots, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useI18n } from '@/vendors/i18n'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { useProcedureStore } from '@/stores/ProcedureStore'
import { useProcedureRestrictions } from '@/composables/modules/procedures'
import { omit as _omit } from 'lodash'
import { toRefs } from 'vue'

const props = defineProps({
  project: { type: Object, required: true },
  isPreview: { type: Boolean, default: false }
})

const { project } = toRefs(props)

const slots = useSlots()
const route = useRoute()
const { t } = useI18n()

const procedure = computed(() => project.value?.procedures.donation)

const { getProcedureAuthorization } = useProcedureStore()
const { isExecutionLimitReached, fetchProcedureRestrictions } = useProcedureRestrictions({ procedure })
const { isAuthenticated } = storeToRefs(useAuthenticationStore())

const { timestamp } = getProcedureAuthorization({ id: procedure.value?.slug })

const query = computed(() => _omit(route.query, ['tab']))

watch(isAuthenticated, () => {
  if (isAuthenticated.value && procedure.value && !timestamp.value) fetchProcedureRestrictions({ show_modal: false })
}, { immediate: true })
</script>