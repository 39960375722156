<template>
  <footer class="footer">
    <div class="container wrapper">
      <section
        v-if="column_1.menu.length > 0"
        class="column"
      >
        <h3
          v-if="$i18n.translate(column_1.title)"
          class="footer__title"
        >
          {{ $i18n.translate(column_1.title) }}
        </h3>

        <ul v-if="column_1.menu.length > 0">
          <li
            v-for="({ link }, index) in column_1.menu"
            :key="`menu_item_${index}`"
            class="footer__li"
          >
            <a
              class="footer__link"
              @click.prevent="setRouteNavigation(link)"
            >
              {{ $i18n.translate(link.text) }}
            </a>
          </li>
        </ul>
      </section>

      <section class="column">
        <h3
          v-if="$i18n.translate(column_2.title)"
          class="footer__title"
        >
          {{ $i18n.translate(column_2.title) }}
        </h3>

        <ul>
          <li class="footer__li">
            <a
              @click.prevent="openSessionAppModal('register')"
              class="nav__login footer__link"
              v-if="!isAuthenticated"
            >
              <fa-icon
                class="margin__r--6"
                :icon="['fas', 'user']"
              />

              <span v-html="$i18n.t('literal.register')" />
            </a>

            <router-link
              class="nav__login footer__link d-flex align-items-center"
              :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
              v-if="isAuthenticated && user"
            >
              <user-profile-img
                size="2"
                :avatar="user.avatar"
              />

              <span class="bottom__user-name d-block margin__l--6">{{ user.firstname }}</span>
            </router-link>
          </li>

          <li class="footer__li">
            <a
              @click.prevent="openSessionAppModal('login')"
              class="nav__login footer__link"
              v-if="!isAuthenticated"
            >
              <fa-icon
                class="login__icon margin__r--6"
                :icon="['fal', 'sign-in']"
              />

              <span v-html="$i18n.t('literal.login')" />
            </a>

            <router-link
              v-if="isAuthenticated && user"
              :to="{ name: 'Logout', params: { locale: $i18n.locale } }"
              class="nav__login link--naked pointer link__color--danger"
            >
              <fa-icon
                class="margin__r--6 color--danger"
                :icon="['fas', 'sign-out']"
                fixed-width
              />

              <span v-html="$i18n.t('literal.logout')" />
            </router-link>
          </li>

          <li
            class="footer__li"
            v-for="({ link }, index) in column_2.actions"
            :key="`menu_item_${index}`"
          >
            <a
              class="footer__link"
              @click.prevent="setRouteNavigation(link)"
            >
              {{ $i18n.translate(link.text) }}
            </a>
          </li>
        </ul>
      </section>

      <section
        class="column"
        v-if="socialNetworks.length > 0"
      >
        <h3
          v-if="$i18n.translate(column_3.title)"
          class="footer__title"
        >
          {{ $i18n.translate(column_3.title) }}
        </h3>

        <ul>
          <li
            class="footer__li"
            v-for="(network, i) in socialNetworks"
            :key="'footer_menu_network_' + i"
          >
            <a
              class="footer__link"
              :href="network.url[$i18n.locale]"
              target="_blank"
            >
              <fa-icon
                class="footer__link-icon"
                v-if="network.icon"
                size="lg"
                :icon="network.icon"
              />

              <span
                v-if="$i18n.translate(network.text)"
              >
                {{ $i18n.translate(network.text) }}
              </span>
            </a>
          </li>
        </ul>
      </section>

      <section
        class="column"
        v-if="$i18n.translate(column_4.name) || column_4.address.length > 0 || contactInfos.length > 0"
      >
        <h3
          v-if="$i18n.translate(column_4.title)"
          class="footer__title"
        >
          {{ $i18n.translate(column_4.title) }}
        </h3>

        <p v-if="$i18n.translate(column_4.name)">
          {{ $i18n.translate(column_4.name) }}
        </p>

        <div
          class="footer__address"
          v-if="column_4.address.length > 0"
        >
          <fa-icon
            class="footer__address--icon margin__r--6"
            :icon="['fas', 'map-marker-alt']"
          />

          <address>
            <p
              v-for="({ line }, index) in column_4.address"
              :key="`menu_item_${index}`"
            >
              {{ $i18n.translate(line) }}
            </p>
          </address>
        </div>

        <div
          v-if="contactInfos.length > 0"
          class="footer__address"
        >
          <fa-icon
            class="footer__address--icon margin__r--6"
            :icon="['fas', 'phone-alt']"
          />

          <address>
            <template
              v-for="(item, index) in contactInfos"
              :key="'footer_infos_line_' + index"
            >
              <a
                class="footer__text d-block"
                :key="'footer_infos_line_' + index"
                :href="item.url"
                v-if="item.url"
                target="_blank"
              >
                {{ item.text }}
              </a>

              <span
                v-else
                class="d-block"
              >
                {{ item.text }}
              </span>
            </template>
          </address>
        </div>
      </section>

      <app-modal
        v-if="showSessionAppModal"
        size="sm"
        @close="showSessionAppModal = false"
      >
        <template
          #body
        >
          <session-forms
            class="modal__body"
            :tab="template"
            @authenticated="showSessionAppModal = false"
          />
        </template>
      </app-modal>
    </div>
  </footer>
</template>

<script>
import { defineComponent } from 'vue'
import { defineAsyncComponent } from 'vue'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'
import { mapGetters } from 'vuex'
import UserProfileImg from '&/atoms/UserProfileImg'

export default defineComponent({
  name: 'CustomFooterIHECS',
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  components: {
    UserProfileImg,
    SessionForms: defineAsyncComponent(() => import('&/modules/sessions/SessionForms')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal'))
  },
  data () {
    return {
      showSessionAppModal: false,
      mobileMenuOpened: false
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    column_1 () {
      return _get(this.section, 'attributes.column_1', {})
    },
    column_2 () {
      return _get(this.section, 'attributes.column_2', {})
    },
    column_3 () {
      return _get(this.section, 'attributes.column_3', {})
    },
    column_4 () {
      return _get(this.section, 'attributes.column_4', {})
    },
    socialNetworks () {
      return _get(this.client, 'platform.social_networks', []).filter(link => this.$i18n.translate(link.url))
    },
    contactInfos () {
      return _get(this.column_4, 'contact', [])
        .map(info => {
          return {
            text: this.$i18n.translate(info.text),
            url: info.type !== 'text' ? (info.type === 'phone_number' ? 'tel:' : 'mailto:') + this.$i18n.translate(info.text).replace(/ /g, '') : null
          }
        })
        .filter(info => info.text)
    }
  },
  methods: {
    openSessionAppModal (template) {
      this.template = template
      this.showSessionAppModal = true
    }
  }
})
</script>

<style lang="scss" scoped>
$color--tertiary: #6bc5cf;
$light-grey: #cccccc;

.footer {
  font-size: rem(14px);
  background: $white;
  padding: $padding__base * 3;
  line-height: 1.75;

  &__title {
    text-transform: uppercase;
    color: $light-grey;
    font-size: rem(16px);
    font-family: $font-family--secondary;
    letter-spacing: 2px;
    margin: calc($margin__base / 2) 0;
  }

  &__li {
    &:not(:last-child) {
      margin-bottom: calc($margin__base / 2);
    }
  }

  &__link {
    cursor: pointer;
    transition: all .3s;

    &-icon {
      font-size: 16px;
      margin-right: calc($margin__base / 2);
    }

    &:hover {
      color: $color--tertiary;
    }
  }

  &__address {
    display: flex;

    &--icon {
      width: 14px;
      margin-top: 5px;
    }
  }

  &__text {
    &:nth-child(1) {
      &::before {
        content: 'T.';
        margin-right: 3px;
      }
    }
  }
}

.wrapper {
  margin: 0 auto;

  @include mq(sm) {
    max-width: 640px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq(md) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 960px;
  }

  @include mq(lg) {
    max-width: 1140px;
  }
}

.column {
  padding: 0 0 $padding__base;

  @include mq(md) {
    width: 25%;
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}
</style>