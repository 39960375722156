<template>
  <div>
    <hr class="separator separator__size--full border__color--dw margin__tb--18">

    <div
      class="d-flex justify-content-center"
      v-if="loader"
    >
      <app-spinner size="3" />
    </div>

    <fieldset
      class="fieldset"
      v-else
    >
      <div class="fieldset__card">
        <label
          class="input__label"
          for="card-number"
        >
          {{ t('$block_payment.card_number_label') }} *
        </label>

        <stripe-element
          class="margin__t--6"
          type="cardNumber"
          id="card-number"
          :v="validator.number"
          :icon="['far', 'credit-card']"
          :placeholder="t('$block_payment.card_number_placeholder')"
          @change="e => card.number = e.complete"
        />
      </div>

      <div>
        <label
          class="input__label"
          for="card-expiry"
        >
          {{ t('$block_payment.card_expiry_date_label') }} *
        </label>

        <stripe-element
          class="margin__t--6"
          type="cardExpiry"
          id="card-expiry"
          :v="validator.expiry"
          :icon="['far', 'calendar-alt']"
          :placeholder="t('$block_payment.card_expiry_placeholder')"
          @change="e => card.expiry = e.complete"
        />
      </div>

      <div>
        <label
          class="input__label"
          for="card-cvc"
        >
          {{ t('$block_payment.card_cvc_label') }} *
        </label>

        <stripe-element
          class="margin__t--6"
          type="cardCvc"
          id="card-cvc"
          :v="validator.cvc"
          :icon="['fas', 'lock']"
          :placeholder="t('$block_payment.card_cvc_placeholder')"
          @change="e => card.cvc = e.complete"
        />
      </div>
    </fieldset>
  </div>
</template>

<script setup>
import { ref, reactive, defineAsyncComponent, inject } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useNotificationStore } from '@/stores/NotificationStore'
import { useProcedureNavigation, useProcedureBlockValidator } from '@/composables/modules/procedures'
import { sameAs } from '@vuelidate/validators'
import { useStripe } from '@/vendors/integrations/stripe'

const AppSpinner = defineAsyncComponent(() => import('&/atoms/AppSpinner'))
const StripeElement = defineAsyncComponent(() => import('&/molecules/StripeElement'))

const procedure = inject('procedure')
const procedure_execution = inject('procedure_execution')

const loader = ref(true)
const card = reactive({ number: false, expiry: false, cvc: false })

const { t } = useI18n()
const stripe = useStripe()
const { nextRedirection } = useProcedureNavigation({ procedure, procedure_execution })
const { postModalNotification } = useNotificationStore()
const { validator } = useProcedureBlockValidator({ validations: { number: { sameAs: sameAs(true) }, expiry: { sameAs: sameAs(true) }, cvc: { sameAs: sameAs(true) } }, state: card })

stripe.loader()
  .then(() => loader.value = false)
  .catch(() => postModalNotification({ title: 'errors.an_error_occurred', code: '#001501', error: 'Stripe failed to load', close: nextRedirection }))
</script>

<style lang="scss" scoped>
.fieldset {
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;

  @include mq(sm) {
    grid-template-columns: 1fr 1fr;

    &__card {
      grid-column: span 2;
    }
  }
}
</style>