<template>
  <header
    class="header"
    :class="{'mobile': showMobileMenu, 'scroll': showMobileLogo}"
  >
    <div class="container">
      <div class="grid">
        <router-link
          :to="{ name: 'Home', params: { locale } }"
          v-show="client.logo.url"
        >
          <img
            class="logo__mobile"
            :src="translate(mobileLogo)"
            :alt="client.name"
          >

          <img
            class="logo__desktop"
            :src="client.logo.url"
            :alt="client.name"
          >
        </router-link>

        <ul class="navigation">
          <li
            class="navigation__li"
            v-for="({ link }, key) in links"
            :key="`link_${key}`"
          >
            <app-link
              :link="link"
              class="navigation__link"
            />
          </li>
        </ul>

        <article class="actions">
          <ul class="social social__ul">
            <li
              class="social__li"
              v-for="(media, index) in socialMedias"
              :key="'header_menu_social_media_' + index"
            >
              <a
                class="social__link"
                :href="translate(media.url)"
                target="_blank"
              >
                <fa-icon
                  v-if="media.icon"
                  size="md"
                  :icon="media.icon"
                  class="social__icon"
                />
              </a>
            </li>
          </ul>

          <ul class="buttons buttons__ul">
            <li
              class="buttons__li"
              v-for="({ link }, key) in actions"
              :key="`link_${key}`"
            >
              <app-link
                :link="link"
                class="buttons__link"
              />
            </li>
          </ul>

          <div class="session">
            <template v-if="isAuthUser">
              <router-link
                class="session__user"
                :to="{ name: 'Profile', params: { locale } }"
              >
                <user-profile-img
                  :size="2"
                  :avatar="authUser.avatar"
                />

                <p class="margin__l--6">
                  {{ authUser.firstname }}
                </p>
              </router-link>
            </template>

            <template v-else>
              <a
                @click.prevent="toggleSessionModal('login')"
                class="session__user"
              >
                <fa-icon
                  size="lg"
                  :icon="['fal', 'user-circle']"
                  fixed-width
                />

                {{ translate(login) || t('literal.login') }}
              </a>
            </template>

            <language-switcher class="language" />
          </div>
        </article>

        <app-burger-menu
          class="burger"
          :state="showMobileMenu"
          @toggle="toggleMobileMenu"
        />
      </div>
    </div>

    <app-modal
      v-if="showSessionModal"
      size="sm"
      @close="showSessionModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="sessionTemplate"
          @authenticated="showSessionModal = false"
        />
      </template>
    </app-modal>
  </header>
</template>

<script setup>
import { computed, defineAsyncComponent, ref, watch, onMounted, onBeforeUnmount } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useClientStore } from '@/stores/ClientStore'
import { useLanguageStore } from '@/stores/LanguageStore'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { get as _get } from 'lodash'

import AppLink from '&/atoms/AppLink'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
const UserProfileImg = defineAsyncComponent(() => import('&/atoms/UserProfileImg'))
const SessionForms = defineAsyncComponent(() => import('&/modules/sessions/SessionForms'))
const AppModal = defineAsyncComponent(() => import('&/organisms/AppModal'))
const LanguageSwitcher = defineAsyncComponent(() => import('&/atoms/LanguageSwitcher'))

const props = defineProps({ section: { type: Object, required: true } })

const route = useRoute()
const { t, translate } = useI18n()
const { locale } = storeToRefs(useLanguageStore())
const { authUser, isAuthUser } = storeToRefs(useAuthenticationStore())
const { getClient } = useClientStore()

const { client } = getClient()

const showSessionModal = ref(false)
const showMobileMenu = ref(false)
const showMobileLogo = ref(false)
const sessionTemplate = ref('login')
const links = computed(() => _get(props, 'section.attributes.links', []))
const actions = computed(() => _get(props, 'section.attributes.actions', []))
const login = computed(() => _get(props, 'section.attributes.login', {}))
const mobileLogo = computed(() => _get(props, 'section.attributes.mobile_logo', {}))

const socialMedias = computed(() => {
  const links = _get(client.value, 'platform.social_networks', [])

  return links.map(link => {
    if (link.icon.includes('facebook-square')) {
      link.icon[1] = 'facebook-f'
    } else if (link.icon.includes('linkedin')) {
      link.icon[1] = 'linkedin-in'
    }

    return link
  }).filter(link => translate(link.url))
})

watch(route, () => showMobileMenu.value = false)

const toggleSessionModal = template => {
  sessionTemplate.value = template
  showSessionModal.value = true
}

const toggleMobileMenu = () => showMobileMenu.value = !showMobileMenu.value

const handleScroll = () => {
  if (Math.round(window.scrollY) > 102) showMobileLogo.value = true
  if (Math.round(window.scrollY) < 66 && !showMobileMenu.value) showMobileLogo.value = false
}

onMounted(() => window.addEventListener('scroll', handleScroll))
onBeforeUnmount(() => window.removeEventListener('scroll', handleScroll))
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: 184px 2fr 1fr;

  .actions {
    justify-self: flex-end;
  }
}

.navigation, .actions {
  display: flex;
  align-items: center;
  gap: 18px;
  padding: 18px 0 24px 0;
  position: relative;
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  padding-top: 36px;
  z-index: 1000;
  background-color: $white;
  transition: all .3s;

  & .burger {
    display: inherit;
    margin-left: auto;

    @include mq(md) {
      display: none;
    }
  }
}

.logo {
  &__desktop {
    position: absolute;
    max-height: 96px;
    display: block;
  }

  &__mobile {
    position: relative;
    max-height: 41px;
    display: none;
  }
}

.navigation {
  display: none;
  gap: 24px;
  transition: all .3s;

  @include mq(md) {
    display: flex;
    align-items: center;
  }

  &__li {
    position: relative;

    &:after {
      content: '';
      height: 3px;
      width: 0;
      background-color: $color--primary;
      position: absolute;
      bottom: -2px;
      left: 0;
      transition: all .25s ease-out;
    }

    &.active:after, &:hover:after {
      width: 100%;
    }
  }

  &:deep(.navigation__link) {
    text-transform: uppercase;
    font-weight: bold;
  }
}

.social {
  &__ul {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $black;
    color: $white;
    border-radius: 50%;
    padding: 1px;
    width: 24px;
    height: 24px;
  }
}

.buttons {
  &__ul {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &:deep(.buttons__link) {
    color: $color--primary;
    font-size: .85rem;
    font-weight: bold;
    text-transform: uppercase;
    padding: 7px 30px;
    border: 3px solid $color--primary;
    line-height: 1;
    white-space: nowrap;
    transition: all .25s ease-out;

    &:hover {
      color: $black;
      border-color: $black;
    }
  }
}

.session {
  margin-left: auto;
  display: none;

  @include mq(md) {
    display: inherit;
  }

  &__user {
    display: flex;
    align-items: center;
    color: $color--primary;
    gap: 3px;
    transition: all .25s ease-out;

    &:hover {
      color: $black;
    }
  }
}

.language {
  font-weight: bold;
  margin-left: 18px;

  & :deep() {
    .ls__dropdown-menu-icon {
      display: none;
    }

    .vs__actions {
      color: $color--primary;
      padding-left: 3px;
    }

    .vs__dropdown-toggle {
      padding: 3px;
    }

    .vs__dropdown-menu {
      right: -2px;
      left: auto;
      margin: 1px 0 0 0;
      background-color: $white;
      border: 0;
      border-radius: 0;
    }

    .vs__dropdown-option {
      padding: 3px 6px;
    }

    .v-select {
      border: 0;
    }

    .vs__selected {
      color: $color--primary;
      font-size: 12px;
    }
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}

.scroll {
  padding-top: 0;

  .grid {
    grid-template-columns: 70px 2fr 1fr;
    align-items: center;

    @media (max-width: 991px) {
      grid-template-columns: 70px 1fr 70px;
    }
  }

  .navigation {
    padding: 18px 0;
  }

  .logo {
    &__mobile {
      display: block;
    }

    &__desktop {
      display: none;
    }
  }
}

@media (max-width: 1200px) {
  .header {
    padding-top: 0;
  }

  .grid {
    grid-template-columns: 70px 1fr 2fr;
    align-items: center;
  }

  .navigation {
    padding: 18px 0;
  }

  .logo {
    &__mobile {
      display: block;
    }

    &__desktop {
      display: none;
    }
  }
}

@media (max-width: 991px) {
  .grid {
    grid-template-columns: 70px 1fr 70px;
    align-items: center;

    .actions {
      justify-self: center;
    }
  }

  .navigation, .actions {
    padding: 24px 0;
  }

  .social, .language {
    display: none;
  }

  .buttons__li:nth-child(n+2) {
    display: none;
  }

  .mobile {
    position: fixed;
    min-height: 100vh;

    .logo__mobile {
      margin: auto;
      margin-top: 24px;
    }

    .grid {
      flex-direction: column;
      grid-template-columns: 1fr;
    }

    .actions {
      justify-content: center;
      padding: 28px;
      gap: 36px;
      flex-direction: column;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        width: 100%;
        height: 3px;
        background-color: $color--primary;
      }
    }

    .social {
      display: flex;
      order: 2;
    }

    .language, .buttons__li:nth-child(n+2) {
      display: block;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .navigation {
      display: block;
      margin-top: 20px;
      margin-bottom: 12px;

      &__li {
        text-align: center;
        padding: 0;

        &:not(:first-child) {
          margin-top: 12px;
        }

        &:after {
          display: none;
        }
      }
    }

    .session {
      display: flex;
      order: 3;
    }

    .burger {
      position: absolute;
      right: 10px;
      top: 10px;

      &:deep(.menu__label) {
        display: none;
      }
    }
  }
}
</style>
