import { defineStore } from 'pinia'
import { toValue } from 'vue'
import Benefiting from '@/classes/benefitings/Benefiting'
import axios from '@/vendors/axios/axios'
import { getStringifyQuery } from '@/utils/utils'
import { set as _set } from 'lodash'


export const useBenefitingStore = defineStore('BenefitingStore', {
  state: () => ({
    benefitings: {},
    tags: []
  }),
  getters: {
    getBenefitings: state => ({ query } = {}) => {
      return state.$getItems({
        resource: 'benefitings',
        query
      })
    }
  },
  actions: {
    fetchBenefitings ({ query }) {
      const q = getStringifyQuery({ query: toValue(query) })
      const key = q || 'default'

      _set(this.benefitings, `['${key}'].loader`, true)
      _set(this.benefitings, `['${key}'].error`, null)

      return new Promise((resolve, reject) => {
        axios.get('/api/v1/benefitings' + q)
          .then(response => {
            const items = response.data.data.benefitings.map(item => new Benefiting(item))

            _set(this.benefitings, `['${key}'].items`, items)

            this.tags = response.data.data.tags

            resolve(items)
          })
          .catch(error => {
            _set(this.benefitings, `['${key}'].error`, error)
            reject(error)
          })
          .finally(() => _set(this.benefitings, `['${key}'].loader`, false))
      })
    }
  }
})