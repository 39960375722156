import OrderLine from '@/classes/procedures/OrderLine'

export default class OrderLines extends Array {
  constructor (order_lines, ...rest) {
    Array.isArray(order_lines) ? super(...order_lines.map(order_line => new OrderLine(order_line))) : super(...rest)
  }

  update ({ product_id, quantity }) {
    return this.remove({ product_id }).add({ product_id, quantity })
  }

  add ({ product_id, quantity }) {
    if (product_id && quantity) this.push(new OrderLine({ product_id, quantity }))

    return this
  }

  remove ({ product_id }) {
    return this.filter(order_line => order_line.product_id !== product_id)
  }
}
