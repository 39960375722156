<template>
  <footer class="footer">
    <div class="container footer__wrapper">
      <div>
        <router-link
          :to="{ name: 'Home', params: { locale } }"
          class="logo"
        >
          <img
            class="logo__img"
            :src="translate(logo) || client.logo.url"
            :alt="client.name"
          >
        </router-link>

        <ul
          v-if="(translate(email.url) || translate(email.text)) || translate(website.url) || translate(website.text)"
          class="footer__infos margin__t--24"
        >
          <li v-if="translate(email.url) || translate(email.text)">
            <a
              :href="translate(email.url)"
              class="link__color--white-sec link--naked"
            >
              {{ translate(email.text) }}
            </a>
          </li>

          <li v-if="translate(website.url) || translate(website.text)">
            <a
              :href="translate(website.url)"
              class="link__color--white-sec link--naked"
            >
              {{ translate(website.text) }}
            </a>
          </li>
        </ul>
      </div>

      <nav>
        <ul class="navigation__ul">
          <li
            v-for="({ link }, index) in links"
            :key="`navigation_${index}`"
            class="navigation__li"
          >
            <app-link
              :link="link"
              class="navigation__link link--naked link__color--white-sec"
            />
          </li>
        </ul>
      </nav>

      <div>
        <app-link
          :link="cta"
          class="btn btn__color--sec btn__size--medium cta"
        />

        <ul
          class="networks margin__t--42"
          v-if="socialNetworks.length"
        >
          <li
            class="networks__li"
            v-for="(network, index) in socialNetworks"
            :key="'network_' + index"
          >
            <a
              class="networks__a"
              :href="translate(network.url)"
              target="_blank"
              :aria-label="translate(network.text)"
            >
              <fa-icon
                v-if="network.icon"
                size="lg"
                :icon="network.icon"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useClientStore } from '@/stores/ClientStore'
import { get as _get } from 'lodash'
import { useI18n } from '@/vendors/i18n'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))

const props = defineProps({
  section: { type: Object, required: true }
})

const { translate, getLocale } = useI18n()
const { getClient } = useClientStore()

const { client } = getClient()

const locale = computed(() => getLocale())
const logo = computed(() => _get(props, 'section.attributes.column_1.logo.image', {}))
const website = computed(() => _get(props, 'section.attributes.column_1.website', {}))
const email = computed(() => _get(props, 'section.attributes.column_1.email', {}))
const links = computed(() => _get(props, 'section.attributes.column_2.nav', []))
const cta = computed(() => _get(props, 'section.attributes.column_3.cta', {}))

const socialNetworks = computed(() => _get(client.value, 'platform.social_networks', []).filter(network => translate(network.url)).map(network => {
  if (network.icon[1] === 'facebook-square') network.icon[1] = 'facebook-f'
  if (network.icon[1] === 'linkedin') network.icon[1] = 'linkedin-in'
  if (network.icon[1] === 'twitter') network.icon[1] = 'x-twitter'

  return network
}))
</script>

<style lang="scss" scoped>
$color--primary: #1D7874;
$color--secondary: #F95738;
.footer {
  background: $color--tertiary;
  padding: 48px;

  @include mq(sm) {
    padding: 48px 0 72px;
  }

  :deep() {
    .cta {
      font-size: rem(16px);
      line-height: 1.55;
      border-radius: 0;
      position: relative;
      z-index: 99;
      font-family: $font-family--secondary;
      text-transform: uppercase;
      text-align: center;

      @include mq(sm) {
        font-size: rem(20px);
      }
    }
  }

  &__wrapper {
    display: grid;
    gap: $margin__base * 4 $margin__base * 2;
    grid-template-columns: 1fr;

    @include mq(sm) {
      grid-template-columns: auto 1fr;
    }

    @include mq(md) {
      grid-template-columns: 1fr 1fr auto;
    }

    & > * {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
    }
  }

  &__infos {
    font-size: rem(18px);
  }
}

.logo {
  &__img {
    height: 130px;
  }
}

.networks {
  display: grid;
  grid-template-columns: repeat(5, 48px);
  gap: 6px 12px;

  @include mq(md) {
    gap: 6px 18px;
    grid-template-columns: repeat(5, 48px);
  }

  &__li {
    &:not(:last-child) {
      margin-right: $margin__base;
    }
  }

  &__a {
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    background: $color--primary;
    transition: $transition__duration;
    border-radius: 50%;
    color: $white;
    border: 1px solid $white;
    font-size: rem(20px);

    &:hover {
      background: $color--secondary;
    }
  }
}

.navigation {
  &__li {
    &:not(:last-child) {
      margin-bottom: calc($margin__base / 2);
    }

    &:deep() {
      .navigation__link {
        font-size: rem(32px);
        line-height: 1.1;
        font-family: $font-family--secondary;

        @include mq(sm) {
          font-size: rem(48px);
        }

        &.is-active {
          color: $color--secondary;
        }
      }
    }
  }
}
</style>
