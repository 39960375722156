<template>
  <input
    type="tel"
    class="input input--border input--radius"
    name="input-tel"
    v-model="tel"
    :id="id"
    :placeholder="placeholder"
    :disabled="disabled"
    @blur="blurIfMandatory()"
    v-bind="$attrs"
  >

  <div
    class="input--errors"
    v-if="v && v.$error"
  >
    <p
      class="input--error"
      v-if="v.required && v.required.$invalid"
    >
      {{ $i18n.t('errors.input_tel_required') }}
    </p>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InputTel',
  emits: ['update:modelValue'],
  props: {
    placeholder: {
      type: String,
      default: '...'
    },
    id: {
      type: String
    },
    modelValue: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    v: {
      type: Object
    }
  },
  computed: {
    tel: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    blurIfMandatory () {
      if (this.v) {
        this.v.$touch()
      }
    }
  }
})
</script>
