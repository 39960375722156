export default [
  {
    name: 'custom_header_fastenaktion',
    path: 'modules/pages/fastenaktion/HeaderFastenaktion'
  },
  {
    name: 'custom_footer_fastenaktion',
    path: 'modules/pages/fastenaktion/FooterFastenaktion'
  }
]
