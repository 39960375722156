<template>
  <section
    class="banner background"
    :style="`background-image: url(${ translate(banner.background) })`"
    v-if="isVisible('banner')"
  >
    <div
      class="editor editor__content"
      v-if="translate(banner.text)"
      v-html="translate(banner.text)"
    />
  </section>

  <section
    class="description"
    v-if="isVisible('description')"
  >
    <div class="container">
      <div
        class="editor editor__content"
        v-if="translate(description.text)"
        v-html="translate(description.text)"
      />
    </div>
  </section>

  <section
    class="stats"
    v-if="isVisible('stats')"
  >
    <div class="container">
      <h2 class="stats__title title--sec">
        {{ translate(stats.title) }}
      </h2>

      <div class="stats__grid">
        <article class="stats__card">
          <hr class="illustration__goal">

          <h3 class="card__title">
            {{ translate(stats.goal.title) }}
          </h3>

          <ul class="goal__ul">
            <li class="goal__li">
              <span class="goal__number goal__circle">{{ translate(stats.goal.stat_1) }}</span>
            </li>

            <li class="goal__li">
              <app-money
                class="goal__number"
                :show-currency="false"
                :amount="amountCollected"
              />

              <p class="goal__label">
                {{ translate(stats.goal.label_2) }}
              </p>
            </li>

            <li class="goal__li">
              <span class="goal__label">{{ translate(stats.goal.label_3_front) }}</span> <app-date
                class="goal__number"
                :timestamp="stats.goal.stat_3"
                filter="differenceInCalendarDays"
              /> <span class="goal__label">{{ translate(stats.goal.label_3_back) }}</span>
            </li>
          </ul>
        </article>

        <article class="stats__card">
          <hr class="illustration__impact">

          <h3 class="card__title">
            {{ translate(stats.impact.title) }}
          </h3>

          <ul class="impact__ul">
            <li class="impact__li">
              <span class="impact__number">{{ translate(stats.impact.stat_1) }}</span> <span class="impact__label">{{ translate(stats.impact.label_1) }}</span>
            </li>

            <li class="impact__li">
              <span class="impact__number">{{ translate(stats.impact.stat_2) }}</span> <span class="impact__label">{{ translate(stats.impact.label_2) }}</span>
            </li>

            <li class="impact__li">
              <span class="impact__number">{{ translate(stats.impact.stat_3) }}</span> <span class="impact__label">{{ translate(stats.impact.label_3) }}</span>
            </li>

            <li class="impact__li">
              <span class="impact__number">{{ translate(stats.impact.stat_4) }}</span> <span class="impact__label">{{ translate(stats.impact.label_4) }}</span>
            </li>
          </ul>
        </article>

        <article class="stats__card stats__chart">
          <div class="chart__flex chart__head">
            <h3 class="card__title chart__title">
              {{ translate(stats.cost.title) }}
            </h3>

            <span class="chart__number chart__circle">
              {{ translate(stats.cost.number) }}
            </span>
          </div>

          <div class="chart__flex chart__main">
            <img
              :src="translate(stats.cost.chart)"
              :alt="translate(stats.cost.title)"
            >
          </div>

          <ul
            class="chart__ul chart__footer"
            v-if="stats.cost.categories?.length"
          >
            <li
              class="chart__li"
              v-for="(category, index) in stats.cost.categories"
              :key="`category_${index}`"
            >
              <span
                class="chart__dots"
                :style="`background-image: url(${ translate(category.image) })`"
                v-if="translate(category.image)"
              />

              {{ translate(category.label) }}
            </li>
          </ul>
        </article>
      </div>

      <div class="stats__actions btn__container">
        <app-link
          :link="stats.button"
          class="btn btn__color--sec btn__size--medium"
        />

        <div
          class="editor editor__content"
          v-if="translate(stats.text)"
          v-html="translate(stats.text)"
        />
      </div>
    </div>
  </section>

  <section
    class="about"
    v-if="isVisible('about')"
  >
    <div class="container">
      <div class="about__grid">
        <article class="about__infos">
          <h2 class="about__title title--prim">
            {{ translate(about.title) }}
          </h2>

          <div
            class="editor editor__content"
            v-if="translate(about.text)"
            v-html="translate(about.text)"
          />
        </article>

        <figure class="about__figure">
          <img
            v-if="translate(about.image)"
            :src="translate(about.image)"
            :alt="translate(about.title)"
            class="about__image"
          >
        </figure>
      </div>
    </div>
  </section>

  <section
    class="testimonials"
    v-if="isVisible('testimonials')"
  >
    <div class="container">
      <div class="testimonials__flex">
        <h2 class="testimonials__title title--prim">
          <hr class="illustration__testimonials">

          {{ translate(testimonials.title) }}
        </h2>

        <div
          class="testimonials__text editor editor__content"
          v-if="translate(testimonials.text)"
          v-html="translate(testimonials.text)"
        />
      </div>

      <app-glide-slides
        class="testimonials__slides"
        v-if="testimonials.testimonials?.length"
        :options="testimonials_options"
        :slides="testimonials.testimonials"
      >
        <template #default="{ slide }">
          <article class="testimonials__slide">
            <div class="slide__grid">
              <div class="slide__infos">
                <div
                  class="slide__testimonial editor editor__content"
                  v-html="translate(slide.testimonial)"
                  v-if="translate(slide.testimonial)"
                />

                <p
                  v-if="translate(slide.author)"
                  class="slide__author"
                >
                  {{ translate(slide.author) }}
                </p>
              </div>

              <figure
                v-if="translate(slide.image)"
                class="slide__figure"
              >
                <img
                  :src="translate(slide.image)"
                  :alt="translate(slide.author)"
                  class="slide__image"
                >
              </figure>
            </div>

            <div
              class="slide__text editor editor__content"
              v-html="translate(slide.text)"
              v-if="translate(slide.text)"
            />
          </article>
        </template>

        <template #prev>
          <fa-icon
            class="element color--ter"
            size="xl"
            :icon="['fal', 'long-arrow-left']"
          />
        </template>

        <template #next>
          <fa-icon
            class="element color--ter"
            size="xl"
            :icon="['fal', 'long-arrow-right']"
          />
        </template>
      </app-glide-slides>
    </div>
  </section>

  <section
    class="goal"
    v-if="isVisible('goal')"
  >
    <div class="container">
      <div class="goal__grid">
        <article class="goal__infos">
          <h2 class="goal__title title--prim">
            {{ translate(goal.title) }}
          </h2>

          <div
            class="editor editor__content"
            v-if="translate(goal.text)"
            v-html="translate(goal.text)"
          />
        </article>

        <figure class="goal__figure">
          <img
            v-if="translate(goal.image)"
            :src="translate(goal.image)"
            :alt="translate(goal.title)"
            class="goal__image"
          >
        </figure>
      </div>

      <div class="goal__btn-container margin__b--36">
        <app-link
          :link="goal.button"
          class="goal__btn btn btn__color--sec btn__size--medium"
        />
      </div>
    </div>
  </section>

  <section
    class="picture background"
    :style="`background-image: url(${ translate(picture.image) })`"
    v-if="isVisible('picture')"
  />
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { mapGetters, mapActions } from '@/store/map-state'
import { useI18n } from '@/vendors/i18n'
import { isNumber as _isNumber, get as _get } from 'lodash'
import useSection from '@/composables/modules/pages/useSection'

import AppGlideSlides from '&/organisms/AppGlideSlides'
import AppMoney from '&/atoms/AppMoney'
import AppDate from '&/atoms/AppDate'
const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))

const props = defineProps({
  page: { type: Object, required: true },
  sections: { type: Object, required: true }
})

const { translate } = useI18n()
const { getSectionAttributes, isVisible } = useSection(props.page)
const { fetchClientStatistics } = mapActions('client')
const { statistics } = mapGetters('client')

const testimonials_options = {
  perView: 1,
  type: 'carousel',
  bound: true
}

const banner = computed(() => getSectionAttributes('banner'))
const description = computed(() => getSectionAttributes('description'))
const stats = computed(() => getSectionAttributes('stats'))
const about = computed(() => getSectionAttributes('about'))
const testimonials = computed(() => getSectionAttributes('testimonials'))
const goal = computed(() => getSectionAttributes('goal'))
const picture = computed(() => getSectionAttributes('picture'))

const amountCollected = computed(() => _get(stats.value, 'goal.stat_2') && _isNumber(stats.value.goal.stat_2) ? stats.value.goal.stat_2 + _get(statistics.value, 'amount_collected', 0) : _get(statistics.value, 'amount_collected', 0))

fetchClientStatistics({ query: { procedure_id:  _get(stats.value, 'goal.forms_2', []) } })
</script>

<style lang="scss" scoped>
.banner {
  min-height: 400px;
  padding: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  overflow: hidden;

  @include mq(sm) {
    min-height: 500px;
    padding: 60px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(black, .3);
  }

  .editor {
    max-width: 800px;
    width: 100%;
    font-family: $font-family--secondary;
    font-size: 24px;
    color: $white;
    text-align: right;

    @include mq(sm) {
      font-size: 35px;
    }
  }
}

.description {
  padding: 32px 0;
  background-color: $white;

  @include mq(sm) {
    padding: 42px 0;
  }

  .editor {
    max-width: 95%;
    margin: auto;

    @include mq(sm) {
      max-width: 70%;
    }
  }
}

.stats {
  padding: 32px 0;
  background-color: $white;

  @include mq(sm) {
    padding: 42px 0;
  }

  &__title {
    text-align: center;
    position: relative;
    z-index: 1;

    &:after {
      content: '';
      background-image: image-path('allezchantez/circles.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 85%;
      width: 100%;
      height: 200px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      z-index: -1;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    margin-top: 60px;

    @include mq(md) {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 120px;
    }
  }

  &__actions {
    flex-direction: column;
    margin-top: 40px;
    gap: 28px;

    @include mq(sm) {
      margin-top: 82px;
      gap: 37px;
    }

    &:deep(.btn) {
      font-size: 20px;
      font-family: $font-family--secondary;
      border-radius: 16px;
      background-color: #FF7C80;

      @include mq(sm) {
        font-size: 24px;
      }

      &:hover {
        background-color: #EA5153;
      }
    }

    .editor {
      text-align: center;
    }
  }

  &__card {
    background-color: #E9F5FD;
    border-radius: 84px;
    padding: 35px;
    position: relative;

    @include mq(sm) {
      padding: 55px;
    }
  }

  &__chart {
    display: grid;
    padding: 35px;
    gap: 30px 0;

    @include mq(md) {
      grid-template-columns: repeat(3, 1fr);
      grid-area: 2 / first / end / 1;
    }

    @include mq(lg) {
      gap: 0;
    }
  }
}

.card {
  &__title {
    font-size: 25px;
    font-family: $font-family--secondary;
    color: $color--tertiary;
    text-align: center;

    @include mq(sm) {
      font-size: 35px;
    }
  }
}

.illustration {
  &__goal {
    width: 75px;
    height: 78px;
    background-image: image-path('allezchantez/target.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: -30px;
    right: 0;
    border: 0;

    @include mq(sm) {
      width: 125px;
      height: 125px;
      top: -50px;
    }
  }

  &__impact {
    width: 75px;
    height: 78px;
    background-image: image-path('allezchantez/star.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: -30px;
    right: 0px;
    border: 0;

    @include mq(sm) {
      width: 125px;
      height: 125px;
      top: -60px;
      right: -10px;
    }
  }

  &__testimonials {
    width: 30px;
    height: 30px;
    background-image: image-path('allezchantez/glow.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: -30px;
    left: -30px;
    border: 0;

    @include mq(sm) {
      width: 50px;
      height: 50px;
      top: -60px;
      left: -50px;
    }
  }
}

.goal {
  &__ul {
    margin-top: 30px;
  }

  &__li {
    line-height: 1.3;
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 25px;

      @include mq(sm) {
        margin-bottom: 50px;
      }
    }
  }

  &__number {
    font-size: 60px;
    font-family: $font-family--secondary;
    color: $color--primary;

    @include mq(sm) {
      font-size: 80px;
    }
  }

  &__circle {
    position: relative;
    z-index: 0;
    padding: 0 10px;

    &:after {
      content: '';
      background-image: image-path('allezchantez/goal_circle.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      width: 100%;
      height: 200px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      z-index: -1;
    }
  }

  &__label {
    font-size: 22px;
    font-family: $font-family--secondary;
    color: $color--tertiary;

    @include mq(sm) {
      font-size: 26px;
    }
  }
}

.impact {
  &__ul {
    margin-top: 30px;
  }

  &__li {
    line-height: 1.8;
  }

  &__number {
    font-size: 38px;
    font-family: $font-family--secondary;
    color: $color--primary;
    margin-right: 12px;

    @include mq(sm) {
      font-size: 45px;
    }
  }

  &__label {
    font-size: 22px;
    font-family: $font-family--secondary;
    color: $color--tertiary;

    @include mq(sm) {
      font-size: 26px;
    }
  }
}

.chart {
  &__title {
    @include mq(sm) {
      max-width: 270px;
    }
  }

  &__flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__number {
    font-size: 55px;
    font-family: $font-family--secondary;
    color: $color--secondary;
    margin-top: 18px;

    @include mq(sm) {
      font-size: 70px;
    }
  }

  &__circle {
    position: relative;
    z-index: 0;
    padding: 0 60px;

    &:after {
      content: '';
      background-image: image-path('allezchantez/cost_circle.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 85%;
      width: 100%;
      height: 200px;
      position: absolute;
      top: 65%;
      left: 0;
      transform: translateY(-50%);
      z-index: -1;
    }
  }

  &__ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 24px;
  }

  &__li {
    font-size: 18px;
    font-family: $font-family--secondary;
    color: $color--tertiary;
    line-height: 1.8;

    @include mq(sm) {
      font-size: 22px;
      white-space: nowrap;
    }
  }

  &__dots {
    width: 10px;
    height: 13px;
    display: inline-block;
    vertical-align: middle;
    background-size: cover;
    margin-right: 8px;
  }

  &__head {
    @include mq(md) {
      grid-area: 1 / 1 / 3 / 3;
    }

    @include mq(lg) {
      grid-area: 1;
    }
  }
}

.goal, .about {
  padding: 60px 0;
  background-color: $white;

  &__title {
    margin-bottom: 24px;
    text-align: center;

    @include mq(sm) {
      text-align: left;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 48px;
    align-items: center;
    justify-content: center;

    @include mq(md) {
      grid-template-columns: 60% 1fr;
    }
  }

  .editor {
    line-height: 1.7;
  }

  &__figure {
    max-width: 430px;
    width: 100%;
    margin: auto;

    @include mq(md) {
      margin-left: auto;
    }
  }

  &__image {
    display: block;
    border-radius: 50%;
    width: 100%;
  }

  &:deep(.goal__btn) {
    font-size: rem(18px);
    font-family: $font-family--secondary;
    border-radius: 16px;
    background-color: #FF7C80;

    @include mq(sm) {
      font-size: rem(25px);
    }

    &:hover {
      background-color: #EA5153;
    }
  }

  &__btn-container {
    margin-top: 35px;
    display: flex;
    justify-content: center;

    @include mq(sm) {
      margin-top: 70px;
    }
  }
}

.about {
  &__image {
    order: 1;
  }

  &__infos {
    order: 2;
  }

  &__grid {
    grid-template-columns: 1fr;

    @include mq(md) {
      grid-template-columns: 40% 1fr;
    }
  }

  &__figure {
    max-width: 430px;
    margin: auto;
    order: 2;

    @include mq(md) {
      margin-right: auto;
      margin-left: unset;
      order: 1;
    }
  }
}

.testimonials {
  padding: 30px 0;
  background-color: $white;

  @include mq(sm) {
    padding: 60px 0;
  }

  &__title {
    margin-bottom: 24px;
    text-align: center;
    position: relative;
    display: inline-block;
  }

  &__flex {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    max-width: 650px;
    margin: auto;
  }

  &__slides {
    max-width: 100%;
    margin: 50px auto 0 auto;

    @include mq(sm) {
      max-width: calc(100% - 100px);
    }

    @media (min-width: 1150px) {
      max-width: 945px;
    }

    @media (max-width: 766px) {
      :deep(.glide__arrow) {
        background-color: #E9F5FD;
        border: 0;
        width: 50px;
      }
    }
  }

  &__slide {
    background-color: #E9F5FD;
    border-radius: 84px;
    padding: 50px 40px;

    @include mq(sm) {
      padding: 70px;
    }
  }
}

.slide {
  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;

    @include mq(md) {
      grid-template-columns: 60% 1fr;
    }
  }

  &__testimonial, &__author {
    font-size: 30px;
    color: $color--tertiary;
    font-family: $font-family--secondary;
  }

  &__author {
    font-size: 20px;
    text-align: right;
  }

  &__text {
    margin-top: 48px;

    @include mq(md) {
      max-width: 60%;
    }
  }

  &__figure {
    max-width: 120px;
    justify-self: center;
    padding-bottom: 30px;
    order: 1;

    @include mq(md) {
      max-width: 215px;
      order: 2;
      margin-left: auto;
      padding-left: 30px;
      padding-bottom: 0px;
    }
  }

  &__infos {
    order: 2;

    @include mq(md) {
      order: 1;
    }
  }

  &__image {
    display: block;
    border-radius: 50%;
    width: 100%;
  }
}

.picture {
  background-color: $white;
  max-height: 960px;

  @include aspect-ratio(2000, 1333);
}

.title {
  font-size: 30px;
  font-family: $font-family--secondary;

  @include mq(sm) {
    font-size: 50px;
  }

  &--prim {
    @extend .title;
    color: $color--primary;
  }

  &--sec {
    @extend .title;
    color: $color--secondary;
  }
}

.background {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>