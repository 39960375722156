import blocks from '@/configurations/templates/blocks'
import Configuration from '@/classes/templates/Configuration'

export default class Block {
  constructor ({ name = null, attributes = {}, configuration } = {}) {
    this.name = name
    this.attributes = attributes
    this.configuration = configuration ? new Configuration(configuration) : null
  }

  get reference () {
    return blocks.find(({ name }) => name === this.name)
  }

  get component_path () {
    return this.reference?.component_path
  }

  get settings () {
    return this.reference?.settings || {}
  }
}