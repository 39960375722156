import Procedures from '@/classes/procedures/Procedures'

export default class Campaign {
  constructor ({ id = null, name = {}, cta = {}, learn_more = {}, image = null, description = {}, procedures, slug = null, status = null, sporting_activities_settings = {}, project_display_settings = {}, limit_of_members = null, goal_amount_per_member = 0 } = {}) {
    this.id = id
    this.name = name
    this.cta = cta
    this.learn_more = learn_more
    this.image = image
    this.description = description
    this.procedures = new Procedures(procedures)
    this.slug = slug
    this.status = status
    this.sporting_activities_settings = sporting_activities_settings
    this.project_display_settings = project_display_settings
    this.limit_of_members = limit_of_members
    this.goal_amount_per_member = goal_amount_per_member
  }
}
