<template>
  <div
    class="section"
    :style="sectionStyle"
  >
    <div class="container">
      <div class="row">
        <section class="col-12">
          <div
            class="editor editor__content editor__page margin__b--36"
            v-if="$i18n.translate(attributes.text_editor)"
            v-html="$i18n.translate(attributes.text_editor)"
          />
        </section>
      </div>

      <div class="row">
        <section
          class="col-12 col-md-4 col-lg-3"
          v-if="showFilters"
        >
          <aside class="filters">
            <search-field
              v-if="showSearch"
              :model-value="queries.search"
              @search-query="updateQuery($event, 'search')"
            />

            <fieldset
              v-if="showSort"
              class="filters__fieldset"
            >
              <label class="filters__label">
                {{ $i18n.t('literal.sorted_by') }}
              </label>

              <input-select
                class="margin__t--6"
                :model-value="sortByFieldQuery"
                :options="sorted_options"
                :searchable="false"
                @input="updateQuery($event, 'sort')"
              >
                <template #selected-option="{ option }">
                  <span>{{ $i18n.translate(option.label) }}</span>
                </template>

                <template #option="{ option }">
                  <span>{{ $i18n.translate(option.label) }}</span>
                </template>
              </input-select>
            </fieldset>

            <fieldset
              v-if="showCampaignsFilter"
              class="filters__fieldset"
            >
              <label class="filters__label filters__label--flex">
                {{ $i18n.t('literal.filter_by_campaign') }}

                <app-spinner
                  class="margin__l--12"
                  v-if="loader.filters"
                  size="2"
                />
              </label>

              <input-select
                class="select margin__t--6"
                v-if="!loader.filters"
                :placeholder="$i18n.t('literal.all_campaigns')"
                :multiple="true"
                :searchable="true"
                :options="campaigns_options"
                :close-on-select="false"
                :model-value="queries.campaign_id"
                @input="updateQuery($event, 'campaign_id')"
              >
                <template #selected-option="{ option }">
                  <span>{{ $i18n.translate(option.label) }}</span>
                </template>

                <template #option="{ option }">
                  <fa-icon
                    v-if="isSelectedCampaignOption(option)"
                    class="margin__r--12 color--success"
                    :icon="['fal', 'check-square']"
                    size="lg"
                  />

                  <fa-icon
                    v-else
                    class="margin__r--12 color--lg"
                    :icon="['fal', 'square']"
                    size="lg"
                  />

                  <span>{{ $i18n.translate(option.label) }}</span>
                </template>
              </input-select>
            </fieldset>

            <fieldset
              v-if="showBenefitingsFilter"
              class="filters__fieldset"
            >
              <label class="filters__label filters__label--flex">
                {{ $i18n.t('literal.filter_by_beneficiary') }}

                <app-spinner
                  class="margin__l--12"
                  v-if="loader.filters"
                  size="2"
                />
              </label>

              <input-select
                class="select margin__t--6"
                v-if="!loader.filters"
                :placeholder="$i18n.t('literal.all_beneficiaries')"
                :multiple="true"
                :searchable="true"
                :options="benefitings_options"
                :close-on-select="false"
                :model-value="queries.benefiting_id"
                :paginable="true"
                :filterable="false"
                @input="updateQuery($event, 'benefiting_id')"
              >
                <template #selected-option="{ option }">
                  <span>{{ $i18n.translate(option.label) }}</span>
                </template>

                <template #option="{ option }">
                  <fa-icon
                    v-if="isSelectedBenefitingOption(option)"
                    class="margin__r--12 color--success"
                    :icon="['fal', 'check-square']"
                    size="lg"
                  />

                  <fa-icon
                    v-else
                    class="margin__r--12 color--lg"
                    :icon="['fal', 'square']"
                    size="lg"
                  />

                  <span>{{ $i18n.translate(option.label) }}</span>
                </template>
              </input-select>
            </fieldset>
          </aside>
        </section>

        <section
          class="col-12"
          :class="{'col-md-8 col-lg-9': showFilters}"
        >
          <div
            class="row"
            v-if="projects.length > 0"
          >
            <div
              class="col-12 col-sm-6"
              :class="{'col-lg-4': showFilters, 'col-md-4 col-xl-3': !showFilters}"
              v-for="project in projects"
              :key="project.id"
            >
              <project-card :project="project" />
            </div>
          </div>

          <article v-if="projects.length === 0 && !loader.projects">
            <div class="projects__none">
              <fa-icon
                class="margin__r--6"
                :icon="['fal', 'search']"
              />

              {{ $i18n.t('projects.no_results') }}
            </div>
          </article>

          <template v-if="hasButton">
            <div class="margin__t--36 align--center">
              <button
                type="button"
                :style="buttonStyle"
                class="btn btn__size--medium btn__color--prim"
                v-if="$i18n.translate(attributes.button.text)"
                :disabled="!isActive(attributes.button)"
                @click.prevent="setRouteNavigation(attributes.button)"
              >
                {{ $i18n.translate(attributes.button.text) }}
              </button>
            </div>
          </template>


          <app-pagination
            class="margin__t--24"
            :paginator="paginator"
            @change="updateQuery($event, 'page')"
            v-if="showPagination"
          />

          <div
            class="spinner__container"
            v-if="loader.projects"
          >
            <app-spinner />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
import { mapActions } from 'vuex'
import { get as _get, isEqual as _isEqual } from 'lodash'
import ProjectCard from '&/organisms/ProjectCard'
import PageRouteNavigation from '%/PageRouteNavigation'
import { useSectionStyle } from '@/composables/modules/pages'
import { projects_overview_options } from '@/configurations/general/components-default-data'

export default defineComponent({
  name: 'SectionProjectsOverview',
  emits: ['updateQuery'],
  components: {
    ProjectCard,
    AppPagination: defineAsyncComponent(() => import('&/molecules/AppPagination')),
    InputSelect: defineAsyncComponent(() => import('&/atoms/InputSelect')),
    SearchField: defineAsyncComponent(() => import('&/molecules/SearchField')),
    AppSpinner: defineAsyncComponent(() => import('@/components/atoms/AppSpinner'))
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    },
    query: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup: props => ({ ...useSectionStyle(props) }),
  data () {
    return {
      loader: {
        projects: false,
        filters: true
      },
      paginator: {},
      projects: [],
      selected_campaigns: null,
      sorted_options: projects_overview_options,
      campaigns_options: [],
      benefitings_options: []
    }
  },
  mixins: [PageRouteNavigation],
  created () {
    const promises = [
      this.showCampaignsFilter ? this.fetchCampaigns({ query: { view_format: 'page_selection' } }) : null,
      this.showBenefitingsFilter ? this.fetchBenefitings({ query: { per_page: 2000, view_format: 'page_selection', 'sort_by_field[field]': 'name', 'sort_by_field[direction]': 'asc' } }) : null
    ]

    Promise.all(promises).then(([campaigns, benefitings]) => {
      this.campaigns_options = campaigns ? campaigns.items.map(campaign => ({ label: campaign.name, value: campaign.id, status: campaign.status })) : []
      this.benefitings_options = benefitings ? benefitings.benefitings.map(benefiting => ({ label: benefiting.name, value: benefiting.id })): []

      this.getProjects()
    })
      .finally(() => this.loader.filters = false)
  },
  watch: {
    query (to, from) {
      if (!_isEqual(to, from)) this.getProjects()
    }
  },
  computed: {
    attributes () {
      return this.section.attributes
    },
    queries () {
      return {
        'search': this.query.search || null,
        'sort_by_field[field]': this.query['sort_by_field[field]'] || this.getFieldQuery(this.attributes.query.sort, 'sort_by_field[field]') || 'current_amount',
        'sort_by_field[direction]': this.query['sort_by_field[direction]'] || this.getFieldQuery(this.attributes.query.sort, 'sort_by_field[direction]') || 'desc',
        'campaign_id': this.campaignIds,
        'benefiting_id': this.benefitingIds,
        'page': this.query.page || 1,
        'per_page': this.query.per_page || this.attributes.query.per_page || 9
      }
    },
    campaignIds () {
      let campaignIds = this.query.campaign_id || this.attributes.query.campaign_id || []

      if (!Array.isArray(campaignIds)) campaignIds = [campaignIds]

      return this.campaigns_options.length ? campaignIds.filter(id => this.campaigns_options.some(campaign => campaign.value === id)) : campaignIds
    },
    benefitingIds () {
      let benefitingIds = this.query.benefiting_id || this.attributes.query.benefiting_id || []

      if (!Array.isArray(benefitingIds)) benefitingIds = [benefitingIds]

      return this.benefitings_options.length ? benefitingIds.filter(id => this.benefitings_options.some(benefiting => benefiting.value === id)) : benefitingIds
    },
    sortByFieldQuery () {
      const query = {
        'sort_by_field[field]': this.queries['sort_by_field[field]'],
        'sort_by_field[direction]': this.queries['sort_by_field[direction]']
      }

      return this.sorted_options.find(option => _isEqual(option.query, query))
    },
    hasButton () {
      return _get(this.attributes, 'button.show', false)
    },
    showSearch () {
      return _get(this.attributes, 'query.show_search', false)
    },
    showSort () {
      return _get(this.attributes, 'query.show_sort', false)
    },
    showCampaignsFilter () {
      return _get(this.attributes, 'query.show_campaigns_filter', false)
    },
    showBenefitingsFilter () {
      return _get(this.attributes, 'query.show_benefitings_filter', false)
    },
    showPagination () {
      return _get(this.attributes, 'query.show_pagination', false)
    },
    showFilters () {
      return this.showSearch || this.showSort || this.showCampaignsFilter
    }
  },
  methods: {
    ...mapActions({
      fetchProjects: 'projects/fetchProjects',
      fetchCampaigns: 'campaigns/fetchCampaigns',
      fetchBenefitings: 'benefitings/fetchBenefitings',
    }),
    isSelectedCampaignOption (option) {
      return this.queries.campaign_id.includes(option.value)
    },
    isSelectedBenefitingOption (option) {
      return this.queries.benefiting_id.includes(option.value)
    },
    getFieldQuery (value, key) {
      const option = _get(this.getFieldOption(value), 'query', {})
      return option[key]
    },
    getFieldOption (value) {
      return this.sorted_options.find(option => option.value === value) || {}
    },
    updateQuery (value, key) {
      if (key === 'sort') {
        const obj = this.getFieldOption(value)
        Object.keys(obj.query).forEach(key => this.queries[key] = obj.query[key])
      }

      if (value !== this.queries[key]) this.queries[key] = value
      if (key !== 'page') this.queries['page'] = 1

      this.$emit('updateQuery', this.queries)
    },
    getProjects () {
      this.loader.projects = true

      this.fetchProjects({ query: this.queries })
        .then(data => {
          this.projects = data.items
          this.paginator = data.paginator
        })
        .finally(() => this.loader.projects = false)
    }
  }
})
</script>

<style lang="scss" scoped>
.select :deep() {
  .vs__dropdown-option {
    &--highlight {
      background-color: $vdw;
      color: $black;
    }

    &--selected {
      background-color: $white;
      color: $black;
    }
  }
}

.filters {
  display: block;
  margin-top: $margin__base;

  &__fieldset {
    margin-top: $margin__base * 1.5;
  }

  &__label {
    color: $lg;
    font-size: em(14px);

    &--flex {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}

.projects {
  &__none {
    margin-top: $margin__base;
    color: $lg;
    text-align: center;
    font-size: em(18px);
  }
}

.title {
  &__h2 {
    font-size: em(24px);

    @include mq(md) {
      font-size: em(32px);
    }
  }
}

.spinner {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: $margin__base * 2;
  }
}
</style>
