<template>
  <header class="header">
    <div class="container">
      <article class="header__article article__website">
        <app-link
          :link="website"
          class="header__website link__color--black translate__x-3"
          v-slot="{ text }"
          v-if="website && website.show"
        >
          <fa-icon
            class="website__icon element margin__r--6"
            :icon="['fas', 'chevron-left']"
            size="xs"
          />

          {{ text }}
        </app-link>
      </article>

      <article class="header__article article__logo">
        <router-link
          :to="{ name: 'Home', params: { locale } }"
          v-show="client.logo.url"
        >
          <img
            class="header__logo"
            :src="client.logo.url"
            :alt="client.name"
          >
        </router-link>
      </article>

      <article class="header__article article__languages">
        <language-switcher v-slot="{ options, updateLanguage, isActive }">
          <ul class="languages__ul">
            <li
              class="languages__li"
              v-for="language in options"
              :key="language.id"
            >
              <button
                class="languages__a"
                :class="{'active': isActive(language.id)}"
                type="button"
                @click.prevent="updateLanguage(language.id)"
              >
                {{ language.label.value.toUpperCase() }}
              </button>
            </li>
          </ul>
        </language-switcher>
      </article>
    </div>
  </header>
</template>

<script setup>
import { defineAsyncComponent, computed } from 'vue'
import { mapGetters } from '@/store/map-state'
import { get as _get } from 'lodash'
import { useI18n } from '@/vendors/i18n'

import AppLink from '&/atoms/AppLink'
const LanguageSwitcher = defineAsyncComponent(() => import('&/atoms/LanguageSwitcher'))

const props = defineProps({ section: { type: Object, required: true } })

const { locale } = useI18n()
const { client } = mapGetters('client')

const website = computed(() => _get(props, 'section.attributes.back_link', {}))
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @include mq(md) {
    flex-wrap: nowrap;
  }
}

.header {
  padding: 6px 0 24px 0;
  background-color: $white;

  @include mq(md) {
    padding: 60px 0;
  }

  &__article {
    width: 50%;

    @include mq(md) {
      width: 33.333%;
    }
  }

  &__logo {
    max-width: 274px;
    min-width: 150px;
    width: 100%;
    display: block;
    margin: auto;
  }

  &:deep(.header__website) {
    font-size: 16px;

    @include mq(md) {
      font-size: 24px;
    }
  }
}

.article {
  &__logo {
    text-align: center;
  }

  &__website {
    text-align: center;
    margin-bottom: 24px;
    width: 100%;

    @include mq(md) {
      text-align: left;
      width: 33.333%;
      margin-bottom: 0;
    }
  }
}

.website {
  &__icon {
    font-size: 15px;
  }
}

.languages {
  &__ul {
    display: flex;
    font-size: 18px;
    position: relative;
    padding-left: 18px;
  }

  &__li {
    padding: 0 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:last-child):after {
      content: '';
      display: block;
      height: 25px;
      width: 2px;
      margin-left: 12px;
      background-color: #242627;
    }
  }

  &__a {
    display: block;
    text-transform: uppercase;
    font-size: 24px;
    transition: all .3s;
    line-height: 1;
    border-bottom: 2px solid transparent;

    &.active {
     border-bottom: 2px solid #242627;
    }

    &:hover:not(.active) {
      color: $color--primary;
    }
  }
}

:deep(.locale-switcher) {
  background-color: transparent;
}
</style>
