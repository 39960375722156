<template>
  <div class="map">
    <div class="map__container">
      <h2
        class="map__title"
        v-if="translate(props.section.second_floor?.title)"
      >
        {{ translate(props.section.second_floor?.title) }}
      </h2>

      <svg
        width="1259px"
        height="547px"
        viewBox="0 0 1259 547"
      >
        <g
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g transform="translate(-132.000000, -1987.000000)">
            <g transform="translate(132.892216, 1987.000000)">
              <image
                x="0"
                y="0"
                width="1257.77246"
                height="547"
                :xlink:href="getImagePath('clients/blindenzorglichtliefde/map_second_floor.png')"
              />

              <g transform="translate(132.107784, 0.000000)">
                <line
                  x1="67"
                  y1="16"
                  x2="67.5"
                  y2="187.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  stroke-linecap="square"
                />
                <circle
                  class="marker"
                  fill="#000000"
                  cx="66"
                  cy="16"
                  r="16"
                  @click="showModal('dot_1', 'second_floor')"
                />

                <line
                  x1="187"
                  y1="16"
                  x2="187.5"
                  y2="226.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  stroke-linecap="square"
                />
                <circle
                  class="marker"
                  fill="#000000"
                  cx="186"
                  cy="16"
                  r="16"
                  @click="showModal('dot_2', 'second_floor')"
                />

                <line
                  x1="305"
                  y1="6"
                  x2="305.5"
                  y2="140.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  fill="#000000"
                  stroke-linecap="square"
                />
                <circle
                  fill="#000000"
                  cx="304"
                  cy="16"
                  r="16"
                  class="marker"
                  @click="showModal('dot_3', 'second_floor')"
                />

                <line
                  x1="15.5"
                  y1="381.5"
                  x2="15.5"
                  y2="480.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  stroke-linecap="square"
                />
                <circle
                  class="marker"
                  fill="#000000"
                  cx="16"
                  cy="488"
                  r="16"
                  @click="showModal('dot_4', 'second_floor')"
                />

                <line
                  x1="215.5"
                  y1="337.5"
                  x2="215.5"
                  y2="480.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  stroke-linecap="square"
                />
                <circle
                  class="marker"
                  fill="#000000"
                  cx="216"
                  cy="488"
                  r="16"
                  @click="showModal('dot_5', 'second_floor')"
                />

                <line
                  x1="305.5"
                  y1="311.5"
                  x2="305.5"
                  y2="480.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  stroke-linecap="square"
                />
                <circle
                  class="marker"
                  fill="#000000"
                  cx="306"
                  cy="488"
                  r="16"
                  @click="showModal('dot_6', 'second_floor')"
                />

                <line
                  x1="495.5"
                  y1="211.5"
                  x2="495.5"
                  y2="480.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  stroke-linecap="square"
                />
                <circle
                  class="marker"
                  fill="#000000"
                  cx="496"
                  cy="488"
                  r="16"
                  @click="showModal('dot_7', 'second_floor')"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>

    <div class="map__container">
      <h2
        class="map__title"
        v-if="translate(props.section.first_floor?.title)"
      >
        {{ translate(props.section.first_floor?.title) }}
      </h2>

      <svg
        width="1249px"
        height="543px"
        viewBox="0 0 1249 543"
      >
        <g
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g transform="translate(-139.000000, -2581.000000)">
            <g transform="translate(139.000000, 2581.000000)">
              <image
                x="0"
                y="0"
                width="1248.57485"
                height="543"
                :xlink:href="getImagePath('clients/blindenzorglichtliefde/map_first_floor.png')"
              />

              <g transform="translate(66.000000, 16.000000)">
                <line
                  x1="22"
                  y1="134"
                  x2="22.5"
                  y2="204.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  fill="#000000"
                  stroke-linecap="square"
                />
                <circle
                  @click="showModal('dot_1', 'first_floor')"
                  class="marker"
                  fill="#000000"
                  cx="21"
                  cy="144"
                  r="16"
                />

                <line
                  x1="127"
                  y1="16"
                  x2="127.5"
                  y2="133.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  stroke-linecap="square"
                />
                <circle
                  @click="showModal('dot_2', 'first_floor')"
                  class="marker"
                  fill="#000000"
                  cx="126"
                  cy="16"
                  r="16"
                />

                <line
                  x1="225"
                  y1="6"
                  x2="225.5"
                  y2="88.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  fill="#000000"
                  stroke-linecap="square"
                />
                <circle
                  @click="showModal('dot_3', 'first_floor')"
                  class="marker"
                  fill="#000000"
                  cx="224"
                  cy="16"
                  r="16"
                />

                <line
                  x1="278"
                  y1="6"
                  x2="278.5"
                  y2="169.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  fill="#000000"
                  stroke-linecap="square"
                />
                <circle
                  @click="showModal('dot_4', 'first_floor')"
                  class="marker"
                  fill="#000000"
                  cx="277"
                  cy="16"
                  r="16"
                />

                <line
                  x1="365"
                  y1="6"
                  x2="365.5"
                  y2="88.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  fill="#000000"
                  stroke-linecap="square"
                />
                <circle
                  @click="showModal('dot_5', 'first_floor')"
                  class="marker"
                  fill="#000000"
                  cx="364"
                  cy="16"
                  r="16"
                />

                <line
                  x1="15.5"
                  y1="321.5"
                  x2="15.5"
                  y2="420.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  stroke-linecap="square"
                />
                <circle
                  class="marker"
                  fill="#000000"
                  @click="showModal('dot_6', 'first_floor')"
                  cx="16"
                  cy="428"
                  r="16"
                />

                <line
                  x1="115.5"
                  y1="321.5"
                  x2="115.5"
                  y2="420.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  stroke-linecap="square"
                />
                <circle
                  @click="showModal('dot_7', 'first_floor')"
                  class="marker"
                  fill="#000000"
                  cx="116"
                  cy="428"
                  r="16"
                />

                <line
                  x1="215.5"
                  y1="269.5"
                  x2="215.5"
                  y2="420.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  stroke-linecap="square"
                />
                <circle
                  @click="showModal('dot_8', 'first_floor')"
                  class="marker"
                  fill="#000000"
                  cx="216"
                  cy="428"
                  r="16"
                />

                <line
                  x1="275.5"
                  y1="321.5"
                  x2="275.5"
                  y2="420.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  stroke-linecap="square"
                />
                <circle
                  @click="showModal('dot_9', 'first_floor')"
                  class="marker"
                  fill="#000000"
                  cx="276"
                  cy="428"
                  r="16"
                />

                <line
                  x1="365.5"
                  y1="321.5"
                  x2="365.5"
                  y2="420.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  stroke-linecap="square"
                />
                <circle
                  @click="showModal('dot_10', 'first_floor')"
                  class="marker"
                  fill="#000000"
                  cx="366"
                  cy="428"
                  r="16"
                />

                <line
                  x1="485.5"
                  y1="290.5"
                  x2="485.5"
                  y2="420.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  stroke-linecap="square"
                />
                <circle
                  @click="showModal('dot_11', 'first_floor')"
                  class="marker"
                  fill="#000000"
                  cx="486"
                  cy="428"
                  r="16"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>

    <div class="map__container">
      <h2
        class="map__title"
        v-if="translate(props.section.ground_floor?.title)"
      >
        {{ translate(props.section.ground_floor?.title) }}
      </h2>

      <svg
        width="1440px"
        height="627px"
        viewBox="0 0 1440 627"
      >
        <g
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g transform="translate(-73.000000, -3163.000000)">
            <g transform="translate(73.000000, 3163.000000)">
              <image
                x="0"
                y="0"
                width="1440"
                height="626.25"
                :xlink:href="getImagePath('clients/blindenzorglichtliefde/map_ground_floor.png')"
              />

              <g transform="translate(152.000000, 64.000000)">
                <line
                  x1="22"
                  y1="134"
                  x2="22.5"
                  y2="204.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  fill="#000000"
                  stroke-linecap="square"
                />
                <circle
                  class="marker"
                  @click="showModal('dot_1', 'ground_floor')"
                  fill="#000000"
                  cx="21"
                  cy="144"
                  r="16"
                />

                <line
                  x1="127"
                  y1="16"
                  x2="127.5"
                  y2="88.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  stroke-linecap="square"
                />
                <circle
                  class="marker"
                  @click="showModal('dot_2', 'ground_floor')"
                  fill="#000000"
                  cx="126"
                  cy="16"
                  r="16"
                />

                <line
                  x1="225"
                  y1="6"
                  x2="225.5"
                  y2="88.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  fill="#000000"
                  stroke-linecap="square"
                />
                <circle
                  class="marker"
                  @click="showModal('dot_3', 'ground_floor')"
                  fill="#000000"
                  cx="224"
                  cy="16"
                  r="16"
                />

                <line
                  x1="275"
                  y1="6"
                  x2="275.5"
                  y2="150.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  fill="#000000"
                  stroke-linecap="square"
                />
                <circle
                  class="marker"
                  @click="showModal('dot_4', 'ground_floor')"
                  fill="#000000"
                  cx="274"
                  cy="16"
                  r="16"
                />

                <line
                  x1="365"
                  y1="6"
                  x2="365.5"
                  y2="88.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  fill="#000000"
                  stroke-linecap="square"
                />
                <circle
                  class="marker"
                  @click="showModal('dot_5', 'ground_floor')"
                  fill="#000000"
                  cx="364"
                  cy="16"
                  r="16"
                />

                <line
                  x1="565"
                  y1="6"
                  x2="565.5"
                  y2="88.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  fill="#000000"
                  stroke-linecap="square"
                />
                <circle
                  class="marker"
                  @click="showModal('dot_6', 'ground_floor')"
                  fill="#000000"
                  cx="564"
                  cy="16"
                  r="16"
                />

                <line
                  x1="625"
                  y1="6"
                  x2="625.5"
                  y2="88.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  fill="#000000"
                  stroke-linecap="square"
                />
                <circle
                  class="marker"
                  @click="showModal('dot_7', 'ground_floor')"
                  fill="#000000"
                  cx="624"
                  cy="16"
                  r="16"
                />

                <line
                  x1="15.5"
                  y1="321.5"
                  x2="15.5"
                  y2="420.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  stroke-linecap="square"
                />
                <circle
                  class="marker"
                  @click="showModal('dot_8', 'ground_floor')"
                  fill="#000000"
                  cx="16"
                  cy="428"
                  r="16"
                />

                <line
                  x1="115.5"
                  y1="321.5"
                  x2="115.5"
                  y2="420.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  stroke-linecap="square"
                />
                <circle
                  class="marker"
                  @click="showModal('dot_9', 'ground_floor')"
                  fill="#000000"
                  cx="116"
                  cy="428"
                  r="16"
                />

                <line
                  x1="215.5"
                  y1="269.5"
                  x2="215.5"
                  y2="420.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  stroke-linecap="square"
                />
                <circle
                  class="marker"
                  @click="showModal('dot_10', 'ground_floor')"
                  fill="#000000"
                  cx="216"
                  cy="428"
                  r="16"
                />

                <line
                  x1="275.5"
                  y1="321.5"
                  x2="275.5"
                  y2="420.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  stroke-linecap="square"
                />
                <circle
                  class="marker"
                  @click="showModal('dot_11', 'ground_floor')"
                  fill="#000000"
                  cx="276"
                  cy="428"
                  r="16"
                />

                <line
                  x1="365.5"
                  y1="321.5"
                  x2="365.5"
                  y2="420.5"
                  id="Line-2"
                  stroke="#000000"
                  stroke-width="3"
                  stroke-linecap="square"
                />
                <circle
                  class="marker"
                  @click="showModal('dot_12', 'ground_floor')"
                  fill="#000000"
                  cx="366"
                  cy="428"
                  r="16"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>

  <modal
    v-show="modale.show"
    @close="modale.show = false"
  >
    <template #body>
      <article
        class="room"
        v-if="project"
      >
        <div
          class="room__cover"
          :style="{ backgroundImage: `url(${ projectCover })` }"
        />

        <h3
          class="room__title"
          v-if="project.name"
        >
          {{ project.name }}
        </h3>

        <div
          class="editor editor__content room__text"
          v-html="project.description"
          v-if="project.description"
        />

        <div
          class="progress-bar"
          v-if="goalAmount"
        >
          <div
            class="progress-bar__current"
            :style="{ width: `${ getProgressbarValue }%` }"
          >
            <div class="progress-bar__label">
              <app-money :amount="collectedAmount" />
            </div>
          </div>

          <span class="progress-bar__goal">
            <app-money :amount="goalAmount" />
          </span>
        </div>

        <div
          class="room__action"
          v-if="getDonationProcedureSlug && translate(getCtaOngoingText)"
        >
          <router-link
            class="btn__color--black btn__size--classic room__button"
            :to="{ name: 'ClientProcedure', params: { procedureSlug: getDonationProcedureSlug } }"
          >
            {{ translate(getCtaOngoingText) }}
          </router-link>
        </div>
      </article>
    </template>
  </modal>
</template>

<script setup>
import { ref, reactive, computed, watch } from 'vue'
import { mapGetters, mapActions } from '@/store/map-state'
import { getImagePath } from '@/utils/utils'
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'

import Modal from '&/organisms/AppModal'
import AppMoney from '&/atoms/AppMoney'

const props = defineProps({
  section: { type: Object, required: true }
})

const { translate } = useI18n()
const selected_dot = ref()
const modale = reactive({
  show: false
})

const { fetchProject } = mapActions('projects')
const { getProjectById } = mapGetters('projects')

const selected_project_id = computed(() => selected_dot.value?.project_id[0])
const project = computed(() => getProjectById.value(selected_project_id.value))
const projectCover = computed(() => _get(project.value, 'image.url') || _get(project.value, 'campaign.image.url') || getImagePath('placeholders/project-card-cover.jpg'))
const collectedAmount = computed(() => _get(project.value, 'statistics.amount_collected') || 0)
const goalAmount = computed(() => _get(project.value, 'goal_amount') || 0)
const getProgressbarValue = computed(() => (collectedAmount.value / goalAmount.value) * 100)
const getDonationProcedure = computed(() => project.value?.procedures?.find(procedure => procedure.category === 'donation'))
const getDonationProcedureSlug = computed(() => getDonationProcedure.value?.slug)
const getCtaOngoingText = computed(() => _get(project.value, 'campaign.content.project_cta_ongoing'))

watch(selected_project_id, value => {
  if (!project.value && value) fetchProject({ id: value })
})

const showModal = (key, map_id) => {
  selected_dot.value = props.section[map_id][key]
  if (selected_project_id.value) modale.show = true
}
</script>

<style lang="scss" scoped>
.modal {
  background-color: rgba(0, 0, 0, .85);
}

:deep(.modal__container) {
  width: 100%;
  border-radius: 0;
  padding: 24px;
  border-radius: 8px;

  @media (min-width: 554px) {
    width: 554px;
    padding: 32px;
  }

  .modal__btn--close {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 44px;
    height: 44px;
    background-color: $white;
    border-radius: 50%;
    box-shadow: 0px 2px 4px rgba(0,0,0, 0.5);
    font-size: 28px;
    color: $black;

    @media (min-width: 554px) {
      top: -22px;
      right: -22px;
    }

    &:focus {
      outline: 0;
    }

    &:hover svg {
      transform: rotate(90deg);
    }

    & svg {
      color: $black;
      transition: all .3s;
    }
  }
}

.map {
  padding-top: 50px;

  &__title {
    font-size: 20px;
    font-family: $font-family--secondary;
  }

  &__container {
    margin-top: 24px;
    overflow-x: auto;

    svg {
      max-width: 100%;
      min-width: 992px;

      @media (max-width: 992px) {
        max-width: auto;
      }
    }
  }
}

.marker {
  cursor: pointer;

  @supports ( transform-box: fill-box ) {
    transform-box: fill-box;
    transform-origin: 50% 50%;
    transition: all .2s;
    transform: scale(0.9);
    stroke-width: 2px;
    stroke: $black;

    @include mq(sm) {
      &:hover {
        fill: $white;
      }
    }
  }
}

.room {
  &__cover {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include aspect-ratio(3, 2);
  }

  &__title {
    font-size: 24px;
    font-family: $font-family--secondary;
    margin-top: 25px;

    @include mq(md) {
      font-size: 30px;
    }
  }

  &__text {
    margin-top: 12px;
    font-size: 16px;

    @include mq(md) {
      font-size: 22px;
    }
  }

  &__action {
    display: flex;
    justify-content: center;
    margin-top: 12px;
  }

  &__button {
    display: inline-block;
    border-radius: 16px;
    font-size: 16px;
    border: 1px solid transparent;
    transition: all .3s;

    @include mq(md) {
      font-size: 21px;
    }

    &:hover {
      background-color: $white;
      border: 1px solid $black;
      color: $black;
    }
  }
}

.progress-bar {
  width: 100%;
  height: 15px;
  border-radius: 8px;
  position: relative;
  background: #EFEFEF;
  font-size: rem(13px);
  margin-bottom: 50px;
  margin-top: 50px;

  @include mq(md) {
    margin-bottom: 60px;
    margin-top: 60px;
  }

  &__current {
    position: relative;
    background: $black;
    height: 100%;
    max-width: 100%;
    text-align: right;
    border-radius: 8px;
  }

  &__label {
    display: block;
    position: relative;
    transform: translateY(-100%);
    white-space: nowrap;
    font-size: 16px;
    font-family: $font-family--secondary;
    top: -10px;

    @include mq(md) {
      font-size: 20px;
    }
  }

  &__goal {
    position: absolute;
    right: 0;
    top: calc(100% + 10px);
    font-size: 16px;
    font-family: $font-family--secondary;

    @include mq(md) {
      font-size: 20px;
    }
  }
}
</style>