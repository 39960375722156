import { defineStore } from 'pinia'
import SportingActivity from '@/classes/sporting-activities/SportingActivity'

export const useSportingActivityStore = defineStore('SportingActivityStore', {
  state: () => ({
    loader: false,
    error: {}
  }),
  actions: {
    postActivity ({ params }) {
      return this.$postItem({
        endpoint: '/api/v1/sporting_activities',
        model: SportingActivity,
        params: new SportingActivity(params)
      })
    }
  }
})