<template>
  <header
    class="header"
    :class="{ 'is-minimal': isMinimal && !is_visible, 'is-open': show_mobile_menu || show_mobile_search }"
  >
    <nav class="header__top-nav">
      <div class="container header__wrapper header__wrapper--top">
        <ul
          class="top-nav"
          v-if="topNav.links.length"
        >
          <li
            v-for="({ link }, key) in topNav.links"
            :key="`top-nav_item_${key}`"
          >
            <app-link
              class="top-nav__a"
              :link="link"
              target="_self"
            />
          </li>
        </ul>

        <language-switcher class="language-switcher">
          <template #selected-option="{ option }">
            <fa-icon
              class="language-switcher__icon"
              aria-hidden="true"
              :icon="['far', 'globe']"
              fixed-width
            />

            {{ option.value }}
          </template>

          <template #option="{ option }">
            {{ option.value }}
          </template>
        </language-switcher>

        <div>
          <app-session-button class="session" />
        </div>
      </div>
    </nav>

    <div class="container header__container">
      <a
        class="logo"
        :href="translate(logo.url)"
        v-if="translate(logo.url)"
      >
        <img
          class="logo__img"
          :src="translate(logo.img) || client.logo.small.url"
          :alt="client.name"
        >
      </a>

      <router-link
        class="logo"
        :to="{ name: 'Home', params: { locale } }"
        v-else
      >
        <img
          class="logo__img"
          :src="translate(logo.img) || client.logo.small.url"
          :alt="client.name"
        >
      </router-link>

      <div class="header__wrapper">
        <main-navigation
          class="header__nav"
          :links="nav.links"
          v-if="nav.links.length"
        />

        <search-form
          class="header__search"
          :search="search"
        />

        <div class="header__cta">
          <app-link
            class="cta"
            :link="cta.link"
            target="_self"
          >
            <template #default="{ text }">
              <fa-icon
                class="cta__icon"
                aria-hidden="true"
                :icon="['fal', 'heart']"
                fixed-width
              />

              <span>{{ text }}</span>
            </template>
          </app-link>
        </div>

        <div class="header__cta">
          <button
            class="search-btn"
            type="submit"
            @click="show_mobile_search = true"
          >
            <fa-icon
              aria-hidden="true"
              :icon="['fal', 'magnifying-glass']"
              fixed-width
            />
          </button>
        </div>

        <button
          class="header__btn"
          type="button"
          @click="handleClickShowMenu"
        >
          <svg
            role="img"
            aria-hidden="true"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 18 18"
            width="18"
            height="18"
          >
            <path
              d="M1 3.094c0-.468.376-.844.844-.844h14.062c.468 0 .844.376.844.844a.842.842 0 0 1-.844.844H1.844A.842.842 0 0 1 1 3.094Zm0 5.625c0-.468.376-.844.844-.844h14.062c.468 0 .844.376.844.844a.842.842 0 0 1-.844.844H1.844A.842.842 0 0 1 1 8.719Zm15.75 5.625a.842.842 0 0 1-.844.844H1.844A.842.842 0 0 1 1 14.344c0-.468.376-.844.844-.844h14.062c.468 0 .844.376.844.844Z"
              fill="currentColor"
            />
          </svg>
        </button>

        <app-burger-menu
          class="header__burger"
          :state="show_mobile_search"
          v-if="show_mobile_search"
          @toggle="show_mobile_search = !show_mobile_search"
        />

        <app-burger-menu
          class="header__burger"
          :state="show_mobile_menu"
          v-else
          @toggle="show_mobile_menu = !show_mobile_menu"
        />
      </div>
    </div>

    <transition name="fade-mobile-menu">
      <search-form
        class="mobile__search"
        :search="search"
        v-if="show_mobile_search"
      />
    </transition>

    <transition name="fade-mobile-menu">
      <div
        class="mobile-menu"
        :class="{ 'mobile-menu--full': submenu }"
        v-if="show_mobile_menu"
      >
        <mobile-navigation
          :links="nav.links"
          v-if="nav.links"
          @submenu="key => submenu = key"
        />

        <template v-if="!submenu">
          <app-link
            class="mobile__cta"
            :link="cta.link"
            target="_self"
          >
            <template #default="{ text }">
              <fa-icon
                aria-hidden="true"
                :icon="['fal', 'heart']"
                fixed-width
              />

              <span>{{ text }}</span>
            </template>
          </app-link>

          <ul
            class="mobile-submenu"
            v-if="topNav.links.length"
          >
            <li
              v-for="({ link }, key) in topNav.links"
              :key="`mobile-submenu_${key}`"
            >
              <app-link
                class="mobile-submenu__a"
                :link="link"
                target="_self"
              />
            </li>
          </ul>

          <div class="mobile__container d-flex justify-content-between align-items-center padding__tb--12">
            <language-switcher class="language-switcher mobile__language-switcher">
              <template #selected-option="{ option }">
                <fa-icon
                  class="language-switcher__icon"
                  aria-hidden="true"
                  :icon="['far', 'globe']"
                  fixed-width
                />

                {{ option.value }}
              </template>

              <template #option="{ option }">
                {{ option.value }}
              </template>
            </language-switcher>

            <app-session-button class="session mobile__session" />
          </div>
        </template>
      </div>
    </transition>
  </header>
</template>

<script setup>
import { ref, computed, toRefs, watch, defineAsyncComponent } from 'vue'
import { useClientStore } from '@/stores/ClientStore'
import { useI18n } from '@/vendors/i18n'
import { useWindowScroll } from '@/composables/app/useWindowScroll'
import { useWindowSize } from '@/composables/app/useWindowSize'
import { get as _get } from 'lodash'

import AppLink from '&/atoms/AppLink'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
import LanguageSwitcher from '&/atoms/LanguageSwitcher'
import AppSessionButton from '&/organisms/AppSessionButton'

const MainNavigation = defineAsyncComponent(() => import('./HeaderUzafoundationNavigation'))
const MobileNavigation = defineAsyncComponent(() => import('./HeaderUzafoundationMobileNavigation'))
const SearchForm = defineAsyncComponent(() => import('./HeaderUzafoundationSearchForm'))

const props = defineProps({ section: { type: Object, required: true } })

const { getClient } = useClientStore()
const { translate } = useI18n()
const { locale } = toRefs(useI18n())
const { top } = useWindowScroll()
const { width } = useWindowSize()

const { client } = getClient()

const show_mobile_menu = ref(false)
const show_mobile_search = ref(false)
const is_visible = ref(false)
const current_position = ref(0)
const submenu = ref(null)

const topNav = computed(() => _get(props, 'section.attributes.top_nav', {}))
const logo = computed(() => _get(props, 'section.attributes.logo', {}))
const nav = computed(() => _get(props, 'section.attributes.nav', {}))
const search = computed(() => _get(props, 'section.attributes.search', {}))
const cta = computed(() => _get(props, 'section.attributes.cta', {}))

const isMinimal = computed(() => top.value > 60 || width.value < 1200)

const handleClickShowMenu = () => {
  is_visible.value = true
  current_position.value = top.value
}

watch(top, () => {
  if (isMinimal.value && is_visible.value && Math.abs(top.value - current_position.value) > 20) {
    is_visible.value = false
    current_position.value = 0
  }
})

watch(width, () => is_visible.value = false)
</script>

<style lang="scss" scoped>
$black: #141415;
$color--primary: #3222bf;
$color--secondary: #e11433;
$color--tertiary: #dbd8f8;

.sr-only {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

:deep(.cta) {
  padding: 7px 10px;
  line-height: 1;
  display: inline-block;
  color: $color--secondary;
  border: 1px solid $color--secondary;
  border-radius: 5px;
  display: inline-flex;
  gap: 10px;
  align-items: center;
  font-weight: 600;
  text-decoration: underline;
  text-decoration-color: transparent;
  text-decoration-thickness: 2px;
  transition: text-decoration-color .3s ease-in-out;

  @include mq(sm) {
    padding: 7px 20px;
  }

  @media (min-width:1220px) {
    padding: 8px 15px;
    line-height: 25.6px;
  }

  &.is-active,
  &:hover {
    text-decoration-color: $color--secondary;
  }

  .cta__icon {
    font-size: rem(18px);

    @include mq(sm) {
      font-size: rem(22px);
    }

    @media (min-width:1200px) {
      font-size: rem(18px);
    }
  }

  .is-minimal & {
    border: 0;

    @media (min-width:1220px) {
      padding: 9px 20px;
    }

    span {
      display: none;
    }
  }
}

.header {
  position: sticky;
  top: 0;
  z-index: 999;
  transition: all 0.3s ease-in-out;

  &::before {
    background: $white;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: all 0.3s ease-in-out;
  }

  &.is-minimal {
    @media (min-width:1200px) {
      transform: translateY(-40px);
    }
  }

  &.is-open {
    &::before {
      background: $color--primary;

      @media (min-width: 1200px) {
        background: $white;
      }
    }
  }

  &__top-nav {
    border-bottom: 1px solid $color--tertiary;
    display: none;

    @media (min-width:1200px) {
      display: block;
    }
  }

  &__container {
    padding: 10px 20px;
    display: flex;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
    max-width: 1320px;

    @include mq(sm) {
      padding: 15px 20px;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    flex: 1 1 auto;
    min-height: 32px;

    .is-minimal & {
      gap: 0;
    }

    &--top {
      max-width: 1320px;
      padding: 0 20px;
      gap: 0;

      & > * {
        padding-left: 30px;

        &:not(:last-child) {
          padding-right: 30px;
          border-right: 1px solid $color--tertiary;
        }
      }
    }
  }

  &__nav {
    display: none;

    @media (min-width:1200px) {
      display: flex;

      .is-minimal & {
        display: none;
      }
    }
  }

  &__btn {
    display: none;
    color: #3122bf;

    .is-minimal & {
      @media (min-width: 1200px) {
        display: flex;
        align-items: center;
        padding-left: 20px;
      }
    }
  }

  &__cta {
    .is-minimal & {
      border-right: 1px solid rgba(#2a2270, 0.2);

      @media (min-width: 1200px) {
        border-left: 1px solid rgba(#2a2270, 0.2);
      }
    }

    .is-open & {
      display: none;

      @media (min-width: 1200px) {
        display: inline-block;
      }
    }
  }

  &__search {
    max-width: 200px;
    display: none;

    @media (min-width: 1200px) {
      display: block;
    }

    @media (min-width: 1250px) {
      max-width: 250px;
    }

    .is-minimal & {
      margin-right: 20px;
    }

    &:deep() {
      .search {
        &__input {
          .is-minimal & {
            padding: 6px 15px;
          }
        }
      }
    }
  }

  &__burger {
    padding-left: 0;
    padding-right: 0;
    margin-left: 20px;
    padding-top: 3px;
    padding-bottom: 3px;

    @media (min-width: 1200px) {
      display: none;
    }

    :deep() {
      .menu__label {
        display: none;
      }

      .menu__line {
        width: 22px;
        background-color: #3122bf;
        margin-bottom: 5px;

        &:nth-child(3) {
          margin-bottom: 0;
        }
      }

      .menu__close {
        &::before,
        &::after {
          background-color: $white;
        }
      }
    }
  }
}

.top-nav {
  display: flex;
  gap: 30px;
  font-size: rem(14px);

  &:deep(.top-nav__a) {
    display: block;
    padding: 9px 0;
    color: $black;
    transition: all .3s ease-in-out;
    text-decoration: underline;
    text-decoration-color: transparent;
    line-height: 22.4px;

    &.is-active,
    &:hover {
      text-decoration-color: $black;
    }
  }
}

.logo {
  &__img {
    display: block;
    width: auto;
    height: 25px;

    @include mq(sm) {
      height: 30px;
    }
  }
}

.language-switcher {

  &__icon {
    margin-right: 8px;
  }

  &:deep() {
    .locale-switcher {
      background: transparent;
    }

    .v-select,
    .vs__search {
      font-size: 14px;
      border: 0px!important;
    }

    .ls__dropdown-menu {
      line-height: 21px;
      color: $black;
      font-weight: 600;
      display: flex;
      align-items: center;
      padding: 9px 0;
    }

    .ls__dropdown-menu-icon {
      display: none;
    }

    .vs__dropdown-toggle {
      padding: 0;
    }

    .vs__actions {
      padding: 0 0 0 8px;
    }

    .vs__open-indicator {
      color: #2a2270;
      font-size: rem(18px);
    }

    .vs__dropdown-menu {
      background: $white;
      border: 0;
      left: 0;
    }

    .vs__dropdown-option {
      font-size: rem(14px);
      line-height: 21px;
      background: transparent!important;
      padding: 0;

      .ls__dropdown-option {
        color: $black;
        padding: 0;
        transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        cursor: pointer;
        text-transform: uppercase;
        text-align: center;
        text-decoration: underline;
        text-decoration-color: $black;
        padding:4px 10px;
        width: 100%;
        display: block;

        &:hover {
          text-decoration-color: transparent;
          color: #2a2270;
        }
      }

      &--selected {
        background: transparent!important;

        .ls__dropdown-option {
          color: #2a2270;
        }
      }
    }
  }
}

:deep(.session) {
  font-size: rem(14px);
  text-decoration: underline;
  text-decoration-color: transparent;
  color: $black;
  transition: .3s ease-in-out;

  span {
    margin-left: 8px;
  }

  &:hover {
    text-decoration-color: $black;
  }
}

.search-btn {
  color: $color--primary;
  padding: 6px 10px;
  font-size: rem(18px);
  line-height: 1;

  @include mq(sm) {
    padding: 6px 20px;
    font-size: rem(22px);
  }

  @media (min-width: 1200px) {
    display: none;
  }
}

.mobile {
  &__container {
    padding: 10px 20px;
  }

  &__search {
    position: absolute;
    top: 100%;
    padding: 20px;
    background: $color--primary;
    border-top: 1px solid rgba(#fff, 0.2);

    &:deep() {
      .search {
        &__input {
          background: $white;
        }
      }
    }
  }

  &__language-switcher {
    justify-content: flex-start;

    &:deep() {
      .ls__dropdown-menu {
        color: $white;
        line-height: 1;
      }

      .vs__open-indicator {
        color: $white;
      }
    }
  }
}

:deep(.mobile__cta) {
  padding: 13px 20px;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;

  svg {
    font-size: rem(22px);
  }
}

:deep(.mobile__session) {
  color: $white;
}

.mobile-menu {
  background: $color--primary;
  color: $white;
  position: absolute;
  width: 100%;
  height: calc(100vh);
  padding: 52px 0px 30px;
  top: 0;
  z-index: -2;

  &--full {
    padding-bottom: 0;
  }

  @media (min-width: 1200px) {
    display: none;
  }

  :deep(a) {
    color: $white;
    text-decoration-color: transparent;
    text-decoration-thickness: 2px;
    font-weight: 600;

    &.is-active,
    &:hover {
      text-decoration-color: currentColor;
    }
  }
}

.mobile-submenu {
  :deep(.mobile-submenu__a) {
    display: block;
    padding: 13px 20px;
  }
}

.fade-mobile-menu-enter-active {
  animation: fadeTranslationY 0.3s ease-in-out;
}

.fade-mobile-menu-leave-active {
  animation: fadeTranslationY 0.3s ease-in-out reverse;
}

@keyframes fadeTranslationY {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}
</style>