<template>
  <div
    class="divider"
    :style="sectionStyle"
  />
</template>

<script>
import { defineComponent } from 'vue'
import { useSectionStyle } from '@/composables/modules/pages'

export default defineComponent({
  name: 'SectionDevider',
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  setup: props => ({ ...useSectionStyle(props) })
})
</script>

<style lang="scss" scoped>
.divider {
  display: block;
  width: 100%;
  height: $margin__base * 3;
}
</style>
