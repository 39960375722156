<template>
  <div>
    <label
      class="input__label"
      :for="id"
    >
      {{ translate(label) }} <span v-if="block.is_required">*</span>
    </label>

    <input-iban
      class="margin__t--6"
      v-model="model"
      :v="validator"
      :id="id"
      :placeholder="translate(placeholder)"
    />
  </div>
</template>

<script setup>
import { inject, toRefs } from 'vue'
import { useModule } from '@/composables/app/useModule'
import { useProcedureBlockValidator } from '@/composables/modules/procedures'
import { useI18n } from '@/vendors/i18n'

import InputIban from '&/atoms/InputIban'

const props = defineProps({ block: { type: Object, required: true } })

const { block } = toRefs(props)

const procedure_execution = inject('procedure_execution')

const { translate } = useI18n()
const { label, placeholder, model, id } = useModule({ component: block, parent: procedure_execution })
const { validator } = useProcedureBlockValidator({ validations: block.value.validations, state: model })
</script>
