<template>
  <button
    class="button"
    :class="{ 'is-active': isActive }"
    :disabled="disabled"
    @click="command"
  >
    <fa-icon
      :icon="icon"
      fixed-width
    />
  </button>
</template>

<script setup>
const emit = defineEmits(['command'])

defineProps({
  isActive: { type: Boolean, required: true, default: () => false },
  disabled: { type: Boolean, default: () => false },
  icon: { type: Array, required: true }
})

const command = () => emit('command')
</script>

<style lang="scss" scoped>
.button {
  padding: 5px 3px;
  margin-right: calc($margin__base / 2);
  transition: all 0.3s;
  display: flex;
  align-items: center;
  border: 1px solid transparent;

  &.is-active {
    border-color: $color--primary;
  }
}
</style>
