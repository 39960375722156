export default [
  {
    name: 'custom_footer_sfov',
    path: 'modules/pages/streekfondsoostvlaanderen/FooterStreekfondsoostvlaanderen'
  },
  {
    name: 'custom_header_sfov',
    path: 'modules/pages/streekfondsoostvlaanderen/HeaderStreekfondsoostvlaanderen'
  }
]
