<template>
  <span>
    {{ number }}
  </span>
</template>

<script setup>
import { toRefs, computed } from 'vue'
import { useMoney } from '@/composables/app/useMoney'

const props = defineProps({
  amount: { type: [Number, String], required: true },
  showCurrency: { type: Boolean, default: true }
})

const { amount, showCurrency } = toRefs(props)

const { getAmount } = useMoney()

const number = computed(() => getAmount({ amount, showCurrency }))
</script>
