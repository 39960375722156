<template>
  <div>
    <input
      type="file"
      class="inputfile"
      :class="{ 'inputfile--hidden': hidden }"
      ref="input"
      :id="inputId"
      :accept="accept"
      :disabled="disabled"
      :multiple="multiple"
      @dragover="emit('dragover')"
      @dragleave="emit('dragleave')"
      @change="handleInputChange"
    >

    <label
      :for="inputId"
      :class="{ 'inputfile--disabled': disabled }"
      v-if="!hidden"
    >
      <fa-icon
        class="margin__r--6"
        :icon="['far', 'file-image']"
      />

      <slot name="label">
        {{ t('literal.upload_image') }}
      </slot>
    </label>
  </div>
</template>

<script setup>
import { computed, ref, toRefs } from 'vue'
import { useNotificationStore } from '@/stores/NotificationStore'
import Files from '@/classes/files/Files'
import { useI18n } from '@/vendors/i18n'
import { guid } from '@/utils/utils'

const props = defineProps({
  id: String,
  accept: { type: String, default: 'image/*' },
  disabled: { type: Boolean, default: false },
  multiple: { type: Boolean, default: false },
  hidden: { type: Boolean, default: false }
})

const emit = defineEmits(['change', 'dragover', 'dragleave'])

const { postSideNotification } = useNotificationStore()
const { t } = useI18n()

const { id, accept, multiple } = toRefs(props)

const input = ref()

const inputId = computed(() => id.value || 'file' + guid(1))
const regex = computed(() => new RegExp(accept.value.replaceAll(/ |\+/g, '').split(',').join('|')))

const handleInputChange = e => {
  const files = new Files(e.target.files).filter(file => {
    const is_allowed = regex.value.test(file.type.replaceAll('+', ''))

    if (!is_allowed) postSideNotification({ text: 'errors.input_file_type_is_not_allowed', type: 'error', delay: 10000, data: { name: file.name } })

    return is_allowed
  })

  if (files.length) emit('change', multiple.value ? files : files[0])

  input.value.value = ''
}

defineExpose({ input })
</script>

<style lang="scss" scoped>
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  & + label {
    font-size: rem(14px);
    color: $white;
    background-color: $color--primary;
    display: inline-block;
    cursor: pointer;
    border-radius: $radius__buttons;
    transition: all $transition__duration;
    padding: 6px 24px;
  }

  &--disabled {
    background-color: $vlg !important;
    color: $mg !important;
    cursor: default !important;
  }

  &--hidden {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 10;
    cursor: pointer;
  }

  & + label * {
    pointer-events: none;
  }

  &:focus + label,
  & + label:hover {
    background-color: shade($color--primary, 10%);
  }

  &:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }
}
</style>
