export default [
  {
    name: 'custom_footer_baluchon',
    path: 'modules/pages/baluchonalzheimer/FooterBaluchonalzheimer'
  },
  {
    name: 'custom_header_baluchon',
    path: 'modules/pages/baluchonalzheimer/HeaderBaluchonalzheimer'
  },
  {
    name: 'custom_homepage_baluchon',
    path: 'modules/pages/baluchonalzheimer/HomepageBaluchonalzheimer'
  }
]
