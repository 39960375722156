<template>
  <div
    class="map"
    ref="map"
  >
    <svg
      version="1.1"
      id="building"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 696 557"
      width="696"
      height="557"
      style="enable-background:new 0 0 696 557;"
      xml:space="preserve"
      class="map_svg"
    >
      <image
        style="overflow:visible;"
        width="696"
        height="557"
        xlink:href="https://koalect-images.s3.eu-west-3.amazonaws.com/clients/ilot/building.png"
      />

      <g v-if="!loader.projects && rooms.length > 1">
        <circle
          id="1"
          class="map__dot"
          :class="{ 'is-funded': isFunded(1) }"
          @click="showModal(1)"
          cx="118.8"
          cy="193.5"
          r="11"
        />

        <circle
          class="map__dot"
          :class="{ 'is-funded': isFunded(2) }"
          @click="showModal(2)"
          cx="239.2"
          cy="275.4"
          r="11"
        />

        <circle
          class="map__dot"
          :class="{ 'is-funded': isFunded(3) }"
          @click="showModal(3)"
          cx="468.2"
          cy="250.4"
          r="11"
        />

        <circle
          class="map__dot"
          :class="{ 'is-funded': isFunded(4) }"
          @click="showModal(4)"
          cx="344.2"
          cy="242.4"
          r="11"
        />

        <circle
          class="map__dot"
          :class="{ 'is-funded': isFunded(5) }"
          @click="showModal(5)"
          cx="438.2"
          cy="111.4"
          r="11"
        />

        <circle
          class="map__dot"
          :class="{ 'is-funded': isFunded(6) }"
          @click="showModal(6)"
          cx="551.2"
          cy="188.4"
          r="11"
        />
        <circle
          class="map__dot"
          :class="{ 'is-funded': isFunded(7) }"
          @click="showModal(7)"
          cx="562.2"
          cy="316.4"
          r="11"
        />

        <circle
          class="map__dot"
          :class="{ 'is-funded': isFunded(8) }"
          @click="showModal(8)"
          cx="510.2"
          cy="358.4"
          r="11"
        />

        <circle
          class="map__dot"
          :class="{ 'is-funded': isFunded(9) }"
          @click="showModal(9)"
          cx="344.2"
          cy="347.4"
          r="11"
        />

        <circle
          class="map__dot"
          :class="{ 'is-funded': isFunded(10) }"
          @click="showModal(10)"
          cx="438.2"
          cy="411.4"
          r="11"
        />

        <circle
          class="map__dot"
          :class="{ 'is-funded': isFunded(11) }"
          @click="showModal(11)"
          cx="359.4"
          cy="177"
          r="11"
        />
      </g>
    </svg>

    <app-modal
      v-show="project"
      @close="project = null"
    >
      <template #body>
        <article
          class="room padding__tb--30 padding__lr--54"
          v-if="project"
        >
          <h3
            class="room__title bold color--prim"
            v-if="project.name"
          >
            {{ project.name }}
          </h3>

          <div
            class="editor editor__content room__content margin__t--12"
            v-html="project.description"
            v-if="project.description"
          />

          <div class="room__progressbar margin__t--24">
            <app-progress-bar
              v-if="goalAmount"
              :value="collectedAmount"
              :max-value="goalAmount"
              class="progress-bar margin__t--24"
            />
          </div>

          <ul
            class="room__stats bold color--prim margin__t--6"
            v-if="donationProcedureEndDate || goalAmount"
          >
            <li v-if="donationProcedureEndDate">
              <fa-icon
                class="margin__r--6"
                size="lg"
                :icon="['fal', 'clock']"
              />

              <app-date
                filter="formatDistanceToNow"
                v-if="isOngoing"
                :timestamp="donationProcedureEndDate"
              />

              <span v-else>
                {{ $i18n.t('literal.ended') }}
              </span>
            </li>

            <li v-if="goalAmount">
              <app-money :amount="goalAmount" />
            </li>
          </ul>

          <router-link
            class="link__color--sec room__cta d-inline-block margin__t--24"
            :to="{ name: 'ClientProcedure', params: { procedureSlug: donationProcedureSlug } }"
            v-if="donationProcedureSlug && isOngoing"
          >
            {{ translate(ctaOngoingText) || $i18n.t('literal.donate') }}
          </router-link>
        </article>
      </template>
    </app-modal>
  </div>
</template>

<script setup>
import { ref, reactive, computed, defineAsyncComponent, nextTick } from 'vue'
import { mapActions } from '@/store/map-state'
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'
import { isPast, isFuture } from 'date-fns'

const AppModal = defineAsyncComponent(() => import('&/organisms/AppModal'))
const AppMoney = defineAsyncComponent(() => import('&/atoms/AppMoney'))
const AppDate = defineAsyncComponent(() => import('&/atoms/AppDate'))
const AppProgressBar = defineAsyncComponent(() => import('&/atoms/AppProgressBar'))

const { fetchProjects } = mapActions('projects')

const { translate } = useI18n()

const props = defineProps({
  campaign: { type: Array, default: () => [] },
  rooms: { type: Array, default: () => [] }
})

const map = ref(null)
const projects = ref([])
const project = ref()
const loader = reactive({ projects: true })

const rooms = computed(() => props.rooms.map(room => ({ ...room, project: getProjectById(_get(room, 'project_id.0')) })))
const collectedAmount = computed(() => _get(project.value, 'amount_collected') || 0)
const donationProcedure = computed(() => project.value?.procedures?.find(procedure => procedure.category === 'donation'))
const goalAmount = computed(() => _get(donationProcedure.value, 'goal_amount') || 0)
const donationProcedureSlug = computed(() => donationProcedure.value?.slug)
const donationProcedureStartDate = computed(() => donationProcedure.value?.start_date)
const donationProcedureEndDate = computed(() => donationProcedure.value?.end_date)
const isOngoing = computed(() => isPast(new Date(donationProcedureStartDate.value)) && (!!donationProcedureEndDate.value && isFuture(new Date(donationProcedureEndDate.value)) || !donationProcedureEndDate.value))
const ctaOngoingText = computed(() => _get(project.value, 'campaign.content.project_cta_ongoing'))

const getProjectById = project_id => projects.value.find(project => project.id === project_id)
const getProjectByRoomId = room_id => _get(rooms.value.find(room => room.room_id === room_id), 'project')
const showModal = room_id => project.value = getProjectByRoomId(room_id)

const isFunded = room_id => {
  const project = getProjectByRoomId(room_id)
  const goal = _get(project, 'goal_amount', 0)
  const amount = _get(project, 'statistics.amount_collected', 0)

  return goal ? amount >= goal : false
}

nextTick(() => map.value.scrollTo(map.value.clientWidth / 2, 0))

if (props.campaign.length) {
  fetchProjects({ query: { campaign_id: props.campaign, per_page: 15 } })
    .then(({ items }) => projects.value = items)
    .finally(() => loader.projects = false)
}
</script>

<style lang="scss" scoped>
.map {
  &__svg {
    margin: 0 auto;
  }

  &__dot {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #FFFAF5;
    stroke: #4D7381;
    stroke-width: 2;
    transition: all $transition__duration;
    transform-origin: 50% 50%;
    transform-box: fill-box;
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
    }

    &.is-funded {
      fill: #5DB783;
    }
  }
}

.modal {
  &:deep() {
    .modal {
      &__container {
        border-radius: 26px;
        background: #FFFAF4;
        max-width: 456px;
      }
    }
  }
}

.room {
  &__title {
    font-size: rem(26px);
  }

  &__progressbar {
    .progressbar {
      height: 8px;
      color: $color--primary;

      &::-webkit-progress-bar {
        background-color: $white;
      }

      &::-moz-progress-bar {
        background-color: $color--secondary;
      }

      &::-webkit-progress-value {
        background-color: $color--secondary;
      }
    }
  }

  &__stats {
    display: flex;
    align-items: center;
    font-size: rem(14px);
    justify-content: space-between;
  }
}
</style>