export default [
  {
    name: 'custom_footer_rikolto',
    path: 'modules/pages/rikolto/FooterRikolto'
  },
  {
    name: 'custom_header_rikolto',
    path: 'modules/pages/rikolto/HeaderRikolto'
  },
  {
    name: 'custom_rikolto_campaign',
    path: 'modules/pages/rikolto/CampaignRikolto'
  }
]
