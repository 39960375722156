<template>
  <div>
    <header
      class="header"
      v-if="section"
    >
      <div class="header__container">
        <section class="header__cell">
          <img
            class="logo__img"
            v-if="client.logo.url"
            :src="client.logo.url"
          >
        </section>

        <section class="header__cell">
          <language-switcher
            class="header__language"
            v-if="isMultilingual"
          />
        </section>
      </div>
    </header>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import LanguageSwitcher from '&/atoms/LanguageSwitcher'

export default defineComponent({
  components: {
    LanguageSwitcher
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user'
    }),
    isMultilingual () {
      return this.client.active_languages.length > 1
    }
  }
})
</script>

<style lang="scss" scoped>
$breakpoint: 'md';

.header {
  padding: calc($padding__base / 2) $padding__base;

  @include mq(sm) {
    padding: $padding__base;
  }

  @include mq(md) {
    padding: $padding__base $padding__base * 2;
  }

  &__cell, &__language {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__language {
    margin-left: $margin__base;
  }

  .locale-switcher {
    box-shadow: none;
  }
}

.logo {
  &__img {
    max-width: 170px;
    max-height: 40px;
    transition: all .5s;
    display: block;

    @include mq(sm) {
      max-width: 190px;
      max-height: 55px;
    }
  }
}
</style>
