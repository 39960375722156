import { useUserStore } from '@/stores/UserStore'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { useNotificationStore } from '@/stores/NotificationStore'
import { getQueryFromUrl } from '@/utils/query'
import { get as _get } from 'lodash'

export default [
  {
    path: 'verification/user',
    name: 'VerificationUser',
    props: true,
    beforeEnter (to, from, next) {
      const { postUserVerification } = useUserStore()
      const { setAuthToken, fetchAuthUser } = useAuthenticationStore()
      const { postSideNotification } = useNotificationStore()

      const { token, return_url = '/' } = to.query
      const query = getQueryFromUrl(return_url)

      if (!token) return next({ path: return_url })

      postUserVerification({ params: { token } })
        .then(auth_token => {
          setAuthToken(auth_token)

          fetchAuthUser().finally(() => {
            postSideNotification({ text: 'literal.your_account_has_been_validated' })

            next({ path: return_url, query })
          })
        })
        .catch(errors => {
          _get(errors, 'data.errors', []).forEach(error => postSideNotification({ text: `errors.${error}`, type: 'error', delay: 0 }))

          next({ path: return_url, query })
        })
    }
  }
]
