<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__grid">
        <article>
          <router-link
            :to="{ name: 'Home', params: { locale } }"
            v-show="translate(logo.image)"
          >
            <img
              class="logo__img"
              :src="translate(logo.image)"
              :alt="translate(logo.url)"
            >
          </router-link>

          <ul class="contact contact__ul">
            <li class="contact__li">
              <fa-icon
                :icon="['fal', 'location-dot']"
                class="icon__address margin__r--12"
                fixed-width
              />

              <div
                v-if="translate(contact.address)"
                class="editor editor__content"
                v-html="translate(contact.address)"
              />
            </li>

            <li class="contact__li">
              <fa-icon
                :icon="['fal', 'phone']"
                class="margin__r--12"
                fixed-width
              />

              {{ translate(contact.phone) }}
            </li>

            <li class="contact__li">
              <fa-icon
                :icon="['fal', 'envelope']"
                class="margin__r--12"
                fixed-width
              />

              <a
                class="contact__link"
                :href="email.url"
                target="_blank"
              >
                {{ email.text }}
              </a>
            </li>
          </ul>
        </article>

        <article class="navigation">
          <ul class="navigation__ul">
            <li
              v-for="({ item }, index) in links"
              :key="`navigation_${index}`"
              class="navigation__li"
            >
              <app-link
                :link="item"
                class="navigation__link link--naked link__color--black"
              />
            </li>
          </ul>
        </article>

        <article
          class="social"
          v-if="medias.length > 0 && medias"
        >
          <h3 class="social__title">
            {{ translate(social.title) }}
          </h3>

          <ul class="social__ul">
            <li
              class="social__li"
              v-for="(media, i) in medias"
              :key="'footer_menu_media_' + i"
            >
              <a
                class="social__link"
                :href="translate(media.url)"
                target="_blank"
              >
                <fa-icon
                  v-if="media.icon"
                  size="lg"
                  :icon="media.icon"
                  class="social__icon"
                />
              </a>
            </li>
          </ul>
        </article>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { computed } from 'vue'
import { mapGetters } from '@/store/map-state'
import { get as _get } from 'lodash'
import { useI18n } from '@/vendors/i18n'

import AppLink from '&/atoms/AppLink'

const props = defineProps({
  section: { type: Object, required: true }
})

const { getLocale, translate } = useI18n()
const { client } = mapGetters('client')

const locale = computed(() => getLocale())
const logo = computed(() => _get(props, 'section.attributes.logo', {}))
const contact = computed(() => _get(props, 'section.attributes.contact', {}))
const links = computed(() => _get(props, 'section.attributes.links', {}))
const social = computed(() => _get(props, 'section.attributes.social_networks', {}))

const email = computed(() => ({
  text: translate(_get(contact.value, 'email', null)).replace(/ /g, ''),
  url: 'mailto:' + translate(_get(contact.value, 'email', null)).replace(/ /g, '')
}))

const medias = computed(() => {
  const links = _get(client.value, 'platform.social_networks', [])
  return links.length > 0
    ? links.map(link => {
      if (link.icon.includes('facebook-square')) {
        link.icon[1] = 'facebook-f'
      } else if (link.icon.includes('linkedin')) {
        link.icon[1] = 'linkedin-in'
      }

      return link
    })
      .filter(link => translate(link.url))
    : []
})
</script>

<style lang="scss" scoped>
.footer {
  padding: 60px 16px;
  background-color: #0C2529;
  font-size: rem(14px);
  color: $white;

  .container {
    padding: 0;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: $margin--base * 3;
    justify-content: space-between;

    @include mq(md) {
      grid-template-columns: repeat(3, fit-content(200px));
    }
  }
}

.logo {
  &__img {
    display: block;
    max-width: 145px;

    @include mq(md) {
      max-width: 145px;
    }
  }
}

.contact {
  &__ul {
    margin-top: 24px;
  }

  &__li {
    display: flex;
    align-items: center;
    font-size: 18px;
    white-space: nowrap;
  }

  &__li:not(:last-child) {
    margin-bottom: 12px;
  }

  &__link {
    text-decoration: none;
    color: white;
    transition: all .3s;

    &:hover {
      color: $color--primary;
    }
  }

  .icon__address {
    align-self: flex-start;
    position: relative;
    top: 3px;
  }
}

.navigation {
  & :deep(.navigation__link) {
    color: $white;
    font-size: rem(24px);
    white-space: nowrap;
  }

  &__ul {
    @include mq(md) {
      margin-top: 24px;
    }
  }

  &__li {
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: $color--primary;
      border-radius: 50%;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: all .3s;
      opacity: 0;
    }

    &:not(:last-child) {
      margin-bottom: 6px;

      @include mq(md) {
        margin-bottom: 12px;
      }
    }

    &:hover:before {
      left: -18px;
      opacity: 1;
    }
  }
}

.social {
  &__title {
    color: $white;
    margin-bottom: 12px;
    font-size: 24px;
  }

  &__ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: $margin--base * 2;
    align-items: center;
    margin-bottom: 24px;

    @include mq(md) {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: $margin--base;
    }
  }

  &__li {
    transition: all .3s ease-out;

    &:hover .networks__a {
      top: -4px;
    }
  }

  &__link {
    position: relative;
    color: $black;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all .3s ease-out;
    width: 37px;
    height: 37px;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border: 1px solid $white;
      background-color: $white;
      border-radius: 50%;
      z-index: 0;
      transition: all .3s ease-out;
    }

    &:hover {
      color: $white;

      &:after {
        background-color: transparent;
      }
    }
  }

  &__icon {
    z-index: 1;
    font-size: 16px;
  }
}
</style>
