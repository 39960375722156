import { defineStore } from 'pinia'
import { useClientStore } from '@/stores/ClientStore'
import { get as _get } from 'lodash'
import i18n from '@/vendors/i18n'

export const useCurrencyStore = () => {
  const { getClient } = useClientStore()
  const { client } = getClient()

  const innerStore = defineStore('CurrencyStore', {
    state: () => ({
      available_currencies: _get(client.value, 'active_currencies', []),
      currency: null
    }),
    getters: {
      getThousands: state => i18n.t(`currencies.${state.currency.toLowerCase()}_thousands`) || ',',
      getDecimals: state => i18n.t(`currencies.${state.currency.toLowerCase()}_decimals`) || '.'
    },
    actions: {
      setCurrency () {
        this.currency = _get(this.available_currencies, 0, 'EUR')
      }
    }
  })

  const store = innerStore()

  if (!store.currency) store.setCurrency()

  return store
}