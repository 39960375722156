<template>
  <div class="title__container">
    <h2 class="title__h2 bold">
      {{ t('literal.contributions') }}
    </h2>

    <button
      class="header__actions link__color--prim link--naked"
      type="button"
      @click.prevent="openExportModal()"
    >
      <fa-icon
        class="icon"
        :icon="['fal', 'download']"
      />

      <span class="margin__l--6">
        {{ t('literal.download_contributions') }}
      </span>
    </button>
  </div>

  <section
    class="margin__t--36 align--center"
    v-if="!contributions.length && !contributionsLoader"
  >
    <fa-icon
      class="margin__r--6"
      :icon="['fal', 'empty-set']"
    />

    {{ t('literal.no_contributions_yet') }}
  </section>

  <table
    class="table"
    :class="{'table__6': isEmailCategoryAvailable}"
    role="table"
    aria-label="List of contributions"
    v-if="contributions.length && !contributionsLoader"
  >
    <caption class="table__caption">
      {{ t('literal.list_of_contributions') }}
    </caption>

    <thead clas="table__thead">
      <tr
        class="table__tr table__tr--naked"
        role="rowgroup"
      >
        <th
          class="table__th"
          role="columnheader"
        >
          <app-table-sort-by
            field="created_at"
            v-model="sorting"
          >
            {{ t('literal.date_and_time') }}
          </app-table-sort-by>
        </th>

        <th
          class="table__th"
          role="columnheader"
        >
          {{ t('literal.contributor') }}
        </th>

        <th
          class="table__th"
          role="columnheader"
          v-if="isEmailCategoryAvailable"
        >
          {{ t('literal.email') }}
        </th>

        <th
          class="table__th"
          role="columnheader"
        >
          {{ t('literal.amount') }}
        </th>

        <th
          class="table__th"
          role="columnheader"
        >
          {{ t('literal.method') }}
        </th>

        <th
          class="table__th"
          role="columnheader"
        />
      </tr>
    </thead>

    <tbody class="table__tbody card">
      <tr
        class="table__tr table__hover"
        role="rowgroup"
        v-for="contribution in contributions"
        :key="contribution.id"
        @click.prevent="handleClickContribution(contribution.id)"
      >
        <td
          class="table__td"
          role="cell"
        >
          <app-date
            :timestamp="contribution.created_at"
            filter="dd/MM/yyyy – HH:mm"
          />
        </td>

        <td
          class="table__td"
          role="cell"
        >
          <div class="table__contributor">
            <user-profile-img
              class="margin__r--12"
              size="2"
              :avatar="contribution.customer.avatar"
            />

            <span v-if="contribution.customer.is_anonymous">
              {{ t('literal.anonymous') }}
            </span>

            <span v-else>
              {{ contribution.customer.fullname }}
            </span>
          </div>
        </td>

        <td
          class="table__td table__ellipsis"
          role="cell"
          v-if="isEmailCategoryAvailable"
        >
          <span v-if="contribution.customer.email">
            {{ contribution.customer.email }}
          </span>

          <span v-else>
            —
          </span>
        </td>

        <td
          class="table__td"
          role="cell"
        >
          <app-money
            v-if="contribution.amount"
            :amount="contribution.amount"
          />

          <span v-else>
            —
          </span>
        </td>

        <td
          class="table__td"
          role="cell"
        >
          <app-payment-logo
            v-if="contribution.payment_method"
            :type="contribution.payment_method"
          />

          <span v-else>
            —
          </span>
        </td>

        <td
          class="table__td"
          role="cell"
        >
          <fa-icon
            size="xs"
            class="table__arrow margin__r--6"
            :icon="['fal', 'chevron-right']"
          />
        </td>
      </tr>
    </tbody>
  </table>

  <div
    class="spinner__container"
    v-if="contributionsLoader"
  >
    <app-spinner />
  </div>

  <app-pagination
    class="margin__t--24"
    :paginator="pagination"
    @change="page => query.page = page"
  />

  <app-export-modal
    v-if="show_export_modal"
    :is-loading="exportLoader"
    @export="handleExport"
    @close="closeExportModal()"
  >
    <template #title>
      <h3 class="title__h3 bold">
        {{ t('literal.download_contributions_file') }}
      </h3>
    </template>
  </app-export-modal>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'
import { useProjectStore } from '@/stores/ProjectStore'
import { useI18n } from '@/vendors/i18n'
import { useHead } from '@unhead/vue'
import { useModals } from '@/composables/app/useModals'
import { useFilters } from '@/composables/app/useFilters'
import { get as _get } from 'lodash'
import { slugify, scrollTo } from '@/utils/utils'
import { format as formatDate } from 'date-fns'

import UserProfileImg from '&/atoms/UserProfileImg'
import AppDate from '&/atoms/AppDate'
import AppMoney from '&/atoms/AppMoney'
import AppPaymentLogo from '&/atoms/AppPaymentLogo'

const AppSpinner = defineAsyncComponent(() => import('&/atoms/AppSpinner'))
const AppPagination = defineAsyncComponent(() => import('&/molecules/AppPagination'))
const AppTableSortBy = defineAsyncComponent(() => import('&/molecules/AppTableSortBy'))
const AppExportModal = defineAsyncComponent(() => import('&/organisms/AppExportModal'))

const props = defineProps({ projectSlug: { required: true, type: String } })

const { t } = useI18n()
const route = useRoute()
const { push } = useRouter()
const { getProject, getContributions, fetchContributionsExport } = useProjectStore()
const { loader: exportLoader } = storeToRefs(useProjectStore())
const { show_modal: show_export_modal, openModal: openExportModal, closeModal: closeExportModal } = useModals()
const { query, sorting } = useFilters({
  default_query: { project_slug: props.projectSlug },
  handleChange: () => setTimeout(() => scrollTo())
})

const { project } = getProject({ id: props.projectSlug })
const { contributions, pagination, loader: contributionsLoader } = getContributions({ id: props.projectSlug, query })

const isEmailCategoryAvailable = computed(() => contributions.value?.some(contribution => contribution.customer.email))

const handleExport = ({ format, extension }) => fetchContributionsExport({ id: props.projectSlug, query: { format }, filename: `${slugify(`Contributions - ${formatDate(new Date(), 'dd/MM/yyyy HH:mm')}`)}.${extension}` }).finally(() => closeExportModal())
const handleClickContribution = id => push({ name: 'PrivateContribution', params: { contributionId: id }, query: { ...route.query } })

useHead({ title: computed(() => project.value ? `${t('literal.contributions')} / ${_get(project.value, 'name', '')}` : t('literal.contributions')) })
</script>

<style lang="scss" scoped>
.title {
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
  }
}

.table {
  margin-top: 24px;

  &__tr {
    grid-template-columns: 1fr 2fr repeat(2, 1fr) 50px;
    white-space: nowrap;

    @include mq(sm) {
      grid-template-columns: 1fr minmax(120px, 2fr) repeat(2, 1fr) 50px;
    }
  }

  &__6 .table__tr {
    grid-template-columns: 1fr repeat(2, 2fr) repeat(2, 1fr) 50px;

    @include mq(sm) {
      grid-template-columns: 1fr minmax(120px, 2fr) minmax(120px, 2fr) repeat(2, 1fr) 50px;
    }
  }

  &__contributor {
    display: flex;
    align-items: center;
  }
}

.load-more {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $margin__base * 2;
}

.spinner {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: $margin__base * 2;
  }
}
</style>
