import { mixpanel } from '@/vendors/integrations'

export default class {
  static set = ({ user }) => {
    mixpanel.people.set({
      '$user_id': user.id,
      '$avatar': user.avatar.url,
      '$email': user.email,
      '$phone': user.phone_number,
      '$first_name': user.firstname,
      '$last_name': user.lastname,
      '$language': user.language,
      '$role': user.role.charAt(0).toUpperCase() + user.role.slice(1).replace('_', ' '),
      '$birthday': user.birthday
    })
  }
}
