export default [
  {
    name: 'custom_footer_24htelevie',
    path: 'modules/pages/24htelevie/Footer24hTelevie'
  },
  {
    name: 'custom_header_24htelevie',
    path: 'modules/pages/24htelevie/Header24hTelevie'
  },
  {
    name: 'custom_homepage_24htelevie',
    path: 'modules/pages/24htelevie/Homepage24hTelevie'
  }
]
