import { computed, toValue } from 'vue'
import { storeToRefs } from 'pinia'
import { useLanguageStore } from '@/stores/LanguageStore'
import { get as _get, set as _set } from 'lodash'
import { guid } from '@/utils/utils'

export function useModule ({ component, parent, default_value = null, is_multilingual = false }) {
  const { locale } = storeToRefs(useLanguageStore())

  const id = `field${guid(4)}`
  const attributes = computed(() => _get(toValue(component), 'attributes', {}))
  const settings = computed(() => _get(toValue(component), 'settings', {}))
  const label = computed(() => _get(attributes.value, 'label', {}))
  const placeholder = computed(() => _get(attributes.value, 'placeholder', {}))
  const options = computed(() => _get(toValue(component), 'options', {}))
  const autocomplete = computed(() => _get(options.value, 'autocomplete'))
  const defaultValue = computed(() => _get(attributes.value, 'default_value', default_value))

  const model = computed({
    get: () => is_multilingual ? _get(toValue(parent), `${toValue(component).model}.${locale.value}`) : _get(toValue(parent), toValue(component).model),
    set: value => is_multilingual ? _set(toValue(parent), `${toValue(component).model}.${locale.value}`, value || null) : _set(toValue(parent), toValue(component).model, value)
  })

  return {
    id,
    model,
    settings,
    label,
    placeholder,
    options,
    autocomplete,
    attributes,
    defaultValue
  }
}