<template>
  <div>
    <header
      class="header"
      :class="{'header--fixed': mobileMenuOpened}"
      v-if="section"
    >
      <div class="header__wrapper">
        <router-link
          :to="{ name: 'Home', params: { locale: $i18n.locale } }"
          v-show="client.logo.url"
        >
          <img
            class="logo__img"
            :src="client.logo.url"
            :alt="client.name"
          >
        </router-link>

        <div class="header__cell">
          <div class="header__row">
            <language-switcher
              class="header__language margin__r--24"
              v-if="isMultilingual"
            />

            <button
              @click.prevent="openSessionAppModal('register')"
              class="header__session"
              v-if="!isAuthenticated"
            >
              <fa-icon
                class="margin__r--6"
                :icon="['far', 'user']"
              />
              <span v-html="$i18n.t('literal.login')" />
            </button>

            <router-link
              class="header__session header__session--user"
              :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
              v-if="isAuthenticated && user"
            >
              <user-profile-img
                :size="2"
                :avatar="user.avatar"
              />
            </router-link>
          </div>
          <nav class="header__row header__row--nav">
            <ul class="header__menu">
              <li
                class="header__menu-item"
                v-for="({ link }, index) in links"
                :key="`menu_item_${index}`"
              >
                <button
                  class="header__link bold"
                  :class="{'header__link--active': isVisited(link)}"
                  @click.prevent="setRouteNavigation(link)"
                >
                  {{ $i18n.translate(link.text) }}
                </button>
              </li>
            </ul>

            <ul class="header__actions margin__l--24 padding__l--24">
              <template
                v-for="({ link }, index) in actions"
              >
                <li
                  class="header__actions-item"
                  v-if="link.show"
                  :key="`cta_${index}`"
                >
                  <button
                    class="header__button bold"
                    v-if="link"
                    :disabled="!isActive(link)"
                    @click.prevent="setRouteNavigation(link)"
                  >
                    {{ $i18n.translate(link.text) }}

                    <fa-icon
                      class="margin__l--6"
                      :icon="['fal', 'long-arrow-right']"
                    />
                  </button>
                </li>
              </template>
            </ul>
          </nav>
        </div>

        <app-burger-menu
          class="header__burger"
          :state="mobileMenuOpened"
          @toggle="toggleMobileMenu"
        />
      </div>

      <transition name="fade-mobile-menu">
        <section
          class="mobile__menu"
          v-if="section && mobileMenuOpened"
        >
          <ul class="mobile__sessions">
            <li
              class="mobile__sessions-item header__sessions-item"
              v-if="!isAuthenticated"
            >
              <a
                @click.prevent="openSessionAppModal('login')"
                class="link__color--black link--naked pointer"
              >
                <fa-icon
                  class="margin__r--6 color--prim"
                  :icon="['fal', 'user']"
                  fixed-width
                />
                {{ $i18n.t('literal.login') }}
              </a>
            </li>

            <li
              class="mobile__sessions-item"
              v-if="!isAuthenticated"
            >
              <a
                @click.prevent="openSessionAppModal('register')"
                class="link__color--black link--naked pointer"
              >
                <fa-icon
                  class="margin__r--6 color--prim"
                  :icon="['fal', 'user-plus']"
                  fixed-width
                />
                {{ $i18n.t('literal.register') }}
              </a>
            </li>

            <li
              class="mobile__sessions-item pointer"
              v-if="isAuthenticated && user"
            >
              <router-link
                :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
                @click.capture="mobileMenuOpened = false"
                class="d-flex align-items-center"
              >
                <user-profile-img
                  class="margin__r--6"
                  size="2"
                  :avatar="user.avatar"
                />

                {{ user.firstname }}
              </router-link>
            </li>

            <li
              class="mobile__sessions-item header__sessions-item pointer"
              v-if="isAuthenticated && user"
            >
              <router-link :to="{ name: 'Logout' }">
                <fa-icon
                  class="margin__r--6 color--danger"
                  :icon="['fal', 'sign-out']"
                  fixed-width
                />
                {{ $i18n.t('literal.logout') }}
              </router-link>
            </li>
          </ul>

          <ul class="mobile__list">
            <li
              class="mobile__list-item"
              v-for="({ link }, index) in links"
              :key="`mobile_menu_item_${index}`"
            >
              <a
                class="mobile__list-link bold"
                :class="{'mobile__link--active': isVisited(link)}"
                @click.prevent="setRouteNavigation(link); mobileMenuOpened = false"
              >
                {{ $i18n.translate(link.text) }}
              </a>
            </li>
          </ul>

          <ul class="mobile__actions">
            <li
              class="mobile__actions-item"
              v-for="({ link }, index) in actions"
              :key="`cta_${index}`"
            >
              <button
                class="header__button"
                v-if="link"
                :disabled="!isActive(link)"
                @click.prevent="setRouteNavigation(link); mobileMenuOpened = false"
              >
                {{ $i18n.translate(link.text) }}

                <fa-icon
                  class="margin__l--6"
                  :icon="['fal', 'long-arrow-right']"
                />
              </button>
            </li>

            <li class="mobile__list-item">
              <div class="mobile__list-link mobile__list-language">
                <language-switcher v-if="isMultilingual" />
              </div>
            </li>
          </ul>
        </section>
      </transition>
    </header>

    <app-modal
      v-if="showSessionAppModal"
      size="sm"
      @close="showSessionAppModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="template"
          @authenticated="showSessionAppModal = false"
        />
      </template>
    </app-modal>
  </div>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'
import UserProfileImg from '&/atoms/UserProfileImg'
import PageRouteNavigation from '%/PageRouteNavigation'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
import LanguageSwitcher from '&/atoms/LanguageSwitcher'

export default defineComponent({
  name: 'CustomHeaderUZGent',
  components: {
    UserProfileImg,
    AppBurgerMenu,
    LanguageSwitcher,
    SessionForms: defineAsyncComponent(() => import('&/modules/sessions/SessionForms')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      showSessionAppModal: false,
      mobileMenuOpened: false,
      template: null
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    attributes () {
      return _get(this.section, 'attributes', {})
    },
    actions () {
      return _get(this.attributes, 'actions', [])
    },
    navigation () {
      return _get(this.attributes, 'navigation', [])
    },
    isMultilingual () {
      return this.client.active_languages.length > 1
    },
    links () {
      return this.navigation.filter(({ link }) => link.text[this.$i18n.locale])
    }
  },
  methods: {
    openSessionAppModal (template) {
      this.template = template
      this.showSessionAppModal = true
    },
    toggleMobileMenu (value) {
      this.mobileMenuOpened = !value
    }
  }
})
</script>

<style lang="scss" scoped>
  $color--primary: #0169CA;
  $color--secondary: #131415;
  $color--tertiary: #7AA9EB;

  .header {
    padding: $padding__base * 2;
    border-bottom: 1px solid $color--primary;
    background: $white;

    &--fixed {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 100;
    }

    &__wrapper {
      max-width: 1440px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      background: $white;
      position: relative;
      z-index: 10;
    }

    &__cell {
      display: none;

      @include mq(md) {
        display: block;
      }
    }

    &__row {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &--nav {
        margin-top: $margin__base * 1.5;
        line-height: 0;
      }
    }

    &__session {
      color: $color--tertiary;
      font-size: rem(14px);
      transition: all $transition__duration;

      &:hover {
        color: $color--primary;
      }
    }

    &__menu {
      display: flex;

      &-item {
        margin-right: $margin__base * 2;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__link {
      color: $color--primary;
      text-transform: uppercase;
      transition: all $transition__duration;

      &:hover {
        color: $color--tertiary;
      }

      &--active {
        color: $color--secondary;
      }
    }

    &__actions {
      border-left: 2px solid $color--primary;
    }

    &__button {
      color: $color--tertiary;
      text-transform: uppercase;
      font-size: rem(12px);
      transition: all $transition__duration;

      svg {
        transition: all $transition__duration
      }

      &:hover {
        color: $color--primary;

        svg {
          transform: translateX(3px);
        }
      }
    }

    &__burger {
      display: inherit;

      &.mennu__line {
        background-color: $color--primary;
      }

      @include mq(md) {
        display: none;
      }
    }
  }

  .mobile {
    &__menu {
      position: relative;
      z-index: 0;
      padding-top: $padding__base;
    }

    &__sessions {
      padding: $padding__base;
      border-top: 1px solid $color--primary;
      border-bottom: 1px solid $color--primary;
      display: flex;
      align-items: center;
      justify-content: center;

      &-item {
        margin: 0 $margin__base * 2 0 0;

        @include mq(sm) {
          margin-right: $margin__base * 4;
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    &__list {
      padding: $padding__base;

      &-item {
        &:last-child {
          margin-bottom: 0;
        }
      }

      &-link {
        color: $color--primary;
        text-transform: uppercase;
        padding: calc($padding__base / 1.5) 0;
        display: block;
      }
    }

    &__actions {
      padding: $padding__base;
    }

    &__link {
      &--active {
        color: $color--secondary;
      }
    }
  }

  .fade-mobile-menu-enter-active {
    animation: fadeTranslationY .3s ease-in-out;
  }

  .fade-mobile-menu-leave-active {
    animation: fadeTranslationY .3s ease-in-out reverse;
  }

  @keyframes fadeTranslationY {
    from {
      transform: translateY(-10px);
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  .logo {
    &__img {
      width: auto;
      height: 30px;
      display: block;

      @include mq(sm) {
        height: 36px;
      }

      @include mq(md) {
        height: 48px;
      }

      @include mq(lg) {
        height: 57px;
      }
    }
  }

  .modal {
    &__body {
      padding: $padding__base * 2;

      @include mq(md) {
        padding: $padding__base * 3 $padding__base * 4;
      }
    }
  }
</style>
