import { toValue, computed } from 'vue'
import { useProcedure, useProcedureExecution, useProcedureStep } from '@/composables/modules/procedures'
import { get as _get, set as _set } from 'lodash'
import { useVuelidate } from '@vuelidate/core'

export function useProcedureValidator ({ procedure, procedure_execution }) {
  const { steps } = useProcedure({ procedure })
  const { id: procedureExecutionId } = useProcedureExecution({ procedure, procedure_execution })
  const { step_index, isLastStep, isInexistentStep } = useProcedureStep({ procedure })

  const procedureValidator = computed(() => _get(toValue(procedure), 'validator', false))

  const stepsValidationRules = computed(() => steps.value.slice(0, -1).map(step => step.sections.blocks
    .filter(block => block.model && block.isAvailable(toValue(procedure_execution)))
    .reduce((acc, block) => _set(acc, block.model, { ..._get(acc, block.model), ...block.validations }), {})
  ))

  const stepsValidator = computed(() => stepsValidationRules.value.map(rule => useVuelidate(rule, procedure_execution)))
  const firstInvalidStepIndex = computed(() => stepsValidator.value.findIndex(step => step.value.$invalid) + 1)

  const stepValidator = computed(() => isInexistentStep.value || (isLastStep.value && !procedureExecutionId.value) || (!isLastStep.value && firstInvalidStepIndex.value && step_index > firstInvalidStepIndex.value) ? firstInvalidStepIndex.value || 1 : 0)

  return {
    procedureValidator,
    stepValidator
  }
}