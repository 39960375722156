<template>
  <v-tooltip
    :distance="distance"
    theme="tooltip"
    :triggers="triggers"
    :placement="placement"
  >
    <template #default>
      <slot name="default" />
    </template>

    <template #popper>
      <slot name="popper" />
    </template>
  </v-tooltip>
</template>

<script>
import { Tooltip as VTooltip } from 'floating-vue'

export default {
  name: 'AppTooltip',
  components: {
    VTooltip
  },
  props: {
    triggers: {
      type: Array,
      default: () => {
        return ['click', 'hover']
      }
    },
    placement: {
      type: String,
      default: 'top'
    },
    distance: {
      type: Number,
      default: 10
    }
  }
}
</script>