<template>
  <div class="input__container">
    <div class="input__flex input--number">
      <span
        class="prefix padding__l--12"
        v-if="slots.prefix"
      >
        <slot name="prefix" />
      </span>

      <input
        type="number"
        class="input"
        v-model="model"
        :id="id"
        :min="min"
        :max="max"
        :placeholder="placeholder"
        :disabled="disabled"
        @blur="handleBlur"
      >
      <span
        class="suffix padding__r--12"
        v-if="slots.suffix"
      >
        <slot name="suffix" />
      </span>

      <div class="arrows">
        <slot
          name="up"
          :increment="handleClickIncrement"
        >
          <button
            class="arrows-item"
            :disabled="disabled"
          >
            <fa-icon
              @click="handleClickIncrement"
              :icon="['fal', 'chevron-up']"
            />
          </button>
        </slot>

        <slot
          name="down"
          :decrement="handleClickDecrement"
        >
          <button
            class="arrows-item"
            :disabled="disabled"
          >
            <fa-icon
              @click="handleClickDecrement"
              :icon="['fal', 'chevron-down']"
            />
          </button>
        </slot>
      </div>
    </div>

    <div
      class="input--errors"
      v-if="v && v.$error"
    >
      <p
        class="input--error"
        v-if="'required' in v && v.required.$invalid === true"
      >
        {{ t('errors.input_number_required') }}
      </p>

      <p
        class="input--error"
        v-else-if="'minValue' in v && v.minValue.$invalid === true"
      >
        {{ t('errors.input_number_min_value', { value: v.minValue.$params.min }) }}
      </p>

      <p
        class="input--error"
        v-else-if="'numeric' in v && v.numeric.$invalid === true"
      >
        {{ t('errors.input_number_not_numeric') }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, toRefs, useSlots } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { isUndefined as _isUndefined } from 'lodash'

const emits = defineEmits(['update:modelValue'])

const props = defineProps({
  id: String,
  placeholder: { type: String, default: '' },
  modelValue: { type: [Number, String], required: true },
  min: [Number, String],
  max: [Number, String],
  disabled: { type: Boolean, default: false },
  v: Object
})

const slots = useSlots()
const { t } = useI18n()

const timeout = ref()

const { id, placeholder, modelValue, min, max, v, disabled } = toRefs(props)

const model = computed({
  get: () => modelValue.value,
  set: number => {
    clearTimeout(timeout.value)

    timeout.value = setTimeout(() => {
      if (!_isUndefined(max.value) && parseFloat(number) >= parseFloat(max.value)) model.value = parseFloat(max.value)
      if (!_isUndefined(min.value) && parseFloat(number) <= parseFloat(min.value)) model.value = parseFloat(min.value)
    }, 500)

    emits('update:modelValue', number)
  }
})

const handleClickIncrement = () => {
  if ((_isUndefined(max.value) || parseFloat(model.value) < parseFloat(max.value)) && disabled.value === false) model.value++
}

const handleClickDecrement = () => {
  if ((_isUndefined(min.value) || parseFloat(model.value) > parseFloat(min.value)) && disabled.value === false) model.value--
}

const handleBlur = () => {
  if (v.value) v.value.$touch()
}
</script>

<style lang="scss" scoped>
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input--number {
  -moz-appearance: textfield;
  border: 1px solid rgba($dg, 0.1);
  border-radius: $radius__input;
}

.input__flex {
  display: flex;
  align-items: center;
}

.arrows {
  height: 100%;
  font-size: 12px;
  margin-right: calc($margin__base / 2);

  &-item {
    display: block;
    margin: 0 calc($margin__base / 4);
    font-size: 10px;

    &:disabled {
      cursor: default;
    }
  }
}

.prefix,
.suffix {
  white-space: nowrap;
}
</style>
