<template>
  <section
    class="banner"
    :style="`background-image: url(${ translate(banner.image) })`"
    v-if="isVisible('banner')"
  >
    <div class="banner__content">
      <h1 class="banner__title">
        {{ translate(banner.title) }}
      </h1>

      <p class="banner__subtitle">
        {{ translate(banner.subtitle) }}
      </p>
    </div>
  </section>

  <section
    class="informations"
    v-if="isVisible('informations')"
  >
    <div class="container">
      <div
        class="informations__text editor editor__content"
        v-if="translate(informations.text)"
        v-html="translate(informations.text)"
      />
    </div>

    <article class="informations__article">
      <img
        class="article__image"
        :src="translate(informations.image)"
        alt="Illustration"
      >

      <div class="article__quote">
        <div
          class="quote__text editor editor__content"
          v-if="translate(informations.quote)"
          v-html="translate(informations.quote)"
        />

        <p class="quote__author">
          {{ translate(informations.author) }}
        </p>
      </div>
    </article>

    <div class="informations__action">
      <app-link
        :link="informations.button"
        class="btn__color--black btn__size--classic informations__button"
      />
    </div>
  </section>

  <section
    class="rooms"
    v-if="isVisible('rooms')"
  >
    <div class="container">
      <div class="rooms__head">
        <h2 class="rooms__title">
          {{ translate(rooms.title) }}
        </h2>

        <div
          class="rooms__text editor editor__content"
          v-if="translate(rooms.text)"
          v-html="translate(rooms.text)"
        />
      </div>

      <map-blindenzorglichtliefde :section="rooms" />
    </div>
  </section>

  <section
    class="timeline"
    v-if="isVisible('timeline')"
  >
    <div class="container">
      <div class="timeline__grid">
        <h2 class="timeline__title">
          {{ translate(timeline.title) }}
        </h2>

        <ol class="timeline__ol">
          <li
            class="timeline__li"
            v-for="(event, index) in timeline.events"
            :key="`event_${index}`"
          >
            <div class="timeline__card">
              <h3
                class="event__title"
                v-if="translate(event.title)"
              >
                {{ translate(event.title) }}
              </h3>

              <div
                class="event__text editor editor__content"
                v-if="translate(event.text)"
                v-html="translate(event.text)"
              />
            </div>

            <span class="event__date">
              {{ translate(event.date) }}
            </span>
          </li>
        </ol>
      </div>
    </div>
  </section>

  <section
    class="gallery"
    v-if="isVisible('gallery')"
  >
    <div class="container">
      <div class="gallery__grid">
        <ul
          class="gallery__ul gallery--start"
          v-if="gallery.images_right?.length"
        >
          <li class="gallery--selfend">
            <h2 class="gallery__title">
              {{ translate(gallery.title) }}
            </h2>
          </li>

          <li
            class="gallery__li"
            v-for="({ image }, index) in gallery.images_right"
            :key="`gallery_right_${index}`"
          >
            <img
              class="gallery__img"
              :src="translate(image)"
              :alt="`Gallery image ${index}`"
            >
          </li>
        </ul>

        <ul
          class="gallery__ul gallery--end"
          v-if="gallery.images_left?.length"
        >
          <li
            class="gallery__li"
            v-for="({ image }, index) in gallery.images_left"
            :key="`gallery_left_${index}`"
          >
            <img
              class="gallery__img"
              :src="translate(image)"
              :alt="`Gallery image ${index}`"
            >
          </li>
        </ul>
      </div>
    </div>
  </section>

  <section
    class="testimonials"
    v-if="isVisible('testimonials')"
  >
    <div class="container">
      <h2 class="testimonials__title">
        {{ translate(testimonials.title) }}
      </h2>

      <app-glide-slides
        class="testimonials__slides"
        v-if="testimonials.testimonials?.length"
        :options="testimonials_options"
        :slides="testimonials.testimonials"
      >
        <template #default="{ slide }">
          <article class="testimonials__slide">
            <div
              class="slide__text editor editor__content"
              v-html="translate(slide.text)"
            />

            <p class="slide__reference">
              {{ translate(slide.reference) }}
            </p>
          </article>
        </template>

        <template #prev>
          <fa-icon
            class="element"
            size="lg"
            :icon="['fal', 'arrow-left']"
          />
        </template>

        <template #next>
          <fa-icon
            class="element"
            size="lg"
            :icon="['fal', 'arrow-right']"
          />
        </template>
      </app-glide-slides>

      <div
        class="testimonials__action"
        v-if="translate(testimonials.button?.text)"
      >
        <app-link
          :link="testimonials.button"
          class="btn__color--black btn__size--classic testimonials__button"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'
import useSection from '@/composables/modules/pages/useSection'

import AppGlideSlides from '&/organisms/AppGlideSlides'
import MapBlindenzorglichtliefde from '&/modules/pages/blindenzorglichtliefde/MapBlindenzorglichtliefde'
const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))

const props = defineProps({
  page: { type: Object, required: true },
  sections: { type: Object, required: true }
})

const { translate } = useI18n()
const { getSectionAttributes, isVisible } = useSection(props.page)

const testimonials_options = {
  perView: 3,
  gap: 30,
  rewind: false,
  type: 'slider',
  bound: true,
  breakpoints: {
    1200: {
      perView: 2
    },
    850: {
      perView: 1
    }
  }
}

const banner = computed(() => getSectionAttributes('banner'))
const informations = computed(() => getSectionAttributes('informations'))
const rooms = computed(() => getSectionAttributes('rooms'))
const timeline = computed(() => getSectionAttributes('timeline'))
const gallery = computed(() => getSectionAttributes('gallery'))
const testimonials = computed(() => getSectionAttributes('testimonials'))
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;
  padding-top: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  @include mq(md) {
    min-height: 360px;
  }

  &__content {
    padding: 0 50px 30px 0;
    text-align: right;
    color: $white;

    @include mq(md) {
      padding: 0 100px 75px 0;
    }
  }

  &__title {
    font-size: 40px;
    line-height: 1.3;

    @include mq(md) {
      font-size: 60px;
    }
  }

  &__subtitle {
    font-size: 20px;
    line-height: 1;

    @include mq(md) {
      font-size: 28px;
    }
  }
}

.informations {
  padding-top: 40px;
  background-color: $white;

  @include mq(md) {
    padding-top: 72px;
  }

  &__text {
    max-width: 750px;
    font-size: 16px;
    padding: 0 24px;

    @include mq(md) {
      font-size: 22px;
      padding: 0;
    }
  }

  &__article {
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    justify-content: center;
    align-items: center;

    @include mq(md) {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 65px;
      gap: 60px;
    }

    @include mq(xl) {
      grid-template-columns: repeat(2, 700px);
    }
  }

  &__action {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:deep(.informations__button) {
    margin: 40px auto;
    display: inline-block;
    border-radius: 16px;
    font-size: 18px;
    border: 1px solid transparent;
    transition: all .3s;

    @include mq(md) {
      margin: 72px auto;
      font-size: 21px;
    }

    &:hover {
      background-color: $white;
      border: 1px solid $black;
      color: $black;
    }
  }
}

.article {
  &__image {
    display: block;
    max-width: 470px;
    width: 100%;
    justify-self: center;
  }

  &__quote {
    background-color: $black;
    color: $white;
    padding: 50px;

    @include mq(md) {
      padding: 72px;
    }
  }
}

.quote {
  &__text {
    font-size: 24px;

    @include mq(md) {
      font-size: 38px;
    }
  }

  &__author {
    text-align: right;
    margin-top: 30px;
    font-family: $font-family--secondary;
    font-size: 14px;

    @include mq(md) {
      font-size: 16px;
    }
  }
}

.rooms {
  background-color: $white;
  padding-top: 40px;

  @include mq(md) {
    padding-top: 72px;
  }

  &__head {
    @include mq(md) {
      display: flex;
     justify-content: center;
    }
  }

  &__title {
    font-size: 24px;
    font-family: $font-family--secondary;
    line-height: 1.4;

    @include mq(md) {
      max-width: 190px;
      font-size: 30px;
      margin-right: 75px;
    }
  }

  &__text {
    max-width: 500px;
    font-size: 16px;
    padding: 30px 0 0 0;
    position: relative;

    @include mq(md) {
      padding: 50px 30px 0 30px;
      font-size: 23px;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 100%;
        background-color: $black;
      }
    }
  }
}

.timeline {
  background-color: $white;
  padding-top: 75px;

  @include mq(md) {
    padding-top: 150px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 40px;
    align-items: flex-start;

    @include mq(md) {
      grid-template-columns: 200px auto;
      gap: 60px;
    }

    @include mq(lg) {
      grid-template-columns: 400px auto;
    }
  }

  &__title {
    font-size: 24px;
    font-family: $font-family--secondary;
    line-height: 1.4;
    position: relative;

    @include mq(md) {
      padding-bottom: 200px;
      padding-right: 75px;
      max-width: min-content;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 1px;
        height: 100%;
        background-color: $black;
      }
    }
  }

  &__ol {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    width: 100%;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 100%;
      background-color: $black;
    }

    @include mq(md) {
      width: 690px;

      &:after {
        left: 50%;
      }
    }
  }

  &__card {
    padding: 28px 22px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0,0,0, 0.5);
    max-width: 260px;

    &:before {
      content: '';
      position: absolute;
      top: 40px;
      left: 0;
      width: 40px;
      height: 1px;
      background-color: $black;
    }

    &:after {
      content: '';
      position: absolute;
      top: 30px;
      left: 40px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid $black
    }

    @include mq(md) {
      padding: 42px 29px;

      &:before {
        left: 50%;
        top: 50%;
      }

      &:after {
        left: calc(50% + 40px);
        top: calc(50% - 10px);
      }
    }
  }

  &__li {
    position: relative;
    padding-top: 80px;
    padding-bottom: 12px;
    padding-left: 24px;
    margin-right: auto;

    @include mq(md) {
      padding: 0;
    }

    .event__date {
      left: 80px;
    }
  }

  &__li:nth-child(odd) {
    @include mq(md) {
      padding-right: calc(100% - 260px);

      .event__date {
        left: calc(50% + 140px);
      }
    }
  }

  &__li:nth-child(even) {
    @include mq(md) {
      padding-left: calc(100% - 260px);

      .event__date {
        right: calc(50% + 140px);
      }

      .timeline__card {
        &:before {
          left: calc(50% - 40px);
        }

        &:after {
          left: calc(50% - 60px);
        }
      }
    }
  }
}

.event {
  &__title {
    font-size: 20px;
    font-family: $font-family--secondary;
  }

  &__text {
    margin-top: 20px;
  }

  &__date {
    position: absolute;
    top: 27px;
    font-family: $font-family--secondary;
    display: block;
    text-align: center;

    @include mq(md) {
      top: 50%;
      transform: translateY(-50%);
      max-width: min-content;
    }
  }
}

.gallery {
  background-color: $white;
  padding-top: 75px;

  @include mq(md) {
    padding-top: 150px;
  }

  &__title {
    font-size: 24px;
    font-family: $font-family--secondary;
    line-height: 1.4;
    position: relative;

    @include mq(md) {
      padding-bottom: 200px;
      padding-left: 29px;
     padding-right: 50px;
     font-size: 30px;
     max-width: min-content;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 100%;
        background-color: $black;
      }
    }
  }

  &__ul {
    display: flex;
    flex-direction: column;
  }

  &--start {
    align-items: flex-start;
    order: 0;

    @include mq(md) {
      order: 1;
    }
  }

  &--end {
    align-items: flex-start;
    order: 1;

    @include mq(md) {
      align-items: flex-end;
      order: 0;
    }
  }

  &--selfend {
    align-self: flex-start;
    margin-bottom: 24px;

    @include mq(md) {
      align-self: flex-end;
      margin-bottom: 50px;
    }
  }

  &__li:not(:last-child) {
    padding-bottom: 15px;

    @include mq(md) {
      padding-bottom: 30px;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
    justify-content: center;

    @include mq(md) {
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
    }
  }

  &__img {
    max-width: 100%;
    max-height: 800px;
    display: block;
  }
}

.testimonials {
  background-color: $white;
  padding-top: 75px;
  padding-bottom: 40px;

  @include mq(md) {
    padding-top: 150px;
    padding-bottom: 80px;
  }

  &__title {
    font-size: 24px;
    font-family: $font-family--secondary;
    line-height: 1.4;
    position: relative;
    margin-bottom: 24px;

    @include mq(md) {
      font-size: 30px;
      margin-bottom: 30px;
    }
  }

  &__slide {
    background-color: rgba(#B9B9B9, 7%);
    align-self: stretch;
    padding: 24px 24px 80px 24px;
    position: relative;

    @include mq(md) {
      padding: 30px 30px 100px 30px;
    }
  }

  &__action {
    display: flex;
    justify-content: center;
  }

  &:deep(.testimonials__button) {
    border-radius: 16px;
    border: 1px solid transparent;
    transition: all .3s;
    margin-top: 48px;

    &:hover {
      background-color: transparent;
      border: 1px solid $black;
      color: $black;
    }
  }
}

.slide {
  &__text {
    font-size: 18px;

    @include mq(md) {
      font-size: 24px;
    }
  }

  &__reference {
    position: absolute;
    bottom: 30px;
    right: 30px;
  }
}
</style>