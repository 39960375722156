<template>
  <form
    class="search"
    @submit.prevent="searchSubmit()"
  >
    <input-text
      :placeholder="placeholder || $i18n.t('literal.search_for_a_project')"
      class="search__input"
      v-model="search"
    />

    <button
      class="search__btn"
      type="submit"
      name="search-btn"
    >
      <fa-icon
        class="search__icon"
        :icon="['fal', 'search']"
        fixed-width
      />
    </button>
  </form>
</template>

<script>
import { defineComponent } from 'vue'
import InputText from '&/atoms/InputText'
import { useTracking } from '@/vendors/tracking'

export default defineComponent({
  name: 'SearchField',
  emits: ['input', 'submit', 'searchQuery', 'update:modelValue'],
  data () {
    return {
      search_value: null,
      last_search: null,
      delay_timer: 0,
      query: {
        search: this.$route.query.search || null
      }
    }
  },
  components: {
    InputText
  },
  props: {
    placeholder: {
      type: String
    },
    modelValue: {
      type: String,
      default: ''
    }
  },
  setup: () => ({ tracking: useTracking() }),
  computed: {
    search: {
      get () {
        return this.modelValue || this.query.search || this.search_value
      },
      set (value) {
        if (value !== this.last_search) this.last_search = value
        this.search_value = value || null
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    searchSubmit () {
      clearTimeout(this.delay_timer)
      this.$emit('submit', this.search_value)
      this.onChange()
    },
    onChange () {
      if (this.search_value) this.tracking.action.search({ query: this.search_value })

      this.$emit('searchQuery', this.search_value)
    }
  },
  watch: {
    search_value () {
      clearTimeout(this.delay_timer)
      this.delay_timer = setTimeout(() => {
        this.onChange()
      }, 1000)
    }
  }
})
</script>

<style scoped lang="scss">
.search {
  width: 100%;
  background-color: $dw;
  padding: calc($padding--base / 4) calc($padding--base / 2);
  justify-content: flex-start;
  align-items: stretch;
  border-radius: 5px;
  display: flex;

  & :deep() {
    .search__input {
      flex-grow: 1;
      background: transparent;

      &::placeholder {
        font-size: em(18px);
        color: $mg;
      }
    }
  }

  &__btn {
    padding: 0 $padding--base;
    color: $mg;
    transition: all .3s;

    &:hover {
      color: $color--primary;
    }
  }
}
</style>
