import { defineStore } from 'pinia'

export const useStravaStore = defineStore('StravaStore', {
  state: () => ({
    loader: false,
    error: {}
  }),
  actions: {
    fetchExchangeCode ({ query }) {
      return this.$fetchItem({
        endpoint: '/api/v1/strava/exchange_code',
        query
      })
    },
    postDeauthorizeUser () {
      return this.$postItem({
        endpoint: '/api/v1/strava/deauthorize_user'
      })
    }
  }
})