export default [
  {
    name: 'custom_footer_iles_de_paix',
    path: 'modules/pages/ilesdepaix/FooterIlesdepaix'
  },
  {
    name: 'custom_header_iles_de_paix',
    path: 'modules/pages/ilesdepaix/HeaderIlesdepaix'
  },
  {
    name: 'custom_homepage_iles_de_paix',
    path: 'modules/pages/ilesdepaix/HomepageIlesdepaix'
  }
]
