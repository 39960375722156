<template>
  <footer class="footer">
    <div class="container">
      <div class="columns">
        <div class="column">
          <h2 class="footer__title">
            {{ translate(column_1.title) }}
          </h2>

          <div
            v-if="translate(column_1.text)"
            class="editor editor__content"
            v-html="translate(column_1.text)"
          />
        </div>

        <div class="column">
          <app-link
            :link="column_2.title"
            class="footer__title footer__link"
            v-if="column_2.title && column_2.title.show"
          />

          <div
            v-if="translate(column_2.text)"
            class="editor editor__content"
            v-html="translate(column_2.text)"
          />
        </div>

        <div class="column">
          <h2 class="footer__title">
            {{ translate(column_3.title) }}
          </h2>

          <div
            v-if="translate(column_3.text)"
            class="editor editor__content"
            v-html="translate(column_3.text)"
          />
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { computed } from 'vue'
import { get as _get } from 'lodash'
import { useI18n } from '@/vendors/i18n'

import AppLink from '&/atoms/AppLink'

const props = defineProps({
  section: { type: Object, required: true }
})

const { translate } = useI18n()

const column_1 = computed(() => _get(props, 'section.attributes.column_1', {}))
const column_2 = computed(() => _get(props, 'section.attributes.column_2', {}))
const column_3 = computed(() => _get(props, 'section.attributes.column_3', {}))
</script>

<style lang="scss" scoped>
.footer {
  background-color: $white;
  padding: 80px 0;

  &:deep(.footer__title) {
    font-size: 24px;
    font-family: $font-family--secondary;
    color: $color--primary;
    margin-bottom: 20px;
    position: relative;

    @include mq(md) {
      font-size: 30px;
      margin-bottom: 28px;
    }
  }

  &:deep(.footer__link) {
    border-bottom: 2px solid $color--primary;
    transition: all .3s;

    &:hover {
      color: shade($color--primary, .1, 47%);
      border-bottom: 2px solid shade($color--primary, .1, 47%);
    }
  }
}

:deep(.editor__content) {
  a {
    transition: all .3s;

    &:hover {
      color: $color--primary;
    }
  }
}

.columns {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 48px;

  @include mq(md) {
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
