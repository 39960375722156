<template>
  <section v-if="procedure">
    <article class="card">
      <div class="card__padding">
        <h3 class="title__h3 bold">
          {{ t('project.settings_goal_title') }}
        </h3>

        <form-notification
          class="margin__t--12"
          :keys="error"
        />

        <fieldset class="margin__t--18">
          <label
            for="goal"
            class="input__label input__label--small"
          >
            <template v-if="project.goal_amount_per_member">
              {{ t('literal.basic_goal') }}
            </template>

            <template v-else>
              {{ t('literal.goal') }}
            </template>
          </label>

          <input-amount
            id="goal"
            class="margin__t--6"
            v-model="goal"
            currency="EUR"
          />
        </fieldset>

        <p
          v-if="project.goal_amount_per_member"
          class="margin__t--12"
          v-html="tc('project_settings_goal.dynamic_goal_explanation', project.members_count, { goal_amount: `<span class='bold'>${goalAmount}</span>`, members_count: `<span class='bold'>${project.members_count}</span>`, goal_amount_per_member: `<span class='bold'>${goalAmountPerMember}</span>` })"
        />

        <app-save-button
          class="margin__t--24"
          name="save_goal_amount"
          :is-loading="loader"
          :is-disabled="loader"
          :is-saved="!isUnsaved"
          @click="handleClickSave()"
        />
      </div>
    </article>

    <modal-confirm
      v-if="show_unsaved_modal"
      @close="cancelLeavingPage()"
      @confirm="leavePage()"
    >
      <template #confirm-content>
        <div class="align--center">
          <p>{{ t('literal.leave_page_without_saving') }}</p>
        </div>
      </template>
    </modal-confirm>
  </section>
</template>

<script setup>
import { computed, toRefs, defineAsyncComponent } from 'vue'
import { useProjectStore } from '@/stores/ProjectStore'
import { useProcedureStore } from '@/stores/ProcedureStore'
import { useNotificationStore } from '@/stores/NotificationStore'
import { useUnsaved } from '@/composables/app/useUnsaved'
import { useI18n } from '@/vendors/i18n'
import { useHead } from '@unhead/vue'
import { useMoney } from '@/composables/app/useMoney'
import { set as _set } from 'lodash'

import InputAmount from '&/atoms/InputAmount'
import AppSaveButton from '&/molecules/AppSaveButton'

const FormNotification = defineAsyncComponent(() => import('&/molecules/FormNotification'))
const ModalConfirm = defineAsyncComponent(() => import('&/organisms/ModalConfirm'))

const props = defineProps({ projectSlug: { type: String, required: true } })

const { projectSlug } = toRefs(props)

const { t, tc } = useI18n()
const { getAmount } = useMoney()
const { getProject, fetchProject, $resetStore } = useProjectStore()
const { getProcedure, patchProcedure } = useProcedureStore()
const { postSideNotification } = useNotificationStore()

const { project } = getProject({ id: projectSlug.value })
const { procedure, clonedProcedure, loader, error } = getProcedure({ id: project.value?.procedures.donation?.id, dependencies: [project] })

useHead({ title: computed(() => `${t('literal.goal')} / ${t('literal.settings')} / ${project.value?.name}`) })
const { show_unsaved_modal, isUnsaved, cancelLeavingPage, leavePage } = useUnsaved({ condition: computed(() => JSON.stringify(procedure.value) !== JSON.stringify(clonedProcedure.value)) })

const goalAmount = computed(() => getAmount({ amount: procedure.value.goal_amount }))
const goalAmountPerMember = computed(() => getAmount({ amount: project.value.goal_amount_per_member }))

const goal = computed({
  get: () => clonedProcedure.value?.fixed_goal_amount || 0,
  set: goal => _set(clonedProcedure.value, 'fixed_goal_amount', goal)
})

const handleClickSave = () => {
  patchProcedure({ id: procedure.value.id, params: { fixed_goal_amount: goal.value } })
    .then(() => {
      $resetStore(['projects'])

      fetchProject({ id: project.value.slug })
      postSideNotification({ text: 'literal.modifications_have_been_saved' })
    })
}
</script>
