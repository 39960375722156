import { computed, toValue } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useClientStore } from '@/stores/ClientStore'
import { useClientColors } from '@/composables/app/useClientColors'
import { get as _get } from 'lodash'

export function useProcedureStyle ({ procedure }) {
  const { translate } = useI18n()
  const { getClient } = useClientStore()

  const { client } = getClient()

  const logo = computed(() => translate(_get(toValue(procedure), 'style.logo', {})) || _get(client.value, 'logo.medium.url') || _get(client.value, 'logo.full.url'))

  const style = computed(() => {
    const { background_type, background_image, background_color } = _get(toValue(procedure), 'style', {})
    const { backgroundDonationFormColor } = useClientColors()

    if (background_type === 'image' && translate(background_image)) return { backgroundImage: `url(${translate(background_image)})` }
    if (background_type === 'color' && background_color) return { backgroundColor: background_color }

    return { backgroundColor: toValue(backgroundDonationFormColor) }
  })

  return { logo, style }
}