<template>
  <div>
    <label
      class="input__label"
      :for="id"
    >
      {{ translate(label) }} <span v-if="block.is_required">*</span>
    </label>

    <input-text
      class="margin__t--6"
      v-if="!isTextarea"
      v-model="model"
      :v="validator"
      :id="id"
      :autocomplete="autocomplete"
      :placeholder="translate(placeholder)"
    />

    <input-textarea
      class="margin__t--6"
      v-else
      v-model="model"
      :id="id"
      :v="validator"
      :placeholder="translate(placeholder)"
    />
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, inject, toRefs } from 'vue'
import { useModule } from '@/composables/app/useModule'
import { useProcedure, useProcedureBlockValidator } from '@/composables/modules/procedures'
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'

const InputText = defineAsyncComponent(() => import('&/atoms/InputText'))
const InputTextarea = defineAsyncComponent(() => import('&/atoms/InputTextarea'))

const props = defineProps({ block: { type: Object, required: true } })

const { block } = toRefs(props)

const procedure = inject('procedure')
const procedure_execution = inject('procedure_execution')
const external_validations = inject('external_validations')

const validations = computed(() => ({ ...block.value.validations, external: _get(external_validations.value, block.value.model, {}) }))

const { translate } = useI18n()
const { getDefaultValue } = useProcedure({ procedure })
const { label, placeholder, model, id, autocomplete, options, defaultValue } = useModule({ component: block, parent: procedure_execution, default_value: getDefaultValue(block.value.default_value) })
const { validator } = useProcedureBlockValidator({ validations, state: model })

const isTextarea = computed(() => _get(options.value, 'is_textarea', false))

if (!model.value && defaultValue.value) model.value = defaultValue.value
</script>
