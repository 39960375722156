import Address from '@/classes/addresses/Address'
import Transactions from '@/classes/transactions/Transactions'

export default class User {
  constructor ({ id = null, firstname = null, lastname = null, email = null, gender = null, avatar = null, language = null, birthday = null, role = null, is_super_admin = false, phone_number = null, address = {}, notifications = {}, transactions, integrations = {}, sporting_statistics = {}, is_verified = false } = {}) {
    this.id = id
    this.firstname = firstname
    this.lastname = lastname
    this.email = email
    this.gender = gender
    this.avatar = avatar
    this.language = language
    this.birthday = birthday
    this.role = role
    this.is_super_admin = is_super_admin
    this.phone_number = phone_number
    this.address = new Address(address)
    this.notifications = notifications
    this.transactions = new Transactions(transactions)
    this.integrations = integrations
    this.sporting_statistics = sporting_statistics
    this.is_verified = is_verified
  }

  get fullname () {
    return [this.firstname, this.lastname].join(' ')
  }

  get public_name () {
    return [this.firstname, this.lastname ? this.lastname.charAt(0) + '.' : null].filter(name => name).join(' ')
  }

  get is_koalect () {
    return this.role === 'koalect'
  }

  get is_admin () {
    return this.role === 'admin'
  }
}