import { inject } from 'vue'
import store from '@/store'
import MixpanelBrowser from 'mixpanel-browser'
import { get as _get } from 'lodash'

class Mixpanel {
  constructor () {
    this.mixpanel = MixpanelBrowser
  }

  init ({ token, config }) {
    this.mixpanel.init(token, config)

    const client = _get(store.getters, 'client/client', {})

    if (client) this.mixpanel.register_once({ 'Client Id': client.id, 'Client Name': client.name })
  }
}

const mixpanelSymbol = Symbol()
const mixpanel = new Mixpanel()

export const createMixpanel = {
  install: (app, { token, config = {} }) => {
    mixpanel.init({ token, config })

    app.provide(mixpanelSymbol, mixpanel.mixpanel)
  }
}

export function useMixpanel () {
  return inject(mixpanelSymbol)
}

export default mixpanel.mixpanel