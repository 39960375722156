<template>
  <input-checkbox
    v-model="model"
    :v="validator"
  >
    <span v-html="label" /> *
  </input-checkbox>

  <p
    class="additional-text"
    v-html="text"
    v-if="translate(text)"
  />
</template>

<script setup>
import { computed, inject, toRefs } from 'vue'
import { useClientStore } from '@/stores/ClientStore'
import { useModule } from '@/composables/app/useModule'
import { useProcedureBlockValidator } from '@/composables/modules/procedures'
import { useI18n } from '@/vendors/i18n'
import { get as _get, isUndefined as _isUndefined } from 'lodash'

import InputCheckbox from '&/atoms/InputCheckbox'

const props = defineProps({ block: { type: Object, required: true } })

const { block } = toRefs(props)

const procedure_execution = inject('procedure_execution')

const { translate, locale } = useI18n()
const { getClient } = useClientStore()
const { model, attributes, defaultValue } = useModule({ component: block, parent: procedure_execution, default_value: false })
const { validator } = useProcedureBlockValidator({ validations: block.value.validations, state: model })

const { client } = getClient()

const label = computed(() => interpolate(translate(_get(attributes.value, 'editor_content', {}))))
const text = computed(() => interpolate(translate(_get(attributes.value, 'text', {}))))

const interpolate = (content = '') => {
  content = content.replace(/<span id='terms_of_use'>([^>]*)<\/span>/g, `<a href="/${locale}/terms-of-use" class="link__color--prim" target="_blank">$1</a>`)
  content = content.replace(/<span id='privacy_policy'>([^>]*)<\/span>/g, `<a href="/${locale}/privacy-policy" class="link__color--prim" target="_blank">$1</a>`)
  content = content.replace(/<span class="bold" id="client">([^>]*)<\/span>/g, `<strong class="bold">${client.value.name}</strong>`)

  return content
}

if (typeof model.value !== 'boolean' && !_isUndefined(defaultValue.value)) model.value = defaultValue.value
</script>

<style lang="scss" scoped>
.additional-text {
  color: $lg;
  margin-top: 4px;
  font-size: em(12px);
  white-space: pre-line;
  margin-left: 28px;
}
</style>

