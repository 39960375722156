import { get as _get } from 'lodash'

export default ({ doc, selection, name }) => {
  const { from, to } = selection

  let node
  let pos

  doc.nodesBetween(from, to, (elm, position) => {
    if (_get(elm, 'type.name') === name) {
      node = elm
      pos = position

      return false
    }
  })

  return { node, pos }
}