<template>
  <div
    class="description"
    v-if="isShowed('description')"
  >
    <div
      class="editor"
      v-if="isAuthorized && is_editing"
    >
      <app-text-editor
        id="description"
        class="description__editor"
        :layout="layout"
        v-model="description"
      />

      <app-save-button
        class="margin__t--24"
        :is-loading="loader"
        :is-disabled="loader || fileLoader"
        @click="handleClickSave"
      />
    </div>

    <template v-else>
      <button
        class="btn btn__container btn__size--small margin__b--24 btn__edit"
        @click.prevent="handleClickEdit"
        :aria-label="t('literal.edit_project_description')"
        v-if="isAuthorized && !is_editing"
      >
        <fa-icon
          size="sm"
          :icon="['fal', 'edit']"
          fixed-width
          class="margin__r--6"
        />

        {{ t('literal.edit_description') }}
      </button>

      <article
        class="editor__content"
        :class="`editor__content--${layout}`"
        v-html="project.description"
      />
    </template>
  </div>
</template>

<script setup>
import { ref, computed, toRefs, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { useProjectStore } from '@/stores/ProjectStore'
import { useFileStore } from '@/stores/FileStore'
import { useI18n } from '@/vendors/i18n'
import { useBlock } from '@/composables/modules/templates'
import { get as _get } from 'lodash'

const AppTextEditor = defineAsyncComponent(() => import('&/organisms/AppTextEditor'))
const AppSaveButton = defineAsyncComponent(() => import('&/molecules/AppSaveButton'))

const props = defineProps({
  column: { type: Object, required: true },
  block: { type: Object, required: true }
})

const { column, block } = toRefs(props)

const route = useRoute()
const { t } = useI18n()
const { authUser } = storeToRefs(useAuthenticationStore())
const { getProject, patchProject } = useProjectStore()
const { objects, isShowed } = useBlock(block)
const { loader: fileLoader } = storeToRefs(useFileStore())

const is_editing = ref(false)

const { clonedProject, loader } = getProject({ id: route.params.projectSlug })

const layout = computed(() => column.value.is_small ? 'compact' : 'normal')
const project = computed(() => _get(objects.value, 'project'))
const isAuthorized = computed(() => project.value.isAuthorized(authUser.value))

const description = computed({
  get: () => clonedProject.value.description,
  set: description => clonedProject.value.description = description
})

const handleClickEdit = () => {
  description.value = project.value.description
  is_editing.value = true
}

const handleClickSave = () => {
  patchProject({ id: route.params.projectSlug, params: { description: description.value } })
    .then(() => is_editing.value = false)
}
</script>

<style lang="scss" scoped>

.description {
  position: relative;

  &__editor,
  &__content {
    position: relative;
    z-index: 0;
  }
}

.btn {
  &__edit {
    background: $white;
    padding: calc($padding__base / 4) $padding__base;
    font-size: em(14px);
    color: $dg;

    @include mq(md) {
      opacity: .6;
    }

    &:hover {
      opacity: 1;
    }
  }
}
</style>