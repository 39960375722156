import { ref, computed, onBeforeUnmount, onBeforeMount } from 'vue'
import { get as _get, merge as _merge, omit as _omit } from 'lodash'

const width = ref(window.innerWidth)

const handleResize = () => width.value = window.innerWidth

export function useBreakpoints (config = {}) {
  const breakpoints = { sm: 767, md: 992, lg: 1200, xl: 1400 }

  const availableBreakpoints = computed(() => Object.keys(breakpoints).filter(breakpoint => width.value >= breakpoints[breakpoint]))
  const style = computed(() => ({ ..._omit(config.value, Object.keys(breakpoints)), ...getResponsiveStyle(config.value) }))

  const getResponsiveStyle = config => {
    const style = {}

    availableBreakpoints.value.forEach(breakpoint => _merge(style, _get(config, breakpoint)))

    return style
  }

  onBeforeMount(() => window.addEventListener('resize', handleResize))
  onBeforeUnmount(() => window.removeEventListener('resize', handleResize))

  return {
    style,
    getResponsiveStyle
  }
}
