<template>
  <form
    class="form"
    :class="`form--${layout}`"
    v-if="is_active"
    @submit.prevent="handleSubmit"
  >
    <div class="form__wrapper padding__l--12 padding__r--6">
      <fa-icon
        class="form__icon"
        :icon="['fal', 'link']"
        fixed-width
      />

      <input
        class="form__input"
        type="text"
        v-model="link.text"
        placeholder="..."
        @keydown.esc="hide"
      >

      <input
        class="form__input"
        type="text"
        v-model="link.href"
        placeholder="https://"
        ref="input"
        @keydown.esc="hide"
      >

      <button
        class="link__color--success link--naked form__action"
        type="submit"
      >
        <fa-icon
          :icon="['fal', 'check']"
          fixed-width
        />
      </button>

      <button
        class="link__color--danger link--naked form__action"
        @click="handleClickUnset"
        type="button"
      >
        <fa-icon
          :icon="['fal', 'times']"
          fixed-width
        />
      </button>
    </div>

    <div
      class="padding__l--12 padding__r--6 padding__t--6"
      v-if="v$ && v$.$error"
    >
      <span
        class="input--error"
        v-if="v$.href.$invalid"
      >
        {{ t('errors.input_url_incorrect') }}
      </span>
    </div>

    <div class="form__settings">
      <fieldset class="radio__toggles">
        <input
          type="radio"
          class="radio__input"
          id="link_type_button"
          value="button"
          v-model="link.type"
        >

        <label
          class="radio__label"
          for="link_type_button"
        >
          {{ t('literal.button') }}
        </label>

        <input
          type="radio"
          class="radio__input"
          id="link_type_link"
          value="link"
          v-model="link.type"
        >
        <label
          class="radio__label"
          for="link_type_link"
        >
          {{ t('literal.link') }}
        </label>

        <div class="radio__slide" />
      </fieldset>

      <ul class="colors__list">
        <li
          :key="systemColor.name"
          v-for="systemColor in systemColors"
          class="colors__li"
        >
          <input
            v-model="link.color"
            type="radio"
            :id="systemColor.name"
            :value="systemColor.hex"
            class="colors__input"
          >

          <label
            :for="systemColor.name"
            :style="{ backgroundColor: systemColor.hex }"
            :class="`colors__label colors__label--${systemColor.name}`"
          />
        </li>
      </ul>
    </div>
  </form>
</template>

<script setup>
import { ref, reactive, computed, nextTick } from 'vue'
import { useClientColors } from '@/composables/app/useClientColors'
import { useClientStore } from '@/stores/ClientStore'
import { useI18n } from '@/vendors/i18n'
import { useVuelidate } from '@vuelidate/core'
import { url, or } from '@vuelidate/validators'
import { validURISchema, validTemplatingVariable } from '@/utils/validators'

const emit = defineEmits(['show', 'hide'])

const { t } = useI18n()
const { systemColors, defaultColor, getBtnTextColor } = useClientColors()

const props = defineProps({
  editor: { type: Object, required: true },
  layout: { type: String, default: 'normal' }
})

const { getClient } = useClientStore()
const { client } = getClient()

const rules = computed(() => link && link.href ? { href: { valid: or(url, validURISchema, validTemplatingVariable) } } : {})

const input = ref(null)

const is_active = ref(false)
const link = reactive({ text: null, href: null, type: null, color: null })

const v$ = useVuelidate(rules, link)

const toggle = attrs => attrs && (!is_active.value || !attrs.href || attrs.href !== link.href) ? show(attrs) : hide()
const isDomainMatch = url => Object.keys(client.value.domain_names).some(domain => url.includes(domain))

const show = (attrs = {}) => {
  let { type = 'link', href, textColor, bgColor, text } = attrs
  let color = type === 'button' ? bgColor : textColor

  if (!text) {
    const { state } = props.editor
    const { selection } = state
    const { from, to } = selection
    const content = state.doc.textBetween(from, to, ' ')

    if (content) text = content
  }

  Object.assign(link, { text, type, href, color: color && systemColors.value.find(c => c.hex === color) ? color : defaultColor.value })

  is_active.value = true

  emit('show', { padding: 139 })

  nextTick(() => {
    if (input.value) input.value.focus()
  })
}

const hide = () => {
  if (!is_active.value) return

  Object.assign(link, { text: null, type: null, href: null, color: null })

  is_active.value = false

  v$.value.$reset()

  emit('hide')
}

const handleSubmit = () => {
  const { text, href, type, color } = link

  v$.value.$touch()

  if (v$.value && v$.value.$error) return

  const target = href && isDomainMatch(href) ? '_self' : '_blank'
  const textColor = type === 'button' ? getBtnTextColor(color) : color
  const bgColor = type === 'button' ? color : null

  props.editor.commands.setLink({ type, text, href, textColor, bgColor, target })

  hide()
}

const handleClickUnset = () => {
  props.editor.commands.unsetLink({ text: link.text })

  hide()
}

defineExpose({ show, hide, toggle })
</script>

<style lang="scss" scoped>
.form {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: $padding__base;
  background: $dw;
  transform: translateY(100%);

  &__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: $white;
    border-radius: $radius__input;
  }

  &__input {
    display: block;
    flex-grow: 1;
    outline: none;
    padding: 0 calc($padding__base / 2);
    height: 48px;
    border: 0;
    width: 100%;

    .form:not(.form--compact) & {
      @include mq(sm) {
        width: inherit;
      }
    }

    & + .menubar__input {
      border-left: 6px solid $vdw;
    }
  }

  &__action {
    padding: calc($padding__base / 2);

    .form:not(.form--compact) & {
      @include mq(md) {
        padding: $padding__base;
      }
    }
  }

  &__settings {
    margin-top: calc($margin__base / 1.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .form:not(.form--compact) & {
      @include mq(sm) {
        flex-direction: row;
      }
    }
  }
}

.colors {
  &__list {
    display: flex;
    justify-content: center;
    margin-top: calc($margin__base / 1.5);

    .form:not(.form--compact) & {
      @include mq(sm) {
        margin-top: 0;
      }
    }
  }

  &__li {
    &:not(:last-child) {
      margin-right: calc($margin__base / 1.5);

      .form:not(.form--compact) & {
        @include mq(sm) {
          margin-right: $margin__base;
        }

        @include mq(md) {
          margin-right: calc($margin__base / 2);
        }

        @include mq(lg) {
          margin-right: $margin__base;
        }
      }
    }
  }

  &__label {
    display: block;
    position: relative;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 2px solid transparent;
    cursor: pointer;
    transition: all $transition__duration;

    .form:not(.form--compact) & {
      @include mq(sm) {
        width: 24px;
        height: 24px;
      }

      @include mq(md) {
        width: 20px;
        height: 20px;
      }

      @include mq(lg) {
        width: 24px;
        height: 24px;
      }
    }

    &--white-color {
      border-color: rgba($dg, 0.1);
    }
  }

  &__input {
    position: absolute;
    opacity: 0;

    &:checked + label {
      border-color: rgba($dg, 0.5);
    }
  }
}

.radio {
  &__toggles {
    background-color: $white;
    border-radius: $radius__input;
    border: 1px solid $vdw;
    display: inline-block;
    position: relative;
  }

  &__label {
    position: relative;
    cursor: pointer;
    display: inline-block;
    width: 75px;
    padding: 4px calc($padding__base / 2);
    z-index: 2;
    text-transform: uppercase;
    text-align: center;
    font-size: rem(14px);
    white-space: nowrap;
    color: $lg;
    transition: all .3s ease-out;

      .form:not(.form--compact) & {
      @include mq(sm) {
        padding: 4px $padding__base;
        width: 100px;
      }

      @include mq(md) {
        padding: 4px calc($padding__base / 2);
        width: 75px;
      }

      @include mq(lg) {
        padding: 4px $padding__base;
        width: 100px;
      }
    }
  }

  &__input {
    position: absolute;
    opacity: 0;

    &:checked + .radio__label {
      color: $white;
    }

    &:checked ~ .radio__slide {
      border-radius: $radius__input;
      position: absolute;
      top: 2px;
      left: 2px;
      width: calc(50% - 4px);
      height: calc(100% - 4px);
      background-color: $color--primary;
      z-index: 1;
      transition: all .3s ease-out;
    }

    @for $i from 1 through 2 {
      &:nth-of-type(#{$i}):checked ~ .radio__slide {
        left: calc((50% * (#{$i - 1})) + 2px);
      }
    }
  }
}
</style>
