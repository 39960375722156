<template>
  <div>
    <header
      class="header"
      :class="{'header--fixed': mobileMenuOpened}"
      v-if="section"
    >
      <div class="header__row">
        <div class="wrapper">
          <ul class="header__sessions">
            <li
              class="header__sessions-item"
              v-if="!isAuthenticated"
            >
              <button
                @click.prevent="openSessionAppModal('register')"
                class="header__sessions-link"
              >
                {{ $i18n.t('literal.register') }}
              </button>
            </li>

            <li v-if="isAuthenticated && user">
              <router-link
                class="header__sessions-user d-flex align--center"
                :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
              >
                <user-profile-img
                  :avatar="user.avatar"
                  :size="2"
                  class="margin__r--6"
                />

                {{ user.firstname }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>

      <div class="header__row padding__tb--18">
        <div class="wrapper header__container">
          <div class="header__cell">
            <router-link
              :to="{ name: 'Home', params: { locale: $i18n.locale } }"
              v-show="client.logo.url"
              class="logo"
            >
              <img
                class="logo__img"
                :src="client.logo.url"
                :alt="client.name"
              >
            </router-link>

            <ul
              class="header__menu"
              v-if="links.length > 0"
            >
              <li
                class="header__menu-item"
                v-for="({ link }, index) in links"
                :key="`menu_item_${index}`"
              >
                <button
                  class="header__link bold"
                  :class="{'header__link--active': isVisited(link)}"
                  @click.prevent="setRouteNavigation(link)"
                >
                  {{ $i18n.translate(link.text) }}
                </button>
              </li>
            </ul>
          </div>


          <div class="header__cell">
            <ul
              class="header__actions"
              v-if="actions.length > 0"
            >
              <template
                v-for="({ link }, index) in actions"
              >
                <li
                  class="header__actions-item"
                  v-if="link.show"
                  :key="`cta_${index}`"
                >
                  <button
                    class="header__button bold"
                    v-if="link"
                    :disabled="!isActive(link)"
                    @click.prevent="setRouteNavigation(link)"
                  >
                    {{ $i18n.translate(link.text) }}
                  </button>
                </li>
              </template>
            </ul>

            <app-burger-menu
              class="header__burger"
              :state="mobileMenuOpened"
              @toggle="toggleMobileMenu"
            />
          </div>
        </div>
      </div>


      <transition name="fade-mobile-menu">
        <section
          class="header__cell mobile__menu"
          v-if="section && mobileMenuOpened"
        >
          <search-field
            class="mobile__search"
            v-if="section.attributes.search_bar"
          />

          <ul class="mobile__sessions">
            <li
              class="mobile__sessions-item header__sessions-item"
              v-if="!isAuthenticated"
            >
              <a
                @click.prevent="openSessionAppModal('login')"
                class="link__color--black link--naked pointer"
              >
                <fa-icon
                  class="margin__r--6 color--prim"
                  :icon="['fal', 'sign-in']"
                  fixed-width
                />
                {{ $i18n.t('literal.login') }}
              </a>
            </li>

            <li
              class="mobile__sessions-item header__sessions-item"
              v-if="!isAuthenticated"
            >
              <a
                @click.prevent="openSessionAppModal('register')"
                class="link__color--black link--naked pointer"
              >
                <fa-icon
                  class="margin__r--6 color--prim"
                  :icon="['fal', 'user-plus']"
                  fixed-width
                />
                {{ $i18n.t('literal.register') }}
              </a>
            </li>

            <li
              class="mobile__sessions-item header__sessions-item pointer"
              v-if="isAuthenticated && user"
            >
              <router-link
                :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
                @click.capture="mobileMenuOpened = false"
              >
                <user-profile-img
                  size="3"
                  :avatar="user.avatar"
                />
              </router-link>
            </li>

            <li
              class="mobile__sessions-item header__sessions-item pointer"
              v-if="isAuthenticated && user"
            >
              <router-link :to="{ name: 'Logout' }">
                <fa-icon
                  class="margin__r--6 color--danger"
                  :icon="['fal', 'sign-out']"
                  fixed-width
                />
                {{ $i18n.t('literal.logout') }}
              </router-link>
            </li>
          </ul>

          <ul class="mobile__list">
            <li
              class="mobile__list-item"
              v-for="({ link }, index) in links"
              :key="`mobile_menu_item_${index}`"
            >
              <a
                class="mobile__list-link"
                :class="{'mobile__link--active': isVisited(link)}"
                @click.prevent="setRouteNavigation(link); mobileMenuOpened = false"
              >
                {{ $i18n.translate(link.text) }}
              </a>
            </li>
          </ul>

          <ul
            class="mobile__actions"
            v-if="actions.length > 0"
          >
            <li
              class="mobile__actions-item"
              v-for="({ link }, index) in actions"
              :key="`cta_${index}`"
            >
              <button
                class=" bold"
                v-if="link"
                :disabled="!isActive(link)"
                @click.prevent="setRouteNavigation(link); mobileMenuOpened = false"
              >
                {{ $i18n.translate(link.text) }}
              </button>
            </li>
          </ul>
        </section>
      </transition>
    </header>

    <app-modal
      v-if="showSessionAppModal"
      size="sm"
      @close="showSessionAppModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="template"
          @authenticated="showSessionAppModal = false"
        />
      </template>
    </app-modal>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
import { get as _get } from 'lodash'
import { mapGetters } from 'vuex'
import UserProfileImg from '&/atoms/UserProfileImg'
import PageRouteNavigation from '%/PageRouteNavigation'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'

export default defineComponent({
  name: 'CustomHeaderIlesDePaix',
  components: {
    UserProfileImg,
    AppBurgerMenu,
    SessionForms: defineAsyncComponent(() => import('&/modules/sessions/SessionForms')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      showSessionAppModal: false,
      mobileMenuOpened: false,
      template: null
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    isMultilingual () {
      return this.client.active_languages.length > 1
    },
    links () {
      return _get(this.section, 'attributes.links', [])
    },
    actions () {
      return _get(this.section, 'attributes.actions', [])
    }
  },
  methods: {
    openSessionAppModal (template) {
      this.template = template
      this.showSessionAppModal = true
    },
    toggleMobileMenu (value) {
      this.mobileMenuOpened = !value
    }
  }
})
</script>

<style lang="scss" scoped>
$breakpoint: 'md';
$color--primary: #efdddc;
$color--secondary: #b27d71;
$color--tertiary: #2c2c2b;

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;

  @include mq(md) {
    padding: 0 30px;
  }

  @include mq(1100px) {
    padding: 0 70px;
  }
}

.header {
  background-color: $color--primary;

  &__cell, &__menu, &__actions, &__sessions, &__language {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__cell--grow {
    flex-grow: 1;
  }

  &__row {
    &:first-child {
      background: $white;
      line-height: 40px;
      height: 40px;
    }
  }

  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__button {
    font-family: $font-family--secondary;
    border-radius: 50px;
    border: 1px solid transparent;
    font-size: rem(16px);
    padding: calc($padding__base / 2) $padding__base * 2;
    transition: all $transition__duration;
    display: inline-block;
    background-color: $color--secondary;
    color: $white;

    &:focus,
    &:hover {
      background-color: shade($color--secondary);
    }
  }

  &__menu {
    margin-right: $margin__base * 4;
    display: none;

    @include mq($breakpoint) {
      display: flex;
    }

    &-item {
      &:not(:first-child) {
        margin-left: $margin__base * 1.5;
      }
    }
  }

  &__link {
    position: relative;
    border: 1px solid transparent;
    color: $color--secondary;
    font-weight: bold;
    transition: all .3s;
    font-size: rem(20px);

    &:focus {
      outline: 0;
    }

    &:hover {
      color: $color--tertiary;
    }
  }

  &__actions {
    display: none;

    @include mq($breakpoint) {
      display: flex;
    }

    &-item {
      white-space: nowrap;

      &:not(:last-child) {
        margin-right: $margin__base;
      }
    }
  }

  &__sessions {
    display: none;

    @include mq($breakpoint) {
      display: flex;
      justify-content: flex-end;
      height: 40px;
    }

    &-item {
      padding: $padding__base;
      display: block;
    }

    &-link {
      color: $color--tertiary;
    }

    &-user {
      display: block;
    }
  }

  &__burger {
    display: inherit;

    @include mq($breakpoint) {
      display: none;
    }
  }
}

.mobile {
  &__nav {
    width: 100vw;
    height: 100vh;
    background: $white;
    position: fixed;
    z-index: 10;
    overflow: hidden;
  }

  &__link {
    &--active {
      font-weight: bold;

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: $color--primary;
      }
    }
  }

  &__menu {
    margin-top: $margin--base;
    border-top: 1px solid $color--tertiary;
    display: block;

    @include mq($breakpoint) {
      display: none;
    }
  }

  &__list {
    margin-top: $margin--base;

    &-link {
      display: inline-block;
      padding: $padding--base;
    }
  }

  &__actions {
    margin-top: $margin--base;
    display: block;

    &-item {
      text-align: center;
    }

    &-link {
      display: inline-block;
      padding: $padding--base $padding--base * 2;
    }
  }

  &__sessions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $margin--base;
    justify-items: center;
    align-items: center;
    border-bottom: 1px solid $color--tertiary;

    &-item {
      white-space: nowrap;
    }
  }
}

.logo {
  margin-right: $margin__base * 1.5;
  position: relative;
  top: -3px;

  &__img {
    transition: all .5s;
    display: block;
    width: 25px;
  }
}

.fade-mobile-menu-enter-active {
  animation: fadeTranslationY .5s ease-in-out;
}

.fade-mobile-menu-leave-active {
  animation: fadeTranslationY .5s ease-in-out reverse;
}

@keyframes fadeTranslationY {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 100%;
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}
</style>
