import { nextTick } from 'vue'
import { getAttributes } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'

export default ({ type, tag, toggle }) => {
  return new Plugin({
    key: new PluginKey('handleClickNode'),
    props: {
      handleClick: (view, pos, event) => {
        if (!tag || !toggle) return

        nextTick(() => {
          const attrs = getAttributes(view.state, type.name)
          const node = event.target?.closest(tag)

          node ? toggle(attrs) : toggle()
        })
      }
    }
  })
}