<template>
  <template-navigation
    :column="column"
    v-if="column"
  />
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'
import { useProjectStore } from '@/stores/ProjectStore'
import { useTemplateStore } from '@/stores/TemplateStore'
import { get as _get } from 'lodash'

const TemplateNavigation = defineAsyncComponent(() => import('&/modules/templates/TemplateNavigation'))

const route = useRoute()
const { getProject } = useProjectStore()
const { getTemplate } = useTemplateStore()
const { project } = getProject({ id: route.params.projectSlug })
const { template } = getTemplate({ id: computed(() => _get(project.value, 'template_id')) })

const column = computed(() => template.value?.getColumn('project_member_navigation'))
</script>
