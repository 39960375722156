<template>
  <div>
    <header
      class="header"
      :class="{ 'header--fixed': mobile_menu_opened }"
      v-if="section"
    >
      <div class="header__topbar">
        <ul class="header__sessions">
          <li class="header__sessions-item">
            <button
              class="sessions__login"
              type="button"
              @click.prevent="authAction"
            >
              <svg
                width="29"
                height="29"
              >
                <path
                  class="user"
                  d="M14.363.677c-7.722 0-14 6.278-14 14s6.278 14 14 14 14-6.278 14-14-6.278-14-14-14zm0 8.016a4.402 4.402 0 014.39 4.391 4.402 4.402 0 01-4.39 4.391 4.402 4.402 0 01-4.391-4.39 4.402 4.402 0 014.39-4.392zM6.7 23.166v-.649a3.39 3.39 0 013.389-3.39h8.547a3.39 3.39 0 013.39 3.39v.649a11.428 11.428 0 01-7.663 2.947c-2.948 0-5.63-1.12-7.663-2.947z"
                  fill="#000"
                  fill-rule="evenodd"
                />
              </svg>
            </button>
          </li>

          <li
            class="header__sessions-item"
            v-if="isMultilingual"
          >
            <language-switcher class="header__language" />
          </li>
        </ul>
      </div>

      <div class="container header__container">
        <section class="header__cell">
          <router-link
            class="logo__container"
            :to="{ name: 'Home', params: { locale: $i18n.locale } }"
            v-show="client.logo.url"
          >
            <img
              class="logo__img"
              :src="client.logo.url"
              :alt="client.name"
            >
          </router-link>
        </section>

        <section class="header__cell">
          <ul class="header__menu">
            <li
              class="header__menu-item"
              v-for="({ link }, index) in links"
              :key="`menu_item_${index}`"
            >
              <a
                class="header__link link__color--dg link--naked"
                :class="{ 'header__link--active': isVisited(link) }"
                @click.prevent="setRouteNavigation(link)"
              >
                {{ $i18n.translate(link.text) }}
              </a>
            </li>
          </ul>

          <ul class="header__actions">
            <template v-for="({ link }, index) in actions">
              <li
                class="header__actions-item"
                v-if="link.show"
                :key="`cta_${index}`"
              >
                <button
                  class="header__actions-link btn btn__size--classic btn__color--ter"
                  v-if="link"
                  :disabled="!isActive(link)"
                  @click.prevent="setRouteNavigation(link)"
                >
                  {{ $i18n.translate(link.text) }}
                </button>
              </li>
            </template>
          </ul>

          <button
            class="header__burger margin__r--24"
            type="button"
            @click.prevent="mobile_menu_opened = !mobile_menu_opened"
          >
            <fa-icon
              size="2x"
              :icon="['far', 'bars']"
            />
          </button>
        </section>
      </div>
    </header>

    <transition name="fade-mobile-menu">
      <section
        class="header__cell mobile__menu"
        v-if="section && mobile_menu_opened"
      >
        <button
          class="mobile__close"
          type="button"
          @click.prevent="mobile_menu_opened = !mobile_menu_opened"
        >
          <fa-icon
            size="2x"
            :icon="['fal', 'times']"
          />
        </button>

        <ul class="header__sessions">
          <li class="header__sessions-item pointer">
            <button
              class="sessions__login"
              type="button"
              @click.prevent="authAction"
            >
              <svg
                width="29"
                height="29"
              >
                <path
                  class="user"
                  d="M14.363.677c-7.722 0-14 6.278-14 14s6.278 14 14 14 14-6.278 14-14-6.278-14-14-14zm0 8.016a4.402 4.402 0 014.39 4.391 4.402 4.402 0 01-4.39 4.391 4.402 4.402 0 01-4.391-4.39 4.402 4.402 0 014.39-4.392zM6.7 23.166v-.649a3.39 3.39 0 013.389-3.39h8.547a3.39 3.39 0 013.39 3.39v.649a11.428 11.428 0 01-7.663 2.947c-2.948 0-5.63-1.12-7.663-2.947z"
                  fill="#000"
                  fill-rule="evenodd"
                />
              </svg>
            </button>
          </li>

          <li
            class="header__sessions-item pointer"
            v-if="isMultilingual"
          >
            <language-switcher class="header__language" />
          </li>
        </ul>

        <ul class="mobile__list">
          <li
            class="mobile__list-item"
            v-for="({ link }, index) in links"
            :key="`mobile_menu_item_${index}`"
          >
            <a
              class="mobile__list-link link__color--dg link--naked"
              :class="{ 'mobile__link--active': isVisited(link) }"
              @click.prevent="setRouteNavigation(link); mobile_menu_opened = false;"
            >
              {{ $i18n.translate(link.text) }}
            </a>
          </li>
        </ul>

        <ul class="mobile__actions">
          <li
            class="mobile__actions-item"
            v-for="({ link }, index) in actions"
            :key="`cta_${index}`"
          >
            <button
              class="mobile__actions-link btn btn__size--classic btn__color--ter"
              v-if="link"
              :disabled="!isActive(link)"
              @click.prevent="
                setRouteNavigation(link);
                mobile_menu_opened = false;
              "
            >
              {{ $i18n.translate(link.text) }}
            </button>
          </li>
        </ul>
      </section>
    </transition>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex';
import { get as _get } from 'lodash';
import PageRouteNavigation from '%/PageRouteNavigation';
import LanguageSwitcher from '&/atoms/LanguageSwitcher';

export default defineComponent({
  name: 'CustomHeader111111',
  components: {
    LanguageSwitcher
  },
  props: {
    section: {
      required: true,
      type: Object,
    },
  },
  data () {
    return {
      mobile_menu_opened: false,
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated',
    }),
    attributes () {
      return _get(this.section, 'attributes', {});
    },
    actions () {
      return _get(this.attributes, 'actions', []);
    },
    navigation () {
      return _get(this.attributes, 'navigation', []);
    },
    isMultilingual () {
      return this.client.active_languages.length > 1;
    },
    links () {
      return this.navigation.filter(({ link }) => link.text[this.$i18n.locale]);
    },
  },
  methods: {
    authAction () {
      if (this.isAuthenticated) {
        this.mobile_menu_opened = false;
        this.$router.push({ name: 'Profile' });
      } else {
        this.mobile_menu_opened = false;
        this.$router.push({ name: 'Register' });
      }
    }
  }
})
</script>

<style lang="scss" scoped>
$breakpoint: "md";

.container {
  padding: 0;

  @include mq(md) {
    padding-left: $padding--base;
    padding-right: $padding--base;
  }
}

.header {
  background-color: rgba($white, 0.95);
  box-shadow: 0 4px 8px 0 rgba(82, 97, 115, 0.1);

  @include mq(md) {
    margin-bottom: 32px;
  }

  &__cell,
  &__menu,
  &__actions,
  &__sessions,
  &__language {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__cell--grow {
    flex-grow: 1;
  }

  &--fixed {
    z-index: 1000;
    background-color: $white;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      z-index: 1;
      background-color: rgba($black, 0.8);
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mq(md) {
      padding: 0px 30px;
      position: relative;
      top: 32px;
      margin-top: -32px;
      align-items: flex-start;
    }
  }

  &__topbar {
    display: none;

    @include mq(md) {
      display: block;
      padding: 20px 30px 35px;
    }
  }

  &__menu {
    margin-right: $margin__base * 4;
    display: none;

    @include mq($breakpoint) {
      display: block;
    }

    &-item {
      display: inline;

      &:not(:first-child) {
        &:before {
          content: "";
          border-left: 2px solid $color--secondary;
          height: 40px;
          vertical-align: top;
          display: inline-block;
          padding-left: 25px;
        }

        margin-left: 30px;
      }
    }
  }

  &__link {
    position: relative;
    font-size: rem(22px);

    &:after {
      content: "";
      display: block;
      position: absolute;
      transition: width 0.3s;
      width: 0;
      height: 3px;
      bottom: -4px;
      left: 0;
      background: $color--secondary;
    }

    &:focus,
    &:hover {
      &:after {
        width: 100%;
        background: $color--secondary;
      }
    }

    &--active {
      position: relative;
      color: $color--primary;

      &:hover,
      &:focus {
        &:after {
          width: 100%;
          background: $color--primary;
        }
      }

      &:after {
        width: 100%;
        background: $color--primary;
      }
    }
  }

  &__actions {
    display: none;

    @include mq($breakpoint) {
      display: flex;
    }

    &-item {
      white-space: nowrap;

      &:not(:last-child) {
        margin-right: $margin__base;
      }
    }

    &-link {
      padding: calc($padding--base / 2) $padding--base;
      font-size: rem(20px);
    }
  }

  &__sessions {
    margin-top: $margin--base * 2;

    @include mq($breakpoint) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 0;
    }

    &-item:not(:last-child) {
      margin-right: $margin--base * 2;
    }
  }

  &__burger {
    display: inherit;

    @include mq($breakpoint) {
      display: none;
    }
  }

  &__language {
    border: 2px solid $color--secondary;
    font-weight: bold;

    & :deep() {
      .ls__dropdown-menu-icon {
        display: none;
      }

      .vs__actions {
        display: none;
      }

      .vs__dropdown-toggle {
        padding: 3px;
      }

      .vs__dropdown-menu {
        right: -2px;
        left: auto;
        margin: 1px 0 0 0;
        border: 2px solid $color--secondary;
      }

      .vs__dropdown-option {
        padding: 3px 6px;
      }
    }
  }
}

.logo {
  &__img {
    width: 150px;
    transition: all 0.5s;
    display: block;

    @include mq(md) {
      width: 215px;
    }

    &--hidden {
      visibility: hidden;
      opacity: 0%;
    }
  }
}

.sessions {
  &__login {
    display: flex;

    .user {
      transition: all 0.3s;
    }

    &:hover .user {
      fill: $color--primary;
    }
  }
}

.mobile {
  &__menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 260px;
    background-color: $white;
    display: block;
    z-index: 1200;
    padding: 0px 30px;

    @include mq($breakpoint) {
      display: none;
    }
  }

  &__close {
    position: absolute;
    left: -50px;
    padding: 15px;
    color: $white;
  }

  &__list {
    margin-top: $margin--base;

    &-link {
      display: inline-block;
      padding: $padding--base;
      color: $dg;
      border-radius: 6px;
      font-size: rem(22px);
      color: $color--secondary;
    }
  }

  &__link {
    &--active {
      color: $color--primary;

      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: $color--primary;
      }
    }
  }

  &__actions {
    margin-top: $margin--base;
    display: block;

    &-link {
      display: inline-block;
      padding: calc($padding--base / 2) $padding--base;
      font-size: rem(20px);
    }
  }
}

.fade-mobile-menu-enter-active {
  animation: fadeTranslationX 0.5s ease-in-out;
}

.fade-mobile-menu-leave-active {
  animation: fadeTranslationX 0.5s ease-in-out reverse;
}

@keyframes fadeTranslationX {
  from {
    transform: translateX(100px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 100%;
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}
</style>
