export default class Update {
  constructor ({ id = null, content = null, is_public = false, is_edit = false, notify_by_email = false, target_type = null, target_id = null, user_id = null, created_at = null, updated_at = null, emails_have_been_sent = null } = {}) {
    this.id = id
    this.content = content
    this.is_public = is_public
    this.is_edit = is_edit
    this.notify_by_email = notify_by_email
    this.target_type = target_type
    this.target_id = target_id
    this.user_id = user_id
    this.created_at = created_at
    this.updated_at = updated_at
    this.emails_have_been_sent = emails_have_been_sent
  }
}
