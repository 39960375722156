<template>
  <div>
    <header
      class="header"
      :class="{'header--fixed': mobileMenuOpened}"
      v-if="section"
    >
      <div class="wrapper">
        <div class="header__row d-none d-md-flex justify-content-center justify-content-md-end">
          <button
            @click.prevent="openSessionAppModal('register')"
            class="header__session"
            v-if="!isAuthenticated"
          >
            <fa-icon
              class="margin__r--6"
              :icon="['fas', 'user']"
            />
            <span v-html="$i18n.t('literal.login')" />
          </button>

          <router-link
            class="header__session header__session--user d-flex align-items-center"
            :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
            v-if="isAuthenticated && user"
          >
            <user-profile-img
              size="2"
              :avatar="user.avatar"
            />

            <span class="d-block margin__l--6">{{ user.firstname }}</span>
          </router-link>

          <language-switcher
            class="header__language margin__l--24"
            v-if="isMultilingual"
          />
        </div>

        <div class="header__row justify-content-center">
          <nav
            class="header__cell"
            v-if="links_1.length > 0"
          >
            <ul class="header__menu">
              <li
                class="header__menu-item"
                :class="{'header__menu-item--submenu': item.submenu.length > 0}"
                v-for="(item, index) in links_1"
                :key="`menu_1_item_${index}`"
              >
                <a
                  class="header__menu-link bold"
                  :class="{'header__menu-link--active': isVisited(item.link)}"
                  @click.prevent="setRouteNavigation(item.link); mobileMenuOpened = false"
                >
                  {{ $i18n.translate(item.link.text) }}
                </a>

                <ul
                  v-if="item.submenu.length > 0"
                  class="header__submenu"
                >
                  <li
                    class="header__submenu-item"
                    v-for="(subitem, key) in item.submenu"
                    :key="`menu_1_item_${index}_${key}`"
                  >
                    <a
                      class="header__submenu-link bold"
                      :class="{'header__submenu-link--active': isVisited(subitem.link)}"
                      @click.prevent="setRouteNavigation(subitem.link); mobileMenuOpened = false"
                    >
                      {{ $i18n.translate(subitem.link.text) }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>

          <router-link
            :to="{ name: 'Home', params: { locale: $i18n.locale } }"
            v-show="client.logo.url"
            class="logo"
          >
            <img
              class="logo__img"
              :src="client.logo.url"
              :alt="client.name"
            >
          </router-link>

          <nav
            class="header__cell justify-content-end"
          >
            <ul
              class="header__menu"
              v-if="links_2.length > 0"
            >
              <li
                class="header__menu-item"
                :class="{'header__menu-item--submenu': item.submenu.length > 0}"
                v-for="(item, index) in links_2"
                :key="`menu_2_item_${index}`"
              >
                <a
                  class="header__menu-link bold"
                  :class="{'header__menu-link--active': isVisited(item.link)}"
                  @click.prevent="setRouteNavigation(item.link); mobileMenuOpened = false"
                >
                  {{ $i18n.translate(item.link.text) }}
                </a>

                <ul
                  v-if="item.submenu.length > 0"
                  class="header__submenu"
                >
                  <li
                    class="header__submenu-item"
                    v-for="(subitem, key) in item.submenu"
                    :key="`menu_1_item_${index}_${key}`"
                  >
                    <a
                      class="header__submenu-link bold"
                      :class="{'header__submenu-link--active': isVisited(subitem.link)}"
                      @click.prevent="setRouteNavigation(subitem.link); mobileMenuOpened = false"
                    >
                      {{ $i18n.translate(subitem.link.text) }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>

            <form
              class="header__search"
              v-if="search.show"
              @submit.prevent="goToProjects()"
            >
              <button class="header__search-btn margin__r--12">
                <fa-icon
                  :icon="['fas', 'search']"
                />
              </button>

              <input-text
                class="header__search-input"
                v-model="form.search"
                :placeholder="$i18n.translate(search.text)"
              />
            </form>

            <app-burger-menu
              class="header__burger d-md-none"
              :state="mobileMenuOpened"
              @toggle="toggleMobileMenu"
            />
          </nav>
        </div>
      </div>

      <transition name="fade-mobile-menu">
        <section
          class="mobile"
          v-if="mobileMenuOpened"
        >
          <div class="wrapper">
            <ul
              class="mobile__menu"
              v-if="links.length > 0"
            >
              <li
                class="mobile__menu-item"
                v-for="(item, index) in links"
                :key="`menu_mobile_item_${index}`"
              >
                <a
                  class="mobile__menu-link bold"
                  :class="{'mobile__menu-link--active': isVisited(item.link)}"
                  @click.prevent="setRouteNavigation(item.link); mobileMenuOpened = false"
                >
                  {{ $i18n.translate(item.link.text) }}
                </a>

                <ul
                  v-if="item.submenu.length > 0"
                  class="mobile__submenu"
                >
                  <li
                    class="mobile__menu-item"
                    v-for="(subitem, key) in item.submenu"
                    :key="`menu_mobile_item_${index}_${key}`"
                  >
                    <a
                      class="mobile__menu-link bold"
                      :class="{'mobile__menu-link--active': isVisited(subitem.link)}"
                      @click.prevent="setRouteNavigation(subitem.link); mobileMenuOpened = false"
                    >
                      {{ $i18n.translate(subitem.link.text) }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>

            <hr class="separator separator__size--full border__color--dw margin__tb--18">

            <form
              class="mobile__search"
              v-if="search.show"
              @submit.prevent="goToProjects()"
            >
              <button class="mobile__search-btn margin__r--12">
                <fa-icon
                  :icon="['fas', 'search']"
                />
              </button>

              <input-text
                class="mobile__search-input"
                v-model="form.search"
                :placeholder="$i18n.translate(search.text)"
              />
            </form>

            <hr class="separator separator__size--full border__color--dw margin__tb--18">

            <ul class="mobile__sessions">
              <li
                class="mobile__sessions-item"
                v-if="!isAuthenticated"
              >
                <a
                  @click.prevent="openSessionAppModal('login')"
                  class="link__color--black link--naked pointer"
                >
                  <fa-icon
                    class="margin__r--6"
                    :icon="['fas', 'user']"
                    fixed-width
                  />
                  {{ $i18n.t('literal.login') }}
                </a>
              </li>

              <li
                class="mobile__sessions-item pointer"
                v-if="isAuthenticated && user"
              >
                <router-link
                  :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
                  @click.capture="mobileMenuOpened = false"
                  class="d-flex align-items-center"
                >
                  <user-profile-img
                    class="margin__r--6"
                    size="2"
                    :avatar="user.avatar"
                  />

                  {{ user.firstname }}
                </router-link>
              </li>

              <li
                class="mobile__sessions-item mobile__sessions-item--logout pointer"
                v-if="isAuthenticated && user"
              >
                <router-link
                  :to="{ name: 'Logout' }"
                  class="link--naked pointer link__color--danger"
                >
                  <fa-icon
                    class="margin__r--6 color--danger"
                    :icon="['fas', 'sign-out']"
                    fixed-width
                  />
                  {{ $i18n.t('literal.logout') }}
                </router-link>
              </li>

              <li v-if="isMultilingual">
                <language-switcher />
              </li>
            </ul>

            <hr class="separator separator__size--full border__color--dw margin__tb--18">
          </div>
        </section>
      </transition>
    </header>

    <app-modal
      v-if="showSessionAppModal"
      size="sm"
      @close="showSessionAppModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="template"
          @authenticated="showSessionAppModal = false"
        />
      </template>
    </app-modal>
  </div>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import { get as _get } from 'lodash'
import { mapGetters } from 'vuex'
import PageRouteNavigation from '%/PageRouteNavigation'
import UserProfileImg from '&/atoms/UserProfileImg'
import LanguageSwitcher from '&/atoms/LanguageSwitcher'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'

export default defineComponent({
  name: 'CustomHeaderRefugeeWalk',
  components: {
    UserProfileImg,
    LanguageSwitcher,
    AppBurgerMenu,
    InputText: defineAsyncComponent(() => import('&/atoms/InputText')),
    SessionForms: defineAsyncComponent(() => import('&/modules/sessions/SessionForms')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      form: {
        search: ''
      },
      showSessionAppModal: false,
      mobileMenuOpened: false,
      template: null
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    isMultilingual () {
      return this.client.active_languages.length > 1
    },
    links () {
      return [...this.links_1, ...this.links_2]
    },
    links_1 () {
      return _get(this.section, 'attributes.links_1', [])
    },
    links_2 () {
      return _get(this.section, 'attributes.links_2', [])
    },
    search () {
      return _get(this.section, 'attributes.search', {})
    }
  },
  methods: {
    openSessionAppModal (template) {
      this.template = template
      this.showSessionAppModal = true
    },
    toggleMobileMenu (value) {
      this.mobileMenuOpened = !value
    },
    goToProjects () {
      if (!this.form.search) return

      if (this.isVisited(this.search)) {
        if (this.search.target_url)
          window.location.replace(`/${this.$i18n.locale}/${this.search.target_url}?search=${this.form.search}`)

        return
      }

      if (this.search.target_url) {
        this.$router.push({ path: `/${this.$i18n.locale}/${this.search.target_url}?search=${this.form.search}` }).catch()
      } else if (this.search.target_name) {
        this.$router.push({ name: this.search.target_name, params: this.search.target_params, query: { search: this.form.search } }).catch()
      }
    }
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #FFB700;

.wrapper {
  max-width: 1318px;
  margin: 0 auto;
  padding: 0 $padding__base * 1.5;

  @include mq(sm) {
    padding: 0 $padding__base * 2;
  }
}

.logo {
  &__img {
    max-width: 100%;
    height: auto;
  }
}

.header {
  padding: $padding__base 0;
  background: $white;

  @include mq(md) {
    padding: $padding__base * 2 0;
  }

  @include mq(lg) {
    padding: $padding__base * 2.5 0;
  }

  &__row {
    display: grid;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: 1fr 75px 1fr;

    @include mq(sm) {
      grid-template-columns: 1fr 150px 1fr;
    }

    @include mq(md) {
      grid-template-columns: 1fr 130px 1fr;
    }

    @include mq(lg) {
      grid-template-columns: 1fr 170px 1fr;
    }

    @include mq(xl) {
      grid-template-columns: 1fr 190px 1fr;
    }
  }

  &__cell {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
  }

  &__menu {
    font-family: $font-family--secondary;
    display: none;
    text-transform: uppercase;
    font-size: rem(16px);

    @include mq(md) {
      display: flex;
    }

    @include mq(lg) {
      font-size: rem(20px);
    }

    @include mq(xl) {
      font-size: rem(20px);
    }

    &-item {
      position: relative;

      &:not(:last-child) {
        margin-right: $margin__base;

        @media screen and (min-width: 1080px) {
          margin-right: $margin__base * 1.4;
        }

        @include mq(xl) {
          margin-right: $margin__base * 2;
        }
      }

      &:hover {
        .header {
          &__submenu {
            height: auto;
            opacity: 1;
            transform: translateY(0px);
          }
        }
      }

      &--submenu {
        &:hover {
          .header {
            &__menu {
              &-link {
                color: $color--primary;
              }
            }
          }
        }
      }
    }

    &-link {
      display: block;
      transition: all .2s;
      padding: $padding__base 0;

      &:hover, &--active {
        color: $color--primary;
      }
    }
  }

  &__submenu {
    position: absolute;
    height: 0;
    opacity: 0;
    font-size: rem(14px);
    background: $color--primary;
    width: 160px;
    padding: 0 $padding__base;
    transform: translateY(-10px);
    transition: all .2s;
    overflow: hidden;
    z-index: 10;

    &-item {
      padding: $padding__base 0;

      &:not(:first-child) {
        border-top: 1px solid $black;
      }
    }

    &-link {
      display: block;
      transition: all .2s;

      &:hover, &--active {
        color: $white;
      }
    }
  }

  &__search {
    border: 2px solid $black;
    display: none;
    align-items: center;
    padding: $padding__base * 0.8 $padding__base;
    margin-left: $margin__base * 2;
    transition: all .2s;

    @include mq(md) {
      display: flex;
    }

    @include mq(lg) {
      padding: $padding__base * 0.8 $padding__base * 3;
    }

    &-input {
      :deep() {
        .input {
          border: 0;
          font-family: $font-family--secondary;
          font-weight: bold;
          font-size: rem(16px);
          max-width: 70px;
          padding: 0;
          text-transform: uppercase;
          transition: all .2s;

          @include mq(lg) {
            font-size: rem(19px);
          }

          @include mq(xl) {
            font-size: rem(21px);
          }

          &::placeholder {
            color: $black;
          }

          &:focus {
            color: $color--primary;
          }
        }
      }
    }

    &-btn {
      svg {
        width: 16px;
        height: 16px;

        @include mq(md) {
          width: 20px;
          height: 20px;
        }

        @include mq(xl) {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}

.mobile {
  @include mq(md) {
    display: none;
  }

  &__menu {
    margin-top: $margin__base;

    &-item {
      &:not(:first-child) {
        margin-top: calc($margin__base / 2);
      }
    }

    &-link {
      &--active {
        color: $color--primary;
      }
    }
  }

  &__submenu {
    padding-left: $padding__base;
    margin: $margin__base 0;
    border-left: 2px solid $black;
  }

  &__search {
    border: 2px solid $black;
    display: flex;
    align-items: center;
    padding: $padding__base * 0.8 $padding__base;
    transition: all .2s;


    @include mq(lg) {
      padding: $padding__base * 0.8 $padding__base * 3;
    }

    &-input {
      :deep() {
        .input {
          border: 0;
          font-family: $font-family--secondary;
          font-weight: bold;
          max-width: 70px;
          padding: 0;
          text-transform: uppercase;
          transition: all .2s;
          font-size: rem(21px);

          &::placeholder {
            color: $black;
          }

          &:focus {
            color: $color--primary;
          }
        }
      }
    }

    &-btn {
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  &__sessions {
    margin-top: $margin__base;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-item {
      &--logout {
        margin: 0 calc($margin__base / 2) 0 auto;
      }
    }
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}
</style>
