<template>
  <app-modal @close="handleClickClose">
    <template #body>
      <section class="card__padding">
        <h2 class="bold">
          {{ t('literal.members') }}
        </h2>

        <ul class="members margin__t--12">
          <li
            class="members__li justify-content-between"
            v-for="user in users"
            :key="user.id"
          >
            <div class="d-flex align-items-center">
              <user-profile-img
                class="members__img"
                :size="4"
                :avatar="user.avatar"
              />

              <p class="margin__l--12">
                {{ user.public_name }}
              </p>
            </div>

            <sporting-activities-distance
              class="color--sec"
              :distance="user.sporting_statistics.total_distance"
              v-if="isAllowedSportingActivities && block.isShowed('statistic_distance')"
            />
          </li>

          <li v-if="isAllowedInviteMembers">
            <router-link
              class="invite-member link__color--prim link--naked d-flex align-items-center"
              :to="{ name: 'ProjectSettings', query: { tab: 'members' } }"
            >
              <figure class="invite-member__icon margin__r--12">
                <fa-icon :icon="['fal', 'user-plus']" />
              </figure>

              {{ t('literal.invite_new_members') }}
            </router-link>
          </li>
        </ul>
      </section>
    </template>
  </app-modal>
</template>

<script setup>
import { computed, toRefs, defineAsyncComponent, inject } from 'vue'
import { mapGetters } from '@/store/map-state'
import { useAllowedFeatures } from '@/composables/app/useAllowedFeatures'
import { useRoles } from '@/composables/app/useRoles'
import { useBlock } from '@/composables/modules/templates'
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'

import AppModal from '@/components/organisms/AppModal'
import UserProfileImg from '&/atoms/UserProfileImg'

const SportingActivitiesDistance = defineAsyncComponent(() => import('&/molecules/SportingActivitiesDistance'))

const emit = defineEmits(['close'])

const props = defineProps({ project: { type: Object, required: true } })

const template = inject('template')

const { t } = useI18n()
const { isKoalect, isSuperAdmin } = useRoles()
const { allowedSportingActivities } = useAllowedFeatures()
const { user: authUser } = mapGetters('auth')

const { project } = toRefs(props)

const block = computed(() => useBlock(template.value?.getBlock('project_sporting_activities')))
const users = computed(() => _get(project.value, 'users', []))
const isAllowedInviteMembers = computed(() => (project.value?.isAuthorized(authUser.value) || isKoalect.value || isSuperAdmin.value) && _get(project.value, 'campaign.project_display_settings.invite_project_members', false))
const isAllowedSportingActivities = computed(() => allowedSportingActivities && _get(project.value, 'campaign.sporting_activities_settings.enabled', false) )

const handleClickClose = () => emit('close')
</script>

<style lang="scss" scoped>
.modal {
  &:deep(.modal__container) {
    @media (min-width: 420px) {
      width: 420px;
    }
  }
}

.members {
  &__li {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}

.invite-member {
  &__icon {
    transition: all .3s;
    width: 48px;
    height: 48px;
    border: 1px dashed currentColor;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>