<template>
  <header
    class="header"
    :class="{'mobile': showMobileMenu}"
  >
    <div class="container">
      <router-link
        :to="{ name: 'Home', params: { locale } }"
        v-show="client.logo.url"
      >
        <img
          class="logo__img"
          :src="client.logo.url"
          :alt="client.name"
        >
      </router-link>

      <ul class="navigation">
        <li
          class="navigation__li"
          v-for="({ link, submenu }, key) in links"
          :key="`link_${key}`"
        >
          <app-link
            :link="link"
            class="navigation__link"
          />

          <ul
            class="submenu"
            v-if="submenu && submenu.length"
          >
            <li
              class="submenu__li"
              v-for="({ link: sublink }, key2) in submenu"
              :key="`link_${key}_submenu_${key2}`"
            >
              <app-link
                :link="sublink"
                class="submenu__link navigation__link"
              />
            </li>
          </ul>
        </li>
      </ul>

      <article class="session">
        <template v-if="isAuthenticated && user">
          <router-link
            class="session__user"
            :to="{ name: 'Profile', params: { locale } }"
          >
            <user-profile-img
              :size="2"
              :avatar="user.avatar"
            />

            <p class="margin__l--6">
              {{ user.firstname }}
            </p>
          </router-link>
        </template>

        <template v-else>
          <a
            @click.prevent="toggleSessionModal('login')"
            class="session__login"
          >
            {{ translate(login) || t('literal.login') }}
          </a>
        </template>
      </article>

      <app-burger-menu
        class="burger"
        :state="showMobileMenu"
        @toggle="toggleMobileMenu"
      />
    </div>
  </header>

  <app-modal
    v-if="showSessionModal"
    size="sm"
    @close="showSessionModal = false"
  >
    <template #body>
      <session-forms
        class="modal__body"
        :tab="sessionTemplate"
        @authenticated="showSessionModal = false"
      />
    </template>
  </app-modal>
</template>

<script setup>
import { computed, defineAsyncComponent, ref, watch } from 'vue'
import { mapGetters } from '@/store/map-state'
import { get as _get } from 'lodash'
import { useI18n } from '@/vendors/i18n'
import { useRoute } from 'vue-router'

import AppLink from '&/atoms/AppLink'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
const UserProfileImg = defineAsyncComponent(() => import('&/atoms/UserProfileImg'))
const SessionForms = defineAsyncComponent(() => import('&/modules/sessions/SessionForms'))
const AppModal = defineAsyncComponent(() => import('&/organisms/AppModal'))

const props = defineProps({
  section: { type: Object, required: true }
})

const { getLocale, t, translate } = useI18n()
const route = useRoute()
const { client } = mapGetters('client')
const { isAuthenticated, user } = mapGetters('auth')

const showSessionModal = ref(false)
const showMobileMenu = ref(true)
const sessionTemplate = ref('login')
const locale = computed(() => getLocale())
const links = computed(() => _get(props, 'section.attributes.links', []))
const login = computed(() => _get(props, 'section.attributes.login', {}))

watch(route, () => showMobileMenu.value = false)

const toggleSessionModal = (template) => {
  sessionTemplate.value = template
  showSessionModal.value = true
}

const toggleMobileMenu = () => {
  showMobileMenu.value = !showMobileMenu.value
}
</script>

<style lang="scss" scoped>
.container {
  max-width: 340px;
  padding: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;

  @media (min-width: 670px) {
    max-width: 640px;
  }

  @media (min-width: 990px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
}

.header {
  border-bottom: 4px solid $color--tertiary;
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1000;
  background-color: rgba(white, .95);

  & .burger {
    display: inherit;
    margin-left: auto;

    @include mq(md) {
      display: none;
    }
  }
}

.logo {
  &__img {
    max-height: 60px;
    display: block;
    transition: width .3s, height .3s;

    @include mq(md) {
      max-height: 90px;
      margin-right: 44px;
    }
  }
}

.navigation {
  display: none;

  @include mq(md) {
    display: flex;
    align-items: center;
  }

  &__li {
    position: relative;

    &:hover .submenu {
      visibility: visible;
      opacity: 1;
      transform: translate(-50%, 12px);
    }
  }

  &__li:not(:last-child) {
    padding-right: 12px;
    line-height: 1;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 12px;
      background-color: $color--secondary;
    }
  }

  &__li:not(:first-child) {
    padding-left: 12px;
  }

  :deep(.navigation__link) {
    line-height: 1;
    color: $color--secondary;
    transition: all .3s;
    padding: 12px 0;
    display: block;

    &:hover, &.is-active {
      color: shade($color--secondary, 10%);
    }
  }
}

.submenu {
  transition: all 0.3s;
  visibility: hidden;
  opacity: 0;
  background-color: white;
  border: 1px solid $color--secondary;
  color: $white;
  position: absolute;
  left: 50%;
  top: calc(100% - 12px);
  text-align: left;
  transform: translateX(-50%);
  width: 240px;
  padding: 0 10px;

  &__li {
    text-align: center;

    &:not(:first-child) {
      border-top: 1px solid $color--secondary;
    }
  }

  :deep(.submenu__link) {
    font-size: 16px;
    border-top: 1px solid rgba($color--primary, .3);
  }
}

.session {
  margin-left: auto;
  display: none;

  @include mq(md) {
    display: inherit;
  }

  &__login {
    display: block;
    padding: 3px 12px;
    background-color: $color--primary;
    color: set-contrast($color--primary);
    transition: all .3s;

    &:hover {
      background-color: shade($color--primary, 1%, 40%);
    }
  }

  &__user {
    display: flex;
    align-items: center;
    transition: all .3s;

    &:hover {
      color: shade($color--secondary, 1%, 25%);
    }
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}

@media (max-width: 991px) {
  .mobile {
    position: fixed;
    min-height: 100vh;

    .logo__img {
      width: 90px;
      height: 90px;
      margin-top: 40px;
    }

    .container {
      flex-direction: column;
    }

    .navigation {
      display: block;
      margin-top: 20px;

      &__li {
        text-align: center;
        padding: 0;

        &:not(:first-child) {
          margin-top: 12px;
        }

        &:after {
          display: none;
        }

        :deep(>.navigation__link) {
          font-weight: bold;
        }

        &:hover .submenu {
          transform: translate(-50%, 0);
        }
      }
    }

    .submenu {
      visibility: visible;
      opacity: 1;
      position: relative;
      width: 100%;
    }

    .session {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
      margin-left: 0;
      margin-top: 36px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -24px;
        width: 100%;
        height: 1px;
        background-color: $color--secondary;
      }
    }

    .burger {
      position: absolute;
      right: 0;
    }
  }
}
</style>
