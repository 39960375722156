<template>
  <app-modal
    v-if="modal_notification"
    @close="handleClickClose()"
    size="md"
  >
    <template #body>
      <section
        class="modal"
        :class="`modal--${type}`"
      >
        <fa-icon
          class="icon margin__b--36"
          size="5x"
          :icon="icon"
        />

        <div class="modal__body">
          <h1
            class="title__h1 bold margin__b--12"
            v-if="t(title)"
          >
            {{ t(title) }}
          </h1>


          <p v-if="t(text)">
            {{ t(text) }}
          </p>
        </div>

        <div class="modal__footer margin__t--36">
          <button
            class="btn btn__size--medium"
            :class="`btn__color--${color}`"
            @click.prevent="handleClickClose()"
          >
            {{ t(`literal.close`) }}
          </button>
        </div>

        <p
          class="code color--vlg margin__t--12"
          v-if="code"
        >
          {{ code }}

          <template v-if="sentry_id">
            - {{ sentry_id }}
          </template>
        </p>
      </section>
    </template>
  </app-modal>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useNotificationStore } from '@/stores/NotificationStore'
import { useI18n } from '@/vendors/i18n'
import { useSentry } from '@/vendors/integrations/sentry'
import { get as _get } from 'lodash'

import AppModal from '&/organisms/AppModal'

const { t } = useI18n()
const sentry = useSentry()
const { $resetStore } = useNotificationStore()
const { modal_notification } = storeToRefs(useNotificationStore())

const sentry_id = ref(null)

const type = computed(() => _get(modal_notification.value, 'type', 'error'))
const title = computed(() => _get(modal_notification.value, 'title', ''))
const text = computed(() => _get(modal_notification.value, 'text', ''))
const code = computed(() => _get(modal_notification.value, 'code', ''))
const error = computed(() => [_get(modal_notification.value, 'error', ''), code.value].filter(v => v).join(' - '))
const state = computed(() => ({}))
const close = computed(() => _get(modal_notification.value, 'close'))

const icon = computed(() => {
  if (type.value === 'success') return ['far', 'check-circle']
  if (type.value === 'info') return ['fal', 'info-circle']
  if (type.value === 'warning') return ['fas', 'exclamation-circle']

  return ['fal', 'exclamation-triangle']
})

const color = computed(() => {
  if (type.value === 'success') return 'success'
  if (type.value === 'info') return 'ter'
  if (type.value === 'warning') return 'warning'

  return 'danger'
})

const handleClickClose = () => {
  if (close.value && typeof close.value === 'function') close.value()

  $resetStore('modal_notification')
}

watch(error, () => {
  if (type.value === 'error' && error.value) sentry_id.value = sentry.error(Error(error.value), state.value)
})
</script>

<style lang="scss">
.modal {
  padding: $padding__base * 4;
  text-align: center;

  &--error {
    border-bottom: 3px solid $color--danger;

    .icon {
      color: $color--danger;
    }
  }

  &--warning {
    border-bottom: 3px solid $color--warning;

    .icon {
      color: $color--warning;
    }
  }

  &--success {
    border-bottom: 3px solid $color--success;

    .icon {
      color: $color--success;
    }
  }

  &--info {
    border-bottom: 3px solid $color--tertiary;

    .icon {
      color: $color--tertiary;
    }
  }
}

.code {
  font-size: rem(14px);
}
</style>
