import { defineStore } from 'pinia'
import Project from '@/classes/projects/Project'
import Update from '@/classes/updates/Update'
import { get as _get } from 'lodash'

const models = { project: Project, update: Update }

export const useSampleStore = defineStore('SampleStore', {
  state: () => ({
    sample: {},
    samples: {},
    loader: false,
    error: {}
  }),
  getters: {
    getSample: state => ({ id }) => {
      return state.$getItem({
        resource: 'sample',
        key: id,
        fetch: () => state.fetchSample({ id })
      })
    },
    getSamples: state => ({ id, query }) => {
      return state.$getItems({
        resource: 'samples',
        query,
        fetch: () => state.fetchSamples({ id, query })
      })
    }
  },
  actions: {
    fetchSample ({ id }) {
      return this.$fetchItem({
        endpoint: `/api/v1/samples/${id}`,
        resource: `sample.${id}`,
        model: _get(models, id, null)
      })
    },
    fetchSamples ({ id, query }) {
      return this.$fetchItems({
        endpoint: `/api/v1/samples/${id}`,
        resource: 'samples',
        model: _get(models, id, null),
        query
      })
    }
  }
})