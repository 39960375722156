<template>
  <textarea
    ref="textarea"
    class="input input--border input--radius"
    :id="id"
    @keyup="heightAdjustement"
    :placeholder="placeholder"
    v-model="textarea"
    :maxlength="maxlength"
    @blur="blurIfMandatory()"
    v-bind="$attrs"
  />

  <div
    class="input--errors"
    v-if="v && v.$error"
  >
    <p
      class="input--error"
      v-if="v.required && v.required.$invalid"
    >
      {{ $i18n.t('errors.input_textarea_required') }}
    </p>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InputTextarea',
  emits: ['update:modelValue'],
  props: {
    placeholder: {
      type: String,
      default: '...'
    },
    id: {
      type: String
    },
    modelValue: {
      type: String,
      default: ''
    },
    v: {
      type: Object
    },
    maxlength: {
      type: Number
    }
  },
  computed: {
    textarea: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    blurIfMandatory () {
      if (this.v) {
        this.v.$touch()
      }
    },
    heightAdjustement () {
      this.$refs.textarea.style.height = '1px'
      this.$refs.textarea.style.height = (25 + this.$refs.textarea.scrollHeight) + 'px'
    }
  },
  mounted () {
    this.heightAdjustement()
  }
})
</script>

<style lang="scss" scoped>
.input {
  max-width: 100%;
  min-width: 100%;
  display: block;
}
</style>
