<template>
  <div
    class="notification"
    :class="'notification--' + type"
    v-if="hasKey"
  >
    <fa-icon
      class="icon"
      size="sm"
      :icon="icon"
    />

    <ul
      class="list"
      v-if="allKeys.length"
    >
      <template v-for="(key, index) in allKeys">
        <li
          class="list__item"
          :key="index"
          v-if="key"
        >
          <slot :key="key">
            <p>{{ t(key) }}</p>
          </slot>
        </li>
      </template>
    </ul>
  </div>
</template>

<script setup>
import { computed, toRefs } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { get as _get, isPlainObject as _isPlainObject } from 'lodash'

const props = defineProps({
  keys: { type: [Object, String, Array] },
  type: { type: String, default: 'error' }
})

const { t } = useI18n()
const { type, keys } = toRefs(props)

const types = {
  error: ['fal', 'exclamation-circle']
}

const icon = computed(() => types[type.value])
const rawKeys = computed(() => _isPlainObject(keys.value) ? _get(keys.value, 'data.errors', null) : keys.value)
const allKeys = computed(() => Array.isArray(rawKeys.value) ? rawKeys.value.map(key => createKey(key)) : [createKey(rawKeys.value)])
const hasKey = computed(() => Array.isArray(rawKeys.value) ? rawKeys.value.length : rawKeys.value)

const createKey = key => key && type.value === 'error' && !key.startsWith('errors.') ? `errors.${key}` : key
</script>

<style scoped lang="scss">
.notification {
  padding: $padding__base;
  border-top-right-radius: $radius__cards;
  border-bottom-right-radius: $radius__cards;
  display: flex;
  justify-content: flex-start;

  &--error {
    background: tint($color--danger, 50%, 90%);
    border-left: 3px solid $color--danger;

    .icon {
      color: $color--danger;
    }
  }

  .icon {
    margin-right: $margin__base;
    margin-top: calc($margin__base / 2);
  }

  .list {
    &__item:not(:first-child) {
      margin-top: $margin__base;
    }
  }
}
</style>
