import Project from '@/classes/projects/Project'

const endpoint = '/api/v1/user'
const resource = 'user'

export default {
  namespaced: true,
  state: {
    transactions: {
      items: {}
    },
    subscriptions: {
      items: {}
    },
    projects: {
      items: {}
    }
  },
  getters: {
    getTransactions: state => state.transactions.items,
    getSubscriptions: state => state.subscriptions.items,
    getProjects: state => state.projects.items,
    getSubscription: state => id => Object.values(state.subscriptions.items).find(subscription => subscription.id === id)
  },
  actions: {
    fetchUserSubscriptions: ({ dispatch }, { query }) => dispatch(
      'fetchItems',
      {
        endpoint: `${endpoint}/subscriptions`,
        resource: `${resource}.subscriptions`,
        query
      },
      { root: true }
    ),

    fetchUserProjects: ({ dispatch }, { query }) => dispatch(
      'fetchItems',
      {
        endpoint: `${endpoint}/projects`,
        resource: `${resource}.projects`,
        query,
        model: project => new Project(project),
        removeStoredItems: true
      },
      { root: true }
    ),

    fetchUserTransaction: ({ dispatch }, { id }) => dispatch(
      'fetchItem',
      {
        endpoint: `${endpoint}/transactions/${id}`,
        resource: `${resource}.transactions`
      },
      { root: true }
    ),

    fetchUserSubscription: ({ dispatch }, { id }) => dispatch(
      'fetchItem',
      {
        endpoint: `${endpoint}/subscriptions/${id}`,
        resource: `${resource}.subscriptions`
      },
      { root: true }
    ),

    patchUserSubscription: ({ dispatch }, { id, params }) => dispatch(
      'patchItem',
      {
        endpoint: `${endpoint}/subscriptions/${id}`,
        resource: `${resource}.subscriptions`,
        params: {
          subscription: params
        }
      },
      { root: true }
    )
  }
}
