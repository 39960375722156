<template>
  <section
    class="section"
    :style="sectionStyle"
  >
    <div class="container">
      <div
        class="editor editor__content editor__page"
        v-html="$i18n.translate(section.attributes.text)"
      />
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import { useSectionStyle } from '@/composables/modules/pages'

export default defineComponent({
  name: 'SectionTextEditor',
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  setup: props => ({ ...useSectionStyle(props) })
})
</script>

<style lang="scss" scoped>
.section {
  padding: $padding__base 0;

  @include mq(md) {
    padding: $padding__base * 3 0;
  }
}
</style>
