export default [
  {
    name: 'media+text',
    path: 'modules/pages/default/sections/SectionMediaText'
  },
  {
    name: 'media',
    path: 'modules/pages/default/sections/SectionMedia'
  }
]
