<template>
  <div class="container">
    <div class="row justify-content-center editor">
      <div class="col-12 col-sm-10 col-lg-8">
        <div
          class="editor__content"
          v-html="$i18n.translate(section.attributes.text)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SectionContent',
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  }
})
</script>
