<template>
  <div
    class="section"
    :style="{ backgroundColor: style.background_color }"
  >
    <div class="container">
      <section class="section__content">
        <figure class="section__img">
          <img
            class="img"
            :src="attributes.img_src || 'http://via.placeholder.com/800x450/FFFFFF'"
            alt=""
          >
        </figure>

        <article class="section__text">
          <h2
            class="title__h2 bold text__baseline"
            v-if="attributes.title"
            v-html="$i18n.translate(attributes.title)"
          />

          <p
            class="margin__t--18 text__subline"
            v-if="attributes.text"
            v-html="$i18n.translate(attributes.text)"
          />

          <button
            class="margin__t--36 btn btn__size--medium btn__color--prim"
            type="button"
            v-if="attributes.link"
            target="_blank"
            :disabled="!isActive(attributes.link)"
            @click.prevent="setRouteNavigation(attributes.link)"
          >
            {{ $i18n.translate(attributes.link.text) }}
          </button>
        </article>
      </section>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import PageRouteNavigation from '%/PageRouteNavigation'

export default defineComponent({
  name: 'SectionImgTextEditor',
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    attributes () {
      return this.section.attributes
    },
    style () {
      return this.section.style
    }
  }
})
</script>

<style lang="scss" scoped>
.section {
  padding: $padding__base * 3 0;

  &__content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__img {
    width: 100%;
    margin-bottom: $margin__base * 2;

    @include mq(md) {
      width: 50%;
      padding-right: $padding__base * 1.5;
      margin-bottom: 0;
    }
  }

  &__text {
    width: 100%;

    @include mq(md) {
      width: 50%;
      padding-left: $padding__base * 1.5;
    }
  }
}

.img {
  max-width: 100%;
}
</style>
