<template>
  <div>
    <div
      class="margin__b--18"
      v-if="invites.length"
    >
      <fieldset
        class="margin__t--12"
        v-for="(invite, index) in invites"
        :key="index"
      >
        <create-project-invite-email
          :label="label"
          v-model="invite.email"
          @delete="handleCLickDeleteEmail(index)"
        />
      </fieldset>
    </div>

    <button
      class="btn btn__dashed--vlg btn__size--full-12 align--left"
      type="button"
      v-if="!limitOfMembers || (limitOfMembers && limitOfMembersLeft > 0)"
      @click.prevent="handleClickAddEmail()"
    >
      <fa-icon
        class="margin__r--6"
        :icon="['fal', 'plus']"
      />

      {{ translate(attributes.cta) }}
    </button>

    <p
      class="margin__t--18"
      v-if="limitOfMembers && translate(attributes.project_limited)"
    >
      {{ interpolate(attributes.project_limited, { project: { members: { limit: limitOfMembers } } }) }}
    </p>
  </div>
</template>

<script setup>
import { ref, computed, toRefs, inject, defineAsyncComponent, watch, onBeforeMount, onBeforeUnmount } from 'vue'
import { useModule } from '@/composables/app/useModule'
import { useProcedure } from '@/composables/modules/procedures'
import { useI18n } from '@/vendors/i18n'
import { uniq as _uniq } from 'lodash'

const CreateProjectInviteEmail = defineAsyncComponent(() => import('&/modules/procedures/fragments/create_invite/CreateProjectInviteEmail'))

const props = defineProps({ block: { type: Object, required: true } })

const { block } = toRefs(props)

const procedure = inject('procedure')
const procedure_execution = inject('procedure_execution')

const { translate, interpolate } = useI18n()
const { limitOfMembers } = useProcedure({ procedure })
const { label, model, attributes } = useModule({ component: block, parent: procedure_execution })

const invites = ref([])

const limitOfMembersLeft = computed(() => limitOfMembers.value - invites.value.length)

const handleClickAddEmail = () => invites.value.push({ email: null })
const handleCLickDeleteEmail = index => invites.value.splice(index, 1)

watch(invites, () => model.value = _uniq(invites.value.map(({ email }) => email).filter(email => email)), { deep: true })

onBeforeMount(() => {
  if (!model.value) model.value = []

  invites.value = model.value.slice(0, limitOfMembers.value ? limitOfMembers.value : model.value.length).map(email => ({ email }))
})

onBeforeUnmount(() => {
  if (!block.value.isVisible(procedure_execution)) model.value = []
})
</script>

<style lang="scss" scoped>
.btn {
  border-color: rgba($dg, 0.1);
  font-size: em(14px);
  color: rgba($dg, 0.6);
  transition: all .3s;

  &:hover {
    border-color: rgba($dg, 0.6);
    color: rgba($dg, 0.8);
  }
}
</style>