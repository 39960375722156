<template>
  <div>
    <label
      class="input__label"
      :for="id"
    >
      {{ translate(label) }} <span v-if="block.is_required">*</span>
    </label>

    <app-google-autocomplete
      class="margin__t--6"
      :id="id"
      :types="types"
      :address="model"
      :placeholder="translate(placeholder)"
      :v="validator"
      @placechanged="address => model = address"
    />
  </div>
</template>

<script setup>
import { inject, toRefs } from 'vue'
import { useModule } from '@/composables/app/useModule'
import { useProcedureBlockValidator } from '@/composables/modules/procedures'
import { useI18n } from '@/vendors/i18n'
import Address from '@/classes/addresses/Address'
import { required } from '@vuelidate/validators'
import { get as _get } from 'lodash'

import AppGoogleAutocomplete from '&/atoms/AppGoogleAutocomplete'

const props = defineProps({ block: { type: Object, required: true } })

const { block } = toRefs(props)

const procedure_execution = inject('procedure_execution')

const { translate } = useI18n()
const { label, placeholder, model, attributes, id, defaultValue } = useModule({ component: block, parent: procedure_execution, default_value: new Address() })
const { validator } = useProcedureBlockValidator({ validations: block.value.is_required ? { country: { required } } : {}, state: model })

const types = _get(attributes.value, 'type', '(regions)')

if (!model.value && defaultValue.value) model.value = defaultValue.value
</script>
