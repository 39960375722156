<template>
  <p v-if="name && texts.length && isShowed('benefiting')">
    <template
      v-for="(text, index) in texts"
      :key="index"
    >
      <template v-if="text === 'beneficiary'">
        <router-link
          class="link__color--black-prim link--naked bold"
          :to="{ name: 'Page', params: { path: pageSlug } }"
          v-if="pageSlug"
        >
          {{ name }}
        </router-link>

        <strong
          class="bold"
          v-else
        >
          {{ name }}
        </strong>
      </template>

      <template v-else>
        {{ text }}
      </template>
    </template>
  </p>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useBlock } from '@/composables/modules/templates'
import { get as _get } from 'lodash'

const props = defineProps({ block: { type: Object, required: true } })

const { translate } = useI18n()
const { objects, getText, isShowed } = useBlock(props.block)

const benefiting = computed(() => _get(objects.value, 'project.benefiting', {}))
const name = computed(() => _get(benefiting.value, 'name'))
const pageSlug = computed(() => _get(benefiting.value, 'page_slug'))

const benefitingText = computed(() => translate(getText('benefiting')) || '')
const texts = computed(() => benefitingText.value.split('%%').map(v => v.split('%%')).flat().filter(v => v))
</script>