<template>
  <div class="header--sticky">
    <header
      class="header"
      ref="header"
      v-if="section"
    >
      <div class="wrapper header__wrapper">
        <div class="header__topbar">
          <search-field
            class="header__search margin__r--24"
            :placeholder="$i18n.translate(search.placeholder)"
            @submit="goToProjects($event)"
          />

          <language-switcher
            class="language margin__r--24"
            v-if="isMultilingual"
          />

          <button
            @click.prevent="openSessionAppModal('register')"
            v-if="!isAuthenticated"
          >
            <fa-icon
              class="margin__r--6"
              :icon="['fas', 'user']"
            />
            <span v-html="$i18n.t('literal.login')" />
          </button>

          <router-link
            class="header__user d-flex align-items-center"
            :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
            v-if="isAuthenticated && user"
          >
            <user-profile-img
              size="2"
              :avatar="user.avatar"
            />

            <span class="d-block margin__l--6">{{ user.firstname }}</span>
          </router-link>
        </div>

        <div class="header__nav">
          <div class="header__logo">
            <router-link
              :to="{ name: 'Home', params: { locale: $i18n.locale } }"
              v-show="client.logo.url"
              class="logo"
            >
              <img
                class="logo__img"
                :src="client.logo.url"
                :alt="client.name"
              >
            </router-link>

            <div class="header__baseline">
              <p>{{ $i18n.translate(logo.text) }}</p>

              <a
                class="link__color--prim"
                v-if="logo.link && logo.link.show"
                @click.prevent="setNavigation(logo.link); mobileMenuOpened = false"
              >
                {{ $i18n.translate(logo.link.text) }}
              </a>
            </div>
          </div>

          <div class="header__links">
            <nav
              v-if="navigation.length > 0"
            >
              <ul class="header__menu">
                <li
                  class="header__menu-item"
                  v-for="(item, index) in navigation"
                  :key="`menu_item_${index}`"
                >
                  <a
                    class="header__menu-link"
                    :class="{'header__menu-link--active': isVisited(item.link)}"
                    @click.prevent="setNavigation(item.link); mobileMenuOpened = false"
                  >
                    {{ $i18n.translate(item.link.text) }}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div class="header__actions">
          <nav
            v-if="actions.length > 0"
          >
            <ul class="header__actions">
              <li
                class="header__actions-item"
                v-for="(item, index) in actions"
                :key="`actions_item_${index}`"
              >
                <a
                  class="header__actions-link"
                  :class="{'header__actions-link--active': isVisited(item.link)}"
                  @click.prevent="setNavigation(item.link); mobileMenuOpened = false"
                >
                  {{ $i18n.translate(item.link.text) }}
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <app-burger-menu
          class="header__burger d-md-none"
          :state="mobileMenuOpened"
          @toggle="toggleMobileMenu"
        />
      </div>

      <transition name="fade-mobile-menu">
        <section
          class="mobile"
          v-if="mobileMenuOpened"
        >
          <div class="wrapper mobile__wrapper">
            <div class="mobile__close">
              <button @click="mobileMenuOpened=false">
                <fa-icon
                  :icon="['fal', 'times']"
                  fixed-width
                />
              </button>
            </div>

            <search-field
              class="mobile__search"
              :placeholder="$i18n.translate(search.placeholder)"
              @search-query="goToProjects($event)"
            />

            <ul
              class="mobile__menu"
              v-if="navigation.length > 0"
            >
              <li
                class="mobile__menu-item"
                v-for="(item, index) in navigation"
                :key="`menu_mobile_item_${index}`"
              >
                <a
                  class="mobile__menu-link"
                  :class="{'mobile__menu-link--active': isVisited(item.link)}"
                  @click.prevent="setNavigation(item.link); mobileMenuOpened = false"
                >
                  {{ $i18n.translate(item.link.text) }}
                </a>
              </li>
            </ul>

            <ul
              class="mobile__actions"
              v-if="actions.length > 0"
            >
              <li
                class="mobile__actions-item"
                v-for="(item, index) in actions"
                :key="`actions_mobile_item_${index}`"
              >
                <a
                  class="mobile__actions-link"
                  @click.prevent="setNavigation(item.link); mobileMenuOpened = false"
                >
                  {{ $i18n.translate(item.link.text) }}
                </a>
              </li>
            </ul>

            <hr class="separator separator__size--full border__color--ter margin__tb--18">

            <ul class="mobile__sessions">
              <li
                class="mobile__sessions-item"
                v-if="!isAuthenticated"
              >
                <a
                  @click.prevent="openSessionAppModal('login')"
                  class="link__color--white link--naked pointer"
                >
                  <fa-icon
                    class="margin__r--6"
                    :icon="['fas', 'user']"
                    fixed-width
                  />

                  {{ $i18n.t('literal.login') }}
                </a>
              </li>

              <li
                class="mobile__sessions-item pointer"
                v-if="isAuthenticated && user"
              >
                <router-link
                  :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
                  @click.capture="mobileMenuOpened = false"
                  class="d-flex align-items-center"
                >
                  <user-profile-img
                    class="margin__r--6"
                    :avatar="user.avatar"
                    size="2"
                  />

                  {{ user.firstname }}
                </router-link>
              </li>

              <li class="mobile__sessions-item">
                <language-switcher
                  class="language"
                  v-if="isMultilingual"
                />
              </li>
            </ul>

            <hr class="separator separator__size--full border__color--ter margin__tb--18">
          </div>
        </section>
      </transition>
    </header>

    <app-modal
      v-if="showSessionAppModal"
      size="sm"
      @close="showSessionAppModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="template"
          @authenticated="showSessionAppModal = false"
        />
      </template>
    </app-modal>
  </div>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import { get as _get } from 'lodash'
import { mapGetters } from 'vuex'
import PageRouteNavigation from '%/PageRouteNavigation'
import UserProfileImg from '&/atoms/UserProfileImg'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
import LanguageSwitcher from '&/atoms/LanguageSwitcher'
import SearchField from '&/molecules/SearchField'

export default defineComponent({
  name: 'CustomHeaderLightForTheWorld',
  components: {
    UserProfileImg,
    AppBurgerMenu,
    LanguageSwitcher,
    SearchField,
    SessionForms: defineAsyncComponent(() => import('&/modules/sessions/SessionForms')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      showSessionAppModal: false,
      mobileMenuOpened: false,
      template: null
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    isMultilingual () {
      return this.client.active_languages.length > 1
    },
    navigation () {
      return _get(this.section, 'attributes.navigation', [])
    },
    actions () {
      return _get(this.section, 'attributes.actions', [])
    },
    logo () {
      return _get(this.section, 'attributes.logo', {})
    },
    search () {
      return _get(this.section, 'attributes.search', {})
    }
  },
  methods: {
    openSessionAppModal (template) {
      this.template = template
      this.showSessionAppModal = true
    },
    toggleMobileMenu (value) {
      this.mobileMenuOpened = !value
    },
    setNavigation (obj) {
      if (this.$i18n.translate(obj.url)) {
        window.location.href = this.$i18n.translate(obj.url)
      } else {
        this.setRouteNavigation(obj)
      }
    },
    goToProjects (search) {
      this.$router.push({ path: `/${this.$i18n.locale}/projects?search=${search}` })
    }
  }
})
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;

  @include mq(sm) {
    padding: 0 30px;
  }

  @include mq(md) {
    padding: 0 60px;
  }
}

.logo {
  display: block;
  position: relative;
  width: 156px;

  &__website {
    display: block;
    font-size: rem(12px);
    margin-bottom: 20px;
    transition: all .2s;
    color: $black;

    &-icon {
      position: relative;
      display: inline-block;
      transition: all .2s;
      transform: none;
      color: $black;
    }
  }

  &__img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  &:hover {
    .logo {
      &__website {
        color: $color--secondary;

        &-icon {
          color: $color--secondary;
          transform: translateX(-5px);
        }
      }
    }
  }
}
.header {
  position: relative;
  width: 100%;
  padding: $padding__base 0;
  background: rgba(5,13,57,.4);
  color: #e6e6ea;
  background: rgba(5,13,57,.9);

  &--sticky {
    position: sticky;
    top: 0;
    z-index: 1000;
  }

  @include mq(md) {
    padding: 10px 0 20px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;

    @include mq(md) {
      flex-wrap: wrap;
    }
  }

  &__nav, &__actions {
    display: flex;
    align-items: center;
  }

  &__topbar {
    display: none;
    position: relative;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 6px;
    margin-top: 6px;
    font-size: 16px;

    @include mq(md) {
      display: flex;
    }
  }

  &__logo {
    margin-right: 29px;
  }

  &__user {
    color: #e6e6ea;
  }

  &__search {
    max-width: 140px;
    background-color: transparent;
    border-bottom: 1px solid #e6e6ea;
    border-radius: 0;
    padding: 0;
    display: flex;

    @include mq(md) {
      display: flex
    }

    & :deep() .input {
      background-color: transparent !important;
      border: 0;
      color: #e6e6ea;
      padding: calc($padding__base / 2) 0;

      &::placeholder {
        color: #e6e6ea;
      }
    }

    & :deep() .search__btn {
      padding: 0 6px;
      color: #e6e6ea;

      &:hover {
        color: $color--primary;
      }
    }
  }

  &__baseline {
    font-size: 12px;
    text-align: center;
    margin-top: 7px;

    p {
      display: inline-block;

      @include mq(md) {
        display: block;
      }
    }
  }

  &__menu {
    display: none;
    font-size: rem(16px);

    @include mq(md) {
      display: flex;
      align-items: center;
    }

    @include mq(lg) {
      font-size: rem(18px);
    }

    &-item {
      &:not(:last-child) {
        margin-right: 12px;

        @include mq(lg) {
          margin-right: 20px;
        }
      }
    }

    &-link {
      position: relative;
      display: block;
      color: #e6e6ea;
      line-height: 34px;
      transition: color .2s linear;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 24px;
        height: 2px;
        background: $color--primary;
        transition: width .2s linear;
      }

      &:hover, &--active {
        color: $color--primary;

        &:before {
          width: 100%;
        }
      }
    }
  }

  &__actions {
    display: none;

    @include mq(lg) {
      margin-left: 20px;
    }

    @include mq(md) {
      display: flex;
      align-items: center;
      font-size: 16px;
    }

    &-item {
      &:not(:last-child) {
        margin-right: 24px;
      }

      &:nth-child(odd) {
        .header__actions-link {
          border: 1px solid $color--primary;
          transition: color .2s linear, border-color .2s linear, background-color .2s linear, border .2s linear, opacity .2s linear;

          &:hover {
            background: $color--primary;
            color: set-contrast($color--primary);
          }
        }
      }

      &:nth-child(even) {
        .header__actions-link {
          background-color: $color--primary;
          color: set-contrast($color--primary);
          transition: color .2s linear, border-color .2s linear, background-color .2s linear, border .2s linear, opacity .2s linear;

          &:hover {
            background: shade($color--primary, 8%, 45%);
          }
        }
      }
    }

    &-link {
      display: block;
      color: $color--primary;
      padding: 11px 17px;
      border-radius: 4px;
      font-weight: normal;
      transition: all .2s;
    }
  }

  &__burger {
    color: $color--primary;
    line-height: 1;

    :deep() {
      .menu__line {
        background-color: $color--primary;
      }

      .menu__close::before, .menu__close::after {
        background-color: $color--primary;
      }
    }
  }
}

.flex {
  &--end {
    justify-content: flex-end;
  }

  &--between {
    justify-content: space-between;
  }
}

.language {
  & :deep() {
    .locale-switcher {
      background: none;
    }

    .v-select {
      border-radius: 5px;
    }

    .ls__dropdown-menu-icon {
      display: none;
    }

    .vs__selected {
      color: #e6e6ea;
    }

    .vs__open-indicator {
      fill: #e6e6ea;
    }
  }
}

.mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: $color--primary;
  z-index: 999;
  text-align: center;

  &__wrapper {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 24px;
  }

  &__close {
    text-align: right;
    font-size: 36px;
    color: $color--tertiary;
  }

  &__menu {
    font-size: rem(28px);
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-link {
      color: $color--tertiary;
      padding: 10px 0;
      display: inline-block;
      position: relative;
      line-height: 1;

      &--active {
        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 2px;
          background: $color--tertiary;
          left: 0;
          bottom: 0;
        }
      }
    }
  }

  &__actions {
    display: block;
    justify-content: center;
    align-items: center;
    font-size: 16px;

    &-item {
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      &:nth-child(even) {
        .mobile__actions-link {
          background-color: $color--tertiary;
          color: set-contrast($color--tertiary);
        }
      }
    }

    &-link {
      display: block;
      color: $color--tertiary;
      border-radius: 4px;
      padding: 11px 17px;
      border: 2px solid $color--tertiary;
    }
  }

  &__search {
    background-color: transparent;
    border: 1px solid $color--tertiary;
    display: flex;
    margin-top: 12px;

    & :deep() .input {
      background-color: transparent !important;
      border: 0;
      color: $color--tertiary;

      &::placeholder {
        color: $color--tertiary;
        font-size: 12px;
      }
    }

    & :deep() .search__btn {
      padding: 0 6px;
      color: $color--tertiary;
    }
  }

  &__sessions {
    color: $white;
    display: flex;
    align-items: center;

    &-item {
      color: $color--tertiary;

      &:not(:last-child) {
        margin-right: $margin__base * 2;
      }

      &:last-child {
         margin-left: auto;
      }
    }

    .language {
      & :deep() {
        .v-select {
          border-color: $color--tertiary;
        }

        .vs__selected {
          color: $color--tertiary;
        }

        .vs__open-indicator {
          fill: $color--tertiary;
        }
      }
    }
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}

.fade-mobile-menu-enter-active, .fade-mobile-menu-leave-active {
  transition: opacity .5s;
}
.fade-mobile-menu-enter, .fade-mobile-menu-leave-to {
  opacity: 0;
}
</style>
