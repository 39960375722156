export default class TwikeyFormConfiguration {
  constructor ({ redirect_url = {}, qr_codes = {}, default_amount = 0, payment_links = {}, reference = null } = {}) {
    this.redirect_url = redirect_url
    this.qr_codes = qr_codes
    this.default_amount = default_amount
    this.payment_links = payment_links
    this.reference = reference
  }

  get extensions () {
    return Object.keys(this.qr_codes)
  }
}
