import store from '@/store'
import { gtm, mixpanel } from '@/vendors/integrations'

export default class {
  static init = ({ user }) => {
    mixpanel.identify(user.email)
    mixpanel.register({
      '$user_id': user.id,
      '$email': user.email
    })
  }

  static register = ({ user, method }) => {
    mixpanel.reset()
    mixpanel.alias(user.email)
    mixpanel.track('New user', {
      'Method': method
    })

    gtm.track({
      event: 'register',
      auth_method: method
    })
  }

  static login = ({ method }) => {
    mixpanel.track('Login', {
      'Method': method
    })

    gtm.track({
      event: 'login',
      auth_method: method
    })
  }

  static logout = () => {
    const client = store.getters['client/client']
    mixpanel.reset()

    mixpanel.register_once({
      'Client Id': client.id,
      'Client Name': client.name
    })
  }
}
