import { computed } from 'vue'
import { useStore } from 'vuex'

export function useRoles () {
  const store = useStore()
  const user = computed(() => store.getters['auth/user'])
  const isSuperAdmin = computed(() => user.value && user.value.is_super_admin)
  const isKoalect = computed(() => user.value && ['koalect'].includes(user.value.role))

  return {
    isSuperAdmin,
    isKoalect
  }
}