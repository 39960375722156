<template>
  <footer
    class="footer"
    v-if="section"
  >
    <section class="footer__map">
      <app-google-maps
        class="footer__map"
        :zoom="16"
        :map-style="mapStyle"
        :center="{ lat: 50.88751855387174, lng: 4.30789352742865 }"
        :markers="mapMarkers"
        :marker-clustering="false"
      />
    </section>

    <section class="footer__infos">
      <article>
        <h2 class="title__h2">
          {{ $i18n.translate(contact.title) }}
        </h2>

        <p
          class="editor editor__content"
          v-html="$i18n.translate(contact.text)"
        />

        <ul class="margin__tb--24">
          <li>
            <fa-icon
              :icon="['far', 'map-marker-alt']"
              class="margin__r--6"
            />

            {{ $i18n.translate(contact.address) }}
          </li>

          <li class="margin__t--6">
            <fa-icon
              :icon="['far', 'phone-alt']"
              class="margin__r--6"
            />

            {{ $i18n.translate(contact.phone) }}
          </li>

          <li
            class="margin__t--6"
            v-if="$i18n.translate(contact.email)"
          >
            <fa-icon
              :icon="['far', 'envelope']"
              class="margin__r--6"
            />

            <a
              :href="`mailto:${$i18n.translate(contact.email)}`"
              class="link__color--prim link--naked"
            >
              {{ $i18n.translate(contact.email) }}
            </a>
          </li>

          <li class="margin__t--6">
            <fa-icon
              :icon="['far', 'university']"
              class="margin__r--6"
            />

            {{ $i18n.translate(contact.bank_account) }}
          </li>
        </ul>
      </article>

      <article>
        <h2 class="title__h2">
          {{ $i18n.translate(links.title) }}
        </h2>

        <ul>
          <template
            v-for="({ link }, index) in links.navigation"
            :key="`footer_item_link_${index}`"
          >
            <li v-show="link.show">
              <a
                class="link link__color--white-prim link--naked"
                :class="{'link--active': isVisited(link)}"
                @click.prevent="setRouteNavigation(link)"
              >
                {{ $i18n.translate(link.text) }}
              </a>
            </li>
          </template>
        </ul>
      </article>

      <article v-if="networks.length">
        <h2 class="title__h2">
          {{ $i18n.translate(social.title) }}
        </h2>

        <ul class="networks__ul">
          <li
            class="networks__li"
            v-for="(network, index) in networks"
            :key="'footer_menu_network_' + index"
          >
            <a
              class="networks__a"
              :href="$i18n.translate(network.url)"
              target="_blank"
            >
              <fa-icon
                v-if="network.icon"
                size="lg"
                :icon="network.icon"
              />
            </a>
          </li>
        </ul>
      </article>

      <article class="logo">
        <a
          v-if="logo.src"
          class="logo"
          target="_blank"
          :href="$i18n.translate(logo.link.url)"
        >
          <img
            class="logo__img"
            :src="$i18n.translate(logo.src)"
            :alt="$i18n.translate(logo.link.text)"
          >
        </a>
      </article>
    </section>
  </footer>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'
import AppGoogleMaps from '&/atoms/AppGoogleMaps'
import { getImagePath } from '@/utils/utils'

export default defineComponent({
  name: 'CustomFooterUZBrusselFoundation',
  components: {
    AppGoogleMaps
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  mixins: [PageRouteNavigation],
  data () {
    return {
      mapStyle: [
        {
          'featureType': 'administrative',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#444444'
            }
          ]
        },
        {
          'featureType': 'landscape',
          'elementType': 'all',
          'stylers': [
            {
              'color': '#f2f2f2'
            },
            {
              'visibility': 'on'
            }
          ]
        },
        {
          'featureType': 'landscape',
          'elementType': 'labels',
          'stylers': [
            {
              'visibility': 'on'
            }
          ]
        },
        {
          'featureType': 'poi',
          'elementType': 'all',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'poi.business',
          'elementType': 'all',
          'stylers': [
            {
              'visibility': 'on'
            },
            {
              'hue': '#ff0000'
            },
            {
              'saturation': '-100'
            }
          ]
        },
        {
          'featureType': 'road',
          'elementType': 'all',
          'stylers': [
            {
              'saturation': -100
            },
            {
              'lightness': 45
            }
          ]
        },
        {
          'featureType': 'road.highway',
          'elementType': 'all',
          'stylers': [
            {
              'visibility': 'simplified'
            }
          ]
        },
        {
          'featureType': 'road.arterial',
          'elementType': 'labels.icon',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'road.local',
          'elementType': 'all',
          'stylers': [
            {
              'visibility': 'on'
            }
          ]
        },
        {
          'featureType': 'transit',
          'elementType': 'all',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'water',
          'elementType': 'all',
          'stylers': [
            {
              'visibility': 'on'
            },
            {
              'color': '#a9a9a9'
            }
          ]
        }
      ],
      mapMarkers: [
        {
          position: { lat: 50.88751855387174, lng: 4.30789352742865 },
          icon: this.getImagePath('clients/uz_brussel_foundation/marker.png')
        }
      ]
    }
  },
  setup: () => ({ getImagePath }),
  computed: {
    ...mapGetters({
      client: 'client/client'
    }),
    attributes () {
      return _get(this.section, 'attributes', {})
    },
    logo () {
      return _get(this.attributes, 'logo', {})
    },
    links () {
      return _get(this.attributes, 'links', {})
    },
    social () {
      return _get(this.attributes, 'social', {})
    },
    contact () {
      return _get(this.attributes, 'contact', {})
    },
    networks () {
      const links = _get(this.client, 'platform.social_networks', [])
      return links.filter(link => this.$i18n.translate(link.url))
    }
  }
})
</script>

<style lang="scss" scoped>
.footer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @include mq(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  &__infos {
    padding: 40px;
    background-color: $color--tertiary;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: $margin__base * 2;
    color: $white;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
      padding: 100px;
      grid-gap: $margin__base * 3;
    }

    @include mq(md) {
      grid-template-columns: repeat(1, 1fr);
    }

    @include mq(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.title {
  &__h2 {
    font-size: rem(32px);
    font-weight: 700;
    color: $color--primary;
    margin-bottom: $margin__base * 2;
  }
}

.logo {
  &__img {
    max-width: 170px;
  }
}

.networks {
  &__ul {
    display: grid;
    grid-template-columns: repeat(5, 40px);
    grid-gap: $margin__base * 2;
    margin-bottom: $margin__base * 2;
  }

  &__a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: $white;
    color: $black;
    transition: all .3s;

    &:hover {
      background-color: $black;
      color: $white;
    }
  }
}
</style>
