<template>
  <form @keyup.enter="handleLogin()">
    <form-notification
      class="margin__t--12"
      :keys="error"
    />

    <div class="margin__t--24">
      <label
        class="input__label"
        for="login_email"
      >
        {{ t('literal.email') }} *
      </label>

      <input-email
        autocomplete="email"
        id="login_email"
        class="margin__t--6"
        v-model="form.email"
        :v="v$.email"
      />
    </div>

    <div class="margin__t--24">
      <label
        class="input__label"
        for="login_password"
      >
        {{ t('literal.password') }} *
      </label>

      <input-password
        class="margin__t--6"
        id="login_password"
        autocomplete="current-password"
        v-model="form.password"
        :v="v$.password"
      />
    </div>

    <div class="margin__t--6">
      <button
        type="button"
        class="margin__t--6 link__color--prim link--naked pointer"
        @click.prevent="emit('forgotPassword')"
      >
        {{ t('literal.forgot_password') }}
      </button>
    </div>

    <div class="btn__container">
      <app-save-button
        class="btn btn__size--medium btn__color--prim margin__t--36"
        :show-icon="false"
        :is-loading="loader"
        @click="handleLogin()"
      >
        {{ t('literal.login') }}
      </app-save-button>
    </div>
  </form>
</template>

<script setup>
import { defineAsyncComponent, reactive } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from '@/vendors/i18n'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'

import InputEmail from '&/atoms/InputEmail'
import InputPassword from '&/atoms/InputPassword'
import AppSaveButton from '&/molecules/AppSaveButton'

const FormNotification = defineAsyncComponent(() => import('&/molecules/FormNotification'))

const emit = defineEmits(['userAuthenticated', 'forgotPassword'])

const { t } = useI18n()
const { login } = useAuthenticationStore()
const { loader, error } = storeToRefs(useAuthenticationStore())

const form = reactive({
  email: null,
  password: null
})

const rules = {
  email: { required, email },
  password: { required, minLength: minLength(8) }
}

const v$ = useVuelidate(rules, form)

const handleLogin = () => {
  v$.value.$validate()
  if (v$.value.$invalid) return

  login({ params: form }).then(() => emit('userAuthenticated'))
}
</script>

<style scoped lang="scss">
.btn__container {
  justify-content: center;
}
</style>
