import i18n from '@/vendors/i18n'
import { truncate } from '@/utils/utils'

export default class Product {
  constructor ({ id = null, name = i18n.getLocalesTranslations(), is_free = false, price = 0, is_unlimited_stock = false, current_stock = 0, total_ordered = 0, is_limited_per_order = false, max_quantity_per_order = 0, display_on_page = false, display_options = null, image = i18n.getLocalesTranslations(), target_id = null, target_type = null } = {}) {
    this.id = id
    this.name = name
    this.is_free = is_free
    this.price = price
    this.is_unlimited_stock = is_unlimited_stock
    this.current_stock = current_stock
    this.total_ordered = total_ordered
    this.is_limited_per_order = is_limited_per_order
    this.max_quantity_per_order = max_quantity_per_order
    this.display_on_page = display_on_page
    this.display_options = display_options
    this.image = image
    this.target_id = target_id
    this.target_type = target_type
  }

  get full_name () {
    return i18n.translate(this.name) || ''
  }

  get has_long_name () {
    return this.full_name.length > 50
  }

  get short_name () {
    return truncate(this.full_name, 50)
  }

  get is_in_stock () {
    return this.current_stock > 0 || this.is_unlimited_stock
  }

  get max_quantity () {
    if (this.is_unlimited_stock) return this.is_limited_per_order ? this.max_quantity_per_order : null
    if (this.is_limited_per_order && this.max_quantity_per_order < this.current_stock) return this.max_quantity_per_order

    return this.current_stock
  }

  get cover () {
    return i18n.translate(this.image)
  }
}