export default [
  {
    name: 'custom_header_vijvens',
    path: 'modules/pages/vijvens/HeaderVijvens'
  },
  {
    name: 'homepage_vijvens',
    path: 'modules/pages/vijvens/HomepageVijvens'
  },
  {
    name: 'custom_footer_vijvens',
    path: 'modules/pages/vijvens/FooterVijvens'
  }
]
