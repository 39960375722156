const Preview = () => import(/* webpackChunkName: 'Preview' */'@/views/preview/ViewPreview')

const routes = [
  {
    path: 'preview/:category/:templateId',
    name: 'Preview',
    component: Preview,
    props: true,
    meta: {
      layout: 'full'
    }
  }
]

export default routes
