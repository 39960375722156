<template>
  <div>
    <header
      class="header"
      :class="{'header--fixed': mobileMenuOpened}"
      v-if="section"
    >
      <div class="wrapper d-none d-md-flex justify-content-end padding__tb--12">
        <language-switcher
          class="header__language margin__r--12"
          v-if="isMultilingual"
        />

        <button
          @click.prevent="openSessionModal('register')"
          class="header__session"
          v-if="!isAuthenticated"
        >
          <fa-icon
            class="margin__r--6"
            :icon="['fas', 'user']"
          />
          <span v-html="$i18n.t('literal.login')" />
        </button>

        <router-link
          class="header__session header__session--user d-flex align-items-center"
          :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
          v-if="isAuthenticated && user"
        >
          <user-profile-img
            size="2"
            :avatar="user.avatar"
          />

          <span class="d-block margin__l--6">{{ user.firstname }}</span>
        </router-link>
      </div>

      <div class="wrapper header__wrapper">
        <div class="header__cell">
          <router-link
            :to="{ name: 'Home', params: { locale: $i18n.locale } }"
            v-show="client.logo.url"
            class="logo"
          >
            <img
              class="logo__img"
              :src="client.logo.url"
              :alt="client.name"
            >
          </router-link>

          <nav
            v-if="navigation.length > 0"
          >
            <ul class="header__menu">
              <li
                class="header__menu-item"
                v-for="(item, index) in navigation"
                :key="`menu_item_${index}`"
              >
                <a
                  class="header__menu-link"
                  :class="{'header__menu-link--active': isVisited(item.link)}"
                  @click.prevent="setRouteNavigation(item.link); mobileMenuOpened = false"
                >
                  {{ $i18n.translate(item.link.text) }}

                  <img
                    class="header__menu-logo"
                    :src="$i18n.translate(item.logo)"
                    :alt="$i18n.translate(item.link.text)"
                    v-if="$i18n.translate(item.logo)"
                  >
                </a>

                <ul
                  v-if="item.sublinks && item.sublinks.length > 0"
                  class="header__menu-submenu padding--12"
                >
                  <li
                    class="header__menu-subitem"
                    v-for="(sublink, i) in item.sublinks"
                    :key="`menu_item_${index}_${i}`"
                  >
                    <a
                      class="header__menu-sublink padding__tb--6"
                      :class="{'header__menu-sublink--active': isVisited(sublink.link)}"
                      @click.prevent="setRouteNavigation(sublink.link); mobileMenuOpened = false"
                    >
                      {{ $i18n.translate(sublink.link.text) }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>

        <div class="header__cell">
          <nav
            v-if="actions.length > 0"
          >
            <ul class="header__actions">
              <li
                class="header__actions-item"
                v-for="(item, index) in actions"
                :key="`actions_item_${index}`"
              >
                <a
                  class="header__actions-link"
                  :class="{'header__actions-link--active': isVisited(item.link)}"
                  @click.prevent="setRouteNavigation(item.link); mobileMenuOpened = false"
                >
                  {{ $i18n.translate(item.link.text) }}
                </a>
              </li>
            </ul>
          </nav>

          <app-burger-menu
            class="header__burger d-lg-none"
            :state="mobileMenuOpened"
            @toggle="toggleMobileMenu"
          />
        </div>
      </div>

      <transition name="fade-mobile-menu">
        <section
          class="mobile"
          v-if="mobileMenuOpened"
        >
          <div class="wrapper mobile__wrapper">
            <ul
              class="mobile__menu"
              v-if="navigation.length > 0"
            >
              <li
                class="mobile__menu-item"
                v-for="(item, index) in navigation"
                :key="`menu_mobile_item_${index}`"
              >
                <a
                  class="mobile__menu-link"
                  :class="{'mobile__menu-link--active': isVisited(item.link)}"
                  @click.prevent="setRouteNavigation(item.link); mobileMenuOpened = false"
                >
                  {{ $i18n.translate(item.link.text) }}

                  <img
                    class="mobile__menu-logo"
                    :src="$i18n.translate(item.logo)"
                    :alt="$i18n.translate(item.link.text)"
                    v-if="$i18n.translate(item.logo)"
                  >
                </a>

                <ul
                  v-if="item.sublinks && item.sublinks.length > 0"
                  class="mobile__menu-submenu padding--6"
                >
                  <li
                    class="mobile__menu-subitem"
                    v-for="(sublink, i) in item.sublinks"
                    :key="`menu_item_${index}_${i}`"
                  >
                    <a
                      class="mobile__menu-sublink padding__tb--6"
                      :class="{'mobile__menu-sublink--active': isVisited(sublink.link)}"
                      @click.prevent="setRouteNavigation(sublink.link); mobileMenuOpened = false"
                    >
                      {{ $i18n.translate(sublink.link.text) }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>

            <ul
              class="mobile__actions d-md-none"
              v-if="actions.length > 0"
            >
              <li
                class="mobile__actions-item"
                v-for="(item, index) in actions"
                :key="`actions_mobile_item_${index}`"
              >
                <a
                  class="mobile__actions-link bold"
                  @click.prevent="setRouteNavigation(item.link); mobileMenuOpened = false"
                >
                  {{ $i18n.translate(item.link.text) }}
                </a>
              </li>
            </ul>

            <hr class="separator separator__size--full border__color--dw margin__tb--12 d-md-none">

            <ul class="mobile__sessions d-md-none">
              <li
                class="mobile__sessions-item"
                v-if="!isAuthenticated"
              >
                <a
                  @click.prevent="openSessionModal('login')"
                  class="link--naked pointer"
                >
                  <fa-icon
                    class="margin__r--6"
                    :icon="['fas', 'user']"
                    fixed-width
                  />
                  {{ $i18n.t('literal.login') }}
                </a>
              </li>

              <li
                class="mobile__sessions-item pointer"
                v-if="isAuthenticated && user"
              >
                <router-link
                  :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
                  @click.capture="mobileMenuOpened = false"
                  class="d-flex align-items-center"
                >
                  <user-profile-img
                    class="margin__r--6"
                    :avatar="user.avatar"
                    size="2"
                  />
                  {{ user.firstname }}
                </router-link>
              </li>

              <li
                class="mobile__sessions-item mobile__sessions-item--logout pointer"
                v-if="isAuthenticated && user"
              >
                <router-link
                  :to="{ name: 'Logout' }"
                  class="link--naked pointer link__color--danger"
                >
                  <fa-icon
                    class="margin__r--6 color--danger"
                    :icon="['fas', 'sign-out']"
                    fixed-width
                  />
                  {{ $i18n.t('literal.logout') }}
                </router-link>
              </li>

              <li v-if="isMultilingual">
                <language-switcher v-slot="{ options, updateLanguage, isActive }">
                  <ul class="languages__ul">
                    <li
                      class="languages__li"
                      v-for="language in options"
                      :key="language.id"
                    >
                      <button
                        class="languages__a"
                        :class="{'active': isActive(language.id)}"
                        type="button"
                        @click.prevent="updateLanguage(language.id)"
                      >
                        {{ language.label.value.toUpperCase() }}
                      </button>
                    </li>
                  </ul>
                </language-switcher>
              </li>
            </ul>

            <hr class="separator separator__size--full border__color--dw margin__tb--12 d-md-none">
          </div>
        </section>
      </transition>
    </header>

    <app-modal
      v-if="showSessionModal"
      size="sm"
      @close="showSessionModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="template"
          @authenticated="showSessionModal = false"
        />
      </template>
    </app-modal>
  </div>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import { get as _get } from 'lodash'
import { mapGetters } from 'vuex'
import PageRouteNavigation from '%/PageRouteNavigation'
import UserProfileImg from '&/atoms/UserProfileImg'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
import { scrollTo } from '@/utils/utils'

export default defineComponent({
  name: 'CustomHeaderRikolto',
  components: {
    UserProfileImg,
    AppBurgerMenu,
    LanguageSwitcher: defineAsyncComponent(() => import('&/atoms/LanguageSwitcher')),
    SessionForms: defineAsyncComponent(() => import('&/modules/sessions/SessionForms')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      showSessionModal: false,
      mobileMenuOpened: false,
      template: null
    }
  },
  setup: () => ({ scrollTo }),
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    navigation () {
      return _get(this.section, 'attributes.navigation', [])
    },
    actions () {
      return _get(this.section, 'attributes.actions', [])
    },
    isMultilingual () {
      return this.client.active_languages.length > 1
    }
  },
  methods: {
    openSessionModal (template) {
      this.template = template
      this.showSessionModal = true
    },
    toggleMobileMenu (value) {
      const delay = !value ? 300 : 0
      this.scrollTo()

      setTimeout(() => {
        this.mobileMenuOpened = !value
      }, delay)
    }
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #9EAB05;
$color--secondary: #131415;

.wrapper {
  max-width: 1310px;
  margin: 0 auto;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
}

.logo {
  display: block;
  position: relative;
  width: 130px;

  @include mq(md) {
    margin-right: $margin__base;
  }

  @include mq(xl) {
    width: 140px;
    margin-right: $margin__base * 3;
  }

  &__img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.header {
  background: $white;
  position: relative;
  z-index: 999;

  &--fixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;

    @include mq(lg) {
      position: relative;
    }
  }

  &__session {
    font-size: rem(14px);

    svg {
      color: rgba(#8E8B8B, 0.74);
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: $padding__base * 1.5;
    padding-bottom: $padding__base * 1.5;
    position: relative;

    @include mq(sm) {
      padding-top: $padding__base * 2.5;
      padding-bottom: $padding__base * 2.5;
    }

    &::before {
      content: '';
      border-top: 1px solid #979797;
      width: calc(100% - 40px);
      display: block;
      position: absolute;
      top: 0;
      left: 20px;
    }
  }

  &__cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__menu {
    display: none;

    @include mq(lg) {
      display: flex;
      align-items: center;
    }

    @include mq(xl) {
      font-size: rem(18px);
    }

    &-item {
      position: relative;

      &:not(:last-child) {
        margin-right: $margin__base;

        @include mq(xl) {
          margin-right: $margin__base * 1.5;
        }
      }

      &:hover {
        .header__menu-submenu {
          display: block;
        }
      }
    }

    &-link {
      position: relative;
      display: flex;
      align-items: center;
      color: $color--secondary;
      padding: 10px 0;
      transition: all .2s;

      &:hover, &--active {
        color: $color--primary;
      }
    }

    &-logo {
      height: 10px;
      display: block;
      width: auto;
      margin-left: 4px;
    }

    &-submenu {
      display: none;
      position: absolute;
      z-index: 999;
      background: $white;
      font-size: rem(14px);
      min-width: 160px;
      text-align: center;
      left: 50%;
      top: 40px;
      transform: translateX(-50%);
      box-shadow: 0 2px 11px rgba(#000000, 0.09);
    }

    &-subitem {
      &:not(:last-child) {
        border-bottom: 1px solid rgba(#979797, 0.5);
      }
    }

    &-sublink {
      line-height: 1.5;
      color: #131415;
      display: block;
      transition: .2s all;

      &:hover,
      &--active {
        color: $color--primary;
      }
    }
  }

  &__actions {
    display: none;

    @include mq(sm) {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 1;
    }

    @include mq(md) {
      font-size: 16px;
    }

    @include mq(lg) {
       font-size: 18px;
    }

    &-item {
      &:not(:last-child) {
        margin-right: $margin__base * 2;
      }

      &:nth-child(even) {
        .header__actions {
          &-link {
            background: $color--primary;
            color: $white;

            &:hover {
              background: darken($color--primary, 5);
              border-color: darken($color--primary, 5);
            }
          }
        }
      }
    }

    &-link {
      font-family: $font-family--secondary;
      display: block;
      color: $color--primary;
      padding: 16px;
      border: 2px solid $color--primary;
      line-height: 1;
      transition: all .2s;
      font-weight: 700;
      transition: all .2s;
      text-align: center;

      &:hover {
        background: $color--primary;
        color: $white;
      }
    }
  }

  &__burger {
    color: $color--primary;
    line-height: 1;

    & :deep() {
      .menu__line {
        background-color: $color--primary;
      }

      .menu__close::before, .menu__close::after {
        background-color: $color--primary;
      }
    }
  }
}

.mobile {
  position: fixed;
  top: 80px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 80px);
  background: $white;
  z-index: 999;
  text-align: center;

  .languages {
    &__ul {
      display: flex;
      position: relative;
      padding-left: 18px;
    }

    &__li {
      padding: 0 6px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:not(:last-child):after {
        content: '';
        display: block;
        height: 15px;
        width: 1px;
        margin-left: 12px;
        background-color: $color--primary;
      }
    }

    &__a {
      display: block;
      text-transform: uppercase;
      font-size: 14px;
      transition: all .3s;
      line-height: 1;
      border-bottom: 2px solid transparent;

      &.active {
        padding-bottom: 2px;
        border-bottom: 1px solid $color--primary;
      }
    }
  }

  @include mq(sm) {
    top: 110px;
    height: calc(100vh - 110px);
  }

  @include mq(md) {
    top: 156px;
    height: calc(100vh - 156px);
  }

  @include mq(lg) {
    display: none;
  }

  &__wrapper {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__menu {
    font-size: rem(20px);
    flex: 1 1 auto;
    margin-bottom: $margin__base * 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include mq(sm) {
      font-size: rem(32px);
    }

    &-link {
      color: $color--secondary;
      padding: 10px 0;
      display: inline-block;
      position: relative;
      line-height: 1;

      &--active {
        color: $color--primary;
      }
    }

     &-logo {
      height: 10px;
      display: block;
      width: auto;
      margin: 4px auto 0;
    }

    &-submenu {
      font-size: rem(14px);

      @include mq(sm) {
        font-size: rem(20px);
      }

      @include mq(md) {
        font-size: rem(16px);
      }
    }

    &-subitem {
      padding: calc($padding__base / 2) 0;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(#979797, 0.5);
      }
    }

    &-sublink {
      &--active {
        color: $color--primary;
      }
    }
  }

  &__actions {
    font-size: rem(16px);

    @include mq(sm) {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: rem(18px);
    }

    &-item {
      &:not(:last-child) {
        margin-bottom: $margin__base;

        @include mq(sm) {
          margin: 0 20px 0 0;
        }
      }

      &:nth-child(even) {
        .mobile__actions {
          &-link {
            color: $white;
            background: $color--primary;
            border-color: $color--primary;
          }
        }
      }
    }

    &-link {
      display: block;
      color: $color--primary;
      padding: $padding__base * 1.5;
      border: 2px solid $color--primary;
      line-height: 1;
      transition: all .2s;

      &:hover {
        background: $color--secondary;
      }
    }
  }

  &__sessions {
    color: $color--secondary;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      color: $color--secondary;
    }

    svg {
      color: rgba(#8E8B8B, 0.74);
    }

    &-item {
      &:not(:last-child) {
        margin-right: $margin__base * 2;
      }
    }
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}

.fade-mobile-menu-enter-active, .fade-mobile-menu-leave-active {
  transition: opacity .5s;
}
.fade-mobile-menu-enter, .fade-mobile-menu-leave-to {
  opacity: 0;
}
</style>
