import { computed, toValue } from 'vue'
import { storeToRefs } from 'pinia'
import { useSecureStore } from '@/stores/SecureStore'
import { useProcedureStore } from '@/stores/ProcedureStore'
import { useProcedure, useProcedureExecution, useProcedureTransaction } from '@/composables/modules/procedures'
import { useStripe, useSentry } from '@/vendors/integrations'
import { useReCaptcha } from 'vue-recaptcha-v3'
import { get as _get, set as _set } from 'lodash'

export function useProcedureToken ({ procedure, procedure_execution }) {
  const { error } = storeToRefs(useProcedureStore())
  const { getProcedureSecureToken } = useSecureStore()
  const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
  const { slug, paymentProvider } = useProcedure({ procedure })
  const { paymentMethod } = useProcedureExecution({ procedure, procedure_execution })
  const { paymentMethodInformations } = useProcedureTransaction({ procedure, procedure_execution })
  const stripe = useStripe()
  const sentry = useSentry()

  const { procedure: procedureSecure } = getProcedureSecureToken({ id: slug })

  const secureToken = computed(() => _get(procedureSecure.value, 'token'))

  const createRecaptchaToken = async () => {
    await recaptchaLoaded()
    return await executeRecaptcha('login')
  }

  const set3dSecureToken = () => new Promise((resolve, reject) => {
    if (!['stripe', 'stripe_standard_connect'].includes(paymentProvider.value) || paymentMethod.value !== 'three_d_secure') return resolve()

    stripe.createPaymentMethod({ type: 'card', card: stripe.getElement('cardNumber') })
      .then(payment_method => resolve(paymentMethodInformations.value = { three_d_secure: { payment_method } }))
      .catch(error => {
        sentry.error(Error('#001502 - Create Stripe 3D Secure token failed'))
        reject(error)
      })
  })

  const setRecaptchaToken = () => new Promise((resolve, reject) => {
    if (!_get(toValue(procedure), 'actions.recaptcha')) return resolve()

    createRecaptchaToken()
      .then(token => resolve(_set(toValue(procedure_execution), 'actions_data.recaptcha.recaptcha_response', token)))
      .catch(error => reject(error))
  })

  const setSecureToken = () => _set(toValue(procedure_execution), 'token', secureToken.value)

  const setTokens = () => Promise.all([set3dSecureToken(), setRecaptchaToken(), setSecureToken()])
    .catch(e => error.value = e)
    .finally(() => stripe.clearElements())

  return {
    secureToken,
    setTokens
  }
}