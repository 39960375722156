<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-sm-10 col-lg-8">
        <section class="faq editor">
          <button
            class="faq__question"
            @click.prevent="is_opened = !is_opened"
          >
            <fa-icon
              class="icon margin__r--12 color--prim"
              :class="{'icon--opened': is_opened}"
              :icon="['fal', 'chevron-right']"
              size="xs"
            />
            <span v-html="$i18n.translate(section.attributes.question || section.attributes.title)" />
          </button>
          <div
            v-if="is_opened"
            class="faq__answer editor__content"
            v-html="$i18n.translate(section.attributes.answer || section.attributes.text)"
          />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SectionFaqQuestion',
  data () {
    return {
      is_opened: false
    }
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  }
})
</script>

<style lang="scss" scoped>
  .faq {
    padding: $padding__base 0;
    border-bottom: 1px solid $dw;

    &__question {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-weight: bold;
      text-align: left;
    }

    &__answer {
      margin-top: calc($margin__base / 2);
      margin-left: 20px;
    }
  }

  .icon {
    transition: all .3s;

    &--opened {
      transform: rotate(90deg);
    }
  }
</style>
