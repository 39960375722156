import { computed, toValue } from 'vue'
import { useProcedureStep } from '@/composables/modules/procedures'

export function useProcedureSections ({ procedure, procedure_execution }) {
  const { step } = useProcedureStep({ procedure })
  const sections = computed(() => step.value?.sections.getAvailableSections(toValue(procedure_execution)) || [])

  return {
    sections
  }
}