<template>
  <div>
    <header
      class="header"
      :class="{'header--fixed': mobileMenuOpened}"
      v-if="section"
    >
      <div class="wrapper">
        <div class="header__nav header__nav--right header__nav--user">
          <language-switcher class="header__language" />

          <ul class="header__sessions">
            <li
              class="header__sessions-item"
              v-if="!isAuthenticated"
            >
              <button
                @click.prevent="openSessionAppModal('register')"
                class="header__sessions-register bold"
              >
                <fa-icon
                  class="margin__r--6"
                  :icon="['fas', 'user']"
                />
                <p v-html="$i18n.t('literal.register')" />
              </button>
            </li>

            <li v-if="isAuthenticated && user">
              <router-link
                class="header__sessions-user"
                :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
              >
                <user-profile-img
                  :avatar="user.avatar"
                  size="2"
                />
              </router-link>
            </li>
          </ul>
        </div>

        <div class="header__nav header__nav--spacing">
          <div class="header__nav">
            <router-link
              :to="{ name: 'Home', params: { locale: $i18n.locale } }"
              v-show="client.logo.url"
              class="header__logo"
            >
              <img
                :src="client.logo.url"
                :alt="client.name"
              >
            </router-link>

            <ul class="nav">
              <li
                class="nav__el"
                v-for="(link, index) in availableLinks"
                :key="`nav__el-${index}`"
              >
                <button
                  @click.prevent="setRouteNavigation(link)"
                  class="nav__link"
                  :class="{'nav__link--active': isVisited(link)}"
                >
                  {{ $i18n.translate(link.text) }}
                </button>
              </li>
            </ul>
          </div>

          <div class="header__nav header__nav--right">
            <ul class="header__actions">
              <template v-for="(cta, index) in section.attributes.actions">
                <li
                  class="header__actions-item bold"
                  v-if="cta.show"
                  :key="`cta_${index}`"
                >
                  <button
                    @click.prevent="setRouteNavigation(cta)"
                    class="header__button"
                    v-if="cta"
                    :disabled="!isActive(cta)"
                  >
                    {{ $i18n.translate(cta.text) }}
                  </button>
                </li>
              </template>
            </ul>

            <app-burger-menu
              class="header__burger"
              :state="mobileMenuOpened"
              @toggle="toggleMobileMenu"
            />
          </div>
        </div>
      </div>

      <transition name="fade-mobile-menu">
        <section
          class="mobile__menu"
          v-if="section && mobileMenuOpened"
        >
          <div class="wrapper">
            <ul class="mobile__list mobile__nav">
              <li
                v-for="(link, index) in availableLinks"
                :key="`mobile_menu_item_${index}`"
              >
                <a
                  @click.prevent="setRouteNavigation(link); mobileMenuOpened = false"
                  class="link--naked mobile__nav-item"
                  :class="{'mobile__nav-item--active': isVisited(link)}"
                >
                  {{ $i18n.translate(link.text) }}
                </a>
              </li>
            </ul>

            <ul class="mobile__list mobile__actions">
              <li
                class="mobile__actions-item bold"
                v-for="(cta, index) in section.attributes.actions"
                :key="`cta_${index}`"
              >
                <button
                  @click.prevent="setRouteNavigation(cta); mobileMenuOpened = false"
                  v-if="cta"
                  :disabled="!isActive(cta)"
                >
                  {{ $i18n.translate(cta.text) }}
                </button>
              </li>
            </ul>

            <ul class="mobile__list mobile__sessions">
              <li v-if="!isAuthenticated">
                <a @click.prevent="openSessionAppModal('login')">
                  <fa-icon
                    class="margin__r--6 mobile__icon"
                    :icon="['fal', 'sign-in']"
                    fixed-width
                  />
                  {{ $i18n.t('literal.login') }}
                </a>
              </li>

              <li
                class="mobile__sessions-item header__sessions-item"
                v-if="!isAuthenticated"
              >
                <a
                  @click.prevent="openSessionAppModal('register')"
                  class="link__color--black link--naked pointer"
                >
                  <fa-icon
                    class="margin__r--6 mobile__icon"
                    :icon="['fal', 'user-plus']"
                    fixed-width
                  />
                  {{ $i18n.t('literal.register') }}
                </a>
              </li>

              <li
                class="mobile__sessions-item header__sessions-item pointer"
                v-if="isAuthenticated && user"
              >
                <router-link
                  :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
                  @click.capture="mobileMenuOpened = false"
                >
                  <user-profile-img
                    size="3"
                    :avatar="user.avatar"
                  />
                </router-link>
              </li>

              <li
                class="mobile__sessions-item header__sessions-item pointer"
                v-if="isAuthenticated && user"
              >
                <router-link :to="{ name: 'Logout' }">
                  <fa-icon
                    class="margin__r--6 color--danger"
                    :icon="['fal', 'sign-out']"
                    fixed-width
                  />
                  {{ $i18n.t('literal.logout') }}
                </router-link>
              </li>
            </ul>

            <language-switcher class="mobile__list mobile__language" />
          </div>
        </section>
      </transition>
    </header>

    <app-modal
      v-if="showSessionAppModal"
      size="sm"
      @close="showSessionAppModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="template"
          @authenticated="showSessionAppModal = false"
        />
      </template>
    </app-modal>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'
import UserProfileImg from '&/atoms/UserProfileImg'
import PageRouteNavigation from '%/PageRouteNavigation'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
import LanguageSwitcher from '&/atoms/LanguageSwitcher'

export default defineComponent({
  name: 'CustomHeaderAZV',
  components: {
    UserProfileImg,
    AppBurgerMenu,
    LanguageSwitcher,
    SessionForms: defineAsyncComponent(() => import('&/modules/sessions/SessionForms')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      showSessionAppModal: false,
      mobileMenuOpened: false,
      template: null
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    availableLinks () {
      return this.section.attributes.links.filter(link => link.text[this.$i18n.locale])
    }
  },
  methods: {
    openSessionAppModal (template) {
      this.template = template
      this.showSessionAppModal = true
    },
    toggleMobileMenu (value) {
      this.mobileMenuOpened = !value
    }
  }
})
</script>

<style lang="scss" scoped>
  $color-principal: #adab00;
  $color-principal-darker: #797700;
  $color-second: #438382;
  $color-ter: #625731;

  header {
    font-family: $font-family--primary;
    font-size: rem(16px);
  }

  .wrapper {
    padding: calc($padding__base / 2) $padding__base * 3;

    @include mq(sm) {
      padding: calc($padding__base / 2) $padding__base * 3;
    }

    @include mq(md) {
      padding: $padding__base $padding__base * 5;
    }

    @include mq(lg) {
      padding: $padding__base $padding__base * 10;
    }
  }

  .header {
    background-color: rgba($white, .95);
    box-shadow: 0 4px 8px 0 rgba(82, 97, 115, 0.1);

    &--fixed {
      background-color: $white;
      left: 0;
      height: 100vh;
      position: fixed;
      top: 0;
      width: 100vw;
      z-index: 1000;

      @include mq(md) {
        height: initial;
      }
    }

    &__nav {
      align-items: center;
      display: flex;

      &--right {
        justify-content: flex-end;
      }

      &--spacing {
        justify-content: space-between;
      }

      &--user {
        margin-bottom: $margin__base;
      }
    }

    &__logo {
      margin-right: $margin__base*3;

      & img {
        display: block;
        height: auto;
        max-height: 50px;
        max-width: 120px;
        width: 100%;

        @include mq(sm) {
          max-width: 200px;
        }

        @include mq(lg) {
          max-height: 65px;
        }
      }
    }

    &__language {
      display: none;

      @include mq(md) {
        display: inherit;
      }
    }

    &__menu {
      display: none;
      margin-right: $margin__base * 4;

      @include mq(md) {
        display: flex;
      }

      &-item {
        &:not(:first-child) {
          margin-left: $margin__base * 4;
        }
      }
    }

    &__sessions {
      display: none;
      margin-left: $margin__base * 2;

      @include mq(md) {
        display: flex;
        justify-content: flex-end;
      }

      &-register {
        align-items: center;
        display: flex;
        white-space: nowrap;

        &:hover,
        &:focus {
          color: $color--primary;
          outline: 0;
          transition: color $transition__duration ease;
        }
      }

      &-user {
        display: block;
        margin-bottom: 0;
      }
    }

    &__actions {
      display: none;

      @include mq(md) {
        align-items: center;
        display: flex;
      }

      &-item {
        color: $color-ter;
        transition: color $transition__duration;
        white-space: nowrap;

        &:hover,
        &:focus {
          color: $color-principal;
        }

        &:not(:last-child) {
          margin-right: $margin__base*2;
        }

        &:last-child {
          background-color: $color-principal;
          border-radius: $radius__buttons;
          color: $white;
          display: inline-block;
          padding: $padding__base $padding__base*2;
          transition: background-color $transition__duration;

          &:hover,
          &:focus {
            background-color: $color-principal-darker;
          }
        }
      }
    }

    &__burger {
      display: inherit;

      @include mq(xl) {
        display: none;
      }
    }
  }

  .nav {
    display: none;

    @include mq(xl) {
      display: flex;

      &__el {
        padding: 2px;

        &:not(:first-of-type) {
          margin-left: $margin__base;
        }
      }

      &__link {
        color: $color-ter;
        transition: color $transition__duration;

        &:hover,
        &:focus {
          color: $color-principal;
        }

        &--active {
          color: $color-principal;
          pointer-events: none;
        }
      }
    }
  }

  .mobile {
    @include mq(xl) {
      &__menu {
        display: none;
      }
    }

    &__list {
      @include mq(md) {
        align-items: center;
        display: flex;
        justify-content: space-evenly;
      }

      &:not(:first-child) {
        margin-top: $margin__base * 3;
      }

      & li:not(:first-child) {
        margin-top: calc($margin__base / 2);
      }
    }

    &__actions {
      @include mq(md) {
        display: none;
      }

      &-item {
        transition: color $transition__duration;
        white-space: nowrap;

        &:hover,
        &:focus {
          color: $color-principal;
        }

        &:not(:last-child) {
          margin-bottom: calc($margin__base / 2);
        }

        &:last-child {
          color: $white;
          display: inline-block;
          background-color: $color-principal;
          border-radius: $radius__buttons;
          padding: $padding__base $padding__base * 2;
          transition: background-color $transition__duration;

          &:hover,
          &:focus {
            background-color: $color-ter;
          }
        }
      }
    }

    &__nav-item {
        transition: color $transition__duration;
        &:hover,
        &:focus {
          color: $color-principal;
        }

      &--active {
        color: $color-principal;
        pointer-events: none;
      }
    }

    @include mq(md) {
      &__sessions {
        display: none;
      }
    }

    &__language {
      display: block;

      @include mq(md) {
        display: none;
      }
    }

    &__icon {
      color: $color-principal;
    }
  }

  .fade-mobile-menu-enter-active {
    animation: fadeTranslationY $transition__duration ease-in-out;
  }

  .fade-mobile-menu-leave-active {
    animation: fadeTranslationY $transition__duration ease-in-out reverse;
  }

  @keyframes fadeTranslationY {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }

    to {
      opacity: 100%;
      transform: translateY(0px);
    }
  }

  .modal__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
</style>
