<template>
  <div>
    <header
      class="header"
      :class="{'header--fixed': mobileMenuOpened}"
      v-if="section"
    >
      <div class="header__container container">
        <section class="header__cell">
          <router-link
            :to="{ name: 'Home', params: { locale: $i18n.locale } }"
            v-show="client.logo.url"
          >
            <img
              class="logo__img"
              :src="client.logo.url"
              :alt="client.name"
            >
          </router-link>
        </section>

        <section class="header__cells">
          <div class="header__cells--session">
            <ul class="header__sessions">
              <li
                class="header__sessions-item"
                v-if="!isAuthenticated"
              >
                <button
                  @click.prevent="openSessionAppModal('login')"
                  class="link__color--mg link--naked pointer bold"
                >
                  <fa-icon
                    class="margin__r--6"
                    :icon="['fas', 'user']"
                    fixed-width
                  />

                  {{ $i18n.t('literal.login') }}
                </button>
              </li>

              <li v-if="isAuthenticated && user">
                <router-link
                  class="header__sessions-item"
                  :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
                >
                  <user-profile-img :avatar="user.avatar" />

                  <div class="margin__l--6">
                    <p>{{ user.firstname }}</p>
                    <p class="link__see">
                      {{ $i18n.t('literal.see_profile') }}
                    </p>
                  </div>
                </router-link>
              </li>
            </ul>

            <language-switcher
              class="header__language"
              v-if="isMultilingual"
            />
          </div>

          <div class="header__cells--menu">
            <ul class="header__menu">
              <li
                class="header__menu-item"
                v-for="(item, index) in links"
                :key="`menu_item_${index}`"
              >
                <button
                  class="header__link link__color--sec link--naked"
                  :class="{'header__link--active': isVisited(item.link)}"
                  @click.prevent="setRouteNavigation(item.link)"
                >
                  {{ $i18n.translate(item.link.text) }}
                </button>

                <ul
                  class="header__submenu"
                  v-if="item.submenu && item.submenu.length"
                >
                  <li
                    class="header__menu-subitem"
                    v-for="({ link }, index2) in item.submenu"
                    :key="`menu_item_${index2}`"
                  >
                    <button
                      class="header__link link__color--sec link--naked"
                      :class="{'header__link--active': isVisited(link)}"
                      @click.prevent="setRouteNavigation(link)"
                    >
                      {{ $i18n.translate(link.text) }}
                    </button>
                  </li>
                </ul>
              </li>
            </ul>

            <ul
              class="header__actions"
              v-if="actions.length"
            >
              <template
                v-for="({ link }, index) in actions"
                :key="`cta_${index}`"
              >
                <li class="header__actions-item">
                  <button
                    class="btn btn__size--classic btn__color--prim"
                    v-if="link.show"
                    :disabled="!isActive(link)"
                    @click.prevent="setRouteNavigation(link)"
                  >
                    {{ $i18n.translate(link.text) }}
                  </button>
                </li>
              </template>
            </ul>

            <app-burger-menu
              class="header__burger"
              :state="mobileMenuOpened"
              @toggle="toggleMobileMenu"
            />
          </div>
        </section>
      </div>

      <transition name="fade-mobile-menu">
        <section
          class="header__cell mobile__menu"
          v-if="section && mobileMenuOpened"
        >
          <ul class="mobile__sessions">
            <li
              class="mobile__sessions-item header__sessions-item"
              v-if="!isAuthenticated"
            >
              <a
                @click.prevent="openSessionAppModal('login')"
                class="link__color--black link--naked pointer"
              >
                <fa-icon
                  class="margin__r--6 color--prim"
                  :icon="['fal', 'sign-in']"
                  fixed-width
                />

                {{ $i18n.t('literal.login') }}
              </a>
            </li>

            <li
              class="mobile__sessions-item header__sessions-item"
              v-if="!isAuthenticated"
            >
              <a
                @click.prevent="openSessionAppModal('register')"
                class="link__color--black link--naked pointer"
              >
                <fa-icon
                  class="margin__r--6 color--prim"
                  :icon="['fal', 'user-plus']"
                  fixed-width
                />

                {{ $i18n.t('literal.register') }}
              </a>
            </li>

            <li
              v-if="isAuthenticated && user"
              @click.prevent="mobileMenuOpened = false"
            >
              <router-link
                class="mobile__sessions-item"
                :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
              >
                <user-profile-img
                  :avatar="user.avatar"
                  size="4"
                />

                <div class="margin__l--6">
                  <p>{{ user.firstname }}</p>

                  <p class="link__see">
                    {{ $i18n.t('literal.see_profile') }}

                    <fa-icon
                      class="margin__l--6"
                      size="xs"
                      :icon="['fal', 'chevron-right']"
                    />
                  </p>
                </div>
              </router-link>
            </li>

            <li
              class="mobile__sessions-item header__sessions-item pointer"
              v-if="isAuthenticated && user"
            >
              <router-link :to="{ name: 'Logout' }">
                <fa-icon
                  class="margin__r--6 color--danger"
                  :icon="['fal', 'sign-out']"
                  fixed-width
                />

                {{ $i18n.t('literal.logout') }}
              </router-link>
            </li>
          </ul>

          <ul class="mobile__list">
            <li
              class="mobile__list-item"
              v-for="(item, index) in links"
              :key="`mobile_menu_item_${index}`"
            >
              <a
                class="mobile__list-link link__color--dg link--naked"
                :class="{'mobile__link--active': isVisited(item.link)}"
                @click.prevent="setRouteNavigation(item.link); mobileMenuOpened = false"
              >
                {{ $i18n.translate(item.link.text) }}
              </a>

              <ul
                class="mobile__submenu"
                v-if="item.submenu && item.submenu.length"
              >
                <li
                  class="mobile__submenu-item"
                  v-for="({ link }, index2) in item.submenu"
                  :key="`menu_item_${index2}`"
                >
                  <img
                    :src="getImagePath('clients/ulb/arrow.svg')"
                    alt="Navigation arrows"
                  >

                  <a
                    class="mobile__list-link link__color--dg link--naked"
                    :class="{'mobile__link--active': isVisited(link)}"
                    @click.prevent="setRouteNavigation(link); mobileMenuOpened = false"
                  >
                    {{ $i18n.translate(link.text) }}
                  </a>
                </li>
              </ul>
            </li>

            <li class="mobile__list-item">
              <div class="mobile__list-link mobile__list-language">
                <language-switcher v-if="isMultilingual" />
              </div>
            </li>
          </ul>

          <ul class="mobile__actions">
            <li
              class="mobile__actions-item"
              v-for="({ link }, index) in actions"
              :key="`cta_${index}`"
            >
              <button
                class="mobile__actions-link btn btn__size--classic btn__color--prim"
                v-if="link"
                :disabled="!isActive(link)"
                @click.prevent="setRouteNavigation(link); mobileMenuOpened = false"
              >
                {{ $i18n.translate(link.text) }}
              </button>
            </li>
          </ul>
        </section>
      </transition>
    </header>

    <app-modal
      v-if="showSessionAppModal"
      size="sm"
      @close="showSessionAppModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="template"
          @authenticated="showSessionAppModal = false"
        />
      </template>
    </app-modal>
  </div>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'
import UserProfileImg from '&/atoms/UserProfileImg'
import PageRouteNavigation from '%/PageRouteNavigation'
import LanguageSwitcher from '&/atoms/LanguageSwitcher'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
import { getImagePath } from '@/utils/utils'

export default defineComponent({
  name: 'CustomHeaderULB',
  components: {
    UserProfileImg,
    LanguageSwitcher,
    AppBurgerMenu,
    SessionForms: defineAsyncComponent(() => import('&/modules/sessions/SessionForms')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  setup: () => ({ getImagePath }),
  data () {
    return {
      showSessionAppModal: false,
      mobileMenuOpened: false,
      template: null
    }
  },
  mixins: [
    PageRouteNavigation
  ],
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    attributes () {
      return _get(this.section, 'attributes', {})
    },
    actions () {
      return _get(this.attributes, 'actions', [])
    },
    links () {
      return _get(this.attributes, 'links', [])
    },
    isMultilingual () {
      return this.client.active_languages.length > 1
    }
  },
  methods: {
    openSessionAppModal (template) {
      this.template = template
      this.showSessionAppModal = true
    },
    toggleMobileMenu (value) {
      this.mobileMenuOpened = !value
    }
  }
})
</script>

<style lang="scss" scoped>
  $color-primary: #004A86;
  $color-secondary: #131415;
  $color-tertiary: #1992BC;

  .btn__color--prim:focus,
  .btn__color--prim:hover {
    background-color: darken( #004985, 10% );
  }

  .header {
    padding: calc($padding__base / 2) $padding__base;
    background-color: rgba($white, .95);
    box-shadow: 0 4px 8px 0 rgba(#5261731a, 0.1);

    @include mq(sm) {
      padding: $padding__base 0;
    }

    @include mq(md) {
      padding: $padding__base * 2 0 $padding__base * 4;
    }

    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__menu {
      display: none;

      @include mq(md) {
        display: flex;
        margin-right: $margin__base * 2;
      }

      @include mq(lg) {
        margin-right: $margin__base * 4;
      }

      &-item {
        position: relative;
        padding: $padding__base 0;

        &:not(:first-child) {
          margin-left: $margin__base * 2;
        }

        &:hover {
          .header__submenu {
            visibility: visible;
            opacity: 1;
            transform: translate(-50%, 12px);
          }
        }
      }
    }

    &__submenu {
      transition: all 0.3s;
      visibility: hidden;
      opacity: 0;
      background-color: #F1F2F8;
      position: absolute;
      left: 50%;
      z-index: 20;
      text-align: left;
      transform: translateX(-50%);
      width: 120px;
      padding: 0 10px;

      .header__menu-subitem {
        text-align: center;

        &:first-of-type {
          .header__link {
            border-top: 0;
          }
        }

        .header__link {
          display: block;
          width: 100%;
          font-size: 12px;
          padding: $padding__base 0;
          border-top: 1px solid rgba($color-primary, .3);
        }
      }
    }

    &__link {
      position: relative;
      font-size: rem(18px);
      border: 1px solid transparent;
      transition: all .3s;
      text-align: center;

      &:focus {
        border: 1px solid $color-primary;
        outline: 0;
      }

      &:hover {
        color: $color-primary;
      }

      &--active {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          display: block;
          width: 100%;
          height: 1px;
          margin-top: 6px;
          background: $color-primary;
        }
      }
    }

    &__actions {
      display: none;

      @include mq(md) {
        display: flex;
      }

      &-item {
        white-space: nowrap;

        &:not(:last-child) {
          margin-right: $margin__base;
        }

        .btn {
          border-radius: 0;
          font-weight: 700;
          font-family: $font-family--secondary;

          @include mq(lg) {
            padding: $padding__base * 2 $padding__base * 2.5;
          }
        }
      }
    }

    &__sessions {
      font-family: $font-family--secondary;
      margin-left: $margin__base * 2;
      display: none;

      @include mq(md) {
        display: block;
      }

      &-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:hover .link__see {
          color: shade($color-primary, 10%);
        }

        &:focus .link__see {
          border-bottom: 1px solid $color-primary;
        }

        .link__see {
          font-size: em(12px);
          line-height: 1.2;
          color: $color-primary;
          transition: all .3s;
        }
      }
    }

    &__language {
      margin-left: $margin__base;
      display: none;

      @include mq(md) {
        display: block;
      }
    }

    &__burger {
      display: inherit;

      @include mq(md) {
        display: none;
      }
    }

    .locale-switcher {
      box-shadow: none;

      .vs__dropdown-toggle {
        padding-right: 0;
      }
    }

    &__cells {
      &--session {
        justify-content: flex-end;
      }

      &--session,
      &--menu {
        display: flex;
        align-items: center;
      }

      &--menu {
        margin-top: 5px;
      }
    }
  }

  .logo {
    &__img {
      max-width: 170px;
      max-height: 40px;
      transition: all .5s;
      display: block;

      @include mq(md) {
        max-width: 270px;
        max-height: 73px;
        margin-top: 30px;
      }

      @include mq(lg) {
        max-width: 303px;
        max-height: 73px;
      }

      &--hidden {
        visibility: hidden;
        opacity: 0%;
      }
    }
  }

  .mobile {
    &__submenu {
      padding-left: 10px;

      .mobile__submenu-item {
        position: relative;
        padding-left: 8px;
      }
    }

    &__nav {
      width: 100vw;
      height: 100vh;
      background: $white;
      position: fixed;
      z-index: 10;
      overflow: hidden;
    }

    &__link {
      &--active {
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background: $color-primary;
        }
      }
    }

    &__menu {
      margin-top: $margin--base;
      border-top: 1px solid $dw;
      display: block;

      @include mq(md) {
        display: none;
      }
    }

    &__list {
      margin-top: $margin--base;

      &-link {
        display: inline-block;
        padding: $padding--base;
        color: $dg;
        font-weight: bold;
        border-radius: 6px;
      }

      &-language {
        padding-left: 0;
      }
    }

    &__actions {
      margin-top: $margin--base;
      display: block;

      &-item {
        text-align: center;
      }

      &-link {
        display: inline-block;
        padding: $padding--base $padding--base * 2;
      }
    }

    &__sessions {
      padding: $padding--base;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $dw;
      margin-top: calc($margin--base / 2);

      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .fade-mobile-menu-enter-active {
    animation: fadeTranslationY .5s ease-in-out;
  }

  .fade-mobile-menu-leave-active {
    animation: fadeTranslationY .5s ease-in-out reverse;
  }

  @keyframes fadeTranslationY {
    from {
      transform: translateY(-50px);
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 100%;
    }
  }

  .modal {
    &__body {
      padding: $padding__base * 2;

      @include mq(md) {
        padding: $padding__base * 3 $padding__base * 4;
      }
    }
  }
</style>
