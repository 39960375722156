<template>
  <div>
    <div class="page__head">
      <router-link
        class="page__title"
        :to="{ name: 'PrivateAllProducts' }"
      >
        <fa-icon
          class="icon__back"
          :icon="['fal', 'arrow-left']"
        />

        <h2 class="title__h2 bold">
          {{ t('literal.create_a_new_product') }}
        </h2>
      </router-link>

      <app-save-button
        :is-loading="loader || fileLoader"
        :is-disabled="loader || fileLoader"
        @click="handleClickSave"
      >
        {{ t('literal.create_product') }}
      </app-save-button>
    </div>

    <private-product-form
      v-model:product="product"
      v-model:project="clonedProject"
      v-model:added-quantity="added_quantity"
    />

    <app-save-button
      class="margin__t--24"
      :is-loading="loader || fileLoader"
      :is-disabled="loader || fileLoader"
      @click="handleClickSave"
    >
      {{ t('literal.create_product') }}
    </app-save-button>
  </div>
</template>

<script setup>
import { computed, reactive, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { mapGetters } from '@/store/map-state'
import { useProjectStore } from '@/stores/ProjectStore'
import { useProductStore } from '@/stores/ProductStore'
import { useFileStore } from '@/stores/FileStore'
import { useRouter } from 'vue-router'
import { useHead } from '@unhead/vue'
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'
import { useRoles } from '@/composables/app/useRoles'
import { useProduct } from '@/composables/modules/projects/useProduct'
import useVuelidate from '@vuelidate/core'
import Product from '@/classes/products/Product'
import { scrollTo } from '@/utils/utils'

import AppSaveButton from '&/molecules/AppSaveButton'
import PrivateProductForm from '&/modules/project/PrivateProductForm'

const props = defineProps({ projectSlug: { required: true, type: String } })

const { t } = useI18n()
const { push } = useRouter()
const { getProject, patchProject } = useProjectStore()
const { postProduct, postProductStock, $resetStore } = useProductStore()
const { loader } = storeToRefs(useProductStore())
const { loader: fileLoader } = storeToRefs(useFileStore())
const { isSuperAdmin, isKoalect } = useRoles()
const { user } = mapGetters('auth')
const v$ = useVuelidate()

const { project, clonedProject } = getProject({ id: props.projectSlug })

const added_quantity = ref(0)
const product = reactive(new Product({
  is_free: true,
  is_unlimited_stock: true,
  display_on_page: true,
  target_id: project.value.id,
  target_type: 'Project'
}))

const { productsDisplayOrder } = useProduct(product, clonedProject)

const handleClickSave = () => {
  v$.value.$validate()
  if (v$.value.$invalid) return scrollTo('.input--errors')

  postProduct({ params: product })
    .then(product => {
      productsDisplayOrder.value.push({
        product_id: product.id,
        display_on_page: product.display_on_page
      })

      const promises = [patchProject({ id: props.projectSlug, params: { settings: { products_display_order: productsDisplayOrder.value } } })]
      if (added_quantity.value) promises.push(postProductStock({ id: product.id, params: { added_quantity: added_quantity.value } }))

      Promise.all(promises).then(() => {
        $resetStore('products')
        push({ name: 'PrivateAllProducts' })
      })
    })
}

const allowProductCreation = computed(() => project.value ? _get(project.value, 'campaign.project_display_settings.product_creation', false) && (isSuperAdmin.value || isKoalect.value || project.value.isAuthorized(user.value)) : null)
watch(allowProductCreation, value => {
  if (value === false) push({ name: 'PrivateAllProducts' })
}, { immediate: true })

useHead({ title: computed(() => project.value ? `${t('literal.new_product')} / ${t('literal.products')} / ${_get(project.value, 'name', '')}` : null) })
</script>

<style lang="scss" scoped>
.page {
  &__head {
    margin: 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    display: flex;
    align-items: center;

    &:hover .icon__back {
      transform: translateX(-6px);
    }
  }
}

.icon {
  &__back {
    font-size: 20px;
    margin-right: 12px;
    transition: all .3s;
  }
}
</style>
