<template>
  <component
    v-if="componentPath"
    v-bind="objects"
    v-on="$attrs"
    :key="randomKey"
    :is="componentPath"
  />
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'

const components = {}
const componentNames = require.context('&', true, /\.vue$/i, 'sync')
  .keys()
  .map(file => file.replace(/(^.\/)|(\.vue$)/g, ''))
  .forEach(component => components[component] = defineAsyncComponent(() => import('&/' + component + '.vue')))

export default defineComponent({
  name: 'AppComponentLoader',
  props: {
    objects: {
      type: Object
    },
    componentPath: {
      type: String
    },
    v: {
      type: Object
    }
  },
  data () {
    return {
      componentNames
    }
  },
  components,
  computed: {
    randomKey () {
      return Math.random()
    }
  }
})
</script>