<template>
  <footer
    v-if="section"
    class="footer footer__grid"
  >
    <div class="footer__grid--item">
      <section class="footer__column">
        <div class="footer__column--name margin--24">
          <img
            class="footer__column--name-logo"
            v-if="client.logo.url"
            :src="client.logo.url"
            :alt="client.name"
          >

          <span
            class="footer__column--name-text bold margin__l--18"
            v-if="$i18n.translate(column_2.name)"
          >
            {{ $i18n.translate(column_2.name) }}
          </span>
        </div>
      </section>

      <section class="footer__column">
        <address
          class="footer__column--infos"
          v-if="column_2.infos.length > 0"
        >
          <span
            class="footer__column--infos-line"
            v-for="(item, i) in column_2.infos"
            :key="'line_' + i"
            v-html="$i18n.translate(item.line)"
          />
        </address>

        <address
          v-if="contactInfos.length > 0"
          class="footer__column--contacts"
        >
          <div
            class="footer__column--contact"
            v-for="(item, index) in contactInfos"
            :key="'item_' + index"
          >
            <span
              class="margin__r--6"
              v-if="item.prefix"
            >
              {{ item.prefix }}
            </span>

            <a
              class="footer__column--contact-link footer__column--contact-a d-block"
              :key="'footer_infos_line_' + index"
              :href="item.url"
              v-if="item.url"
              target="_blank"
            >
              {{ item.text }}
            </a>

            <p
              v-else
              class="footer__column--contact-text footer__column--contact-a d-block"
              :key="'footer_infos_line_' + index"
            >
              {{ item.text }}
            </p>

            <br>
          </div>
        </address>
      </section>
    </div>

    <section class="footer__column footer__grid--item">
      <ul
        class="footer__column--networks"
        v-if="socialNetworks.length > 0"
      >
        <li
          class="footer__column--networks-item margin--6 padding--12"
          v-for="(network, i) in socialNetworks"
          :key="'footer_menu_network_' + i"
        >
          <a
            class="footer__column--networks-a link link--naked bold"
            :href="$i18n.translate(network.url)"
            target="_blank"
          >
            <fa-icon
              v-if="network.icon"
              size="lg"
              :icon="network.icon"
            />
          </a>
        </li>
      </ul>
    </section>
  </footer>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'

export default defineComponent({
  name: 'CustomFooterParalympic',
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      client: 'client/client'
    }),
    column_2 () {
      return _get(this.section, 'attributes.column_2', [])
    },
    column_3 () {
      return _get(this.section, 'attributes.column_3', [])
    },
    contactInfos () {
      return _get(this.column_2, 'contact', [])
        .map(info => {
          let url = '';

          if (info.type !== 'text') {
            if (info.type === 'phone_number') url += 'tel:'
            if (info.type === 'email') url += 'mailto:'

            url += this.$i18n.translate(info.text).replace(/ /g, '')
          }

          return {
            prefix: this.$i18n.translate(info.prefix),
            text: this.$i18n.translate(info.text),
            url
          }
        })
        .filter(info => info.text)
    },
    socialNetworks () {
      const links = _get(this.client, 'platform.social_networks', [])

      return links
        .map(link => {
          if (link.icon.includes('facebook-square')) {
            link.icon[1] = 'facebook-f'
          } else if (link.icon.includes('linkedin')) {
            link.icon[1] = 'linkedin-in'
          }

          return link
        })
        .filter(link => this.$i18n.translate(link.url))
    }
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #d20a11;
$black: #222;

.footer {
  font-size: rem(12px);
  padding: $padding__base * 3;
  text-align: center;

  &__grid {
    @include mq(sm) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;

      &--item:nth-child(1) {
        grid-column: 2;
      }
    }
  }

  &__column {
    &--name {
      display: flex;
      align-items: center;
      justify-content: center;

      &-logo {
        max-height: 50px;
        width: auto;
      }

      &-text {
        text-align: center;
      }
    }

    &--infos {
      display: flex;
      flex-direction: column;

      &-line {
        &:nth-child(1) {
          margin-right: calc($margin__base / 2);
        }
      }
    }

    &--contacts {
      margin-bottom: $margin__base * 3;

      @include mq(md) {
        display: flex;
        justify-content: center;
         margin-bottom: 0;
      }
    }

    &--contact {
      display: flex;
      justify-content: center;

      @include mq(md){
        &:nth-child(1) {
          &::after {
            content: "-";
            margin: 0 calc($margin__base / 2);
          }
        }
      }

      &-a {
        color: $color--primary;
        transition: all $transition__duration;

        &:hover {
          color: #f64c52;
        }
      }
    }

    &--networks {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;

      @include mq(sm) {
        justify-content: flex-end;
      }

      &-item {
        background: #555;
        width: 50px;
        height: 50px;
        border-radius: 3px;
        transition: all $transition__duration;

        &:hover {
          background: #6f6f6f;
        }
      }

      &-a {
        color: $white;
      }
    }
  }
}
</style>