export default [
  {
    name: 'custom_header_beplanet',
    path: 'modules/pages/beplanet/HeaderBeplanet'
  },
  {
    name: 'custom_footer_beplanet',
    path: 'modules/pages/beplanet/FooterBeplanet'
  },
  {
    name: 'homepage_beplanet',
    path: 'modules/pages/beplanet/HomepageBeplanet'
  },
  {
    name: 'projects_beplanet',
    path: 'modules/pages/beplanet/ProjectsBeplanet'
  },
  {
    name: 'benefiting_beplanet',
    path: 'modules/pages/beplanet/BenefitingBeplanet'
  }
]
