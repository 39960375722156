<template>
  <div
    class="section"
    :style="sectionStyle"
  >
    <div class="container">
      <section
        class="section__content"
        :class="{'section__reverse': getLayout === 'reverse'}"
      >
        <article class="section__media">
          <figure v-if="attributes.media.type === 'image'">
            <img
              class="img"
              :src="$i18n.translate(attributes.media.image_url) || getImagePath('placeholders/project-card-cover.jpg')"
              alt=""
            >
          </figure>

          <app-video-embed
            v-if="attributes.media.type === 'video'"
            :src="$i18n.translate(attributes.media.video_url)"
          />
        </article>

        <article class="section__text">
          <div
            class="editor editor__content editor__page"
            v-if="$i18n.translate(attributes.text)"
            v-html="$i18n.translate(attributes.text)"
          />

          <button
            class="margin__t--24 btn btn__size--small btn__color--prim"
            :style="buttonStyle"
            type="button"
            v-if="attributes.button && attributes.button.show && isActive(attributes.button)"
            :disabled="!isActive(attributes.button)"
            @click.prevent="setRouteNavigation(attributes.button)"
          >
            {{ $i18n.translate(attributes.button.text) }}
          </button>
        </article>
      </section>
    </div>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
import PageRouteNavigation from '%/PageRouteNavigation'
import { useSectionStyle } from '@/composables/modules/pages'
import { getImagePath } from '@/utils/utils'

export default defineComponent({
  name: 'SectionMediaText',
  components: {
    AppVideoEmbed: defineAsyncComponent(() => import('@/components/atoms/AppVideoEmbed'))
  },
  mixins: [PageRouteNavigation],
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  setup: props => ({ ...useSectionStyle(props), getImagePath }),
  computed: {
    attributes () {
      return this.section.attributes
    }
  }
})
</script>

<style lang="scss" scoped>
.section {
  padding: $padding__base * 3 0;

  &__content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include mq(md) {
      display: flex;
    }
  }

  &__text {
    width: 100%;
    margin-bottom: 0;

    @include mq(md) {
      width: 50%;
      padding-left: $padding__base * 1.5;
    }
  }

  &__media {
    width: 100%;

    @include mq(md) {
      width: 50%;
      padding-right: $padding__base * 1.5;
    }
  }

  &__reverse {
    .section__text {
      order: 1;
      margin-bottom: $margin__base * 2;

      @include mq(md) {
        padding-right: $padding__base * 1.5;
        padding-left: 0;
      }
    }

    .section__media {
      order: 2;

      @include mq(md) {
        padding-left: $padding__base * 1.5;
        padding-right: 0;
      }
    }
  }
}

.img {
  max-width: 100%;
}
</style>
