<template>
  <div class="container padding__tb--18">
    <template-row
      v-for="(row, index) in rows"
      :key="`row_${index}`"
      :row="row"
    />
  </div>
</template>

<script setup>
import { toRefs } from 'vue'

import TemplateRow from '&/modules/templates/TemplateRow'

const props = defineProps({
  rows: { type: Array, required: true }
})

const { rows } = toRefs(props)
</script>