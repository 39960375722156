<template>
  <main>
    <section
      class="hero"
      :style="`background-image: url(${$i18n.translate(section.attributes.hero.image)})`"
    >
      <div class="wrapper hero__wrapper">
        <h2 class="hero__title">
          {{ $i18n.translate(section.attributes.hero.title) }}
        </h2>

        <button
          @click.prevent="setRouteNavigation(section.attributes.hero.cta)"
          class="cta"
        >
          <fa-icon :icon="['fas', 'plus']" />
          {{ $i18n.translate(section.attributes.hero.cta.title) }}
        </button>
      </div>

      <div
        class="stats__white-strip"
        v-if="stats && metrics.length"
      >
        <ul class="stats bg__color--sec">
          <li
            class="stats__el"
            v-for="metric in metrics"
            :key="metric.index"
          >
            <span class="stats__data bold margin__b--6">
              <app-date
                v-if="metric.type === 'date' && metric.date"
                :timestamp="metric.date"
                filter="differenceInCalendarDays"
              />
              <app-money
                v-else-if="metric.type === 'amount_collected'"
                :amount="stats[metric.type]"
              />
              <span v-else>
                {{ stats[metric.type] }}
              </span>
            </span>
            {{ $i18n.translate(metric.text) }}
          </li>
        </ul>
      </div>
    </section>

    <section class="about">
      <div class="wrapper">
        <div
          class="about__content"
          v-html="$i18n.translate(section.attributes.about.content)"
        />

        <div
          class="carousel"
          v-if="slider"
        >
          <div
            class="carousel__viewport"
            @mouseenter="isPaused = true"
            @mouseleave="isPaused = false"
          >
            <a
              v-for="(slide, index) in slider"
              :key="index"
              :class="['carousel__slide', {'carousel__slide--active': index === showSlide}]"
              @click.prevent="setRouteNavigation(slide.link)"
            >
              <img
                :src="$i18n.translate(slide.image)"
                :alt="$i18n.translate(slide.link.text)"
                width="400"
                height="400"
              >
            </a>
          </div>

          <button
            class="carousel__btn"
            @click="prev()"
          >
            <fa-icon :icon="['fas', 'chevron-left']" />
          </button>

          <button
            class="carousel__btn carousel__btn--next"
            @click="next()"
          >
            <fa-icon :icon="['fas', 'chevron-right']" />
          </button>
        </div>
      </div>
    </section>

    <section
      class="projects"
      v-if="Object.values(projects).length"
    >
      <div class="wrapper">
        <h2 class="projects__title">
          {{ $i18n.translate(section.attributes.actions.title) }}
        </h2>

        <div class="row margin__b--36">
          <template
            v-for="project in projects"
            :key="project.id"
          >
            <section class="col-12 col-sm-6 col-md-3">
              <project-card
                class="project-card"
                :project="project"
              />
            </section>
          </template>
        </div>

        <button
          @click.prevent="setRouteNavigation(section.attributes.actions.cta)"
          class="cta"
        >
          {{ $i18n.translate(section.attributes.actions.cta.title) }}
        </button>
      </div>
    </section>
  </main>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import PageRouteNavigation from '%/PageRouteNavigation'
import ProjectCard from '&/organisms/ProjectCard'
import { get as _get } from 'lodash'

export default defineComponent({
  name: 'CustomHomepageCNCD',
  components: {
    ProjectCard,
    AppDate: defineAsyncComponent(() => import('&/atoms/AppDate')),
    AppMoney: defineAsyncComponent(() => import('&/atoms/AppMoney'))
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  mixins: [PageRouteNavigation],
  data () {
    return {
      query_projects: {
        'sort_by_field[field]': 'current_amount',
        'sort_by_field[direction]': 'desc',
        'per_page': 4
      },
      query_stats: {},
      showSlide: 0,
      isPaused: false,
      sliderInterval: null
    }
  },
  created () {
    this.setQueries()
    this.fetchProjects({ query: this.query_projects, removeStoredItems: true })
    this.fetchClientStatistics({ query: this.query_stats })
  },
  computed: {
    ...mapGetters({
      projects: 'projects/getProjects',
      stats: 'client/statistics'
    }),
    metrics () {
      return _get(this.section, 'attributes.stats.metrics', [])
    },
    campaign_id () {
      return _get(this.section, 'attributes.stats.campaign_id', [])
    },
    slider () {
      return _get(this.section, 'attributes.about.slider', [])
    }
  },
  methods: {
    ...mapActions({
      fetchProjects: 'projects/fetchProjects',
      fetchClientStatistics: 'client/fetchClientStatistics'
    }),
    setQueries () {
      if (this.campaign_id.length)
        this.query_stats.campaign_id = this.campaign_id
    },
    next (clicked = true) {
      if (clicked) clearInterval(this.sliderInterval)
      this.showSlide >= this.section.attributes.about.slider.length - 1 ? this.showSlide = 0 : ++this.showSlide
    },
    prev () {
      clearInterval(this.sliderInterval)
      this.showSlide <= 0 ? this.showSlide = this.section.attributes.about.slider.length - 1 : --this.showSlide
    },
    delayToChange () {
      this.sliderInterval = setInterval(() => {
        if (!this.isPaused) {
          this.next(false)
        }
      }, 6000)
    }
  },
  mounted () {
    this.delayToChange()
  }
})
</script>

<style lang="scss" scoped>
$color-primary: #76b729;
$color-secondary: #ffa500;

.wrapper {
  padding: $padding__base * 5 $padding__base * 2;
  max-width: 1200px;
  margin: auto;

  @include mq(sm) {
    padding: $padding__base * 5 $padding__base * 4;
  }
}

.cta {
  align-items: center;
  background-color: $color-primary;
  border-radius: $radius__buttons;
  color: $white;
  display: inline-flex;
  font-size: rem(18px);
  font-weight: 700;
  padding: 8px 16px;
  text-transform: uppercase;
  transition: background-color $transition__duration;

  & svg {
    margin-right: 12px;
    transform-origin: center;
    transition: transform $transition__duration;
  }

  &:hover, &:focus {
    background-color: $color-secondary;

    & svg {
      transform: rotate(90deg);
    }
  }
}

.hero {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &::after {
    background-color: rgba($black, .3);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  &__wrapper {
    @include mq(sm) {
      padding: $padding__base * 10 $padding__base * 8;
    }
  }

  &__title {
    color: $white;
    font-family: $font-family--secondary;
    font-size: rem(42px);
    font-weight: 700;
    line-height: rem(50px);
    margin-bottom: $margin__base * 2;
    max-width: 600px;
    position: relative;
    text-transform: uppercase;
    z-index: 1;
  }

  & .cta {
    position: relative;
    z-index: 1;
  }

  & .stats {
    align-items: center;
    background-color: $white;
    box-shadow: 0 8px 16px 0 rgba(12,0,51,.1);
    display: flex;
    flex-direction: column;
    font-size: rem(18px);
    justify-content: space-around;
    padding: $padding__base * 2;
    position: relative;
    z-index: 2;

    @include mq(sm) {
      flex-direction: row;
      padding: $padding__base * 3 $padding__base * 2;
      margin: auto;
      max-width: 700px;
      width: 90%;

      &__white-strip {
        position: relative;

        &::after {
          background-color: $vdw;
          bottom: 0;
          content: '';
          left: 0;
          height: 50%;
          position: absolute;
          width: 100%;
          z-index: 1;
        }
      }
    }

    &__el {
      font-size: rem(16px);
      text-align: center;
      width: 33%;

      &:not(:last-of-type) {
        margin-bottom: $margin__base * 2;

        @include mq(sm) {
          margin-bottom: 0;
        }
      }
    }

    &__data {
      color: $color-primary;
      display: block;
      font-size: rem(36px);
    }
  }
}

.projects {
  &__title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: $margin__base * 4;

    @include mq(sm){
      font-size: 2.25rem;
    }
  }

  & .cta {
    background-color: transparent;
    border: 1px solid $color-primary;
    color: $color-primary;
    display: block;
    margin: $margin__base * 2 auto 0;
    transition: color $transition__duration,
                background-color $transition__duration;

    &:hover, &:focus {
      background-color: $color-secondary;
      border-color: $color-secondary;
      color: $white;
    }
  }
}

.about {
  @include mq(sm) {
    & .wrapper {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }

  &__content {
    border-left: $color-primary 6px solid;
    padding-left: $padding__base * 2;
    margin-bottom: $margin__base * 4;
    max-width: 600px;

    @include mq(sm) {
      margin-bottom: 0;
      margin-right: $margin__base * 4;
    }
  }

  & .carousel {
    position: relative;

    &__viewport {
      align-items: center;
      height: 70vmin;
      margin: auto;
      max-height: 700px;
      max-width: 700px;
      overflow: hidden;
      position: relative;
      width: 70vmin;

      @include mq(sm){
        height: 50vmin;
        max-height: 500px;
        max-width: 500px;
        width: 50vmin;
      }
    }

    &__slide {
      cursor: pointer;
      left: 50%;
      height: calc(100% - 50px);
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: opacity $transition__duration;
      width: calc(100% - 50px);

      @include mq(sm){
        height: calc(100% - 75px);
        width: calc(100% - 75px);
      }

      &--active {
        opacity: 1;
        pointer-events: initial;
        z-index: 1;
      }

      & img {
        height: 100%;
        width: 100%;
      }
    }

    &__btn {
      color: $color-primary;
      font-size: 30px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: color $transition__duration;

      &--next{
        left: inherit;
        right: 0;
      }

      &:hover,
      &:focus {
        color: $color-secondary;
      }
    }
  }
}

</style>
