export default [
  {
    name: 'banner',
    path: 'modules/pages/default/sections/SectionBanner'
  },
  {
    name: 'quote',
    path: 'modules/pages/default/sections/SectionQuote'
  },
  {
    name: 'sponsors',
    path: 'modules/pages/default/sections/SectionSponsors'
  },
  {
    name: 'text_editor',
    path: 'modules/pages/default/sections/SectionTextEditor'
  },
  {
    name: 'img_content',
    path: 'modules/pages/default/sections/SectionImgContent'
  },
  {
    name: 'content_img',
    path: 'modules/pages/default/sections/SectionContentImg'
  },
  {
    name: 'counter',
    path: 'modules/pages/default/sections/SectionMetrics'
  },
  {
    name: 'how_it_works',
    path: 'modules/pages/default/sections/SectionHowItWorks'
  },
  {
    name: 'divider',
    path: 'modules/pages/default/sections/SectionDivider'
  }
]
