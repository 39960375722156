<template>
  <section
    class="card margin__t--18 padding--24"
    v-if="procedure"
  >
    <h2 class="title bold margin__r--12">
      {{ t('literal.project_qr_code') }}
    </h2>

    <p class="margin__t--12">
      {{ t('@qrcodes.project_twikey_description') }}
    </p>

    <ul class="qrcodes">
      <li
        class="qrcode__li"
        v-for="language in languages"
        :key="language.id"
      >
        <h3 class="qrcode__title">
          {{ t(`literal.qr_code_in_${language.value}`) }}
        </h3>

        <div
          class="qrcode__img"
          :style="{ backgroundImage: `url(${procedure.form_configuration.qr_codes.png[language.id]})` }"
        />

        <ul class="qrcode__links">
          <li
            v-for="extension in Object.keys(procedure.form_configuration.qr_codes)"
            :key="`${extension}_${language.id}`"
          >
            <a
              class="link__color--prim link--naked"
              type="button"
              :href="getQrcodeSource(extension, language.id)"
              target="_blank"
            >
              <fa-icon
                class="margin__r--6"
                :icon="['fal', 'download']"
              />

              {{ t('literal.download') }} .{{ extension }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </section>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { mapGetters, mapActions } from '@/store/map-state'
import availables from '@/vendors/i18n/translations/available'

const props = defineProps({ procedureId: { type: String, required: true } })

const procedure = ref()
const { t, translate } = useI18n()
const { client } = mapGetters('client')
const { fetchProcedure } = mapActions('procedures')
const languages = Object.values(availables).filter(available => client.value.active_languages.includes(available.id))

fetchProcedure({ slug: props.procedureId }).then(db_procedure => procedure.value = db_procedure)
const getQrcodeSource = (extension, lang) => translate(procedure.value.form_configuration.qr_codes[extension], lang)
</script>

<style lang="scss" scoped>
.title {
  font-size: em(20px);
}

.qrcodes {
  margin-top: 18px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 18px;

  @include mq(sm) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include mq(lg) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.qrcode {
  &__title {
    font-size: em(18px);
    font-weight: bold;;
  }

  &__li {
    border: 1px solid rgba($dg, .1);
    border-radius: 4px;
    padding: 18px;
  }

  &__img {
    margin: auto;
    margin-top: 18px;
    display: block;
    width: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include aspect-ratio(1, 1);
  }

  &__links {
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}
</style>