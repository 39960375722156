export default {
  methods: {
    isActive (obj) {
      return this.$i18n.translate(obj.url) || (obj.target_url && obj.target_url !== 'custom')
    },
    isVisited (obj) {
      if (obj.target_name === 'Page') return obj.target_params.path === this.$route.params.path
      return (obj.url[this.$i18n.locale] === this.$route.fullPath) || (obj.target_url === this.$route.params.path) || (obj.target_name === this.$route.name)
    },
    setRouteNavigation (obj, fallback = false) {
      if (this.isVisited(obj)) return

      if (this.$i18n.translate(obj.url)) {
        window.open(this.$i18n.translate(obj.url), '_blank')
      } else if (obj.target_url) {
        this.$router.push({ path: '/' + this.$i18n.locale + '/' + obj.target_url }).catch()
      } else if (obj.target_name) {
        this.$router.push({ name: obj.target_name, params: obj.target_params }).catch()
      } else if (fallback) {
        this.$router.push(fallback).catch()
      }
    }
  }
}
