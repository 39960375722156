<template>
  <p class="notification bg__color--ter align--center padding--12">
    <slot name="default" />
  </p>
</template>

<style lang="scss" scoped>
.notification {
  position: relative;
  z-index: 10;
  font-size: rem(14px);
  color: set-contrast($color--tertiary, $white);
}
</style>