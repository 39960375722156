<template>
  <template-navigation
    :column="column"
    v-if="column.blocks.length"
  />
</template>

<script setup>
import { ref, computed, inject, defineAsyncComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useProjectStore } from '@/stores/ProjectStore'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import Column from '@/classes/templates/Column'
import { get as _get } from 'lodash'

const TemplateNavigation = defineAsyncComponent(() => import('&/modules/templates/TemplateNavigation'))

const props = defineProps({
  column: { type: Object, required: true }
})

const isPreview = inject('preview', ref(false))

const route = useRoute()
const { getProject } = useProjectStore()
const { authUser } = storeToRefs(useAuthenticationStore())

const { project } = getProject({ id: route.params.projectSlug })

const column = computed(() => new Column({ ...props.column, blocks: props.column.blocks.filter(block => _get(block, 'attributes.show_only_project_members', false) && !project.value?.isAuthorized(authUser.value) && !isPreview.value ? false : true) }))
</script>