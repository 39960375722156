<template>
  <div
    class="section"
    :style="sectionStyle"
  >
    <div class="container">
      <figure v-if="attributes.media.type === 'image' && $i18n.translate(attributes.media.image_url)">
        <img
          class="img"
          :src="$i18n.translate(attributes.media.image_url)"
          alt=""
        >
      </figure>

      <app-video-embed
        v-if="attributes.media.type === 'video' && $i18n.translate(attributes.media.video_url)"
        :src="$i18n.translate(attributes.media.video_url)"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
import { useSectionStyle } from '@/composables/modules/pages'

export default defineComponent({
  name: 'SectionMedia',
  components: {
    AppVideoEmbed: defineAsyncComponent(() => import('@/components/atoms/AppVideoEmbed'))
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  setup: props => ({ ...useSectionStyle(props) }),
  computed: {
    attributes () {
      return this.section.attributes
    }
  }
})
</script>

<style lang="scss" scoped>
.section {
  .container {
    max-width: 100%;
    padding: 0;

    @include mq(md) {
      max-width: 960px;
    }

    @include mq(lg) {
      max-width: 1280px;
    }

    @include mq(xl) {
      max-width: 1440px;
    }
  }
}

.img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
</style>
