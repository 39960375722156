<template>
  <div class="position-relative">
    <input-file
      :id="id"
      multiple
      hidden
      @change="handleInputChange"
    />

    <label
      :for="id"
      class="button"
      :class="{ 'is-active': isActive }"
      :disabled="disabled"
    >
      <fa-icon
        :icon="icon"
        fixed-width
      />
    </label>
  </div>
</template>


<script setup>
import { guid } from '@/utils/utils'

import InputFile from '&/atoms/InputFile'

const emit = defineEmits(['command'])

defineProps({
  isActive: { type: Boolean, required: true, default: () => false },
  disabled: { type: Boolean, default: () => false },
  icon: { type: Array, required: true }
})

const id = `image_${guid(1)}`

const handleInputChange = files => emit('command', files)
</script>

<style lang="scss" scoped>
.button {
  position: relative;
  padding: 5px 3px;
  margin-right: calc($margin__base / 2);
  transition: all 0.3s;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  z-index: 20;
  cursor: pointer;

  &.is-active {
    border-color: $color--primary;
  }
}
</style>
