import Customer from '@/classes/customers/Customer'
import Procedure from '@/classes/procedures/Procedure'

export default class Transaction {
  constructor ({ id = null, category = null, payment_method = null, payment_method_informations = {}, tax_receipt_requested = null, amount = 0, form_amount = 0, currency = null, redirect_url = null, customer = {}, procedure = {}, products = null, payload = null, user_id = null, informations = {}, terms_of_use = false, frequency = null, created_at = null, procedure_execution_informations = {}, public_procedure_execution_id = null, procedure_id = null, status = null, provider_ids = {} } = {}) {
    this.id = id
    this.category = category
    this.payment_method = payment_method
    this.payment_method_informations = payment_method_informations
    this.tax_receipt_requested = tax_receipt_requested
    this.amount = amount
    this.form_amount = form_amount
    this.currency = currency
    this.redirect_url = redirect_url
    this.customer = new Customer(customer)
    this.procedure = new Procedure(procedure)
    this.products = products
    this.payload = payload
    this.user_id = user_id
    this.informations = informations
    this.terms_of_use = terms_of_use
    this.frequency = frequency
    this.created_at = created_at
    this.procedure_execution_informations = procedure_execution_informations
    this.public_procedure_execution_id = public_procedure_execution_id
    this.procedure_id = procedure_id
    this.status = status
    this.provider_ids = provider_ids
  }

  get is_subscription () {
    return this.frequency === 'monthly'
  }

  get to_database () {
    return {
      payment_method: this.payment_method,
      payment_method_informations: this.payment_method_informations,
      tax_receipt_requested: this.tax_receipt_requested,
      amount: this.amount,
      form_amount: this.form_amount,
      currency: this.currency,
      redirect_url: this.redirect_url,
      is_subscription: this.is_subscription,
      frequency: this.frequency
    }
  }

  get to_tamaro () {
    return {
      return_url: this.redirect_url,
      success_url: this.redirect_url,
      error_url: this.redirect_url,
      cancel_url: this.redirect_url,
      payment_type: this.frequency === 'one_time' ? 'onetime' : 'recurring',
      stored_customer_donation_receipt: this.tax_receipt_requested || false,
      stored_koa_transaction_id: this.id
    }
  }
}
