import Order from '@/classes/procedures/Order'
import Transaction from '@/classes/transactions/Transaction'
import Project from '@/classes/projects/Project'
import AcceptInvite from '@/classes/procedures/AcceptInvite'

export default class ActionsData {
  constructor ({ transaction = {}, order, project = {}, accept_invite = {}, fee = {}, create_invite = {}, recaptcha = {} } = {}) {
    this.transaction = new Transaction(transaction)
    this.order = new Order(order)
    this.project = new Project(project)
    this.accept_invite = new AcceptInvite(accept_invite)
    this.fee = fee
    this.create_invite = create_invite
    this.recaptcha = recaptcha
  }

  get to_database () {
    return {
      ...this,
      transaction: this.transaction.to_database,
      project: this.project.to_database
    }
  }

  get to_tamaro () {
    return {
      ...this.transaction.to_tamaro
    }
  }
}
