<template>
  <div>
    <header
      class="header"
      :class="{'header--fixed': mobileMenuOpened}"
      v-if="section"
    >
      <div class="header__row">
        <div class="wrapper d-flex justify-content-end">
          <button
            @click.prevent="openSessionAppModal('register')"
            class="header__session bold"
            v-if="!isAuthenticated"
          >
            <fa-icon
              class="margin__r--6"
              :icon="['fas', 'user']"
            />
            <span v-html="$i18n.t('literal.login')" />
          </button>

          <router-link
            class="header__session header__session--user bold d-flex align-items-center"
            :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
            v-if="isAuthenticated && user"
          >
            <user-profile-img
              size="2"
              :avatar="user.avatar"
            />

            <span class="d-block margin__l--6">{{ user.firstname }}</span>
          </router-link>
        </div>
      </div>

      <div class="header__row">
        <div class="wrapper header__wrapper">
          <nav
            class="header__cell"
            v-if="links_1.length > 0"
          >
            <ul class="header__menu">
              <li
                class="header__menu-item"
                :class="{'header__menu-item--submenu': item.submenu.length > 0}"
                v-for="(item, index) in links_1"
                :key="`menu_1_item_${index}`"
              >
                <a
                  class="header__menu-link bold"
                  :class="{'header__menu-link--active': isVisited(item.link)}"
                  @click.prevent="setRouteNavigation(item.link); mobileMenuOpened = false"
                >
                  {{ $i18n.translate(item.link.text) }}

                  <fa-icon
                    class="header__menu-link--icon margin__l--6"
                    size="xs"
                    :icon="['fal', 'chevron-down']"
                    v-if="item.submenu.length > 0"
                  />
                </a>

                <nav
                  v-if="item.submenu.length > 0"
                  class="header__submenu"
                >
                  <ul
                    class="header__submenu--items"
                    v-for="(subitems, key) in getSubMenu(item.submenu)"
                    :key="`menu_1_items_${key}`"
                  >
                    <li
                      class="header__submenu-item"
                      v-for="(subitem, i) in subitems"
                      :key="`menu_1_item_${index}_${i}`"
                    >
                      <a
                        class="header__submenu-link bold"
                        :class="{'header__submenu-link--active': isVisited(subitem.link)}"
                        @click.prevent="setRouteNavigation(subitem.link); mobileMenuOpened = false"
                      >
                        {{ $i18n.translate(subitem.link.text) }}
                      </a>
                    </li>
                  </ul>
                </nav>
              </li>
            </ul>
          </nav>

          <figure class="logo__wrapper">
            <router-link
              :to="{ name: 'Home', params: { locale: $i18n.locale } }"
              v-show="client.logo.url"
              class="logo"
            >
              <img
                class="logo__img"
                :src="client.logo.url"
                :alt="client.name"
                width="250"
                height="86"
              >
            </router-link>
          </figure>

          <nav
            class="header__cell justify-content-end"
          >
            <ul
              class="header__menu"
              v-if="links_2.length > 0"
            >
              <li
                class="header__menu-item"
                :class="{'header__menu-item--submenu': item.submenu.length > 0}"
                v-for="(item, index) in links_2"
                :key="`menu_2_item_${index}`"
              >
                <a
                  class="header__menu-link bold"
                  :class="{'header__menu-link--active': isVisited(item.link)}"
                  @click.prevent="setRouteNavigation(item.link); mobileMenuOpened = false"
                >
                  {{ $i18n.translate(item.link.text) }}

                  <fa-icon
                    class="header__menu-link--icon margin__l--6"
                    size="xs"
                    :icon="['fal', 'chevron-right']"
                    v-if="item.submenu.length > 0"
                  />
                </a>

                <nav
                  v-if="item.submenu.length > 0"
                  class="header__submenu"
                >
                  <ul
                    class="header__submenu--items"
                    v-for="(subitems, key) in getSubMenu(item.submenu)"
                    :key="`menu_1_items_${key}`"
                  >
                    <li
                      class="header__submenu-item"
                      v-for="(subitem, i) in subitems"
                      :key="`menu_1_item_${index}_${i}`"
                    >
                      <a
                        class="header__submenu-link bold"
                        :class="{'header__submenu-link--active': isVisited(subitem.link)}"
                        @click.prevent="setRouteNavigation(subitem.link); mobileMenuOpened = false"
                      >
                        {{ $i18n.translate(subitem.link.text) }}
                      </a>
                    </li>
                  </ul>
                </nav>
              </li>
            </ul>

            <button
              class="cta btn__color--sec btn__size--classic header__action"
              v-if="action && action.show"
              @click.prevent="setRouteNavigation(action)"
            >
              {{ $i18n.translate(action.text) }}
            </button>

            <app-burger-menu
              class="header__burger d-lg-none"
              :state="mobileMenuOpened"
              @toggle="toggleMobileMenu"
            />
          </nav>
        </div>
      </div>

      <transition name="fade-mobile-menu">
        <section
          class="mobile"
          v-if="mobileMenuOpened"
        >
          <div class="wrapper">
            <ul
              class="mobile__menu"
              v-if="links.length > 0"
            >
              <li
                class="mobile__menu-item"
                v-for="(item, index) in links"
                :key="`menu_mobile_item_${index}`"
              >
                <a
                  class="mobile__menu-link bold"
                  :class="{'mobile__menu-link--active': isVisited(item.link)}"
                  @click.prevent="setRouteNavigation(item.link); mobileMenuOpened = false"
                >
                  {{ $i18n.translate(item.link.text) }}
                </a>

                <ul
                  v-if="item.submenu.length > 0"
                  class="mobile__submenu"
                >
                  <li
                    class="mobile__menu-item"
                    v-for="(subitem, key) in item.submenu"
                    :key="`menu_mobile_item_${index}_${key}`"
                  >
                    <a
                      class="mobile__menu-link bold"
                      :class="{'mobile__menu-link--active': isVisited(subitem.link)}"
                      @click.prevent="setRouteNavigation(subitem.link); mobileMenuOpened = false"
                    >
                      {{ $i18n.translate(subitem.link.text) }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>

            <div class="d-sm-none">
              <hr class="separator separator__size--full border__color--dw margin__tb--18">

              <div
                v-if="action && action.show"
                class="d-flex justify-content-center"
              >
                <button
                  class="cta btn__color--sec btn__size--classic"
                  @click.prevent="setRouteNavigation(action)"
                >
                  {{ $i18n.translate(action.text) }}
                </button>
              </div>
            </div>

            <hr class="separator separator__size--full border__color--dw margin__tb--18">

            <ul class="mobile__sessions">
              <li
                class="mobile__sessions-item"
                v-if="!isAuthenticated"
              >
                <a
                  @click.prevent="openSessionAppModal('login')"
                  class="link__color--white bold link--naked pointer"
                >
                  <fa-icon
                    class="margin__r--6"
                    :icon="['fas', 'user']"
                    fixed-width
                  />
                  {{ $i18n.t('literal.login') }}
                </a>
              </li>

              <li
                class="mobile__sessions-item bold pointer"
                v-if="isAuthenticated && user"
              >
                <router-link
                  :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
                  @click.capture="mobileMenuOpened = false"
                  class="d-flex align-items-center link__color--white link--naked"
                >
                  <user-profile-img
                    class="margin__r--6"
                    size="2"
                    :avatar="user.avatar"
                  />

                  {{ user.firstname }}
                </router-link>
              </li>

              <li
                class="mobile__sessions-item mobile__sessions-item--logout pointer"
                v-if="isAuthenticated && user"
              >
                <router-link
                  :to="{ name: 'Logout' }"
                  class="link--naked pointer link__color--danger"
                >
                  <fa-icon
                    class="margin__r--6 color--danger"
                    :icon="['fas', 'sign-out']"
                    fixed-width
                  />
                  {{ $i18n.t('literal.logout') }}
                </router-link>
              </li>
            </ul>

            <hr class="separator separator__size--full border__color--dw margin__tb--18">
          </div>
        </section>
      </transition>
    </header>

    <app-modal
      v-if="showSessionAppModal"
      size="sm"
      @close="showSessionAppModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="template"
          @authenticated="showSessionAppModal = false"
        />
      </template>
    </app-modal>
  </div>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import { get as _get } from 'lodash'
import { mapGetters } from 'vuex'
import PageRouteNavigation from '%/PageRouteNavigation'
import UserProfileImg from '&/atoms/UserProfileImg'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'

export default defineComponent({
  name: 'CustomHeaderUMons',
  components: {
    UserProfileImg,
    AppBurgerMenu,
    SessionForms: defineAsyncComponent(() => import('&/modules/sessions/SessionForms')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      showSessionAppModal: false,
      mobileMenuOpened: false,
      template: null
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    links () {
      return [...this.links_1, ...this.links_2]
    },
    links_1 () {
      return _get(this.section, 'attributes.links_1', [])
    },
    links_2 () {
      return _get(this.section, 'attributes.links_2', [])
    },
    action () {
      return _get(this.section, 'attributes.action', {})
    }
  },
  methods: {
    openSessionAppModal (template) {
      this.template = template
      this.showSessionAppModal = true
    },
    toggleMobileMenu (value) {
      this.mobileMenuOpened = !value
    },
    getSubMenu (submenu) {
      const index = submenu.length / 2

      return [submenu.slice(0, index), submenu.slice(index)]
    }
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #1D97B1;
$color--secondary: #B80237;

.wrapper {
  max-width: 1318px;
  margin: 0 auto;
  padding: 0 $padding__base * 1.5;

  @include mq(sm) {
    padding: 0 $padding__base * 2;
  }
}

.cta {
  cursor: pointer;
  transition: all $transition__duration;
  font-weight: bold;
  font-size: rem(14px);
}

.logo {
  padding: $padding__base * 2.5 $padding__base * 2;
  background: $white;
  position: absolute;
  width: 100%;
  top: 0;

  @include mq(lg) {
    padding: $padding__base * 2.5 $padding__base * 3;
  }

  &__wrapper {
    position: relative;
    height: 100%;
    margin: 0 $margin__base;
  }

  &__img {
    max-width: 170px;
    width: 100%;
    height: auto;
  }
}

.header {
  background: #1D97B1;
  position: relative;
  z-index: 999;

  &__row {
    &:first-child {
      background: #F6F5F5;
      padding: calc($padding__base / 6) 0;
    }
  }

  &__wrapper {
    display: grid;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: 0px 150px 1fr;
    height: 80px;

    @include mq(sm) {
      height: 100px;
      grid-template-columns: 0px 240px 1fr;
    }

    @include mq(md) {
      grid-template-columns: 1fr 240px 1fr;
    }
  }

  &__session {
    padding: calc($padding__base / 2);
    color: $color--primary;
    font-family: $font-family--secondary;
  }

  &__cell {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
  }

  &__menu {
    font-family: $font-family--secondary;
    display: none;
    font-size: rem(16px);

    @include mq(lg) {
      display: flex;
    }

    &-item {
      position: relative;

      &:not(:last-child) {
        margin-right: $margin__base;

        @media screen and (lg) {
          margin-right: $margin__base * 2;
        }

        @include mq(xl) {
          margin-right: $margin__base * 4;
        }
      }

      &:hover {
        .header {
          &__menu {
            &-link {
              &--icon {
                transform: rotate(-180deg);
              }
            }
          }

          &__submenu {
            height: auto;
            opacity: 1;
            transform: translateY(0px);
          }
        }
      }

      &--submenu {
        &:hover {
          .header {
            &__menu {
              &-link {
                color: #131415;
              }
            }
          }
        }
      }
    }

    &-link {
      display: block;
      transition: all .3s;
      padding: $padding__base 0;
      color: $white;

      &--icon {
        position: relative;
        transform-origin: center;
        transition: .2s linear;
      }

      &:hover, &--active {
        color: #131415;
      }
    }
  }

  &__submenu {
    position: absolute;
    height: 0;
    opacity: 0;
    font-size: rem(14px);
    background: $white;
    padding: 0 $padding__base;
    transform: translateY(-10px);
    transition: all .3s;
    overflow: hidden;
    z-index: 10;
    display: flex;
    width: 375px;

    &--items {
      width: 50%;

      &:not(:last-child) {
        margin-right: $margin__base * 2;
      }
    }

    &-item {
      padding: $padding__base 0;

      &:not(:first-child) {
        border-top: 1px solid $color--secondary;
      }
    }

    &-link {
      display: block;
      transition: all .3s;

      &:hover, &--active {
        color: $color--secondary;
      }
    }
  }

  &__action {
    margin-left: $margin__base * 2;
    display: none;

    @include mq(sm) {
      display: block;
    }

    @include mq(xl) {
      margin-left: $margin__base * 4;
    }
  }

  &__burger {
    color: $white;

    & :deep() {
      .menu__line {
        background: $white;
      }

      .menu__close {
        &::before, &::after {
          background: $white;
        }
      }
    }
  }
}

.mobile {
  @include mq(lg) {
    display: none;
  }

  &__menu {
    margin-top: $margin__base * 3;

    &-item {
      &:not(:first-child) {
        margin-top: calc($margin__base / 2);
      }
    }

    &-link {
      color: $white;

      &--active {
        color: #131415;
      }
    }
  }

  &__submenu {
    padding-left: $padding__base * 2;
    margin: $margin__base 0;
    border-left: 2px solid $color--secondary;
  }

  &__sessions {
    margin-top: $margin__base;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-item {
      &--logout {
        margin: 0 calc($margin__base / 2) 0 auto;
      }
    }
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}
</style>
