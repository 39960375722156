<template>
  <app-modal
    @close="$emit('close')"
    size="confirm"
  >
    <template #body>
      <section class="modal">
        <slot name="confirm-content">
          <div class="align--center">
            <p>{{ $i18n.t('$modal_confirm.global_confirmation_msg') }}</p>
          </div>
        </slot>

        <div class="margin__t--24 modal__footer">
          <button
            class="btn btn__color--success btn__size--classic"
            @click.prevent="$emit('confirm')"
          >
            <fa-icon
              class="margin__r--6"
              :icon="['far', 'thumbs-up']"
            />
            {{ $i18n.t('literal.confirm') }}
          </button>

          <button
            class="link__color--danger link--naked margin__l--24"
            @click.prevent="$emit('close')"
          >
            <fa-icon
              class="margin__r--6"
              :icon="['fas', 'times']"
            />
            {{ $i18n.t('literal.cancel') }}
          </button>
        </div>
      </section>
    </template>
  </app-modal>
</template>

<script>
import { defineComponent } from 'vue'
import AppModal from '&/organisms/AppModal'

export default defineComponent({
  components: {
    AppModal
  },
  emits: ['close', 'confirm']
})
</script>

<style lang="scss">
.modal {
  padding: $padding__base * 2;

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
