import { mixpanel, gtm } from '@/vendors/integrations'
import { centsToAmount } from '@/utils/utils'
import { get as _get } from 'lodash'
import store from '@/store'

export default class {
  static viewStep = ({ step, procedure, procedure_execution, order }) => {
    mixpanel.track('Visited procedure step ' + step.index, {
      'Procedure Id': procedure.id,
      'Procedure Slug': procedure.slug,
      'Procedure Category': procedure.category.charAt(0).toUpperCase() + procedure.category.slice(1),
      'Procedure Status': procedure.status.charAt(0).toUpperCase() + procedure.status.slice(1),
      'Target Id': procedure.target_id,
      'Target Type': procedure.target_type,
      'Index': step.index,
      'Name': step.name
    })

    gtm.track({ ecommerce: null })
    gtm.track({
      event: 'view_step',
      ecommerce: {
        value: centsToAmount(_get(procedure_execution, 'actions_data.transaction.amount', 0)),
        items: this.products({ items: order, procedure })
      },
      step_index: step.index,
      step_name: step.name,
      procedure_id: procedure.id,
      procedure_slug: procedure.slug,
      procedure_category: procedure.category,
      procedure_target_type: procedure.target_type,
      procedure_target_id: procedure.target_id
    })
  }

  static nextStep = () => {
    mixpanel.track('Clicked next')
  }

  static viewItemList = ({ products = [], procedure }) => {
    gtm.track({ ecommerce: null })
    gtm.track({
      event: 'view_item_list',
      ecommerce: {
        items: this.products({ items: products, procedure })
      },
      procedure_id: procedure.id,
      procedure_slug: procedure.slug,
      procedure_category: procedure.category,
      procedure_target_type: procedure.target_type,
      procedure_target_id: procedure.target_id
    })
  }

  static addToCart = ({ product, quantity, index, procedure }) => {
    gtm.track({ ecommerce: null })
    gtm.track({
      event: 'add_to_cart',
      ecommerce: {
        items: [this.product({ product, quantity, index, procedure })]
      },
      procedure_id: procedure.id,
      procedure_slug: procedure.slug,
      procedure_category: procedure.category,
      procedure_target_type: procedure.target_type,
      procedure_target_id: procedure.target_id
    })
  }

  static removeFromCart = ({ product, quantity, index, procedure }) => {
    gtm.track({ ecommerce: null })
    gtm.track({
      event: 'remove_from_cart',
      ecommerce: {
        items: [this.product({ product, quantity, index, procedure })]
      },
      procedure_id: procedure.id,
      procedure_slug: procedure.slug,
      procedure_category: procedure.category,
      procedure_target_type: procedure.target_type,
      procedure_target_id: procedure.target_id
    })
  }

  static addPaymentInfo = ({ procedure_execution, procedure, order }) => {
    gtm.track({ ecommerce: null })
    gtm.track({
      event: 'add_payment_info',
      ecommerce: {
        value: centsToAmount(_get(procedure_execution, 'actions_data.transaction.amount', 0)),
        payment_method: _get(procedure_execution, 'actions_data.transaction.payment_method'),
        items: this.products({ items: order, procedure })
      },
      procedure_id: procedure.id,
      procedure_slug: procedure.slug,
      procedure_category: procedure.category,
      procedure_target_type: procedure.target_type,
      procedure_target_id: procedure.target_id
    })
  }

  static checkout = ({ procedure_execution, procedure, order }) => {
    const transaction = _get(procedure_execution, 'actions_data.transaction')
    const currency = _get(transaction, 'currency', 'EUR')
    const products = this.products({ items: order, procedure, currency })

    mixpanel.track('Checkout', {
      'Procedure Id': procedure.id,
      'Procedure Slug': procedure.slug,
      'Procedure Category': procedure.category.charAt(0).toUpperCase() + procedure.category.slice(1),
      'Procedure Status': procedure.status.charAt(0).toUpperCase() + procedure.status.slice(1),
      'Target Id': procedure.target_id,
      'Target Type': procedure.target_type,
      'Cart': products
    })

    gtm.track({ ecommerce: null })
    gtm.track({
      event: 'checkout',
      ecommerce: {
        value: centsToAmount(_get(procedure_execution, 'actions_data.transaction.amount', 0)),
        currency,
        items: products
      },
      procedure_id: procedure.id,
      procedure_slug: procedure.slug,
      procedure_category: procedure.category,
      procedure_target_type: procedure.target_type,
      procedure_target_id: procedure.target_id
    })
  }

  static execution = ({ procedure_execution, procedure, order }) => {
    const transaction = _get(procedure_execution, 'actions_data.transaction')
    const amount = centsToAmount(_get(transaction, 'amount', 0))
    const currency = _get(transaction, 'currency', 'EUR')
    const fee = _get(procedure_execution, 'actions_data.fee.active', false) ? centsToAmount(_get(procedure, 'actions.fee.parameters.amount', 0)) : 0
    const products = this.products({ items: order, procedure, currency })

    mixpanel.track('Execution', {
      'Execution Id': procedure_execution.id,
      'Procedure Id': procedure.id,
      'Procedure Slug': procedure.slug,
      'Procedure Category': procedure.category.charAt(0).toUpperCase() + procedure.category.slice(1),
      'Procedure Status': procedure.status.charAt(0).toUpperCase() + procedure.status.slice(1),
      'Target Id': procedure.target_id,
      'Target Type': procedure.target_type,
      'Cart': products
    })

    gtm.track({ ecommerce: null })
    gtm.track({
      event: 'execution',
      ecommerce: {
        affiliation: 'Koalect Platform',
        transaction_id: transaction.id,
        currency: transaction.currency,
        payment_method: transaction.payment_method,
        category: transaction.category,
        is_subscription: !!transaction.is_subscription,
        target_id: transaction.target_id,
        target_type: transaction.target_type,
        value: amount,
        tax: 0,
        shipping: 0,
        coupon: '',
        fee: fee,
        items: products
      },
      execution_id: procedure_execution.id,
      procedure_id: procedure.id,
      procedure_slug: procedure.slug,
      procedure_category: procedure.category,
      procedure_target_type: procedure.target_type,
      procedure_target_id: procedure.target_id
    })
  }

  static transaction = ({ procedure, procedure_execution, order }) => {
    const transaction = _get(procedure_execution, 'actions_data.transaction')
    const amount = centsToAmount(_get(transaction, 'amount', 0))
    const fee = _get(procedure_execution, 'actions_data.fee.active', false) ? centsToAmount(_get(procedure, 'actions.fee.parameters.amount', 0)) : 0
    const products = this.products({ items: order, procedure, currency: transaction.currency })

    mixpanel.track('New transaction', {
      'Id': transaction.id,
      'Category': transaction.category.charAt(0).toUpperCase() + transaction.category.slice(1),
      'Created At': transaction.created_at,
      'Updated At': transaction.updated_at,
      'Amount': amount,
      'Currency': transaction.currency,
      'Type': transaction.is_recurring ? 'Monthly transaction' : 'One-time',
      'Status': transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1),
      'Payment Method': transaction.payment_method.charAt(0).toUpperCase() + transaction.payment_method.slice(1),
      'Target Type': transaction.target_type,
      'Target Id': transaction.target_id,
      'Subscription': transaction.is_subscription,
      'Procedure Slug': procedure.slug,
      'Procedure ID': procedure.id,
      'Cart': products
    })

    mixpanel.people.track_charge(amount, {
      '$time': transaction.created_at,
      'Id': transaction.id,
      'Category': transaction.category.charAt(0).toUpperCase() + transaction.category.slice(1),
      'Updated At': transaction.updated_at,
      'Currency': transaction.currency,
      'Type': transaction.is_recurring ? 'Monthly transaction' : 'One-time',
      'Status': transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1),
      'Payment Method': transaction.payment_method.charAt(0).toUpperCase() + transaction.payment_method.slice(1),
      'Target Type': transaction.target_type,
      'Target Id': transaction.target_id,
      'Procedure Slug': procedure.slug,
      'Procedure ID': procedure.id
    });

    gtm.track({ ecommerce: null })
    gtm.track({
      event: 'transaction',
      ecommerce: {
        affiliation: 'Koalect Platform',
        transaction_id: transaction.id,
        currency: transaction.currency,
        payment_method: transaction.payment_method,
        category: transaction.category,
        is_subscription: !!transaction.is_subscription,
        target_id: transaction.target_id,
        target_type: transaction.target_type,
        value: amount,
        tax: 0,
        shipping: 0,
        coupon: '',
        fee: fee,
        items: products
      },
      procedure_id: procedure.id,
      procedure_slug: procedure.slug,
      procedure_category: procedure.category,
      procedure_target_type: procedure.target_type,
      procedure_target_id: procedure.target_id
    })
  }

  static products ({ items = [], procedure, currency = 'EUR' }) {
    return items.map((product, index) => this.product({ product, currency, procedure, index }))
  }

  static product ({ product = {}, type = 'Product', currency = 'EUR', procedure, index = 0 }) {
    const clientName = _get(store.getters, 'client/client.name')
    const id = _get(product, 'id', '')
    const name = Object.values(_get(product, 'name', {})).find(name => name)

    return {
      affiliation: 'Koalect Platform',
      item_id: id,
      item_name: name,
      item_brand: clientName,
      item_category: Object.values(_get(product, 'label', {})).find(label => label),
      item_category2: type,
      item_category3: _get(procedure, 'target_type'),
      item_list_id: _get(procedure, 'id'),
      item_list_name: Object.values(_get(procedure, 'name')).find(name => name),
      index,
      price: centsToAmount(_get(product, 'price', 0)),
      currency,
      quantity: product.quantity
    }
  }
}
