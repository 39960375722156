<template>
  <fieldset
    class="frequencies"
    v-if="frequencies.length"
  >
    <legend class="visually-hidden">
      {{ t('literal.choose_payment_frequency') }}
    </legend>

    <div
      class="frequency"
      v-for="({ value, text }) in frequencies"
      :key="value"
    >
      <input
        type="radio"
        class="frequency__input visually-hidden"
        v-model="frequency"
        :value="value"
        :disabled="frequencies.length === 1"
        :id="`${id}_${value}`"
      >

      <label
        class="frequency__label d-block padding--12 align--center"
        :for="`${id}_${value}`"
      >
        <fa-icon
          class="margin__r--6"
          :class="{ 'd-none': frequencies.length === 1 }"
          :icon="value === frequency ? ['fal', 'check-circle'] : ['fal', 'circle']"
          aria-hidden="true"
        />

        {{ translate(text) }}
      </label>
    </div>
  </fieldset>
</template>

<script setup>
import { computed, inject, toRefs } from 'vue'
import { useModule } from '@/composables/app/useModule'
import { useProcedureTransaction } from '@/composables/modules/procedures'
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'

const props = defineProps({ block: { type: Object, required: true } })

const { block } = toRefs(props)

const procedure = inject('procedure')
const procedure_execution = inject('procedure_execution')

const { t, translate } = useI18n()
const { frequency } = useProcedureTransaction({ procedure, procedure_execution })
const { id, attributes } = useModule({ component: block, parent: procedure_execution })

const frequencies = computed(() => _get(attributes.value, 'options', []))

if (!frequency.value || !frequencies.value.some(f => f.value === frequency.value)) frequency.value = _get(frequencies.value, '0.value', 'one_time')
</script>

<style lang="scss" scoped>
.frequencies {
  border: 1px solid $dw;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  @include mq(md) {
    flex-direction: row;
  }
}

.frequency {
  flex-grow: 1;
  position: relative;

  &__input {
    &:focus+.frequency__label {
      border-color: $dg;
    }

    &:checked+.frequency__label {
      cursor: default;
      background-color: $color--primary;
      border-color: $color--primary;
      color: set-contrast($color--primary);
    }

    &:disabled+.frequency__label {
      background: transparent;
      color: $black;
      border-color: transparent;
    }
  }

  &__label {
    color: $lg;
    border-radius: 6px;
    width: 100%;
    user-select: none;
    white-space: nowrap;
    transition: all .3s;
    cursor: pointer;
    border: 1px solid transparent;

    &:hover {
      background-color: tint($color--primary, 95%, 95%);
      border-color: tint($color--primary, 95%, 95%);
      color: $black;
    }
  }
}
</style>