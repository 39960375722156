import { Plugin, PluginKey } from 'prosemirror-state'
import { get as _get } from 'lodash'

export default ({ handler }) => {
  return new Plugin({
    key: new PluginKey('handlepasteImage'),
    props: {
      handlePaste: (view, event) => {
        const files = _get(event, 'clipboardData.files', [])

        if (!files.length) return

        event.preventDefault()

        handler({ files: Array.from(files) })
      }
    }
  })
}