<template>
  <ul
    class="nav"
    :class="{ 'nav--submenu': currentSubmenu }"
    v-if="allLinks.length"
  >
    <template v-if="currentSubmenu">
      <li>
        <button
          class="nav__back"
          @click="handleClickBack()"
        >
          <fa-icon
            class="nav__arrow"
            :icon="['fas', 'chevron-left']"
          />

          {{ t('literal.back') }}
        </button>
      </li>

      <li>
        <app-link
          class="nav__parent"
          :link="currentSubmenu.link"
          target="_self"
        />
      </li>
    </template>

    <li
      class="nav__item"
      v-for="({ key, link, links: items }) in currentLinks"
      :key="`nav_item_${key}`"
    >
      <span>
        <app-link
          class="nav__a"
          :link="link"
          target="_self"
          v-slot="{ text }"
        >
          {{ text }}

          <fa-icon
            class="nav__arrow"
            :icon="['fal', 'long-arrow-right']"
            fixed-width
            v-if="!items?.length"
          />
        </app-link>

        <button
          class="nav__btn"
          type="button"
          v-if="items?.length"
          @click="handleClickSubmenu(key)"
        >
          <fa-icon
            class="nav__arrow"
            :icon="['fas', 'chevron-right']"
          />
        </button>
      </span>
    </li>
  </ul>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'

import AppLink from '&/atoms/AppLink'

const emit = defineEmits(['submenu'])

const props = defineProps({
  links: { type: Array, default: () => [] }
})

const { t } = useI18n()

const submenu = ref(null)
const allLinks = computed(() => addIndex(props.links))

const currentSubmenu = computed(() => _get(allLinks.value, submenu.value))

const currentLinks = computed(() => _get(currentSubmenu.value, 'links') || allLinks.value)

const handleClickSubmenu = key => {
  submenu.value = key

  emit('submenu', key)
}

const handleClickBack = () => handleClickSubmenu(currentSubmenu.value?.key.split('.').slice(0, -2).join(''))

const addIndex = (arr, prefix = '') => arr.map((item, index) => {
  const key = prefix ? `${prefix}.${index}` : `${index}`

  return item.links?.length ? { key: key, ...item, links: addIndex(item.links, `${key}.links`) } : { key: key, ...item }
})
</script>

<style lang="scss" scoped>
$black: #141415;
$color--primary: #3122bf;
$color--tertiary: #dbd8f8;

.nav {
  &--submenu {
    background: $white;
    height: 100%;

    :deep(.nav__a) {
      color: $black;
    }
  }

  &__item {
    border-top: 1px solid rgba(#fff, 0.2);

    &:last-child {
      border-bottom: 1px solid rgba(#fff, 0.2);
    }

    & > span {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
    }
  }

  &__btn {
    padding: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .nav--submenu &{
      color: $black;
    }
  }

  &__arrow {
    font-size: rem(18px);
  }

  :deep(.nav__a) {
    padding: 17.5px 20px 17.5px 20px;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  &__back {
    background: $color--primary;
    padding: 12.5px 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    text-align: left;
  }

  :deep(.nav__parent) {
    background: $color--tertiary;
    padding: 12.5px 20px;
    display: block;
    color: $black;
  }
}
</style>