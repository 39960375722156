import { toValue } from 'vue'
import { defineStore } from 'pinia'
import { set as _set, get as _get, pick as _pick } from 'lodash'
import Procedure from '@/classes/procedures/Procedure'

export const useProcedureStore = defineStore('ProcedureStore', {
  state: () => ({
    procedure: {},
    navigation: {},
    authorization: {},
    loader: false,
    error: {}
  }),
  getters: {
    getProcedure: state => ({ id, dependencies }) => {
      return state.$getItem({
        resource: 'procedure',
        key: id,
        dependencies,
        fetch: () => state.fetchProcedure({ id: toValue(id) })
      })
    },
    getNavigation: state => ({ id }) => {
      return _get(state.navigation, toValue(id), {})
    },
    getProcedureAuthorization: state => ({ id }) => {
      return state.$getItem({
        resource: 'authorization',
        key: id
      })
    }
  },
  actions: {
    fetchProcedure ({ id }) {
      return this.$fetchItem({
        endpoint: `/api/v1/procedures/${toValue(id)}`,
        resource: `procedure.${toValue(id)}`,
        model: Procedure
      })
    },
    patchProcedure ({ id, params }) {
      return this.$patchItem({
        endpoint: `/api/v1/procedures/${toValue(id)}`,
        resource: `procedure.${toValue(id)}`,
        model: Procedure,
        params: {
          procedure: _pick(new Procedure(params), Object.keys(params))
        }
      })
    },
    fetchProcedureTransactionCheck ({ procedure_id, transaction_id }) {
      return this.$fetchItem({
        endpoint: `/api/v1/procedures/${toValue(procedure_id)}/transactions/${toValue(transaction_id)}/check`,
      })
    },
    fetchProcedureAuthorization ({ id }) {
      return this.$fetchItem({
        endpoint: `/api/v1/procedures/${toValue(id)}/authorization`,
        resource: `authorization.${toValue(id)}`
      })
    },
    postNavigation ({ procedure_id, from }) {
      _set(this.navigation, procedure_id, { fullPath: from.fullPath, params: from.params })
    }
  },
  persist: {
    pick: ['navigation'],
    storage: sessionStorage
  }
})