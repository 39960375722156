<template>
  <div class="header--sticky">
    <header
      class="header"
      :class="{'header--fixed': mobileMenuOpened}"
      v-if="section"
    >
      <div class="wrapper">
        <div class="header__nav">
          <div class="header__nav">
            <router-link
              :to="{ name: 'Home', params: { locale: $i18n.locale } }"
              v-show="client.logo.url"
              class="header__logo"
            >
              <img
                :src="client.logo.url"
                :alt="client.name"
              >
            </router-link>

            <ul class="nav">
              <li
                class="nav__el nav__link bold"
                v-for="({ link }, index) in links"
                :key="`nav__el-${index}`"
              >
                <button
                  @click.prevent="setRouteNavigation(link)"
                  :class="{'nav__link--active': isVisited(link)}"
                >
                  {{ $i18n.translate(link.text) }}
                </button>
              </li>
            </ul>
          </div>

          <div class="header__nav header__nav--right">
            <ul class="header__sessions">
              <li
                class="header__sessions-item"
                v-if="!isAuthenticated"
              >
                <button
                  @click.prevent="openSessionAppModal('register')"
                  class="header__sessions-register bold link--naked"
                >
                  <fa-icon
                    class="margin__r--6"
                    :icon="['fas', 'user']"
                  />
                  <p v-html="$i18n.t('literal.register')" />
                </button>
              </li>

              <li v-if="isAuthenticated && user">
                <router-link
                  class="header__sessions-user"
                  :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
                >
                  <user-profile-img :avatar="user.avatar" />
                </router-link>
              </li>
            </ul>

            <template
              v-for="({ link }, index) in actions"
              :key="`cta_${index}`"
            >
              <button
                @click.prevent="setRouteNavigation(link)"
                class="header__action cta"
              >
                <fa-icon :icon="['fas', 'plus']" />
                {{ $i18n.translate(link.text) }}
              </button>
            </template>

            <app-burger-menu
              class="header__burger"
              :state="mobileMenuOpened"
              @toggle="toggleMobileMenu"
            />
          </div>
        </div>
      </div>

      <transition name="fade-mobile-menu">
        <section
          class="mobile__menu"
          v-if="section && mobileMenuOpened"
        >
          <div class="wrapper">
            <ul class="mobile__list mobile__nav">
              <li
                v-for="({ link }, index) in links"
                :key="`mobile_menu_item_${index}`"
              >
                <a
                  @click.prevent="setRouteNavigation(link); mobileMenuOpened = false"
                  class="nav__link link--naked bold"
                  :class="{'nav__link--active': isVisited(link)}"
                >
                  {{ $i18n.translate(link.text) }}
                </a>
              </li>
            </ul>

            <div class="mobile__list mobile__actions">
              <template
                v-for="({ link }, index) in actions"
              >
                <button
                  @click.prevent="setRouteNavigation(link); mobileMenuOpened = false"
                  :disabled="!isActive(link)"
                  class="cta"
                  v-if="link"
                  :key="`cta_${index}`"
                >
                  <fa-icon :icon="['fas', 'plus']" />
                  {{ $i18n.translate(link.text) }}
                </button>
              </template>
            </div>

            <ul class="mobile__list mobile__sessions">
              <li v-if="!isAuthenticated">
                <a @click.prevent="openSessionAppModal('login')">
                  <fa-icon
                    class="margin__r--6 mobile__icon"
                    :icon="['fal', 'sign-in']"
                    fixed-width
                  />
                  {{ $i18n.t('literal.login') }}
                </a>
              </li>

              <li
                class="mobile__sessions-item header__sessions-item"
                v-if="!isAuthenticated"
              >
                <a
                  @click.prevent="openSessionAppModal('register')"
                  class="link__color--black link--naked pointer"
                >
                  <fa-icon
                    class="margin__r--6 mobile__icon"
                    :icon="['fal', 'user-plus']"
                    fixed-width
                  />
                  {{ $i18n.t('literal.register') }}
                </a>
              </li>

              <li
                class="mobile__sessions-item header__sessions-item pointer"
                v-if="isAuthenticated && user"
              >
                <router-link
                  :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
                  @click.capture="mobileMenuOpened = false"
                >
                  <user-profile-img
                    size="2"
                    :avatar="user.avatar"
                  />
                </router-link>
              </li>

              <li
                class="mobile__sessions-item header__sessions-item pointer"
                v-if="isAuthenticated && user"
              >
                <router-link :to="{ name: 'Logout' }">
                  <fa-icon
                    class="margin__r--6 color--danger"
                    :icon="['fal', 'sign-out']"
                    fixed-width
                  />
                  {{ $i18n.t('literal.logout') }}
                </router-link>
              </li>
            </ul>
          </div>
        </section>
      </transition>
    </header>

    <app-modal
      v-if="showSessionAppModal"
      size="sm"
      @close="showSessionAppModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="template"
          @authenticated="showSessionAppModal = false"
        />
      </template>
    </app-modal>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'
import UserProfileImg from '&/atoms/UserProfileImg'
import PageRouteNavigation from '%/PageRouteNavigation'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'

export default defineComponent({
  name: 'CustomHeaderCNCD',
  components: {
    UserProfileImg,
    AppBurgerMenu,
    SessionForms: defineAsyncComponent(() => import('&/modules/sessions/SessionForms')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      showSessionAppModal: false,
      mobileMenuOpened: false,
      template: null
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    attributes () {
      return _get(this.section, 'attributes', {})
    },
    actions () {
      return _get(this.attributes, 'actions', [])
    },
    navigation () {
      return _get(this.attributes, 'navigation', [])
    },
    isMultilingual () {
      return this.client.active_languages.length > 1
    },
    links () {
      return this.navigation.filter(({ link }) => link.text[this.$i18n.locale])
    }
  },
  methods: {
    openSessionAppModal (template) {
      this.template = template
      this.showSessionAppModal = true
    },
    toggleMobileMenu (value) {
      this.mobileMenuOpened = !value
    }
  }
})
</script>

<style lang="scss" scoped>
$color-primary: #76b729;
$color-primary-darker: #619723;
$color-secondary: #ffa500;

.wrapper {
  padding: $padding__base $padding__base * 2;
  max-width: 1200px;
  margin: auto;

  @include mq(sm) {
    padding: $padding__base * 2 $padding__base * 4;
  }
}

.cta {
  align-items: center;
  background-color: $color-primary;
  border-radius: $radius__buttons;
  color: $white;
  display: inline-flex;
  font-size: rem(16px);
  font-weight: 700;
  padding: 8px 16px;
  text-transform: uppercase;
  transition: background-color $transition__duration;

  & svg {
    margin-right: 12px;
    transform-origin: center;
    transition: transform $transition__duration;
  }

  &:hover, &:focus {
    background-color: $color-secondary;

    & svg {
      transform: rotate(90deg);
    }
  }
}

.header {
  background-color: $white;
  box-shadow: 0 4px 8px 0 rgba(82, 97, 115, 0.1);
  font-size: rem(14px);

  &--sticky {
    position: sticky;
    top: 0;
    z-index: 999;
  }

  &--fixed {
    background-color: $white;
    left: 0;
    height: 100vh;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1000;
    @include mq(sm) {
      height: initial;
    }
  }

  &__nav {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__sessions {
    display: none;

    @include mq(sm) {
      display: block;
      margin-right: $margin__base * 2;

      &-register{
        align-items: center;
        color: $color-primary;
        display: flex;
        transition: color $transition__duration;

        &:hover,
        &:focus {
          color: $color-primary-darker;
        }
      }
    }
  }

  &__logo {
    height: 38px;

    @include mq(sm){
      height: 60px;
      margin-right: $margin__base * 5;
    }

    & img {
      height: 100%;
    }
  }

  &__action {
    display: none;

    @include mq(sm){
      display: block;
    }
  }

  @include mq(lg){
    &__burger {
      display: none;
    }
  }
}

.nav {
  display: none;

  @include mq(lg) {
    display: flex;

    &__el {
      padding: 2px;

      &:not(:first-of-type) {
        margin-left: $margin__base;
      }
    }
  }

  &__link {
    color: $black;
    transition: color $transition__duration;

    &:hover,
    &:focus {
      color: $color-primary;
    }

    &--active {
      color: $color-primary;
      pointer-events: none;
    }
  }
}

.mobile {
  &__list {
    & li:not(:last-child) {
      margin-bottom: calc($margin__base / 2);
    }

    &:not(:first-child) {
      margin-top: $margin__base * 2;
    }

    @include mq(sm) {
      align-items: center;
      display: flex;
      justify-content: space-around;
    }
  }

  @include mq(sm){
    &__actions {
      display: none;
    }
  }

  &__sessions {
    @include mq(sm){
      display: none;
    }

    &-item {
      margin-right: $margin__base * 2;
    }
  }

  @include mq(lg){
    &__menu {
      display: none;
    }
  }

  &__icon {
    color: $color-primary;
  }
}

.fade-mobile-menu-enter-active {
  animation: fadeTranslationY $transition__duration ease-in-out;
}

.fade-mobile-menu-leave-active {
  animation: fadeTranslationY $transition__duration ease-in-out reverse;
}

@keyframes fadeTranslationY {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 100%;
    transform: translateY(0px);
  }
}

.modal__body {
  padding: $padding__base * 2;
    @include mq(md) {
    padding: $padding__base * 3 $padding__base * 4;
  }
}
</style>
