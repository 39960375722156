<template>
  <header
    class="header bg__color--white"
    :class="{ 'is-fixed': showMobileMenu }"
  >
    <div class="container header__wrapper">
      <a
        class="logo"
        :href="translate(logo.url)"
        v-if="logoImage && translate(logo.url)"
      >
        <img
          class="logo__img"
          :src="logoImage"
          :alt="client.name"
        >
      </a>

      <router-link
        :to="{ name: 'Home', params: { locale } }"
        class="logo"
        v-else-if="logoImage"
      >
        <img
          class="logo__img"
          :src="logoImage"
          :alt="client.name"
        >
      </router-link>

      <nav
        v-if="links.length > 0"
        class="nav d-none d-md-flex"
      >
        <ul class="d-flex nav__ul">
          <li
            class="nav__li"
            v-for="({ item }, key) in links"
            :key="`link_${key}`"
          >
            <app-link
              :link="item"
              class="nav__a"
            />
          </li>
        </ul>
      </nav>

      <div class="header__action">
        <router-link
          class="session bold d-none d-sm-flex align-items-center"
          :to="{ name: 'Profile', params: { locale } }"
          v-if="isAuthenticated && user"
        >
          <user-profile-img
            :size="2"
            :avatar="user.avatar"
          />

          <p class="margin__l--6">
            {{ user.firstname }}
          </p>
        </router-link>

        <button
          class="session bold d-none d-sm-block"
          @click.prevent="toggleSessionModal('login')"
          v-else
        >
          <fa-icon
            class="margin__r--6"
            :icon="['fas', 'user']"
          />

          {{ t('literal.login') }}
        </button>

        <app-link
          :link="cta"
          class="d-none d-sm-inline-block btn btn__color--prim btn__size--classic bold header__cta"
        />

        <app-burger-menu
          class="burger d-md-none"
          :state="showMobileMenu"
          @toggle="toggleMobileMenu"
        />
      </div>
    </div>

    <transition name="fade-mobile-menu">
      <nav
        class="mobile-nav d-md-none"
        v-if="showMobileMenu"
      >
        <div class="container mobile-nav__wrapper">
          <div class="mobile-nav__container">
            <ul
              class="mobile-nav__ul align--center"
              v-if="links.length > 0"
            >
              <li
                class="mobile-nav__li"
                v-for="({ item }, key) in links"
                :key="`link_${key}`"
              >
                <app-link
                  :link="item"
                  class="mobile-nav__a"
                />
              </li>
            </ul>
          </div>

          <div class="mobile-nav__action d-flex justify-content-center align-items-center">
            <router-link
              class="mobile-nav__session bold d-flex align-items-center"
              :to="{ name: 'Profile', params: { locale } }"
              v-if="isAuthenticated && user"
            >
              <user-profile-img
                :size="2"
                :avatar="user.avatar"
              />

              <p class="margin__l--6">
                {{ user.firstname }}
              </p>
            </router-link>

            <button
              class="mobile-nav__session bold"
              @click.prevent="toggleSessionModal('login')"
              v-else
            >
              <fa-icon
                class="margin__r--6"
                :icon="['fas', 'user']"
              />

              {{ t('literal.login') }}
            </button>

            <app-link
              :link="cta"
              class="btn btn__color--prim btn__size--classic bold mobile-nav__cta"
            />
          </div>
        </div>
      </nav>
    </transition>

    <app-modal
      v-if="showSessionModal"
      size="sm"
      @close="showSessionModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="sessionTemplate"
          @authenticated="showSessionModal = false"
        />
      </template>
    </app-modal>
  </header>
</template>

<script setup>
import { computed, defineAsyncComponent, ref, watch } from 'vue'
import { mapGetters } from '@/store/map-state'
import { get as _get } from 'lodash'
import { useI18n } from '@/vendors/i18n'
import { scrollTo } from '@/utils/utils'
import { useRoute } from 'vue-router'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const AppBurgerMenu = defineAsyncComponent(() => import('&/atoms/AppBurgerMenu'))
const UserProfileImg = defineAsyncComponent(() => import('&/atoms/UserProfileImg'))
const AppModal = defineAsyncComponent(() => import('&/organisms/AppModal'))
const SessionForms = defineAsyncComponent(() => import('&/modules/sessions/SessionForms'))

const props = defineProps({
  section: { type: Object, required: true }
})

const { locale, t, translate } = useI18n()
const route = useRoute()
const { client } = mapGetters('client')
const { isAuthenticated, user } = mapGetters('auth')

const showSessionModal = ref(false)
const showMobileMenu = ref(false)
const sessionTemplate = ref('login')

const logo = computed(() => _get(props, 'section.attributes.logo', {}))
const links = computed(() => _get(props, 'section.attributes.links', []))
const cta = computed(() => _get(props, 'section.attributes.cta', {}))
const logoImage = computed(() => translate(_get(logo.value, 'image', {})))

const toggleSessionModal = template => {
  sessionTemplate.value = template
  showSessionModal.value = true
}

const toggleMobileMenu = () => {
  scrollTo()

  setTimeout(() => showMobileMenu.value = !showMobileMenu.value, 100)
}

watch(route, () => showMobileMenu.value = false)
</script>

<style lang="scss" scoped>
$black: #131415;

.header {
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
  position: relative;
  z-index: 9999;

  &.is-fixed {
    position: fixed;
    width: 100%;
    top: 0;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__action {
    display: flex;
    align-items: center;
    gap: $margin__base;
    padding: $padding__base 0;

    @include mq(md) {
      align-items: flex-end;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__cta {
    @include mq(sm) {
      font-size: rem(18px);
    }
  }
}

.logo {
  max-width: 80px;
  display: block;
  padding: calc($padding__base / 2) 0;

  @include mq(sm) {
    max-width: 100px;
  }

  @include mq(md) {
    max-width: 140px;
  }

  &__img {
    max-width: 100%;
    height: auto;
  }
}

.nav {
  padding: 0 $padding__base;
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
  font-size: rem(18px);

  @include mq(lg) {
    font-size: rem(20px);
  }

  @include mq(xl) {
    font-size: rem(24px);
  }

  &__li {
    border-left: 1px solid $black;

    &:last-child {
      border-right: 1px solid $black;
    }
  }

  &:deep(.nav__a) {
    display: block;
    padding: $padding__base * 3.5 $padding__base;
    transition: all $transition__duration;
    color: $black;

    @include mq(xl) {
      padding: $padding__base * 3.5 $padding__base * 2;
    }

    &.is-active,
    &:focus,
    &:hover {
      color: $color--primary;
    }
  }
}

.session {
  color: $black;
  font-size: rem(18px);
}

.burger.menu {
  padding: 0;
}

.mobile-nav {
  position: absolute;
  bottom: 0;
  z-index: 999;
  background: $white;
  width: 100%;
  height: calc(100vh - 67px);
  top: 67px;

  &.fade-mobile-menu-enter-active,
  &.fade-mobile-menu-leave-active {
    transition: opacity 0.5s ease;
  }

  &.v-enter-from,
  &.fade-mobile-menu-leave-to {
    opacity: 0;
  }


  @include mq(sm) {
    height: calc(100vh - 78px);
    top: 78px;
  }

  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: $padding__base;
    padding-bottom: $padding__base;

    @include mq(sm) {
      padding-top: $padding__base * 3;
      padding-bottom: $padding__base * 3;
    }
  }

  &__container {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__ul {
    font-size: rem(24px);

    @include mq(sm) {
      font-size: rem(32px);
    }
  }

  &__li {
    &:not(:last-child) {
      border-bottom: 1px solid $black;
    }
  }

  &:deep(.mobile-nav__a) {
    display: block;
    padding: $padding__base;
    transition: all $transition__duration;
    color: $black;

    @include mq(sm) {
      padding: $padding__base * 2;
    }

    &.is-active,
    &:focus,
    &:hover {
      color: $color--primary;
    }
  }

  &__action {
    gap: $margin__base * 2;
    padding-top: $padding__base * 2;
    border-top: 1px solid $black;
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}
</style>