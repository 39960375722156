<template>
  <div v-if="blocks.length">
    <nav
      class="nav"
      :class="{'nav--full': tab}"
    >
      <ul class="nav__ul">
        <template
          v-for="block in blocks"
          :key="`column_${block.name}`"
        >
          <app-component-loader
            class="nav__li block"
            :objects="{ block }"
            :component-path="block.component_path"
          />
        </template>
      </ul>
    </nav>

    <div
      class="padding__tb--36"
      v-if="rows.length"
    >
      <template-row
        v-for="(row, index) in rows"
        :key="`${currentTab}_row_${index}`"
        :row="row"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, toRefs, onBeforeMount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from '@/vendors/i18n'
import { slugify } from '@/utils/utils'
import { get as _get } from 'lodash'

import AppComponentLoader from '&/atoms/AppComponentLoader'
import TemplateRow from '&/modules/templates/TemplateRow'

const props = defineProps({
  column: { type: Object, required: true }
})

const { column } = toRefs(props)

const { replace } = useRouter()
const route = useRoute()
const { translate } = useI18n()

const query = computed(() => _get(column.value, 'settings.query'))
const currentTab = computed(() => _get(route.query, query.value))

const blocks = computed(() => column.value.blocks
  .filter(block => _get(block, 'attributes.show_navigation', false))
  .map(block => {
    if (query.value) {
      block.attributes.path = ''
      block.attributes.query = { [query.value]: slugify(translate(_get(block, 'attributes.text_navigation', {}))) }
    }

    return block
  })
)


const defaultTab = computed(() => blocks.value.find(block => block))

const tab = computed(() => blocks.value.find(block =>
  Object.values(_get(block, 'attributes.text_navigation', {}))
    .map(text => slugify(text))
    .includes(currentTab.value)
))

const rows = computed(() => _get(tab.value, 'configuration.rows', []))

onBeforeMount(() => {
  if (!query.value || !blocks.value.length) return

  if (tab.value) {
    if (currentTab.value !== _get(tab.value, `attributes.query.${query.value}`)) replace({ query: _get(tab.value, 'attributes.query') })
  } else {
    replace({ query: _get(defaultTab.value, 'attributes.query') })
  }
})
</script>

<style lang="scss" scoped>
.nav {
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  &::after {
    box-shadow: inset 0 -1px 0 0 $dw;
    bottom: 0;
  }

  &--full {
    &::before,
    &::after {
      width: 100vw;
    }

    &::before {
      border-top: 1px solid $dw;
    }
  }

  &__ul {
    position: relative;
    z-index: 10;
    overflow-x: auto;
    display: flex;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 2px;

    @include mq(sm) {
      padding-bottom: 0;
    }

    &::-webkit-scrollbar {
      height: 2px;
    }

    &::-webkit-scrollbar-track {
      background: $dw;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $vlg;
      border-radius: 10px;
    }

    @supports not selector(::-webkit-scrollbar) {
      & {
        scrollbar-color: $vlg $dw;
        scrollbar-width: thin;
      }
    }
  }

  &__li {
    &:not(:last-child) {
      margin-right: 12px;

      @include mq(sm) {
        margin-right: 24px;
      }
    }
  }
}
</style>