<template>
  <section
    class="banner"
    :style="sectionStyle"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-sm-10 col-lg-8">
          <article class="banner__content">
            <div
              class="editor editor__content editor__page title__h1"
              :style="{'color': sectionStyle.color}"
              v-html="$i18n.translate(section.attributes.title)"
              v-if="$i18n.translate(section.attributes.title)"
            />

            <div
              class="editor editor__content editor__page"
              :style="{'color': sectionStyle.color}"
              v-html="$i18n.translate(section.attributes.text)"
              v-if="$i18n.translate(section.attributes.text)"
            />

            <button
              type="button"
              :style="buttonStyle"
              class="margin__t--36 btn btn__size--medium btn__color--prim"
              v-if="attributes.button.show && $i18n.translate(attributes.button.text)"
              :disabled="!isActive(attributes.button)"
              @click.prevent="setRouteNavigation(attributes.button)"
            >
              {{ $i18n.translate(attributes.button.text) }}
            </button>
          </article>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import PageRouteNavigation from '%/PageRouteNavigation'
import { useSectionStyle } from '@/composables/modules/pages'

export default defineComponent({
  name: 'SectionBanner',
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  mixins: [PageRouteNavigation],
  setup: props => ({ ...useSectionStyle(props) }),
  computed: {
    attributes () {
      return this.section.attributes
    },
    style () {
      return this.section.style
    }
  }
})
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  padding: $padding__base * 2;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  min-height: 200px;

  @include mq(md) {
    padding: $padding__base * 4;
    min-height: 500px;
  }

  &__min-height {
    min-height: 200px;
  }
}

.text {
  &__baseline {
    font-size: em(32px);
    line-height: 1.2;

    @include mq(md) {
      font-size: em(54px);
    }
  }

  &__subline {
    font-size: em(16px);

    @include mq(md) {
      font-size: em(20px);
    }
  }
}

.title {
  &__h1 {
    font-size: rem(42px);

    @include mq(md) {
      font-size: rem(60px);
    }
  }
}
</style>
