<template>
  <main class="main">
    <section
      class="main__banner"
      :style="{ backgroundImage: `url(${$i18n.translate(attributes.banner.background_image)})` }"
    >
      <article class="banner__content">
        <div
          class="banner__text editor editor__content"
          v-html="$i18n.translate(section.attributes.banner.text)"
        />

        <div class="banner__buttons">
          <button
            type="button"
            class="button button__black bold"
            @click.prevent="setRouteNavigation(section.attributes.banner.button_1)"
          >
            {{ $i18n.translate(section.attributes.banner.button_1.text) }}
          </button>

          <button
            class="button button__yellow bold"
            @click.prevent="setRouteNavigation(section.attributes.banner.button_2)"
          >
            {{ $i18n.translate(section.attributes.banner.button_2.text) }}
          </button>
        </div>
      </article>
    </section>

    <section class="main__projects">
      <div class="container">
        <div class="align--center margin__b--48">
          <h2 class="title--h2 title--black bold projects__title">
            {{ $i18n.translate(section.attributes.projects.title) }}
          </h2>
        </div>

        <div class="row margin__b--36">
          <template
            v-for="project in projects"
            :key="project.id"
          >
            <section class="col-12 col-sm-6 col-md-3">
              <project-card
                class="project-card"
                :project="project"
              />
            </section>
          </template>
        </div>

        <div class="align--center">
          <button
            class="button button__black projects__button bold"
            @click.prevent="setRouteNavigation(section.attributes.projects.button)"
          >
            {{ $i18n.translate(section.attributes.projects.button.text) }}
          </button>
        </div>
      </div>
    </section>

    <section class="main__steps">
      <div class="container">
        <div class="align--center margin__b--48">
          <h2 class="title--h2 steps__title title--white bold">
            {{ $i18n.translate(section.attributes.steps.title) }}
          </h2>
        </div>

        <div class="steps">
          <article
            class="steps__step"
            v-for="(step, index) in section.attributes.steps.steps"
            :key="'step_' + index"
          >
            <div class="step__img-wrapper">
              <img
                class="step__image"
                :src="$i18n.translate(step.img_src)"
              >
            </div>

            <h3 class="step__title title--h3 bold">
              {{ $i18n.translate(step.title) }}
            </h3>

            <div
              class="editor editor__content"
              v-html="$i18n.translate(step.text)"
            />
          </article>
        </div>

        <div class="align--center">
          <button
            class="button button__black steps__button"
            v-if="$i18n.translate(section.attributes.steps.button.text)"
            @click.prevent="setRouteNavigation(section.attributes.steps.button)"
          >
            {{ $i18n.translate(section.attributes.steps.button.text) }}
          </button>
        </div>
      </div>
    </section>

    <section
      class="main__campaigns"
      v-if="campaign_items.length > 0"
    >
      <div class="container">
        <div class="align--center margin__b--48">
          <h2 class="title--h2 title--black campaigns__title bold">
            {{ $i18n.translate(section.attributes.campaigns.title) }}
          </h2>
        </div>

        <template
          v-for="(campaign, index) in campaign_items"
          :key="campaign.id"
        >
          <article
            class="campaign"
            :class="{'campaign--reverse': index % 2}"
          >
            <div
              class="campaign__cover"
              :style="{ backgroundImage: `url(${ campaign.image.url || getImagePath('placeholders/project-card-cover.jpg') })` }"
            />

            <div class="campaign__infos">
              <h3 class="campaign__title title--h3 title--black bold">
                {{ campaign.name[$i18n.locale] }}
              </h3>

              <div
                class="campaign__description editor__content"
                v-if="$i18n.translate(campaign.description)"
                v-html="$i18n.translate(campaign.description)"
              />

              <button
                class="campaign__cta button button__black bold"
                type="button"
                :disabled="isCtaDisabled(campaign)"
                @click.prevent="goToCampaign(campaign)"
              >
                {{ $i18n.translate(campaign.cta.text) }}
              </button>
            </div>
          </article>
        </template>
      </div>
    </section>

    <section class="main__quote">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-10 col-md-10 col-lg-8">
            <section class="quote">
              <fa-icon
                class="quote__icon"
                :icon="['fas', 'quote-left']"
              />

              <div
                class="quote__text editor editor__content"
                v-html="$i18n.translate(section.attributes.quote.quote)"
              />

              <article class="quote__author">
                <figure
                  class="quote__figure"
                  :style="{ backgroundImage: `url(${$i18n.translate(attributes.quote.image)})` }"
                />
                <p class="quote__name">
                  {{ $i18n.translate(section.attributes.quote.author_name) }}
                </p>
                <p class="quote__job">
                  {{ $i18n.translate(section.attributes.quote.author_job) }}
                </p>
              </article>
            </section>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { get as _get } from 'lodash'
import { isPast } from 'date-fns'
import PageRouteNavigation from '%/PageRouteNavigation'
import ProjectCard from '&/organisms/ProjectCard'
import { getImagePath } from '@/utils/utils'
import { projects_overview_options } from '@/configurations/general/components-default-data'

export default defineComponent({
  name: 'CustomHomepageIlesDePaix',
  components: {
    ProjectCard
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  mixins: [PageRouteNavigation],
  data () {
    return {
      campaign_items: []
    }
  },
  setup: () => ({ getImagePath }),
  created () {
    this.fetchProjects({ query: this.projectsQuery, removeStoredItems: true })

    if (this.campaigns.ids.length) {
      this.fetchCampaigns({ query: { campaign_id: this.campaigns.ids } })
        .then(data => this.campaign_items = Object.values(data.items))
    }
  },
  computed: {
    ...mapGetters({
      projects: 'projects/getProjects'
    }),
    attributes () {
      return this.section.attributes
    },
    campaigns () {
      return _get(this.attributes, 'campaigns', {})
    },
    projectsSettings () {
      return _get(this.attributes, 'projects', {})
    },
    projectsSort () {
      return projects_overview_options.find(o => o.value === _get(this.projectsSettings, 'query.sort', 'activity_last'))?.query || { 'sort_by_field[field]': 'activity', 'sort_by_field[direction]': 'desc' }
    },
    projectsQuery () {
      return {
        'sort_by_field[field]': 'current_amount',
        'sort_by_field[direction]': 'desc',
        'per_page': 4,
        ..._get(this.projectsSettings, 'query', {}),
        ...this.projectsSort
      }
    }
  },
  methods: {
    ...mapActions({
      fetchProjects: 'projects/fetchProjects',
      fetchCampaigns: 'campaigns/fetchCampaigns'
    }),
    isCtaDisabled (campaign) {
      const procedure = Array.isArray(campaign.procedures) ? campaign.procedures.find(procedure => procedure.category === 'create-project') : null
      if (!procedure) return true
      if (!campaign.cta.url[this.$i18n.locale] && !campaign.cta.target_name && !campaign.cta.target_url) return true
      if (!isPast(new Date(procedure.start_date)) || (procedure.end_date && isPast(new Date(procedure.end_date)))) return true
      return false
    },
    goToCampaign (campaign) {
      const procedureSlug = Array.isArray(campaign.procedures) ? campaign.procedures.find(procedure => procedure.category === 'create-project').slug : null

      if (_get(campaign, `cta.url[${this.$i18n.locale}]`)) {
        window.open(campaign.cta.url[this.$i18n.locale], '_blank')
      } else {
        this.$router.push({ name: 'ClientProcedure', params: { procedureSlug } }).catch()
      }
    }
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #f2e884;
$color--secondary: #b27d71;
$color--tertiary: #2c2c2b;

.main {
  &__banner {
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;
    min-height: 200px;

    @include aspect-ratio(1440, 697);

    @include mq(md) {
      background-size: cover;
      background-position: center;
      padding: 96px 84px;
      min-height: 500px;

      &::before {
        display: none;
      }
    }
  }

  &__projects {
    background-color: $white;
    padding: $padding__base * 3 0;

    @include mq(md) {
      padding: $padding__base * 6 0;
    }
  }

  &__steps {
    background-position: left;
    background-color: $color--secondary;
    padding: $padding__base * 3 0;

    @include mq(md) {
      padding: $padding__base * 6 0;
    }
  }

  &__campaigns {
    padding: $padding__base * 3 0;
    background: $white;

    @include mq(md) {
      padding: $padding__base * 6 0;
    }
  }

  &__quote {
    background-color: $white;
    padding: $padding__base * 3 0;

    @include mq(md) {
      padding: $padding__base * 8 0 $padding__base * 6 0;
    }
  }
}

.banner {
  &__content {
    width: 100%;
    background-color: $color--secondary;
    padding: $padding__base * 2;

    @include mq(sm) {
      padding: $padding__base * 4;
    }

    @include mq(md) {
      width: 540px;
    }
  }

  &__buttons {
    margin-top: $margin--base * 3;
    display: block;

    @include mq(sm) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .button {
      width: 100%;

      @include mq(sm) {
        width: auto;
      }
    }

    .button:not(:last-child) {
      margin-bottom: $margin--base;

      @include mq(sm) {
        margin-right: $margin--base * 2;
        margin-bottom: 0;
      }
    }
  }

  &__text {
    color: $white;
    font-size: rem(18px);

    @include mq(sm) {
      font-size: rem(20px);
    }
  }
}

.projects {
  &__title {
    max-width: 700px;
    margin: 0 auto
  }

  &__button {
    @include mq(sm) {
      min-width: 240px;
    }
  }
}

.steps {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: $margin--base * 4;
  justify-items: center;
  align-items: center;
  margin-bottom: $margin--base * 4;

  @include mq(md) {
    grid-template-columns: repeat(3, 1fr);
  }

  &__title {
    max-width: 700px;
    margin: 0 auto
  }

  &__step {
    height: 100%;
    color: $white;
  }

  &__button {
    @include mq(sm) {
      min-width: 240px;
    }
  }
}

.step {
  &__img-wrapper {
    height: 50px;
    margin-bottom: $margin--base * 2;
  }

  &__image {
    display: block;
    margin: 0 auto;
    height: 100%;
  }

  &__title {
    margin-bottom: $margin--base;
  }
}

.campaigns {
  &__title {
    max-width: 700px;
    margin: 0 auto
  }
}

.campaign {
  display: grid;
  background: #efdddc;
  grid-template-columns: 1fr;

  @include mq(sm) {
    gap: $margin__base * 2;
    grid-template-columns: repeat(2, 1fr);
  }

  &:not(:last-child) {
    margin-bottom: $margin--base;

    @include mq(sm) {
      margin-bottom: $margin--base * 5;
    }
  }

  &__cover {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    @include aspect-ratio(102, 91);
  }

  &__infos {
    position: relative;
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 2 $padding__base * 8 $padding__base * 2 $padding__base * 7;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      &--reverse {
        .campaign__cover {
          order: 2;
        }

        .campaign__infos {
          order: 1;
        }
      }
    }
  }

  &__title {
    margin-bottom: $margin--base * 1.5;
  }

  &__description {
    margin-bottom: $margin--base * 2;
  }
}

.quote {
  position: relative;
  padding: $padding__base * 2 $padding__base * 2 0 $padding__base * 2;

  &__text {
    position: relative;
    font-size: rem(18px);
    margin-bottom: $margin__base * 2;
    z-index: 10;
    color: $color--tertiary;
    font-weight: bold;

    @include mq(md) {
      font-size: rem(22px);
      margin-bottom: $margin__base * 3;
    }
  }

  &__icon {
    color: #efdddc;
    font-size: 90px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0px;
    z-index: 0;

    @include mq(md) {
      top: -40px;
    }
  }

  &__figure {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: $vlg;
    border-radius: 4px;
    width: 120px;
    border-radius: 50%;
    margin-right: $margin__base * 2;
    margin: $margin--base * 2 auto;
    @include aspect-ratio(1, 1);
  }

  &__name, &__job {
    font-size: rem(16px);
    font-family: $font-family--secondary;
  }

  &__author {
    text-align: center;
  }
}

.title {
  &--h2 {
    font-size: rem(40px);
    font-family: $font-family--secondary;
    line-height: 1.2;

    @include mq(md) {
      font-size: rem(60px);
    }
  }

  &--h3 {
    font-family: $font-family--secondary;
    font-size: rem(24px);
    line-height: 1.36;

    @include mq(sm) {
      font-size: rem(30px);
    }
  }

  &--back {
    color: $color--tertiary;
  }

  &--white {
    color: $white;
  }
}

.button {
  font-family: $font-family--secondary;
  border-radius: 50px;
  font-size: rem(16px);
  padding: $padding__base $padding__base * 2;
  transition: all $transition__duration;
  display: inline-block;

  &__black {
    background-color: $color--tertiary;
    color: $white;

    &:focus,
    &:hover {
      background-color: shade($color--tertiary);
    }
  }

  &__yellow {
    background-color: $color--primary;
    color: $color--tertiary;

    &:focus,
    &:hover {
      background-color: shade($color--primary);
    }

    &:disabled {
      background-color: $vlg;
      color: $mg;
      border: 1px solid transparent;
      cursor: default;
    }
  }

  &__border {
    background-color: transparent;
    border: 1px solid $color--secondary;
    color: $color--secondary;
    padding: calc($padding__base / 2) $padding__base * 2;

    &:focus,
    &:hover {
      color: shade($color--secondary);
      border-color: shade($color--secondary);
    }
  }
}

.project-card {
  & :deep() .progressbar {

    &::-moz-progress-bar {
      background-color: $color--secondary;
    }

    &::-webkit-progress-value {
      background-color: $color--secondary;
    }
  }

  & :deep() .goal__current-amount {
    color: $color--secondary;
  }
}
</style>
