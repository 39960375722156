import Rows from '@/classes/templates/Rows'
import configurations from '@/configurations/templates'

export default class Configuration {
  constructor ({ name = null, rows } = {}) {
    this.name = name
    this.rows = new Rows(rows)
  }

  get reference () {
    return configurations.find(({ name }) => name === this.name)
  }

  get path () {
    return this.reference?.path
  }
}