import { ref, computed, toValue } from 'vue'

export function useTimeout ({ delay = 1000 }) {
  const timestamp = ref(null)

  const isStarted = computed(() => !!timestamp.value)

  const isTimeout = () => isStarted.value ? Date.now() - timestamp.value > toValue(delay) : false
  const startTimeout = () => timestamp.value = Date.now()

  return {
    isStarted,
    isTimeout,
    startTimeout
  }
}