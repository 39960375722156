import File from '@/classes/files/File'

export default class extends File {
  constructor (file, fileName, options) {
    super(file, fileName, options)
  }

  getSize (callback = () => ({})) {
    const image = new Image()
    image.src = this.toBlobURL()

    image.onload = () => callback({ width: image.width, height: image.height })
  }

  setSize ({ max_width, max_height }) {
    return this.is_svg ? this.setSvgSize({ max_width }) : this.setImageSize({ max_width, max_height })
  }

  setImageSize ({ max_width, max_height }) {
    return new Promise((resolve) => {
      const image = new Image()
      image.src = this.toBlobURL()

      image.onload = () => {
        const { width, height } = this.getCropSize({ width: image.width, height: image.height, max_width, max_height })

        let canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height
        image.width = width
        image.height = height

        const ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, width, height)

        canvas.toBlob(blob => resolve(new this.constructor(blob, `${this.filename}.${this.extension}`, { type: this.type })), this.type, 0.8)
      }
    })
  }

  setSvgSize ({ max_width }) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      const parser = new DOMParser()

      reader.readAsText(this, 'UTF-8')

      reader.onload = e => {
        const svg = parser.parseFromString(e.target.result, 'image/svg+xml').querySelector('svg')
        const viewBow = svg.getAttribute('viewBox')

        if (viewBow) {
          let [, , width, height] = viewBow.split(' ')

          svg.setAttribute('width', max_width)
          svg.setAttribute('height', (height / width) * max_width)
        }

        const blob = new Blob([svg.outerHTML], { type: 'image/svg+xml' })

        resolve(new this.constructor(blob, `${this.filename}.${this.extension}`, { type: this.type }))
      }
    })
  }

  setType (type = 'image/png') {
    return new Promise(resolve => {
      if (!this.type === type) resolve(this)

      const image = new Image()
      image.src = this.toBlobURL()

      image.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        canvas.width = image.width
        canvas.height = image.height

        ctx.drawImage(image, 0, 0, image.width, image.height)

        canvas.toBlob(blob => resolve(new this.constructor(blob, `${this.filename}.${this.getExtensionFromType(type)}`, { type })), type, 0.8)
      }
    })
  }

  getCropSize ({ width, height, max_width = 1600, max_height = 900 }) {
    if (width > height) {
      if (width > max_width) {
        height *= max_width / width
        width = max_width
      }
    } else if (height > max_height) {
      width *= max_height / height
      height = max_height
    }

    return { width: Math.round(width), height: Math.round(height) }
  }

  getExtensionFromType (type) {
    const types = { 'image/png': 'png', 'image/jpeg': 'jpg', 'image/svg+xml': 'svg' }

    return types[type] || null
  }

  toPNG (callback = () => ({})) {
    return this.setType('image/png').then(image => callback(image))
  }
}