import { Plugin, PluginKey } from 'prosemirror-state'
import { get as _get } from 'lodash'

export default ({ handler }) => {
  return new Plugin({
    key: new PluginKey('handleDropImage'),
    props: {
      handleDrop: (view, event) => {
        const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY })
        const files = _get(event, 'dataTransfer.files', [])

        if (!files.length) return

        event.preventDefault()

        handler({ files: Array.from(files), pos: coordinates.pos })
      }
    }
  })
}