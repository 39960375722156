<template>
  <div class="home">
    <section
      class="banner"
      v-if="isVisible('banner')"
    >
      <div
        class="banner__img"
        :style="`background-image: url(${ $i18n.translate(banner.background) })`"
        v-if="$i18n.translate(banner.background) || $i18n.translate(banner.video)"
      >
        <video
          class="banner__video"
          width="1920"
          height="372"
          autoplay
          muted
          loop
          playsinline
          v-if="$i18n.translate(banner.video)"
        >
          <source
            :src="$i18n.translate(banner.video)"
            type="video/mp4"
          >
        </video>

        <div class="banner__container">
          <h1 class="banner__title">
            {{ $i18n.translate(banner.title) }}
          </h1>
        </div>
      </div>

      <fa-icon
        class="banner__arrow"
        :icon="['fal', 'arrow-down']"
      />
    </section>

    <section
      class="sterren"
      v-if="isVisible('quotes') || isVisible('dots')"
    >
      <blockquote
        class="quote quote--left"
        v-if="isVisible('quotes')"
      >
        <fa-icon
          class="quote__icon"
          :icon="['fal', 'quote-left']"
        />

        <img
          class="quote__img"
          :src="$i18n.translate(quotes.left.image)"
          :alt="`Picture of ${ $i18n.translate(quotes.left.name) }`"
        >

        <p
          class="quote__text editor editor__content"
          v-if="$i18n.translate(quotes.left.text)"
          v-html="$i18n.translate(quotes.left.text)"
        />

        <p class="quote__name">
          {{ $i18n.translate(quotes.left.name) }}
        </p>
      </blockquote>

      <div class="d-flex justify-content-center">
        <stars-kikirpa
          class="sterren__svg"
          :section="dots"
          v-if="isVisible('dots')"
        />
      </div>

      <blockquote
        class="quote quote--right"
        v-if="isVisible('quotes')"
      >
        <fa-icon
          class="quote__icon"
          :icon="['fal', 'quote-right']"
        />

        <img
          class="quote__img"
          :src="$i18n.translate(quotes.right.image)"
          :alt="`Picture of ${ $i18n.translate(quotes.right.name) }`"
        >

        <p
          class="quote__text editor editor__content"
          v-if="$i18n.translate(quotes.right.text)"
          v-html="$i18n.translate(quotes.right.text)"
        />

        <p class="quote__name">
          {{ $i18n.translate(quotes.right.name) }}
        </p>
      </blockquote>
    </section>

    <section
      class="capsule"
      v-if="isVisible('capsule')"
    >
      <div class="container">
        <div class="capsule__grid">
          <figure
            class="capsule__slider"
            ref="slider"
          >
            <template v-if="slides.length > 0">
              <div
                class="capsule__slides"
                :style="{ width: `${ clonedSlides.length * slider.width }px`, left: `${ -slider.width * ((clonedSlides.length - slides.length) / 2) }px`, transform: `translateX(${ -slider.index * slider.width }px)` }"
              >
                <div
                  class="capsule__slide"
                  v-for="(slide, index) in clonedSlides"
                  :style="{ backgroundImage: `url(${ $i18n.translate(slide.background) })`, width: `${slider.width}px` }"
                  :key="`modal_slide_${index}`"
                >
                  <h2
                    class="title__section slide__title"
                    v-if="$i18n.translate(slide.title)"
                  >
                    {{ $i18n.translate(slide.title) }}
                  </h2>

                  <div
                    class="editor editor__content slide__text"
                    v-html="$i18n.translate(slide.text)"
                    v-if="$i18n.translate(slide.text)"
                  />

                  <p
                    class="slide__amount"
                    v-if="$i18n.translate(slide.amount)"
                  >
                    {{ $i18n.translate(slide.amount) }}
                  </p>
                </div>
              </div>

              <nav
                class="capsule__nav"
                v-if="slides.length > 1"
              >
                <button
                  class="capsule--prev translate__x-3"
                  @click="goToPrev()"
                  aria-label="Go to prev slide"
                >
                  <fa-icon
                    class="element"
                    :icon="['fas', 'chevron-left']"
                    aria-hidden="true"
                  />
                </button>

                <button
                  class="capsule--next translate__x3"
                  @click="goToNext()"
                  aria-label="Go to next slide"
                >
                  <fa-icon
                    class="element"
                    :icon="['fas', 'chevron-right']"
                    aria-hidden="true"
                  />
                </button>
              </nav>
            </template>

            <fa-icon
              :icon="['far', 'image']"
              v-else
            />
          </figure>

          <article class="capsule__content">
            <h2 class="title__section">
              {{ $i18n.translate(capsule.title) }}
            </h2>

            <div
              class="editor editor__content capsule__text"
              v-html="$i18n.translate(capsule.text)"
            />

            <button
              v-if="capsule.cta && capsule.cta.show"
              @click.prevent="setRouteNavigation(capsule.cta)"
              class="btn translate__x3"
            >
              {{ $i18n.translate(capsule.cta.text) }}

              <fa-icon
                class="btn__icon element element"
                :icon="['fas', 'chevron-right']"
              />
            </button>
          </article>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import StarsKikirpa from '&/modules/pages/kikirpa/StarsKikirpa'
import PageRouteNavigation from '%/PageRouteNavigation'
import { getImagePath } from '@/utils/utils'
import useSection from '@/composables/modules/pages/useSection'
import { get as _get } from 'lodash'

export default {
  name: 'CustomHomepageKikirpa',
  components: {
    StarsKikirpa
  },
  setup: props => {
    const { getSection, getSectionAttributes, isVisible } = useSection(props.page)

    return { getImagePath, getSection, getSectionAttributes, isVisible }
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      slider: {
        index: 0,
        width: 625,
        is_animating: false
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.$nextTick(() => this.slider.width = this.$refs.slider.clientWidth)
  },
  mixins: [
    PageRouteNavigation
  ],
  computed: {
    attributes () {
      return _get(this.section, 'attributes', {})
    },
    banner () {
      return this.getSectionAttributes('banner')
    },
    dots () {
      return this.getSectionAttributes('dots')
    },
    quotes () {
      return this.getSectionAttributes('quotes')
    },
    capsule () {
      return this.getSectionAttributes('capsule')
    },
    slides () {
      return _get(this.capsule, 'slides', [])
    },
    clonedSlides () {
      const slides = [...this.slides]

      slides.unshift(this.slides[this.slides.length - 1])
      slides.push(this.slides[0])

      return slides
    }
  },
  methods: {
    ...mapActions({
      fetchClientStatistics: 'client/fetchClientStatistics'
    }),
    handleResize () {
      if (this.$refs.slider && this.slides.length) this.slider.width = this.$refs.slider.clientWidth
    },
    goToPrev () {
      if (this.slider.is_animating) return

      this.slider.is_animating = true

      if (this.slider.index <= 0) {
        this.slider.index = this.slides.length

        setTimeout(() => {
          this.slider.is_animating = false
          this.goToPrev()
        })
      } else {
        this.slider.index--

        setTimeout(() => this.slider.is_animating = false, 300)
      }
    },
    goToNext () {
      if (this.slider.is_animating) return

      this.slider.is_animating = true

      if (this.slider.index >= this.slides.length - 1) {
        this.slider.index = -1

        setTimeout(() => {
          this.slider.is_animating = false
          this.goToNext()
        })
      } else {
        this.slider.index++

        setTimeout(() => this.slider.is_animating = false, 300)
      }
    }
  },
  unmounted () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style lang="scss" scoped>
.title {
  &__section {
    font-size: rem(36px);
    margin-bottom: 48px;
    text-align: center;
    font-family: $font-family__secondary;
    color: #B58C4B;

    @include mq(sm) {
      font-size: rem(48px);
      margin-bottom: 48px;
    }
  }
}

.banner {
  &__img {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 250px;

    @include mq(sm) {
      height: 372px;
    }

    @include mq(md) {
      @include aspect-ratio(1440, 372);
      height: auto;
    }
  }

  &__arrow {
    font-size: 38px;
    color: #979797;
    display: block;
    margin: auto;
    margin-top: 70px;

    @include mq(sm) {
      margin-top: 140px;
    }
  }

  &__video {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: 100%;
  }

  &__container {
    position: absolute;
    z-index: 1;
    top: 0px;
    bottom: auto;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 24px;

    @include mq(sm) {
      top: auto;
      bottom: 0px;
    }

    @media (min-width: 1500px) {
      width: 1200px;
      bottom: -96px;
    }
  }

  &__title {
    font-size: 48px;
    font-family: $font-family__secondary;
    display: block;
    color: #DCDCDC;
    line-height: 1.2;

    @include mq(sm) {
      font-size: 80px;
    }

    @media (min-width: 1500px) {
      font-size: 132px;
    }
  }
}

.sterren {
  padding: 60px 0 60px 0;
  display: block;

  @media (min-width: 850px) {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 1260px) {
    padding: 60px 0 120px 0;
    align-items: flex-start;
    flex-direction: row;
  }

  &__svg {
    position: relative;
    overflow: auto;
    max-height: 70vh;

    @include mq(md) {
      max-height: inherit;
    }

    @media (min-width: 850px) {
      width: 500px;
    }
  }
}

.quote {
  position: relative;
  max-width: 280px;
  margin: auto;
  padding: 12px;

  &__img {
    border-radius: 50%;
    margin-bottom: 24px;
    display: inline-block;
  }

  &__text {
    font-family: $font-family--secondary;
    color: $color--primary;
    margin-bottom: 24px;
  }

  &__name {
    text-align: right;
    font-size: rem(18px);
    color: #979797;
  }

  &__icon {
    position: absolute;
    font-size: 120px;
    top: 30%;
    color: #E3E3E3;
  }

  &--right {
    @media (min-width: 1260px) {
      align-self: flex-end;
      margin-bottom: 86px;
      text-align: right;
    }

    .quote__icon {
      left: calc(100% - 24px);
    }
  }

  &--left {
    @media (min-width: 1260px) {
      margin-top: 86px;
    }

    .quote__icon {
      right: calc(100% - 24px);
    }
  }
}

.capsule {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: $white;

  @include mq(md) {
    padding-top: 120px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 48px;

    @include mq(lg) {
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
    }
  }

  &__slider {
    background: $color--primary;
    position: relative;
    overflow: hidden;
    @include aspect-ratio(625, 1000);

    @include mq(sm) {
      @include aspect-ratio(625, 579);
    }
  }

  &__slides {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: .3s ease-in-out;
  }

  &__slide {
    position: relative;
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 34px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include mq(sm) {
      padding: 54px;
    }
  }

  &__nav {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    font-size: rem(18px);
    color: $white;
    line-height: 1;
    z-index: 10;

    svg {
      transition: .2s all;
    }
  }

  &--prev, &--next {
    background: #131415;
    padding: 10px 26px;
  }

  &--prev {
    margin-right: 2px;
  }

  &__content {
    padding: 54px;

    .title__section {
      text-align: left;
      margin-bottom: 24px;
    }
  }

  &__text {
    font-size: rem(16px);
    font-family: $font-family--secondary;
    margin-bottom: 48px;
  }
}

.slide {
  &__title {
    color: #131415;
    margin-bottom: 24px;
  }

  &__text {
    font-size: rem(16px);
    text-align: center;
    margin-bottom: 30px;

    @include mq(sm) {
      font-size: rem(20px);
    }
  }

  &__amount {
    font-size: rem(36px);
    color: $color--primary;
    font-family: $font-family--secondary;
    text-align: center;

    @include mq(sm) {
      font-size: rem(48px);
    }
  }
}

.btn {
  display: flex;
  align-items: center;
  background: $color--primary;
  padding: 12px 18px;
  line-height: 1;
  font-size: rem(18px);
  color: $white;
  transition: all .3s;
  border-radius: 0;

  &:hover {
    background-color: shade($color--primary, 5%);
  }

  &__icon {
    font-size: 12px;
    margin-left: 6px;
  }

  &__container {
    justify-content: center;
    margin-top: 48px;
  }
}
</style>
