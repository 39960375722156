<template>
  <div
    class="glider-contain"
    ref="glider_contain"
  >
    <div
      ref="glider"
      class="glider"
    >
      <slot />
    </div>

    <button
      type="button"
      :aria-label="$i18n.t('literal.previous')"
      class="glider-prev"
    >
      <slot name="prev">
        <fa-icon :icon="['fal', 'chevron-left']" />
      </slot>
    </button>

    <button
      type="button"
      :aria-label="$i18n.t('literal.next')"
      class="glider-next"
    >
      <slot name="next">
        <fa-icon :icon="['fal', 'chevron-right']" />
      </slot>
    </button>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Glider from 'glider-js';
import { get as _get } from 'lodash'

export default defineComponent({
  name: 'AppSlides',
  props: {
    options: {
      type: Object
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    duration: {
      type: Number,
      default: 5000
    }
  },
  data () {
    return {
      slides: null,
      is_hovering: false,
      anim_id: 0,
      timeout: 0,
      default_options: {
        slidesToScroll: 1,
        slidesToShow: 1
      },
      events: {
        over: ['mouseover', 'touchstart'],
        out: ['mouseout', 'ontouchend', 'ontouchcancel']
      }
    }
  },
  mounted () {
    this.slides = new Glider(this.$refs.glider, { arrows: this.arrows, ...this.default_options, ...this.options })

    if (this.autoplay) {
      this.$refs.glider.addEventListener('glider-animated', this.gliderAnimated)

      this.events.over.forEach(event => this.$refs.glider_contain.addEventListener(event, this.mouseOver))
      this.events.out.forEach(event => this.$refs.glider_contain.addEventListener(event, this.mouseOut))

      this.autoSlide()
    }
  },
  computed: {
    arrows () {
      return {
        prev: this.$refs.glider.parentNode.querySelector('.glider-prev'),
        next: this.$refs.glider.parentNode.querySelector('.glider-next')
      }
    },
    slidesToShow () {
      return _get(this.slides, 'opt.slidesToShow')
    },
    slidesCount () {
      return this.slides.track.childElementCount
    },
    isAnimating () {
      return this.anim_id !== this.slides.animate_id
    }
  },
  methods: {
    autoSlide () {
      this.timeout = setTimeout(() => {
        const slide = (this.slides.slide + 1) % this.slides.slides.length

        this.slides.scrollItem(slide > (this.slidesCount - this.slidesToShow) ? 0 : slide);
      }, this.duration)
    },
    gliderAnimated () {
      this.anim_id = this.slides.animate_id
      if (!this.is_hovering) this.autoSlide()
    },
    mouseOver () {
      this.is_hovering = true

      clearTimeout(this.timeout)
    },
    mouseOut () {
      this.is_hovering = false

      if (!this.isAnimating) this.autoSlide()
    }
  },
  beforeUnmount () {
    clearTimeout(this.timeout)

    this.$refs.glider.removeEventListener('glider-animated', this.gliderAnimated)

    this.events.over.forEach(event => this.$refs.glider_contain.removeEventListener(event, this.mouseOver))
    this.events.out.forEach(event => this.$refs.glider_contain.removeEventListener(event, this.mouseOut))

    this.slides.destroy()
  }
})
</script>

<style lang="scss" scoped>
.glider {
  margin: 0 auto;
  position: relative;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  transform: translateZ(0);

  &.draggable {
    user-select: none;
    cursor: -webkit-grab;
    cursor: grab;
  }

  &-contain {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }

  & :deep() .glider-track {
    transform: translateZ(0);
    width: 100%;
    min-width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  & :deep() .glider-slide {
    user-select: none;
    flex: 0 0 auto;

    img {
      max-width: 75%;
      display: block;
      margin: auto;

      @include mq(md) {
        max-width: 100%;
      }
    }
  }

  &::-webkit-scrollbar {
    opacity: 0;
    height: 0;
  }

  &-prev, &-next {
    user-select: none;
    position: absolute;
    outline: none;
    padding: $padding__base;
    z-index: 2;
    font-size: 24px;
    left: 0;
    top: 50%;
    margin-top: -18px;
    opacity: 1;
    line-height: 1;
    transition: opacity .5s cubic-bezier(.17,.67,.83,.67), color .5s cubic-bezier(.17,.67,.83,.67);

    &:hover, &:focus {
      color: #a89cc8;
    }

    &.disabled {
      display: none;
    }

    @include mq(md) {
      left: -35px;
    }
  }

  &-next {
    right: 0;
    left: auto;

    @include mq(md) {
      right: -35px;
    }
  }
}
</style>
