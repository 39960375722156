import { computed, toValue } from 'vue'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { useProcedureStore } from '@/stores/ProcedureStore'
import { useNotificationStore } from '@/stores/NotificationStore'
import { useProcedure, useProcedureStep, useProcedureNavigation } from '@/composables/modules/procedures'
import { useAccountVerification } from '@/composables/app/useAccountVerification'
import { storeToRefs } from 'pinia'
import { get as _get } from 'lodash'

export function useProcedureRestrictions ({ procedure, procedure_execution }) {
  const { getProcedureAuthorization, fetchProcedureAuthorization } = useProcedureStore()
  const { postModalNotification } = useNotificationStore()
  const { slug } = useProcedure({ procedure })
  const { isLastStep } = useProcedureStep({ procedure })
  const { nextRedirection } = useProcedureNavigation({ procedure, procedure_execution })
  const { isAuthenticated } = storeToRefs(useAuthenticationStore())
  const { isVerified } = useAccountVerification()

  const { error } = getProcedureAuthorization({ id: slug })

  const isAuthenticationRequired = computed(() => toValue(procedure)?.is_authentication_required && !isAuthenticated.value)
  const isAuthenticationPermitted = computed(() => toValue(procedure)?.is_authentication_required && isAuthenticated.value)
  const isAccountVerificationRequired = computed(() => isAuthenticationPermitted.value && !isVerified.value)
  const isOneTimeCompletion = computed(() => toValue(procedure)?.is_one_time_completion)
  const isAllowedToFetchRestrictions = computed(() => isAuthenticationPermitted.value && isOneTimeCompletion.value && !isLastStep.value)

  const isExecutionLimitReached = computed(() => _get(error.value, 'data.errors', []).includes('user_already_completed_form'))
  const restrictionError = computed(() => {
    if (isExecutionLimitReached.value) return { title: 'errors.user_already_completed_form_title', text: 'errors.user_already_completed_form_text' }

    return { title: 'errors.user_unauthorized_to_proceed_title', text: 'errors.user_unauthorized_to_proceed_text' }
  })

  const fetchProcedureRestrictions = ({ show_modal = true } = {}) => {
    if (isAllowedToFetchRestrictions.value) fetchProcedureAuthorization({ id: slug }).catch(() => {
      if (show_modal) postModalNotification({ ...restrictionError.value, close: nextRedirection })
    })
  }

  return {
    isAuthenticationRequired,
    isAccountVerificationRequired,
    isExecutionLimitReached,
    fetchProcedureRestrictions
  }
}