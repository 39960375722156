<template>
  <div>
    <section class="banner">
      <article class="banner__column">
        <div class="banner__row bg__color--ter">
          <div
            class="banner__img"
            :style="{ backgroundImage: `url(${ $i18n.translate(section.attributes.header.tertiary.image)})` }"
          />

          <div class="banner__content">
            <h2 class="title__h3">
              {{ $i18n.translate(section.attributes.header.tertiary.title) }}
            </h2>

            <p
              class="editor editor__content"
              v-html="$i18n.translate(section.attributes.header.tertiary.text)"
            />
          </div>
        </div>

        <div class="banner__row-reverse bg__color--prim">
          <div
            class="banner__img"
            :style="{ backgroundImage: `url(${ $i18n.translate(section.attributes.header.primary.image)})` }"
          />

          <div class="banner__content">
            <h2 class="title__h3">
              {{ $i18n.translate(section.attributes.header.primary.title) }}
            </h2>

            <p
              class="editor editor__content"
              v-html="$i18n.translate(section.attributes.header.primary.text)"
            />
          </div>
        </div>
      </article>

      <article class="banner__column bg__color--sec">
        <div
          class="banner__img"
          :style="{ backgroundImage: `url(${ $i18n.translate(section.attributes.header.secondary.image)})` }"
        />

        <div class="banner__content">
          <h2 class="title__h3">
            {{ $i18n.translate(section.attributes.header.secondary.title) }}
          </h2>

          <p
            class="editor editor__content"
            v-html="$i18n.translate(section.attributes.header.secondary.text)"
          />

          <div class="banner__buttons">
            <app-link
              :link="section.attributes.header.secondary.cta1"
              class="btn btn__grey margin__r--24"
            />

            <app-link
              :link="section.attributes.header.secondary.cta2"
              class="btn btn__green"
            />
          </div>
        </div>
      </article>
    </section>

    <section
      class="campaigns"
      v-if="section.attributes.header.cta.show || campaigns.length"
    >
      <div class="container">
        <div
          class="banner__button"
          v-if="section.attributes.header.cta.show"
        >
          <app-link
            :link="section.attributes.header.cta"
            class="btn__border"
          />
        </div>

        <div
          class="campaigns__container"
          v-if="campaigns.length"
        >
          <h2 class="title__h2">
            {{ $i18n.translate(section.attributes.campaigns.title) }}
          </h2>

          <article
            v-for="campaign in campaigns"
            class="campaign"
            :key="'campaign_' + campaign.id"
          >
            <div
              class="campaign__cover"
              ref="cover"
              :style="{ backgroundImage: `url(${ campaign.image.url }), url(${ getImagePath('placeholders/project-card-cover.jpg') })` }"
            />

            <div class="campaign__infos">
              <h3 class="campaign__title title__h3">
                {{ campaign.name[$i18n.locale] }}
              </h3>

              <p
                class="infos__description editor__content"
                v-if="campaign.description"
                v-html="$i18n.translate(campaign.description)"
              />

              <div
                class="campaign__cta-container"
                v-if="getCreateProjectProcedure(campaign)"
              >
                <app-link
                  :link="campaign.cta"
                  class="btn__secondary margin__r--24"
                  :disabled="isCtaDisabled(campaign)"
                />

                <p
                  class="campaign__cta-start"
                  v-if="!isCampaignStarted(campaign)"
                >
                  <fa-icon :icon="['fal', 'clock']" />

                  {{ $i18n.t('literal.starts_in') }}

                  <app-date
                    :timestamp="getCreateProjectProcedure(campaign).start_date"
                    filter="formatDistanceToNow"
                  />
                </p>

                <p
                  class="campaign__cta-ended"
                  v-if="isCampaignEnded(campaign)"
                >
                  {{ $i18n.t('literal.registrations_are_closed') }}
                </p>
              </div>
            </div>
          </article>
        </div>
      </div>
    </section>

    <section
      class="projects"
      v-if="projects.length > 0"
    >
      <div class="container">
        <h2
          class="title__h2 margin__b--48"
          v-if="section.attributes.projects.title && $i18n.translate(section.attributes.projects.title)"
        >
          {{ $i18n.translate(section.attributes.projects.title) }}
        </h2>


        <div class="projects__list row">
          <template
            v-for="project in projects"
            :key="project.id"
          >
            <section class="col-12 col-sm-6 col-md-3">
              <project-card
                class="project-card"
                :project="project"
              />
            </section>
          </template>
        </div>

        <div class="projects__footer">
          <app-link
            :link="section.attributes.projects.cta1"
            class="btn btn__secondary"
          />

          <app-link
            :link="section.attributes.projects.cta2"
            class="btn btn__border"
          />
        </div>
      </div>
    </section>

    <section class="quotes">
      <div class="container">
        <h2 class="title__h2 margin__b--48">
          {{ $i18n.translate(section.attributes.quote.title) }}
        </h2>

        <app-slides
          class="quotes__slides"
          v-if="hasQuotes"
        >
          <article
            class="glider__slide"
            v-for="(quote, index) in section.attributes.quote.items"
            :key="'quote_' + index"
          >
            <p
              class="editor editor__content"
              v-html="$i18n.translate(quote.text)"
            />

            <p class="quotes__author">
              {{ $i18n.translate(quote.author) }}
            </p>
          </article>

          <template #prev>
            <fa-icon
              class="quotes__arrow"
              size="lg"
              :icon="['fal', 'arrow-circle-left']"
            />
          </template>

          <template #next>
            <fa-icon
              class="quotes__arrow"
              size="lg"
              :icon="['fal', 'arrow-circle-right']"
            />
          </template>
        </app-slides>
      </div>
    </section>

    <section class="about">
      <div class="container">
        <article class="campaign">
          <div
            class="campaign__cover about__cover"
            ref="cover"
            :style="{ backgroundImage: `url(${ $i18n.translate(section.attributes.about.image) }), url(${ getImagePath('placeholders/project-card-cover.jpg') })` }"
          />

          <div class="campaign__infos about__infos">
            <h3 class="campaign__title title__h3">
              {{ $i18n.translate(section.attributes.about.title) }}
            </h3>

            <p
              class="infos__description editor__content"
              v-html="$i18n.translate(section.attributes.about.text)"
            />
          </div>
        </article>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
import { mapActions } from 'vuex'
import { get as _get } from 'lodash'
import { isPast } from 'date-fns'
import AppDate from '&/atoms/AppDate'
import ProjectCard from '&/organisms/ProjectCard'
import AppSlides from '&/organisms/AppSlides'
import { getImagePath } from '@/utils/utils'

export default defineComponent({
  name: 'CustomHomepageRodekruisvlaanderen',
  components: {
    AppDate,
    ProjectCard,
    AppSlides,
    AppLink: defineAsyncComponent(() => import('&/atoms/AppLink'))
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      campaigns: [],
      projects: [],
      loader: {
        active: false
      }
    }
  },
  setup: () => ({ getImagePath }),
  created () {
    const campaign_id = _get(this.section, 'attributes.campaigns.items.ids', [])

    if (campaign_id.length) {
      this.loader.active = true
      this.fetchCampaigns({ query: { campaign_id } })
        .then(data => {
          this.campaigns = data.items
        })
        .finally(() => {
          this.loader.active = false
        })
    }

    this.fetchProjects({
      query: _get(this.projects, 'query', {
        'sort_by_field[field]': 'current_amount',
        'sort_by_field[direction]': 'desc',
        'per_page': 4
      }), removeStoredItems: true
    })
      .then(data => this.projects = Object.values(data.items))
  },
  computed: {
    hasQuotes () {
      const quotes = _get(this.section, 'attributes.quote.items', [])
      return quotes.length
    }
  },
  methods: {
    ...mapActions({
      fetchCampaigns: 'campaigns/fetchCampaigns',
      fetchProjects: 'projects/fetchProjects'
    }),
    getCreateProjectProcedure (campaign) {
      return Array.isArray(campaign.procedures) ? campaign.procedures.find(procedure => procedure.category === 'create-project') : false
    },
    getCreateProjectProcedureRoute (campaign) {
      return this.getCreateProjectProcedure(campaign) ? { name: 'ClientProcedure', params: { procedureSlug: this.getCreateProjectProcedure(campaign).slug } } : false
    },
    showCta (campaign) {
      return _get(campaign, 'cta.text[' + this.$i18n.locale + ']') && _get(campaign, 'cta.show')
    },
    isCampaignStarted (campaign) {
      return isPast(new Date(this.getCreateProjectProcedure(campaign).start_date))
    },
    isCampaignEnded (campaign) {
      return this.getCreateProjectProcedure(campaign).end_date ? isPast(new Date(this.getCreateProjectProcedure(campaign).end_date)) : false
    },
    isCtaDisabled (campaign) {
      if (!this.getCreateProjectProcedure(campaign)) return true
      if (!campaign.cta.url[this.$i18n.locale] && !campaign.cta.target_name && !campaign.cta.target_url) return true
      if (!this.isCampaignStarted(campaign) || this.isCampaignEnded(campaign)) return true
      return false
    }
  }
})
</script>

<style lang="scss" scoped>
$grey: #E3E3E3;

.title {
  &__h2 {
    font-size: rem(36px);
    font-weight: bold;
    text-align: center;

    @include mq(sm) {
      font-size: rem(48px);
    }
  }

  &__h3 {
    font-size: rem(22px);
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: $margin__base * 1.5;

    @include mq(sm) {
      font-size: rem(28px);
    }

    &:after {
      content: '';
      display: block;
      width: 45px;
      height: 2px;
      background-color: $black;
      margin-top: calc($margin__base / 2);
    }
  }
}

.banner {
  display: grid;
  grid-template-columns: repeat(1, 100%);

  @include mq(lg) {
    grid-template-columns: repeat(2, 50%);
  }

  &__column {
    display: grid;
  }

  &__row {
    display: block;

    @include mq(sm) {
      display: grid;
      grid-template-columns: 40% 60%;
    }
  }

  &__row-reverse {
    display: block;

    @include mq(sm) {
      display: grid;
      grid-template-columns: 60% 40%;

      .banner__img {
        order: 2;
        margin-right: -1px;
      }
    }
  }

  &__img {
    display: block;
    background-size: cover;
    background-position: center;
    min-height: 250px;
  }

  &__content {
    color: $white;
    padding: $padding__base * 5 $padding__base * 3;
    display: block;
    margin: auto;
  }

  &__buttons {
    margin-top: $margin__base * 2;

    .btn {
      margin: $margin__base 0;
      display: block;

      @include mq(sm) {
        margin: 0 $margin__base * 2 0 0;
      }
    }

    @include mq(sm) {
      display: flex;
      justify-content: flex-start;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
  }
}

.campaigns {
  background-color: $grey;
  padding: 50px 12px;

  @include mq(md) {
    padding: 50px;
  }

  &__container {
    margin-top: 40px;

    @include mq(md) {
      margin-top: 100px;
    }
  }
}

.campaign {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: $margin__base * 2 0;

  @include mq(md) {
    flex-wrap: nowrap;
    margin: $margin__base * 4 0;
  }

  &__cover {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include aspect-ratio(1, 1);
    width: 100%;

    @include mq(md) {
      width: 40%;
      margin-right: -50px;
    }
  }

  &__infos {
    position: relative;
    color: $black;
    background-color: $white;
    padding: $padding__base * 4 $padding__base * 2;
    width: 100%;

    @include mq(md) {
      width: 60%;
      margin-left: -50px;
      border-radius: 9px;
    }
  }

  &__title:after {
    background-color: $color--primary;
  }

  &__cta {
    &-container {
      margin-top: $margin__base * 2;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    &-start, &-ended {
      color: $lg;
      font-size: rem(14px);
      padding: $padding__base 0;
    }
  }
}

.projects {
  padding: $margin__base * 4 0;

  &__title {
    color: $color--primary;
    font-weight: 700;
    font-size: rem(32px);
    text-align: center;

    @include mq(sm) {
      font-size: rem(38px);
    }
  }

  &__footer {
    margin-top: $margin__base * 2;

    @include mq(sm) {
      margin-top: $margin__base * 3;
    }

    .btn {
      display: block;
      margin-right: 0;
      margin-bottom: $margin__base;

      @include mq(sm) {
        margin-right: $margin__base * 2;
        margin-bottom: 0;
      }
    }

    @include mq(sm) {
      display: flex;
      justify-content: center;
    }
  }
}

.quotes {
  padding: $margin__base * 2 0;

  @include mq(sm) {
    padding: $margin__base * 4 0;
  }

  &__author {
    font-weight: bold;
    text-align: right;
    margin-top: $margin__base * 2;
  }

  &__arrow {
    color: $color--primary;
    transition: all .3s;

    &:hover {
      color: shade($color--primary, 10%, 33%);
    }
  }

  &__slides {
    background-color: $grey;
    padding: 80px 0 50px 0;
    border-radius: 9px;

    & :deep() .glider__slide {
      padding: 0 7%;
    }

    & :deep() .glider-next {
      right: 10px;

      @include mq(sm) {
        right: 30px;
      }
    }

    & :deep() .glider-prev {
      left: 10px;

      @include mq(sm) {
        left: 30px;
      }
    }
  }
}

.about {
  padding: $margin__base * 2 0;

  @include mq(sm) {
    padding: $margin__base * 4 0;
  }

  &__infos {
    background-color: $grey;
    z-index: 1;

    @include mq(md) {
      order: 1;
      margin-left: 0;
      margin-right: -50px;
    }
  }

  &__cover {
    @include mq(md) {
      order: 2;
      margin-right: 0;
      margin-left: -50px;
    }
  }
}

:deep(.btn) {
  font-size: rem(14px);
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 7px;
  padding: $padding__base $padding__base * 1.5;
  transition: all .3s;

  &.btn__grey {
    @extend .btn;
    color: #131415;
    background-color: $grey;

    &:focus, &:hover {
      background-color: darken($grey, 10%);
    }
  }

  &.btn__green {
    @extend .btn;
    color: #131415;
    background-color: #81A6AB;

    &:focus, &:hover {
      background-color: darken(#81A6AB, 10%);
    }
  }

  &.btn__border {
    @extend .btn;
    color: $color--secondary;
    border: 2px solid $color--secondary;

    &:focus, &:hover {
      background-color: tint($color--secondary, 90%, 90%);
    }
  }

  &.btn__secondary {
    @extend .btn;
    color: $white;
    background-color: $color--secondary;

    &:focus, &:hover {
      background-color: shade($color--secondary, 10%, 30%);
    }

    &:disabled,
    &[disabled="true"] {
      background-color: $vlg;
      color: $mg;
      cursor: default;

      &:hover {
        background-color: $vlg;
        color: $mg;
        cursor: default;
      }
    }
  }
}
</style>
