<template>
  <div class="input__container">
    <v-select
      class="input--border input--radius"
      :model-value="selected"
      @update:model-value="selected = $event"
      :multiple="multiple"
      :input-id="id"
      :placeholder="placeholder"
      :label="label"
      :reduce="reduce"
      :options="options"
      :clearable="clearable"
      :selectable="selectable"
      :close-on-select="closeOnSelect"
      :filter-by="filterBy"
      :disabled="disabled"
      :searchable="searchable"
      :taggable="taggable"
      :filterable="filterable"
      :create-option="createOption"
      @search="handleSearch"
      @search:blur="handleBlur"
      @open="handleOpen"
      @close="handleClose"
    >
      <template #selected-option="option">
        <slot
          name="selected-option"
          :option="option"
        />
      </template>

      <template #option="option">
        <slot
          name="option"
          :option="option"
        />
      </template>

      <template #no-options="option">
        <slot
          name="no-options"
          :option="option"
        />
      </template>

      <template #open-indicator="option">
        <slot
          name="open-indicator"
          :option="option"
        />
      </template>

      <template #spinner="option">
        <slot
          name="spinner"
          :option="option"
        />
      </template>

      <template #list-footer>
        <slot name="list-footer">
          <li
            class="vs__pagination padding--12 align--center color--vlg"
            ref="loadmore"
            v-if="paginable"
            v-show="hasNextPage"
          >
            {{ t('literal.loading_more_options') }}
          </li>
        </slot>
      </template>
    </v-select>

    <div
      class="input--errors"
      v-if="v && v.$error"
    >
      <p
        class="input--error"
        v-if="v.required && v.required.$invalid"
      >
        {{ t('errors.input_select_required') }}
      </p>

      <p
        class="input--error"
        v-if="v.sameAs && v.sameAs.$invalid"
      >
        {{ t('errors.input_select_required') }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, toRefs, nextTick, onBeforeMount } from 'vue'
import { useI18n } from '@/vendors/i18n'
import vSelect from 'vue-select'

import Deselect from './InputSelectDeselect'
import OpenIndicator from './InputSelectOpenIndicator'

import 'vue-select/dist/vue-select.css'

vSelect.props.components.default = () => ({
  Deselect: Deselect,
  OpenIndicator: OpenIndicator
})

const emit = defineEmits(['input', 'search', 'update:modelValue'])

const props = defineProps({
  multiple: { type: Boolean, default: false },
  id: { type: String },
  closeOnSelect: { type: Boolean, default: true },
  placeholder: { type: String, default: '...' },
  modelValue: { default: '' },
  disabled: { type: Boolean, default: false },
  options: { type: Array, required: true },
  clearable: { type: Boolean, default: false },
  searchable: { type: Boolean, default: false },
  taggable: { type: Boolean, default: false },
  filterable: { type: Boolean, default: true },
  paginable: { type: Boolean, default: false },
  label: String,
  v: Object,
  selectable: { type: Function, default: option => option },
  reduce: { type: Function, default: select => select.value },
  filterBy: { type: Function, default: (option, label, search) => (label || '').toLowerCase().includes(search.toLowerCase()) },
  createOption: { type: Function, default: value => ({ value, label: value }) },
  perPage: { type: Number, default: 100 }
})

const { t } = useI18n()
const { multiple, closeOnSelect, placeholder, modelValue, disabled, clearable, searchable, taggable, paginable, label, v, selectable, reduce, filterBy, createOption, perPage } = toRefs(props)

const loadmore = ref()
const limit = ref(perPage.value)
const search = ref(null)

const filtered = computed(() => search.value ? props.options.filter(option => filterBy.value(option.value, option.label, search.value)) : props.options)
const options = computed(() => paginable.value ? filtered.value.slice(0, limit.value) : props.options)
const hasNextPage = computed(() => options.value.length < filtered.value.length)

const selected = computed({
  get: () => paginable.value ? Array.isArray(modelValue.value) ? modelValue.value.map(value => getSelectedOption(value)) : getSelectedOption(modelValue.value) : modelValue.value,
  set: selected => {
    emit('update:modelValue', selected)
    emit('input', selected)
  }
})

const getSelectedOption = value => typeof value === 'object' ? value : props.options.find(option => reduce.value(option) === value)

const handleOpen = () => {
  if (hasNextPage.value) nextTick(() => observer.observe(loadmore.value))
}

const handleClose = () => observer.disconnect()

const handleSearch = (value, loading) => {
  search.value = value

  emit('search', value, loading)
}

const handleBlur = () => {
  if (v.value) v.value.$touch()
}

const handleScroll = ([{ isIntersecting, target }]) => {
  if (isIntersecting) {
    const ul = target.offsetParent
    const scrollTop = target.offsetParent.scrollTop

    limit.value += perPage.value

    nextTick(() => ul.scrollTop = scrollTop)
  }
}

const observer = new IntersectionObserver(handleScroll)

onBeforeMount(() => observer.disconnect())
</script>

<style lang="scss">
.v-select {
  font-size: rem(14px);
}

.modal__container {
  overflow: inherit !important;
}

.vs {
  &__dropdown-toggle {
    padding: 6px 3px;
    border: 0;

    & input[type=search]::placeholder {
      color: $vlg;
      font-size: rem(14px);
    }
  }

  &__dropdown-menu {
    padding: 0;
    border-top: 0;
    margin-bottom: $margin__base * 2;
    border: 1px solid rgba($dg, 0.1);
    box-shadow: none;
    overflow: auto;
    z-index: 1000;
  }

  &__pagination {
    .pagination {
      border-top: 1px solid rgba($dg, 0.1);
    }
  }

  &__dropdown-option {
    transition: all .3s;
    padding: 9px 12px;

    &--highlight {
      background-color: $vdw;
      color: $black;
    }

    &--selected {
      background-color: $color--secondary;
      color: $white;
    }
  }

  &__open-indicator {
    transform: rotate(0deg) scale(0.6);
  }

  &--open {
    .vs__open-indicator {
      transform: rotate(180deg) scale(0.6);
    }
  }

  &__actions {
    padding: 0 3px;
  }

  &__selected {
    margin: 0 $margin__base 0 0;
    border: 0;
    padding: 2px 6px;
    margin: 3px;
  }

  &__deselect {
    margin-left: calc($margin__base / 2);

    &>svg {
      fill: $black;
    }
  }

  &__search,
  &__search:focus {
    line-height: 1;
    margin: 0;
    border: 0;
  }

  &__search:focus {
    padding: 0;
  }

  &__search {
    height: 29px;
  }
}
</style>
