export default [
  {
    name: 'custom_header_belgizitvol',
    path: 'modules/pages/belgizitvol/HeaderBelgizitvol'
  },
  {
    name: 'custom_footer_belgizitvol',
    path: 'modules/pages/belgizitvol/FooterBelgizitvol'
  },
  {
    name: 'homepage_belgizitvol',
    path: 'modules/pages/belgizitvol/HomepageBelgizitvol'
  }
]
