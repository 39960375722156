export default [
  {
    name: 'custom_footer_kajaktrail',
    path: 'modules/pages/kajaktrail/FooterKajaktrail'
  },
  {
    name: 'custom_header_kajaktrail',
    path: 'modules/pages/kajaktrail/HeaderKajaktrail'
  },
  {
    name: 'custom_homepage_kajaktrail',
    path: 'modules/pages/kajaktrail/HomepageKajaktrail'
  }
]
