export default [
  {
    name: 'custom_footer_uzbrusselfoundation',
    path: 'modules/pages/uzbrusselfoundation/FooterUzbrusselfoundation'
  },
  {
    name: 'custom_header_uzbrusselfoundation',
    path: 'modules/pages/uzbrusselfoundation/HeaderUzbrusselfoundation'
  },
  {
    name: 'custom_homepage_uzbrusselfoundation',
    path: 'modules/pages/uzbrusselfoundation/HomepageUzbrusselfoundation'
  }
]
