<template>
  <div class="sterren__container">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 840 2479"
      style="enable-background:new 0 0 840 2479;"
      xml:space="preserve"
      class="sterren"
      v-if="campaignId && projects.length"
    >
      <g class="plan">
        <image
          width="840"
          height="2479"
          xlink:href="https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/leeg.jpg"
        />
      </g>

      <g class="stars">
        <image
          width="50"
          height="50"
          id="star"
          :xlink:href="isFunded(1) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 178.095 2352.0144)"
          @click="handleClickStar(1)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(2) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 331.0001 2352.0144)"
          @click="handleClickStar(2)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(3) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 395 2352.0144)"
          @click="handleClickStar(3)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(4) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 459.082 2352.0144)"
          @click="handleClickStar(4)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(5) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 582.415 2352.0144)"
          @click="handleClickStar(5)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(6) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 643.3163 2302.0144)"
          @click="handleClickStar(6)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(7) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 146.7658 2302.0144)"
          @click="handleClickStar(7)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(8) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 146.7658 2182.0146)"
          @click="handleClickStar(8)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(9) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 331.3911 2232.0703)"
          @click="handleClickStar(9)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(10) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 454.4278 2232.0703)"
          @click="handleClickStar(10)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(11) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 395 2254.8503)"
          @click="handleClickStar(11)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(12) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 331.3911 2147.0144)"
          @click="handleClickStar(12)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(13) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 454.4278 2147.0144)"
          @click="handleClickStar(13)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(14) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 395 2124.2344)"
          @click="handleClickStar(14)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(15) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 331.3911 1833.849)"
          @click="handleClickStar(15)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(16) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 454.4278 1833.849)"
          @click="handleClickStar(16)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(17) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 395 1856.629)"
          @click="handleClickStar(17)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(18) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 331.3911 1748.7931)"
          @click="handleClickStar(18)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(19) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 454.4278 1748.7931)"
          @click="handleClickStar(19)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(20) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 395 1726.0131)"
          @click="handleClickStar(20)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(21) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 331.3911 1446.2942)"
          @click="handleClickStar(21)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(22) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 454.4278 1446.2942)"
          @click="handleClickStar(22)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(23) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 395 1469.0742)"
          @click="handleClickStar(23)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(24) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 331.3911 1361.2383)"
          @click="handleClickStar(24)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(25) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 454.4278 1361.2383)"
          @click="handleClickStar(25)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(26) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 395 1338.4583)"
          @click="handleClickStar(26)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(27) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 146.7658 2074.5703)"
          @click="handleClickStar(27)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(28) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 146.7658 1904.8485)"
          @click="handleClickStar(28)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(29) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 146.7658 1792.7375)"
          @click="handleClickStar(29)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(30) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 146.7658 1677.4045)"
          @click="handleClickStar(30)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(31) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 146.7658 1517.8492)"
          @click="handleClickStar(31)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(32) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 146.7658 1405.7384)"
          @click="handleClickStar(32)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(33) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 146.7658 1290.4054)"
          @click="handleClickStar(33)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(34) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 207.6671 2022.4594)"
          @click="handleClickStar(34)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(35) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 207.6671 1960.6818)"
          @click="handleClickStar(35)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(36) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 207.6671 1627.349)"
          @click="handleClickStar(36)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(37) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 207.6671 1565.5715)"
          @click="handleClickStar(37)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(38) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 207.6671 1235.3499)"
          @click="handleClickStar(38)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(39) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 395 2022.4594)"
          @click="handleClickStar(39)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(40) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 395 1960.6818)"
          @click="handleClickStar(40)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(41) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 395 1627.349)"
          @click="handleClickStar(41)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(42) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 395 1565.5715)"
          @click="handleClickStar(42)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(43) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 395 1235.3499)"
          @click="handleClickStar(43)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(44) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 395 1173.5723)"
          @click="handleClickStar(44)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(45) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 395 1004.6838)"
          @click="handleClickStar(45)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(46) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 342.0084 1029.6837)"
          @click="handleClickStar(46)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(47) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 447.9916 1029.6837)"
          @click="handleClickStar(47)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(48) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 489.5471 1058.129)"
          @click="handleClickStar(48)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(49) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 300.4529 1058.129)"
          @click="handleClickStar(49)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(50) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 236.0034 1033.129)"
          @click="handleClickStar(50)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(51) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 553.9966 1033.129)"
          @click="handleClickStar(51)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(52) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 395 917.5728)"
          @click="handleClickStar(52)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(53) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 327.0001 855.3507)"
          @click="handleClickStar(53)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(54) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 412.9999 379.7961)"
          @click="handleClickStar(54)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(55) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 316.7603 414.4627)"
          @click="handleClickStar(55)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(56) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 278.7171 344.2406)"
          @click="handleClickStar(56)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(57) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 547.2826 344.2406)"
          @click="handleClickStar(57)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(58) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 509.2394 414.4627)"
          @click="handleClickStar(58)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(59) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 412.9999 232.2409)"
          @click="handleClickStar(59)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(60) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 324.4949 252.6853)"
          @click="handleClickStar(60)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(61) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 501.5048 252.6853)"
          @click="handleClickStar(61)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(62) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 462.9999 855.3507)"
          @click="handleClickStar(62)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(63) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 295.9817 937.1284)"
          @click="handleClickStar(63)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(64) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 494.0183 937.1284)"
          @click="handleClickStar(64)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(65) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 586.0166 946.9061)"
          @click="handleClickStar(65)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(66) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 203.9834 946.9061)"
          @click="handleClickStar(66)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(67) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 323.4446 1115.7947)"
          @click="handleClickStar(67)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(68) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 156.3976 1090.7947)"
          @click="handleClickStar(68)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(69) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 633.6024 1090.7947)"
          @click="handleClickStar(69)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(70) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 466.5554 1115.7947)"
          @click="handleClickStar(70)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(71) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 582.415 2022.4594)"
          @click="handleClickStar(71)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(72) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 582.415 1960.6818)"
          @click="handleClickStar(72)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(73) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 582.415 1627.349)"
          @click="handleClickStar(73)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(74) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 582.415 1565.5715)"
          @click="handleClickStar(74)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(75) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 582.415 1235.3499)"
          @click="handleClickStar(75)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(76) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 643.3163 2189.9033)"
          @click="handleClickStar(76)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(77) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 643.3163 2074.5703)"
          @click="handleClickStar(77)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(78) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 643.3163 1904.8485)"
          @click="handleClickStar(78)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(79) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 643.3163 1792.7375)"
          @click="handleClickStar(79)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(80) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 643.3163 1677.4045)"
          @click="handleClickStar(80)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(81) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 643.3163 1517.8492)"
          @click="handleClickStar(81)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(82) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 643.3163 1405.7384)"
          @click="handleClickStar(82)"
        />

        <image
          width="50"
          height="50"
          :xlink:href="isFunded(83) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-grey.png'"
          transform="matrix(6.123234e-17 -1 1 6.123234e-17 643.3163 1290.4054)"
          @click="handleClickStar(83)"
        />
      </g>
    </svg>

    <app-modal
      v-if="selected_project"
      @close="selected_project = null"
    >
      <template #body>
        <article class="popup">
          <div
            class="popup__cover"
            :style="{ backgroundImage: `url(${translate(popup.image)}), url(${getImagePath('placeholders/project-card-cover.jpg')})` }"
            v-if="!procedure || procedure.current_amount === 0"
          />

          <div class="popup__infos">
            <template v-if="procedure && procedure.current_amount > 0">
              <div
                class="d-flex justify-content-center padding__tb--12"
                v-if="loader"
              >
                <app-spinner />
              </div>

              <template v-if="!loader && contribution">
                <h3 class="title__section">
                  {{ contribution.customer.firstname }} {{ contribution.customer.lastname }}
                </h3>

                <div
                  class="editor editor__content popup__text"
                  v-html="contribution.informations.message"
                  v-if="contribution.informations.message"
                />
              </template>
            </template>

            <template v-else>
              <h3
                class="title__section"
                v-if="translate(popup.title)"
              >
                {{ translate(popup.title) }}
              </h3>

              <div
                class="editor editor__content popup__text"
                v-html="translate(popup.text)"
                v-if="translate(popup.text)"
              />

              <router-link
                :to="{ name: 'ClientProcedure', params: { procedureSlug: procedure.slug } }"
                class="btn translate__x3"
                v-if="procedure && isOngoing && translate(popup.cta)"
              >
                {{ translate(popup.cta) }}

                <fa-icon
                  class="btn__icon element element"
                  :icon="['fas', 'chevron-right']"
                />
              </router-link>
            </template>
          </div>
        </article>
      </template>
    </app-modal>
  </div>
</template>

<script setup>
import { ref, computed, defineAsyncComponent } from 'vue'
import { useProjectStore } from '@/stores/ProjectStore'
import { useI18n } from '@/vendors/i18n'
import { getImagePath } from '@/utils/utils'
import { get as _get } from 'lodash'
import { isPast, isFuture } from 'date-fns'

const AppSpinner = defineAsyncComponent(() => import('@/components/atoms/AppSpinner'))
const AppModal = defineAsyncComponent(() => import('&/organisms/AppModal'))

const props = defineProps({ section: { type: Object, default: () => ({}) } })

const { translate } = useI18n()
const { getProjects, getPublicProcedureExecutions } = useProjectStore()

const selected_project = ref(null)

const popup = computed(() => _get(props.section, 'popup', {}))
const campaignId = computed(() => _get(props.section.settings, 'campaign_id', []))
const slug = computed(() => _get(selected_project.value, 'slug', null))
const query = computed(() => ({ id: slug.value, 'sort_by_field[field]': 'created_at', 'sort_by_field[direction]': 'asc' }))
const procedure = computed(() => _get(selected_project.value, 'procedures', []).find(procedure => procedure.category === 'donation'))
const startDate = computed(() => procedure.value ? new Date(procedure.value.start_date) : new Date())
const endDate = computed(() => procedure.value && procedure.value.end_date ? new Date(procedure.value.end_date) : null)
const isOngoing = computed(() => isPast(startDate.value) && (!endDate.value || isFuture(endDate.value)))
const contribution = computed(() => publicProcedureExecutions.value.find(() => true))

const { projects: allProjects } = getProjects({ query: { campaign_id: campaignId.value, per_page: 85, 'sort_by_field[field]': 'created_at', 'sort_by_field[direction]': 'asc' } })
const { publicProcedureExecutions, loader } = getPublicProcedureExecutions({ id: slug, query })

const projects = computed(() => {
  const items = allProjects.value
  const item = items.pop()

  return [...items.slice(0, 37), item, ...items.slice(37)]
})

const getProject = id => _get(projects.value, id - 1)
const isFunded = id => _get(getProject(id), 'amount_collected', 0) > 0
const handleClickStar = id => selected_project.value = getProject(id)
</script>

<style lang="scss" scoped>
:deep(.modal__container) {
  width: 100%!important;
  border-radius: 0!important;

  @media (min-width: 520px) {
    width: 520px!important;
  }
}

:deep(.modal__btn--close) {
  background-color: $white;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;

  &:hover svg {
    transform: rotate(90deg);
  }

  & svg {
    color: $color--primary;
    font-size: rem(22px);
    transition: all .3s;
  }
}

.sterren {
  width: 100%;
  position: relative;

  &__container {
    overflow-x: auto;
    width: 100%;
    max-width: 500px;

    @media (min-width: 850px) {
      overflow-x: hidden;
    }
  }
}

.stars {
  image {
    cursor: pointer;
    transition: .2s linear ease-in-out;
  }
}

:deep(.btn) {
  display: flex;
  align-items: center;
  background: $color--primary;
  padding: 12px 18px;
  line-height: 1;
  font-size: rem(18px);
  color: $white;
  transition: all .3s;
  border-radius: 0;
  width: fit-content;

  &:hover {
    background-color: shade($color--primary, 5%);
  }

  .btn__icon {
    font-size: 12px;
    margin-left: 6px;
  }

  .btn__container {
    justify-content: center;
    margin-top: 48px;
  }
}

.popup {
  width: 100%;
  background-color: $white;

  &__cover {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include aspect-ratio(517, 241);
  }

  &__infos {
    position: relative;
    padding: 24px 30px;
    color: $black;

    .title__section {
      font-size: rem(24px);
      color: $color--primary;
      margin-bottom: 18px;
      text-align: left;
      line-height: 1.2;
      font-family: $font-family--secondary;
    }
  }

  &__text {
    margin-bottom: 38px;
    font-size: rem(14px);
  }

  &__chevron {
    font-size: 12px;
    margin-left: 6px;
  }

  &__link {
    display: flex;
    align-items: center;
    font-weight: bold;
  }
}
</style>