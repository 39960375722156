<template>
  <app-modal @close="emit('close')">
    <template #body>
      <section class="card__header">
        <h2 class="card__title bold">
          {{ t('literal.new_version_detected') }}
        </h2>

        <p class="margin__t--12">
          {{ t('literal.please_reload_the_page_in_order_to_use_the_new_version_released') }} 🎉
        </p>
      </section>

      <section class="card__footer card--border btn__container">
        <button
          type="button"
          class="btn btn__size--small btn__color--ter"
          @click.prevent="reloadPage()"
        >
          <fa-icon
            class="margin__r--6"
            :icon="['fal', 'rotate-right']"
          />

          {{ t('literal.reload_the_page') }}
        </button>
      </section>
    </template>
  </app-modal>
</template>

<script setup>
import { useI18n } from '@/vendors/i18n'
import AppModal from '&/organisms/AppModal'

const emit = defineEmits(['close'])
const { t } = useI18n()

const reloadPage = () => window.location.reload()
</script>

<style lang="scss" scoped>
:deep(.modal__container) {
  @media (min-width: 510px) {
    width: 510px;
  }
}

:deep(.modal__body) {
  padding: 18px;
}

.card {
  &__title {
    font-size: rem(18px);
  }

  &__footer {
    justify-content: center;
    margin-top: 18px;
    padding-bottom: 0;
  }
}
</style>