<template>
  <div class="home">
    <section
      class="banner"
      v-if="isVisible('banner')"
    >
      <div class="container banner__container">
        <div class="banner__image">
          <figure
            class="banner__figure"
            :style="{ backgroundImage: `url(${translate(banner.image)})` }"
          >
            <button
              class="banner__play"
              v-if="translate(banner.video)"
              @click.prevent="openVideoModal()"
            />

            <app-modal
              v-if="show_video_modal"
              size="xl"
              @close="closeVideoModal()"
            >
              <template #body>
                <app-video-embed
                  :src="translate(banner.video)"
                  v-if="translate(banner.video)"
                />
              </template>
            </app-modal>
          </figure>
        </div>

        <div>
          <div class="banner__wrapper">
            <h1
              class="title title__h1"
              v-if="translate(banner.title)"
            >
              {{ translate(banner.title) }}
            </h1>

            <div
              class="editor__content margin__t--24"
              v-html="translate(banner.content)"
              v-if="translate(banner.content)"
            />

            <button
              class="btn btn__size--small btn__color--sec cta margin__t--18 banner__cta"
              @click.prevent="scrollTo('#crowdfunding')"
              v-if="isVisible('crowdfunding') && translate(banner.cta)"
            >
              {{ translate(banner.cta) }}
            </button>
          </div>
        </div>
      </div>
    </section>

    <section
      class="crowdfunding"
      id="crowdfunding"
      v-if="isVisible('crowdfunding')"
    >
      <div class="container">
        <div class="hgroup">
          <h2
            class="title title__h2 hgroup__title"
            v-if="translate(crowdfunding.title.text)"
          >
            <img
              :src="translate(crowdfunding.title.icon)"
              alt=""
              class="hgroup__icon"
              aria-hidden="true"
              v-if="translate(crowdfunding.title.icon)"
            >

            {{ translate(crowdfunding.title.text) }}
          </h2>

          <div
            class="editor__content margin__t--24 hgroup__content"
            v-html="translate(crowdfunding.content)"
            v-if="translate(crowdfunding.content)"
          />
        </div>

        <div
          class="crowdfunding__projects margin__t--42"
          v-if="crowdfunding_projects.length && crowdfunding_projects_statistics.length"
        >
          <article
            class="project"
            v-for="(project, key) in crowdfunding_projects"
            :key="`crowdfunding_${project.id}`"
          >
            <figure
              class="project__figure"
              :style="{ backgroundImage: `url(${getProjectImage(project)}), url(${getImagePath('placeholders/project-card-cover.jpg')})` }"
            />

            <div class="project__content">
              <h3 class="title title__h4 project__title">
                <router-link
                  :to="{ name: 'Project', params: { projectSlug: project.slug } }"
                  class="title__link link__color--ter--sec link--naked bold project__link"
                  :aria-label="project.name"
                >
                  {{ truncate(project.name, 60) }}
                </router-link>
              </h3>

              <p class="align--right project__statistic">
                <app-money
                  :amount="crowdfunding_projects_statistics[key]?.amount_collected"
                  class="bold color--prim"
                />
              </p>

              <app-progress-bar
                class="progressbar project__progressbar"
                :value="crowdfunding_projects_statistics[key]?.amount_collected"
                :max-value="getProjectGoal(project)"
                v-if="getProjectGoal(project)"
              />

              <p
                class="align--right project__statistic"
                v-if="getProjectGoal(project)"
              >
                <app-money
                  :amount="getProjectGoal(project)"
                  class="bold color--ter"
                />
              </p>

              <span
                class="btn btn__size--small btn__color--prim cta d-inline-block project__cta"
                v-if="translate(crowdfunding.cta)"
              >
                {{ translate(crowdfunding.cta) }}
              </span>
            </div>
          </article>
        </div>
      </div>
    </section>

    <article
      class="hightlited_action"
      v-if="isVisible('hightlited_action')"
    >
      <div class="container">
        <div class="hgroup">
          <h2
            class="title title__h2 hgroup__title"
            v-if="translate(hightlitedAction.title.text)"
          >
            <img
              :src="translate(hightlitedAction.title.icon)"
              alt=""
              class="hgroup__icon"
              aria-hidden="true"
              v-if="translate(hightlitedAction.title.icon)"
            >

            {{ translate(hightlitedAction.title.text) }}
          </h2>

          <div
            class="editor__content margin__t--24 hgroup__content"
            v-html="translate(hightlitedAction.content)"
            v-if="translate(hightlitedAction.content)"
          />
        </div>

        <div
          class="hightlited_action__project hightlited_project margin__t--24"
          v-if="hightlitedProject && hightlitedProjectStatistics"
        >
          <div class="hightlited_project__content">
            <div class="hightlited_project__wrapper">
              <h3 class="title title__h4 align--center">
                {{ hightlitedProject.name }}
              </h3>

              <img
                class="margin__t--24 hightlited_project__image"
                :src="translate(hightlitedAction.image)"
                alt=""
                aria-hidden="true"
                v-if="translate(hightlitedAction.image)"
              >

              <p class="align--right project__statistic margin__t--48">
                <app-money
                  :amount="hightlitedProjectStatistics.amount_collected"
                  class="bold color--prim"
                />
              </p>

              <app-progress-bar
                class="progressbar project__progressbar"
                :value="hightlitedProjectStatistics.amount_collected"
                :max-value="getProjectGoal(hightlitedProject)"
                v-if="getProjectGoal(hightlitedProject)"
              />

              <p
                class="align--right project__statistic"
                v-if="getProjectGoal(hightlitedProject)"
              >
                <app-money
                  :amount="getProjectGoal(hightlitedProject)"
                  class="bold color--ter"
                />
              </p>

              <router-link
                :to="{ name: 'Project', params: { projectSlug: hightlitedProject.slug } }"
                class="btn btn__size--small btn__color--prim cta d-inline-block project__cta margin__t--12"
                :aria-label="hightlitedProject.name"
                v-if="translate(hightlitedAction.cta) && hightlitedProject.procedures.donation?.slug"
              >
                {{ translate(hightlitedAction.cta) }}
              </router-link>
            </div>
          </div>

          <figure
            class="hightlited_project__figure"
            :style="{ backgroundImage: `url(${getProjectImage(hightlitedProject)}), url(${getImagePath('placeholders/project-card-cover.jpg')})` }"
          />
        </div>
      </div>
    </article>

    <article
      class="campaign"
      v-if="isVisible('campaign')"
    >
      <div class="container campaign__wrapper">
        <figure
          class="campaign__figure"
          :style="{ backgroundImage: `url(${translate(campaign.image)}), url(${getImagePath('placeholders/project-card-cover.jpg')})` }"
        />

        <div class="campaign__content">
          <h2
            class="title title__h1"
            v-if="translate(campaign.title)"
          >
            {{ translate(campaign.title) }}
          </h2>

          <div
            class="editor__content margin__t--24"
            v-html="translate(campaign.content)"
            v-if="translate(campaign.content)"
          />

          <app-link
            :link="campaign.cta"
            class="btn btn__color--sec btn__size--small cta d-inline-block margin__t--24"
          />
        </div>
      </div>
    </article>

    <section
      class="actions"
      v-if="isVisible('actions') && projects.length"
    >
      <div class="container">
        <h2
          class="title title__h2 align--center"
          v-if="translate(actions.title)"
        >
          {{ translate(actions.title) }}
        </h2>

        <div class="row margin__t--24">
          <template
            v-for="project in projects"
            :key="project.id"
          >
            <div class="col-12 col-sm-6 col-md-3">
              <project-card
                class="project-card"
                :project="project"
              />
            </div>
          </template>
        </div>
      </div>
    </section>

    <section
      class="testimonials"
      v-if="isVisible('testimonials') && testimonials.quotes.length"
    >
      <div class="container testimonials__wrapper">
        <h2
          class="title title__h3 align--center testimonials__title"
          v-if="translate(testimonials.title)"
        >
          {{ translate(testimonials.title) }}
        </h2>

        <div class="testimonials__quotes margin__t--18">
          <article
            class="quote"
            v-for="({ quote, image, author }, key) in testimonials.quotes"
            :key="`quote_${key}`"
          >
            <figure
              class="quote__figure"
              :style="{ backgroundImage: `url(${translate(image)}), url(${getImagePath('placeholders/project-card-cover.jpg')})` }"
            />

            <blockquote class="padding__tb--12">
              <fa-icon
                class="quote__icon color--sec"
                :icon="['fas', 'quote-left']"
                v-if="translate(quote)"
              />

              <div
                class="editor__content"
                v-html="translate(quote)"
                v-if="translate(quote)"
              />

              <cite
                class="quote__author bold margin__t--6 d-block"
                v-if="translate(author)"
              >
                {{ translate(author) }}
              </cite>
            </blockquote>
          </article>
        </div>
      </div>
    </section>

    <section
      class="about color--white align--center padding__tb--48"
      v-if="isVisible('about')"
    >
      <div class="container about__wrapper">
        <h2
          class="title title__h2"
          v-html="translate(about.title)"
          v-if="translate(about.title)"
        />

        <div
          class="editor__content margin__t--12"
          v-html="translate(about.content)"
          v-if="translate(about.content)"
        />

        <app-link
          :link="about.cta"
          class="btn btn__color--prim btn__size--small cta d-inline-block margin__t--12"
        />
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, computed, defineAsyncComponent } from 'vue'
import { useProjectStore } from '@/stores/ProjectStore'
import { useI18n } from '@/vendors/i18n'
import useSection from '@/composables/modules/pages/useSection'
import { useModals } from '@/composables/app/useModals'
import { projects_overview_options } from '@/configurations/general/components-default-data'
import { scrollTo, getImagePath, truncate } from '@/utils/utils'
import { get as _get, omit as _omit } from 'lodash'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const AppMoney = defineAsyncComponent(() => import('&/atoms/AppMoney'))
const AppProgressBar = defineAsyncComponent(() => import('&/atoms/AppProgressBar'))
const AppVideoEmbed = defineAsyncComponent(() => import('&/atoms/AppVideoEmbed'))
const ProjectCard = defineAsyncComponent(() => import('&/organisms/ProjectCard'))
const AppModal = defineAsyncComponent(() => import('&/organisms/AppModal'))

const props = defineProps({
  page: { type: Object, required: true },
  sections: { type: Object, required: true }
})

const { translate } = useI18n()
const { getProject, getProjects, getStatistics, fetchProject, fetchStatistics } = useProjectStore()
const { getSectionAttributes, isVisible } = useSection(props.page)
const { show_modal: show_video_modal, openModal: openVideoModal, closeModal: closeVideoModal } = useModals()

const crowdfunding_projects = ref([])
const crowdfunding_projects_statistics = ref([])

const banner = computed(() => getSectionAttributes('banner'))
const crowdfunding = computed(() => getSectionAttributes('crowdfunding'))
const hightlitedAction = computed(() => getSectionAttributes('hightlited_action'))
const campaign = computed(() => getSectionAttributes('campaign'))
const actions = computed(() => getSectionAttributes('actions'))
const testimonials = computed(() => getSectionAttributes('testimonials'))
const about = computed(() => getSectionAttributes('about'))

const crowdfundingProjectsPromises = computed(() => isVisible('crowdfunding') ? _get(crowdfunding.value, 'project_id', []).map(id => fetchProject({ id })) : [])
const crowdfundingProjectsStatisticsPromises = computed(() => isVisible('crowdfunding') ? _get(crowdfunding.value, 'project_id', []).map(id => fetchStatistics({ id })) : [])
const hightlitedActionProjectId = computed(() => _get(hightlitedAction.value, 'project_id'))

const projectsQuery = computed(() => {
  const query = _get(actions.value, 'query', {})
  const sortByField = _get(projects_overview_options.find(sort => sort.value === query.sort), 'query', { 'sort_by_field[field]': 'created_at', 'sort_by_field[direction]': 'desc' })

  return _omit({ ...query, ...sortByField }, ['sort', 'collapse'])
})

Promise.all(crowdfundingProjectsPromises.value).then(projects => crowdfunding_projects.value = projects)
Promise.all(crowdfundingProjectsStatisticsPromises.value).then(statistics => crowdfunding_projects_statistics.value = statistics)

const { project: hightlitedProject } = getProject({ id: hightlitedActionProjectId.value, dependencies: [isVisible('hightlited_action') && hightlitedActionProjectId] })
const { statistics: hightlitedProjectStatistics } = getStatistics({ id: hightlitedActionProjectId.value, dependencies: [isVisible('hightlited_action') && hightlitedActionProjectId] })
const { projects } = getProjects({ query: projectsQuery })

const getProjectImage = project => _get(project, 'image.url') || _get(project, 'campaign.image.url') || getImagePath('placeholders/project-card-cover.jpg')
const getProjectGoal = project => _get(project.procedures.donation, 'goal_amount', 0)
</script>

<style lang="scss" scoped>
:deep(.cta) {
  font-family: $font-family--secondary;
  font-size: rem(18px);
  text-transform: uppercase;
  padding: 3px 12px;
  border-radius: 0;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.hgroup {
  text-align: center;
  font-size: rem(18px);

  &__title {
    @include mq(lg) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__icon {
    width: auto;
    height: 62px;
    display: block;
    margin: 0 auto 6px;

    @include mq(lg) {
      margin: 0 18px 0 0;
      display: inline-block;
    }
  }

  &__content {
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
  }
}

.title {
  text-transform: uppercase;
  font-weight: 700;

  &__h1 {
    font-size: rem(48px);
    line-height: 1;

    @include mq(md) {
      font-size: rem(72px);
    }
  }

  &__h2 {
    font-size: rem(36px);
    line-height: 1.23;

    @include mq(sm) {
      font-size: rem(60px);
    }
  }

  &__h3 {
    line-height: 1.13;
    font-size: rem(32px);

    @include mq(md) {
      font-size: rem(38px);
    }
  }

  &__h4 {
    line-height: 1;
    font-size: rem(28px);

    @include mq(md) {
      font-size: rem(32px);
    }
  }
}

.banner {
  background: #D9D9D9;
  font-size: rem(18px);
  line-height: 1.44;
  align-items: center;

  &__container {
    display: grid;
    grid-template-columns: 1fr;

    @include mq(sm) {
      gap: 24px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &__image {
    @include aspect-ratio(1114, 760);
  }

  &__figure {
    position: absolute;
    width: 100vw;
    min-height: 100%;
    top: 0;
    right: 50%;
    transform: translateX(50%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include mq(sm) {
      width: 50vw;
      right: 0;
      transform: none;
    }
  }

  &__play {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    transition: all .2s linear;

    &:hover {
      opacity: 1;
    }

    &::before {
      content: '';
      width: 0px;
      height: 0px;
      border-style: solid;
      border-color: transparent transparent $white transparent;
      transform: rotate(90deg);
      position: relative;
      border-width: 0 50px 86.6px 50px;

      @include mq(md) {
        border-width: 0 80px 138.6px 80px;
      }
    }
  }

  &__wrapper {
    padding: 48px 0;

    @include mq(lg) {
      padding: 160px 0;
    }
  }

  &__cta {
    &::before {
      content: '';
      position: relative;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 7px 12.1px 7px;
      border-color: transparent transparent $white transparent;
      transform: rotate(180deg);
      margin-right: 6px;
    }
  }
}

.crowdfunding {
  padding: 48px 0 68px;

  :deep(.progressbar) {
    height: 12px;
  }


  &__projects {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    max-width: 1050px;
    margin-left: auto;
    margin-right: auto;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .project {
    background: $white;
    position: relative;
    transition: all .2s;
    display: flex;
    flex-direction: column;

    &:hover {
      box-shadow: 0 4px 10px 0 rgba(82, 97, 115, 0.2);

      .project__cta {
        background-color: shade($color--primary);
      }
    }

    &__figure {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      @include aspect-ratio(515, 331);
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px 24px 36px;
      flex: 1 1 auto;
    }

    &__title {
      & +.project__statistic {
        margin-top: auto;
      }
    }

    &__statistic {
      font-size: rem(14px);
      line-height: 1.5;
      width: 100%;
    }

    :deep(.project__link) {
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
      }
    }
  }
}

.hightlited_action {
  padding: 68px 0 34px;
  background: $white;
  position: relative;
  overflow: hidden;

  :deep(.progressbar) {
    height: 12px;
  }

  &__project {
    background: #F9F9F9;
  }
}

.hightlited_project {
  display: grid;
  grid-template-columns: 1fr;

  @include mq(sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq(xl) {
    grid-template-columns: auto minmax(0, 625px);
  }

  &__content {
    position: relative;
    z-index: 10;
  }

  &__wrapper {
    max-width: 461px;
    padding: 48px 24px 72px;
    margin: 0 auto;

    @include mq(sm) {
      padding: 48px 24px;
    }
  }

  &__figure {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100%;

    @include aspect-ratio(625, 752);
  }

  &__image {
    position: absolute;
    bottom: 0;
    transform: translateY(calc(100% - 54px));
    display: block;
    width: 120%;
    height: auto;
    max-width: 793px;

    @include mq(sm) {
      position: relative;
      transform: none;
    }

    @include mq(lg) {
      width: 194.8402948403%;
    }
  }
}

.campaign {
  padding: 34px 0;
  background: $white;
  font-size: rem(18px);

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    max-width: 1043px;
    margin: 0 auto;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__figure {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @include aspect-ratio(514, 687);
  }

  &__content {
    padding: 24px 0;

    @include mq(sm) {
      padding-top: 48px;
    }

    @include mq(md) {
      padding-top: 70px;
    }
  }
}

.actions {
  background: $white;
  padding: 34px 0 68px;
}

.testimonials {
  padding: 48px 0;
  background: #D9D9D9;

  &__title {
    max-width: 777px;
    margin-left: auto;
    margin-right: auto;
  }

  &__quotes {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    max-width: 1055px;
    margin-left: auto;
    margin-right: auto;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.quote {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 24px;
  font-size: rem(18px);

  @include mq(md) {
    grid-template-columns: 192px auto;
  }

  &__figure {
    min-height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;

    @include aspect-ratio(390, 512);

    @include mq(sm) {
      @include aspect-ratio(1, 1);
    }

    @include mq(md) {
      @include aspect-ratio(390, 512);
    }
  }

  &__author {
    font-family: $font-family--secondary;
    text-transform: uppercase;
  }
}

.about {
  background: $color--secondary;
  font-size: rem(18px);

  &__wrapper {
    max-width: 1157px;
  }

  &__title {
    line-height: 1.23;
  }
}
</style>