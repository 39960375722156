<template>
  <div>
    <div class="header__top">
      <div class="container">
        <div class="header__container">
          <ul class="flex">
            <li>
              <fa-icon
                :icon="['far', 'map-marker-alt']"
                class="margin__r--6"
              />

              {{ $i18n.translate(attributes.top.address) }}
            </li>

            <li>
              <fa-icon
                :icon="['far', 'phone-alt']"
                class="margin__r--6"
              />

              {{ $i18n.translate(attributes.top.phone) }}
            </li>

            <li>
              <fa-icon
                :icon="['far', 'university']"
                class="margin__r--6"
              />

              {{ $i18n.translate(attributes.top.bank_account) }}
            </li>
          </ul>

          <div class="flex">
            <ul class="flex">
              <li>
                <a
                  class="link__color--white-prim link--naked"
                  v-show="attributes.top.website.show"
                  @click.prevent="setRouteNavigation(attributes.top.website)"
                >
                  <fa-icon
                    :icon="['far', 'bookmark']"
                    class="margin__r--6"
                  />

                  {{ $i18n.translate(attributes.top.website.text) }}
                </a>
              </li>
            </ul>

            <language-switcher
              class="language"
              v-slot="{ options, updateLanguage, isActive }"
            >
              <ul class="language__ul">
                <li
                  class="language__li"
                  v-for="language in options"
                  :key="language.id"
                >
                  <button
                    class="link__color--white-prim link--naked"
                    :class="{'link--active': isActive(language.id)}"
                    type="button"
                    @click.prevent="updateLanguage(language.id)"
                  >
                    {{ language.label.value.toUpperCase() }}
                  </button>
                </li>
              </ul>
            </language-switcher>
          </div>
        </div>
      </div>
    </div>

    <header
      class="header"
      :class="{'header--fixed': mobileMenuOpened}"
      v-if="section"
    >
      <div class="container">
        <div class="header__logo header__container">
          <button
            v-show="client.logo.url"
            @click.prevent="setRouteNavigation(logoUrl)"
          >
            <img
              class="logo__img"
              :src="client.logo.url"
              :alt="client.name"
            >
          </button>

          <section>
            <ul class="header__sessions">
              <li
                class="header__sessions-item"
                v-if="!isAuthenticated"
              >
                <button
                  @click.prevent="openSessionAppModal('login')"
                  class="link__color--prim link--naked pointer"
                >
                  <fa-icon
                    class="margin__r--6"
                    :icon="['fas', 'user']"
                  />

                  {{ $i18n.t('literal.login') }}
                </button>
              </li>

              <li v-if="isAuthenticated && user">
                <router-link
                  class="header__sessions-item"
                  :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
                >
                  <user-profile-img :avatar="user.avatar" />

                  <div class="margin__l--6">
                    <p>{{ user.firstname }}</p>
                    <p class="link__see">
                      {{ $i18n.t('literal.see_profile') }}
                    </p>
                  </div>
                </router-link>
              </li>
            </ul>

            <app-burger-menu
              class="header__burger"
              :state="mobileMenuOpened"
              @toggle="toggleMobileMenu"
            />
          </section>
        </div>
      </div>

      <transition name="fade-mobile-menu">
        <section
          class="mobile__menu"
          v-if="section && mobileMenuOpened"
        >
          <ul class="mobile__sessions">
            <li
              class="mobile__sessions-item header__sessions-item"
              v-if="!isAuthenticated"
            >
              <a
                @click.prevent="openSessionAppModal('login')"
                class="link__color--white link--naked pointer"
              >
                <fa-icon
                  class="margin__r--6 color--prim"
                  :icon="['fal', 'sign-in']"
                  fixed-width
                />
                {{ $i18n.t('literal.login') }}
              </a>
            </li>

            <li
              class="mobile__sessions-item header__sessions-item"
              v-if="!isAuthenticated"
            >
              <a
                @click.prevent="openSessionAppModal('register')"
                class="link__color--white link--naked pointer"
              >
                <fa-icon
                  class="margin__r--6 color--prim"
                  :icon="['fal', 'user-plus']"
                  fixed-width
                />
                {{ $i18n.t('literal.register') }}
              </a>
            </li>

            <li
              v-if="isAuthenticated && user"
              @click.prevent="mobileMenuOpened = false"
            >
              <router-link
                class="mobile__sessions-item"
                :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
              >
                <user-profile-img
                  :avatar="user.avatar"
                  size="4"
                />

                <div class="margin__l--6">
                  <p>{{ user.firstname }}</p>
                  <p class="link__see">
                    {{ $i18n.t('literal.see_profile') }}
                    <fa-icon
                      class="margin__l--6"
                      size="xs"
                      :icon="['fal', 'chevron-right']"
                    />
                  </p>
                </div>
              </router-link>
            </li>
          </ul>

          <ul class="mobile__list">
            <template
              v-for="({ link }, index) in attributes.bottom.navigation"
              :key="`mobile_menu_item_${index}`"
            >
              <li
                class="mobile__list-item"
                v-show="link.show"
              >
                <a
                  class="mobile__list-link link__color--dg link--naked"
                  :class="{'mobile__link--active': isVisited(link)}"
                  @click.prevent="setRouteNavigation(link); mobileMenuOpened = false"
                >
                  {{ $i18n.translate(link.text) }}
                </a>
              </li>
            </template>
          </ul>

          <ul class="mobile__actions">
            <template
              v-for="({ link }, index) in attributes.bottom.actions"
              :key="`cta_${index}`"
            >
              <li
                class="mobile__actions-item"
                v-show="link.show"
              >
                <button
                  class="mobile__actions-link btn btn__size--classic btn__color--prim"

                  :disabled="!isActive(link)"
                  @click.prevent="setRouteNavigation(link); mobileMenuOpened = false"
                >
                  {{ $i18n.translate(link.text) }}
                </button>
              </li>
            </template>
          </ul>

          <language-switcher
            class="language"
            v-slot="{ options, updateLanguage, isActive }"
          >
            <ul class="language__ul">
              <li
                class="language__li"
                v-for="language in options"
                :key="language.id"
              >
                <button
                  class="link__color--white-prim link--naked"
                  :class="{'link--active': isActive(language.id)}"
                  type="button"
                  @click.prevent="updateLanguage(language.id)"
                >
                  {{ language.label.value.toUpperCase() }}
                </button>
              </li>
            </ul>
          </language-switcher>
        </section>
      </transition>
    </header>

    <div class="header__bottom">
      <div class="container">
        <div class="header__container">
          <ul class="flex header__navigation">
            <template
              v-for="({ link }, index) in attributes.bottom.navigation"
              :key="`menu_item_link_${index}`"
            >
              <li v-show="link.show">
                <a
                  class="link link__color--dg link--naked"
                  :class="{'link--active': isVisited(link)}"
                  @click.prevent="setRouteNavigation(link)"
                >
                  {{ $i18n.translate(link.text) }}
                </a>
              </li>
            </template>
          </ul>

          <ul class="flex header__actions">
            <template
              v-for="({ link }, index) in attributes.bottom.actions"
              :key="`menu_item_cta_${index}`"
            >
              <li v-show="link.show">
                <button
                  class="btn btn__size--classic btn__color--prim"
                  :disabled="!isActive(link)"
                  @click.prevent="setRouteNavigation(link)"
                >
                  {{ $i18n.translate(link.text) }}
                </button>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>

    <app-modal
      v-if="showSessionAppModal"
      size="sm"
      @close="showSessionAppModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="template"
          @authenticated="showSessionAppModal = false"
        />
      </template>
    </app-modal>
  </div>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'
import UserProfileImg from '&/atoms/UserProfileImg'
import PageRouteNavigation from '%/PageRouteNavigation'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
import LanguageSwitcher from '&/atoms/LanguageSwitcher'

export default defineComponent({
  name: 'CustomHeaderUZBrusselFoundation',
  components: {
    UserProfileImg,
    AppBurgerMenu,
    LanguageSwitcher,
    SessionForms: defineAsyncComponent(() => import('&/modules/sessions/SessionForms')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      showSessionAppModal: false,
      mobileMenuOpened: false,
      template: null
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    attributes () {
      return _get(this.section, 'attributes', {})
    },
    logoUrl () {
      return _get(this.attributes, 'logo.link')
    }
  },
  methods: {
    openSessionAppModal (template) {
      this.template = template
      this.showSessionAppModal = true
    },
    toggleMobileMenu (value) {
      this.mobileMenuOpened = !value
    }
  }
})
</script>

<style lang="scss" scoped>
$breakpoint: 'sm';

.flex {
  display: flex;
  gap: 0 6px;
}

.header {
  background-color: rgba($white, .95);
  box-shadow: 0 4px 8px 0 rgba(82, 97, 115, 0.1);

  @include mq(sm) {
    padding: $padding__base * 1.5;
  }

  @include mq(md) {
    padding: $padding__base * 1.5 $padding__base * 2;
  }

  &__cell, &__menu, &__actions, &__sessions, &__language {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__cell--grow {
    flex-grow: 1;
  }

  &__logo {
    padding: $padding--base;

    @include mq(sm) {
      padding: 0;
    }
  }

  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background-color: $white;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__top {
    display: none;
    background-color: $color--tertiary;
    color: $white;
    font-size: rem(12px);
    padding: 6px 0;

    @include mq($breakpoint) {
      display: block;
    }

    & li:not(:first-child) {
      margin-left: 18px;
    }

    .link--active {
      color: $color--primary;
    }
  }

  &__bottom {
    display: none;
    background-color: $color--primary;
    color: $white;
    padding: 18px 0;

    @include mq($breakpoint) {
      display: block;
    }

    li:not(:first-child) {
      margin-left: 18px;
    }
  }

  &__navigation {
    font-size: rem(16px);

    @include mq(md) {
      font-size: rem(20px);
    }

    li:not(:first-child) {
      margin-left: 20px;

      @include mq(md) {
        margin-left: 40px;
      }
    }

    .link {
      color: $white;

      &:hover, &:focus, &--active {
        color: $color--tertiary;
      }
    }
  }

  &__actions {
    & li:nth-child(even) .btn {
      background: $color--tertiary;

      &:hover {
        background: transparent;
      }
    }

    & li:nth-child(odd) .btn {
      background: transparent;

      &:hover {
        background: $color--tertiary;
      }
    }

    .btn {
      border-radius: 0;
      border: 2px solid $white;
    }
  }

  &__sessions {
    margin-left: $margin__base * 2;
    display: none;

    @include mq($breakpoint) {
      display: block;
    }

    &-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &:hover .link__see {
        color: shade($color--primary, 10%);
      }

      &:focus .link__see {
        border-bottom: 1px solid $color--primary;
      }

      .link__see {
        font-size: em(14px);
        line-height: 1.2;
        color: $color--primary;
        transition: all .3s;
      }
    }
  }

  &__burger {
    display: inherit;

    @include mq($breakpoint) {
      display: none;
    }

    & :deep() .menu__label {
      display: none;
    }
  }

  .locale-switcher {
    box-shadow: none;
  }
}

.locale-switcher {
  background-color: transparent;
}

.logo {
  &__img {
    width: 200px;
    transition: all .5s;
    display: block;

    @include mq(md) {
      width: 340px;
    }

    &--hidden {
      visibility: hidden;
      opacity: 0%;
    }
  }
}

.mobile {
  &__nav {
    width: 100vw;
    height: 100vh;
    background: $white;
    position: fixed;
    z-index: 10;
    overflow: hidden;
  }

  &__menu {
    margin-top: $margin--base;
    border-top: 1px solid $dw;
    display: block;
    background-color: $color--tertiary;
    padding: 18px;

    @include mq($breakpoint) {
      display: none;
    }
  }

  &__list {
    padding: $padding--base 0;
    border-bottom: 1px solid $color--primary;

    &-link {
      display: block;
      width: 100%;
      text-align: center;
      padding: $padding--base;
      color: $white;
    }
  }

  &__link {
    &--active {
      background-color: rgba($white, .1);
      color: $color--primary;
    }
  }

  &__actions {
    margin-top: $margin--base * 2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    justify-content: space-between;

    &-item:nth-child(even) .btn {
      background: transparent;

      &:hover {
        background: $color--tertiary;
      }
    }

    &-link {
      padding: calc($padding--base / 2) $padding--base;
      border-radius: 0;
      border: 2px solid $white;
      font-size: rem(14px);
      width: 100%;
    }
  }

  &__sessions {
    padding: 0 $padding--base $padding--base $padding--base;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color--primary;

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $white;
    }

    .link__see {
      color: $color--primary;
      font-size: rem(12px);
    }
  }

  &__languages {
    margin-top: 24px;
    text-align: center;
    color: $white;

    .link--active {
      color: $color--primary;
    }
  }
}

.fade-mobile-menu-enter-active {
  animation: fadeTranslationY .5s ease-in-out;
}

.fade-mobile-menu-leave-active {
  animation: fadeTranslationY .5s ease-in-out reverse;
}

@keyframes fadeTranslationY {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 100%;
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}

.language {
  justify-content: center;
  margin-top: 24px;

  @include mq($breakpoint) {
    margin-left: 18px;
    margin-top: 0;
    justify-content: flex-end;
  }

  &:deep .locale-switcher {
    background-color: transparent;
  }

  &__ul {
    display: flex;
    gap: 10px;

    @include mq($breakpoint) {
      gap: 8px;
    }
  }

  &__li {
    margin: 0 !important;
    position: relative;

    &:not(:last-child):after {
      content: '|';
      position: absolute;
      right: -7px;
      color: $white;

      @include mq($breakpoint) {
        right: -6px;
      }
    }

    & .link--active {
      color: $color--primary;
    }
  }
}
</style>
