export default [
  {
    name: 'custom_footer_uzgent',
    path: 'modules/pages/uzgent/FooterUzgent'
  },
  {
    name: 'custom_header_uzgent',
    path: 'modules/pages/uzgent/HeaderUzgent'
  },
  {
    name: 'custom_homepage_uzgent',
    path: 'modules/pages/uzgent/HomepageUzgent'
  }
]
