export default [
  {
    name: 'custom_footer_azvmsv',
    path: 'modules/pages/azvmsv/FooterAzvmsv'
  },
  {
    name: 'custom_header_azvmsv',
    path: 'modules/pages/azvmsv/HeaderAzvmsv'
  },
  {
    name: 'custom_homepage_azvmsv',
    path: 'modules/pages/azvmsv/HomepageAzvmsv'
  }
]
