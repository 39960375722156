<template>
  <main class="main">
    <section class="main__header">
      <div class="container">
        <div class="header__images">
          <img
            class="img img__balloons"
            :src="images.balloons"
          >

          <img
            class="img img__clouds"
            :src="images.clouds"
          >
        </div>
      </div>

      <div class="header__counter">
        <div class="container">
          <h2 class="title--counter">
            {{ $i18n.translate(attributes.header.title) }}
          </h2>

          <ul
            class="counter__ul"
            v-if="metrics.length > 0 && stats"
          >
            <li
              class="counter__li"
              v-for="metric in metrics"
              :key="metric.index"
            >
              <span class="stats__strong bold margin__b--6">
                <app-date
                  class="counter__stats"
                  v-if="metric.type === 'date' && metric.date"
                  :timestamp="metric.date"
                  filter="differenceInCalendarDays"
                />

                <app-money
                  class="counter__stats"
                  v-else-if="metric.type === 'amount_collected'"
                  :amount="stats[metric.type]"
                />

                <span
                  class="counter__stats"
                  v-else
                >
                  {{ stats[metric.type] }}
                </span>
              </span>

              <p class="counter__label">
                {{ $i18n.translate(metric.text) }}
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div class="header__description">
        <div class="container align--center">
          <p v-html="$i18n.translate(attributes.header.description)" />
        </div>
      </div>
    </section>

    <section class="main__section1">
      <div class="section1__title">
        <img
          class="img img__rocket"
          :src="images.rocket"
        >
        <h1 class="title--campaigns">
          {{ $i18n.translate(attributes.actions.title) }}
        </h1>
      </div>

      <div class="section1__campaigns">
        <div class="container">
          <div class="row">
            <template v-if="regularCampaigns.length">
              <section
                class="col-12 col-sm-6 col-md-3 col-lg-3"
                v-for="(item, index) in regularCampaigns"
                :key="`${item.campaign.id}_${index}`"
                @click.prevent="goToCampaign(item.campaign)"
              >
                <article class="card card--regular">
                  <div class="card__header">
                    <h3 class="bold title--card">
                      {{ $i18n.translate(item.campaign.name) }}
                    </h3>
                  </div>

                  <div
                    class="card__cover"
                    :style="{ backgroundImage: `url(${ item.campaign.image.url || getImagePath('placeholders/project-card-cover.jpg') })` }"
                  />

                  <div class="card__description editor__content">
                    <p
                      v-if="$i18n.translate(item.text)"
                      v-html="$i18n.translate(item.text)"
                    />

                    <p
                      v-else
                      v-html="$i18n.translate(item.campaign.description)"
                    />
                  </div>
                </article>
              </section>
            </template>
          </div>
        </div>
      </div>
    </section>

    <svg
      class="img__cloud"
      viewBox="0 0 2456.89 395.94"
    ><g><path
      d="M4.47 395.77C-8.95 334 15.68 267.82 58.87 221.61S161.7 147.26 223 131.46c64.05-16.53 132.85-20.41 195.82-.16s119.26 67 141.11 129.42C547.9 116.4 672 22 815.66 3.9s283.58 43.1 307.75 185.86c36.19-48.3 113.92-73.16 177.46-51.12 86.11 29.88 113.94 87.05 123.85 146.59 57.25-77.25 137.37-95 219.38-84.77s162.41 81.91 171.49 164c8.6-143.47 104.7-278.14 237.58-332.92s296-27 403.18 68.74"
      fill="none"
      stroke="#cad9dd"
      stroke-miterlimit="10"
      stroke-width="1.61"
    /></g></svg>

    <section class="main__section2">
      <div class="container">
        <h2 class="title--section2">
          {{ $i18n.translate(attributes.body.title) }}
          <img
            class="img img__stars1"
            :src="images.nearby_stars"
          >
        </h2>

        <p
          class="section2__description editor editor__content"
          v-html="$i18n.translate(attributes.body.text)"
        />
      </div>
    </section>

    <svg
      class="img__waves-header"
      viewBox="0 0 1641.24 299.83"
    >
      <g>
        <path
          d="M1524.43 115.28c-67 13.45-138.12-17.82-182.45-69.76-7.7 116.16-71 173.31-164.65 210.76s-203.38 19-290.75-31.51c-63.34-36.58-104.26-71.57-109.64-155.41-18.27 73.09-80.13 106-144 105.33S495.33 124.18 489.32 71.58c-9.78 31.18-42.34 56.9-82.12 56.9-62.35 0-90-51.84-97.82-78.47-27.61 38.1-67.26 86.65-130.38 87.49A272.34 272.34 0 013.86 75.93 23 23 0 010 71.82l10.13 228H1618L1641.24 0c-10.55 56-59.84 103.84-116.81 115.28z"
          fill="#d8e5dc"
        />
      </g>
    </svg>

    <section class="main__section3">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8">
            <h2 class="title--campaigns">
              {{ $i18n.translate(attributes.campaigns.title) }}
            </h2>

            <template
              v-for="(item, index) in highlightedCampaigns"
              :key="`${item.campaign.id}_${index}`"
            >
              <article class="card card--highlighted">
                <div
                  class="card__cover"
                  :style="{ backgroundImage: `url(${ item.campaign.image.url || getImagePath('placeholders/project-card-cover.jpg') })` }"
                />

                <div class="card__infos">
                  <h3 class="bold title--card">
                    {{ $i18n.translate(item.campaign.name) }}
                  </h3>

                  <div class="card__description editor__content">
                    <p v-html="$i18n.translate(item.campaign.description)" />
                  </div>

                  <button
                    class="card__cta btn btn__size--small"
                    type="button"
                    :disabled="isCtaDisabled(item.campaign)"
                    @click.prevent="goToCampaign(item.campaign)"
                  >
                    {{ $i18n.translate(item.campaign.cta.text) }}
                  </button>
                </div>
              </article>
            </template>
          </div>
        </div>
      </div>

      <img
        class="img img__stars2"
        :src="images.spaced_stars"
      >
    </section>

    <svg
      class="img__waves-footer"
      viewBox="0 0 1499.06 103"
    >
      <g>
        <path
          d="M3.27 73.61C58.18 29.49 132 9.76 201.59 20.61 260.52 29.8 314.52 59.37 373 71.08c76.33 15.28 154.9-.66 232.45-7.44a983 983 0 01199 2.86c43.58 5.07 87.22 13.08 131 10.37 31-1.92 61.52-9.2 92.48-12.12 42.71-4 85.71.26 128.4 4.56L1491.08 103l8-103H0z"
          fill="#d8e5dc"
        />
      </g>
    </svg>

    <section class="main__section4">
      <div class="container">
        <a :href="setRouteNavigation(attributes.gift.link)">
          <img
            class="img img__bird"
            :src="$i18n.translate(attributes.gift.image)"
          >
        </a>

        <img
          class="img img__flowers"
          :src="images.flowers"
        >
      </div>
    </section>
  </main>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { get as _get, omit as _omit } from 'lodash'
import { isPast } from 'date-fns'
import PageRouteNavigation from '%/PageRouteNavigation'
import AppMoney from '&/atoms/AppMoney'
import { getImagePath } from '@/utils/utils'

export default defineComponent({
  name: 'CustomHomepageBovenDeWolken',
  components: {
    AppMoney,
    AppDate: defineAsyncComponent(() => import('&/atoms/AppDate'))
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  mixins: [PageRouteNavigation],
  data () {
    return {
      query: {
        'sort_by_field[field]': 'created_at',
        'sort_by_field[direction]': 'desc',
        'per_page': 4
      }
    }
  },
  setup: () => ({ getImagePath }),
  created () {
    this.fetchCampaigns({ query: this.query })
    this.fetchClientStatistics({ query: this.metricsQuery })
  },
  computed: {
    ...mapGetters({
      campaigns: 'campaigns/getCampaigns',
      stats: 'client/statistics'
    }),
    regularCampaigns () {
      if (!Object.values(this.campaigns).length) return []
      const items = _get(this.attributes, 'actions.items', [])

      return items.map(item => {
        item.campaign = Object.values(this.campaigns).find(campaign => item.id.includes(campaign.id))
        return item
      }).filter(item => item.campaign)
    },
    highlightedCampaigns () {
      if (!Object.values(this.campaigns).length) return []
      const items = _get(this.attributes, 'campaigns.items', [])

      return items.map(item => {
        item.campaign = Object.values(this.campaigns).find(campaign => item.id.includes(campaign.id))
        return item
      }).filter(item => item.campaign)
    },
    images () {
      return _get(this.section, 'attributes.images', {})
    },
    attributes () {
      return _get(this.section, 'attributes', {})
    },
    metrics () {
      return _get(this.attributes, 'stats.metrics', [])
    },
    metricsQuery () {
      return _omit(_get(this.attributes, 'stats.settings', {}), ['collapse'])
    }
  },
  methods: {
    ...mapActions({
      fetchCampaigns: 'campaigns/fetchCampaigns',
      fetchClientStatistics: 'client/fetchClientStatistics'
    }),
    isCtaDisabled (campaign) {
      const procedure = Array.isArray(campaign.procedures) ? campaign.procedures.find(procedure => procedure.category === 'create-project') : null
      if (!procedure) return true
      if (!campaign.cta.url[this.$i18n.locale] && !campaign.cta.target_name && !campaign.cta.target_url) return true
      if (!isPast(new Date(procedure.start_date)) || (procedure.end_date && isPast(new Date(procedure.end_date)))) return true
      return false
    },
    goToCampaign (campaign) {
      const procedureSlug = Array.isArray(campaign.procedures) ? campaign.procedures.find(procedure => procedure.category === 'create-project').slug : null

      if (_get(campaign, `cta.url[${this.$i18n.locale}]`)) {
        window.open(campaign.cta.url[this.$i18n.locale], '_blank')
      } else {
        this.$router.push({ name: 'ClientProcedure', params: { procedureSlug } }).catch()
      }
    }
  }
})
</script>

<style lang="scss" scoped>
$manhattan: #F7B8A0;
$paris_white: #D8E5DC;
$jelly_bean: #29809B;
$geyser: #CAD9DD;

.main {
  padding-top: 20px;
  background-color: white;
  overflow: hidden;

  &__section1 {
    padding: 50px 20px 0px 20px;

    @include mq(sm) {
      padding: 50px 20px 0px;
    }
  }

  &__section2 {
    padding: 0 12px;

    @include mq(sm) {
      padding: 0 100px;
    }
  }

  &__section3 {
    padding-bottom: 30px;
    background-color: $paris_white;
    position: relative;

    @include mq(sm) {
      padding-bottom: 60px;
    }
  }
}

.header {
  &__counter {
    background-color: $paris_white;
    padding: 50px 0 20px 0;
    margin-top: -20px;

    @include mq(sm) {
      margin-top: -70px;
      padding: 100px 0 20px 0;
    }
  }

  &__description {
    background-color: $manhattan;
    color: white;
    padding: 20px 0;
    font-size: rem(14px);
  }

  &__images {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
  }
}

.section1 {
  &__title {
    position: relative;
    padding: 30px 0;

    @include mq(sm) {
      padding: 80px 0px;
    }
  }
}

.counter {
  &__ul {
    margin-top: $margin--base;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: $margin--base * 2;
    justify-items: center;
    align-items: center;

    @include mq(md) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__li {
    text-align: center;
  }

  &__stats {
    font-size: rem(22px);
    text-align: center;
    color: $white;
    font-weight: lighter;

    @include mq(md) {
      font-size: rem(28px);
    }
  }

  &__label {
    color: $manhattan;
    text-align: center;
    margin-top: calc($margin--base / 2);
  }
}

.section2 {
  &__description {
    color: $manhattan;
  }
}

.title {
  &--counter {
    font-size: rem(16px);
    color: $jelly_bean;
    text-align: center;
    font-weight: bold;

    @include mq(sm) {
      font-size: rem(20px);
    }
  }

  &--campaigns {
    font-size: rem(18px);
    color: $manhattan;
    text-align: center;
    font-weight: bold;

    @include mq(sm) {
      font-size: rem(40px);
    }
  }

  &--card {
    font-weight: lighter;
    color: $manhattan;
  }

  &--section2 {
    color: $jelly_bean;
    font-size: rem(22px);
    display: inline;
    position: relative;

    @include mq(sm) {
      font-size: rem(40px);
    }
  }
}

.card {
  margin: $margin__base 0;
  background-color: #21829e;
  color: white;

  &--regular {
    @include mq(sm) {
      cursor: pointer;
      transition: all .3s;
      height: calc(100% - #{$margin__base});

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  &--highlighted {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: $margin__base * 2 0;

    @include mq(md) {
      flex-wrap: nowrap;
    }

    .card__cover {
      border-top-left-radius: $radius__cards;
      border-bottom-left-radius: $radius__cards;
      width: 100%;

      @include mq(md) {
        width: 60%;
      }
    }

    .card__infos {
      width: 100%;
      text-align: center;
      padding: $padding__base * 3 $padding__base * 2 $padding__base * 3 $padding__base * 2;

      @include mq(md) {
        width: 40%;
      }
    }

    .card__cta {
      background-color: $geyser;
      color: $jelly_bean;
      font-size: rem(14px);
      border-radius: 10px;

      &:hover, &:focus {
        background-color: $manhattan;
        color: white;
      }

      &:disabled {
        background-color: $vlg;
        color: $mg;
        cursor: default;
      }
    }

    .card__description {
      margin-top: $margin__base;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  &__cover {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include aspect-ratio(3, 2);
  }

  &__header {
    text-align: center;
    padding: 24px 6px 6px 6px;
  }

  &__description {
    font-size: rem(12px);
    text-align: center;
    padding: 6px 6px 24px 6px;
  }
}

.img {
  display: block;

  &__balloons {
    width: 100px;
    margin: auto;
    z-index: 3;

    @include mq(sm) {
      width: 200px;
      margin: none;
    }
  }

  &__clouds {
    position: absolute;
    display: block;
    width: 100%;
    bottom: 0px;
    right: -50px;
    z-index: 2;

    @include mq(sm) {
      position: relative;
      right: 0;
      width: calc(100% - 300px);
      max-width: 760px
    }
  }

  &__rocket {
    position: absolute;
    top: -100px;
    right: 0;
    width: 400px;

    @include mq(sm) {
      top: 50%;
      right: 50%;
      margin-right: -470px;
      margin-top: -200px;
      width: 600px;
    }
  }

  &__cloud {
    margin-left: -100px;
    margin-bottom: -25px;
    margin-top: -30px;
    width: calc(100% + 400px);

    @include mq(sm) {
      margin-left: -200px;
      margin-bottom: -25px;
      margin-top: -120px;
      width: calc(100% + 300px);
    }
  }

  &__stars1 {
    position: absolute;
    top: -15px;
    right: -25px;
    width: 40px;
  }

  &__waves-header {
    margin-left: -100px;
    margin-bottom: -10px;
    width: calc(100% + 150px);

    @include mq(sm) {
      width: calc(100% + 200px);
    }
  }

  &__waves-footer {
    margin-left: -100px;
    margin-top: -10px;
    width: calc(100% + 150px);

    @include mq(sm) {
      width: calc(100% + 200px);
    }
  }

  &__bird {
    margin-left: -75px;
    width: calc(100% + 100px);

    @include mq(sm) {
      margin-left: 0;
      width: 750px;
    }
  }

  &__flowers {
    margin-left: -150px;
    margin-top: 30px;
    padding-bottom: 40px;
    width: calc(100% + 300px);

    @include mq(sm) {
      margin-left: 0;
      margin-top: 60px;
      width: 100%;
    }
  }

  &__stars2 {
    position: absolute;
    right: 50%;
    margin-right: -35px;
    top: calc(100% - 40px);
    width: 90px;

    @include mq(sm) {
      margin-right: 0;
      right: 80px;
      top: 100%;
      width: 140px;
    }
  }
}
</style>
