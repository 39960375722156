<template>
  <footer class="footer">
    <section class="footer__sponsors">
      <ul class="sponsors">
        <li
          class="sponsors__li"
          v-for="({link, logo}, index) in sponsors.logos"
          :key="`sponsor_${index}`"
        >
          <app-link
            :link="link"
            class="navigation__link"
          >
            <img
              class="sponsors__img"
              :src="translate(logo)"
              :alt="translate(link.text)"
            >
          </app-link>
        </li>
      </ul>
    </section>

    <section class="footer__infos">
      <div class="container">
        <div class="infos">
          <div>
            <h2 class="bold">
              {{ translate(column_1.title) }}
            </h2>

            <ul>
              <li
                v-for="(link, index) in infos"
                :key="`group1_${index}`"
              >
                <a
                  class="link__color--white link--naked"
                  :href="link.url"
                  v-if="link.url"
                  target="_blank"
                >
                  {{ link.text }}
                </a>

                <span v-else>
                  {{ link.text }}
                </span>
              </li>
            </ul>
          </div>

          <div class="navigation">
            <ul>
              <li
                v-for="({link}, index) in column_2.group_1"
                :key="`group1_${index}`"
              >
                <app-link
                  :link="link"
                  class="navigation__link"
                />
              </li>
            </ul>

            <ul class="margin__t--24">
              <li
                v-for="({link}, index) in column_2.group_2"
                :key="`group2_${index}`"
              >
                <app-link
                  :link="link"
                  class="navigation__link"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script setup>
import { computed } from 'vue'
import { get as _get } from 'lodash'
import { useI18n } from '@/vendors/i18n'

import AppLink from '&/atoms/AppLink'

const props = defineProps({
  section: { type: Object, required: true }
})

const { translate } = useI18n()

const column_1 = computed(() => _get(props, 'section.attributes.column_1', {}))
const column_2 = computed(() => _get(props, 'section.attributes.column_2', {}))
const sponsors = computed(() => _get(props, 'section.attributes.sponsors', {}))
const infos = computed(() => {
  return _get(column_1.value, 'contact', [])
    .map(info => {
      let url = ''

      if (info.type !== 'text') {
        if (info.type === 'phone_number') url += 'tel:'
        if (info.type === 'email') url += 'mailto:'

        url += translate(info.text).replace(/ /g, '')
      }

      return {
        text: translate(info.text),
        url
      }
    })
    .filter(info => info.text)
})
</script>

<style lang="scss" scoped>
.container {
  max-width: 340px;
  padding: 0;
  margin: 0 auto;

  @media (min-width: 670px) {
    max-width: 640px;
  }

  @media (min-width: 990px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
}

.footer {
  padding-top: 32px;
  background-color: $white;
  font-size: rem(14px);

  &__infos {
    background-color: #626262;
    margin-top: 24px;
    padding: 50px 0;
  }
}

.sponsors {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px 30px;
  flex-wrap: wrap;

  &__li {
    position: relative;
  }

  &__li:not(:last-child) {
    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 40px;
      background-color: #aaa;
      position: absolute;
      right: -15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__img {
    display: block;
    max-width: 100px;
    max-height: 100px;
  }
}

.infos {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: $margin--base * 2;
  justify-items: center;
  align-items: baseline;
  color: $white;

  @include mq(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  & > * {
    width: 100%;

    @include mq(md) {
      width: auto;
    }
  }

  &:after {
    content: '';
    display: none;
    width: 1px;
    height: 100px;
    background-color: $white;
    position: absolute;

    @include mq(md) {
      display: block;
    }
  }
}

.navigation {
  &:deep(.navigation__link) {
    color: $white;
  }
}
</style>
