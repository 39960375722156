import { Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import { getLinkType, getStyleRule, getPasteRules, deleteNode, handleClickNode } from '@/vendors/tiptap/helpers'
import { hexToRGB, rgbToHSL } from '@/utils/utils'
import { get as _get } from 'lodash'
import NodeLink from '&/modules/editor/NodeLink'

export default Node.create({
  name: 'link',
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,

  addOptions () {
    return {
      toggle: false,
      defaultColor: null,
      HTMLAttributes: {
        target: '_blank',
        rel: 'noopener noreferrer nofollow',
      }
    }
  },

  addAttributes () {
    return {
      type: {
        default: 'link',
        parseHTML: element => getLinkType(element),
        rendered: false
      },
      href: { default: null },
      rel: { default: this.options.HTMLAttributes.rel },
      target: {
        default: this.options.HTMLAttributes.target,
        parseHTML: element => element.getAttribute('target'),
        renderHTML: attributes => ({ target: attributes.target || '_blank' })
      },
      text: {
        default: null,
        parseHTML: element => element.innerText,
        rendered: false
      },
      class: {
        default: null,
        renderHTML: attributes => {
          const { type, isInlineStyle = false } = attributes

          return type === 'button' && !isInlineStyle ? { class: 'btn btn__size--classic' } : {}
        }
      },
      textColor: {
        default: this.options.defaultColor,
        parseHTML: element => getStyleRule(element, '--textcolor') || getStyleRule(element, 'color') || (getLinkType(element) === 'button' ? '#ffffff' : this.options.defaultColor),
        renderHTML: attributes => {
          const { type, textColor, isInlineStyle = false } = attributes
          const color = textColor ? textColor : (type === 'button' ? '#ffffff' : this.options.defaultColor)

          if (isInlineStyle) return { style: `color:${color}` }

          const hsl = rgbToHSL(hexToRGB(color))

          return { style: `--textcolor:${color};--textcolor-darken:hsl(${hsl.h}, ${hsl.s}%, ${hsl.l - 10}%)` }
        }
      },
      bgColor: {
        default: null,
        parseHTML: element => getStyleRule(element, '--bgcolor') || getStyleRule(element, 'background-color') || this.options.defaultColor,
        renderHTML: attributes => {
          const { type, bgColor, isInlineStyle = false } = attributes

          if (!bgColor || type !== 'button') return {}

          if (isInlineStyle) return { style: `background-color:${bgColor}` }

          const hsl = rgbToHSL(hexToRGB(bgColor))

          return { style: `--bgcolor:${bgColor};--bgcolor-darken:hsl(${hsl.h}, ${hsl.s}%, ${hsl.l - 10}%)` }
        }
      }
    }
  },

  parseHTML () {
    return [{ tag: 'a[href]' }]
  },

  renderHTML ({ node, HTMLAttributes }) {
    return ['a', HTMLAttributes, _get(node, 'attrs.text') || _get(node, 'attrs.href')]
  },

  addNodeView () {
    return VueNodeViewRenderer(NodeLink)
  },

  addCommands () {
    return {
      setLink: attributes => ({ tr, chain, commands }) => {
        if (!attributes.text) attributes.text = attributes.href.replace(/mailto:/, '').replace(/tel:/, '')

        if (attributes.href) {
          chain().insertContentAt(tr.selection, { type: this.name, attrs: attributes }).run()
        } else {
          commands.unsetLink(attributes)
        }
      },
      unsetLink: attributes => props => deleteNode({ attributes, props, name: this.name })
    }
  },

  addPasteRules () {
    return getPasteRules({
      type: this.type,
      expressions: ['link'],
      handler: ({ chain, input }) => chain.setLink({ href: input })
    })
  },

  addProseMirrorPlugins () {
    const plugins = []

    if (this.options.toggle) {
      plugins.push(handleClickNode({
        tag: 'a',
        type: this.type,
        toggle: this.options.toggle
      }))
    }

    return plugins
  }
})