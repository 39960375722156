<template>
  <div v-if="blocks.length">
    <procedure-blocks
      :blocks="blocks"
      index="tamaro"
    />
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { available_fields } from '@/configurations/financials/tamaro'
import Block from '@/classes/procedures/Block'
import { get as _get } from 'lodash'

const ProcedureBlocks = defineAsyncComponent(() => import('&/modules/procedures/layouts/ProcedureBlocks'))

const props = defineProps({ fields: { type: Array, default: () => [] } })

const blocks = computed(() => props.fields.filter(key => _get(available_fields, key)).map(key => new Block(available_fields[key])))
</script>