<template>
  <div>
    <header
      class="header"
      ref="header"
      v-if="section"
    >
      <div class="wrapper header__wrapper">
        <div class="header__nav">
          <div class="header__logo">
            <router-link
              :to="{ name: 'Home', params: { locale: $i18n.locale } }"
              v-show="client.logo.url"
              class="logo"
            >
              <img
                class="logo__img"
                :src="client.logo.url"
                :alt="client.name"
              >
            </router-link>
          </div>

          <div class="header__links">
            <nav
              v-if="navigation.length > 0"
            >
              <ul class="header__menu">
                <li
                  class="header__menu-item"
                  v-for="(item, index) in navigation"
                  :key="`menu_item_${index}`"
                >
                  <a
                    class="header__menu-link"
                    :class="{'header__menu-link--active': isVisited(item.link)}"
                    @click.prevent="setRouteNavigation(item.link); mobileMenuOpened = false"
                  >
                    {{ $i18n.translate(item.link.text) }}

                    <fa-icon
                      v-if="item.submenu.length > 0"
                      size="xs"
                      class="icon"
                      :icon="['fal', 'chevron-down']"
                    />
                  </a>

                  <ul
                    v-if="item.submenu.length > 0"
                    class="header__submenu"
                  >
                    <li
                      class="header__submenu-item"
                      v-for="(subitem, key) in item.submenu"
                      :key="`menu_1_item_${index}_${key}`"
                    >
                      <a
                        class="header__submenu-link"
                        :class="{'header__submenu-link--active': isVisited(subitem.link)}"
                        @click.prevent="setRouteNavigation(subitem.link); mobileMenuOpened = false"
                      >
                        {{ $i18n.translate(subitem.link.text) }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div class="header__actions">
          <div class="header__topbar">
            <button
              @click.prevent="openSessionAppModal('register')"
              v-if="!isAuthenticated"
            >
              <span v-html="$i18n.t('literal.login')" />
            </button>

            <router-link
              class="header__user d-flex align-items-center"
              :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
              v-if="isAuthenticated && user"
            >
              <user-profile-img
                size="2"
                :avatar="user.avatar"
              />

              <span class="d-block margin__l--6">{{ user.firstname }}</span>
            </router-link>
          </div>

          <nav
            v-if="actions.length > 0"
          >
            <ul class="header__cta">
              <li
                class="header__cta-item"
                v-for="(item, index) in actions"
                :key="`actions_item_${index}`"
              >
                <a
                  class="header__cta-link"
                  :class="{'header__cta-link--active': isVisited(item.link)}"
                  @click.prevent="setRouteNavigation(item.link); mobileMenuOpened = false"
                >
                  {{ $i18n.translate(item.link.text) }}
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <app-burger-menu
          class="header__burger d-md-none"
          :state="mobileMenuOpened"
          @toggle="toggleMobileMenu"
        />
      </div>

      <transition name="fade-mobile-menu">
        <section
          class="mobile"
          v-if="mobileMenuOpened"
        >
          <div class="wrapper mobile__wrapper">
            <ul class="mobile__sessions">
              <li
                class="mobile__sessions-item"
                v-if="!isAuthenticated"
              >
                <a
                  @click.prevent="openSessionAppModal('login')"
                  class="link__color--white link--naked pointer"
                >
                  <fa-icon
                    class="margin__r--6"
                    :icon="['fas', 'user']"
                    fixed-width
                  />
                  {{ $i18n.t('literal.login') }}
                </a>
              </li>

              <li
                class="mobile__sessions-item pointer d-flex align-items-center"
                v-if="isAuthenticated && user"
                @click.prevent="mobileMenuOpened = false"
              >
                <router-link
                  class="mobile__sessions-link"
                  :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
                >
                  <user-profile-img
                    class="margin__r--6"
                    :avatar="user.avatar"
                    size="2"
                  />
                  {{ user.firstname }}
                </router-link>
              </li>

              <li class="mobile__close">
                <button @click="mobileMenuOpened=false">
                  <fa-icon
                    :icon="['fal', 'times']"
                    fixed-width
                  />
                </button>
              </li>
            </ul>

            <hr class="separator separator__size--full border__color--ter margin__tb--12">

            <ul
              class="mobile__menu"
              v-if="navigation.length > 0"
            >
              <li
                class="mobile__menu-item"
                v-for="(item, index) in navigation"
                :key="`menu_mobile_item_${index}`"
              >
                <a
                  class="mobile__menu-link"
                  :class="{'mobile__menu-link--active': isVisited(item.link)}"
                  @click.prevent="setRouteNavigation(item.link); mobileMenuOpened = false"
                >
                  {{ $i18n.translate(item.link.text) }}
                </a>

                <ul
                  v-if="item.submenu.length > 0"
                  class="mobile__submenu"
                >
                  <li
                    class="mobile__submenu-item"
                    v-for="(subitem, key) in item.submenu"
                    :key="`menu_1_item_${index}_${key}`"
                  >
                    <a
                      class="mobile__submenu-link"
                      :class="{'mobile__submenu-link--active': isVisited(subitem.link)}"
                      @click.prevent="setRouteNavigation(subitem.link); mobileMenuOpened = false"
                    >
                      {{ $i18n.translate(subitem.link.text) }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>

            <ul
              class="mobile__actions"
              v-if="actions.length > 0"
            >
              <li
                class="mobile__actions-item"
                v-for="(item, index) in actions"
                :key="`actions_mobile_item_${index}`"
              >
                <a
                  class="mobile__actions-link"
                  @click.prevent="setRouteNavigation(item.link); mobileMenuOpened = false"
                >
                  {{ $i18n.translate(item.link.text) }}
                </a>
              </li>
            </ul>
          </div>
        </section>
      </transition>
    </header>

    <app-modal
      v-if="showSessionAppModal"
      size="sm"
      @close="showSessionAppModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="template"
          @authenticated="showSessionAppModal = false"
        />
      </template>
    </app-modal>
  </div>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import { get as _get } from 'lodash'
import { mapGetters } from 'vuex'
import PageRouteNavigation from '%/PageRouteNavigation'
import UserProfileImg from '&/atoms/UserProfileImg'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'

export default defineComponent({
  name: 'CustomHeaderToWalkAgain',
  components: {
    UserProfileImg,
    AppBurgerMenu,
    SessionForms: defineAsyncComponent(() => import('&/modules/sessions/SessionForms')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      showSessionAppModal: false,
      mobileMenuOpened: false,
      template: null
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    navigation () {
      return _get(this.section, 'attributes.navigation', [])
    },
    actions () {
      return _get(this.section, 'attributes.actions', [])
    }
  },
  methods: {
    openSessionAppModal (template) {
      this.template = template
      this.showSessionAppModal = true
    },
    toggleMobileMenu (value) {
      this.mobileMenuOpened = !value
    }
  }
})
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;

  @include mq(sm) {
    padding: 0 30px;
  }
}

.logo {
  display: block;
  position: relative;
  width: 100px;

  &__img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.header {
  position: relative;
  width: 100%;
  padding: 10px 20px;
  background: $white;

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__nav, &__cta {
    display: flex;
    align-items: center;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 24px;
  }

  &__topbar {
    margin-bottom: 6px;
    font-size: 15px;
    display: none;

    @include mq(md) {
      display: block;
    }
  }

  &__logo {
    margin-right: 50px;
  }

  &__menu {
    display: none;
    font-size: rem(14px);

    @include mq(md) {
      display: flex;
      align-items: center;
    }

    &-item {
      position: relative;

      &:not(:last-child) {
        margin-right: 12px;
      }

      .icon {
        margin-left: 6px;
        transition: all .3s;
      }

      &:hover {
        .header__submenu {
          opacity: 1;
          top: 100%;
          max-height: 600px;
        }

        .icon {
          transform: rotate(180deg);
        }
      }
    }

    &-link {
      position: relative;
      display: block;
      color: #4d4d4e;
      line-height: 34px;
      padding: 5px;
      transition: color .2s linear;

      &:hover, &--active {
        color: #1a1a1b;
      }
    }
  }

  &__submenu {
    position: absolute;
    left: 50%;
    overflow: hidden;
    transform: translate3d(-50%, 0, 0);
    max-height: 0;
    width: max-content;
    z-index: 90;
    transition: max-height .3s linear;

    &-link {
      position: relative;
      display: block;
      color: #4d4d4e;
      padding: 12px 15px;
      transition: color .2s linear;
      background-color: $white;
      z-index: 50;
      border-bottom: 1px solid transparent;

      &:hover, &--active {
        color: #1a1a1b;
      }
    }

    &-item:not(:last-child) .header__submenu-link {
      border-bottom: 1px solid $vdw;
    }
  }

  &__cta {
    display: none;

    @include mq(lg) {
      margin-left: 20px;
    }

    @include mq(md) {
      display: flex;
      align-items: center;
      font-size: 14px;
    }

    &-item {
      &:not(:last-child) {
        margin-right: 24px;
      }

      .header__cta-link {
        background-color: $color--primary;
        color: set-contrast($color--primary);
        transition: all .2s;
        text-transform: uppercase;
        font-weight: bold;
        font-family: $font-family--secondary;
        font-style: italic;

        &:hover {
          background: shade($color--primary, 8%, 45%);
        }
      }
    }

    &-link {
      display: block;
      color: $color--primary;
      padding: 11px 17px;
      border-radius: 4px;
      font-weight: normal;
      transition: all .2s;
    }
  }

  &__burger {
    color: #4c4c4d;
    line-height: 1;

    :deep() {
      .menu__line {
        background-color: #4c4c4d;
      }

      .menu__close::before, .menu__close::after {
        background-color: #4c4c4d;
      }
    }
  }
}

.flex {
  &--end {
    justify-content: flex-end;
  }

  &--between {
    justify-content: space-between;
  }
}

.mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #4c4c4d;
  z-index: 999;
  text-align: center;
  overflow: auto;padding-bottom: 40px;

  &__wrapper {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 12px;
  }

  &__close {
    text-align: right;
    font-size: 36px;
    color: #ffee00;
  }

  &__menu {
    font-size: 24px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px 0 24px;

    &-item {
      padding: 6px 0;
    }

    &-link {
      color: #ffee00;
      padding: 10px 0;
      display: inline-block;
      position: relative;
      line-height: 1;
      font-family: $font-family--secondary;

      &--active {
        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 2px;
          background: #ffee00;
          left: 0;
          bottom: 0;
        }
      }
    }
  }

  &__submenu {
    margin-top: 12px;

    &-item {
      font-size: 18px;
    }

    &-link {
      color: $white;
    }
  }

  &__actions {
    display: block;
    justify-content: center;
    align-items: center;
    font-size: 16px;

    &-item {
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      &:nth-child(even) {
        .mobile__actions-link {
          background-color: #ffee00;
          color: set-contrast(#ffee00);
        }
      }
    }

    &-link {
      display: block;
      color: #ffee00;
      border-radius: 4px;
      padding: 11px 17px;
      border: 2px solid #ffee00;
      font-family: $font-family--secondary;
      text-transform: uppercase;
      font-style: italic;
    }
  }

  &__sessions {
    color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-item {
      &:not(:last-child) {
        margin-right: $margin__base * 2;
      }
    }

    &-link {
      display: inherit;
      color: $white;
    }
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}

.fade-mobile-menu-enter-active, .fade-mobile-menu-leave-active {
  transition: opacity .5s;
}
.fade-mobile-menu-enter, .fade-mobile-menu-leave-to {
  opacity: 0;
}
</style>
