<template>
  <div>
    <header
      class="header"
      :class="{'header--fixed': mobileMenuOpened}"
      v-if="section"
    >
      <div class="header__container">
        <section class="header__cell">
          <router-link
            :to="{ name: 'Home', params: { locale: $i18n.locale } }"
            v-show="client.logo.url"
          >
            <img
              class="logo__img"
              :src="client.logo.url"
              :alt="client.name"
            >
          </router-link>

          <ul class="header__menu">
            <li
              class="header__menu-item"
              v-for="({ link }, index) in links"
              :key="`menu_item_${index}`"
            >
              <button
                class="header__link link__color--dg link--naked"
                :class="{'header__link--active': isVisited(link)}"
                @click.prevent="setRouteNavigation(link)"
              >
                {{ $i18n.translate(link.text) }}
              </button>
            </li>
          </ul>
        </section>

        <section class="header__cell">
          <ul class="header__actions">
            <li
              class="header__actions-item"
              v-if="!isAuthenticated"
            >
              <button
                @click.prevent="openSessionAppModal('login')"
                class="header__action header__action--arrow btn"
              >
                <i class="icon" />
                {{ $i18n.translate(login.text) }}
              </button>
            </li>

            <li v-if="isAuthenticated && user">
              <router-link
                class="header__sessions-item"
                :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
              >
                <user-profile-img :avatar="user.avatar" />

                <div class="margin__l--6">
                  <p>{{ user.firstname }}</p>
                  <p class="link__see">
                    {{ $i18n.t('literal.see_profile') }}
                  </p>
                </div>
              </router-link>
            </li>
          </ul>

          <app-burger-menu
            class="header__burger"
            :state="mobileMenuOpened"
            @toggle="toggleMobileMenu"
          />
        </section>
      </div>

      <transition name="fade-mobile-menu">
        <section
          class="header__cell mobile__menu"
          v-if="section && mobileMenuOpened"
        >
          <ul class="mobile__sessions">
            <li
              class="mobile__sessions-item header__sessions-item"
              v-if="!isAuthenticated"
            >
              <a
                @click.prevent="openSessionAppModal('login')"
                class="link__color--black link--naked pointer"
              >
                <fa-icon
                  class="margin__r--6 color--prim"
                  :icon="['fal', 'sign-in']"
                  fixed-width
                />
                {{ $i18n.t('literal.login') }}
              </a>
            </li>

            <li
              class="mobile__sessions-item header__sessions-item"
              v-if="!isAuthenticated"
            >
              <a
                @click.prevent="openSessionAppModal('register')"
                class="link__color--black link--naked pointer"
              >
                <fa-icon
                  class="margin__r--6 color--prim"
                  :icon="['fal', 'user-plus']"
                  fixed-width
                />
                {{ $i18n.t('literal.register') }}
              </a>
            </li>

            <li
              v-if="isAuthenticated && user"
              @click.prevent="mobileMenuOpened = false"
            >
              <router-link
                class="mobile__sessions-item"
                :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
              >
                <user-profile-img
                  :avatar="user.avatar"
                  size="4"
                />

                <div class="margin__l--6">
                  <p>{{ user.firstname }}</p>
                  <p class="link__see">
                    {{ $i18n.t('literal.see_profile') }}
                    <fa-icon
                      class="margin__l--6"
                      size="xs"
                      :icon="['fal', 'chevron-right']"
                    />
                  </p>
                </div>
              </router-link>
            </li>
          </ul>

          <ul class="mobile__list">
            <li
              class="mobile__list-item"
              v-for="({ link }, index) in links"
              :key="`mobile_menu_item_${index}`"
            >
              <a
                class="mobile__list-link link__color--dg link--naked"
                :class="{'mobile__link--active': isVisited(link)}"
                @click.prevent="setRouteNavigation(link); mobileMenuOpened = false"
              >
                {{ $i18n.translate(link.text) }}
              </a>
            </li>
          </ul>
        </section>
      </transition>
    </header>

    <app-modal
      v-if="showSessionAppModal"
      size="sm"
      @close="showSessionAppModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="template"
          @authenticated="showSessionAppModal = false"
        />
      </template>
    </app-modal>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'
import UserProfileImg from '&/atoms/UserProfileImg'
import PageRouteNavigation from '%/PageRouteNavigation'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'

export default defineComponent({
  name: 'CustomHeaderKajaktrail',
  components: {
    UserProfileImg,
    AppBurgerMenu,
    SessionForms: defineAsyncComponent(() => import('&/modules/sessions/SessionForms')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      showSessionAppModal: false,
      mobileMenuOpened: false,
      template: null
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    attributes () {
      return _get(this.section, 'attributes', {})
    },
    navigation () {
      return _get(this.attributes, 'navigation', [])
    },
    login () {
      return _get(this.attributes, 'login', {})
    },
    links () {
      return this.navigation.filter(({ link }) => link.text[this.$i18n.locale])
    }
  },
  methods: {
    openSessionAppModal (template) {
      this.template = template
      this.showSessionAppModal = true
    },
    toggleMobileMenu (value) {
      this.mobileMenuOpened = !value
    }
  }
})
</script>

<style lang="scss" scoped>
$breakpoint: 'md';

.header {
  position: relative;
  padding: calc($padding__base / 2) $padding__base;
  background-color: rgba($white, .95);
  z-index: 90;
  background-color: $white;

  @include mq(sm) {
    padding: $padding__base;
  }

  @include mq(md) {
    padding: 28px 48px;
  }

  &__cell, &__menu, &__actions, &__sessions, &__language {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__cell--grow {
    flex-grow: 1;
  }

  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background-color: $white;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__menu {
    margin-left: 45px;
    display: none;

    @include mq($breakpoint) {
      display: flex;
    }

    &-item {
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }

  &__link {
    position: relative;
    border: 1px solid transparent;
    font-weight: bold;
    padding: 5px;

    &--active, &:hover {
      color: $color--primary;
    }
  }

  &__actions {
    display: none;

    @include mq($breakpoint) {
      display: flex;
    }

    &-item {
      white-space: nowrap;

      &:not(:last-child) {
        margin-right: $margin__base;
      }
    }
  }

  &__action {
    display: block;
    font-size: 14px;
    padding: 14px 30px;
    border-radius: 4px;
    background-color: $color--primary;
    color: set-contrast($color--primary);
    transition: all .3s;

    &:hover {
      background-color: shade($color--primary, 30%, 25%);
    }

    &--arrow {
      & .icon {
        position: relative;
        top: 1px;
        display: inline-block;
        width: 9px;
        height: 12px;
        margin-right: 12px;
        transition: background .2s linear;
        background: image-path('kajaktrail/arrow_white.svg');
        background-size: auto 100%;
      }
    }
  }

  &__sessions {
    margin-left: $margin__base * 2;
    display: none;

    @include mq($breakpoint) {
      display: block;
    }

    &-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &:hover .link__see {
        color: shade($color--primary, 10%);
      }

      &:focus .link__see {
        border-bottom: 1px solid $color--primary;
      }

      .link__see {
        font-size: em(14px);
        line-height: 1.2;
        color: $color--primary;
        transition: all .3s;
      }
    }
  }

  &__language {
    margin-left: $margin__base;
    display: none;

    @include mq($breakpoint) {
      display: block;
    }
  }

  &__burger {
    display: inherit;

    @include mq($breakpoint) {
      display: none;
    }
  }

  .locale-switcher {
    box-shadow: none;
  }
}

.logo {
  &__img {
    max-width: 120px;
    transition: all .5s;
    display: block;

    &--hidden {
      visibility: hidden;
      opacity: 0%;
    }
  }
}

.mobile {
  &__nav {
    width: 100vw;
    height: 100vh;
    background: $white;
    position: fixed;
    z-index: 10;
    overflow: hidden;
  }

  &__link {
    &--active {
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: $color--primary;
      }
    }
  }

  &__menu {
    margin-top: $margin--base;
    border-top: 1px solid $dw;
    display: block;

    @include mq($breakpoint) {
      display: none;
    }
  }

  &__list {
    margin-top: $margin--base;

    &-link {
      display: inline-block;
      padding: $padding--base;
      color: $dg;
      font-weight: bold;
      border-radius: 6px;
    }

    &-language {
      padding-left: 0;
    }
  }

  &__actions {
    margin-top: $margin--base;
    display: block;

    &-item {
      text-align: center;
    }

    &-link {
      display: inline-block;
      padding: $padding--base $padding--base * 2;
    }
  }

  &__search {
    display: flex;
  }

  &__sessions {
    padding: $padding--base;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $dw;
    margin-top: calc($margin--base / 2);

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.fade-mobile-menu-enter-active {
  animation: fadeTranslationY .5s ease-in-out;
}

.fade-mobile-menu-leave-active {
  animation: fadeTranslationY .5s ease-in-out reverse;
}

@keyframes fadeTranslationY {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 100%;
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}
</style>
