export default [
  {
    name: 'custom_footer_allezchantez',
    path: 'modules/pages/allezchantez/FooterAllezchantez'
  },
  {
    name: 'custom_header_allezchantez',
    path: 'modules/pages/allezchantez/HeaderAllezchantez'
  },
  {
    name: 'custom_homepage_allezchantez',
    path: 'modules/pages/allezchantez/HomepageAllezchantez'
  }
]