<template>
  <div
    class="section"
    :style="sectionStyle"
  >
    <div class="container">
      <div
        class="editor editor__content editor__page margin__b--24"
        v-if="$i18n.translate(attributes.text)"
        v-html="$i18n.translate(attributes.text)"
      />

      <section
        class="counter"
        v-if="attributes.metrics.length > 0 && stats"
      >
        <ul class="counter__ul">
          <li
            class="counter__li"
            v-for="(metric, index) in attributes.metrics"
            :key="`${metric.type}_${index}`"
          >
            <div class="counter__stats">
              <app-date
                v-if="metric.type === 'date' && metric.date"
                :timestamp="metric.date"
                filter="differenceInCalendarDays"
              />

              <app-money
                v-else-if="metric.type === 'amount_collected'"
                :amount="stats[metric.type] || 0"
              />

              <p v-else-if="metric.type === 'sporting_statistics'">
                {{ getSportingStatistic(metric.sporting_statistics) }}
              </p>

              <p v-else>
                {{ stats[metric.type] || 0 }}
              </p>
            </div>

            <p class="counter__label">
              {{ $i18n.translate(metric.text) }}
            </p>
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
import { mapActions } from 'vuex'
import { useSectionStyle } from '@/composables/modules/pages'
import { get as _get } from 'lodash'

export default defineComponent({
  name: 'SectionMetrics',
  components: {
    AppDate: defineAsyncComponent(() => import('@/components/atoms/AppDate')),
    AppMoney: defineAsyncComponent(() => import('@/components/atoms/AppMoney'))
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  setup: props => ({ ...useSectionStyle(props) }),
  data () {
    return {
      query: {},
      stats: {}
    }
  },
  created () {
    this.setQueries()
    this.fetchClientStatistics({ query: this.query })
      .then(stats => {
        this.stats = stats
      })
  },
  computed: {
    attributes () {
      return this.section.attributes || {}
    },
    queries () {
      return this.attributes.query || {}
    },
    style () {
      return this.section.style
    },
    sportingStatistics () {
      return _get(this.stats, 'sporting_statistics', {})
    }
  },
  methods: {
    ...mapActions({
      fetchClientStatistics: 'client/fetchClientStatistics'
    }),
    setQueries () {
      if (this.queries.campaign_id) {
        this.query.campaign_id = this.queries.campaign_id
      }

      if (this.queries.procedure_id) {
        this.query.procedure_id = this.queries.procedure_id
      }

      if (this.queries.benefiting_id) {
        this.query.benefiting_id = this.queries.benefiting_id
      }
    },
    getSportingStatistic (key) {
      const value = _get(this.sportingStatistics, key, 0)
      let distance = value / 1000

      if (key === 'total_distance') return (distance % 1 !== 0) ? distance.toFixed(2) : distance
      if (key === 'total_time') return Math.round(value / 3600)

      return value
    }
  }
})
</script>

<style lang="scss" scoped>
.section {
  padding: $padding__base 0;

  @include mq(md) {
    padding: $padding__base * 3 0;
  }
}

.counter {
  &__ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: $margin--base * 2;
    justify-items: center;
    align-items: baseline;

    @include mq(md) {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }

  &__stats {
    font-size: rem(28px);
    font-weight: bold;
    text-align: center;

    @include mq(md) {
      font-size: rem(40px);
    }
  }

  &__label {
    font-size: rem(16px);
    text-align: center;
    margin-top: calc($margin--base / 2);

    @include mq(md) {
      font-size: rem(20px);
    }
  }
}
</style>
