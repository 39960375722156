<template>
  <footer
    class="footer"
    v-if="section"
  >
    <div class="container">
      <div class="footer__wrapper">
        <section
          v-if="$i18n.translate(column1.logo)"
          class="footer__column"
        >
          <img
            class="footer__logo"
            :src="$i18n.translate(column1.logo)"
            alt="Logo To Walk Again"
          >
        </section>

        <section
          v-if="column2.links.length"
          class="footer__column"
        >
          <h2
            v-if="$i18n.translate(column2.title)"
            class="footer__title"
          >
            {{ $i18n.translate(column2.title) }}
          </h2>

          <ul class="footer__ul">
            <li
              class="footer__li"
              v-for="({ link }, index) in column2.links"
              :key="'footer_column1_item_' + index"
            >
              <a
                class="footer__link"
                v-if="link && link.show"
                @click.prevent="setRouteNavigation(link)"
              >
                {{ $i18n.translate(link.text) }}
              </a>
            </li>
          </ul>

          <ul
            class="networks"
            v-if="networks.length > 0"
          >
            <li
              class="networks__el"
              v-for="(network, index) in networks"
              :key="'footer_menu_network_' + index"
            >
              <a
                class="link__color--prim link--naked"
                :href="$i18n.translate(network.url)"
                target="_blank"
              >
                <fa-icon
                  v-if="network.icon"
                  size="lg"
                  :icon="network.icon"
                />
              </a>
            </li>
          </ul>
        </section>

        <div
          v-if="$i18n.translate(column3.title) || contactInfos.length > 0"
          class="footer__column"
        >
          <h2
            v-if="$i18n.translate(column3.title)"
            class="footer__title"
          >
            {{ $i18n.translate(column3.title) }}
          </h2>

          <address
            class="footer__address"
            v-if="address.length"
          >
            <p
              v-for="(item, index) in address"
              :key="`address_line_${index}`"
            >
              {{ $i18n.translate(item.text) }}
            </p>
          </address>

          <ul>
            <li
              class="footer__li"
              v-for="(item, index) in contactInfos"
              :key="`contact_line_${index}`"
            >
              <a
                class="footer__link"
                :key="'footer_infos_line_' + index"
                :href="item.url"
                v-if="item.url"
                target="_blank"
              >
                {{ item.text }}
              </a>

              <p
                class="footer__text"
                v-else
              >
                {{ item.text }}
              </p>
            </li>
          </ul>
        </div>

        <section class="footer__column">
          <ul class="footer__ul">
            <li class="footer__li">
              <a
                class="footer__button footer__button--fill"
                v-if="column4.button_1 && column4.button_1.show"
                @click.prevent="setRouteNavigation(column4.button_1)"
              >
                {{ $i18n.translate(column4.button_1.text) }}
              </a>
            </li>

            <li class="footer__li">
              <a
                class="footer__button footer__button--border footer__button--small"
                v-if="column4.button_2 && column4.button_2.show"
                @click.prevent="setRouteNavigation(column4.button_2)"
              >
                {{ $i18n.translate(column4.button_2.text) }}
              </a>
            </li>
          </ul>
        </section>
      </div>
    </div>
  </footer>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'

export default defineComponent({
  name: 'CustomFooterToWalkAgain',
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({ client: 'client/client' }),
    column1 () {
      return _get(this.section, 'attributes.column_1', {})
    },
    column2 () {
      return _get(this.section, 'attributes.column_2', {})
    },
    column3 () {
      return _get(this.section, 'attributes.column_3', {})
    },
    address () {
      return _get(this.column3, 'address', [])
    },
    contactInfos () {
      return _get(this.column3, 'infos', [])
        .map(info => {
          const url = info.type === 'text' ? null : (info.type === 'phone_number' ? 'tel:' : 'mailto:') + this.$i18n.translate(info.text).replace(/ /g, '')

          return {
            text: this.$i18n.translate(info.text),
            url
          }
        })
        .filter(info => info.text)
    },
    column4 () {
      return _get(this.section, 'attributes.column_4', {})
    },
    networks () {
      const links = _get(this.client, 'platform.social_networks') || []
      return links.length > 0 ? links.filter(link => this.$i18n.translate(link.url)) : []
    }
  }
})
</script>

<style lang="scss" scoped>
.footer {
  background-color: #4d4d4e;
  padding: 36px 0;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: $margin--base * 2;
    text-align: center;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
      text-align: left;
    }

    @include mq(md) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__logo {
    max-width: 100px;
  }

  &__title {
    text-transform: uppercase;
    color: $white;
    font-weight: bold;
    font-size: rem(18px);
    margin-bottom: 10px;
    font-family: $font-family--secondary;
    font-style: italic;
  }

  &__li:not(:last-child) {
    margin-bottom: 10px;
  }

  &__address {
    margin-bottom: 10px;
  }

  &__link, &__text, &__address {
    color: $white;
  }

  &__link:hover {
    color: $color--primary;
  }

  &__button {
    padding: 12px 24px;
    text-transform: uppercase;
    font-size: 16px;
    border-radius: 3px;
    font-weight: bold;
    transition: all .3s;
    white-space: nowrap;
    display: block;
    text-align: center;
    font-style: italic;
    margin-bottom: 20px;
    font-family: $font-family--secondary;

    &--fill {
      color: #4d4d4e;
      background-color: $color--primary;

      &:hover {
        background: shade($color--primary, 3%, 47%);
      }
    }

    &--border {
      color: $color--primary;
      background: transparent;
      border: 2px solid $color--primary;

      &:hover {
        color: #4d4d4e;
        background-color: $color--primary;
      }
    }

    &--small {
      font-size: 12px;
    }
  }
}

.networks {
  display: flex;
  font-size: rem(14px);
  justify-content: center;
  margin: $margin__base * 2 0;

  @include mq(sm){
    justify-content: flex-start;
  }

  &__el {
    position: relative;
    overflow: hidden;

    &:not(:first-of-type){
      margin-left: $margin__base * 2;
    }
  }
}
</style>
