<template>
  <nav class="procedure__nav">
    <div class="nav__side nav__side--back">
      <p
        class="color--warning"
        v-if="is_loading"
      >
        <fa-icon
          class="margin__r--6"
          :icon="['fas', 'hourglass-half']"
        />

        <span v-if="!isMobileDevice">{{ t('literal.processing') }}</span>
      </p>

      <button
        type="button"
        class="link__color--black link--naked translate__x-6"
        v-else-if="showBackButton"
        @click.prevent="backRedirection()"
      >
        <fa-icon
          class="element margin__r--6"
          :icon="['fas', 'long-arrow-alt-left']"
        />

        {{ translate(backButton) }}
      </button>
    </div>

    <h1 class="bold nav__title">
      {{ translate(procedure.name) }}
    </h1>

    <div class="nav__side nav__side--lang">
      <language-switcher @language-updated="language => setCustomer({ language })" />
    </div>
  </nav>
</template>

<script setup>
import { inject } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useProcedureExecution, useProcedureStep, useProcedureNavigation } from '@/composables/modules/procedures'
import isMobile from 'is-mobile'

import LanguageSwitcher from '&/atoms/LanguageSwitcher'

const procedure = inject('procedure')
const procedure_execution = inject('procedure_execution')
const is_loading = inject('is_loading')

const { t, translate } = useI18n()
const { backButton } = useProcedureStep({ procedure })
const { setCustomer } = useProcedureExecution({ procedure, procedure_execution })
const { showBackButton, backRedirection } = useProcedureNavigation({ procedure, procedure_execution })

const isMobileDevice = isMobile({ tablet: true })
</script>

<style lang="scss" scoped>
.procedure {
  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 48px;
    padding: 12px 24px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    background-color: $white;

    @include mq(sm) {
      min-height: 62px;
    }
  }
}

.nav {
  &__side {
    display: flex;
    align-items: center;
    font-size: em(12px);
    color: $lg;
    flex: 1;

    &--back {
      justify-content: flex-start;
    }

    &--lang {
      justify-content: flex-end;
    }

    @include mq(md) {
      font-size: em(14px);
    }
  }

  &__title {
    font-size: em(14px);
    max-width: 60%;
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 4;

    @include mq(md) {
      max-width: 75%;
    }
  }
}
</style>