<template>
  <main class="home">
    <section
      class="home__banner"
      v-if="isVisible('banner')"
    >
      <div
        class="banner__content background"
        :style="{backgroundImage: `url(${translate(banner.content.background)})`}"
      >
        <h2 class="title banner__title">
          {{ translate(banner.content.title) }}
        </h2>

        <div
          class="editor editor__content banner__text"
          v-html="translate(banner.content.text)"
        />
      </div>

      <figure
        :style="{backgroundImage: `url(${translate(banner.media.image_src)})`}"
        class="banner__media background overlay"
        @click="show_youtube_modal = true"
      >
        <figcaption>{{ translate(banner.content.title) }}</figcaption>

        <fa-icon
          :icon="['fal', 'circle-play']"
          size="4x"
          class="banner__play"
        />
      </figure>

      <app-modal
        v-if="show_youtube_modal"
        size="lg"
        @close="show_youtube_modal = false"
      >
        <template #body>
          <app-video-embed :src="translate(banner.media.video_src)" />
        </template>
      </app-modal>
    </section>

    <section
      class="home__news news"
      v-if="isVisible('news')"
    >
      <div class="news__header">
        <h2 class="title news__title">
          {{ translate(news.title) }}
        </h2>

        <div
          class="editor editor__content text"
          v-html="translate(news.text)"
        />
      </div>

      <ul class="news__ul">
        <li class="news__li">
          <img
            class="news__image"
            v-if="translate(news.news_1.image)"
            :src="translate(news.news_1.image)"
          >

          <app-link
            class="news__article overlay background"
            :style="{backgroundImage: `url(${translate(news.news_1.background)})`}"
            :link="news.news_1.link"
          >
            <h3 class="title news__subtitle">
              {{ translate(news.news_1.title) }}
            </h3>

            <div
              class="editor editor__content news__subtext"
              v-html="translate(news.news_1.text)"
            />
          </app-link>
        </li>

        <li class="news__li">
          <app-link
            class="news__article overlay background"
            :style="{backgroundImage: `url(${translate(news.news_2.background)})`}"
            :link="news.news_2.link"
          >
            <h3 class="title news__subtitle">
              {{ translate(news.news_2.title) }}
            </h3>

            <div
              class="editor editor__content news__subtext"
              v-html="translate(news.news_2.text)"
            />
          </app-link>

          <img
            class="news__image"
            v-if="translate(news.news_2.image)"
            :src="translate(news.news_2.image)"
          >
        </li>

        <li class="news__li">
          <img
            class="news__image"
            v-if="translate(news.news_3.image)"
            :src="translate(news.news_3.image)"
          >

          <app-link
            class="news__article overlay background"
            :style="{backgroundImage: `url(${translate(news.news_3.background)})`}"
            :link="news.news_3.link"
          >
            <h3 class="title news__subtitle">
              {{ translate(news.news_3.title) }}
            </h3>

            <div
              class="editor editor__content news__subtext"
              v-html="translate(news.news_3.text)"
            />
          </app-link>
        </li>
      </ul>

      <div class="slider">
        <app-link
          class="slider__link"
          :link="news.sliderbar"
          v-slot="{ text }"
        >
          <ul class="slider__ul">
            <li
              class="slider__li"
              v-for="(slide, index) in slides"
              :key="`slide_${index}`"
            >
              <p class="slider__text">
                {{ text }}
              </p>

              <fa-icon
                :icon="['fal', 'arrow-right']"
                class="slider__icon"
              />
            </li>
          </ul>
        </app-link>
      </div>
    </section>

    <section
      class="home__news news"
      v-if="isVisible('events')"
    >
      <ul class="news__ul">
        <li class="news__li">
          <app-link
            class="news__article overlay background"
            :style="{backgroundImage: `url(${translate(events.event_1.background)})`}"
            :link="events.event_1.link"
          >
            <h3 class="title news__subtitle">
              {{ translate(events.event_1.title) }}
            </h3>

            <div
              class="editor editor__content news__subtext"
              v-html="translate(events.event_1.text)"
            />
          </app-link>

          <img
            class="news__image"
            v-if="translate(events.event_1.image)"
            :src="translate(events.event_1.image)"
          >
        </li>

        <li class="news__li">
          <img
            class="news__image"
            v-if="translate(events.event_2.image)"
            :src="translate(events.event_2.image)"
          >

          <app-link
            class="news__article overlay background"
            :style="{backgroundImage: `url(${translate(events.event_2.background)})`}"
            :link="events.event_2.link"
          >
            <h3 class="title news__subtitle">
              {{ translate(events.event_2.title) }}
            </h3>

            <div
              class="editor editor__content news__subtext"
              v-html="translate(events.event_2.text)"
            />
          </app-link>
        </li>

        <li class="news__li">
          <app-link
            class="news__article overlay background"
            :style="{backgroundImage: `url(${translate(events.event_3.background)})`}"
            :link="events.event_3.link"
          >
            <h3 class="title news__subtitle">
              {{ translate(events.event_3.title) }}
            </h3>

            <div
              class="editor editor__content news__subtext"
              v-html="translate(events.event_3.text)"
            />
          </app-link>

          <img
            class="news__image"
            v-if="translate(events.event_3.image)"
            :src="translate(events.event_3.image)"
          >
        </li>
      </ul>

      <div class="slider">
        <app-link
          class="slider__link"
          :link="events.sliderbar"
          v-slot="{ text }"
        >
          <ul class="slider__ul">
            <li
              class="slider__li"
              v-for="(slide, index) in slides"
              :key="`slide_${index}`"
            >
              <p class="slider__text">
                {{ text }}
              </p>

              <fa-icon
                :icon="['fal', 'arrow-right']"
                class="slider__icon"
              />
            </li>
          </ul>
        </app-link>
      </div>
    </section>

    <section
      class="home__project"
      v-if="isVisible('project') && hightlighted_project"
    >
      <div class="project__content">
        <h2 class="title project__title">
          {{ translate(project.title) }}
        </h2>

        <div
          class="editor editor__content text"
          v-html="translate(project.text)"
        />

        <div class="btn__container">
          <button
            class="btn project__button"
            type="button"
            :disabled="isProjectButtonDisabled"
            @click.prevent="goToProjectProcedure()"
          >
            <span v-if="isDonationNotStartedYet">{{ t('project_page.btn_donate_not_started_yet') }}</span>
            <span v-if="!isDonationNotStartedYet && !isDonationClosed">{{ translate(project.button) }}</span>
            <span v-if="isDonationClosed">{{ t('project_page.btn_donate_finished') }}</span>
          </button>
        </div>

        <div class="project__goal">
          <ul class="goal__stats">
            <li>
              <fa-icon
                class="margin__r--6"
                :icon="['fal', 'users']"
              />

              <span>{{ hightlighted_project.statistics.contributors_count }} {{ tc('plural.contributor', hightlighted_project.statistics.contributors_count).toLowerCase() }}</span>
            </li>
          </ul>

          <app-progress-bar
            v-if="hightlighted_project.goal_amount !== null"
            class="goal__progressbar"
            :value="hightlighted_project.statistics.amount_collected"
            :max-value="hightlighted_project.goal_amount"
          />

          <hr
            v-else
            class="separator separator__size--full color--dw"
          >

          <ul class="goal__stats">
            <li>
              <template v-if="donationProcedure.end_date">
                <fa-icon
                  class="margin__r--6"
                  size="lg"
                  :icon="['fal', 'clock']"
                />

                <app-date
                  filter="formatDistanceToNow"
                  v-if="!isProjectButtonDisabled"
                  :timestamp="donationProcedure.end_date"
                />

                <span v-else>
                  {{ t('literal.ended') }}
                </span>
              </template>
            </li>

            <li>
              <app-money
                v-if="hightlighted_project.goal_amount !== null"
                :amount="hightlighted_project.goal_amount"
              />
            </li>
          </ul>
        </div>
      </div>

      <figure
        :style="{backgroundImage: `url(${translate(project.image)})`}"
        class="project__media background"
      >
        <figcaption>{{ translate(project.title) }}</figcaption>
      </figure>
    </section>

    <section
      class="home__steps"
      v-if="isVisible('steps')"
    >
      <div class="steps__header">
        <h2 class="title steps__title">
          {{ translate(steps.title) }}
        </h2>

        <div
          class="editor editor__content text"
          v-html="translate(steps.text)"
        />
      </div>

      <ul class="steps__ul">
        <li
          class="steps__li step"
          v-for="(step, index) in steps.items"
          :key="`step_${index}`"
        >
          <h2 class="title step__title">
            {{ translate(step.title) }}
          </h2>

          <div
            class="editor editor__content text"
            v-html="translate(step.text)"
          />

          <div class="btn__container">
            <app-link
              :link="step.button"
              class="step__button btn"
            />
          </div>
        </li>
      </ul>
    </section>

    <section
      class="home__projects projects"
      v-if="isVisible('projects')"
    >
      <h2 class="title projects__title">
        {{ translate(projects.title) }}
      </h2>

      <ul class="projects__ul">
        <li
          class="projects__li"
          v-for="item in highlighted_projects.items"
          :key="item.id"
        >
          <project-card
            class="projects__card"
            :project="item"
          />
        </li>
      </ul>

      <div class="btn__container margin__t--48">
        <app-link
          :link="projects.button"
          class="projects__button btn"
        />
      </div>
    </section>
  </main>
</template>

<script setup>
import { defineAsyncComponent, ref, computed } from 'vue'
import { mapActions } from '@/store/map-state'
import { useI18n } from '@/vendors/i18n'
import { useRouter } from 'vue-router'
import { isPast, isFuture } from 'date-fns'
import useSection from '@/composables/modules/pages/useSection'

const AppVideoEmbed = defineAsyncComponent(() => import('&/atoms/AppVideoEmbed'))
const AppModal = defineAsyncComponent(() => import('&/organisms/AppModal'))
const AppProgressBar = defineAsyncComponent(() => import('&/atoms/AppProgressBar'))
const AppMoney = defineAsyncComponent(() => import('&/atoms/AppMoney'))
const AppDate = defineAsyncComponent(() => import('&/atoms/AppDate'))
const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const ProjectCard = defineAsyncComponent(() => import('&/organisms/ProjectCard'))

const props = defineProps({
  page: { type: Object, required: true },
  sections: { type: Object, required: true }
})

const { translate, tc, t } = useI18n()
const { push } = useRouter()
const { getSectionAttributes, isVisible } = useSection(props.page)

const { fetchProject, fetchProjects } = mapActions('projects')

const slides = ref(10)
const show_youtube_modal = ref(false)
const hightlighted_project = ref(null)
const highlighted_projects = ref([])
const banner = computed(() => getSectionAttributes('banner'))
const news = computed(() => getSectionAttributes('news'))
const events = computed(() => getSectionAttributes('events'))
const project = computed(() => getSectionAttributes('project'))
const steps = computed(() => getSectionAttributes('steps'))
const projects = computed(() => getSectionAttributes('projects'))
const donationProcedure = computed(() => Array.isArray(hightlighted_project.value.procedures) ? hightlighted_project.value.procedures.find(procedure => procedure.category === 'donation') : false)
const isDonationClosed = computed(() => !!donationProcedure.value.end_date && isPast(new Date(donationProcedure.value.end_date)))
const isDonationNotStartedYet = computed(() => isFuture(new Date(donationProcedure.value.start_date)) && !isDonationClosed.value)
const isProjectButtonDisabled = computed(() => isDonationClosed.value || isDonationNotStartedYet.value)

const goToProjectProcedure = () => push({ name: 'ClientProcedure', params: { procedureSlug: donationProcedure.value.slug } })

if (isVisible('projects')) fetchProjects({ query: projects.value.query }).then(projects => highlighted_projects.value = projects)
if (project.value.id?.length) fetchProject({ id: project.value.id[0] }).then(project => hightlighted_project.value = project)
</script>

<style lang="scss" scoped>
.title {
  font-size: rem(24px);
  margin-bottom: 16px;
  color: $black;
  font-weight: bold;

  @include mq(md) {
    font-size: rem(40px);
  }
}

.text {
  font-size: rem(16px);
  font-weight: bold;
  color: $black;

  @include mq(md) {
    font-size: rem(18px);
  }
}

:deep(.btn) {
  font-size: rem(18px);
  background-color: $black;
  color: $white;
  padding: 12px 24px;
  transition: all .3s;
  border: 2px solid transparent;
  font-weight: bold;
  border-radius: 0;

  &:hover {
    color: $black;
    background-color: $white;
    border-color: $black;
  }

  @include mq(md) {
    font-size: rem(24px);
  }
}

:deep(.overlay) {
  position: relative;

  & > * {
    z-index: 1;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(127, 80, 80, 0.0718705) 0%, #000000 101.44%);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
}

:deep(.background) {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  figcaption {
    position: absolute;
    color: transparent;
    top: 0;
  }
}

.home {
  &__banner {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__project {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    background-color: $color--primary;

    @include mq(md) {
      grid-template-columns: minmax(400px, 600px) 1fr;
    }

    @include mq(xl) {
      grid-template-columns: minmax(600px, 800px) 1fr;
    }
  }

  &__projects {
    background-color: $white;
    padding: 48px;

    @include mq(xl) {
      padding: 48px 120px;
    }

    .btn__container {
      justify-content: center;
    }
  }
}

.banner {
  &__content, &__media {
    padding: 48px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include mq(sm) {
      padding: 0 48px;
      min-height: 416px;
    }

    @include mq(xl) {
      padding: 48px 120px;
      min-height: 550px;
    }
  }

  &__play {
    transition: all .3s;
    color: $white;
  }

  &__media {
    cursor: pointer;

    &:hover .banner__play {
      font-size: 80px;
    }
  }

  &__text {
    max-width: 540px;
    color: $white;
  }

  &__title {
    color: $white;
  }
}

.news {
  &__header {
    background-color: $white;
    padding: 48px;

    @include mq(xl) {
      padding: 48px 120px;
    }
  }

  &__ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: stretch;

    @include mq(md) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__li {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__image {
    display: block;
    width: calc(100% + 2px);
    position: relative;
    left: -2px;
    order: 2;

    @include mq(md) {
      order: inherit;
    }
  }

  &:deep(.news__article) {
    padding: 48px;
    min-height: 390px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    transition: all .3s;
    overflow: hidden;
    order: 1;

    @include mq(md) {
      order: inherit;
    }

    @include mq(xl) {
      padding: 48px 120px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: inherit;
      transition: inherit;
      z-index: 0;
    }

    &:hover:after {
      transform: scale(1.2);
    }
  }

  &__subtitle {
    color: $white;
    position: relative;
    line-height: 1.2;
  }

  &__subtext {
    font-size: rem(12px);
    color: $white;
  }
}

.slider {
  &__ul {
    gap: 24px;
    justify-content: flex-end;
  }

  &__li {
    position: relative;
    animation: move 10s linear infinite;
  }

  &__ul, &__li {
    display: flex;
    align-items: center;
  }

  &:deep(.slider__link) {
    background-color: #FC00FF;
    color: $white;
    padding: 32px 0;
    display: block;
    transition: all .3s;

    &:hover {
      background-color: $black;
      color: #FC00FF;
    }
  }

  &__text {
    font-size: 32px;
    white-space: nowrap;
    line-height: 1;

    @include mq(sm) {
      font-size: 64px;
    }
  }

  &__icon {
    margin-left: 16px;
    font-size: 32px;

    @include mq(sm) {
      font-size: 64px;
    }
  }
}

.project {
  &__content {
    padding: 0 48px 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    order: 2;

    @include mq(md) {
      order: inherit;
      min-height: 630px;
      padding: 48px;
    }

    @include mq(xl) {
      padding: 48px 120px;
    }
  }

  &__media {
    margin: 48px;
    order: 1;
    @include aspect-ratio(3, 2);

    @include mq(md) {
      margin: 0;
      order: inherit;
    }
  }

  &__button {
    margin-top: 30px;
  }

  &__goal {
    margin-top: 40px;

    @include mq(md) {
      margin-top: 60px;
    }

    &:deep(.goal__progressbar) {
      height: 16px;
      border-radius: 0;

      &::-webkit-progress-bar {
        background-color: $white;
        border-radius: 0;
      }

      &::-moz-progress-bar {
        border-radius: 0;
        background-color: $color--secondary;
      }

      &::-webkit-progress-value {
        border-radius: 0;
        background-color: $color--secondary;
      }
    }
  }
}

.steps {
  &__header {
    background-color: $white;
    padding: 48px;

    @include mq(xl) {
      padding: 48px 120px;
    }
  }

  &__text {
    font-size: rem(18px);
    font-weight: bold;
  }

  &__ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: stretch;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__li {
    padding: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:nth-child(3n - 2) {
      background-color: $color--primary;
    }

    &:nth-child(3n) {
      background-color: $color--secondary;

      & .text, & .title {
        color: $white;
      }
    }

    @include mq(sm) {
      min-height: 518px;

      &:nth-child(3n - 2) {
        background-color: inherit;
      }

      &:nth-child(3n) {
        background-color: inherit;

        & .text, & .title {
          color: inherit;
        }
      }

      &:nth-child(4n - 3) {
        background-color: $color--primary;
      }

      &:nth-child(4n) {
        background-color: $color--secondary;

        & .text, & .title {
          color: $white;
        }
      }
    }

    @include mq(xl) {
      padding: 48px 120px;
    }

    & .text {
      max-width: 550px;
    }
  }
}

.step {
  &:deep(.step__button) {
    margin-top: 32px;

    @include mq(sm) {
      margin-top: 48px;
    }
  }
}

.projects {
  &__title {
    text-align: center;
  }

  &__ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
    align-items: stretch;
  }

  &__li {
    width: 100%;
    min-width: 300px;

    @include mq(sm) {
      width: 400px;
    }
  }

  & :deep(.projects__card) {
    .card__cover {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &.card {
      box-shadow: none;
    }

    .goal__current-amount {
      color: $color--secondary;
    }

    .goal__stats, .infos__campaign {
      color: $black;
      font-weight: bold;
    }

    .progressbar {
      &::-moz-progress-bar {
        background-color: $color--secondary;
      }

      &::-webkit-progress-value {
        background-color: $color--secondary;
      }
    }
  }
}

.goal {
  &__stats {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
  }
}

@keyframes move {
  to {
    transform: translateX(calc(100% + 24px));
  }
}
</style>