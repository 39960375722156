<template>
  <section class="card margin__t--18">
    <div class="card__main padding--24">
      <div class="title__container">
        <h2 class="title bold margin__r--12">
          {{ $i18n.t('literal.payconiq_bancontact_qr_code') }}
        </h2>

        <a
          class="btn btn__color--prim btn__size--small"
          download
          target="_blank"
          :href="payconiqUrl('XL')"
          v-if="payconiqPaymentProfileId"
        >
          <fa-icon
            class="margin__r--6"
            :icon="['fal', 'cloud-download']"
          />

          {{ $i18n.t('literal.download_qr_code') }}
        </a>
      </div>

      <p class="margin__t--12">
        {{ $i18n.t('project.payconiq_description') }}
      </p>

      <ol class="steps">
        <li class="steps__li">
          {{ $i18n.t('literal.let_them_open_their_banking_app') }}
        </li>

        <li class="steps__li">
          <div v-html="$i18n.t('literal.scan_this_qr_code_with_their_banking_app')" />
        </li>

        <li class="steps__li">
          {{ $i18n.t('literal.select_an_amount') }}
        </li>

        <li class="steps__li">
          {{ $i18n.t('literal.validate') }}
        </li>
      </ol>

      <a
        class="qrcode margin__t--24"
        download
        target="_blank"
        :href="payconiqUrl('XL')"
        v-if="payconiqPaymentProfileId"
      >
        <img
          class="qrcode__img"
          :src="payconiqUrl()"
        >
      </a>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { mapGetters } from '@/store/map-state'
import { get as _get } from 'lodash'

const { client } = mapGetters('client')

const props = defineProps({
  project: {
    required: true,
    type: Object
  }
})

const payconiqPaymentProfileId = _get(client.value, 'integrations.payconiq.payment_profile_id') || null
const procedure = computed(() => props.project.procedures.find(procedure => procedure.category === 'donation'))

const procedureId = computed(() => {
  const id = procedure.value.id.replace(/-/g, '')
  return id ? '?R=' + id : '?'
})

const payconiqDefaultAmount = computed(() => {
  const amount = _get(client, 'integrations.payconiq.default_amount')
  return amount && Number.isInteger(amount) ? '&A=' + amount : ''
})

const bankStatement = computed(() => {
  const statement = _get(procedure, 'value.payment_profile.bank_statement')
  return statement ? '&D=' + encodeURI(statement.substring(0, 35)) : ''
})

const payconiqUrl = (size = 'S') => {
  const c = encodeURIComponent('https://payconiq.com/t/1/' + payconiqPaymentProfileId + procedureId.value + payconiqDefaultAmount.value + bankStatement.value)
  return `https://portal.payconiq.com/qrcode?s=${size}&f=PNG&c=` + c
}
</script>

<style lang="scss" scoped>
.title {
  font-size: em(20px);

  &__container {
    text-align: center;
    display: block;

    @include mq(sm) {
      display: flex;
      justify-content: space-between;
      text-align: left;
    }

    .btn {
      margin: $margin__base 0;
      display: inline-block;

       @include mq(sm) {
         margin: 0;
       }
    }
  }
}

.steps {
  counter-reset: steps;

  &__li {
    position: relative;
    counter-increment: steps;
    padding-left: $padding__base * 3;

    &::before {
      content: counter(steps) ". ";
      position: absolute;
      left: 0;
      top: 0;
      padding: 0 $padding__base;
    }
  }
}

.qrcode {
  display: block;
}
</style>
