<template>
  <main class="home">
    <section class="banner">
      <div class="wrapper wrapper--banner">
        <div
          class="editor editor__content"
          v-if="$i18n.translate(banner.title)"
          v-html="$i18n.translate(banner.title)"
        />

        <button
          class="banner__button bold btn btn__size--classic margin__t--18"
          v-if="banner.button && banner.button.show && $i18n.translate(banner.button.text)"
          @click.prevent="setRouteNavigation(banner.button)"
        >
          {{ $i18n.translate(banner.button.text) }}
        </button>

        <ul
          class="banner__stats"
          v-if="metrics.length > 0 && statistics"
        >
          <li
            class="banner__stats-item"
            v-for="(metric, i) in metrics"
            :key="`metric_item_${i}`"
          >
            <p>
              <strong class="bold">
                <app-date
                  v-if="metric.type === 'date' && metric.date"
                  :timestamp="metric.date"
                  filter="differenceInCalendarDays"
                />

                <app-money
                  v-else-if="metric.type === 'amount_collected'"
                  :amount="statistics[metric.type]"
                />

                <template v-else-if="metric.type === 'sporting_statistics'">
                  {{ getSportingStatistic(metric.sporting_statistics) }}
                </template>

                <template v-else>
                  {{ statistics[metric.type] }}
                </template>
              </strong>

              <span class="d-block">{{ $i18n.translate(metric.text) }}</span>
            </p>
          </li>
        </ul>
      </div>

      <!-- <ul
        class="banner__bullets"
        v-if="slides.length > 1"
      >
        <li
          class="banner__bullets-item"
          v-for="(slide, i) in slides"
          :class="{ 'is-active': i == index }"
          :ref="`bullet`"
          :key="`slide_${i}`"
          @click="goTo(i), setSliderInterval()"
        />
      </ul> -->

      <div
        class="slider"
        v-if="slides.length > 0"
        ref="slides"
      >
        <div
          class="slider__slides"
          :class="{'is-animated': has_transition}"
          :style="sliderStyle"
        >
          <figure
            class="slider__slide"
            v-for="(slide, i) in clonedSlides"
            :key="`slider_slide_${i}`"
            :style="{ backgroundImage: `url(${$i18n.translate(slide.image)})`}"
          />
        </div>
      </div>

      <div class="slider__buttons">
        <button
          class="slider__buttons--left"
          @click="goToPrev(), setSliderInterval()"
        >
          <fa-icon
            class="icon"
            :icon="['fas', 'chevron-left']"
          />
        </button>

        <button
          class="slider__buttons--right"
          @click="goToNext(), setSliderInterval()"
        >
          <fa-icon
            class="icon"
            :icon="['fas', 'chevron-right']"
          />
        </button>
      </div>
    </section>

    <section class="navigation">
      <ul
        v-if="navigation.length > 0"
        class="navigation__grid"
      >
        <li
          class="navigation__grid-item"
          v-for="(link, i) in navigation"
          :key="`menu_item_${i}`"
        >
          <a
            class="navigation__grid-link"
            @click.prevent="setRouteNavigation(link.link)"
          >
            <figure class="navigation--img-height">
              <img
                class="navigation--img"
                v-if="$i18n.translate(link.image)"
                :src="$i18n.translate(link.image)"
              >
            </figure>

            <span class="navigation--link bold">
              {{ $i18n.translate(link.link.text) }}
            </span>

            <fa-icon
              class="navigation--icon"
              :icon="['fal', 'chevron-down']"
            />
          </a>
        </li>
      </ul>
    </section>

    <section class="campaigns">
      <div class="wrapper">
        <div
          class="editor editor__content campaigns__title"
          v-if="$i18n.translate(campaigns.title)"
          v-html="$i18n.translate(campaigns.title)"
        />

        <div
          class="campaigns__description editor editor__content"
          v-if="$i18n.translate(campaigns.description)"
          v-html="$i18n.translate(campaigns.description)"
        />

        <section class="campaigns__grid">
          <template v-if="campaigns_list.length > 0">
            <div
              v-for="campaign in campaigns_list"
              :key="campaign.id"
            >
              <div
                class="campaigns__grid-item"
                :style="{ backgroundImage: `url(${ campaign.image.url })` }"
              >
                <a
                  class="campaigns__grid-item--link"
                  @click.prevent="goToCampaign(campaign)"
                >
                  <h3
                    class="campaigns__grid-item--title bold"
                  >
                    {{ $i18n.translate(campaign.name) }}
                  </h3>

                  <div class="campaigns__grid-item--arrow">
                    <span class="campaigns__grid-item--more">
                      {{ $i18n.t('literal.show_more') }}
                    </span>

                    <fa-icon
                      class="icon"
                      :icon="['fal', 'chevron-right']"
                    />
                  </div>
                </a>
              </div>
            </div>

            <a
              class="campaigns__link"
              v-if="campaigns.image && $i18n.translate(campaigns.email)"
              :href="`mailto:${$i18n.translate(campaigns.email)}`"
            >
              <img
                class="campaigns__item"
                :src="$i18n.translate(campaigns.image)"
              >
            </a>
          </template>
        </section>
      </div>
    </section>

    <section class="contact">
      <div class="wrapper">
        <div class="contact__title">
          <div
            class="editor editor__content"
            v-if="$i18n.translate(contact.title)"
            v-html="$i18n.translate(contact.title)"
          />
        </div>

        <div class="contact__grid">
          <div class="contact__grid-item">
            <div
              class="contact__description bold"
              v-if="$i18n.translate(contact.description)"
              v-html="$i18n.translate(contact.description)"
            />

            <address
              class="contact__address"
              v-if="contact.address.length > 0"
            >
              <span
                v-for="(item, i) in contact.address"
                :key="`line_${i}`"
              >
                {{ $i18n.translate(item.line) }} <br>
              </span>
            </address>
          </div>

          <div class="contact__grid-item">
            <ul
              class="contact__bank"
              v-if="contact.bank.length > 0"
            >
              <li
                v-for="(item, i) in contact.bank"
                :key="`line_${i}`"
              >
                {{ $i18n.translate(item.line) }} <br>
              </li>
            </ul>

            <small
              class="contact__details"
              v-if="$i18n.translate(contact.details)"
            >
              {{ $i18n.translate(contact.details) }}
            </small>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { defineComponent } from 'vue'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'
import { mapGetters, mapActions } from 'vuex'
import AppMoney from '&/atoms/AppMoney'

export default defineComponent({
  name: 'CustomHomepageJessa',
  components: {
    AppMoney
  },
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      index: 0,
      interval: null,
      is_animated: false,
      loader: {
        active: false
      },
      campaigns_list: [],
      query: {},
      has_transition: true,
      left: '0'
    }
  },
  created () {
    this.fetchClientStatistics()

    this.query.campaign_id = this.campaignsIds

    if (this.campaignsIds.length) {
      this.loader.active = true
      this.fetchCampaigns({ query: this.query })
        .then(data => this.campaigns_list = data.items)
        .finally(() => this.loader.active = false)
    }
  },
  mounted () {
    this.setSliderInterval()
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      statistics: 'client/statistics'
    }),
    attributes () {
      return _get(this.section, 'attributes', {})
    },
    banner () {
      return _get(this.attributes, 'banner', [])
    },
    metrics () {
      return _get(this.banner, 'metrics', [])
    },
    slides () {
      return _get(this.banner, 'slider', [])
    },
    sliderStyle () {
      return `width:${this.clonedSlides.length * 100}vw;transform:translateX(-200vw);left:${this.left}`
    },
    clonedSlides () {
      if (this.slides.length <= 1) return this.slides

      const slides = [...this.slides]

      for (let i = 1; i <= 2; i++) {
        slides.unshift(this.slides[this.slides.length - i])
      }

      for (let i = 0; i < 2; i++) {
        slides.push(this.slides[i])
      }

      return slides
    },
    navigation () {
      return _get(this.banner, 'navigation', [])
    },
    campaigns () {
      return _get(this.attributes, 'campaigns', [])
    },
    campaignsIds () {
      return _get(this.section, 'attributes.campaigns.ids', [])
    },
    contact () {
      return _get(this.attributes, 'contact', [])
    }
  },
  methods: {
    ...mapActions({
      fetchClientStatistics: 'client/fetchClientStatistics',
      fetchCampaigns: 'campaigns/fetchCampaigns'
    }),
    setSliderInterval () {
      clearInterval(this.interval)

      this.interval = setInterval(() => this.goToNext(), 7000)
    },
    goTo (i) {
      this.is_animated = true

      return new Promise(resolve => {
        this.index = i
        this.left = (-this.index * 100) + 'vw'

        setTimeout(() => {
          this.is_animated = false
          resolve()
        }, 100)
      })
    },
    goToNext () {
      if (this.is_animated) return

      if (this.index + 1 > this.slides.length - 1) {
        this.has_transition = false
        this.goTo(-1).then(() => {
          this.has_transition = true

          this.goTo(this.index + 1)
        })
      } else {
        this.goTo(this.index + 1)
      }
    },
    goToPrev () {
      if (this.is_animated) return

      if (this.index - 1 < 0) {
        this.has_transition = false

        this.goTo(this.slides.length)
          .then(() => {
            this.has_transition = true

            this.goTo(this.index - 1)
          })
      } else {
        this.goTo(this.index - 1)
      }
    },
    goToCampaign (campaign) {
      const procedureSlug = Array.isArray(campaign.procedures) ? campaign.procedures.find(procedure => procedure.category === 'create-project').slug : null

      if (_get(campaign, `cta.url[${this.$i18n.locale}]`)) {
        window.open(campaign.cta.url[this.$i18n.locale], '_blank')
      } else {
        this.$router.push({ name: 'ClientProcedure', params: { procedureSlug } }).catch()
      }
    }
  },
  unmounted () {
    clearInterval(this.interval)
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #74c9b8;
$color--secondary: #6bacb5;
$color--third: #00bdd0;
$color--fourth: #f4898e;
$light-grey: #eff2f6;

.wrapper {
  position: relative;
  max-width: 340px;
  margin: 0 auto;

  @include mq(sm) {
    max-width: 640px;
    margin: 0 auto;
  }

  @include mq(lg) {
    max-width: 1140px;
    margin: 0 auto;
  }
}

.banner {
  position: relative;
  color: $white;
  text-align: center;
  padding: $padding__base * 8 $padding__base * 2;
  text-shadow: 0px 1px 5px rgba(0,0,0,0.75);

  @include mq(sm) {
    padding: $padding__base * 12 $padding__base * 5;
  }

  @include mq(lg) {
    padding: $padding__base * 18 $padding__base * 5;
  }

  &__actions {
    position: relative;

    @include mq(sm) {
      max-width: 340px;
      margin: 0 auto;
    }
  }

  &__button {
    background: $color--primary;
    margin-top: $margin__base * 2;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: rem(12px);

    &:hover {
      background: darken($color--primary, 10%);
    }

    &--icon {
      position: absolute;
      top: 50%;
      width: 35px;
      height: 35px;
      background: #ffffff33;
    }
  }

  &__stats {
    &::before {
      content: '';
      display: block;
      width: 100px;
      height: 3px;
      background: $white;
      margin: $margin__base * 3 auto;
      font-size: 0;
    }

    &-item {
      font-size: rem(20px);

      @include mq(md) {
        font-size: rem(32px);
      }
    }
  }

  &__bullets {
    display: flex;
    justify-content: space-around;
    margin: $margin__base * 2 auto $margin__base;
    width: 50px;
    cursor: pointer;

    &-item {
      display: block;
      padding: 5px;
      margin: 0 calc($margin__base / 2);
      background: transparent;
      border: 2px solid $white;
      opacity: .7;
    }
  }
}

.is-active {
  background: $white;
}

.slider {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: -10;
  height: 100%;
  width: 100%;

  &__slides {
    position: relative;
    display: flex;
    height: 100%;
    transform: translateX(-200vw);
    left: 0;

    &.is-animated {
      transition: all .6s ease-in-out;
    }
  }

  &__slide {
    width: 100vw;
    background-color: #FAF7F8;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  &__buttons {
    position: absolute;
    display: flex;
    top: 50%;
    left: 0;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;

    &--left {
      margin-left: $margin__base * 4;
    }

    &--right {
      margin-right: $margin__base * 4;
    }

    &--left, &--right {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      background: rgba(#B0ACA4, 0.28);
      transition: all ease-in-out .3s;

      &:hover {
        background: $white;
        color: $color--primary;
      }
    }
  }
}

.navigation {
  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;

    &-item {
      &:nth-child(4n) {
        background-color: #00BDD0;
      }

      &:nth-child(4n + 1) {
        background: #F88A90;
      }

      &:nth-child(4n + 2) {
        background: #6BACB5;
      }

      &:nth-child(4n + 3) {
        background: #52CCB8;
      }

      &:hover {
        .navigation--icon {
          transform: translateY(7px);
        }
      }
    }

    &-link {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: $padding__base 0;
      color: $white;
      font-size: rem(14px);

      @include mq(sm) {
        font-size: rem(16px);
      }
    }

    @include mq(sm) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &--img, &--img-height {
    height: 45px;
    width: auto;
  }

  &--link {
    color: $white;
    margin: calc($margin__base / 2) 0;
  }

  &--icon {
    transition: all ease-in .2s;
    margin: 0 auto;
  }
}

.campaigns {
  padding: $padding__base * 4 $padding__base $padding__base * 8;
  background: #EEF2F7;

  &__description {
    margin-top: $margin__base * 2;
    color: #6D778C;

    @include mq(md) {
      max-width: 680px;
    }
  }

  &__grid {
    display: grid;
    margin-top: $margin__base * 3;

    @include mq(sm) {
      margin-top: $margin__base * 6;
    }

    &-item {
      height: 300px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      &--link {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255,0.5);

        @include mq(md) {
          transition: all ease-in-out .3s;

          &:hover {
            background: rgba(255,255,255,0.8);

            .campaigns__grid-item {
              &--more {
                transform: translateX(0);
                opacity: 1;
              }

              &--arrow {
                width: 100%;
              }
            }
          }
        }
      }

      &--title {
        text-align: center;
        font-size: rem(18px);
        transition: all ease-in-out .3s;
        font-family: $font-family--secondary;
      }

      &--more {
        overflow: hidden;
        transition: all ease-in-out .3s;
        transform: translateX(100%);
        opacity: 0;
        align-self: center;
        padding: 0 $padding__base;
      }

      &--arrow {
        position: absolute;
        padding: 0 $padding__base;
        bottom: 0;
        right: 0;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background: $color--secondary;
        color: $white;
        transition: all ease-in-out .3s;
      }
    }

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(md) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__link {
    width: fit-content;
    padding: $padding__base * 2;
    margin: 0 auto;
    align-self: center;
  }
}

.contact {
  position: relative;
  padding: $padding__base * 4 $padding__base;
  background: #F4F7FA;

  &__title {
    margin-bottom: $margin__base * 2;
  }

  &__bank {
    margin-bottom: $margin__base * 2;
  }

  &__details {
    font-size: rem(12px);

    @include mq(sm) {
      font-size: rem(14px);
    }
  }

  @include mq(md) {
    &__grid {
      display: flex;
      justify-content: space-between;

      &-item {
        max-width: 380px;
      }
    }
  }

  @include mq(lg) {
    &__grid {
      justify-content: flex-start;

      &-item {
        &:nth-child(1) {
          width: 40%;
          margin-right: $margin__base * 8;
        }
      }
    }
  }
}


:deep() {
  .editor__content {
    & h1 {
      font-size: rem(36px);
      line-height: 1;
      margin: 0 auto;
      margin-bottom: $margin__base * 2;

      &:nth-child(1) {
        margin-bottom: 0;
      }

      @include mq(md) {
        font-size: rem(60px);
        width: auto;
        margin-left: auto;
      }

      @include mq(lg) {
        width: 50%;
      }
    }

    & h2 {
      font-size: rem(24px);
      line-height: 1.6;
      text-transform: uppercase;

      @include mq(md) {
        font-size: rem(36px);
      }
    }
  }
}
</style>
