<template>
  <app-modal
    :size="size"
    @close="handleClickClose"
  >
    <template #body>
      <session-forms
        class="modal__body"
        :tab="tab"
        :available-tabs="availableTabs"
        @authenticated="handleClickClose"
      />
    </template>
  </app-modal>
</template>

<script setup>
import { toRefs } from 'vue'
import AppModal from '&/organisms/AppModal'
import SessionForms from '&/modules/sessions/SessionForms'

const emit = defineEmits(['close'])

const props = defineProps({
  tab: { type: String, default: 'register' },
  availableTabs: { type: Array, default: () => ['login', 'register'] },
  size: { type: String, default: 'sm' }
})

const { tab, availableTabs, size } = toRefs(props)

const handleClickClose = () => emit('close')
</script>

<style lang="scss" scoped>
.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}
</style>