<template>
  <div
    class="embed"
    v-if="valid"
  >
    <iframe
      sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation allow-presentation"
      class="embed__item"
      allowfullscreen
      :src="url"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppVideoEmbed',
  props: {
    src: {
      type: String
    },
    params: {
      type: Object
    }
  },
  data () {
    return {
      valid: false,
      url: '',
      videos: [
        {
          reg: /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/i,
          url: 'https://www.youtube.com/embed/$5',
          params: {
            'picture-in-picture': 1,
            accelerometer: 1,
            gyroscope: 1
          }
        },
        {
          reg: /^.*vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/i,
          url: 'https://player.vimeo.com/video/$1',
          params: {
            title: 0,
            byline: 0,
            portrait: 0
          }
        },
        {
          reg: /^.*(?:\/video|dai.ly)\/([A-Za-z0-9]+)([^#&?]*).*/i,
          url: 'https://www.dailymotion.com/embed/video/$1',
          params: {
            autoplay: 0
          }
        }
      ]
    }
  },
  watch: {
    src () {
      this.parse()
    }
  },
  methods: {
    parse () {
      if (this.src) {
        for (let i = 0; i < this.videos.length; i++) {
          const v = this.videos[i]
          const m = v.reg.exec(this.src)

          if (m) {
            const params = Object.assign({}, v.params, this.params)
            const query = Object.keys(params).map(key => key + '=' + params[key]).join('&')
            const and = v.url.indexOf('?') >= 0 ? '&' : '?'
            this.url = this.src.replace(v.reg, v.url) + and + query
            this.valid = true

            return
          }
        }
      }

      this.valid = false
    }
  },
  mounted () {
    this.parse()
  }
})
</script>

<style lang="scss" scoped>
.embed {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  width: 100%;

  &__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
