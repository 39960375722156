import { omit as _omit, mapKeys as _mapKeys } from 'lodash'

export default class Informations {
  constructor ({ terms_of_use = false, sepa_mandate_terms = false, newsletter = false, message = null, ...informations }) {
    this.terms_of_use = terms_of_use
    this.sepa_mandate_terms = sepa_mandate_terms
    this.newsletter = newsletter
    this.message = message

    Object.assign(this, informations)
  }


  get to_tamaro () {
    const custom_parameters = _mapKeys(_omit(this, ['terms_of_use', 'newsletter', 'message']), (value, key) => `stored_${key.replaceAll('-', '_')}`)

    return {
      sepa_mandate_terms: this.sepa_mandate_terms,
      stored_customer_email_permission: this.newsletter,
      stored_customer_message: this.message,
      ...custom_parameters
    }
  }
}
