<template>
  <form @keyup.enter="handleRegister()">
    <form-notification
      class="margin__t--12"
      :keys="error"
    />

    <div class="margin__t--24">
      <label
        class="input__label"
        for="register_firstname"
      >
        {{ t('literal.firstname') }} *
      </label>

      <input-text
        name="firstname"
        class="margin__t--6"
        id="register_firstname"
        placeholder="Jon"
        v-model="form.firstname"
        :v="v$.firstname"
      />
    </div>

    <div class="margin__t--24">
      <label
        class="input__label"
        for="register_lastname"
      >
        {{ t('literal.name') }} *
      </label>

      <input-text
        id="register_lastname"
        class="margin__t--6"
        placeholder="Snow"
        v-model="form.lastname"
        :v="v$.lastname"
      />
    </div>

    <div class="margin__t--24">
      <label
        class="input__label"
        for="register_email"
      >
        {{ t('literal.email') }} *
      </label>

      <input-email
        id="register_email"
        class="margin__t--6"
        v-model="form.email"
        :v="v$.email"
      />
    </div>

    <div class="margin__t--24">
      <label
        class="input__label"
        for="register_password"
      >
        {{ t('literal.new_password') }} *
      </label>

      <input-password
        id="register_password"
        class="margin__t--6"
        v-model="form.password"
        :v="v$.password"
      />
    </div>

    <div class="margin__t--24">
      <label
        class="input__label"
        for="register_password_confirmation"
      >
        {{ t('literal.password_confirmation') }} *
      </label>

      <input-password
        id="register_password_confirmation"
        class="margin__t--6"
        v-model="form.password_confirmation"
        :v="v$.password_confirmation"
      />
    </div>

    <div class="margin__t--12">
      <input-checkbox
        v-model="form.terms_of_use"
        class="color--prim"
        :v="v$.terms_of_use"
      >
        <span v-html="t('@register.label_agree_terms_and_privacy')" />
      </input-checkbox>
    </div>

    <div class="btn__container">
      <app-save-button
        class="btn btn__size--medium btn__color--prim margin__t--36"
        :show-icon="false"
        :is-loading="loader"
        @click="handleRegister()"
      >
        {{ t('literal.create_your_account') }}
      </app-save-button>
    </div>
  </form>
</template>

<script setup>
import { defineAsyncComponent, reactive, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useI18n } from '@/vendors/i18n'
import useVuelidate from '@vuelidate/core'
import { required, minLength, email, sameAs } from '@vuelidate/validators'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'

import InputText from '&/atoms/InputText'
import InputEmail from '&/atoms/InputEmail'
import InputPassword from '&/atoms/InputPassword'
import InputCheckbox from '&/atoms/InputCheckbox'
import AppSaveButton from '&/molecules/AppSaveButton'

const FormNotification = defineAsyncComponent(() => import('&/molecules/FormNotification'))

const emit = defineEmits(['userAuthenticated'])

const { t, locale } = useI18n()
const { fullPath } = useRoute()
const { login, postAuthUser } = useAuthenticationStore()
const { loader, error } = storeToRefs(useAuthenticationStore())

const form = reactive({
  firstname: null,
  lastname: null,
  email: null,
  password: null,
  password_confirmation: null,
  terms_of_use: false,
  language: locale,
  return_url: fullPath
})

const rules = {
  firstname: { required },
  lastname: { required },
  email: { required, email },
  password: { required, minLength: minLength(8) },
  password_confirmation: { sameAs: sameAs(computed(() => form.password)) },
  terms_of_use: { terms: sameAs(true) }
}

const v$ = useVuelidate(rules, form)

const handleRegister = () => {
  v$.value.$validate()
  if (v$.value.$invalid) return

  postAuthUser({ params: form }).then(() => login({ params: form }).then(() => emit('userAuthenticated')))
}
</script>

<style scoped lang="scss">
.btn__container {
  justify-content: center;
}
</style>
