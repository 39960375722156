import Blocks from '@/classes/procedures/Blocks'

export default class Section {
  constructor ({ name = null, blocks } = {}) {
    this.name = name
    this.blocks = new Blocks(blocks)
  }

  isAvailable (data) {
    if (this.name === 'transaction_payment') return this.blocks.transaction_payment_method?.isAvailable(data)
    return this.blocks.some(block => block.isAvailable(data))
  }
}
