import axios from '@/vendors/axios/axios'
import { get as _get } from 'lodash'

const endpoint = '/api/v1/secure'
const resource = 'secure'

export default {
  namespaced: true,
  state: {
    procedures: {}
  },
  getters: {
    getTokenByProcedureId: state => id => _get(state.procedures, id, null)
  },
  actions: {
    fetchProcedureSecureToken ({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios.get(`${endpoint}/token/procedure/${id}`)
          .then(response => {
            const token = _get(response, 'data.data.token')

            commit('setItem', { resource: `${resource}.procedures`, id, item: token }, { root: true })

            resolve(token)
          })
          .catch(() => reject())
      })
    }
  }
}
