import Procedure from '@/classes/procedures/Procedure'

export default class Procedures extends Array {
  constructor (procedures, ...rest) {
    Array.isArray(procedures) ? super(...procedures.map(procedure => new Procedure(procedure))) : super(...rest)
  }

  get donation () {
    return this.find(procedure => procedure.category === 'donation')
  }

  get create_project () {
    return this.find(procedure => procedure.category === 'create-project')
  }

  get project_invite () {
    return this.find(procedure => procedure.category === 'project-invite')
  }

  get qr_code () {
    return this.find(procedure => procedure.category === 'qr-code')
  }
}
