<template>
  <svg
    enable-background="new 0 0 461 670"
    width="461"
    height="670"
    viewBox="0 0 461 670"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    v-if="goal1 || goal2"
  >
    <mask
      id="a"
      height="209"
      maskUnits="userSpaceOnUse"
      width="321"
      x="-81"
      y="-18"
    >
      <circle
        clip-rule="evenodd"
        cx="95"
        cy="95"
        fill="#fff"
        fill-rule="evenodd"
        r="95"
      />
    </mask>
    <g transform="translate(.063256)">
      <ellipse
        clip-rule="evenodd"
        cx="184.8"
        cy="429"
        fill="#355f71"
        fill-rule="evenodd"
        opacity=".2433"
        rx="169.5"
        ry="157"
      />
      <g
        clip-rule="evenodd"
        fill="#35364e"
        fill-rule="evenodd"
        transform="matrix(.90630779 -.42261826 .42261826 .90630779 0 192.291043)"
        v-if="isShowTimeLeft"
      >
        <path d="m39.2.3c-.3.8-1.3.9-1.8 1.5-.5.5-.6 1.2-1.1 1.7-1.2-.1-.2-1.7.3-2.2.8-.9 2.1-1.8 2.6-1" />
        <path
          d="m36.6 7.2c-5 .6-8.8 1.7-12.4 3.8-7 4-11.8 9.9-14.3 17.8-.9 2.7-1.5 6-1.5 9.3 0 1.9.3 3.4.3 4.9 0 3.7 1 6.1 2 8.7.5 1.3 1 2.6 1.7 3.7 1.1 1.7 2.8 3.3 4.3 5s3 3.3 4.7 4.6c1.8 1.3 3.9 2.3 6 3.2 2.1 1 4.3 1.9 6.5 2.8 3.7 1.5 8.3 1.4 12.4.8 5.4-.8 9.5-3.1 13.2-5.4 1-.6 2-1.2 2.7-2 .9-.9 1.5-2.1 2.4-3.2s1.7-2.1 2.3-3.3 1.3-2.5 2-3.7 1.4-2.4 2-3.7c1.6-3.8 2.4-9.8 1.5-14.9-.3-1.7-.3-3.4-.7-5-.2-1-.8-1.9-1.1-2.8-.6-1.5-1.3-2.8-2-3.9-.8-1.2-1.9-2.2-2.6-3.3-1.1-1.6-2.2-3.3-3.4-4.4-1.2-1.2-2.8-2.1-4.2-3.2-2.9-2.4-5.5-4.1-9.9-5.1-1.9-.5-4.7-.9-6.7-.9-1.8.1-3.5 0-5.2.2zm2.5-1.9c.9 0 1.9 0 2.6-.1.1-1.5-2.9-2-2.6.1zm4.5-.1c5.2.7 9.7 1.8 13.2 4.2 1.1.8 2.1 1.7 3.2 2.6 2.2 1.7 4.2 3.4 6.1 5.5.9 1 1.5 2.3 2.4 3.4.4.5.9 1 1.3 1.6 1.3 2.1 3.1 5 3.8 7.9.3 1.4.3 2.9.6 4.4.8 4.3.4 9.7-.6 13.7-.8 3.3-3 6.1-4.4 9-.7 1.5-1.5 2.9-2.6 4.2-1 1.3-1.9 2.8-2.9 3.8-1.7 1.7-3.8 2.8-5.9 3.9-4.1 2.4-9 4-15.2 4.3-3.1.1-6.1 0-8.7-1-4.7-1.9-9.4-3.6-13.1-6.3-1.9-1.3-3.4-3-5-4.8-1.5-1.7-3.3-3.4-4.5-5.2-.9-1.4-1.5-3.1-2.2-4.8-.8-1.7-1.4-3.5-1.7-5.3-.6-4-.6-8-.3-11.6.5-6 2.9-11.3 5.5-15.3 5.3-7.7 12.9-13 24.8-14.1-.5-4.3 6.1-3.9 6.2-.1z"
        />
        <path
          d="m17.8 10.4c-.1-.9.9-1.6 1.6-2.2 2-1.7 5.4-3.1 8.1-3.7.4-.1.9-.2 1 .3.1.7-1.3.8-1.4.8-2.7.6-5.2 1.8-7.1 3.2-.6.6-1.1 1.7-2.2 1.6"
        />
        <path
          d="m39.8 13.9c-1 0-1.2-1.3-.8-2.4-6.5.2-11.2 2.1-15 5 .4.6 1.3.5 1.4 1.4.1 1.6-2.3.7-2.6-.3-2.4 1.9-4.3 4.2-5.8 7 2 .5.9 3-.9 1.6-1.6 3.5-3 7.6-2.6 12.8.5.3.7-.1 1.5 0 1.2.6.3 2.3-1.3 1.9-.1 5.2 1.8 9.1 3.9 12.3 1.3-1.7 3 .2 1.3 1.5 2.2 2.4 4.1 5.1 7.3 6.7.4-.6.6-1.3 1.5-1.2 1.1.1.7 1.4.1 2 3.9 1.6 6.9 4.1 12.5 4.1-.1-1.2.3-2.6 1.6-1.9.3.4.3 1.1.3 1.9 4.2-.1 7.6-1.2 10.6-2.6-1.3-1.3 1.3-2.9 1.5-.9 3.6-1.6 6-4.3 8-7.4-1.8-.2-.6-2.7.9-1.5 1.8-3.6 4.1-6.7 4.4-11.8-1.5-.1-4.1.5-3.8-1.1.2-1 2.6-.5 3.8-.5.1-4.9-.5-9.3-2.2-12.5-.7.7-2.1 1-2.1-.3 0-.7.7-.9 1.2-1.3-2-2.7-3.7-5.8-6.4-7.9-.1 1.1-1.7 1.4-1.9.3-.1-.7.4-.9.7-1.5-3.9-3.4-8.6-6-16.1-6-.1 1.2 0 2.6-1 2.6zm-.7-3.8c7-.3 12.8 1.4 16.6 4.7 1 .9 2 1.6 3 2.4s1.8 1.7 2.6 2.6 1.4 2.1 2.2 3.1c1.8 2.2 3.4 4.4 4.4 7.8.3.9.3 2 .4 3s.4 1.9.5 2.8c.3 3.7-.1 7.3-.9 10.2-.8 3.1-2.6 5.6-4.1 8.4-1.5 2.7-3.3 5.1-5.6 6.8-2.4 1.8-5.2 3.3-8.2 4.3-3.2 1-6.4 1.5-10.9 1.5-3.6-.1-6.5-1.6-9.5-3-2.8-1.4-5.6-2.6-7.8-4.7-2.1-2-4.1-4.2-5.9-6.6-.9-1.2-1.4-2.7-2-4.2-.7-1.5-1.3-3-1.5-4.6-.4-2.4-.5-4.9-.4-7.3 0-7.3 2.7-12.9 6-17.1 2.3-2.8 5-5.2 8.5-7 3.6-1.9 7.2-2.8 12.6-3.1z"
        />
        <path
          d="m22.4 13.3c-.1 1-1.3 1.4-2 2-3.2 2.7-5.8 6.2-7.9 10.2-1.7 3.4-2.4 8-2.7 12.5-.1.8-.1 1.8 0 2.7.1 1.1.8 2.5.3 3.5-.9 0-.8-.8-.9-1.3-1-5.4.2-11.4 1.8-16.3.6-1.9 1.8-3.8 2.9-5.5s2.5-3.4 3.7-4.6 2.8-2.6 4-3.2c.3-.2.5-.2.8 0"
        />
        <path
          d="m26.3 17.4c-.2-.8.8-1.1 1.5-1.4 1.5-.7 4.1-1.3 5.9-1.7.5-.1 1.7-.4 2.3-.4.4 0 1 0 1 .3.1.5-1.3.7-1.4.8-2.8.6-5.2.8-7.5 1.8-.6.2-1 .9-1.8.6"
        />
        <path
          d="m40.8 38.1c-.3-1.4-2.4-1.5-3.1-.5-.8 1.3.5 3 2.1 2.7.8-.5 1.2-1.3 1-2.2zm9.8-16.3c-3.4 4.4-5.6 10-9.1 14.2.4.7 1.1 1.2 1 2.4 3.8.7 7.8 1.3 11.6 2 .1-.2-.4-.8-.1-1.3 1.1-1.2 1.9.8 2.6 1.4.4.3 1.1.5 1.1 1 0 .7-.8 1.1-1.6 1.4-.9.4-2.1 1-2.7.3-.2-.4-.1-.9.1-1.2-3.7-.6-7.5-1.1-11.1-1.9-.8.6-.9 1.6-2 1.9-1.7.5-4-.6-4.5-2.1-.9-2.6 1.2-4.9 3.9-4.6 3.8-4.4 6.1-10.3 9.6-14.9-.6.4-1.9.4-1.7-.8.1-.6 1-.8 1.9-1.4.8-.5 1.5-1.3 2.2-1.4 2.3-.2.8 2.1.6 3.3-.2.8-.1 2.1-.9 2.2-.4.1-.6-.2-.9-.5z"
        />
        <path
          d="m35.8 18.5c-.8 1.5-2.3 2.4-3.6 3.5-3 2.7-5.9 5.7-9.1 8.4-.6.5-1.3 1.3-2.1 1-.1-.9 1-1.3 1.5-1.8 4.1-3.4 8.7-7.7 12.5-11.4 0 0 .4-.1.3 0 .4-.1.3.2.5.3"
        />
        <path
          d="m24.7 19.7c-.6 1.1-1.8 1.4-2.6 2.4-.9 1-1.4 2.5-2.6 3.2 0-.1-.1-.1-.2-.1-.5-.6.4-1.2.9-1.8 1.1-1.5 2.1-2.9 3.8-3.8.2-.2.5-.4.7.1"
        />
        <path
          d="m9.4 19.7c.1.9-.7 1.6-1.1 2.2-3.3 4.9-6.7 14.2-4.6 22.9.6 2.4 1.3 4.6 2.1 6.8.2.6.8 1.4.3 2-.8.1-1-1-1.2-1.5-.8-2.1-1.3-4.2-1.9-6.4-1.2-4.6-.8-10.1.3-14.7.9-4 3.3-8.7 5.5-11.3 0-.2.5-.2.6 0"
        />
        <path
          d="m55.3 20.9c-.2 1.1-.7 2.2-1.3 3.1-.5.8-1.1 1.9-2.2 1.9 0-1 1-1.6 1.5-2.4s.9-1.8 1.3-2.7c0-.1.2-.1.2-.2.2-.1.3.1.5.3"
        />
        <path
          d="m58.3 64c-.3-.9.6-1 1-1.4s.7-1 1.2-1.4c.7-.6 1.4-1 2-1.6 1.8-1.8 3-3.9 4.2-6.6.7-1.7 1.5-3.3 2-5.1.6-1.8 1.3-3.7 1.5-5.7.2-2.4.2-4.9 0-7.4-.1-1.6-.5-2.9-.8-4.4-.6-3.3-2.4-5.7-4-8.2-.3-.4-.8-.9-.8-1.5 1-.7 1.9 1.7 2.5 2.6 1.4 2.3 2.7 3.9 3.2 7 .1.8.2 1.6.3 2.3.5 2.3.7 6.3.4 9.4-.2 2-.9 4.2-1.5 6.1s-1.4 3.7-2.1 5.4c-1.1 2.6-2.6 5.1-4.4 6.8-.6.6-1.4 1-2.1 1.6-.7.9-1.3 2.3-2.6 2.1"
        />
        <path
          d="m4.1 23.1c.1 0 .2.1.3.2 0 1.1-.9 1.9-1.4 2.8-1.6 2.9-2 7.6-2.3 12-.1 1.8.3 4.6.6 6.1.1.4.9 1.6.1 1.8s-1-1.8-1.1-2.2c-.7-4-.3-8.8.3-12.9.4-3.2 1.7-5.7 3.2-7.6.1-.1.2-.2.3-.2"
        />
        <path
          d="m39.1 23.1c.1 0 .2.1.3.2 0 1-1.3 1.7-2 2.3-2.4 1.9-4.7 4.2-7.1 6.2-2.4 2.1-4.7 4.1-7.1 6.3-.7.7-1.4 1.7-2.5 1.8-.4-.6.3-1 .6-1.3 2.6-2.4 5.3-4.9 8.1-7.3 1.6-1.3 3-2.9 4.6-4.2 1.8-1.3 3.4-2.9 5.1-4"
        />
        <path d="m55.3 23.4c.3 0 .2.2.4.3-.2 1-.9 2.3-2 2 0-.1 0-.2-.1-.2.6-.8 1-1.6 1.7-2.1" />
        <path
          d="m42.1 25.5c-.1.7-.8 1.1-1.3 1.6-.9 1-1.6 2-2.7 2.9-.4.4-1 1.1-1.6.7.2-1.2 1.5-1.6 2.3-2.5 1-1.1 1.6-2.4 2.8-3.1.3.1.4.2.5.4"
        />
        <path
          d="m70.2 59.7c-.4-.7.3-1.3.6-1.8 1-1.5 2-3.2 2.6-5.4.3-1.4.7-2.9 1-4.4s.5-2.9.8-4.5c.1-.5.4-1.1.4-1.6.1-1.1.1-2.4 0-3.6-.2-3-.5-5.7-.9-8.4-.2-1.1-.2-2.3-.5-3.2-.2-.5-.6-.7-.7-1.3 1.3-1 1.6 1.3 1.8 2.7.6 4.1 1.7 9 1.2 13.9-.1.6-.3 1.2-.4 1.8-.4 2.6-1 5.1-1.5 7.4-.2.8-.3 1.7-.5 2.4-.6 1.7-1.5 3.2-2.5 4.7-.4.6-.7 1.4-1.4 1.3"
        />
        <path d="m25.3 30.1c-.8-.5.9-1.6 1.5-2.1.8-.7 1.4-1.2 1.9-1.6h.3c.8.6-.9 1.7-1.5 2.3-.8.6-1.4 1.3-2.2 1.4" />
        <path
          d="m59.5 28c.1.6-.5.7-.9.9-1.5 1.2-2.9 2.8-4.4 4.2-.5.4-1.1 1.6-1.9 1 .4-1.2 1.9-2.1 3-3.2s2.1-2.4 3.2-3c.3-.1.8-.4 1 .1"
        />
        <path d="m57.5 33.3c-.5-.8.5-1.2.9-1.6.5-.5 1.1-1 1.6-1.3 1.3.3.1 1-.4 1.5-.6.5-1.4 1.5-2.1 1.4" />
        <path d="m29.3 34.4c.5.9-1.1 2.5-2.1 2.1 0-.6.5-1 .9-1.3.2-.2.6-.8 1.2-.8" />
        <path
          d="m77.5 34.8c.4-.1.7 1.1.8 1.7.9 5.5-.5 10.4-1.5 14.7-.2.8-.2 2-1 2.2-.5-.8 0-1.8.2-2.6.2-.9.4-1.8.6-2.6.5-2.7 1.2-5.2 1-8.6-.1-1.6-.5-3.2-.4-4.6.1-.1.3-.4.3-.2"
        />
        <path d="m36 35.9c-.1.7-.8.9-1 1.6-.2.8-.3 2.3-.1 3.4.2 1.4 1.6 2 2 3-1 .9-2.8-1.6-3-2.7-.4-1.9.3-6.7 2.1-5.3" />
        <path d="m32.9 40c.2.1.4.4.3.9.2.4.9.9.4 1.5-1 0-2-2.1-.7-2.4" />
        <path
          d="m59.1 43.3c-.3.9-1.1 1.2-2.1 1.7-.4.2-2.6 1.2-2.7.2-.1-.6.8-.4 1.5-.6 1-.3 2.2-1.1 2.6-1.5.3-.1.6-.1.7.2"
        />
        <path
          d="m45.3 45.1c-.9 1.1-2.2 1.7-3.3 2.6-2.8 2.1-5.6 4.2-8.6 6.1-.5.3-1.2 1-2 .6-.1-.8.9-1 1.5-1.4 3.7-2.4 7.9-5.6 11.5-8 .2-.2.7-.4.9.1"
        />
        <path d="m10.9 45.3c.3.6-.4 1-.8.6-.1-.6.4-.9.8-.6" />
        <path d="m55.8 46.7c0-.2 0-.3 0-.5.6.1 3.2-1.6 3.2-.5 0 .5-2.4 1.6-3.2 1" />
        <path
          d="m53.3 46.6c-.4.8-1.3 1.3-2 1.9-1.5 1.1-3 2.2-4.5 3.3-.7.5-1.5 1.5-2.4 1 .1-.9 1.2-1.2 1.9-1.7 1.9-1.5 4.4-3.5 6.3-4.8 0 0 .6 0 .7.3"
        />
        <path
          d="m14.9 55.8c-.1 0-.2 0-.2.1-.7-.1-.8-1.1-1.2-1.8-.9-1.7-2.5-4-2.9-5.9-.1-.4-.1-.7.1-1 .7-.6.7.4.9 1 .4 1.6 1.5 3.3 2.3 4.7.5.9 1.3 2 1 2.9"
        />
        <path
          d="m53.3 49.9c.1.9-.9 1.1-1.5 1.5-1.6 1-3.2 2.6-5.1 3.3 0-.1-.1-.2-.3-.3-.1-.8.8-.9 1.4-1.3.5-.3 1-.8 1.5-1.1 1.1-.8 2.3-1.4 3.2-2 .1-.2.4-.5.8-.1"
        />
        <path
          d="m40 50.8c-.3.9-1.4 1.4-2.1 2-1.5 1.2-3 2.3-4.6 3.5-.7.5-1.5 1.4-2.5 1.2-.1-.9 1.1-1.3 1.8-1.9 2-1.5 4.7-3.6 6.6-4.9.1-.1.6-.3.8.1"
        />
        <path d="m16.3 57.2c-.1.3-.2.6-.7.5-.6-.4.4-1.3.7-.5" />
        <path d="m57.1 64.8v.5c-.3.3-1.2.2-1-.5.2-.2.9-.2 1 0" />
        <path
          d="m55.5 66.3c.1.9-.9 1.3-1.6 1.7-2.6 1.5-6 1.8-10.1 2-1 .1-2.3.7-3.1 0 .3-.9 2.2-.9 3.1-.9 2.2-.2 4.4-.2 6.3-.6s3.4-1.3 4.7-2.3c0 0 .4-.1.3 0 .3 0 .3 0 .4.1"
        />
        <path
          d="m29.2 72.9c.1 0 .1-.1.2-.1.1-.1.2-.3.4-.2.9.3 1.7 1.1 3.1 1.5 1.2.3 2.4.9 3.4 1.1 4.2.9 9.8.1 13.1-.8 1.4-.4 2-.7 3.4-.9.2 0 .8-.3.9.2.2.8-3.3 1.4-4 1.6-2.7.8-4.5.9-7.2 1.1-3.9.3-7.9-.6-11-1.7-.5-.2-.9-.5-1.4-.8-.2-.2-1-.3-.9-1"
        />
        <path d="m51.2 77.5c-.2 1-1.9 1-3.2 1.1-1.2.1-2.8.2-3.3-.4.4-.9 2-.5 3.2-.5 1 0 2-.4 2.6-.4.4-.1.6-.1.7.2" />
      </g>
      <g
        mask="url(#a)"
        opacity=".5384"
        transform="translate(250.936744 480.000186)"
      >
        <g
          clip-rule="evenodd"
          fill="#fff"
          fill-rule="evenodd"
          transform="translate(-81 -18)"
        >
          <path d="m288.8 206.7 29.4-26.1 2.8 2.4-29.6 26z" />
          <path d="m278.3 206.8 39.9-35.5 2.8 2.5-40.1 35.2z" />
          <path d="m268.4 206.8 49.8-44.8 2.8 2.5-50.1 44.5z" />
          <path d="m258.5 206.9 59.7-53.6 2.8 2.5-60.1 53.2z" />
          <path d="m248 206.9 70.2-62.8 2.8 2.5-70.7 62.4z" />
          <path d="m237.5 207 80.7-72.2 2.8 2.5-81.2 71.7z" />
          <path d="m227.6 207 90.6-81.5 2.8 2.5-91.2 81z" />
          <path d="m217.7 206.5 100.5-90.3 2.8 2.5-101.2 89.7z" />
          <path d="m207.2 207.2 111-99.6 2.8 2.5-111.8 98.9z" />
          <path d="m196.7 207.2 121.5-108.9 2.8 2.5-122.4 108.2z" />
          <path d="m186.8 206.7 131.4-117.7 2.8 2.5-132.3 116.9z" />
          <path d="m176.3 206.7 141.9-126.9 2.8 2.4-142.9 126.2z" />
          <path d="m165.8 206.8 152.4-136.3 2.8 2.5-153.5 135.4z" />
          <path d="m156.5 207.5 161.7-145.7 2.8 2.5-162.8 144.7z" />
          <path d="m146 206.9 172.2-154.3 2.8 2.4-173.4 153.4z" />
          <path d="m135.5 207 182.7-163.7 2.8 2.5-184 162.6z" />
          <path d="m125.6 207.1 192.6-173.1 2.8 2.5-194 171.9z" />
          <path d="m115 207.1 203.2-181.7 2.8 2.4-204.5 180.6z" />
          <path d="m105.1 207.2 213.1-191.1 2.8 2.5-214.5 189.8z" />
          <path d="m94.6 207.2 223.6-200.4 2.8 2.5-225.1 199.1z" />
          <path d="m84.1 207.3 231.6-207.3 2.8 2.5-233.2 205.9z" />
          <path d="m74.2 207.4 231.7-207.4 2.7 2.4-233.3 206z" />
          <path d="m63.7 207.4 231.8-207.4 2.6 2.4-233.3 206z" />
          <path d="m54.4 207.5 230.6-207.5 2.6 2.3-232.2 206.1z" />
          <path d="m43.9 207.6 230.7-207.6 2.5 2.2-232.3 206.2z" />
          <path d="m33.4 207 230.7-207 2.5 2.2-232.4 205.6z" />
          <path d="m22.9 207.1 230.8-207.1 2.4 2.1-232.4 205.7z" />
          <path d="m13.6 207.1 230.9-207.1 2.3 2-232.5 205.8z" />
          <path d="m3.1 207.2 230.9-207.2 2.3 2-232.6 205.8z" />
          <path d="m0 200.4 223.6-200.4 2.2 1.9-225.2 199.1z" />
          <path d="m0 191.7 213.2-191.1 2 1.9-214.6 189.8z" />
          <path d="m0 182.5 202.7-181.9 2 1.8-204.1 180.6z" />
          <path d="m0 173.2 192.9-172.6 1.9 1.8-194.2 171.4z" />
          <path d="m0 163.9 182.4-163.9 1.9 1.7-183.7 162.8z" />
          <path d="m0 155.3 172.6-154.7 1.8 1.6-173.8 153.6z" />
          <path d="m0 146 162.2-145.4 1.7 1.6-163.3 144.3z" />
          <path d="m0 136.7 151.7-136.1 1.7 1.5-152.8 135.2z" />
          <path d="m0 127.4 141.3-126.8 1.6 1.4-142.3 126z" />
          <path d="m0 118.8 131.5-118.2 1.5 1.4-132.4 117.3z" />
          <path d="m0 109.5 121.6-108.9 1.5 1.3-122.5 108.2z" />
          <path d="m0 100.2 111.2-99.6 1.4 1.3-112 98.9z" />
          <path d="m0 91 100.7-90.4 1.4 1.2-101.5 89.7z" />
          <path d="m0 81.7 90.3-81.1 1.2 1.1-90.9 80.5z" />
          <path d="m0 72.4 79.9-71.8 1.1 1.1-80.4 71.3z" />
          <path d="m0 63.7 70.6-63.1 1.1 1-71.1 62.7z" />
          <path d="m0 54.5 60.2-53.9 1 .9-60.6 53.5z" />
          <path d="m0 45.2 49.8-44.6.9.9-50.1 44.3z" />
          <path d="m0 35.9 39.3-35.3.9.8-39.6 35.1z" />
          <path d="m0 27.3 28.9-26.1.8.8-29.1 25.8z" />
          <path d="m0 18 19.6-17.4.8.7-19.8 17.3z" />
        </g>
      </g>
      <g transform="translate(242.936744)">
        <g
          clip-rule="evenodd"
          fill-rule="evenodd"
        >
          <path
            d="m138.9 13c4 14.7.9 30.4.9 45.4-.2 153.8-5.5 307.8-1.3 461.4.4 15.6 3.9 37.7-13.2 45.7-8 2.9-16.9 2.5-25.3 3.1-9.9.3-19.8.5-29.8.5-12.7-.2-25.5-.3-38.2-1.8-10.7-.9-23.4-4.8-25.8-16.7-1.9-9.1-.7-18.6-1-27.8-5-158.5-7.2-318.1-2.9-476.6.3-13-2.9-27.8 5.3-39.1 10-11.2 35.8-5.5 50.1-6.5 17.4.9 74-5.6 81.2 12.4"
            fill="#fff3e9"
          />
          <path
            d="m72.2 479.1c-.5-21.7.1-43.4.3-65.1.1-105.3 7.6-210.5 3.5-315.8 0-3.8.3-8.6-4.6-9.2-4.4-.4-6.2 4.1-5.5 7.7 4.6 127.7-5.7 255.4-3.7 383.1-4.4 1-8.5 3-12.1 5.9-17.1 14.6-12.2 42.4 11.6 45.9 19.3 2.8 32.8-15.6 32.3-31.5-.3-10.4-6.8-19.7-21.8-21z"
            fill="#eecaad"
          />
          <path
            d="m62.2 476.1c0 1.3 0 2.5.1 3.8-4.4 1-8.5 3-12.1 5.9-17.1 14.6-12.2 42.4 11.6 45.9 19.3 2.8 32.8-15.6 32.3-31.5-.3-10.5-6.8-19.9-21.8-21.1 0-1 0-1.9-.1-2.9z"
            fill="#fe9a47"
          />
          <path
            d="m55.6 464.2h24.3v21.3h-24.3z"
            fill="#fe9a47"
            class="progressbar"
            :style="`transform: scaleY(${scale > 19 ? 19 : scale})`"
          />
        </g>
        <path
          d="m108.4 501c0-3.9-.6-7.2-2-9.9-1.1-11.1-12.2-17.9-15.9-20.2l-.1-.1c-2.1-1.2-4.3-2.2-6.7-3 .2-58.2 1.3-117.3 2.5-174.5l.1-3.3c.2-11 .4-21.9.7-32.9 1.7-63.8 3.3-137.9 1.1-210.6l-.2-.5c-1.8-8.5-8.3-14-16.6-14-5.3 0-10.3 2.3-13.6 6.1-2.5 3-3.8 6.6-3.9 10.7h-.1v.9c1.9 74.4.2 150.1-1.5 223.3v.3c-.6 28.3-1.3 57.6-1.7 86.4-.9 31-1 62.6-1.2 93.1v2.6c0 5-.1 10.1-.1 15.1-6 3-11.2 7.2-15 12.2-8.9 11.8-10.8 27.3-5.1 40.5 5.5 12.7 16.6 20.8 30.6 22.4 1.8.2 3.7.3 5.5.3 17.8 0 32.8-9.2 40.2-24.5 3.3-6.4 4.4-13.6 3-20.4zm-46.7 30.7c-23.8-3.5-28.6-31.3-11.6-45.9 3.6-2.9 7.7-4.9 12.1-5.9-2-127.7 8.3-255.4 3.7-383.1-.6-3.6 1.2-8.2 5.5-7.7 5 .6 4.6 5.4 4.6 9.2 4.1 105.3-3.4 210.5-3.5 315.8-.2 21.7-.8 43.4-.3 65.1 15 1.2 21.4 10.6 21.8 21.1.5 15.8-13 34.3-32.3 31.4z"
          fill="#fff"
        />
      </g>
      <g fill="#35364e">
        <text
          font-size="36"
          transform="translate(371.9367 143.0002)"
          class="thermometer__goal"
          v-if="goal2"
        >
          {{ goal2 / 1000 }}K
        </text>

        <text
          font-size="36"
          transform="translate(366.9367 402.0002)"
          v-if="goal1"
          class="thermometer__goal"
        >
          {{ goal1 / 1000 }}K
        </text>

        <template v-if="isShowTimeLeft">
          <text
            font-size="20"
            transform="translate(18.9367 143.0002)"
            class="thermometer__timeleft"
          >
            {{ translate(timeLeftText) }}

            <template v-if="timeLeft.years > 0">
              {{ timeLeft.years }} {{ $i18n.t('literal.years').toLowerCase() }}
            </template>

            <template v-else-if="timeLeft.months > 0">
              {{ timeLeft.months }} {{ $i18n.t('literal.months').toLowerCase() }}
            </template>

            <template v-else-if="timeLeft.days > 0">
              {{ timeLeft.days }} {{ $i18n.t('literal.days').toLowerCase() }}
            </template>

            <template v-else-if="timeLeft.hours > 0">
              {{ timeLeft.hours }} {{ $i18n.t('literal.hours').toLowerCase() }}
            </template>

            <template v-else-if="timeLeft.minutes > 0">
              {{ timeLeft.minutes }} {{ $i18n.t('literal.minutes').toLowerCase() }}
            </template>
          </text>

          <path
            clip-rule="evenodd"
            d="m97.8 183.3c8.3-1.5 13.9-6.2 16.8-14.1.1-.7.4-1.7.9-3 .1-.2.1-.4.2-.6h.1c-2.3.8-4.5 1.5-7.2 2.1-1.1.2-3.9.2-2.8-1.9.7-1.3 3.4-.9 4.5-1.2s3.5-1.2 5.4-2c1.6-.6 4.3-2.3 5.5-1.4.8.6.4 1.4.3 2.5-.2 2.1-.2 4.4-.3 7 0 .3 0 .9-.1 1.6v.3c-.2 2-.7 4.3-2.6 2.8-1.1-.9-.2-3.1-.2-4.2.1-1.3 0-2.4 0-3.5-3.3 10.4-7.9 16.1-20.1 18.7 0 0-2.5 1.1-3.4-.7-.5-1.1.5-1.9 3-2.4z"
            fill-rule="evenodd"
          />
        </template>
      </g>
    </g>
  </svg>
</template>

<script setup>
import { computed } from 'vue'
import { mapGetters, mapActions } from '@/store/map-state'
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'
import { intervalToDuration } from 'date-fns'

const { fetchClientStatistics } = mapActions('client')
const { statistics } = mapGetters('client')

const { translate } = useI18n()

const props = defineProps({
  goal: { type: Object },
  metric: { type: Object }
})

const collectedAmount = computed(() => _get(statistics.value, 'amount_collected', 0) / 100)
const endDate = computed(() => _get(props.goal, 'end_date'))
const timeLeftText = computed(() => _get(props.goal, 'time_left'))
const timeLeft = computed(() => endDate.value ? intervalToDuration({ start: new Date(), end: new Date(endDate.value) }) : null)
const isShowTimeLeft = computed(() => (timeLeft.value.years > 0 || timeLeft.value.months > 0 || timeLeft.value.days > 0) && translate(timeLeftText.value))
const goal1 = computed(() => _get(props.goal, 'goal_1', 0) * 1000)
const goal2 = computed(() => _get(props.goal, 'goal_2', 0) * 1000)
const finalGoal = computed(() => collectedAmount.value <= goal1.value ? goal1.value : goal2.value)
const maxScale = computed(() => collectedAmount.value <= goal1.value ? 4.5 : 14.5)
const scale = computed(() => (maxScale.value / finalGoal.value) * collectedAmount.value + (maxScale.value > 4.5 ? 4.5 : 0))

fetchClientStatistics({ query: props.metric })
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');

$font: 'Indie Flower', cursive;

.thermometer {
  &__timeleft {
    font-family: $font;
  }

  &__goal {
    font-family: $font-family--secondary;
    font-weight: 700;
  }
}

.progressbar {
  transform-origin: center bottom;
  transform-box: fill-box;
}
</style>