<template>
  <div class="row">
    <div class="col-12 col-md-8">
      <form-notification
        :keys="error"
        class="margin__b--12"
      />

      <div class="card">
        <section class="card__header">
          <h2 class="card__title bold">
            {{ t('literal.title') }}
          </h2>
        </section>

        <section class="card__main">
          <div class="input__container">
            <input-text
              id="product_name"
              v-model="productName"
              :v="v$.name[locale]"
              :disabled="isStaticView"
            />
          </div>
        </section>
      </div>

      <div class="card">
        <section class="card__header">
          <label
            class="card__title bold margin__r--12"
            for="price"
          >
            {{ t('literal.price') }}
          </label>

          <input-checkbox-switch
            id="price"
            v-model="isPriced"
            size="s"
            :disabled="isStaticView"
          />
        </section>

        <section class="card__main">
          <input-amount
            id="product_price"
            v-model="product.price"
            :v="v$.price"
            v-if="isPriced"
            :disabled="isStaticView"
          />

          <p v-else>
            {{ t('literal.the_product_is_free_to_order') }}
          </p>
        </section>
      </div>

      <section
        class="card"
        v-if="!isStaticView || (isStaticView && translate(product.image))"
      >
        <div class="card__header">
          <label
            class="card__title bold margin__r--12 cursor--pointer"
            for="image"
          >
            {{ t('literal.image') }}
          </label>
        </div>

        <div class="card__main">
          <app-image-preview
            id="image"
            v-model="image"
            :width="660"
            :height="440"
            v-if="!isStaticView"
          />

          <figure
            class="card__img"
            v-else
            :style="{backgroundImage: `url(${ translate(product.image) })`}"
          />
        </div>
      </section>

      <div class="card">
        <section class="card__header">
          <label
            class="card__title bold margin__r--12"
            for="quantity"
          >
            {{ t('literal.inventary') }}
          </label>

          <input-checkbox-switch
            id="quantity"
            v-model="isLimitedStock"
            size="s"
            :disabled="isStaticView"
          />
        </section>

        <section class="card__main">
          <div
            class="stock__container"
            v-if="isLimitedStock"
          >
            <p
              class="stock__infos"
              v-if="isEditView"
            >
              <span
                class="d-inline-block margin__r--12"
                v-html="tc(`plural.x_items_sold`, product.total_ordered, { x: `<strong class='bold'>${product.total_ordered}</strong>` })"
              />

              <span
                class="d-inline-block"
                v-html="tc(`plural.x_items_in_stock`, product.current_stock, { x: `<strong class='bold'>${product.current_stock}</strong>` })"
              />
            </p>

            <div
              class="stock__field"
              v-if="!isStaticView"
            >
              <label
                class="stock__label"
                for="add_quantity"
                v-if="isEditView"
              >
                {{ t('literal.add') }}
              </label>

              <input-number
                id="add_quantity"
                v-model.number="quantity"
                :v="v$.quantity"
                :disabled="isStaticView"
              />
            </div>
          </div>

          <template v-else>
            <p class="d-flex justify-content-between">
              <span>
                {{ t('literal.quantity_is_unlimited') }}
              </span>

              <span
                v-if="isEditView"
                v-html="tc(`plural.x_items_sold`, product.total_ordered, { x: `<strong class='bold'>${product.total_ordered}</strong>` })"
              />
            </p>
          </template>
        </section>
      </div>

      <div class="card">
        <section class="card__header">
          <label
            for="limit_per_order"
            class="card__title bold margin__r--12"
          >
            {{ t('literal.quantity_limit_per_order') }}
          </label>

          <input-checkbox-switch
            id="limit_per_order"
            v-model="isLimitedPerOrder"
            size="s"
            :disabled="isStaticView"
          />
        </section>

        <section class="card__main">
          <div
            class="input__container"
            v-if="isLimitedPerOrder"
          >
            <input-number
              v-model.number="maxQuantityPerOrder"
              :v="v$.max_quantity_per_order"
              :disabled="isStaticView"
            />
          </div>

          <p v-else>
            {{ t('literal.people_can_order_as_many_of_this_product_as_they_want') }}
          </p>
        </section>
      </div>
    </div>

    <div class="col-12 col-md-4">
      <div class="card">
        <section class="card__header">
          <label class="card__title bold">
            {{ t('literal.display_options') }}
          </label>
        </section>

        <section class="card__main card__header">
          <label
            class="margin__r--12"
            for="display_options"
          >
            {{ t('literal.display_on_project_page') }}
          </label>

          <input-checkbox-switch
            id="display_options"
            v-model="displayOnPage"
            :disabled="isDisplayOptionDisabled"
            size="s"
          />
        </section>
      </div>

      <div
        class="card"
        :class="{'card--disabled': product.total_ordered > 0 }"
        v-if="isEditView && !isStaticView"
      >
        <div class="card__main card__delete">
          <h2 class="bold">
            {{ t(`literal.delete_product`) }}
          </h2>

          <p class="margin__t--12">
            {{ t(`literal.you_can_only_delete_products_that_have_not_been_ordered_yet`) }}
          </p>
        </div>

        <div class="card__footer">
          <button
            type="button"
            class="link__color--lg-danger link--naked padding--12 product__delete-btn"
            name="delete_product"
            :disabled="product.total_ordered > 0"
            @click.prevent="emit('openDeleteModale')"
          >
            <fa-icon
              v-if="!deleteLoader"
              :icon="['fal', 'trash']"
              class="margin__r--6"
            />

            <app-spinner
              size="2"
              class="margin__r--6"
              v-else
            />

            {{ t(`literal.delete_product`) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { forIn as _forIn } from 'lodash'
import { useI18n } from '@/vendors/i18n'
import { useRoute } from 'vue-router'
import { useProductStore } from '@/stores/ProductStore'
import { useProduct } from '@/composables/modules/projects/useProduct'
import { required } from '@vuelidate/validators'
import { validateMultilingual } from '@/utils/validators'
import useVuelidate from '@vuelidate/core'

import InputText from '&/atoms/InputText'
import InputAmount from '&/atoms/InputAmount'
import InputCheckboxSwitch from '&/atoms/InputCheckboxSwitch'
import InputNumber from '&/atoms/InputNumber'
import AppImagePreview from '&/organisms/AppImagePreview'

const AppSpinner = defineAsyncComponent(() => import('@/components/atoms/AppSpinner'))
const FormNotification = defineAsyncComponent(() => import('&/molecules/FormNotification'))

const emit = defineEmits(['openDeleteModale'])
const product = defineModel('product', { required: true, type: Object })
const project = defineModel('project', { required: true, type: Object })
const addedQuantity = defineModel('addedQuantity', { type: Number, default: 0 })

const route = useRoute()
const { t, tc, translate, locale } = useI18n()
const { productsDisplayOrder, productDisplayOrder, isCampaignProduct, isDisplayOptionDisabled } = useProduct(product, project)
const { loader: deleteLoader, error } = storeToRefs(useProductStore())
const v$ = useVuelidate({ name: validateMultilingual({ required }) }, product)

const isEditView = computed(() => route.name === 'PrivateEditProduct')
const isStaticView = computed(() => isEditView.value && isCampaignProduct.value)

const productName = computed({
  get: () => product.value.name[locale],
  set: text => _forIn(product.value.name, (value, key, object) => object[key] = text)
})

const isPriced = computed({
  get: () => !product.value.is_free,
  set: value => {
    product.value.is_free = !value
    if (!value) product.value.price = 0
  }
})

const quantity = computed({
  get: () => addedQuantity.value,
  set: value => addedQuantity.value = Math.round(value) || 0
})

const image = computed({
  get: () => product.value.image[locale],
  set: image => _forIn(product.value.image, (value, key, object) => object[key] = image)
})

const isLimitedStock = computed({
  get: () => !product.value.is_unlimited_stock,
  set: value => {
    product.value.is_unlimited_stock = !value
    quantity.value = 0
  }
})

const isLimitedPerOrder = computed({
  get: () => product.value.is_limited_per_order,
  set: value => {
    product.value.is_limited_per_order = value
    if (!value) product.value.max_quantity_per_order = 0
  }
})

const maxQuantityPerOrder = computed({
  get: () => product.value.max_quantity_per_order,
  set: value => product.value.max_quantity_per_order = Math.round(value) || 0
})

const displayOnPage = computed({
  get: () => typeof productDisplayOrder.value == 'boolean' ? productDisplayOrder.value : product.value.display_on_page,
  set: value => {
    typeof productDisplayOrder.value == 'boolean' ? productDisplayOrder.value = value : productsDisplayOrder.value.push({ product_id: product.value.id, display_on_page: value })
    productDisplayOrder.value = value
    if (!isCampaignProduct.value) product.value.display_on_page = value
  }
})
</script>

<style scoped lang="scss">
.input {
  &__label {
    color: $lg;
    font-size: rem(12px);
  }
}

.card {
  margin-bottom: 24px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    flex-grow: 1;
  }

  &__delete {
    border: 0;
  }

  &__footer {
    padding: 12px;
    text-align: center;
    border-top: 1px solid $dw;
  }

  &__label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px;
  }

  &__img {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    max-width: 460px;
    border-radius: 4px;
    @include aspect-ratio(3, 2);
  }

  &--disabled {
    .card__footer {
      border-color: rgba(#2E293D, 0.1);
    }
  }
}

.product {
  &__delete-btn {
    display: inline-flex;
    align-items: center;
    padding: 0;

    &:disabled {
      cursor: not-allowed;
      color: rgba(#2E293D, 0.4);
    }
  }
}

.stock {
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__field {
    display: flex;
    align-items: center;
  }

  &__label {
    margin-right: 12px;
    white-space: nowrap;
  }
}
</style>