<template>
  <div>
    <header
      class="header"
      :class="{'header--fixed': mobileMenuOpened}"
      v-if="section"
    >
      <div class="header__topbar">
        <div class="wrapper d-none d-md-flex justify-content-end">
          <language-switcher v-slot="{ options, updateLanguage, isActive }">
            <ul class="languages__ul padding__tb--18">
              <li
                class="languages__li"
                v-for="language in options"
                :key="language.id"
              >
                <button
                  class="languages__a"
                  :class="{'active': isActive(language.id)}"
                  type="button"
                  @click.prevent="updateLanguage(language.id)"
                >
                  {{ language.label.value.toUpperCase() }}
                </button>
              </li>
            </ul>
          </language-switcher>

          <button
            @click.prevent="openSessionModal('register')"
            class="header__session"
            v-if="!isAuthenticated"
          >
            <fa-icon
              class="margin__r--6"
              :icon="['fal', 'circle-user']"
            />

            <span>{{ $i18n.t('literal.login') }}</span>
          </button>

          <router-link
            class="header__session header__session--user d-flex align-items-center"
            :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
            v-if="isAuthenticated && user"
          >
            <user-profile-img
              size="2"
              :avatar="user.avatar"
            />
          </router-link>
        </div>
      </div>

      <div class="wrapper header__wrapper">
        <div class="header__cell header__burger">
          <app-burger-menu
            class="header__burger-menu"
            :state="mobileMenuOpened"
            @toggle="toggleMobileMenu"
          />
        </div>

        <div class="header__cell">
          <router-link
            :to="{ name: 'Home', params: { locale: $i18n.locale } }"
            @click="mobileMenuOpened = false"
            v-show="client.logo.url"
            class="logo"
          >
            <img
              class="logo__img"
              :src="client.logo.url"
              :alt="client.name"
            >
          </router-link>
        </div>

        <nav class="header__infos">
          <ul
            class="header__menu"
            v-if="navigation.length > 0"
          >
            <li
              class="header__menu-item"
              v-for="({ link, sublinks }, index) in navigation"
              :key="`menu_item_${index}`"
            >
              <app-link
                :link="link"
                class="header__menu-link"
                @click="mobileMenuOpened = false"
              />

              <ul
                v-if="sublinks.length > 0"
                class="header__submenu padding__t--12"
              >
                <li
                  class="header__submenu-item"
                  v-for="(sublink, i) in sublinks"
                  :key="`menu_item_${i}`"
                >
                  <app-link
                    :link="sublink.link"
                    class="header__submenu-link"
                    @click="mobileMenuOpened = false"
                  />
                </li>
              </ul>
            </li>
          </ul>

          <ul
            class="header__actions"
            v-if="actions.length > 0"
          >
            <li
              class="header__actions-item"
              v-for="(item, index) in actions"
              :key="`actions_item_${index}`"
            >
              <app-link
                :link="item.link"
                class="header__actions-link translate__x3"
                v-slot="{ text }"
                v-if="item.link && item.link.show"
                @click="mobileMenuOpened = false"
              >
                {{ text }}

                <fa-icon
                  class="header__actions-icon element"
                  :icon="['fas', 'chevron-right']"
                />
              </app-link>
            </li>
          </ul>
        </nav>
      </div>

      <transition name="fade-mobile-menu">
        <section
          class="mobile"
          v-if="mobileMenuOpened"
        >
          <div class="wrapper mobile__wrapper">
            <nav class="mobile__nav">
              <ul
                class="mobile__menu"
                v-if="navigation.length > 0"
              >
                <li
                  class="mobile__menu-item"
                  v-for="({ link, sublinks }, index) in navigation"
                  :key="`menu_mobile_item_${index}`"
                >
                  <app-link
                    :link="link"
                    class="mobile__menu-link"
                    v-slot="{ text }"
                    v-if="link && link.show"
                    @click="mobileMenuOpened = false"
                  >
                    {{ text }}

                    <fa-icon
                      class="header__actions-icon element"
                      :icon="['fas', 'chevron-right']"
                    />
                  </app-link>

                  <ul
                    v-if="sublinks.length > 0"
                    class="mobile__submenu padding__t--12"
                  >
                    <li
                      class="mobile__submenu-item"
                      v-for="(sublink, i) in sublinks"
                      :key="`menu_mobile_item_${i}`"
                    >
                      <app-link
                        :link="sublink.link"
                        class="mobile__submenu-link"
                        @click="mobileMenuOpened = false"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>

            <hr class="separator separator__size--full border__color--dw margin__tb--12">

            <div class="mobile__sessions">
              <language-switcher v-slot="{ options, updateLanguage, isActive }">
                <ul class="languages__ul">
                  <li
                    class="languages__li"
                    v-for="language in options"
                    :key="language.id"
                  >
                    <button
                      class="languages__a"
                      :class="{'active': isActive(language.id)}"
                      type="button"
                      @click.prevent="updateLanguage(language.id)"
                    >
                      {{ language.label.value.toUpperCase() }}
                    </button>
                  </li>
                </ul>
              </language-switcher>

              <ul>
                <li
                  class="mobile__sessions-item"
                  v-if="!isAuthenticated"
                >
                  <a
                    @click.prevent="openSessionModal('login')"
                    class="link--naked pointer"
                  >
                    <fa-icon
                      class="margin__r--6"
                      :icon="['fal', 'circle-user']"
                      fixed-width
                    />

                    <span>{{ $i18n.t('literal.login') }}</span>
                  </a>
                </li>

                <li
                  class="mobile__sessions-item pointer d-flex align-items-center"
                  v-if="isAuthenticated && user"
                  @click.prevent="goToPage('Profile'); mobileMenuOpened = false"
                >
                  <user-profile-img
                    class="margin__r--6"
                    :avatar="user.avatar"
                    size="2"
                  />
                </li>
              </ul>
            </div>
          </div>
        </section>
      </transition>
    </header>

    <app-modal
      v-if="showSessionModal"
      size="sm"
      @close="showSessionModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="template"
          @authenticated="showSessionModal = false"
        />
      </template>
    </app-modal>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { get as _get } from 'lodash'
import { mapGetters } from 'vuex'
import UserProfileImg from '&/atoms/UserProfileImg'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
import LanguageSwitcher from '&/atoms/LanguageSwitcher'
import { scrollTo } from '@/utils/utils'

export default {
  name: 'CustomHeaderKikirpa',
  components: {
    UserProfileImg,
    AppBurgerMenu,
    LanguageSwitcher,
    AppLink: defineAsyncComponent(() => import('&/atoms/AppLink')),
    SessionForms: defineAsyncComponent(() => import('&/modules/sessions/SessionForms')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  setup: () => ({ scrollTo }),
  data () {
    return {
      showSessionModal: false,
      mobileMenuOpened: false,
      template: null
    }
  },
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    navigation () {
      return _get(this.section, 'attributes.navigation', [])
    },
    actions () {
      return _get(this.section, 'attributes.actions', [])
    }
  },
  methods: {
    openSessionModal (template) {
      this.template = template
      this.showSessionModal = true
    },
    toggleMobileMenu (value) {
      const delay = !value ? 300 : 0
      this.scrollTo()

      setTimeout(() => {
        this.mobileMenuOpened = !value
      }, delay)
    }
  }
}
</script>

<style lang="scss" scoped>
$color--primary: #B58C4B;
$color--secondary: #B58C4B;

.wrapper {
  max-width: 1310px;
  margin: 0 auto;
  position: relative;
  padding-left: 24px;
  padding-right: 24px;

  @media (min-width: 1120px) {
    padding-left: 84px;
   padding-right: 84px;
  }
}

.languages {
  &__ul {
    display: flex;
    font-size: 18px;
    position: relative;
    padding-left: 18px;

    &:before {
      content: '';
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      left: 0;
      height: 100%;
      width: 1px;
      background-color: #404040;

      @media (min-width: 1120px) {
        display: block;
      }
    }
  }

  &__li {
    padding: 0 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__a {
    display: block;
    color: #404040;
    line-height: 1;
    border-bottom: 1px solid transparent;
    transition: all .3s;

    &.active {
     border-bottom: 1px solid #404040;
    }

    &:hover:not(.active) {
      color: $color--primary;
    }
  }
}

.logo {
  display: block;
  position: relative;
  margin-right: $margin__base * 2;

  &__img {
    display: block;
    max-width: 100px;
    height: auto;

    @media (min-width: 1120px) {
      max-width: 130px;
    }
  }
}

.header {
  background: $white;
  position: relative;
  z-index: 999;

  &__topbar {
    border-bottom: 1px solid #404040;
    display: none;

    @media (min-width: 1120px) {
      display: block;
    }
  }

  &--fixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;

    @include mq(lg) {
      position: relative;
    }
  }

  &__session {
    margin-left: 24px;
    font-size: rem(18px);
    transition: all .3s;

    &:hover {
      color: $color--primary;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding-top: 18px;
    padding-bottom: 18px;

    @media (min-width: 1120px) {
      justify-content: flex-start;
    }
  }

  &__cell {
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      justify-content: flex-start;
    }

    &:last-child {
      justify-content: flex-end;
    }
  }

  &__infos {
    display: flex;
    justify-content: space-between;
    width: auto;

    @media (min-width: 1120px) {
      width: 100%;
    }
  }

  &__menu {
    display: none;
    margin-right: $margin__base * 2;
    margin-left: $margin__base * 2;

    @media (min-width: 1120px) {
      display: flex;
      align-items: center;
    }

    &-item {
      position: relative;

      &:not(:last-child) {
        margin-right: 18px;
      }

      &:hover {
        .header__submenu {
          display: block;
        }
      }
    }

    :deep(.header__menu-link) {
      position: relative;
      display: flex;
      align-items: center;
      color: #404040;
      padding: 0;
      font-size: 18px;
      transition: all .3s;

      &:hover:not(.is-active) {
        color: $color--primary;
      }

      &.is-active {
        border-bottom: 1px solid #404040;
      }
    }
  }

  &__submenu {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    font-size: rem(15px);
    width: 220px;
    display: none;

    &-item {
      &:not(:last-child) {
        border-bottom: 1px solid $white;
      }
    }

    :deep(.header__submenu-link) {
      display: block;
      background: $color--primary;
      padding: calc($padding__base / 2) $padding__base;
      color: $white;
      transition: all .2s;

      &:hover {
        background: shade($color--primary);
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    line-height: 1;

    &-icon {
      font-size: 12px;
      margin-left: 6px;
    }

    &-item {
      &:not(:last-child) {
        margin-right: $margin__base * 2;
      }
    }

    :deep(.header__actions-link) {
      display: flex;
      align-items: center;
      background: $color--primary;
      padding: 12px 18px;
      line-height: 1;
      font-size: rem(18px);
      color: $white;
      transition: all .3s;

      &:hover {
        background-color: shade($color--primary, 5%);
      }
    }
  }

  &__burger {
    display: block;

    @media (min-width: 1120px) {
      display: none;
    }

    &-menu {
      color: $color--primary;
      line-height: 1;

      :deep() {
        .menu__line {
          background-color: $color--primary;
        }

        .menu__label {
          display: none;
        }

        .menu__close::before, .menu__close::after {
          background-color: $color--primary;
        }
      }
    }
  }
}

.mobile {
  position: fixed;
  top: 80px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 80px);
  background: $white;
  z-index: 999;
  text-align: center;

  @media (min-width: 1120px) {
    top: 156px;
    height: calc(100vh - 156px);
  }

  @include mq(lg) {
    display: none;
  }

  &__wrapper {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__nav {
    flex: 1 1 auto;
    overflow-y: auto;
    padding: $padding__base 0;
  }

  &__menu {
    flex: 1 1 auto;
    text-align: center;

    &-item {
      &:not(:last-child) {
        margin-bottom: 18px;
      }
    }

    :deep(.mobile__menu-link) {
      color: $black;
      padding: 10px 0;
      display: inline-block;
      position: relative;
      line-height: 1;
      font-size: 24px;
      font-weight: 700;

      &.is-active {
        color: $color--secondary;
        border-bottom: 1px solid $color--secondary;
      }
    }
  }

  &__submenu {
    font-size: rem(18px);

    :deep(.mobile__submenu-link) {
      color: $color--primary;
    }
  }

  &__sessions {
    color: #404040;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 24px;
    font-size: rem(18px);

    .languages__ul {
      margin-right: 36px;
    }

    &-item {
      &:not(:last-child) {
        margin-right: $margin__base * 2;
      }
    }
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @media (min-width: 1120px) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}

.fade-mobile-menu-enter-active, .fade-mobile-menu-leave-active {
  transition: opacity .5s;
}
.fade-mobile-menu-enter, .fade-mobile-menu-leave-to {
  opacity: 0;
}
</style>
