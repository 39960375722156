<template>
  <footer class="footer bg__color--white">
    <div class="container footer__wrapper">
      <div>
        <router-link
          class="logo"
          :to="{ name: 'Home', params: { locale } }"
          v-if="translate(logo1)"
        >
          <img
            class="logo__img"
            :src="translate(logo1)"
            :alt="client.name"
          >
        </router-link>
      </div>

      <div>
        <img
          :src="translate(logo2)"
          class="logo__img"
          alt=""
          v-if="translate(logo2)"
        >
      </div>

      <nav
        class="nav"
        v-if="navigation.length > 0"
      >
        <ul class="nav__ul">
          <li
            class="nav__li"
            v-for="({ link }, key) in navigation"
            :key="`link_${key}`"
          >
            <app-link
              :link="link"
              class="nav__a link__color--ter-prim link--naked"
            />
          </li>
        </ul>
      </nav>
    </div>

    <div class="container">
      <nav
        class="subnav"
        v-if="subNavigation.length > 0"
      >
        <ul class="subnav__ul">
          <template
            v-for="({ type, value }, key) in subNavigation"
            :key="`subnav_${key}`"
          >
            <li
              class="subnav__li"
              v-if="translate(value)"
            >
              <template v-if="type === 'email'">
                <a
                  class="link__color--ter-prim link--naked"
                  :href="`mailto:${translate(value)}`"
                >
                  {{ translate(value) }}
                </a>
              </template>

              <template v-else-if="type === 'phone'">
                <a
                  class="link__color--ter-prim link--naked"
                  :href="`tel:${getPhone(translate(value))}`"
                >
                  {{ translate(value) }}
                </a>
              </template>

              <template v-else>
                {{ translate(value) }}
              </template>
            </li>
          </template>
        </ul>
      </nav>
    </div>
  </footer>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useClientStore } from '@/stores/ClientStore'
import { get as _get } from 'lodash'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))

const props = defineProps({
  section: { type: Object, required: true }
})

const { translate, locale } = useI18n()
const { getClient } = useClientStore()

const { client } = getClient()

const logo1 = computed(() => _get(props, 'section.attributes.logo_1', {}))
const logo2 = computed(() => _get(props, 'section.attributes.logo_2', {}))
const navigation = computed(() => _get(props, 'section.attributes.navigation', []))
const subNavigation = computed(() => _get(props, 'section.attributes.sub_navigation', []))

const getPhone = phone => phone.replace(/[^\d.-]+/g, '')
</script>

<style lang="scss" scoped>
.footer {
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 48px;
    padding-top: 68px;
    padding-bottom: 48px;
    text-align: center;

    @include mq(sm) {
      grid-template-columns: auto auto 250px;
      text-align: left;
    }
  }
}

.logo {
  &__img {
    max-width: 250px;
    height: auto;
    display: inline-block;

    @include mq(sm) {
      max-width: 100%;
    }
  }
}

.nav {
  &__li {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  :deep(.nav__a) {
    line-height: 1;
    font-size: rem(18px);

    @include mq(lg) {
      font-size: rem(24px);
    }

    &.is-active {
      color: $color--primary;
    }
  }
}

.subnav {
  font-size: rem(18px);
  padding-bottom: 24px;

  &__ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__li {
    text-align: center;

    &:not(:last-child) {
      &::after {
        content: '|';
        display: inline-block;
        margin: 0 6px;
      }
    }
  }
}
</style>