<template>
  <div
    class="editor__content"
    v-if="translate(attributes.editor_content)"
    v-html="translate(attributes.editor_content)"
  />

  <p
    v-else-if="translate(attributes.text)"
    class="color--lg"
  >
    {{ translate(attributes.text) }}
  </p>
</template>

<script setup>
import { toRefs, inject } from 'vue'
import { useModule } from '@/composables/app/useModule'
import { useI18n } from '@/vendors/i18n'

const props = defineProps({ block: { type: Object, required: true } })

const { block } = toRefs(props)

const procedure_execution = inject('procedure_execution')

const { translate } = useI18n()
const { attributes } = useModule({ component: block, parent: procedure_execution })
</script>
