<template>
  <div v-if="(isShowed('text_share') && text) || (isShowed('sharing_methods') && sharingMethods.length)">
    <h3
      class="title color--lg"
      v-if="isShowed('text_share') && text"
    >
      {{ text }}
    </h3>

    <app-share
      :networks="sharingMethods"
      whatsapp-text="project_page.share_whatsapp_text_url"
      email-text="project_page.share_email_text_url"
      x-text="project_page.share_twitter_text_url"
      :target-id="project.id"
      target-type="Project"
      :disabled="isDisabled"
      v-if="sharingMethods.length"
    />
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useBlock } from '@/composables/modules/templates'
import { get as _get } from 'lodash'

const AppShare = defineAsyncComponent(() => import('&/molecules/AppShare'))

const props = defineProps({ block: { type: Object, required: true } })

const { translate } = useI18n()
const { attributes, objects, getText, isShowed } = useBlock(props.block)

const project = computed(() => _get(objects.value, 'project'))
const text = computed(() => translate(getText('share')))
const sharingMethods = computed(() => _get(attributes, 'sharing_methods', []))
const isDisabled = computed(() => project.value.status !== 'published')
</script>

<style lang="scss" scoped>
.title {
  font-size: rem(14px);
}
</style>