import { ref, onMounted, onBeforeUnmount } from 'vue'

export default function useScrollAnimation () {
  const waypoints = ref([])

  onMounted(() => {
    document.querySelectorAll('[scroll-animation]').forEach(elm => waypoints.value.push(elm))
    window.addEventListener('scroll', handleScroll)
  })

  onBeforeUnmount(() => window.removeEventListener('scroll', handleScroll))

  const handleScroll = () => {
    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
    const clientHeight = isNaN(window.innerHeight) ? window.clientHeight : window.innerHeight

    waypoints.value.forEach((waypoint, index) => {
      const offset = waypoint.offsetTop

      if (scrollTop >= offset - (clientHeight / 2)) {
        waypoint.setAttribute('scroll-animation', true)
        waypoints.value.splice(index, 1)
      }

      if (!waypoints.value.length)
        window.removeEventListener('scroll', handleScroll)
    })
  }
}