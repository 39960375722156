export default [
  {
    name: 'custom_footer_paralympic',
    path: 'modules/pages/paralympic/FooterParalympic'
  },
  {
    name: 'custom_header_paralympic',
    path: 'modules/pages/paralympic/HeaderParalympic'
  },
  {
    name: 'custom_homepage_paralympic',
    path: 'modules/pages/paralympic/HomepageParalympic'
  }
]
