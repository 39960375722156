export default [
  {
    name: 'custom_footer_jez',
    path: 'modules/pages/jez/FooterJez'
  },
  {
    name: 'custom_header_jez',
    path: 'modules/pages/jez/HeaderJez'
  },
  {
    name: 'benefiting_jez',
    path: 'modules/pages/jez/BenefitingJez'
  }
]
