<template>
  <div
    class="spinner"
    :class="['spinner__size--' + size]"
  >
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enable-background="new 0 0 0 0"
    >
      <path
        class="spinner__circle"
        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
      />
    </svg>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    size: {
      type: String,
      default: '4'
    }
  }
})
</script>

<style scoped lang="scss">
.spinner {
  font-size: 1rem;
  animation: rotate .8s ease-in-out infinite;

  &__circle {
    fill: $black;
  }

  &__size {
    @for $size from 1 through 5 {
      &--#{$size} {
        width: 10px * $size;
        height: 10px * $size;
      }
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
