<template>
  <table
    class="table"
    :class="{ 'table--small': column.is_small }"
    width="100%"
    role="table"
    :aria-label="t('literal.overview_of_project_files')"
    v-if="isAllowed"
  >
    <caption class="table__caption">
      {{ t('literal.overview_of_project_files') }}
    </caption>

    <thead
      class="table__thead"
      v-show="!column.is_small"
    >
      <tr
        class="table__tr table__tr--naked"
        role="rowgroup"
      >
        <th
          class="table__th"
          role="columnheader"
        >
          {{ t('literal.title') }}
        </th>

        <th
          class="table__th"
          role="columnheader"
        >
          {{ t('literal.format') }}
        </th>

        <th
          class="table__th"
          role="columnheader"
        />
      </tr>
    </thead>

    <tbody class="table__tbody card">
      <tr
        class="table__tr table__hover"
        v-for="({ name, file }, index) in files"
        :key="`table_tr_${index}`"
        @click="download(translate(file))"
      >
        <td
          class="table__td table__td--title"
          role="cell"
        >
          {{ translate(name) || getFileName(translate(file)) }}
        </td>

        <td
          class="table__td"
          role="cell"
          v-if="!column.is_small"
        >
          {{ getFileFormat(translate(file)) }}
        </td>

        <td>
          <fa-icon
            class="table__icon"
            :icon="['fal', 'download']"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { useI18n } from '@/vendors/i18n'
import { useDownload } from '@/composables/app/useDownload'
import { useBlock } from '@/composables/modules/templates'
import { get as _get } from 'lodash'

const props = defineProps({
  column: { type: Object, required: true },
  block: { type: Object, required: true }
})

const { t, translate } = useI18n()
const { download } = useDownload()
const { attributes, objects, isShowed, isPreview } = useBlock(props.block)
const { authUser } = storeToRefs(useAuthenticationStore())

const project = computed(() => _get(objects.value, 'project'))
const files = computed(() => _get(attributes, 'files', []).filter(({ file }) => translate(file)))
const isAllowed = computed(() => isShowed('files') && files.value.length && (project.value?.isAuthorized(authUser.value) || isPreview.value))

const getFileFormat = url => url.split('.').pop().toUpperCase()
const getFileName = url => url.split('/').pop()
</script>

<style lang="scss" scoped>
.table {
  margin-top: 24px;

  &__td {
    &--title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__tr {
    display: grid !important;
    grid-template-columns: auto 75px 16px;

    @include mq(sm) {
      grid-template-columns: 2fr 1fr 16px;
    }
  }

  &__icon {
    transition: all $transition__duration;
  }

  &__hover {
    &:hover {
      .table__icon {
        color: $color--primary;
      }
    }
  }

  &--small {
    .table {
      &__tr {
        grid-template-columns: auto 16px;
      }
    }
  }
}
</style>
