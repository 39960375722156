<template>
  <div class="bg__color--white">
    <section
      class="banner"
      v-if="isVisible('banner')"
      :style="{ backgroundImage: `url(${translate(banner.image)})`}"
    >
      <div class="container banner__container">
        <div class="banner__wrapper">
          <h1
            class="title--1 bold"
            v-if="translate(banner.title)"
          >
            {{ translate(banner.title) }}
          </h1>

          <div
            class="editor editor__content margin__t--24"
            v-if="translate(banner.content)"
            v-html="translate(banner.content)"
          />
        </div>
      </div>
    </section>

    <section
      class="counter padding__tb--24"
      v-if="statistics && isVisible('counter')"
    >
      <div class="container">
        <ul
          class="d-flex justify-content-between margin__b--12"
          v-if="counter.content.amount"
        >
          <li>
            <app-money
              class="bold"
              :amount="statistics.amount_collected"
            />

            <span class="margin__l--6">{{ translate(counter.content.amount) }}</span>
          </li>
        </ul>

        <div
          class="counter__progress progress bg__color--white"
          v-if="counter.query.goal"
        >
          <div
            class="progress__bar"
            :style="{ width: percentage }"
          />
        </div>

        <ul
          class="d-flex justify-content-between margin__t--12"
          v-if="counter.content.contributors || counter.content.goal"
        >
          <li v-if="counter.content.contributors">
            <strong class="bold">{{ statistics.contributors_count }}</strong>

            <span class="margin__l--6">{{ translate(counter.content.contributors) }}</span>
          </li>

          <li v-if="counter.content.goal">
            <app-money
              class="bold"
              :amount="counter.query.goal"
            />

            <span class="margin__l--6">{{ translate(counter.content.goal) }}</span>
          </li>
        </ul>
      </div>
    </section>

    <section
      class="building padding__t--60 padding__b--18"
      v-if="isVisible('building')"
    >
      <div class="container building__container">
        <div class="building__wrapper align--center">
          <h2
            class="title--1 bold building__title"
            v-if="translate(building.title)"
          >
            {{ translate(building.title) }}
          </h2>

          <div
            class="editor editor__content building__content margin__t--12"
            v-if="translate(building.content)"
            v-html="translate(building.content)"
          />
        </div>

        <div class="building__representation margin__t--12">
          <div class="building__level">
            <button
              @click="handleToggleLevel"
              class="building__btn btn btn__color--sec"
            >
              <fa-icon
                :icon="level > 0 ? ['far', 'chevron-down'] : ['far', 'chevron-up']"
              />
            </button>

            <strong class="bold margin__l--12">+{{ level }}</strong>
          </div>

          <div class="building__legend">
            <ul class="legend">
              <li
                v-for="({ color, name, legend }, index) in dots"
                :key="`legend_${index}`"
                class="d-flex align-items-center legend__item"
              >
                <span
                  class="legend__color margin__r--6"
                  :style="{ backgroundColor: translate(color) }"
                />

                <p>{{ translate(name) }} <small>{{ translate(legend) }}</small></p>
              </li>
            </ul>
          </div>

          <div
            class="building__plan"
            ref="plan"
          >
            <transition name="fade-down">
              <campaign-deloods-level-0
                :dots="dots"
                v-if="level === 0"
                @click-dot="handleClickDot"
              />
            </transition>

            <transition name="fade-up">
              <campaign-deloods-level-1
                :dots="dots"
                v-if="level === 1"
                @click-dot="handleClickDot"
              />
            </transition>
          </div>
        </div>
      </div>
    </section>

    <section
      class="support padding__tb--18"
      v-if="support.items && support.items.length"
    >
      <div class="container">
        <ul class="support__items align--center">
          <li
            class="support__item"
            v-for="({ title, content, cta }, index) in support.items"
            :key="`support_${index}`"
          >
            <h2
              class="title--2 bold"
              v-if="translate(title)"
            >
              {{ translate(title) }}
            </h2>

            <div
              class="editor editor__content margin__t--18"
              v-if="translate(content)"
              v-html="translate(content)"
            />

            <app-link
              :link="cta"
              class="support__cta bold link__color--prim margin__t--18 d-inline-block"
            />
          </li>
        </ul>
      </div>
    </section>

    <div class="layout margin__t--54">
      <div class="container layout__wrapper">
        <section class="ambassadors padding__tb--48">
          <div class="ambassadors__wrapper">
            <h2
              v-if="translate(ambassadors.title)"
              class="bold title--2 align--center"
            >
              {{ translate(ambassadors.title) }}
            </h2>

            <app-glide-slides
              class="slider ambassadors__slider margin__t--24"
              :options="slider_options"
              :slides="ambassadorsSlides"
              v-if="ambassadorsSlides.length"
            >
              <template #default="{ slide }">
                <article class="ambassador">
                  <figure
                    :style="{ backgroundImage: `url(${translate(slide.image)})` }"
                    class="ambassador__figure color--white"
                  >
                    <fa-icon
                      class="no-thumb"
                      :icon="['far', 'image']"
                      v-if="!translate(slide.image)"
                    />
                  </figure>

                  <div class="margin__t--12">
                    <h3
                      v-if="translate(slide.name)"
                      class="title--3 bold d-flex justify-content-between align-items-center"
                    >
                      {{ translate(slide.name) }}

                      <ul
                        v-if="slide.social_networks.length"
                        class="d-flex margin__l--12"
                      >
                        <li
                          v-for="({icon, url}, index) in slide.social_networks"
                          :key="`social_network_${index}`"
                          class="ambassador__network"
                        >
                          <a :href="translate(url)">
                            <fa-icon
                              size="lg"
                              :icon="icon"
                            />
                          </a>
                        </li>
                      </ul>
                    </h3>

                    <div
                      class="editor editor__content margin__t--12"
                      v-if="translate(slide.content)"
                      v-html="translate(slide.content)"
                    />
                  </div>
                </article>
              </template>

              <template #prev>
                <fa-icon
                  class="color--white element"
                  :icon="['fal', 'chevron-left']"
                />
              </template>

              <template #next>
                <fa-icon
                  class="color--white element"
                  :icon="['fal', 'chevron-right']"
                />
              </template>
            </app-glide-slides>
          </div>
        </section>

        <section
          class="projects padding__tb--48"
        >
          <div class="projects__wrapper">
            <h2
              v-if="translate(projects.title)"
              class="bold title--2 align--center"
            >
              {{ translate(projects.title) }}
            </h2>

            <app-glide-slides
              class="slider projects__slider margin__t--24"
              :options="slider_options"
              :slides="projects_items"
              v-if="projects_items.length"
            >
              <template #default="{ slide }">
                <project-card :project="slide" />
              </template>

              <template #prev>
                <fa-icon
                  class="color--white element"
                  :icon="['fal', 'chevron-left']"
                />
              </template>

              <template #next>
                <fa-icon
                  class="color--white element"
                  :icon="['fal', 'chevron-right']"
                />
              </template>
            </app-glide-slides>

            <div class="align--center">
              <app-link
                :link="projects.cta"
                class="bold btn btn__color--prim margin__t--24 d-inline-block cta"
              />
            </div>
          </div>
        </section>
      </div>
    </div>

    <section class="about bg__color--sec color--white">
      <div class="container about__wrapper">
        <div>
          <h2
            class="title--1 bold"
            v-if="translate(about.title)"
          >
            {{ translate(about.title) }}
          </h2>

          <div
            class="editor editor__content margin__t--18"
            v-if="translate(about.content)"
            v-html="translate(about.content)"
          />

          <app-link
            :link="about.cta"
            class="bold btn btn__color--prim margin__t--18 d-inline-block cta"
          />
        </div>

        <figure class="about__figure">
          <img
            class="about__image"
            :src="translate(about.image)"
            :alt="translate(about.title)"
            v-if="translate(about.image)"
          >

          <fa-icon
            class="no-thumb"
            :icon="['far', 'image']"
            v-else
          />
        </figure>
      </div>
    </section>

    <section
      class="gallery"
      v-if="gallerySlides.length"
    >
      <div class="container align--center">
        <h2
          class="title--1 bold"
          v-if="translate(gallery.title)"
        >
          {{ translate(gallery.title) }}
        </h2>

        <div class="gallery__slider margin__t--24">
          <app-glide-slides
            class="slider"
            :options="gallery_options"
            :slides="gallerySlides"
          >
            <template #default="{ slide }">
              <div class="gallery__slide color--white">
                <figure
                  v-for="({ image }, i) in slide"
                  :key="`gallery_slide_${i}`"
                  :style="{ backgroundImage: `url(${translate(image)})` }"
                  class="gallery__image"
                >
                  <fa-icon
                    class="no-thumb"
                    :icon="['far', 'image']"
                    v-if="!translate(image)"
                  />
                </figure>
              </div>
            </template>

            <template #prev>
              <fa-icon
                class="color--white element"
                :icon="['fal', 'chevron-left']"
              />
            </template>

            <template #next>
              <fa-icon
                class="color--white element"
                :icon="['fal', 'chevron-right']"
              />
            </template>
          </app-glide-slides>
        </div>
      </div>
    </section>

    <section
      class="sponsors padding__tb--36"
      v-if="sponsors.items.length"
    >
      <div class="container">
        <h2
          class="title--1 bold align--center"
          v-if="translate(sponsors.title)"
        >
          {{ translate(sponsors.title) }}
        </h2>

        <div class="sponsors__slider margin__t--24">
          <app-glide-slides
            class="slider"
            :options="sponsors_options"
            :slides="sponsors.items"
          >
            <template #default="{ slide }">
              <div class="sponsor d-flex align-items-center justify-content-center">
                <a
                  class="d-block sponsor__link"
                  target="_blank"
                  :href="translate(slide.url) && translate(slide.logo)"
                  v-if="translate(slide.url)"
                >
                  <img
                    class="sponsor__logo"
                    :src="translate(slide.logo)"
                    :alt="translate(slide.name)"
                  >
                </a>

                <img
                  v-else-if="translate(slide.logo)"
                  class="sponsor__logo"
                  :src="translate(slide.logo)"
                  :alt="translate(slide.name)"
                >
              </div>
            </template>

            <template #prev>
              <fa-icon
                class="color--white element"
                :icon="['fal', 'chevron-left']"
              />
            </template>

            <template #next>
              <fa-icon
                class="color--white element"
                :icon="['fal', 'chevron-right']"
              />
            </template>
          </app-glide-slides>
        </div>
      </div>
    </section>

    <app-modal
      class="project"
      v-if="dot"
      @close="dot = null"
    >
      <template #body>
        <figure class="project__figure bg__color--sec color--white">
          <img
            class="project__img"
            :src="translate(dot.image)"
            :alt="translate(dot.name)"
            v-if="translate(dot.image)"
          >

          <fa-icon
            :icon="['far', 'image']"
            class="project__icon no-thumb"
            v-else
          />
        </figure>

        <div class="project__wrapper">
          <h3 class="project__title bold color--prim">
            {{ translate(dot.name) }}
          </h3>

          <div
            class="editor editor__content margin__t--12"
            v-if="dot.content"
            v-html="translate(dot.content)"
          />

          <template v-if="campaignGoal">
            <div class="progress bg__color--dw margin__t--24">
              <div
                class="progress__bar"
                :style="{ width: campaignPercentage }"
              />
            </div>

            <p class="bold align--right margin__t--6">
              <app-money :amount="campaignGoal" />
            </p>
          </template>

          <app-link
            :link="dot.cta"
            class="bold link__color--prim margin__t--12 d-inline-block"
          />
        </div>
      </template>
    </app-modal>
  </div>
</template>

<script setup>
import { ref, computed, defineAsyncComponent, onMounted, nextTick } from 'vue'
import { mapActions } from '@/store/map-state'
import { useCampaignStore } from '@/stores/CampaignStore'
import { useI18n } from '@/vendors/i18n'
import useSection from '@/composables/modules/pages/useSection'
import { projects_overview_options } from '@/configurations/general/components-default-data'
import { get as _get, chunk as _chunk } from 'lodash'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const AppMoney = defineAsyncComponent(() => import('&/atoms/AppMoney'))
const AppModal = defineAsyncComponent(() => import('&/organisms/AppModal'))
const ProjectCard = defineAsyncComponent(() => import('&/organisms/ProjectCard'))
const AppGlideSlides = defineAsyncComponent(() => import('&/organisms/AppGlideSlides'))

const CampaignDeloodsLevel0 = defineAsyncComponent(() => import('&/modules/pages/deloods/CampaignDeloodsLevel0'))
const CampaignDeloodsLevel1 = defineAsyncComponent(() => import('&/modules/pages/deloods/CampaignDeloodsLevel1'))

const props = defineProps({
  page: { type: Object, required: true },
  sections: { type: Object, required: true }
})

const { translate } = useI18n()
const { getStatistics } = useCampaignStore()

const slider_options = { perView: 1, type: 'carousel', bound: false }
const gallery_options = { perView: 1, type: 'carousel', bound: false, autoplay: 4000, hoverpause: true }
const sponsors_options = { perView: 5, type: 'carousel', bound: false, autoplay: 3000, hoverpause: true, breakpoints: { 991: { perView: 3 }, 575: { perView: 1 } } }

const plan = ref(null)
const statistics = ref(null)
const level = ref(0)
const dot = ref(null)
const projects_items = ref([])

const { fetchClientStatistics } = mapActions('client')
const { fetchProjects } = mapActions('projects')
const { getSectionAttributes, isVisible } = useSection(props.page)

const { statistics: campaignStatistics } = getStatistics({ id: computed(() => _get(dot.value, 'campaign_id.0')) })

const banner = computed(() => getSectionAttributes('banner'))
const counter = computed(() => getSectionAttributes('counter'))
const building = computed(() => getSectionAttributes('building'))
const support = computed(() => getSectionAttributes('support'))
const about = computed(() => getSectionAttributes('about'))
const ambassadors = computed(() => getSectionAttributes('ambassadors'))
const projects = computed(() => getSectionAttributes('projects'))
const gallery = computed(() => getSectionAttributes('gallery'))
const sponsors = computed(() => getSectionAttributes('sponsors'))

const goal = computed(() => _get(counter.value, 'query.goal', 0) / 100)
const amountCollected = computed(() => _get(statistics.value, 'amount_collected', 0) / 100)
const percentage = computed(() => amountCollected.value && goal.value ? ((amountCollected.value / goal.value) * 100) + '%' : '0%')

const dots = computed(() => _get(building.value, `level_${level.value}.rooms`, []))
const campaignGoal = computed(() => parseInt(_get(dot.value, 'goal', 0)))
const campaignAmountCollected = computed(() => _get(campaignStatistics.value, 'amount_collected', 0))
const campaignPercentage = computed(() => campaignAmountCollected.value && campaignGoal.value ? ((campaignAmountCollected.value / campaignGoal.value) * 100) + '%' : '0%')

const ambassadorsSlides = computed(() => _get(ambassadors.value, 'items', []).map(ambassador => ({
  ...ambassador, social_networks: ambassador.social_networks.map(social_network => {
    const type = social_network.type.toLowerCase()

    if (type === 'facebook') social_network.icon = ['fab', 'facebook-f']
    else if (type === 'linkedin') social_network.icon = ['fab', 'linkedin-in']
    else social_network.icon = ['fab', type]

    return social_network
  })
})))

const projectsSort = computed(() => projects_overview_options.find(o => o.value === _get(projects.value,'query.sort', 'activity_last'))?.query || { 'sort_by_field[field]': 'activity', 'sort_by_field[direction]': 'desc' })

const gallerySlides = computed(() => _chunk(_get(gallery.value, 'items', []), 3))

const handleToggleLevel = () => level.value > 0 ? level.value = 0 : level.value = 1
const handleClickDot = index => dot.value = _get(dots.value, index)

if (isVisible('counter')) fetchClientStatistics({ query: { campaign_id: _get(counter.value, 'query.campaign_id', []), procedure_id: _get(counter.value, 'query.procedure_id', []) } }).then(stats => statistics.value = stats)
if (isVisible('projects')) fetchProjects({ query: { per_page: 4, ...projectsSort.value, ..._get(projects.value, 'query', {}) } }).then(projects => projects_items.value = projects.items)

onMounted(() => nextTick(() => setTimeout(() => plan.value.scrollTo(100, 0))))

</script>

<style lang="scss" scoped>
$color--primary: #05548B;
$color--secondary: #71A437;
$color--tertiary: #E9F7DA;

@keyframes fade-up {
  from {
    opacity: 0;
    transform: translateY(30px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fade-down {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.title {
  &--1 {
    font-size: rem(38px);
    line-height: 1;
  }

  &--2 {
    font-size: rem(32px);
    line-height: 1.18;
  }

  &--3 {
    font-size: rem(24px);
    line-height: 1;
  }
}

.fade-down-enter-active {
  animation: fade-down .2s ease-in-out;
}

.fade-down-leave-active {
  animation: fade-down .2s ease-in-out reverse;
}

.fade-up-enter-active {
  animation: fade-up .2s ease-in-out;
}

.fade-up-leave-active {
  animation: fade-up .2s ease-in-out reverse;
}

:deep() {
  .cta {
    font-size: rem(18px);
    line-height: 1;
    border-radius: 0;
    padding: 16px $padding__base * 2;
  }
}

.slider {
  &:deep() {
    .glide {
      &__track {
        position: relative;
        z-index: 10;
      }

      &__arrows {
        margin-top: $margin__base;
        z-index: 0;

        @include mq(lg) {
          margin: 0;
          width: calc(100% + 216px);
          left: -108px;
        }
      }

      &__arrow {
        background: $color--secondary;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        border: 0;
        transition: $transition__duration;

        &:hover {
          background: shade($color--secondary);
        }
      }
    }
  }
}

.progress {
  width: 100%;
  height: 18px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  height: 8px;

  &__bar {
    background: $color--secondary;
    position: absolute;
    max-width: 100%;
    top: 0;
    width: 0%;
    height: 110%;
    border-radius: 0 12px 12px 0;
    transition: all .3s;
  }
}

.no-thumb {
  font-size: rem(48px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.banner {
  background-color: $color--primary;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: $white;
  font-size: rem(18px);
  line-height: 1.4;
  position: relative;
  padding: $padding__base* 7 0;

  @include mq(md) {
    padding: $padding__base * 14.5 0 $padding__base * 20;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 0;
    background: $color--primary;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;

    @include mq(md) {
      opacity: 0.47;
    }
  }

  &__container {
    position: relative;
    z-index: 10;
  }

  &__wrapper {
    max-width: 500px;
  }
}

.counter {
  background: $color--primary;
  color: $white;

  &__progress {
    height: 18px;
  }
}

.building {
  position: relative;
  z-index: 10;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: calc(100% - 12px);
    background: #E9E9FA;
    position: absolute;
    left: 0;
    top: 12px;

    @include mq(sm) {
      height: 75%;
    }

    @include mq(lg) {
      height: calc(100% - 77px);
    }
  }

  &__container {
    position: relative;
    z-index: 10;
  }

  &__wrapper {
    font-size: rem(14px);
    line-height: 1.55;
    max-width: 840px;
    margin: 0 auto;
  }

  &__title {
    max-width: 624px;
    margin: 0 auto;
  }

  &__representation {
    display: grid;
    gap: $margin__base * 2;
    grid-template-columns: 1fr;

    @include mq(sm) {
      gap: $margin__base * 2;
    }

    @include mq(lg) {
      grid-template-columns: 3fr 6fr 3fr;
    }
  }

  &__level {
    font-size: rem(30px);
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq(md) {
      font-size: rem(48px);
    }
  }

  &__btn {
    color: $white;
    border-radius: 50px;
    width: 36px;
    height: 36px;
    font-size: rem(20px);
    position: relative;

    @include mq(md) {
      width: 48px;
      height: 48px;
      font-size: rem(24px);
    }

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__plan {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;

    @include mq(sm) {
      overflow: visible;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: $white;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: $color--primary;
      border-radius: 8px;
    }

    @include aspect-ratio(647.6, 483);

    &::before {
      width: 600px;
      height: 483px;

      @include mq(md) {
        width: 100%;
        height: auto;
      }
    }

    @include mq(sm) {
      grid-column: span 2;
    }

    @include mq(lg) {
      grid-column: inherit;
    }

    svg {
      position: absolute;
      top: 0;
      transition: all;
      width: 600px;

      @include mq(sm) {
        min-width: inherit;
        width: 100%;
        height: auto;
      }
    }
  }

  &__legend {
    display: flex;
    justify-content: center;

    @include mq(sm) {
      justify-content: flex-end;
      align-items: center;
    }

    @include mq(sm) {
      order: 2;
    }
  }
}

.project {
  &:deep() {
    .modal {
      &__container {
        max-width: 456px;
        width: 90%;
      }

      &__btn--close {
        background: $color--secondary;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        font-size: rem(40px);
        position: absolute;
        transition: all $transition__duration;

        &:hover {
          background-color: shade($color--secondary);
        }

        svg {
          color: $white;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  &__figure {
    position: relative;
    overflow: hidden;

    @include aspect-ratio(456, 212);
  }

  &__img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    width: 100%;
  }

  &__wrapper {
    padding: $padding__base * 2;

    @include mq(sm) {
      padding: $padding__base * 2 $padding__base * 4.5;
    }
  }

  &__title {
    font-size: rem(26px);
    line-height: 1;
  }
}

.legend {
  font-size: rem(12px);
  line-height: 1.16;

  @include mq(sm) {
    display: flex;
    gap: calc($margin__base / 2);
  }

  @include mq(lg) {
    max-width: 192px;
    display: block;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: calc($margin__base / 2);

      @include mq(sm) {
        margin: 0;
      }

      @include mq(lg) {
        margin-bottom: calc($margin__base / 2);
      }
    }

    small {
      font-size: rem(8px);
    }
  }

  &__color {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid $black;
    flex: 0 0 auto;
  }
}

.support {
  position: relative;
  z-index: 0;

  &__items {
    display: grid;
    grid-template-columns: 1fr;
    gap: $margin__base * 2;
    font-size: rem(14px);

    @include mq(sm) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      line-height: 1.57;
    }
  }

  &__item {
    background: $color--tertiary;
    padding: $padding__base * 4 $padding__base * 2;

    @include mq(sm) {
      width: calc(50% - 12px);
    }

    @include mq(md) {
      padding: $padding__base * 5 $padding__base * 3.5;
    }

    @include mq(lg) {
      width: calc(33% - 16px);
    }
  }

  &:deep() {
    .support__cta {
      font-size: rem(24px);
    }
  }
}

.about {
  padding: $padding__base * 6 0;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: $margin__base * 2;
    align-items: center;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(xl) {
      gap: $margin__base * 12;
    }
  }

  &__figure {
    background: shade($color--secondary);

    @include aspect-ratio(516, 432);
  }

  &__image {
    position: absolute;
    object-fit: contain;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
  }
}

.gallery {
  padding: $padding__base * 4 0 $padding__base * 3;

  &__slider {
    @include mq(sm) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);

      & > .slider {
        grid-column: 2/12;
      }
    }
  }

  &__slide {
    display: grid;
    gap: $margin__base * 2;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  &__image {
    background-color: shade($color--secondary);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include aspect-ratio(515, 318);

    &:first-child {
      grid-column: 1;
      grid-row: 1 / 3;

      @include aspect-ratio(515, 662);
    }
  }
}

.layout {
  &__wrapper {
    @include mq(md) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      & > section {
        min-width: 0;
      }
    }
  }
}

.ambassadors {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100%;
    z-index: 0;
    right: 50%;
    transform: translateX(50%);
    top: 0;
    background: #E9E9FA;

    @include mq(md) {
      right: 0;
      transform: none;
      width: 50vw;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__slider {
    max-width: 342px;
    margin-left: auto;
    margin-right: auto;

    @include mq(md) {
      max-width: 270px;
    }

    @include mq(lg) {
      max-width: 300px;
    }

    @include mq(xl) {
      max-width: 324px;
    }
  }
}

.projects {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100%;
    z-index: 0;
    left: 50%;
    top: 0;
    background: $color--tertiary;
    transform: translateX(-50%);

    @include mq(md) {
      left: 0;
      transform: none;
      width: 50vw;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__slider {
    max-width: 342px;
    margin-left: auto;
    margin-right: auto;

    @include mq(md) {
      max-width: 270px;
    }

    @include mq(lg) {
      max-width: 300px;
    }

    @include mq(xl) {
      max-width: 342px;
    }
  }
}

.ambassador {
  width: 100%;

  &__figure {
    background: shade($color--secondary);

    @include aspect-ratio(324, 353);
  }

  &__network {
    &:not(:last-child) {
      margin-right: calc($margin__base / 2);
    }

    a {
      display: block;
      color: #0064A4;
      background: #AEC200;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      font-size: rem(11px);
      line-height: 24px;
      text-align: center;
      transition: $transition__duration;

      &:hover {
        background: shade(#AEC200);
      }
    }
  }
}

.sponsors {
  &__slider {
    @include mq(sm) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);

      & > .slider {
        grid-column: 2/12;
      }
    }
  }
}

.sponsor {
  padding: 0 $padding__base * 2;

  &__link {
    transition: $transition__duration;
    &:hover {
      opacity: 0.7;
    }
  }

  &__logo {
    display: block;
    max-height: 94px;
    width: auto;
    max-width: 100%;
  }
}
</style>