<template>
  <app-modal
    class="modal"
    @close="emit('close')"
  >
    <template #body>
      <article class="card__padding">
        <slot name="title" />
      </article>

      <fieldset class="card__padding export__options">
        <input-radio
          v-model="selected_option"
          :data="options"
        />
      </fieldset>

      <article class="card__padding">
        <app-save-button
          class="btn btn__size--small btn__color--prim d-inline-flex"
          :is-loading="isLoading"
          :disabled="isLoading"
          @click="handleClickButton()"
        >
          <template #icon>
            <fa-icon
              class="icon"
              :icon="['fal', 'download']"
              fixed-width
            />
          </template>

          {{ t('literal.download') }}
        </app-save-button>
      </article>
    </template>
  </app-modal>
</template>

<script setup>
import { ref, computed, toRefs, defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'

import AppSaveButton from '&/molecules/AppSaveButton'
const InputRadio = defineAsyncComponent(() => import('&/atoms/InputRadio'))
const AppModal = defineAsyncComponent(() => import('&/organisms/AppModal'))

const props = defineProps({ isLoading: { type: Boolean } })
const emit = defineEmits(['export', 'close'])

const { t } = useI18n()
const { isLoading } = toRefs(props)

const options = [
  {
    label: t('literal.csv_file') + ' (.csv)',
    value: 'csv',
    extension: 'csv'
  },
  {
    label: t('literal.excel_file') + ' (.xls)',
    value: 'excel',
    extension: 'xls'
  }
]

const selected_option = ref(options[0].value)

const selectedOption = computed(() => options.find(option => option.value === selected_option.value))

const handleClickButton = () => {
  emit('export', {
    format: selectedOption.value.value,
    extension: selectedOption.value.extension
  })
}
</script>

<style lang="scss" scoped>
.modal {
  &:deep(.modal__container) {
    width: 412px;
    border-radius: 7px;

    .modal__btn--close {
      top: 18px;
      right: 18px;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.export {
  &__options {
    background-color: rgba($dg, .05);

    &:deep(.radio__list):not(:last-child) {
      margin-bottom: 12px;
    }
  }
}
</style>