<template>
  <footer
    v-if="section"
    class="footer padding__t--48"
  >
    <div class="wrapper footer__wrapper padding__b--24">
      <section class="footer__column">
        <router-link
          class="footer__logo"
          :to="{ name: 'Home', params: { locale: $i18n.locale } }"
          v-if="client.logo.url"
        >
          <img
            v-if="client.logo"
            :src="client.logo.url"
            :alt="client.name"
          >
        </router-link>

        <div
          class="editor__content footer__content margin__t--24"
          v-if="$i18n.translate(column1.content)"
          v-html="$i18n.translate(column1.content)"
        />
      </section>

      <section
        v-if="column2.navigation.length"
        class="footer__column"
      >
        <nav class="footer__nav">
          <ul
            class="footer__ul"
            v-for="(links, index) in navigation"
            :key="`footer_nav_${index}`"
          >
            <li
              class="footer__li"
              v-for="({ link }, i) in links"
              :key="`footer_menu_item_${i}`"
            >
              <button
                class="footer__link"
                v-if="link && link.show && $i18n.translate(link.text)"
                @click.prevent="setRouteNavigation(link)"
              >
                {{ $i18n.translate(link.text) }}
              </button>
            </li>
          </ul>
        </nav>
      </section>
    </div>

    <div
      class="wrapper d-flex justify-content-center justify-content-md-end padding__b--24"
      v-if="socialNetworks.length > 0"
    >
      <ul class="networks__ul">
        <li
          class="networks__li"
          v-for="(network, i) in socialNetworks"
          :key="'footer_menu_network_' + i"
        >
          <a
            class="networks__a"
            :href="network.url[$i18n.locale]"
            target="_blank"
          >
            <span
              class="networks__span"
              v-if="network.text"
            >
              {{ $i18n.translate(network.text) }}
            </span>

            <fa-icon
              v-if="network.icon"
              size="lg"
              :icon="network.icon"
            />
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'

export default defineComponent({
  name: 'CustomFooterRikolto',
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client'
    }),
    column1 () {
      return _get(this.section, 'attributes.column_1', {})
    },
    column2 () {
      return _get(this.section, 'attributes.column_2', {})
    },
    navigation () {
      const nav = this.column2.navigation
      const half = Math.ceil(nav.length / 2)
      return [nav.slice(0, half), nav.slice(-half + 1)]
    },
    socialNetworks () {
      const links = _get(this.client, 'platform.social_networks') || []

      return links.length > 0
        ? links.map(link => {
          if (link.icon.includes('facebook-square')) {
            link.icon[1] = 'facebook-f'
          } else if (link.icon.includes('linkedin')) {
            link.icon[1] = 'linkedin-in'
          }

          return link
        })
          .filter(link => link.url[this.$i18n.locale])
        : []
    }
  }
})
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 1310px;
  margin: 0 auto;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
}

.footer {
  position: relative;
  background: #3C3934;
  text-align: center;

  @include mq(sm) {
    text-align: left;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: $margin--base * 2;

    @include mq(sm) {
      gap: $margin--base * 5;
      grid-template-columns: 350px 1fr;
    }

    @include mq(lg) {
      gap: $margin--base * 11;
      grid-template-columns: 408px 1fr;
    }
  }

  &__logo {
    max-width: 193px;
    height: auto;
    display: inline-block;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__content {
    color: $white;
    font-size: rem(18px);
    line-height: 1.4;
    max-width: 408px;
    margin-left: auto;
    margin-right: auto;
  }

  &__nav {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0 $margin__base * 2;

    @include mq(sm) {
      padding-top: 33px;
    }

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(lg) {
      gap: 0 $margin__base * 8;
      grid-template-columns: repeat(2, 230px);
    }
  }

  &__li {
    margin: 6px 0;
  }

  &__link {
    color: $white;
    position: relative;
    transition: all .3s;
    text-decoration: none;
    font-size: rem(18px);
    display: block;
    display: inline-block;

    @include mq(sm) {
      text-align: left;
    }

    &:hover, &--active {
      color: #9EAB05;
    }
  }
}

.networks {
  &__ul {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: $margin--base * 2;
    justify-items: center;
    align-items: center;
  }

  &__a {
    color: $white;
    transition: all .3s;
    display: block;
    position: relative;
    font-size: rem(17px);

    &:hover, &:focus {
      color: #9EAB05;
    }
  }

  &__span {
    display: none;
  }
}
</style>
