export default [
  {
    name: 'custom_footer_uzafoundation',
    path: 'modules/pages/uzafoundation/FooterUzafoundation'
  },
  {
    name: 'custom_header_uzafoundation',
    path: 'modules/pages/uzafoundation/HeaderUzafoundation'
  }
]
