import { createApp } from 'vue'
import App from '@/App.vue'
import { createI18n } from '@/vendors/i18n'
import router from '@/routes'
import store from '@/store'
import pinia from '@/stores'
import { useClientStore } from '@/stores/ClientStore'
import { createBranding } from '@/vendors/branding'
import { createFacebook, createGTM, createMixpanel, createStripe, createSentry, createGMaps, createTamaro } from '@/vendors/integrations'
import { createTracking } from '@/vendors/tracking'
import { createHead } from '@unhead/vue'
import FloatingVue from 'floating-vue'
import FontAwesome from '@/vendors/fontawesome/FontAwesomeIconsLibrary'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { get as _get } from 'lodash'

const Koalect = createApp(App)
  .use(FontAwesome)
  .use(FloatingVue)
  .use(createHead())
  .use(pinia)
  .use(store)
  .use(createI18n)

const { fetchClient } = useClientStore(pinia)

fetchClient().then(client => {
  Koalect
    .use(createStripe, { id: process.env.VUE_APP_STRIPE_ID })
    .use(createTamaro)
    .use(createBranding)
    .use(router)
    .use(createMixpanel, { token: process.env.VUE_APP_MIXPANEL_TOKEN, config: { api_host: 'https://api-eu.mixpanel.com', batch_requests: true, debug: false, cross_subdomain_cookie: /koalect.com|koalect.dev/.exec(window.location.host) ? false : true } })
    .use(createGTM)
    .use(createTracking)
    .use(createFacebook, { version: process.env.VUE_APP_FACEBOOK_VERSION })
    .use(createGMaps, { key: process.env.VUE_APP_GOOGLE_MAP_API_KEY })
    .use(createSentry, { enable: false, dsn: 'https://51db06cbf2e54792a6169cfb7be931a3@o190566.ingest.sentry.io/5502608', router, environment: process.env.NODE_ENV })
    .use(VueReCaptcha, { siteKey: _get(client, 'integrations.recaptcha.site_key'), loaderOptions: { autoHideBadge: true, useEnterprise: true } })
    .mount('#app')
})