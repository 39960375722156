import { computed, toValue } from 'vue'
import { useCartStore } from '@/stores/CartStore'
import { useProcedure, useProcedureStep } from '@/composables/modules/procedures'
import { useTracking } from '@/vendors/tracking'

import { get as _get, set as _set } from 'lodash'

export function useProcedureOrder ({ procedure, procedure_execution, products = [] }) {
  const { patchOrder } = useCartStore()
  const tracking = useTracking()
  const { id } = useProcedure({ procedure })
  const { step_index } = useProcedureStep({ procedure })

  const order = computed({
    get: () => _get(toValue(procedure_execution), 'actions_data.order.order_lines', []),
    set: order_lines => {
      _set(toValue(procedure_execution), 'actions_data.order.order_lines', order_lines)

      patchOrder({ id, order: getOrder() })
    }
  })

  const initOrder = () => {
    order.value = order.value
      .map(({ product_id, quantity }) => {
        const product = getProduct(product_id)

        if (!product) return { product_id, quantity: 0 }

        if (!toValue(procedure_execution).id) {
          if (!product.is_in_stock) return { product_id, quantity: 0 }
          if (product.max_quantity && product.max_quantity < quantity) return { product_id, quantity: product.max_quantity }
        }

        return { product_id, quantity }
      })
      .filter(({ quantity }) => quantity > 0)
  }

  const updateOrder = ({ product, quantity = 0 }) => {
    const product_id = _get(toValue(product), 'id')
    const currentQuantity = _get(order.value.find(order_line => order_line.product_id === product_id), 'quantity', 0)

    if (!product_id) return

    order.value = order.value.update({ product_id, quantity })

    if (!currentQuantity || quantity > currentQuantity) {
      tracking.procedure.addToCart({ product: toValue(product), quantity: quantity - currentQuantity, procedure: toValue(procedure), index: toValue(step_index) })
    } else {
      tracking.procedure.removeFromCart({ product: toValue(product), quantity: currentQuantity - quantity, procedure: toValue(procedure), index: toValue(step_index) })
    }
  }

  const getQuantity = product_id => _get(order.value.find(order_line => order_line.product_id === product_id), 'quantity', 0)

  const getOrder = () => order.value.map(({ product_id, quantity }) => {
    const product = getProduct(product_id)

    return Object.assign(product, { quantity, total: product.price * quantity })
  })

  const getProduct = id => toValue(products).find(product => product.id === id)

  return {
    order,
    initOrder,
    getQuantity,
    updateOrder
  }
}