export default class Paginator {
  constructor ({ count = null, current_page = 1, next_page = null, per_page = 1, previous_page = null, total_pages = 1 } = {}) {
    this.count = count
    this.current_page = current_page
    this.next_page = next_page
    this.per_page = per_page
    this.previous_page = previous_page
    this.total_pages = total_pages
  }

  get is_first_page () {
    return this.current_page === 1
  }

  get is_last_page () {
    return this.current_page === this.total_pages
  }
}
