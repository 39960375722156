<template>
  <nav class="nav">
    <ul class="nav__ul">
      <li class="nav__li">
        <router-link
          class="nav__link"
          :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
          :class="{'nav__link--active': $route.name === 'Profile' }"
        >
          {{ $i18n.t('literal.profile') }}
        </router-link>
      </li>

      <li class="nav__li">
        <router-link
          class="nav__link"
          :to="{ name: 'ProfileSettings' }"
          :class="{'nav__link--active': $route.name && $route.name.startsWith('ProfileSettings') }"
        >
          {{ $i18n.t('literal.settings') }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
.nav {
  width: 100%;
  margin-bottom: $margin--base * 2;
  border-bottom: 1px solid $dw;
  font-size: em(14px);

  @include mq(md) {
    margin-bottom: $margin--base * 3;
  }

  &__ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__li {
    &:not(:first-child) {
      margin-left: $margin__base * 2;
    }
  }

  &__link {
    padding: $padding__base;
    color: $lg;
    display: block;
    border: 1px solid transparent;

    &:focus {
      border: 1px solid $black;
    }

    &--active {
      color: $black;
      font-weight: bold;
      position: relative;

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: -1px;
        left: 0;
        background: $color--secondary;
      }
    }
  }
}
</style>
