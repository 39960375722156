<template>
  <app-modal
    @close="emit('cancel')"
    size="lg"
  >
    <template #body>
      <section class="modal">
        <h2 class="title__h3 bold">
          {{ t('literal.edit_image') }}
        </h2>

        <cropper
          class="cropper"
          :resize-image="resize_options"
          :src="image"
          :stencil-props="{ aspectRatio: ratio, scalable: false }"
          :min-width="minWidth"
          :min-height="minHeight"
          ref="cropperNode"
        />

        <button
          class="btn btn__size--small btn__color--prim"
          type="button"
          name="save-crop-image"
          @click.prevent="crop"
        >
          <fa-icon
            class="margin__r--6"
            :icon="['fal', 'save']"
          />

          {{ t('literal.save') }}
        </button>

        <div class="margin__t--18">
          <button
            class="link__color--black bold"
            type="button"
            name="button"
            @click.prevent="emit('cancel')"
          >
            {{ t('literal.cancel') }}
          </button>
        </div>
      </section>
    </template>
  </app-modal>
</template>

<script setup>
import { computed, ref, toRefs } from 'vue'
import { useI18n } from '@/vendors/i18n'
import Image from '@/classes/files/Image'
import 'vue-advanced-cropper/dist/style.css'

import { Cropper } from 'vue-advanced-cropper'
import AppModal from '&/organisms/AppModal'

const emit = defineEmits(['cancel', 'crop'])

const props = defineProps({
  file: { type: Object, required: true },
  ratio: { type: Number, default: 3 / 2 },
  minWidth: { type: [String, Number], default: 50 },
  minHeight: { type: [String, Number], default: 50 }
})

const { t } = useI18n()
const { file, ratio, minWidth, minHeight } = toRefs(props)

const resize_options = { touch: false, wheel: false, adjustStencil: true }

const cropperNode = ref()
const crop_coordinates = ref({ width: 0, height: 0, left: 0, top: 0 })

const name = computed(() => [file.value?.filename, file.value?.extension].join('.'))
const image = computed(() => file.value?.toBlobURL())

const crop = () => {
  const { coordinates, canvas } = cropperNode.value.getResult()

  crop_coordinates.value = coordinates

  canvas.toBlob(blob => emit('crop', new Image(blob, name.value)), file.value.type, 0.8)
}
</script>

<style lang="scss" scoped>
.modal {
  text-align: left;
  padding: $padding__base * 1.5;
}

.cropper {
  margin: $margin__base * 2 0;
}
</style>
