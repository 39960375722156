<template>
  <h3
    class="title"
    v-if="isShowed('text') && title"
  >
    {{ title }}
  </h3>
</template>

<script setup>
import { useI18n } from '@/vendors/i18n'
import { useBlock } from '@/composables/modules/templates'
import { computed } from 'vue';

const props = defineProps({ block: { type: Object, required: true } })

const { translate } = useI18n()
const { getText, isShowed } = useBlock(props.block)

const title = computed(() => translate(getText()))
</script>

<style  lang="scss" scoped>
.title {
  color: $lg;
  font-size: em(20px);
}
</style>