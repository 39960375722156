<template>
  <footer v-if="section">
    <img
      class="footerline"
      :src="getImagePath('clients/ulb/footerline.svg')"
      alt="Footer colorline"
    >

    <section class="footer">
      <div class="footer__container container">
        <section class="footer__column col-12 col-sm-6 col-md-4">
          <router-link
            :to="{ name: 'Home', params: { locale: $i18n.locale } }"
            v-if="client.logo.url"
            class="logo"
          >
            <img
              class="logo__img"
              :src="$i18n.translate(column1.logo_src)"
              :alt="client.name"
            >
          </router-link>
        </section>

        <section class="footer__column col-12 col-sm-6 col-md-4">
          <h3
            class="footer__title"
            v-if="$i18n.translate(column2.title)"
          >
            {{ $i18n.translate(column2.title) }}
          </h3>

          <template
            v-for="({ line }, index) in column2.address"
            :key="'footer_address_line_' + index"
          >
            <span class="footer__adress">
              {{ $i18n.translate(line) }}
            </span>
          </template>

          <template
            v-for="({ phone }, index) in column2.phones"
            :key="'footer_phone_' + index"
          >
            <a
              class="footer__link"
              :href="'tel:' + $i18n.translate(phone)"
            >
              {{ $i18n.translate(phone) }}
            </a>
          </template>
        </section>

        <section class="footer__column col-12 col-sm-6 col-md-4">
          <h3
            class="footer__title"
            v-if="$i18n.translate(column3.title)"
          >
            {{ $i18n.translate(column3.title) }}
          </h3>

          <div
            class="social_networks"
            v-if="socialNetworks.length > 0"
          >
            <ul class="networks__ul">
              <li
                class="networks__li"
                v-for="(network, index) in socialNetworks"
                :key="'footer_menu_network_' + index"
              >
                <a
                  class="networks__a"
                  :href="$i18n.translate(network.url)"
                  target="_blank"
                >

                  <span
                    class="networks__span"
                    v-if="network.text"
                  >
                    {{ $i18n.translate(network.text) }}
                  </span>

                  <fa-icon
                    v-if="network.icon"
                    size="lg"
                    :icon="network.icon"
                  />
                </a>
              </li>
            </ul>
          </div>

          <ul
            class="footer__navigation"
            v-if="column3.links.length > 0"
          >
            <li
              class="footer_menu"
              v-for="({ link }, index) in column3.links"
              :key="'footer_menu footer_menu_' + index"
            >
              <a
                class="footer__navigation__link"
                @click.prevent="setRouteNavigation(link)"
              >
                {{ $i18n.translate(link.text) }}
              </a>
            </li>
          </ul>
        </section>
      </div>
    </section>
  </footer>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'
import { getImagePath } from '@/utils/utils'

export default defineComponent({
  name: 'CustomFooterULB',
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  mixins: [
    PageRouteNavigation
  ],
  setup: () => ({ getImagePath }),
  computed: {
    ...mapGetters({ client: 'client/client' }),
    column1 () {
      return _get(this.section, 'attributes.column_1', {})
    },
    column2 () {
      return _get(this.section, 'attributes.column_2', {})
    },
    column3 () {
      return _get(this.section, 'attributes.column_3', {})
    },
    socialNetworks () {
      const links = _get(this.client, 'platform.social_networks') || []
      return links.length > 0 ? links.filter(link => this.$i18n.translate(link.url)) : []
    }
  }
})
</script>

<style lang="scss" scoped>
.footerline {
  width: 100%;
  margin-bottom: -6px;
}

.footer {
  background-color: #00223F;
  padding: $padding__base * 3 $padding__base * 3;
  font-family: $font-family--secondary;
  color: #ffffff;
  font-weight: bold;
  font-size: 13px;

  &__container {
    @include mq(sm) {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }

  &__column {
    margin-left: 0;
    text-align: center;
    margin-bottom: $margin__base * 2;

    @include mq(sm) {
      width: 50%;
      text-align: left;
      margin-bottom: 0;
    }

    @include mq(md) {
      width: auto;
    }
  }

  &__title {
    margin-bottom: $margin__base * 2;
  }

  &__adress {
    margin-bottom: $margin__base;
  }

  &__link {
    font-family: $font-family--secondary;
    color: #ffffff;
    font-weight: bold;
  }

  svg {
    font-size: 16px;
  }

  &__navigation {
    margin-top: $margin__base * 4;

    .footer_menu {
      display: inline-block;
      padding: 0 $padding__base;
      border-right: 1px solid #ffffff;

      .footer__navigation__link {
        font-family: $font-family--secondary;
        color: #ffffff;
        font-weight: bold;
      }

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        border-right: 0;
      }
    }
  }

  &__link {
    display: block;

    &:first-of-type {
      margin-top: 20px;
    }
  }

  &__adress {
    display: block;
  }

  .networks {
    &__ul {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: $margin--base * 2;
      justify-items: center;
      align-items: center;
      max-width: 180px;
      margin: auto;

      @include mq(sm) {
        margin: inherit;
      }
    }

    &__a {
      color: $black;
      transition: all .3s;
      font-family: $font-family--secondary;
      color: #ffffff;
      font-weight: bold;

      &:hover, &:focus {
        color: $color--primary;
      }
    }

    &__span {
      display: none;
    }
  }

  .logo {
    display: block;

    &__img {
      max-width: 100%;
      display: block;
      margin: auto;

      @include mq(sm) {
        margin: 0;
      }

      @include mq(lg) {
        max-width: 300px;
      }
    }
  }
}
</style>
