import { computed, toValue } from 'vue'
import { useCartStore } from '@/stores/CartStore'
import { useProcedure } from '@/composables/modules/procedures'
import { get as _get, set as _set } from 'lodash'

export function useProcedureFee ({ procedure, procedure_execution }) {
  const { patchFee } = useCartStore()
  const { id } = useProcedure({ procedure })

  const action = computed(() => _get(toValue(procedure), 'actions.fee'))
  const amount = computed(() => fee.value ? _get(action.value, 'parameters.amount', 0) : 0)

  const fee = computed({
    get: () => _get(toValue(procedure_execution), 'actions_data.fee.active', !!action.value),
    set: value => {
      _set(toValue(procedure_execution), 'actions_data.fee.active', toValue(value))

      patchFee({ id, fee: amount })
    }
  })

  return {
    fee,
    amount,
    action
  }
}