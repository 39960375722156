<template>
  <div
    class="btn__container"
    v-if="translate(attributes.label)"
  >
    <a
      :href="translate(attributes.url)"
      :class="{'btn--disabled': !translate(attributes.url)}"
      target="_blank"
      rel="noreferrer"
      class="btn btn__size--classic btn__color--prim"
    >
      {{ translate(label) }}
    </a>
  </div>
</template>

<script setup>
import { inject, toRefs } from 'vue'
import { useModule } from '@/composables/app/useModule'
import { useI18n } from '@/vendors/i18n'

const props = defineProps({ block: { type: Object, required: true } })

const { block } = toRefs(props)

const procedure_execution = inject('procedure_execution')

const { translate } = useI18n()
const { label, attributes } = useModule({ component: block, parent: procedure_execution })
</script>
