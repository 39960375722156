<template>
  <div>
    <label
      class="input__label label"
      :for="id"
    >
      {{ translate(label) }}
    </label>

    <div class="d-flex justify-content-between align-items-center margin__t--6">
      <input-email
        class="flex-grow-1 margin__r--12"
        :id="id"
        v-model="model"
        :v="validator"
      />

      <button
        class="link__color--black-danger"
        type="button"
        :aria-label="t('literal.delete')"
        @click.prevent="emit('delete')"
      >
        <fa-icon :icon="['fal', 'trash']" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, toRefs } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useProcedureBlockValidator } from '@/composables/modules/procedures'
import { guid } from '@/utils/utils'
import { email } from '@vuelidate/validators'

import InputEmail from '&/atoms/InputEmail'

const emit = defineEmits(['update:modelValue', 'delete'])

const props = defineProps({
  modelValue: { type: String, default: '' },
  label: Object
})

const { modelValue } = toRefs(props)

const id = `field${guid(4)}`

const model = computed({
  get: () => modelValue.value,
  set: email => emit('update:modelValue', email)
})

const { t, translate } = useI18n()
const { validator } = useProcedureBlockValidator({ validations: { email }, state: model })
</script>

<style lang="scss" scoped>
.label {
  font-size: rem(14px);
}
</style>