import Customer from '@/classes/customers/Customer'

export default class Contribution {
  constructor ({ id = null, amount = 0, created_at = null, customer = {}, donation_amount = 0, message = null, private_message = null, order_amount = 0, ordered_products = [], payment_method = null } = {}) {
    this.id = id
    this.amount = amount
    this.created_at = created_at
    this.customer = new Customer(customer)
    this.donation_amount = donation_amount
    this.message = message
    this.private_message = private_message
    this.order_amount = order_amount
    this.ordered_products = ordered_products
    this.payment_method = payment_method
  }
}
