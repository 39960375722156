const Profile = () => import(/* webpackChunkName: 'Profile' */'@/views/profile/ViewProfile')
const ProfileSubscriptionDetails = () => import(/* webpackChunkName: 'ProfileSubscriptionDetails' */'@/views/profile/ViewProfileSubscriptionDetails')
const ProfileSettings = () => import(/* webpackChunkName: 'ProfileSettings' */'@/views/profile/ViewProfileSettings')
const ProfileExportData = () => import(/* webpackChunkName: 'ProfileExportData' */'@/views/profile/ViewProfileExportData')
const ProfileSettingsPersonalInfo = () => import(/* webpackChunkName: 'ProfileSettingsPersonalInfo' */'@/views/profile/ViewProfileSettingsPersonalInfo')
const ProfileSettingsAddress = () => import(/* webpackChunkName: 'ProfileSettingsAddress' */'@/views/profile/ViewProfileSettingsAddress')
const ProfileSettingsProfilePicture = () => import(/* webpackChunkName: 'ProfileSettingsProfilePicture' */'@/views/profile/ViewProfileSettingsProfilePicture')
const ProfileSettingsIntegrations = () => import(/* webpackChunkName: 'ProfileSettingsProfilePicture' */'@/views/profile/ViewProfileSettingsIntegrations')
const ProfileSettingsChangePassword = () => import(/* webpackChunkName: 'ProfileSettingsChangePassword' */'@/views/profile/ViewProfileSettingsChangePassword')
const ProfileSettingsLanguage = () => import(/* webpackChunkName: 'ProfileSettingsLanguage' */'@/views/profile/ViewProfileSettingsLanguage')

const routes = [
  {
    path: 'profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true,
      layout: 'full'
    }
  },
  {
    path: 'profile/subscription/:id',
    name: 'ProfileSubscriptionDetails',
    component: ProfileSubscriptionDetails,
    props: true,
    meta: {
      requiresAuth: true,
      layout: 'header'
    }
  },
  {
    path: 'profile/settings',
    name: 'ProfileSettings',
    component: ProfileSettings,
    meta: {
      requiresAuth: true,
      layout: 'header'
    }
  },
  {
    path: 'profile/export-data',
    name: 'ProfileExportData',
    component: ProfileExportData,
    meta: {
      requiresAuth: true,
      layout: 'yield'
    }
  },
  {
    path: 'profile/settings/personal-info',
    name: 'ProfileSettingsPersonalInfo',
    component: ProfileSettingsPersonalInfo,
    meta: {
      requiresAuth: true,
      layout: 'header'
    }
  },
  {
    path: 'profile/settings/address',
    name: 'ProfileSettingsAddress',
    component: ProfileSettingsAddress,
    meta: {
      requiresAuth: true,
      layout: 'header'
    }
  },
  {
    path: 'profile/settings/profile-picture',
    name: 'ProfileSettingsProfilePicture',
    component: ProfileSettingsProfilePicture,
    meta: {
      requiresAuth: true,
      layout: 'header'
    }
  },
  {
    path: 'profile/settings/integrations',
    name: 'ProfileSettingsIntegrations',
    component: ProfileSettingsIntegrations,
    meta: {
      requiresAuth: true,
      layout: 'header'
    }
  },
  {
    path: 'profile/settings/change-password',
    name: 'ProfileSettingsChangePassword',
    component: ProfileSettingsChangePassword,
    meta: {
      requiresAuth: true,
      layout: 'header'
    }
  },
  {
    path: 'profile/settings/language',
    name: 'ProfileSettingsLanguage',
    component: ProfileSettingsLanguage,
    meta: {
      requiresAuth: true,
      layout: 'header'
    }
  }
]

export default routes
