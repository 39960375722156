<template>
  <section
    class="banner background"
    :style="`background-image: url(${ translate(banner.background) })`"
    v-if="isVisible('banner')"
  >
    <div class="banner__videos">
      <video
        data-device="desktop"
        class="banner__video"
        autoplay
        muted
        loop
        playsinline
        v-if="translate(banner.video_desktop)"
      >
        <source
          :src="translate(banner.video_desktop)"
          type="video/mp4"
        >
      </video>

      <video
        data-device="mobile"
        class="banner__video"
        autoplay
        muted
        loop
        playsinline
        v-if="translate(banner.video_mobile)"
      >
        <source
          :src="translate(banner.video_mobile)"
          type="video/mp4"
        >
      </video>
    </div>

    <img
      class="banner__logo"
      :src="translate(banner.logo)"
      alt="Expeditie Natuurpunt 2023"
    >

    <div class="banner__box">
      <app-link
        :link="banner.button"
        class="banner__cta"
      />
    </div>

    <hr class="separation banner__separation">
  </section>

  <section
    class="description"
    v-if="isVisible('description')"
  >
    <div class="container">
      <p class="description__dates">
        {{ translate(description.dates) }}
      </p>

      <ul class="description__ul">
        <li class="description__li">
          <div
            class="editor editor__content"
            v-if="translate(description.left_text)"
            v-html="translate(description.left_text)"
          />
        </li>

        <li class="description__li">
          <div
            class="editor editor__content"
            v-if="translate(description.middle_text)"
            v-html="translate(description.middle_text)"
          />
        </li>

        <li class="description__li">
          <div
            class="editor editor__content"
            v-if="translate(description.right_text)"
            v-html="translate(description.right_text)"
          />
        </li>
      </ul>
    </div>

    <hr class="separation description__separation">
  </section>

  <section
    class="infos"
    v-if="isVisible('infos')"
  >
    <article
      class="infos__article background"
      :style="`background-image: url(${ translate(infos.left.background) })`"
    >
      <app-link
        :link="infos.left.cta"
        class="infos__cta"
      />
    </article>

    <article
      class="infos__article background"
      :style="`background-image: url(${ translate(infos.right.background) })`"
    >
      <app-link
        :link="infos.right.cta"
        class="infos__cta"
      />
    </article>

    <hr class="separation infos__separation infos__separation--top">
    <hr class="separation infos__separation infos__separation--bottom">
  </section>

  <section
    class="routes background"
    v-if="isVisible('routes')"
    :style="`background-image: url(${ translate(routes.background) })`"
  >
    <h3 class="routes__title">
      {{ translate(routes.title) }}
    </h3>

    <app-link
      :link="routes.button"
      class="routes__cta"
      v-slot="{ text }"
    >
      <svg
        class="routes__svg"
        x="0px"
        y="0px"
        width="164.13px"
        height="69.73px"
        viewBox="0 0 164.13 69.73"
        style="enable-background:new 0 0 164.13 69.73;"
        xml:space="preserve"
      >
        <defs />
        <g>
          <defs>
            <rect
              id="SVGID_1_"
              y="0"
              width="164.13"
              height="69.73"
            />
          </defs>
          <clipPath id="SVGID_2_">
            <use
              xlink:href="#SVGID_1_"
              style="overflow:visible;"
            />
          </clipPath>
          <path
            style="&amp;st0;"
            d="M121.98,7.63c0.56,0.45,0-0.06,0.61-0.12c-0.01,0.17-0.02,0.34,0.12,0.36
          C122.55,8.22,121.98,8.08,121.98,7.63"
          />
          <path
            style="&amp;st0;"
            d="M161.83,31.61c0.02-0.26,0.3-0.26,0.61-0.24C162.52,31.79,162.01,31.81,161.83,31.61"
          />
          <path
            style="&amp;st0;"
            d="M146.45,31.86c-0.77,0.06-1.45,0.35-2.06,0C144.87,31.21,145.84,31.65,146.45,31.86"
          />
          <path
            style="&amp;st0;"
            d="M147.54,61.29h0.36c-0.02,0.3-0.02,0.59,0.24,0.6C147.77,62.28,147.27,61.62,147.54,61.29"
          />
          <path
            style="&amp;st0;"
            d="M107.69,62.14c0.11,0.07,0.22,0.15,0.24,0c0.4,0.18-0.21,0.34-0.24,0.49
          C107.39,62.6,107.65,62.21,107.69,62.14"
          />
          <path
            style="&amp;st0;"
            d="M146.57,66.26c0.13,0.03,0.08,0.24,0.24,0.24c-0.01,0.36-0.72,0.32-0.6-0.12c0.1,0.07,0.2,0.13,0.36,0.12
          V66.26z"
          />
          <path
            style="&amp;st0;"
            d="M123.8,66.98c0.08,0.44-0.08,0.64-0.24,0.85c-0.67,0.14-1.2-0.49-1.09-0.73
          C122.81,66.47,123.31,66.71,123.8,66.98"
          />
          <path
            style="&amp;st0;"
            d="M119.92,68.8c0.1,0.06,0.2,0.13,0.36,0.12c0.03,0.21-0.34,0.6,0,0.73C120,70,119.93,69.12,119.92,68.8"
          />
          <path
            style="&amp;st0;"
            d="M53.91,67.59c0.59-0.1-0.15,0.18-0.12,0.36c0.38,0.06,0.32-0.42,0.61-0.12v-0.48
          C54.16,67.29,53.62,67.52,53.91,67.59 M49.06,67.59c0.18,0.02,0.11,0.29,0.12,0.49c0.42,0.06,0.14-0.59,0.49-0.61
          C49.51,67.3,49.08,67.28,49.06,67.59 M139.79,66.14c0.21,0.05-0.03,0.16,0,0.36c0.57,0.04,0.89,0.3,0.97-0.49
          C140.51,65.78,139.96,65.93,139.79,66.14 M142.82,66.01c0.05,0.21-0.18,0.31,0,0.36c0.04-0.25,0.33,0.18,0.36-0.24
          c-0.35-0.22-0.55-0.02-0.6-0.36c-0.23-0.03-0.27,0.14-0.49,0.12v0.24c0.15,0.01,0.17,0.15,0.36,0.12
          C142.43,65.78,142.64,66.3,142.82,66.01 M77.77,66.01c0.03-0.39-0.14-0.58-0.49-0.61C77.42,65.64,77.46,65.97,77.77,66.01
            M146.21,64.8c-0.28-0.03-0.28-0.32-0.12-0.48c-0.44,0.08-0.65-0.08-0.85-0.24c0.01,0.21-0.09,0.31-0.24,0.36
          c-0.21-0.12-0.24-0.4-0.48-0.48c-0.34,0.16-0.76-0.01-1.21,0.24c0.12,0.64,0.57,0.93,0.61,1.45c0.21-0.25,0.49-0.08,0.12-0.36
          c0.28,0.04,0.61,0.26,0.36-0.12C145.16,64.98,145.85,64.84,146.21,64.8 M99.09,63.47c0.13,0.22,0.51,0.82,0.73,0.24
          c-0.25-0.07-0.5-0.23-0.24-0.49C99.3,63.19,99.12,63.26,99.09,63.47 M95.82,64.2c0.08-0.38,0.32-0.16,0.61-0.12
          c-0.02-0.51,0.6-0.37,0.48-0.97c-0.65-0.36-1.4,0.11-1.57,0.61C96.02,63.56,95.28,64.14,95.82,64.2 M143.66,62.99
          c0.02-0.14,0.19-0.13,0.36-0.12c0.08-0.45-0.43-0.3-0.61-0.48C143.4,62.68,143.48,62.88,143.66,62.99 M117.86,61.78
          c-0.35,0.6-0.57,0.86-0.73,1.45c0.44,0.31,1.41,0.25,1.33-0.24c0.3,0.18,0.59,0.38,0.97,0.49c0.27-0.38,0.4-0.16,0.85-0.36
          c0.08-0.45-0.4-0.97-0.24-1.09c0.07-0.01,0.14-0.02,0.12-0.12C119.34,62.05,118.63,61.93,117.86,61.78 M145.48,57.54
          c-0.25,0.07-0.8-0.15-0.85,0.12C144.93,57.74,145.35,57.84,145.48,57.54 M147.66,57.66c0.53,0.17,0.76,0.05,0.97-0.36
          C148.25,57.04,147.72,57.01,147.66,57.66 M26.53,50.03c0.19-0.17,0.31-0.42,0.36-0.73c-0.18-0.06-0.31-0.18-0.49-0.24
          c0.21,0.45-0.07,0.19-0.12,0.6C26.86,49.5,26.23,50.46,26.53,50.03 M142.09,45.3c0.2-0.16,0.77,0.04,0.73-0.36
          C142.58,44.59,142.09,45.01,142.09,45.3 M150.45,38.76c0.45-0.42,0.62-0.47,1.33-0.49c-0.22-0.66-1.28-0.14-1.45-0.6
          C150,38.02,150.11,38.48,150.45,38.76 M145.84,36.46c0.35-0.01,0.79,0.07,0.97-0.12v-0.48C146.35,35.91,146.05,36.14,145.84,36.46
            M147.42,36.46c0.01-0.07,0.02-0.14,0.12-0.12c0.31,0.56,1.34,0.34,1.7,0c-0.31-0.13-0.42-0.47-0.61-0.73
          C148.3,35.8,147.3,35.97,147.42,36.46 M148.75,34.52c0.32,0.37,1.78,0.41,1.82-0.24C149.99,34.38,148.95,34.04,148.75,34.52
            M155.05,35.01c-0.14-0.05-0.02-0.58-0.24-0.85c-0.92,0.39-1.94,0.18-2.67-0.24c-0.16,0.32-0.71,0.26-0.97,0.48
          c0.07,0,0.14,0.1,0,0.12C152.17,35.53,154.01,34.65,155.05,35.01 M150.08,33.19c0.72,0.19,1.13,0,1.45-0.48
          c-0.4-0.37-0.59,0.13-1.21-0.24C150.38,32.84,149.95,32.74,150.08,33.19 M154.56,33.19c0.74,0.92,2.46-0.55,2.91,0
          c0.14-0.05,0.6-0.11,0.85-0.36c-1.45-0.32-2.56-0.76-3.88,0c-0.13-0.38-0.49-0.05-0.85-0.36c-0.02,0.14,0.02,0.34-0.12,0.36
          c-0.04-0.4-0.57,0.36-0.48-0.24c-0.24,0.12-0.49,0.24-0.73,0.36c0.33,0.31,0.81,0.48,1.09,0.85
          C154.02,33.86,154.13,33.37,154.56,33.19 M25.93,28.1c-0.57-0.6-2.18-0.85-2.42,0.6c0.88,1.25,2.09-0.03,3.63,0.12
          c-0.26-0.65,0.33-0.76-0.24-1.09C26.57,27.86,26.35,28.08,25.93,28.1 M27.74,26.16c-0.1,0.62-0.74-0.03-0.73,0.6
          c0.21-0.01,0.38,0.03,0.48,0.12C27.38,26.52,28.15,26.42,27.74,26.16 M84.07,2.54c0.15,0.55-0.33,0.47-0.24,0.97
          c0.5,0.41,1.67,0.17,2.06,0.36c0.4-0.05,0.24-0.64,0.73-0.61c0.91,0.85,2.83-0.29,3.88,0.85c0.42-0.84,1.72-0.11,2.3-0.85V2.54
          C90.11,2.11,86.93,2.83,84.07,2.54 M120.53,7.39c0.09-0.02,0.6-0.01,0.85-0.12c0.23,0.17,0.23,0.58,0.61,0.6
          c-0.41,0.44-1.15,0.02-1.09-0.36c-0.71,0.33-2.53,0.74-3.15,0.36c0.03,0.61,0.4,1.14,0.36,1.45c-1.43,0.58,0.5,1.99,1.21,1.33
          c0.06,0.2-0.08,0.47-0.24,0.85c-1.07,0.38-3.27,0.76-2.18,2.18c1.46-0.14,1.91,0.84,2.91,0c1.83,1.22,4.94,0.09,6.66,1.94
          c1.72-0.2,2.23,0.55,3.51,0.24c-0.4,0.85-2.03,0.47-2.66,1.09c0.09,0.52,0.8,0.41,0.97,0.85c-0.84,0.53-2.76-0.01-3.03,1.09
          c0.86,0.69,2.34,0.26,3.51-0.24c0.03-0.44-0.36-0.44-0.36-0.85c1.45-0.25,5.25,0.49,5.69-1.7c-0.64,0.25-1.4-0.14-1.94-0.36
          c0.84-0.68,2.28-0.13,3.27,0c0.34-0.02,0.52-0.21,0.48-0.6c-0.24-0.2-0.45-0.44-0.85-0.49c0.82-0.98,2.36-0.72,3.51-1.21
          c0.87-0.37,1.6-1.07,2.54-1.33c1.13-0.32,2.35-0.28,3.03-1.21c1.83,1.08,3.54,4.02,2.18,6.18c0.71,0.61-0.44,1.67-0.85,1.09
          c-0.57,0.59-0.19,1.05-0.24,2.06c-0.48-0.11-0.31,0.42-0.85,0.24c-0.07,0.25,0.19,0.84-0.24,0.73c0.03,0.23,0.31,0.8,0.49,0.49
          c-0.51,1.82-0.1,5.84-1.45,7.99c1.15,0.58,2.77-0.74,4,0.24c-1.48,0.81-4.23-0.4-4.72,1.45c0.31,0.55,1.17-0.21,1.21,0.61
          c-1.06-0.13-1.26,0.6-1.94,0.85c0.05,0.35,0.43,0.38,0.36,0.85c-0.61-0.16-0.6,0.17-1.21,0.36v0.85c0.75-0.16,1.67-0.16,2.42,0
          c0.2-0.02-0.13-0.51,0-0.85c-0.12-0.24-0.54-0.19-0.48-0.61c0.9-0.06,1.9,0.06,2.79-0.24c-0.1,0.9-0.7,0.58-1.57,0.61
          c-0.32,0.08-0.24,0.57-0.49,0.73c1.38,0.81,3.51,0.78,4.85-0.12c-0.16-0.33-0.3-0.67-0.24-1.21c0.36,0.3,0.82-0.08,0.85-0.48
          c0.03-0.47-0.45-0.44-0.73-0.61c0.68-0.76-0.97-1.72,0-2.79c0.93,0.67,2.33,0.12,3.51,0.24c0.16-0.04,0.19-0.21,0.36-0.24
          c-0.15-0.37-0.41-0.64-0.6-0.97h-0.85c0.28-0.98,2.03,0.08,2.3-0.73c0-0.4-0.39-0.41-0.36-0.85c0.72-0.37,1.57-0.62,1.33-1.94
          c0.44-0.25,0.99-0.38,1.21-0.85c-0.02-0.63-0.59-0.7-0.61-1.33c1.17,0.2,0.81-1.12,1.45-1.45h3.27c0.45-0.36,0.68-0.94,1.33-1.09
          c0.13-0.45-0.3-0.35-0.24-0.73c0.29-0.2,0.43-0.54,0.85-0.61c0.24,0.33,0.88,0.25,0.85,0.85c0.29,0,0.36-0.21,0.73-0.12
          c1.19,1.53,0.86,4.61,1.33,6.54c-0.43,0.44,0.1,1.13-0.24,1.7c-0.25-0.07-0.26-0.19-0.12-0.36c-0.42,0.09-0.18,0.46,0.12,0.48
          c-0.27,0.62-0.59,1.19-1.33,1.33c0.01,0.51,0.43,0.62,0.73,0.85c-0.9,0.33-1.74,1.31-3.03,0.85c0.17,0.54,0.59,0.36,0.97,0.48
          c-0.02,0.75,0.48-0.27,0.73,0.61c-0.35,0.35-0.38-0.24-0.85-0.12c0.69,0.29-0.61,0.47-1.09,0.85c0.06,0.1,0.13,0.2,0.12,0.36
          c0.08,0.36,0.81,0.08,0.85,0.48c0.04,0.32-0.25,0.31-0.48,0.36v-0.49c-0.42,0.14-0.79,0.34-0.73,0.97
          c-0.69-0.18-1.98-1.14-2.54-0.12c-0.45-0.61-0.78,0.53-1.09-0.12c-0.33-0.09-0.24,0.24-0.49,0.24c0.16,0.67,0.85,0.74,1.21,0.73
          c0.26,0.71-0.2,0.93-0.24,1.33c-0.38-0.34-0.63,0.39-0.48,0.48c-0.34,0.28-0.47,0.15-0.97,0.36c0.07-0.26,0.42-0.66,0-0.73
          c0.15-0.22,0.47,0.2,0.6,0.12c0.17-0.12-0.05-0.2,0.24-0.36c-0.11-0.33-0.27,0.37-0.49,0c-0.2-0.24,0.01-0.15,0-0.48
          c-1.24,0.43-2.24-0.44-3.39,0.24c-0.03,0.56,0.92,0.13,0.73,0.85c0.52-0.41,0.65,0.42,1.09,0.48c-0.42,0.25-0.8,0.41-1.09,0.12
          c-0.18,0.11,0.03,0.18-0.12,0.48c0.1,0.35,0.18,0.14,0.49,0.12c-0.78,1.21-2.56,1.3-3.76,1.09c0.1-0.47,0.12-0.63-0.12-0.97
          c-0.37,0.15-0.47,0.09-0.73-0.12c0.25,0.56,0.32,1.22-0.24,1.45c-0.63-0.17-0.8-0.61-1.33-0.61c0.11-0.26,0.37-0.36,0.36-0.73
          c-0.61-0.78-1.49,0.15-2.06,0.12c-0.35-0.1-0.14-0.18-0.12-0.48c-0.28,0.04-0.5,0.14-0.73,0.24c-1.01-1.01-1.71-0.07-3.27-0.12
          c-0.04-0.44-0.48-0.49-0.36-1.09c0.56-0.33,2.87-0.51,2.91,0.12c0.14-0.02,0.1-0.22,0.12-0.36c0.33-0.04,0.57,0.07,0.73,0
          c1.41-0.6-0.89-1.19-0.97-1.57c-1.2,0.22-3.37-0.28-3.76,1.45c0.26,0.27,0.34,0.7,0.85,0.73c-0.17,0.31-0.39,0.58-0.97,0.49
          c-0.47,0.33-0.35,1.06-0.24,1.7c0.59,0.5,1.56,0.62,1.94,1.33c0.46-0.35,0.91,0.16,0.97,0.61c0.41-0.21,0.45,0.04,0.61,0.36
          c1.09-0.02,1.93,0.17,2.54,0.12c0.2,1.05-1.29,0.24-1.7,1.09c-0.37-0.17-0.65,0.05-1.09,0.12c0.7,1.15,0.44,1.54,0.73,2.3
          c0.64-0.33,0.96-0.3,1.45-0.12v0.61c-0.77,0.58-1.9,0.52-2.91,0.61c0.2,0.53-0.07,0.2-0.48,0.48c1.28,1.14,3.72-0.04,5.21,0.24
          c0.86-0.63,2.26-1.32,3.39-0.97c1.03-1.07,2.21-0.23,2.42,1.09c0.8,0,0.87,0.55,1.7,0.24c0.41,0.32,0.43,1.1,0.24,1.7
          c-0.7-0.04-1.46,1.21-0.61,1.58c-0.27,0.41-0.26,1.11-0.85,1.21c0.17,0.16,0.12,0.53,0.12,0.85c-0.54,0.26-0.92,0.69-1.45,0.97
          c0.26,0.1,0.28,0.44,0.24,0.85c-0.59,0.06-1.01,0.28-1.09,0.85c-0.49-0.34-0.81,0-1.33-0.48c-0.35,0.12-0.39,0.38-0.61,0.36
          c-0.4-0.12,0.14-0.24,0.12-0.36c-0.34-0.29-0.82,0.31-1.09,0.48c-0.47-0.41-0.74-0.17-1.09,0.24c0,0.15,0.68-0.03,0.36,0.36
          c0.99-0.08,1.64-0.21,2.18-0.48c-0.08,0.27,0.12,0.69,0.48,0.73c0.13,0.57-0.61,0.28-0.61,0.73c0.01,0.39,0.16,0.65,0.49,0.73
          c-0.05,0.23-0.35,0.22-0.24,0.61c-0.15-0.05-0.25-0.16-0.24-0.36c-0.94,0.5-1.6,0.31-3.03,0.12c-0.05-0.28-0.54-0.32-0.24-0.61
          c-0.75,0.59-0.96,0.91-0.85,1.94c0.66-0.06,1,0.22,1.7,0.12c-0.39,1.16-1.31,1.15-1.82,0.73c-0.1,0.02-0.12,0.12-0.24,0.12
          c0.05,0.54,0.49,0.66,0.73,0.48c0.34,0-0.29,0.33,0.24,0.24c-0.19,0.09,0.03,0.59-0.36,0.48c-0.11,0.29,0.54,0.62,0,0.61
          c0.67,0.53,1.19,0.02,2.06,0.48c0.43-0.03-0.43-0.33,0-0.36c0.99,0.34-0.55,2.02-1.21,1.82c0.31,0.35-0.05,0.75,0,1.21
          c-0.2,0.03-0.21-0.11-0.36-0.12c0.26,0.42-0.22,0.24-0.24,0.85c-0.54-0.1-0.98-0.31-1.58-0.36c0.15,0.74,1.05,0.05,1.58,0.48
          c-0.46,0.68-0.86,0.93-1.82,0.61c-0.22,0.18-0.29,0.52-0.49,0.73c-0.43-0.22-1.2-0.09-1.33-0.61c-0.48,0.31-2,0.55-1.94,0.24
          c-0.97,0.65-2.1,0.33-2.79,0.12c-0.14,0.02-0.1,0.22-0.12,0.36c-0.51-0.17-1.24-0.13-1.57-0.48c-0.03,0.31-1.51,0.07-1.57-0.85
          c0.18-0.07,0.63,0.14,0.6-0.12c0.22,0.09,0.02,0.22,0,0.36c0.5-0.05,0.07-0.36,0.49-0.36c0.1,0.34-0.17,0.31-0.12,0.61
          c0.4-0.19,0.75-0.14,1.33-0.61c-0.13-0.32-0.65-0.13,0-0.36c-0.92,0.13-1.72,0.15-2.18-0.12c-0.27,0.07,0.15,0.19-0.12,0.36
          c-0.36-0.17-0.92-0.13-1.09-0.48c-0.64,0.39-1.61,0.4-2.42,0.36c-0.44-0.88-1.21-0.94-2.3-0.97c-0.41-0.95-1.22-0.07-1.58,0.36
          c-0.02-0.3-0.24-0.35-0.36-0.61c-1.01-0.11-0.9,2.26-2.3,1.33c0.2-0.4-0.06-0.53-0.24-0.85c-0.63,0.41-1.25,0.5-1.45,1.09
          c0.12-0.57-1.49-0.73-2.06-0.61c0.04-0.53-0.36-0.54-0.12-0.97c-0.25-0.87-1.06,0.23-1.33-0.24c-0.17,0.15-0.18,0.46-0.36,0.61
          c-1.09-0.32-1.43-1.02-2.54-1.09c-0.72,0.43-1.43,0.06-1.94,0c-0.1-0.54,0.22-0.67,0.61-0.73c0.35,0.66,1.05-0.28,1.33-0.49
          c-0.3-0.55-1.23-0.47-1.45-1.09c-0.82,0.03-0.71,0.99-1.21,1.33c-0.17-0.12,0.05-0.2-0.24-0.36c-0.4,0.03-0.83,0.44-1.21,0.12
          c-0.14,0.02-0.1,0.22-0.12,0.36c0.86,0.41,1.18,0.82,2.06,0.24c0.13,0.43-0.39,0.55-0.97,0.61c0.12,0.17,0.2-0.05,0.36,0.24
          c-0.54,0.52-1.1-0.89-1.57,0.24c-0.18-0.39-0.44-0.14-0.85-0.12c0.28-0.48-0.23-0.37-0.24-0.85c0.71,0.28,1.02-0.99,0.85-1.33
          c-0.69,0.19-1.07,0.33-1.82,0.12c0.2-0.33,0.21-0.84,0.48-1.09c-0.14-0.33-0.63,0-0.85,0c0.05-0.68-0.42-0.67-0.36-1.09
          c-0.45-0.05-0.68,0.5-1.09,0.12c-0.66,1.12-1.86,1.69-2.3,3.03c0.04,0.17,0.21,0.01,0.24-0.12c0.37,0.18-0.1,0.53,0,0.85
          c-0.71,0.21-1.88,0.75-2.54-0.12c-1.06,0.91-2.23,0.35-3.39,0.49c0.15-0.16,0.05-0.19,0-0.36c-0.43,0.38-0.9,0.31-1.33,0.36
          c-0.21-0.1,0.05-0.74,0.12-0.97c-0.02-0.27-0.31,0.16-0.6,0c0.65,0.99-0.65,1.2-1.45,1.21c-0.42-0.85-1.31-0.02-1.94-0.48
          c-0.14,0.02-0.1,0.22-0.12,0.36c-0.41-0.08-0.45-0.39-0.61,0.12c-0.44-0.48-0.63-0.45-1.45-0.36c0.01-0.29-1.29-0.36-1.45,0.36
          c-0.74-0.3-1.76,0.32-2.42-0.24c-0.2,0.44-1.2,0.5-1.69,0.97c0.02,0.22,0.41,0.07,0.48,0.24c0.22,1.18-3.03,1.1-3.75,0.61
          c-0.23-0.16-0.21-0.51-0.48-0.48c-0.36,0.03-0.42,0.84-0.61,0.24c0.6-0.1-0.33-0.48-0.24-0.85c-0.33,0.36-0.6,0.04-0.97,0.36
          c0.03,0.44,0.17,0.46,0,0.85c-0.1,0.02-0.11-0.05-0.12-0.12H80.8c-0.06-0.26,0.17-0.23,0.36-0.24c-0.08-0.32-0.83-0.34-0.97-0.24
          c0.02,0.14,0.12,0.07,0.12,0c0.42,0.43-0.57,0.47-0.61,0.85c-0.57-0.61-1.69,0.52-2.54-0.24c-0.55,0.45-1.25,0.17-1.45-0.61
          c-0.2,0.32-0.32,0.73-0.49,1.09c-0.77-0.17-0.79-0.92-0.97-1.21c-0.26,0.27-0.67-0.22-0.73,0.36c0.03,0.31,0.21-0.17,0.48,0
          c0.06,0.38-0.3,0.43-0.48,0.24c0.17,0.07,0.02,0.47,0.24,0.48c-0.05,0.23-0.56,0.01-0.36,0.49c-0.5,0.1-0.62-0.19-1.09-0.12
          c-0.02-0.22,0.15-0.26,0.12-0.49c-0.92,0.14-1.36,0.62-2.06,0.61c0.18,0.38,0.42,0.82,0.85,0.6c-0.66,0.57-1.41-0.39-2.06,0.49
          c-0.05-0.39-0.5-0.38-0.61-0.73c-0.24,0-0.15,0.33-0.49,0.24c0.1-0.42,0.34-0.4,0-0.73c-0.16,0.02-0.22,0.5-0.24,0
          c-0.26,0.5-0.82,0.3-1.21,0.48c-0.4,0.19-0.41,0.7-0.61,0.24c0.02,0.05-0.69,0.02-0.12,0.24c-0.2,0.19-0.38,0.05-0.61,0
          c0.07-0.28,0.24-0.73,0.49-0.49c-0.48-0.26-0.91-0.11-1.7-0.48c-0.05,0.15-0.15,0.34-0.49,0.48c-1.63-0.41-2.28-0.75-3.88-0.36
          c-0.16,0.48,0.4,0.25,0.24,0.73c-0.62-0.05-1.12,0.01-1.09,0.6c-0.48,0.09-0.63-0.05-0.85,0.12c-0.04-0.42-0.55-0.34-0.97-0.61
          c0.15,0.36-0.84,0.16-0.85-0.36c-0.57,0.24-0.57,1.04-1.45,0.97c-0.2-0.16-0.45-0.52-0.24-0.73c-0.41,0.32-0.46,0.39-0.97,0.24
          c0.2-0.24-0.01-0.15,0-0.48c-0.47-0.12-0.7,0.27-0.97,0c0.02-0.27,0.51-0.05,0.73-0.12c-0.08-0.75-0.71-0.19-0.36-0.85
          c-0.44,0.09-0.24,0.81-0.97,0.61c0.01,0.31,0.45,0.19,0.61,0.36c-0.03,0.15-0.24,0.12-0.24,0c0.13,0.45-1,0.7-1.21,0.12
          c-1.2,0.98-2.71-0.16-3.76-0.12c-0.68,0.02-2.02,1.14-2.42,0.12c-0.61,0.1-0.65-0.43-1.57-0.73c-0.47,0.29-1.17,0.41-1.33-0.24
          c-0.83,0.24-2.07-0.1-2.3-0.85c-0.66,0.37-1.04,0.11-2.3,0.24c0.06-0.18,0.18-0.31,0.24-0.48c-0.29,0.5-1.39,0.16-1.45,0.36
          c-1.03-0.7-4.42-2.17-6.54-2.18c-1.22-1.43-3.91-0.65-5.45-1.33c-0.91-0.86-2.45-1.1-2.79-2.54c-1.85-0.21-2.57-1.55-3.75-2.42
          c-1.49-0.08-2.23-0.92-3.15-1.58c-1.97-0.41-3.66-1.11-5.09-2.06H8.73c-0.09-0.11-0.13-0.27-0.12-0.49
          c-0.92-0.01-1.88-0.92-2.18-1.94c0.18-0.14,0.34-0.3,0.49-0.48c-0.1-0.74-0.5-1.19-0.61-1.94c1.1-0.66,2.28-0.65,3.15-1.33
          c0.43-0.34,2.27-1.42,2.91-1.58c1.62-0.4,4.22,0.54,5.57,0.48c0.15-0.05,0.25-0.15,0.24-0.36c0.38-0.06,0.49,0.29,0.6-0.12
          c0.21,0.11,0.2,0.45,0.49,0.48c2.99-0.61,6.24,0.11,9.08-1.45c0.09-0.46,0.23-0.94-0.49-0.85c-0.07-0.73-0.89-0.73-1.09-1.33
          c-1.95-0.03-2.09-1.64-3.76-1.7c0-1.9-1.43-1.69-2.54-2.06c0.07-0.44,0.15-0.65-0.24-0.85c0.55-0.57,0.87-1.07,0.73-2.06
          c0.35-0.93,0.88-2.28,0.12-2.67c0.01-0.3,0.23-0.38,0-0.61c-0.14,0.02-0.07,0.12,0,0.12c-0.06-0.04-0.84-0.14-0.49-0.48
          c-0.34-0.1-0.31,0.17-0.61,0.12c-0.04-0.28,0.02-0.47,0.12-0.61c-0.64-0.05-0.23,0.92-0.24,0.97c-0.12,0.21-0.84-0.44-0.61-0.6
          c-0.22-0.02-0.26,0.15-0.49,0.12c-0.04-0.21,0.1-0.23,0.24-0.24c-0.52,0.07-0.37-0.86-0.85-0.73c-0.25-0.11,0.18-0.54,0.48-0.49
          c0,0.12-0.15,0.09-0.24,0.12c0.84,0.18,0.93-0.12,2.06-0.24c-0.2,0.59,0.14,0.97,0.49,1.33c0.24-0.34,0.6-0.74,0.36-1.21
          c0.46,0.06,0.51-0.69,1.09-0.85c-0.39-0.57-0.24-0.41-0.73-0.73c0.24-0.23,0.04-0.44,0-0.73c-0.85-0.08-1.18,0.35-2.06,0.24
          c-0.43-1.06-1.86,0.23-2.3,0.24c-0.34,0.12-0.34-0.69-0.61-0.24c-0.16-0.09,0.1-0.78,0.36-0.85c-0.82-1.32,4.06-1.54,5.81-1.21
          c0.47-0.58,0.59-1.4,0-1.94c0.21-0.46,0.31-0.89,0-1.33c-0.39,0.07-0.56-0.08-0.85-0.12c-0.1-0.6-0.05-0.79,0.24-1.21
          c-0.48-0.12-1.26-0.58-2.06-0.12c0.25,1.11-1.51,1.26-2.54,0.85c0.13-0.46-0.32-0.76,0.12-0.85c-0.17-0.03-0.66-0.14-0.97-0.36
          c-0.31,0.01-0.17,0.56-0.48,0.24c0.04,0.44-0.14,0.67-0.24,0.97c-0.8-0.04-0.94,0.35-1.45,0.24c-0.51,0.74-0.26,1.69-0.73,2.3
          c-0.93-0.24-2.12-0.22-2.67-0.85c0.15-0.73,0.21-0.73-0.12-1.33c-0.91-0.15-0.76-0.36-1.69-0.12c-0.41-0.34-0.65-1.93-1.21-1.33
          C8.09,22.36,7.99,22.25,8,22.05c-1.06,1.29-2.25-0.27-4,0c-0.18-1.13-1.12-1.4-1.7-2.18c-0.69-0.94-2.24-1.94-2.3-2.79
          c-0.12-1.59,1.59-2.02,2.66-2.91c1.16-0.96,1.54-2.14,2.54-2.91c2.52,0.42,4.77,1.05,6.78,0.12c2.07,0.59,5.72-2.49,8.6-1.58
          c0.23-0.05,0.1-0.47,0.49-0.36c-0.02,0.1-0.12,0.12-0.12,0.24c0.3-0.42,1.39-0.06,1.45-0.73c2.11,0.14,3.23-0.84,5.57-0.73
          c1.65-1.04,3.92-0.01,5.81-0.73c1.35,0.38,3.52-0.21,5.21-0.48c2.34-0.38,4.65-0.94,7.03-1.09c0.42-0.03,0.89,0.16,1.21,0.12
          c0.83-0.1,1.77-0.38,3.03-0.36c1.19,0.01,2.45,0.09,3.63,0.12C55,5.85,56.18,5.39,57.3,5.33c-0.01,0,0.01,0.24,0,0.24
          c0.37-0.05,0.6-0.43,0.97-0.49c0.6-0.08,2.86,0.46,3.39-0.85C62.78,4.23,63.33,4.03,64.2,4c0.05,0.23,0.35,0.22,0.24,0.61
          c2.47-0.65,4.61-0.73,7.51-0.85c0.07,0.39-0.23,0.38,0,0.61c1.12-0.56,1.97-1.39,3.39-1.33c0.24-0.08,0.32-0.32,0.36-0.6
          c0.72-0.08,1.22-0.6,1.7,0c-0.7,0.92-0.02,1.53,0.61,2.06c1.74-1.08,2.58-1.43,4.85-1.33c-0.15-0.47,0.02-0.56-0.12-1.09
          c0.89-0.05,1.9,0.06,2.67-0.36c0.49,0.68,1.65,0.22,2.3,0.24c0.89-0.4,0.68-1.9,2.06-1.82c0.1,0.28-0.2,0.7,0.12,1.21
          c0.28-0.13,0.25-0.56,0.73-0.48C90.58,0.5,90.13,0.25,90.37,0c1.6,0.14,2.84,0.21,4.85,0.24v0.49c0.24,0,0.15-0.33,0.48-0.24
          c0.25,0.83,0.35,1.42,1.45,1.57c-0.19,0.29-0.4,0.57-0.36,1.09c0.76,0.51,1.86,0.35,3.27,0.61c-0.17,0.57-1.17,0.65-0.85,1.33
          c1.18-0.41,2.28,0.64,2.91-0.36c0.25,0.19,0.78,0.11,0.85,0.48c1.11-0.31,1.86,0.26,2.79-0.12c1.07-0.44,1.61-2.29,3.51-1.09
          c0.2,0.29,0.27,0.7,0.36,1.09c1.32-0.09,3.03,0.8,4.48,0.61c-0.07,0.39,0.08,0.56,0.12,0.85c0.64-0.37,1.22,0.61,1.7,0
          c0.32,0.12,0.4,0.49,0.61,0.73c0.34,0.1,0.31-0.17,0.61-0.12v0.48c0.63,0.01,0.91,0.21,1.09-0.36c0.94,0.06,2.09,0.26,2.3-0.36
          C120.93,7.01,120.63,7.18,120.53,7.39"
          />
          <path
            style="&amp;st0;"
            d="M144.87,43.97c0.31,0.03-0.17,0.21,0,0.48c-0.57,0.09-0.83-0.14-1.33-0.12c-0.01-0.37,0.26-0.47,0.36-0.73
          c0.49,0.14,0.52,0.53,0.97,0.12C144.89,43.93,144.67,44.25,144.87,43.97"
          />
          <path
            style="&amp;st0;"
            d="M62.39,68.07c0.14,0.02,0.07,0.12,0,0.12c0,0.07,0.61,0.07,0.61,0C63.47,68.65,61.76,68.7,62.39,68.07"
          />
          <path
            style="&amp;st0;"
            d="M121.13,8.96c0.28,0.66-0.98,0.51-1.58,0.85c0.03-0.31-0.94-0.6-0.73,0c-0.41,0.05-0.36-0.36-0.61-0.49
          c0.15-0.29,0.5-0.39,0.85-0.48c0.01,0.22-0.15,0.26-0.12,0.48c0.89-0.21,1.44-0.39,2.3-0.48C121.29,9.06,121.14,9.09,121.13,8.96"
          />
          <path
            style="&amp;st0;"
            d="M63.23,68.44c0.29-0.62,0.58-0.48,1.21-0.73c0.29,0.04,0.27,0.38,0.49,0.48
          C64.18,68.54,64.12,68.2,63.23,68.44"
          />
        </g>
      </svg>

      <span class="routes__span">{{ text }}</span>
    </app-link>

    <hr class="separation routes__separation">
  </section>

  <section
    class="social"
    v-if="isVisible('social') && socialMedias.length > 0 && socialMedias"
  >
    <ul class="social__ul">
      <li
        class="social__li"
        v-for="(media, i) in socialMedias"
        :key="'footer_menu_media_' + i"
      >
        <a
          class="social__link"
          :href="translate(media.url)"
          target="_blank"
        >
          <fa-icon
            v-if="media.icon"
            size="lg"
            :icon="media.icon"
            class="social__icon"
          />
        </a>
      </li>
    </ul>
  </section>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { mapGetters } from '@/store/map-state'
import { get as _get } from 'lodash'
import useSection from '@/composables/modules/pages/useSection'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))

const props = defineProps({
  page: { type: Object, required: true },
  sections: { type: Object, required: true }
})

const { translate } = useI18n()
const { getSectionAttributes, isVisible } = useSection(props.page)
const { client } = mapGetters('client')

const banner = computed(() => getSectionAttributes('banner'))
const description = computed(() => getSectionAttributes('description'))
const infos = computed(() => getSectionAttributes('infos'))
const routes = computed(() => getSectionAttributes('routes'))
const socialMedias = computed(() => {
  const links = _get(client.value, 'platform.social_networks', [])
  return links.length > 0 ? links.filter(link => translate(link.url)) : []
})
</script>

<style lang="scss" scoped>
.banner {
  min-height: 300px;
  padding-top: 80px;
  z-index: 100;

  &__logo {
    display: block;
    margin: auto;
    max-width: 300px;
  }

  &__videos {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  &__video {
    position: relative;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%,-50%);

    &[data-device=desktop] {
      display: none;

      @include mq(sm) {
        display: block;
      }
    }

    &[data-device=mobile] {
      display: block;

      @include mq(sm) {
        display: none;
      }
    }
  }

  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:deep(.banner__cta) {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    margin-top: 50px;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 1.3;
    text-transform: uppercase;
    font-weight: bold;
    padding: 5px 10px;
    width: 240px;
    color: $white;
    overflow: hidden;
    border: 2px solid #bf2631;
    border-radius: 7px;
    background: radial-gradient(ellipse at center,#e93d36 47%,#bc0f0f 86%,#c62632 100%);
    box-shadow: 0 0 15px -2px #1c1c1c;
    transition: all .2s;
    transform-origin: center;
    text-align: center;

    &:hover {
      transform: scale(1.1);
    }

    &:before {
      content: '';
      position: absolute;
      top: -37px;
      left: -29px;
      display: block;
      width: 134%;
      height: 60px;
      border-radius: 50%;
      background: rgba(241,226,206,.35);
      transform: rotate(-4deg);
    }
  }

  &__separation {
    position: relative;
    background-image: image-path('expeditienatuurpunt/banner_separation_mobile.svg');
    background-position: bottom;
    background-size: 101%;
    min-height: 100px;
    width: 101%;
    bottom: -1px;

    @include mq(sm) {
      background-image: image-path('expeditienatuurpunt/banner_separation_desktop.svg');
      background-size: 140%;
      min-height: 120px;
    }

    @include mq(xl) {
      background-size: 110%;
    }
  }
}

.description {
  background-color: $white;
  padding: 40px 0 40px;
  text-align: center;
  position: relative;

  &__dates {
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    color: $color--danger;

    @include mq(md) {
      font-size: 30px;
    }
  }

  &__ul {
    display: grid;
    grid-template-columns: repeat(1, 320px);
    justify-content: center;
    align-items: center;

    @include mq(sm) {
      grid-template-columns: repeat(3, 270px);
    }
  }

  &__li {
    padding: 18px 0;
    font-size: 18px;
    text-transform: uppercase;
    color: $color--secondary;

    &:not(:last-child) {
      border-bottom: 2px dotted #222;
    }

    @include mq(sm) {
      padding: 0 12px;

      &:not(:last-child) {
        border-right: 2px dotted #222;
        border-bottom: 0;
      }
    }

    @include mq(md) {
      font-size: 20px;
    }
  }

  &__separation {
    background-image: image-path('expeditienatuurpunt/description_separation_mobile.svg');
    background-position: top;
    background-size: 105%;
    min-height: 35px;
    width: 101%;
    z-index: 999;
    position: absolute;
    top: 100%;

    @include mq(sm) {
      background-image: image-path('expeditienatuurpunt/description_separation_desktop.svg');
    }
  }
}

.infos {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  position: relative;

  @include mq(sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  &__article {
    min-height: 370px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      border-right: 12px solid $white;
    }

    @include mq(sm) {
      min-height: 500px;
    }
  }

  &:deep(.infos__cta) {
    font-family: $font-family--secondary;
    font-size: 34px;
    line-height: 1.2;
    padding: 12px 24px;
    color: #fff;
    transition: all .2s;
    background-color: rgba(#e0001a, .8);
    border: 1px solid #e0001a;

    &:hover {
      background-color: rgba(#e0001a, .9);
    }
  }

  &__separation {
    background-size: 101%;
    width: 101%;
    z-index: 999;
    position: absolute;
    min-height: 35px;

    &--top {
      background-image: image-path('expeditienatuurpunt/description_separation_mobile.svg');
      background-position: top;
      top: 100%;

      @include mq(sm) {
        background-image: image-path('expeditienatuurpunt/description_separation_desktop.svg');
      }
    }

    &--bottom {
      background-image: image-path('expeditienatuurpunt/routes_separation_mobile.svg');
      background-position: bottom;
      bottom: -1px;

      @include mq(sm) {
        background-image: image-path('expeditienatuurpunt/routes_separation_desktop.svg');
      }
    }
  }
}

.routes {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $white;
  min-height: 400px;

  @include mq(sm) {
    min-height: 550px;
  }

  &__title {
    font-size: 28px;
    font-family: $font-family--secondary;
    display: block;
    max-width: 80%;
    text-align: center;

    @include mq(sm) {
      font-size: 40px;
    }

    @include mq(md) {
      font-size: 48px;
    }
  }

  &:deep(.routes__cta) {
    font-size: 20px;
    font-weight: bold;
    padding: 20px 0;
    position: relative;
    margin-top: 40px;
    color: $color--tertiary;
    transition: all .2s;

    @include mq(sm) {
      font-size: 24px;
      margin-top: 100px;
    }

    &:hover {
      color: $white;

      .routes__svg {
        fill: $color--tertiary;
      }
    }
  }

  &__span {
    position: relative;
    z-index: 0;
    display: block;
    text-align: center;
    max-width: 115px;
  }

  &__svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: $white;
    width: auto;
    height: 100%;
    z-index: 0;
    transition: all .2s;
  }

  &__separation {
    background-image: image-path('expeditienatuurpunt/routes_separation_mobile.svg');
    background-position: bottom;
    background-size: 105%;
    min-height: 35px;
    width: 101%;
    z-index: 999;
    position: absolute;
    bottom: 0;

    @include mq(sm) {
      background-image: image-path('expeditienatuurpunt/routes_separation_desktop.svg');
    }
  }
}

.social {
  background-color: $white;
  position: relative;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 2px dotted #222;

  &__ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
  }

  &__link:hover .social__icon {
    color: $color--tertiary;
  }

  &__icon {
    font-size: 87px;
    color: #7aa386;
    transition: all .2s;
  }
}

.background {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.separation {
  background-repeat: no-repeat;
  display: block;
  margin: 0;
  border: 0;
}
</style>