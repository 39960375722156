<template>
  <div class="custom">
    <section
      class="banner"
      :style="{backgroundImage: `url(${$i18n.translate(banner.background)})`}"
    >
      <div class="wrapper banner__wrapper">
        <div class="banner__text-wrapper">
          <h1
            class="banner__title"
            v-if="$i18n.translate(banner.title)"
          >
            {{ $i18n.translate(banner.title) }}
          </h1>

          <div
            class="banner__content editor__content margin__t--18"
            v-if="$i18n.translate(banner.content)"
            v-html="$i18n.translate(banner.content)"
          />

          <div
            class="goal margin__t--30"
            v-if="$i18n.translate(banner.goal.title) && banner.goal.items.length > 0"
          >
            <p class="goal__title">
              {{ $i18n.translate(banner.goal.title) }}
            </p>

            <ul class="goal__items margin__t--6">
              <template v-for="(goal, index) in banner.goal.items">
                <li
                  class="goal__item"
                  v-if="$i18n.translate(goal.text)"
                  :key="`goal_${index}`"
                >
                  {{ $i18n.translate(goal.text) }}
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <ul
      class="metrics"
      v-if="statistics && metrics.items.length > 0"
    >
      <li
        class="metrics__item"
        v-for="(metric, index) in metrics.items"
        :key="`metric_${index}`"
      >
        <strong>
          <app-date
            v-if="metric.type === 'date' && metric.date"
            :timestamp="metric.date"
            filter="differenceInCalendarDays"
          />

          <app-money
            v-else-if="metric.type === 'amount_collected'"
            :amount="statistics[metric.type]"
          />

          <template v-else-if="metric.type === 'sporting_statistics'">
            {{ getSportingStatistic(metric.sporting_statistics) }}
          </template>

          <template v-else>
            {{ statistics[metric.type] }}
          </template>
        </strong>

        <span class="d-block">{{ $i18n.translate(metric.text) }}</span>
      </li>
    </ul>

    <section
      class="events"
      v-if="$i18n.translate(events.title) || $i18n.translate(events.content) || events.items.length > 0"
    >
      <i
        class="events__illustration"
        v-if="events.show_illustration"
      />

      <div class="wrapper events__wrapper">
        <div class="events__text-wrapper">
          <h2
            class="title--2 events__title"
            v-if="$i18n.translate(events.title)"
          >
            {{ $i18n.translate(events.title) }}
          </h2>

          <div
            class="events__content editor__content margin__t--24"
            v-if="$i18n.translate(events.content)"
            v-html="$i18n.translate(events.content)"
          />
        </div>

        <ul
          class="events__items margin__t--48"
          v-if="events.items.length > 0"
        >
          <li
            class="event"
            :style="{ backgroundImage: `url(${$i18n.translate(event.background)})` }"
            v-for="(event, index) in events.items"
            :key="`event_${index}`"
          >
            <article
              class="event__content"
              :class="{'event__content--padding': !event.cta.show || !$i18n.translate(event.cta.text)}"
            >
              <h3
                class="event__name"
                :class="{'event__name--large': $i18n.translate(event.name).length <= 25 }"
                v-if="$i18n.translate(event.name)"
              >
                {{ $i18n.translate(event.name) }}
              </h3>

              <button
                class="cta cta__color--blue margin__t--18"
                @click.prevent="setRouteNavigation(event.cta)"
                v-if="event.cta && event.cta.show && $i18n.translate(event.cta.text)"
              >
                {{ $i18n.translate(event.cta.text) }}
              </button>
            </article>
          </li>
        </ul>
      </div>
    </section>

    <section
      class="projects"
      v-if="projects.show && project_cards.length > 0"
    >
      <i
        class="projects__illustration"
        v-if="projects.show_illustration"
      />

      <div class="wrapper">
        <h2
          class="title--2 projects__title"
          v-if="$i18n.translate(projects.title)"
        >
          {{ $i18n.translate(projects.title) }}
        </h2>

        <div class="projects__items row">
          <section
            class="col-12 col-sm-6 col-md-3"
            v-for="project in project_cards"
            :key="project.id"
          >
            <project-card
              class="project-card"
              :project="project"
            />
          </section>
        </div>

        <div class="projects__action">
          <button
            class="cta cta__color--green"
            @click.prevent="setRouteNavigation(projects.cta)"
            v-if="projects.cta && projects.cta.show && $i18n.translate(projects.cta.text)"
          >
            {{ $i18n.translate(projects.cta.text) }}
          </button>
        </div>
      </div>
    </section>

    <section
      class="supporters"
      v-if="supporters.show && supporters.items.length > 0"
    >
      <i
        class="supporters__illustration"
        v-if="supporters.show_illustration"
      />

      <div class="wrapper">
        <h2
          class="title--2 supporters__title"
          v-if="$i18n.translate(supporters.title)"
        >
          {{ $i18n.translate(supporters.title) }}
        </h2>

        <div class="supporters__items">
          <article
            class="supporter"
            v-for="(supporter, index) in supporters.items"
            :key="`supporter_${index}`"
          >
            <figure
              class="supporter__avatar"
              :style="{ backgroundImage: `url(${$i18n.translate(supporter.image)})` }"
            >
              <figcaption>{{ $i18n.translate(supporter.name) }}</figcaption>

              <fa-icon
                :icon="['far', 'image']"
                v-if="!$i18n.translate(supporter.image)"
              />
            </figure>

            <h3
              class="supporter__name margin__t--24"
              v-if="$i18n.translate(supporter.name)"
            >
              {{ $i18n.translate(supporter.name) }}
            </h3>
          </article>
        </div>
      </div>
    </section>

    <section
      class="gallery"
      v-if="gallery.show && galleryImages.length > 0"
    >
      <i
        class="gallery__illustration"
        v-if="gallery.show_illustration"
      />

      <div class="wrapper">
        <div
          class="gallery__grid"
          :class="`gallery__grid--${images.length}`"
          v-for="(images, index) in galleryImages"
          :key="`gallery_row_${index}`"
        >
          <figure
            v-for="(image, i) in images"
            class="gallery__image"
            :class="`gallery__image__${i}`"
            :key="`gallery_row_${index}_image_${i}`"
            :style="{ backgroundImage: `url(${$i18n.translate(image.image)})` }"
          >
            <fa-icon
              :icon="['far', 'image']"
              v-if="!$i18n.translate(image.image)"
            />
          </figure>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'
import { projects_overview_options } from '@/configurations/general/components-default-data'

export default defineComponent({
  name: 'CustomRikoltoCampaign',
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  components: {
    AppMoney: defineAsyncComponent(() => import('&/atoms/AppMoney')),
    ProjectCard: defineAsyncComponent(() => import('&/organisms/ProjectCard')),
    AppDate: defineAsyncComponent(() => import('&/atoms/AppDate'))
  },
  mixins: [
    PageRouteNavigation
  ],
  data () {
    return {
      project_cards: []
    }
  },
  created () {
    if (this.metrics.items.length)
      this.fetchClientStatistics({ query: this.metrics.query })

    if (this.projects.show) this.fetchProjects({ query: this.projectsQuery, removeStoredItems: true }).then(data => this.project_cards = Object.values(data.items))
  },
  computed: {
    ...mapGetters({
      statistics: 'client/statistics',
      getProjects: 'projects/getProjects'
    }),
    attributes () {
      return _get(this.section, 'attributes', {})
    },
    banner () {
      return _get(this.attributes, 'banner', {})
    },
    metrics () {
      return _get(this.attributes, 'metrics', [])
    },
    events () {
      return _get(this.attributes, 'events', {})
    },
    projects () {
      return _get(this.attributes, 'projects', {})
    },
    projectsQuery () {
      const sort = projects_overview_options.find(option => option.value === _get(this.projects, 'query.sort')) || { 'sort_by_field[field]': 'current_amount', 'sort_by_field[direction]': 'desc' }

      return { ..._get(this.projects, 'query', { 'per_page': 4 }), ...sort.query }
    },
    supporters () {
      return _get(this.attributes, 'supporters', {})
    },
    gallery () {
      return _get(this.attributes, 'gallery', {})
    },
    galleryImages () {
      let images = []
      let items = _get(this.gallery, 'items', [])
      let splitIndex = 5

      while (items.length > 0) {
        images.push(items.slice(0, splitIndex))
        items = items.slice(splitIndex)
        splitIndex = splitIndex === 5 ? 4 : 5
      }

      return images
    },
    sportingStatistics () {
      return _get(this.statistics, 'sporting_statistics', {})
    }
  },
  methods: {
    ...mapActions({
      fetchClientStatistics: 'client/fetchClientStatistics',
      fetchProjects: 'projects/fetchProjects'
    }),
    getSportingStatistic (key) {
      const value = _get(this.sportingStatistics, key, 0)

      if (key === 'total_distance') {
        let distance = value / 1000
        if (distance % 1 !== 0) distance = distance.toFixed(2)
        return `${distance.toString().replace('.', ',')} ${this.$i18n.t('literal.km')}`
      }

      if (key === 'total_time') return Math.round(value / 3600) + 'h'
      if (key === 'total_elevation_gain') value + 'm'
      if (key === 'total_calories') value + 'kcal'

      return value
    }
  }
})
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 1310px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
}

.cta {
  display: inline-block;
  padding: $padding__base * 1.5;
  font-size: rem(18px);
  line-height: 1;
  font-family: $font-family--secondary;
  transition: all .2s;

  &__color {
    &--blue {
      background: #012D61;
      color: $white;

      &:hover {
        background: darken(#012D61, 10);
      }
    }

    &--green {
      background: #9EAB05;
      color: $white;

      &:hover {
        background: darken(#9EAB05, 10);
      }
    }
  }
}

.title {
  &--2 {
    color: #3C3934;
    text-align: center;
    font-size: rem(36px);
    line-height: 1.4;
    font-weight: 600;
    font-family: $font-family--secondary;

    @include mq(sm) {
      font-size: rem(60px);
    }
  }
}

.banner {
  background-color: #C6CD72;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  color: $white;

  @include mq(sm) {
    padding-top: $padding__base * 12;
  }

  &__wrapper {
    position: relative;
    overflow: hidden;

    @include mq(sm) {
      max-width: 100%;

      @include aspect-ratio(1, 1);
    }

    &::before {
      content: '';
      opacity: 0.6;
      background: #000;
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 0;

      @include mq(sm) {
        width: 96%;
        height: auto;
        border-radius: 50%;
      }

      @include mq(xl) {
        width: 83%;
      }
    }
  }

  &__text-wrapper {
    position: relative;
    z-index: 10;
    max-width: 600px;
    margin: 0 auto;
    padding: 80px 0;
  }

  &__title {
    font-size: rem(48px);
    font-family: $font-family--secondary;

    @include mq(sm) {
      font-size: rem(72px);
    }
  }

  .goal {
    color: #F6FF8D;
    font-size: rem(18px);
    line-height: 1.4;

    &__title {
      font-size: rem(30px);
      line-height: 1;
      font-family: $font-family--secondary;
      color: #F7FF95;
    }

    &__items {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__item {
      &:not(:last-child) {
        &::after {
          display: inline-block;
          margin: 0 calc($margin__base / 4);
          content: '|';
        }
      }
    }
  }
}

.metrics {
  text-align: center;
  font-size: rem(18px);
  color: #131415;
  flex-wrap: wrap;
  justify-content: center;
  background: #C6CD72;

  @include mq(sm) {
    display: flex;
  }

  &__item {
    padding: $padding__base * 3 $padding__base $padding__base * 2.5;

    @media (max-width: #{map-get($grid-breakpoints, "sm")}) {
      &:nth-child(even) {
        background: #9EAB05;
      }
    }

    @media (min-width: #{map-get($grid-breakpoints, "sm")}) and (max-width: #{map-get($grid-breakpoints, "md")}) {
      width: 50%;

      &:nth-child(4n+1), &:nth-child(4n+4) {
        background: #9EAB05;
      }
    }

    @include mq(md) {
      flex: 1;
      width: 25%;

      &:nth-child(8n+2),
      &:nth-child(8n+4),
      &:nth-child(8n+5),
      &:nth-child(8n+7) {
        background: #9EAB05;
      }
    }

    strong {
      color: #012D61;
      font-family: $font-family--secondary;
      font-weight: bold;
      font-size: rem(36px);
    }
  }
}

.events {
  text-align: center;
  padding: 60px 0;
  position: relative;
  background: $white;

  @include mq(sm) {
    padding: 110px 0 165px;
  }

   &__illustration {
    pointer-events: none;
    background-image: url('https://koalect-images.s3.eu-west-3.amazonaws.com/clients/rikolto/bike_green.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: 180px;
    position: absolute;
    left: 0;
    top: 110px;
    z-index: 0;
    transition: all .3s ease-in-out;
    display: none;

    @include mq(md) {
      display: block;
    }

    @include mq(lg) {
      width: 256px;
      left: 0;
    }

    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: calc(485 / 241) * 100%;
    }
  }

  &__wrapper {
    max-width: 840px;
    z-index: 10;
  }

  &__text-wrapper {
    max-width: 600px;
    margin: 0 auto;
  }

  &__items {
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    @include mq(sm) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: auto;
    }
  }

  .event {
    background-color: #C6CD72;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include aspect-ratio(1, 1);

    @include mq(sm) {
      width: calc(50% - 12px);
    }

    &:not(:first-child) {
      margin-top: $margin__base * 2;

      @include mq(sm) {
        margin-top: $margin__base * 4;
      }
    }

    @include mq(sm) {
      &:nth-child(2) {
        margin-top: 0;
      }
    }

    &__content {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: $padding__base * 4 $padding__base;

      &--padding {
        padding-bottom: $padding__base * 10;
      }
    }

    &__name {
      font-size: rem(28px);
      font-family: $font-family--secondary;
      text-transform: uppercase;
      color: $white;
      line-height: 1;
      font-weight: 700;

      &--large {
        font-size: rem(36px);
      }
    }
  }
}

.projects {
  background: #F8FBD6;
  padding: 60px 0 96px;
  position: relative;

   &__illustration {
    pointer-events: none;
    background-image: url('https://koalect-images.s3.eu-west-3.amazonaws.com/clients/rikolto/bike_blue.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: 180px;
    position: absolute;
    right: -30px;
    top: -250px;
    z-index: 0;
    transition: all .3s ease-in-out;
    display: none;

    @include mq(md) {
      display: block;
    }

    @include mq(lg) {
      width: 256px;
      top: -260px;
      right: -30px;
    }

    @include mq(xl) {
      right: 0;
    }

     &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: calc(730 / 329) * 100%;
    }
  }

  &__title {
    margin-bottom: 48px;

    @include mq(sm) {
      margin-bottom: 72px;
    }
  }

  &__action {
    text-align: center;
    margin-top: 48px;

    @include mq(sm) {
      margin-top: 72px;
    }
  }
}

.supporters {
  padding: 60px 0 96px;
  background: $white;
  position: relative;

  &__illustration {
    pointer-events: none;
    background-image: url('https://koalect-images.s3.eu-west-3.amazonaws.com/clients/rikolto/world_green.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: 220px;
    position: absolute;
    left: 0;
    top: -40px;
    z-index: 0;
    transition: all .3s ease-in-out;
    display: none;

    @include mq(md) {
      display: block;
    }

    @include mq(lg) {
      width: 256px;
      top: -80px;
      left: -30px;
    }

    @include mq(xl) {
      left: 0;
    }

    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: calc(404 / 256) * 100%;
    }
  }

  &__title {
    margin-bottom: 48px;

    @include mq(sm) {
      margin-bottom: 72px;
    }
  }

  &__items {
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

   @include mq(sm) {
     width: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
   }
  }

  .supporter {
    margin-bottom: 18px;

    @media (min-width: #{map-get($grid-breakpoints, "sm")}) and (max-width: #{map-get($grid-breakpoints, "md")}) {
      width: calc(33% - 36px);
      margin-right: $margin__base * 1.5;

      &:nth-child(3n+3) {
        margin-right: 0;
      }
    }

    @include mq(md) {
      width: calc(20% - 15px);
      margin-right: $margin__base * 1.5;

      &:nth-child(5n+5) {
        margin-right: 0;
      }
    }

    &__avatar {
      background-color: #C6CD72;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      font-size: rem(48px);

      @include aspect-ratio(238, 300);

      @include mq(md) {
        @include aspect-ratio(238, 358);
      }

      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      figcaption {
        position: absolute;
        width: 1px;
        height: 1px;
        color: transparent;
        overflow: hidden;
      }
    }

    &__name {
      text-align: center;
      font-size: rem(18px);
    }
  }
}

.gallery {
  position: relative;
  background: #F8FBD6;
  overflow: hidden;

  @include mq(sm) {
    padding: 96px 0 180px;
  }

  &__illustration {
    pointer-events: none;
    background-image: url('https://koalect-images.s3.eu-west-3.amazonaws.com/clients/rikolto/world_white.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: 400px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    transition: all .3s ease-in-out;
    display: none;

    @include mq(md) {
      display: block;
    }

    @include mq(lg) {
      width: 590px;
    }

    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: calc(374 / 446) * 100%;
    }
  }

  &__grid {
    &:not(:last-child) {
      @include mq(sm) {
        margin-bottom: $margin__base * 2;
      }
    }

    @include mq(sm) {
      display: grid;
      gap: $margin__base * 2;
    }

    @media (min-width: #{map-get($grid-breakpoints, "sm")}) and (max-width: #{map-get($grid-breakpoints, "md")}) {
      grid-template-areas:
        "image_0 image_2"
        "image_1 image_2"
        "image_3 image_4";
      grid-template-columns: repeat(2, 1fr);

      &:nth-child(even) {
        grid-template-areas:
          "image_0 image_1"
          "image_0 image_2"
          "image_3 image_4";
      }

      &--1 {
        grid-template-areas: "image_0 image_0";

         &:nth-child(even) {
           grid-template-areas: "image_0 image_0";
         }

        .gallery__image {
          @include aspect-ratio(728, 253);
        }
      }

      &--2 {
        grid-template-areas: "image_0 image_1";

         &:nth-child(even) {
            grid-template-areas: "image_0 image_1";
         }
      }

      &--4 {
        grid-template-areas:
        "image_0 image_2"
        "image_1 image_2"
        "image_3 image_3";

        &:nth-child(even) {
          grid-template-areas:
            "image_0 image_1"
            "image_0 image_2"
            "image_3 image_3";
        }

        .gallery__image__3 {
          @include aspect-ratio(728, 253);
        }
      }
    }

    @include mq(md) {
      grid-template-areas:
        "image_0 image_2 image_3"
        "image_1 image_2 image_4";
      grid-template-columns: repeat(3, 1fr);

       &:nth-child(even) {
         grid-template-areas:
          "image_0 image_2 image_3"
          "image_1 image_2 image_4";
       }

      &--1,
      &--1:nth-child(even) {
         grid-template-areas: "image_0 image_0 image_0";

        .gallery__image {
          @include aspect-ratio(1230, 284);
        }
      }

      &--2,
      &--2:nth-child(even) {
        grid-template-areas: "image_0 image_1";
        grid-template-columns: repeat(2, 1fr);

        .gallery__image {
          @include aspect-ratio(603, 284);
        }
      }

      &--3,
      &--3:nth-child(even) {
        grid-template-areas: "image_0 image_1 image_2";
      }

      &--4,
      &--4:nth-child(even) {
        grid-template-areas:
          "image_0 image_1 image_3"
          "image_0 image_2 image_3";
      }
    }
  }

  &__image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #C6CD72;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    font-size: rem(48px);

    @include aspect-ratio(816, 588);

    @include mq(sm) {
      width: auto;
      left: 0;
      transform: none;
    }

    @for $i from 0 through 4 {
      &__#{$i} {
        grid-area: image_#{$i}
      }
    }

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
