<template>
  <div class="sterren__container">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 508.2 1190.1"
      style="enable-background:new 0 0 508.2 1190.1;"
      xml:space="preserve"
      class="sterren"
      v-if="campaignId && projects.length"
    >
      <g class="plan">
        <image
          width="854"
          height="2000"
          transform="scale(.595)"
          xlink:href="https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/sterren-actie-johannes-de-doper-map-kikirpa.png"
        />
      </g>

      <g class="stars">
        <image
          width="50"
          height="50"
          transform="translate(256.1 198.8) scale(.5)"
          :xlink:href="isFunded(1) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(1)"
        />

        <image
          width="50"
          height="50"
          transform="translate(284.9 171.4) scale(.5)"
          :xlink:href="isFunded(2) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(2)"
        />

        <image
          width="50"
          height="50"
          transform="translate(227.3 171.4) scale(.5)"
          :xlink:href="isFunded(3) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(3)"
        />

        <image
          width="50"
          height="50"
          transform="translate(256.1 144.6) scale(.5)"
          :xlink:href="isFunded(4) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(4)"
        />

        <image
          width="50"
          height="50"
          transform="translate(411.8 217.7) scale(.5)"
          :xlink:href="isFunded(5) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(5)"
        />

        <image
          width="50"
          height="50"
          transform="translate(440.1 189.9) scale(.5)"
          :xlink:href="isFunded(6) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(6)"
        />

        <image
          width="50"
          height="50"
          transform="translate(384.1 189.9) scale(.5)"
          :xlink:href="isFunded(7) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(7)"
        />

        <image
          width="50"
          height="50"
          transform="translate(411.8 168.5) scale(.5)"
          :xlink:href="isFunded(8) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(8)"
        />

        <image
          width="50"
          height="50"
          transform="translate(254.6 357.2) scale(.5)"
          :xlink:href="isFunded(9) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(9)"
        />

        <image
          width="50"
          height="50"
          transform="translate(283.4 329.8) scale(.5)"
          :xlink:href="isFunded(10) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(10)"
        />

        <image
          width="50"
          height="50"
          transform="translate(225.9 329.8) scale(.5)"
          :xlink:href="isFunded(11) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(11)"
        />

        <image
          width="50"
          height="50"
          transform="translate(254.6 303.1) scale(.5)"
          :xlink:href="isFunded(12) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(12)"
        />

        <image
          width="50"
          height="50"
          transform="translate(410.8 356.9) scale(.5)"
          :xlink:href="isFunded(13) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(13)"
        />

        <image
          width="50"
          height="50"
          transform="translate(439.6 329.4) scale(.5)"
          :xlink:href="isFunded(14) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(14)"
        />

        <image
          width="50"
          height="50"
          transform="translate(382.1 329.4) scale(.5)"
          :xlink:href="isFunded(15) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(15)"
        />

        <image
          width="50"
          height="50"
          transform="translate(410.8 302.7) scale(.5)"
          :xlink:href="isFunded(16) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(16)"
        />

        <image
          width="50"
          height="50"
          transform="translate(303.2 581.7) scale(.5)"
          :xlink:href="isFunded(17) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(17)"
        />

        <image
          width="50"
          height="50"
          transform="translate(216 633) scale(.5)"
          :xlink:href="isFunded(18) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(18)"
        />

        <image
          width="50"
          height="50"
          transform="translate(321.8 633) scale(.5)"
          :xlink:href="isFunded(19) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(19)"
        />

        <image
          width="50"
          height="50"
          transform="translate(270 634.4) scale(.5)"
          :xlink:href="isFunded(20) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(20)"
        />

        <image
          width="50"
          height="50"
          transform="translate(239.2 667.7) scale(.5)"
          :xlink:href="isFunded(21) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(21)"
        />

        <image
          width="50"
          height="50"
          transform="translate(294.7 667.7) scale(.5)"
          :xlink:href="isFunded(22) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(22)"
        />

        <image
          width="50"
          height="50"
          transform="translate(268 691.9) scale(.5)"
          :xlink:href="isFunded(23) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(23)"
        />

        <image
          width="50"
          height="50"
          transform="translate(234.7 581.7) scale(.5)"
          :xlink:href="isFunded(24) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(24)"
        />

        <image
          width="50"
          height="50"
          transform="translate(270 545) scale(.5)"
          :xlink:href="isFunded(25) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(25)"
        />

        <image
          width="50"
          height="50"
          transform="translate(321.8 521.7) scale(.5)"
          :xlink:href="isFunded(26) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(26)"
        />

        <image
          width="50"
          height="50"
          transform="translate(216 521.7) scale(.5)"
          :xlink:href="isFunded(27) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(27)"
        />

        <image
          width="50"
          height="50"
          transform="translate(299.8 485.2) scale(.5)"
          :xlink:href="isFunded(28) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(28)"
        />

        <image
          width="50"
          height="50"
          transform="translate(237.1 485.2) scale(.5)"
          :xlink:href="isFunded(29) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(29)"
        />

        <image
          width="50"
          height="50"
          transform="translate(268 467.5) scale(.5)"
          :xlink:href="isFunded(30) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(30)"
        />

        <image
          width="50"
          height="50"
          transform="translate(444.1 581.7) scale(.5)"
          :xlink:href="isFunded(31) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(31)"
        />

        <image
          width="50"
          height="50"
          transform="translate(363.3 633) scale(.5)"
          :xlink:href="isFunded(32) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(32)"
        />

        <image
          width="50"
          height="50"
          transform="translate(460.2 634.4) scale(.5)"
          :xlink:href="isFunded(33) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(33)"
        />

        <image
          width="50"
          height="50"
          transform="translate(410.8 661.2) scale(.5)"
          :xlink:href="isFunded(34) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(34)"
        />

        <image
          width="50"
          height="50"
          transform="translate(382.1 676.3) scale(.5)"
          :xlink:href="isFunded(35) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(35)"
        />

        <image
          width="50"
          height="50"
          transform="translate(437.5 678.6) scale(.5)"
          :xlink:href="isFunded(36) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(36)"
        />

        <image
          width="50"
          height="50"
          transform="translate(408.8 694.4) scale(.5)"
          :xlink:href="isFunded(37) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(37)"
        />

        <image
          width="50"
          height="50"
          transform="translate(382.1 581.7) scale(.5)"
          :xlink:href="isFunded(38) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(38)"
        />

        <image
          width="50"
          height="50"
          transform="translate(460.2 531.7) scale(.5)"
          :xlink:href="isFunded(39) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(39)"
        />

        <image
          width="50"
          height="50"
          transform="translate(360.3 521.7) scale(.5)"
          :xlink:href="isFunded(40) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(40)"
        />

        <image
          width="50"
          height="50"
          transform="translate(408.8 498.5) scale(.5)"
          :xlink:href="isFunded(41) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(41)"
        />

        <image
          width="50"
          height="50"
          transform="translate(433.5 485.2) scale(.5)"
          :xlink:href="isFunded(42) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(42)"
        />

        <image
          width="50"
          height="50"
          transform="translate(382.1 487.1) scale(.5)"
          :xlink:href="isFunded(43) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(43)"
        />

        <image
          width="50"
          height="50"
          transform="translate(408.8 460.3) scale(.5)"
          :xlink:href="isFunded(44) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(44)"
        />

        <image
          width="50"
          height="50"
          transform="translate(254.6 855.5) scale(.5)"
          :xlink:href="isFunded(45) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(45)"
        />

        <image
          width="50"
          height="50"
          transform="translate(283.4 828.1) scale(.5)"
          :xlink:href="isFunded(46) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(46)"
        />

        <image
          width="50"
          height="50"
          transform="translate(225.9 828.1) scale(.5)"
          :xlink:href="isFunded(47) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(47)"
        />

        <image
          width="50"
          height="50"
          transform="translate(254.6 801.3) scale(.5)"
          :xlink:href="isFunded(48) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(48)"
        />

        <image
          width="50"
          height="50"
          transform="translate(410.8 855.9) scale(.5)"
          :xlink:href="isFunded(49) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(49)"
        />

        <image
          width="50"
          height="50"
          transform="translate(439.6 828.4) scale(.5)"
          :xlink:href="isFunded(50) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(50)"
        />

        <image
          width="50"
          height="50"
          transform="translate(382.1 828.4) scale(.5)"
          :xlink:href="isFunded(51) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(51)"
        />

        <image
          width="50"
          height="50"
          transform="translate(410.8 801.7) scale(.5)"
          :xlink:href="isFunded(52) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(52)"
        />

        <image
          width="50"
          height="50"
          transform="translate(256.6 1006.1) scale(.5)"
          :xlink:href="isFunded(53) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(53)"
        />

        <image
          width="50"
          height="50"
          transform="translate(288.3 977) scale(.5)"
          :xlink:href="isFunded(54) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(54)"
        />

        <image
          width="50"
          height="50"
          transform="translate(224.3 977) scale(.5)"
          :xlink:href="isFunded(55) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(55)"
        />

        <image
          width="50"
          height="50"
          transform="translate(256.6 945.9) scale(.5)"
          :xlink:href="isFunded(56) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(56)"
        />

        <image
          width="50"
          height="50"
          transform="translate(410.8 1015.2) scale(.5)"
          :xlink:href="isFunded(57) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(57)"
        />

        <image
          width="50"
          height="50"
          transform="translate(439.6 987.8) scale(.5)"
          :xlink:href="isFunded(58) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(58)"
        />

        <image
          width="50"
          height="50"
          transform="translate(382.1 987.8) scale(.5)"
          :xlink:href="isFunded(59) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(59)"
        />

        <image
          width="50"
          height="50"
          transform="translate(410.8 961) scale(.5)"
          :xlink:href="isFunded(60) ? 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-blue.png' : 'https://koalect-images.s3.eu-west-3.amazonaws.com/clients/kikirpa/star-circle-grey.png'"
          @click="handleClickStar(60)"
        />
      </g>
    </svg>

    <app-modal
      v-if="selected_project"
      @close="selected_project = null"
    >
      <template #body>
        <article class="popup">
          <div
            class="popup__cover"
            :style="{ backgroundImage: `url(${translate(popup.image)}), url(${getImagePath('placeholders/project-card-cover.jpg')})` }"
            v-if="!procedure || procedure.current_amount === 0"
          />

          <div class="popup__infos">
            <template v-if="procedure && procedure.current_amount > 0">
              <div
                class="d-flex justify-content-center padding__tb--12"
                v-if="loader"
              >
                <app-spinner />
              </div>

              <template v-if="!loader && contribution">
                <h3 class="title__section">
                  {{ contribution.customer.firstname }} {{ contribution.customer.lastname }}
                </h3>

                <div
                  class="editor editor__content popup__text"
                  v-html="contribution.informations.message"
                  v-if="contribution.informations.message"
                />
              </template>
            </template>

            <template v-else>
              <h3
                class="title__section"
                v-if="translate(popup.title)"
              >
                {{ translate(popup.title) }}
              </h3>

              <div
                class="editor editor__content popup__text"
                v-html="translate(popup.text)"
                v-if="translate(popup.text)"
              />

              <router-link
                :to="{ name: 'ClientProcedure', params: { procedureSlug: procedure.slug } }"
                class="btn translate__x3"
                v-if="procedure && isOngoing && translate(popup.cta)"
              >
                {{ translate(popup.cta) }}

                <fa-icon
                  class="btn__icon element element"
                  :icon="['fas', 'chevron-right']"
                />
              </router-link>
            </template>
          </div>
        </article>
      </template>
    </app-modal>
  </div>
</template>

<script setup>
import { ref, computed, defineAsyncComponent } from 'vue'
import { useProjectStore } from '@/stores/ProjectStore'
import { useI18n } from '@/vendors/i18n'
import { getImagePath } from '@/utils/utils'
import { get as _get } from 'lodash'
import { isPast, isFuture } from 'date-fns'

const AppSpinner = defineAsyncComponent(() => import('@/components/atoms/AppSpinner'))
const AppModal = defineAsyncComponent(() => import('&/organisms/AppModal'))

const props = defineProps({ section: { type: Object, default: () => ({}) } })

const { translate } = useI18n()
const { getProjects, getPublicProcedureExecutions } = useProjectStore()

const selected_project = ref(null)

const popup = computed(() => _get(props.section, 'popup', {}))
const campaignId = computed(() => _get(props.section.settings, 'campaign_id', []))
const slug = computed(() => _get(selected_project.value, 'slug', null))
const query = computed(() => ({ id: slug.value, 'sort_by_field[field]': 'created_at', 'sort_by_field[direction]': 'asc' }))
const procedure = computed(() => _get(selected_project.value, 'procedures', []).find(procedure => procedure.category === 'donation'))
const startDate = computed(() => procedure.value ? new Date(procedure.value.start_date) : new Date())
const endDate = computed(() => procedure.value && procedure.value.end_date ? new Date(procedure.value.end_date) : null)
const isOngoing = computed(() => isPast(startDate.value) && (!endDate.value || isFuture(endDate.value)))
const contribution = computed(() => publicProcedureExecutions.value.find(() => true))

const { projects } = getProjects({ query: { campaign_id: campaignId.value, per_page: 60, 'sort_by_field[field]': 'created_at', 'sort_by_field[direction]': 'asc' } })
const { publicProcedureExecutions, loader } = getPublicProcedureExecutions({ id: slug, query })

const getProject = id => _get(projects.value, id - 1)
const isFunded = id => _get(getProject(id), 'amount_collected', 0) > 0
const handleClickStar = id => selected_project.value = getProject(id)
</script>

<style lang="scss" scoped>
:deep(.modal__container) {
  width: 100%!important;
  border-radius: 0!important;

  @media (min-width: 520px) {
    width: 520px!important;
  }
}

:deep(.modal__btn--close) {
  background-color: $white;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;

  &:hover svg {
    transform: rotate(90deg);
  }

  & svg {
    color: $color--primary;
    font-size: rem(22px);
    transition: all .3s;
  }
}

.sterren {
  width: 100%;
  position: relative;

  &__container {
    overflow-x: auto;
    width: 100%;
    max-width: 500px;

    @media (min-width: 850px) {
      overflow-x: hidden;
    }
  }
}

.stars {
  image {
    cursor: pointer;
    outline: solid 1px transparent;
    border-radius: 50%;
    outline-offset: -1px;
    transition: all .2s linear;

    &:hover {
      outline: solid 1px #e2cd7c;
      outline-offset: 4px;
    }
  }
}

:deep(.btn) {
  display: flex;
  align-items: center;
  background: $color--primary;
  padding: 12px 18px;
  line-height: 1;
  font-size: rem(18px);
  color: $white;
  transition: all .3s;
  border-radius: 0;
  width: fit-content;

  &:hover {
    background-color: shade($color--primary, 5%);
  }

  .btn__icon {
    font-size: 12px;
    margin-left: 6px;
  }

  .btn__container {
    justify-content: center;
    margin-top: 48px;
  }
}

.popup {
  width: 100%;
  background-color: $white;

  &__cover {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include aspect-ratio(517, 241);
  }

  &__infos {
    position: relative;
    padding: 24px 30px;
    color: $black;

    .title__section {
      font-size: rem(24px);
      color: $color--primary;
      margin-bottom: 18px;
      text-align: left;
      line-height: 1.2;
      font-family: $font-family--secondary;
    }
  }

  &__text {
    margin-bottom: 38px;
    font-size: rem(14px);
  }

  &__chevron {
    font-size: 12px;
    margin-left: 6px;
  }

  &__link {
    display: flex;
    align-items: center;
    font-weight: bold;
  }
}
</style>