<template>
  <div class="home">
    <div class="container">
      <section
        class="campaign"
        v-if="isVisible('campaign')"
      >
        <article class="campaign__slider">
          <div
            class="slides"
            v-if="campaign.images?.length"
          >
            <transition-group
              name="slide"
              mode="out-in"
            >
              <template
                v-for="({ image }, index) in campaign.images"
                :key="index"
              >
                <figure
                  v-show="index + 1 == slide_active"
                  :style="{ backgroundImage: `url(${translate(image)})` }"
                  class="slides__img"
                />
              </template>
            </transition-group>
          </div>

          <ul
            class="dots"
            v-if="campaign.images.length > 1"
          >
            <li
              class="dots__li"
              v-for="(item, index) in campaign.images"
              :key="`slider_dot_${index}`"
              :class="{ active: ++index === slide_active }"
              @click="jump(index)"
            />
          </ul>
        </article>

        <div class="campaign__content">
          <h2 class="title--h1 after after--prim">
            {{ translate(campaign.title) }}
          </h2>

          <div
            class="editor editor__content"
            v-if="translate(campaign.text)"
            v-html="translate(campaign.text)"
          />

          <div class="margin__t--24">
            <app-link
              :link="campaign.button"
              class="button"
            />
          </div>
        </div>
      </section>

      <section
        class="goal"
        v-if="isVisible('goal')"
      >
        <div class="goal__content">
          <h2 class="goal__title title--h1">
            {{ translate(goal.title) }}
          </h2>

          <div
            class="editor editor__content"
            v-if="translate(goal.text)"
            v-html="translate(goal.text)"
          />
        </div>

        <div
          class="goal__img"
          v-if="translate(goal.image)"
          :style="`background-image: url(${ translate(goal.image) })`"
        />
      </section>

      <section
        class="building"
        v-if="isVisible('building')"
      >
        <h2 class="building__title title--h1 after after--prim color--prim">
          {{ translate(building.title) }}
        </h2>

        <div
          class="building__text editor editor__content"
          v-if="translate(building.text)"
          v-html="translate(building.text)"
        />

        <div class="building__house">
          <h3
            class="title--h3 align--center color--prim margin__b--24"
            v-if="currentStep"
          >
            {{ translate(currentStep.title) }}
          </h3>

          <designmuseumgent-building :stage="currentStep" />

          <div class="progressbar">
            <div
              class="progressbar__current"
              :style="{ width: percentage }"
            />

            <ul class="progressbar__steps">
              <li
                class="progressbar__step"
                v-for="(step, index) in orderedSteps"
                :key="`progressbar_label_${index}`"
                :style="{ left: getStepPosition(step) }"
              >
                {{ translate(step.label) }}
              </li>
            </ul>
          </div>
        </div>

        <div class="building__button">
          <app-link
            :link="building.button"
            class="button"
          />
        </div>
      </section>

      <section
        class="donations"
        v-if="isVisible('donations')"
      >
        <div class="donations__section">
          <h3 class="title--h2 after after--sec">
            {{ translate(donations.individual.title) }}
          </h3>

          <div
            class="editor editor__content"
            v-if="translate(donations.individual.text)"
            v-html="translate(donations.individual.text)"
          />

          <div class="margin__t--24">
            <app-link
              :link="donations.individual.link"
              class="link"
            />
          </div>
        </div>

        <div class="donations__section">
          <h3 class="title--h2 after after--sec">
            {{ translate(donations.company.title) }}
          </h3>

          <div
            class="editor editor__content"
            v-if="translate(donations.company.text)"
            v-html="translate(donations.company.text)"
          />

          <div class="margin__t--24">
            <app-link
              :link="donations.company.link"
              class="link"
            />
          </div>
        </div>
      </section>

      <section
        class="beneficiaries"
        v-if="isVisible('beneficiaries')"
      >
        <h2 class="beneficiaries__title title--h1 color--prim after after--prim">
          {{ translate(beneficiaries.title) }}
        </h2>

        <div class="beneficiaries__grid">
          <div class="beneficiaries__section">
            <figure
              class="beneficiaries__img"
              v-if="translate(beneficiaries.left.image)"
              :style="`background-image: url(${ translate(beneficiaries.left.image) })`"
            />

            <h3 class="title--h3">
              {{ translate(beneficiaries.left.title) }}
            </h3>

            <div
              class="editor editor__content"
              v-if="translate(beneficiaries.left.text)"
              v-html="translate(beneficiaries.left.text)"
            />
          </div>

          <div class="beneficiaries__section">
            <figure
              class="beneficiaries__img"
              v-if="translate(beneficiaries.right.image)"
              :style="`background-image: url(${ translate(beneficiaries.right.image) })`"
            />

            <h3 class="title--h3">
              {{ translate(beneficiaries.right.title) }}
            </h3>

            <div
              class="editor editor__content"
              v-if="translate(beneficiaries.right.text)"
              v-html="translate(beneficiaries.right.text)"
            />
          </div>
        </div>
      </section>

      <section
        class="contributions"
        v-if="isVisible('contributions_individual')"
      >
        <h2
          class="contributions__title title--h1 after after--prim"
          v-if="translate(contributionsIndividual.title)"
        >
          {{ translate(contributionsIndividual.title) }}
        </h2>

        <ul
          class="contributions__grid"
          v-if="individualProjectId"
        >
          <public-contribution
            v-for="contribution in individualContributions"
            class="contributions__li"
            :contribution="contribution"
            :key="contribution.id"
          />
        </ul>

        <app-pagination
          class="margin__t--24"
          :paginator="individualContributionsPagination"
          @change="page => individualContributionsQuery.page = page"
        />

        <div class="margin__t--36 contributions__button">
          <app-link
            :link="contributionsIndividual.button"
            class="button"
          />
        </div>
      </section>

      <section
        class="contributions"
        v-if="isVisible('contributions_company')"
      >
        <h2
          class="contributions__title title--h1 after after--prim"
          v-if="translate(contributionsCompany.title)"
        >
          {{ translate(contributionsCompany.title) }}
        </h2>

        <ul
          class="contributions__grid"
          v-if="companyProjectId"
        >
          <public-contribution
            v-for="contribution in companyContributions"
            class="contributions__li"
            :contribution="contribution"
            :key="contribution.id"
          />
        </ul>

        <app-pagination
          class="margin__t--24"
          :paginator="companyContributionsPagination"
          @change="page => companyContributionsQuery.page = page"
        />

        <div class="margin__t--36 contributions__button">
          <app-link
            :link="contributionsCompany.button"
            class="button"
          />
        </div>
      </section>
    </div>
  </div>

  <section
    class="networks"
    v-if="networks.length"
  >
    <ul class="networks__ul">
      <li
        class="networks__li"
        v-for="(network, index) in networks"
        :key="'header_menu_social_media_' + index"
      >
        <a
          class="networks__link"
          :href="translate(network.url)"
          target="_blank"
        >
          <fa-icon
            v-if="network.icon"
            size="md"
            :icon="network.icon"
            class="networks__icon"
          />
        </a>
      </li>
    </ul>
  </section>
</template>

<script setup>
import { computed, ref, defineAsyncComponent } from 'vue'
import { useProjectStore } from '@/stores/ProjectStore'
import { useClientStore } from '@/stores/ClientStore'
import { useI18n } from '@/vendors/i18n'
import { get as _get, mapValues as _mapValues } from 'lodash'
import useSection from '@/composables/modules/pages/useSection'

import DesignmuseumgentBuilding from './HomepageDesignmuseumgentBuilding.vue'
const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const PublicContribution = defineAsyncComponent(() => import('&/modules/project/PublicContribution'))
const AppPagination = defineAsyncComponent(() => import('&/molecules/AppPagination'))

const props = defineProps({
  page: { type: Object, required: true },
  sections: { type: Object, required: true }
})

const { translate } = useI18n()
const { getSectionAttributes, isVisible } = useSection(props.page)
const { getPublicProcedureExecutions } = useProjectStore()
const { getClient, getStatistics } = useClientStore()
const { statistics } = getStatistics()
const { client } = getClient()

const slide_active = ref(1)
const campaign = computed(() => getSectionAttributes('campaign'))
const goal = computed(() => getSectionAttributes('goal'))
const building = computed(() => getSectionAttributes('building'))
const donations = computed(() => getSectionAttributes('donations'))
const beneficiaries = computed(() => getSectionAttributes('beneficiaries'))
const contributionsIndividual = computed(() => getSectionAttributes('contributions_individual'))
const contributionsCompany = computed(() => getSectionAttributes('contributions_company'))

const amountCollected = computed(() => _get(statistics.value, 'amount_collected', 0) / 100)

const steps = computed(() => _mapValues(_get(building.value, 'steps', {}), step => ({ ...step, amount: step.amount ? step.amount / 100 : step.amount })))
const orderedSteps = computed(() => Object.keys(steps.value).map((key) => ({ ...steps.value[key] })).sort((a, b) => a.amount - b.amount).map((item, index) => ({ index, ...item })))
const currentStep = computed(() => orderedSteps.value.filter(step => step.amount < amountCollected.value).pop())
const goalAmount = computed(() => _get(orderedSteps.value.findLast(x => x), 'amount', 700000))
const percentage = computed(() => amountCollected.value ? (Math.round((amountCollected.value / goalAmount.value) * 100)) + '%' : '0%')

const individualProjectId = computed(() => _get(contributionsIndividual.value, 'project_id', []).find(e => e))
const companyProjectId = computed(() => _get(contributionsCompany.value, 'project_id', []).find(e => e))

const networks = computed(() => {
  const links = _get(client.value, 'platform.social_networks', [])

  return links.map(link => {
    if (link.icon.includes('facebook-square')) {
      link.icon[1] = 'facebook-f'
    } else if (link.icon.includes('linkedin')) {
      link.icon[1] = 'linkedin-in'
    }

    return link
  }).filter(link => translate(link.url))
})

const individualContributionsQuery = ref({
  project_id: individualProjectId.value,
  page: 1,
  per_page: 9,
  'sort_by_field[field]': 'created_at',
  'sort_by_field[direction]': 'desc'
})

const companyContributionsQuery = ref({
  project_id: companyProjectId.value,
  page: 1,
  per_page: 9,
  'sort_by_field[field]': 'created_at',
  'sort_by_field[direction]': 'desc'
})

const { publicProcedureExecutions: individualContributions, pagination: individualContributionsPagination } = getPublicProcedureExecutions({ id: individualProjectId.value, query: individualContributionsQuery.value })
const { publicProcedureExecutions: companyContributions, pagination: companyContributionsPagination } = getPublicProcedureExecutions({ id: companyProjectId.value, query: companyContributionsQuery.value })

const getStepPosition = step => (Math.round((step.amount / goalAmount.value) * 100)) + '%'
const jump = index => slide_active.value = index
</script>

<style lang="scss" scoped>
.title {
  &--h1 {
    font-size: 28px;
    line-height: 1.1;

    @include mq(sm) {
      font-size: 38px;
    }
  }

  &--h2 {
    font-size: 24px;
    line-height: 32px;

    @include mq(sm) {
      font-size: 30px;
    }
  }

  &--h3 {
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;

    @include mq(sm) {
      font-size: 24px;
    }
  }
}

.img {
  &__background {
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.after {
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 30px;
    height: 3px;
    margin: 18px 0;

    @include mq(sm) {
      margin: 24px 0;
    }
  }

  &--prim:after {
    background-color: $color--primary;
  }

  &--sec:after {
    background-color: $color--secondary;
  }
}

.editor__content, :deep(.editor__content) {
  ul {
    list-style-type: circle;
  }

  li::marker {
    color: $color--primary;
  }

  li:not(:last-child) {
    margin-bottom: 6px;
  }
}

.button, :deep(.button) {
  color: $color--primary;
  font-size: .85rem;
  font-weight: bold;
  text-transform: uppercase;
  padding: 7px 30px;
  border: 3px solid $color--primary;
  background-color: $white;
  line-height: 1;
  transition: all .25s ease-out;

  &:hover {
    color: $black;
    border-color: $black;
  }
}

.home {
  padding: 48px 0 60px 0;
  background: $white;
  position: relative;

  @include mq(sm) {
    padding: 78px 0 106px 0;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 102, 51, 0.90) 0px, rgba(255, 102, 51, 0.00) 397px);
    pointer-events: none;
  }
}

.slides {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    @include aspect-ratio(623, 486);
  }

  .slide-enter-active, .slide-leave-active {
    transition: all 0.5s ease;
  }

  .slide-enter-from, .slide-leave-to {
    opacity: 0;
  }

  .slide-leave-active {
    position: absolute;
  }
}

.dots {
  position: absolute;
  display: block;
  width: 100%;
  text-align: center;
  bottom: 18px;

  &__li {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $white;
    display: inline-block;
    margin: 0 8px;
    cursor: pointer;
    transition: all .3s;

    &.active {
      background: $color--primary;
      outline: 1px solid $white;
      outline-offset: 4px;
    }
  }
}

.campaign {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  border: 1px solid $color--primary;
  margin-bottom: 60px;
  background-color: $white;

  @include mq(sm) {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 90px;
  }

  &__content {
    padding: 24px;

    @include mq(sm) {
      padding: 42px;
    }
  }

  &__slider {
    position: relative;
  }
}

.goal {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin-bottom: 60px;

  @include mq(sm) {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 90px;
  }

  &__title {
    margin-bottom: 32px;

    @include mq(sm) {
      margin-bottom: 48px;
    }
  }

  &__img {
    width: 200px;
    height: 200px;
    margin: auto;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include mq(sm) {
      width: 300px;
      height: 300px;
    }
  }
}

.building {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;

  @include mq(sm) {
    margin-bottom: 90px;
  }

  &__title::after {
    margin: 18px auto;

    @include mq(sm) {
      margin: 24px auto;
    }
  }

  &__text {
    text-align: center;
    margin-bottom: 24px;
    max-width: 920px;
  }

  &__house {
    max-width: 100%;
    width: 100%;

    @include mq(md) {
      max-width: 640px;
    }

    @include mq(lg) {
      max-width: 740px;
    }

    @include mq(xl) {
      max-width: 840px;
    }
  }

  &__button {
    margin-top: 36px;

    @include mq(sm) {
      margin-top: 54px;
    }
  }
}

.progressbar {
  width: 90%;
  margin: 12px auto;
  height: 12px;
  position: relative;
  background-color: #EBEBEB;
  border: 1px solid #D2D2D4;

  @include mq(sm) {
    margin: 0;
    width: 100%;
  }

  &__current {
    background: $color--primary;
    border-right: 1px solid #D2D2D4;
    position: absolute;
    max-width: 100%;
    top: 0;
    width: 0%;
    height: 100%;
    transition: all .3s;
  }

  &__steps {
    position: relative;
    top: 11px;
    display: flex;
  }

  &__step {
    position: absolute;
    font-weight: bold;
    width: 36px;
    height: 25px;
    margin-left: -12px;
    font-size: 12px;

    @include mq(sm) {
      font-size: 14px;
    }

    &:nth-child(even) {
      display: none;

      @include mq(sm) {
        display: block;
      }
    }

    &:before {
      content: '';
      width: 1px;
      height: 4px;
      background-color: #D2D2D4;
      display: block;
      margin-left: 12px;
    }
  }
}

.donations {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  gap: 24px;
  margin-bottom: 60px;

  @include mq(sm) {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 90px;
  }

  @media (min-width: 1090px) {
    grid-template-columns: repeat(2, 516px);
  }

  &__section {
    border: 1px solid $color--secondary;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include mq(sm) {
      padding: 24px 42px;
    }

    &:deep(.link) {
      color: $color--secondary;
      text-transform: uppercase;
      font-weight: bold;
      line-height: 15px;
      font-size: 12px;
      transition: all .3s;

      &:after {
        content: '';
        display: inline-block;
        margin-left: 3px;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 4px 0 4px 8px;
        border-color: transparent transparent transparent $color--secondary;
        transform: rotate(0deg);
        transition: all .3s;
      }

      &:hover {
        color: shade($color--secondary, 1%, 25%);

        &:after {
          transform: translateX(3px);
        }
      }
    }
  }
}

.beneficiaries {
  margin-bottom: 60px;

  @include mq(sm) {
    margin-bottom: 90px;
  }

  &__title {
    text-align: center;

    &:after {
      margin: 18px auto;

      @include mq(sm) {
        margin: 24px auto;
      }
    }
  }

  .title--h3 {
    margin-bottom: 24px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    gap: 24px;
    text-align: left;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1090px) {
      grid-template-columns: repeat(2, 516px);
    }
  }

  &__img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 24px;
    border: 1px solid $color--primary;

    @include aspect-ratio(516, 322);
  }
}

.contributions {
  &:not(:last-child) {
    margin-bottom: 90px;
  }

  &__title {
    text-align: center;

    &:after {
      margin: 24px auto;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-end;
    gap: 16px 24px;

    @include mq(sm) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &:deep() {
    .contribution__img, .contribution__actions {
      display: none;
    }

    .contribution__infos {
      margin-left: 0;
    }

    .contributions__li {
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(46, 41, 61, .1);
    }

    .infos__amount, .infos__message {
      font-weight: bold;
    }

    .infos__name {
      font-size: 1rem;
    }
  }
}

.networks {
  display: none;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);

  @include mq(sm) {
    right: 12px;
    display: block;
  }

  @include mq(lg) {
    right: 90px;
  }

  &__ul {
    border: 1px solid $black;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px 6px;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $black;
    color: $white;
    border-radius: 50%;
    padding: 1px;
    width: 24px;
    height: 24px;
  }
}
</style>