<template>
  <main class="home">
    <ul
      class="wrapper partners"
      v-if="Array.isArray(partners.logos) && partners.logos.length > 0"
    >
      <li
        v-for="(logo, i) in partners.logos"
        :key="'logo_' + i"
      >
        <a
          v-if="$i18n.translate(logo.logo)"
          :class="{'disable': $i18n.translate(logo.link.url) == null}"
          :href="$i18n.translate(logo.link.url)"
          target="_blank"
        >
          <img
            :src="$i18n.translate(logo.logo)"
            :alt="logo.text"
            class="partners__logo"
          >
        </a>
      </li>
    </ul>

    <section class="wrapper banner padding--36">
      <div class="left">
        <h1
          class="banner__title bold"
          v-if="$i18n.translate(banner.title)"
        >
          {{ $i18n.translate(banner.title) }}
        </h1>

        <div
          class="banner__text editor editor__content margin__b--12"
          v-if="$i18n.translate(banner.text)"
          v-html="$i18n.translate(banner.text)"
        />

        <div
          v-if="$i18n.translate(banner.button.text)"
          class="banner__button btn__size--classic"
        >
          <a
            class="banner__button--a bold"
            @click.prevent="setRouteNavigation(banner.button)"
          >
            {{ $i18n.translate(banner.button.text) }}
          </a>
        </div>
      </div>

      <figure
        class="banner__image"
        :style="{ backgroundImage: `url(${ $i18n.translate(banner.image) })` }"
      />
    </section>

    <section class="explanation">
      <div
        class="wrapper explanation__text editor editor__content"
        v-if="$i18n.translate(explanation.text)"
        v-html="$i18n.translate(explanation.text)"
      />

      <figure
        class="explanation__image"
        :style="{ backgroundImage: `url(${ $i18n.translate(explanation.image) })` }"
      />
    </section>
  </main>
</template>

<script>
import { defineComponent } from 'vue'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'

export default defineComponent({
  name: 'CustomHomepageParalympic',
  mixins: [PageRouteNavigation],
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  computed: {
    partners () {
      return _get(this.section, 'attributes.partners', {})
    },
    banner () {
      return _get(this.section, 'attributes.banner', {})
    },
    explanation () {
      return _get(this.section, 'attributes.explanation', {})
    }
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #d20a11;
$color--secondary: #ffd500;
$black: #222;

.home {
  background: $white;
  color: $black;
  font-weight: 500;

  &::after {
    content: ' ';
    border-style: solid;
    border-width: 70px 70px 0 0;
    border-color: #d20a11 transparent transparent transparent;
    position: absolute;
    top: 0;

    @include mq(md) {
      border-width: 170px 170px 0 0;
    }
  }
}

.wrapper {
  max-width: 1440px;

  @include mq(xl) {
    margin: 0 auto;
  }
}

.partners {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: calc($grid-gutter-width / 2);
  padding: 0 $padding__base;

  @include mq(sm) {
    padding: 0 $padding__base * 4;
  }

  &__logo {
    max-width: 80px;
    max-height: 200px;

    @include mq(sm) {
      max-width: 200px;
    }
  }
}

.left {
  @include mq(sm) {
    margin-bottom: $margin__base * 8;
  }
}

.banner {
  padding-bottom: 0;

  &__title {
    color: $color--primary;
    font-size: rem(20px);
    margin-bottom: $margin__base * 2;
    line-height: 35px;
  }

  &__button {
    max-width: 65%;
    margin: auto;
    margin-bottom: $margin__base * 4;
    background: $color--primary;
    text-transform: uppercase;
    font-size: rem(12px);
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    transition: all $transition__duration;

    &--a {
      color: $white;
      width: 100%;
    }

    &:hover {
      background: shade($color--primary, 10%, 50%);
    }
  }

  &__image {
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;

    @include aspect-ratio(1.25, 1);
  }

  @include mq(sm) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-end;

    &__title {
      font-size: rem(30px);
    }

    &__image {
      background-size: contain;
    }
  }
}

.explanation {
  @include mq(md) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &__text {
    background: $black;
    color: $white;
    padding: $padding__base * 3;

    @include mq(md) {
      order: 2;
    }

    @include mq(lg) {
      padding: $padding__base * 8;
    }
  }

  & a {
    &:hover {
      color: red;
    }
  }

  &__image {
    background-color: $dg;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
    min-height: 320px;

    @include aspect-ratio(1, 1);

    @include mq(sm) {
      @include aspect-ratio(2.4, 1);
    }

    @include mq(md) {
      @include aspect-ratio(3.2, 1);
    }

    &::before {
      content: '';
      background-image: url(https://koalect-images.s3.eu-west-3.amazonaws.com/clients/paralympic/decoration2.png);
      background-repeat: no-repeat;
      background-position: bottom left;
      transform: translate(-80px, 80px);

      @include mq(md) {
        background-position: bottom left;
        width: auto;
        height: 100%;
      }
    }

    &::after {
      content: ' ';
      border-style: solid;
      border-width: 41px 48.3px 0 41px;
      border-color: $black transparent transparent transparent;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%,0);

      @include mq(md) {
        top: 50%;
        left: 100%;
        transform: translate(-70%, -50%) rotateZ(90deg);
      }
    }
  }
}

.disable {
  cursor: not-allowed;
}
</style>