export default [
  {
    name: 'custom_header_jessa',
    path: 'modules/pages/jessafonds/HeaderJessafonds'
  },
  {
    name: 'custom_homepage_jessa',
    path: 'modules/pages/jessafonds/HomepageJessafonds'
  }
]
