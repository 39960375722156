<template>
  <div>
    <input-checkbox v-model="model">
      {{ translate(label) }}
    </input-checkbox>

    <p
      class="additional-text"
      v-if="translate(text)"
    >
      {{ interpolate(text, { tax_percentage_result: percentage }) }}
    </p>
  </div>
</template>

<script setup>
import { computed, toRefs, inject } from 'vue'
import { useModule } from '@/composables/app/useModule'
import { useProcedureTransaction } from '@/composables/modules/procedures'
import { useI18n } from '@/vendors/i18n'
import { useMoney } from '@/composables/app/useMoney'
import { get as _get, isUndefined as _isUndefined } from 'lodash'

import InputCheckbox from '&/atoms/InputCheckbox'

const props = defineProps({ block: { type: Object, required: true } })

const { block } = toRefs(props)

const procedure = inject('procedure')
const procedure_execution = inject('procedure_execution')

const { translate, interpolate } = useI18n()
const { getAmount } = useMoney()
const { model, label, attributes, defaultValue } = useModule({ component: block, parent: procedure_execution })
const { formAmount } = useProcedureTransaction({ procedure, procedure_execution })

const text = computed(() => _get(attributes.value, 'text', {}))
const amount = computed(() => formAmount.value >= 4000 ? (formAmount.value * 55 / 100) : formAmount.value)
const percentage = computed(() => getAmount({ amount }))

if (typeof model.value !== 'boolean' && !_isUndefined(defaultValue.value)) model.value = defaultValue.value
</script>

<style lang="scss" scoped>
.additional-text {
  color: $lg;
  margin-top: 4px;
  font-size: em(12px);
  white-space: pre-line;
  margin-left: 28px;
}
</style>