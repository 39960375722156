<template>
  <header
    class="header"
    :class="{'is-sticky': show_mobile_menu}"
  >
    <div class="container d-none d-md-flex align-items-center justify-content-end topbar">
      <language-switcher class="language-switcher" />
      <app-session-button class="session" />
    </div>

    <div class="container d-flex justify-content-between align-items-center">
      <router-link
        class="logo"
        :to="{ name: 'Home', params: { locale } }"
        @click="show_mobile_menu = false"
      >
        <img
          class="logo__img"
          :src="translate(logo) || client.logo.small.url"
          :alt="client.name"
        >
      </router-link>

      <nav
        class="navigation d-none d-md-block"
        :class="{'d-flex': show_mobile_menu}"
        v-if="links.length"
      >
        <ul class="navigation__ul d-md-flex align--center">
          <li
            v-for="({ link }, index) in links"
            :key="`navigation_${index}`"
            class="navigation__li"
          >
            <app-link
              :link="link"
              class="navigation__a link--naked"
              @click="show_mobile_menu = false"
            />
          </li>

          <li
            class="navigation__li navigation__li--cta"
            v-if="cta?.show"
          >
            <app-link
              :link="cta"
              class="navigation__a link--naked bg__color--prim navigation__a--cta"
              @click="show_mobile_menu = false"
            />
          </li>
        </ul>

        <div class="d-flex d-md-none justify-content-between navigation__actions">
          <language-switcher
            class="language-switcher language"
            v-slot="{ options, updateLanguage, isActive }"
          >
            <ul class="language__ul">
              <li
                class="language__li"
                v-for="language in options"
                :key="language.id"
              >
                <button
                  class="link--naked"
                  :class="{'language__btn--active': isActive(language.id)}"
                  type="button"
                  @click.prevent="updateLanguage(language.id)"
                >
                  {{ language.label.value.toUpperCase() }}
                </button>
              </li>
            </ul>
          </language-switcher>

          <app-session-button
            class="session"
            @click="show_mobile_menu = false"
            @close="show_mobile_menu = false"
          />
        </div>
      </nav>

      <app-burger-menu
        class="burger-menu d-md-none"
        :state="show_mobile_menu"
        @toggle="handleClickBurgerMenu()"
      />
    </div>
  </header>
</template>

<script setup>
import { ref, computed, toRefs } from 'vue'
import { useClientStore } from '@/stores/ClientStore'
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'
import { scrollTo } from '@/utils/utils'

import AppLink from '&/atoms/AppLink'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
import LanguageSwitcher from '&/atoms/LanguageSwitcher'
import AppSessionButton from '&/organisms/AppSessionButton'

const props = defineProps({ section: { type: Object, required: true } })

const { getClient } = useClientStore()
const { translate } = useI18n()
const { locale } = toRefs(useI18n())

const { client } = getClient()

const show_mobile_menu = ref(false)

const logo = computed(() => _get(props, 'section.attributes.logo', {}))
const links = computed(() => _get(props, 'section.attributes.links', []))
const cta = computed(() => _get(props, 'section.attributes.cta', {}))

const handleClickBurgerMenu = () => {
  scrollTo()

  show_mobile_menu.value = !show_mobile_menu.value
}
</script>


<style lang="scss" scoped>
$color--bg: #FFFFFF;
$color--primary: #46935A;
$color--secondary: #30643D;
$color--tertiary: #F3FFD3;

.header {
  position: relative;
  z-index: 999;
  background: $color--bg;

  &.is-sticky {
    position: sticky;
    top: 0;

    @include mq(md) {
      position: relative;
    }
  }
}

.topbar {
  gap: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 14px;
  position: relative;

  &::before,
  &::after {
    content: '';
    display: block;
    width: 100vw;
    height: 1px;
    background: #E9E9E9;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  @include mq(md) {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: rem(16px);
  }
}

.language-switcher {
  &:deep() {
    .locale-switcher {
      background: transparent;
    }

    .v-select,
    .vs__search {
      font-size: 14px;
      border: 0px!important;
    }

    .vs__dropdown-toggle {
      padding: 0;
      align-items: center;
      color: $color--secondary;
    }

    .vs__selected-options {
      line-height: 1;
    }

    .vs__selected {
      display: block;
      line-height: 21px;
      color: currentColor;

      @include mq(md) {
        font-size: rem(16px);
      }
    }

    .vs__actions {
      line-height: 16px;
      width: 12px;
      padding: 0;
    }

    .vs__open-indicator {
      fill: currentColor;
      font-size: rem(20px);
      line-height: 16px;
    }
  }
}

.logo {
  display: block;
  padding: 16px 0;
  position: relative;

  &__img {
    display: block;
    width: auto;
    height: 40px;

    @include mq(sm) {
      height: 50px;
    }

    @include mq(md) {
      height: 40px;
    }

    @include mq(lg) {
      height: 60px;
    }

    @include mq(xl) {
      height: 74px;
    }
  }
}

.navigation {
  font-size: rem(30px);
  position: fixed;
  background: $color--bg;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 100px 0 48px;
  z-index: -1;
  flex-direction: column;
  overflow-y: auto;

  @include mq(md) {
    font-size: rem(20px);
    position: relative;
    width: auto;
    height: auto;
    padding: 0;
    z-index: inherit;
  }

  &__ul {
    @include mq(md) {
      align-items: center;
    }
  }

  &__li {
    &--cta {
      @include mq(md) {
        margin-left: 8px;
      }
    }
  }

  &:deep(.navigation__a) {
    display: block;
    padding: 20px 16px;
    color: $color--secondary;
    transition: all .3s ease-in-out;

    @include mq(sm) {
      padding: 30px 16px;
    }

    @include mq(md) {
      padding: 60px 16px;
    }

    &:hover,
    &.is-active {
      color: $color--primary;
    }

    &.navigation__a--cta {
      color: $color--primary;
      background: $color--tertiary;

      @include mq(md) {
        padding: 60px 35px;
      }

      &:hover,
      &.is-active {
        background-color: $color--primary;
        color: #FFFEF5;
      }
    }
  }

  &__actions {
    font-size: 24px;
    padding: 24px 20px;
    margin-top: auto;
  }
}

.language {
  &__ul {
    display: flex;
    align-items: center;
  }

  &__li {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  &__btn {
    &--active {
      color: $color--primary;
    }
  }
}
</style>