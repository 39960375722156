<template>
  <div v-if="blockProducts.length">
    <div>
      <product-item
        v-for="product in blockProducts"
        class="products__item"
        :product="product"
        :key="product.id"
      />
    </div>

    <div
      class="input--errors margin__t--12"
      v-if="validator.$dirty && validator.$error"
    >
      <p
        class="input--error"
        v-if="validator.required && validator.required.$invalid"
      >
        {{ t('errors.product_selection_required') }}
      </p>

      <p
        class="input--error"
        v-if="validator.maxLength && validator.maxLength.$invalid"
      >
        {{ tc('plural.you_can_choose_a_maximum_of_x_option', validator.maxLength.$params.max, { x: validator.maxLength.$params.max }) }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, inject, toRefs, onMounted, onBeforeUnmount } from 'vue'
import { useModule } from '@/composables/app/useModule'
import { useProcedureBlockValidator, useProcedureOrder } from '@/composables/modules/procedures'
import { useI18n } from '@/vendors/i18n'
import { useTracking } from '@/vendors/tracking'
import { required, maxLength } from '@vuelidate/validators'
import { get as _get } from 'lodash'

const ProductItem = defineAsyncComponent(() => import('&/modules/procedures/fragments/order/ProductItem'))

const props = defineProps({ block: { type: Object, required: true } })

const { block } = toRefs(props)

const procedure = inject('procedure')
const procedure_execution = inject('procedure_execution')
const products = inject('products')

const { t, tc } = useI18n()
const tracking = useTracking()
const { attributes } = useModule({ component: block, parent: procedure_execution })
const { order } = useProcedureOrder({ procedure, procedure_execution, products })

const ids = computed(() => _get(attributes.value, 'ids', []))
const blockProducts = computed(() => block.value.name === 'project_products' ? products.value.filter(product => product.display_on_page === true && product.display_options !== 'never') : products.value.filter(product => ids.value.includes(product.id)).sort((a, b) => ids.value.indexOf(a.id) - ids.value.indexOf(b.id)))
const blockProductIds = computed(() => blockProducts.value.map(product => product.id))
const selectedProducts = computed(() => blockProducts.value.filter(product => order.value.some(order_line => order_line.product_id === product.id)))

const productLimit = computed(() => {
  const length = block.value.mandatory?.find(m => m.includes('maxProductLength'))

  return length ? parseInt(length.split(':')[1]) : false
})

const validations = computed(() => {
  const rules = {}

  if (blockProducts.value.length && block.value.is_required) rules.required = required
  if (productLimit.value) rules.maxLength = maxLength(productLimit.value)

  return rules
})

const { validator } = useProcedureBlockValidator({ validations, state: selectedProducts })

onMounted(() => tracking.procedure.viewItemList({ products: blockProducts.value, procedure: procedure.value }))

onBeforeUnmount(() => {
  if (!block.value.isVisible(procedure_execution.value) && blockProductIds.value.length && order.value.length) order.value = order.value.filter(({ product_id }) => !blockProductIds.value.includes(product_id))
})
</script>

<style lang="scss" scoped>
.products {
  &__item {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}
</style>