export default [
  {
    name: 'custom_footer_towalkagain',
    path: 'modules/pages/twa/FooterTwa'
  },
  {
    name: 'custom_header_towalkagain',
    path: 'modules/pages/twa/HeaderTwa'
  },
  {
    name: 'custom_homepage_towalkagain',
    path: 'modules/pages/twa/HomepageTwa'
  },
  {
    name: 'custom_towalkagain_levensverhalen',
    path: 'modules/pages/twa/LevensverhalenTwa'
  },
  {
    name: 'custom_towalkagain_network',
    path: 'modules/pages/twa/NetworkTwa'
  },
  {
    name: 'custom_towalkagain_waaromsteunen',
    path: 'modules/pages/twa/WaaromsteunenTwa'
  }
]
