export default [
  {
    name: 'custom_footer_unicefbelgique',
    path: 'modules/pages/unicefbelgique/FooterUnicefbelgique'
  },
  {
    name: 'homepage_unicefbelgique',
    path: 'modules/pages/unicefbelgique/HomepageUnicefbelgique'
  },
  {
    name: 'custom_header_unicefbelgique',
    path: 'modules/pages/unicefbelgique/HeaderUnicefbelgique'
  }
]
