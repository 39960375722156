<template>
  <div class="input__container cInputPassword">
    <div class="input__container">
      <input
        :type="this.type"
        class="input input-password input--border input--radius"
        name="input-password"
        v-model="password"
        :id="id"
        :placeholder="placeholder"
        @blur="blurIfMandatory()"
      >
      <fa-icon
        class="input__icon color--lg"
        @click.prevent="toggleInput"
        :icon="['far', icon]"
      />
    </div>

    <div
      class="input--errors"
      v-if="v && v.$error"
    >
      <p
        class="input--error"
        v-if="v.required && v.required.$invalid"
      >
        {{ $i18n.t('errors.input_password_required') }}
      </p>

      <p
        class="input--error"
        v-if="v.minLength && v.minLength.$invalid"
      >
        {{ $i18n.t('errors.input_password_min_length_8') }}
      </p>

      <p
        class="input--error"
        v-if="v.sameAs && v.sameAs.$invalid"
      >
        {{ $i18n.t('errors.input_password_wrong_validation') }}
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InputPassword',
  emits: ['update:modelValue'],
  props: {
    id: {
      type: String
    },
    placeholder: {
      type: String,
      default: '*********'
    },
    modelValue: {
      type: String,
      default: ''
    },
    v: {
      type: Object
    }
  },
  computed: {
    password: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  data () {
    return {
      type: 'password',
      icon: 'eye'
    }
  },
  methods: {
    toggleInput () {
      this.type === 'password' ? this.type = 'text' : this.type = 'password'
      this.icon === 'eye' ? this.icon = 'eye-slash' : this.icon = 'eye'
    },
    blurIfMandatory () {
      if (this.v) {
        this.v.$touch()
      }
    }
  }
})
</script>

<style lang="scss" scoped>
  .cInputPassword {
    .input__container {
      position: relative;
    }

    .input__password {
      padding-right: 36px;
      position: relative;
    }

    .input__icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;
    }
  }
</style>
