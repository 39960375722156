import { toValue, computed } from 'vue'
import { get as _get } from 'lodash'

export function useProcedure ({ procedure }) {
  const id = computed(() => _get(toValue(procedure), 'id'))
  const slug = computed(() => _get(toValue(procedure), 'slug'))
  const category = computed(() => _get(toValue(procedure), 'category'))
  const steps = computed(() => _get(toValue(procedure), 'form_configuration.steps', []))
  const targetId = computed(() => _get(toValue(procedure), 'target_id'))
  const targetType = computed(() => _get(toValue(procedure), 'target_type'))
  const redirectionLink = computed(() => _get(toValue(procedure), 'form_configuration.redirection_link'))
  const backRedirectionLink = computed(() => _get(toValue(procedure), 'form_configuration.back_redirection_link'))
  const paymentProvider = computed(() => _get(toValue(procedure), 'payment_provider'))
  const limitOfMembers = computed(() => _get(toValue(procedure), 'limit_of_members', 0))
  const successPaymentPageUrl = computed(() => _get(toValue(procedure), 'success_payment_page_url'))
  const externalReference = computed(() => _get(toValue(procedure), 'external_reference', ''))
  const additionalExternalReference = computed(() => _get(toValue(procedure), 'additional_external_reference', ''))

  const productsQuery = computed(() => {
    if (targetType.value === 'Project') return { project_id: targetId.value }
    if (targetType.value === 'Client') return { target_type: 'Procedure', target_id: id.value }
    if (targetType.value === 'Campaign') return { target_type: 'Campaign', target_id: targetId.value }

    return { 'sort_by_field[field]': 'created_at', 'sort_by_field[direction]': 'desc' }
  })

  const getDefaultValue = key => _get(toValue(procedure), `default_values.${key}`, null)

  return {
    id,
    slug,
    category,
    steps,
    targetId,
    targetType,
    redirectionLink,
    backRedirectionLink,
    paymentProvider,
    limitOfMembers,
    productsQuery,
    successPaymentPageUrl,
    externalReference,
    additionalExternalReference,
    getDefaultValue
  }
}