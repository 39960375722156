<template>
  <div v-if="isAuthenticated && allowedSportingActivitiesProvider('manual')">
    <slot name="button">
      <button
        class="link--naked link__color--prim align--left d-flex align-items-center button"
        @click.prevent="openModal"
      >
        <fa-icon
          class="margin__r--6"
          :icon="['fal', 'plus-circle']"
        />

        {{ t('literal.add_activity_manually') }}
      </button>
    </slot>

    <app-modal
      v-if="show_modal"
      size="sm"
      @close="closeModal"
    >
      <template #body>
        <div class="padding--24">
          <h2 class="bold title__h2">
            {{ t('literal.add_an_activity') }}
          </h2>

          <form-notification
            :keys="error"
            class="margin__t--18"
          />

          <fieldset class="margin__t--18">
            <label
              class="color--lg"
              for="activity_name"
            >
              {{ t('literal.title') }}
            </label>

            <input-text
              class="margin__t--6"
              v-model="activity.name"
              :v="v$.activity.name"
              id="activity_name"
              :placeholder="t('literal.evening_run')"
            />
          </fieldset>

          <fieldset class="margin__t--18">
            <label
              class="color--lg"
              for="activity_type"
            >
              {{ t('literal.type') }}
            </label>

            <input-select
              class="margin__t--6"
              v-model="type"
              id="activity_type"
              :options="typeOptions"
              :searchable="true"
              :v="v$.activity.activity_type"
            />
          </fieldset>

          <fieldset class="margin__t--18">
            <label
              class="color--lg"
              for="activity_distance"
            >
              {{ t('literal.distance') }}
            </label>

            <input-number
              class="margin__t--6"
              v-model="distance"
              id="activity_distance"
              :v="v$.distance"
            >
              <template #suffix>
                {{ t('literal.km').toLowerCase() }}
              </template>
            </input-number>
          </fieldset>

          <fieldset class="margin__t--18">
            <label
              class="color--lg"
              for="activity_time"
            >
              {{ t('literal.time') }}
            </label>

            <input-number
              class="margin__t--6"
              v-model="time"
              id="activity_time"
              :v="v$.time"
            >
              <template #suffix>
                {{ t('literal.minutes').toLowerCase() }}
              </template>
            </input-number>
          </fieldset>

          <button
            :disabled="loader"
            @click.prevent="handleClickSave"
            class="btn__container btn btn__size--small btn__color--prim margin__t--24"
          >
            <fa-icon
              v-if="!loader"
              class="margin__r--6"
              :icon="['fal', 'save']"
            />

            <app-spinner
              class="margin__r--6"
              v-else
              size="2"
            />

            {{ t('literal.save_activity') }}
          </button>
        </div>
      </template>
    </app-modal>
  </div>
</template>

<script setup>
import { reactive, computed, toRefs, defineAsyncComponent } from 'vue'
import { mapGetters } from '@/store/map-state'
import { storeToRefs } from 'pinia'
import { useSportingActivityStore } from '@/stores/SportingActivityStore'
import { useAllowedFeatures } from '@/composables/app/useAllowedFeatures'
import { useModals } from '@/composables/app/useModals'
import { useI18n } from '@/vendors/i18n'
import SportingActivity from '@/classes/sporting-activities/SportingActivity'
import { useVuelidate } from '@vuelidate/core'
import { required, numeric, minValue } from '@vuelidate/validators'
import { get as _get, set as _set } from 'lodash'

const AppSpinner = defineAsyncComponent(() => import('&/atoms/AppSpinner'))
const InputText = defineAsyncComponent(() => import('&/atoms/InputText'))
const InputSelect = defineAsyncComponent(() => import('&/atoms/InputSelect'))
const InputNumber = defineAsyncComponent(() => import('&/atoms/InputNumber'))
const AppModal = defineAsyncComponent(() => import('&/organisms/AppModal'))
const FormNotification = defineAsyncComponent(() => import('&/molecules/FormNotification'))

const emit = defineEmits(['created'])

const props = defineProps({
  targetType: { type: String, required: true },
  targetId: { type: String, required: true },
  types: { type: Array, default: () => ['running', 'walking', 'biking', 'swimming', 'wheelchair'] }
})

const { t } = useI18n()
const { show_modal, openModal } = useModals()
const { allowedSportingActivitiesProvider } = useAllowedFeatures()
const { postActivity, $resetStore } = useSportingActivityStore()
const { loader, error } = storeToRefs(useSportingActivityStore())
const { isAuthenticated } = mapGetters('auth')

const { targetType, targetId, types } = toRefs(props)

const initial_activity = new SportingActivity({ target_type: targetType.value, target_id: targetId.value })

const rules = {
  activity: { name: { required }, activity_type: { required } },
  distance: { required, minValue: minValue(0.1) },
  time: { required, numeric, minValue: minValue(1) }
}

const activity = reactive({ ...initial_activity })

const typeOptions = computed(() => types.value.map(type => ({ label: t(`literal.${type.toLowerCase()}`), value: type })))

const type = computed({
  get: () => _get(activity, 'activity_type', null),
  set: type => _set(activity, 'activity_type', type)
})

const distance = computed({
  get: () => _get(activity, 'distance', 0) / 1000,
  set: distance => _set(activity, 'distance', distance * 1000)
})

const time = computed({
  get: () => _get(activity, 'moving_time', 0) / 60,
  set: time => {
    _set(activity, 'moving_time', time * 60)
    _set(activity, 'elapsed_time', time * 60)
  }
})

const v$ = useVuelidate(rules, { activity, distance, time })

const handleClickSave = () => {
  v$.value.$touch()

  if (v$.value.$error) return

  postActivity({ params: activity })
    .then(() => {
      closeModal()

      emit('created')
    })
}

const closeModal = () => {
  show_modal.value = false

  v$.value.$reset()
  $resetStore('error')

  Object.assign(activity, initial_activity)
}
</script>

<style lang="scss" scoped>
.button {
  font-size: rem(14px);
  line-height: 1.3;
}
</style>
