<template>
  <header
    class="header"
    :class="{ 'is-home': isHome, 'is-fixed': showMobileMenu }"
  >
    <div class="container header__wrapper">
      <div class="topbar">
        <router-link
          class="session__user d-flex align-items-center padding__lr--12 padding__tb--6 link__color--sec link--naked"
          :to="{ name: 'Profile', params: { locale } }"
          v-if="isAuthenticated && user"
        >
          <user-profile-img
            :size="2"
            :avatar="user.avatar"
          />

          <p class="margin__l--6">
            {{ user.firstname }}
          </p>
        </router-link>

        <button
          @click.prevent="toggleSessionModal('login')"
          class="session__login padding__lr--6 link__color--sec link--naked d-flex align-items-center"
          v-else
        >
          <img
            src="https://koalect-images.s3.eu-west-3.amazonaws.com/clients/saintluc/user.svg"
            aria-hidden="true"
            class="margin__r--6"
          >

          {{ t('literal.login') }}
        </button>
      </div>

      <div class="logo__container">
        <app-link
          :link="logo.website"
          class="link__color--sec link--naked logo__website d-inline-block"
          v-slot="{ text }"
        >
          <fa-icon
            class="margin__r--6"
            :icon="['fal', 'arrow-left']"
          />

          {{ text }}
        </app-link>

        <router-link
          :to="{ name: 'Home', params: { locale } }"
          class="logo"
          v-if="translate(logo.img) || client.logo.url"
        >
          <img
            class="logo__img"
            :src="translate(logo.img) || client.logo.url"
            :alt="client.name"
          >
        </router-link>
      </div>

      <div class="d-flex align-items-center justify-content-end justify-content-md-between">
        <nav
          class="d-md-flex justify-content-between align-items-md-center menus"
          :class="{ 'd-flex': showMobileMenu, 'd-none': !showMobileMenu }"
        >
          <ul class="navigation d-md-flex align-items-center">
            <li
              class="navigation__li"
              v-for="({ link }, key) in navigation"
              :key="`link_${key}`"
            >
              <app-link
                :link="link"
                class="navigation__link link__color--black-prim link--naked"
              />
            </li>
          </ul>

          <div class="padding__lr---12 padding__t--12 padding__b--60">
            <app-link
              :link="cta"
              class="btn btn__color--sec btn__size--classic cta d-inline-block d-sm-none"
            />
          </div>
        </nav>

        <app-link
          :link="cta"
          class="btn btn__color--sec btn__size--classic cta d-none d-sm-block"
        />

        <app-burger-menu
          class="burger d-md-none no-padding--r"
          :state="showMobileMenu"
          @toggle="toggleMobileMenu"
        />
      </div>
    </div>

    <app-modal
      v-if="showSessionModal"
      size="sm"
      @close="showSessionModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :template="sessionTemplate"
          @close="showSessionModal = false"
        />
      </template>
    </app-modal>
  </header>
</template>

<script setup>
import { computed, defineAsyncComponent, ref, watch } from 'vue'
import { mapGetters } from '@/store/map-state'
import { get as _get } from 'lodash'
import { useI18n } from '@/vendors/i18n'
import { scrollTo } from '@/utils/utils'
import { useRoute } from 'vue-router'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const AppBurgerMenu = defineAsyncComponent(() => import('&/atoms/AppBurgerMenu'))
const UserProfileImg = defineAsyncComponent(() => import('&/atoms/UserProfileImg'))
const AppModal = defineAsyncComponent(() => import('&/organisms/AppModal'))
const SessionForms = defineAsyncComponent(() => import('&/modules/sessions/SessionForms'))

const props = defineProps({
  section: { type: Object, required: true }
})

const { locale, t, translate } = useI18n()
const route = useRoute()
const { client } = mapGetters('client')
const { isAuthenticated, user } = mapGetters('auth')

const isHome = computed(() => route.name === 'Home')

const showSessionModal = ref(false)
const showMobileMenu = ref(false)
const sessionTemplate = ref('login')
const logo = computed(() => _get(props, 'section.attributes.logo', []))
const navigation = computed(() => _get(props, 'section.attributes.navigation', []))
const cta = computed(() => _get(props, 'section.attributes.cta', {}))

watch(route, () => showMobileMenu.value = false)

const toggleSessionModal = template => {
  sessionTemplate.value = template
  showSessionModal.value = true
}

const toggleMobileMenu = () => {
  scrollTo()

  setTimeout(() => showMobileMenu.value = !showMobileMenu.value, 100)
}
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  z-index: 99;
  background: $white;

  &.is-home {
    background: transparent;
  }

  &.is-fixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    height: 100vh;
    background: $white;

    @include mq(md) {
      background: none;
      position: relative;
      height: inherit;
    }

    .header {
      &__wrapper {
        border-radius: 0;

        @include mq(md) {
          border-radius: 0 0 57px 57px;
        }
      }
    }
  }

  &__wrapper {
    background: $white;
    display: grid;
    gap: 6px 12px;
    padding: 12px 24px;
    border-radius: 0 0 32px 32px;
    grid-template-columns: 140px auto;
    grid-template-rows: auto 1fr;
    align-items: center;
    grid-template-areas:
      "logo topbar"
      "logo menus";

    @include mq(sm) {
      grid-template-columns: 170px auto;
      gap: 12px;
    }

    @include mq(md) {
      gap: $margin__base $margin__base * 2;
      grid-template-columns: 220px auto;
      padding: 12px 48px 40px;
      border-radius: 0 0 57px 57px;
      align-items: flex-start;
    }

    @include mq(lg) {
      padding: 12px 108px 40px;
      grid-template-columns: 240px auto;
    }

    @include mq(xl) {
      gap: $margin__base 88px;
    }
  }
}

:deep(.cta) {
  padding: 18px 40px;
  border-radius: 27px;
  font-size: rem(16px);
  line-height: 1.16;
  flex: 0 0 auto;
  letter-spacing: -0.16px;

  @include mq(md) {
    font-size: rem(18px);
  }
}
.topbar {
  display: flex;
  flex-direction: row-reverse;
  grid-area: topbar;
}
.session {
  &__login,
  &__user {
    font-size: rem(12px);

    @include mq(sm) {
       font-size: rem(14px);
    }

    @include mq(lg) {
      font-size: rem(16px);
    }
  }
}

.logo {
  display: block;
  grid-area: logo;

  &__container {
    grid-area: logo;

    &:deep(.logo__website) {
      font-size: rem(10px);
      margin-bottom: 12px;

      @include mq(sm) {
        margin-bottom: 18px;
      }
    }
  }

  &__img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.burger {
  :deep(.menu__label) {
    margin-right: calc($margin__base / 2);
    font-size: rem(12px);
  }
}

.menus {
  text-align: center;
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 108px);
  flex-direction: column;
  scrollbar-width: none;
  grid-area: menus;
  position: fixed;
  left: 0;
  bottom: 0;
  background: $white;

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
     display: none;
  }

  @include mq(sm) {
    height: calc(100vh - 123px);
  }

  @include mq(md) {
    text-align: left;
    flex-direction: row;
    flex: 1 1 auto;
    height: auto;
    overflow: hidden;
    position: relative;
  }
}

.navigation {
  flex: 1 1 0;
  font-size: rem(24px);
  line-height: 1;
  text-align: center;
  padding: 24px;
  min-width: 0;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;

  @include mq(sm) {
    padding: 48px 24px;
  }

  @include mq(md) {
    padding: 0;
    font-size: rem(16px);
  }

  @include mq(lg) {
    font-size: rem(18px);
  }

  &::-webkit-scrollbar {
     display: none;
  }

  @include mq(md) {
    text-align: left;
    padding: 0;
    overflow-x: auto;
    font-size: rem(18px);
  }

  &__li {
    @include mq(md) {
      white-space: nowrap;

      &:not(:last-child) {
        margin-right: 12px;

        @include mq(lg) {
          margin-right: 30px;
        }
      }
    }
  }

  :deep(.navigation__link) {
    display: block;
    padding: $padding__base * 2;
    line-height: 1;

    @include mq(md) {
      padding: calc($padding__base / 2) 0;
    }

    &.is-active {
      color: $color--primary;
    }
  }
}
.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}
</style>
