<template>
  <div
    class="slider"
    :style="{ width: `${width}px`}"
  >
    <div
      class="slider__slides"
      :class="{'has-transition': has_transition}"
      :style="{ width: `${width * clonedSlides.length}px`, transform: `translateX(${ (width * current_index) * -1 }px)`, left: left }"
    >
      <figure
        class="slides__slide slide"
        :style="{ backgroundImage: `url(${translate(slide?.image)})`, width: `${width}px`}"
        v-for="(slide, index) in clonedSlides"
        :key="`slide-${index}`"
      />
    </div>

    <nav
      class="slider__arrows container"
      v-if="slides.length > 1"
    >
      <button
        class="arrow arrow--prev"
        @click.prevent="handleClickPrev()"
      >
        <fa-icon
          class="element color--white"
          size="xl"
          :icon="['fal', 'long-arrow-left']"
        />
      </button>

      <button
        class="arrow arrow--next"
        @click.prevent="handleClickNext()"
      >
        <fa-icon
          class="element color--white"
          size="xl"
          :icon="['fal', 'long-arrow-right']"
        />
      </button>
    </nav>

    <nav
      class="container slider__nav"
      v-if="slides.length > 1"
    >
      <ul class="nav__ul">
        <li
          class="nav__li"
          v-for="(slide, index) in slides"
          :key="`nav-${index}`"
        >
          <button
            class="nav__bullet"
            :class="{ 'is-active': current_index === index }"
            @click="current_index = index"
          />
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup>
import { ref, computed, toRefs, watch } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useWindowSize } from '@/composables/app/useWindowSize'

const props = defineProps({ slides: { type: Array, default: () => [] } })

const { translate } = useI18n()
const { width } = useWindowSize()

const { slides } = toRefs(props)

const current_index = ref(0)
const has_transition = ref(true)

const left = computed(() => slides.value.length > 1 ? `-${width.value * 2}px` : '0px')

const clonedSlides = computed(() => {
  if (slides.value.length <= 1) return slides.value

  const cloned_slides = [...slides.value]

  for (let i = 1; i <= 2; i++) cloned_slides.unshift(slides.value[slides.value.length - i])

  for (let i = 0; i < 2; i++) cloned_slides.push(slides.value[i])

  return cloned_slides
})

const handleClickPrev = () => {
  if (current_index.value - 1 < 0) {
    has_transition.value = false
    current_index.value = slides.value.length

    setTimeout(() => {
      has_transition.value = true
      current_index.value--
    }, 10)
  } else {
    current_index.value--
  }
}

const handleClickNext = () => {
  if (current_index.value >= slides.value.length - 1) {
    has_transition.value = false
    current_index.value = -1

    setTimeout(() => {
      has_transition.value = true
      current_index.value = 0
    }, 10)

  } else {
    current_index.value++
  }
}

watch(width, () => {
  has_transition.value = false

  setTimeout(() => has_transition.value = true, 10)
})
</script>

<style lang="scss" scoped>
$color--bg: #FFFFFF;
$color--primary: #46935A;

.slider {
  width: 100%;
  height: 100%;

  &__slides {
    position: relative;
    display: flex;
    height: 100%;

    &.has-transition {
      transition: all .3s ease-in-out;
    }
  }

  &__slide {
    flex: 1 0 100vw;
    height: 100%;
  }

  &__nav {
    position: absolute;
    left: 50%;
    bottom: 32px;
    transform: translateX(-50%);

    @include mq(md) {
      bottom: 48px;
    }
  }

  &__arrows {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: rem(24px);
  }
}

.slide {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: $color--bg;
}

.nav {
  &__ul {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    @include mq(sm) {
      gap: 16px;
      justify-content: center;
    }
  }

  &__bullet {
    position: relative;
    display: block;
    width: 10px;
    height: 10px;
    background: $color--bg;
    transition: all .2s ease-in-out;
    border-radius: 50%;
    z-index: 9999;

    @include mq(sm) {
      width: 16px;
      height: 16px;
    }

    &:hover {
      background: rgba(#fff, 0.7);
    }

    &.is-active {
      background: $color--primary;
    }
  }
}

.arrow {
  svg {
    transition: all .2s ease-in-out;
  }

  &--prev {
    &:hover {
      svg {
        @include mq(md) {
          transform: translateX(-10px);
        }
      }
    }
  }

  &--next {
    &:hover {
      svg {
        @include mq(md) {
          transform: translateX(10px);
        }
      }
    }
  }
}
</style>