import { toValue } from 'vue'
import { defineStore } from 'pinia'
import Project from '@/classes/projects/Project'
import Projects from '@/classes/projects/Projects'
import Contributions from '@/classes/contributions/Contributions'
import { pick as _pick } from 'lodash'

export const useProjectStore = defineStore('ProjectStore', {
  state: () => ({
    projects: {},
    project: {},
    statistics: {},
    public_procedure_executions: {},
    contributions: {},
    invites: {},
    invite: {},
    loader: false,
    error: {}
  }),
  getters: {
    getProjects: state => ({ query, dependencies } = {}) => {
      return state.$getItems({
        resource: 'projects',
        query,
        dependencies,
        fetch: () => state.fetchProjects({ query })
      })
    },
    getProject: state => ({ id, dependencies }) => {
      return state.$getItem({
        resource: 'project',
        key: id,
        dependencies,
        fetch: () => state.fetchProject({ id })
      })
    },
    getStatistics: state => ({ id }) => {
      return state.$getItem({
        resource: 'statistics',
        key: id,
        fetch: () => state.fetchStatistics({ id })
      })
    },
    getPublicProcedureExecutions: state => ({ id, query } = {}) => {
      return state.$getItems({
        resource: 'public_procedure_executions',
        query,
        fetch: () => state.fetchPublicProcedureExecutions({ id, query })
      })
    },
    getContributions: state => ({ id, query } = {}) => {
      return state.$getItems({
        resource: 'contributions',
        query,
        fetch: () => state.fetchContributions({ id, query })
      })
    },
    getInvites: state => ({ id, query, dependencies }) => {
      return state.$getItem({
        resource: 'invites',
        key: id,
        dependencies,
        fetch: () => state.fetchInvites({ id, query })
      })
    },
    getInvite: state => ({ id, token, dependencies }) => {
      return state.$getItem({
        resource: 'invite',
        key: token,
        dependencies,
        fetch: () => state.fetchInvite({ id, token: token })
      })
    }
  },
  actions: {
    fetchProjects ({ query }) {
      return this.$fetchItems({
        endpoint: '/api/v1/projects',
        resource: 'projects',
        model: Projects,
        query
      })
    },
    fetchProject ({ id }) {
      return this.$fetchItem({
        endpoint: `/api/v1/projects/${id}`,
        resource: `project.${id}`,
        model: Project
      })
    },
    fetchStatistics ({ id }) {
      return this.$fetchItem({
        endpoint: `/api/v1/projects/${id}/statistics`,
        resource: `statistics.${id}`
      })
    },
    patchProject ({ id, params }) {
      return this.$patchItem({
        endpoint: `/api/v1/projects/${id}`,
        resource: `project.${id}`,
        model: Project,
        params: {
          project: _pick(new Project(params), Object.keys(params))
        }
      })
    },
    patchProjectImage ({ id, params }) {
      return this.$patchItem({
        endpoint: `/api/v1/projects/${id}`,
        resource: `project.${id}`,
        model: Project,
        params,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
    },
    deleteProject ({ id }) {
      return this.$deleteItem({
        endpoint: `/api/v1/projects/${id}`,
        resource: `project.${id}`
      })
    },
    fetchPublicProcedureExecutions ({ id, query }) {
      return this.$fetchItems({
        endpoint: `/api/v1/projects/${toValue(id)}/public_procedure_executions`,
        resource: 'public_procedure_executions',
        query
      })
    },
    fetchContributions ({ id, query }) {
      return this.$fetchItems({
        endpoint: `/api/v1/projects/${toValue(id)}/contributions`,
        resource: 'contributions',
        model: Contributions,
        query
      })
    },
    fetchInvites ({ id, query }) {
      return this.$fetchItem({
        endpoint: `/api/v1/projects/${id}/invites`,
        resource: `invites.${id}`,
        query
      })
    },
    fetchInvite ({ id, token }) {
      return this.$fetchItem({
        endpoint: `/api/v1/projects/${id}/invites/${token}`,
        resource: `invite.${token}`
      })
    },
    fetchContributionsExport ({ id, query, filename }) {
      return this.$fetchExport({
        endpoint: `/api/v1/projects/${id}/contributions/download`,
        filename,
        query
      })
    },
    postProjectInvites ({ id, params }) {
      return this.$postItem({
        endpoint: `/api/v1/projects/${id}/invites`,
        resource: `invites.${id}`,
        params: {
          invites: params
        }
      })
    },
    deleteProjectInvite ({ id, invite_id }) {
      return this.$deleteItem({
        endpoint: `/api/v1/projects/${id}/invites/${invite_id}`
      })
    },
    deleteProjectMember ({ id, member_id }) {
      return this.$deleteItem({
        endpoint: `/api/v1/projects/${id}/members/${member_id}`
      })
    }
  }
})