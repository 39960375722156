<template>
  <button
    type="button"
    class="btn btn__size--small btn__color--prim"
    :class="{ 'btn--unsaved': !isSaved, 'btn__responsive': isResponsive }"
    :disabled="isDisabled || isLoading"
    @click.prevent="handleClick()"
  >
    <slot
      name="icon"
      v-if="!isLoading && isSaved && showIcon"
    >
      <fa-icon
        class="icon"
        :icon="['far', 'save']"
        fixed-width
      />
    </slot>

    <fa-icon
      v-if="!isSaved && !isLoading"
      :icon="['fal', 'exclamation-triangle']"
      fixed-width
    />

    <app-spinner
      v-if="isLoading"
      size="2"
    />

    <div class="btn__text">
      <slot name="default">
        <p>{{ t('literal.save') }}</p>
      </slot>
    </div>
  </button>
</template>

<script setup>
import { defineAsyncComponent, toRefs } from 'vue'
import { useI18n } from '@/vendors/i18n'

const AppSpinner = defineAsyncComponent(() => import('&/atoms/AppSpinner'))

const emit = defineEmits(['click', 'clickUnsaved'])
const props = defineProps({
  isLoading: { type: Boolean, default: false },
  isDisabled: { type: Boolean, default: false },
  isSaved: { type: Boolean, default: true },
  isResponsive: { type: Boolean, default: false },
  showIcon: { type: Boolean, default: true }
})

const { t } = useI18n()

const { isLoading, isSaved, isDisabled, showIcon } = toRefs(props)
const handleClick = () => {
  emit('click')
  if (isSaved.value === false) emit('clickUnsaved')
}
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  align-items: center;
  gap: 6px;

  &__responsive {
    background-color: transparent;
    white-space: nowrap;
    padding: 0 6px;
    margin-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    color: $color--primary;
    border: 1px solid $color--primary;
    border-radius: 50%;

    & > .btn__text {
      display: none;

      @include mq(md) {
        display: inherit;
      }
    }

    @include mq(md) {
      background-color: $color--primary;
      border-radius: $radius__buttons;
      color: set-contrast($color--primary, $white);
      padding: calc($padding__base / 2) $padding__base * 2;
      margin-left: 24px;
      border: 0;
      width: auto;
      height: auto;

      &:focus,
      &:hover {
        background-color: shade($color--primary);
      }
    }

    &.btn--unsaved {
      border: 1px solid $color--warning;
      color: $color--warning;

      @include mq(md) {
        background-color: $color--warning;
        color: set-contrast($color--warning, $white);

        &:focus,
        &:hover {
          background-color: shade($color--warning, 8%, 35%);
        }
      }
  }
  }
}
</style>