<template>
  <footer
    v-if="section"
    class="footer"
  >
    <div class="wrapper footer__wrapper">
      <div class="footer__col">
        <img
          v-if="$i18n.translate(column_1.logo)"
          :src="$i18n.translate(column_1.logo)"
          :alt="$i18n.translate(column_1.organisation.name)"
          width="250"
          height="86"
          class="footer__logo"
        >

        <address
          v-if="organisation.address.length > 0"
          class="footer__address margin__t--24"
        >
          <template
            v-for="(item, index) in organisation.address"
            :key="'footer_address_line_' + index"
          >
            <span class="d-block">
              {{ $i18n.translate(item.line) }}
            </span>
          </template>
        </address>

        <address
          v-if="contactInfos.length > 0"
          class="footer__address margin__t--24"
        >
          <template v-for="(item, index) in contactInfos">
            <a
              class="footer__infos-link d-block"
              :key="'footer_infos_line_' + index"
              :href="item.url"
              v-if="item.url"
              target="_blank"
            >
              {{ item.text }}
            </a>

            <span
              v-else
              class="footer__infos-text d-block"
              :key="'footer_infos_line_' + index"
            >
              {{ item.text }}
            </span>
          </template>
        </address>
      </div>

      <div class="footer__col">
        <nav
          v-if="navigation"
          class="footer__navigation"
        >
          <ul
            v-for="(menu, key) in navigation"
            :key="`footer_nav_${key}`"
            class="footer__menu"
          >
            <li
              v-for="(item, index) in menu"
              :key="`footer_nav_${key}_${index}`"
              class="footer__menu-item"
            >
              <a
                class="footer__menu-link bold"
                :class="{'footer__menu-link--active': isVisited(item.link)}"
                @click.prevent="setRouteNavigation(item.link)"
              >
                {{ $i18n.translate(item.link.text) }}
              </a>
            </li>
          </ul>

          <ul
            class="networks"
            v-if="socialNetworks.length"
          >
            <li
              class="networks__item"
              v-for="(network, index) in socialNetworks"
              :key="'footer_menu_network_' + index"
            >
              <a
                class="networks__link"
                :href="$i18n.translate(network.url)"
                target="_blank"
              >
                <span
                  class="networks__text"
                  v-if="network.text"
                >
                  {{ $i18n.translate(network.text) }}
                </span>

                <fa-icon
                  v-if="network.icon"
                  size="lg"
                  :icon="network.icon"
                />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </footer>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'

export default defineComponent({
  name: 'CustomFooterUMons',
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client'
    }),
    organisation () {
      return _get(this.column_1, 'organisation', {})
    },
    contact () {
      return _get(this.column_1, 'contact', {})
    },
    contactInfos () {
      return _get(this.contact, 'infos', [])
        .map(info => {
          return {
            text: this.$i18n.translate(info.text),
            url: info.type !== 'text' ? (info.type === 'phone_number' ? 'tel:' : 'mailto:') + this.$i18n.translate(info.text).replace(/ /g, '') : null
          }
        })
        .filter(info => info.text)
    },
    navigation () {
      return this.getMenu(_get(this.column_2, 'navigation', []))
    },
    column_1 () {
      return _get(this.section, 'attributes.column_1')
    },
    column_2 () {
      return _get(this.section, 'attributes.column_2')
    },
    socialNetworks () {
      const links = _get(this.client, 'platform.social_networks', [])

      return links
        .map(link => {
          if (link.icon.includes('facebook-square')) {
            link.icon[1] = 'facebook-f'
          } else if (link.icon.includes('linkedin')) {
            link.icon[1] = 'linkedin-in'
          }

          return link
        })
        .filter(link => link.url[this.$i18n.locale])
    }
  },
  methods: {
    getMenu (menu) {
      const index = menu.length / 2

      return [menu.slice(0, index), menu.slice(index)]
    }
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #1D97B1;
$color--secondary: #B80237;

.wrapper {
  max-width: 1318px;
  margin: 0 auto;
  padding: 0 $padding__base * 1.5;

  @include mq(sm) {
    padding: 0 $padding__base * 2;
  }
}

.networks {
  display: flex;
  font-size: rem(15px);
  grid-area: networks;
  margin-top: $margin__base * 2;

  @include mq(sm) {
    margin-top: 0;
  }

  &__item {
    &:not(:last-child) {
      margin-right: $margin__base * 1.5;
    }
  }

  &__text {
    display: block;
    width: 0;
    height: 0;
    color: transparent;
  }

  &__link {
    color: $color--secondary;
    transition: all .3s;

    &:hover {
      color: shade($color--secondary);
    }
  }
}

.footer {
  position: relative;
  background: #D8D8D8;
  padding: $padding__base * 5 0;
  color: #131415;
  font-size: rem(14px);

  &__wrapper {
    @include mq(sm) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-areas: "contact contact contact contact . nav nav nav nav nav nav nav";
    }

    @include mq(md) {
      grid-template-areas: ". contact contact contact . nav nav nav nav nav nav .";
    }
  }

  &__col {
    &:first-child {
      grid-area: contact;
    }

    &:last-child {
      grid-area: nav;
    }

    &:not(:last-child) {
      margin-bottom: $margin__base * 3;

      @include mq(sm) {
        margin: 0 $margin__base * 2 0 0;
      }

      @include mq(md) {
        margin-right: $margin__base;
      }
    }
  }

  &__logo {
    max-width: 170px;
    width: 100%;
    height: auto;
  }

  &__address {
    color: #131415;

    @include mq(md) {
      font-size: rem(20px);
      line-height: 1.65;
    }
  }

  &__infos {
    @include mq(md) {
      font-size: rem(20px);
      line-height: 1.65;
    }

    &-link {
      color: #131415;
      transition: all .3s;

      &:hover {
        color: $color--secondary;
      }
    }
  }

  &__navigation {
    @include mq(sm) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: "nav1 nav2"
                           ". networks";
      gap: $margin__base * 5;
    }
  }

  &__menu {
    &:first-child {
      grid-area: nav1;
    }

    &:last-child {
      grid-area: nav2;
    }

    &:not(:last-child) {
      margin-bottom: $margin__base;
    }

    @include mq(sm) {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    @include mq(md) {
      font-size: rem(20px);
    }

    &-item {
      &:not(:last-child) {
        margin-bottom: $margin__base;

        @include mq(md) {
          margin-bottom: $margin__base * 1.5;
        }
      }
    }

    &-link {
      color: #131415;

      &:hover, &--active {
        color: $color--secondary;
      }
    }
  }
}
</style>
