export default [
  {
    name: 'default_header',
    path: 'modules/pages/default/headers/HeaderDefault'
  },
  {
    name: 'header_direct_donation',
    path: 'modules/pages/default/headers/HeaderDirectDonation'
  }
]
