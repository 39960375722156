<template>
  <footer class="footer">
    <div class="container footer__wrapper">
      <div>
        <router-link
          :to="{ name: 'Home', params: { locale } }"
          class="logo"
        >
          <img
            class="logo__img"
            :src="translate(logo) || client.logo.url"
            :alt="client.name"
          >
        </router-link>

        <div
          class="editor editor__content margin__t--24"
          v-if="translate(content)"
          v-html="translate(content)"
        />

        <ul
          class="networks margin__t--18 d-flex align-items-center"
          v-if="socialNetworks.length"
        >
          <li
            class="networks__li"
            v-for="(network, index) in socialNetworks"
            :key="'network_' + index"
          >
            <a
              class="networks__a"
              :href="$i18n.translate(network.url)"
              target="_blank"
              :aria-label="$i18n.translate(network.text)"
            >
              <fa-icon
                v-if="network.icon"
                size="lg"
                :icon="network.icon"
              />
            </a>
          </li>
        </ul>
      </div>

      <nav>
        <ul class="navigation__ul">
          <li
            v-for="({ link }, index) in links"
            :key="`navigation_${index}`"
            class="navigation__li"
          >
            <app-link
              :link="link"
              class="navigation__link link--naked link__color--black-prim"
            />
          </li>
        </ul>
      </nav>

      <div class="d-flex align-items-center flex-md-column  justify-content-md-end align-items-md-end footer__actions">
        <app-link
          :link="cta"
          class="bold btn btn__color--prim d-inline-block cta"
        />

        <div>
          <router-link
            class="session__user d-inline-flex align-items-center link__color--black-prim link--naked"
            :to="{ name: 'Profile', params: { locale } }"
            v-if="isAuthenticated && user"
          >
            <user-profile-img
              :size="2"
              :avatar="user.avatar"
            />

            <p class="margin__l--6">
              {{ user.firstname }}
            </p>
          </router-link>

          <button
            @click.prevent="toggleSessionModal('login')"
            class="session__login link__color--black-prim link--naked"
            v-else
          >
            <fa-icon
              class="margin__r--6"
              :icon="['fas', 'user']"
              fixed-width
            />

            {{ t('literal.login') }}
          </button>
        </div>
      </div>
    </div>

    <app-modal
      v-if="showSessionModal"
      size="sm"
      @close="showSessionModal = false"
    >
      <template #body>
        <div class="padding--24">
          <session-forms
            class="modal__body"
            :tab="sessionTemplate"
            @authenticated="showSessionModal = false"
          />
        </div>
      </template>
    </app-modal>
  </footer>
</template>

<script setup>
import { ref, computed, defineAsyncComponent } from 'vue'
import { mapGetters } from '@/store/map-state'
import { get as _get } from 'lodash'
import { useI18n } from '@/vendors/i18n'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const AppModal = defineAsyncComponent(() => import('&/organisms/AppModal'))
const SessionForms = defineAsyncComponent(() => import('&/modules/sessions/SessionForms'))
const UserProfileImg = defineAsyncComponent(() => import('&/atoms/UserProfileImg'))

const props = defineProps({
  section: { type: Object, required: true }
})

const { t, translate, getLocale } = useI18n()
const { client } = mapGetters('client')
const { isAuthenticated, user } = mapGetters('auth')

const showSessionModal = ref(false)
const sessionTemplate = ref('login')

const locale = computed(() => getLocale())
const logo = computed(() => _get(props, 'section.attributes.logo', {}))
const content = computed(() => _get(props, 'section.attributes.content', {}))
const cta = computed(() => _get(props, 'section.attributes.cta'))
const links = computed(() => _get(props, 'section.attributes.nav', []))

const socialNetworks = computed(() => _get(client.value, 'platform.social_networks', []).filter(network => translate(network.url)).map(network => {
  if (network.icon[1] === 'facebook-square') network.icon[1] = 'facebook-f'
  if (network.icon[1] === 'linkedin') network.icon[1] = 'linkedin-in'

  return network
}))

const toggleSessionModal = template => {
  sessionTemplate.value = template
  showSessionModal.value = true
}
</script>

<style lang="scss" scoped>
.footer {
  background: $white;
  box-shadow: 0 -3px 21px 0 rgba(0,0,0,0.06);
  padding: $padding__base * 4 0;

  @include mq(sm) {
    padding-bottom: $padding__base * 6;
  }

  :deep() {
    .cta {
      font-size: rem(18px);
      line-height: 1;
      border-radius: 0;
      padding: 16px $padding__base * 2;
    }
  }

  &__wrapper {
    display: grid;
    gap: $margin__base * 2;
    grid-template-columns: 1fr;

    @include mq(sm) {
      gap: $margin__base * 4;
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(md) {
      gap: $margin__base * 4;
      grid-template-columns: 300px auto 1fr;
    }

    @include mq(xl) {
      gap: $margin__base * 11;
      grid-template-columns: 300px 1fr 1fr;
    }
  }

  &__actions {
    gap: $margin__base;

    @include mq(sm) {
      grid-column: 1/3;
    }

    @include mq(md) {
      grid-column: inherit;
    }
  }
}

.logo {
  &__img {
    max-width: 190px;
  }
}
.networks {
  &__li {
    &:not(:last-child) {
      margin-right: $margin__base;
    }
  }

  &__a {
    display: block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: #AEC200;
    transition: $transition__duration;
    border-radius: 50%;
    color: #0064A4;
    font-size: rem(15px);

    &:hover {
      background: shade(#AEC200);
    }
  }
}

.navigation {
  &__li {
    &:not(:last-child) {
      margin-bottom: calc($margin__base / 2);
    }

    &:deep() {
      .navigation__link {
        font-size: rem(18px);
        line-height: 1.7;

        @include mq(lg) {
          font-size: rem(24px);
        }

        &.is-active {
          color: $color--primary;
        }
      }
  }
  }
}
</style>
