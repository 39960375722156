<template>
  <img
    class="image"
    v-if="translate(attributes.image)"
    :src="translate(attributes.image)"
    @error="setDefaultImage"
  >
</template>

<script setup>
import { toRefs, inject } from 'vue'
import { useModule } from '@/composables/app/useModule'
import { useI18n } from '@/vendors/i18n'
import { getImagePath } from '@/utils/utils'

const props = defineProps({ block: { type: Object, required: true } })

const { block } = toRefs(props)

const procedure_execution = inject('procedure_execution')

const { translate } = useI18n()
const { attributes } = useModule({ component: block, parent: procedure_execution })

const setDefaultImage = ({ target }) => target.src = getImagePath('placeholders/project-card-cover.jpg')
</script>

<style lang="scss" scoped>
.image {
  display: block;
  max-width: 100%;
}
</style>
