import { useClientStore } from '@/stores/ClientStore'
import { get as _get } from 'lodash'

export function useAllowedFeatures () {
  const { getClient } = useClientStore()

  const { client } = getClient()

  const providers = _get(client.value, 'allowed_features.sporting_activities.providers', false)
  const allowedPayconiq = !!_get(client.value, 'integrations.payconiq.payment_profile_id', false)
  const allowedSportingActivities = _get(client.value, 'integrations.strava.client_id', false) && (providers === true || Array.isArray(providers) && providers.length > 0)
  const allowedAccountVerification = _get(client.value, 'allowed_features.account_verification', false)

  const allowedSportingActivitiesProvider = provider => providers === true || Array.isArray(providers) && providers.includes(provider)

  return {
    allowedAccountVerification,
    allowedPayconiq,
    allowedSportingActivities,
    allowedSportingActivitiesProvider
  }
}