<template>
  <footer class="footer">
    <div class="container footer__wrapper">
      <div>
        <router-link
          :to="{ name: 'Home', params: { locale } }"
          class="logo d-block margin__b--18"
          v-if="translate(logo)"
        >
          <img
            class="logo__img"
            :src="translate(logo)"
            :alt="client.name"
            v-if="translate(logo)"
          >
        </router-link>

        <app-link
          :link="website"
          class="link__color--white link--naked"
        />
      </div>

      <div>
        <nav v-if="links.length > 0">
          <ul class="navigation">
            <li
              class="navigation__li"
              v-for="({ link }, key) in links"
              :key="`link_${key}`"
            >
              <app-link
                :link="link"
                class="navigation__link link__color--white link--naked bold"
              />
            </li>
          </ul>
        </nav>
      </div>

      <div>
        <div
          class="editor__content informations"
          v-if="$i18n.translate(informations)"
          v-html="$i18n.translate(informations)"
        />
      </div>

      <div>
        <ul class="actions margin__b--48">
          <li
            class="actions__li"
            v-for="({ link, color, type }, key) in actions"
            :key="`action_${key}`"
          >
            <app-link
              :link="link"
              :class="`actions__btn btn btn__${type}--${color}`"
            />
          </li>
        </ul>

        <ul
          class="social"
          v-if="socialMedias.length > 0"
        >
          <li
            class="social__li"
            v-for="(media, i) in socialMedias"
            :key="'footer_menu_media_' + i"
          >
            <a
              class="social__link"
              :href="translate(media.url)"
              target="_blank"
            >
              <fa-icon
                v-if="media.icon"
                size="lg"
                :icon="media.icon"
                class="social__icon"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { computed } from 'vue'
import { mapGetters } from '@/store/map-state'
import { get as _get } from 'lodash'
import { useI18n } from '@/vendors/i18n'

import AppLink from '&/atoms/AppLink'

const props = defineProps({
  section: { type: Object, required: true }
})

const { getLocale, translate } = useI18n()
const { client } = mapGetters('client')

const locale = computed(() => getLocale())
const logo = computed(() => _get(props, 'section.attributes.logo', []))
const website = computed(() => _get(props, 'section.attributes.website', []))
const links = computed(() => _get(props, 'section.attributes.links', []))
const informations = computed(() => _get(props, 'section.attributes.informations', []))
const actions = computed(() => _get(props, 'section.attributes.actions', []))

const socialMedias = computed(() => {
  const links = _get(client.value, 'platform.social_networks', [])
  return links.length > 0
    ? links.map(link => {
      if (link.icon.includes('facebook-square')) {
        link.icon[1] = 'facebook-f'
      } else if (link.icon.includes('linkedin')) {
        link.icon[1] = 'linkedin-in'
      }

      return link
    })
      .filter(link => translate(link.url))
    : []
})
</script>

<style lang="scss" scoped>
.footer {
  background: #236583;
  color: $white;
  padding: $padding__base * 2 0;
  text-align: center;

  @include mq(sm) {
    text-align: left;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: $margin__base * 2;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
      padding: $padding__base * 4 0 $padding__base * 5;
    }

    @include mq(md) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include mq(lg) {
      grid-template-columns: repeat(3, 1fr) 3fr;
      gap: $margin__base * 7.5;
    }
  }

  .logo {
    &__img {
      width: 108px;
      height: auto;
      display: block;
      margin: 0 auto;

      @include mq(sm) {
        margin: 0;
      }
    }
  }

  .navigation {
    font-size: rem(18px);

    &__li {
      &:not(:last-child) {
        margin-bottom: $margin__base;
      }
    }

    :deep(.navigation__link) {
      &.is-active {
        text-decoration: underline;
      }
    }
  }

  .informations {
    font-size: rem(12px);

    &:deep() {
      a {
        text-decoration: none;
      }
    }
  }

  .actions {
    @include mq(sm) {
      display: flex;
      align-items: center;
    }

    @include mq(md) {
      justify-content: flex-end;
    }

    &__li {
      padding: $padding__base calc($padding__base / 2);

      @include mq(sm) {
        padding: 0;

        &:not(:last-child) {
          margin-right: $margin__base;
        }
      }
    }

    :deep(.actions__btn) {
      width: 100%;
      display: block;
      border-radius: 50px;
      border-width: 2px;
      padding: calc($padding__base - 2px) $padding__base * 1.5;
      font-size: rem(18px);

      @include mq(md) {
        width: auto;
        font-size: rem(16px);
      }

      @include mq(lg) {
        padding: calc($padding__base - 2px) $padding__base * 2.5;
      }

      @include mq(xl) {
        font-size: rem(18px);
      }

      &[class*='btn__solid'] {
        border-width: 2px;
        padding-top: calc($padding__base - 4px);
        padding-bottom: calc($padding__base - 4px);
      }
    }
  }
  .social {
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq(sm) {
      justify-content: flex-start;
    }

    @include mq(md) {
      justify-content: flex-end;
    }

    &__li {
      &:not(:last-child) {
        margin-right: $margin__base;
      }
    }

    &__link {
      color: $white;
      display: block;
      text-align: center;
      width: 35px;
      height: 35px;
      position: relative;
      border: 1px solid $white;
      border-radius: 50%;
      transition: all $transition__duration;

      &:hover {
        background-color: $color--secondary;
      }

      & > * {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
