export default [
  {
    name: 'custom_footer_co2logic',
    path: 'modules/pages/co2logic/FooterCo2logic'
  },
  {
    name: 'custom_header_co2logic',
    path: 'modules/pages/co2logic/HeaderCo2logic'
  }
]
