import Configuration from '@/classes/templates/Configuration'
import { flattenDeep as _flattenDeep } from 'lodash'

export default class Template {
  constructor ({ id = null, configuration = {} } = {}) {
    this.id = id
    this.configuration = new Configuration(configuration)
  }

  getColumns () {
    return _flattenDeep(this.configuration.rows.map(row => row.columns))
  }

  getColumn (name) {
    return this.getColumns().find(column => column.name === name)
  }

  getBlocks () {
    return _flattenDeep(this.getColumns().map(column => column.blocks))
  }

  getBlock (name) {
    return this.getBlocks().find(block => block.name === name)
  }
}