<template>
  <footer class="footer">
    <div class="container footer__container--1">
      <div>
        <router-link
          class="logo"
          :to="{ name: 'Home', params: { locale } }"
          v-if="translate(column_1.logo)"
        >
          <img
            class="logo__img"
            :src="translate(column_1.logo)"
            :alt="client.name"
          >
        </router-link>
      </div>

      <div>
        <h4
          class="footer__title"
          v-if="translate(column_2.title)"
        >
          {{ translate(column_2.title) }}
        </h4>

        <address
          class="margin__t--12"
          v-html="address"
        />
      </div>

      <div>
        <h4
          class="footer__title"
          v-if="translate(column_3.title)"
        >
          {{ translate(column_3.title) }}
        </h4>

        <ul
          class="margin__t--12 contact"
          v-if="column_3.contact.length"
        >
          <li
            class="contact__li"
            v-for="({ type, prefix, value }, key) in column_3.contact"
            :key="`contact_${key}`"
          >
            <span
              class="contact__prefix"
              v-if="translate(prefix)"
            >
              {{ translate(prefix) }}
            </span>

            <template v-if="type === 'email'">
              <a :href="`mailto:${translate(value)}`">{{ translate(value) }}</a>
            </template>

            <template v-else-if="type === 'phone'">
              <a :href="`tel:${translate(value).replace(/[^\d.-]+/g, '')}`">{{ translate(value) }}</a>
            </template>

            <template v-else-if="type === 'website'">
              <a :href="value">{{ translate(value) }}</a>
            </template>

            <template v-else>
              {{ translate(value) }}
            </template>
          </li>
        </ul>
      </div>

      <div>
        <h4
          class="footer__title"
          v-if="translate(column_4.title)"
        >
          {{ translate(column_4.title) }}
        </h4>

        <ul
          class="margin__t--12 bank_accounts"
          v-if="column_4.bank_accounts.length"
        >
          <li
            class="bank_accounts__li"
            v-for="({ prefix, value }, key) in column_4.bank_accounts"
            :key="`bank_account_${key}`"
          >
            <span
              class="bank_accounts__prefix"
              v-if="translate(prefix)"
            >
              {{ translate(prefix) }}
            </span>

            {{ translate(value) }}
          </li>
        </ul>
      </div>
    </div>

    <div class="container padding__tb--36 footer__container--2">
      <ul
        class="networks"
        v-if="socialNetworks.length"
      >
        <li
          class="networks__li"
          v-for="(network, i) in socialNetworks"
          :key="'footer_menu_network_' + i"
        >
          <a
            class="networks__a"
            :href="translate(network.url)"
            :aria-label="translate(network.text)"
            target="_blank"
          >
            <fa-icon
              class="networks__icon"
              size="lg"
              :icon="network.icon"
            />
          </a>
        </li>
      </ul>

      <nav
        class="nav"
        v-if="column_5.bottom_navigation.length"
      >
        <ul class="nav__ul">
          <li
            class="nav__li"
            v-for="({ type, link, text }, key) in column_5.bottom_navigation"
            :key="`link_${key}`"
          >
            <app-link
              :link="link"
              class="nav__a"
              v-if="type === 'link'"
            />

            <span
              class="padding__lr--6"
              v-else
            >
              {{ translate(text) }}
            </span>
          </li>
        </ul>
      </nav>
    </div>

    <div class="aerf">
      <div class="container aerf__container">
        <figure>
          <img
            :src="translate(column_5.logo)"
            class="aerf__logo"
            width="273"
            height="94"
          >
        </figure>

        <div>
          <h5
            class="footer__title aerf__title"
            v-if="translate(column_5.title)"
          >
            <a
              class="aerf__link"
              :href="translate(column_5.link)"
              target="_blank"
              v-if="translate(column_5.link)"
            >
              {{ translate(column_5.title) }}
            </a>

            <template v-else>
              {{ translate(column_5.title) }}
            </template>
          </h5>

          <div
            class="editor__content margin__t--12 footer__text"
            v-html="translate(column_5.text)"
            v-if="translate(column_5.text)"
          />
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useClientStore } from '@/stores/ClientStore'
import { get as _get } from 'lodash'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))

const props = defineProps({
  section: { type: Object, required: true }
})

const { translate, locale } = useI18n()
const { getClient } = useClientStore()

const { client } = getClient()

const column_1 = computed(() => _get(props, 'section.attributes.column_1', {}))
const column_2 = computed(() => _get(props, 'section.attributes.column_2', {}))
const column_3 = computed(() => _get(props, 'section.attributes.column_3', {}))
const column_4 = computed(() => _get(props, 'section.attributes.column_4', {}))
const column_5 = computed(() => _get(props, 'section.attributes.column_5', {}))

const address = computed(() => _get(column_2.value, 'address', []).map(({ text }) => translate(text)).join('<br />'))

const socialNetworks = computed(() => {
  return _get(client.value, 'platform.social_networks', [])
    .map(link => {
      if (link.icon.includes('facebook-square')) {
        link.icon[1] = 'facebook-f'
      } else if (link.icon.includes('linkedin')) {
        link.icon[1] = 'linkedin-in'
      }

      return link
    })
    .filter(({ url }) => translate(url))
})
</script>

<style lang="scss" scoped>
$color--primary: #00AFEF;
$color--secondary: #202243;
$color--tertiary: #FFFF00;

a {
  color: #6f6f6f;
  transition: color 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);

  &:hover {
    color: $color--primary;
  }
}

.footer {
  padding-top: 48px;
  color: #6f6f6f;
  font-size: 15px;
  line-height: 1.73;
  background: $white;

  @include mq(sm) {
    padding-top: 90px
  }

  & > * {
    &:not(:first-child):not(:last-child) {
      border-top: 1px solid #dedede;
    }
  }

  &__container {
    &--1 {
      display: grid;
      grid-template-columns: 1fr;
      gap: 24px;
      padding-bottom: 48px;

      @include mq(sm) {
        grid-template-columns: repeat(2, 1fr);
        padding-bottom: 100px;
        gap: 42px;
      }

      @include mq(md) {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    &--2 {
      display: grid;
      grid-template-columns: 1fr;
      gap: 12px;

      @include mq(md) {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &__title {
    color: $color--secondary;
    font-size: rem(18px);
    font-weight: 500;
    line-height: 1.44;
    text-transform: uppercase;
    font-family: $font-family--secondary;

    a {
      color: $color--secondary;

      &:hover {
        color: $color--primary;
      }
    }
  }
}

.logo {
  display: block;

  &__img {
    width: auto;
    height: 95px;
  }
}

.contact {
  &__prefix {
    &::after {
      content: ' : ';
    }
  }
}

.bank_accounts {
  &__prefix {
    color: $color--secondary;

    &::after {
      color: #6f6f6f;
      content: ' : ';
    }
  }
}

.networks {
  display: flex;
  font-size: rem(13px);
  justify-content: center;
  padding-bottom: 12px;

  &:not(:last-child) {
    border-bottom: 1px solid #f5f5f5;
  }

  @include mq(md) {
    justify-content: flex-start;
    padding: 0;

    &:not(:last-child) {
      border: 0;
    }
  }

  &__a {
    display: block;
    padding: 0 12px;
    color: $color--primary;

    &:hover {
      color: #008cbf;
    }
  }
}

.nav {
  font-size: rem(13px);

  &__ul {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;

    @include mq(sm) {
      display: flex;
      justify-content: flex-start;
      justify-content: center;
    }

    @include mq(md) {
      justify-content: flex-start;
    }
  }

  &__li {
    &:not(:last-child) {
      &::after {
        @include mq(sm) {
          content: '-'
        }
      }
    }
  }

  :deep(.nav__a) {
    display: inline-block;
    padding: 0 6px;
    color: $color--primary;

    &:hover {
      color: #008cbf;
    }
  }
}

.aerf {
  padding: 40px 0;
  background: #f5f5f5;
  font-size: rem(15px);
  position: relative;

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    align-items: center;

    @include mq(sm) {
      display: flex;
    }

    @include mq(md) {
      gap: 76px;
    }
  }

  &__logo {
    width: auto;
    height: 94px;
    margin: 0 auto;
    display: block;

    @include mq(sm) {
      margin: 0;
    }
  }

  &__link {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>