import { toValue } from 'vue'
import { defineStore } from 'pinia'

export const useSecureStore = defineStore('SecureStore', {
  state: () => ({
    procedure: {},
    loader: false,
    error: {}
  }),
  getters: {
    getProcedureSecureToken: state => ({ id }) => {
      return state.$getItem({
        resource: 'procedure',
        key: id,
        fetch: () => state.fetchProcedureSecureToken({ id: toValue(id) })
      })
    }
  },
  actions: {
    fetchProcedureSecureToken ({ id }) {
      return this.$fetchItem({
        endpoint: `/api/v1/secure/token/procedure/${toValue(id)}`,
        resource: `procedure.${toValue(id)}`
      })
    }
  }
})