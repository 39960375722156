<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 647.6 472"
    style="enable-background:new 0 0 647.6 472;"
    width="647.6"
    height="472"
    xml:space="preserve"
  >


    <image
      width="1296"
      height="944"
      xlink:href="https://koalect-images.s3.eu-west-3.amazonaws.com/clients/deloods/campaign_building_level_1.png"
      transform="translate(0 .1) scale(.4998)"
      overflow="visible"
      enable-background="new"
    />

    <circle
      class="dot"
      :fill="getColorByIndex(0)"
      @click="handleClickDot(0)"
      v-if="getProjectIdByIndex(0)"
      cx="274"
      cy="87"
      r="14.5"
    />

    <circle
      class="dot"
      :fill="getColorByIndex(1)"
      @click="handleClickDot(1)"
      v-if="getProjectIdByIndex(1)"
      cx="352"
      cy="113"
      r="14.5"
    />

    <circle
      class="dot"
      :fill="getColorByIndex(2)"
      @click="handleClickDot(2)"
      v-if="getProjectIdByIndex(2)"
      cx="411"
      cy="170"
      r="14.5"
    />

    <circle
      :fill="getColorByIndex(3)"
      @click="handleClickDot(3)"
      v-if="getProjectIdByIndex(3)"
      class="dot"
      cx="296"
      cy="296"
      r="14.5"
    />

    <circle
      class="dot"
      :fill="getColorByIndex(4)"
      @click="handleClickDot(4)"
      v-if="getProjectIdByIndex(4)"
      cx="136"
      cy="360"
      r="14.5"
    />
  </svg>
</template>


<script setup>
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'

const emit = defineEmits('selected-project')

const props = defineProps({
  dots: { type: Array, default: () => [] }
})

const { translate } = useI18n()

const getProjectIdByIndex = index => _get(props, `dots.${index}.project_id.0`)
const getColorByIndex = index => translate(_get(props, `dots.${index}.color`))

const handleClickDot = index => emit('click-dot', index)
</script>

<style lang="scss" scoped>
.dot {
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke: #000000;
  stroke-width: 3;
  cursor: pointer;

  @supports ( transform-box: fill-box ) {
  transform-box: fill-box;
  transform-origin: 50% 50%;
  transition: all .2s;

  @include mq(sm) {
    &:hover {
      transform: translate(0, 0) scale(1.2);
    }
  }
}
}
</style>