<template>
  <fieldset>
    <label
      :for="`email_${id}`"
      class="input__label input__label--small"
    >
      {{ t('literal.email') }}
    </label>

    <input-email
      :id="`email_${id}`"
      class="margin__t--6"
      v-model="model"
      :v="v$"
    />
  </fieldset>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useVuelidate } from '@vuelidate/core'
import { email } from '@vuelidate/validators'
import { guid } from '@/utils/utils'

import InputEmail from '&/atoms/InputEmail'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({ modelValue: String })

const { t } = useI18n()

const id = guid(1)

const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})

const v$ = useVuelidate({ email }, model, { $scope: 'invite' })
</script>