<template>
  <div>
    <header
      class="header"
      :class="{'header--fixed': mobileMenuOpened}"
      v-if="section"
    >
      <div class="wrapper header__wrapper">
        <div class="header__cell">
          <router-link
            :to="{ name: 'Home', params: { locale: $i18n.locale } }"
            v-show="client.logo.url"
            class="logo"
          >
            <img
              class="logo__img"
              :src="client.logo.url"
              :alt="client.name"
            >
          </router-link>

          <nav
            v-if="navigation.length > 0"
          >
            <ul class="header__menu">
              <li
                class="header__menu-item"
                v-for="(item, index) in navigation"
                :key="`menu_item_${index}`"
              >
                <app-link
                  :link="item.link"
                  class="header__menu-link"
                  @click="mobileMenuOpened = false"
                />
              </li>
            </ul>
          </nav>
        </div>

        <div class="header__cell">
          <div class="margin__r--24 d-none d-md-flex">
            <button
              @click.prevent="openSessionAppModal('register')"
              class="header__session"
              v-if="!isAuthenticated"
            >
              <fa-icon
                class="margin__r--6"
                :icon="['fas', 'user']"
              />
              <span v-html="$i18n.t('literal.login')" />
            </button>

            <router-link
              class="header__session header__session--user d-flex align-items-center"
              :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
              v-if="isAuthenticated && user"
            >
              <user-profile-img
                size="2"
                :avatar="user.avatar"
              />

              <span class="d-block margin__l--6">{{ user.firstname }}</span>
            </router-link>
          </div>

          <nav
            v-if="actions.length > 0"
          >
            <ul class="header__actions">
              <li
                class="header__actions-item"
                v-for="(item, index) in actions"
                :key="`actions_item_${index}`"
              >
                <app-link
                  :link="item.link"
                  class="header__actions-link"
                  @click="mobileMenuOpened = false"
                />
              </li>
            </ul>
          </nav>

          <app-burger-menu
            class="header__burger d-md-none"
            :state="mobileMenuOpened"
            @toggle="toggleMobileMenu"
          />
        </div>
      </div>

      <transition name="fade-mobile-menu">
        <section
          class="mobile"
          v-if="mobileMenuOpened"
        >
          <div class="wrapper mobile__wrapper">
            <ul
              class="mobile__menu"
              v-if="navigation.length > 0"
            >
              <li
                class="mobile__menu-item"
                v-for="(item, index) in navigation"
                :key="`menu_mobile_item_${index}`"
              >
                <app-link
                  class="mobile__menu-link"
                  :link="item.link"
                  @click="mobileMenuOpened = false"
                />
              </li>
            </ul>

            <ul
              class="mobile__actions"
              v-if="actions.length > 0"
            >
              <li
                class="mobile__actions-item"
                v-for="(item, index) in actions"
                :key="`actions_mobile_item_${index}`"
              >
                <app-link
                  class="mobile__actions-link bold"
                  :link="item.link"
                  @click="mobileMenuOpened = false"
                />
              </li>
            </ul>

            <hr class="separator separator__size--full border__color--dw margin__tb--18">

            <ul class="mobile__sessions">
              <li
                class="mobile__sessions-item"
                v-if="!isAuthenticated"
              >
                <a
                  @click.prevent="openSessionAppModal('login')"
                  class="link__color--white link--naked pointer"
                >
                  <fa-icon
                    class="margin__r--6"
                    :icon="['fas', 'user']"
                    fixed-width
                  />
                  {{ $i18n.t('literal.login') }}
                </a>
              </li>

              <li v-if="isAuthenticated && user">
                <router-link
                  class="pointer d-flex align-items-center"
                  :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
                  @click.capture="mobileMenuOpened = false"
                >
                  <user-profile-img
                    class="margin__r--6"
                    :avatar="user.avatar"
                    size="2"
                  />

                  {{ user.firstname }}
                </router-link>
              </li>

              <li
                class="mobile__sessions-item mobile__sessions-item--logout pointer"
                v-if="isAuthenticated && user"
              >
                <router-link
                  :to="{ name: 'Logout', params: { locale: $i18n.locale } }"
                  class="link--naked pointer link__color--danger"
                >
                  <fa-icon
                    class="margin__r--6 color--danger"
                    :icon="['fas', 'sign-out']"
                    fixed-width
                  />
                  {{ $i18n.t('literal.logout') }}
                </router-link>
              </li>
            </ul>

            <hr class="separator separator__size--full border__color--dw margin__tb--18">
          </div>
        </section>
      </transition>
    </header>

    <app-modal
      v-if="showSessionAppModal"
      size="sm"
      @close="showSessionAppModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="template"
          @authenticated="showSessionAppModal = false"
        />
      </template>
    </app-modal>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
import { get as _get } from 'lodash'
import { mapGetters } from 'vuex'
import UserProfileImg from '&/atoms/UserProfileImg'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
import { scrollTo } from '@/utils/utils'

export default defineComponent({
  name: 'CustomHeader24hTelevie',
  components: {
    UserProfileImg,
    AppBurgerMenu,
    AppLink: defineAsyncComponent(() => import('&/atoms/AppLink')),
    SessionForms: defineAsyncComponent(() => import('&/modules/sessions/SessionForms')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  setup: () => ({ scrollTo }),
  data () {
    return {
      showSessionAppModal: false,
      mobileMenuOpened: false,
      template: null
    }
  },
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    navigation () {
      return _get(this.section, 'attributes.navigation', [])
    },
    actions () {
      return _get(this.section, 'attributes.actions', [])
    }
  },
  methods: {
    openSessionAppModal (template) {
      this.template = template
      this.showSessionAppModal = true
    },
    toggleMobileMenu (value) {
      const delay = !value ? 300 : 0
      this.scrollTo()

      setTimeout(() => {
        this.mobileMenuOpened = !value
      }, delay)
    }
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #f40b0b;
$color--secondary: #131415;

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;

  @include mq(sm) {
    padding: 0 30px;
  }

  @include mq(md) {
    padding: 0 60px;
  }
}

.logo {
  display: block;
  position: relative;
  width: 147px;

  @include mq(md) {
    margin-right: $margin__base * 2;
  }

  @include mq(lg) {
    margin-right: $margin__base * 4;
  }

  &__img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.header {
  padding: 20px 0;
  background: $white;

  &--fixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;

    @include mq(md) {
      position: relative;
    }
  }

  @include mq(sm) {
    padding: 32px 0;
  }

  @include mq(md) {
    padding: 45px 0;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__menu {
    display: none;

    @include mq(md) {
      display: flex;
      align-items: center;
      font-size: rem(20px);
    }

    @include mq(lg) {
      font-size: rem(24px);
    }

    &-item {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }

    :deep(.header__menu-link) {
      position: relative;
      display: block;
      color: $color--secondary;
      padding: 10px 0;
      transition: all .2s;
      font-weight: 500;

      &:after {
        content: '';
        position: absolute;
        display: block;
        background: $color--primary;
        width: 100%;
        height: 2px;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%) scale(0, 1);
        transition: all .2s;
      }

      &:hover, &.is-active {
        color: $color--primary;

        &:after {
          transform: translateX(-50%) scale(1);
        }
      }
    }
  }

  &__actions {
    display: none;

    @include mq(sm) {
      display: flex;
      align-items: center;
      font-size: 14px;
    }

    @include mq(md) {
      font-size: 16px;
    }

    @include mq(lg) {
       font-size: 18px;
    }

    &-item {
      &:not(:last-child) {
        margin-right: 20px;
      }

      &:nth-child(even) {
        .header__actions {
          &-link {
            font-weight: 500;
            background: $color--primary;
            color: $white;

            &:hover {
              background: darken($color--primary, 5);
              border-color: darken($color--primary, 5);
            }
          }
        }
      }
    }

    :deep(.header__actions-link) {
      display: block;
      color: $color--primary;
      padding: 17px 20px 15px;
      border: 2px solid $color--primary;
      line-height: 1;
      transition: all .2s;
      font-weight: 700;
      border-radius: 35px;
      transition: all .2s;
      text-align: center;

      @include mq(lg) {
        padding: 23px 20px 21px;
        min-width: 220px;
      }

      &:hover {
        background: $color--primary;
        color: $white;
      }
    }
  }

  &__burger {
    color: $color--primary;
    line-height: 1;

    :deep() {
      .menu__line {
        background-color: $color--primary;
      }

      .menu__close::before, .menu__close::after {
        background-color: $color--primary;
      }
    }
  }
}

.mobile {
  position: fixed;
  top: 104px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 104px);
  background: $color--secondary;
  z-index: 999;
  text-align: center;

  @include mq(sm) {
    top: 128px;
    height: calc(100vh - 128px);
  }

  @include mq(md) {
    display: none;
  }

  &__wrapper {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 24px;
  }

  &__menu {
    font-size: rem(36px);
    flex: 1 1 auto;
    margin-bottom: $margin__base * 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    :deep(.mobile__menu-link) {
      color: $white;
      padding: 10px 0;
      display: inline-block;
      position: relative;
      line-height: 1;

      &.is-active {
        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 2px;
          background: $white;
          left: 0;
          bottom: 0;
        }
      }
    }
  }

  &__actions {
    font-size: 18px;

    @include mq(sm) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-item {
      &:not(:last-child) {
        margin-bottom: $margin__base;

        @include mq(sm) {
          margin: 0 20px 0 0;
        }
      }

      &:nth-child(even) {
        .mobile__actions {
          &-link {
            background: $color--primary;
            border-color: $color--primary;
          }
        }
      }
    }

    :deep(.mobile__actions-link) {
      display: block;
      color: $white;
      padding: 19px 20px 15px;
      border: 2px solid $white;
      line-height: 1;
      transition: all .2s;
      border-radius: 35px;

      &:hover {
        background: $color--secondary;
      }
    }
  }

  &__sessions {
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;

    &-item {
      &:not(:last-child) {
        margin-right: $margin__base * 2;
      }
    }
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}

.fade-mobile-menu-enter-active, .fade-mobile-menu-leave-active {
  transition: opacity .5s;
}
.fade-mobile-menu-enter, .fade-mobile-menu-leave-to {
  opacity: 0;
}
</style>
