<template>
  <div
    :class="{'d-flex justify-content-between align-items-center': icon }"
    v-if="showStatistic || slots.text"
  >
    <fa-icon
      class="icon margin__r--18"
      :icon="icon"
      v-if="icon"
    />

    <p>
      <strong
        class="value color--sec d-block"
        v-if="showStatistic"
      >
        <slot name="value">
          <app-money
            v-if="statistic.includes('amount_collected')"
            :amount="value"
          />

          <sporting-activities-distance
            :distance="value"
            v-else-if="statistic.includes('distance')"
          />

          <sporting-activities-time
            :time="value"
            v-else-if="statistic.includes('time')"
          />

          <template v-else>
            {{ value }}
          </template>
        </slot>
      </strong>

      <span
        class="text d-block"
        v-if="slots.text"
      >
        <slot
          name="text"
          :value="formatedValue"
        />
      </span>
    </p>
  </div>
</template>

<script setup>
import { toRefs, defineAsyncComponent, useSlots, computed } from 'vue'
import { get as _get } from 'lodash'

const AppMoney = defineAsyncComponent(() => import('&/atoms/AppMoney'))
const SportingActivitiesDistance = defineAsyncComponent(() => import('&/molecules/SportingActivitiesDistance'))
const SportingActivitiesTime = defineAsyncComponent(() => import('&/molecules/SportingActivitiesTime'))

const props = defineProps({
  icon: Array,
  statistics: { type: Object, default: () => ({}) },
  statistic: { type: String, required: true },
  showStatistic: { type: Boolean, default: true }
})

const slots = useSlots()

const { icon, statistics, statistic, showStatistic } = toRefs(props)

const value = computed(() => _get(statistics.value, statistic.value, 0))

const formatedValue = computed(() => {
  if (statistic.value.includes('amount_collected')) return value.value / 1000
  if (statistic.value.includes('distance')) return value.value / 1000
  if (statistic.value.includes('time')) return (value.value / 3600) < 1 ? Math.round(value.value / 60) : Math.round(value.value / 3600)

  return value.value
})
</script>

<style lang="scss" scoped>
.icon {
  font-size: rem(18px);
}

.value {
  font-weight: bold;
  font-size: em(24px);
  line-height: 1;

  @include mq(md) {
    font-size: em(32px);
  }
}

.text {
  margin-top: 3px;
}
</style>