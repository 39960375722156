<template>
  <article
    class="card margin__tb--12"
    v-if="project && template"
  >
    <figure
      class="cover"
      :style="{ backgroundImage: `url('${coverImage}'), url('${getImagePath('placeholders/project-card-cover.jpg')}')` }"
      v-if="cover.isShowed('cover_on_card')"
    />

    <div class="infos padding--18">
      <span
        class="status status--need_approval"
        v-if="status === 'need_approval'"
      >
        {{ t('literal.need_approval') }}
      </span>

      <h3
        class="bold title"
        :class="{'no-margin': !title.isShowed('title_on_card') }"
      >
        <router-link
          :to="{ name: 'Project', params: { projectSlug: project.slug } }"
          class="title__link link__color--dg link--naked bold"
          :aria-label="project.name"
        >
          <template v-if="title.isShowed('title_on_card')">
            {{ truncate(project.name, 60) }}
          </template>
        </router-link>
      </h3>

      <p
        class="campaign"
        v-if="campaign.id"
      >
        <fa-icon
          class="margin__r--6"
          :icon="['fal', 'flag']"
        />

        {{ translate(project.campaign.name) }}
      </p>

      <ul class="members">
        <template v-if="members.isShowed('members_list_on_card')">
          <li
            class="member"
            :style="{ zIndex: project.users.length - index }"
            v-for="(user, index) in project.users.slice(0, 2)"
            :key="user.id"
          >
            <user-profile-img
              class="member__avatar"
              size="4"
              :avatar="user.avatar"
            />
          </li>

          <li
            class="member"
            :style="{ zIndex: 0 }"
            v-if="project.users.length > 2"
          >
            <span class="member__avatar member__more">+{{ project.users.length - 2 }}</span>
          </li>
        </template>
      </ul>

      <ul class="statistics">
        <li>
          <template v-if="contributors.isShowed('contributors_on_card')">
            <fa-icon
              class="margin__r--6"
              :icon="['fal', 'users']"
            />

            {{ project.contributors_count }}

            <template v-if="plural(contributors.getText('contributors'))">
              {{ ` ${plural(contributors.getText('contributors'), project.contributors_count).toLowerCase()}` }}
            </template>
          </template>
        </li>

        <li>
          <app-money
            :amount="project.amount_collected"
            class="bold color--prim"
            v-if="amountCollected.isShowed('amount_on_card')"
          />
        </li>
      </ul>

      <app-progress-bar
        v-if="amountCollected.isShowed('progressbar') && goalAmount"
        :value="project.amount_collected"
        :max-value="goalAmount"
      />

      <hr
        v-else-if="(endDate && duration.isShowed('duration_on_card')) || (goalAmount && amountCollected.isShowed('amount_on_card'))"
        class="separator separator__size--full color--dw"
      >

      <ul class="statistics">
        <li>
          <template v-if="endDate && duration.isShowed('duration_on_card')">
            <fa-icon
              class="margin__r--6"
              :icon="['fal', 'clock']"
            />

            <app-date
              filter="formatDistanceToNow"
              :timestamp="endDate"
              v-if="isFuture(endDate)"
            />

            <template v-else>
              {{ t('literal.ended') }}
            </template>
          </template>
        </li>

        <li>
          <template v-if="goalAmount && amountCollected.isShowed('amount_on_card')">
            /<app-money :amount="goalAmount" />
          </template>
        </li>
      </ul>
    </div>
  </article>
</template>

<script setup>
import { computed, toRefs, defineAsyncComponent } from 'vue'
import { useBlock } from '@/composables/modules/templates'
import { useI18n } from '@/vendors/i18n'
import { getImagePath, truncate } from '@/utils/utils'
import { get as _get } from 'lodash'
import { isFuture } from 'date-fns'

const UserProfileImg = defineAsyncComponent(() => import('&/atoms/UserProfileImg'))
const AppMoney = defineAsyncComponent(() => import('&/atoms/AppMoney'))
const AppDate = defineAsyncComponent(() => import('&/atoms/AppDate'))
const AppProgressBar = defineAsyncComponent(() => import('&/atoms/AppProgressBar'))

const props = defineProps({ project: { type: Object, required: true } })

const { project } = toRefs(props)

const { t, translate, plural } = useI18n()

const status = computed(() => _get(project.value, 'status', 'published'))
const template = computed(() => _get(project.value, 'template'))
const procedure = computed(() => _get(project.value, 'procedures', []).find(procedure => procedure.category === 'donation'))
const coverImage = computed(() => _get(project.value, 'image.url') || _get(project.value, 'campaign.image.url') || getImagePath('placeholders/project-card-cover.jpg'))
const campaign = computed(() => _get(project.value, 'campaign', {}))
const endDate = computed(() => procedure.value.end_date ? new Date(procedure.value.end_date) : null)
const goalAmount = computed(() => _get(procedure.value, 'goal_amount', 0))

const cover = computed(() => useBlock(template.value?.getBlock('project_cover')))
const title = computed(() => useBlock(template.value?.getBlock('project_title')))
const members = computed(() => useBlock(template.value?.getBlock('project_members_list')))
const contributors = computed(() => useBlock(template.value?.getBlock('project_contributors_statistic')))
const amountCollected = computed(() => useBlock(template.value?.getBlock('project_amount_collected_statistic')))
const duration = computed(() => useBlock(template.value?.getBlock('project_duration_statistic')))
</script>

<style lang="scss" scoped>
.card {
  min-height: calc(100% - 24px);
  transition: all .2s;

  &:hover {
    transform: scale(1.005);
    box-shadow: 0 4px 10px 0 rgba(82, 97, 115, 0.2);
  }
}

.cover {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  border-top-left-radius: $radius__cards;
  border-top-right-radius: $radius__cards;

  @include aspect-ratio(3, 2);
}

.title {
  &__link {
    min-height: 48px;
    display: block;
    overflow: hidden;

    &:empty {
      min-height: 0;
    }

    &:focus {
      outline: none;

      &::before {
        border-radius: $radius__cards;
        outline: 1px dashed $vlg;
        outline-offset: 5px;
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
    }

  }
}

.infos {
  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
}

.campaign {
  color: $lg;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.members {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 48px;
}

.member {
  position: relative;

  &:not(:first-child) {
    margin-left: -12px;
  }

  &__avatar {
    display: block;
    border: 3px solid $white;
    border-radius: 50%;
  }

  &__more {
    font-size: em(14px);
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $vlg;
    width: 48px;
    height: 48px;
  }
}

.statistics {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $lg;
  font-size: em(14px);
  min-height: calc(#{em(14px)} * 1.5);
}

.status {
  display: inline-block;
  padding: 3px 6px;
  border-radius: 3px;
  font-size: rem(14px);

  &--need_approval {
    color: set-contrast($color--warning, $white);
    background: $color--warning;
  }
}
</style>