import { computed, toValue } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useProcedure, useProcedureStep } from '@/composables/modules/procedures'
import { useProcedureStore } from '@/stores/ProcedureStore'
import { useProcedureExecutionStore } from '@/stores/ProcedureExecutionStore'
import { useCartStore } from '@/stores/CartStore'
import { useTracking } from '@/vendors/tracking'
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'

export function useProcedureNavigation ({ procedure, procedure_execution }) {
  const { translate } = useI18n()
  const { push } = useRouter()
  const route = useRoute()
  const tracking = useTracking()

  const { id: procedureId, redirectionLink, backRedirectionLink, targetType, targetId, slug } = useProcedure({ procedure })
  const { $resetStoreItem: resetProcedureExecution } = useProcedureExecutionStore()
  const { $resetStoreItem: resetCart } = useCartStore()
  const { isFirstStep, isLastStep, step_index } = useProcedureStep({ procedure })

  const { getNavigation, $resetStoreItem: resetProcedure } = useProcedureStore()
  const { loader } = storeToRefs(useProcedureStore())
  const { fullPath: redirectionFullPath, params: redirectionParams } = getNavigation({ id: slug })

  const showBackButton = computed(() => {
    if (!isFirstStep.value && !isLastStep.value) return true
    if (isFirstStep.value && targetType.value === 'Project') return true
    if (isFirstStep.value && redirectionFullPath) return true

    return false
  })

  const getProjectRedirectionId = computed(() => {
    const { project } = _get(toValue(procedure_execution), 'actions_data', {})

    if (project?.slug) return project.slug
    if (redirectionParams?.projectSlug) return redirectionParams.projectSlug
    if (targetType.value === 'Project') return targetId.value

    return false
  })

  const goToStep = ({ index, query = {} }) => push({ params: { stepSlug: `step-${index}` }, query })

  const goToPreviousStep = ({ query = route.query } = {}) => goToStep({ index: step_index - 1, query })

  const goToNextStep = ({ query = route.query } = {}) => goToStep({ index: step_index + 1, query }).then(() => tracking.procedure.nextStep())

  const goToProject = () => push({ name: 'Project', params: { projectSlug: getProjectRedirectionId.value } })

  const goToRedirectionFullPath = () => push({ path: redirectionFullPath })

  const goToHome = () => push({ name: 'Home' })

  const backRedirection = () => {
    if (!isFirstStep.value && !isLastStep.value) return goToPreviousStep()
    if (isFirstStep.value && translate(backRedirectionLink.value)) window.location.href = translate(backRedirectionLink.value)
    else if (isFirstStep.value && redirectionFullPath) goToRedirectionFullPath()
    else if (isFirstStep.value && targetType.value === 'Project') goToProject()
  }

  const nextRedirection = () => {
    if (translate(redirectionLink.value)) {
      loader.value = true
      window.location.href = translate(redirectionLink.value)

      resetObjects()
    } else if (getProjectRedirectionId.value) {
      goToProject().then(() => resetObjects())
    } else if (redirectionFullPath) {
      goToRedirectionFullPath().then(() => resetObjects())
    } else {
      goToHome().then(() => resetObjects())
    }
  }

  const resetObjects = () => {
    resetProcedure({ key: slug, ressource: 'navigation' })
    resetProcedure({ key: slug, ressource: 'authorization' })
    resetCart({ key: procedureId, ressource: 'cart' })
    resetProcedureExecution({ key: procedureId, ressource: 'procedure_execution' })
  }

  return {
    showBackButton,
    goToStep,
    goToNextStep,
    nextRedirection,
    backRedirection
  }
}