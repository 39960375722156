import ActionsData from '@/classes/procedures/ActionsData'
import Customer from '@/classes/customers/Customer'
import Informations from '@/classes/procedures/Informations'
import { get as _get, pick as _pick } from 'lodash'

export default class ProcedureExecution {
  constructor ({ id = null, actions_data = {}, objects = {}, informations = {}, customer = {}, status = null, token = null } = {}) {
    this.id = id
    this.actions_data = new ActionsData({ ...actions_data, ...objects })
    this.informations = new Informations(informations)
    this.customer = new Customer(customer)
    this.status = status
    this.token = token
  }

  get payment_method () {
    return _get(this.actions_data, 'transaction.payment_method')
  }

  get transaction_id () {
    return _get(this.actions_data, 'transaction.id')
  }

  get transaction_status () {
    return _get(this.actions_data, 'transaction.status')
  }

  get transaction_redirect_url () {
    return _get(this.actions_data, 'transaction.provider_ids.redirect_url')
  }

  get transaction_intent_id () {
    return _get(this.actions_data, 'transaction.provider_ids.intent_id')
  }

  get has_transaction () {
    return _get(this.actions_data, 'transaction.amount')
  }

  get has_order () {
    return _get(this.actions_data, 'order.order_lines', []).length > 0
  }

  get has_project () {
    return _get(this.actions_data, 'project.name')
  }

  get has_accept_invite () {
    return _get(this.actions_data, 'accept_invite.invite_token')
  }

  get has_create_invite () {
    return _get(this.actions_data, 'create_invite.emails', []).length > 0
  }

  get to_database () {
    const actions = ['fee', 'recaptcha']

    if (this.has_transaction) actions.push('transaction')
    if (this.has_order) actions.push('order')
    if (this.has_project) actions.push('project')
    if (this.has_accept_invite) actions.push('accept_invite')
    if (this.has_create_invite) actions.push('create_invite')

    return { ...this, actions_data: _pick(this.actions_data.to_database, actions) }
  }

  get to_tamaro () {
    return {
      stored_koa_procedure_execution_id: this.id,
      ...this.actions_data.to_tamaro,
      ...this.customer.to_tamaro,
      ...this.informations.to_tamaro
    }
  }
}
