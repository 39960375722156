import { ref, onMounted, onUnmounted } from 'vue'

export function useWindowScroll () {
  const left = ref(window.scrollX)
  const top = ref(window.scrollY)

  const handleScroll = () => {
    left.value = window.scrollX
    top.value = window.scrollY
  }

  onMounted( () => window.addEventListener('scroll', handleScroll))
  onUnmounted( () => window. removeEventListener('scroll', handleScroll))

  return { left, top }
}