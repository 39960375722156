<template>
  <div class="map__container">
    <svg
      width="600"
      height="506"
      viewBox="0 0 600 506"
      fill="none"
      class="map"
    >
      <rect
        width="600"
        height="505.91"
        fill="url(#pattern0)"
      />

      <path
        class="marker"
        d="M135 137.765C135 143.669 130.097 148.494 124 148.494C117.903 148.494 113 143.669 113 137.765C113 131.861 117.903 127.036 124 127.036C130.097 127.036 135 131.861 135 137.765Z"
        fill="#FFE1CC"
        stroke="#5D5D5D"
        stroke-width="2"
        @click="toggleModal('room_0')"
      />

      <path
        class="marker"
        d="M215 125.765C215 131.669 210.097 136.494 204 136.494C197.903 136.494 193 131.669 193 125.765C193 119.861 197.903 115.036 204 115.036C210.097 115.036 215 119.861 215 125.765Z"
        fill="#FFE1CC"
        stroke="#5D5D5D"
        stroke-width="2"
        @click="toggleModal('room_1')"
      />

      <path
        class="marker"
        d="M396 78.7291C396 84.6332 391.097 89.4582 385 89.4582C378.903 89.4582 374 84.6332 374 78.7291C374 72.825 378.903 68 385 68C391.097 68 396 72.825 396 78.7291Z"
        fill="#FFE1CC"
        stroke="#5D5D5D"
        stroke-width="2"
        @click="toggleModal('room_2')"
      />

      <path
        class="marker"
        d="M215 183.765C215 189.669 210.097 194.494 204 194.494C197.903 194.494 193 189.669 193 183.765C193 177.861 197.903 173.036 204 173.036C210.097 173.036 215 177.861 215 183.765Z"
        fill="#FFE1CC"
        stroke="#5D5D5D"
        stroke-width="2"
        @click="toggleModal('room_3')"
      />

      <path
        class="marker"
        d="M169 227.765C169 233.669 164.097 238.494 158 238.494C151.903 238.494 147 233.669 147 227.765C147 221.861 151.903 217.036 158 217.036C164.097 217.036 169 221.861 169 227.765Z"
        fill="#FFE1CC"
        stroke="#5D5D5D"
        stroke-width="2"
        @click="toggleModal('room_4')"
      />

      <path
        class="marker"
        d="M372 216.765C372 222.669 367.097 227.494 361 227.494C354.903 227.494 350 222.669 350 216.765C350 210.861 354.903 206.036 361 206.036C367.097 206.036 372 210.861 372 216.765Z"
        fill="#FFE1CC"
        stroke="#5D5D5D"
        stroke-width="2"
        @click="toggleModal('room_5')"
      />

      <path
        class="marker"
        d="M91 434.765C91 440.669 86.0968 445.494 80 445.494C73.9032 445.494 69 440.669 69 434.765C69 428.861 73.9032 424.036 80 424.036C86.0968 424.036 91 428.861 91 434.765Z"
        fill="#FFE1CC"
        stroke="#5D5D5D"
        stroke-width="2"
        @click="toggleModal('room_6')"
      />

      <path
        class="marker"
        d="M203 374.765C203 380.669 198.097 385.494 192 385.494C185.903 385.494 181 380.669 181 374.765C181 368.861 185.903 364.036 192 364.036C198.097 364.036 203 368.861 203 374.765Z"
        fill="#FFE1CC"
        stroke="#5D5D5D"
        stroke-width="2"
        @click="toggleModal('room_7')"
      />

      <path
        class="marker"
        d="M512 252.765C512 258.669 507.097 263.494 501 263.494C494.903 263.494 490 258.669 490 252.765C490 246.861 494.903 242.036 501 242.036C507.097 242.036 512 246.861 512 252.765Z"
        fill="#FFE1CC"
        stroke="#5D5D5D"
        stroke-width="2"
        @click="toggleModal('room_8')"
      />

      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlink:href="#image0_0_366"
            transform="scale(0.000788022 0.000934579)"
          />
        </pattern>

        <image
          id="image0_0_366"
          width="1269"
          height="1070"
          xlink:href="https://koalect-images.s3.eu-west-3.amazonaws.com/clients/vijvens/map.png"
        />
      </defs>
    </svg>

    <modal
      v-show="show_modal"
      @close="show_modal = false"
    >
      <template #body>
        <article class="card">
          <section
            class="room"
            v-if="slides.length > 0"
          >
            <figure
              class="room__slider"
              ref="sliderRef"
            >
              <div
                class="room__slides"
                :style="{ width: slidesWidth, left: leftPositionSlides, transform: transformSlides }"
              >
                <div
                  class="room__slide"
                  v-for="({image}, index) in clonedSlides"
                  :style="{ backgroundImage: `url(${ translate(image) })`, width: `${slider.width}px` }"
                  :key="`modal_slide_${index}`"
                />
              </div>
            </figure>

            <nav
              class="room__nav"
              v-if="slides.length > 1"
            >
              <button
                class="room__nav--prev"
                @click="goToPrev()"
                aria-label="Go to prev slide"
              >
                <fa-icon
                  :icon="['fas', 'chevron-left']"
                  aria-hidden="true"
                />
              </button>

              <button
                class="room__nav--next"
                @click="goToNext()"
                aria-label="Go to next slide"
              >
                <fa-icon
                  :icon="['fas', 'chevron-right']"
                  aria-hidden="true"
                />
              </button>
            </nav>
          </section>

          <div class="card__infos">
            <h3
              class="card__title"
              v-if="translate(selected_room.title)"
            >
              {{ translate(selected_room.title) }}
            </h3>

            <div
              class="editor editor__content card__content"
              v-html="translate(selected_room.content)"
              v-if="translate(selected_room.content)"
            />
          </div>
        </article>
      </template>
    </modal>
  </div>
</template>

<script setup>
import { ref, computed, reactive, nextTick, onUnmounted, onMounted } from 'vue'
import { useI18n } from '@/vendors/i18n'

import Modal from '&/organisms/AppModal'

const props = defineProps({
  building: { type: Object, required: true }
})

const slider = reactive({
  index: 0,
  width: 428,
  is_animating: false
})

const { translate } = useI18n()
const sliderRef = ref(null)
const show_modal = ref(false)
const selected_room = ref({})
const slides = computed(() => selected_room.value.gallery || [])
const clonedSlides = computed(() => {
  const items = [...slides.value]

  items.unshift(slides.value[slides.value.length - 1])
  items.push(slides.value[0])

  return items
})

const slidesWidth = computed(() => `${ clonedSlides.value.length * slider.width }px`)
const leftPositionSlides = computed(() => `${ -slider.width * ((clonedSlides.value.length - slides.value.length) / 2) }px`)
const transformSlides = computed(() => `translateX(${ -slider.index * slider.width }px`)

const toggleModal = id => {
  show_modal.value = true
  selected_room.value = props.building[id]
  nextTick(() => slider.width = sliderRef.value.clientWidth)
}

const goToPrev = () => {
  if (slider.is_animating) return

  slider.is_animating = true

  if (slider.index <= 0) {
    slider.index = slides.value.length

    setTimeout(() => {
      slider.is_animating = false
      goToPrev()
    })
  } else {
    slider.index--

    setTimeout(() => slider.is_animating = false, 300)
  }
}

const goToNext = () => {
  if (slider.is_animating) return

  slider.is_animating = true

  if (slider.index >= slides.value.length - 1) {
    slider.index = -1

    setTimeout(() => {
      slider.is_animating = false
      goToNext()
    })
  } else {
    slider.index++

    setTimeout(() => slider.is_animating = false, 300)
  }
}

const handleResize = () => {
  if (show_modal.value && sliderRef.value && slides.value.length) slider.width = sliderRef.value.clientWidth
}

onMounted(() => window.addEventListener('resize', handleResize))
onUnmounted(() => window.removeEventListener('resize', handleResize))
</script>

<style lang="scss" scoped>
:deep(.modal) {
  .modal__container {
    position: relative;
    overflow: visible;

    @media (min-width: 620px) {
      width: 620px;
    }
  }

  .modal__btn--close {
    width: 30px;
    height: 30px;
    background-color: #39808C;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover svg {
      transform: rotate(90deg);
    }

    @media (min-width: 735px) {
      top: -50px;
      right: -50px;
      width: 50px;
      height: 50px;
    }

    svg {
      color: $white;
      font-size: 18px;
      transition: all .3s;

      @media (min-width: 735px) {
        font-size: 30px;
      }
    }
  }
}

.map {
  min-width: 600px;
  position: relative;

  &__container {
    overflow-x: auto;

    @media (min-width: 600px) {
      overflow-x: hidden;
    }
  }
}

.card {
  padding: 24px;
  border-radius: 7px;
  background-color: #FFF3D4;

  @include mq(sm) {
    padding: 48px 96px;
  }

  &__cover {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    @include aspect-ratio(3, 2);
  }

  &__slider {
    & :deep(.glide__arrows) {
      top: 100%;
      transform: translateY(-50%);
      width: 100px;
      margin: auto;

      @include mq(sm) {
        left: auto;
        right: 24px;
      }
    }

    & :deep(.glide__arrow) {
      background-color: #39808C;
      color: $white;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 0;
    }
  }

  &__title {
    margin-top: 32px;
    font-size: rem(26px);
    font-weight: bold;

    @include mq(sm) {
      margin-top: 42px;
    }
  }

  &__content {
    margin-top: 12px;
  }
}

.marker {
  cursor: pointer;

  @supports ( transform-box: fill-box ) {
    transform-box: fill-box;
    transform-origin: 50% 50%;
    transition: all .2s;

    @include mq(sm) {
      &:hover {
        transform: translate(0, 0) scale(1.3);
      }
    }
  }
}

.room {
  position: relative;

  &__slider {
    background: $color--primary;
    position: relative;
    overflow: hidden;

    @include aspect-ratio(428, 286);
  }

  &__slides {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: .3s ease-in-out;
  }

  &__slide {
    position: relative;
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: calc(50% - 50px);
    top: 100%;
    transform: translateY(-50%);
    width: 100px;
    font-size: rem(20px);
    color: $white;

    button {
      background-color: #39808C;
      color: $white;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    svg {
      transition: .2s all;
      opacity: 0.7;
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }

    &--prev {
      &:hover {
        svg {
          transform: translateX(-3px);
        }
      }
    }

    &--next {
      &:hover {
        svg {
          transform: translateX(3px);
        }
      }
    }
  }
}
</style>