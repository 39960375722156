import { defineStore } from 'pinia'
import { $cookies } from '@/utils/utils'
import { useLanguageStore } from '@/stores/LanguageStore'
import { get as _get, pick as _pick } from 'lodash'
import User from '@/classes/users/User'
import Tracking from '@/vendors/tracking'
import store from '@/store'

export const useAuthenticationStore = defineStore('AuthenticationStore', {
  state: () => ({
    auth_user: {},
    auth_token: null,
    loader: false,
    error: {}
  }),
  getters: {
    getAuthUser: state => () => {
      return state.$getItem({
        resource: 'auth_user',
        fetch: () => state.fetchAuthUser()
      })
    },
    authUser: state => _get(state, 'auth_user.item'),
    isAuthUser: state => !!state.authUser,
    isAuthenticated: state => !!state.auth_token
  },
  actions: {
    initAuthentication () {
      return new Promise((resolve, reject) => {
        if (this.isAuthUser) {
          resolve(this.authUser)
        } else if ($cookies.get('authToken')) {
          return this.fetchAuthUser()
            .then(user => resolve(user))
            .catch(error => reject(error))
        } else {
          resolve(null)
        }
      })
    },
    setAuthToken (auth_token) {
      $cookies.set({ name: 'authToken', value: auth_token })
      this.auth_token = auth_token
    },
    fetchAuthUser () {
      return this.$fetchItem({
        endpoint: '/auth/user',
        resource: 'auth_user',
        model: User
      }).then(user => {
        const { setLocale } = useLanguageStore()
        setLocale(user.language)
        store.commit('auth/setUser', user)
        Tracking.session.init({ user })
        Tracking.user.set({ user })
        return user
      })
    },
    postAuthUser ({ params, token }) {
      return this.$postItem({
        endpoint: '/auth/user',
        resource: 'auth_user',
        model: User,
        params: {
          user: params,
          token
        }
      }).then(user => {
        const { setLocale } = useLanguageStore()
        setLocale(user.language)
        store.commit('auth/setUser', user)
        Tracking.session.init({ user })
        Tracking.user.set({ user })
        Tracking.session.register({ user, method: 'email' })
        return user
      })
    },
    patchAuthUser ({ params }) {
      return this.$patchItem({
        endpoint: '/auth/user',
        resource: 'auth_user',
        model: User,
        params: {
          user: _pick(new User(params), Object.keys(params))
        }
      })
    },
    postAuthUserPassword ({ params }) {
      return this.$postItem({
        endpoint: '/auth/user/forgot_password',
        params: {
          user: params
        }
      })
    },
    patchAuthUserPassword ({ params, token }) {
      return this.$patchItem({
        endpoint: '/auth/user/password',
        params: {
          user: params,
          token
        }
      })
    },
    patchAuthUserImage ({ params }) {
      return this.$patchItem({
        endpoint: '/auth/user',
        resource: 'auth_user',
        params,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
    },
    login ({ params }) {
      return this.$postItem({
        endpoint: '/auth/login',
        params: {
          user: params
        }
      }).then(({ auth_token }) => {
        this.setAuthToken(auth_token)

        Tracking.session.login({ method: 'email' })
        return null
      })
    },
    logout () {
      $cookies.delete({ name: 'authToken' })
      this.$resetStore('auth_user')
      store.commit('auth/setUser', null)
      Tracking.session.logout()
    }
  }
})