import { findSelectedNodeOfType } from '@/vendors/tiptap/helpers'

export default ({ attributes = {}, props, name }) => {
  const { tr, dispatch, state } = props

  const { selection } = tr
  const { doc } = state
  const { node, pos } = findSelectedNodeOfType({ doc, selection, name })

  if (!node) return

  let transaction = tr
  transaction = tr.delete(pos, pos + node.nodeSize)

  if (attributes.text)
    transaction = tr.insertText(attributes.text)

  if (transaction.docChanged) dispatch(transaction)
}