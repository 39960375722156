<template>
  <div>
    <div class="page__head">
      <h2 class="title__h2 bold">
        {{ t('literal.products') }}
      </h2>

      <router-link
        class="btn btn__size--small btn__color--prim"
        :to="{ name: 'PrivateNewProduct' }"
        v-if="products.length && allowProductCreation"
      >
        {{ t('literal.create_new_product') }}
      </router-link>
    </div>

    <div
      class="page__loading"
      v-if="!products.length && loader"
    >
      <app-spinner />
    </div>

    <template v-else-if="!products.length && !loader">
      <section class="card page__empty">
        <h3 class="bold title__empty">
          {{ t('literal.you_dont_have_any_product_yet') }}
        </h3>

        <p class="text__empty">
          {{ t('literal.before_you_start_selling_products_first_you_have_to_create_them') }}
        </p>

        <router-link
          class="btn btn__empty btn__size--small btn__color--prim"
          :to="{ name: 'PrivateNewProduct' }"
          v-if="allowProductCreation"
        >
          {{ t('literal.create_new_product') }}
        </router-link>
      </section>

      <p class="products__help">
        <fa-icon
          class="margin__r--6"
          :icon="['fal', 'circle-info']"
        />

        {{ t('literal.learn_more_about_products') }}
      </p>
    </template>

    <table
      class="table"
      width="100%"
      role="table"
      :aria-label="t('literal.overview_of_project_products')"
      v-else
    >
      <caption class="table__caption">
        {{ t('literal.overview_of_project_products') }}
      </caption>

      <thead class="table__thead">
        <tr
          class="table__tr table__tr--naked"
          role="rowgroup"
        >
          <th
            class="table__th table__visible"
            role="columnheader"
          />

          <th
            class="table__th table__visible"
            role="columnheader"
          >
            {{ t('literal.title') }}
          </th>

          <th
            class="table__th"
            role="columnheader"
          >
            {{ t('literal.price') }}
          </th>

          <th
            class="table__th"
            role="columnheader"
          >
            {{ t('literal.quantity_ordered') }}
          </th>

          <th
            class="table__th"
            role="columnheader"
          >
            {{ t('literal.stock') }}
          </th>

          <th
            class="table__th"
            role="columnheader"
          >
            {{ t('literal.created_by') }}
          </th>

          <th
            class="table__th"
            role="columnheader"
          >
            {{ t('literal.display_on_page') }}
          </th>

          <th
            class="table__th table__visible"
            role="columnheader"
          />
        </tr>
      </thead>

      <container
        class="card table__tbody"
        orientation="vertical"
        lock-axis="y"
        drag-handle-selector=".table__grip"
        tag="tbody"
        @drop="onDrop($event)"
      >
        <draggable
          class="table__tr table__hover"
          tag="tr"
          v-for="(product, index) in products"
          :key="`table_tr_${index}`"
          @click.prevent="goProduct(product)"
        >
          <private-all-products-item :product="product" />
        </draggable>
      </container>
    </table>
  </div>
</template>

<script setup>
import { defineAsyncComponent, computed, watch } from 'vue'
import { mapGetters } from '@/store/map-state'
import { useProjectStore } from '@/stores/ProjectStore'
import { useProductStore } from '@/stores/ProductStore'
import { useRoles } from '@/composables/app/useRoles'
import { useHead } from '@unhead/vue'
import { useRouter } from 'vue-router'
import { useI18n } from '@/vendors/i18n'
import { arrayMove } from '@/utils/utils'
import { get as _get } from 'lodash'

import { Container, Draggable } from 'vue3-smooth-dnd'
import PrivateAllProductsItem from '&/modules/project/PrivateAllProductsItem'
const AppSpinner = defineAsyncComponent(() => import('&/atoms/AppSpinner'))

const props = defineProps({ projectSlug: { required: true,type: String } })

const { t } = useI18n()
const { push } = useRouter()
const { getProject, patchProject } = useProjectStore()
const { getProducts } = useProductStore()
const { isSuperAdmin, isKoalect } = useRoles()
const { user } = mapGetters('auth')

const { project } = getProject({ id: props.projectSlug })
const { products: allProducts, loader } = getProducts({ query: computed(() => ({ project_id: _get(project.value, 'id') })) })

useHead({ title: computed(() => project.value ? `${t('literal.products')} / ${_get(project.value, 'name', '')}` : null) })

const productsDisplayOrder = computed(() => _get(project.value, 'settings.products_display_order', []))
const products = computed(() => allProducts.value.map(product => {
  const projectProduct = productsDisplayOrder.value.find(projectProduct => projectProduct.product_id === product.id)

  return Object.assign(product, { display_on_page: _get(projectProduct, 'display_on_page', product.display_on_page) } )
}))

const allowProductCreation = computed(() => _get(project.value, 'campaign.project_display_settings.product_creation', false))
const isAuthorized = computed(() => project.value ? (isSuperAdmin.value || isKoalect.value || project.value.isAuthorized(user.value)) : null)

const goProduct = product => push({ name: 'PrivateEditProduct', params: { productId: product.id } })

const onDrop = ({ addedIndex, removedIndex }) => {
  arrayMove(allProducts.value, removedIndex, addedIndex)
  const products_display_order = products.value.map(product => ({ product_id: product.id, display_on_page: product.display_on_page }))
  patchProject({ id: props.projectSlug, params: { settings: { products_display_order } } })

  document.body.className = document.body.className.split(' ').filter(c => c.indexOf('dnd') < 0).join(' ')
}

watch(isAuthorized, value => {
  if (value === false) push({ name: 'Project' })
}, { immediate: true })
</script>

<style lang="scss" scoped>
.page {
  &__head {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__empty {
    max-width: 628px;
    text-align: center;
    margin: 48px auto 0;
    padding: 24px;
  }

  &__loading {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.products {
  &__help {
    text-align: center;
    margin-top: 24px;
  }
}

.table {
  margin-top: 24px;

  &__tr {
    display: grid !important;
    grid-template-columns: 40px 1fr 50px;

    @include mq(sm) {
      grid-template-columns: 40px minmax(120px, 2fr) repeat(5, 1fr) 50px;
    }
  }

  &__td:not(.table__visible), &__th:not(.table__visible) {
    display: none;

    @include mq(sm) {
      display: block;
    }
  }

  &__grip {
    position: relative;
    font-size: 16px;
    cursor: grab;

    &:after {
      position: absolute;
      right: 10px;
      width: 1px;
      height: 10px;
      background-color: rgba(#2E293D, .9);
    }
  }
}

.title {
  &__empty {
    font-size: rem(20px);
    margin-bottom: 24px;
  }
}

.text {
  &__empty {
    color: rgba(#2E293D, .6);
  }
}

.btn {
  &__empty {
    display: inline-block;
    margin-top: 24px;
  }
}
</style>