export default class Address {
  constructor ({ line1 = null, line2 = null, number = null, box = null, postal_code = null, city = null, country = null, state = null, latitude = null, longitude = null } = {}) {
    this.line1 = line1
    this.line2 = line2
    this.number = number
    this.box = box
    this.postal_code = postal_code
    this.city = city
    this.country = country
    this.state = state
    this.latitude = latitude
    this.longitude = longitude
  }

  get to_html () {
    return [[this.line1, this.line2, this.number, this.box].filter(v => v).join(' '), [this.postal_code, this.city, this.country ? `(${this.country})` : ''].filter(v => v).join(' ')].filter(v => v).join('<br/>')
  }

  get to_tamaro () {
    return {
      stored_customer_street: this.line1,
      stored_customer_street2: this.line2,
      stored_customer_street_number: this.number,
      stored_customer_zip_code: this.postal_code,
      stored_customer_city: this.city,
      stored_customer_state: this.state,
      stored_customer_country: this.country
    }
  }
}
