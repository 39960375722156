<template>
  <input-select
    v-model="country"
    :options="options"
    :id="id"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    :v="v"
    :disabled="disabled"
    :searchable="true"
  />
</template>

<script setup>
import { computed, toRefs } from 'vue'
import { useI18n } from '@/vendors/i18n'
import countries from 'i18n-iso-countries'

import InputSelect from '&/atoms/InputSelect'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: { default: '' },
  disabled: { type: Boolean, default: false },
  id: { type: String },
  placeholder: { type: String },
  autocomplete: { type: String, default: 'shipping country' },
  allowed: { type: Array, default: () => [] },
  v: { type: Object }
})

const { allowed } = toRefs(props)

const { locale } = useI18n()

const country = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})

const options = computed(() => {
  const iso = locale.split('-')[0]
  // eslint-disable-next-line
  countries.registerLocale(require('i18n-iso-countries/langs/' + iso + '.json'))
  return Object.keys(countries.getNames(iso)).filter(key => !allowed.value.length || allowed.value.includes(key)).map(key => ({ label: countries.getNames(iso)[key], value: key }))
})
</script>
