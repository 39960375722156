const parseToQueryParams = object => {
  let queries = {}

  Object.keys(object).forEach(index => {
    let values = []

    Object.keys(object[index]).forEach(query => {
      let value = object[index][query]
      if (!value) return

      if (Array.isArray(value) && !value.length) {
        values.push(`${query}:[]`)
        return
      }

      if (query === 'search') value = encodeURIComponent(value)

      value && Array.isArray(value) ? values.push(`${query}:${value.join()}`) : values.push(`${query}:${value}`)
    })

    queries[index] = values
  })

  return queries
}

const serializeFromQueryParams = object => {
  let queries = {}

  Object.keys(object).forEach(key => {
    const values = typeof object[key] === 'object' ? object[key] : [object[key]]
    queries[key] = {}

    values.forEach(query => {
      const values = query.split(':')

      if (values.length > 1) {
        if (values[1].includes(',')) {
          queries[key][values[0]] = values[1].split(',')
          return
        }

        if (values[1].includes('[]')) {
          queries[key][values[0]] = []
          return
        }

        queries[key][values[0]] = decodeURIComponent(values[1])
      }
    })
  })

  return queries
}

const getQueryFromUrl = (url = '') => {
  return url
    .match(/[?&]([^=#]+)=([^&#]*)/g)
    ?.reduce((params, match) => {
      const [key, value] = match.substring(1).split('=')

      return { ...params, [key]: decodeURIComponent(value) }
    }, {}) || {}
}

export {
  parseToQueryParams,
  serializeFromQueryParams,
  getQueryFromUrl
}