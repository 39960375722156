import Style from '@/classes/procedures/Style'
import Steps from '@/classes/procedures/Steps'

export default class FormConfiguration {
  constructor ({ name = null, redirection_link = {}, back_redirection_link = {}, steps, style = {}, payment_methods = {} } = {}) {
    this.name = name
    this.redirection_link = redirection_link
    this.back_redirection_link = back_redirection_link
    this.steps = new Steps(steps)
    this.style = new Style(style)
    this.payment_methods = payment_methods
  }
}
