import { inject } from 'vue'
import { useClientStore } from '@/stores/ClientStore'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import * as SentryVue from '@sentry/vue'

class Sentry {
  constructor () {
    this.enable = false
  }

  init ({ app, enable = false, dsn, router, environment = 'production' } = {}) {
    this.enable = enable

    if (!this.enable) return

    const { getClient } = useClientStore()
    const { client } = getClient()

    SentryVue.init({
      app,
      dsn,
      integrations: [
        SentryVue.browserTracingIntegration({ router })
      ],
      ignoreErrors: [
        'Non-Error exception captured',
        'Non-Error promise rejection captured'
      ],
      initialScope: {
        client: { id: client.value.id, name: client.value.name, allowed_features: client.value.allowed_features, active_languages: client.value.active_languages, active_currencies: client.value.active_currencies }
      },
      tracesSampleRate: 0.5,
      environment
    })
  }

  message (message, data = {}) {
    if (!this.enable) return

    const { authUser } = useAuthenticationStore()

    let message_id

    SentryVue.withScope(scope => {
      Object.keys(data).forEach(key => scope.setExtra(key, JSON.stringify(data[key])))

      if (authUser) scope.setUser({ id: authUser.id })

      message_id = SentryVue.captureMessage(message, 'info')
    })

    return message_id
  }

  error (exception, data = {}) {
    if (!this.enable) return

    const { authUser } = useAuthenticationStore()

    let error_id

    SentryVue.withScope(scope => {
      Object.keys(data).forEach(key => scope.setExtra(key, JSON.stringify(data[key])))

      if (authUser) scope.setUser({ id: authUser.id })

      error_id = SentryVue.captureException(exception)
    })

    return error_id
  }
}

const sentrySymbol = Symbol()
const sentry = new Sentry()

export const createSentry = {
  install: (app, config = {}) => {
    sentry.init({ app, ...config })

    app.provide(sentrySymbol, sentry)
  }
}

export function useSentry () {
  return inject(sentrySymbol)
}

export default sentry