import { computed } from 'vue'
import { useAllowedFeatures } from '@/composables/app/useAllowedFeatures'
import { get as _get } from 'lodash'

export function useQrcode ({ project }) {
  const { allowedPayconiq } = useAllowedFeatures()

  const isPayconiqActivated = computed(() => allowedPayconiq && _get(project.value, 'campaign.project_display_settings.payconiq_qr_code', false))
  const twikeyProcedure = computed(() => _get(project.value, 'procedures', []).find(procedure => procedure.category === 'qr-code'))
  const isTwickeyActivated = computed(() => _get(project.value, 'campaign.project_display_settings.twikey_qrcode', true) && !!twikeyProcedure.value)
  const isQrcodeAvailable = computed(() => isTwickeyActivated.value || isPayconiqActivated.value)

  return {
    isPayconiqActivated,
    isTwickeyActivated,
    isQrcodeAvailable,
    twikeyProcedure
  }
}