import axios from '@/vendors/axios/axios'
import queryString from 'query-string'

const endpoint = '/api/v1/benefitings'
const resource = 'benefitings'

export default {
  namespaced: true,
  state: {
    items: {},
    tags: []
  },
  getters: {
    getBenefitings: state => state.items,
    getTags: state => state.tags
  },
  actions: {
    fetchBenefitings ({ commit }, { query, removeStoredItems = false } = {}) {
      const q = query ? `?${queryString.stringify(query, { arrayFormat: 'bracket' })}` : ''

      return new Promise((resolve, reject) => {
        axios.get(endpoint + q)
          .then(response => {
            const { benefitings, tags } = response.data.data

            if (removeStoredItems) {
              commit('deleteItems', { resource: `${resource}.items` }, { root: true })
            }

            benefitings.forEach(benefiting => commit('setItem', { resource: `${resource}.items`, id: benefiting.id, item: benefiting }, { root: true }))
            commit('setItem', { resource, id: 'tags', item: tags }, { root: true })

            resolve({ benefitings, tags })
          })
          .catch(error => reject(error))
      })
    }
  }
}
