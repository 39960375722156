import { useProcedureStore } from '@/stores/ProcedureStore'
import { useSecureStore } from '@/stores/SecureStore'

const Procedure = () => import(/* webpackChunkName: 'Procedure' */'@/views/procedure/ViewProcedure')

const routes = [
  {
    path: 'p/:procedureSlug',
    name: 'ClientProcedure',
    redirect: to => ({
      name: 'ClientProcedureStep',
      query: to.query,
      params: { ...to.params, stepSlug: 'step-1' }
    })
  },
  {
    path: 'p/:procedureSlug/:stepSlug',
    name: 'ClientProcedureStep',
    component: Procedure,
    props: true,
    beforeEnter: (to, from, next) => {
      const { postNavigation } = useProcedureStore()
      const { fetchProcedureSecureToken } = useSecureStore()

      const { procedureSlug, stepSlug } = to.params

      if (!/^step-\d+$/.test(stepSlug)) return next({ ...to, params: { ...to.params, stepSlug: 'step-1' } })

      if (from.name) postNavigation({ procedure_id: procedureSlug, from })

      fetchProcedureSecureToken({ id: procedureSlug }).finally(() => next())
    }
  }
]

export default routes
