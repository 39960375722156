<template>
  <div>
    <form-notification
      :keys="error"
      class="margin__b--12"
    />

    <div class="preview__container">
      <figure
        class="preview__figure"
        :class="{ 'bg__color--vlg': !modelValue && !preview, [`preview__figure--${ratio}`]: ratio }"
      >
        <app-spinner
          class="preview__spinner"
          v-if="loader"
        />

        <img
          class="preview__image"
          :class="{ 'preview__image--is-loading': loader }"
          :src="image"
          @error="handleImageError"
        >

        <input-file
          :id="inputId"
          :accept="accept"
          :disabled="loader || is_processing"
          hidden
          @change="handleImageChange"
        />

        <button
          v-if="deletable && modelValue"
          class="btn preview__delete"
          :disabled="loader || is_processing"
          @click.prevent.stop="handleClickDelete()"
        >
          <fa-icon
            fixed-width
            :icon="['fal', 'trash']"
          />
        </button>
      </figure>

      <div class="position-relative d-flex flex-column justify-content-center align-items-start padding__tb--12">
        <label
          :for="inputId"
          class="btn btn__color--prim btn__size--small d-inline-block"
          :class="{ 'btn--disabled': loader || is_processing }"
        >
          <fa-icon
            class="margin__r--6"
            :icon="['far', 'file-image']"
          />

          <slot
            name="label"
            v-bind="{ modelValue }"
          >
            {{ modelValue ? t('literal.edit_image') : t('literal.upload_image') }}
          </slot>
        </label>

        <p
          class="preview__tips margin__t--12"
          v-if="tips || (width && height)"
        >
          <fa-icon
            class="margin__r--6"
            :icon="['fal', 'lightbulb']"
          />

          <slot
            name="tips"
            v-bind="{ width, height }"
          >
            <template v-if="width === 9999">
              {{ t('tips.recommended_dimensions_image_high', { height }) }}
            </template>

            <template v-else-if="height === 9999">
              {{ t('tips.recommended_dimensions_image_wide', { width }) }}
            </template>

            <template v-else>
              {{ t('tips.recommended_dimensions_image', { width, height }) }}
            </template>
          </slot>
        </p>
      </div>
    </div>

    <p
      class="input--errors margin__t--6"
      v-if="v?.$error"
    >
      <span
        class="input--error"
        v-if="v.required?.$invalid"
      >
        {{ t('errors.input_file_required') }}
      </span>
    </p>

    <modal-crop-image
      v-if="show_modal"
      :file="file"
      :ratio="width / height"
      :min-width="width"
      :min-height="height"
      @cancel="cancel"
      @crop="handleCropImage"
    />
  </div>
</template>

<script setup>
import { defineAsyncComponent, ref, computed, toRefs, useSlots } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from '@/vendors/i18n'
import { useFileStore } from '@/stores/FileStore'
import { useNotificationStore } from '@/stores/NotificationStore'
import { useModals } from '@/composables/app/useModals'
import { getImagePath, guid } from '@/utils/utils'

import InputFile from '&/atoms/InputFile'

const AppSpinner = defineAsyncComponent(() => import('@/components/atoms/AppSpinner'))
const FormNotification = defineAsyncComponent(() => import('@/components/molecules/FormNotification'))
const ModalCropImage = defineAsyncComponent(() => import('&/organisms/ModalCropImage'))

const emit = defineEmits(['aborted', 'uploaded', 'update:modelValue'])

const props = defineProps({
  modelValue: String,
  id: String,
  accept: { type: String, default: 'image/*' },
  crop: { type: Boolean, default: true },
  upload: { type: Boolean, default: true },
  deletable: { type: Boolean, default: true },
  width: { type: Number, default: 1440 },
  height: { type: Number, default: 960 },
  maxWeight : { type: Number, default: 0 },
  placeholder: { type: String, default: 'placeholders/project-card-cover.jpg' },
  v: Object
})

const { t } = useI18n()
const { postFile } = useFileStore()
const { loader, error } = storeToRefs(useFileStore())
const { show_modal, closeModal, openModal } = useModals()
const { tips } = useSlots()
const { postSideNotification } = useNotificationStore()

const { id, accept, upload, modelValue, crop, width, height, maxWeight, placeholder } = toRefs(props)

const preview = ref()
const file = ref()
const is_processing = ref(false)

const inputId = computed(() => id.value || 'image' + guid(1))
const image = computed(() => preview.value || modelValue.value || getImagePath(placeholder.value))

const ratio = computed(() => {
  if (!width.value || !height.value || width.value === 9999 || height.value === 9999) return false
  if (width.value === height.value) return '1-1'

  return height.value > width.value ? '2-3' : '3-2'
} )

const handleImageError = e => e.target.src = getImagePath(placeholder.value)

const cancel = () => {
  file.value = preview.value = null
  is_processing.value = false

  closeModal()
}

const handleClickDelete = () => {
  emit('update:modelValue', null)

  file.value = preview.value = null
}

const handleImageChange = image => {
  is_processing.value = true
  file.value = image

  crop.value && ratio.value && !file.value.is_svg ? openModal() : handleCropImage(image)
}

const handleCropImage = file => {
  closeModal()

  file.setSize({ max_width: width.value, max_height: height.value })
    .then(file => {
      is_processing.value = false

      if (maxWeight.value && file.filesize > maxWeight.value) return postSideNotification({ text: 'errors.input_file_size_exceeded', type: 'error', delay: 10000, data: { name: file.name, size: maxWeight.value } })

      if (upload.value) return handleUploadImage(file)

      emit('update:modelValue', file)
    })
}

const handleUploadImage = file => {
  file.toDataURL(url => preview.value = url)

  file.toArrayBuffer(binary => {
    postFile({ params: { binary, name: file.name, type: file.type } })
      .then(url => emit('update:modelValue', url))
      .catch(() => cancel())
      .finally(() => preview.value = null)
  })
}
</script>

<style lang="scss" scoped>
.preview {
  &__container {
    position: relative;
    display: grid;
    align-items: center;
    gap: 24px;
    grid-template-columns: 1fr;

    @include mq(sm) {
      gap: 12px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include mq(md) {
      gap: 24px;
    }
  }

  &__figure {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all .2s;

    @include aspect-ratio(3, 2);

    &--2-3 {
      @include aspect-ratio(2, 3);
    }

    &--1-1 {
      @include aspect-ratio(1, 1);
    }
  }

  &__image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;

    &--is-loading {
      animation: loading-opacity 1s ease-in-out infinite alternate-reverse;
    }
  }

  &__spinner {
    position: absolute;
  }

  &__tips {
    font-size: rem(14px);
    color: rgba($dg, .6);
  }

  &__delete {
    position: absolute;
    z-index: 20;
    bottom: 12px;
    right: 12px;
    background-color: $white;
    border-radius: $radius__cards;
    width: 24px;
    height: 24px;
    box-shadow: 0 4px 8px 0 rgba(82, 97, 115, 0.1);

    &:hover {
      background-color: tint($color--danger, 35%);
      color: $white;
    }
  }
}
</style>
