import { gtm, mixpanel } from '@/vendors/integrations'

export default class {
  static share = ({ network, target_id, target_type }) => {
    gtm.track({
      event: 'share',
      share_method: network,
      target_id,
      target_type
    })
  }

  static search = ({ query }) => {
    gtm.track({
      event: 'search',
      search_term: query
    })
  }

  static view = ({ from, to }) => {
    if (from.name === to.name && from.path === to.path) return

    mixpanel.track('Page view', {
      'Name': to.name,
      'Path': to.path,
      'Locale': to.params.locale,
      'Initial page': from.name === undefined
    })
  }

  static link = ({ type, text, url, id, name, params }) => {
    gtm.track({
      event: 'click_link',
      link_type: type,
      link_text: text,
      link_url: url,
      target_id: id,
      target_name: name,
      target_params: params
    })
  }
}
