<template>
  <footer v-if="section">
    <section class="footer">
      <div class="row footer__container container">
        <section class="footer__column col-sm-4">
          <router-link
            :to="{ name: 'Home', params: { locale: $i18n.locale } }"
            v-show="client.logo.url"
          >
            <img
              class="logo__img"
              :src="client.logo.url"
              :alt="client.name"
            >
          </router-link>
        </section>

        <section class="footer__column col-sm-4">
          <h3
            class="title--h3 color--white"
            v-if="$i18n.translate(column2.title)"
          >
            {{ $i18n.translate(column2.title) }}
          </h3>

          <ul
            :class="{ 'margin__t--12': column2.title }"
            v-if="navigation.length"
          >
            <li
              class="footer__li"
              v-for="({ link }, index) in navigation"
              :key="'footer_menu_item_' + index"
            >
              <a
                class="footer__link link--naked"
                :class="{ 'footer__link--active': isVisited(link) }"
                v-if="link"
                @click.prevent="setRouteNavigation(link)"
              >
                <fa-icon
                  class="margin__r--6"
                  v-if="link.icon"
                  :icon="link.icon"
                />

                {{ $i18n.translate(link.text) }}
              </a>
            </li>
          </ul>
        </section>

        <section class="footer__column col-sm-4">
          <h3
            class="title--h3 color--white"
            v-if="$i18n.translate(column3.title)"
          >
            {{ $i18n.translate(column3.title) }}
          </h3>

          <ul
            :class="{ 'margin__t--12': column3.title }"
            v-if="actions.length"
          >
            <li
              class="footer__li"
              v-for="({ link }, index) in actions"
              :key="'footer_menu_item_' + index"
            >
              <a
                class="footer__link link--naked"
                :class="{ 'footer__link--active': isVisited(link) }"
                v-if="link"
                @click.prevent="setRouteNavigation(link)"
              >
                <fa-icon
                  class="margin__r--6"
                  v-if="link.icon"
                  :icon="link.icon"
                />

                {{ $i18n.translate(link.text) }}
              </a>
            </li>
          </ul>
        </section>
      </div>
    </section>
  </footer>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex';
import { get as _get } from 'lodash';
import PageRouteNavigation from '%/PageRouteNavigation';

export default defineComponent({
  name: 'CustomFooter111111',
  props: {
    section: {
      required: true,
      type: Object,
    },
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client',
    }),
    column2 () {
      return _get(this.section, 'attributes.column_2', {});
    },
    navigation () {
      return _get(this.column2, 'navigation', []);
    },
    column3 () {
      return _get(this.section, 'attributes.column_3', {});
    },
    actions () {
      return _get(this.column3, 'actions', []);
    },
  },
})
</script>

<style lang="scss" scoped>
.footer {
  background-color: #000000;

  &__container {
    display: flex;
    align-items: flex-start;
    padding: $padding--base * 2 0;
  }

  &__column {
    width: 100%;
    margin: $margin--base 0;
    text-align: center;

    @include mq(sm) {
      width: 50%;
      text-align: left;
      margin: $margin--base * 2 0;
    }

    @include mq(md) {
      margin: 0;
    }
  }

  &__link {
    color: $white;
    font-size: rem(20px);
    margin: 5px 0 5px 0;
    display: inline-block;
    position: relative;

    @include mq(md) {
      font-size: rem(24px);
      margin: 5px 0 10px 0;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      transition: width 0.3s;
      width: 45px;
      height: 3px;
      bottom: 0;
      left: 0;
      background: $color--primary;
    }

    &:focus,
    &:hover {
      &:after {
        width: 100%;
      }
    }

    &--active {
      color: $color--primary;

      &:after {
        width: 100%;
      }
    }
  }
}

.title {
  &--h3 {
    font-size: rem(24px);
    margin-bottom: $margin--base * 2;
    position: relative;
    display: inline-block;
  }
}

.logo {
  &__img {
    max-width: 215px;
    display: block;
  }
}

.footer__container {
  margin: auto;
}

/* Mobile devices */
@media only screen and (max-width: 767px) {
  .logo {
    &__img {
      margin: auto;
    }
  }
}
</style>
