<template>
  <footer v-if="section">
    <section class="footer">
      <div class="footer__container container">
        <section class="footer__column">
          <router-link
            :to="{ name: 'Home', params: { locale: $i18n.locale } }"
            v-show="client.logo.url"
          >
            <img
              class="logo__img"
              :src="client.logo.url"
              :alt="client.name"
            >
          </router-link>
        </section>

        <section class="footer__column">
          <h3
            class="title--h3 bold"
            v-if="$i18n.translate(column2.title)"
          >
            {{ $i18n.translate(column2.title) }}
          </h3>

          <ul
            :class="{'margin__t--12': column2.title}"
            v-if="navigation.length"
          >
            <li
              v-for="({ link }, index) in navigation"
              :key="'footer_menu_item_' + index"
            >
              <app-link
                :link="link"
                class="link__color--black-mg link--naked"
                active-class="link__color--prim"
              />
            </li>
          </ul>
        </section>

        <section class="footer__column">
          <h3
            class="title--h3 bold"
            v-if="$i18n.translate(column3.title)"
          >
            {{ $i18n.translate(column3.title) }}
          </h3>

          <ul
            :class="{'margin__t--12': column3.title}"
            v-if="actions.length"
          >
            <li
              v-for="({ link }, index) in actions"
              :key="'footer_menu_item_' + index"
            >
              <app-link
                :link="link"
                class="link__color--black-mg link--naked"
                active-class="link__color--prim"
              />
            </li>
          </ul>
        </section>

        <section
          class="footer__column"
          v-if="networks.length > 0"
        >
          <ul class="networks__ul">
            <li
              class="networks__li"
              v-for="(network, index) in networks"
              :key="'footer_menu_network_' + index"
            >
              <a
                class="networks__a"
                :href="$i18n.translate(network.url)"
                target="_blank"
              >
                <span
                  class="networks__span"
                  v-if="network.text"
                >
                  {{ $i18n.translate(network.text) }}
                </span>

                <fa-icon
                  v-if="network.icon"
                  size="lg"
                  :icon="network.icon"
                />
              </a>
            </li>
          </ul>
        </section>
      </div>
    </section>
  </footer>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'

export default defineComponent({
  name: 'FooterDefault',
  components: {
    AppLink: defineAsyncComponent(() => import('&/atoms/AppLink'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      client: 'client/client'
    }),
    column2 () {
      return _get(this.section, 'attributes.column_2', {})
    },
    navigation () {
      return _get(this.column2, 'navigation', [])
    },
    column3 () {
      return _get(this.section, 'attributes.column_3', {})
    },
    actions () {
      return _get(this.column3, 'actions', [])
    },
    networks () {
      const links = _get(this.client, 'platform.social_networks', [])
      return links.filter(link => this.$i18n.translate(link.url))
    }
  }
})
</script>

<style lang="scss" scoped>
.footer {
  background-color: $white;
  padding: $padding__base * 3 0;

  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  &__column {
    width: 100%;
    margin: $margin--base 0;
    text-align: center;

    @include mq(sm) {
      width: 50%;
      text-align: left;
      margin: $margin--base * 2 0;
    }

    @include mq(md) {
      width: auto;
      margin: 0;
    }
  }
}

.networks {
  &__ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: $margin--base * 2;
    justify-items: center;
    align-items: center;
  }

  &__a {
    color: $black;
    transition: all .3s;

    &:hover, &:focus {
      color: $color--primary;
    }
  }

  &__span {
    display: none;
  }
}

.logo {
  &__img {
    max-width: 150px;
    display: block;
    margin: auto;

    @include mq(sm) {
      margin: 0;
      max-width: 180px;
    }
  }
}
</style>
