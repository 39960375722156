<template>
  <div class="header--sticky">
    <header
      class="header"
      :class="{'header--fixed': mobileMenuOpened}"
      v-if="section"
    >
      <div class="header__container">
        <router-link
          :to="{ name: 'Home', params: { locale: $i18n.locale } }"
          v-show="client.logo.url"
          class="logo"
        >
          <img
            class="logo__img"
            :src="client.logo.url"
            :alt="client.name"
          >
        </router-link>

        <ul
          class="header__menu"
          v-if="menu.length > 0"
        >
          <li
            class="header__menu-item"
            v-for="({ link }, index) in menu"
            :key="`menu_item_${index}`"
          >
            <button
              class="header__link"
              :class="{'header__link--active': isVisited(link)}"
              @click.prevent="setRouteNavigation(link)"
            >
              {{ $i18n.translate(link.text) }}
            </button>
          </li>
        </ul>

        <div class="header__cell">
          <language-switcher
            class="header__language"
            v-if="isMultilingual"
          />

          <ul class="header__sessions margin__l--12">
            <li
              class="header__sessions-item"
              v-if="!isAuthenticated"
            >
              <button
                @click.prevent="openSessionAppModal('register')"
                class="header__link"
              >
                <p v-html="$i18n.t('literal.register')" />
              </button>
            </li>

            <li v-if="isAuthenticated && user">
              <router-link
                class="header__sessions-user"
                :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
              >
                <user-profile-img
                  :avatar="user.avatar"
                  size="2"
                />
              </router-link>
            </li>
          </ul>

          <ul
            class="header__actions"
            v-if="cta.length > 0"
          >
            <template
              v-for="({ button }, index) in cta"
            >
              <li
                class="header__actions-item"
                v-if="button.show"
                :key="`button_${index}`"
              >
                <button
                  class="header__button"
                  v-if="button"
                  :disabled="!isActive(button)"
                  @click.prevent="setRouteNavigation(button)"
                >
                  {{ $i18n.translate(button.text) }}
                </button>
              </li>
            </template>
          </ul>
        </div>

        <app-burger-menu
          class="header__burger"
          :state="mobileMenuOpened"
          @toggle="toggleMobileMenu"
        />
      </div>

      <transition name="fade-mobile-menu">
        <section
          class="header__cell mobile__menu"
          v-if="section && mobileMenuOpened"
        >
          <ul class="mobile__sessions">
            <li
              class="mobile__sessions-item header__sessions-item"
              v-if="!isAuthenticated"
            >
              <a
                @click.prevent="openSessionAppModal('login')"
                class="link__color--black link--naked pointer"
              >
                <fa-icon
                  class="margin__r--6 color--prim"
                  :icon="['fal', 'sign-in']"
                  fixed-width
                />
                {{ $i18n.t('literal.login') }}
              </a>
            </li>

            <li
              class="mobile__sessions-item header__sessions-item"
              v-if="!isAuthenticated"
            >
              <a
                @click.prevent="openSessionAppModal('register')"
                class="link__color--black link--naked pointer"
              >
                <fa-icon
                  class="margin__r--6 color--prim"
                  :icon="['fal', 'user-plus']"
                  fixed-width
                />
                {{ $i18n.t('literal.register') }}
              </a>
            </li>

            <li
              class="mobile__sessions-item header__sessions-item pointer"
              v-if="isAuthenticated && user"
            >
              <router-link
                :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
                @click.capture="mobileMenuOpened = false"
              >
                <user-profile-img
                  size="2"
                  :avatar="user.avatar"
                />
              </router-link>
            </li>


            <li
              class="mobile__sessions-item header__sessions-item pointer"
              v-if="isAuthenticated && user"
            >
              <router-link :to="{ name: 'Logout', params: { locale: $i18n.locale } }">
                <fa-icon
                  class="margin__r--6 color--danger"
                  :icon="['fal', 'sign-out']"
                  fixed-width
                />
                {{ $i18n.t('literal.logout') }}
              </router-link>
            </li>
          </ul>

          <ul
            class="mobile__list"
            v-if="menu.length > 0"
          >
            <li
              class="mobile__list-item"
              v-for="(link, index) in menu"
              :key="`mobile_menu_item_${index}`"
            >
              <a
                class="mobile__list-link"
                :class="{'mobile__link--active': isVisited(link.link)}"
                @click.prevent="setRouteNavigation(link.link); mobileMenuOpened = false"
              >
                {{ $i18n.translate(link.link.text) }}
              </a>
            </li>
          </ul>

          <ul
            class="mobile__actions"
            v-if="cta.length > 0"
          >
            <li
              class="mobile__actions-item"
              v-for="({ button }, index) in cta"
              :key="`button_${index}`"
            >
              <button
                class="header__button"
                v-if="button"
                :disabled="!isActive(button)"
                @click.prevent="setRouteNavigation(button); mobileMenuOpened = false"
              >
                {{ $i18n.translate(button.text) }}
              </button>
            </li>

            <li class="mobile__list-item">
              <div class="mobile__list-link mobile__list-language">
                <language-switcher v-if="isMultilingual" />
              </div>
            </li>
          </ul>
        </section>
      </transition>
    </header>

    <app-modal
      v-if="showSessionAppModal"
      size="sm"
      @close="showSessionAppModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="template"
          @authenticated="showSessionAppModal = false"
        />
      </template>
    </app-modal>
  </div>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import { get as _get } from 'lodash'
import { mapGetters } from 'vuex'
import UserProfileImg from '&/atoms/UserProfileImg'
import PageRouteNavigation from '%/PageRouteNavigation'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
import LanguageSwitcher from '&/atoms/LanguageSwitcher'

export default defineComponent({
  name: 'CustomHeaderBaluchon',
  components: {
    UserProfileImg,
    AppBurgerMenu,
    LanguageSwitcher,
    SessionForms: defineAsyncComponent(() => import('&/modules/sessions/SessionForms')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      showSessionAppModal: false,
      mobileMenuOpened: false,
      template: null
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    logo () {
      return _get(this.section, 'attributes.logo')
    },
    menu () {
      return _get(this.section, 'attributes.menu.links')
    },
    isMultilingual () {
      return this.client.active_languages.length > 1
    },
    cta () {
      return _get(this.section, 'attributes.cta')
    }
  },
  methods: {
    openSessionAppModal (template) {
      this.template = template
      this.showSessionAppModal = true
    },
    toggleMobileMenu (value) {
      this.mobileMenuOpened = !value
    }
  }
})
</script>

<style lang="scss" scoped>
$breakpoint: md;
$color-principal-dark: #750727;
$color-principal-neutral: #d50d47;
$color-secondary-dark: #719419;
$color-secondary-neutral: #92c020;
$color-secondary-light: #e7efd3;

.header {
  padding: 10px 0;
  box-shadow: 0 4px 8px 0 rgba(82, 97, 115, 0.1);
  font-size: rem(16px);
  background: $white;

  &--sticky {
    position: sticky;
    top: 0;
    z-index: 999;
  }

  &__cell, &__menu, &__actions, &__sessions, &__language {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__language {
      display: none;
    @include mq($breakpoint) {
      display: inherit;
    }
  }

  &__cell--grow {
    flex-grow: 1;
  }

  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background-color: $white;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;

    @include mq($breakpoint){
      padding: 0 50px;
    }
  }

  &__button {
    font-family: $font-family--secondary;
    font-size: .75em;
    font-weight: 700;
    color: $white;
    text-transform: uppercase;
    display: inline-block;
    padding: 12px 24px;
    background-color: $color-principal-neutral;
    border: none;
    border-radius: $radius__buttons;
    transition: background-color $transition__duration;

    &:focus,
    &:hover {
      background-color: $color-principal-dark;
    }
  }

  &__menu {
    margin-right: $margin__base * 4;
    display: none;

    @include mq($breakpoint) {
      display: flex;
    }

    &-item {
      &:not(:first-child) {
        margin-left: $margin__base * 4;
      }
    }
  }

  &__link {
    position: relative;
    border: 1px solid transparent;
    color: $color-principal-neutral;

    &:focus {
      outline: 0;
      font-weight: bold;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 0%;
      height: 1px;
      margin-top: 3px;
      background: $color-principal-neutral;
      transition: all $transition__duration;
    }

    &:hover:after {
      width: 100%;
    }

    &--active {
      position: relative;
      font-weight: bold;

      &:after {
        width: 100%;
      }
    }
  }

  &__actions {
    display: none;

    @include mq($breakpoint) {
      display: flex;
      margin-left: $margin__base;
    }

    &-item {
      white-space: nowrap;

      &:not(:last-child) {
        margin-right: $margin__base;
      }
    }
  }

  &__sessions {
    display: none;

    @include mq($breakpoint) {
      display: flex;
      justify-content: flex-end;
    }

    &-item {
      padding: $padding__base;
      display: block;
    }

    &-user {
      display: block;
    }
  }

  &__burger {
    display: inherit;

    &.mennu__line {
      background-color: $color-principal-neutral;
    }

    @include mq($breakpoint) {
      display: none;
    }
  }
}

.mobile {
  &__nav {
    width: 100vw;
    height: 100vh;
    background: $white;
    position: fixed;
    z-index: 10;
    overflow: hidden;
  }

  &__link {
    &--active {
      font-weight: bold;

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: $color-principal-neutral;
      }
    }
  }

  &__menu {
    margin-top: $margin--base;
    border-top: 1px solid $dw;
    display: block;

    @include mq($breakpoint) {
      display: none;
    }
  }

  &__list {
    margin-top: $margin--base;
    padding: 0 15px;
    text-align: center;

    @include mq($breakpoint){
      padding: 0 50px;
    }

    &-link {
      display: inline-block;
      padding: $padding--base;
    }
  }

  &__actions {
    margin-top: $margin--base;
    display: block;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mq($breakpoint){
      padding: 0 50px;
    }

    &-item {
      text-align: center;
    }

    &-link {
      display: inline-block;
      padding: $padding--base $padding--base * 2;
    }
  }

  &__sessions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $margin--base;
    justify-items: center;
    align-items: center;
    border-bottom: 1px solid $dw;
    padding: 0 15px;

    @include mq($breakpoint){
      padding: 0 50px;
    }

    &-item {
      white-space: nowrap;
    }
  }
}

.logo {
  &__img {
    transition: all $transition__duration;
    display: block;
    height: 50px;

    @include mq($breakpoint) {
      height: 65px;
    }
  }
}

.fade-mobile-menu-enter-active {
  animation: fadeTranslationY .5s ease-in-out;
}

.fade-mobile-menu-leave-active {
  animation: fadeTranslationY .5s ease-in-out reverse;
}

@keyframes fadeTranslationY {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 100%;
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq($breakpoint) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}
</style>
