<template>
  <footer
    class="footer"
    v-if="section"
  >
    <div class="container">
      <div class="footer__content">
        <section class="footer__column footer__newsletter">
          <p
            class="editor editor__content"
            v-html="$i18n.translate(newsletter.text)"
          />
        </section>

        <section class="footer__column">
          <router-link
            :to="{ name: 'Home', params: { locale: $i18n.locale } }"
            v-show="client.logo.url"
          >
            <img
              class="footer__logo"
              :src="client.logo.url"
              :alt="client.name"
            >
          </router-link>
        </section>

        <section class="footer__column footer__contact">
          <ul>
            <li>{{ $i18n.translate(contact.email_label) }}</li>
            <li>
              <a
                class="link__color--prim link--naked"
                target="_blank"
                :href="`mailto:${$i18n.translate(contact.email)}`"
              >
                {{ $i18n.translate(contact.email) }}
              </a>
            </li>
            <li>{{ $i18n.translate(contact.phone_number) }}</li>
          </ul>

          <button
            class="link__color--prim link--naked margin__t--18"
            v-if="contact.faq && contact.faq.show"
            @click.prevent="setRouteNavigation(contact.faq)"
          >
            {{ $i18n.translate(contact.faq.text) }}
          </button>
        </section>

        <section class="footer__column footer__social">
          <p>
            {{ $i18n.translate(social.text_1) }}
          </p>

          <ul
            class="networks"
            v-if="networks.length > 0"
          >
            <li
              class="networks__el"
              v-for="(network, i) in networks"
              :key="'footer_menu_network_' + i"
            >
              <a
                class="link__color--white-prim link--naked"
                :href="$i18n.translate(network.url)"
                target="_blank"
              >
                <fa-icon
                  v-if="network.icon"
                  size="lg"
                  :icon="network.icon"
                />
              </a>
            </li>
          </ul>

          <p
            class="editor editor__content"
            v-html="$i18n.translate(social.text_2)"
          />
        </section>
      </div>
    </div>
  </footer>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'

export default defineComponent({
  name: 'CustomFooterLightForTheWorld',
  props: {
    section: {
      required: true,
      type: Object,
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client',
    }),
    attributes () {
      return _get(this.section, 'attributes', {})
    },
    newsletter () {
      return _get(this.attributes, 'newsletter', {})
    },
    contact () {
      return _get(this.attributes, 'contact', {})
    },
    social () {
      return _get(this.attributes, 'social', {})
    },
    networks () {
      const links = _get(this.client, 'platform.social_networks', [])
      return links.length > 0 ? links.filter(link => link.url[this.$i18n.locale]) : []
    }
  }
})
</script>

<style lang="scss" scoped>
.footer {
  background-color: #050d39;
  padding: 32px 0;
  color: $white;

  @include mq(sm) {
    padding: 64px 0;
  }

  &__content {
    display: grid;
    grid-template-columns: 1;
    grid-gap: $margin__base * 2;
    align-items: center;
    font-size: 14px;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: $margin__base * 4;
      font-size: 16px;
    }

    @include mq(md) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__newsletter {
    font-size: 12px;
    text-align: center;

    @include mq(sm) {
      text-align: left;
    }
  }

  &__logo {
    max-width: 158px;
    margin: auto;
    display: block;

    @include mq(sm) {
      margin: 0;
    }

    @include mq(md) {
      margin: auto;
    }
  }

  &__contact {
    text-align: center;

    @include mq(sm) {
      text-align: left;
    }
  }

  &__social {
    text-align: center;

    @include mq(sm) {
      text-align: left;
    }

    @include mq(md) {
      text-align: right;
    }
  }
}

.networks {
  display: flex;
  font-size: rem(14px);
  justify-content: center;
  margin: $margin__base * 2 0;

  @include mq(sm){
    justify-content: flex-start;
  }

  @include mq(md){
    justify-content: flex-end;
  }

  &__el {
    position: relative;
    overflow: hidden;

    &:not(:first-of-type){
      margin-left: $margin__base * 2;
    }
  }
}
</style>
