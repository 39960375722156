<template>
  <div>
    <section
      class="banner bg__color--ter"
      v-if="$i18n.translate(section.attributes.header.banner)"
    >
      <figure
        class="banner__img"
        :style="{backgroundImage: `url(${ $i18n.translate(section.attributes.header.banner) })`,}"
      />
    </section>

    <section style="height: 100%">
      <div class="container">
        <div class="row top_menu">
          <div
            class="col-md-4 firstBackground d-flex align-items-center justify-content-center"
            :style="{backgroundImage: `url(${ getImagePath('clients/111111/brush-1.png') })`}"
          >
            <a
              @click.prevent="setRouteNavigation(section.attributes.header.first_button)"
              v-if="section.attributes.header.first_button && section.attributes.header.first_button.show && $i18n.translate(section.attributes.header.first_button.text)"
            >
              {{ $i18n.translate(section.attributes.header.first_button.text) }}
            </a>
          </div>

          <div
            class="col-md-4 secondBackground d-flex align-items-center justify-content-center"
            :style="{backgroundImage: `url(${ getImagePath('clients/111111/brush-2.png') })`}"
          >
            <a
              @click.prevent="setRouteNavigation(section.attributes.header.second_button)"
              v-if="section.attributes.header.second_button && section.attributes.header.second_button.show && $i18n.translate(section.attributes.header.second_button.text)"
            >
              {{ $i18n.translate(section.attributes.header.second_button.text) }}
            </a>
          </div>

          <div
            class="col-md-4 thirdBackground d-flex align-items-center justify-content-center"
            :style="{backgroundImage: `url(${ getImagePath('clients/111111/brush-3.png') })`}"
          >
            <a
              @click.prevent="setRouteNavigation(section.attributes.header.third_button)"
              v-if="section.attributes.header.third_button && section.attributes.header.third_button.show && $i18n.translate(section.attributes.header.third_button.text)"
            >
              {{ $i18n.translate(section.attributes.header.third_button.text) }}
            </a>
          </div>
        </div>

        <div class="row first_container margin__t--60">
          <div class="col">
            <div class="container_title">
              <div class="container_title_left">
                {{ $i18n.translate(section.attributes.campaign.title_first_part) }}
              </div>

              <div class="container_title_right">
                {{ $i18n.translate(section.attributes.campaign.title_second_part) }}
              </div>
            </div>

            <div
              v-if="$i18n.translate(section.attributes.campaign.text)"
              class="first_container_text editor editor__content"
              v-html="$i18n.translate(section.attributes.campaign.text)"
            />

            <div class="container_button">
              <a
                @click.prevent="setRouteNavigation(section.attributes.campaign.read_more)"
                v-if="section.attributes.campaign.read_more && section.attributes.campaign.read_more.show && $i18n.translate(section.attributes.campaign.read_more.text)"
              >
                {{ $i18n.translate(section.attributes.campaign.read_more.text) }}
              </a>
            </div>
          </div>

          <div class="col container_image">
            <img
              v-if="$i18n.translate(section.attributes.campaign.image)"
              class="img-fluid"
              :src="$i18n.translate(section.attributes.campaign.image)"
            >
          </div>
        </div>

        <div class="projects">
          <section-projects-overview
            :page="page"
            :section="projectSection"
          />
        </div>

        <div class="row margin__t--36">
          <div
            class="col-md-4 firstBackground d-flex align-items-center justify-content-center"
            :style="{backgroundImage: `url(${ getImagePath('clients/111111/brush-1.png') })`}"
          >
            <a
              @click.prevent="setRouteNavigation(section.attributes.header.first_button)"
              v-if="section.attributes.header.first_button && section.attributes.header.first_button.show && $i18n.translate(section.attributes.header.first_button.text)"
            >
              {{ $i18n.translate(section.attributes.header.first_button.text) }}
            </a>
          </div>

          <div
            class="col-md-4 secondBackground d-flex align-items-center justify-content-center"
            :style="{backgroundImage: `url(${ getImagePath('clients/111111/brush-2.png') })`}"
          >
            <a
              @click.prevent="setRouteNavigation(section.attributes.header.second_button)"
              v-if="section.attributes.header.second_button && section.attributes.header.second_button.show && $i18n.translate(section.attributes.header.second_button.text)"
            >
              {{ $i18n.translate(section.attributes.header.second_button.text) }}
            </a>
          </div>

          <div
            class="col-md-4 thirdBackground d-flex align-items-center justify-content-center"
            :style="{backgroundImage: `url(${ getImagePath('clients/111111/brush-3.png') })`}"
          >
            <a
              @click.prevent="setRouteNavigation(section.attributes.header.third_button)"
              v-if="section.attributes.header.third_button && section.attributes.header.third_button.show && $i18n.translate(section.attributes.header.third_button.text)"
            >
              {{ $i18n.translate(section.attributes.header.third_button.text) }}
            </a>
          </div>
        </div>

        <div class="row second_container margin__t--36">
          <div
            class="col container_image"
            v-if="$i18n.translate(section.attributes.about.image)"
          >
            <img
              class="img-fluid"
              :src="$i18n.translate(section.attributes.about.image)"
            >
          </div>
          <div class="col">
            <div class="container_title">
              <div
                class="container_title_left"
                v-if="$i18n.translate(section.attributes.about.title_first_part)"
              >
                {{ $i18n.translate(section.attributes.about.title_first_part) }}
              </div>

              <div
                class="container_title_right2"
                v-if="$i18n.translate(section.attributes.about.title_second_part)"
              >
                {{ $i18n.translate(section.attributes.about.title_second_part) }}
              </div>
            </div>

            <div
              v-if="$i18n.translate(section.attributes.about.text)"
              class="second_container_text editor editor__content"
              v-html="$i18n.translate(section.attributes.about.text)"
            />

            <div class="container_button">
              <a
                @click.prevent="setRouteNavigation(section.attributes.about.read_more)"
                v-if="section.attributes.about.read_more && section.attributes.about.read_more.show && $i18n.translate(section.attributes.about.read_more.text)"
              >
                {{ $i18n.translate(section.attributes.about.read_more.text) }}
              </a>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col carousel_content">
            <div
              class="carousel-wrapper"
              v-if="carousel.items && carousel.items.length > 0"
            >
              <div
                class="carousel-item"
              >
                <transition name="fade">
                  <div v-if="show">
                    <div
                      v-if="$i18n.translate( carousel.items[0].text)"
                      class="carousel_text"
                      v-html="$i18n.translate( carousel.items[0].text)"
                    />

                    <div class="carousel_action">
                      <button
                        class="carousel_button"
                        @click.prevent="setRouteNavigation( carousel.items[0].button)"
                        v-if=" carousel.items[0].button && carousel.items[0].button.show"
                      >
                        {{ $i18n.translate( carousel.items[0].button.text) }}
                      </button>
                    </div>
                  </div>
                </transition>
              </div>

              <div
                class="arrow arrow-prev cursor--pointer"
                :style="{backgroundImage: `url(${ getImagePath('clients/111111/arrow-left.png') })`}"
                @click="goBack()"
              />

              <div
                class="arrow arrow-next cursor--pointer"
                :style="{backgroundImage: `url(${ getImagePath('clients/111111/arrow-right.png') })`}"
                @click="goNext()"
              />
            </div>
          </div>
        </div>
      </div>

      <ul
        class="networks margin__b--60"
        v-if="socialNetworks.length > 0"
      >
        <li
          class="networks__item"
          v-for="(network, i) in socialNetworks"
          :key="'footer_menu_network_' + i"
        >
          <a
            class="networks__link"
            :href="network.url[$i18n.locale]"
            target="_blank"
          >
            <span
              class="networks__text"
              v-if="network.text"
            >
              {{ $i18n.translate(network.text) }}
            </span>

            <fa-icon
              v-if="network.icon"
              size="lg"
              :icon="network.icon"
            />
          </a>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { get as _get } from 'lodash'
import { mapGetters } from 'vuex'
import PageRouteNavigation from '%/PageRouteNavigation'
import SectionProjectsOverview from '&/modules/pages/default/sections/SectionProjectsOverview'
import { getImagePath } from '@/utils/utils'

export default defineComponent({
  name: 'CustomHomepage111111',
  components: {
    SectionProjectsOverview
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      project_cards: [],
      show: true
    }
  },
  setup: () => ({ getImagePath }),
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client'
    }),
    attributes () {
      return this.section.attributes
    },
    projectSection () {
      return {
        ...this.section,
        attributes: this.section.attributes.projects
      }
    },
    carousel () {
      return _get(this.section, 'attributes.testimonials', {})
    },
    socialNetworks () {
      return _get(this.client, 'platform.social_networks', []).filter(link => link.url[this.$i18n.locale])
    }
  },
  methods: {
    goBack () {
      const last = this.carousel.items.pop()
      this.carousel.items = [last].concat(this.carousel.items)
      this.show = false

      setTimeout(() => {
        this.show = true
      }, 300)
    },
    goNext () {
      const first = this.carousel.items.shift()
      this.carousel.items = this.carousel.items.concat(first)
      this.show = false

      setTimeout(() => {
        this.show = true
      }, 300)
    },
    slidesStyle () {
      return {
        width: this.width * this.clonedSlides.length + 'px',
        left: -this.width * 2 + 'px',
        transform: `translateX(${this.index * -this.width + 'px'})`
      }
    }
  }
})
</script>

<style lang="scss" scoped>

$color--primary: #be1a21;
$color--secondary: #131415;
$color--tertiary: #b2bcff;

.banner {
  &__img {
    display: block;
    background-size: cover;
    background-position: center;
    min-height: 604px;
    margin-top: -30px;
  }

  &__content {
    color: $white;
    padding: $padding__base * 5 $padding__base * 3;
    display: block;
    margin: auto;
  }

  &__buttons {
    margin-top: $margin__base * 2;

    .btn {
      margin: $margin__base 0;
      display: block;

      @include mq(sm) {
        margin: 0 $margin__base * 2 0 0;
      }
    }

    @include mq(sm) {
      display: flex;
      justify-content: flex-start;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
  }
}

.top_menu {
  padding-top: 30px;
}

.firstBackground {
  font-family: $font-family--secondary;
  position: relative;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 85px;
}

.secondBackground {
  font-family: $font-family--secondary;
  position: relative;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 65px;
}
.thirdBackground {
  font-family: $font-family--secondary;
  position: relative;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 95px;
}

.firstBackground :deep() a,
.secondBackground :deep() a,
.thirdBackground :deep() a {
  color: #ffffff !important;
  font-weight: 800;
  font-size: 24px;
  text-shadow: 2px 2px #000;
}

.first_container {
  display: flex;
  flex-direction: column;
}

.second_container {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.container_title_left {
  font-family: $font-family--secondary;
  font-weight: 700;
  font-size: 34px;
  line-height: 48px;
  padding: 2px 5px 2px 0px;
  transform: rotate(-2deg);
  text-transform: uppercase;
  display: inline-block;
}
.container_title_right {
  font-family: $font-family--secondary;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #e33126;
  padding: 10px;
  margin-left: 5px;
  transform: rotate(5deg);
  background-color: #f5dec8;
  display: inline-block;
}
.container_title_right2 {
  font-family: $font-family--secondary;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #ffffff;
  line-height: 30px;
  padding: 10px;
  margin-left: 5px;
  transform: rotate(4deg);
  background-color: #e33126;
  display: inline-block;
}
.first_container_text {
  font-family: $font-family--primary;
  padding-top: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.second_container_text {
  font-family: $font-family--primary;
  padding-top: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.container_button {
  padding-top: 50px;
}

.container_button :deep() a {
  font-family: $font-family--secondary;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  text-decoration: underline;
}

.container_image {
  padding-top: 30px;
}

.social_icons {
  margin-top: 50px;
  margin-bottom: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 48px;
  line-height: 48px;
}

.icon:not(:first-child) {
  padding-left: 30px;
}

.icon a:link,
a:active,
a:hover,
a:visited {
  color: #000000;
}

/* * * CAROUSEL * * */

.carousel_content {
  margin-top: 40px;
}

.carousel-wrapper {
  min-height: 200px;
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.carousel-item {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 25px 50px;
  transition: all 0.5s ease-in-out;
  background-color: #f5dec8;
}

.arrow {
  display: inline-block;
  padding: 12px;
  width: 50px;
  height: 36px;
  background-repeat: no-repeat;
}

.arrow-prev {
  left: 50px;
  position: absolute;
  top: 50%;
}

.arrow-next {
  right: 50px;
  position: absolute;
  top: 50%;
}

.light {
  color: white;
}

/*Select every element*/
[id^="item"] {
  display: none;
}

.carousel_text {
  padding-left: 0px;
  padding-right: 0px;
  font-family: $font-family--primary;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.carousel_text :deep() p {
  padding-top: 20px;
}

.carousel_action {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}
.carousel_button {
  background: #e33126;
  transform: rotate(4deg);
  font-family: $font-family--secondary;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  padding: 10px;
}

.firstBackground :deep() a,
.secondBackground :deep() a,
.thirdBackground :deep() a {
  color: #ffffff !important;
  font-size: 20px;
}

.secondBackground :deep() a {
  margin-top: -10px;
}

/* Mobile devices */
@media only screen and (max-width: 767px) {

  .carousel_action {
    padding-bottom: 80px;
  }

  .arrow-prev {
    bottom: 30px;
    top: auto !important;
  }

  .arrow-next {
    bottom: 30px;
    top: auto !important;
  }

  .carousel-item {
    padding: 20px 40px;
  }

  .firstBackground {
    margin-left: 10px;
    margin-right: 10px;
  }

  .secondBackground {
    padding-right: 50px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .thirdBackground {
    padding-right: 40px;
    margin-left: 10px;
    margin-right: 5px;
  }

  .img-fluid {
    width: 100%;
    height: auto;
  }

  .carousel_button {
    font-size: 18px;
  }

  .arrow-prev {
    left: 100px;
    position: absolute;
    top: 85%;
  }

  .arrow-next {
    right: 100px;
    position: absolute;
    top: 85%;
  }

  .banner__img {
    min-height: 360px;
  }
}

/* Medium devices (landscape tablets, 991px and down) */
@media only screen and (max-width: 991px) {
  .banner__img {
    margin-top: 0;
  }
}

/* Medium devices (landscape tablets, 400px and down) */
@media only screen and (max-width: 400px) {

  .firstBackground :deep() a,
  .secondBackground :deep() a,
  .thirdBackground :deep() a {
    font-size: 18px;
    line-height: 20px;
  }
}

/* Medium devices (landscape tablets, 360px and down) */
@media only screen and (max-width: 360px) {

  .arrow-prev {
    left: 80px;
  }

  .arrow-next {
    right: 80px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .carousel_text {
    font-size: 20px;
    line-height: 24px;
    padding-left: 70px;
    padding-right: 70px;
  }
}

/* Large devices (laptops/desktops,1024px and up) */
@media only screen and (min-width: 992px) {

  .secondBackground {
    background-position-x: 20px;
  }

  .container {
    max-width: 992px !important;
  }

  .first_container {
    flex-direction: row;
  }

  .second_container {
    flex-direction: row;
  }

  .firstBackground :deep() a,
  .secondBackground :deep() a,
  .thirdBackground :deep() a {
    font-size: 17px;
  }

  .banner__img {
    margin-top: -30px;
  }

  .top_menu {
    padding-top: 0;
  }
  .firstBackground {
    margin-top: -40px;
    font-size: 16px;
  }

  .secondBackground {
    margin-top: -25px;
    font-size: 16px;
  }

  .thirdBackground {
    margin-top: -45px;
    font-size: 16px;
  }

  .container_title_left {
    font-size: 48px;
  }

  .container_title_right {
    font-size: 30px;
    margin-left: 10px;
  }

  .container_title_right2 {
    font-size: 30px;
    margin-left: 10px;
  }

  .first_container_text {
    font-size: 22px;
    line-height: 26px;
  }

  .second_container_text {
    font-size: 22px;
    line-height: 26px;
  }

  .carousel_content {
    margin-top: 70px;
  }

  .carousel_text {
    font-size: 22px;
    line-height: 26px;
    padding-left: 150px;
    padding-right: 150px;
  }
}

/* Extra large devices (large laptops and desktops, 1440px and up) */
@media only screen and (min-width: 1280px) {

  .secondBackground {
    background-position-x: 50px;
  }

  .container {
    max-width: 1280px !important;
  }

  .firstBackground :deep() a,
  .secondBackground :deep() a,
  .thirdBackground :deep() a {
    font-weight: 800;
    font-size: 24px;
  }

  .first_container_text {
    padding-right: 80px;
  }

  .container_button {
    padding-top: 30px;
  }

  .carousel_text {
    padding-left: 200px;
    padding-right: 200px;
  }
}

.wrapper {
  max-width: 1318px;
  margin: 0 auto;
  padding: 0 $padding__base * 1.5;

  @include mq(sm) {
    padding: 0 $padding__base * 2;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.projects {
  padding: $margin__base * 2 0;

  &__list {
    .card {
      [scroll-animation] & {
        opacity: 0;
      }

      [scroll-animation="true"] & {
        @for $i from 1 through 12 {
          :deep() &:nth-child(#{$i}) {
            animation: show-up 0.2s ease-in-out #{$i * 0.1}s 1 normal forwards;
          }
        }
      }
    }
  }
}

.networks {
  display: flex;
  font-size: rem(24px);
  margin-top: $margin__base * 2;
  justify-content: center;

  @include mq(sm) {
    font-size: rem(32px);
    margin-top: $margin__base * 3.5;
    justify-content: center;
  }

  &__item {
    &:not(:last-child) {
      margin-right: $margin__base * 1.5;
    }
  }

  &__text {
    display: block;
    width: 0;
    height: 0;
    color: transparent;
  }

  &__link {
    color: $color--secondary;
    transition: all .2s;

    &:hover {
      color: $color--primary;
    }
  }
}

</style>
