import { useClientStore } from '@/stores/ClientStore'
import i18n from '@/vendors/i18n'
import { helpers as vuelidateHelpers } from '@vuelidate/validators'
import { differenceInMinutes } from 'date-fns'
import { isValidIBAN } from 'ibantools'

const supportedImageFile = value => {
  if (!vuelidateHelpers.req(value)) {
    return true
  }
  const supported = ['jpg', 'jpeg', 'gif', 'png', 'svg']
  const suffix = value.split('.').pop()
  return supported.includes(suffix)
}

const validIban = value => !vuelidateHelpers.req(value) ? true : isValidIBAN(value)

const validVideoUrl = value => {
  if (!vuelidateHelpers.req(value)) {
    return true
  }

  const youtubeRegexp = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
  const vimeoRegexp = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/i
  const dailymotionRegexp = /^.*(?:\/video|dai.ly)\/([A-Za-z0-9]+)([^#&?]*).*/i

  let results = [youtubeRegexp, vimeoRegexp, dailymotionRegexp].map(regexp => {
    const val = value.match(regexp)
    return !!(val && val[0])
  })

  return results.includes(true)
}

const validURISchema = value => {
  if (!vuelidateHelpers.req(value)) {
    return true
  }

  const mailtoRegex = /^mailto:\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})$/
  const telRegex = /^tel:([0-9+]+)$/

  return [mailtoRegex, telRegex].map(regexp => {
    const val = value.match(regexp)
    return !!(val && val[0])
  }).includes(true)
}

const validIframe = (value) => {
  if (!vuelidateHelpers.req(value)) return true

  const match = value.match(/^<iframe(.+)<\/iframe>/)

  return !!(match && match[0])
}

const validTemplatingVariable = value => {
  if (!vuelidateHelpers.req(value)) return true

  const match = value.replace(/ /g, '').match(/^{{[a-z_.-]+}}$/)

  return !!(match && match[0])
}

const validateMultilingual = rules => {
  const { getClient } = useClientStore()
  const { client } = getClient()
  return client.value.active_languages.reduce((acc, curr) => {
    acc[curr] = rules
    return acc
  }, {})
}

const minDate = date => vuelidateHelpers.withParams(
  { type: 'minDate', min: date },
  value => !vuelidateHelpers.req(value) || !date || differenceInMinutes(new Date(value), new Date(date)) >= 0
)

const maxDate = date => vuelidateHelpers.withParams(
  { type: 'maxDate', max: date },
  value => !vuelidateHelpers.req(value) || !date || differenceInMinutes(new Date(value), new Date(date)) <= 0
)

const externalValidation = (error, params = {}) => vuelidateHelpers.withMessage(i18n.t(error, params), vuelidateHelpers.withParams({ type: 'custom', error, params }, () => false))

export {
  supportedImageFile,
  validIban,
  validVideoUrl,
  validURISchema,
  validIframe,
  validTemplatingVariable,
  minDate,
  maxDate,
  validateMultilingual,
  externalValidation
}
