<template>
  <footer
    v-if="section"
    class="footer"
  >
    <div class="wrapper footer__wrapper">
      <div class="footer__col">
        <nav
          v-if="links.length > 0"
        >
          <ul class="footer__menu">
            <li
              class="footer__menu-item"
              v-for="(item, index) in links"
              :key="`menu_link_${index}`"
            >
              <app-link
                :link="item.link"
                class="footer__menu-link"
              />
            </li>
          </ul>
        </nav>

        <ul
          class="networks"
          v-if="socialNetworks.length > 0"
        >
          <li
            class="networks__item"
            v-for="(network, i) in socialNetworks"
            :key="'footer_menu_network_' + i"
          >
            <a
              class="networks__link"
              :href="network.url[$i18n.locale]"
              target="_blank"
            >
              <span
                class="networks__text"
                v-if="network.text"
              >
                {{ $i18n.translate(network.text) }}
              </span>

              <fa-icon
                v-if="network.icon"
                size="lg"
                :icon="network.icon"
              />
            </a>
          </li>
        </ul>
      </div>

      <div class="footer__col">
        <nav
          v-if="navigation.length > 0"
        >
          <ul class="footer__menu">
            <li
              class="footer__menu-item"
              v-for="(item, index) in navigation"
              :key="`menu_item_${index}`"
            >
              <app-link
                :link="item.link"
                class="footer__menu-link"
              />
            </li>
          </ul>
        </nav>
      </div>

      <div class="footer__col">
        <div class="margin__b--18">
          <button
            @click.prevent="openSessionAppModal('register')"
            class="footer__session"
            v-if="!isAuthenticated"
          >
            <fa-icon
              class="margin__r--6"
              :icon="['fas', 'user']"
            />
            <span v-html="$i18n.t('literal.login')" />
          </button>

          <router-link
            class="footer__session footer__session--user d-flex align-items-center"
            :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
            v-if="isAuthenticated && user"
          >
            <user-profile-img
              size="2"
              :avatar="user.avatar"
            />

            <span class="d-block margin__l--6">{{ user.firstname }}</span>
          </router-link>
        </div>

        <app-link
          :link="cta"
          class="footer__cta"
          @click="mobileMenuOpened = false"
        />

        <div
          class="organisers"
          v-if="organisers.logos && organisers.logos.length > 0"
        >
          <h4
            v-if="organisers.title"
            class="organisers__title"
          >
            {{ $i18n.translate(organisers.title) }}
          </h4>

          <ul class="organisers__logos">
            <li
              v-for="(organiser, index) in organisers.logos"
              :key="`organisers_${index}`"
              class="organiser"
            >
              <app-link
                :link="organiser.link"
                class="organiser__link"
                v-slot="{ text }"
                v-if="organiser.link && organiser.link.show"
              >
                <img
                  :src="$i18n.translate(organiser.logo)"
                  :alt="text"
                  class="organiser__logo"
                >
              </app-link>

              <img
                :src="$i18n.translate(organiser.logo)"
                :alt="organiser.link.text"
                class="organiser__logo"
                v-else
              >
            </li>
          </ul>
        </div>
      </div>
    </div>

    <app-modal
      v-if="showSessionAppModal"
      size="sm"
      @close="showSessionAppModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="template"
          @authenticated="showSessionAppModal = false"
        />
      </template>
    </app-modal>
  </footer>
</template>

<script>
import { defineComponent } from 'vue'
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'
import UserProfileImg from '&/atoms/UserProfileImg'

export default defineComponent({
  name: 'CustomFooter24hTelevie',
  components: {
    UserProfileImg,
    AppLink: defineAsyncComponent(() => import('&/atoms/AppLink')),
    SessionForms: defineAsyncComponent(() => import('&/modules/sessions/SessionForms')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      showSessionAppModal: false,
    }
  },
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    column_1 () {
      return _get(this.section, 'attributes.column_1')
    },
    column_2 () {
      return _get(this.section, 'attributes.column_2')
    },
    column_3 () {
      return _get(this.section, 'attributes.column_3')
    },
    socialNetworks () {
      return _get(this.client, 'platform.social_networks', []).filter(link => link.url[this.$i18n.locale])
    },
    links () {
      return _get(this.column_1, 'links')
    },
    navigation () {
      return _get(this.column_2, 'navigation')
    },
    cta () {
      return _get(this.column_3, 'cta')
    },
    organisers () {
      return _get(this.column_3, 'organisers')
    }
  },
  methods: {
    openSessionAppModal (template) {
      this.template = template
      this.showSessionAppModal = true
    }
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #f40b0b;
$color--secondary: #131415;

.wrapper {
  max-width: 1318px;
  margin: 0 auto;
  padding: 0 $padding__base * 1.5;

  @include mq(sm) {
    padding: 0 $padding__base * 2;
  }
}

.networks {
  display: flex;
  font-size: rem(24px);
  margin-top: $margin__base * 2;
  justify-content: center;

  @include mq(sm) {
    font-size: rem(32px);
    margin-top: $margin__base * 3.5;
    justify-content: start;
  }

  &__item {
    &:not(:last-child) {
      margin-right: $margin__base * 1.5;
    }
  }

  &__text {
    display: block;
    width: 0;
    height: 0;
    color: transparent;
  }

  &__link {
    color: $color--secondary;
    transition: all .2s;

    &:hover {
      color: $color--primary;
    }
  }
}

.footer {
  position: relative;
  background: #F7F8FF;
  padding: $padding__base * 5 0;
  color: $color--secondary;
  font-size: rem(14px);

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: $margin__base;

    @include mq(sm) {
      grid-template-columns: 1fr 1fr 1fr;
      gap: $margin__base * 2
    }

    @include mq(md) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: $margin__base * 3
    }
  }

  &__col {
    text-align: center;

    @include mq(sm) {
      text-align: left;

      &:last-child {
        text-align: right;
      }
    }
  }

  &__menu {
    font-weight: 500;

    &-item {
      &:not(:last-child) {
        margin-bottom: $margin__base;

        @include mq(sm) {
          margin-bottom: $margin__base * 1.5;
        }
      }
    }

    :deep(.footer__menu-link) {
      display: block;
      font-size: rem(16px);
      transition: all .2s;

      @include mq(sm) {
        font-size: rem(18px);
      }

      @include mq(md) {
        font-size: rem(24px);
      }

      &:hover, &.is-active {
        color: $color--primary;
      }
    }
  }

  &__session {
    justify-content: center;

    @include mq(sm) {
      justify-content: flex-end;
    }
  }

  :deep(.footer__cta) {
    display: inline-block;
    color: $white;
    background: $color--primary;
    padding: 19px 22px 17px;
    line-height: 1;
    transition: all .2s;
    font-weight: 500;
    border-radius: 35px;
    transition: all .2s;
    text-align: center;
    font-size: rem(16px);
    margin-top: $margin__base * 1.5;
    font-size: 14px;

    @include mq(md) {
      font-size: 16px;
    }

    @include mq(lg) {
       font-size: 18px;
    }

    @include mq(lg) {
      padding: 23px 20px 21px;
      min-width: 220px;
    }

    &:hover {
      background: darken($color--primary, 5);
    }
  }
}

.organisers {
  margin-top: $margin__base * 4.5;

  @include mq(sm) {
    text-align: left;
    display: inline-block;
  }

  &__title {
    margin-bottom: $margin__base * 1.5;
    font-size: rem(14px);
    font-weight: 500;
  }

  &__logos {
    display: inline-grid;
    grid-template-columns: 90px 90px;
    gap: $margin__base * 3;
  }
}

.organiser {
  :deep(.organiser__link) {
    display: block;
  }

  &__logo {
    max-width: 100%;
    height: auto;
    display: block;
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}
</style>
