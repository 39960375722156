<template>
  <div class="input__container">
    <div class="input__flex">
      <button
        class="button button__down"
        :disabled="decrementDisabled || disabled"
        @click="handleClickDecrement"
      >
        <fa-icon :icon="['fal', 'minus']" />
      </button>

      <input
        type="number"
        class="input"
        v-model="model"
        :id="id"
        :min="min"
        :max="max"
        :disabled="disabled"
        :placeholder="placeholder"
        @blur="handleBlur"
      >

      <button
        class="button button__up"
        :disabled="incrementDisabled || disabled"
        @click="handleClickIncrement"
      >
        <fa-icon :icon="['fal', 'plus']" />
      </button>
    </div>

    <div
      class="input--errors"
      v-if="v && v.$error"
    >
      <p
        class="input--error"
        v-if="'required' in v && v.required.$invalid === true"
      >
        {{ t('errors.input_number_required') }}
      </p>

      <p
        class="input--error"
        v-else-if="'minValue' in v && v.minValue.$invalid === true"
      >
        {{ t('errors.input_number_min_value', { value: v.minValue.$params.min }) }}
      </p>

      <p
        class="input--error"
        v-else-if="'numeric' in v && v.numeric.$invalid === true"
      >
        {{ t('errors.input_number_not_numeric') }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, toRefs } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { isUndefined as _isUndefined, isNull as _isNull } from 'lodash'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  id: String,
  placeholder: { type: String, default: '' },
  modelValue: { type: [Number, String], required: true },
  min: [Number, String],
  max: [Number, String],
  disabled: { type: Boolean, default: false },
  v: Object
})

const { t } = useI18n()

const timeout = ref()

const { id, placeholder, modelValue, min, max, v } = toRefs(props)

const model = computed({
  get: () => modelValue.value,
  set: number => {
    clearTimeout(timeout.value)

    timeout.value = setTimeout(() => {
      if (incrementDisabled.value) emit('update:modelValue', parseFloat(max.value))
      if (decrementDisabled.value) emit('update:modelValue', parseFloat(min.value))
    }, 500)

    emit('update:modelValue', number)
  }
})

const incrementDisabled = computed(() => (!_isUndefined(max.value) || !_isNull(max.value)) && parseFloat(model.value) >= parseFloat(max.value))
const decrementDisabled = computed(() => (!_isUndefined(min.value) || !_isNull(min.value)) && parseFloat(model.value) <= parseFloat(min.value))

const handleClickIncrement = () => model.value++
const handleClickDecrement = () => model.value--

const handleBlur = () => {
  if (v.value) v.value.$touch()
}
</script>

<style lang="scss" scoped>
.input {
  background-color: transparent;
  text-align: center;
  padding: 9px 0;
  width: 50px;

  &:disabled {
    cursor: inherit;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &__flex {
    -moz-appearance: textfield;
    display: flex;
    align-items: center;
    border: 1px solid rgba(#2E293D, .1);
    border-radius: 4px;
  }
}

.button {
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color--primary;
  padding: 18px;
  cursor: pointer;

  &:disabled {
    color: $black;
    cursor: inherit;
  }

  &__down {
    padding-right: 9px;
  }

  &__up {
    padding-left: 9px;
  }
}
</style>
