<template>
  <footer v-if="section">
    <section class="footer">
      <div class="footer__container container">
        <section class="footer__column col-12 col-sm-6 col-md-4">
          <h3
            class="footer__title"
            v-if="$i18n.translate(column1.title)"
          >
            {{ $i18n.translate(column1.title) }}
          </h3>

          <ul v-if="column1.websites.length > 0">
            <li
              v-for="({ item }, index) in column1.websites"
              class="footer__li"
              :key="'footer_websites_' + index"
            >
              <app-link
                :link="item"
                class="footer__a footer__text"
              />
            </li>
          </ul>

          <ul v-if="column1.iban.length > 0">
            <li
              v-for="({ item }, index) in column1.iban"
              class="footer__li"
              :key="'footer_iban_' + index"
            >
              <span class="footer__text">
                {{ $i18n.translate(item) }}
              </span>
            </li>
          </ul>
        </section>

        <section class="footer__column col-12 col-sm-6 col-md-4">
          <h3
            class="footer__title"
            v-if="$i18n.translate(column2.title)"
          >
            {{ $i18n.translate(column2.title) }}
          </h3>

          <ul v-if="column2.address.length > 0">
            <li
              v-for="({ item }, index) in column2.address"
              class="footer__li"
              :key="'footer_address_line_' + index"
            >
              <span class="footer__text">
                {{ $i18n.translate(item) }}
              </span>
            </li>
          </ul>

          <ul v-if="column2.emails.length > 0">
            <li
              v-for="({ item }, index) in column2.emails"
              class="footer__li"
              :key="'footer_emails_line_' + index"
            >
              <a
                class="footer__text footer__a"
                :href="'mailto:' + $i18n.translate(item)"
              >
                {{ $i18n.translate(item) }}
              </a>
            </li>
          </ul>

          <ul v-if="column2.emails.length > 0">
            <li
              v-for="({ item }, index) in column2.phones"
              class="footer__li"
              :key="'footer_phones_line_' + index"
            >
              <a
                class="footer__text footer__a"
                :href="'tel:' + $i18n.translate(item)"
              >
                {{ $i18n.translate(item) }}
              </a>
            </li>
          </ul>
        </section>

        <section class="footer__column col-12 col-sm-6 col-md-4">
          <h3
            class="footer__title"
            v-if="$i18n.translate(column3.title)"
          >
            {{ $i18n.translate(column3.title) }}
          </h3>

          <div
            class="social_networks"
            v-if="socialNetworks.length > 0"
          >
            <ul class="networks__ul">
              <li
                class="networks__li"
                v-for="(network, index) in socialNetworks"
                :key="'footer_menu_network_' + index"
              >
                <a
                  class="networks__a"
                  :href="$i18n.translate(network.url)"
                  target="_blank"
                >
                  <fa-icon
                    v-if="network.icon"
                    size="lg"
                    :icon="network.icon"
                  />
                </a>
              </li>
            </ul>
          </div>

          <ul
            class="margin__t--48"
            v-if="column3.actions.length > 0"
          >
            <li
              v-for="({ item }, index) in column3.actions"
              class="footer__li"
              :key="'footer_action_' + index"
            >
              <app-link
                :link="item"
                class="footer__button footer__text"
              />
            </li>
          </ul>
        </section>
      </div>
    </section>
  </footer>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'

export default defineComponent({
  name: 'CustomFooterRodeKruisVlaanderen',
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  components: {
    AppLink: defineAsyncComponent(() => import('&/atoms/AppLink'))
  },
  computed: {
    ...mapGetters({ client: 'client/client' }),
    column1 () {
      return _get(this.section, 'attributes.column_1', {})
    },
    column2 () {
      return _get(this.section, 'attributes.column_2', {})
    },
    column3 () {
      return _get(this.section, 'attributes.column_3', {})
    },
    socialNetworks () {
      const links = _get(this.client, 'platform.social_networks') || []
      return links.length > 0 ? links.filter(link => this.$i18n.translate(link.url)) : []
    }
  }
})
</script>

<style lang="scss" scoped>
.footer {
  background-color: #1F1F1F;
  padding: $padding__base * 4;
  font-family: $font-family--secondary;
  color: #ffffff;

  &__container {
    @include mq(sm) {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }

  &__column {
    margin-left: 0;
    text-align: center;
    margin-bottom: $margin__base * 4;

    @include mq(sm) {
      width: 50%;
      text-align: left;
      margin-bottom: 0;
    }

    @include mq(md) {
      width: auto;
    }
  }

  &__title {
    font-size: 22px;
    text-transform: uppercase;
    color: #E3E3E3;
    font-weight: bold;
    margin-bottom: $margin__base * 2;
  }

  &__text {
    font-size: 18px;
    color: $white;
  }

  &__li {
    margin-top: $margin--base;
  }

  :deep(.footer__a) {
    transition: all .3s;
    color: $white;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }

  :deep(.footer__button) {
    border-radius: $radius__buttons;
    transition: all $transition__duration;
    background-color: $color--secondary;
    padding: 12px 18px;
    font-size: 14px;
    text-transform: uppercase;
    display: inline-block;

    &:hover, &:focus {
      background-color: shade($color--secondary);
    }
  }

  .networks {
    &__ul {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: $margin--base * 2;
      justify-items: center;
      align-items: center;
      max-width: 140px;
      margin: auto;

      @include mq(sm) {
        margin: inherit;
      }
    }

    &__a {
      color: $black;
      transition: all .3s;
      font-family: $font-family--secondary;
      color: #ffffff;
      font-weight: bold;

      &:hover, &:focus {
        color: $color--secondary;
      }
    }
  }
}
</style>
