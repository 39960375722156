<template>
  <form
    class="search"
    :action="translate(search.url)"
    v-if="translate(search.url) && translate(search.query)"
  >
    <fieldset class="search__container">
      <label
        for="search"
        class="sr-only"
      >
        {{ translate(search.label) || 'Search' }}
      </label>

      <input
        id="search"
        type="search"
        class="search__input"
        :name="translate(search.query)"
        :placeholder="translate(search.placeholder)"
      >

      <button
        type="submit"
        class="search__btn"
      >
        <fa-icon
          aria-hidden="true"
          :icon="['fal', 'magnifying-glass']"
          fixed-width
        />
      </button>
    </fieldset>
  </form>
</template>

<script setup>
import { useI18n } from '@/vendors/i18n'

defineProps({
  search: { type: Object, default: () => ({}) }
})

const { translate } = useI18n()
</script>

<style lang="scss" scoped>
.search {
  position: relative;
  width: 100%;

  &__container {
    position: relative;
  }

  &__input {
    border-radius: 5px;
    border: 0;
    background: rgba(#3222bf, 0.1);
    display: block;
    padding: 9px 15px;
    width: 100%;
    line-height: 25.6px;
    font-size: 16px;
    outline-color: #005fcb;
    outline-width: 0.5px;
    color: #141414;
  }

  &__btn {
    position: absolute;
    padding: 0 13px;
    height: 100%;
    top: 0;
    right: 0;
    line-height: 1;
    font-size: rem(18px);
    color: #3122bf;
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
}
</style>