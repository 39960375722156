<template>
  <div>
    <div
      class="radio__list"
      v-for="element in data"
      :key="element.value"
      v-bind="$attrs"
    >
      <label
        class="color--lg radio__label"
        :class="{ 'radio--selected': isSelected(element.value) }"
      >
        <div class="radio__container">
          <input
            type="radio"
            class="input radio__element"
            v-model="radio"
            :value="element.value"
            :checked="isSelected(element.value)"
            @blur="blurIfMandatory"
          >

          <fa-icon
            class="radio__icon"
            :icon="isSelected(element.value) ? ['fal', 'check-circle'] : ['fal', 'circle']"
          />

          {{ element.label }}
        </div>

        <div
          class="radio__images"
          v-if="element.images && element.images.length > 0"
        >
          <img
            class="radio__image"
            :src="image"
            alt="image"
            v-for="image in element.images"
            :key="image"
          >
        </div>
      </label>
    </div>

    <div
      class="input--errors margin__t--6"
      v-if="v && v.$error"
    >
      <p
        class="input--error"
        v-if="v.required && v.required.$invalid"
      >
        {{ $i18n.t('errors.input_radio_required') }}
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InputRadio',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String
    },
    data: {
      type: [Array, Object],
      required: true
    },
    v: {
      type: Object
    }
  },
  computed: {
    radio: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    blurIfMandatory () {
      if (this.v) {
        this.v.$touch()
      }
    },
    isSelected (value) {
      return this.modelValue === value
    }
  }
})
</script>

<style lang="scss" scoped>
.radio {
  &__element {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    position: absolute;
    border-radius: 6px;
    cursor: pointer;
  }

  &--selected {
    color: $black;

    .radio__icon {
      color: $color--success;
    }
  }

  &__images {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__label {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__image {
    margin-left: calc($margin__base / 2);
  }

  &__container {
    position: relative;
  }

  &__icon {
    margin-right: calc($margin__base / 2);
  }
}
</style>
