import Action from '@/classes/procedures/Action'

export default class Actions extends Array {
  constructor (actions, ...rest) {
    Array.isArray(actions) ? super(...actions.map(action => new Action(action))) : super(...rest)
  }

  get (name) {
    return this.find(action => action.name === name)
  }

  get transaction () {
    return this.get('transaction')
  }

  get order () {
    return this.get('order')
  }

  get project () {
    return this.get('project')
  }

  get fee () {
    return this.get('fee')
  }

  get accept_invite () {
    return this.get('accept_invite')
  }

  get create_invite () {
    return this.get('create_invite')
  }

  get recaptcha () {
    return this.get('recaptcha')
  }
}
