<template>
  <header
    class="header bg__color--white"
    :class="{ 'is-fixed': showMobileMenu }"
  >
    <div class="container">
      <div class="topbar">
        <router-link
          class="session__user d-flex align-items-center padding__lr--12 padding__tb--6"
          :to="{ name: 'Profile', params: { locale } }"
          v-if="isAuthenticated && user"
        >
          <user-profile-img
            :size="2"
            :avatar="user.avatar"
          />

          <p class="margin__l--6">
            {{ user.firstname }}
          </p>
        </router-link>

        <a
          @click.prevent="toggleSessionModal('login')"
          class="session__login padding__lr--12 padding__tb--6"
          v-else
        >
          {{ t('literal.login') }}
        </a>

        <language-switcher
          class="languages"
          v-slot="{ options, updateLanguage, isActive }"
        >
          <ul class="languages d-flex align-items-center">
            <li
              class="languages__li"
              v-for="language in options"
              :key="language.id"
            >
              <button
                class="languages__btn"
                :class="{ 'is-active': isActive(language.id) }"
                type="button"
                @click.prevent="updateLanguage(language.id)"
              >
                <template v-if="isPhone">
                  {{ language.label.value.toUpperCase() }}
                </template>

                <template v-else>
                  {{ language.label.name }}
                </template>
              </button>
            </li>
          </ul>
        </language-switcher>
      </div>

      <div class="d-flex align-items-center justify-content-between padding--12">
        <router-link
          :to="{ name: 'Home', params: { locale } }"
          class="margin__r--12"
          v-if="translate(logo) || client.logo.url"
        >
          <img
            class="logo__img"
            :src="translate(logo)"
            :alt="client.name"
            v-if="translate(logo)"
          >

          <img
            class="logo__img"
            :src="client.logo.url"
            :alt="client.name"
            v-else
          >
        </router-link>

        <search-field
          class="d-none d-md-flex"
          :placeholder="translate(search)"
          @search-query="handleSearch($event)"
          v-if="isSearch"
        />

        <app-burger-menu
          class="burger d-md-none no-padding--r"
          :state="showMobileMenu"
          @toggle="toggleMobileMenu"
        />
      </div>

      <nav
        class="d-md-flex justify-content-between align-items-md-center menus bg__color--white"
        :class="{ 'd-flex': showMobileMenu, 'd-none': !showMobileMenu }"
      >
        <ul class="navigation d-md-flex align-items-center">
          <li
            class="navigation__li"
            v-for="({ link }, key) in links"
            :key="`link_${key}`"
          >
            <app-link
              :link="link"
              class="navigation__link link__color--prim-black link--naked"
            />
          </li>
        </ul>

        <ul class="actions d-md-flex">
          <li
            v-if="isSearch"
            class="d-md-none actions__li"
          >
            <search-field
              class="d-inline-flex"
              :placeholder="translate(search)"
              @search-query="handleSearch($event)"
            />
          </li>

          <li
            class="actions__li"
            v-for="({ link, color }, key) in actions"
            :key="`action_${key}`"
          >
            <app-link
              :link="link"
              :class="'actions__btn btn btn__size--classic btn__color--' + color"
            />
          </li>
        </ul>
      </nav>
    </div>

    <app-modal
      v-if="showSessionModal"
      size="sm"
      @close="showSessionModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="sessionTemplate"
          @authenticated="showSessionModal = false"
        />
      </template>
    </app-modal>
  </header>
</template>

<script setup>
import { computed, defineAsyncComponent, ref, watch } from 'vue'
import { mapGetters } from '@/store/map-state'
import { useLanguageStore } from '@/stores/LanguageStore'
import { get as _get } from 'lodash'
import { useI18n } from '@/vendors/i18n'
import { scrollTo } from '@/utils/utils'
import { parseToQueryParams } from '@/utils/query'
import { useRouter, useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'

import isMobile from 'is-mobile'
import LanguageSwitcher from '&/atoms/LanguageSwitcher'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const AppBurgerMenu = defineAsyncComponent(() => import('&/atoms/AppBurgerMenu'))
const UserProfileImg = defineAsyncComponent(() => import('&/atoms/UserProfileImg'))
const SearchField = defineAsyncComponent(() => import('&/molecules/SearchField'))
const AppModal = defineAsyncComponent(() => import('&/organisms/AppModal'))
const SessionForms = defineAsyncComponent(() => import('&/modules/sessions/SessionForms'))

const props = defineProps({
  section: { type: Object, required: true }
})

const { t, translate } = useI18n()
const { locale } = storeToRefs(useLanguageStore())
const router = useRouter()
const route = useRoute()
const { client } = mapGetters('client')
const { isAuthenticated, user } = mapGetters('auth')

const showSessionModal = ref(false)
const showMobileMenu = ref(false)
const sessionTemplate = ref('login')
const logo = computed(() => _get(props, 'section.attributes.logo', []))
const links = computed(() => _get(props, 'section.attributes.links', []))
const actions = computed(() => _get(props, 'section.attributes.actions', []))
const search = computed(() => _get(props, 'section.attributes.search'))
const isSearch = computed(() => _get(props, 'section.attributes.is_search', []))
const isPhone = computed(() => isMobile({ tablet: false }))

watch(route, () => showMobileMenu.value = false)

const toggleSessionModal = template => {
  sessionTemplate.value = template
  showSessionModal.value = true
}

const handleSearch = search => {
  if (search != null) router.push({ path: `/${locale.value}/projects`, query: parseToQueryParams([{}, { search }]) })
}

const toggleMobileMenu = () => {
  scrollTo()

  setTimeout(() => showMobileMenu.value = !showMobileMenu.value, 100)
}
</script>

<style lang="scss" scoped>
.header {
  &.is-fixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    height: 100vh;
  }
}
.topbar {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $vlg;
}

.languages {
  &__btn {
    color: $vlg;
    transition: all .2s;
    padding: calc($padding__base / 2);
    font-size: rem(13px);

    @include mq(sm) {
      padding: calc($padding__base / 2) $padding__base;
    }

    &.is-active {
      color: $black;
    }

    &:hover {
      color: $black;
      background-color: $dw;
    }
  }
}

.logo {
  &__img {
    width: auto;
    max-width: 100%;
    max-height: 50px;

    @include mq(md) {
      max-height: 60px;
    }
  }
}

.search.search {
  padding: 0;

  @include mq(md) {
    max-width: 210px;
  }

  &:deep {
    .search {
      &__input {
        border-radius: 0;
        font-size: rem(13px);
      }

      &__btn {
        background: $black;
        color: $white;
      }
    }
  }
}

.burger {
  :deep(.menu__label) {
    margin-right: calc($margin__base / 2);
    font-size: rem(12px);
  }
}

.menus {
  text-align: center;
  overflow-y: auto;
  height: calc(100vh - 111px);
  flex-direction: column;
  scrollbar-width: none;

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
     display: none;
  }

  @include mq(md) {
    text-align: left;
    flex-direction: row;
    height: auto;
    overflow: hidden;
    padding-bottom: $padding__base;
  }
}

.navigation {
  flex: 1 1 0;
  font-size: rem(20px);
  line-height: 1;
  text-align: center;
  padding: $padding__base * 2;
  min-width: 0;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;

  @include mq(md) {
    font-size: rem(18px);
  }

  @include mq(lg) {
    font-size: rem(18px);
  }

  @include mq(xl) {
     font-size: rem(20px);
  }

  &::-webkit-scrollbar {
     display: none;
  }

  @include mq(md) {
    text-align: left;
    padding: 0;
    overflow-x: auto;
    font-size: rem(18px);
  }

  @include mq(lg) {
    font-size: rem(20px);
  }

  &__li {
    white-space: nowrap;

    &:not(:last-child) {
      border: 0px solid $vlg;
      border-bottom-width: 1px;

      @include mq(md) {
        border-bottom-width: 0;
        border-right-width: 1px;
      }
    }
  }

  :deep(.navigation__link) {
    display: block;
    padding: $padding__base;
    line-height: 1;

    @include mq(md) {
      padding: calc($padding__base / 2) $padding__base;
    }

    &.is-active {
      color: $black;
    }
  }
}

.actions {
  padding: $padding__base * 2;
  flex: 0 0 auto;
  position: relative;

  @include mq(md) {
    padding: 0;
    margin-left: $margin__base;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -48px;
      top: 0;
      width: 48px;
      height: 100%;
      background: $white;
      background: linear-gradient(-90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    }
  }

  &__li {
    padding: $padding__base;

    @include mq(md) {
      padding: 0;

      &:not(:last-child) {
        margin-right: $margin__base;
      }
    }
  }

  :deep(.actions__btn) {
    width: 100%;
    display: block;

    @include mq(md) {
      width: auto;
    }
  }
}
.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}
</style>
