<template>
  <div>
    <input-checkbox
      v-model="model"
      :v="validator"
      v-if="translate(label)"
    >
      <div class="d-flex">
        <span
          class="editor editor__content"
          v-html="translate(label)"
        />

        <span
          class="margin__l--6"
          v-if="block.is_required"
        >
          *
        </span>
      </div>
    </input-checkbox>

    <p
      class="additional-text"
      v-if="translate(text)"
    >
      {{ translate(text) }}
    </p>
  </div>
</template>

<script setup>
import { computed, inject, toRefs } from 'vue'
import { useModule } from '@/composables/app/useModule'
import { useProcedureBlockValidator } from '@/composables/modules/procedures'
import { useI18n } from '@/vendors/i18n'
import { get as _get, isUndefined as _isUndefined } from 'lodash'

import InputCheckbox from '&/atoms/InputCheckbox'

const props = defineProps({ block: { type: Object, required: true } })

const { block } = toRefs(props)

const procedure_execution = inject('procedure_execution')

const { translate } = useI18n()
const { label, model, attributes, defaultValue } = useModule({ component: block, parent: procedure_execution, default_value: false })
const { validator } = useProcedureBlockValidator({ validations: block.value.validations, state: model })

const text = computed(() => _get(attributes.value, 'text', {}))

if (typeof model.value !== 'boolean' && !_isUndefined(defaultValue.value)) model.value = defaultValue.value
</script>

<style lang="scss" scoped>
.additional-text {
  color: $lg;
  margin-top: 4px;
  font-size: em(12px);
  white-space: pre-line;
  margin-left: 28px;
}
</style>
