<template>
  <ul
    class="nav"
    v-if="links.length"
  >
    <li
      class="nav__item"
      v-for="({ link, links: items }, key) in links"
      :key="`nav_item_${key}`"
    >
      <span>
        <app-link
          class="nav__a"
          :link="link"
          target="_self"
        />

        <fa-icon
          class="nav__chevron"
          :icon="['fas', 'chevron-down']"
          v-if="items.length"
        />
      </span>

      <template v-if="items.length">
        <div
          class="mega-menu nav__submenu"
          v-if="isMegaMenu(items)"
        >
          <div class="container mega-menu__container">
            <div class="mega-menu__header">
              <app-link
                class="mega-menu__title"
                :link="link"
                target="_self"
              />

              <app-link
                class="mega-menu__btn"
                :link="link"
                target="_self"
              />
            </div>

            <ul
              class="mega-menu__columns"
              v-if="items.length"
            >
              <li
                v-for="(item, k) in items"
                :key="`nav_item_${key}_${k}`"
              >
                <app-link
                  class="mega-menu__sub_title"
                  :link="item.link"
                  target="_self"
                  v-slot="{ text }"
                >
                  {{ text }}

                  <fa-icon
                    class="mega-menu__arrow"
                    :icon="['fal', 'long-arrow-right']"
                    fixed-width
                  />
                </app-link>

                <ul
                  class="mega-menu__items"
                  v-if="item.links?.length"
                >
                  <li
                    class="mega-menu__item"
                    v-for="(sub_item, i) in item.links"
                    :key="`nav_item_${key}_${k}_${i}`"
                  >
                    <app-link
                      class="mega-menu__a"
                      :link="sub_item.link"
                      target="_self"
                      v-slot="{ text }"
                    >
                      {{ text }}

                      <fa-icon
                        class="mega-menu__arrow"
                        :icon="['fal', 'long-arrow-right']"
                        fixed-width
                      />
                    </app-link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <ul
          class="submenu nav__submenu"
          v-else
        >
          <li
            class="submenu__item"
            v-for="(item, k) in items"
            :key="`nav_item_${key}_${k}`"
          >
            <app-link
              class="submenu__a"
              :link="item.link"
              target="_self"
              v-slot="{ text }"
            >
              {{ text }}

              <fa-icon
                class="mega-menu__arrow"
                :icon="['fal', 'long-arrow-right']"
                fixed-width
              />
            </app-link>
          </li>
        </ul>
      </template>
    </li>
  </ul>
</template>

<script setup>
import AppLink from '&/atoms/AppLink'

defineProps({
  links: { type: Array, default: () => [] }
})

const isMegaMenu = links => links.some(({ links }) => !!links.length)
</script>

<style lang="scss" scoped>
$black: #141415;
$color--primary: #3122bf;
$color--tertiary: #dbd8f8;


.nav {
  gap: 20px;

  @media (min-width: 1250px) {
    gap: 30px;
  }

  &:not(:last-child) {
    padding-right: 30px;
  }

  &__chevron {
    color: $color--primary;
    margin-left: 8px;
    transition: all .3s ease-in-out;
    font-size: rem(16px);

    @media (min-width: 1250px) {
      font-size: rem(18px);
    }
  }

  &:deep(.nav__a) {
    color: $black;
    text-decoration: underline;
    transition: all .3s ease-in-out;
    text-decoration-color: transparent;
    text-decoration-thickness: 2px;
    font-weight: 600;

    &.is-active,
    &:hover {
      text-decoration-color: currentColor;
    }
  }

  &__submenu {
    max-height: 0px;
    opacity: 0;
    overflow: auto;
    transition: all .3s ease-in-out;
    background: transparent;
    top: 90%;
    z-index: -2;
  }

  &__item {
    &.is-active,
    &:hover {
      .nav {
        &__chevron {
          transform: rotate(180deg);
        }

        &__submenu {
          opacity: 1;
          max-height: calc(100vh - 115px);
          top: 100%;
        }
      }
    }
  }
}

.mega-menu {
  background: $color--tertiary;
  position: absolute;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);

  &:deep(a) {
    text-decoration: underline;
    transition: all .3s ease-in-out;
    text-decoration-color: transparent;
    text-decoration-thickness: 2px;

    &.is-active,
    &:hover {
      text-decoration-color: currentColor;
    }
  }

  &__container {
    padding-top: 45px;
    padding-bottom: 30px;
  }

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &::before {
      content: '';
      background: rgba($color--primary, 0.2);
      width: 100%;
      height: 1px;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
    }
  }

  &__columns {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 30px;
    gap: 45px;
  }

  &__arrow {
    font-size: rem(18px);
    color: $color--primary;
  }

  &__items {
    border-top: 1px solid rgba($color--primary, 0.2);
    padding: 15px 0;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &:deep() {
   .mega-menu {
      &__title {
        font-size: rem(32px);
        font-weight: 500;
        background: $color--tertiary;
        color: $color--primary;
        position: relative;
        z-index: 10;
        padding-right: 30px;
      }

      &__btn {
        position: relative;
        z-index: 10;
        color: $color--primary;
        background: $color--tertiary;
        padding: 8px 15px;
        border: 1px solid $color--primary;
        border-radius: 5px;
        line-height: 25.6px;
        font-weight: 500;
        text-decoration: underline;
        text-decoration-color: transparent;
        text-decoration-thickness: 2px;
        transition: text-decoration-color .3s ease-in-out;
        flex: 0 0 auto;

        &.is-active,
        &:hover {
          text-decoration-color: $color--primary;
        }
      }

      &__sub_title {
        color: $color--primary;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10.5px 0;
        font-weight: 600;
        line-height: 28.8px;
      }

      &__a {
        color: $black;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.submenu {
  position: absolute;
  background: $color--tertiary;
  max-width: 220px;

  &__item {
    padding: 7.5px 20px;
  }

  :deep(.submenu__a) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    line-height: 25.6px;
  }
}
</style>