<template>
  <node-view-wrapper
    :class="className"
    :style="style"
    as="a"
    @click.prevent.stop="handleClick"
  >
    {{ text }}
  </node-view-wrapper>
</template>

<script setup>
import { computed } from 'vue'
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3'
import { NodeSelection } from 'prosemirror-state'
import { get as _get } from 'lodash'

const props = defineProps(nodeViewProps)

const toggle = computed(() => _get(props, 'extension.options.toggle'))
const view = computed(() => _get(props, 'editor.view'))
const state = computed(() => _get(props, 'editor.state'))
const attrs = computed(() => _get(props, 'node.attrs'))

const text = computed(() => _get(attrs.value, 'text') || _get(attrs.value, 'href'))
const type = computed(() => _get(attrs.value, 'type', 'link'))
const textColor = computed(() => _get(attrs.value, 'textColor'))
const bgColor = computed(() => _get(attrs.value, 'bgColor'))

const className = computed(() => type.value === 'button' ? 'btn btn__size--classic' : '')
const style = computed(() => type.value === 'button' ? { color: textColor.value, backgroundColor: bgColor.value } : { color: textColor.value })

const handleClick = () => {
  const tr = state.value.tr
  const selection = NodeSelection.create(state.value.doc, props.getPos())

  view.value.dispatch(tr.setSelection(selection))

  toggle.value(attrs.value)
}
</script>