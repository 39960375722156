<template>
  <button
    class="d-inline-flex align-items-center"
    :aria-label="t('literal.resend_an_email_to_validate_my_account')"
    :disabled="loader"
    @click.prevent.stop="handleClick()"
  >
    <slot
      name="default"
      v-bind="{ loader }"
    >
      <app-spinner
        class="margin__r--6"
        size="2"
        v-if="loader"
      />

      {{ t('literal.resend_email') }}
    </slot>
  </button>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useI18n } from '@/vendors/i18n'
import { useUserStore } from '@/stores/UserStore'
import { useNotificationStore } from '@/stores/NotificationStore'
import { get as _get } from 'lodash'

const AppSpinner = defineAsyncComponent(() => import('&/atoms/AppSpinner'))

const { fullPath } = useRoute()
const { t } = useI18n()
const { postSendVerificationEmail } = useUserStore()
const { loader } = storeToRefs(useUserStore())
const { postSideNotification } = useNotificationStore()

const handleClick = () => {
  postSendVerificationEmail({ params: { return_url: fullPath } })
    .then(() => postSideNotification({ text: 'literal.the_verification_email_has_been_sent' }))
    .catch(errors => _get(errors, 'data.errors', []).forEach(error => postSideNotification({ text: `errors.${error}`, type: 'error', delay: 0 })))
}
</script>