let files = []
const requireModule = require.context('@/configurations/pages', true, /\.js$/i, 'sync')

requireModule.keys().forEach(fileName => {
  if (fileName === './index.js') return
  files = [
    ...files,
    ...requireModule(fileName).default
  ]
})

export default files
