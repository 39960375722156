import { createStore } from 'vuex'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import client from './modules/client'
import procedures from './modules/procedures'
import auth from './modules/auth'
import user from './modules/user'
import projects from './modules/projects'
import campaigns from './modules/campaigns'
import products from './modules/products'
import secure from './modules/secure'
import benefitings from './modules/benefitings'

export default createStore({
  state: {
    is_router_error: false
  },
  getters,
  actions,
  mutations,
  modules: {
    client,
    procedures,
    auth,
    user,
    projects,
    campaigns,
    products,
    secure,
    benefitings
  }
})
