import { inject } from 'vue'
import { useClientStore } from '@/stores/ClientStore'
import { get as _get } from 'lodash'
import { loadStripe } from '@stripe/stripe-js'
import i18n from '@/vendors/i18n'

class Stripe {
  constructor () {
    this.id = null
    this.options = {}
    this.stripe = null
    this.elements = null
  }

  init (id = null) {
    const { getClient } = useClientStore()
    const { client } = getClient()

    const account_id = _get(client.value, 'integrations.stripe.account_id')

    this.id = id
    this.options = account_id ? { stripeAccount: account_id } : {}

    return this
  }

  loader () {
    return new Promise((resolve, reject) => {
      if (!this.id) return reject()
      if (this.stripe) return resolve(this.stripe)

      loadStripe(this.id, this.options)
        .then(stripe => {
          this.stripe = stripe

          this.elements = this.stripe.elements({
            fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Lato:300,400' }],
            locale: i18n.locale
          })

          resolve(this.stripe)
        })
        .catch(error => reject(error))
    })
  }

  getElements () {
    return _get(this.elements, '_elements', [])
  }

  getElement (type) {
    return this.elements?.getElement(type)
  }

  createElement (type, options = {}) {
    return this.elements?.create(type, options)
  }

  clearElements () {
    this.getElements().forEach(element => element.clear())
  }

  createPaymentMethod (params) {
    return new Promise((resolve, reject) => {
      this.loader()
        .then(stripe => {
          stripe.createPaymentMethod(params)
            .then(result => result.error ? reject(result.error.code) : resolve(result.paymentMethod.id))
            .catch(() => reject('errors.an_error_occurred_while_processing_your_card_try_again_in_a_little_bit_transaction'))
        })
        .catch(error => reject(error))
    })
  }
}

const stripeSymbol = Symbol()
const stripe = new Stripe()

export const createStripe = {
  install: (app, { id } = {}) => app.provide(stripeSymbol, stripe.init(id))
}

export function useStripe () {
  return inject(stripeSymbol)
}

export default stripe

