<template>
  <footer>
    <div class="wrapper">
      <div class="block">
        <router-link
          :to="{ name: 'Home', params: { locale: $i18n.locale } }"
          v-if="$i18n.translate(column1.logo_src)"
          class="logo"
        >
          <img
            :src="$i18n.translate(column1.logo_src)"
            :alt="client.name"
          >
        </router-link>

        <ul
          class="networks"
          v-if="networks.length > 0"
        >
          <li
            class="networks__el"
            v-for="(network, i) in networks"
            :key="'footer_menu_network_' + i"
          >
            <a
              class="link__color--white-prim link--naked bold"
              :href="$i18n.translate(network.url)"
              target="_blank"
            >
              <span
                class="networks__span"
                v-if="network.text"
              >
                {{ $i18n.translate(network.text) }}
              </span>
              <fa-icon
                v-if="network.icon"
                size="1x"
                :icon="network.icon"
              />
            </a>
          </li>
        </ul>
      </div>

      <div class="block">
        <h2 class="block__title">
          {{ $i18n.translate(column2.title) }}
        </h2>

        <ul class="block__list">
          <li
            v-for="({ link }, index) in navigation"
            :key="'footer_menu_nav_' + index"
          >
            <button
              @click.prevent="setRouteNavigation(link)"
              :class="{'link__color--prim': isVisited(link)}"
            >
              {{ $i18n.translate(link.text) }}
            </button>
          </li>
        </ul>
      </div>

      <div class="block">
        <h2 class="block__title">
          {{ $i18n.translate(column3.title) }}
        </h2>

        <ul class="block__list">
          <li
            v-for="({ link }, index) in actions"
            :key="'footer_menu_actions_' + index"
          >
            <button
              @click.prevent="setRouteNavigation(link)"
              :class="{'link__color--prim': isVisited(link)}"
            >
              {{ $i18n.translate(link.text) }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomFooterCNCD',
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client'
    }),
    column1 () {
      return _get(this.section, 'attributes.column_1', {})
    },
    column2 () {
      return _get(this.section, 'attributes.column_2', {})
    },
    navigation () {
      return _get(this.column2, 'navigation', [])
    },
    column3 () {
      return _get(this.section, 'attributes.column_3', {})
    },
    actions () {
      return _get(this.column3, 'actions', [])
    },
    networks () {
      const links = _get(this.client, 'platform.social_networks', [])
      return links.filter(link => this.$i18n.translate(link.url))
    }
  }
})
</script>

<style lang="scss" scoped>
$color-primary: #76b729;
$color-secondary: #ffa500;

footer {
  background-color: #000;
  color: $white;
}

.wrapper {
  padding: $padding__base * 5 $padding__base * 2;
  max-width: 1200px;
  margin: auto;

  @include mq(sm) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: $padding__base * 5 $padding__base * 4;
  }
}

.block {
  &:not(:last-child){
    margin-bottom: $margin__base * 3;
  }

  &__title {
    font-family: $font-family--secondary;
    font-size: 1.5rem;
    margin-bottom: $margin__base;

    @include mq(sm) {
      font-size: 2.25rem;
    }
  }

  &__list li{
    transition: color $transition__duration;
    &:hover, &:focus {
      color: $color-primary;
    }
  }
}

.logo {
  display: block;
  height: 65px;

  & img {
    height: 100%;
  }
}

.networks {
  display: flex;
  margin-top: $margin__base * 4;

  &__el {
    overflow: hidden;
    position: relative;

    &:not(:first-of-type){
      margin-left: $margin__base * 2;
    }
  }

  &__span {
    position: absolute;
    left: 0;
    overflow: hidden;
    width: 0;
  }
}
</style>
