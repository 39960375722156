<template>
  <header class="header bg__color--white">
    <div class="container header__wrapper">
      <template v-if="translate(logo.image) || client.logo.url">
        <a
          :href="translate(logo.url)"
          class="logo"
          v-if="translate(logo.url)"
        >
          <img
            class="logo__img"
            :src="translate(logo.image) || client.logo.url"
            :alt="client.name"
          >
        </a>

        <router-link
          :to="{ name: 'Home', params: { locale } }"
          class="logo"
          v-else
        >
          <img
            class="logo__img"
            :src="translate(logo.image) || client.logo.url"
            :alt="client.name"
          >
        </router-link>
      </template>

      <div class="header__actions">
        <router-link
          class="session__user d-inline-flex align-items-center link__color--prim link--naked"
          :to="{ name: 'Profile', params: { locale } }"
          v-if="isAuthenticated && user"
        >
          <user-profile-img
            :size="2"
            :avatar="user.avatar"
          />

          <p class="margin__l--6">
            {{ user.firstname }}
          </p>
        </router-link>

        <button
          @click.prevent="toggleSessionModal('login')"
          class="session__login link__color--prim link--naked"
          v-else
        >
          {{ t('literal.login') }}
        </button>
      </div>
    </div>

    <app-modal
      v-if="showSessionModal"
      size="sm"
      @close="showSessionModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="sessionTemplate"
          @authenticated="showSessionModal = false"
        />
      </template>
    </app-modal>
  </header>
</template>

<script setup>
import { computed, defineAsyncComponent, ref } from 'vue'
import { mapGetters } from '@/store/map-state'
import { get as _get } from 'lodash'
import { useI18n } from '@/vendors/i18n'

const AppModal = defineAsyncComponent(() => import('&/organisms/AppModal'))
const SessionForms = defineAsyncComponent(() => import('&/modules/sessions/SessionForms'))
const UserProfileImg = defineAsyncComponent(() => import('&/atoms/UserProfileImg'))

const props = defineProps({
  section: { type: Object, required: true }
})

const { getLocale, t, translate } = useI18n()
const { client } = mapGetters('client')
const { isAuthenticated, user } = mapGetters('auth')

const showSessionModal = ref(false)
const sessionTemplate = ref('login')
const locale = computed(() => getLocale())
const logo = computed(() => _get(props, 'section.attributes.logo', {}))

const toggleSessionModal = template => {
  sessionTemplate.value = template
  showSessionModal.value = true
}
</script>

<style lang="scss" scoped>
.header {
  padding: $padding__base *   2.5 0 $padding__base * 1.5;

  &__wrapper {
    text-align: center;

    @include mq(sm) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
    }
  }

  &__actions {
    margin-top: $margin__base;

    @include mq(sm) {
      margin: 0;
    }
  }
}

.logo {
  display: block;

  &__img {
    max-width: 211px;
    height: auto;
  }
}

.session {
  &__login,
  &__user {
    text-transform: uppercase;
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}
</style>
