<template>
  <footer
    v-if="section"
    class="footer"
  >
    <div class="wrapper footer__wrapper">
      <section class="footer__content footer__columns">
        <article>
          <h3 class="newsletter__title">
            {{ $i18n.translate(newsletter.title) }}
          </h3>

          <p>{{ $i18n.translate(newsletter.text) }}</p>

          <a
            class="newsletter__link translate__x3"
            @click.prevent="setRouteNavigation(newsletter.link)"
            v-if="newsletter.link && newsletter.link.show"
            target="_blank"
          >
            <span class="newsletter__link--text">{{ $i18n.translate(newsletter.link.text) }}</span>

            <fa-icon
              class="newsletter__icon element"
              :icon="['fas', 'chevron-right']"
            />
          </a>

          <h3 class="networks__title">
            {{ $i18n.translate(social.title) }}
          </h3>

          <ul
            class="networks__ul"
            v-if="socialNetworks.length > 0"
          >
            <li
              class="networks__li"
              v-for="(network, i) in socialNetworks"
              :key="'footer_menu_network_' + i"
            >
              <a
                class="networks__a"
                :href="$i18n.translate(network.url)"
                target="_blank"
              >
                <fa-icon
                  v-if="network.icon"
                  size="lg"
                  class="networks__icon"
                  :icon="network.icon"
                />
              </a>
            </li>
          </ul>

          <small class="networks__disclaimer">{{ $i18n.translate(social.description) }}</small>
        </article>

        <article v-if="navigation.links.length">
          <nav class="footer__nav">
            <ul class="footer__ul">
              <li
                class="footer__li"
                v-for="({ link }, index) in navigation.links"
                :key="`footer_menu_item_${index}`"
              >
                <a
                  class="footer__link"
                  v-if="link && link.show && $i18n.translate(link.text)"
                  @click.prevent="setRouteNavigation(link)"
                >
                  {{ $i18n.translate(link.text) }}
                </a>
              </li>
            </ul>
          </nav>

          <nav class="footer__nav margin__t--24">
            <ul class="footer__ul">
              <li
                class="footer__li"
                v-for="({ link }, index) in action.links"
                :key="`footer_menu_item_${index}`"
              >
                <a
                  class="footer__button translate__x3"
                  v-if="link && link.show && $i18n.translate(link.text)"
                  @click.prevent="setRouteNavigation(link)"
                >
                  {{ $i18n.translate(link.text) }}

                  <fa-icon
                    class="footer__button--icon element"
                    :icon="['fas', 'chevron-right']"
                  />
                </a>
              </li>
            </ul>
          </nav>
        </article>
      </section>

      <section class="footer__content footer__content--border">
        <img
          class="footer__logo"
          v-if="$i18n.translate(logo.image)"
          :src="$i18n.translate(logo.image)"
        >

        <address
          class="contact__address"
          v-if="$i18n.translate(contact.address)"
        >
          <p>
            {{ $i18n.translate(contact.address) }}
          </p>
        </address>

        <ul class="contact__ul">
          <li
            class="contact__li"
            v-if="$i18n.translate(contact.email)"
          >
            <fa-icon :icon="['fas', 'envelope']" />

            <a
              class="contact__link contact__text"
              :href="`mailto:${ $i18n.translate(contact.email) }`"
              target="_blank"
            >
              {{ $i18n.translate(contact.email) }}
            </a>
          </li>

          <li
            class="contact__li"
            v-if="$i18n.translate(contact.phone)"
          >
            <fa-icon :icon="['fas', 'phone']" />

            <span class="contact__text">
              {{ $i18n.translate(contact.phone) }}
            </span>
          </li>
        </ul>

        <ul class="partners">
          <li
            class="partners__li"
            v-for="({ image }, index) in partners.images"
            :key="`partner_${index}`"
          >
            <img
              class="partners__img"
              v-if="$i18n.translate(image)"
              :src="$i18n.translate(image)"
            >
          </li>
        </ul>
      </section>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'

export default {
  name: 'CustomFooterKikirpa',
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client'
    }),
    logo () {
      return _get(this.section, 'attributes.logo', {})
    },
    social () {
      return _get(this.section, 'attributes.social', {})
    },
    contact () {
      return _get(this.section, 'attributes.contact', {})
    },
    navigation () {
      return _get(this.section, 'attributes.navigation', {})
    },
    action () {
      return _get(this.section, 'attributes.action', {})
    },
    partners () {
      return _get(this.section, 'attributes.partners', {})
    },
    newsletter () {
      return _get(this.section, 'attributes.newsletter', {})
    },
    socialNetworks () {
      const links = _get(this.client, 'platform.social_networks') || []

      return links.length > 0
        ? links.map(link => {
          if (link.icon.includes('facebook-square')) {
            link.icon[1] = 'facebook-f'
          } else if (link.icon.includes('linkedin')) {
            link.icon[1] = 'linkedin-in'
          }

          return link
        })
          .filter(link => link.url[this.$i18n.locale])
        : []
    }
  }
}
</script>

<style lang="scss" scoped>
$color--primary: #B58C4B;
$color--secondary: #B58C4B;

.wrapper {
  max-width: 1310px;
  margin: 0 auto;
  position: relative;
  padding-left: 24px;
  padding-right: 24px;

  @media (min-width: 1120px) {
    padding-left: 84px;
   padding-right: 84px;
  }
}

.footer {
  position: relative;
  background-color: $white;
  border-top: 1px solid #404040;

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    @include mq(sm) {
      grid-template-columns: 1fr auto;
    }
  }

  &__content {
    padding: 50px 24px 50px 0px;

    @media (min-width: 1120px) {
      padding: 50px 84px 50px 0px;
    }

    &--border {
      padding: 12px 0px 50px 0px;

      @include mq(sm) {
        border-left: 1px solid #404040;
        padding: 50px 0px 50px 24px;
      }

      @media (min-width: 1120px) {
        padding: 50px 0px 50px 24px;
      }
    }
  }

  &__columns {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    justify-content: space-between;

    @include mq(sm) {
      grid-template-columns: 1fr 200px;
      gap: 80px;
    }
  }

  &__li {
    &:not(:last-child) {
      margin-bottom: 18px;
    }
  }

  &__link {
    position: relative;
    text-decoration: none;
    display: inline-block;
    font-size: rem(18px);
    transition: all .3s;

    &:hover {
      color: $color--primary;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    background: $color--primary;
    padding: 12px 18px;
    line-height: 1;
    font-size: rem(18px);
    color: $white;
    transition: all .3s;
    width: max-content;

    &--icon {
      font-size: 12px;
      margin-left: 6px;
    }

    &:hover {
      background-color: shade($color--primary, 5%);
    }
  }

  &__logo {
    max-width: 250px;
    display: block;
    margin-bottom: 24px;
  }
}

.newsletter {
  &__title {
    font-size: rem(24px);
    font-family: $font-family--secondary;
    margin-bottom: 12px;
  }

  &__link {
    display: flex;
    align-items: center;
    margin-top: 12px;
    transition: all .3s;
    width: fit-content;

    &:hover {
      color: $color--primary;

      .newsletter__link--text {
        border-bottom: 1px solid $color--primary;
      }
    }

    &--text {
      border-bottom: 1px solid #404040;
      line-height: 1;
    }
  }

  &__icon {
    font-size: 8px;
    margin-left: 12px;
  }
}

.networks {
  &__title {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold;
  }

  &__ul {
    display: flex;
    gap: $margin--base * 2;
    justify-content: flex-start;
    margin: 12px 0;
  }

  &__a {
    position: relative;
    transition: all .3s ease-out;

    &:hover {
      color: $color--primary;
    }
  }

  &__disclaimer {
    font-size: rem(12px);
    display: block;
  }
}

.contact {
  &__address {
    margin-bottom: 24px;
  }

  &__li {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__text {
    display: inline-block;
    line-height: 1;
    margin-left: 12px;
  }

  &__link {
    border-bottom: 1px solid #404040;
    transition: all .3s;

    &:hover {
      color: $color--primary;
      border-bottom: 1px solid $color--primary;
    }
  }
}

.partners {
  display: flex;
  gap: 24px;
  margin-top: 60px;

  &__img {
    display: block;
    max-width: 90px;
  }
}
</style>
