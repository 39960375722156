export default [
  {
    name: 'custom_footer_111111',
    path: 'modules/pages/111111/Footer111111'
  },
  {
    name: 'custom_header_111111',
    path: 'modules/pages/111111/Header111111'
  },
  {
    name: 'custom_homepage_111111',
    path: 'modules/pages/111111/Homepage111111'
  }
]
