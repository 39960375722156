<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__grid">
        <router-link
          :to="{ name: 'Home', params: { locale } }"
          v-show="client.logo.url"
        >
          <img
            class="logo__img"
            :src="client.logo.url"
            :alt="client.name"
          >
        </router-link>

        <article>
          <ul>
            <li
              v-for="({line}, index) in address"
              :key="`address_${index}`"
            >
              {{ translate(line) }}
            </li>

            <li
              v-for="({line}, index) in phoneNumbers"
              :key="`phone_${index}`"
            >
              {{ translate(line) }}
            </li>

            <li
              v-for="(email, index) in emails"
              :key="`email_${index}`"
            >
              <a
                class="link__color--black"
                :href="email.url"
                target="_blank"
              >
                {{ email.text }}
              </a>
            </li>
          </ul>
        </article>

        <article class="navigation">
          <ul>
            <li
              v-for="({link}, index) in navigation"
              :key="`navigation_${index}`"
            >
              <app-link
                :link="link"
                class="navigation__link link__color--black"
              />
            </li>
          </ul>
        </article>

        <article
          class="social"
          v-if="socialMedias.length > 0 && socialMedias"
        >
          <ul class="social__ul">
            <li
              class="social__li"
              v-for="(media, i) in socialMedias"
              :key="'footer_menu_media_' + i"
            >
              <a
                class="social__link"
                :href="translate(media.url)"
                target="_blank"
              >
                <fa-icon
                  v-if="media.icon"
                  size="lg"
                  :icon="media.icon"
                  class="social__icon"
                />
              </a>
            </li>
          </ul>
        </article>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { computed } from 'vue'
import { mapGetters } from '@/store/map-state'
import { get as _get } from 'lodash'
import { useI18n } from '@/vendors/i18n'

import AppLink from '&/atoms/AppLink'

const props = defineProps({
  section: { type: Object, required: true }
})

const { getLocale, translate } = useI18n()
const { client } = mapGetters('client')

const locale = computed(() => getLocale())
const address = computed(() => _get(props, 'section.attributes.address', []))
const navigation = computed(() => _get(props, 'section.attributes.navigation', []))
const phoneNumbers = computed(() => _get(props, 'section.attributes.phone_number', []))

const emails = computed(() => {
  return _get(props, 'section.attributes.email', []).map(({ line }) => {
    return {
      text: translate(line).replace(/ /g, ''),
      url: 'mailto:' + translate(line).replace(/ /g, '')
    }
  })
})

const socialMedias = computed(() => {
  const links = _get(client.value, 'platform.social_networks', [])
  return links.length > 0
    ? links.map(link => {
      if (link.icon.includes('facebook-square')) {
        link.icon[1] = 'facebook-f'
      } else if (link.icon.includes('linkedin')) {
        link.icon[1] = 'linkedin-in'
      }

      return link
    })
      .filter(link => translate(link.url))
    : []
})
</script>

<style lang="scss" scoped>
.footer {
  padding: 60px 16px;
  background-color: #FBFBFB;
  font-size: rem(14px);

  .container {
    padding: 0;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: $margin--base * 2;
    justify-content: space-between;

    @include mq(md) {
      grid-template-columns: repeat(4, fit-content(100px));
    }
  }
}

.logo {
  &__img {
    display: block;
    max-width: 180px;

    @include mq(md) {
      max-width: 230px;
    }
  }
}

.social {
  &__ul {
    display: flex;
    justify-content: center;
    gap: $margin--base * 4;
    justify-items: center;
    align-items: center;
    margin-bottom: 24px;

    @include mq(sm) {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: $margin--base * 2;
    }
  }

  &__li {
    transition: all .3s ease-out;

    &:hover .networks__a {
      top: -4px;
    }
  }

  &__link {
    position: relative;
    color: $black;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all .3s ease-out;
    width: 45px;
    height: 45px;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border: 1px solid $black;
      border-radius: 50%;
      z-index: 0;
      transition: all .3s ease-out;
    }

    &:hover {
      color: $white;

      &:after {
        background-color: $black;
      }
    }
  }

  &__icon {
    z-index: 1;
    font-size: 24px;
  }
}
</style>
