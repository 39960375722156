<template>
  <form
    class="donate"
    v-if="procedure && isShowed('donate_field')"
  >
    <label
      class="donate__label bold"
      for="amount"
      v-if="isShowed('text_donate_field')"
    >
      {{ label }}
    </label>

    <input-amount
      class="donate__input"
      id="amount"
      v-model="formAmount"
    />

    <project-button
      class="btn__size--full-6 donate__button margin__t--12"
      :project="project"
      :is-preview="isPreview"
      v-if="labelButton"
    >
      {{ labelButton }}
    </project-button>
  </form>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useProcedureExecutionStore } from '@/stores/ProcedureExecutionStore'
import { useBlock } from '@/composables/modules/templates'
import { useProcedure, useProcedureTransaction } from '@/composables/modules/procedures'
import { get as _get } from 'lodash'

import InputAmount from '&/atoms/InputAmount'
const ProjectButton = defineAsyncComponent(() => import('@/components/modules/project/ProjectButton'))

const props = defineProps({ block: { type: Object, required: true } })

const { translate } = useI18n()
const { objects, isShowed, getText, isPreview } = useBlock(props.block)
const { getProcedureExecution } = useProcedureExecutionStore()

const project = computed(() => _get(objects.value, 'project'))
const procedure = computed(() => project.value?.procedures.donation)
const label = computed(() => translate(getText('donate_field')))
const labelButton = computed(() => translate(getText('donate_button')))

const { id } = useProcedure({ procedure })
const { procedureExecution } = getProcedureExecution({ procedure_id: id, dependencies: [procedure] })
const { formAmount } = useProcedureTransaction({ procedure, procedure_execution: procedureExecution })
</script>

<style lang="scss" scoped>
.donate {
  border: 1px solid rgba(#2E293D, .1);
  border-radius: 4px;
  padding: 18px;

  &__input :deep(.input) {
    background-color: transparent;
  }

  &__label {
    display: block;
    margin-bottom: 12px;
    font-weight: bold;
  }
}
</style>
