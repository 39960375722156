<template>
  <input
    type="url"
    class="input input--border input--radius"
    name="input-url"
    v-model="url"
    :placeholder="placeholder"
    @blur="blurIfMandatory()"
    v-bind="$attrs"
  >

  <div
    class="input--errors"
    v-if="v && v.$error"
  >
    <span
      class="input--error"
      v-if="v.required && v.required.$invalid"
    >
      {{ $i18n.t('errors.input_url_required') }}
    </span>
    <span
      class="input--error"
      v-if="v.validVideoUrl && v.validVideoUrl.$invalid"
    >
      {{ $i18n.t('errors.invalid_input_url') }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InputUrl',
  emits: ['update:modelValue'],
  props: {
    placeholder: {
      type: String,
      default: '...'
    },
    modelValue: {
      type: String,
      default: ''
    },
    v: {
      type: Object
    }
  },
  computed: {
    url: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    blurIfMandatory () {
      if (this.v) {
        this.v.$touch()
      }
    }
  }
})
</script>
