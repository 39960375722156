import Product from '@/classes/products/Product'
const endpoint = '/api/v1/products'

export default {
  namespaced: true,
  state: {
    items: {}
  },
  getters: {
    products: state => Object.values(state.items),
  },
  actions: {
    fetchProducts: ({ dispatch }, { query, removeStoredItems }) => dispatch(
      'fetchItems',
      {
        endpoint,
        query,
        resource: 'products',
        model: product => new Product(product),
        removeStoredItems
      },
      { root: true }
    )
  },
  mutations: {
    setProducts (state, products) {
      state.items = products
    }
  }
}
