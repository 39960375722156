<template>
  <div class="not-found-msg">
    <p>Due to some reasons, this element cannot be displayed properly. Please contact the support to have more informations.</p>
  </div>
</template>

<style lang="scss" scoped>
.not-found-msg {
  background: tint($color--danger, 70%);
  color: $white;
  padding: $padding__base;
  text-align: center;
}
</style>
