<template>
  <footer v-if="section">
    <section
      class="footer"
      :class="{'footer__with-columns': section.attributes.columns}"
    >
      <div class="footer__container container">
        <section
          class="footer__column"
          :class="['footer__column--' + section.attributes.columns.length]"
          v-for="(column, index) in section.attributes.columns"
          :key="'footer_element_' + index"
        >
          <router-link
            :to="{ name: 'Home', params: { locale: $i18n.locale } }"
            v-if="column.logo"
          >
            <img
              class="logo__img"
              :src="column.logo[$i18n.locale]"
              :alt="client.name"
            >
          </router-link>

          <ul v-if="column.list">
            <li
              class="footer__li"
              v-for="(item, menuIndex) in column.list"
              :key="'footer_menu_item_' + index + menuIndex"
            >
              <h3
                class="footer__text title--h3"
                v-if="item.title && item.title[$i18n.locale]"
              >
                {{ item.title[$i18n.locale] }}
              </h3>

              <p
                class="footer__text"
                v-if="item.text && item.text[$i18n.locale]"
                v-html="item.text[$i18n.locale]"
              />

              <a
                class="footer__link footer__text link--naked"
                :class="{'footer__link--active': isVisited(item.link)}"
                v-if="item.link"
                @click.prevent="setRouteNavigation(item.link)"
              >
                <fa-icon
                  class="margin__r--6"
                  v-if="item.link.icon"
                  :icon="item.link.icon"
                />
                {{ $i18n.translate(item.link.text) }}
              </a>

              <template v-if="item.social && item.social.length > 0">
                <a
                  class="footer__link footer__social footer__text link--naked margin__r--6"
                  :class="{'footer__link--active': isVisited(social)}"
                  v-for="social in item.social"
                  :key="social.text[$i18n.locale]"
                  @click.prevent="setRouteNavigation(social)"
                >
                  <fa-icon
                    class="margin__r--6"
                    size="2x"
                    v-if="social.icon"
                    :icon="social.icon"
                  />
                </a>
              </template>
            </li>
          </ul>
        </section>
      </div>
    </section>
  </footer>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import PageRouteNavigation from '%/PageRouteNavigation'

export default defineComponent({
  name: 'FooterDefault',
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client'
    })
  }
})
</script>

<style lang="scss" scoped>
.footer {
  background-color: white;

  &__container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  &__with-columns {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3;
    }
  }

  &__column {
    @for $m from 1 through 6 {
      &--#{$m} {
        width: 100%;
        margin: $margin--base 0;

        @include mq(sm) {
          width: auto;
          text-align: left;
          margin: 0;
        }
      }
    }
  }

  &__li {
    margin-bottom: 8px;
  }

  &__text {
    color: #29809B;
    font-size: rem(12px);
  }

  &__link {
    position: relative;
    transition: all .3s;

    &:hover, &:focus {
      color: darken(#29809B, 15%);
    }

    @include mq(md) {
      margin: 5px 0 10px 0;
    }
  }

  &__social {
    margin-right: 6px;
  }
}

.title {
  &--h3 {
    margin-bottom: $margin--base;
    font-weight: bold;
  }
}

.logo {
  &__img {
    width: 130px;
    display: block;
  }
}
</style>
