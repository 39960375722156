<template>
  <header
    class="header"
    :class="{ 'is-fixed': show_mobile_menu }"
  >
    <div class="container header__container">
      <router-link
        class="logo"
        :to="{ name: 'Home', params: { locale } }"
        v-if="translate(logo)"
      >
        <img
          class="logo__img"
          :src="translate(logo)"
          :alt="client.name"
        >
      </router-link>

      <div class="header__wrapper">
        <nav
          class="nav"
          v-if="navigation.length > 0"
        >
          <ul class="nav__ul d-none d-sm-flex">
            <li
              class="nav__li"
              v-for="({ link }, key) in navigation"
              :key="`link_${key}`"
            >
              <app-link
                :link="link"
                class="nav__a link__color--ter-prim link--naked"
              />
            </li>
          </ul>
        </nav>

        <div class="header__actions d-flex align-items-center justify-content-between">
          <app-burger-menu
            class="no-padding d-sm-none"
            :state="show_mobile_menu"
            @toggle="toggleMobileMenu"
          />

          <app-link
            :link="cta"
            class="btn btn__size--small btn__color--ter cta"
          />

          <app-session-button class="session link__color--ter-prim" />
        </div>
      </div>
    </div>

    <nav
      class="mobile-nav d-sm-none"
      v-if="show_mobile_menu && navigation.length > 0"
    >
      <ul class="nav__ul">
        <li
          class="mobile-nav__li"
          v-for="({ link }, key) in navigation"
          :key="`link_${key}`"
        >
          <app-link
            :link="link"
            class="mobile-nav__a link__color--white-prim link--naked"
          />
        </li>
      </ul>
    </nav>
  </header>
</template>

<script setup>
import { ref, computed, watch, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import { useClientStore } from '@/stores/ClientStore'
import { get as _get } from 'lodash'
import { useI18n } from '@/vendors/i18n'
import { scrollTo } from '@/utils/utils'

import AppLink from '&/atoms/AppLink'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
import AppSessionButton from '&/organisms/AppSessionButton'

const props = defineProps({
  section: { type: Object, required: true }
})

const { getClient } = useClientStore()
const { translate } = useI18n()
const { locale } = toRefs(useI18n())
const route = useRoute()

const { client } = getClient()

const show_mobile_menu = ref(false)

const logo = computed(() => _get(props, 'section.attributes.logo', {}))
const navigation = computed(() => _get(props, 'section.attributes.navigation', []))
const cta = computed(() => _get(props, 'section.attributes.cta', {}))

const toggleMobileMenu = () => {
  scrollTo()

  setTimeout(() => show_mobile_menu.value = !show_mobile_menu.value, 100)
}

watch(route, () => show_mobile_menu.value = false)
</script>

<style lang="scss" scoped>
:deep(.cta) {
  font-family: $font-family--secondary;
  font-size: rem(18px);
  text-transform: uppercase;
  padding: 3px 12px;
  border-radius: 0;
  margin-left: auto;
  font-weight: bold;

  &:hover {
    background-color: $color--primary;
  }

  @include mq(sm) {
    margin: inherit;
  }
}

:deep(.session) {
  font-size: rem(16px);
  line-height: 1;

  @include mq(sm) {
    font-size: rem(18px)
  }

  @include mq(lg) {
    font-size: rem(24px)
  }

  span {
    display: none;

    @include mq(sm) {
      display: inline-block;
    }
  }
}

.header {
  padding: 12px 0;
  position: relative;

  @include mq(sm) {
    padding: 24px 0;
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background: $white;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
  }

  &.is-fixed {
    position: fixed;
    z-index: 99;
    width: 100%;

    @include mq(sm) {
      position: relative;
    }
  }

  &__container {
    position: relative;
    z-index: 10;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;

    @include mq(md) {
      margin-top: 24px;
      padding-left: 8.4957131723%;
    }
  }

  &__actions {
    gap: 12px;
    flex: 1 1 auto;

    @include mq(sm) {
      gap: 24px;
      flex: 0 0 auto;
    }
  }
}

.logo {
  display: block;

  &__img {
    max-width: 100%;
    height: auto;
    display: block;
  }
}

.nav {
  &__ul {
    gap: 24px;
  }

  :deep(.nav__a) {
    line-height: 1.16;

    @include mq(sm) {
      font-size: rem(18px);
    }

    @include mq(lg) {
      font-size: rem(24px);
    }

    &.is-active {
      color: $color--primary;
    }
  }
}

.mobile-nav {
  position: fixed;
  top: 0px;
  height: 100vh;
  width: 100%;
  background: $color--tertiary;
  z-index: 999;
  text-align: center;
  font-size: rem(32px);
  padding: 162px 12px 32px;
  z-index: 0;

  &__li {
    padding: 12px;
  }

  &:deep(.mobile-nav__a) {
    &.is-active {
      color: $color--primary;
    }
  }
}
</style>
