<template>
  <div
    v-if="hasElements"
    class="section"
    :style="sectionStyle"
  >
    <div class="container">
      <div
        class="editor editor__content editor__page margin__b--24"
        v-if="translate(title)"
        v-html="translate(title)"
      />

      <div
        class="campaigns padding__tb--12"
        :class="{'campaigns--highlighted': isHighlighted}"
        v-if="campaigns.length > 0 || loader"
      >
        <template v-if="loader">
          <article
            class="card card--loading"
            v-for="campaignId in campaignIds"
            :key="campaignId"
          >
            <app-spinner />
          </article>
        </template>

        <template v-else>
          <campaign-card
            :is-highlighted="isHighlighted"
            v-for="campaign in campaigns"
            :campaign="campaign"
            :key="campaign.id"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, toRefs, defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useCampaignStore } from '@/stores/CampaignStore'
import { useSectionStyle } from '@/composables/modules/pages'
import { get as _get } from 'lodash'

import CampaignCard from '&/organisms/CampaignCard'

const AppSpinner = defineAsyncComponent(() => import('&/atoms/AppSpinner'))

const props = defineProps({
  page: { type: Object, required: true },
  section: { type: Object, required: true }
})

const { section } = toRefs(props)

const { translate } = useI18n()
const { sectionStyle } = useSectionStyle(props)
const { getCampaigns } = useCampaignStore()

const title = computed(() => _get(section.value, 'attributes.title', {}))
const campaignIds = computed(() => _get(section.value, 'attributes.campaigns.ids', []))
const isHighlighted = computed(() => _get(section.value, 'attributes.campaigns.highlighted', false))

const query = computed(() => ({ campaign_id: campaignIds.value.length ? campaignIds.value : [''] }))
const hasElements = computed(() => translate(title.value) || !!campaignIds.value.length)

const { campaigns, loader } = getCampaigns({ query })
</script>

<style lang="scss" scoped>
.section {
  padding: $padding__base 0;

  @include mq(md) {
    padding: $padding__base * 3 0;
  }
}

.campaigns {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr;

  @include mq(sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq(md) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include mq(lg) {
    grid-template-columns: repeat(4, 1fr);
  }

  &--highlighted {
    grid-template-columns: 1fr;
  }
}

.card {
  &--loading {
    height: 415px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
