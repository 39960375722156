<template>
  <form
    @submit.prevent="sendRequest"
    novalidate
  >
    <div class="margin__t--24">
      <label
        class="color--lg"
        for="forgot_password_email"
      >
        {{ $i18n.t('literal.email') }} *
      </label>

      <input-email
        id="forgot_password_email"
        v-model="form.email"
        :v="v$.form.email"
        class="margin__t--6"
      />
    </div>

    <div class="align--center margin__t--36 button__container">
      <button
        type="submit"
        name="login-button"
        class="btn btn__size--medium btn__color--prim"
        :disabled="loader.active"
      >
        {{ $i18n.t('literal.send') }}
      </button>

      <app-spinner
        v-if="loader.active"
        class="margin__l--12"
      />
    </div>
  </form>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import InputEmail from '&/atoms/InputEmail'

export default defineComponent({
  name: 'ForgotPasswordForm',
  emits: ['forgotPasswordMailSended'],
  components: {
    InputEmail,
    AppSpinner: defineAsyncComponent(() => import('@/components/atoms/AppSpinner'))
  },
  data () {
    return {
      form: {
        email: null
      },
      loader: {
        active: false
      },
      errors: null
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  methods: {
    ...mapActions({
      forgotPassword: 'auth/forgotPassword'
    }),
    sendRequest () {
      this.v$.form.$touch()
      if (this.v$.form.$pending || this.v$.form.$error) return
      this.loader.active = true
      this.forgotPassword({ params: this.form })
        .then(() => {
          this.$emit('forgotPasswordMailSended')
          this.loader.active = false
        })
        .catch(error => {
          this.errors = error.data.errors
          this.loader.active = false
        })
    }
  },
  validations: {
    form: {
      email: { required, email }
    }
  }
})
</script>

<style scoped lang="scss">
  .button__container {
    display: flex;
    justify-content: center;
  }
</style>
