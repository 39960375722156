<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 685.2 483"
    style="enable-background:new 0 0 685.2 483;"
    width="685.2"
    height="483"
    xml:space="preserve"
  >
    <image
      height="965"
      overflow="visible"
      transform="matrix(.5 0 0 .5 -.2 .2)"
      width="1371"
      xlink:href="https://koalect-images.s3.eu-west-3.amazonaws.com/clients/deloods/campaign_building_level_0.png"
    />

    <circle
      class="dot"
      cx="415"
      cy="146"
      r="14.5"
      :fill="getColorByIndex(0)"
      @click="handleClickDot(0)"
      v-if="getProjectIdByIndex(0)"
    />

    <circle
      class="dot"
      cx="211"
      cy="114"
      r="14.5"
      :fill="getColorByIndex(1)"
      @click="handleClickDot(1)"
      v-if="getProjectIdByIndex(1)"
    />

    <circle
      class="dot"
      cx="336"
      cy="304"
      r="14.5"
      :fill="getColorByIndex(2)"
      @click="handleClickDot(2)"
      v-if="getProjectIdByIndex(2)"
    />

    <circle
      class="dot"
      cx="155"
      cy="381"
      r="14.5"
      :fill="getColorByIndex(3)"
      @click="handleClickDot(3)"
      v-if="getProjectIdByIndex(3)"
    />
  </svg>
</template>

<script setup>
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'

const emit = defineEmits('selected-project')

const props = defineProps({
  dots: { type: Array, default: () => [] }
})

const { translate } = useI18n()

const getProjectIdByIndex = index => _get(props, `dots.${index}.project_id.0`)
const getColorByIndex = index => translate(_get(props, `dots.${index}.color`))

const handleClickDot = index => emit('click-dot', index)
</script>

<style lang="scss" scoped>
.dot {
  fill-rule: evenodd;
  clip-rule:evenodd;
  stroke:#000000;
  stroke-width:3;
  cursor: pointer;

  @supports ( transform-box: fill-box ) {
  transform-box: fill-box;
  transform-origin: 50% 50%;
  transition: all .2s;

  @include mq(sm) {
    &:hover {
      transform: translate(0, 0) scale(1.2);
    }
  }
}
}
</style>