import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { useAllowedFeatures } from '@/composables/app/useAllowedFeatures'

export function useAccountVerification () {
  const { allowedAccountVerification } = useAllowedFeatures()
  const { authUser } = storeToRefs(useAuthenticationStore())

  const isVerified = computed(() => !allowedAccountVerification || !authUser.value || authUser.value.is_verified)

  return {
    isVerified
  }
}