import { computed } from 'vue'
import { useClientStore } from '@/stores/ClientStore'
import { get as _get, uniqBy as _uniqBy } from 'lodash'

export function useClientColors () {
  const { getClient } = useClientStore()
  const { client } = getClient()

  const colors = computed(() => client.value.style.colors)
  const systemColors = computed(() => _uniqBy(colors.value.filter(color => color.category === 'system_colors')))
  const buttonTextColors = computed(() => colors.value.filter(color => color.category === 'button_text_colors'))
  const defaultColor = computed(() => getColor('primary-color'))
  const backgroundDonationFormColor = computed(() => _get(colors.value.find(color => color.name === 'background-donation-form-color'), 'hex', null))

  const getColor = (name, defaultColor = '#333333') => _get(colors.value.find(color => color.name === name), 'hex', defaultColor)

  const getBtnTextColor = hex => {
    const color = systemColors.value.find(color => color.hex === hex)
    const name = _get(color, 'name', 'text-button-primary-color')

    return _get(buttonTextColors.value.find(color => color.name.includes(name)), 'hex', '#FFFFFF')
  }

  return {
    colors,
    systemColors,
    buttonTextColors,
    defaultColor,
    backgroundDonationFormColor,
    getColor,
    getBtnTextColor
  }
}
