import User from '@/classes/users/User'
import axios from '@/vendors/axios/axios'
import { $cookies } from '@/utils/utils'
import tracking from '@/vendors/tracking'

const endpoint = '/auth'
const resource = 'auth'

export default {
  namespaced: true,
  state: {
    user: null
  },
  getters: {
    user: state => state.user,
    isAuthenticated: state => !!state.user
  },
  actions: {
    forgotPassword ({}, { params }) {
      return new Promise((resolve, reject) => {
        axios.post(`${endpoint}/user/forgot_password`, { user: params })
          .then(() => resolve())
          .catch(error => reject(error))
      })
    },

    patchUserPassword ({}, { params, token }) {
      return new Promise((resolve, reject) => {
        axios.patch(`${endpoint}/user/password`, { user: params, token })
          .then(() => resolve())
          .catch(error => reject(error))
      })
    },

    fetchAuthUser ({ state, commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${endpoint}/user`)
          .then(response => {
            const user = new User(response.data.data)
            commit('setItem', { resource, id: 'user', item: user }, { root: true })

            tracking.session.init({ user })
            tracking.user.set({ user })

            resolve(state.user)
          })
          .catch(error => reject(error))
      })
    },

    fetchAuthUserTransactions: ({ dispatch }) => dispatch(
      'fetchItem',
      { endpoint: `${endpoint}/user/transactions` },
      { root: true }
    ),

    patchAuthUser: ({ dispatch }, { params }) => dispatch(
      'patchItem',
      {
        endpoint: `${endpoint}/user`,
        resource,
        id: 'user',
        params: {
          user: params
        },
        model: user => new User(user)
      },
      { root: true }
    ),

    patchAuthUserImage: ({ dispatch }, { params }) => dispatch(
      'patchImage',
      {
        endpoint: `${endpoint}/user`,
        resource,
        id: 'user',
        params
      },
      { root: true }
    ),

    getAuthUserDataExport: ({ dispatch }) => dispatch(
      'fetchItem',
      {
        endpoint: `${endpoint}/user/data_export`,
        resource,
        id: 'data_export'
      },
      { root: true }
    )
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    },
    removeAuthToken () {
      $cookies.delete({ name: 'authToken' })
    }
  }
}
