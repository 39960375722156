<template>
  <input
    type="time"
    class="input input--border input--radius input-time"
    :id="name"
    :name="name"
    v-model="time"
  >
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InputTime',
  emits: ['update:modelValue'],
  props: {
    name: {
      type: String,
      default: 'inputtime'
    },
    modelValue: {
      type: String
    }
  },
  computed: {
    time: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.input-time {
  &::-webkit-datetime-edit-text {
    padding: calc($padding__base / 2);
  }

  &::-webkit-clear-button {
    display: none;
  }

  &::-webkit-inner-spin-button {
    display: none;
  }
}
</style>
