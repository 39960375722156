import { computed, toValue } from 'vue'
import { storeToRefs } from 'pinia'
import { useRouter, useRoute } from 'vue-router'
import { useProcedureExecutionStore } from '@/stores/ProcedureExecutionStore'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { useNotificationStore } from '@/stores/NotificationStore'
import { useCartStore } from '@/stores/CartStore'
import { useSecureStore } from '@/stores/SecureStore'
import Customer from '@/classes/customers/Customer'
import { useProcedure, useProcedureNavigation } from '@/composables/modules/procedures'
import { useTracking } from '@/vendors/tracking'
import { useSentry } from '@/vendors/integrations/sentry'
import { get as _get, set as _set, pick as _pick, merge as _merge, has as _has } from 'lodash'

export function useProcedureExecution ({ procedure, procedure_execution }) {
  const { fetchProcedureSecureToken } = useSecureStore()
  const { fetchProcedureExecution, patchProcedureExecutionInformations } = useProcedureExecutionStore()
  const { loader, error } = storeToRefs(useProcedureExecutionStore())
  const { authUser } = storeToRefs(useAuthenticationStore())
  const { query } = useRoute()
  const { replace } = useRouter()
  const { id: procedureId, slug: procedureSlug, category } = useProcedure({ procedure })
  const { goToNextStep, nextRedirection } = useProcedureNavigation({ procedure, procedure_execution })
  const { getCart } = useCartStore()
  const { postModalNotification } = useNotificationStore()
  const tracking = useTracking()
  const sentry = useSentry()

  const { order } = getCart({ id: procedureId })

  const id = computed(() => _get(toValue(procedure_execution), 'id'))

  const isSucceeded = computed(() => _get(toValue(procedure_execution), 'status') === 'done')

  const paymentMethod = computed(() => _get(toValue(procedure_execution), 'payment_method'))

  const setCustomer = customer => _set(toValue(procedure_execution), 'customer', new Customer(_merge(_get(toValue(procedure_execution), 'customer', {}), customer)))

  const setCustomerFromAuthUser = () => {
    if (!authUser.value) return

    const keys = toValue(procedure).blocks.all('customer.').map(({ model }) => model.replace('customer.', '')).filter(key => !_get(toValue(procedure_execution), `customer.${key}`))

    setCustomer(_pick(authUser.value, ['firstname', 'lastname', 'email', ...keys]))
  }

  const setFromUrlQuery = () => {
    const available_keys = [/^(customer|actions_data)\[[a-z_]+]/, /^informations\[private_message]$/]

    if (!Object.keys(query).length) return

    const keys = Object.keys(query)
      .filter(key => {
        let value = query[key]

        const is_existing_key = _has(toValue(procedure_execution), key.replace(/\[\d\].+|(informations)\[.+\]$/, (match, p1) => p1 === 'informations' ? 'informations' : ''))
        const is_available_key = available_keys.some(regex => regex.test(key))

        if (!isNaN(value)) value = parseFloat(value)
        if (value === 'true' || value === 'false') value = value === 'true'

        return is_existing_key && is_available_key ? !_set(toValue(procedure_execution), key, value) : true
      })

    replace({ query: _pick(query, keys) })
  }

  const handleFetchProcedureExecution = execution_id => {
    loader.value = true

    fetchProcedureExecution({ procedure_id: procedureId.value, execution_id: toValue(execution_id) })
      .then(() => handleDoneProcedureExecution())
      .catch(() => postModalNotification({ title: 'errors.form_execution_validation_failed_title', text: 'errors.form_execution_validation_failed_text', code: '#001304', error: 'GET Execution failed', state: { procedure_id: procedureId.value, execution_id: toValue(execution_id) }, close: nextRedirection }))
  }

  const handlePatchProcedureExecution = () => {
    loader.value = true

    return patchProcedureExecutionInformations({ procedure_id: procedureId, execution_id: id, params: procedure_execution.value.informations })
      .catch(e => {
        loader.value = false
        error.value = e
        sentry.error(Error('#001402 - PATCH Execution failed'))
      })
  }

  const handleDoneProcedureExecution = () => {
    if (isSucceeded.value) {
      const { transaction, project, accept_invite } = _get(toValue(procedure_execution), 'actions_data', {})

      tracking.procedure.execution({ procedure: toValue(procedure), procedure_execution: toValue(procedure_execution), order: order.value })

      if (transaction.amount > 0) tracking.procedure.transaction({ procedure: toValue(procedure), procedure_execution: toValue(procedure_execution), order: order.value })
      if (category.value === 'create-project' && project.id) tracking.project.created({ project, transaction })
      if (category.value === 'project-invite' && accept_invite.id) tracking.project.memberJoined({ accept_invite, transaction })
    }

    fetchProcedureSecureToken({ id: procedureSlug })

    goToNextStep({ query: { execution_id: id.value } }).then(() => loader.value = false)
  }

  return {
    id,
    isSucceeded,
    paymentMethod,
    setCustomer,
    setCustomerFromAuthUser,
    setFromUrlQuery,
    handleFetchProcedureExecution,
    handlePatchProcedureExecution,
    handleDoneProcedureExecution
  }
}