<template>
  <header
    class="header"
    :class="{ 'is-home': isHome, 'is-fixed': showMobileMenu }"
  >
    <div class="container d-flex justify-content-between align-items-center align-items-sm-start header__container">
      <router-link
        :to="{ name: 'Home', params: { locale } }"
        class="logo header__logo margin__r--30"
        :class="{ 'logo--home': isHome }"
      >
        <img
          class="logo__img"
          :src="translate(logo) || client.logo.url"
          :alt="client.name"
        >
      </router-link>

      <div class="d-flex align-items-center header__actions">
        <router-link
          class="session__user d-inline-flex align-items-center link--naked"
          :class="{ 'link__color--black-prim': !isHome && !showMobileMenu, 'link__color--white-white': isHome || showMobileMenu }"
          :to="{ name: 'Profile', params: { locale } }"
          v-if="isAuthenticated && user"
        >
          <user-profile-img
            :size="2"
            :avatar="user.avatar"
          />

          <p class="margin__l--6">
            {{ user.firstname }}
          </p>
        </router-link>

        <button
          @click.prevent="openModal()"
          class="session__login link--naked"
          :class="{ 'link__color--black-prim': !isHome && !showMobileMenu, 'link__color--white-white': isHome || showMobileMenu }"
          v-else
        >
          <fa-icon
            :icon="['fas', 'user']"
            fixed-width
          />

          {{ t('literal.login') }}
        </button>

        <app-burger-menu
          class="header__burger d-sm-none"
          :class="{ 'color--white': isHome || showMobileMenu, 'color--dg': !isHome && !showMobileMenu }"
          :state="showMobileMenu"
          @toggle="toggleMobileMenu"
        />

        <div
          class="d-none d-sm-flex flex-sm-row header__cta"
          :class="{ 'd-flex': showMobileMenu }"
        >
          <app-link
            :link="cta1"
            class="bold btn btn__color--prim cta"
          />

          <app-link
            :link="cta2"
            class="bold btn btn__color--sec cta"
          />
        </div>
      </div>
    </div>

    <session-modal
      tab="login"
      v-if="show_modal"
      @close="closeModal"
    />
  </header>
</template>

<script setup>
import { ref, computed, defineAsyncComponent } from 'vue'
import { mapGetters } from '@/store/map-state'
import { useClientStore } from '@/stores/ClientStore'
import { useI18n } from '@/vendors/i18n'
import { useModals } from '@/composables/app/useModals'
import { useRoute } from 'vue-router'
import { scrollTo } from '@/utils/utils'
import { get as _get } from 'lodash'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const UserProfileImg = defineAsyncComponent(() => import('&/atoms/UserProfileImg'))
const AppBurgerMenu = defineAsyncComponent(() => import('&/atoms/AppBurgerMenu'))
const SessionModal = defineAsyncComponent(() => import('&/organisms/SessionModal'))

const props = defineProps({
  section: { type: Object, required: true }
})

const route = useRoute()
const { t, translate, getLocale } = useI18n()
const { isAuthenticated, user } = mapGetters('auth')
const { show_modal, openModal, closeModal } = useModals()
const { getClient } = useClientStore()

const { client } = getClient()

const showMobileMenu = ref(false)

const isHome = computed(() => route.name === 'Home')
const locale = computed(() => getLocale())
const logo = computed(() => isHome.value || showMobileMenu.value ? _get(props, 'section.attributes.logo.image_home', {}) : _get(props, 'section.attributes.logo.image', {}))
const cta1 = computed(() => _get(props, 'section.attributes.cta_1'))
const cta2 = computed(() => _get(props, 'section.attributes.cta_2'))

const toggleMobileMenu = () => {
  scrollTo()

  setTimeout(() => showMobileMenu.value = !showMobileMenu.value, 100)
}
</script>

<style lang="scss" scoped>
$color--primary: #1D7874;
$color--secondary: #F95738;

.header {
  padding: 6px 0;
  position: relative;
  z-index: 999;

  &.is-fixed {
    position: fixed;
    width: 100%;
    top: 0;
  }

  @include mq(sm) {
    padding: 24px 0 12px;
  }

  &:not(.is-home) {
    background: $white;

    @include mq(sm) {
      padding: 12px 0;
    }
  }

  &.is-home {
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%);
    }
  }

  :deep() {
    .cta {
      font-size: rem(18px);
      line-height: 1.55;
      border-radius: 0;
      position: relative;
      font-family: $font-family--secondary;
      text-transform: uppercase;
      padding: 6px;

      @include mq(sm) {
        font-size: rem(16px);
        padding: 12px;
      }

      @include mq(lg) {
        font-size: rem(20px);
        padding: 12px 24px;
      }
    }
  }

  &__container {
    position: relative;
    z-index: 10;
  }

  &__logo {
    position: relative;
    z-index: 10;
  }

  &__actions {
    position: relative;
    z-index: 0;
    gap: 12px;

    @include mq(lg) {
      gap: 24px;
    }
  }

  &__cta {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: darken($color--primary, 10%);
    z-index: 0;
    padding: 72px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    @include mq(sm) {
      position: relative;
      width: auto;
      height: auto;
      background: none;
      padding: 0;
      gap: 12px;
    }
  }

  &__burger {
    padding: 0!important;
    z-index: 10;

    & :deep() {
      .menu {
        &__label {
          font-size: rem(12px);
          margin-right: 6px;
          transition: all .2s ease-in-out;
        }

        &__line {
          background: currentColor;
          transition: all .2s ease-in-out;
        }

        &__close {
          &::before,
          &::after {
            background: currentColor;
            transition: all .2s ease-in-out;
          }
        }
      }
    }
  }
}

.logo {
  display: block;

  &--home {
    .logo {
      &__img {
        @include mq(sm) {
          height: 100px;
        }

        @include mq(md) {
          height: 150px;
        }
      }
    }
  }

  &__img {
    height: 50px;
    width: auto;
    display: block;
    transition: all .2s ease-in-out;
  }
}

.session {
  &__user,
  &__login {
    position: relative;
    font-size: rem(14px);
    z-index: 10;
    transition: all .2s ease-in-out;

    @include mq(sm) {
      font-size: rem(16px);
    }
  }
}
</style>
