<template>
  <section
    class="banner"
    id="banner"
    v-if="isVisible('banner')"
    :style="{ backgroundImage: `url(${$i18n.translate(banner.background)})` }"
  >
    <app-link
      :link="banner.cta"
      class="cta bold"
    />
  </section>

  <section
    class="counter"
    id="counter"
    v-if="isVisible('counter') && counter.items.length > 0 && statistics"
  >
    <ul class="counter__ul">
      <li
        class="counter__li"
        v-for="(metric, index) in counter.items"
        :key="`${metric.type}_${index}`"
      >
        <div class="counter__stats">
          <app-date
            v-if="metric.type === 'date' && metric.date"
            :timestamp="metric.date"
            filter="differenceInCalendarDays"
          />

          <app-money
            v-else-if="metric.type === 'amount_collected'"
            :amount="statistics[metric.type]"
          />

          <p v-else-if="metric.type === 'sporting_statistics'">
            {{ getSportingStatistic(metric.sporting_statistics) }}
          </p>

          <p v-else>
            {{ statistics[metric.type] }}
          </p>
        </div>

        <p class="counter__label margin__t--6">
          {{ $i18n.translate(metric.text) }}
        </p>
      </li>
    </ul>
  </section>

  <section
    class="projects"
    id="projects"
    v-if="isVisible('projects') && project_items.length > 0"
  >
    <div class="wrapper">
      <h2
        class="title--h2"
        v-if="projects.title && $i18n.translate(projects.title)"
      >
        {{ $i18n.translate(projects.title) }}
      </h2>


      <div class="projects__list row margin__b--36">
        <template
          v-for="project in project_items"
          :key="project.id"
        >
          <section class="col-12 col-sm-6 col-md-3">
            <project-card
              class="project-card"
              :project="project"
            />
          </section>
        </template>
      </div>

      <div
        class="projects__footer margin__t--48"
        v-if="projects.cta && projects.cta.show && $i18n.translate(projects.cta.text)"
      >
        <app-link
          :link="projects.cta"
          class="cta bold"
        />
      </div>
    </div>
  </section>

  <section
    class="how_it_works"
    id="how_it_works"
    v-if="isVisible('how_it_works') && how_it_works.steps.length > 0"
  >
    <div class="wrapper">
      <h2
        class="title--h2"
        v-if="how_it_works.title && $i18n.translate(how_it_works.title)"
      >
        {{ $i18n.translate(how_it_works.title) }}
      </h2>

      <ol class="how_it_works__steps">
        <template
          v-for="(step, index) in how_it_works.steps"
          :key="`step_${index}`"
        >
          <li
            v-if="$i18n.translate(step.text)"
            class="how_it_works__step"
          >
            <h3 class="how_it_works__title">
              {{ $i18n.translate(step.text) }}
            </h3>

            <button
              v-if="step.section"
              @click="scrollTo(`#${step.section}`)"
              class="how_it_works__anchor"
            >
              <fa-icon :icon="['fas', 'chevron-down']" />
            </button>
          </li>
        </template>
      </ol>
    </div>
  </section>

  <section
    class="campaigns"
    id="campaigns"
    v-if="isVisible('campaigns') && campaign_items.length > 0"
  >
    <div class="wrapper">
      <h2
        class="title--h2"
        v-if="campaigns.title && $i18n.translate(campaigns.title)"
      >
        {{ $i18n.translate(campaigns.title) }}
      </h2>

      <div class="campaigns__list row justify-content-center">
        <template
          v-for="campaign in campaign_items"
          :key="campaign.id"
        >
          <section class="col-12 col-sm-6 col-md-4">
            <campaign-card
              class="campaign-card"
              :campaign="campaign"
            />
          </section>
        </template>
      </div>
    </div>
  </section>

  <section
    class="about"
    v-if="isVisible('about')"
  >
    <div class="wrapper">
      <h2
        class="title--h2"
        v-if="about.title && $i18n.translate(about.title)"
      >
        {{ $i18n.translate(about.title) }}
      </h2>
    </div>

    <div class="wrapper about__wrapper">
      <div class="about__media">
        <figure
          v-if="about.media.type === 'image' && $i18n.translate(about.media.image_url)"
          :style="{ background: `url(${$i18n.translate(about.media.image_url)})`}"
          class="about__image"
        />

        <fa-icon
          :icon="['far', 'image']"
          size="4x"
          v-else-if="about.media.type === 'image'"
        />

        <app-video-embed
          v-if="about.media.type === 'video' && $i18n.translate(about.media.video_url)"
          :src="$i18n.translate(about.media.video_url)"
          class="about__video"
        />

        <fa-icon
          :icon="['far', 'video']"
          size="4x"
          v-else-if="about.media.type === 'video'"
        />
      </div>

      <div
        class="about__content editor__content"
        v-html="$i18n.translate(about.text)"
      />
    </div>
  </section>

  <section
    class="quotes"
    v-if="isVisible('quotes') && quotes.items.length > 0"
  >
    <div class="wrapper">
      <div class="quotes__container padding__tb--48">
        <nav
          class="quotes__nav"
          v-if=" quotes.items.length > 1"
        >
          <button
            @click="goToPrev"
            class="quotes__nav-button"
          >
            <fa-icon :icon="['fas', 'chevron-left']" />
          </button>

          <button
            @click="goToNext"
            class="quotes__nav-button"
          >
            <fa-icon :icon="['fas', 'chevron-right']" />
          </button>
        </nav>


        <ul class="quotes__slider">
          <template
            v-for="(quote, index) in quotes.items"
            :key="`quote_${index}`"
          >
            <transition name="fade">
              <li
                v-if="quote_index === index"
                class="quote"
              >
                <blockquote>
                  <figure class="quote__figure">
                    <img
                      :src="$i18n.translate(quote.avatar)"
                      :alt="$i18n.translate(quote.name)"
                      v-if="$i18n.translate(quote.avatar)"
                    >

                    <fa-icon
                      :icon="['far', 'image']"
                      size="2x"
                      v-else
                    />
                  </figure>

                  <div
                    class="editor__content quote__content margin__t--24"
                    v-html="$i18n.translate(quote.quote)"
                  />

                  <figcaption class="quote__author margin__t--48">
                    <cite>{{ $i18n.translate(quote.name) }}</cite>
                  </figcaption>
                </blockquote>
              </li>
            </transition>
          </template>
        </ul>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, ref, onBeforeMount, defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import { pick as _pick, get as _get } from 'lodash'
import { projects_overview_options } from '@/configurations/general/components-default-data'
import useSection from '@/composables/modules/pages/useSection'
import { scrollTo } from '@/utils/utils'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const AppDate = defineAsyncComponent(() => import('&/atoms/AppDate'))
const AppMoney = defineAsyncComponent(() => import('&/atoms/AppMoney'))
const AppVideoEmbed = defineAsyncComponent(() => import('&/atoms/AppVideoEmbed'))
const ProjectCard = defineAsyncComponent(() => import('&/organisms/ProjectCard'))
const CampaignCard = defineAsyncComponent(() => import('&/organisms/CampaignCard'))

const statistics = ref(null)
const project_items = ref([])
const campaign_items = ref([])
const quote_index = ref(0)

const props = defineProps({
  page: { type: Object, required: true },
  sections: { type: Object, required: true }
})

const store = useStore()
const { getSectionAttributes, isVisible } = useSection(props.page)

const banner = computed(() => getSectionAttributes('banner'))
const counter = computed(() => getSectionAttributes('counter'))
const projects = computed(() => getSectionAttributes('projects'))
const sort = projects_overview_options.find(option => option.value === _get(projects.value, 'query.sort'))
const how_it_works = computed(() => getSectionAttributes('how_it_works'))
const campaigns = computed(() => getSectionAttributes('campaigns'))
const about = computed(() => getSectionAttributes('about'))
const quotes = computed(() => getSectionAttributes('quotes'))

onBeforeMount(() => {
  if (isVisible('counter')) {
    store.dispatch('client/fetchClientStatistics', ({ query: _pick(counter.value.query, ['campaign_id']) }))
      .then(metrics => statistics.value = metrics)
  }

  if (isVisible('projects')) {
    store.dispatch('projects/fetchProjects', ({ query: { ..._pick(projects.value.query, ['campaign_id', 'per_page']), ...sort } }))
      .then(projects => project_items.value = projects.items)
  }

  if (isVisible('campaigns')) {
    store.dispatch('campaigns/fetchCampaigns', ({ query: _pick(campaigns.value.query, ['campaign_id']) }))
      .then(campaigns => campaign_items.value = campaigns.items)
  }
})

const getSportingStatistic = key => {
  const value = _get(statistics.value, `sporting_statistics.${key}`, 0)
  let distance = value / 1000

  if (key === 'total_distance') return (distance % 1 !== 0) ? distance.toFixed(2) : distance
  if (key === 'total_time') return Math.round(value / 3600)

  return value
}

const goToPrev = () => quote_index.value == 0 ? quote_index.value = quotes.value.items.length - 1 : quote_index.value--
const goToNext = () => quote_index.value + 1 > quotes.value.items.length - 1 ? quote_index.value = 0 : quote_index.value++
</script>


<style lang="scss" scoped>
$color--primary: #131415;
$color--secondary: #FEFF00;

.wrapper {
  max-width: 1440px;
  padding: 0 $padding__base;
  margin: 0 auto;

  @include mq(sm) {
    padding: 0 $padding__base * 2;
  }

  @include mq(md) {
    padding: 0 $padding__base * 7;
  }
}

.title {
  &--h2 {
    font-size: rem(40px);
    line-height: 1.4;
    font-family: $font-family--secondary;
    text-align: center;
    margin-bottom: $margin__base * 2;
    text-transform: uppercase;
    font-weight: 500;

    @include mq(sm) {
      font-size: rem(60px);
      margin-bottom: $margin__base * 4;
    }
  }
}

:deep(.cta) {
  padding: $padding__base $padding__base * 2;
  background: $color--secondary;
  font-family: $font-family--secondary;
  color: $color--primary;
  text-transform: uppercase;
  font-size: rem(16px);
  line-height: .9;
  transition: all $transition__duration;
  white-space: nowrap;

  @include mq(sm) {
    padding: $padding__base * 1.5 $padding__base * 2.5;
    font-size: rem(20px);
  }

  &:hover {
    background: $color--primary;
    color: $white;
  }
}

.banner {
  position: relative;
  background-color: $color--primary;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @include aspect-ratio(1, 1);

  @include mq(sm) {
    margin-bottom: $margin__base * 2.5;

    @include aspect-ratio(1440, 560);
  }

  &:after {
    content: '';
    display: block;
    ;
    width: 100%;
    height: 280px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, #131415 92.64%);
    z-index: 0;
  }

  &:deep(.cta) {
    position: absolute;
    left: 50%;
    bottom: $margin__base * 5;
    transform: translateX(-50%);
    z-index: 10;
  }
}

.counter {
  text-align: center;
  font-size: rem(20px);
  line-height: 1;

  @include mq(sm) {
    padding: $padding__base * 2.5 0;
  }

  &__ul {
    margin: 0 auto;
    background: #F3F3F3;
    padding: $padding__base * 2.5 $padding__base;
    grid-template-columns: repeat(1fr);
    gap: $margin__base * 2;
    display: grid;

    @include mq(sm) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding: $padding__base * 6 $padding__base * 8.5;
      max-width: 90%;
    }

    @include mq(md) {
      max-width: 840px;
    }
  }

  &__stats {
    font-family: $font-family--secondary;
    font-size: rem(48px);
    font-weight: 500;
  }
}

.projects {
  padding: $padding__base * 2.5 0 $padding__base * 5;

  &__footer {
    text-align: center;
  }
}

.how_it_works {
  padding: $padding__base * 4 0;

  @include mq(sm) {
    padding: $padding__base * 5 0 $padding__base * 7.5;
  }

  &__steps {
    display: grid;
    grid-template-columns: 1fr;
    gap: $margin__base * 2;
    counter-reset: steps;
    padding-top: $padding__base * 3;

    @include mq(sm) {
      padding: 0;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__step {
    background: $color--secondary;
    padding: $padding__base * 7 $padding__base * 3;
    font-size: rem(26px);
    line-height: 1.03;
    text-align: center;
    counter-increment: steps;
    position: relative;

    &::before {
      content: "0" counter(steps);
      font-size: rem(120px);
      line-height: 1;
      color: #CFCFCF;
      font-family: $font-family--secondary;
      font-weight: 900;
      z-index: 989;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
      position: absolute;
      pointer-events: none;
    }

    &:nth-child(3n+1) {
      &::before {
        @include mq(sm) {
          left: $margin__base * 2;
          transform: translateY(-50%)
        }
      }
    }

    &:nth-child(3n+2) {
      &::before {
        @include mq(sm) {
          top: initial;
          bottom: 0;
          transform: translate(-50%, 50%);
        }
      }
    }

    &:nth-child(3n+3) {
      &::before {
        @include mq(sm) {
          left: initial;
          right: $margin__base * 2;
          transform: translateY(-50%);
        }
      }
    }
  }

  &__anchor {
    border: 1px solid $color--primary;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    font-size: rem(12px);
    position: absolute;
    left: 50%;
    bottom: 26px;
    transform: translateX(-50%);
    z-index: 999;
  }
}

.campaigns {
  padding: $padding__base * 4 0 $padding__base * 3;

  @include mq(sm) {
    padding: $padding__base * 7.5 0 $padding__base * 5;
  }

  &:deep(.campaign-card) {
    box-shadow: none;
    border-radius: 0;

    .card__infos {
      background: #F4F4F4;
      padding: $padding__base * 2.5 $padding__base * 3.5 $padding__base * 4;
    }

    .card__cover {
      border-radius: 0;
    }

    .infos__title {
      font-family: $font-family--secondary;
      font-weight: 500 !important;
      font-size: rem(32px);
      line-height: 1;
    }

    .infos__description {
      font-size: rem(20px);
      line-height: 1.1;
      margin-top: $margin__base;
    }

    .cta__container {
      margin-top: $margin__base * 1.5;
    }

    .cta__btn {
      padding: $padding__base $padding__base * 2;
      background: $color--secondary;
      font-family: $font-family--secondary;
      color: $color--primary;
      text-transform: uppercase;
      font-size: rem(16px);
      line-height: .9;
      transition: all $transition__duration;
      white-space: nowrap;
      font-weight: 700;

      @include mq(sm) {
        padding: $padding__base * 1.5 $padding__base * 2.5;
        font-size: rem(20px);
      }

      &:hover {
        background: $color--primary;
        color: $white;
      }
    }
  }
}

.about {
  padding: $padding__base * 2.5 0;

  @include mq(sm) {
    padding: $padding__base * 5 0;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
      gap: $margin__base * 2;
    }

    @include mq(lg) {
      gap: $margin__base * 10;
    }
  }

  &__media {
    background: #F4F4F4;
    position: relative;

    @include aspect-ratio(624, 377);

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__image,
  &__video.about__video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__content {
    padding: $padding__base * 2 $padding__base;

    &:deep(ul) {
      padding-left: 0;

      li {
        list-style: none;

        &::before {
          content: '';
          width: 9px;
          height: 9px;
          display: block;
          background: $color--secondary;
          border: 1px solid $color--primary;
          border-radius: 50%;
          margin: 7px calc($margin__base / 2) 0 0;
          float: left;
        }
      }
    }
  }
}

.quotes {
  text-align: center;
  padding: $padding__base * 2.5 0 0;

  @include mq(sm) {
    padding: $padding__base * 5 0 $padding__base * 16.5;
  }

  &__container {
    background: #F4F4F4;
    position: relative;
    transition: .2s all;
  }

  &__slider {
    max-width: 980px;
    margin: 0 auto;
    position: relative;
    padding: 0 $padding__base;

    @include mq(sm) {
      padding: 0 $padding__base * 7.5;
    }
  }

  &__nav {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: $padding__base * 2 $padding__base * 2 $padding__base * 4;

    @include mq(sm) {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      justify-content: space-between;
      height: 0;
      padding: 0 $padding__base * 2;
      z-index: 999;
    }

    @include mq(xl) {
      padding: 0 $padding__base * 7.5;
    }

    &-button {
      width: 42px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      border: 2px solid $color--primary;
      transition: all $transition__duration;

      &:not(:last-child) {
        margin-right: $margin__base * 2;
      }

      &:hover {
        background: $color--secondary;
      }
    }
  }

  .quote {
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 3s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
      opacity: 0;
    }

    &__figure {
      width: 95px;
      height: 95px;
      background: darken(#F4F4F4, 5);
      position: relative;
      overflow: hidden;
      border-radius: 50%;
      margin: 0 auto;

      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &__content {
      font-size: rem(22px);
      line-height: 1;
      font-family: $font-family--secondary;

      @include mq(sm) {
        font-size: rem(32px);
      }
    }

    &__author {
      font-size: rem(20px);

      &::before {
        content: '';
        display: block;
        margin: 0 auto $margin__base;
        width: 12px;
        height: 1px;
        background: $color--primary;
      }
    }
  }
}
</style>