export default [
  {
    name: 'custom_footer_internationalamnesty',
    path: 'modules/pages/internationalamnesty/FooterInternationalamnesty'
  },
  {
    name: 'custom_header_internationalamnesty',
    path: 'modules/pages/internationalamnesty/HeaderInternationalamnesty'
  },
  {
    name: 'custom_homepage_internationalamnesty',
    path: 'modules/pages/internationalamnesty/HomepageInternationalamnesty'
  }
]
