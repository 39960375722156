<template>
  <app-notification-bar v-if="isAllowed && !isVerified">
    {{ t('account_verification.notification_bar_msg') }}

    <send-verification-email-button class="link__color--white">
      {{ t('literal.click_here_to_resend') }}
    </send-verification-email-button>.
  </app-notification-bar>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from '@/vendors/i18n'
import { useAccountVerification } from '@/composables/app/useAccountVerification'

const AppNotificationBar = defineAsyncComponent(() => import('&/atoms/AppNotificationBar'))
const SendVerificationEmailButton = defineAsyncComponent(() => import('&/molecules/SendVerificationEmailButton'))

const route = useRoute()
const { t } = useI18n()
const { isVerified } = useAccountVerification()

const isAllowed = computed(() => !['ClientProcedureStep'].includes(route.name))
</script>