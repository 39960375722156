import { inject } from 'vue'
import { createI18n as I18n } from 'vue-i18n'
import enGB from './translations/en-GB.json'
import frFR from './translations/fr-FR.json'
import nlNL from './translations/nl-NL.json'
import deDE from './translations/de-DE.json'
import itIT from './translations/it-IT.json'
import available from './translations/available'
import store from '@/store'
import { get as _get, mapValues as _mapValues } from 'lodash'

const locale = 'en-GB'
const i18nSymbol = Symbol()

const i18n = I18n({
  locale,
  fallbackLocale: 'en-GB',
  messages: {
    'en-GB': enGB,
    'fr-FR': frFR,
    'nl-NL': nlNL,
    'de-DE': deDE,
    'it-IT': itIT
  }
})

i18n.global.translate = function (key, locale = i18n.global.locale) {
  const available_languages = _get(store.getters, 'client/client.active_languages', [])
  if (key && typeof key === 'object') return key[locale] || Object.keys(key).filter(k => available_languages.includes(k)).map(k => key[k]).find(k => k)
  if (key && typeof key === 'string') return key
  return false
}

i18n.global.interpolate = function (key, obj = {}) {
  let text = i18n.global.translate(key)
  const expression = /%%([a-z.-_]+)%%/
  const regex = new RegExp(expression)
  let result

  while ((result = regex.exec(text)) !== null)
    text = text.replace(expression, _get(obj, result[1], result[1]))

  return text
}

i18n.global.plural = function (key, count, obj = {}) {
  if (!key) return

  const [singular, plural] = i18n.global.translate(key).split(' | ')

  return i18n.global.interpolate(!plural || count === 1 ? singular : plural, obj)
}

i18n.global.getLocalesTranslations = function (key) {
  return _mapValues(available, ({ id }) => key ? i18n.global.t(key, {}, { locale: id }) : null)
}

i18n.global.getCurrentLanguage = function () {
  return Object.values(available).find(lang => lang.id === i18n.global.locale)
}

i18n.global.getLocale = function () {
  return i18n.global.locale
}

export default i18n.global

export const createI18n = {
  install: function (app) {
    app.use(i18n)
    app.provide(i18nSymbol, i18n.global)
  }
}

export function useI18n () {
  return inject(i18nSymbol)
}
