<template>
  <footer>
    <div class="wrapper">
      <ul class="block">
        <li class="block__el">
          <button
            @click.prevent="setRouteNavigation(section.attributes.column_1.faq)"
            class="link link--naked bold"
            :class="{'link__color--prim': isVisited(section.attributes.column_1.faq)}"
          >
            {{ $i18n.translate(section.attributes.column_1.faq.text) }}
          </button>
        </li>

        <li class="block__el">
          <a
            class="link link--naked bold"
            :href="$i18n.translate(section.attributes.column_1.website.url)"
            target="_blank"
          >
            {{ $i18n.translate(section.attributes.column_1.website.text) }}
          </a>
        </li>
      </ul>

      <div class="block">
        <address
          class="block__el block__el--address"
          v-html="$i18n.translate(section.attributes.column_2.address)"
        />
        <a
          class="block__el link link--naked bold"
          :href="`mailto:${$i18n.translate(section.attributes.column_2.email.url)}`"
        >
          {{ $i18n.translate(section.attributes.column_2.email.text) }}
        </a>
      </div>

      <div class="block">
        <div class="block__el">
          <p v-if="socialNetworks.length > 0">
            {{ $i18n.translate(section.attributes.column_3.networks) }}
          </p>

          <ul
            class="networks"
            v-if="socialNetworks.length > 0"
          >
            <li
              class="networks__el"
              v-for="(network, i) in socialNetworks"
              :key="'footer_menu_network_' + i"
            >
              <a
                class="link link--naked bold"
                :href="network.url[$i18n.locale]"
                target="_blank"
              >
                <span
                  class="networks__span"
                  v-if="network.text"
                >
                  {{ $i18n.translate(network.text) }}
                </span>
                <fa-icon
                  v-if="network.icon"
                  size="lg"
                  :icon="network.icon"
                />
              </a>
            </li>
          </ul>
        </div>

        <div>
          <button
            @click.prevent="setRouteNavigation(section.attributes.column_3.button)"
            class="link link--naked pointer bold padding--6 block__button"
          >
            {{ $i18n.translate(section.attributes.column_3.button.text) }}
          </button>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'

export default defineComponent({
  name: 'CustomFooterAZV',
  computed: {
    ...mapGetters({
      client: 'client/client'
    }),
    socialNetworks () {
      const links = _get(this.client, 'platform.social_networks', [])
      return links.length > 0 ? links.filter(link => link.url[this.$i18n.locale]) : []
    }
  },
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  mixins: [PageRouteNavigation]
})
</script>

<style lang="scss" scoped>
$color-principal: #acaa00;
$color-second: #438382;
$color-ter: #625731;

footer {
  background-color: $color-second;
  color:$white;
  font-family: $font-family--primary;
  font-size: rem(18px);
}

.wrapper {
  margin: 0 auto;
  padding: $padding__base * 3;

  @include mq(sm) {
    display: flex;
    padding: $padding__base * 3 $padding__base * 3;
  }

  @include mq(md) {
    padding: $padding__base * 3 $padding__base * 5;
  }

  @include mq(lg) {
    padding: $padding__base * 3 $padding__base * 10;
  }
}

.block {
  display: flex;
  flex-direction: column;
  margin-bottom: $margin__base * 3;
  text-align: center;

  @include mq(sm) {
    margin: 0;
    text-align: left;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__el {
    margin-bottom: $margin__base;

    &:last-child {
      margin-bottom: 0;
    }

    &--address span{
      display: block;
    }
  }

  @include mq(sm) {
    &:nth-child(1){
      margin-right: 100px;
    }

    &:last-child {
      align-self: end;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      margin-left: auto;
    }
  }

  &__button {
    border-bottom: 3px solid $white;

    &:hover, :focus {
      border-color: $color-principal;
    }
  }
}

.networks {
  display: flex;
  font-size: rem(14px);
  justify-content: center;
  margin-top: calc($margin__base / 2);

  @include mq(sm){
    justify-content: flex-start;
  }

  &__el {
    overflow: hidden;
    position: relative;

    &:not(:first-of-type){
      margin-left: $margin__base;
    }
  }

  &__span {
    position: absolute;
    left: 0;
    overflow: hidden;
    width: 0;
  }
}

.link {
  color: $white;
  transition: all $transition__duration;

  &:hover,
  &:focus {
    color: $color-principal;
  }
}

</style>
