<template>
  <footer
    class="footer"
    translate="no"
  >
    <div class="container footer__wrapper">
      <div>
        <router-link
          :to="{ name: 'Home', params: { locale } }"
          class="logo"
        >
          <img
            class="logo__img"
            :src="translate(logo) || client.logo.url"
            :alt="client.name"
          >
        </router-link>

        <div
          class="editor editor__content color--white margin__t--18 info"
          v-if="translate(info)"
          v-html="translate(info)"
        />

        <ul
          class="networks margin__t--42"
          v-if="socialNetworks.length"
        >
          <li
            class="networks__li"
            v-for="(network, index) in socialNetworks"
            :key="'network_' + index"
          >
            <a
              class="networks__a"
              :href="translate(network.url)"
              target="_blank"
              :aria-label="translate(network.text)"
            >
              <fa-icon
                v-if="network.icon"
                size="lg"
                :icon="network.icon"
              />
            </a>
          </li>
        </ul>
      </div>

      <nav>
        <ul class="navigation__ul">
          <li
            v-for="({ link }, index) in links"
            :key="`navigation_${index}`"
            class="navigation__li"
          >
            <app-link
              :link="link"
              class="navigation__link link--naked link__color--white-prim"
            />
          </li>
        </ul>
      </nav>

      <div
        class="d-flex align-items-center"
        v-for="(partner, key) in partners"
        :key="`partner_${key}`"
      >
        <template v-if="translate(partner.logo)">
          <a
            :href="translate(partner.url)"
            target="_blank"
            v-if="translate(partner.url)"
          >
            <img
              class="partner__logo"
              :src="translate(partner.logo)"
              :alt="translate(partner.name)"
            >
          </a>

          <img
            class="partner__logo"
            :src="translate(partner.logo)"
            :alt="translate(partner.name)"
            v-else
          >
        </template>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useClientStore } from '@/stores/ClientStore'
import { get as _get } from 'lodash'
import { useI18n } from '@/vendors/i18n'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))

const props = defineProps({
  section: { type: Object, required: true }
})

const { translate, getLocale } = useI18n()
const { getClient } = useClientStore()

const { client } = getClient()

const locale = computed(() => getLocale())
const logo = computed(() => _get(props, 'section.attributes.column_1.logo', {}))
const info = computed(() => _get(props, 'section.attributes.column_1.text', {}))
const links = computed(() => _get(props, 'section.attributes.column_2.nav', []))
const partners = computed(() => [_get(props, 'section.attributes.column_3.partner', {}), _get(props, 'section.attributes.column_4.partner', {})])

const socialNetworks = computed(() => _get(client.value, 'platform.social_networks', []).filter(network => translate(network.url)).map(network => {
  if (network.icon[1] === 'facebook-square') network.icon[1] = 'facebook-f'
  if (network.icon[1] === 'linkedin') network.icon[1] = 'linkedin-in'

  return network
}))
</script>

<style lang="scss" scoped>
$color--primary: #E60F8E;
$color--tertiary: #653472;
.footer {
  background: $color--tertiary;
  padding: $padding__base * 4 0;

  @include mq(sm) {
    padding-bottom: $padding__base * 6;
  }

  &__wrapper {
    display: grid;
    gap: $margin__base * 4 $margin__base * 2;
    grid-template-columns: 1fr;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(md) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.logo {
  &__img {
    height: 50px;
  }
}

.info {
  font-size: rem(14px);
}

.networks {
  display: grid;
  grid-template-columns: repeat(5, 36px);
  gap: 6px 12px;

  @include mq(md) {
    gap: 6px 18px;
    grid-template-columns: repeat(4, 36px);
  }

  &__li {
    &:not(:last-child) {
      margin-right: $margin__base;
    }
  }

  &__a {
    display: flex;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    background: $color--primary;
    transition: $transition__duration;
    border-radius: 50%;
    color: $white;
    font-size: rem(16px);

    &:hover {
      background: shade($color--primary);
    }
  }
}

.navigation {
  &__li {
    &:not(:last-child) {
      margin-bottom: calc($margin__base / 2);
    }

    &:deep() {
      .navigation__link {
        font-size: rem(18px);
        line-height: 1.2;

        @include mq(xl) {
          font-size: rem(20px);
        }

        &.is-active {
          color: $color--primary;
        }
      }
    }
  }
}

.partner {
  &__logo {
    max-height: 190px;
    max-width: 100%;
    width: auto;
  }
}
</style>
