<template>
  <template v-if="sections.length">
    <section
      class="card section padding__lr--24 padding__tb--18"
      v-for="(section, index) in sections"
      :key="`${section.name}_${index}`"
    >
      <procedure-blocks :blocks="section.blocks.getAvailableBlocks(procedure_execution)" />
    </section>
  </template>
</template>

<script setup>
import { inject } from 'vue'
import { useProcedureSections } from '@/composables/modules/procedures'

import ProcedureBlocks from '&/modules/procedures/layouts/ProcedureBlocks'

const procedure = inject('procedure')
const procedure_execution = inject('procedure_execution')

const { sections } = useProcedureSections({ procedure, procedure_execution })
</script>

<style lang="scss" scoped>
.section {
  &:not(:last-child) {
    margin-bottom: $margin__base * 2;
  }
}
</style>