<template>
  <footer
    v-if="section"
    class="footer"
  >
    <div class="footer__container">
      <div class="footer__wrapper">
        <section class="footer__column">
          <address
            class="footer__address margin__b--24"
            v-if="column1.address && column1.address.length > 0"
          >
            <fa-icon
              :icon="['fas', 'map-marker-alt']"
            />

            <template
              v-for="({ line }, i) in column1.address"
              :key="'footer_address_line_' + i"
            >
              <span>{{ $i18n.translate(line) }}</span>
            </template>
          </address>

          <address
            class="footer__address margin__b--24"
            v-if="column1.emails && column1.emails.length > 0"
          >
            <fa-icon
              :icon="['fal', 'envelope']"
            />

            <template
              v-for="({ email }, i) in column1.emails"
              :key="'footer_phone_' + i"
            >
              <a
                class="footer__link"
                :href="'mailto:' + $i18n.translate(email)"
              >
                {{ $i18n.translate(email) }}
              </a>
            </template>
          </address>

          <address
            class="footer__address margin__b--24"
            v-if="column1.phones && column1.phones.length > 0"
          >
            <fa-icon
              :icon="['fas', 'phone-alt']"
            />

            <template
              v-for="({ phone }, i) in column1.phones"
              :key="'footer_phone_' + i"
            >
              <a
                class="footer__link"
                :href="'tel:' + $i18n.translate(phone)"
              >
                {{ $i18n.translate(phone) }}
              </a>
            </template>
          </address>
        </section>

        <section class="footer__column">
          <ul
            :class="{'margin__t--12': column2.title}"
            v-if="column2.actions"
          >
            <li
              class="footer__li"
              v-for="({ link }, menuIndex) in column2.actions"
              :key="'footer_menu_item_' + menuIndex"
            >
              <a
                class="btn btn__size--classic btn__solid--prim bold footer__button"
                v-if="link"
                @click.prevent="setRouteNavigation(link)"
              >
                <fa-icon
                  class="margin__r--6"
                  v-if="link.icon"
                  :icon="link.icon"
                />
                {{ $i18n.translate(link.text) }}
              </a>
            </li>
          </ul>
        </section>

        <section class="footer__column">
          <h3 class="title--h3 bold">
            {{ $i18n.translate(column3.title) }}
          </h3>

          <ul
            class="networks__ul"
            v-if="socialNetworks.length > 0"
          >
            <li
              class="networks__li"
              v-for="(network, i) in socialNetworks"
              :key="'footer_menu_network_' + i"
            >
              <a
                class="networks__a"
                :href="network.url[$i18n.locale]"
                target="_blank"
              >
                <span
                  class="networks__span"
                  v-if="network.text"
                >
                  {{ $i18n.translate(network.text) }}
                </span>

                <fa-icon
                  v-if="network.icon"
                  size="lg"
                  :icon="network.icon"
                />
              </a>
            </li>
          </ul>
        </section>
      </div>
    </div>
  </footer>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'

export default defineComponent({
  name: 'CustomFooterUZGent',
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client'
    }),
    column1 () {
      return _get(this.section, 'attributes.column_1', {})
    },
    column2 () {
      return _get(this.section, 'attributes.column_2', {})
    },
    column3 () {
      return _get(this.section, 'attributes.column_3', {})
    },
    socialNetworks () {
      const links = _get(this.client, 'platform.social_networks') || []

      return links.length > 0
        ? links.map(link => {
          if (link.icon.includes('facebook-square')) {
            link.icon[1] = 'facebook-f'
          } else if (link.icon.includes('linkedin')) {
            link.icon[1] = 'linkedin-in'
          }

          return link
        })
          .filter(link => link.url[this.$i18n.locale])
        : []
    }
  }
})
</script>

<style lang="scss" scoped>
  $color--primary: #0169CA;
  $color--secondary: #131415;
  $color--tertiary: #7AA9EB;

  .footer {
    position: relative;
    background: $white;
    padding-top: 100px;

    &__container {
      border-top: 3px solid $color--primary;
      padding: 60px 0;
    }

    &__wrapper {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: $margin--base * 2;
      justify-items: left;
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 $padding__base * 2;

      @include mq(sm) {
        padding: 0;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
      }
    }

    &__li {
      margin: 6px 0 6px 0;

      @include mq(md) {
        margin: 12px 0;
      }
    }

    &__text {
      margin-top: $margin--base;
      color: $color--primary;
    }

    &__button {
      font-family: $font-family--secondary;
      border-radius: 0;
      text-transform: uppercase;
      transition: all $transition__duration;
      background-color: transparent;
      display: inline-block;
      text-align: center;
      font-size: rem(14px);
    }

    &__address {
      position: relative;
      font-weight: bold;
      color: $color--primary;
      padding-left: $padding__base * 2.5;
      font-size: rem(14px);

      & * {
        display: block;
      }

      svg {
        position: absolute;
        left: 0;
        top: 3px;

        &[data-icon="map-marker-alt"] {
          left: 2px;
        }
      }
    }

    &__link {
      color: $color--primary;
      position: relative;
      font-weight: bold;
      transition: all .3s;
      text-decoration: none;

      &:hover, &--active {
        color: $color--tertiary;
      }
    }
  }

  .networks {
    &__ul {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: $margin--base * 2;
      justify-items: center;
      align-items: center;
    }

    &__a {
      color: $color--primary;
      transition: all .3s;
      display: block;
      width: 28px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      border-radius: 50%;
      border: 1px solid $color--primary;
      position: relative;

      svg {
        font-size: rem(14px);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover, &:focus {
        border-color: $color--tertiary;
        color: $color--tertiary;
      }
    }

    &__span {
      display: none;
    }
  }

  .title {
    &--h3 {
      font-family: $font-family--secondary;
      font-size: rem(14px);
      color: $color--primary;
      margin-bottom: $margin__base * 1.5;
    }
  }
</style>
