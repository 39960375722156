import { PasteRule } from '@tiptap/core'
import { getRegexExpression } from '@/vendors/tiptap/helpers'

export default ({ type, expressions = [], handler }) => {
  const rules = []

  expressions
    .map(name => getRegexExpression(name))
    .filter(expression => expression)
    .forEach(regex => {
      rules.push(new PasteRule({
        find: regex,
        type: type,
        handler: props => handler({ chain: props.chain().deleteRange(props.range), input: props.match.input })
      }))
    })

  return rules
}