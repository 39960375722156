<template>
  <input
    type="text"
    class="input input--border input--radius"
    :id="id"
    :name="name"
    :autocomplete="autocomplete"
    :placeholder="placeholder"
    :disabled="disabled"
    v-model="model"
    v-bind="$attrs"
    @blur="handleInputBlurred()"
  >

  <p
    class="input--errors input--error"
    v-if="v && v.$error"
  >
    <template v-if="v.required?.$invalid">
      {{ t('errors.input_text_required') }}
    </template>

    <template v-else-if="v.maxLength?.$invalid">
      {{ t('errors.input_text_max_length', { value: v.maxLength.$params.max }) }}
    </template>

    <template v-else-if="v.minLength?.$invalid">
      {{ t('errors.input_text_min_length', { value: v.minLength.$params.min }) }}
    </template>

    <template v-else-if="v.alphaNumExtended?.$invalid">
      {{ t('errors.input_text_alpha_num') }}
    </template>

    <template v-else-if="v.validIban?.$invalid">
      {{ t('errors.input_text_invalid_iban') }}
    </template>

    <template v-else-if="v.external?.$invalid && v.external.$message">
      {{ v.external.$message }}
    </template>
  </p>
</template>

<script setup>
import { computed, toRefs } from 'vue'
import { useI18n } from '@/vendors/i18n'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  id: String,
  name: String,
  placeholder: { type: String, default: '...' },
  modelValue: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  autocomplete: String,
  v: Object
})

const { modelValue, v } = toRefs(props)

const { t } = useI18n()

const model = computed({
  get: () => modelValue.value,
  set: value => emit('update:modelValue', value)
})

const handleInputBlurred = () => v.value?.$touch()
</script>
