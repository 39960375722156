<template>
  <span :title="applyFilter('dd/MM/yyyy')">{{ applyFilter() }}</span>
</template>

<script>
import { defineComponent } from 'vue'
import { format, formatDistanceToNow, formatDistanceToNowStrict, formatDistanceStrict, differenceInCalendarDays } from 'date-fns'
import availables from '@/vendors/i18n/translations/available'
import en from 'date-fns/locale/en-US'
import fr from 'date-fns/locale/fr'
import nl from 'date-fns/locale/nl'
import de from 'date-fns/locale/de'
import it from 'date-fns/locale/it'

export default defineComponent({
  props: {
    timestamp: {
      required: true
    },
    filter: {
      type: String,
      default: 'formatDistanceToNow'
    }
  },
  data () {
    return {
      en,
      fr,
      nl,
      de,
      it
    }
  },
  computed: {
    timestampNormalized () {
      return new Date(this.timestamp)
    },
    getLocale () {
      const language = Object.values(availables).find((language) => language.id === this.$i18n.locale)
      return this[language.value]
    }
  },
  methods: {
    applyFilter (fltr) {
      const filter = fltr || this.filter
      if (filter === 'formatDistanceStrict') {
        return formatDistanceStrict(this.timestampNormalized, new Date(), { unit: 'day', locale: this.getLocale })
      } else if (filter === 'formatDistanceToNowStrict') {
        return formatDistanceToNowStrict(this.timestampNormalized, { locale: this.getLocale, addSuffix: true })
      } else if (filter === 'differenceInCalendarDays') {
        return Math.max(0, differenceInCalendarDays(this.timestampNormalized, new Date()))
      } else if (filter === 'dd.MM.yy') {
        return format(this.timestampNormalized, 'dd.MM.yy')
      } else if (filter === 'HH:mm') {
        return format(this.timestampNormalized, 'HH:mm')
      } else if (filter === 'dd/MM/yyyy') {
        return format(this.timestampNormalized, 'dd/MM/yyyy')
      } else if (filter === 'dd/MM/yyyy HH:mm') {
        return format(this.timestampNormalized, 'dd/MM/yyyy HH:mm')
      } else if (filter === 'dd/MM/yyyy – HH:mm') {
        return format(this.timestampNormalized, 'dd/MM/yyyy – HH:mm')
      } else if (filter === 'yyyy') {
        return format(this.timestampNormalized, 'yyyy')
      } else if (filter === 'dd MMMM Y') {
        return format(this.timestampNormalized, 'dd MMMM Y', { locale: this.getLocale })
      } else {
        return formatDistanceToNow(this.timestampNormalized, { locale: this.getLocale })
      }
    }
  }
})
</script>
