<template>
  <div class="custom">
    <div class="container">
      <section class="content__wrapper">
        <h2 class="custom__title">
          {{ $i18n.translate(intro.title) }}
        </h2>

        <p
          class="editor editor__content"
          v-html="$i18n.translate(intro.text)"
        />

        <hr class="separator separator__size--full border__color--vlg margin__tb--48">
      </section>

      <section
        class="blogs"
        v-for="(article, index) in articles.items"
        :key="`blog_article_${index}`"
      >
        <div class="content__wrapper">
          <h3 class="blogs__title">
            {{ $i18n.translate(article.title) }}
          </h3>

          <p
            class="editor editor__content"
            v-html="$i18n.translate(article.text)"
          />
        </div>

        <section
          class="custom__stories"
          v-if="article.news.items.length"
        >
          <article
            class="custom__story"
            v-for="(blog, index_2) in article.news.items"
            :key="`blog_article_${index}_${index_2}`"
          >
            <a
              class="story__link"
              :class="{'story__unlink': !isActive(blog.button) }"
              @click.prevent="setRouteNavigation(blog.button)"
            >
              <img
                class="story__image"
                :src="$i18n.translate(blog.image)"
                :alt="$i18n.translate(blog.title)"
              >

              <div class="story__content">
                <h3 class="story__title">
                  {{ $i18n.translate(blog.title) }}
                </h3>

                <span
                  class="story__button"
                  :class="{'story__button--disabled': !isActive(blog.button)}"
                  v-if="blog.button && blog.button.show"
                >
                  {{ $i18n.translate(blog.button.text) }}
                </span>
              </div>
            </a>
          </article>
        </section>

        <div class="content__wrapper">
          <hr class="separator separator__size--full border__color--vlg margin__tb--48">
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'

export default defineComponent({
  name: 'CustomTowalkagainLevensverhalen',
  components: {},
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  mixins: [
    PageRouteNavigation
  ],
  computed: {
    attributes () {
      return _get(this.section, 'attributes', {})
    },
    intro () {
      return _get(this.attributes, 'intro', {})
    },
    articles () {
      return _get(this.attributes, 'articles', [])
    }
  }
})
</script>

<style lang="scss" scoped>
.custom {
  padding: 30px 0 50px 0;
  background-color: #f7f7f1;

  @include mq(md) {
    padding: 60px 0 100px 0;
  }

  &__title {
    margin-bottom: 32px;
    font-size: 48px;
    font-family: $font-family--secondary;
    font-style: italic;
  }

  &__stories {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    margin: 50px 0;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(md) {
      grid-template-columns: repeat(3, 1fr);
      gap: 50px;
      margin: 100px 0;
    }
  }

  &__story {
    background-color: #fbfbfb;
    width: 100%;
  }
}

.story {
  &__link {
    display: block;
    box-shadow: 6px 10px 20px 0 #d5d5d580;
    height: 100%;
    transition: all .5s ease-in-out;

    &:hover {
      box-shadow: 0 10px 45px #6464644d;
      transform: translate3d(0, -2px, 0);

      .story__button:after {
        width: calc(100% - 48px);
      }
    }
  }

  &__unlink {
    cursor: default;

    &:hover {
      box-shadow: 6px 10px 20px 0 #d5d5d580;
      transform: translate3d(0, 0, 0);

      .story__button:after {
        width: 0%;
      }
    }
  }

  &__image {
    max-width: 100%;
  }

  &__content {
    padding: 30px 60px;
  }

  &__title {
    font-size: 19px;
    padding-left: 31px;
    color: #4d4d4e;
    font-weight: bold;
    font-family: $font-family--secondary;
    white-space: break-spaces;
    position: relative;

    &:before {
      content: image-path('towalkagain/triangle.svg');
      position: absolute;
      left: 0;
    }
  }

  &__button {
    display: inline-block;
    margin-top: 18px;
    padding: 12px 24px;
    background-color: $color--primary;
    border-radius: 3px;
    text-transform: uppercase;
    font-style: italic;
    font-weight: bold;
    font-size: 12px;
    font-family: $font-family--secondary;
    position: relative;

    &--disabled {
      background-color: $vlg;
      color: $mg;
      cursor: default;

      &:hover {
        background-color: $vlg;
        color: $mg;
        cursor: default;
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 0%;
      bottom: 9px;
      background: $black;
      height: 2px;
      margin-top: 5px;
      transition: width .2s .3s linear;
    }
  }
}

.blogs {
  &__title {
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 25px;
    font-weight: bold;
  }

  &:last-child .separator {
    display: none;
  }
}

.content__wrapper {
  max-width: 760px;
  margin: auto;
}
</style>
