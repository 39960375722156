<template>
  <app-share
    v-if="url"
    :whatsapp-text="getTranslationKey('whatsapp_text_url')"
    :email-text="getTranslationKey('email_text_url')"
    :x-text="getTranslationKey('twitter_text_url')"
    :target-url="url"
    :target-id="id"
    :target-type="type"
  />
</template>

<script setup>
import { computed, inject } from 'vue'
import { useProjectStore } from '@/stores/ProjectStore'
import { useProcedure } from '@/composables/modules/procedures'
import { useRouter } from 'vue-router'
import { get as _get } from 'lodash'

import AppShare from '&/molecules/AppShare'

const procedure = inject('procedure')

const { resolve } = useRouter()
const { getProject } = useProjectStore()

const { targetId, targetType } = useProcedure({ procedure })
const type = computed(() => targetType.value === 'Project' ? 'Project' : 'Procedure')


const { project } = getProject({ id: type.value === 'Project' ? targetId.value : null })

const id = computed(() => project.value ? targetId.value : _get(procedure.value, 'id'))
const procedureUrl = computed(() => window.location.origin + resolve({ name: 'ClientProcedure', params: { procedureSlug: procedure.value.slug } }).href)
const url = computed(() => project.value ? window.location.origin + resolve({ name: 'Project', params: { projectSlug: project.value.slug } }).href : procedureUrl.value)

const getTranslationKey = key => '$block_sharing_links.' + targetType.value.toLowerCase() + '_' + key
</script>
