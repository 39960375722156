<template>
  <div>
    <label
      :for="id"
      class="input__label"
    >
      {{ translate(label) }} <span v-if="block.is_required">*</span>
    </label>


    <input-select
      class="margin__t--6"
      v-model="model"
      :id="id"
      :placeholder="t('literal.select_an_option')"
      :options="options"
      :searchable="isSearchable"
      :v="validator"
    />
  </div>
</template>

<script setup>
import { inject, toRefs, computed } from 'vue'
import { useModule } from '@/composables/app/useModule'
import { useProcedureBlockValidator } from '@/composables/modules/procedures'
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'

import InputSelect from '&/atoms/InputSelect'

const props = defineProps({ block: { type: Object, required: true } })

const { block } = toRefs(props)

const procedure_execution = inject('procedure_execution')

const { t, translate } = useI18n()
const { id, label, model, attributes, defaultValue } = useModule({ component: block, parent: procedure_execution })
const { validator } = useProcedureBlockValidator({ validations: block.value.validations, state: model })

const options = computed(() => _get(attributes.value, 'options', []).map(option => ({ label: translate(option.text), value: option.value })))
const isSearchable = computed(() => options.value.length > 10)

if (!model.value && defaultValue.value) model.value = defaultValue.value
</script>
