<template>
  <main>
    <section
      class="hero"
      :style="{ backgroundImage: `url(${$i18n.translate(section.attributes.hero.image)})` }"
    >
      <div class="hero__wrapper">
        <h1
          class="hero__title margin__b--12"
          v-if="$i18n.translate(section.attributes.hero.title)"
        >
          {{ $i18n.translate(section.attributes.hero.title) }}
        </h1>
        <p
          class="hero__content margin__b--48"
          v-if="$i18n.translate(section.attributes.hero.subtitle)"
        >
          {{ $i18n.translate(section.attributes.hero.subtitle) }}
        </p>

        <div class="hero__ctas">
          <button
            v-if="section.attributes.hero.cta1"
            @click.prevent="setRouteNavigation(section.attributes.hero.cta1)"
            class="cta padding__tb--12 padding__lr--24"
          >
            {{ $i18n.translate(section.attributes.hero.cta1.text) }}
          </button>

          <button
            v-if="section.attributes.hero.cta2"
            @click.prevent="setRouteNavigation(section.attributes.hero.cta2)"
            class="link__color--white-prim link--naked pointer bold padding__tb--12 padding__lr--24"
          >
            {{ $i18n.translate(section.attributes.hero.cta2.text) }}
          </button>
        </div>
      </div>
    </section>

    <section
      class="stats"
      v-if="section.attributes.metrics && stats"
    >
      <ul
        class="stats__ul"
        v-if="stats"
      >
        <li
          class="stats__el"
          v-for="metric in section.attributes.metrics"
          :key="metric.index"
        >
          <span class="stats__strong bold margin__b--6">
            <app-date
              v-if="metric.type === 'date' && metric.date"
              :timestamp="metric.date"
              filter="differenceInCalendarDays"
            />
            <app-money
              v-else-if="metric.type === 'amount_collected'"
              :amount="stats[metric.type]"
            />
            <span v-else>
              {{ stats[metric.type] }}
            </span>
          </span>
          {{ $i18n.translate(metric.text) }}
        </li>
      </ul>
    </section>

    <section class="article">
      <div class="wrapper">
        <div class="article__content">
          <h2 class="title">
            {{ $i18n.translate(section.attributes.article.title) }}
          </h2>

          <div
            class="article__text"
            v-html="$i18n.translate(section.attributes.article.text)"
          />

          <button
            @click.prevent="setRouteNavigation(section.attributes.article.cta)"
            class="cta padding__tb--12 padding__lr--24"
          >
            {{ $i18n.translate(section.attributes.article.cta.text) }}
          </button>
        </div>

        <img :src="$i18n.translate(section.attributes.article.image)">
      </div>
    </section>

    <section
      class="projects"
      v-if="Object.values(projects).length"
    >
      <div class="wrapper">
        <h2
          class="title"
          v-if="$i18n.translate(section.attributes.action.title)"
        >
          {{ $i18n.translate(section.attributes.action.title) }}
        </h2>

        <div class="row margin__b--36">
          <template
            v-for="project in projects"
            :key="project.id"
          >
            <section class="col-12 col-sm-6 col-md-3">
              <project-card
                class="project-card"
                :project="project"
              />
            </section>
          </template>
        </div>

        <button
          @click.prevent="setRouteNavigation(section.attributes.action.cta)"
          class="cta padding__tb--12 padding__lr--24 margin__t--36"
        >
          {{ $i18n.translate(section.attributes.action.cta.text) }}
        </button>
      </div>
    </section>

    <section class="article article--darker">
      <div class="wrapper">
        <img :src="$i18n.translate(section.attributes.more.image)">

        <div class="article__content">
          <h2 class="title">
            {{ $i18n.translate(section.attributes.more.title) }}
          </h2>

          <div
            class="article__text"
            v-html="$i18n.translate(section.attributes.more.text)"
          />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import PageRouteNavigation from '%/PageRouteNavigation'
import ProjectCard from '&/organisms/ProjectCard'
import { get as _get } from 'lodash'
import { projects_overview_options } from '@/configurations/general/components-default-data'

export default defineComponent({
  name: 'CustomHomepageIlesDePaix',
  components: {
    ProjectCard,
    AppDate: defineAsyncComponent(() => import('&/atoms/AppDate')),
    AppMoney: defineAsyncComponent(() => import('&/atoms/AppMoney'))
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  mixins: [PageRouteNavigation],
  created () {
    this.fetchClientStatistics()
    this.fetchProjects({ query: this.projectQuery, removeStoredItems: true })
  },
  computed: {
    ...mapGetters({
      projects: 'projects/getProjects',
      stats: 'client/statistics'
    }),
    attributes () {
      return this.section.attributes
    },
    action () {
      return _get(this.attributes, 'action', {});
    },
    projectSort () {
      return projects_overview_options.find(o => o.value === _get(this.action, 'query.sort', 'activity_last'))?.query || { 'sort_by_field[field]': 'activity', 'sort_by_field[direction]': 'desc' }
    },
    projectQuery () {
      return { per_page: 4, ...this.projectSort, ..._get(this.action, 'query', {}) }
    }
  },
  methods: {
    ...mapActions({
      fetchProjects: 'projects/fetchProjects',
      fetchClientStatistics: 'client/fetchClientStatistics'
    })
  }
})
</script>

<style lang="scss" scoped>
  $color-principal: #adab00;
  $color-principal-darker: #797700;
  $color-second: #438382;
  $color-ter: #625731;

  main {
    background-color: $white;
    font-family: $font-family--primary;
  }

  .title {
    color: $color-ter;
    font-family: $font-family--secondary;
    font-size: rem(36px);
    font-weight: 900;
    line-height: 1.2;
  }

  .cta {
    background-color: $color-principal;
    border-radius: $radius__buttons;
    color: $white;
    cursor: pointer;
    display: block;
    font-weight: normal;
    position: relative;
    transition: all $transition__duration;

    &:hover,
    &:focus {
      background-color: $color-principal-darker;
    }
  }

  .link {
    cursor: pointer;

  }

  .wrapper {
    margin: 0 auto;
    max-width: 1400px;
    padding: $padding__base * 3;

    @include mq(sm) {
      padding: $padding__base * 2 $padding__base * 3;
    }

    @include mq(md) {
      padding: $padding__base * 4 $padding__base * 5;
    }

    @include mq(lg) {
      padding: $padding__base * 6 $padding__base * 10;
    }
  }

  .hero {
    color: $white;
    text-align: center;
    background-color: $vlg;
    background-position: center;
    background-size: cover;

    @include mq(sm) {
      text-align: left;
    }

    &__wrapper {
      background-position: center;
      background-size: cover;
      max-width: initial;
      padding: $padding__base * 4 $padding__base * 2;
      position: relative;

      &::before {
        content: '';
        display: block;
        background-color: rgba($color-ter, .2);
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;

        @include mq(sm) {
          display: none;
        }
      }

      @include mq(sm) {
        background: none;
        padding: $padding__base * 9 $padding__base * 5 $padding__base * 14;
      }

      @include mq(md) {
        padding: $padding__base * 9 $padding__base * 5 $padding__base * 14;
      }

      @include mq(lg) {
        padding: $padding__base * 9 $padding__base * 10 $padding__base * 14;
      }
    }

    &__title {
      font-family: $font-family--secondary;
      font-size: rem(32px);
      line-height: 1.4;
      position: relative;
      text-shadow: 1px 1px 2px rgba($black, .8);
      z-index: 1;

      @include mq(sm) {
        font-size: rem(47px);
        max-width: 450px;
      }
    }

    &__content {
      font-size: rem(20px);
      line-height: 1.4;
      position: relative;
      text-shadow: 1px 1px 2px rgba($black, .8);
      z-index: 1;

      @include mq(sm) {
        font-size: rem(27px);
        max-width: 450px;
      }
    }

    &__ctas {
      align-items: center;
      display: flex;
      flex-direction: column;
      font-size: 1.2rem;
      justify-content: center;
      position: relative;
      z-index: 1;

      @include mq(sm) {
        flex-direction: row;
        justify-content: left;
      }
    }
  }

  .stats {
    z-index: 1;
    font-size: rem(18px);
    color: $white;

    &__ul {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      background-color: $color-second;
      padding: $padding__base * 2;

      @include mq(sm) {
        border-radius: $radius__cards;
        flex-direction: row;
        padding: $padding__base * 3;
        margin: -70px auto 0;
        max-width: 850px;
        width: 90%;
      }
    }

    &__el {
      text-align: center;

      &:not(:last-of-type) {
        margin-bottom: $margin__base * 2;

        @include mq(sm) {
          margin-bottom: 0;
        }
      }

      @include mq(sm) {
        width: 33%;
      }
    }

    &__strong {
      display: block;
      font-size: 2em;
      line-height: 1;
    }

    &__white-strip {
      position: relative;

      &::after {
        content: '';
        height: 50%;
        width: 100%;
        background-color: $white;
        position: absolute;
        z-index: 0;
        bottom: 0;
        left: 0;
      }
    }
  }

  .article {
    @include mq(md) {
      & .wrapper {
        align-items: center;
        display: flex;
      }
    }

    &__content {
      margin-bottom: $margin__base * 5;

      @include mq(md) {
        margin: 0 70px 0 0;
        width: calc(50% - 35px);
      }
    }

    &__text {
      color: $color-ter;
      font-size: rem(18px);
      line-height: 1.4;
      margin: $margin__base * 2 0;

      & p:not(:last-of-type) {
        margin-bottom: $margin__base * 3;
      }
    }

    & img {
      display: block;
      margin: auto;
      max-width: 640px;
      width: 100%;

      @include mq(md) {
        width: calc(50% - 35px);
      }
    }

    &--darker {
      background-color: #ecece8;

      & .article__content {
        margin-top: $margin__base * 5;

        @include mq(md) {
          margin: 0 0 0 70px;
        }
      }
    }

    & .btn {
      display: inline-block;
    }
  }

  .projects {
    & .wrapper {
      max-width: initial;
    }

    & .title {
      margin-bottom: $margin__base * 4;
      text-align: center;
    }

    & .btn {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    & .card {
      margin: 0;
    }

    & .cta {
      margin-left: auto;
      margin-right: auto;
    }
  }
</style>
