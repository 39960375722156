<template>
  <header
    ref="header"
    class="header bg__color--white"
    :class="{ 'is-minimal': is_minimal, 'is-show': show_mobile_menu }"
  >
    <div class="header__topbar">
      <div class="container header__container">
        <router-link
          class="logo"
          :to="{ name: 'Home', params: { locale } }"
        >
          <img
            class="logo__img"
            :src="translate(logo.image)"
            :alt="client.name"
          >
        </router-link>

        <div class="header__wrapper header__wrapper--top">
          <app-session-button class="header__session">
            <template #login>
              {{ t('literal.login') }}
            </template>
          </app-session-button>

          <language-switcher class="language-switcher" />
        </div>
      </div>
    </div>

    <div class="container header__container header__mainbar">
      <router-link
        class="minial_logo"
        :to="{ name: 'Home', params: { locale } }"
      >
        <img
          class="minial_logo__img"
          :src="show_mobile_menu ? translate(logo.image) : translate(logo.minial_image)"
          :alt="client.name"
        >
      </router-link>

      <div class="header__wrapper">
        <nav
          class="main-nav header__nav"
          v-if="navigation.length"
        >
          <ul class="main-nav__ul">
            <li
              class="main-nav__li"
              v-for="({ link }, key) in navigation"
              :key="`link_${key}`"
            >
              <app-link
                :link="link"
                class="main-nav__a"
              />
            </li>
          </ul>
        </nav>

        <app-link
          :link="cta"
          class="btn btn__size--small cta"
        />

        <app-burger-menu
          class="burger-menu"
          :state="show_mobile_menu"
          @toggle="toggleMobileMenu"
        />
      </div>
    </div>

    <transition name="fade-mobile-menu">
      <div
        class="mobile-menu"
        v-if="show_mobile_menu"
      >
        <div class="container">
          <nav
            class="mobile-nav"
            v-if="navigation.length"
          >
            <ul>
              <li
                v-for="({ link }, key) in navigation"
                :key="`link_${key}`"
              >
                <app-link
                  :link="link"
                  class="mobile-nav__a"
                />
              </li>
            </ul>
          </nav>

          <div class="mobile-menu__wrapper">
            <app-session-button class="header__session">
              <template #login>
                {{ t('literal.login') }}
              </template>
            </app-session-button>

            <language-switcher
              class="mobile-language-switcher"
              v-slot="{ options, updateLanguage, isActive }"
            >
              <ul class="mobile-language-switcher__ul">
                <li
                  class="mobile-language-switcher__li"
                  v-for="language in options"
                  :key="language.id"
                >
                  <button
                    class="mobile-language-switcher__a"
                    :class="{'mobile-language-switcher__a--is-active': isActive(language.id)}"
                    type="button"
                    @click.prevent="updateLanguage(language.id)"
                  >
                    {{ language.label.value.toUpperCase() }}
                  </button>
                </li>
              </ul>
            </language-switcher>
          </div>
        </div>
      </div>
    </transition>
  </header>
</template>

<script setup>
import { ref, computed, watch, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import { useClientStore } from '@/stores/ClientStore'
import { get as _get } from 'lodash'
import { useI18n } from '@/vendors/i18n'
import { useWindowScroll } from '@/composables/app/useWindowScroll'
import { scrollTo } from '@/utils/utils'

import AppLink from '&/atoms/AppLink'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
import LanguageSwitcher from '&/atoms/LanguageSwitcher'
import AppSessionButton from '&/organisms/AppSessionButton'

const props = defineProps({
  section: { type: Object, required: true }
})

const { getClient } = useClientStore()
const { t, translate } = useI18n()
const { locale } = toRefs(useI18n())
const route = useRoute()
const { top } = useWindowScroll()
const { client } = getClient()

const header = ref()
const show_mobile_menu = ref(false)
const is_minimal = ref(false)
const is_animating = ref(false)

const logo = computed(() => _get(props, 'section.attributes.logo', {}))
const navigation = computed(() => _get(props, 'section.attributes.navigation', []))
const cta = computed(() => _get(props, 'section.attributes.cta', {}))

const toggleMobileMenu = () => {
  scrollTo()

  setTimeout(() => show_mobile_menu.value = !show_mobile_menu.value, 100)
}

watch(top, () => {
  const position = top.value + (is_minimal.value ? 60 : 0)

  if (is_animating.value) return
  if (is_minimal.value && position > 60) return
  if (!is_minimal.value && position <= 60) return

  is_animating.value = true

  is_minimal.value = position > 60

  setTimeout(() => {
    if (is_minimal.value) window.scrollTo({ top: top.value + 1 })

    is_animating.value = false
  }, 300)
})

watch(route, () => show_mobile_menu.value = false)
</script>

<style lang="scss" scoped>
$color--primary: #e00234;
$color--secondary: #001ca8;

.container {
  width: 90%;
  max-width: 1596px;
  padding: 0;
  margin: 0 auto;
}

.header {
  position: sticky;
  top: 0;
  z-index: 999;

  &.is-show {
    box-shadow: 0 0 10px 2px rgba(0,0,0,.16);

    @media (min-width: 1200px) {
      box-shadow: none;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 auto;
    gap: 12px;
    transition: all .3s ease-in-out;

    &--top {
      gap: 24px;
      font-size: rem(23px);
      padding: 32px 0;

      .is-minimal & {
        padding: 5px 0;
      }
    }
  }

  &__topbar {
    background: #f2f2f2;
    display: none;

    @media (min-width: 1200px) {
      display: block;
    }
  }

  &__mainbar {
    padding: 11px 0;
    gap: 12px;

    @media (min-width: 1200px) {
      padding: 0;
    }
  }

  :deep(.header__session) {
    color: $color--primary;
  }
}

.logo {
  padding: 16px 7px 0;
  background: $white;
  align-self: flex-end;

  @media (min-width: 1600px) {
    padding-top: 19px;
  }

  .is-minimal & {
    padding: 0px 7px 0;
  }

  &__img {
    display: block;
    width: auto;
    height: 66px;
    transition: height 0.3s ease;

    @media (min-width: 1600px) {
      height: 79px;
    }

    .is-minimal & {
      height: 43px;
    }
  }
}

.minial_logo {
  @media (min-width: 1200px) {
    display: none;
  }

  &__img {
    display: block;
    width: auto;
    height: 46px;
  }
}

.main-nav {
  display: none;

  @media (min-width: 1200px) {
    display: block;
  }

  &__ul {
    display: flex;
    align-items: center;
  }

  :deep(.main-nav__a) {
    display: block;
    padding: 37px 18px;
    line-height: 1.25;
    font-size: rem(22px);
    font-weight: bold;
    transition: all .3s ease-in-out;
    color: $black;
    transition: all 0.3s ease;

    .is-minimal & {
      padding: 13px 18px;
    }

    @media (min-width: 1250px) {
      font-size: rem(24px);
      padding: 37px 22px;

      .is-minimal & {
        padding: 13px 22px;
      }
    }

    &.is-active,
    &:hover {
      color: $white;
      background: $color--secondary;
    }
  }
}

:deep(.cta) {
  padding: 10px 18px;
  background: $color--primary;
  border-radius: 5px;
  color: $white;
  text-transform: uppercase;
  font-weight: bold;
  transition: all .3s ease-in-out;
  line-height: 1;
  font-size: rem(18px);

  .is-show & {
    display: none;

    @media (min-width: 1200px) {
      display: block;
    }
  }

  @include mq(sm) {
    padding: 10px 28px;
  }

  @include mq(md) {
    font-size: rem(25px);

    &:hover {
      transform: matrix(0.996195, -0.0871557, 0.0871557, 0.996195, 0, 0);
    }
  }

  @media (min-width: 1600px) {
    padding: 13px 35px;
    font-size: rem(35px);

    .is-minimal & {
      font-size: rem(25px);
      padding: 10px 28px;
    }
  }
}

:deep(.burger-menu) {
  .menu {
    &__label {
      display: none;
    }

    &__line {
      height: 6px;
      width: 36px;
      background: $color--primary;
    }

    &__close {
      width: 36px;
      height: 36px;

      &:before, &:after {
        background: $color--primary;
      }

      &::before {
        width: 6px;
        height: 36px;
        margin-left: -3px;
      }

      &::after {
        height: 6px;
        width: 36px;
        margin-top: -3px;
      }
    }
  }
}

.burger-menu {
  @media (min-width: 1200px) {
    display: none;
  }
}

.mobile-menu {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  background: $white;
  padding-top: 90px;
  padding-bottom: 40px;
  z-index: -1;
  font-size: rem(24px);
  line-height: 1.2;
  overflow: auto;

  @media (min-width: 1200px) {
    display: none;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    padding: 20px 0;
  }
}

.mobile-nav {
  :deep(.mobile-nav__a) {
    font-size: rem(30px);
    line-height: 1.2;
    padding: 22px 0;
    display: block;
    position: relative;

    @include mq(sm) {
      font-size: rem(35px);
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      height: 100%;
      background: $color--secondary;
      opacity: 0;
      z-index: -1;
    }

    &.is-active,
    &:hover {
      color: $white;

      &::before {
        opacity: 1;
      }
    }
  }
}

.mobile-language-switcher {
  &__ul {
    display: flex;
    gap: 12px;

    @include mq(sm) {
      gap: 30px;
    }
  }

  &__a {
    &--is-active {
      color: $color--secondary;
    }
  }
}

.fade-mobile-menu-enter-active {
  animation: fadeTranslationY .5s ease-in-out;
}

.fade-mobile-menu-leave-active {
  animation: fadeTranslationY .5s ease-in-out reverse;
}

@keyframes fadeTranslationY {
  from {
    transform: translateY(-50px);
    opacity: 0;
    max-height: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 100%;
    max-height: 100vh;
  }
}
</style>
