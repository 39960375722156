<template>
  <div class="home">
    <section
      class="banner"
      :style="`background-image: url(${$i18n.translate(banner.background)})`"
      v-if="$i18n.translate(banner.background) || $i18n.translate(banner.video)"
    >
      <video
        class="banner__video"
        width="1920"
        height="600"
        autoplay
        muted
        loop
        playsinline
        v-if="$i18n.translate(banner.video)"
      >
        <source
          :src="$i18n.translate(banner.video)"
          type="video/mp4"
        >
      </video>
    </section>

    <section
      class="infos"
      v-if="$i18n.translate(infos.text)"
    >
      <div class="infos__wrapper">
        <div
          class="editor editor__content infos__text"
          v-html="$i18n.translate(infos.text)"
        />
      </div>
    </section>

    <section
      class="how_it_works"
      v-if="how_it_works.steps && how_it_works.steps.length > 0"
    >
      <div class="how_it_works__wrapper">
        <h2
          class="how_it_works__title"
          v-if="$i18n.translate(how_it_works.title)"
        >
          {{ $i18n.translate(how_it_works.title) }}
        </h2>

        <section class="how_it_works__steps">
          <article
            class="how_it_works__step"
            v-for="(step, index) in how_it_works.steps"
            :key="'step_element_' + index"
          >
            <figure
              class="how_it_works__img"
              v-if="$i18n.translate(step.img_src)"
            >
              <img
                class="img"
                :src="$i18n.translate(step.img_src)"
                alt=""
              >
            </figure>

            <div
              class="margin__t--18 editor editor__content"
              v-html="$i18n.translate(step.text)"
            />
          </article>
        </section>
      </div>
    </section>

    <section class="course">
      <div class="course__wrapper">
        <header class="course__header">
          <h2 class="course__title">
            {{ $i18n.translate(plan.title) }}
          </h2>

          <h3
            class="course__subtitle editor editor__content"
            v-html="$i18n.translate(plan.text)"
          />
        </header>

        <main class="course__main">
          <article class="course__descp">
            <div class="course__descp__wrapper">
              <h4
                class="course__descp__title"
                v-if="$i18n.translate(plan.nature.title)"
              >
                {{ $i18n.translate(plan.nature.title) }}
              </h4>

              <ul class="course__descp__list">
                <li
                  class="icon-canoe course__descp__list__item"
                  v-if="$i18n.translate(plan.nature.canoe)"
                >
                  {{ $i18n.translate(plan.nature.canoe) }}
                </li>

                <li
                  class="icon-trekking course__descp__list__item"
                  v-if="$i18n.translate(plan.nature.walk)"
                >
                  {{ $i18n.translate(plan.nature.walk) }}
                </li>

                <li
                  class="icon-bicycle course__descp__list__item"
                  v-if="$i18n.translate(plan.nature.bike)"
                >
                  {{ $i18n.translate(plan.nature.bike) }}
                </li>

                <li
                  class="icon-bbq course__descp__list__item"
                  v-if="$i18n.translate(plan.nature.bbq)"
                >
                  {{ $i18n.translate(plan.nature.bbq) }}
                </li>
              </ul>

              <a
                v-if="plan.nature.button && plan.nature.button.show"
                @click.prevent="setRouteNavigation(plan.nature.button)"
                class="cta-link cta-link--arrow course__descp__cta"
              >
                <i class="icon" />
                {{ $i18n.translate(plan.nature.button.text) }}
              </a>
            </div>
          </article>

          <article class="course__descp">
            <div class="course__descp__wrapper">
              <h4
                class="course__descp__title"
                v-if="$i18n.translate(plan.sport.title)"
              >
                {{ $i18n.translate(plan.sport.title) }}
              </h4>

              <ul class="course__descp__list">
                <li
                  class="icon-bicycle course__descp__list__item"
                  v-if="$i18n.translate(plan.sport.bike)"
                >
                  {{ $i18n.translate(plan.sport.bike) }}
                </li>

                <li
                  class="icon-run course__descp__list__item"
                  v-if="$i18n.translate(plan.sport.run)"
                >
                  {{ $i18n.translate(plan.sport.run) }}
                </li>

                <li
                  class="icon-canoe course__descp__list__item"
                  v-if="$i18n.translate(plan.sport.canoe)"
                >
                  {{ $i18n.translate(plan.sport.canoe) }}
                </li>

                <li
                  class="icon-bbq course__descp__list__item"
                  v-if="$i18n.translate(plan.sport.bbq)"
                >
                  {{ $i18n.translate(plan.sport.bbq) }}
                </li>
              </ul>

              <a
                v-if="plan.sport.button && plan.sport.button.show"
                @click.prevent="setRouteNavigation(plan.sport.button)"
                class="cta-link cta-link--arrow course__descp__cta"
              >
                <i class="icon" />
                {{ $i18n.translate(plan.sport.button.text) }}
              </a>
            </div>
          </article>
        </main>

        <footer v-if="$i18n.translate(plan.details)">
          <small class="course__details">
            {{ $i18n.translate(plan.details) }}
          </small>
        </footer>
      </div>
    </section>

    <section class="where">
      <div class="where__wrapper">
        <header v-if="$i18n.translate(where.title) ">
          <h2 class="where__title">
            {{ $i18n.translate(where.title) }}
          </h2>
        </header>

        <main
          v-if="where.buttons.length > 0"
          class="where__main"
        >
          <template v-for="({ button }, index) in where.buttons">
            <button
              v-if="button && button.show"
              :key="`home_button_${index}`"
              @click.prevent="setRouteNavigation(button)"
              class="btn btn__color--prim btn__size--medium"
            >
              {{ $i18n.translate(button.text) }}
            </button>
          </template>
        </main>
      </div>
    </section>

    <section
      class="video__section"
      v-if="aftermovie.items.length"
    >
      <template
        v-for="(movie, index) in aftermovie.items"
        :key="`home_movie_${index}`"
      >
        <div
          class="video"
          :style="`background-image: url(${$i18n.translate(movie.image)})`"
        >
          <div class="video__wrapper">
            <h2 class="video__title">
              {{ $i18n.translate(movie.title) }}
            </h2>
          </div>

          <button
            class="video__player"
            v-if="$i18n.translate(movie.media.video_url)"
            @click.prevent="openVideoAppModal($i18n.translate(movie.media.video_url))"
          />
        </div>
      </template>
    </section>

    <section
      class="stats"
      v-if="stats.length"
    >
      <template
        v-for="(metric, index) in stats"
        :key="`home_metric_${index}`"
      >
        <div class="stats__wrapper">
          <h4 class="stats__list__title">
            {{ $i18n.translate(metric.title) }}
          </h4>

          <ul
            class="stats__list"
            v-if="metric.items.length"
          >
            <li
              class="stats__list__item"
              v-for="(item, i) in metric.items"
              :key="`home_metric_${index}_${i}`"
            >
              <h4 class="stats__list__subtitle">
                {{ $i18n.translate(item.text) }}
              </h4>

              <div v-if="metric.data">
                <app-date
                  class="stats__list__number"
                  v-if="item.type === 'date' && item.date"
                  :timestamp="item.date"
                  filter="differenceInCalendarDays"
                />

                <app-money
                  class="stats__list__number"
                  v-else-if="item.type === 'amount_collected'"
                  :amount="metric.data[item.type]"
                />

                <span
                  class="stats__list__number"
                  v-else
                >
                  {{ metric.data[item.type] || '0' }}
                </span>
              </div>

              <a
                v-if="item.button && item.button.show"
                @click.prevent="setRouteNavigation(item.button)"
                class="cta-link cta-link--arrow course__descp__cta"
              >
                <i class="icon" />
                {{ $i18n.translate(item.button.text) }}
              </a>
            </li>
          </ul>
        </div>
      </template>
    </section>

    <section class="quotes">
      <div class="quotes__wrapper">
        <app-slides
          class="quotes__slides"
          v-if="quotes.length"
          :options="quotes_options"
          :autoplay="true"
        >
          <article
            class="glider__slide"
            v-for="(quote, index) in quotes"
            :key="'quote_' + index"
          >
            <div class="quotes__item">
              <svg
                width="30"
                height="22"
                class="quotes__icon"
              >
                <path
                  d="M17.521 0v9.033c0 6.972 4.664 11.697 11.229 12.967l1.244-2.629C26.954 18.251 25 14.925 25 12.222h5V0H17.521ZM0 0v9.033C0 16.005 4.685 20.731 11.25 22l1.245-2.629C9.454 18.251 7.5 14.925 7.5 12.222h4.979V0H0Z"
                  fill-rule="nonzero"
                />
              </svg>

              <p
                class="editor editor__content"
                v-html="$i18n.translate(quote.text)"
              />

              <p class="quotes__author">
                {{ $i18n.translate(quote.author_name) }}
              </p>

              <p class="quotes__job">
                {{ $i18n.translate(quote.author_job) }}
              </p>
            </div>
          </article>

          <template #prev>
            <i class="arrow arrow--left" />
          </template>

          <template #next>
            <i class="arrow arrow--right" />
          </template>
        </app-slides>
      </div>
    </section>

    <section class="gallery">
      <main class="gallery__wrapper">
        <div
          class="gallery__grid"
          :class="{'gallery__grid--reverse': index % 2,
                   'gallery__grid--one': pictureLength % 3 == 1 && index == pictures.length - 1,
                   'gallery__grid--two': pictureLength % 3 == 2 && index == pictures.length - 1}"
          v-for="(p, index) in pictures"
          :key="`home_gallery_${index}`"
        >
          <a
            class="gallery__link"
            target="_blank"
            v-for="(picture, index2) in p"
            :key="`home_gallery_${index}_${index2}`"
            :href="$i18n.translate(picture.link)"
          >
            <figure
              class="gallery__image"
              :style="{backgroundImage: `url(${ $i18n.translate(picture.image) })`}"
            />
          </a>
        </div>
      </main>
    </section>

    <section class="sponsors">
      <div class="sponsors__wrapper">
        <h2 class="sponsors__title">
          {{ $i18n.translate(sponsors.title) }}
        </h2>

        <app-slides :options="sponsors_options">
          <article
            class="glider__slide"
            v-for="(sponsor, index) in sponsors.items"
            :key="'sponsor_' + index"
          >
            <a
              class="glider__link"
              :class="{'cursor--default': !$i18n.translate(sponsor.website)}"
              target="_blank"
              :href="$i18n.translate(sponsor.website)"
              v-if="$i18n.translate(sponsor.website)"
            >
              <img
                class="glider__img"
                :src="$i18n.translate(sponsor.logo)"
                :alt="$i18n.translate(sponsor.name)"
              >
            </a>

            <img
              v-else
              class="glider__img"
              :src="$i18n.translate(sponsor.logo)"
              :alt="$i18n.translate(sponsor.name)"
            >
          </article>

          <template #prev>
            <i class="arrow arrow--left" />
          </template>

          <template #next>
            <i class="arrow arrow--right" />
          </template>
        </app-slides>
      </div>
    </section>

    <app-modal
      v-if="showVideoAppModal"
      size="xl"
      class="modal"
      @close="showVideoAppModal = false"
    >
      <template #body>
        <app-video-embed
          :src="video_url"
          class="live__video"
        />
      </template>
    </app-modal>
  </div>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { get as _get, set as _set, chunk as _chunk, cloneDeep as _cloneDeep } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'
import AppSlides from '&/organisms/AppSlides'

export default defineComponent({
  name: 'CustomHomepageKajaktrail',
  components: {
    AppSlides,
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal')),
    AppDate: defineAsyncComponent(() => import('&/atoms/AppDate')),
    AppMoney: defineAsyncComponent(() => import('&/atoms/AppMoney')),
    AppVideoEmbed: defineAsyncComponent(() => import('&/atoms/AppVideoEmbed'))
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showVideoAppModal: false,
      video_url: null,
      quotes_options: {
        slidesToShow: 1,
        rewind: true,
        responsive: [
          {
            breakpoint: 850,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 1125,
            settings: {
              slidesToShow: 3
            }
          }
        ]
      },
      sponsors_options: {
        slidesToShow: 1,
        responsive: [
          {
            breakpoint: 1125,
            settings: {
              slidesToShow: 6
            }
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 5
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 2
            }
          }
        ]
      },
      stats: []
    }
  },
  created () {
    this.stats = _cloneDeep(this.metrics)

    this.stats.map(metric => {
      this.fetchClientStatistics({ query: { campaign_id: metric.campaign_id || [], procedure_id: metric.procedure_id || [] } })
        .then(stats => {
          _set(metric, 'data', stats)
          return metric
        })
    })
  },
  mixins: [
    PageRouteNavigation
  ],
  computed: {
    ...mapGetters({
      statistics: 'client/statistics'
    }),
    attributes () {
      return _get(this.section, 'attributes', {})
    },
    plan () {
      return _get(this.attributes, 'plan', {})
    },
    where () {
      return _get(this.attributes, 'where', {})
    },
    banner () {
      return _get(this.attributes, 'banner', {})
    },
    infos () {
      return _get(this.attributes, 'infos', {})
    },
    how_it_works () {
      return _get(this.attributes, 'how_it_works', {})
    },
    quotes () {
      return _get(this.attributes, 'quotes.items', {})
    },
    metrics () {
      return _get(this.attributes, 'metrics', {})
    },
    pictures () {
      return _chunk(_get(this.attributes, 'pictures.items', []), 3)
    },
    picturesItem () {
      return _get(this.attributes, 'pictures.items', {})
    },
    pictureLength () {
      return this.picturesItem.length
    },
    sponsors () {
      return _get(this.attributes, 'sponsors', {})
    },
    aftermovie () {
      return _get(this.attributes, 'aftermovie', {})
    }
  },
  methods: {
    ...mapActions({
      fetchClientStatistics: 'client/fetchClientStatistics'
    }),
    openVideoAppModal (url) {
      this.video_url = url
      this.showVideoAppModal = true
    }
  }
})
</script>

<style lang="scss" scoped>
.modal :deep() .modal__body {
  padding: 40px;
}

.home {
  background: image-path('kajaktrail/bg_brush.jpeg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.banner {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  height: 250px;

  @include mq(sm) {
    height: 450px;
  }

  @include mq(md) {
    @include aspect-ratio(1920, 600);
    height: auto;
  }

  &__video {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: 100%;
  }
}

.infos {
  text-align: center;

  &__wrapper {
    padding: 30px;
    max-width: 1120px;
    margin: 0 auto;

    @include mq(lg) {
      padding: 60px 180px 0;
    }
  }

  &__text {
    font-size: rem(20px);
  }
}

.how_it_works {
  text-align: center;

  &__wrapper {
    padding: 30px;
    max-width: 1440px;
    margin: 0 auto;

    @include mq(lg) {
      padding: 60px 30px 0;
    }
  }

  &__title {
    font-size: 30px;
    line-height: 1.5;
    margin-bottom: $margin__base * 2.5;
    color: $color--primary;
    font-family: $font-family--secondary;

    @include mq(md) {
      font-size: 38px;
    }
  }

  &__steps {
    display: flex;
    gap: $margin__base * 4;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__step {
    @include mq(sm) {
      width: calc(50% - 24px);
    }

    @include mq(lg) {
      width: calc(25% - 36px);
    }
  }

  &__img {
    display: block;
    margin-bottom: $margin__base * 2;
    max-width: 250px;
    margin: auto;

    img {
       max-width: 100%;
       height: auto;
    }
  }
}

.course {
  &__wrapper {
    padding: 30px;

    @include mq(lg) {
      padding: 60px 230px 30px;
    }
  }

  &__header {
    text-align: center;
  }

  &__title {
    font-size: 30px;
    line-height: 1.5;
    margin-bottom: 20px;
    color: $color--primary;
    font-family: $font-family--secondary;

    @include mq(md) {
      font-size: 38px;
    }
  }

  &__subtitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4;

    @include mq(md) {
      font-size: 20px;
    }
  }

  &__main {
    padding: 20px 0;

    @include mq(md) {
      padding: 30px 0;
      display: flex;
      justify-content: center;
    }
  }

  &__descp {
    max-width: 290px;
    margin: 0 auto 30px;
    text-align: center;

    @include mq(md) {
      max-width: inherit;
      margin: 0 140px 0 0;
    }

    &:last-child {
      margin: 0 auto;

      @include mq(md) {
        margin: 0;
      }
    }

    &__wrapper {
      padding: 18px 30px;
      border-radius: 4px;
      background-color: #ffe4d2;

      @include mq(md) {
        padding: 18px 44px;
      }
    }

    &__legend {
      font-size: 14px;
      font-weight: 600;
      margin-top: 15px;
      text-align: center;
    }

    &__title {
      font-size: 28px;
      line-height: 1.4;
      margin-bottom: 20px;
      text-align: center;
      color: $color--primary;
      font-family: $font-family--secondary;
    }

    &__list {
      font-size: 18px;
      position: relative;
      display: block;
      margin: 0 auto;
      text-align: left;
      margin-bottom: 18px;

      &__item {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          content: '';
          display: inline-block;
          width: 27px;
          height: 30px;
          margin-right: 20px;
          vertical-align: top;
          background-repeat: no-repeat;
          background-position: center;
        }

        &.icon-canoe::before {
          background-image: image-path('kajaktrail/icon_canoe.svg');
        }

        &.icon-bicycle::before {
          background-image: image-path('kajaktrail/icon_bicycle.svg');
        }

        &.icon-run::before {
          background-image: image-path('kajaktrail/icon_run.svg');
        }

        &.icon-bbq::before {
          background-image: image-path('kajaktrail/icon_bbq.svg');
        }

        &.icon-trekking::before {
          background-image: image-path('kajaktrail/icon_trekking.svg');
        }
      }
    }
  }

  &__details {
    display: block;
    margin-top: calc($margin__base / 2);
    font-size: rem(12px);
    text-align: center;

    &::before {
      content: '* ';
    }
  }
}

.where {
  text-align: center;

  &__wrapper {
    padding: 30px;

    @include mq(lg) {
      padding: 30px 230px 60px;
    }
  }

  &__title {
    font-size: 30px;
    line-height: 1.5;
    margin-bottom: 40px;
    color: $color--primary;
    font-family: $font-family--secondary;

    @include mq(md) {
      font-size: 38px;
    }
  }

  &__main {
    @include mq(sm) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
    }

    .btn {
      margin-bottom: 24px;
      flex: 0 0 auto;

      &:not(:last-child) {
        @include mq(sm) {
          margin-right: 24px;
        }
      }
    }
  }
}

.cta-link {
  font-size: 14px;
  font-weight: 600;
  position: relative;
  display: inline-block;
  padding: 3px 0;
  transition: color .2s linear;
  color: $color--secondary;

  &:before,
  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: $color--secondary;
  }

  &:before {
    transition: width .1s .1s linear;
  }

  &:after {
    z-index: 10;
    right: inherit;
    left: 0;
    width: 0%;
    transition: width .1s linear;
    background: $color--primary;
  }

  &:hover {
    color: $color--primary;

    &:before {
      width: 0;
      transition: width .1s linear;
    }

    &:after {
      width: 100%;
      transition: width .1s .1s linear;
    }
  }

  &--arrow {
    & .icon {
      position: relative;
      top: 1px;
      display: inline-block;
      width: 9px;
      height: 12px;
      margin-right: 12px;
      transition: background .2s linear;
      background: image-path('kajaktrail/arrow_blue.svg');
      background-size: auto 100%;
    }

    &:hover .icon {
      background: image-path('kajaktrail/arrow_green.svg');
    }
  }
}

.video {
  position: relative;
  width: 100%;
  text-align: center;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  &__section {
    display: flex;
    height: (311.717px) * 2;
    max-height: (311.717px) * 2;
    flex-wrap: wrap;

    @include mq(sm) {
      flex-wrap: nowrap;
    }
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .4;
    background: #131415;
  }

  &__wrapper {
    position: relative;
    z-index: 10;
    color: $white;
  }

  &__title {
    font-family: $font-family--secondary;
    font-size: 24px;
    line-height: 1;
    position: absolute;
    top: 0;
    left: 0;
    margin-bottom: 5px;
    padding: 48px 30px;

    @include mq(sm) {
      padding: 44px;
    }

    @include mq(md) {
      font-size: 48px;
    }
  }

  &__descp {
    font-family: $font-family--secondary;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5;

    @include mq(md) {
      font-size: 20px;
    }
  }

  &__player {
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    transition: background-color .2s linear;
    border-radius: 0;
    background-color: transparent;
    background-image: image-path('kajaktrail/icon_play.svg');
    background-repeat: no-repeat;
    background-position: center 50%;
    background-size: 70px auto;

    @include mq(md) {
      background-size: auto;
    }

    &:hover {
      background-color: rgba(112,121,38,.4);
    }
  }
}

.stats {
  &__wrapper {
    padding: 30px 30px 48px;

    @include mq(sm) {
      padding: 30px 48px 48px;
    }
  }

  &__list {
    font-size: 20px;
    font-weight: 700;
    position: relative;
    width: 250px;
    margin: 0 auto;
    color: $white;

    @include mq(md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 1024px;
    }

    @include mq(lg) {
      font-size: 24px;
    }

    &__item {
      padding: 20px 14px;
      text-align: center;

      @include mq(md) {
        width: 33.33%;
        min-height: 72px;
        margin: 20px 0;
        padding: 0 14px;

        &--center.stats__list__item--center {
          text-align: center;
          border-top-color: transparent;
          border-right-color: $color--primary;
          border-bottom-color: transparent;
          border-left-color: $color--primary;
        }

        &--center2 {
          border-top-color: transparent!important;
          border-bottom-color: transparent!important;
        }
      }

      &--center,
      &--center2 {
        border: 3px solid transparent;
        border-top-color: $color--primary;
        border-bottom-color: $color--primary;
      }
    }

    &__title {
      font-family: $font-family--secondary;
      line-height: 1.5;
      color: #4d531c;
      text-align: center;
      font-size: 30px;
      color: $color--primary;
      margin-bottom: $margin__base * 2;

      @include mq(md) {
        font-size: 38px;
      }
    }

    &__subtitle {
      font-size: rem(20px);
      color: #4d531c;
      text-align: center;
      line-height: 1.4;
    }

    &__number {
      font-size: 26px;
      font-weight: 600;
      color: $color--primary;
    }
  }
}

.gallery {
  &__wrapper {
    max-width: 1120px;
    margin: 0 auto;
    padding: 30px 30px 0;

    @include mq(sm) {
      padding: 30px 48px 0;
    }
  }

  &__grid {
    &:not(:last-child) {
      margin-bottom: 14px;
    }

    @include mq(sm) {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-template-rows: 200px 200px;
      gap: 14px;

      grid-template-areas:
        'picture1 picture3'
        'picture2 picture3';
    }

    @include mq(md) {
      grid-template-columns: 670px 1fr;
      grid-template-rows: 342px 342px;
    }

    .gallery__link:nth-child(3n) {
      @include mq(sm) {
        grid-area: picture3;
      }
    }

    &--reverse {
      grid-template-columns: 1fr 2fr;
      grid-template-areas:
        'picture1 picture2'
        'picture1 picture3';

      @include mq(md) {
        grid-template-columns: 1fr 670px;
      }

      .gallery__link:nth-child(3n) {
        @include mq(sm) {
          grid-area: picture1;
        }
      }
    }

    &--one, &--two {
      grid-template-rows: 340px;
    }

    &--one {
      grid-template-columns: 1fr;
      gap: 0
    }
  }

  &__link {
    position: relative;
    margin-bottom: 14px;
    display: block;
    background: $color--primary;

    @include mq(sm) {
      margin: 0;
    }

    &:nth-child(3n) {
      grid-area: picture3;
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include aspect-ratio(670, 342);
  }
}

.quotes {
  & :deep() {
    .glider-track {
      align-items: inherit;
    }
  }

  &__wrapper {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 30px;
  }

  &__item {
    background: $white;
    padding: 20px;
    min-height: 100%;
  }

  &__author, &__job {
    text-align: center;
    color: $color--primary;
    font-size: rem(18px);
  }

  &__author {
    font-weight: bold;
    margin-top: $margin__base * 3;
  }

  &__job {
    margin-top: 20px;
    font-style: italic;

    &:before {
      content: '';
      display: block;
      width: 4px;
      height: 1px;
      background: $color--primary;
      margin: 0 auto 20px auto;
    }
  }

  &__icon {
    margin: auto;
    display: block;
    margin-bottom: 24px;

    path {
      fill: $color--primary;
    }
  }

  .editor {
    font-family: $font-family--secondary;
    font-size: 20px;
  }

  &__slides {
    padding: 48px 0;
    border-radius: 9px;

    & :deep() .glider__slide {
      padding: 20px;
      border-radius: 4px;
    }

    & :deep() .glider-next {
      right: 10px;

      @include mq(sm) {
        right: -35px;
      }
    }

    & :deep() .glider-prev {
      left: 10px;

      @include mq(sm) {
        left: -35px;
      }
    }
  }
}

.sponsors {
  background-repeat: repeat;
  padding: 35px 0 65px;

  &__wrapper {
    max-width: 1120px;
    margin: 0 auto;
    padding: 0 30px;

    @include mq(sm) {
      padding: 0 48px;
    }
  }

  &__title {
    font-size: 30px;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: center;
    color: $color--primary;
    font-family: $font-family--secondary;

    @include mq(md) {
      font-size: 38px;
    }
  }

  & :deep() .glider-next {
    right: -30px;

    @include mq(md) {
      right: -60px;
    }
  }

  & :deep() .glider-prev {
    left: -30px;

    @include mq(md) {
      left: -60px;
    }
  }
}

.arrow {
  display: block;
  width: 21px;
  height: 27px;
  transition: all .3s;

  &:hover.arrow--right {
    background-image: image-path('kajaktrail/arrow_right_green.svg');
  }

  &:hover.arrow--left {
    background-image: image-path('kajaktrail/arrow_left_green.svg');
  }

  &--left {
    background-image: image-path('kajaktrail/arrow_left_black.svg');
  }

  &--right {
    background-image: image-path('kajaktrail/arrow_right_black.svg');
  }
}

.glider {
  &__link {
    display: block;
  }

  &__slide {
    display: inline-block;
    vertical-align: middle;
    padding: 0 $padding__base;
  }
}

.editor__content {
  &:deep() {
    h2 {
      font-size: 30px;
      line-height: 1.5;
      margin-bottom: $margin__base * 2.5;
      color: $color--primary;
      font-family: $font-family--secondary;

      @include mq(md) {
        font-size: 38px;
      }
    }
  }
}
</style>
