<template>
  <div>
    <div
      class="input input--border input--radius"
      :class="{ 'input--disabled': disabled }"
    >
      <fa-icon
        v-if="icon"
        :icon="icon"
      />

      <div
        :id="id"
        class="input__elm"
      />

      <fa-icon
        v-if="event && !event.error && event.complete"
        class="color--success"
        :icon="['far', 'check-circle']"
      />
    </div>

    <div
      class="input--errors"
      v-if="(event && event.error) || v.$error"
    >
      <span
        class="input--error"
        v-if="event && event.error"
      >
        {{ t(`errors.${event.error.code}`) }}
      </span>

      <span
        class="input--error"
        v-else-if="v.$dirty && v.sameAs.$invalid"
      >
        {{ t('errors.input_text_required') }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useStripe } from '@/vendors/integrations/stripe'

const emit = defineEmits(['change'])

const props = defineProps({
  type: { type: String, required: true, default: 'cardNumber' },
  id: { type: String, required: true },
  v: { type: Object },
  icon: { type: Array },
  placeholder: { type: String },
  disabled: { type: Boolean, default: false }
})

const { t } = useI18n()
const stripe = useStripe()

const element = ref(null)
const event = ref(null)

onMounted(() => {
  element.value = stripe.createElement(props.type, {
    style: { base: { fontFamily: 'Lato, Verdana, Arial, Times, serif', '::placeholder': { color: '#cccccc' } } },
    placeholder: props.placeholder
  })

  element.value.mount(`#${props.id}`)
  element.value.on('change', e => emit('change', event.value = e))
})

onBeforeUnmount(() => {
  element.value.unmount()
  element.value.destroy()
})
</script>

<style lang="scss" scoped>
.input {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__elm {
    flex: 1;
    margin-left: 12px;
  }
}
</style>
