export default [
  {
    name: 'custom_footer_boven_de_wolken',
    path: 'modules/pages/bovendewolken/FooterBovendewolken'
  },
  {
    name: 'custom_homepage_boven_de_wolken',
    path: 'modules/pages/bovendewolken/HomepageBovendewolken'
  }
]
