export default [
  {
    name: 'custom_footer_lightfortheworld',
    path: 'modules/pages/lightfortheworld/FooterLightfortheworld'
  },
  {
    name: 'custom_header_lightfortheworld',
    path: 'modules/pages/lightfortheworld/HeaderLightfortheworld'
  },
  {
    name: 'custom_homepage_lightfortheworld',
    path: 'modules/pages/lightfortheworld/HomepageLightfortheworld'
  },
  {
    name: 'custom_lightfortheworld_kananga',
    path: 'modules/pages/lightfortheworld/KanangaLightfortheworld'
  }
]
