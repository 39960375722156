<template>
  <div class="beers__container">
    <article class="beer__container">
      <div class="beer__header">
        <h3 class="beer__title">
          {{ $i18n.translate(section.big_beer_title) }}
        </h3>

        <p class="beer__goal">
          <app-money :amount="collected" />
        </p>
      </div>

      <div class="beer">
        <ul class="beer__steps">
          <li
            :class="{'activated': isGoalAbove(collected, goal)}"
            class="beer__step"
            v-for="goal in goals"
            :key="goal"
          >
            - <app-money
              :amount="goal"
              :show-currency="false"
            />
          </li>
        </ul>

        <svg
          width="325"
          height="511"
          viewBox="0 0 325 511"
          fill="none"
          class="beer__svg"
        >
          <g id="beer_left">
            <path
              id="glass (mask)"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M226.933 463.522C205.322 452.7 194.976 435.131 194.976 409.774C194.976 380.385 227.212 352.792 253.097 330.591C290.407 298.646 325 260.032 325 194.824C325 144.591 306.106 96.7135 294.801 68.0828C292.005 61.0149 289.608 54.9054 288.05 50.2335C283.618 37.2878 282.24 23.4935 284.02 9.9289C284.043 9.75221 284.072 9.57551 284.105 9.40081L284.142 9.20615C285.051 4.42638 281.385 0 276.519 0H48.5103C43.6339 0 39.9659 4.44335 40.8916 9.22911C40.9266 9.41179 40.9576 9.59647 40.9815 9.78116C42.7591 23.3418 41.381 37.1311 36.95 50.0738C35.3921 54.7457 32.9554 60.8552 30.1991 67.923C18.8944 96.5537 0 144.431 0 194.665C0 260.072 34.5133 298.486 71.9027 330.431C97.7876 352.593 130.024 380.225 130.024 409.615C130.024 434.971 119.518 452.541 98.0672 463.362C90.5983 466.76 82.9427 469.733 75.1383 472.267C60.1586 477.498 45.9778 482.409 39.1869 489.237C35.3521 492.829 33.0612 497.768 32.7956 503.014V503.242C32.7956 507.527 36.2699 511 40.5561 511H284.684C288.97 511 292.444 507.527 292.444 503.242V503.014C292.178 497.768 289.888 492.829 286.053 489.237C279.022 482.569 264.881 477.617 249.862 472.426C242.056 469.894 234.402 466.921 226.933 463.522Z"
              fill="#EAEAEA"
            />
            <mask
              id="mask0_0_173"
              style="mask-type:alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="325"
              height="511"
            >
              <path
                id="glass (mask)_2"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M226.933 463.522C205.322 452.7 194.976 435.131 194.976 409.774C194.976 380.385 227.212 352.792 253.097 330.591C290.407 298.646 325 260.032 325 194.824C325 144.591 306.106 96.7135 294.801 68.0828C292.005 61.0149 289.608 54.9054 288.05 50.2335C283.618 37.2878 282.24 23.4935 284.02 9.9289C284.043 9.75221 284.072 9.57551 284.105 9.40081L284.142 9.20615C285.051 4.42638 281.385 0 276.519 0H48.5103C43.6339 0 39.9659 4.44335 40.8916 9.22911C40.9266 9.41179 40.9576 9.59647 40.9815 9.78116C42.7591 23.3418 41.381 37.1311 36.95 50.0738C35.3921 54.7457 32.9554 60.8552 30.1991 67.923C18.8944 96.5537 0 144.431 0 194.665C0 260.072 34.5133 298.486 71.9027 330.431C97.7876 352.593 130.024 380.225 130.024 409.615C130.024 434.971 119.518 452.541 98.0672 463.362C90.5983 466.76 82.9427 469.733 75.1383 472.267C60.1586 477.498 45.9778 482.409 39.1869 489.237C35.3521 492.829 33.0612 497.768 32.7956 503.014V503.242C32.7956 507.527 36.2699 511 40.5561 511H284.684C288.97 511 292.444 507.527 292.444 503.242V503.014C292.178 497.768 289.888 492.829 286.053 489.237C279.022 482.569 264.881 477.617 249.862 472.426C242.056 469.894 234.402 466.921 226.933 463.522Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_0_173)">
              <g id="bubble">
                <ellipse
                  id="Oval"
                  cx="283.616"
                  cy="171.043"
                  rx="13.9811"
                  ry="13.3683"
                  fill="#F5F4F4"
                />
                <ellipse
                  id="Oval_2"
                  cx="255.654"
                  cy="193.223"
                  rx="8.98783"
                  ry="8.5939"
                  fill="#F5F4F4"
                />
              </g>
              <path
                id="wave_white"
                class="beer__content"
                :style="`transform: translateY(-${xPosition}%)`"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M-121,358.5c0,0,158.2-83.4,243.9-40.4c85.7,43,159.4,41.9,229.1,5.3 c69.8-36.6,41.3,651.6,41.3,651.6H163H-85l-43-344.2L-121,358.5z"
                fill="#F5F4F4"
              />
              <path
                id="wave_yellow"
                class="beer__content"
                :style="`transform: translateY(-${xPosition}%)`"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M-121,389.5c0,0,158.2-83.4,243.9-40.4c85.7,43,159.4,41.9,229.1,5.3 c69.8-36.6,41.3,620.6,41.3,620.6H144H-85l-20-329L-121,389.5z"
                fill="#F3E794"
              />
              <g id="duvel_logo">
                <path
                  id="logo (mask)"
                  d="M145.491 282.273C136.135 282.273 126.633 284.424 117.237 288.685C116.852 263.644 117.209 193.401 117.249 186.507C123.411 182.409 151.784 166.209 152.081 166.049L152.589 165.762L152.019 254.063C152.019 254.161 152.065 254.251 152.15 254.298L152.294 254.352L152.422 254.313L153.506 253.757C155.644 252.698 158.631 252.153 162.413 252.153C168.206 252.153 175.861 253.521 183.419 255.914L184.399 256.22L184.483 256.243C184.637 256.243 184.759 256.123 184.759 255.955V177.223C184.759 177.102 184.693 176.992 184.587 176.961L152.98 164.565L152.883 164.549L152.851 164.553L151.94 164.202C151.627 164.077 151.257 163.924 150.835 163.757C150.698 163.699 150.541 163.639 150.387 163.564C145.018 161.362 132.381 156.194 118.567 156.194C113.066 156.194 107.891 157.014 103.123 158.626L103.082 158.635L103.016 158.598C102.997 158.56 102.972 158.447 103.088 158.301C105.139 155.811 107.337 152.959 109.613 150L109.992 149.512C111.642 147.358 113.345 145.141 115.008 143.034C118.182 139.058 121.673 135.031 125.562 132.02C129.42 129.007 133.652 127 138.274 127C140.134 127 142.05 127.327 143.959 127.962C153.033 130.861 183.735 141.352 199.418 146.722L199.528 146.76C199.659 146.801 199.781 146.842 199.909 146.898C200.689 147.157 201.431 147.401 202.136 147.646L207.417 149.477C212.884 151.528 215.921 154.975 217.618 158.567C219.296 162.174 219.6 165.95 219.522 168.67C219.315 177.287 219.998 226.736 220.151 236.809C220.173 237.904 220.185 238.54 220.185 238.587C220.185 243.9 221.722 250.162 223 253.322C212.383 258.109 189.734 268.842 180.097 291.748C168.284 285.376 156.931 282.273 145.491 282.273Z"
                  fill="#DC555E"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    </article>

    <article class="beer__container beer__small">
      <div class="beer__header">
        <h3 class="beer__title">
          {{ $i18n.translate(section.small_beer_title) }}
        </h3>

        <p class="beer__goal">
          <app-money :amount="collected * 2" />
        </p>
      </div>

      <div class="beer">
        <ul class="beer__steps">
          <li
            :class="{'activated': isGoalAbove(collected * 2, goal * 2)}"
            class="beer__step"
            v-for="goal in goals"
            :key="goal"
          >
            - <app-money
              :amount="goal * 2"
              :show-currency="false"
            />
          </li>
        </ul>

        <svg
          width="204"
          height="320"
          viewBox="0 0 204 320"
          fill="none"
          class="beer__svg"
        >
          <g id="beer_right">
            <path
              id="glass (mask)"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M156.836 295.844C151.937 294.258 147.132 292.397 142.444 290.268C128.879 283.491 122.385 272.489 122.385 256.61C122.385 238.206 142.619 220.927 158.867 207.024C182.286 187.019 204 162.838 204 122.004C204 90.5462 192.14 60.5642 185.044 42.635C183.289 38.209 181.785 34.3831 180.807 31.4574C178.025 23.3505 177.16 14.7122 178.277 6.21771C178.292 6.10706 178.31 5.99641 178.331 5.887L178.354 5.7651C178.924 2.7719 176.623 0 173.569 0H30.4496C27.3887 0 25.0863 2.78253 25.6674 5.77948V5.77948C25.6893 5.89388 25.7087 6.00953 25.7238 6.12519C26.8396 14.6172 25.9745 23.2523 23.1932 31.3573C22.2153 34.283 20.6858 38.1089 18.9558 42.535C11.8599 60.4642 0 90.4462 0 121.904C0 162.863 21.6637 186.919 45.1327 206.923C61.3805 220.802 81.615 238.106 81.615 256.51C81.615 272.389 75.0206 283.391 61.556 290.168C56.8679 292.296 52.0625 294.158 47.1637 295.744C37.7611 299.02 28.8599 302.096 24.5973 306.372C22.1903 308.621 20.7523 311.714 20.5855 314.999V315.142C20.5855 317.825 22.7663 320 25.4567 320H178.694C181.384 320 183.565 317.825 183.565 315.142V314.999C183.398 311.714 181.96 308.621 179.553 306.372C175.14 302.196 166.264 299.095 156.836 295.844"
              fill="#F7F7F7"
            />
            <mask
              id="mask0_0_189"
              style="mask-type:alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="204"
              height="320"
            >
              <path
                id="glass (mask)_2"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M156.836 295.844C151.937 294.258 147.132 292.397 142.444 290.268C128.879 283.491 122.385 272.489 122.385 256.61C122.385 238.206 142.619 220.927 158.867 207.024C182.286 187.019 204 162.838 204 122.004C204 90.5462 192.14 60.5642 185.044 42.635C183.289 38.209 181.785 34.3831 180.807 31.4574C178.025 23.3505 177.16 14.7122 178.277 6.21771C178.292 6.10706 178.31 5.99641 178.331 5.887L178.354 5.7651C178.924 2.7719 176.623 0 173.569 0H30.4496C27.3887 0 25.0863 2.78253 25.6674 5.77948V5.77948C25.6893 5.89388 25.7087 6.00953 25.7238 6.12519C26.8396 14.6172 25.9745 23.2523 23.1932 31.3573C22.2153 34.283 20.6858 38.1089 18.9558 42.535C11.8599 60.4642 0 90.4462 0 121.904C0 162.863 21.6637 186.919 45.1327 206.923C61.3805 220.802 81.615 238.106 81.615 256.51C81.615 272.389 75.0206 283.391 61.556 290.168C56.8679 292.296 52.0625 294.158 47.1637 295.744C37.7611 299.02 28.8599 302.096 24.5973 306.372C22.1903 308.621 20.7523 311.714 20.5855 314.999V315.142C20.5855 317.825 22.7663 320 25.4567 320H178.694C181.384 320 183.565 317.825 183.565 315.142V314.999C183.398 311.714 181.96 308.621 179.553 306.372C175.14 302.196 166.264 299.095 156.836 295.844"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_0_189)">
              <path
                id="wave_white"
                class="beer__content"
                :style="`transform: translateY(-${xPosition}%)`"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M243.6,227.8c0,0-99-50.8-152.5-24.6c-53.6,26.2-99.7,25.5-143.3,3.3 c-43.6-22.3,31.3,442.4,31.3,442.4H107h141V393.7L243.6,227.8z"
                fill="#EAEAEA"
              />
              <path
                id="wave_yellow"
                class="beer__content"
                :style="`transform: translateY(-${xPosition}%)`"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M243.6,251.8c0,0-99-50.8-152.5-24.6c-53.6,26.2-99.7,25.5-143.3,3.3 c-43.6-22.3,31.3,418.4,31.3,418.4H107h141V417.7L243.6,251.8z"
                fill="#F5E262"
              />
              <g id="logo_duvel">
                <path
                  id="Path"
                  d="M96.2025 164.076C90.4334 164.076 84.5735 165.421 78.7796 168.085C78.5421 152.43 78.762 108.514 78.7872 104.204C82.5867 101.641 100.083 91.5137 100.267 91.4135L100.58 91.2336L100.228 146.44C100.228 146.501 100.257 146.557 100.309 146.587L100.398 146.62L100.477 146.596L101.145 146.248C102.464 145.586 104.306 145.246 106.638 145.246C110.21 145.246 114.931 146.101 119.592 147.597L120.196 147.788L120.248 147.802C120.343 147.802 120.418 147.728 120.418 147.623V98.3996C120.418 98.3237 120.377 98.2551 120.312 98.2355L100.821 90.4856L100.761 90.4758L100.742 90.478L100.18 90.2589C99.9865 90.1805 99.7587 90.0846 99.4985 89.9806C99.4135 89.9439 99.3168 89.9064 99.2223 89.8595C95.9112 88.4833 88.1183 85.2517 79.5998 85.2517C76.2076 85.2517 73.0164 85.7645 70.0757 86.7725L70.0505 86.7784L70.0101 86.755C69.9984 86.7311 69.9828 86.6608 70.0543 86.5692C71.3192 85.0126 72.6746 83.2294 74.0783 81.3796L74.3116 81.0744C75.3294 79.7276 76.3796 78.342 77.4049 77.0245C79.3623 74.5384 81.5151 72.0212 83.9135 70.1382C86.2921 68.2548 88.9024 67 91.7522 67C92.8991 67 94.0808 67.2042 95.2583 67.6014C100.853 69.4136 119.787 75.9731 129.458 79.3304L129.525 79.3538C129.606 79.3794 129.682 79.4049 129.761 79.4399C130.242 79.6023 130.699 79.7549 131.134 79.9079L134.391 81.0527C137.762 82.3347 139.635 84.4901 140.681 86.7354C141.716 88.9905 141.903 91.3513 141.855 93.0519C141.727 98.4392 142.149 129.355 142.243 135.652C142.257 136.337 142.264 136.735 142.264 136.764C142.264 140.086 143.212 144.001 144 145.976C137.453 148.969 123.486 155.679 117.543 170C110.259 166.017 103.258 164.076 96.2025 164.076Z"
                  fill="#DC555E"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    </article>
  </div>
</template>

<script>
import AppMoney from '&/atoms/AppMoney'

export default {
  name: 'CustomHomepageKikirpa',
  components: {
    AppMoney
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    stats: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      goals: [4000000, 3000000, 2000000, 1000000]
    }
  },
  computed: {
    xPosition () {
      return (this.collected / this.goals[0]) * 55
    },
    collected () {
      return this.stats.amount_collected || 0
    },
  },
  methods: {
    isGoalAbove (amount, goal) {
      return amount >= goal
    }
  }
}
</script>

<style lang="scss" scoped>
.beers {
  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;

    @include mq(sm) {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-left: 90px;
      flex-direction: row;
      gap: 0;
    }

    @include mq(lg) {
      justify-content: flex-end;
    }
  }
}

.beer {
  position: relative;
  display: flex;
  justify-content: center;

  &__header {
    text-align: center;
    margin-bottom: 24px;
  }

  &__title {
    font-size: rem(24px);
    font-family: $font-family--secondary;
  }

  &__goal {
    font-size: rem(18px);
    color: $color--primary;
    margin-top: 6px;
  }

  &__container {
    position: relative;
  }

  &__steps {
    position: absolute;
    top: 45px;
    right: 0;

    @include mq(sm) {
      left: -90px;
      right: auto;
    }
  }

  &__step {
    font-size: rem(18px);
    color: #595959;

    &:not(:last-child) {
      margin-bottom: 44px;
    }

    &.activated {
      color: $color--primary;
    }
  }

  &__content {
    transform-origin: bottom;
  }

  &__svg {
    width: 100%;

    @include mq(sm) {
      width: auto;
    }
  }

  &__small {
    position: relative;
    width: 230px;

    @include mq(sm) {
      left: -90px;
    }

    .beer__title {
      font-size: rem(18px);
    }

    .beer__steps {
      left: calc(100% - 30px);
      top: 45px;

      @include mq(sm) {
        left: 100%;
      }
    }

    .beer__step {
      white-space: nowrap;

      &:not(:last-child) {
        margin-bottom: 22px;
      }
    }
  }
}
</style>