import { gtm, mixpanel } from '@/vendors/integrations'
import { centsToAmount } from '@/utils/utils'
import { get as _get } from 'lodash'

export default class {
  static created = ({ project, transaction }) => {
    const amount = centsToAmount(_get(transaction, 'amount', 0))
    const currency = _get(transaction, 'currency', 'EUR')

    mixpanel.track('New project', {
      'Id': project.id,
      'Slug': project.slug,
      'Name': project.name,
      'Campaign Id': project.campaign_id,
      'Client Id': project.client_id,
      'Procedure Id': project.procedure_id,
      'Status': project.status.charAt(0).toUpperCase() + project.status.slice(1)
    })

    gtm.track({
      event: 'project_created',
      project_id: project.id,
      project_slug: project.slug,
      project_name: project.name,
      project_status: project.status,
      campaign_id: _get(project, 'campaign.id'),
      ecommerce: {
        currency: currency,
        value: amount
      }
    })
  }

  static memberJoined = ({ accept_invite, transaction }) => {
    const { id, target_id, target_type } = accept_invite
    const amount = centsToAmount(_get(transaction, 'amount', 0))
    const currency = _get(transaction, 'currency', 'EUR')

    mixpanel.track(`User joined ${target_type.toLowerCase()}`, {
      'Id': id,
      'Target Id': target_id,
      'Target Type': target_type
    })

    gtm.track({
      event: 'project_member_joined',
      invite_id: id,
      target_id,
      target_type,
      ecommerce: {
        currency: currency,
        value: amount
      }
    })
  }

  static deleted = ({ project }) => {
    mixpanel.track('Delete project', {
      'Id': project.id,
      'Slug': project.slug,
      'Name': project.name,
      'Campaign Id': project.campaign_id,
      'Client Id': project.client_id,
      'Procedure Id': project.procedure_id,
      'Status': 'Deleted'
    })

    gtm.track({
      event: 'project_deleted',
      project_id: project.id,
      project_slug: project.slug,
      project_name: project.name,
      project_status: 'deleted',
      campaign_id: _get(project, 'campaign.id')
    })
  }
}
