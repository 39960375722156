import { computed } from 'vue'
import { get as _get, set as _set } from 'lodash'

export function useProduct (product, project) {

  const isDisplayOptionDisabled = computed(() => ['always', 'never'].includes(_get(product.value, 'display_options')))
  const isCampaignProduct = computed(() => _get(product.value, 'target_type') === 'Campaign')
  const productsDisplayOrder = computed(() => _get(project.value, 'settings.products_display_order', []))
  const productDisplayOrder = computed({
    get: () => _get(productsDisplayOrder.value.find(p => p.product_id === product.value.id), 'display_on_page'),
    set: value => _set(project.value, 'settings.products_display_order', productsDisplayOrder.value.map(p => p.product_id === product.value.id ? ({ ...p, display_on_page: value }) : p))
  })

  return {
    isDisplayOptionDisabled,
    isCampaignProduct,
    productsDisplayOrder,
    productDisplayOrder
  }
}