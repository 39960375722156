<template>
  <section
    class="section"
    :style="sectionStyle"
  >
    <div class="container">
      <div
        class="editor editor__content editor__page margin__b--36"
        v-if="$i18n.translate(attributes.text_editor)"
        v-html="$i18n.translate(attributes.text_editor)"
      />

      <div class="row justify-content-center">
        <div class="col-10 col-md-10 col-lg-8">
          <figure class="quote">
            <fa-icon
              class="quote__icon"
              :icon="['fal', 'quote-left']"
            />

            <blockquote
              class="quote__text editor editor__content"
              v-if="$i18n.translate(attributes.quote)"
              v-html="$i18n.translate(attributes.quote)"
            />

            <figcaption class="quote__author">
              <figure
                class="quote__figure"
                v-if="$i18n.translate(attributes.image)"
                :style="{ backgroundImage: `url(${ $i18n.translate(attributes.image) })` }"
              />

              <div>
                <p
                  class="quote__name"
                  v-if="$i18n.translate(attributes.author_name)"
                >
                  {{ $i18n.translate(attributes.author_name) }}
                </p>
                <p
                  class="quote__job"
                  v-if="$i18n.translate(attributes.author_job)"
                >
                  {{ $i18n.translate(attributes.author_job) }}
                </p>
              </div>
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import { useSectionStyle } from '@/composables/modules/pages'

export default defineComponent({
  name: 'SectionQuote',
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  setup: props => ({ ...useSectionStyle(props) }),
  computed: {
    attributes () {
      return this.section.attributes
    },
    style () {
      return this.section.style
    }
  }
})
</script>

<style lang="scss" scoped>
.section {
  padding: $padding__base 0;

  @include mq(md) {
    padding: $padding__base * 3 0;
  }
}

.quote {
  position: relative;
  padding: $padding__base * 2 $padding__base * 2 0 $padding__base * 2;

  &__text {
    font-size: rem(18px);
    margin-bottom: $margin__base * 2;

    @include mq(md) {
      font-size: rem(24px);
      margin-bottom: $margin__base * 3;
    }
  }

  &__icon {
    font-size: 24px;
    position: relative;
    top: -10px;
    left: -20px;

    @include mq(md) {
      font-size: 60px;
      position: absolute;
      left: -80px;
    }
  }

  &__figure {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    border-radius: 4px;
    width: 70px;
    border-radius: 50%;
    margin-right: $margin__base * 2;
    @include aspect-ratio(1, 1);

    @include mq(md) {
      width: 100px;
    }
  }

  &__name, &__job {
    font-size: rem(16px);

    @include mq(md) {
      font-size: rem(20px);
    }
  }

  &__author {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__job {
    font-size: rem(14px);
  }
}
</style>
