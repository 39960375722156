<template>
  <main class="main home">
    <section class="hero">
      <div class="wrapper">
        <figure
          class="hero__figure"
          :class="{'without-image': !$i18n.translate(hero.image)}"
        >
          <img
            v-if="$i18n.translate(hero.image)"
            :src="$i18n.translate(hero.image)"
            alt="Refugee Walk - Banner"
            class="hero__figure-image"
          >
        </figure>

        <ul
          class="hero__buttons"
          v-if="heroButtons.length > 0"
        >
          <li
            v-for="(button, index) in heroButtons"
            :key="`hero_cta_${index}`"
            class="hero__buttons-item"
          >
            <a
              @click.prevent="setRouteNavigation(button.cta)"
              :class="`cta cta--${button.color} hero__buttons-link`"
            >
              <span>{{ $i18n.translate(button.cta.text) }}</span>
            </a>
          </li>
        </ul>
      </div>
    </section>

    <section class="stats">
      <div class="wrapper">
        <div
          class="editor__content stats__content"
          v-if="$i18n.translate(stats.content)"
          v-html="$i18n.translate(stats.content)"
        />

        <ul
          class="stats__metrics"
          v-if="metrics.length && statistics"
        >
          <li
            class="stats__metric"
            v-for="(metric, index) in metrics"
            :key="`metric_item_${index}`"
          >
            <figure class="stats__metric-figure">
              <img
                :src="$i18n.translate(metric.image)"
                v-if="$i18n.translate(metric.image)"
                class="stats__metric-icon"
              >
            </figure>

            <p class="stats__metric-data">
              <strong class="d-block stats__metric-value">
                <app-date
                  v-if="metric.type === 'date' && metric.date"
                  :timestamp="metric.date"
                  filter="differenceInCalendarDays"
                />

                <app-money
                  v-else-if="metric.type === 'amount_collected'"
                  :amount="statistics[metric.type]"
                />

                <template v-else>
                  {{ statistics[metric.type] }}
                </template>
              </strong>

              <span class="d-block">{{ $i18n.translate(metric.text) }}</span>
            </p>
          </li>
        </ul>
      </div>
    </section>

    <section class="actions">
      <div class="wrapper">
        <div
          class="editor__content actions__title"
          v-if="$i18n.translate(actions.title)"
          v-html="$i18n.translate(actions.title)"
        />

        <div class="actions__items">
          <article
            class="actions__action"
            v-for="(action , index) in actions.items"
            :key="`action_${index}`"
          >
            <figure
              class="actions__action-figure"
              :style="{ backgroundImage: `url(${$i18n.translate(action.image)})` }"
            >
              <fa-icon
                :icon="['far', 'image']"
                size="4x"
                v-if="!$i18n.translate(action.image)"
              />

              <a
                v-if="action.link.show"
                @click.prevent="setRouteNavigation(action.link)"
              />
            </figure>

            <a
              v-if="action.link.show"
              @click.prevent="setRouteNavigation(action.link)"
              :class="`cta cta--${action.color} actions__action-link`"
            >
              <span>{{ $i18n.translate(action.link.text) }}</span>
            </a>
          </article>
        </div>
      </div>
    </section>

    <section class="about">
      <div class="wrapper about__wrapper">
        <div class="about__col">
          <div
            class="editor__content about__content"
            v-if="$i18n.translate(about.content)"
            v-html="$i18n.translate(about.content)"
          />

          <a
            v-if="about.cta_1.show"
            @click.prevent="setRouteNavigation(about.cta_1)"
            :class="`cta cta--${about.cta_1.color} about__cta1`"
          >
            <span>{{ $i18n.translate(about.cta_1.text) }}</span>
          </a>
        </div>

        <div class="about__col">
          <figure
            class="about__figure"
            :style="{ backgroundImage: `url(${$i18n.translate(about.image)})` }"
          >
            <fa-icon
              :icon="['far', 'image']"
              size="4x"
              v-if="!$i18n.translate(about.image) && !$i18n.translate(about.title)"
            />

            <a
              v-if="about.cta_2.show"
              @click.prevent="setRouteNavigation(about.cta_2)"
            />

            <h2
              class="about__title"
              v-if="$i18n.translate(about.title)"
            >
              {{ $i18n.translate(about.title) }}
            </h2>

            <a
              v-if="about.cta_2.show"
              @click.prevent="setRouteNavigation(about.cta_2)"
              :class="`cta cta--${about.cta_2.color} about__cta2`"
            >
              <span>{{ $i18n.translate(about.cta_2.text) }}</span>
            </a>
          </figure>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { defineComponent } from 'vue'
import { get as _get } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import AppMoney from '&/atoms/AppMoney'
import PageRouteNavigation from '%/PageRouteNavigation'

export default defineComponent({
  name: 'CustomHomepageRefugeeWalk',
  components: {
    AppMoney
  },
  mixins: [PageRouteNavigation],
  data () {
    return {}
  },
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  created () {
    this.fetchClientStatistics()
  },
  computed: {
    ...mapGetters({
      statistics: 'client/statistics'
    }),
    hero () {
      return _get(this.section, 'attributes.hero', {})
    },
    heroButtons () {
      return _get(this.hero, 'buttons', []).slice(0, 3)
    },
    stats () {
      return _get(this.section, 'attributes.stats', {})
    },
    metrics () {
      return _get(this.stats, 'metrics', []).slice(0, 2)
    },
    actions () {
      return _get(this.section, 'attributes.actions', {})
    },
    about () {
      return _get(this.section, 'attributes.about', {})
    }
  },
  methods: {
    ...mapActions({
      fetchClientStatistics: 'client/fetchClientStatistics'
    })
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #FFB700;
$color--secondary: #0092AD;
$color--tertiary: #F95739;

.wrapper {
  max-width: 1318px;
  margin: 0 auto;
  padding: 0 $padding__base * 1.5;

  @include mq(sm) {
    padding: 0 $padding__base * 2;
  }
}

.cta {
  font-size: rem(32px);
  font-weight: bold;
  font-family: $font-family--secondary;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  transform-origin: 50% 50%;
  position: relative;
  text-align: center;

  @include mq(sm) {
    font-size: rem(26px);
  }

  @include mq(md) {
    font-size: rem(36px);
  }

  @include mq(lg) {
    font-size: rem(45px);
  }

  &::before {
    position: absolute;
    content: '';
    display: block;
    width: 90%;
    height: 110%;
    left: 5%;
    top: -5%;
    transition: all .2s;
    transform-origin: 50% 50%;
  }

  &--primary {
    &::before {
      background: $color--primary;
    }

    &:hover {
      &::before {
        background: darken($color--primary, 5);
      }
    }
  }

  &--secondary {
    &::before {
      background: $color--secondary;
    }

    &:hover {
      &::before {
        background: darken($color--secondary, 5);
      }
    }
  }

  &--tertiary {
    &::before {
      background: $color--tertiary;
    }

    &:hover {
      &::before {
        background: darken($color--tertiary, 5);
      }
    }
  }

  span {
    display: block;
    position: relative;
    z-index: 10;
  }
}

.home {
  background: $white;

  @include mq(lg) {
    padding-top: $padding__base * 3.5;
  }
}

.hero {
  padding-bottom: $padding__base * 3;

  &__figure {
    background: $color--primary;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    &.without-image {
      @include aspect-ratio(1300, 400);

      @include mq(md) {
        @include aspect-ratio(1300, 200);
      }
    }

    @include mq(sm) {
      width: 100%;
    }

    &-image {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__buttons {
    text-align: center;
    margin-top: -$margin__base;

    @include mq(sm) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    &-item {
      &:not(:first-child) {
        margin-top: $margin__base * 2.5;

        @include mq(sm) {
          margin: 0 0 0 $margin__base;
        }
      }

      &:nth-child(1) {
        a::before {
          transform: rotate(-3deg);
        }
      }

      &:nth-child(2) {
        a::before {
          transform: rotate(5deg);
        }
      }

      &:nth-child(3) {
        transform: rotate(-3deg);
      }
    }
  }
}

.stats {
  padding: $padding__base * 3 0;

  &__content {
    font-size: rem(18px);
    max-width: 600px;
    margin: 0 auto;

    @include mq(sm) {
      font-size: rem(24px);
    }
  }

  &__metrics {
    margin-top: $margin__base * 5;

    @include mq(sm) {
      display: flex;
      justify-content: center;
    }
  }

  &__metric {
    &:not(:first-child) {
       margin-top: $margin__base * 5;

      @include mq(sm) {
        margin: 0 0 0 $margin__base * 5;
      }
    }

    @include mq(md) {
      display: flex;
      align-items: center;
    }

    &-icon {
      display: block;
      margin: 0 auto;
      max-width: 200px;
      height: auto;
    }

    &-data {
      font-size: rem(18px);
      text-align: center;
      margin-top: $margin__base * 1.5;

      @include mq(sm) {
        font-size: rem(20px);
      }

      @include mq(md) {
        font-size: rem(24px);
        margin: 0 0 0 $margin__base * 1.5;
        text-align: left;
      }
    }

    &-value {
      font-size: 2em;
      font-weight: bold;
      font-family: $font-family--secondary;
    }
  }
}

.actions {
  padding: $padding__base * 3 0;

  &__title {
    margin-bottom: $margin__base * 3;
    text-transform: uppercase;
    font-family: $font-family--secondary;

    :deep() {
      h2 {
        font-size: rem(36px);

        @include mq(md) {
          font-size: rem(48px);
        }
      }
    }
  }

  &__items {
    @include mq(sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: $margin__base * 2;
      column-gap: $margin__base * 2;
    }
  }

  &__action {
    text-align: center;

    &:not(:first-child) {
      margin-top: $margin__base * 3;

      @include mq(sm) {
        margin: 0;
      }
    }

    &:nth-child(odd) {
      .actions__action-link {
        transform: rotate(2deg);
      }
    }

    &:nth-child(even) {
      .actions__action-link {
        &::before {
          transform: rotate(-3deg);
        }

        span {
          transform: rotate(-2deg);
        }
      }
    }

    &-figure {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      background-color: $dw;
      width: 100vw;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @include aspect-ratio(623, 489);

      @include mq(sm) {
        width: 100%;
        left: 0;
        transform: none;
      }

      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.3;
      }

      a {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        transition: all .2s;
        background: $black;

        &:hover {
          opacity: 0.3;
        }
      }
    }

    &-link {
      margin-top: -$margin__base;
    }
  }
}

.about {
  overflow: hidden;
  padding: $padding__base * 3 0 $padding__base * 5;

  @include mq(sm) {
    padding: $padding__base * 5 0 $padding__base * 10;
  }

  &__wrapper {
    @include mq(sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: $margin__base * 2;
    }
  }

  &__col {
    &:not(&:first-child) {
      margin-top: $margin__base * 5;

      @include mq(sm) {
        margin-top: 0;
      }
    }
  }

  &__content {
    font-size: rem(18px);

    @include mq(md) {
      font-size: rem(24px);
    }
  }

  &__cta1 {
    margin-top: $margin__base * 5;

    &::before {
      transform: rotate(-3deg);
    }
  }

  &__figure {
    background-color: $dw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    @include aspect-ratio(408, 510);

    @include mq(sm) {
      width: 100%;
      max-width: 380px;
      transform: translateX(-50%) rotate(5deg);
    }

    @include mq(md) {
      max-width: 400px;
    }

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.3;
      z-index: 10;
    }

    a:not(.cta) {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition: all .2s;
      background: $black;
      opacity: 0.3;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  &__title {
    color: $white;
    text-transform: uppercase;
    font-size: rem(40px);
    text-align: center;
    font-weight: bold;
    font-family: $font-family--secondary;
    position: absolute;
    width: 90%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 20;

    @include mq(sm) {
      transform: rotate(-5deg) translate(-50%, -50%);
    }

    @include mq(md) {
      font-size: rem(48px);
    }
  }

  &__cta2 {
    position: absolute;
    bottom: 0;
    transform: translate(-50%, 50%);
    left: 50%;
    white-space: nowrap;
    z-index: 30;
  }
}

</style>