import { flattenDeep as _flattenDeep } from 'lodash'

let files = []
const requireModule = require.context('@/routes', true, /\.js$/i, 'sync')
requireModule.keys().forEach(fileName => {
  if (fileName === './index.js') return
  if (fileName === './routes.js') return
  files = [
    ...files,
    ...[requireModule(fileName).default]
  ]
})

export default _flattenDeep(files)
