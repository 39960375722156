<template>
  <div
    class="section"
    :style="sectionStyle"
  >
    <div class="container">
      <div
        class="editor editor__content editor__page margin__b--36"
        v-if="$i18n.translate(section.attributes.text)"
        v-html="$i18n.translate(section.attributes.text)"
      />

      <section class="section__columns">
        <article
          class="section__column"
          v-for="(step, index) in attributes.steps"
          :key="'step_element_' + index"
        >
          <figure
            class="section__img"
            v-if="$i18n.translate(step.img_src)"
          >
            <img
              class="img"
              :src="$i18n.translate(step.img_src)"
              alt=""
            >
          </figure>

          <div
            class="margin__t--18 editor editor__content"
            v-html="$i18n.translate(step.text)"
          />
        </article>
      </section>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useSectionStyle } from '@/composables/modules/pages'

export default defineComponent({
  name: 'SectionHowItWorks',
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  setup: props => ({ ...useSectionStyle(props) }),
  computed: {
    attributes () {
      return this.section.attributes
    },
    style () {
      return this.section.style
    }
  }
})
</script>

<style lang="scss" scoped>
.section {
  padding: $padding__base * 3 0;

  &__columns {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: $margin--base * 4;
    justify-items: center;
    align-items: baseline;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(md) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }

  &__column {
    text-align: center;
    max-width: 370px;
    flex: 1 1 0px;
  }

  &__img {
    display: block;
    margin-bottom: $margin__base * 2;
    max-width: 250px;
    margin: auto;
  }

  &__text {
    width: 50%;
    padding-left: $padding__base * 1.5;
  }
}

.img {
  max-width: 100%;
}
</style>
