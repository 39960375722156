import { inject, ref, reactive, onBeforeMount, watchEffect } from 'vue'
import store from '@/store'
import { get as _get } from 'lodash'

class Facebook {
  constructor () {
    this.fb = null
    this.version = null
    this.appId = null
    this.isLoading = false
  }

  init ({ version }) {
    this.appId = _get(store.getters, 'client/client.frontend_integrations.fb_appid', null)
    this.version = version
  }

  loadScript () {
    return new Promise(resolve => {
      if (this.fb || !this.appId) return resolve(this.fb)

      const d = document
      const s = 'script'
      const id = 'facebook-jssdk'

      let js
      let fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s); js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)

      window.fbAsyncInit = () => {
        this.fb = window.FB
        this.fb.init({ appId: this.appId, autoLogAppEvents: true, xfbml: true, version: this.version })

        resolve(this.fb)
      }
    })
  }
}

const fbSymbol = Symbol()
const facebook = new Facebook()

export const createFacebook = {
  install: (app, { version = 'v13.0' } = {}) => {
    facebook.init({ version })
    app.provide(fbSymbol, facebook)
  }
}

export function useFacebook () {
  const facebook = reactive(inject(fbSymbol))
  const fb = ref()

  onBeforeMount(() => facebook.loadScript())
  watchEffect(() => fb.value = facebook.fb )

  return fb
}

export default facebook
