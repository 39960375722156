<template>
  <header class="header">
    <div class="wrapper">
      <section
        class="mobile mobile--smartphone padding__lr--24 padding__b--24"
        v-if="mobileMenuOpened"
      >
        <ul
          class="mobile__menu padding__lr--24"
          v-if="bottom.menu.length > 0"
        >
          <li
            class="mobile__menu-item margin__tb--24"
            v-for="(item, index) in bottom.menu"
            :key="`menu_item_${index}`"
          >
            <a
              class="mobile__menu-link bold"
              @click.prevent="setRouteNavigation(item.link); mobileMenuOpened = false"
            >
              {{ $i18n.translate(item.link.text) }}
            </a>
          </li>

          <li class="mobile__menu-button btn__size--small margin__tb--24">
            <a class="mobile__menu-button--link bold">
              {{ $i18n.translate(top.button.text) }}
            </a>
          </li>

          <li class="mobile__menu-button btn__size--small margin__tb--24">
            <a class="mobile__menu-button--link bold">
              {{ $i18n.translate(bottom.button.text) }}
            </a>
          </li>

          <li>
            <ul class="mobile__sessions margin__t--12">
              <li
                class="mobile__sessions-item margin__r--24"
                v-if="!isAuthenticated"
              >
                <a
                  @click.prevent="openSessionAppModal('login')"
                  class="top__register--mobile bold link--naked pointer"
                >
                  <fa-icon
                    class="margin__r--6"
                    :icon="['fas', 'user']"
                    fixed-width
                  />

                  {{ $i18n.t('literal.login') }}
                </a>
              </li>

              <li
                class="mobile__sessions-item bold link--naked pointer margin__r--24"
                v-if="isAuthenticated && user"
              >
                <router-link
                  :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
                  @click.capture="mobileMenuOpened = false"
                  class="d-flex align-items-center"
                >
                  <user-profile-img
                    class="margin__r--6"
                    size="2"
                    :avatar="user.avatar"
                  />

                  {{ user.firstname }}
                </router-link>
              </li>

              <li
                class="mobile__sessions-item mobile__sessions-item--logout margin__l--48 pointer"
                v-if="isAuthenticated && user"
              >
                <router-link
                  :to="{ name: 'Logout' }"
                  class="link--naked pointer link__color--danger"
                >
                  <fa-icon
                    class="margin__r--6 color--danger"
                    :icon="['fas', 'sign-out']"
                    fixed-width
                  />

                  {{ $i18n.t('literal.logout') }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </section>

      <section class="container-left">
        <div class="container-left--smartphone">
          <a
            class="container-left__website padding__tb--12"
            v-if="logo.url"
            @click.prevent="setRouteNavigation(logo.url)"
          >
            <fa-icon
              class="margin__r--12 margin__t--6"
              :icon="['fal', 'arrow-left']"
            />

            <small>{{ $i18n.translate(logo.url.text) }}</small>
          </a>

          <router-link
            :to="{ name: 'Home', params: { locale: $i18n.locale } }"
            v-show="client.logo.url"
            class="container-left__logo padding__tb--12"
          >
            <img
              class="container-left__logo--img"
              :src="client.logo.url"
              :alt="client.name"
            >
          </router-link>
        </div>

        <app-burger-menu
          class="mobile__burger mobile__burger--smartphone bold"
          :state="mobileMenuOpened"
          @toggle="toggleMobileMenu"
        />
      </section>

      <section class="container-right">
        <section class="top">
          <p
            class="top__title bold padding__tb--6"
            v-if="$i18n.translate(top.title)"
          >
            {{ $i18n.translate(top.title) }}
          </p>

          <div class="top__action">
            <button
              @click.prevent="openSessionAppModal('register')"
              class="top__register bold"
              v-if="!isAuthenticated"
            >
              <fa-icon
                class="margin__r--6"
                :icon="['fas', 'user']"
              />

              <span v-html="$i18n.t('literal.login')" />
            </button>

            <router-link
              class="bold d-none d-lg-flex align-items-center"
              :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
              v-if="isAuthenticated && user"
            >
              <user-profile-img
                size="2"
                :avatar="user.avatar"
              />

              <span class="top__user-name d-block margin__l--6">{{ user.firstname }}</span>
            </router-link>

            <a
              class="top__cta margin__l--24 bold btn__size--small btn__solid--white"
              v-if="$i18n.translate(top.button.text) && top.button.show"
              @click.prevent="setRouteNavigation(top.button)"
            >
              {{ $i18n.translate(top.button.text) }}
            </a>

            <language-switcher
              class="header__language margin__l--12"
              v-if="isMultilingual"
            />
          </div>
        </section>

        <nav class="bottom padding__t--24 padding__b--36 padding__l--24">
          <ul
            class="bottom__menu"
            v-if="bottom.menu.length > 0"
          >
            <li
              class="bottom__menu-item padding__t--12"
              v-for="(item, index) in bottom.menu"
              :key="`menu_item_${index}`"
            >
              <a
                class="bottom__menu-link bold"
                :class="{'bottom__menu-link--active': isVisited(item.link)}"
                @click.prevent="setRouteNavigation(item.link); mobileMenuOpened = false"
              >
                {{ $i18n.translate(item.link.text) }}
              </a>
            </li>
          </ul>

          <a
            class="bottom__cta bold btn__color--prim btn__size--small"
            v-if="$i18n.translate(bottom.button.text)"
            @click.prevent="setRouteNavigation(bottom.button)"
          >
            {{ $i18n.translate(bottom.button.text) }}
          </a>
        </nav>

        <app-burger-menu
          class="mobile__burger mobile__burger--tablet bold"
          :state="mobileMenuOpened"
          @toggle="toggleMobileMenu"
        />

        <section
          class="mobile mobile--tablet padding__b--24"
          v-if="mobileMenuOpened"
        >
          <ul
            class="mobile__menu padding__lr--24"
            v-if="bottom.menu.length > 0"
          >
            <li
              class="mobile__menu-item margin__tb--24"
              v-for="(item, index) in bottom.menu"
              :key="`menu_item_${index}`"
            >
              <a
                class="mobile__menu-link bold"
                @click.prevent="setRouteNavigation(item.link); mobileMenuOpened = false"
              >
                {{ $i18n.translate(item.link.text) }}
              </a>
            </li>

            <li class="mobile__menu-button btn__size--small margin__tb--24">
              <a class="mobile__menu-button--link bold">
                {{ $i18n.translate(top.button.text) }}
              </a>
            </li>

            <li class="mobile__menu-button btn__size--small margin__tb--24">
              <a class="mobile__menu-button--link bold">
                {{ $i18n.translate(bottom.button.text) }}
              </a>
            </li>

            <li>
              <ul class="mobile__sessions">
                <li
                  class="mobile__sessions-item margin__r--24"
                  v-if="!isAuthenticated"
                >
                  <a
                    @click.prevent="openSessionAppModal('login')"
                    class="top__register--mobile bold link--naked pointer"
                  >
                    <fa-icon
                      class="margin__r--6"
                      :icon="['fas', 'user']"
                      fixed-width
                    />

                    {{ $i18n.t('literal.login') }}
                  </a>
                </li>

                <li
                  class="mobile__sessions-item bold link--naked pointer margin__r--24"
                  v-if="isAuthenticated && user"
                >
                  <router-link
                    :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
                    @click.capture="mobileMenuOpened = false"
                    class="d-flex align-items-center"
                  >
                    <user-profile-img
                      class="margin__r--6"
                      size="2"
                      :avatar="user.avatar"
                    />
                  </router-link>
                </li>

                <li
                  class="mobile__sessions-item mobile__sessions-item--logout pointer"
                  v-if="isAuthenticated && user"
                >
                  <router-link
                    :to="{ name: 'Logout' }"
                    class="link--naked pointer link__color--danger"
                  >
                    <fa-icon
                      class="margin__r--6 color--danger"
                      :icon="['fas', 'sign-out']"
                      fixed-width
                    />

                    {{ $i18n.t('literal.logout') }}
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </section>
      </section>

      <app-modal
        v-if="showSessionAppModal"
        size="sm"
        @close="showSessionAppModal = false"
      >
        <template #body>
          <session-forms
            class="modal__body"
            :tab="template"
            @authenticated="showSessionAppModal = false"
          />
        </template>
      </app-modal>
    </div>
  </header>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import { get as _get } from 'lodash'
import { mapGetters } from 'vuex'
import UserProfileImg from '&/atoms/UserProfileImg'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
import PageRouteNavigation from '%/PageRouteNavigation'
import LanguageSwitcher from '&/atoms/LanguageSwitcher'

export default defineComponent({
  name: 'CustomHeaderUGent',
  components: {
    LanguageSwitcher,
    UserProfileImg,
    AppBurgerMenu,
    SessionForms: defineAsyncComponent(() => import('&/modules/sessions/SessionForms')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      showSessionAppModal: false,
      mobileMenuOpened: false,
      template: null
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    logo () {
      return _get(this.section, 'attributes.logo')
    },
    top () {
      return _get(this.section, 'attributes.top')
    },
    bottom () {
      return _get(this.section, 'attributes.bottom')
    },
    isMultilingual () {
      return this.client.active_languages.length > 1
    }
  },
  methods: {
    openSessionAppModal (template) {
      this.template = template
      this.showSessionAppModal = true
    },
    toggleMobileMenu (value) {
      this.mobileMenuOpened = !value
    }
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #1E64C8;
$color--secondary: #E9F0FA;

.header {
  background: $white;

  &__language {
    & :deep() {
      .locale-switcher {
        background: none;
        color: $white;

        .vs__dropdown-toggle {
          padding: calc($padding__base / 2);
        }

        .vs__dropdown-option {
          color: $color--primary;

          &.vs__dropdown-option--selected {
            color: $white;
          }
        }

        .vs__selected {
          color: $white;
        }

        .vs__actions {
          padding: 0;
        }

        .vs__open-indicator {
          fill: $white;
          transform: rotate(0deg) scale(0.4);
        }

        .vs--open .vs__open-indicator {
          transform: rotate(180deg) scale(0.4);
        }

        .ls__dropdown-menu img {
          display: none;
        }
      }
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column-reverse;
  max-width: 1318px;
  margin: 0 auto;

  @include mq(sm) {
    display: grid;
    gap: $padding__base * 5;
    grid-template-columns: min-content auto;
    margin: 0 $margin__base * 4;
  }

  @include mq(md) {
    position: relative;
    margin: 0 $margin__base * 7;
  }

  @include mq(xl) {
    margin: 0 auto;
  }
}

.container {
  &-left {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;

    &--smartphone {
      padding: 0 $padding__base * 2;
    }

    @include mq(sm) {
      display: block;
      min-width: $padding__base * 19;
    }

    @include mq(xl) {
      min-width: $padding__base * 19;
    }

    &__website {
      display: flex;
      font-size: rem(10px);
      transition: all $transition__duration;

      @include mq(sm) {
        min-width: 160px;
      }

      &:hover {
        color: shade(#15488E);
      }
    }

    &__logo {
      &--img {
        width: 72px;

        @include mq(lg) {
          width: 120px;
        }
      }
    }
  }
}

.top {
  position: relative;
  color: $white;
  justify-content: space-between;
  background: $color--primary;
  padding-right: 0;
  padding: $padding__base;
  display: flex;

  @include mq(md) {
    padding: 18px;
    padding-right: 0;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: ' ';
    width: 100vw;
    height: 100%;
    z-index: 0;

    @include mq(sm) {
      background: $color--primary;
    }
  }

  &__title {
    align-self: center;
    z-index: 10;
    font-size: rem(12px);

    @include mq(sm) {
      padding: calc($padding__base / 2);
      font-size: rem(14px);
    }

    @include mq(md) {
      font-size: rem(16px);
    }
  }

  &__action {
    display: flex;
    justify-content: flex-end;
    z-index: 10;
  }

  &__user-name {
    color: $white;
    transition: all $transition__duration;
    z-index: 10;

    &:hover {
      color: #ffffffbf;
    }
  }

  &__cta {
    z-index: 10;
    display: none;
    transition: all $transition__duration;

    @include mq(lg) {
      display: block;
    }

    &:hover {
      border: 1px solid #ffffffbf;
      color: #ffffffbf;
    }
  }

  &__register {
    display: none;
    transition: all $transition__duration;

    @include mq(lg) {
      display: block;
    }

    &:hover {
      color: #ffffffbf;
    }

    &--mobile {
      z-index: 10;
      color: $color--primary;
    }
  }
}

.bottom {
  display: none;
  justify-content: flex-end;
  align-items: baseline;

  &__cta {
    flex-shrink: 0;
  }

  &__menu {
    display: flex;

    &-item {
      justify-content: space-around;
      margin-right: $margin__base * 1.5;

      @include mq(xl) {
        margin-right: $margin__base * 2;
      }
    }

    &-link {
      background: $white;
      color: $color--primary;
      text-transform: uppercase;
      flex-grow: 1;
      transition: all $transition__duration;
      border-bottom: 1px solid transparent;
      display: block;

      &:hover, &--active {
        color: shade(#15488E);
      }

      &--active {
        border-color: shade(#15488E);
      }
    }
  }

  &__cta {
    transition: all $transition__duration;
  }

  @include mq(lg) {
    display: flex;
  }
}

.mobile {
  &--tablet {
    display: none;
  }

  @include mq(sm) {
    &--smartphone {
      display: none;
    }

    &--tablet {
      display: block;
    }
  }

  &__burger {
    margin-left: auto;

    &--tablet {
      display: none;
    }

    @include mq(sm) {
      display: flex;

      &--smartphone {
        display: none;
      }

      &--tablet {
        display: flex;
      }
    }

    @include mq(lg) {
      display: none;
    }
  }

  &__sessions {
    display: flex;
    color: $color--primary;
    justify-content: center;

    @include mq(sm) {
      justify-content: flex-end;
    }
  }

  &__menu {
    text-align: center;
    margin: 0;

    @include mq(sm) {
      text-align: right;
    }

    &-link {
      color: $color--primary;
      text-transform: uppercase;
    }

    &-button {
      background: $color--primary;
      text-align: center;

      @include mq(sm) {
        display: table;
        margin-left: auto;
      }

      &--link {
        color: $white;
      }
    }
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}
</style>
