<template>
  <div
    class="maintenance__page"
    v-if="client"
  >
    <img
      class="maintenance__logo"
      v-if="client.logo.url"
      :src="client.logo.medium.url || client.logo.full.url"
      :alt="client.name + ' logo'"
    >

    <div class="maintenance">
      <section class="maintenance__content">
        <h1 class="maintenance__title">
          Maintenance
        </h1>

        <p class="maintenance__description">
          Hi there, we're upgrading our infrastructure.
        </p>

        <p class="maintenance__sorry">
          You probably came here with the best intentions, we're sorry you can't go any further for now. Please come back later today.
        </p>
      </section>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'AppMaintenance',
  computed: {
    ...mapGetters({
      client: 'client/client'
    })
  }
})
</script>

<style scoped lang="scss">
.maintenance {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: $margin__base * 10;

  &__page {
    background-color: $white;
    min-height: 100vh;
  }

  &__logo {
    margin-top: $margin__base * 2;
    margin-left: $margin__base * 2;
    max-width: 170px;
    max-height: 70px;
  }

  &__title {
    font-size: em(28px);
    color: $color--primary;
  }

  &__description {
    font-size: em(28px);
    color: $dg;
  }

  &__sorry {
    color: $dg;
    font-weight: lighter;

    &:before {
      content: '';
      width: 80%;
      height: 1px;
      background: $color--primary;
      display: block;
      margin: $margin__base * 2 0;
    }
  }
}
</style>
