import Section from '@/classes/procedures/Section'
import Blocks from '@/classes/procedures/Blocks'
import { flattenDeep as _flattenDeep } from 'lodash'

export default class Sections extends Array {
  constructor (sections, ...rest) {
    Array.isArray(sections) ? super(...sections.map(section => new Section(section))) : super(...rest)
  }

  get blocks () {
    return new Blocks(_flattenDeep(this.map(section => section.blocks)))
  }

  get has_custom_fields () {
    return this.blocks.contains('informations.')
  }

  getAvailableSections (data) {
    return this.filter(section => section.isAvailable(data))
  }
}
