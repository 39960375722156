<template>
  <input
    type="email"
    class="input input--border input--radius"
    :id="id"
    :name="name"
    :autocomplete="autocomplete"
    :placeholder="placeholder"
    :disabled="disabled"
    v-model="model"
    v-bind="$attrs"
    @blur="handleInputBlurred()"
  >

  <p
    class="input--errors input--error"
    v-if="v?.$error"
  >
    <template v-if="v.required?.$invalid">
      {{ t('errors.input_email_required') }}
    </template>

    <template v-else-if="v.email?.$invalid">
      {{ t('errors.input_email_not_valid') }}
    </template>
  </p>
</template>

<script setup>
import { computed, toRefs } from 'vue'
import { useI18n } from '@/vendors/i18n'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  id: String,
  name: String,
  placeholder: { type: String, default: 'hello@koalect.com' },
  modelValue: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  autocomplete: String,
  v: Object
})

const { modelValue, v } = toRefs(props)

const { t } = useI18n()

const model = computed({
  get: () => modelValue.value,
  set: value => emit('update:modelValue', value)
})

const handleInputBlurred = () => v.value?.$touch()
</script>
