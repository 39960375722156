export default {
  name: 'project_end_date',
  component_path: 'modules/procedures/blocks/BlockInputDate',
  static_options: {
    available_dates: {
      start: new Date(),
      end: new Date(new Date().setFullYear(new Date().getFullYear() + 10))
    },
    open_date: new Date(),
    show_time: true
  },
  static_mandatory: [`minDate:${new Date().toISOString()}`, `maxDate:${new Date(new Date().setFullYear(new Date().getFullYear() + 10)).toISOString()}`],
  default_value: 'project.end_date'
}