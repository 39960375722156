const endpoint = '/api/v1/client'
const resource = 'client'

export default {
  namespaced: true,
  state: {
    item: null,
    statistics: null
  },
  getters: {
    client: state => state.item,
    statistics: state => state.statistics
  },
  actions: {
    fetchClientStatistics: ({ dispatch }, { query } = {}) => dispatch(
      'fetchItem',
      {
        endpoint: `${endpoint}/statistics`,
        resource,
        query,
        id: 'statistics'
      },
      { root: true }
    )
  },
  mutations: {
    setClient (state, client) {
      state.item = client
    }
  }
}
