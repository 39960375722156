import { defineStore } from 'pinia'

export const useFileStore = defineStore('FileStore', {
  state: () => ({
    loader: false,
    error: {}
  }),
  actions: {
    postFile ({ params }) {
      return this.$postFile({
        endpoint: '/api/v1/files',
        params
      })
    }
  }
})