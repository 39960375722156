export default [
  {
    name: 'custom_footer_ihecs',
    path: 'modules/pages/ihecs/FooterIhecs'
  },
  {
    name: 'custom_header_ihecs',
    path: 'modules/pages/ihecs/HeaderIhecs'
  },
  {
    name: 'custom_homepage_ihecs',
    path: 'modules/pages/ihecs/HomepageIhecs'
  }
]
