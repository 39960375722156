<template>
  <ul class="share">
    <template
      v-for="(network, key) in networks"
      :key="`${network}_${key}`"
    >
      <li
        class="share__li"
        v-if="network === 'facebook'"
      >
        <button
          class="share__button link__color--prim"
          :class="{'cursor--not-allowed link__color--prim-prim': disabled }"
          :disabled="disabled"
          @click="handleClickFacebook()"
          :title="t('literal.share_on_x', { x: 'Facebook' })"
          :aria-label="t('literal.share_on_x', { x: 'Facebook' })"
        >
          <fa-icon
            class="share__icon"
            :icon="['fab', 'facebook-f']"
          />
        </button>
      </li>

      <li
        class="share__li"
        v-if="network === 'messenger' && (fb || isMobileDevice)"
      >
        <button
          class="share__button link__color--prim"
          :class="{ 'cursor--not-allowed link__color--prim-prim': disabled }"
          :disabled="disabled"
          @click="handleClickMessenger()"
          :title="t('literal.send_to_x', { x: 'Messenger' })"
          :aria-label="t('literal.send_to_x', { x: 'Messenger' })"
        >
          <fa-icon
            class="share__icon"
            :icon="['fab', 'facebook-messenger']"
          />
        </button>
      </li>

      <li
        class="share__li"
        v-if="network === 'whatsapp'"
      >
        <button
          class="share__button link__color--prim"
          :class="{ 'cursor--not-allowed link__color--prim-prim': disabled }"
          :disabled="disabled"
          @click="handleClickWhatsApp()"
          :title="t('literal.send_to_x', { x: 'WhatsApp' })"
          :aria-label="t('literal.send_to_x', { x: 'WhatsApp' })"
        >
          <fa-icon
            class="share__icon"
            :icon="['fab', 'whatsapp']"
          />
        </button>
      </li>

      <li
        class="share__li"
        v-if="network === 'x'"
      >
        <button
          class="share__button link__color--prim"
          :class="{ 'cursor--not-allowed link__color--prim-prim': disabled }"
          :disabled="disabled"
          @click="handleClickX()"
          :title="t('literal.share_on_x', { x: 'X' })"
          :aria-label="t('literal.share_on_x', { x: 'X' })"
        >
          <fa-icon
            class="share__icon"
            :icon="['fab', 'x-twitter']"
          />
        </button>
      </li>

      <li
        class="share__li"
        v-if="network === 'linkedin'"
      >
        <button
          class="share__button link__color--prim"
          :class="{ 'cursor--not-allowed link__color--prim-prim': disabled }"
          :disabled="disabled"
          @click="handleClickLinkedin()"
          :title="t('literal.share_on_x', { x: 'Linkedin' })"
          :aria-label="t('literal.share_on_x', { x: 'Linkedin' })"
        >
          <fa-icon
            class="share__icon"
            :icon="['fab', 'linkedin']"
          />
        </button>
      </li>

      <li
        class="share__li"
        v-if="network === 'email'"
      >
        <button
          class="share__button link__color--prim"
          :class="{ 'cursor--not-allowed link__color--prim-prim': disabled }"
          :disabled="disabled"
          @click="handleClickEmail()"
          :title="t('literal.send_by_email')"
          :aria-label="t('literal.send_by_email')"
        >
          <fa-icon
            class="share__icon"
            :icon="['fal', 'envelope']"
          />
        </button>
      </li>

      <li
        class="share__li"
        v-if="network === 'url'"
      >
        <button
          class="share__button link__color--prim"
          :class="{ 'cursor--not-allowed link__color--prim-prim': disabled }"
          :disabled="disabled"
          @click="handleClickCopyLink($event)"
          :title="t('literal.share_the_link')"
          :aria-label="t('literal.share_the_link')"
        >
          <fa-icon
            class="share__icon"
            :icon="['fal', 'link']"
          />
        </button>
      </li>
    </template>
  </ul>
</template>

<script setup>
import { computed, toRefs } from 'vue'
import { useClientStore } from '@/stores/ClientStore'
import { useNotificationStore } from '@/stores/NotificationStore'
import { useFacebook } from '@/vendors/integrations/facebook'
import { useTracking } from '@/vendors/tracking'
import { useI18n } from '@/vendors/i18n'
import { copyToClipboard } from '@/utils/utils'
import isMobile from 'is-mobile'

const props = defineProps({
  networks: { type: Array, default: () => ['facebook', 'messenger', 'whatsapp', 'x', 'linkedin', 'email', 'url'] },
  whatsappText: { type: String, default: '' },
  xText: { type: String, default: '' },
  emailText: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  targetUrl: String,
  targetType: String,
  targetId: String
})

const { networks, whatsappText, xText, emailText, disabled, targetUrl, targetType, targetId } = toRefs(props)

const fb = useFacebook()
const tracking = useTracking()
const { t } = useI18n()
const { getClient } = useClientStore()
const { postSideNotification } = useNotificationStore()

const { client } = getClient()

const url = computed(() => targetUrl.value || window.location.href.split('?')[0])
const isMobileDevice = computed(() => isMobile({ tablet: true }))

const getText = key => encodeURIComponent(t(key, { url: url.value })) || url.value

const handleClickFacebook = () => {
  if (fb.value) {
    fb.value.ui({ method: 'share', href: url.value }, response => {
      if (response !== undefined) shared('Facebook')
    })
  } else {
    shared('Facebook')
    openPopup(`https://www.facebook.com/sharer/sharer.php?u=${url.value}`, 'Facebook')
  }
}

const handleClickMessenger = () => {
  if (isMobileDevice.value) {
    shared('Messenger')
    window.location.href = `fb-messenger://share/?link=${url.value}`
  } else if (fb.value) {
    fb.value.ui({ method: 'send', link: url.value }, response => {
      if (response !== undefined) shared('Messenger')
    })
  }
}

const handleClickWhatsApp = () => {
  shared('WhatsApp')

  if (isMobileDevice.value) {
    window.location.href = `whatsapp://send?text=${getText(whatsappText.value)}`
  } else {
    window.open(`https://web.whatsapp.com/send?text=${getText(whatsappText.value)}`, '_blank')
  }
}

const handleClickX = () => {
  shared('X')

  openPopup(`https://twitter.com/intent/tweet?text=${getText(xText.value)}`, 'X')
}

const handleClickLinkedin = () => {
  shared('Linkedin')

  openPopup(`https://www.linkedin.com/shareArticle?mini=true&url=${url.value}&source=${client.value.name}`, 'Linkedin')
}

const handleClickEmail = () => {
  shared('Email')

  window.location.href = `mailto:?body=${getText(emailText.value)}`
}

const handleClickCopyLink = e => {
  shared('Link')

  copyToClipboard(url.value, e.currentTarget)
    .then(() => postSideNotification({ text: 'literal.the_link_has_been_copied_to_the_clipboard' }))
    .catch(() => postSideNotification({ text: 'literal.error_the_text_could_be_copied', type: 'error', delay: 10000 }))
}

const shared = network => tracking.action.share({ network, target: url.value, target_id: targetId.value, target_type: targetType.value })

const openPopup = (url, title) => {
  const width = 600
  const height = 400
  let left = window.screen.width - width
  let top = window.screen.height - height

  top = top > 0 ? top / 2 : 0
  left = left > 0 ? left / 2 : 0

  window.open(url, title, `width=${width},height=${height},resizable=yes,left=${left},top=${top}`);
}
</script>

<style lang="scss" scoped>
.share {
  margin-top: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: $color--primary;
  gap: 12px;
  font-size: rem(28px);

  @include mq(sm) {
    font-size: rem(30px);
    gap: 24px;
  }

  @include mq(md) {
    font-size: rem(18px);
    gap: 12px;
  }

  &__icon {
    display: block;
  }
}
</style>
