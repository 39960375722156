<template>
  <div v-if="goalAmount">
    <div
      class="percentage bold align--right color--prim"
      :style="{ width: percentage }"
      v-if="isShowed('percentage')"
    >
      {{ percentage }}
    </div>

    <app-progress-bar
      id="progress-bar"
      :value="amountCollected"
      :max-value="goalAmount"
    />

    <ul
      class="statistics"
      :class="{ 'statistics--large': !column.is_small }"
      v-if="isShowed('amount') || isShowed('goal')"
    >
      <li
        class="statistics__li"
        v-if="isShowed('amount')"
      >
        <label
          for="progress-bar"
          class="color--prim bold"
        >
          {{ interpolate(getText('amount'), variables) }}
        </label>
      </li>

      <li
        class="statistics__li statistics__li--goal color--lg"
        v-if="isShowed('goal')"
      >
        {{ interpolate(getText('goal'), variables) }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed, toRefs, defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useBlock } from '@/composables/modules/templates'
import { useMoney } from '@/composables/app/useMoney'
import { get as _get } from 'lodash'

const AppProgressBar = defineAsyncComponent(() => import('&/atoms/AppProgressBar'))

const props = defineProps({
  column: { type: Object, required: true },
  block: { type: Object, required: true }
})

const { block } = toRefs(props)

const { interpolate } = useI18n()
const { getAmount } = useMoney()
const { objects, isShowed, getText } = useBlock(block)

const project = computed(() => _get(objects.value, 'project'))
const statistics = computed(() => _get(objects.value, 'statistics', {}))

const procedure = computed(() => project.value.procedures.donation)
const goalAmount = computed(() => _get(procedure.value, 'goal_amount') || 0)
const amountCollected = computed(() => _get(statistics.value, 'amount_collected', 0))
const percentage = computed(() => Math.round((amountCollected.value / goalAmount.value) * 100) + '%')

const variables = computed(() => {
  return ({
    ...statistics.value,
    amount_collected: getAmount({ amount: amountCollected.value }),
    goal: getAmount({ amount: goalAmount.value })
  })
})
</script>

<style lang="scss" scoped>
.percentage {
  max-width: 100%;
  font-size: rem(14px);
}

.statistics {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;

  &--large {
    @include mq(sm) {
      font-size: rem(18px);
    }
  }

  &__li {
    &--goal {
      margin-left: auto;
    }
  }
}
</style>