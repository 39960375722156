import Blocks from '@/classes/templates/Blocks'
import columns from '@/configurations/templates/columns'
import { get as _get } from 'lodash'

export default class Column {
  constructor ({ name = null, blocks } = {}) {
    this.name = name
    this.blocks = new Blocks(blocks)
  }

  get reference () {
    return columns.find(({ name }) => name === this.name)
  }

  get component_path () {
    return this.reference?.component_path
  }

  get settings () {
    return this.reference?.settings || {}
  }

  get style () {
    return this.reference?.style || {}
  }

  get layout () {
    return this.reference?.layout || 'full'
  }

  get is_allowed () {
    return this.reference?.is_allowed || {}
  }

  get is_small () {
    return this.layout === 'small'
  }

  isAllowed (models) {
    return Object.keys(this.is_allowed).every(key => {
      const value = _get(models, key)

      if (!value) return true
      if (typeof value === 'string') return this.is_allowed[key].includes(value)
      if (typeof value === 'object') return this.is_allowed[key].some(key => Object.keys(value).includes(key))
      if (typeof value === 'boolean') return this.is_allowed[key] === !!value
    })
  }
}