<template>
  <img
    class="picture"
    :class="[getActiveState, 'picture--' + size ]"
    :src="image"
    alt="User profile image"
    @error="setDefaultImage"
  >
</template>

<script>
import { defineComponent } from 'vue'
import { getImagePath } from '@/utils/utils'

export default defineComponent({
  props: {
    avatar: {
      type: Object
    },
    size: {
      type: [String, Number],
      default: '3'
    },
    activeState: {
      type: Boolean,
      default: false
    }
  },
  setup: () => ({ getImagePath }),
  computed: {
    image () {
      return this.avatar && this.avatar.url ? this.avatar.url : this.getImagePath('placeholders/profile-picture.png')
    },
    getActiveState () {
      return this.$route.name && this.$route.name.indexOf('Profile') >= 0 && this.activeState ? 'active' : null
    }
  },
  methods: {
    setDefaultImage (event) {
      event.target.src = this.getImagePath('placeholders/profile-picture.png')
    }
  }
})
</script>

<style scoped lang="scss">
.picture {
  background-color: $vlg;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;

  &--warning {
    background-color: $color--warning;
    color: $white;
  }

  &.active {
    border: 2px solid $color--secondary;
  }

  &--full {
    width: 100%;
    height: 100%;
  }

  @for $size from 1 through 20 {
    &--#{$size} {
      width: $padding__base * $size;
      height: $padding__base * $size;
    }
  }
}
</style>
