<template>
  <form
    class="form"
    :class="`form--${layout}`"
    v-if="is_active"
    @submit.prevent="handleSubmit({ ...embed })"
  >
    <div class="form__wrapper padding__l--12 padding__r--6">
      <fa-icon
        class="form__icon"
        :icon="embed.type === 'video' ? ['fab', 'youtube'] : ['fal', 'code']"
        fixed-width
      />

      <input
        class="form__input"
        type="text"
        v-model="embed.input"
        :placeholder="embed.type === 'video' ? t('editor.placeholder_video') : t('editor.placeholder_iframe')"
        ref="input"
      >

      <button
        class="link__color--success link--naked form__action"
        type="submit"
      >
        <fa-icon
          :icon="['fal', 'check']"
          fixed-width
        />
      </button>

      <button
        class="link__color--danger link--naked form__action"
        @click="handleSubmit({ input: null })"
        type="button"
      >
        <fa-icon
          :icon="['fal', 'times']"
          fixed-width
        />
      </button>
    </div>

    <div
      class="padding__l--12 padding__r--6 padding__t--6"
      v-if="v$ && v$.input.$error"
    >
      <span
        class="input--error"
        v-if="v$.input && v$.input.validVideoUrl && v$.input.validVideoUrl.$invalid"
      >
        {{ t('errors.input_url_incorrect') }}
      </span>

      <span
        class="input--error"
        v-if="v$.input && v$.input.validIframe && v$.input.validIframe.$invalid"
      >
        {{ t('errors.input_iframe_incorrect') }}
      </span>
    </div>
  </form>
</template>

<script setup>
import { ref, reactive, computed, nextTick } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useVuelidate } from '@vuelidate/core'
import { validVideoUrl, validIframe } from '@/utils/validators'

const emit = defineEmits(['show', 'hide', 'command'])

const { t } = useI18n()

const props = defineProps({
  editor: { type: Object, required: true },
  layout: { type: String, default: 'normal' }
})

const rules = computed(() => {
  if (embed.type === 'video') return { input: { validVideoUrl } }
  if (embed.type === 'code') return { input: { validIframe } }

  return {}
})

const input = ref(null)

const is_active = ref(false)
const type = ref(null)
const embed = reactive({ input: null, type: null })

const v$ = useVuelidate(rules, embed)

const toggle = attrs => attrs ? show(attrs) : hide()

const show = (attrs = {}) => {
  Object.assign(embed, { input: attrs.input || '', type: type.value = attrs.type })

  is_active.value = true

  emit('show', { padding: 72 })

  nextTick(() => input.value.focus())
}

const hide = () => {
  if (!is_active.value) return

  Object.assign(embed, { input: null, type: null })

  is_active.value = false

  v$.value.$reset()

  emit('hide')
}

const handleSubmit = attrs => {
  const { type, input } = attrs

  v$.value.$touch()

  if (v$.value.$error) return

  props.editor.commands.setEmbed({ type, input })
  hide()
}

defineExpose({ show, hide, toggle })
</script>

<style lang="scss" scoped>
.form {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: $padding__base;
  background: $dw;
  transform: translateY(100%);

  &__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: $white;
    border-radius: $radius__input;
  }

  &__input {
    display: block;
    flex-grow: 1;
    outline: none;
    padding: 0 calc($padding__base / 2);
    height: 48px;
    border: 0;
    width: 100%;

    .form:not(.form--compact) & {
      @include mq(sm){
        width: inherit;
      }
    }
  }

  &__action {
    padding: calc($padding__base / 2);

    .form:not(.form--compact) & {
      @include mq(md) {
        padding: $padding__base;
      }
    }
  }
}
</style>