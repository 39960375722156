export default [
  {
    name: 'custom_footer_ulb',
    path: 'modules/pages/ulb/FooterUlb'
  },
  {
    name: 'custom_header_ulb',
    path: 'modules/pages/ulb/HeaderUlb'
  },
  {
    name: 'custom_homepage_ulb',
    path: 'modules/pages/ulb/HomepageUlb'
  },
  {
    name: 'custom_homepage_ulb2',
    path: 'modules/pages/ulb/HomepageUlb2'
  }
]
