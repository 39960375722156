import { computed } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'

export default function useSectionStyle (props) {
  const { translate } = useI18n()

  const textColor = computed(() => _get(props.section, 'style.text.color'))

  const backgroundType = computed(() => _get(props.section, 'style.background.type'))
  const backgroundColor = computed(() => _get(props.section, 'style.background.color'))
  const backgroundImage = computed(() => _get(props.section, 'style.background.image'))

  const buttonTextColor = computed(() => _get(props.section, 'style.button.color'))
  const buttonBackgroundColor = computed(() => _get(props.section, 'style.button.background'))

  const sectionStyle = computed(() => {
    const style = {}

    if (textColor.value) style['color'] = textColor.value
    if (backgroundType.value === 'color' && backgroundColor.value) style['backgroundColor'] = backgroundColor.value

    if (backgroundType.value === 'image' && backgroundImage.value) {
      style['backgroundImage'] = `url(${translate(backgroundImage.value)})`
      style['backgroundSize'] = 'cover'
      style['backgroundRepeat'] = 'no-repeat'
      style['backgroundPosition'] = 'center'
    }

    return style
  })

  const buttonStyle = computed(() => {
    const style = {}

    if (buttonTextColor.value) style['color'] = buttonTextColor.value
    if (buttonBackgroundColor.value) style['backgroundColor'] = buttonBackgroundColor.value

    return style
  })

  const getLayout = computed(() => ('layout' in props.section.style) ? props.section.style.layout : null)

  return { sectionStyle, buttonStyle, getLayout }
}