<template>
  <nav class="nav padding__tb--12 margin__b--18">
    <ul class="nav__ul">
      <li class="nav__li color--vlg">
        <router-link
          class="nav__back"
          :to="{ name: 'ProjectSettings', params: { projectSlug: project.slug } }"
        >
          <fa-icon
            class="margin__r--6"
            :icon="['fal', 'chevron-left']"
          />

          {{ t('literal.back') }}
        </router-link>
      </li>

      <li class="nav__li bold align--center">
        <slot name="title" />
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { useI18n } from '@/vendors/i18n'

defineProps({ project: { type: Object, required: true } })

const { t } = useI18n()
</script>

<style lang="scss" scoped>
.nav {
  display: block;
  border-bottom: 1px solid rgba($dg, .1);

  @include mq(md) {
    display: none;
  }

  &__ul {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 15% 60% 15%;
  }

  &__back {
    display: block;
    color: $lg;
    white-space: nowrap;
    font-size: em(14px);
  }
}
</style>
