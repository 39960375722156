export default [
  {
    name: 'custom_header_saintluc',
    path: 'modules/pages/saintluc/HeaderSaintluc'
  },
  {
    name: 'custom_footer_saintluc',
    path: 'modules/pages/saintluc/FooterSaintluc'
  },
  {
    name: 'homepage_saintluc',
    path: 'modules/pages/saintluc/HomepageSaintluc'
  }
]
