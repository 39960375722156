<template>
  <footer class="footer">
    <span
      class="logo"
      aria-hidden="true"
    >
      <svg
        class="logo__svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 361 204"
      >
        <g fill="#ffffff">
          <path d="M113.6 146.5c0 6.6-1.4 12.7-4.1 18.4-1.2 2.6-2.8 5.1-4.6 7.5-.6.9-1.2 1.7-1.8 2.5s-1.3 1.6-1.9 2.6l-3.8 5-.1.1c-1.4 2.3-3.2 4.7-5 7.1-.6.8-1.3 1.6-1.9 2.4-.9 1.4-2.8 3-5.4 4.8-.5.4-.9.6-1.3.7-.4.1-.7.2-1 .1-.7-.2-1.3-1.1-1.6-1.9-.1-.1-.1-.2-.1-.3l-.3-1.2c-.5-1.9-.9-3.5-.9-5.4v-12.5l-3 3-.1.2c-.6.9-1.4 1.8-2.1 2.8-.8 1-1.6 1.9-2.3 2.9l-.1.2H72l-.3.4c-2.1 2.7-4.6 5-7.9 7.1-1.8 1.2-3.9 2.3-6.3 3.3-17.1 8.2-31.6 3.9-40.8-1.3-5.8-2.6-11.4-6.4-16.1-11.1L-1 185c4.9 4.9 10.6 8.9 16.6 11.5 5.7 3.2 13.4 6.1 22.5 6.1 6.1 0 12.9-1.3 20-4.8 3.9-1.7 7-3.5 9.6-5.6.2-.2.5-.4.7-.6l.2-.2c1.6-1.3 2.9-2.8 4.2-4.4.5-.2.9-.6 1-1.2.6-.8 1.3-1.7 2-2.6.6-.7 1.2-1.4 1.7-2.1v7.5c0 2.2.5 3.9 1 5.9l.3 1.2v.1c.3.7.6 1.3 1 1.8.6.8 1.3 1.4 2.1 1.6.3.1.6.1.9.1h.2c.3 0 .6-.1.8-.1.7-.2 1.4-.6 2.1-1.1 2.9-1.9 4.9-3.7 5.9-5.3 1.6-2 3.2-4 4.6-6 .9-1.2 1.7-2.4 2.4-3.6l3.8-5c.6-.9 1.2-1.7 1.8-2.5.6-.8 1.3-1.6 1.9-2.5 1.9-2.5 3.5-5.1 4.8-7.8 2.8-6 4.3-12.4 4.3-19.3V102h-2v44.5zm159.5 11.7c-1 1.5-1.9 3-2.9 4.4-.5.8-1 1.6-1.6 2.4-4.9 7.6-10 15.5-14.9 23.2-4.8 7.7-11.3 11.5-20 11.5h-88.9c-1.2 0-2.3 0-3.3-.5l-.1-.1c-.8-.3-1.6-.6-2.3-1-1.9-1-3.4-2.5-4.4-4.2-.3-.6-.6-1.1-.8-1.8l-.1-.1c-.5-1.1-.5-2.8-.5-4v-11.5c0-.3-.1-.6-.1-.9v-.9c0-4.2 1.8-8 3.7-11.4 4.4-6.9 8.9-13.9 13.3-20.5 4.3-6.7 8.8-13.5 13.2-20.5 4.7-7.1 9.1-13.9 13.3-20.4h-2.4c-4 6.2-8.2 12.6-12.6 19.3-1.9 3-3.9 6-5.8 9-.3.4-.6.9-.8 1.3-.2.3-.4.6-.5.8-.9 1.4-1.8 2.9-2.8 4.3-1.1 1.7-2.2 3.4-3.3 5-1.1 1.7-2.2 3.3-3.3 5-3.3 5.1-6.7 10.3-10 15.5-.9 1.6-1.8 3.2-2.5 5-.9 2.3-1.6 4.8-1.6 7.4 0 .7 0 1.3.1 2v11.3c0 1.3 0 3.3.7 4.8 1.2 3.6 4.2 6.4 8.3 7.9.2.1.3.1.5.2 1.3.6 2.5.7 3.7.7h89.4c9.4 0 16.5-4.1 21.7-12.5 5.4-8.5 11-17.2 16.4-25.6 5.4-8.4 11-17 16.4-25.5l8.1-12.6c5.1-7.9 10.1-15.8 15.1-23.6H309c-7.4 11.5-14.9 23.3-22.5 35.1-4.3 7.4-8.9 14.5-13.4 21.5z" />
          <path d="M12.7 121.8c.2 4.3.8 8.6 1.8 12.9 1.4 7.2 4.2 13.2 8.2 17.9 1.1 1.3 2.3 2.4 3.5 3.5.8.7 1.7 1.4 2.6 2 5.8 3.6 11 5.2 16.9 5.2 7.2 0 12.8-1.6 18.3-5.3 7.2-5.9 11.7-13.1 13.6-22 1.2-5 1.9-10.6 1.9-16v-18h-2v18c0 5.2-.7 10.7-1.9 15.6-1.8 8.4-6 15.3-12.8 20.8-5.1 3.4-10.3 4.9-17.1 4.9-5.6 0-10.5-1.5-15.8-4.9-2.9-1.9-5.4-4.4-7.4-7.4-1.8-2.6-3.4-5.7-4.5-9-.6-1.8-1.1-3.7-1.5-5.7-.9-4.2-1.5-8.4-1.8-12.6-.1-1.4-.1-2.8-.1-4.2V102h-2v15.4c0 1.5.1 2.9.1 4.4zm198.8-11.4l-5.7 8.7c-3 4.6-6.1 9.4-9.3 14.1l-2.7 4.2c-3 4.5-5.9 9.1-8.9 13.6l-.1.1c-.3.6-.7 1.1-1.2 1.7-.5.7-1 1.3-1.4 2-.5 1-.8 2.1-.8 3 0 1 .1 1.9.6 2.8.9 1.7 2.6 2.8 4.8 3.2h9.6c2.7 0 5.4-.1 8.3-.1 5.7-.1 11.6-.3 17.6.1 5 0 8.4-1.4 11.5-5.1.4-.5.9-1.1 1.3-1.7 2.2-3.5 4.6-7.1 6.9-10.7 2.3-3.6 4.7-7.3 7-10.8 7.4-11.2 14.7-22.4 22.1-33.7h-2.4c-7.1 10.9-14.2 21.7-21.4 32.6-2.2 3.5-4.6 7.2-7 10.7-2.3 3.6-4.7 7.2-6.9 10.7-.2.3-.4.5-.6.8-2.9 3.8-5.8 5.1-10.5 5.1-6-.4-11.9-.3-17.7-.1-2.9.1-5.5.1-8.2.1H187c-1.5-.3-2.7-1-3.2-2.1-.2-.4-.3-.9-.4-1.4 0-.8.2-1.7.6-2.6.3-.5.7-1.1 1.2-1.7.5-.6 1-1.3 1.3-2 6.9-10.7 14-21.4 20.8-31.8 3.9-6 7.9-12.1 11.9-18.2H217c-1.8 2.9-3.6 5.7-5.5 8.5zm137.4 2c0 3.7-1.3 7.1-3.7 9.3-1.9 1.8-4.4 2.6-7.3 2.3h-10.7c-1 0-1.9.1-2.8.3-6.3 1.2-10.8 6.5-11.4 14v11.5c.7 7.1 3.8 11.6 8.9 12.8 2 .7 3.4.7 6 .7h12c.9 0 2.1 0 2.4.3l.2.2.2.1c2.5.8 4.8 3.3 6 6.2.4 1 .7 2 .9 3v6.9c0 3.2 0 6.4.6 9.6.2 1.1.4 2.1.9 3.1.6 1.5 1.6 2.8 2.8 4 1.2 1.2 2.7 2.3 4.4 3.1 1.3.6 2.6.7 3.7.7h.5v-2c-1.2 0-2.3 0-3.3-.5-1.3-.7-3.1-1.8-4.6-3.5-1.2-1.4-2.1-3.1-2.4-5.2-.6-3.1-.6-6.2-.6-9.3v-7.1c-.4-3-1.9-6-3.9-8.1-1.2-1.3-2.6-2.2-4.1-2.8-.9-.8-2.3-.8-3.7-.8h-12c-2.5 0-3.6 0-5.4-.6-4.3-1.1-6.8-4.8-7.4-11v-11.3c.6-7.4 5.5-12.3 12.3-12.3H338c3.4.3 6.4-.7 8.8-2.9 2.7-2.5 4.3-6.4 4.3-10.7V102h-2v10.4z" />
        </g>

        <path
          fill="#4437b8"
          d="M271.2 102c15.8-24.3 31.4-48.5 46.6-72.2l.1-.1c1.6-2.5 3.2-4.9 4.7-7.2 1.6-2.3 3.2-4.7 4.7-7.3 3.6-5.7 9.1-9.3 15.4-10.1.9-.1 1.8-.2 2.7-.2 8.8-.6 17.8-.6 26.4-.6v-2c-8.7 0-17.7 0-26.5.6-7 0-13.4 3.1-18 8.7-.6.8-1.3 1.6-1.8 2.5-1.6 2.5-3.2 4.9-4.7 7.2s-3.1 4.7-4.7 7.2v.1c-15.5 24.1-31.3 48.7-47.4 73.4h2.5zm-94.5 0c10.5-16.1 19.9-30.8 28.9-45.1 1.9-2.5 3.8-5.1 5.1-8.4 1.9-3.3 1.5-5.5.9-6.8-.7-1.5-2.2-2.5-4.2-2.8h-56.3c-1.8 0-2.9 0-4-.5l-.1-.1c-3.8-1.3-6.5-3.7-8.2-7.5 0-.7-.2-1.4-.3-2.2-.1-.7-.3-1.5-.3-2V15.9c0-7.5 6.4-11.1 10.4-11.6H247c.5 0 1.1.1 1.6.3.6.2 1.3.3 2 .3 7.1 1.5 8.8 8 9.2 11.6V31c0 4.5-2.1 8.3-4.3 11.5-4.7 7.6-9.7 15.1-14.5 22.4-4.8 7.3-9.8 14.8-14.5 22.4l-9.6 14.7h2.4c3-4.5 5.9-9.1 8.9-13.7 4.7-7.6 9.7-15.1 14.5-22.4 4.8-7.3 9.8-14.8 14.5-22.4 2.3-3.4 4.6-7.5 4.6-12.5V16.4c-.7-7.3-4.7-12.3-10.9-13.5h-.2c-.5 0-1.1-.1-1.7-.3-.7-.2-1.4-.3-2.1-.3h-98.5c-5.8.7-12.1 5.7-12.1 13.6v10.7c0 .7.2 1.6.3 2.4.1.7.3 1.5.3 2v.2l.1.2c1.2 2.7 2.8 4.9 5 6.5 1.3 1 2.8 1.7 4.4 2.3 1.5.7 2.9.7 4.8.7h56.1c.1 0 .2 0 .4.1.9.2 1.8.7 2.3 1.6.6 1.2.3 3-.9 5l-.1.1c-1.1 2.7-2.6 4.8-4.2 7.1-.2.3-.5.6-.7 1-9.2 14.7-18.9 29.7-29.6 46.3h2.2zm134.7 0c.7-1 1.3-2.1 2-3.1 8.2-12.9 16.8-26.3 25.3-39.5 1.2-1.8 3-3.7 4.9-5.5.2-.2.4-.3.6-.4 1.4-.9 2.1-1 2.7-.7.8.4 1.3 1.2 1.8 2.9.1.2.1.5.2.8V102h2V56.1c-.6-2.6-1.3-4.2-3-5.1-1.8-.9-3.5.1-5.4 1.3l-.2.1c-.7.7-1.4 1.4-2.1 2.2-1.2 1.2-2.2 2.4-3.1 3.7-8.5 13.3-17.1 26.7-25.3 39.6l-2.7 4.2h2.3v-.1zM79.6 17.1c0-3.4.8-6.2 2.4-8.3 2.3-3.5 5.2-5.2 8.6-5.2h11.9c.4.1.8.1 1.2.2 5.7 1.1 9 4.7 9.9 10.9V102h2V14.5c-.8-5.7-3.4-9.5-7.9-11.5-1.5-.7-3.1-1.1-5-1.3H90.6c-.5 0-1 0-1.5.1-3.5.4-6.4 2.5-8.7 6-1.8 2.4-2.7 5.6-2.7 9.4V102h2V17.1zm-65-.6c0-4.2-2-8.6-5.1-11.5C6.9 2.5 3.6 1.4.3 1.6l.2 2C4 3.3 6.6 5 8.2 6.4c2.8 2.5 4.5 6.4 4.5 10.1V102h2V16.5z"
        />
      </svg>
    </span>

    <div class="container footer__container footer__container--4">
      <div>
        <h6
          class="footer__title"
          v-if="translate(column1.title)"
        >
          {{ translate(column1.title) }}
        </h6>

        <nav
          class="nav"
          v-if="column1.links"
        >
          <ul>
            <li
              class="nav__li"
              v-for="({ link }, key) in column1.links"
              :key="`link_${key}`"
            >
              <app-link
                class="nav__a"
                :link="link"
                target="_self"
              />
            </li>
          </ul>
        </nav>
      </div>

      <div>
        <h6
          class="footer__title"
          v-if="translate(column2.title)"
        >
          {{ translate(column2.title) }}
        </h6>

        <ul v-if="column2.contact?.length">
          <template
            v-for="({ type, prefix, text }, key) in column2.contact"
            :key="`contact_${key}`"
          >
            <li v-if="translate(text)">
              <template v-if="translate(prefix)">
                {{ `${translate(prefix)} ` }}
              </template>

              <template v-if="type === 'email'">
                <a :href="`mailto:${translate(text)}`">{{ translate(text) }}</a>
              </template>

              <template v-else-if="type === 'phone'">
                <a :href="`tel:${translate(text).replace(/[^\d.-]+/g, '')}`">{{ translate(text) }}</a>
              </template>

              <template v-else>
                {{ translate(text) }}
              </template>
            </li>
          </template>
        </ul>
      </div>

      <div>
        <h6
          class="footer__title"
          v-if="translate(column3.title)"
        >
          {{ translate(column3.title) }}
        </h6>

        <address
          v-html="address"
          v-if="address"
        />
      </div>
    </div>

    <div class="footer__wrapper">
      <div class="container footer__container footer__container--2">
        <nav
          class="legal-nav"
          v-if="column4.links"
        >
          <ul class="legal-nav__ul">
            <li
              v-for="({ link }, key) in column4.links"
              :key="`footer-link_${key}`"
            >
              <app-link
                class="legal-nav__a"
                :link="link"
                target="_self"
              />
            </li>
          </ul>
        </nav>

        <ul
          class="networks"
          v-if="socialNetworks.length"
        >
          <li
            class="networks__li"
            v-for="(network, i) in socialNetworks"
            :key="'footer_menu_network_' + i"
          >
            <a
              class="networks__a"
              :href="translate(network.url)"
              :aria-label="translate(network.text)"
              target="_blank"
            >
              <fa-icon
                class="networks__icon"
                size="lg"
                :icon="network.icon"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { computed } from 'vue'
import { useClientStore } from '@/stores/ClientStore'
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'

import AppLink from '&/atoms/AppLink'

const props = defineProps({ section: { type: Object, required: true } })

const { getClient } = useClientStore()
const { translate } = useI18n()

const { client } = getClient()

const column1 = computed(() => _get(props, 'section.attributes.column_1', {}))
const column2 = computed(() => _get(props, 'section.attributes.column_2', {}))
const column3 = computed(() => _get(props, 'section.attributes.column_3', {}))
const column4 = computed(() => _get(props, 'section.attributes.column_4', {}))

const address = computed(() => column3.value?.address?.map(({ text }) => translate(text)).join('<br />'))

const socialNetworks = computed(() => {
  return _get(client.value, 'platform.social_networks', [])
    .map(link => {
      if (link.icon.includes('facebook-square')) {
        link.icon[1] = 'facebook-f'
      } else if (link.icon.includes('linkedin')) {
        link.icon[1] = 'linkedin-in'
      }

      return link
    })
    .filter(({ url }) => translate(url))
})
</script>

<style lang="scss" scoped>
$color--tertiary: #dbd8f8;

a {
  color: $white;
  text-decoration: underline;
  text-decoration-color: $white;
  transition: all .3s ease-in-out;

  &:hover {
    text-decoration-color: transparent;
  }
}

.logo {
  position: absolute;
  top: 0;
  right: calc((-100vw + 100%) / 2);
  display: block;
  width: 11.125rem;
  transform: translateY(-50%);
  pointer-events: none;

  @include mq(sm) {
    width: 12.5rem;
  }

  @include mq(md) {
    width: 17.1875rem;
  }

  @include mq(lg) {
    width: 23.25rem;
  }

  &__svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.footer {
  position: relative;
  color: $white;
  background: linear-gradient(90deg, hsla(351, 84%, 48%), hsla(246, 70%, 44%));
  margin-top: 112px;
  font-size: rem(14px);

  @include mq(sm) {
    font-size: rem(16px);
  }

  &__wrapper {
    border-top: 1px solid rgba(#fff, 0.2);
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;

    &--2 {
      gap: 30px;
      padding-top: 20px;
      padding-bottom: 20px;

      @include mq(sm) {
        padding-top: 30px;
        padding-bottom: 30px;
        grid-template-columns: auto 1fr;
      }
    }

    &--4 {
      padding-top: 90px;
      padding-bottom: 40px;

      @include mq(sm) {
        padding-bottom: 60px;
      }

      @include mq(md) {
        padding-bottom: 90px;
        grid-template-columns: repeat(4, 1fr);
      }

      @include mq(lg) {
        padding-top: 120px;
        padding-bottom: 120px;
      }
    }
  }

  &__title {
    font-family: $font-family--secondary;
    font-weight: 700;
    font-size: rem(20px);
    margin-bottom: 20px;
  }
}

.nav {
  &__li {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  :deep(.nav__a) {
    color: $white;
    text-decoration: underline;
    text-decoration-color: $white;
    text-decoration-thickness: 2px;
    transition: all .3s ease-in-out;

    &:hover {
      text-decoration-color: transparent;
    }
  }
}

.legal-nav {
  font-size: rem(14px);

  &__ul {
    display: flex;
    gap: 15px 20px;
  }

  :deep(.legal-nav__a) {
    color: $color--tertiary;
    text-decoration: underline;
    text-decoration-color: $color--tertiary;
    transition: all .3s ease-in-out;

    &:hover {
      text-decoration-color: transparent;
    }
  }
}

.networks {
  display: flex;
  gap: 10px;

  @include mq(sm) {
    justify-content: end;
  }

  :deep(.networks__a) {
    background: $white;
    color: #0921bf;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    font-size: rem(14px);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s ease-in-out;

    &:hover {
      color: $white;
      background: #9086ea;
    }
  }
}
</style>