import Address from '@/classes/addresses/Address'
import { format } from 'date-fns'

export default class Customer {
  constructor ({ firstname = null, lastname = null, email = null, address = {}, gender = null, salutation = null, avatar = null, language = null, birthday = null, is_anonymous = null, business_name = null, business_vat = null, national_id = null, phone_number = null } = {}) {
    this.firstname = firstname
    this.lastname = lastname
    this.email = email
    this.address = new Address(address)
    this.gender = gender
    this.salutation = salutation
    this.avatar = avatar
    this.language = language
    this.birthday = birthday
    this.is_anonymous = is_anonymous
    this.business_name = business_name
    this.business_vat = business_vat
    this.national_id = national_id
    this.phone_number = phone_number
  }

  get fullname () {
    return [this.firstname, this.lastname].filter(v => v).join(' ')
  }

  get to_tamaro () {
    return {
      stored_customer_firstname: this.firstname,
      stored_customer_lastname: this.lastname,
      stored_customer_salutation: this.salutation,
      stored_customer_email: this.email,
      stored_customer_phone: this.phone_number,
      stored_customer_birthdate: this.birthday ? format(new Date(this.birthday), 'yyyy-MM-dd') : null,
      stored_customer_company: this.business_name,
      stored_customer_company_vat: this.business_vat,
      stored_customer_national_id: this.national_id,
      ...this.address.to_tamaro
    }
  }
}
