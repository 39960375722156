<template>
  <input
    ref="input"
    type="text"
    class="input input--border input--radius"
    :id="id"
    :name="name"
    :placeholder="placeholder"
    :disabled="disabled"
    :value="iban"
    v-bind="$attrs"
    @blur="handleInputBlurred()"
    @paste="e => handleInputIbanPasted(e)"
  >

  <div
    class="input--errors"
    v-if="v?.$error"
  >
    <span
      class="input--error"
      v-if="v.required?.$invalid"
    >
      {{ t('errors.input_text_required') }}
    </span>

    <span
      class="input--error"
      v-if="v.iban?.$invalid"
    >
      {{ t('errors.input_text_invalid_iban') }}
    </span>
  </div>
</template>

<script setup>
import { ref, computed, toRefs, onMounted, onBeforeUnmount } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { get as _get, chunk as _chunk } from 'lodash'
import { countrySpecs } from 'ibantools'
import { registerCursorTracker, formatGeneral, unformatGeneral } from 'cleave-zen'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  name: { type: String, default: 'input-iban' },
  id: String,
  placeholder: { type: String, default: '...' },
  modelValue: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  v: Object
})

const { modelValue, v } = toRefs(props)

const { t } = useI18n()

const input = ref()

const length = computed(() => {
  const { chars = 40 } = _get(countrySpecs, modelValue.value?.slice(0, 2), {})

  return chars
})

const blocks = computed(() => _chunk(Array(length.value), 4).map(array => array.length))

const iban = computed({
  get: () => getFormatedIban(modelValue.value),
  set: value => emit('update:modelValue', unformatGeneral(value, { delimiter: ' ' }))
})

const handleInputBlurred = () => v.value?.$touch()

const handleInputIbanPasted = e => {
  const text = e.clipboardData?.getData('text')
  const value = e.target.value
  const start = e.target.selectionStart
  const end = e.target.selectionEnd

  iban.value = value ? value.slice(0, start) + text + value.slice(end) : text
}

const getFormatedIban = value => formatGeneral(value, { blocks: blocks.value, delimiter: ' ', uppercase: true })

onMounted(() => {
  registerCursorTracker({ input: input.value, delimiter: '' })

  input.value.addEventListener('input', ({ target }) => iban.value = input.value.value = getFormatedIban(target.value))
})

onBeforeUnmount(() => registerCursorTracker({ input: input.value, delimiter: ' ' }))
</script>
