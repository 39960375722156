<template>
  <header class="header">
    <div class="wrapper d-flex align-content-center justify-content-between">
      <router-link
        :to="{ name: 'Home', params: { locale: $i18n.locale } }"
        v-show="client.logo.url"
      >
        <img
          class="logo__img"
          :src="client.logo.url"
          :alt="client.name"
        >
      </router-link>

      <div class="d-flex align-items-center header__actions">
        <template v-if="isAuthenticated && user">
          <router-link
            class="profile d-flex align-items-center profile bold"
            :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
          >
            <user-profile-img
              :avatar="user.avatar"
              size="2"
            />

            <span class="margin__l--6">{{ user.firstname }}</span>
          </router-link>
        </template>

        <template v-else>
          <button
            @click.prevent="openModal"
            class="profile bold d-flex align-items-center"
          >
            <fa-icon
              :icon="['fas', 'user']"
              fixed-width
            />

            <span class="margin__l--6">{{ $i18n.t('literal.login') }}</span>
          </button>
        </template>

        <app-link
          v-for="(action, key) in actions"
          :link="action.link"
          :key="`action_${key}`"
          class="cta bold"
        />
      </div>
    </div>

    <session-modal
      v-if="show_modal"
      @close="closeModal"
    />
  </header>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import { get as _get } from 'lodash'
import { useModals } from '@/composables/app/useModals'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const UserProfileImg = defineAsyncComponent(() => import('&/atoms/UserProfileImg'))
const SessionModal = defineAsyncComponent(() => import('&/organisms/SessionModal'))

const props = defineProps({
  section: { type: Object, required: true }
})

const store = useStore()
const { show_modal, openModal, closeModal } = useModals()

const client = computed(() => store.getters['client/client'])
const user = computed(() => store.getters['auth/user'])
const isAuthenticated = computed(() => store.getters['auth/isAuthenticated'])

const actions = computed(() => _get(props, 'section.attributes.actions', []))
</script>


<style lang="scss" scoped>
$color--primary: #131415;
$color--secondary: #FEFF00;

.wrapper {
  max-width: 1440px;
  padding: 0 $padding__base;
  margin: 0 auto;

  @include mq(sm) {
    padding: 0 $padding__base * 2;
  }

  @include mq(md) {
    padding: 0 $padding__base * 7;
  }
}

.logo {
  &__img {
    width: 120px;
    height: auto;
    display: block;

    @media screen and (min-width:400px) {
      width: 150px;
    }

    @include mq(sm) {
      width: 230px;
    }

    @include mq(sm) {
      width: 280px;
    }
  }
}
.profile {
  text-transform: uppercase;
  font-family: $font-family--secondary;
  font-size: rem(14px);

  @media screen and (min-width:400px) {
    font-size: rem(16px);
  }

  @include mq(sm) {
    font-size: rem(20px);
  }

  span {
    display: none;

    @media screen and (min-width:480px) {
      display: block;
    }
  }
}

:deep(.cta) {
  padding: calc($padding__base / 2) $padding__base;
  border: 2px solid $color--primary;
  background: $color--secondary;
  font-family: $font-family--secondary;
  color: $color--primary;
  text-transform: uppercase;
  font-size: rem(14px);
  line-height: .9;
  transition: all $transition__duration;

  @media screen and (min-width:400px) {
    font-size: rem(16px);
    padding: $padding__base $padding__base * 2;
  }

  @include mq(sm) {
    padding: $padding__base * 1.5 $padding__base * 2.5;
    font-size: rem(20px);
  }

  &:hover {
    background: $color--primary;
    color: $white;
  }
}
.header {
  &__actions {
    button, a {
      &:not(:last-child) {
        margin-right: $margin__base;

        @include mq(sm) {
          margin-right: $margin__base * 2;
        }
      }
    }
  }
}
</style>