<template>
  <footer class="footer">
    <div class="wrapper">
      <section class="column column_1">
        <a
          :href="$i18n.translate(logo.url)"
          v-if="$i18n.translate(logo.url)"
          class="logo padding__tb--12"
        >
          <img
            class="logo--img"
            :src="client.logo.url"
            :alt="client.name"
          >
        </a>

        <router-link
          :to="{ name: 'Home', params: { locale: $i18n.locale } }"
          v-show="client.logo.url"
          class="logo padding__tb--12"
          v-else
        >
          <img
            class="logo--img"
            :src="client.logo.url"
            :alt="client.name"
          >
        </router-link>

        <ul class="networks">
          <li
            class="networks__item"
            v-for="(network, index) in socialNetworks"
            :key="'footer_menu_network_' + index"
          >
            <a
              class="networks__link"
              :href="$i18n.translate(network.url)"
              target="_blank"
            >
              <fa-icon
                v-if="network.icon"
                size="lg"
                :icon="network.icon"
              />
            </a>
          </li>
        </ul>
      </section>

      <section class="column column_2">
        <ul class="column_2__list">
          <li
            class="column_2__link"
            v-for="({ link }, index) in column_2.navigation"
            :key="'footer_menu_link_' + index"
          >
            <a
              :class="{'column_2__link--active': isVisited(link)}"
              :href="$i18n.translate(link.url)"
              v-if="isWebsiteLink(link)"
            >
              {{ $i18n.translate(link.text) }}
            </a>

            <a
              :class="{'column_2__link--active': isVisited(link)}"
              @click.prevent="setRouteNavigation(link)"
              v-else
            >
              {{ $i18n.translate(link.text) }}
            </a>
          </li>
        </ul>
      </section>

      <section class="column column_3">
        <form
          class="newsletter"
          @submit.prevent="submit"
          v-if="regions.length > 0"
          novalidate
        >
          <fieldset>
            <label
              class="newsletter__label"
              for="email"
            >
              {{ $i18n.translate(column_3.newsletter.title) }}
            </label>

            <input-email
              v-model="newsletter.email"
              id="email"
              class="newsletter__email"
              :placeholder="$i18n.translate(column_3.newsletter.placeholder)"
              :v="v$.newsletter.email"
            />

            <input-select
              class="newsletter__select margin__tb--12"
              v-model="newsletter.region"
              :options="regions"
              :v="v$.newsletter.region"
            />
          </fieldset>

          <p
            class="newsletter__message margin__t--6"
            v-if="messages.newsletter || errors.newsletter"
            :class="{'color--success': messages.newsletter, 'color--danger': errors.newsletter}"
          >
            {{ messages.newsletter || errors.newsletter }}
          </p>

          <button
            class="newsletter__button"
            type="submit"
            :disabled="loader.newsletter"
            v-if="$i18n.translate(column_3.newsletter.button)"
          >
            <svg
              class="newsletter__button-svg"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="180px"
              height="52px"
              viewBox="0 0 180 52"
              style="enable-background:new 0 0 180 52;"
              preserveAspectRatio="none"
              xml:space="preserve"
            >
              <polygon
                style="fill-rule: evenodd;clip-rule: evenodd;"
                points="5.7,52 5.7,52 180,46.7 180,0 0,4.7 "
              />
            </svg>

            <span
              :class="{'is-hide': loader.newsletter}"
              class="newsletter__button-label"
            >
              {{ $i18n.translate(column_3.newsletter.button) }}
            </span>

            <app-spinner
              size="4"
              v-if="loader.newsletter"
              class="newsletter__button-spinner"
            />
          </button>
        </form>
      </section>

      <section class="column column_4">
        <ul
          class="sponsors"
          v-if="column_4.sponsors.length > 0"
        >
          <template v-for="(sponsor, index) in column_4.sponsors">
            <li
              class="sponsors__item"
              v-if="$i18n.translate(sponsor.logo)"
              :key="`sponsor_${index}`"
            >
              <a
                class="sponsors__item-link"
                v-if="$i18n.translate(sponsor.website)"
                @click.prevent="setRouteNavigation(sponsor.website)"
              >
                <img
                  class="sponsors__item-logo"
                  :src="$i18n.translate(sponsor.logo)"
                  :alt="$i18n.translate(sponsor.name.text)"
                >
              </a>
            </li>
          </template>
        </ul>
      </section>
    </div>
  </footer>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
import { get as _get } from 'lodash'
import { mapGetters } from 'vuex'
import PageRouteNavigation from '%/PageRouteNavigation'
import { required, email } from '@vuelidate/validators'
import axios from '@/vendors/axios/axios'
import { useVuelidate } from '@vuelidate/core'

export default defineComponent({
  name: 'CustomFooterSFOV',
  components: {
    InputSelect: defineAsyncComponent(() => import('&/atoms/InputSelect')),
    InputEmail: defineAsyncComponent(() => import('&/atoms/InputEmail')),
    AppSpinner: defineAsyncComponent(() => import('@/components/atoms/AppSpinner'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      showSessionAppModal: false,
      mobileMenuOpened: false,
      template: null,
      newsletter: {
        email: null,
        region: null
      },
      loader: {
        newsletter: false
      },
      errors: {
        newsletter: null
      },
      messages: {
        newsletter: null
      }
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  mixins: [PageRouteNavigation],
  created () {
    if (!this.newsletter.region && this.column_3.newsletter.regions.length)
      this.newsletter.region = this.$i18n.translate(this.column_3.newsletter.regions[0]['name'])
  },
  computed: {
    ...mapGetters({
      client: 'client/client',
      campaigns: 'campaigns/getCampaigns'
    }),
    links () {
      return [_get(this.column_1, 'links', false), _get(this.column_2, 'links', false), _get(this.column_3, 'links', false), _get(this.column_4, 'links', false)].filter(links => links)
    },
    logo () {
      return _get(this.section, 'attributes.logo')
    },
    networks () {
      const links = _get(this.client, 'platform.social_networks', [])
      return links.length > 0 ? links.filter(link => link.url[this.$i18n.locale]) : []
    },
    column_2 () {
      return _get(this.section, 'attributes.column_2', [])
    },
    column_3 () {
      return _get(this.section, 'attributes.column_3', [])
    },
    column_4 () {
      return _get(this.section, 'attributes.column_4', [])
    },
    socialNetworks () {
      const links = _get(this.client, 'platform.social_networks', [])

      return links
        .map(link => {
          if (link.icon.includes('facebook-square')) {
            link.icon[1] = 'facebook-f'
          } else if (link.icon.includes('linkedin')) {
            link.icon[1] = 'linkedin-in'
          }

          return link
        })
        .filter(link => link.url[this.$i18n.locale])
    },
    regions () {
      return this.column_3.newsletter.regions.map(region => ({
        label: this.$i18n.translate(region.name),
        value: this.$i18n.translate(region.name)
      }))
    }
  },
  methods: {
    isWebsiteLink (link) {
      return link.type === 'custom' && this.$i18n.translate(link.url).includes('https://streekfondsoostvlaanderen.be')
    },
    submit () {
      this.v$.newsletter.$touch()
      this.messages.newsletter = null
      this.errors.newsletter = null

      if (this.v$.$error) return

      this.loader.newsletter = true

      axios.post('https://streekfondsoostvlaanderen.be/wp-json/koalect/v1/newsletter', this.newsletter)
        .then(response => {
          this.v$.newsletter.$reset()
          this.newsletter.email = null
          this.messages.newsletter = _get(response, 'data.data.message')
        })
        .catch(errors => this.errors.newsletter = _get(errors, 'data.data.message'))
        .finally(() => {
          this.loader.newsletter = false

          setTimeout(() => {
            this.messages.newsletter = null
            this.errors.newsletter = null
          }, 5000)
        })
    }
  },
  validations () {
    return {
      newsletter: {
        email: { required, email },
        region: { required }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #dbeff7;
$color--secondary: #056fa0;
$color--tertiary: #eb6608;
$color--hover: #32405a;

.footer {
  background: $color--primary;
  color: $white;
  font-weight: normal;
  padding: $padding__base * 3 0 $padding__base * 6;
}

.wrapper {
  padding: 0 $padding__base * 2;

  @include mq(sm) {
    max-width: 720px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
  }

  @include mq(lg) {
    max-width: 1340px;
    display: flex;
    justify-content: space-between;
  }
}

.column {
  margin: 0 auto;
  padding: $padding__base 0;
  text-align: center;

  @include mq(sm) {
    margin: 0;
    align-self: center;
  }
}

.column_1 {
  max-width: 165px;
}

.column_2 {
  font-size: rem(20px);
  width: 100%;

  @include mq(lg) {
    width: auto;
  }

  &__list {
    @include mq(sm) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__link {
    &--active {
      font-weight: bold;
    }

    &:nth-child(6) {
      margin-top: $margin__base * 3;

      @include mq(sm) {
        margin: 0;
      }
    }
  }
}

.column_3 {
  position: relative;

  @include mq(lg) {
    min-width: 300px;
  }
}

.column_4 {
  max-width: 240px;
  margin: 0 auto;
  margin-top: $margin__base * 3;

  @include mq(sm) {
    margin: 0;
    align-self: center;
  }
}

.logo--img {
  width: 100%;
  height: auto;
}

.networks {
  margin-top: $margin__base * 3;
  display: flex;
  justify-content: space-around;

  &__link {
    transition: all ease-in .2s;
    color: $color--secondary;

    &:hover {
      color: $color--hover;
    }
  }
}

.newsletter {
  background: $white;
  padding: $padding__base * 2;
  padding-bottom: $padding__base * 4;
  text-align: left;

  &__label {
    font-size: rem(22px);
    color: $black;
    font-weight: bold;
    display: block;
    margin-bottom: $margin__base;
  }

  &__email {
    & :deep() {
      .input {
        border: 1px solid $color--secondary;
        border-radius: 0;

        &::placeholder {
          color: $lg;
        }
      }
    }
  }

  &__select {
    width: 100%;
    border: 1px solid $color--secondary;
    color: $black;
  }

  &__message {
    font-size: rem(12px);
  }

  &__button {
    position: absolute;
    z-index: 0;
    padding: $padding__base * 2 $padding__base * 4;
    font-weight: bold;
    bottom: -10%;
    right: 5%;

    &:hover, &:disabled {
      .newsletter__button--svg {
        fill: $color--hover
      }
    }

    &-svg {
      transition: ease-in .2s;
      position: absolute;
      z-index: -5;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      fill: $color--secondary;
    }

    &-label {
      transition: all .2s linear;
      opacity: 1;

      &.is-hide {
        opacity: 0;
      }
    }

    &-spinner {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -20px;
      margin-top: -20px;

      & :deep() {
        .spinner__circle {
          fill: $white;
        }
      }
    }
  }
}

.sponsors {
  &__item {
    max-width: 215px;

    &-logo {
      width: 100%;
      height: auto;
    }
  }
}
.networks {
  &__ul {
    display: flex;
    color: $color--primary;
    justify-content: center;

    @include mq(sm) {
      margin-bottom: 0;
    }
  }

  &__li {
    position: relative;
    width: 25px;
    height: 25px;
    background: $white;
    transition: all $transition__duration;

    &:hover {
      transform: scale(1.1);
    }
  }

  &__a {
    position: absolute;
    color: $color--primary;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: rem(12px);

    &:hover {
      color: $color--primary;
    }
  }
}
</style>