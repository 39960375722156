import { defineStore } from 'pinia'

export const useUserStore = defineStore('UserStore', {
  state: () => ({
    loader: false,
    error: {}
  }),
  actions: {
    postUserVerification ({ params }) {
      return this.$postItem({
        endpoint: '/api/v1/users/verification',
        params
      })
    },
    postSendVerificationEmail ({ params }) {
      return this.$postItem({
        endpoint: '/api/v1/users/send_verification_email',
        params
      })
    },
    fetchValidateNationalId ({ query }) {
      return this.$fetchItem({
        endpoint: '/api/v1/users/validate_national_id',
        query
      })
    }
  }
})