<template>
  <app-tooltip
    placement="left"
    :triggers="['hover']"
  >
    <img
      class="pm__logo"
      v-if="paymentMethod"
      :alt="paymentMethod.label + ' logo'"
      :src="paymentMethod.image"
    >

    <template #popper>
      {{ paymentMethod.label }}
    </template>
  </app-tooltip>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
import { getImagePath } from '@/utils/utils'

export default defineComponent({
  props: {
    type: {
      required: true,
      type: String
    }
  },
  components: {
    AppTooltip: defineAsyncComponent(() => import('@/components/atoms/AppTooltip'))
  },
  data () {
    return {
      payment_methods: [
        {
          value: 'bancontactmrcash',
          label: 'Bancontact',
          image: getImagePath('payments/bancontact-logo.svg')
        },
        {
          value: 'bancontact',
          label: 'Bancontact/Maestro',
          image: getImagePath('payments/bancontact-logo.svg')
        },
        {
          value: 'maestro',
          label: 'Maestro',
          image: getImagePath('payments/maestro-logo.svg')
        },
        {
          value: 'three_d_secure',
          label: 'Credit card',
          image: getImagePath('payments/three-d-secure-logo.svg')
        },
        {
          value: 'visa',
          label: 'VISA',
          image: getImagePath('payments/visa-logo.svg')
        },
        {
          value: 'mastercard',
          label: 'Mastercard',
          image: getImagePath('payments/mastercard-logo.svg')
        },
        {
          value: 'sofort',
          label: 'SOFORT',
          image: getImagePath('payments/sofort-logo.svg')
        },
        {
          value: 'ideal',
          label: 'iDEAL',
          image: getImagePath('payments/ideal-logo.svg')
        },
        {
          value: 'sepa_debit',
          label: 'SEPA debit',
          image: getImagePath('payments/sepa-direct.svg')
        },
        {
          value: 'test_payment',
          label: 'Test payment',
          image: getImagePath('payments/test-payment-logo.svg')
        },
        {
          value: 'payconiq',
          label: 'Payconiq',
          image: getImagePath('payments/payconiq-logo.svg')
        },
        {
          value: 'offline',
          label: 'Offline',
          image: getImagePath('payments/offline-logo.svg')
        },
        {
          value: 'twikey',
          label: 'Twikey',
          image: getImagePath('payments/twikey-logo.svg')
        },
        {
          value: 'belfius',
          label: 'Belfius Pay Button',
          image: getImagePath('payments/belfius-pay-logo.svg')
        },
        {
          value: 'KBCPaymentButton',
          label: 'KBC/CBC Pay Button',
          image: getImagePath('payments/kbc-pay-logo.svg')
        },
        {
          value: 'paypal',
          label: 'PayPal',
          image: getImagePath('payments/paypal-logo.svg')
        },
        {
          value: 'eps',
          label: 'EPS',
          image: getImagePath('payments/eps-logo.svg')
        }
      ]
    }
  },
  computed: {
    paymentMethod () {
      return this.payment_methods.find(pm => pm.value === this.type)
    }
  }
})
</script>

<style scoped lang="scss">
.pm {
  &__logo {
    display: block;
    max-width: 30px;
    width: 100%;
  }
}
</style>
