export default [
  {
    name: 'custom_footer_ugent',
    path: 'modules/pages/universiteitgent/FooterUniversiteitgent'
  },
  {
    name: 'custom_header_ugent',
    path: 'modules/pages/universiteitgent/HeaderUniversiteitgent'
  },
  {
    name: 'custom_homepage_ugent',
    path: 'modules/pages/universiteitgent/HomepageUniversiteitgent'
  }
]
