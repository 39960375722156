<template>
  <div
    class="glide"
    ref="glide"
    type="carousel"
  >
    <div
      class="glide__track"
      data-glide-el="track"
    >
      <ul class="glide__slides">
        <li
          class="glide__slide"
          v-for="(slide, index) in slides"
          :key="`slide_${index}`"
        >
          <slot :slide="slide" />
        </li>
      </ul>
    </div>

    <div
      class="glide__arrows"
      data-glide-el="controls"
    >
      <button
        class="glide__arrow glide__arrow--left translate__x-3"
        data-glide-dir="<"
      >
        <slot name="prev">
          <fa-icon
            class="element"
            :icon="['fal', 'chevron-left']"
          />
        </slot>
      </button>

      <button
        class="glide__arrow glide__arrow--right translate__x3"
        data-glide-dir=">"
      >
        <slot name="next">
          <fa-icon
            class="element"
            :icon="['fal', 'chevron-right']"
          />
        </slot>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, toRefs } from 'vue'
import Glide from '@glidejs/glide'
import '@glidejs/glide/dist/css/glide.core.min.css'

const props = defineProps({
  options: { type: Object },
  slides: { type: Array, required: true }
})

const glide = ref()
const { slides, options } = toRefs(props)

const default_options = { perTouch: 1, perView: 3 }

onMounted(() => new Glide(glide.value, { ...default_options, ...options.value }).mount())
</script>

<style lang="scss" scoped>
.glide {
  position: relative;

  &__slides {
    align-items: center;
  }

  &__arrows {
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;

    @include mq(sm) {
      position: absolute;
      justify-content: space-between;
      top: 50%;
      left: -60px;
      transform: translateY(-50%);
      gap: 0;
      width: calc(100% + 120px);
    }
  }

  &__arrow {
    font-size: 18px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border: 1px solid $black;

    @include mq(sm) {
      width: 30px;
      height: 30px;
      background-color: transparent;
      border: 1px solid transparent;
      font-size: 21px;
    }

    &--disabled {
      visibility: hidden;
      opacity: 0;
    }
  }
}
</style>
