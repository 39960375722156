<template>
  <div class="CustomHomepageULB">
    <div
      class="hero"
      :style="`background-image: url(${$i18n.translate(section.attributes.hero.image)})`"
    >
      <div class="container hero__container">
        <div class="hero__wrapper col-md-10 col-md-push-1">
          <div class="hero__content">
            <div
              class="editor__content hero__text"
              v-html="$i18n.translate(section.attributes.hero.content)"
            />
          </div>
        </div>

        <div
          class="hero__stats"
          v-if="stats"
        >
          <div
            class="columns stats__el"
            v-for="metric in section.attributes.metrics"
            :key="metric.index"
          >
            <img
              v-if="$i18n.translate(metric.image)"
              :src="$i18n.translate(metric.image)"
            >

            <span class="stats__data bold margin__b--6">
              <app-date
                v-if="metric.type === 'date' && metric.date"
                :timestamp="metric.date"
                filter="differenceInCalendarDays"
              />

              <app-money
                v-else-if="metric.type === 'amount_collected'"
                :amount="stats[metric.type]"
              />

              <span v-else>
                {{ stats[metric.type] }}
              </span>
            </span>

            <p>{{ $i18n.translate(metric.text) }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="projects">
      <section-projects-overview
        :page="page"
        :section="projectSection"
      />
    </div>

    <div class="section partners">
      <div class="partners__wrapper wrapper">
        <div
          class="editor__content section_partners-title"
          v-html="$i18n.translate(section.attributes.partners.title)"
        />
        <div
          class="partner"
          v-for="(partner, index) in section.attributes.partners.partners"
          :key="'partner_' + index"
        >
          <div class="partner__column">
            <img
              class="partner__image"
              :src="$i18n.translate(partner.image)"
              :alt="$i18n.translate(partner.title)"
              v-if="$i18n.translate(partner.image)"
            >
          </div>

          <div class="partner__column margin__t--36">
            <h3 class="partner__name">
              {{ $i18n.translate(partner.title) }}
            </h3>

            <a
              v-if="$i18n.translate(partner.logo.image)"
              class="partner__link"
              @click.prevent="setRouteNavigation(partner.logo.link)"
            >
              <img
                class="partner__logo"
                :src="$i18n.translate(partner.logo.image)"
                :alt="$i18n.translate(partner.logo.link.text)"
              >
            </a>

            <div
              class="content"
              v-html="$i18n.translate(partner.content)"
            />

            <a @click.prevent="setRouteNavigation(partner.cta)">
              {{ $i18n.translate(partner.cta.text) }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import SectionProjectsOverview from '&/modules/pages/default/sections/SectionProjectsOverview'
import PageRouteNavigation from '%/PageRouteNavigation'

export default defineComponent({
  name: 'CustomHomepageULB',
  components: {
    SectionProjectsOverview,
    AppDate: defineAsyncComponent(() => import('&/atoms/AppDate')),
    AppMoney: defineAsyncComponent(() => import('&/atoms/AppMoney'))
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },
  created () {
    this.fetchClientStatistics()
  },
  computed: {
    ...mapGetters({
      stats: 'client/statistics'
    }),
    projectSection () {
      return {
        ...this.section,
        attributes: this.section.attributes.projects
      }
    }
  },
  methods: {
    ...mapActions({
      fetchClientStatistics: 'client/fetchClientStatistics'
    })
  },
  mixins: [
    PageRouteNavigation
  ]
})
</script>

<style lang="scss" scoped>
$color-primary: #004A86;
$color-secondary: #131415;

:deep() {
  .btn__color--prim:focus,
  .btn__color--prim:hover {
    background-color: darken( $color-primary, 10% );
  }
}

.wrapper {
  padding: $padding__base * 5 $padding__base * 2;
  max-width: 1200px;
  margin: auto;

  @include mq(sm) {
    padding: $padding__base * 5 $padding__base * 4;
  }
}

.hero {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  &__container {
    position: relative;
  }

  &__wrapper {
    margin: 0 auto;
    padding-top: $padding__base * 5;
    padding-bottom: $padding__base * 5;

    @include mq(sm) {
      padding-bottom: $padding__base * 15;
    }
  }

  &__content {
    max-width: 580px;

    & :deep() {
      h1 {
        font-family: $font-family--secondary;
        font-weight: 700;
        font-size: 38px;
        color: #131415;
        line-height: 50px;
        margin-bottom: calc($margin__base * 2) + 6;
      }
    }
  }

  &__stats {
    background-color: $color-primary;
    width: 100vw;
    padding: $padding__base * 2 0;
    margin-top: 30px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: 600px) {
      display: flex;
      justify-content: space-around;
    }

    @include mq(sm) {
      position: absolute;
      bottom: 0;
      transform: translateY(50%);
      padding: $padding__base * 3 $padding__base * 7;
      width: fit-content;
      margin-top: 0;
      left: 0;
    }

    .columns {
      color: $white;
      text-align: center;
      margin-bottom: 35px;

      &:last-of-type {
        margin-right: 0;
      }

      span {
        font-family: $font-family--secondary;
        font-weight: 700;
        font-size: 26px;
        margin-bottom: calc($margin__base / 2);
      }

      p {
        font-size: 20px;
      }

      img {
        display: block;
        margin: 0 auto 12px auto;
        height: 30px;

        @include mq(sm) {
          margin: 0;
          margin-bottom: $margin__base;
        }
      }

      @media (min-width: 600px) {
        margin-bottom: 0;
      }

      @include mq(sm) {
        margin-right: $margin__base * 9;
        text-align: left;
      }
    }
  }
}

.projects {
  @include mq(sm) {
    padding-top: $padding__base * 9;
  }

  @include mq(md) {
    padding-top: $padding__base * 9;
  }

  :deep() {
    h2 {
      font-family: $font-family--secondary;
      font-weight: 700;
      font-size: 38px;
      color: #131415;
      margin-bottom: $margin__base * 3;
    }

    .btn {
      display: block;
      margin: auto;
      border-radius: 0;
      font-weight: 700;
      font-family: $font-family--secondary;

      @include mq(lg) {
        padding: $padding__base * 2 $padding__base * 2.5;
      }
    }
  }
}

.partners {
  &__wrapper {
    & :deep() {
      h2 {
        font-family: $font-family--secondary;
        font-weight: 700;
        font-size: 38px;
        color: #131415;
        margin-bottom: $margin__base * 3;
        text-align: center;
      }
    }
  }

  .partner {
    display: flex;
    margin-bottom: $margin__base * 3;
    flex-direction: column;

    @include mq(sm) {
      margin-bottom: $margin__base * 8;
    }

    &__logo {
      height: 70px;
      width: auto;
      max-width: 100%;
      display: block;
    }

    &__link {
      margin-top: $margin__base * 6;
      margin-bottom: $margin__base;
      max-width: 100%;
      display: block;
      margin-right: auto;

      @include mq(sm) {
        max-width: 320px;
        margin-top: $margin__base * 3;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    @include mq(sm) {
      flex-direction: row;
    }

    @include mq(sm) {
      &:nth-child(odd) {
        flex-direction: row-reverse;

        .partner__link {
          margin-right: 0;
          margin-left: auto;
          overflow: hidden;
        }

        .partner__logo {
          float: right;
        }

        & :deep() {
          text-align: right;

          .partner__column:first-of-type {
            margin-right: 0;
          }

          .partner__column:last-of-type {
            margin-right: $margin__base * 3;
          }

          .partner__name {
            transform: translate(50%,0);
          }

          li {
            position: relative;
            padding-right: 15px;

            &::before {
              content: url('https://koalect-images.s3.eu-west-3.amazonaws.com/clients/ulb/arrow.svg');
              position: absolute;
              right: 0;
              top: 0;
              transform: rotate(180deg);
              left: inherit;
            }
          }
        }
      }
    }

    .partner__column {
      position: relative;

      &:first-of-type {
        width: 100vw;
        left: 50%;
        transform: translateX(-50%);
      }

      @include mq(sm) {
        flex: 0 1 50%;

        &:first-of-type {
          left: 0;
          transform: none;
          width: inherit;
          margin-right: $margin__base * 3;
        }
      }
    }

    &__image {
      width: 100%;
    }

    &__name {
      display: inline-block;
      position: relative;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
      width: fit-content;
      color: $white;
      background-color: $color-primary;
      padding: $padding__base $padding__base * 3.5;
      font-size: 25px;
      text-align: center;

      @include mq(sm) {
        position: relative;
        left: auto;
        top: auto;
        transform: translate(-50%,0);
      }
    }

    & :deep() {
      p {
        font-size: 16px;
        line-height: 27px;
        margin-bottom: $margin__base;

        strong {
          font-weight: bold;
        }
      }

      li {
        position: relative;
        padding-left: 15px;
        margin-bottom: $margin__base;

        b {
          font-weight: bold;
        }

        &::before {
          content: url('https://koalect-images.s3.eu-west-3.amazonaws.com/clients/ulb/arrow.svg');
          position: absolute;
          left: 0;
          top: 3px;
        }
      }

      a {
        text-decoration: underline;
        font-family: $font-family--secondary;
        font-weight: bold;

        &:hover {
          color: $color-primary;
        }
      }
    }
  }
}
</style>
