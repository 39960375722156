<template>
  <header
    class="header"
    :class="{ 'is-minimal': isMinimal }"
  >
    <div class="header__container">
      <router-link
        class="logo"
        :to="{ name: 'Home', params: { locale } }"
      >
        <transition name="logo">
          <img
            class="logo__img"
            :src="translate(logo.stacked)"
            :alt="client.name"
            v-if="!isMinimalLogo && translate(logo.stacked)"
          >
        </transition>

        <transition name="logo">
          <img
            class="logo__img"
            :src="translate(logo.inline)"
            :alt="client.name"
            v-if="isMinimalLogo && translate(logo.inline)"
          >
        </transition>
      </router-link>

      <div class="header__wrapper">
        <div class="top-bar d-none d-md-flex">
          <nav class="top-nav">
            <ul
              class="top-nav__ul"
              v-if="topNavigation.length"
            >
              <li
                class="top-nav__li"
                v-for="({ link }, key) in topNavigation"
                :key="`link_${key}`"
              >
                <app-link
                  :link="link"
                  class="top-nav__a"
                />
              </li>
            </ul>
          </nav>

          <app-session-button class="session" />

          <language-switcher class="language-switcher" />
        </div>

        <div class="main-bar header__mainbar">
          <nav class="main-nav d-none d-md-flex">
            <ul
              class="main-nav__ul"
              v-if="navigation.length"
            >
              <li
                class="main-nav__li"
                v-for="({ link, navigation: subnav }, key) in navigation"
                :key="`link_${key}`"
              >
                <button
                  class="main-nav__a"
                  :class="{ 'is-active': submenu === key, 'has-submenu': subnav.length }"
                  @click="submenu = key"
                  v-if="translate(link.text) && subnav.length"
                >
                  {{ translate(link.text) }}
                </button>

                <app-link
                  :link="link"
                  class="main-nav__a"
                  @click="submenu = null"
                  v-else
                />

                <transition name="submenu">
                  <nav
                    class="main-nav__submenu submenu"
                    v-if="subnav.length && submenu === key"
                  >
                    <ul class="submenu__ul">
                      <li
                        class="submenu__li"
                        v-for="({ link: sublink }, k) in subnav"
                        :key="`link_${key}_${k}`"
                      >
                        <app-link
                          :link="sublink"
                          class="submenu__a"
                          @click="submenu = null"
                        />
                      </li>
                    </ul>
                  </nav>
                </transition>
              </li>
            </ul>
          </nav>

          <app-link
            :link="cta"
            class="btn btn__size--small cta d-none d-sm-flex"
            v-slot="{ text }"
          >
            <span>
              <fa-icon
                class="margin__r--6"
                :icon="['fal', 'long-arrow-right']"
                fixed-width
              />

              {{ text }}
            </span>
          </app-link>

          <app-burger-menu
            class="d-md-none bg__color--white burger-menu"
            :state="show_mobile_menu"
            @toggle="toggleMobileMenu"
          />
        </div>
      </div>
    </div>

    <transition name="mobile-nav">
      <div
        class="mobile-nav"
        v-if="show_mobile_menu"
      >
        <div class="mobile-nav__container">
          <div class="mobile-nav__wrapper">
            <ul
              class="mobile-nav__ul"
              v-if="navigation.length"
            >
              <li
                class="mobile-nav__li"
                v-for="({ link, navigation: subnav }, key) in navigation"
                :key="`link_${key}`"
              >
                <app-link
                  :link="link"
                  class="mobile-nav__a"
                  v-slot="{ text }"
                  @click="submenu = null"
                >
                  {{ text }}

                  <fa-icon
                    class="mobile-nav__arrow"
                    :icon="['fal', 'arrow-right']"
                    fixed-width
                  />
                </app-link>

                <ul
                  class="mobile-nav__ul"
                  v-if="subnav.length"
                >
                  <li
                    class="mobile-nav__li"
                    v-for="({ link: sublink }, k) in subnav"
                    :key="`link_${key}_${k}`"
                  >
                    <app-link
                      :link="sublink"
                      class="mobile-nav__a"
                      @click="submenu = null"
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div class="mobile-nav__bar">
            <nav class="mobile-nav__sub-nav sub-nav">
              <ul
                class="sub-nav__ul"
                v-if="topNavigation.length"
              >
                <li
                  class="sub-nav__li"
                  v-for="({ link }, key) in topNavigation"
                  :key="`link_${key}`"
                >
                  <app-link
                    :link="link"
                    class="sub-nav__a"
                  />
                </li>
              </ul>
            </nav>

            <app-session-button
              class="session"
              :text="false"
            />


            <language-switcher
              class="languages"
              v-slot="{ options, updateLanguage, isActive }"
            >
              <ul class="languages__ul">
                <li
                  class="languages__li"
                  v-for="language in options"
                  :key="language.id"
                >
                  <button
                    class="languages__a"
                    :class="{'active': isActive(language.id)}"
                    type="button"
                    @click.prevent="updateLanguage(language.id)"
                  >
                    {{ language.label.value.toUpperCase() }}
                  </button>
                </li>
              </ul>
            </language-switcher>
          </div>

          <app-link
            :link="cta"
            class="btn btn__size--small cta"
            v-slot="{ text }"
          >
            <span>
              <fa-icon
                class="margin__r--6"
                :icon="['fal', 'long-arrow-right']"
                fixed-width
              />

              {{ text }}
            </span>
          </app-link>
        </div>
      </div>
    </transition>
  </header>
</template>

<script setup>
import { ref, computed, watch, toRefs, onBeforeMount, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'
import { useClientStore } from '@/stores/ClientStore'
import { get as _get } from 'lodash'
import { useI18n } from '@/vendors/i18n'
import { useWindowSize } from '@/composables/app/useWindowSize'
import { useWindowScroll } from '@/composables/app/useWindowScroll'
import { scrollTo } from '@/utils/utils'

import AppLink from '&/atoms/AppLink'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
import LanguageSwitcher from '&/atoms/LanguageSwitcher'
import AppSessionButton from '&/organisms/AppSessionButton'

const props = defineProps({
  section: { type: Object, required: true }
})

const { getClient } = useClientStore()
const { translate } = useI18n()
const { locale } = toRefs(useI18n())
const route = useRoute()
const { width } = useWindowSize()
const { top } = useWindowScroll()
const { client } = getClient()

const submenu = ref(null)
const show_mobile_menu = ref(false)

const logo = computed(() => _get(props, 'section.attributes.logo', {}))
const navigation = computed(() => _get(props, 'section.attributes.navigation', []))
const topNavigation = computed(() => _get(props, 'section.attributes.top_navigation', []))
const cta = computed(() => _get(props, 'section.attributes.cta', {}))

const isMinimal = computed(() => top.value > 60)
const isMinimalLogo = computed(() => width.value < 992 || isMinimal.value)

const toggleMobileMenu = () => {
  scrollTo()

  setTimeout(() => show_mobile_menu.value = !show_mobile_menu.value, 100)
}

const handleClickWindow = ({ target }) => {
  if (target?.classList.contains('main-nav__a')) return

  submenu.value = null
}

watch(route, () => show_mobile_menu.value = false)

onBeforeMount(() => window.addEventListener('click', handleClickWindow))
onBeforeUnmount(() => window.removeEventListener('click', handleClickWindow))
</script>

<style lang="scss" scoped>
$color--primary: #00AEEF;
$color--secondary: #202243;
$color--tertiary: #FFFF00;

a,
:deep(a) {
  transition: color 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

:deep(.cta) {
  font-family: $font-family--secondary;
  color: #585858;
  background: $color--tertiary;
  overflow: hidden;
  position: relative;
  display: inline-flex;
  align-items: center;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: rem(20px);
  letter-spacing: -0.7px;
  padding: 12px 20px;

  @include mq(md) {
    padding: 0px 20px;
  }

  &::before {
    content: '';
    background: #e6e600;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: skewX(-10deg) translateX(110%);
    transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }

  &:hover {
    color: $color--secondary;

    &::before {
      transform: translateX(0);
    }
  }

  span {
    position: relative;
  }
}

.header {
  position: sticky;
  z-index: 10;
  top: 0;
  height: 60px;
  z-index: 9999;

  @include mq(md) {
    height: 100px;
  }

  &__container {
    display: grid;
    grid-template-columns: auto 48px;
    background: $color--primary;
    background: $white;

    @include mq(sm) {
      grid-template-columns: 280px auto;
    }

    @include mq(md) {
      grid-template-columns: 200px auto;
      background: $white;
      transition: all .3s ease;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__mainbar {
    flex-grow: 1;
  }

  &.is-minimal {
    @include mq(md) {
      margin-bottom: 40px;

      :deep(.cta) {
        font-size: rem(18px);
      }

      .header {
        &__container {
          transition: all .3s .1s ease;
          grid-template-columns: 320px auto;
        }
      }

      .logo {
        height: 60px;

        @include aspect-ratio(320, 60);

        &__img {
          max-width: 240px;
        }
      }

      .top-bar {
        margin-top: -38px;
        transition: all .3s ease;
      }

      .main-nav {
        &__a,
        :deep(.main-nav__a) {
          font-size: rem(18px);
        }
      }
    }
  }
}

.logo {
  background: $color--primary;
  display: flex;
  height: 60px;
  transition: all .3s ease;
  position: relative;

  @include mq(md) {
    @include aspect-ratio(200, 100);

    height: 100px;
  }

  &__img {
    position: absolute;
    left: 20px;
    top: 50%;
    width: calc(90% - 40px);
    height: auto;
    max-width: 280px;
    max-height: 60px;
    transform: translateY(-50%);

    @include mq(sm) {
      width: auto;
      max-width: 90%;
    }

    @include mq(md) {
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all .3s .2s ease-in;
    }

    &.logo-enter-from,
    &.logo-leave-to {
      opacity: 0;
      transition: all .2s ease-in-out;
    }
  }
}

.top-bar {
  background: #f5f5f5;
  display: flex;
  align-items: center;
  padding: 0 25px;
  gap: 24px;
  justify-content: flex-end;
  font-size: 12px;
  transition: all .3s ease-in-out;
}

.top-nav {
  position: relative;
  padding: 0 15px;

  &::after {
    content: '|';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #dedede;
  }

  &__ul {
    display: flex;
  }

  :deep(.top-nav__a) {
    color: #585858;
    padding: 10px 15px;
    display: block;

    &.is-active,
    &:hover {
      color: $color--primary;
    }
  }
}

.language-switcher {
  padding: 10px;

  &:deep() {
    .locale-switcher {
      background: transparent;
    }

    .v-select,
    .vs__search {
      font-size: 14px;
      border: 0px!important;

      @include mq(md) {
        font-size: 12px;
      }
    }

    .vs__selected {
      line-height: 1.5;
      color: $color--primary;
    }

    .ls__dropdown-menu-icon {
      display: none;
    }

    .vs__dropdown-toggle {
      padding: 0;
    }

    .vs__actions {
      padding: 0 0 0 8px;
    }

    .vs__open-indicator {
      color: $color--primary;
    }

    .vs__dropdown-menu {
      background: #f5f5f5;
      border: 0;
    }

    .vs__dropdown-option {
      padding: 10px;
      font-size: rem(12px);
      line-height: 1.5;

      &:not(:last-child) {
        border-bottom: 1px solid $white;
      }

      .ls__dropdown-option {
        color: $color--primary;
        padding: 0;
        transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        cursor: pointer;

        &:hover {
          color: $color--secondary;
        }
      }

      &--selected {
        background: transparent;

        .ls__dropdown-option {
          color: $color--secondary;
        }
      }
    }
  }
}

.main-bar {
  display: flex;
  justify-content: flex-end;
  background: $color--primary;

  @include mq(md) {
    background: $white;
    justify-content: space-between;
  }
}

.main-nav {
  padding: 0 20px;
  display: flex;
  text-align: center;

  &__ul {
    display: flex;
  }

  &__a,
  :deep(.main-nav__a) {
    color: $color--secondary;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    height: 100%;
    line-height: 0.9;
    font-family: $font-family--secondary;
    letter-spacing: -0.7px;
    font-size: rem(20px);
    position: relative;

    &.is-active,
    &:hover {
      color: $color--primary;
    }


    &.has-submenu {
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 0 solid $color--secondary;
        transition: border .5s ease-in-out, opacity .5s ease-in-out;
        opacity: 0;
      }

      &.is-active {
        &::after {
          opacity: 1;
          border-bottom: 9px solid $color--secondary;
        }
      }
    }
  }

  &__submenu {
    position: absolute;
    background: $color--secondary;
    left: 0;
    width: 100%;
    z-index: -1;
    padding: 0 24px;
  }
}

.submenu {
  transition: all 0.5s ease;
  overflow: auto;

  &.submenu-enter-active,
  &.submenu-leave-active {
    transform: translateY(0);
  }

  &.submenu-enter-from,
  &.submenu-leave-to {
    opacity: 0;
    transform: translateY(-100%);
  }

  &::-webkit-scrollbar{
    height: 4px;
    width: 4px;
    background: gray;
  }

  &::-webkit-scrollbar-track {
    background: $color--secondary;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &::-webkit-scrollbar-thumb:horizontal{
    background: $color--primary;
    border-radius: 10px;
  }

  &__ul {
    display: inline-flex;
  }

  &__li {
    flex: 0 0 auto;
  }

  :deep(.submenu__a) {
    color: $white;
    padding: 16px 15px 10px 15px;
    display: flex;
    align-items: center;
    font-family: $font-family--secondary;
    letter-spacing: -0.7px;
    font-size: rem(18px);
    text-transform: uppercase;
    border-bottom: 3px solid transparent;
    transition: all .2s ease-in-out;
    white-space: nowrap;

    &.is-active,
    &:hover {
      color: $color--primary;
    }

    &.is-active {
      border-color: $color--primary;
    }
  }
}

.burger-menu {
  &:deep(.menu__label) {
    display: none;
  }
}

.mobile-nav {
  position: fixed;
  max-height: 100vh;
  top: 0px;
  background: #f5f5f5;
  width: 100%;
  z-index: 9999;
  position: relative;
  overflow: hidden;
  transition: all .3s ease-in-out;

  @include mq(md) {
    display: none;
  }

  &.mobile-nav-enter-from,
  &.mobile-nav-leave-to {
    max-height: 0;
  }

  &__container {
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
  }

  &__wrapper {
    flex-grow: 1;
    padding: 20px 20px 20px 0;
    overflow: auto;
  }

  &__ul {
    background: $white;
    padding: 20px;
    text-transform: uppercase;
    font-family: $font-family--secondary;
    letter-spacing: -0.7px;
    font-size: rem(24px);

    @include mq(sm) {
      font-size: rem(32px);
    }
  }

  &__li {
    .mobile-nav {
      &__ul {
        margin: 12px 0;
        background: #f5f5f5;
        font-size: 0.7142857143em
      }
    }
  }

  :deep(.mobile-nav__a) {
    color: $color--secondary;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;

    &.is-active,
    &:hover {
      color: $color--primary;
    }
  }

  &__arrow {
    font-size: 0.7em;
    flex-shrink: 0;
  }

  &__bar {
    display: flex;
    gap: 12px;
    padding: 0 20px;
    font-size: rem(14px);
    color: #585858;

    @include mq(sm) {
      font-size: rem(16px);
    }
  }

  &__sub-nav {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .sub-nav {
    &__ul {
      display: flex;
    }

    :deep(.sub-nav__a) {
      color: #585858;
      padding: 10px;
      display: block;

      &.is-active,
      &:hover {
        color: $color--primary;
      }
    }
  }
}

.languages {
  &__ul {
    display: flex;
    font-size: 13px;
    position: relative;
  }

  :deep(.locale-switcher) {
    background: none;
  }

  &__li {
    padding: 0 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__a {
    display: block;
    color: #404040;
    line-height: 1;
    transition: all .3s;

    &.active,
    &:hover:not(.active) {
      color: $color--primary;
    }
  }
}
</style>
