import { get as _get } from 'lodash'

export default class Action {
  constructor ({ name = null, attributes = {}, parameters = {} } = {}) {
    this.name = name
    this.attributes = attributes
    this.parameters = parameters
  }

  get is_optional () {
    return _get(this.parameters, 'optional', false)
  }
}
