<template>
  <header
    class="header bg__color--white"
    :class="{ 'is-fixed': showMobileMenu }"
  >
    <div class="container header__wrapper">
      <div class="topbar">
        <router-link
          class="session__user d-flex align-items-center padding__lr--12 padding__tb--6 link__color--prim link--naked bold"
          :to="{ name: 'Profile', params: { locale } }"
          v-if="isAuthenticated && user"
        >
          <user-profile-img
            :size="2"
            :avatar="user.avatar"
          />

          <p class="margin__l--6">
            {{ user.firstname }}
          </p>
        </router-link>

        <button
          @click.prevent="toggleSessionModal('login')"
          class="session__login padding__lr--6 link__color--prim link--naked bold"
          v-else
        >
          <fa-icon
            class="margin__r--6"
            :icon="['fas', 'user']"
          />

          {{ t('literal.login') }}
        </button>

        <language-switcher class="languages" />
      </div>

      <router-link
        :to="{ name: 'Home', params: { locale } }"
        class="logo"
        v-if="translate(logo) || client.logo.url"
      >
        <img
          class="logo__img"
          :src="translate(logo)"
          :alt="client.name"
          v-if="translate(logo)"
        >

        <img
          class="logo__img"
          :src="client.logo.url"
          :alt="client.name"
          v-else
        >
      </router-link>

      <div class="d-flex align-items-center justify-content-end justify-content-md-between">
        <nav
          class="d-md-flex justify-content-between align-items-md-center menus"
          :class="{ 'd-flex': showMobileMenu, 'd-none': !showMobileMenu }"
        >
          <ul class="navigation d-md-flex align-items-center">
            <li
              class="navigation__li"
              v-for="({ link }, key) in links"
              :key="`link_${key}`"
            >
              <app-link
                :link="link"
                class="navigation__link link__color--prim-sec link--naked"
              />
            </li>
          </ul>

          <ul class="actions padding--24 d-sm-none">
            <li
              class="actions__li"
              v-for="({ link, color, type }, key) in actions"
              :key="`action_${key}`"
            >
              <app-link
                :link="link"
                :class="`actions__btn btn btn__${type}--${color}`"
              />
            </li>
          </ul>
        </nav>

        <ul class="actions d-none d-sm-flex">
          <li
            class="actions__li"
            v-for="({ link, color, type }, key) in actions"
            :key="`action_${key}`"
          >
            <app-link
              :link="link"
              :class="`actions__btn btn btn__${type}--${color}`"
            />
          </li>
        </ul>

        <app-burger-menu
          class="burger d-md-none no-padding--r"
          :state="showMobileMenu"
          @toggle="toggleMobileMenu"
        />
      </div>
    </div>

    <app-modal
      v-if="showSessionModal"
      size="sm"
      @close="showSessionModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="sessionTemplate"
          @authenticated="showSessionModal = false"
        />
      </template>
    </app-modal>
  </header>
</template>

<script setup>
import { computed, defineAsyncComponent, ref, watch } from 'vue'
import { mapGetters } from '@/store/map-state'
import { get as _get } from 'lodash'
import { useI18n } from '@/vendors/i18n'
import { scrollTo } from '@/utils/utils'
import { useRoute } from 'vue-router'

import LanguageSwitcher from '&/atoms/LanguageSwitcher'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const AppBurgerMenu = defineAsyncComponent(() => import('&/atoms/AppBurgerMenu'))
const UserProfileImg = defineAsyncComponent(() => import('&/atoms/UserProfileImg'))
const AppModal = defineAsyncComponent(() => import('&/organisms/AppModal'))
const SessionForms = defineAsyncComponent(() => import('&/modules/sessions/SessionForms'))

const props = defineProps({
  section: { type: Object, required: true }
})

const { locale, t, translate } = useI18n()
const route = useRoute()
const { client } = mapGetters('client')
const { isAuthenticated, user } = mapGetters('auth')

const showSessionModal = ref(false)
const showMobileMenu = ref(false)
const sessionTemplate = ref('login')
const logo = computed(() => _get(props, 'section.attributes.logo', []))
const links = computed(() => _get(props, 'section.attributes.links', []))
const actions = computed(() => _get(props, 'section.attributes.actions', []))

watch(route, () => showMobileMenu.value = false)

const toggleSessionModal = template => {
  sessionTemplate.value = template
  showSessionModal.value = true
}

const toggleMobileMenu = () => {
  scrollTo()

  setTimeout(() => showMobileMenu.value = !showMobileMenu.value, 100)
}
</script>

<style lang="scss" scoped>
.header {
  padding: $padding__base * 1.5 0;
  background: #FFFAF5;
  &.is-fixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    height: 100vh;
  }

  &__wrapper {
    display: grid;
    gap: $margin__base;
    grid-template-columns: 90px auto;
    grid-template-rows: auto 1fr;
    align-items: flex-start;
    grid-template-areas:
      "logo topbar"
      "logo menus";

    @include mq(md) {
      gap: $margin__base $margin__base * 2;
      grid-template-columns: 80px auto;
    }

    @include mq(lg) {
      grid-template-columns: 108px auto;
    }

    @include mq(xl) {
      gap: $margin__base $margin__base * 4;
    }
  }
}
.topbar {
  display: flex;
  flex-direction: row-reverse;
  grid-area: topbar;
}

.languages {
  :deep(.v-select) {
    border: 0;
    background: #FFFAF5;
  }
}

.session {
  &__login,
  &__user {
    font-size: rem(14px);

    @include mq(lg) {
      font-size: rem(18px);
    }
  }
}

.logo {
  display: block;
  grid-area: logo;

  &__img {
    width: 100%;
    height: auto;
  }
}

.burger {
  :deep(.menu__label) {
    margin-right: calc($margin__base / 2);
    font-size: rem(12px);
  }
}

.menus {
  text-align: center;
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 137px);
  flex-direction: column;
  scrollbar-width: none;
  grid-area: menus;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #FFFAF5;

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
     display: none;
  }

  @include mq(md) {
    text-align: left;
    flex-direction: row;
    flex: 1 1 auto;
    height: auto;
    overflow: hidden;
    position: relative;
  }
}

.navigation {
  flex: 1 1 0;
  font-size: rem(32px);
  line-height: 1;
  text-align: center;
  padding: $padding__base * 4;
  min-width: 0;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;

  @include mq(md) {
    padding: 0;
    font-size: rem(16px);
  }

  @include mq(lg) {
    font-size: rem(18px);
  }

  @include mq(xl) {
    font-size: rem(24px);
  }

  &::-webkit-scrollbar {
     display: none;
  }

  @include mq(md) {
    text-align: left;
    padding: 0;
    overflow-x: auto;
    font-size: rem(18px);
  }

  @include mq(lg) {
    font-size: rem(20px);
  }

  &__li {
    white-space: nowrap;

    &:not(:last-child) {
      margin-right: $margin__base;

      @include mq(lg) {
        margin-right: $margin__base * 2;
      }
    }
  }

  :deep(.navigation__link) {
    display: block;
    padding: $padding__base * 2;
    line-height: 1;

    @include mq(md) {
      padding: calc($padding__base / 2) 0;
    }

    &.is-active {
      color: $color--secondary;
    }
  }
}

.actions {
  flex: 0 0 auto;
  position: relative;

  @include mq(md) {
    padding: 0;
    margin-left: $margin__base;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -48px;
      top: 0;
      width: 48px;
      height: 100%;
      background: #FFFAF5;
      background: linear-gradient(-90deg, rgba(#FFFAF5, 1) 0%, rgba(#FFFAF5, 0) 100%);
    }
  }

  &__li {
    padding: $padding__base calc($padding__base / 2);

    @include mq(sm) {
      padding: 0;

      &:not(:last-child) {
        margin-right: $margin__base;
      }
    }
  }

  :deep(.actions__btn) {
    width: 100%;
    display: block;
    border-radius: 30px;
    border-width: 2px;
    padding: calc($padding__base - 2px) $padding__base * 1.5;
    font-size: rem(18px);

    @include mq(md) {
      width: auto;
      font-size: rem(16px);
    }

    @include mq(lg) {
      padding: calc($padding__base - 2px) $padding__base * 2.5;
    }

    @include mq(xl) {
      font-size: rem(18px);
    }

    &[class*='btn__solid'] {
      border-width: 2px;
      padding-top: calc($padding__base - 4px);
      padding-bottom: calc($padding__base - 4px);
    }
  }
}
.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}
</style>
