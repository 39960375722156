<template>
  <footer class="footer">
    <div class="wrapper">
      <section class="top">
        <div
          v-for="(column, e) in links"
          :key="`column_${e}`"
          class="top__col"
        >
          <nav>
            <ul class="top__menu margin__b--12">
              <li
                class="top__menu-item"
                v-for="(item, index) in column"
                :key="`menu_item_${index}`"
              >
                <h4 class="top__menu-link">
                  <a
                    class="top__menu-link bold"
                    @click.prevent="setRouteNavigation(item.link);"
                  >
                    {{ $i18n.translate(item.link.text) }}
                  </a>
                </h4>

                <nav class="top__submenu margin__tb--24">
                  <ul v-if="item.sublinks.length > 0">
                    <li
                      class="top__submenu-item"
                      v-for="(sublink, i) in item.sublinks"
                      :key="`menu_item_${index}_${i}`"
                    >
                      <a
                        class="top__submenu-link margin__b--12"
                        @click.prevent="setRouteNavigation(sublink.link);"
                      >
                        <fa-icon
                          class="top__submenu-icon margin__r--6 margin__t--6"
                          :icon="['fal', 'arrow-right']"
                        />

                        <span>{{ $i18n.translate(sublink.link.text) }}</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </li>
            </ul>
          </nav>
        </div>

        <div class="top__col">
          <a
            class="top__button bold btn__solid--white btn__size--small"
            @click.prevent="setRouteNavigation(column_5.button)"
          >
            {{ $i18n.translate(column_5.button.text) }}
          </a>
        </div>
      </section>

      <section class="bottom padding__t--12 padding__b--24">
        <ul
          class="networks__ul margin__b--36"
          v-if="socialNetworks.length > 0"
        >
          <li
            class="networks__li margin__r--6"
            v-for="(network, index) in socialNetworks"
            :key="'footer_menu_network_' + index"
          >
            <a
              class="networks__a padding--6"
              :href="$i18n.translate(network.url)"
              target="_blank"
            >
              <fa-icon
                v-if="network.icon"
                size="lg"
                :icon="network.icon"
              />
            </a>
          </li>
        </ul>

        <div class="bottom__right">
          <button
            @click.prevent="openSessionAppModal('register')"
            class="bottom__register bold"
            v-if="!isAuthenticated"
          >
            <fa-icon
              class="margin__r--6"
              :icon="['fas', 'user']"
            />

            <span v-html="$i18n.t('literal.login')" />
          </button>

          <router-link
            class="bottom__user bold d-flex align-items-center"
            :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
            v-if="isAuthenticated && user"
          >
            <user-profile-img
              size="2"
              :avatar="user.avatar"
            />

            <span class="bottom__user-name d-block margin__l--6">{{ user.firstname }}</span>
          </router-link>

          <small
            class="bottom__copyright margin__l--12"
            v-if="$i18n.translate(copyright)"
          >
            {{ $i18n.translate(copyright) }}
          </small>
        </div>
      </section>

      <app-modal
        v-if="showSessionAppModal"
        size="sm"
        @close="showSessionAppModal = false"
      >
        <template #body>
          <session-forms
            class="modal__body"
            :tab="template"
            @authenticated="showSessionAppModal = false"
          />
        </template>
      </app-modal>
    </div>
  </footer>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
import { get as _get } from 'lodash'
import { mapGetters } from 'vuex'
import UserProfileImg from '&/atoms/UserProfileImg'
import PageRouteNavigation from '%/PageRouteNavigation'

export default defineComponent({
  name: 'CustomFooterUGent',
  components: {
    UserProfileImg,
    SessionForms: defineAsyncComponent(() => import('&/modules/sessions/SessionForms')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      showSessionAppModal: false,
      mobileMenuOpened: false,
      template: null
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    links () {
      return [_get(this.column_1, 'links', false), _get(this.column_2, 'links', false), _get(this.column_3, 'links', false), _get(this.column_4, 'links', false)].filter(links => links)
    },
    column_1 () {
      return _get(this.section, 'attributes.column_1', [])
    },
    column_2 () {
      return _get(this.section, 'attributes.column_2', [])
    },
    column_3 () {
      return _get(this.section, 'attributes.column_3', [])
    },
    column_4 () {
      return _get(this.section, 'attributes.column_4', [])
    },
    column_5 () {
      return _get(this.section, 'attributes.column_5', [])
    },
    copyright () {
      return _get(this.section, 'attributes.copyright')
    },
    socialNetworks () {
      const links = _get(this.client, 'platform.social_networks', [])

      return links
        .map(link => {
          if (link.icon.includes('facebook-square')) {
            link.icon[1] = 'facebook-f'
          } else if (link.icon.includes('linkedin')) {
            link.icon[1] = 'linkedin-in'
          }

          return link
        })
        .filter(link => link.url[this.$i18n.locale])
    }
  },
  methods: {
    openSessionAppModal (template) {
      this.template = template
      this.showSessionAppModal = true
    }
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #1E64C8;
$color--secondary: #E9F0FA;
$color--secondary-hover: #030912;

.footer {
  background: $color--primary;
  color: $white;
  font-weight: normal;
}

.wrapper {
  padding: 0 $padding__base * 2;

  @include mq(sm) {
    padding: 0 $padding__base * 4;
  }

  @include mq(mq) {
    padding: 0 $padding__base * 7;
  }
}

.top {
  max-width: 1318px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: $grid-gutter-width;
  justify-content: space-between;
  padding: $padding__base * 3 0;

  @include mq(sm) {
    grid-template-columns: repeat(3, 1fr);
    padding: $padding__base * 5 0 $padding__base * 3;
  }

  @include mq(lg) {
    grid-template-columns: repeat(5, 1fr);
  }

  &__menu-link {
    color: $white;
    text-transform: uppercase;
    transition: all $transition__duration;

    &:hover {
        color: #ffffffbf;
    }
  }

  &__submenu {
    &-link {
      display: flex;
      color: $white;
      transition: all $transition__duration;

      &:hover {
        color: #ffffffbf;
      }
    }
  }

  &__button {
    width: max-content;
    transition: all $transition__duration;

    &:hover {
      border: 1px solid #ffffffbf;
      color: #ffffffbf;
    }
  }
}

.bottom {
  max-width: 1318px;
  margin: 0 auto;
  border-top: 1px solid $white;

  @include mq(sm) {
    display: flex;
    justify-content: space-between;
  }

  &__right {
    text-align: center;

    @include mq(sm) {
      display: flex;
      text-align: center;
      align-items: center;
    }
  }

  &__register, &__user-name {
    color: $white;
    transition: all $transition__duration;

    &:hover {
      color: #ffffffbf;
    }
  }

  &__user {
    justify-content: center;
  }

  &__copyright {
    font-size: rem(10px);
    vertical-align: middle;
  }
}

.networks {
  &__ul {
    display: flex;
    color: $color--primary;
    justify-content: center;

    @include mq(sm) {
      margin-bottom: 0;
    }
  }

  &__li {
    position: relative;
    width: 25px;
    height: 25px;
    background: $white;
    transition: all $transition__duration;

    &:hover {
      transform: scale(1.1);
    }
  }

  &__a {
    position: absolute;
    color: $color--primary;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: rem(12px);

    &:hover {
      color: $color--primary;
    }
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}
</style>