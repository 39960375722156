<template>
  <router-link
    class="session d-flex align-items-center link--naked"
    v-bind="$attrs"
    :to="{ name: 'Profile', params: { locale } }"
    @click="emit('click')"
    v-if="isAuthenticated && authUser"
  >
    <slot
      name="user"
      :user="authUser"
    >
      <user-profile-img
        class="session__avatar"
        :size="2"
        :avatar="authUser.avatar"
        v-if="icon"
      />

      <span
        class="margin__l--6"
        v-if="text"
      >
        {{ authUser.firstname }}
      </span>
    </slot>
  </router-link>

  <button
    class="session link--naked"
    v-bind="$attrs"
    @click.prevent="openModal()"
    v-else
  >
    <slot name="login">
      <fa-icon
        class="session__icon"
        :icon="['fas', 'user']"
        v-if="icon"
      />

      <span
        class="margin__l--6"
        v-if="text"
      >
        {{ t('literal.login') }}
      </span>
    </slot>
  </button>

  <app-modal
    v-if="show_modal"
    size="sm"
    @close="handleModalClose()"
  >
    <template #body>
      <session-forms
        class="modal__body"
        :tab="tab"
        @authenticated="handleModalClose()"
      />
    </template>
  </app-modal>
</template>

<script setup>
import { toRefs, defineAsyncComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { useModals } from '@/composables/app/useModals'
import { useI18n } from '@/vendors/i18n'

const UserProfileImg = defineAsyncComponent(() => import('&/atoms/UserProfileImg'))
const AppModal = defineAsyncComponent(() => import('&/organisms/AppModal'))
const SessionForms = defineAsyncComponent(() => import('&/modules/sessions/SessionForms'))

const emit = defineEmits(['click', 'close'])

const props = defineProps({
  tab: { type: String, default: 'login' },
  icon: { type: Boolean, default: true },
  text: { type: Boolean, default: true }
})

const { tab } = toRefs(props)

const { t } = useI18n()
const { locale } = toRefs(useI18n())
const { show_modal, openModal, closeModal } = useModals()
const { authUser, isAuthenticated } = storeToRefs(useAuthenticationStore())

const handleModalClose = () => {
  closeModal()

  emit('close')
}
</script>

<style lang="scss" scoped>
.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}
</style>