import { Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import { getImageAttributes, handleDropImages, handlePasteImages } from '@/vendors/tiptap/helpers'
import { get as _get } from 'lodash'
import NodeImage from '&/modules/editor/NodeImage'
import Files from '@/classes/files/Files'

export default Node.create({
  name: 'image',
  group: 'block',
  selectable: true,
  draggable: true,
  priority: 1000,

  addOptions () {
    return {
      HTMLAttributes: {}
    }
  },

  addAttributes () {
    return {
      type: { default: 'figure', rendered: false },
      file: { default: null, rendered: false },
      src: {
        default: null,
        parseHTML: element => element.querySelector('img')?.getAttribute('src'),
        rendered: false
      },
      alt: {
        default: null,
        parseHTML: element => element.querySelector('img')?.getAttribute('alt'),
        rendered: false
      },
      title: {
        default: null,
        parseHTML: element => element.querySelector('img')?.getAttribute('title'),
        rendered: false
      },
      width: {
        default: null,
        parseHTML: element => element.querySelector('img')?.getAttribute('width'),
        rendered: false
      },
      height: {
        default: null,
        parseHTML: element => element.querySelector('img')?.getAttribute('height'),
        rendered: false
      }
    }
  },

  parseHTML () {
    return [{ tag: 'figure' }]
  },

  renderHTML ({ node, HTMLAttributes }) {
    const { attrs } = node
    const { src, alt, width, height, isInlineStyle = false, inlineStyles = {} } = attrs
    const attributes = { src, alt, width, height }

    if (isInlineStyle) attributes.style = _get(inlineStyles, 'image')

    return ['figure', HTMLAttributes, ['img', attributes]]
  },

  addNodeView () {
    return VueNodeViewRenderer(NodeImage, {})
  },

  addCommands () {
    return {
      setImages: files => async ({ tr }) => {
        const promises = files.images.map(async image => getImageAttributes(image))
        const images = await Promise.all(promises)

        if (!images.length) return

        this.editor.commands.insertContentAt(tr.selection, [
          ...images.map(image => ({ type: this.name, attrs: image })),
          { type: 'paragraph' }
        ])
      }
    }
  },

  addProseMirrorPlugins () {
    return [
      handleDropImages({
        handler: ({ files, pos }) => this.editor.chain().setTextSelection(pos).setImages(new Files(files))
      }),
      handlePasteImages({
        handler: ({ files }) => this.editor.chain().setImages(new Files(files))
      })
    ]
  }
})
