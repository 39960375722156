export default [
  {
    name: 'custom_footer_deloods',
    path: 'modules/pages/deloods/FooterDeloods'
  },
  {
    name: 'custom_header_deloods',
    path: 'modules/pages/deloods/HeaderDeloods'
  },
  {
    name: 'campaign_deloods',
    path: 'modules/pages/deloods/CampaignDeloods'
  }
]
