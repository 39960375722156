export default [
  {
    name: 'custom_footer_rodekruisvlaanderen',
    path: 'modules/pages/rodekruisvlaanderen/FooterRodekruisvlaanderen'
  },
  {
    name: 'custom_header_rodekruisvlaanderen',
    path: 'modules/pages/rodekruisvlaanderen/HeaderRodekruisvlaanderen'
  },
  {
    name: 'custom_homepage_rodekruisvlaanderen',
    path: 'modules/pages/rodekruisvlaanderen/HomepageRodekruisvlaanderen'
  }
]
