import { get as _get } from 'lodash'

const expressions = {
  link: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-zA-Z]{2,}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
  youtube: /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/g,
  vimeo: /^.*vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/g,
  dailymotion: /^.*(?:\/video|dai.ly)\/([A-Za-z0-9]+)([^#&?]*).*/g,
  iframe: /^<iframe(.)+<\/iframe>$/g
}

export default name => {
  return _get(expressions, name)
}