<template>
  <div class="home">
    <article class="banner js-home-banner">
      <div
        class="banner__img"
        :style="`background-image: url(${$i18n.translate(attributes.banner.image)})`"
      />

      <div class="container">
        <div class="banner__wrapper">
          <div class="banner__content col-md-5 col-sm-9">
            <h1 class="banner__title">
              {{ $i18n.translate(attributes.banner.title) }}
            </h1>

            <p
              class="banner__text editor editor__content"
              v-html="$i18n.translate(attributes.banner.text)"
            />

            <button
              v-if="section.attributes.banner.button && section.attributes.banner.button.show"
              @click.prevent="setRouteNavigation(section.attributes.banner.button)"
              class="cta"
            >
              {{ $i18n.translate(section.attributes.banner.button.text) }}
            </button>
          </div>

          <ul
            class="counter__ul"
            v-if="metrics && stats"
          >
            <li
              class="counter__li"
              v-for="(metric, index) in metrics.items"
              :key="`metric_${index}`"
            >
              <span class="bold margin__b--6">
                <app-date
                  class="counter__stats"
                  v-if="metric.type === 'date' && metric.date"
                  :timestamp="metric.date"
                  filter="differenceInCalendarDays"
                />

                <app-money
                  class="counter__stats"
                  v-else-if="metric.type === 'amount_collected'"
                  :amount="stats[metric.type]"
                />

                <span
                  class="counter__stats"
                  v-else
                >
                  {{ stats[metric.type] }}
                </span>
              </span>

              <p class="counter__label">
                {{ $i18n.translate(metric.text) }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </article>

    <div class="container">
      <section class="steps">
        <div class="steps__wrapper">
          <ul class="steps__ul">
            <li
              class="steps__li"
              v-for="(step, index) in attributes.steps.items"
              :key="`step_home_${index}`"
            >
              <div class="steps__content">
                <figure
                  class="steps__icon"
                  :style="{ backgroundImage: `url(${$i18n.translate(step.img_src)})` }"
                >
                  <figcaption
                    class="figcaption"
                    v-html="$i18n.translate(step.title)"
                  />
                </figure>

                <h2
                  class="steps__title editor editor__content"
                  v-html="$i18n.translate(step.title)"
                />

                <p
                  class="editor editor__content"
                  v-html="$i18n.translate(step.text)"
                />
              </div>
            </li>
          </ul>

          <div class="steps__btn-container">
            <button
              v-if="attributes.steps.cta && attributes.steps.cta.show"
              @click.prevent="setRouteNavigation(attributes.steps.cta)"
              class="cta"
            >
              {{ $i18n.translate(attributes.steps.cta.text) }}
            </button>
          </div>
        </div>
      </section>
    </div>

    <div class="projects">
      <div class="container">
        <h2 class="projects__title">
          {{ $i18n.translate(attributes.projects.title) }}
        </h2>

        <section-projects-overview
          class="projects__items"
          :page="page"
          :section="projectSection"
        />

        <div class="projects__btn-container">
          <button
            v-if="attributes.projects.cta && attributes.projects.cta.show"
            @click.prevent="setRouteNavigation(attributes.projects.cta)"
            class="cta"
          >
            {{ $i18n.translate(attributes.projects.cta.text) }}
          </button>
        </div>
      </div>
    </div>

    <section class="campaigns">
      <div class="container">
        <div class="campaigns__wrapper">
          <article
            class="campaigns__item"
            v-for="(campaign, index) in attributes.campaigns.items"
            :key="`campaign_home_${index}`"
          >
            <figure
              class="campaigns__img"
              :style="{ backgroundImage: `url(${$i18n.translate(campaign.image)})` }"
            >
              <figcaption />
            </figure>

            <div class="campaigns__content">
              <h2 class="campaigns__title">
                {{ $i18n.translate(campaign.title) }}
              </h2>

              <p
                class="editor editor__content"
                v-html="$i18n.translate(campaign.text)"
              />

              <button
                v-if="campaign.button && campaign.button.show"
                @click.prevent="setRouteNavigation(campaign.button)"
                class="cta campaigns__btn"
              >
                {{ $i18n.translate(campaign.button.text) }}
              </button>
            </div>
          </article>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'
import SectionProjectsOverview from '&/modules/pages/default/sections/SectionProjectsOverview'

export default defineComponent({
  name: 'CustomHomepageLightForTheWorld',
  components: {
    SectionProjectsOverview,
    AppDate: defineAsyncComponent(() => import('&/atoms/AppDate')),
    AppMoney: defineAsyncComponent(() => import('&/atoms/AppMoney'))
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  created () {
    this.fetchClientStatistics({ query: { campaign_id: this.metrics.campaign_id } })
  },
  mixins: [
    PageRouteNavigation
  ],
  computed: {
    ...mapGetters({
      stats: 'client/statistics'
    }),
    projectSection () {
      return {
        ...this.section,
        attributes: this.section.attributes.projects
      }
    },
    attributes () {
      return _get(this.section, 'attributes', {})
    },
    metrics () {
      return _get(this.attributes, 'metrics', {})
    }
  },
  methods: {
    ...mapActions({
      fetchClientStatistics: 'client/fetchClientStatistics'
    })
  }
})
</script>

<style lang="scss" scoped>
.home {
  position: relative;
  z-index: 10;
}

.banner {
  font-size: rem(16px);
  line-height: 1.3;
  position: relative;
  z-index: 10;
  color: $white;
  background-color: #333;

  @include mq(md) {
    padding-top: 156px;
    font-size: rem(18px);
    line-height: 24px;
  }

  &__img {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: .8;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__wrapper {
    position: relative;
    z-index: 10;
    display: flex;

    @include mq(md) {
      justify-content: flex-end;
    }
  }

  &__content {
    padding: 150px 0 100px;

    @include mq(md) {
      padding: 58px 0 276px;
    }
  }

  &__title {
    font-size: rem(28px);
    line-height: 1.3;
    margin-bottom: 9px;
    text-shadow: 2px 2px 3px rgba($color--tertiary, .9);

    @include mq(md) {
      font-size: rem(45px);
      line-height: 53px;
      text-shadow: 0px 0px 0px rgba($color--tertiary, 0);
    }
  }

  &__text {
    margin-bottom: 24px;
    color: #f8f8f8;
    text-shadow: 2px 2px 3px rgba($color--tertiary, .9);

    @include mq(md) {
      max-width: 80%;
      min-height: 120px;
      text-shadow: 0px 0px 0px rgba($color--tertiary, 0);
    }
  }
}

.counter {
  &__ul {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 730px;
    padding: 20px 30px;
    transition: opacity .2s linear, transform .2s linear;
    transform: translate3d(-50%, 50%, 0);
    text-align: center;
    opacity: 1;
    color: #dbdbdb;
    background-color: #017db5;

    @include mq(sm) {
      width: 90%;
      padding: 32px 80px;
      border-radius: 9px;
    }
  }

  &__li {
    width: 33%;
    line-height: 1.3;

    @include mq(sm) {
      line-height: 24px;
    }
  }

  &__stats {
    font-size: rem(24px);
    font-weight: 500;
    line-height: 1.2;
    display: block;
    margin-bottom: 6px;
    color: #f1f6ff;

    @include mq(sm) {
      font-size: rem(36px);
      line-height: 42px;
    }
  }

  &__label {
    font-size: rem(14px);

    @include mq(sm) {
      font-size: rem(18px);
    }
  }
}

.steps {
  position: relative;
  z-index: 5;
  padding-top: 118px;
  padding-bottom: 32px;
  background-color: #f8f8f8;

  @include mq(md) {
    padding-top: 150px;
    padding-bottom: 64px;
  }

  &__btn-container {
    margin-top: 64px;
    text-align: center;
  }

  &__ul {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    @include mq(md) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 30px;
    }

    @include mq(lg) {
      grid-gap: 55px;
    }
  }

  &__li {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      z-index: 0;
      display: none;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 100%;

      @include mq(md) {
        display: block;
      }
    }

    &:nth-child(1):before {
      bottom: 0;
      left: -30px;
      width: 233px;
      height: 162px;
      transform: translate3d(-50%, 50%, 0);
      background-image: image-path('lightfortheworld/icon_eye_mono.svg');
    }

    &:nth-child(2):before {
      top: -2px;
      right: 36px;
      width: 148px;
      height: 166px;
      transform: translate3d(50%, -50%, 0);
      background-image: image-path('lightfortheworld/icon_list_mono.svg');
    }

    &:nth-child(3):before {
      right: 0;
      bottom: 0;
      width: 196px;
      height: 206px;
      transform: translate3d(100%, 50%, 0);
      background-image: image-path('lightfortheworld/icon_gift_mono.svg');
    }
  }

  &__content {
    position: relative;
    min-height: 100%;
    padding: 18px 26px;
    border-radius: 2px;
    background-color: $white;

    @include mq(md) {
      padding: 32px 56px;
    }
  }

  &__icon {
    height: 50px;
    margin-bottom: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;

    @include mq(md) {
      margin-bottom: 30px;
    }

    .figcaption {
      display: none;
    }
  }

  &__title {
    margin-bottom: 23px;
    text-align: center;
    color: #017db5;
  }
}

.projects {
  padding: 64px 0;
  background-color: $white;

  &__title {
    font-size: rem(18px);
    font-weight: bold;
    line-height: 26px;
    margin-bottom: 32px;
    text-align: center;

    @include mq(sm) {
      font-size: rem(22px);
      margin-bottom: 28px;
      text-align: left;
    }
  }

  &__btn-container {
    text-align: center;
    margin-top: 28px;
  }
}

.campaigns {
  padding: 32px 0 128px;
  background-color: $white;

  &__wrapper {
    max-width: 1000px;
    margin: auto;
    padding: 0;

    @include mq(sm) {
      padding: 0 30px;
    }
  }

  &__item {
    position: relative;
    margin-bottom: 40px;

    @include mq(md) {
      display: flex;
      align-items: center;
      margin-bottom: 80px;
    }

    &:nth-child(odd) {
      .campaigns__img {
        @include mq(md) {
          order: 2;
          margin-left: -55px;
        }
      }
    }

    &:nth-child(even) {
      .campaigns__img {
        @include mq(md) {
          margin-right: -55px;
        }
      }

      .campaigns__content {
        @include mq(md) {
          padding: 50px 50px 50px 120px;
        }
      }
    }
  }

  &__content {
    position: relative;
    z-index: 30;
    margin-top: -10px;
    padding: 30px;
    color: #f1f6ff;
    border-radius: 0px 0px 8px 8px;
    background-color: #050d39;

    @include mq(md) {
      z-index: 10;
      margin-top: 0;
      padding: 50px 120px 50px 50px;
      border-radius: 8px;
      width: 750px;
    }
  }

  &__img {
    position: relative;
    z-index: 20;
    top: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 250px;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include mq(md) {
      height: inherit;
      align-self: stretch;
      margin: 40px 0;
      width: 100%;
      border-radius: 0px;
    }
  }

  &__title {
    font-size: rem(22px);
    font-weight: 700;
    line-height: 26px;
    margin-bottom: 24px;
    color: $color--primary;
  }

  &__btn {
    margin-top: 30px;
  }
}

.cta {
  background-color: #ffdd1c;
  border-radius: 4px;
  color: #050d39;
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  position: relative;
  padding: 11px 17px;
  transition: all $transition__duration;

  &:hover,
  &:focus {
    background-color: #e8c500;
  }
}
</style>
