<template>
  <header class="header">
    <div
      class="wrapper topbar d-flex justify-content-center justify-content-md-end"
      v-if="websites.length > 0"
    >
      <ul class="websites">
        <li
          class="websites__item"
          v-for="(website, index) in websites"
          :key="`website_${index}`"
        >
          <button
            class="websites__btn"
            @click.prevent="setRouteNavigation(website.link)"
          >
            <fa-icon
              class="websites__icon margin__r--6"
              :icon="['fas', 'chevron-right']"
            />

            {{ $i18n.translate(website.link.text) }}
          </button>
        </li>
      </ul>
    </div>

    <section class="wrapper">
      <app-burger-menu
        class="mobile burger"
        :state="mobileMenuOpened"
        @toggle="toggleMobileMenu"
      />

      <div class="header__col">
        <router-link
          class="logo__link"
          :to="{ name: 'Home', params: { locale: $i18n.locale } }"
          v-show="client.logo.url"
        >
          <img
            class="logo__image"
            :src="client.logo.url"
            :alt="client.name"
          >
        </router-link>

        <ul
          class="desktop desktop__links"
          v-if="navigation.length > 0"
        >
          <li
            class="desktop__links-item"
            v-for="(link , index) in navigation"
            :key="`menu_item_${index}`"
          >
            <a @click.prevent="setRouteNavigation(link.link)">
              {{ $i18n.translate(link.link.text) }}
            </a>
          </li>
        </ul>
      </div>

      <div class="header__col">
        <ul
          class="desktop desktop__buttons"
          v-if="actions.length > 0"
        >
          <li
            class="desktop__buttons-item bold"
            v-for="(button, index) in actions"
            :key="`menu_item_${index}`"
            @click.prevent="setRouteNavigation(button.link)"
          >
            {{ $i18n.translate(button.link.text) }}
          </li>
        </ul>

        <div class="actions">
          <button
            class="actions__login bold"
            @click.prevent="openSessionAppModal('register')"
            v-if="!isAuthenticated"
          >
            <fa-icon
              class="margin__r--6"
              :icon="['fas', 'user-circle']"
            />

            <span v-html="$i18n.t('literal.login')" />
          </button>

          <router-link
            class="actions__login bold"
            :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
            v-if="isAuthenticated && user"
          >
            <user-profile-img
              size="2"
              :avatar="user.avatar"
            />

            <span
              class="top__user-name d-block margin__l--6"
              v-html="truncate($i18n.translate(user.firstname), 10)"
            />
          </router-link>

          <button
            :state="searchOpened"
            @click="toggleSearch"
          >
            <fa-icon
              class="actions__search"
              :icon="['fal', 'search']"
              fixed-width
            />
          </button>
        </div>
      </div>
    </section>

    <div
      class="mobile mobile__menu"
      v-if="mobileMenuOpened"
    >
      <ul v-if="navigation.length > 0">
        <li
          class="mobile__link"
          v-for="(link, index) in navigation"
          :key="`menu_item_${index}`"
        >
          <a
            class="mobile__link--text"
            @click.prevent="setRouteNavigation(link.link); mobileMenuOpened = false"
          >
            {{ $i18n.translate(link.link.text) }}
          </a>
        </li>
      </ul>

      <ul v-if="actions.length > 0">
        <li
          class="mobile__button bold"
          v-for="(button, index) in actions"
          :key="`menu_item_${index}`"
          @click.prevent="setRouteNavigation(button.link); mobileMenuOpened = false"
        >
          {{ $i18n.translate(button.link.text) }}
        </li>

        <li v-if="!isAuthenticated">
          <button
            class="mobile__button bold mobile__login"
            @click.prevent="openSessionAppModal('register')"
          >
            <fa-icon
              class="margin__r--6"
              :icon="['fas', 'user']"
            />

            <span v-html="$i18n.t('literal.login')" />
          </button>
        </li>

        <li v-if="isAuthenticated && user">
          <router-link
            class="mobile__button bold mobile__login"
            :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
          >
            <user-profile-img
              size="2"
              :avatar="user.avatar"
            />

            <span class="top__user-name d-block margin__l--6">{{ user.firstname }}</span>
          </router-link>
        </li>
      </ul>

      <search-field
        class="mobile__search"
        :placeholder="$i18n.translate(search.placeholder)"
        @submit="goToProjects($event)"
      />
    </div>

    <div class="desktop__search">
      <search-field
        class="desktop__search--form"
        :placeholder="$i18n.translate(search.placeholder)"
        @submit="goToProjects($event)"
        v-if="searchOpened"
      />
    </div>

    <app-modal
      v-if="showSessionAppModal"
      size="sm"
      @close="showSessionAppModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="template"
          @authenticated="showSessionAppModal = false"
        />
      </template>
    </app-modal>
  </header>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'
import UserProfileImg from '&/atoms/UserProfileImg'
import SearchField from '&/molecules/SearchField'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
import PageRouteNavigation from '%/PageRouteNavigation'
import { truncate } from '@/utils/utils'

export default defineComponent({
  name: 'CustomHeaderJessa',
  components: {
    UserProfileImg,
    SessionForms: defineAsyncComponent(() => import('&/modules/sessions/SessionForms')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal')),
    SearchField,
    AppBurgerMenu
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      showSessionAppModal: false,
      mobileMenuOpened: false,
      searchOpened: false,
      template: null,
      truncate
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    attributes () {
      return _get(this.section, 'attributes', {})
    },
    websites () {
      return _get(this.attributes, 'websites', [])
    },
    navigation () {
      return _get(this.attributes, 'navigation', [])
    },
    actions () {
      return _get(this.attributes, 'actions', [])
    },
    search () {
      return _get(this.section, 'attributes.search', {})
    }
  },
  methods: {
    openSessionAppModal (template) {
      this.template = template
      this.showSessionAppModal = true
    },
    toggleMobileMenu (value) {
      this.mobileMenuOpened = !value
    },
    toggleSearch () {
      this.searchOpened = !this.searchOpened
    },
    goToProjects (search) {
      this.$router.push({ path: `/${this.$i18n.locale}/projects?search=${search}` })
    }
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #74c9b8;
$color--secondary: #98b6dd;
$off-white: #f7f7f7;
$light-grey: #eee;
$dark-grey: #ccc;

.header {
  position: sticky;
  top: 0;
  width: 100%;
  background: $white;
  border-bottom: 1px solid $light-grey;
  z-index: 100;

  &__col {
    display: flex;

    &:nth-first-child(1) {
      text-align: center;
    }

    &:nth-last-child(1) {
      display: none;

      @include mq(sm) {
        display: flex;
        justify-content: flex-end;
        align-self: center;
      }
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: $padding__base;

  @include mq(sm) {
    padding: $padding__base 0;
    max-width: 640px;
    margin: 0 auto;
  }

  @include mq(md) {
    display: flex;
    justify-content: space-between;
    max-width: 960px;
    flex-direction: row;

    &__col {
      display: flex;
    }
  }

  @include mq(lg) {
    max-width: 1140px;
  }
}

.topbar {
  padding-bottom: 0;
}

.websites {
  font-size: rem(12px);
  display: flex;
  flex-wrap: wrap;

  @include mq(md) {
    display: block;
    font-size: rem(12px);
  }

  &__item {
    padding: calc($padding__base / 4) calc($padding__base / 2);

    @include mq(md) {
      padding: 0;
    }
  }

  &__btn {
    color: #131415;
    display: flex;
    align-items: center;
    transition: all .2s;

    &:hover {
      color: #4CD2AE;
    }
  }

  &__icon {
    font-size: rem(8px);
    color: #4CD2AE;
  }
}

.mobile {
  &__menu {
    position: absolute;
    width: 100vw;
    background: $white;
    z-index: 100;
  }

  &__link {
    padding: $padding__base;
    border-bottom: 2px solid $off-white;

    &--text {
      color: $color--primary;
    }
  }

  &__button {
    background: $off-white;
    padding: $padding__base $padding__base * 2;
    text-align: center;
    text-transform: uppercase;
    color: $white;
    width: 100%;
    font-size: rem(12px);

    &:nth-child(1) {
      background: $color--secondary;
    }

    &:nth-child(2) {
      background: $color--primary;
    }

    &:nth-last-child(1) {
      background: $white;
      color: $color--primary;
    }
  }

  &__login {
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }

  &__login, &__search {
    @include mq(sm) {
      display: none;
    }
  }

  @include mq(sm) {
    width: 275px;
    margin-left: $margin__base * 5;
  }

  @include mq(md) {
    display: none;
  }
}

.desktop {
  display: none;

  &__links, &__buttons {
    align-items: center;
  }

  &__links {
    margin-left: $margin__base * 3;

    &-item {
      position: relative;

      &:not(:nth-child(1)) {
        margin-left: $margin__base * 2;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        height: 1px;
        margin-top: 3px;
        background: $dark-grey;
        transition: transform .2s ease-out;
        transform: scaleX(0);
        transform-origin: left;
      }

      &:hover {
        &::after {
          transform: scaleX(100%);
        }
      }
    }
  }

  &__buttons-item {
    position: relative;
    cursor: pointer;
    margin-right: $margin__base * 2;

    &:nth-child(1) {
      color: $color--secondary;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        height: 1px;
        margin-top: 3px;
        background: $color--secondary;
        transition: transform .2s ease-out;
        transform: scaleX(0);
        transform-origin: left;
      }

      &:hover {
        &::after {
          transform: scaleX(100%);
        }
      }
    }

    &:nth-child(2) {
      color: $color--primary;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        height: 1px;
        margin-top: 3px;
        background: $color--primary;
        transition: transform .2s ease-out;
        transform: scaleX(0);
        transform-origin: left;
      }

      &:hover {
        &::after {
          transform: scaleX(100%);
        }
      }
    }
  }

  &__search {
    position: absolute;
    width: 100vw;
    background: $light-grey;
    transition: all ease-in .6s;

    &--form {
      background: $light-grey;
      max-width: 400px;
      margin: 0 auto;
    }
  }

  @include mq(md) {
    display: flex;
  }
}

.logo {
  &__link {
    width: fit-content;
    line-height: normal;
  }

  &__image {
    max-width: 70px;
    height: auto;

    @include mq(md) {
      width: 105px;
      height: auto;
    }
  }
}

.burger {
  padding: 0;
  margin: 0;
  width: 124px;
  justify-content: flex-end;

  :deep()
  {
    .menu {
      padding: 0;

      &__label {
        display: none;
      }

      &__line, &__close::before, &__close::after {
        background: $color--primary;
      }
    }
  }

  @include mq(md) {
    display: none;
  }
}

.login {
  display: none;

  @include mq(sm) {
    display: block;
  }
}

.actions {
  display: none;
  color: $color--primary;

  @include mq(sm) {
    display: flex;
    justify-content: flex-end;
  }

  &__login {
    position: relative;
    color: $color--primary;
    height: fit-content;
    align-items: center;
    display: flex;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      display: block;
      width: 100%;
      height: 1px;
      margin-top: 3px;
      background: $dark-grey;
      transition: transform .2s ease-out;
      transform: scaleX(0);
      transform-origin: left;
    }

    &:hover {
      &::after {
        transform: scaleX(100%);
      }
    }
  }

  &__search {
    color: #222222;
    margin-left: $margin__base * 2;
  }
}

:deep(){
  .search {
    padding: $padding__base;
    width: auto;
    background: $light-grey;
    border-radius: 0;

    &__input {
      background: $white;
    }

    &__btn {
      background: $color--secondary;
      color: $white;
    }
  }

  .input--border {
    border: none;
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}
</style>