import { gtm } from '@/vendors/integrations'

export default class {
  static view = ({ update }) => {
    gtm.track({
      event: 'view_update',
      update_id: update.id,
      target_type: update.target_type,
      target_id: update.target_id
    })
  }
}
