import { toValue, computed } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { storeToRefs } from 'pinia'
import { useCurrencyStore } from '@/stores/CurrencyStore'

export function useMoney () {
  const { locale } = useI18n()
  const { currency, getThousands, getDecimals } = storeToRefs(useCurrencyStore())

  const getSymbol = computed(() => {
    return Intl.NumberFormat(locale, { style: 'currency', currencyDisplay: 'narrowSymbol', currency: currency.value })
      .formatToParts(0)
      .filter(part => part.type === 'currency')
      .map(({ value }) => value)
      .join('')
  })

  const getAmount = ({ amount, showCurrency = true }) => {
    if (typeof toValue(amount) !== 'number') return

    const number = toValue(amount) / 100
    const minimumFractionDigits = number % 1 === 0 ? 0 : 2
    const style = toValue(showCurrency) ? 'currency' : 'decimal'

    return new Intl.NumberFormat(locale, { style, currency: toValue(currency), minimumFractionDigits, currencyDisplay: 'narrowSymbol' })
      .formatToParts(number)
      .map(({ type, value }) => {
        if (type === 'group') return getThousands.value
        if (type === 'decimal') return getDecimals.value

        return value
      })
      .join('')
  }

  return {
    getSymbol,
    getAmount
  }
}