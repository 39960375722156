<template>
  <footer
    v-if="section"
    class="footer"
  >
    <div class="wrapper footer__wrapper">
      <div class="footer__col">
        <h4
          v-if="$i18n.translate(column_1.title)"
          class="footer__title bold margin__b--24"
        >
          {{ $i18n.translate(column_1.title) }}
        </h4>

        <img
          v-if="$i18n.translate(column_1.logo)"
          :src="$i18n.translate(column_1.logo)"
          alt="Logo - Vluchtelingenwerk Vlaanderen vzw"
          class="footer__logo"
        >

        <address
          v-if="$i18n.translate(organisation.title) || organisation.address.length > 0"
          class="footer__address margin__t--12"
        >
          <h5
            v-if="$i18n.translate(organisation.title)"
            class="bold"
          >
            {{ $i18n.translate(organisation.title) }}
          </h5>

          <template
            v-for="(item, index) in organisation.address"
            :key="'footer_address_line_' + index"
          >
            <span class="d-block">
              {{ $i18n.translate(item.line) }}
            </span>
          </template>
        </address>

        <address
          v-if="$i18n.translate(contact.title) || contactInfos.length > 0"
          class="footer__address margin__t--24"
        >
          <h5
            v-if="$i18n.translate(contact.title)"
            class="bold"
          >
            {{ $i18n.translate(contact.title) }}
          </h5>

          <template
            v-for="(item, index) in contactInfos"
            :key="'footer_infos_line_' + index"
          >
            <a
              class="footer__infos-link d-block"
              :href="item.url"
              target="_blank"
            >
              {{ item.text }}
            </a>
          </template>
        </address>
      </div>

      <div class="footer__col">
        <h4
          v-if="$i18n.translate(column_2.title)"
          class="footer__title bold margin__b--24"
        >
          {{ $i18n.translate(column_2.title) }}
        </h4>

        <ul
          class="footer__sponsors"
          v-if="sponsors.length > 1"
        >
          <template v-for="(sponsor, index) in sponsors">
            <li
              class="footer__sponsor"
              v-if="sponsor.logo[$i18n.locale]"
              :key="`sponsor_${index}`"
            >
              <a
                class="footer__sponsor-link"
                v-if="sponsor.link.url[$i18n.locale]"
                @click.prevent="setRouteNavigation(sponsor.link)"
              >
                <img
                  class="footer__sponsor-logo"
                  :src="$i18n.translate(sponsor.logo)"
                  :alt="$i18n.translate(sponsor.link.text)"
                >
              </a>

              <img
                class="footer__sponsor-logo"
                :src="$i18n.translate(sponsor.logo)"
                :alt="$i18n.translate(sponsor.link.text)"
                v-else
              >
            </li>
          </template>
        </ul>
      </div>

      <div
        class="footer__col"
        v-if="socialNetworks.length"
      >
        <h4
          v-if="$i18n.translate(column_3.title)"
          class="footer__title bold margin__b--24"
        >
          {{ $i18n.translate(column_3.title) }}
        </h4>

        <ul class="networks">
          <li
            class="networks__item"
            v-for="(network, index) in socialNetworks"
            :key="'footer_menu_network_' + index"
          >
            <a
              class="networks__link"
              :href="$i18n.translate(network.url)"
              target="_blank"
            >
              <span
                class="networks__text"
                v-if="network.text"
              >
                {{ $i18n.translate(network.text) }}
              </span>

              <fa-icon
                v-if="network.icon"
                size="lg"
                :icon="network.icon"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'

export default defineComponent({
  name: 'CustomFooterRefugeeWalk',
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client'
    }),
    organisation () {
      return _get(this.column_1, 'organisation', {})
    },
    contact () {
      return _get(this.column_1, 'contact', {})
    },
    contactInfos () {
      return _get(this.contact, 'infos', [])
        .map(info => {
          return {
            text: this.$i18n.translate(info.text),
            url: (info.type === 'phone_number' ? 'tel:' : 'mailto:') + this.$i18n.translate(info.text).replace(/ /g, '')
          }
        })
        .filter(info => info.text)
    },
    sponsors () {
      return _get(this.column_2, 'sponsors', [])
    },
    column_1 () {
      return _get(this.section, 'attributes.column_1')
    },
    column_2 () {
      return _get(this.section, 'attributes.column_2')
    },
    column_3 () {
      return _get(this.section, 'attributes.column_3')
    },
    socialNetworks () {
      const links = _get(this.client, 'platform.social_networks', [])

      return links
        .map(link => {
          if (link.icon.includes('facebook-square')) {
            link.icon[1] = 'facebook-f'
          } else if (link.icon.includes('linkedin')) {
            link.icon[1] = 'linkedin-in'
          }

          return link
        })
        .filter(link => link.url[this.$i18n.locale])
    }
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #FFB700;

.wrapper {
  max-width: 1318px;
  margin: 0 auto;
  padding: 0 $padding__base * 1.5;

  @include mq(sm) {
    padding: 0 $padding__base * 2;
  }
}

.networks {
  display: flex;
  font-size: rem(16px);

  &__item {
    &:not(:last-child) {
      margin-right: $margin__base * 1.5;
    }
  }

  &__text {
    display: block;
    width: 0;
    height: 0;
    color: transparent;
  }

  &__link {
    color: $white;
    transition: all .2s;

    &:hover {
      color: $color--primary;
    }
  }
}

.footer {
  position: relative;
  background: #221918;
  padding: $padding__base * 5 0;
  color: $white;
  font-size: rem(14px);

  &__wrapper {
    @include mq(sm) {
      display: grid;
      grid-template-columns: 1.5fr 1fr 1fr;
    }

    @include mq(md) {
      grid-template-columns: 6fr 3fr 3fr;
    }
  }

  &__col {
    &:not(:last-child) {
      margin-bottom: $margin__base * 3;

      @include mq(sm) {
        margin: 0 $margin__base * 2 0 0;
      }

      @include mq(md) {
        margin-right: $margin__base;
      }
    }
  }

  &__title {
    text-transform: uppercase;
    font-family: $font-family--secondary;
    font-size: rem(18px);
  }

  &__logo {
    max-width: 50%;
    height: auto;
  }

  &__address {
    color: rgba($white, 0.6);
  }

  &__infos {
    color: rgba($white, 0.6);

    &-link {
      color: rgba($white, 0.6);
      transition: all .2s;

      &:hover {
        color: $color--primary;
      }
    }
  }

  &__sponsors {
    display: grid;
    grid-template-columns: 85px 85px;
    row-gap: $margin__base;
    column-gap: $margin__base;
  }

  &__sponsor {
    &-link {
      display: block;
      transition: all .2s;

      &:hover {
        opacity: 0.7;
      }
    }

    &-logo {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
}
</style>
