import { computed, toValue } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'

export function useProcedureProducts ({ procedure, products = [] }) {
  const { getLocalesTranslations } = useI18n()

  const productSelectionBlocks = computed(() => _get(toValue(procedure), 'blocks.product_selection', []))
  const projectProductsBlock = computed(() => _get(toValue(procedure), 'blocks.project_products', {}))

  const allProducts = computed(() => toValue(products).map(product => {
    const block = productSelectionBlocks.value.find(block => _get(block, 'attributes.ids', []).includes(product.id)) || projectProductsBlock.value

    return Object.assign(product, { label: _get(block, 'attributes.label', getLocalesTranslations()) })
  }))

  return { products: allProducts }
}