import { ref,computed, toRefs } from 'vue'
import { useRouter, onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router'

export function useUnsaved (props) {
  const { push } = useRouter()

  const { condition } = toRefs(props)
  const to_route = ref(null)
  const show_unsaved_modal = ref(false)

  const isUnsaved = computed(() => !!condition.value)

  const leavePage = () => {
    show_unsaved_modal.value = false

    if (to_route.value) push(to_route.value)
  }

  const cancelLeavingPage = () => {
    show_unsaved_modal.value = false
    to_route.value = null
  }

  onBeforeRouteUpdate((to, from, next) => setUnsavedParams(to, from, next))
  onBeforeRouteLeave((to, from, next) => setUnsavedParams(to, from, next))

  const setUnsavedParams = (to, from, next) => {
    if (isUnsaved.value && !to_route.value) {
      to_route.value = to
      show_unsaved_modal.value = true
    } else {
      next()
    }
  }

  return {
    show_unsaved_modal,
    isUnsaved,
    cancelLeavingPage,
    leavePage
  }
}
