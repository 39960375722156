<template>
  <footer class="footer bg__color--white">
    <div class="container footer__wrapper">
      <div class="footer__col footer__logo">
        <router-link
          :to="{ name: 'Home', params: { locale } }"
          class="logo d-block margin__b--18"
          v-if="translate(column_1.logo)"
        >
          <img
            class="logo__img"
            :src="translate(column_1.logo)"
            :alt="client.name"
          >
        </router-link>
      </div>

      <div class="footer__col">
        <address
          v-if="address"
          v-html="address"
        />

        <p v-if="translate(column_2.phone_number.value)">
          <template v-if="translate(column_2.phone_number.label)">
            {{ `${translate(column_2.phone_number.label)} ` }}
          </template>

          <a
            :href="`tel:${translate(column_2.phone_number.value)}`"
            class="footer__phone_number"
          >
            {{ translate(column_2.phone_number.value) }}
          </a>
        </p>

        <p v-if="translate(column_2.email.value)">
          <template v-if="translate(column_2.email.label)">
            {{ `${translate(column_2.email.label)} ` }}
          </template>

          <a
            class="link__color--sec bold"
            :href="`mailto:${translate(column_2.email.value).replace(' ', '')}`"
          >
            {{ translate(column_2.email.value) }}
          </a>
        </p>

        <p
          v-if="translate(column_2.additional_info)"
          class="margin__t--18"
        >
          {{ translate(column_2.additional_info) }}
        </p>

        <ul
          class="networks margin__t--18"
          v-if="socialNetworks.length"
        >
          <li
            class="networks__li"
            v-for="(network, index) in socialNetworks"
            :key="'network_' + index"
          >
            <a
              class="networks__a"
              :href="translate(network.url)"
              target="_blank"
              :aria-label="translate(network.text)"
            >
              <fa-icon
                v-if="network.icon"
                size="lg"
                :icon="network.icon"
              />
            </a>
          </li>
        </ul>
      </div>

      <div class="footer__col">
        <nav v-if="column_3.nav && column_3.nav.length">
          <ul class="navigation__ul">
            <li
              v-for="({ link }, index) in column_3.nav"
              :key="`navigation_${index}`"
              class="navigation__li"
            >
              <app-link
                :link="link"
                class="navigation__link link--naked link__color--black-sec"
              />
            </li>
          </ul>
        </nav>

        <app-link
          class="btn btn__color--sec btn__size--classic cta margin__t--30 d-inline-block"
          :link="column_3.cta"
        />
      </div>

      <div class="footer__col footer__legal_information">
        <div
          class="editor editor__content banner__content margin__b--12"
          v-if="translate(column_4.content)"
          v-html="translate(column_4.content)"
        />

        <nav v-if="column_4.nav && column_4.nav.length">
          <ul class="legal-navigation__ul">
            <li
              v-for="({ link }, index) in column_4.nav"
              :key="`navigation_${index}`"
              class="legal-navigation__li"
            >
              <app-link
                :link="link"
                class="legal-navigation__link link--naked link__color--sec"
              />
            </li>
          </ul>
        </nav>

        <p
          class="footer__copyright"
          v-if="translate(column_4.copyright)"
        >
          {{ translate(column_4.copyright) }}
        </p>
      </div>

      <div class="footer__col footer__partners">
        <ul
          class="d-flex align-items-center justify-content-sm-end flex-wrap partners"
          v-if="column_5.partners && column_5.partners.length"
        >
          <li
            class="partners__li"
            v-for="({logo , link}, index) in column_5.partners"
            :key="`partner_${index}`"
          >
            <app-link
              v-if="translate(logo) && link.show && link.url"
              :link="link"
            >
              <img
                :src="translate(logo)"
                :alt="translate(link.text)"
              >
            </app-link>

            <img
              :src="translate(logo)"
              :alt="translate(link.text)"
              v-else-if="translate(logo)"
            >
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useClientStore } from '@/stores/ClientStore'
import { get as _get } from 'lodash'

import AppLink from '&/atoms/AppLink'

const props = defineProps({
  section: { type: Object, required: true }
})

const { getLocale, translate } = useI18n()
const { getClient } = useClientStore()

const { client } = getClient()

const locale = computed(() => getLocale())
const column_1 = computed(() => _get(props, 'section.attributes.column_1', {}))
const column_2 = computed(() => _get(props, 'section.attributes.column_2', {}))
const column_3 = computed(() => _get(props, 'section.attributes.column_3', {}))
const column_4 = computed(() => _get(props, 'section.attributes.column_4', {}))
const column_5 = computed(() => _get(props, 'section.attributes.column_5', {}))

const address = computed(() => _get(column_2.value, 'address', []).map(({ line }) => translate(line)).join('<br/>'))

const socialNetworks = computed(() => {
  const links = _get(client.value, 'platform.social_networks', [])
  return links.length > 0
    ? links.map(link => {
      if (link.icon.includes('facebook-square')) {
        link.icon[1] = 'facebook-f'
      } else if (link.icon.includes('linkedin')) {
        link.icon[1] = 'linkedin-in'
      }

      return link
    })
      .filter(link => translate(link.url))
    : []
})
</script>

<style lang="scss" scoped>
$color--secondary: #4061CC;

:deep(.cta) {
  padding: 18px 40px;
  border-radius: 27px;
  font-size: rem(16px);
  line-height: 1.16;
  letter-spacing: -0.16px;

  @include mq(md) {
    font-size: rem(18px);
  }
}

.footer {
  padding: 20px 0;
  color: #737682;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;

    @include mq(sm) {
      gap: 48px 24px;
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(md) {
      grid-template-columns: repeat(6, 1fr);
    }

    @include mq(lg) {
      gap: 48px 84px;
    }
  }

  &__col {
    min-width: 0;

    @include mq(md) {
      grid-column: span 2;
    }
  }

  &__phone_number {
    color: #737682;

    &:hover {
      color: $black;
    }
  }

  &__logo {
    @include mq(sm) {
      grid-column: span 2;
    }
  }

  &__legal_information,
  &__partners {
    color: #737682;
    font-size: rem(14px);
    line-height: 1.6;

    @include mq(md) {
      grid-column: span 3;
    }
  }

  &__copyright {
    font-size: rem(9px);
    line-height: 2;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
}
.networks {
  display: grid;
  grid-template-columns: repeat(5, 36px);
  gap: 6px 12px;

  &__a {
    display: flex;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    transition: $transition__duration;
    border-radius: 50%;
    color: $color--secondary;
    border: 2px solid $color--secondary;
    font-size: rem(14px);

    &:hover {
      color: darken($color--secondary, 10%);
      border-color: darken($color--secondary, 10%);
    }
  }
}

.navigation {
  &__li {
    &:not(:last-child) {
      margin-bottom: 24px;
    }

    &:deep() {
      .navigation__link {
        font-size: rem(18px);
        line-height: 1.1;

        &.is-active {
          color: $color--secondary;
        }
      }
    }
  }
}

.legal-navigation {
  &__ul {
    display: flex;
    flex-wrap: wrap;
  }

  &__li {
    padding: 3px 6px;
    position: relative;

    @include mq(md) {
      padding: 3px 12px;
    }

    &:first-child {
      padding-left: 0;
    }

    &:not(:last-child) {
      &::after {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateX(50%, -50%);
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #EBEBEB;

        @include mq(md) {
          content: '';
        }
      }
    }
  }
}

.partners {
  &__li {
    padding: 3px 0;

    &:not(:last-child) {
      margin-right: 30px;
    }
  }
}
</style>
