export default [
  {
    name: 'custom_footer_cncd',
    path: 'modules/pages/cncd/FooterCncd'
  },
  {
    name: 'custom_header_cncd',
    path: 'modules/pages/cncd/HeaderCncd'
  },
  {
    name: 'custom_homepage_cncd',
    path: 'modules/pages/cncd/HomepageCncd'
  }
]
