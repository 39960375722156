export default [
  {
    name: 'custom_header_rfl',
    path: 'modules/pages/rfl/HeaderRfl'
  },
  {
    name: 'custom_footer_rfl',
    path: 'modules/pages/rfl/FooterRfl'
  }
]
