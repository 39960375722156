import { Node } from '@tiptap/core'
import { getEmbedAttributes, handleClickNode, getPasteRules, isVideoEmbedLink, deleteNode } from '@/vendors/tiptap/helpers'
import { get as _get } from 'lodash'

export default Node.create({
  name: 'embed',
  group: 'block',
  selectable: true,
  draggable: true,
  priority: 1000,

  addOptions () {
    return {
      toggle: false
    }
  },

  addAttributes () {
    return {
      type: {
        default: 'code',
        parseHTML: element => element.getAttribute('type'),
        renderHTML: attributes => attributes.type ? { type: attributes.type } : {}
      },
      input: {
        default: null,
        parseHTML: element => element.getAttribute('input'),
        renderHTML: attributes => attributes.input ? { input: attributes.input } : {}
      },
      src: {
        default: null,
        parseHTML: element => element.querySelector('iframe')?.getAttribute('src'),
        rendered: false
      },
      width: {
        default: 630,
        parseHTML: element => element.querySelector('iframe')?.getAttribute('width'),
        rendered: false
      },
      height: {
        default: 354,
        parseHTML: element => element.querySelector('iframe')?.getAttribute('height'),
        rendered: false
      },
      frameborder: {
        default: 0,
        parseHTML: element => element.querySelector('iframe')?.getAttribute('frameborder'),
        rendered: false
      },
      allowfullscreen: {
        default: 0,
        parseHTML: element => element.querySelector('iframe')?.getAttribute('allowfullscreen'),
        rendered: false
      },
      allow: {
        default: null,
        parseHTML: element => element.querySelector('iframe')?.getAttribute('allow'),
        rendered: false
      }
    }
  },

  parseHTML () {
    return [
      { tag: 'object[input]' }
    ]
  },

  renderHTML ({ node, HTMLAttributes }) {
    return ['object', HTMLAttributes, [
      'iframe', {
        src: _get(node, 'attrs.src'),
        width: _get(node, 'attrs.width'),
        height: _get(node, 'attrs.height'),
        frameborder: _get(node, 'attrs.frameborder'),
        allowfullscreen: _get(node, 'attrs.allowfullscreen'),
        allow: _get(node, 'attrs.allow')
      }
    ]]
  },

  addCommands () {
    return {
      setEmbed: attributes => ({ tr, commands, chain }) => {
        if (attributes.input) {
          chain()
            .insertContentAt(tr.selection, [
              { type: this.name, attrs: getEmbedAttributes(attributes) },
              { type: 'paragraph' }
            ])
            .run()
        } else {
          commands.unsetEmbed()
        }
      },
      unsetEmbed: () => props => deleteNode({ props, name: this.name })
    }
  },

  addPasteRules () {
    return getPasteRules({
      type: this.type,
      expressions: ['youtube', 'vimeo', 'dailymotion', 'iframe'],
      handler: ({ chain, input }) => chain.setEmbed({ input, type: isVideoEmbedLink(input) ? 'video' : 'code' })
    })
  },

  addProseMirrorPlugins () {
    const plugins = []

    if (this.options.toggle) {
      plugins.push(handleClickNode({
        tag: 'object',
        type: this.type,
        toggle: this.options.toggle
      }))
    }

    return plugins
  }
})