<template>
  <footer
    v-if="section"
    class="footer"
  >
    <div class="wrapper footer__wrapper">
      <div>
        <section class="footer__logo">
          <router-link
            class="logo__img"
            :to="{ name: 'Home', params: { locale: $i18n.locale } }"
            v-if="client.logo.url"
          >
            <img
              v-if="client.logo"
              :src="client.logo.url"
              :alt="client.name"
            >
          </router-link>

          <div
            class="margin__l--24"
            v-if="$i18n.translate(logo.title) || $i18n.translate(logo.baseline)"
          >
            <h2 class="logo__title">
              {{ $i18n.translate(logo.title) }}
            </h2>

            <p class="logo__baseline">
              {{ $i18n.translate(logo.baseline) }}
            </p>
          </div>
        </section>

        <section
          v-if="navigation.length"
          class="footer__column margin__t--30"
        >
          <nav class="footer__nav">
            <ul
              class="footer__ul"
              v-for="({ links }, index) in navigation"
              :key="`footer_nav_${index}`"
            >
              <li
                class="footer__li"
                v-for="({ link }, i) in links"
                :key="`footer_menu_item_${i}`"
              >
                <button
                  class="footer__link"
                  :class="{'footer__link--big': i === 0 || index === 0}"
                  v-if="link && link.show && $i18n.translate(link.text)"
                  @click.prevent="setRouteNavigation(link)"
                >
                  {{ $i18n.translate(link.text) }}
                </button>
              </li>
            </ul>
          </nav>
        </section>
      </div>

      <div>
        <section v-if="socialNetworks.length > 0">
          <h3 class="networks__title">
            {{ $i18n.translate(social.title) }}
          </h3>

          <ul class="networks__ul">
            <li
              class="networks__li"
              v-for="(network, i) in socialNetworks"
              :key="'footer_menu_network_' + i"
            >
              <a
                class="networks__a"
                :href="$i18n.translate(network.url)"
                target="_blank"
              >
                <span
                  class="networks__span"
                  v-if="network.text"
                >
                  {{ $i18n.translate(network.text) }}
                </span>

                <fa-icon
                  v-if="network.icon"
                  size="lg"
                  class="networks__icon"
                  :icon="network.icon"
                />
              </a>
            </li>
          </ul>
        </section>

        <section>
          <h3 class="contact__title">
            {{ $i18n.translate(contact.title) }}
          </h3>

          <address
            class="contact__address"
            v-if="contact.address && contact.address.length"
          >
            <p
              v-for="({ line }, index) in contact.address"
              :key="`address_line_${index}`"
            >
              {{ $i18n.translate(line) }}
            </p>
          </address>

          <a
            class="contact__email"
            :href="`mailto:${$i18n.translate(contact.email)}`"
            target="_blank"
            v-if="$i18n.translate(contact.email)"
          >
            {{ $i18n.translate(contact.email) }}
          </a>
        </section>
      </div>
    </div>
  </footer>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'

export default defineComponent({
  name: 'CustomFooterCo2Logic',
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  mixins: [PageRouteNavigation],
  computed: {
    ...mapGetters({
      client: 'client/client'
    }),
    logo () {
      return _get(this.section, 'attributes.logo', {})
    },
    social () {
      return _get(this.section, 'attributes.social', {})
    },
    contact () {
      return _get(this.section, 'attributes.contact', {})
    },
    navigation () {
      return _get(this.section, 'attributes.navigation', {})
    },
    socialNetworks () {
      const links = _get(this.client, 'platform.social_networks') || []

      return links.length > 0
        ? links.map(link => {
          if (link.icon.includes('facebook-square')) {
            link.icon[1] = 'facebook-f'
          } else if (link.icon.includes('linkedin')) {
            link.icon[1] = 'linkedin-in'
          }

          return link
        })
          .filter(link => link.url[this.$i18n.locale])
        : []
    }
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #2DCD9B;
$color--secondary: #003732;

.wrapper {
  max-width: 1310px;
  margin: 0 auto;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
}

.logo {
  &__img {
    height: auto;
    display: inline-block;

    img {
      display: block;
      max-width: 90px;
      height: auto;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 800;
  }

  &__baseline {
    text-transform: uppercase;
    color: $color--primary;
  }
}

.footer {
  position: relative;
  background: rgba($color--secondary, .7);
  text-align: center;
  color: $white;
  padding: 50px 0 80px 0;

  @include mq(sm) {
    text-align: left;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: $margin--base * 2;

    @include mq(sm) {
      gap: $margin--base * 5;
      grid-template-columns: 1fr 305px;
    }

    @include mq(lg) {
      gap: $margin--base * 11;
    }
  }

  &__logo {
    display: block;

    @include mq(sm) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &__nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0 40px;

    @include mq(sm) {
      padding-top: 33px;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
  }

  &__li {
    margin: 6px 0;
  }

  &__link {
    position: relative;
    color: $white;
    position: relative;
    transition: all .3s;
    text-decoration: none;
    display: block;
    display: inline-block;

    &--big {
      font-size: 21px;
      font-weight: bold;
    }

    @include mq(sm) {
      text-align: left;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 2px;
      top: 100%;
      width: 0px;
      background-color: $color--primary;
      transition: all .6s;
    }

    &:hover:after, &--active:after {
      width: 36px;
    }

    &:hover, &--active {
      opacity: 0.85;
    }
  }
}

.networks {
  &__title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  &__ul {
    display: flex;
    justify-content: center;
    gap: $margin--base * 4;
    justify-items: center;
    align-items: center;
    margin-bottom: 24px;

    @include mq(sm) {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: $margin--base * 2;
    }
  }

  &__li {
    transition: all .3s ease-out;

    &:hover .networks__a {
      top: -4px;
    }
  }

  &__a {
    position: relative;
    top: 0;
    color: rgba($color--secondary, .7);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all .3s ease-out;
    width: 45px;
    height: 45px;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $white;
      border-radius: 50%;
      z-index: 0;
    }
  }

  &__icon {
    z-index: 1;
  }

  &__span {
    display: none;
  }
}

.contact {
  &__title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  &__address {
    margin-bottom: 16px;
  }

  &__email {
    color: $color--primary;
    display: inline-block;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 3px;
      top: 100%;
      width: 40px;
      background-color: $color--primary;
      transition: all .6s;
    }

    &:hover:after, &--active:after {
      width: 100%;
    }
  }
}
</style>
