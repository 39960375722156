<template>
  <article
    class="card campaign"
    :class="{ 'campaign--inactive': !isActive, 'campaign--highlighted': isHighlighted }"
    v-if="campaign"
  >
    <figure
      class="campaign__cover"
      :style="{ backgroundImage: cover }"
      aria-hidden="true"
    >
      <span
        class="campaign__inactive"
        v-if="!isActive"
      >
        {{ t('literal.inactive') }}
      </span>
    </figure>

    <div class="campaign__content">
      <h3
        :id="`campaign_title${uid}`"
        class="bold campaign__title"
      >
        {{ name }}
      </h3>

      <template v-if="description">
        <div
          class="editor__content margin__t--24"
          :id="`campaign_description${uid}`"
          v-if="isHighlighted"
          v-html="description"
        />

        <p
          class="margin__t--24"
          v-else
        >
          {{ truncate(shortDescription, 90) }}

          <button
            class="link__color--lg-dg campaign__readmore"
            :aria-label="t('literal.read_more_about_x_campaign', { x: translate(campaign.name) })"
            :aria-haspopup="`campaign_modal${uid}`"
            :aria-controls="`campaign_modal${uid}`"
            v-if="shortDescription.length > 90"
            @click="openModal()"
          >
            {{ t('literal.read_more') }}
          </button>
        </p>
      </template>

      <div
        class="cta__container padding__t--24"
        v-if="createProjectProcedure"
      >
        <template v-if="cta.show">
          <button
            class="btn btn__size--small btn__color--prim"
            :disabled="true"
            v-if="isDisabled"
          >
            {{ translate(cta.text) }}
          </button>

          <app-link
            :link="cta"
            :query="query"
            :id="campaign.slug"
            :aria-label="t('literal.i_launch_an_action_in_x_campaign', { x: translate(campaign.name) })"
            class="btn btn__size--small btn__color--prim"
            v-else
          />

          <p
            class="cta__info color--lg padding__tb--6"
            v-if="!isStarted"
          >
            <fa-icon :icon="['fal', 'clock']" />

            {{ t('literal.starts_in') }}

            <app-date
              :timestamp="startDate"
              filter="formatDistanceToNow"
            />
          </p>

          <p
            class="cta__info color--lg padding__tb--6"
            v-if="isEnded"
          >
            {{ t('literal.registrations_are_closed') }}
          </p>
        </template>
      </div>

      <app-link
        :link="campaign.learn_more"
        class="d-inline-block link__color--black margin__t--18"
        v-if="isHighlighted"
      />
    </div>

    <app-modal
      :id="`campaign_modal${uid}`"
      :aria-labelledby="`campaign_title${uid}`"
      :aria-describedby="`campaign_description${uid}`"
      :aria-hidden="!show_modal"
      aria-modal="true"
      size="xl"
      v-show="show_modal"
      v-if="!isHighlighted && shortDescription.length > 90"
      @close="closeModal"
    >
      <template #body>
        <campaign-card
          :is-highlighted="true"
          :campaign="campaign"
        />
      </template>
    </app-modal>
  </article>
</template>

<script setup>
import { computed, toRefs, defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useRoute } from 'vue-router'
import { useModals } from '@/composables/app/useModals'
import { get as _get } from 'lodash'
import { isPast } from 'date-fns'
import { truncate, getImagePath, guid } from '@/utils/utils'

import AppDate from '&/atoms/AppDate'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const AppModal = defineAsyncComponent(() => import('&/organisms/AppModal'))

const CampaignCard = defineAsyncComponent(() => import('&/organisms/CampaignCard'))

const props = defineProps({
  campaign: { type: Object, required: true },
  isHighlighted: { type: Boolean, default: false }
})

const { campaign, isHighlighted } = toRefs(props)

const { t, translate } = useI18n()
const { show_modal, openModal, closeModal } = useModals()
const { query } = useRoute()

const uid = computed(() => guid(1))

const createProjectProcedure = computed(() => _get(campaign.value, 'procedures', []).find(procedure => procedure.category === 'create-project'))
const startDate = computed(() => _get(createProjectProcedure.value, 'start_date'))
const endDate = computed(() => _get(createProjectProcedure.value, 'end_date'))

const name = computed(() => isHighlighted.value ? translate(campaign.value.name) : truncate(translate(campaign.value.name), 30))
const description = computed(() => translate(campaign.value.description) )
const shortDescription = computed(() => description.value ? description.value.replace(/(<([^>]+)>)/gi, '') : '')
const cta = computed(() => _get(campaign.value, 'cta', {}))
const cover = computed(() => [_get(campaign.value, isHighlighted.value ? 'image.url' : 'image.small.url'), getImagePath('placeholders/project-card-cover.jpg')].filter(v => v).map(image => `url('${image}')`).join(', '))

const isActive = computed(() => campaign.value.status !== 'inactive')
const isStarted = computed(() => startDate.value ? isPast(new Date(startDate.value)) : false)
const isEnded = computed(() => endDate.value ? isPast(new Date(endDate.value)) : false)

const isDisabled = computed(() => {
  if (!createProjectProcedure.value || !isActive.value) return true
  if (!translate(cta.value.url) && !cta.value.target_name && !cta.value.target_url) return true
  if (!isStarted.value || isEnded.value) return true
  return false
})
</script>

<style lang="scss" scoped>
.campaign {
  position: relative;
  overflow: hidden;
  border-radius: $radius__cards $radius__cards 0 0;
  color: $black;
  display: flex;
  flex-direction: column;

  &__cover {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @include aspect-ratio(3, 2);
  }

  &__inactive {
    position: absolute;
    padding: 4px 6px;
    right: 12px;
    bottom: 12px;
    border-radius: 3px;
    font-size: rem(14px);
    color: $white;
    background-color: $black;
  }

  &__content {
    text-align: center;
    padding: 18px 18px 24px 18px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: em(24px);
    line-height: 1.2;
  }

  &__readmore {
    position: relative;
    z-index: 20;
  }

  &--inactive {
    color: $lg;

    .campaign {
      &__cover {
        filter: grayscale(1);
      }
    }
  }

  &--highlighted {
    display: grid;
    grid-template-columns: 1fr;

    @include mq(md) {
      grid-template-columns: repeat(2, 1fr);
    }

    .campaign {
      &__cover {
        border-radius: $radius__cards;
      }

      &__content {
        padding: 36px 24px;
        display: block;

        @include mq(sm) {
          text-align: left;
        }
      }

      &__title {
        font-size: em(30px);

        @include mq(md) {
          font-size: em(42px);
        }
      }
    }

    .cta {
      &__container {
        @include mq(sm) {
          display: flex;
          align-items: center;
        }
      }

      &__info {
        @include mq(sm) {
          margin-left: 12px;
        }
      }
    }
  }
}

.cta {
  &__container {
    margin-top: auto;
  }

  &__info {
    font-size: rem(14px);
  }
}
</style>
