<template>
  <footer class="footer bg__color--vdw">
    <div class="container footer__container">
      <div class="footer__col">
        <a
          class="logo"
          :href="translate(column1.logo.url)"
          v-if="translate(column1.logo.url)"
        >
          <img
            class="logo__img"
            :src="translate(column1.logo.img) || client.logo.small.url"
            :alt="client.name"
          >
        </a>

        <router-link
          class="logo"
          :to="{ name: 'Home', params: { locale } }"
          v-else
        >
          <img
            class="logo__img"
            :src="translate(column1.logo.img) || client.logo.small.url"
            :alt="client.name"
          >
        </router-link>

        <ul
          class="networks"
          v-if="socialNetworks.length"
        >
          <li
            class="networks__li"
            v-for="(network, i) in socialNetworks"
            :key="'footer_network_' + i"
          >
            <a
              class="networks__a"
              :href="translate(network.url)"
              :aria-label="translate(network.text)"
              target="_blank"
            >
              <fa-icon
                class="networks__icon"
                size="lg"
                :icon="network.icon"
              />
            </a>
          </li>
        </ul>
      </div>

      <div class="footer__col">
        <h4
          class="footer__title align--center"
          v-if="translate(column2.title)"
        >
          {{ translate(column2.title) }}
        </h4>

        <a
          class="logo"
          :href="translate(column2.logo.url)"
          v-if="translate(column2.logo.url)"
        >
          <img
            class="logo__img"
            :src="translate(column2.logo.img)"
          >
        </a>

        <ul
          class="networks"
          v-if="column2.social_networks.length"
        >
          <template
            v-for="({ network }, i) in column2.social_networks"
            :key="'footer_organization_network_' + i"
          >
            <li
              class="networks__li"
              v-if="translate(network.url)"
            >
              <a
                class="networks__a"
                :href="translate(network.url)"
                target="_blank"
              >
                <fa-icon
                  class="networks__icon"
                  size="lg"
                  :icon="['fab', network.icon]"
                />
              </a>
            </li>
          </template>
        </ul>
      </div>

      <div class="footer__col footer__col--contact">
        <h4
          class="footer__title"
          v-if="translate(column3.title)"
        >
          {{ translate(column3.title) }}
        </h4>

        <div class="infos">
          <fa-icon
            class="infos__icon"
            :icon="['fal', 'map-marker-alt']"
          />

          <div>
            <address
              v-html="address"
              v-if="address"
            />

            <a
              class="link__color--prim link--naked"
              :href="`tel:${phone.replace(/[^\d.-]+/g, '')}`"
              v-if="phone"
            >
              {{ phone }}
            </a>
          </div>
        </div>

        <div class="infos">
          <fa-icon
            class="infos__icon"
            :icon="['fal', 'globe']"
          />

          <app-link
            class="link__color--prim link--naked infos__website"
            :link="column3.website"
          />
        </div>

        <app-link
          class="cta btn btn__color--prim"
          :link="column3.cta"
        >
          <template #default="{text}">
            <span>{{ text }}</span>
          </template>
        </app-link>
      </div>
    </div>

    <nav
      class="container nav"
      v-if="column4.links?.length"
    >
      <ul class="nav__ul">
        <li
          class="nav__li"
          v-for="({ link }, key) in column4.links"
          :key="`link_${key}`"
        >
          <app-link
            :link="link"
            class="nav__a link__color--prim link--naked"
            target="_self"
          />
        </li>
      </ul>
    </nav>
  </footer>
</template>

<script setup>
import { computed, toRefs } from 'vue'
import { useClientStore } from '@/stores/ClientStore'
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'

import AppLink from '&/atoms/AppLink'

const props = defineProps({ section: { type: Object, required: true } })

const { getClient } = useClientStore()
const { translate } = useI18n()
const { locale } = toRefs(useI18n())

const { client } = getClient()

const column1 = computed(() => _get(props, 'section.attributes.column_1', {}))
const column2 = computed(() => _get(props, 'section.attributes.column_2', {}))
const column3 = computed(() => _get(props, 'section.attributes.column_3', {}))
const column4 = computed(() => _get(props, 'section.attributes.column_4', {}))

const socialNetworks = computed(() => _get(client.value, 'platform.social_networks', []).filter(({ url }) => translate(url)))
const address = computed(() => column3.value?.address?.map(({ text }) => translate(text)).join('<br />'))
const phone = computed(() => translate(column3.value?.phone))
</script>

<style lang="scss" scoped>
$font-size__base: 18px;

.container {
  max-width: 1430px;
  padding-left: 20px;
  padding-right: 20px;

  @include mq(md) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.footer {
  padding: 60px 0;

  &__container {
    display: grid;
    grid-template-columns: 1fr;

    @include mq(md) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;

    &:first-child {
      padding-top: 0;
    }

    @include mq(md) {
      padding: 0
    }

    &--contact {
      @include mq(md) {
        padding-left: 40px;
        align-items: flex-start;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid $color--primary;

      @include mq(md) {
        border-bottom: 0;
        border-right: 1px solid $color--primary;
      }
    }
  }

  &__title {
    font-size: rem(25px);
    margin-bottom: 20px;
    color: $color--primary;
    font-weight: 500;
  }
}

.infos {
  display: flex;
  align-items: flex-start;
  color: $color--primary;
  gap: 6px;
  font-size: rem(14px);
  margin-bottom: 20px;

  &__icon {
    font-size: 24px;
    min-width: 24px;
  }

  &__website {
    font-size: rem(18px);
  }
}

.logo {
  display: block;

  &__img {
    display: block;
    width: auto;
    height: 100px;
  }
}

:deep(.cta) {
  padding: 20px 40px;
  border-radius: 0;
  display: inline-block;
  background: linear-gradient(to right, #29184a 50%, #422778 50%);
  transition: all 0.3s ease-out;
  background-size: 200% 100%;
  background-position: right bottom;
  margin-top: 10px;

  &:hover {
    background-position: left bottom;
  }
}

.networks {
  display: flex;
  gap: 10px;
  margin-top: 40px;

  &__a {
    width: 30px;
    height: 30px;
    background: $color--primary;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    transition: all $transition__duration;

    &:hover {
      background: $color--tertiary;
      color: $color--primary;
    }
  }
}

.nav {
  &__ul {
    font-size: rem(18px);
    text-align: center;
    padding-top: 30px;
    border-top: 1px solid $color--primary;

    @include mq(md) {
      border: 0;
      padding-top: 80px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
    }
  }

  &__li {
    padding: 10px 0;

    @include mq(md) {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 0;
    }

    &:not(:last-child) {
      &::after {
        content: '';
        display: block;
        width: 20px;
        height: 1px;
        background: $color--primary;
        margin: 20px auto 0;

        @include mq(md) {
          margin: 0;
        }
      }
    }
  }

  :deep(.nav__a) {
    display: block;
  }
}
</style>