import axios from '@/vendors/axios/axios'
import { get as _get } from 'lodash'
import Procedure from '@/classes/procedures/Procedure'
import ProcedureExecution from '@/classes/procedures/ProcedureExecution'

const endpoint = '/api/v1/procedures'
const resource = 'procedures'

export default {
  namespaced: true,
  state: {
    items: {},
    executions: {},
    cart: {}
  },
  getters: {
    getProcedureBySlug: state => slug => Object.values(state.items).find(item => item.slug === slug),
    getProcedureById: state => id => Object.values(state.items).find(item => item.id === id),
    getExecutionById: state => id => _get(state.executions, id, null),
    getCartById: state => id => Object.values(state.cart).find(cart => cart.id === id)
  },
  actions: {
    fetchProcedure: ({ dispatch }, { slug }) => dispatch(
      'fetchItem',
      {
        endpoint: `${endpoint}/${slug}`,
        resource,
        model: procedure => new Procedure(procedure)
      },
      { root: true }
    ),

    fetchProcedureExecution: ({ dispatch }, { slug, id }) => dispatch(
      'fetchItem',
      {
        endpoint: `${endpoint}/${slug}/executions/${id}`,
        model: execution => new ProcedureExecution(execution)
      },
      { root: true }
    ),

    postProcedure: ({ dispatch }, { slug, params }) => dispatch(
      'postItem',
      {
        endpoint: `${endpoint}/${slug}/execute`,
        model: execution => new ProcedureExecution(execution),
        params
      },
      { root: true }
    ),

    patchProcedure: ({ dispatch }, { id, params }) => dispatch(
      'patchItem',
      {
        endpoint: `${endpoint}/${id}`,
        resource,
        params: {
          procedure: params
        },
        model: procedure => new Procedure(procedure)
      },
      { root: true }
    ),

    patchProcedureInformations: ({ dispatch }, { slug, id, params }) => dispatch(
      'patchItem',
      {
        endpoint: `${endpoint}/${slug}/executions/${id}`,
        resource,
        params: {
          procedure_execution: {
            informations: params
          }
        }
      },
      { root: true }
    ),

    patchProcedureExecutionStore ({ commit }, { id, params }) {
      commit('setItem', { resource: `${resource}.executions`, id, item: params }, { root: true })

      return params
    },

    patchCart ({ commit, state }, { id, params }) {
      let cart = _get(state.cart, id, {})
      let total = { amount: 0 }

      Object.keys(params).forEach(key => cart[key] = params[key])

      total.amount += _get(cart, 'fee.amount', 0)
      total.amount += _get(cart, 'transaction.amount', 0)
      cart['order'].forEach(product => total.amount += product.quantity * product.price)

      const item = { ...cart, total }
      commit('setItem', { resource: `${resource}.cart`, id, item }, { root: true })

      return item
    },

    deleteProcedureExecutionStore ({ commit }, { id }) {
      commit('deleteItem', { resource: `${resource}.executions`, id }, { root: true })
    },

    checkTransaction ({}, { slug, id }) {
      return new Promise((resolve, reject) => {
        axios.get(`${endpoint}/${slug}/transactions/${id}/check`)
          .then(response => resolve(response.data.data))
          .catch(error => reject(error.response))
      })
    }
  }
}
