import { ref, reactive, computed, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { clone as _clone, pickBy as _pickBy, get as _get, omit as _omit } from 'lodash'
import filters from '@/configurations/general/filters'

export function useFilters ({ config = [], default_query = {}, default_sorting = { field: 'created_at', direction: 'desc' }, handleChange = null }) {
  const { push, replace } = useRouter()
  const route = useRoute()

  const show_filters = ref(false)

  const blocks = computed(() => config.map(name => filters.find(block => block.name == name)).filter(block => block))

  const query = reactive({ ...blocks.value.map(block => block.components).flat().reduce((query, component) => typeof component.model === 'object' ? { ...query, ...Object.keys(component.model).reduce((obj, key) => ({ ...obj, [component.model[key]]: component.value[key] }), {}) } : { ...query, [component.model]: component.value }, {}), per_page: 20, page: 1, 'sort_by_field[field]': default_sorting.field, 'sort_by_field[direction]': default_sorting.direction, ...default_query, ..._clone(_omit(route.query, ['open_filters'])) })

  const sorting = computed({
    get: () => ({ field: _get(query, 'sort_by_field[field]', default_sorting.field), direction: _get(query, 'sort_by_field[direction]', default_sorting.direction) }),
    set: ({ field, direction }) => direction ? Object.assign(query, { 'sort_by_field[field]': field, 'sort_by_field[direction]': direction }) : Object.assign(query, { 'sort_by_field[field]': default_sorting.field, 'sort_by_field[direction]': default_sorting.direction })
  })

  const hideFilters = () => show_filters.value = false

  watch(() => ({ ...query }), (to, from) => {
    if (to.page !== 1 && to.page === parseInt(from.page)) return query.page = 1

    push({ query: _pickBy(to, value => value) })

    if (handleChange) handleChange()
  })

  if (route.query.open_filters) {
    show_filters.value = true

    replace({ query: _omit(route.query, ['open_filters']) })
  }

  return {
    show_filters,
    query,
    blocks,
    sorting,
    hideFilters
  }
}
