export function useDownload () {
  const download = url => {
    const link = document.createElement('a')
    link.href = url
    link.download = url
    link.target = '_blank'

    link.click()
  }

  return {
    download
  }
}