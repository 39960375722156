export default [
  {
    name: 'custom_footer_umons',
    path: 'modules/pages/umons/FooterUmons'
  },
  {
    name: 'custom_header_umons',
    path: 'modules/pages/umons/HeaderUmons'
  },
  {
    name: 'custom_homepage_umons',
    path: 'modules/pages/umons/HomepageUmons'
  }
]
