<template>
  <button @click="handleClick">
    <slot />

    <template v-if="field === props.field && icon">
      <fa-icon
        size="xs"
        class="margin__l--6"
        :icon="['fal', icon]"
      />
    </template>
  </button>
</template>

<script setup>
import { computed } from 'vue'
import { get as _get } from 'lodash'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  field: { type: String, required: true },
  modelValue: { type: Object, required: true },
  direction: { type: String, default: 'desc' }
})

const field = computed(() => props.modelValue.field)
const direction = computed(() => props.modelValue.direction)
const directions = computed(() => props.direction === 'asc' ? ['asc', 'desc', null] : ['desc', 'asc', null])
const icon = computed(() => {
  const icons = props.direction === 'asc' ? { desc: 'chevron-up', asc: 'chevron-down' } : { desc: 'chevron-down', asc: 'chevron-up' }

  return _get(icons, direction.value, null)
})

const handleClick = () => {
  if (props.field !== field.value) return emit('update:modelValue', { field: props.field, direction: directions.value.find(d => d) })

  let i = directions.value.indexOf(direction.value)

  return emit('update:modelValue', { field: props.field, direction: directions.value[++i % directions.value.length] })
}
</script>