<template>
  <td
    class="table__td table__grip table__visible"
    role="cell"
    @click.stop
  >
    <fa-icon :icon="['fal', 'grip-vertical']" />
  </td>

  <td
    class="table__td table__ellipsis table__visible"
    role="cell"
  >
    {{ product.full_name }}
  </td>

  <td
    class="table__td table__td--large"
    role="cell"
  >
    <app-money
      v-if="product.price"
      :amount="product.price"
    />

    <span v-else>{{ t('literal.free') }}</span>
  </td>

  <td
    class="table__td"
    role="cell"
  >
    {{ product.total_ordered }}
  </td>

  <td
    class="table__td"
    role="cell"
  >
    <span v-if="product.is_unlimited_stock">
      {{ t('literal.unlimited') }}
    </span>

    <span v-else-if="product.current_stock > 0">
      {{ product.current_stock }}
    </span>

    <span v-else>
      {{ t('literal.out_of_stock') }}
    </span>
  </td>

  <td
    class="table__td"
    role="cell"
  >
    {{ productTarget }}
  </td>

  <td
    class="table__td"
    role="cell"
    @click.stop
  >
    <input-checkbox-switch
      :id="product.id"
      :disabled="isDisplayOptionDisabled"
      v-model="displayOnPage"
    />
  </td>

  <td
    class="table__td table__td--last table__visible"
    role="cell"
  >
    <fa-icon
      v-if="isCampaignProduct"
      :icon="['fal', 'lock']"
    />

    <fa-icon
      size="xs"
      v-else
      class="table__arrow"
      :icon="['fal', 'chevron-right']"
    />
  </td>
</template>

<script setup>
import { defineAsyncComponent, computed, toRefs } from 'vue'
import { useClientStore } from '@/stores/ClientStore'
import { useProjectStore } from '@/stores/ProjectStore'
import { useProductStore } from '@/stores/ProductStore'
import { useNotificationStore } from '@/stores/NotificationStore'
import { useProduct } from '@/composables/modules/projects/useProduct'
import { useI18n } from '@/vendors/i18n'
import { useRoute } from 'vue-router'
import { get as _get } from 'lodash'

const InputCheckboxSwitch = defineAsyncComponent(() => import('&/atoms/InputCheckboxSwitch'))
const AppMoney = defineAsyncComponent(() => import('&/atoms/AppMoney'))

const props = defineProps({ product: { required: true, type: Object } })

const route = useRoute()
const { getClient } = useClientStore()
const { patchProject, getProject } = useProjectStore()
const { patchProduct } = useProductStore()
const { t } = useI18n()
const { postSideNotification } = useNotificationStore()

const { client } = getClient()
const { product } = toRefs(props)
const { clonedProject } = getProject({ id: route.params.projectSlug })
const { productsDisplayOrder, productDisplayOrder, isCampaignProduct, isDisplayOptionDisabled } = useProduct(product, clonedProject)

const productTarget = computed(() => isCampaignProduct.value ? _get(client.value, 'name') : t('literal.project') )

const displayOnPage = computed({
  get: () => typeof productDisplayOrder.value == 'boolean' ? productDisplayOrder.value : product.value.display_on_page,
  set: value => {
    typeof productDisplayOrder.value == 'boolean' ? productDisplayOrder.value = value : productsDisplayOrder.value.push({ product_id: product.value.id, display_on_page: value })

    const promises = [patchProject({ id: route.params.projectSlug, params: { settings: { products_display_order: productsDisplayOrder.value } } })]

    if (!isCampaignProduct.value) {
      product.value.display_on_page = value
      promises.push(patchProduct({ id: product.value.id, params: { display_on_page: value } }))
    }

    Promise.all(promises).then(() => postSideNotification({ text: 'literal.modifications_have_been_saved' }))
  }
})
</script>

<style lang="scss" scoped>
.table {
  &__td:not(.table__visible) {
    display: none;

    @include mq(sm) {
      display: block;
    }
  }
}
</style>