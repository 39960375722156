<template>
  <v-dropdown
    :distance="distance"
    :placement="placement"
    :triggers="triggers"
    :disabled="disabled"
  >
    <template #default>
      <slot name="default" />
    </template>

    <template #popper>
      <slot name="popper" />
    </template>
  </v-dropdown>
</template>

<script>
import { defineComponent } from 'vue'
import { Dropdown as VDropdown } from 'floating-vue'

export default defineComponent({
  name: 'AppDropdown',
  components: {
    VDropdown
  },
  props: {
    triggers: {
      type: Array,
      default: () => {
        return ['click', 'focus']
      }
    },
    placement: {
      type: String
    },
    distance: {
      type: Number,
      default: 10
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
</script>